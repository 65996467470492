import {Box} from '@mui/material';
import React, {useState, useEffect} from 'react';
import PlaybookCompMapTable from './PlaybookCompMapTable';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import {useSelector} from 'react-redux';
import config from '../../../Utils/Config';
import theme from '../../../theme';
import PlaybookCompMapDisplay from './PlaybookCompMapDisplay';
import ConfigurationServices from '../../../services/ConfigurationServices';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';

const PlaybookCompMap = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [accessControl, setAccessControl]=useState('');
  const [ActiveMasterSectors, setActiveMasterSectors] = useState([]);
  const [ActiveSectors, setActiveSectors] = useState([]);
  const [ActiveCategories, setActiveCategories] = useState([]);
  const [ActiveCommodities, setActiveCommodities] = useState([]);
  const [ActiveSubCommodities, setActiveSubCommodities] = useState([]);
  const [ActiveCountry, setActiveCountry] = useState([]);
  const updateRow = ConfigurationServices.postPlayBookCompMapData;
  const selectedDropDownData = useSelector((state)=>state?.configurations?.selectedPlayBookCompMapDropDown);
  const [pagination, setPagination] = useState(10);
  const [page, setPage] = React.useState(0);
  const [addingTrigger, setAddingTrigger] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [ActiveMasterSectorsList, setActiveMasterSectorsList] = useState([]);
  const [ActiveSectorsList, setActiveSectorsList] = useState([]);
  const [ActiveCategoriesList, setActiveCategoriesList] = useState([]);
  const [ActiveCommoditiesList, setActiveCommoditiesList] = useState([]);
  const [ActiveSubCommoditiesList, setActiveSubCommoditiesList] = useState([]);

  const heads = [
    {col: 'Playbook Name', subcol: undefined},
    {col: 'Buy Plan Name', subcol: undefined},
    {col: 'As per Buy Plan Configuration', subcol: ['Master Sector', 'Sector', 'Country', 'Category', 'Commodity', 'Sub Commodity']},
    {col: 'Playbook Compliance Mapping', subcol: ['Master Sector*', 'Sector*', 'Country*', 'Category*', 'Commodity*', 'Sub Commodity*']},
    {col: 'Last Edited By', subcol: undefined},
    {col: 'Last Edited On', subcol: undefined},
  ];

  const values = {
    'Playbook Name': 'playbook_name',
    'Buy Plan Name': 'buy_plan_name',
    'Last Edited By': 'last_edited_by',
    'Last Edited On': 'last_edited',
  };

  const sortValues = [
    'Playbook Name',
    'Buy Plan Name',
    'Last Edited By',
    'Last Edited On',
    'Master Sector',
  ];

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const SortValue = useSelector((state)=>state.configurations.selectedSortName);

  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='last_edited') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          (a[SortValue]==null?'':a[SortValue])?.localeCompare((b[SortValue]==null?'':b[SortValue])));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          (b[SortValue]==null?'':b[SortValue])?.localeCompare((a[SortValue]==null?'':a[SortValue])));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      console.log('true');
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.last_edited));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.last_edited));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);


  useEffect(()=>{
    ConfigurationServices.getPlayBookCompMapData()
        .then((res)=>{
          if (res !=undefined && typeof res !== 'string') {
            const keys = [];
            res.length>0 && Object.keys(res[0]).map((obj) => {
              if (obj.split('_').includes('id')) {
                keys.push(obj);
              }
            });
            if (res['userMessage']) {
              setRow([]);
              setErrMsg(res['userMessage']);
            } else {
              const sortedListById = _.sortBy(res, (item) => item.buy_plan_id);
              const sortedListByLastDate = _.sortBy(sortedListById, (item) => new Date(item.last_edited)).reverse();
              let prevValue = null;
              sortedListByLastDate.forEach((data) => {
                if (prevValue !== data.buy_plan_id) {
                  data['show_value'] = true;
                  prevValue = data.buy_plan_id;
                } else {
                  data['show_value'] = false;
                }
              });
              setRow(sortedListByLastDate);
            }
          } else {
            setRow([]);
            setErrMsg('Error Occured due to backend connection.');
          };
        });
  }, [addingTrigger]);

  useEffect(()=>{
    let temp = [...row];
    if (Object.keys(selectedDropDownData).length!==0) {
      Object.entries(selectedDropDownData).map((obj, index)=>{
        if (obj[1].length!==0) {
          temp = temp.filter((r)=>{
            return obj[1].includes(r[obj[0]]);
          });
        }
      });
      setTableFilterData([...temp]);
      setPage(0);
    } else {
      setTableFilterData([...row]);
    }
  }, [selectedDropDownData, row]);


  useEffect(()=>{
    ConfigurationServices.getMasterSectorData()
        .then((res)=>{
          const activeMasterSectors = [];
          res.map((r)=>{
            r.is_active === true && activeMasterSectors.push(r);
          });
          setActiveMasterSectors([...activeMasterSectors]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCategoryData()
        .then((res)=>{
          const activeCategories = [];
          res.map((r)=>{
            r.is_active === true && activeCategories.push(r);
          });
          setActiveCategories([...activeCategories]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCommodityData()
        .then((res)=>{
          const activeCommodities = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rs)=>{
              rs.is_active==true && !activeCommodities.includes(r) && activeCommodities.push(r);
            });
          });
          setActiveCommodities([...activeCommodities]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getSubCommodityData()
        .then((res)=>{
          const activeSub = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rc)=>{
              rc.subCommodities.map((rs)=>{
                rs.is_active==true && !activeSub.includes(rc) && activeSub.push(rc);
              });
            });
          });
          setActiveSubCommodities([...activeSub]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCountrysData()
        .then((res)=>{
          const contr = [];
          res['commodityGroups'].map((rs)=>{
            rs.is_active==true && !contr.includes(rs) && contr.push(rs);
          });
          setActiveCountry([...contr]);
        });
  }, []);


  useEffect(()=>{
    ConfigurationServices.getSectordata()
        .then((res)=>{
          const activeSectors = [];
          res['commodityGroups'].map((r)=>{
            r.sectors.map((rs)=>{
              rs.active === true && !activeSectors.includes(r) && activeSectors.push(r);
            });
          });
          setActiveSectors([...activeSectors]);
        });
  }, []);

  useEffect(() => {
    if (!row || row.length === 0) {
      return;
    }

    // making list of unique master sector that are present in the table
    const uniqueSectorMaster = new Set(row.filter((obj) => obj.master_sector !== null && obj.master_sector !== '').map((obj) => obj.master_sector));
    let matchingObjects = ActiveMasterSectors.filter((obj) => uniqueSectorMaster.has(obj.master_sector));
    setActiveMasterSectorsList(matchingObjects);

    // making list of unique sectors that are present in the table
    const uniqueSector = new Set(row.filter((obj) => obj.sector !== null && obj.sector !== '').map((obj) => obj.sector));
    const sectors = ActiveSectors.flatMap((activeSector) => {
      return activeSector.sectors.map((sector) => ({
        ...sector,
        masterSectorName: activeSector.name,
      }));
    });
    matchingObjects = sectors.filter((obj) => uniqueSector.has(obj.name));
    setActiveSectorsList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueRegions = new Set(row.filter((obj) => obj.country !== null && obj.country !== '').map((obj) => obj.country));
    matchingObjects = ActiveCountry.filter((obj) => uniqueRegions.has(obj.region));
    setCountryList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueCategories = new Set(row.filter((obj) => obj.category !== null && obj.category !== '').map((obj) => obj.category));
    matchingObjects = ActiveCategories.filter((obj) => uniqueCategories.has(obj.category));
    setActiveCategoriesList(matchingObjects);

    // making list of unique commodities that are present in the table
    const uniqueCommodity = new Set(row.filter((obj) => obj.commodity !== null && obj.commodity !== '').map((obj) => obj.commodity));
    const commodity = ActiveCommodities.flatMap((activeCommodity) => {
      return activeCommodity.commodities.map((commodity) => ({
        ...commodity,
        categoryName: activeCommodity.name,
      }));
    });
    matchingObjects = commodity.filter((obj) => uniqueCommodity.has(obj.name));
    setActiveCommoditiesList(matchingObjects);

    // making list of unique subcommodities that are present in the table
    const uniqueSubCommodity = new Set(row.filter((obj) => obj.sub_commodity !== null && obj.sub_commodity !== '').map((obj) => obj.sub_commodity));
    const subCommodity = ActiveSubCommodities.flatMap((activeSubCommodity) => {
      return activeSubCommodity.subCommodities.map((subCommodity) => ({
        ...subCommodity,
        commodityName: activeSubCommodity.name,
      }));
    });
    matchingObjects = subCommodity.filter((obj) => uniqueSubCommodity.has(obj.name));
    setActiveSubCommoditiesList(matchingObjects);
  }, [row]);

  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);


  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Reports:BuyPlanReporting']) {
        setAccessControl(functionalityAccess['Reports:BuyPlanReporting']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;

  return (
    <Box
      sx={{
        bgcolor: '#eff6fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0px',
      }}
    >
      {(accessControl === Edit || accessControl === Read) && <Box
        sx={theme.palette.whitebgbox}
      >
        <PlaybookCompMapDisplay
          row={row}
          setPage={setPage}
          setPagination={setPagination}
          ActiveMasterSectors={ActiveMasterSectorsList}
          ActiveSectors={ActiveSectorsList}
          ActiveCategories={ActiveCategoriesList}
          ActiveCommodities={ActiveCommoditiesList}
          country={countryList}
          ActiveSubCommodities={ActiveSubCommoditiesList}
        />

        <Box sx={{}}>

          <PlaybookCompMapTable
            data={tableFilterData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            sortValues={sortValues}
            accessControl={accessControl}
            values={values}
            addingTrigger={addingTrigger}
            updateRow={updateRow}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            ActiveMasterSectors={ActiveMasterSectors}
            ActiveSectors = {ActiveSectors}
            ActiveCategories = {ActiveCategories}
            ActiveCommodities = {ActiveCommodities}
            ActiveSubCommodities ={ActiveSubCommodities}
            ActiveCountry={ActiveCountry}
            setPage={setPage}
            page={page}
          />

          {(errMsg !=='' || tableFilterData.length == 0) &&
        <ErrorMessage message='Playbook Compliance Mapping' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>
      }
    </Box>
  );
};

export default PlaybookCompMap;
