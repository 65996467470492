/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {Box} from '@mui/material';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './SummaryMetrics.css';
import {getSummaryMetricsData_API} from '../../../redux/features/apiCall';

const summaryMetricsFourDiv = {
  marginRight: '90px',
  display: 'flex',
};

const borderSummaryMetricsFourDiv = {
  border: '2.5px solid #0F5DAA',
  height: '45px',
};
const dataSummaryMetricsFourDiv = {
  marginLeft: '7px',
};
const numberSummaryMetricsFourDiv = {
  font: 'normal normal 700 26px/25px \'Segoe UI\'',
  color: '#3B4655',
};
const textSummaryMetricsFourDiv = {
  color: '#3B4655',
  font: 'normal normal 400 16px/23px \'Segoe UI\'',
};
const minorMajorSpan = {
  color: '#D54444',
  // fontSize: 'smaller',
  font: 'normal normal 600 12px/16px \'Segoe UI\'',
  marginLeft: '4px',
};

function SummaryMetrics() {
  const summaryMetricsAPIData = useSelector((state)=>state?.api?.apiValueSummaryMetricsData);

  const planStatusChange = useSelector((state)=>state?.api?.PlanStatusChange);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSummaryMetricsData_API());
  }, [planStatusChange]);

  return (
    <Box
      sx={{
        bgcolor: '#ffffff',
        borderRadius: '5px',
        width: '97%',
        marginTop: '20px',
        bgcolor: 'white',
        padding: '18px',
      }}
    >
      <Box
        component="span"
        sx={{font: 'normal normal 700 15px/21px \'Segoe UI\'', color: '#0F5DAA'}}
      >
        Summary Metrics
      </Box>
      <Box sx={{display: 'flex', paddingTop: '10px'}}>
        <Box sx={summaryMetricsFourDiv}>
          <Box sx={borderSummaryMetricsFourDiv}></Box>
          <Box sx={dataSummaryMetricsFourDiv}>
            <Box sx={numberSummaryMetricsFourDiv}>{summaryMetricsAPIData?.NEW}</Box>
            <Box sx={textSummaryMetricsFourDiv}>Open Buy Plans</Box>
          </Box>
        </Box>
        <Box sx={summaryMetricsFourDiv}>
          <Box sx={borderSummaryMetricsFourDiv}></Box>
          <Box sx={dataSummaryMetricsFourDiv}>
            <Box sx={numberSummaryMetricsFourDiv}>{summaryMetricsAPIData?.APPROVED}</Box>
            <Box sx={textSummaryMetricsFourDiv}>Approved Buy Plans</Box>
          </Box>
        </Box>
        <Box sx={summaryMetricsFourDiv}>
          <Box sx={borderSummaryMetricsFourDiv}></Box>
          <Box sx={dataSummaryMetricsFourDiv}>
            <Box sx={numberSummaryMetricsFourDiv}>{summaryMetricsAPIData?.CLOSED}</Box>
            <Box sx={textSummaryMetricsFourDiv}>
              Coverage Confirmed Buy Plans
            </Box>
          </Box>
        </Box>
        <Box sx={summaryMetricsFourDiv}>
          <Box sx={borderSummaryMetricsFourDiv}></Box>
          <Box sx={dataSummaryMetricsFourDiv}>
            <Box sx={numberSummaryMetricsFourDiv}>{((+summaryMetricsAPIData?.NORMAL || 0) + (+summaryMetricsAPIData?.CRITICAL || 0))}</Box>
            <Box sx={textSummaryMetricsFourDiv}>Compliance Events <Box component="span" sx={minorMajorSpan} >(Critical {summaryMetricsAPIData?.CRITICAL || 0})</Box></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SummaryMetrics;
