/* eslint-disable camelcase */
import styled from '@emotion/styled';
import {Box, FormControl, InputBase, MenuItem, Select, TableCell, TableRow, TextareaAutosize, createTheme} from '@mui/material';
import React, {useEffect, useState, useMemo} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import config from '../../../Utils/Config';
import {AppConfirmDialog} from '../../common/App-Dialog/AppDialog';
import theme from '../../../theme';

import customealltheme from '../../../theme';
const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const btnStyle={
  display: 'flex',
  border: '0.5px solid #CECECE',
  borderRadius: '4px',
  alignItems: 'center',
  gap: '5px',
  padding: '2px 6px',
  cursor: 'pointer',
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Edit = config.AccessLevel.Edit;
const CommonConfSingleRow = ({
  row,
  ActiveCategories,
  ActiveCommodities,
  ActiveSubCommodities,
  setAddingTrigger,
  Units,
  buyplanPersona,
  addingTrigger,
  accessControl,
  values,
  idKey,
  singleUpdate,
  setErrMsg,
  setIsErrorPopupVisible,
  editModeDisableTrigger,
  page,
  deleteNonPlayBookRow,
}) => {
  const [referenceValue, setReferenceValue] = useState(row['name']);
  const [ownerValue, setOwnerValue] = useState(row['npb_creator_user_id']);
  const [categoryValue, setCategoryValue] = useState(row['commodity_group_id']);
  const [categoryName, setCategoryName] = useState(row['category']);
  const [commodityValue, setCommodityValue] = useState(row['commodity_id']);
  const [commodityName, setCommodityName] = useState(row['commodity']);
  const [subCommodityValue, setSubCommodityValue] = useState(row['sub_commodity_id']);
  const [editMode, setEditMode] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editedDetails, setEditDetails] = useState({});
  const [statusValue, setStatusValue] = useState(row['is_active']);

  function toCamelCase(arr) {
    let string = arr[0];
    for (let i = 1; i < arr.length; i++) {
      string = string + arr[i].charAt(0).toUpperCase() + arr[i].slice(1, arr[i].length);
    }
    return string;
  }
  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(() => {
    const keyvalues = {};
    values.map((h, _index) => {
      keyvalues[h] = row[h];
    });
    setEditDetails({...keyvalues});
  }, [row, values]);


  useEffect(() => {
    if (editMode === true) {
      setEditMode(false);
    }
  }, [editModeDisableTrigger]);

  const editTheRow = () => {
    resetDateOnCancel();
    setEditMode(true);
  };
  useEffect(() => {
    setEditMode(false);
  }, [page]);

  const resetDateOnCancel = () => {
    setReferenceValue(row['name']);
    setCategoryName(row['category']);
    setCommodityName(row['commodity']);
    setCategoryValue(row['commodity_group_id']);
    setCommodityValue(row['commodity_id']);
    setSubCommodityValue(row['sub_commodity_id']);
    setOwnerValue(row['npb_creator_user_id']);
    setStatusValue(row['is_active']);
    setEditMode(false);
  };

  // when indiviual edited and clicked this will send a update request
  const editRow = () => {
    setEditMode(false);
    setTimeout(() => {
      setAddingTrigger(!addingTrigger);
    }, 1000);
    const keyvalues = {};
    const originalEdited = {...editedDetails};
    values.map((h, _index) => {
      if (document.getElementById(h) !== null) {
        originalEdited[h] = document.getElementById(h).value;
      }

      keyvalues[toCamelCase(h.split('_'))] = document.getElementById(h) !== null ? document.getElementById(h).value : editedDetails[h];
    });
    setEditDetails({...originalEdited});
    idKey.map((idkey) => {
      keyvalues[`${idkey}`] = row[idkey];
      keyvalues[toCamelCase(idkey.split('_'))] = row[idkey];
      delete keyvalues[idkey];
    });
    const {
      id,
      sector_group_id,
      sector_id,
      currency_id,
      reporting_bu_id,
      region_id,
      local_unit_id,
      std_unit_id,
    } = row;

    const updatePayload = {
      id: id,
      name: referenceValue,
      commodityGroupId: categoryValue,
      sectorGroupId: sector_group_id,
      commodityId: commodityValue,
      sectorId: sector_id,
      regionId: region_id,
      subCommodityId: subCommodityValue,
      measurementUnitId: local_unit_id,
      stdUnitId: std_unit_id,
      currencyId: currency_id,
      active: statusValue,
      reportingBuId: reporting_bu_id,
      creatorId: ownerValue,
    };
    singleUpdate({
      ...updatePayload,
      // ...keyvalues,
    })
        .then((res) => {
          if (res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
            setAddingTrigger(!addingTrigger);
          }
        })
        .catch(() => {
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured');
          setAddingTrigger(!addingTrigger);
        });
  };

  const showDelPopup = () => {
    setShowConfirmDialog(true);
  };

  const handleDeleteRow = () => {
    deleteNonPlayBookRow(row['id'])
        .then((res) => {
          setShowConfirmDialog(false);
          if (res && res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
          } else if (res) {
            setAddingTrigger(!addingTrigger);
          }
        })
        .catch((err) => {
          setShowConfirmDialog(false);
          setIsErrorPopupVisible(true);
          setErrMsg(err?.message);
          setAddingTrigger(!addingTrigger);
        });
  };

  const getAllCommodities = useMemo(() => {
    let allCommodities = [];
    ActiveCommodities.length !== 0 &&
    categoryName !== '' && ActiveCommodities.forEach((obj) => {
      if (obj.name === categoryName) {
        allCommodities = [...allCommodities, ...obj.commodities];
      }
    });
    return _.orderBy(allCommodities, [(s) => s.name?.toLowerCase()].filter((sb) => sb.is_active));
  }, [ActiveCommodities, ActiveCommodities.length, categoryName]);

  return (
    <>
      {showConfirmDialog && (
        <AppConfirmDialog
          open={showConfirmDialog}
          handleClose={() => {
            setShowConfirmDialog(false);
          }}
          text={''}
          secondLineText={'Are you sure you want to delete this Non Playbook?'}
          setYes={handleDeleteRow}
          ifYesFirst={false}
        />
      )}
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {editMode == true ? (
            <TextareaAutosize
              style={theme.palette.TextArea}
              placeholder="Type in here…"
              value={referenceValue}
              minRows={1}
              maxRows={4}
              onChange={(e) => {
                setReferenceValue(e.target.value);
              }}
            />
          ) : (
            row['name']
          )}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['sector']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['country']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['business_unit']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {(editMode == true && !row.is_non_pb_wf_available) ?(
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={categoryValue}
              onChange={(e) => {
                setCategoryValue(e.target.value);
                setCommodityValue('');
                setSubCommodityValue('');
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox}>
                <span>Select</span>
              </MenuItem>
              {ActiveCategories.length !== 0 &&
                              _.orderBy(ActiveCategories, [(s) => s.category?.toLowerCase()])?.map(
                                  (r) =>
                                    r.is_active == true && (
                                      <MenuItem sx={customTheme.selectMenuBox} key={r?.category_id} value={r?.category_id} className="this-is-new-class"
                                        onClick={() => {
                                          setCategoryName(r.category);
                                        }}
                                      >
                                        {r.category}
                                      </MenuItem>
                                    ),
                              )}
            </Select>
          </FormControl>) :
            row['category']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {(editMode == true && !row.is_non_pb_wf_available) ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={commodityValue}
              onChange={(e) => {
                setCommodityValue(e.target.value);
                setSubCommodityValue('');
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox}>
                <span>Select</span>
              </MenuItem>
              {getAllCommodities?.map(
                  (obj) =>
                    obj.is_active === true && (
                      <MenuItem
                        sx={customTheme.selectMenuBox}
                        key={obj?.id}
                        value={obj?.id}
                        className="this-is-new-class"
                        onClick={() => {
                          setCommodityName(obj.name);
                        }}
                      >
                        {obj.name}
                      </MenuItem>
                    ),
              )}
            </Select>
          </FormControl> ) :
          row['commodity'] }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {(editMode == true && !row.is_non_pb_wf_available) ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={subCommodityValue}
              onChange={(e) => {
                setSubCommodityValue(e.target.value);
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox}>
                <span>Select</span>
              </MenuItem>
              {ActiveSubCommodities.length !== 0 &&
                              commodityName !== '' &&
                              ActiveSubCommodities?.map(
                                  (r) =>
                                    r.name === commodityName &&
                                  _.orderBy(r.subCommodities, [(a) => a.name?.toLowerCase()])?.filter((sb) => sb.is_active)?.map((obj) => (
                                    <MenuItem sx={customTheme.selectMenuBox} key={obj?.id} value={obj?.id} className="this-is-new-class">
                                      {obj.name}
                                    </MenuItem>
                                  )),
                              )}
            </Select>
          </FormControl>) :
          row['sub_commodity']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['local_unit']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['std_unit']}
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          {row['currency']}
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          {editMode === true ? (
            <FormControl style={{width: '100px'}}>
              <Select
                labelId="demo-multiple-checkbox-label"
                sx={customTheme.selectBox}
                value={ownerValue}
                onChange={(e) => {
                  setOwnerValue(e.target.value);
                }}
                label="Select"
                input={<CustomInput label="Tag" />}
                displayEmpty
                className="newclass"
              >
                {buyplanPersona.length !== 0 &&
                  _.orderBy(buyplanPersona?.buyplan_creator || [], [(cat) => cat?.userName?.toLowerCase()?.trim()])?.map((r) => (
                    <MenuItem sx={customTheme.selectMenuBox} key={r?.userId} value={r?.userId} className="this-is-new-class">
                      {r.userName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            row['npb_creator']
          )}
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          {editMode === true ? (
            <FormControl style={{width: '100px'}}>
              <Select
                labelId="demo-multiple-checkbox-label"
                sx={customTheme.selectBox}
                value={statusValue}
                onChange={(e) => {
                  setStatusValue(e.target.value);
                }}
                label="Select"
                input={<CustomInput label="Tag" />}
                displayEmpty
                className="newclass"
              >
                {[
                  {label: 'Active', value: true},
                  {label: 'InActive', value: false},
                ]?.map((obj, index) => (
                  <MenuItem sx={customTheme.selectMenuBox} key={index} value={obj.value} className="this-is-new-class">
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : row['is_active'] ? (
            'Active'
          ) : (
            'Inactive'
          )}
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          {row['updatedBy']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['last_updated_on']}
        </StyledTableCell>

        {accessControl === Edit && (
          <StyledTableCell align="left">
            <Box sx={{display: 'flex', gap: '5px'}}>
              {editMode === false ? (
                <>
                  <Box sx={{...btnStyle, width: '58px'}} onClick={editTheRow} style={customealltheme.palette.TableEdit}>
                    <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
                    <Box>Edit</Box>
                  </Box>
                  <Box onClick={showDelPopup} sx={{...btnStyle, opacity: 1}}>
                    <Box>❌</Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      ...btnStyle,
                      background: '#4297d3',
                      color: 'white',
                      width: '58px',
                      justifyContent: 'center',
                    }}
                    onClick={editRow}
                  >
                    <Box>Save</Box>
                  </Box>
                  <Box sx={{...btnStyle, width: '58px'}} onClick={resetDateOnCancel}>
                    <Box>Cancel</Box>
                  </Box>
                </>
              )}
            </Box>
          </StyledTableCell>
        )}
      </StyledTableRow>
    </>
  );
};

export default CommonConfSingleRow;
