/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {Box, CircularProgress} from '@mui/material';
import React, {useEffect, useState} from 'react'; //
import {useDispatch, useSelector} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn, intColumn} from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../services/dialogService';
import {setPlanStatusChange, updateStatusOfBuyPlan} from '../../redux/features/apiCall';
import {monthArr, roundToTwo} from '../../services/utils';
import Styles from '../../styles/workflowModal';
import {AppConfirmDialog, AppDialog, AppErrorDialog} from '../common/App-Dialog/AppDialog';
// import {styled} from '@mui/material/styles';
import {Button} from '@mui/material';
import customealltheme from '../../theme';
import {setReloadData} from '../../redux/features/configurations';
export default function CoverageSummary({wfDetails, selectedRow, setIsPopupVisible, selectedYear, allYears}) {
  const StyledCoverageSummBottomButton={
    'disabled': selectedRow.coverageMode === 'TREASURY' && selectedRow.atlasFxEnabled==true && true,
    'backgroundColor': customealltheme.palette.customBackground.activeButtonBackground,
    'color': customealltheme.palette.customText.white,
    'textTransform': 'none',
    'width': '133px',
    '&:hover': {
      backgroundColor: customealltheme.palette.customBackground.activeButtonBackground,
    },
    'fontFamily': 'Segoe UI',
    'borderRadius': '5px',
    'fontSize': '12px',
    'height': '30px',
    'fontWeight': 500,
    'marginTop': '18px',
    'float': 'right',
  };
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const pageStatus = useSelector((state) => state.btnStatus.page);
  const [coverageSummaryData, setCoverageSummaryData] = useState([[]]);
  const [isLoading, setIsLoading] = useState(true);
  const [isClosePopupVisible, setIsClosePopupVisible] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const today = new Date();
  const [nextMonthCloseBuyPlanTag, setnextMonthCloseBuyPlanTag] = useState(false);
  const [nextMonthBetweenDays, setnextMonthBetweenDays] = useState(false);
  const [errmsg, setErrmsg] = useState('');
  const isExecutionLastMonth = (selectedRow?.executionMonthYear.includes(today.getMonth()) && selectedRow?.executionMonthYear.includes(today.getFullYear())) ||
    (selectedRow?.executionMonthYear.includes(11) && selectedRow?.executionMonthYear.includes(today.getFullYear() - 1) && today.getMonth() == 0);

  const [isHideCloseButton, setIsHideCloseButton] = useState(!(selectedRow?.status === 'CLOSED' || buttonStatus === 'view' || (selectedRow?.atlasFxEnabled && isExecutionLastMonth && getCurrentMonthWDCount() <= 2)));
  const [columns, setcolumns] = useState([]);
  useEffect(() => {
    const cols = [
      {
        ...keyColumn('executionMonth', textColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['executionMonth'] !== null && (rowData['executionMonth'].toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['executionMonth']).toLocaleString('en-US') : rowData['executionMonth'].toLocaleString('en-US'))}</div>

        ),
        title: 'Execution Month', disabled: 'true',
      },
      {
        ...keyColumn('coverage_year_month', textColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['coverage_year_month'] !== null && (rowData['coverage_year_month'].toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coverage_year_month']).toLocaleString('en-US') : rowData['coverage_year_month'].toLocaleString('en-US'))}</div>

        ), title: 'Coverage Month', disabled: 'true',
      },
      {
        ...keyColumn('planned_coverage', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['planned_coverage'] !== null && (rowData['planned_coverage']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['planned_coverage'])?.toLocaleString('en-US') : rowData['planned_coverage']?.toLocaleString('en-US'))}</div>

        ),
        title: 'Planned Coverage', disabled: 'true',
      },
      {
        ...keyColumn('actual_coverage', textColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['actual_coverage'] !== null && (rowData['actual_coverage']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['actual_coverage'])?.toLocaleString('en-US') : rowData['actual_coverage']?.toLocaleString('en-US'))}</div>

        ),
        title: 'Actual Coverage', disabled: 'true',
      },
      {
        ...keyColumn('variance', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['variance'] !== null && (rowData['variance']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['variance'])?.toLocaleString('en-US') : rowData['variance']?.toLocaleString('en-US'))}</div>

        ),
        title: 'Variance', disabled: 'true',
      },
    ];

    // {...keyColumn('SUPPLIER', intColumn), title: 'Supplier Coverage', disabled: 'true'},
    // {...keyColumn('TREASURY', intColumn), title: 'Treasury Coverage', disabled: 'true'},
    // {...keyColumn('OTHER', intColumn), title: 'Other Coverage', disabled: 'true'}

    if (selectedRow.coverageMode == 'SUPPLIER' && selectedRow.others == false) {
      cols.push({
        ...keyColumn('SUPPLIER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['SUPPLIER'] !== null && (rowData['SUPPLIER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['SUPPLIER'])?.toLocaleString('en-US') : rowData['SUPPLIER']?.toLocaleString('en-US'))}</div>

        ), title: 'Supplier Coverage', disabled: 'true',
      });
    } else if (selectedRow.coverageMode == 'SUPPLIER' && selectedRow.others == true) {
      cols.push({
        ...keyColumn('SUPPLIER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['SUPPLIER'] !== null && (rowData['SUPPLIER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['SUPPLIER'])?.toLocaleString('en-US') : rowData['SUPPLIER']?.toLocaleString('en-US'))}</div>

        ), title: 'Supplier Coverage', disabled: 'true',
      });
      cols.push({
        ...keyColumn('OTHER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['OTHER'] !== null && (rowData['OTHER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['OTHER'])?.toLocaleString('en-US') : rowData['OTHER']?.toLocaleString('en-US'))}</div>

        ), title: 'Other Coverage', disabled: 'true',
      });
    } else if (selectedRow.coverageMode == 'TREASURY' && selectedRow.others == false) {
      cols.push({
        ...keyColumn('TREASURY', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['TREASURY'] !== null && (rowData['TREASURY']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['TREASURY'])?.toLocaleString('en-US') : rowData['TREASURY']?.toLocaleString('en-US'))}</div>

        ), title: 'Treasury Coverage', disabled: 'true',
      });
    } else if (selectedRow.coverageMode == 'TREASURY' && selectedRow.others == true) {
      cols.push({
        ...keyColumn('TREASURY', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['TREASURY'] !== null && (rowData['TREASURY']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['TREASURY'])?.toLocaleString('en-US') : rowData['TREASURY']?.toLocaleString('en-US'))}</div>

        ), title: 'Treasury Coverage', disabled: 'true',
      });
      cols.push({
        ...keyColumn('OTHER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['OTHER'] !== null && (rowData['OTHER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['OTHER'])?.toLocaleString('en-US') : rowData['OTHER']?.toLocaleString('en-US'))}</div>

        ), title: 'Other Coverage', disabled: 'true',
      });
    } else if (selectedRow.coverageMode == 'BOTH' && selectedRow.others == false) {
      cols.push({
        ...keyColumn('SUPPLIER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['SUPPLIER'] !== null && (rowData['SUPPLIER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['SUPPLIER'])?.toLocaleString('en-US') : rowData['SUPPLIER']?.toLocaleString('en-US'))}</div>

        ), title: 'Supplier Coverage', disabled: 'true',
      });
      cols.push({
        ...keyColumn('TREASURY', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['TREASURY'] !== null && (rowData['TREASURY']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['TREASURY'])?.toLocaleString('en-US') : rowData['TREASURY']?.toLocaleString('en-US'))}</div>

        ), title: 'Treasury Coverage', disabled: 'true',
      });
    } else if (selectedRow.coverageMode == 'BOTH' && selectedRow.others == true) {
      cols.push({
        ...keyColumn('SUPPLIER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['SUPPLIER'] !== null && (rowData['SUPPLIER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['SUPPLIER'])?.toLocaleString('en-US') : rowData['SUPPLIER']?.toLocaleString('en-US'))}</div>

        ), title: 'Supplier Coverage', disabled: 'true',
      });
      cols.push({
        ...keyColumn('TREASURY', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['TREASURY'] !== null && (rowData['TREASURY']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['TREASURY'])?.toLocaleString('en-US') : rowData['TREASURY']?.toLocaleString('en-US'))}</div>

        ), title: 'Treasury Coverage', disabled: 'true',
      });
      cols.push({
        ...keyColumn('OTHER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['OTHER'] !== null && (rowData['OTHER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['OTHER'])?.toLocaleString('en-US') : rowData['OTHER']?.toLocaleString('en-US'))}</div>

        ), title: 'Other Coverage', disabled: 'true',
      });
    } else if (selectedRow.coverageMode == 'NONE') {
      cols.push({
        ...keyColumn('OTHER', intColumn),
        component: ({rowData, focus}) => (
          <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['OTHER'] !== null && (rowData['OTHER']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['OTHER'])?.toLocaleString('en-US') : rowData['OTHER']?.toLocaleString('en-US'))}</div>

        ), title: 'Other Coverage', disabled: 'true',
      });
    }

    // cols.push({
    //   ...keyColumn('forecast_value', textColumn),
    //   component: ({rowData, focus}) => (
    //     <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['forecast_value'] !== null && (rowData['forecast_value']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['forecast_value'])?.toLocaleString('en-US') : rowData['forecast_value']?.toLocaleString('en-US'))}</div>

    //   ),
    //   title: 'Forecast Value', disabled: 'true',
    // });

    cols.push({
      ...keyColumn('actual_coverage_percentage', textColumn),
      component: ({rowData, focus}) => (
        <div style={{color: rowData.variance !== 0 ? 'white' : '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['actual_coverage_percentage'] !== null && (rowData['actual_coverage_percentage']?.toString().split('.').length > 1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['actual_coverage_percentage'])?.toLocaleString('en-US') : rowData['actual_coverage_percentage']?.toLocaleString('en-US'))}</div>

      ),
      title: 'Actual Coverage %', disabled: 'true',
    });

    setcolumns([...cols]);
  }, [selectedRow]);
  const dispatch = useDispatch();
  const availableRoles = useSelector((state) => state?.roleBasedAccess?.availableRoles);
  const currentRoleId = useSelector((state) => state?.roleBasedAccess?.currentRoleId);


  const [showApproveConfirmDialog, setshowApproveConfirmDialog] = useState(false);


  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const [accessControlReview, setAccessControlReview]=useState('');
  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Buy_Plan_Review']) {
      setAccessControlReview(functionalityAccess['Buy_Plan:Buy_Plan_Review']);
    }
  }, [functionalityAccess]);

  const approvalCall = () =>{
    const {workFlowId} = selectedRow;
    DialogService.updateStatus(workFlowId, 'APPROVED')
        .then((res) => {
          if (res == true) {
            // setOpen(true);
          }
        }).then(()=>{
          dispatch(setPlanStatusChange('APPROVED'));
          setshowApproveConfirmDialog(false);
          setIsPopupVisible(false);
          const d = new Date();
          dispatch(setReloadData(d.getMilliseconds()));
        })
        .catch((err) => {
          setshowApproveConfirmDialog(false);
          setIsErrorPopupVisible(true);
          setErrmsg(err.errorMessage);
        });
  };

  useEffect(() => {
    if (selectedRow.workFlowId) {
      const forecastData = async () => {
        await DialogService.getForecastData(selectedRow.workFlowId, selectedRow.prevWorkFlowId, selectedRow.sector)
            .then(async (res) => {
              const forecastVolume = [];
              if (res.length) {
                for (let i = 0; i < res.length; i++) {
                  if (res[i].workflowId === selectedRow.workFlowId) {
                    forecastVolume.push({...res[i], coverage_year_month: (res[i].forecastYearMonth&&res[i].forecastYearMonth.length>0 )?`${monthArr[res[i].forecastYearMonth[1] - 1]}-${res[i].forecastYearMonth[0]}`: 0});
                  }
                }
              }

              getCall(monthArr, forecastVolume);
            })
            .catch((err) => console.log(err));
      };
      forecastData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  useEffect(() => {
    if (currentRoleId) {
      // setCurrentRoleID(currentRoleId);
      availableRoles.map((role) => {
        if (currentRoleId === role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);
  useEffect(() => {
    const todayRef = new Date();
    const currentYear = todayRef.getFullYear();
    const currentMonth = (todayRef.getMonth()) + 1;
    const currentDate = getCurrentMonthWDCount();
    let flagToUpdate = false; // JUN 2023, JUL 5 2023 , atlas==true
    const checkYearAndMon = selectedRow.executionMonthYear;
    if (currentYear >= checkYearAndMon[0]) {
      if (currentYear == checkYearAndMon[0]) {
        if (currentMonth === checkYearAndMon[1]) {
          flagToUpdate = false;
        } else if (currentMonth > checkYearAndMon[1]) {
          if (((currentMonth - checkYearAndMon[1]) === 1)) {
            if (currentDate >= 3) {
              flagToUpdate = true;
              setnextMonthBetweenDays(true);
            } else {
              flagToUpdate = false;

              setnextMonthBetweenDays(false);
            }
          } else {
            flagToUpdate = true;
          }
        }
      } else {
        if ((currentMonth == 1 && checkYearAndMon[1] == 12)) {
          if (currentDate >= 3) {
            flagToUpdate = true;
            setnextMonthBetweenDays(true);
          } else {
            flagToUpdate = false;
            setnextMonthBetweenDays(false);
          }
        } else {
          flagToUpdate = true;
        }
      }
    } else {
      flagToUpdate = true;
    }
    setnextMonthCloseBuyPlanTag(flagToUpdate);
  }, [selectedRow, currentRole]);

  const getCurrentMonthWDCount = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    let worked = 0;
    for (let i = 1; i <= today.getDate(); i++) {
      const testDate = new Date(year, month, i);
      const day = testDate.getDay();
      if (day > 0 && day < 6) {
        ++worked;
      }
    }
    return worked;
  };
  const [coverageForecastVolume, setCoverageForecastVolume] = useState({});

  const getCall = async (month, forecastVolume) => {
    const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    await DialogService.getCoverageSummaryData(selectedRow.workFlowId).then((res) => {
      setIsLoading(false);
      const forecastData = {};
      for (let i = 0; i < forecastVolume.length; i++) {
        forecastData[forecastVolume[i].coverage_year_month] = forecastVolume[i].forecastValue;
      }
      setCoverageForecastVolume(forecastData);
      res.map((element) => {
        element.executionMonth = `${month[selectedRow.executionMonthYear[1] - 1]}-${selectedRow.executionMonthYear[0]}`;
        element.actual_coverage = roundToTwo(+wfDetails?.decimal_scale, (element.SUPPLIER ? element.SUPPLIER : 0) + (element.TREASURY ? element.TREASURY : 0) + (element.OTHER ? element.OTHER : 0));
        element.variance = roundToTwo(+wfDetails?.decimal_scale, (element.actual_coverage ? element.actual_coverage : 0) - (element.planned_coverage ? element.planned_coverage : 0));
        // forecast_volume has to be add from backend
        element.forecast_value = forecastVolume?.find((f)=>f.coverage_year_month == element.coverage_year_month)?.forecastValue;
        element.actual_coverage_percentage = (Math.abs(((element.actual_coverage?element.actual_coverage:0)/(forecastVolume?.find((f)=>f.coverage_year_month == element.coverage_year_month)?.forecastValue))*100)=='Infinity'||isNaN(Math.abs(((element.actual_coverage?element.actual_coverage:0)/(forecastVolume?.find((f)=>f.coverage_year_month == element.coverage_year_month)?.forecastValue))*100)))?0: Math.abs(((element.actual_coverage?element.actual_coverage:0)/(forecastVolume?.find((f)=>f.coverage_year_month == element.coverage_year_month)?.forecastValue))*100);
        element.SUPPLIER = (element.SUPPLIER ? element.SUPPLIER : 0);
        element.TREASURY = (element.TREASURY ? element.TREASURY : 0);
        element.OTHER = (element.OTHER ? element.OTHER : 0);
      });
      res.sort((a, b) => {
        const [mon1, year1] = a.coverage_year_month.split('-');
        const [mon2, year2] = b.coverage_year_month.split('-');
        return (year1 - year2) || (monthArr.indexOf(mon1) - monthArr.indexOf(mon2));
      });
      // for (let index=0; index < allYears.length; index++) {
      //   temp[index]=res?.filter((entry) => {
      //     return entry.coverage_year_month.endsWith(''+allYears[index]);
      //   });
      // }
      setCoverageSummaryData(res);
    });
  };

  const openConfirmBox = () => {
    setIsClosePopupVisible(true);
  };

  const closeBuyPlan = (wfId) => {
    DialogService.closeBuyPlan(wfId).then((res) => {
      setIsSuccessPopupVisible(true);
      setIsHideCloseButton(true);
      dispatch(updateStatusOfBuyPlan(wfId, 'CLOSED'));
    }).catch((e)=>{
      setIsErrorPopupVisible(true);
      if (e.errorMessage.includes('Total Coverage mismatch')) {
        setErrmsg('There\'s a mismatch between Total of the Coverage Volume on Historical Coverage tab and Total of the Coverage Volume entered on this tab. Please Check and Re-enter.');
      } else {
        setErrmsg(e.userMessage);
      }
    });
  };


  const [totalActualCoveragePercent, setTotalActualCoveragePercent] = useState(0);
  const [totalForecastValue, setTotalForecastValue] = useState(0);

  useEffect(() => {
    if (Object.keys(coverageForecastVolume)?.length > 0 && coverageSummaryData.length>0) {
      let denominatorOfActualCoveragePercentage = 0;
      let totalActualCoveragePercent = 0;
      //  weighted average of actual coverage percentage and forecast volume
      for (let i = 0; i < coverageSummaryData.length; i++) {
        denominatorOfActualCoveragePercentage += (coverageForecastVolume[coverageSummaryData[i].coverage_year_month]||0);
        totalActualCoveragePercent += (coverageSummaryData[i].actual_coverage_percentage * (coverageForecastVolume[coverageSummaryData[i].coverage_year_month]||0));
      }
      totalActualCoveragePercent = roundToTwo(+wfDetails?.decimal_scale, totalActualCoveragePercent / denominatorOfActualCoveragePercentage);
      setTotalForecastValue(denominatorOfActualCoveragePercentage);
      setTotalActualCoveragePercent(totalActualCoveragePercent);
    }
  }, [coverageSummaryData, coverageForecastVolume]);


  let totalPlannedCov = 0;
  let totalActCov = 0;
  let totalVariance = 0;
  let totalSuppCov = 0;
  let totalTresCov = 0;
  let totalOthCov = 0;
  coverageSummaryData.map((ele) => {
    totalPlannedCov = roundToTwo(+wfDetails?.decimal_scale, totalPlannedCov + (ele.planned_coverage === undefined ? 0 : ele.planned_coverage));
    totalActCov = roundToTwo(+wfDetails?.decimal_scale, totalActCov + (ele.actual_coverage === undefined ? 0 : ele.actual_coverage));
    totalVariance = roundToTwo(+wfDetails?.decimal_scale, totalVariance + (ele.variance === undefined ? 0 : ele.variance));
    totalSuppCov = roundToTwo(+wfDetails?.decimal_scale, totalSuppCov + (ele.SUPPLIER === undefined ? 0 : ele.SUPPLIER));
    totalTresCov = roundToTwo(+wfDetails?.decimal_scale, totalTresCov + (ele.TREASURY === undefined ? 0 : ele.TREASURY));
    totalOthCov = roundToTwo(+wfDetails?.decimal_scale, totalOthCov + (ele.OTHER === undefined ? 0 : ele.OTHER));
  });

  return (
    isLoading ?
      <Styles.StyledCircularProgress sx={{display: 'flex'}}>
        <CircularProgress sx={{color: '#0099D8'}} />
      </Styles.StyledCircularProgress> :
      <>
        <DataSheetGrid
          key="CoverageSummary"
          className="coverageSummary savedDatasheetGridCoverageTable"
          value={coverageSummaryData}
          columns={columns}
          // rowClassName={() => 'coverageSum'}
          disableContextMenu={true}
          gutterColumn={false}
          rowClassName={
            (
              ({rowData}) => ((rowData.planned_coverage ? rowData.planned_coverage : 0) - (rowData.actual_coverage ? rowData.actual_coverage : 0)) == 0 ? 'zeroVariance' : 'variance'
            )
          }
        />
        <Styles.StyledCoverageSummBottomButtonBox style={{display: 'flex'}}>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>Total</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}></Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalPlannedCov !== null && totalPlannedCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalActCov !== null && totalActCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalVariance !== null && totalVariance.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
          {
            selectedRow.coverageMode == 'BOTH' && selectedRow.others == true ?
              <>
                <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalSuppCov !== null && totalSuppCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalTresCov !== null && totalTresCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalOthCov !== null && totalOthCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
              </> :
              (
                selectedRow.coverageMode == 'BOTH' && selectedRow.others == false ?
                  <>
                    <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalSuppCov !== null && totalSuppCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                    <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalTresCov !== null && totalTresCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                  </> :
                  (
                    selectedRow.coverageMode == 'SUPPLIER' && selectedRow.others == true ?
                      <>
                        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalSuppCov !== null && totalSuppCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalOthCov !== null && totalOthCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                      </> :
                      (
                        selectedRow.coverageMode == 'TREASURY' && selectedRow.others == true ?
                          <>
                            <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalTresCov !== null && totalTresCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                            <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalOthCov !== null && totalOthCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>
                          </> :
                          (selectedRow.coverageMode == 'SUPPLIER' ?
                            <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalSuppCov !== null && totalSuppCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText> :
                            (
                              selectedRow.coverageMode == 'TREASURY' ?
                                <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalTresCov !== null && totalTresCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText> :
                                ''
                            ))
                      )
                  )
              )
          }
          {
            selectedRow.coverageMode == 'NONE' && <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalOthCov !== null && totalOthCov.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>

          }

          {/* <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalVariance !== null && totalForecastValue.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText> */}

          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{totalVariance !== null && totalActualCoveragePercent.toLocaleString('en-US')}</Styles.StyledCoverageSummBottomButtonBoxText>

        </Styles.StyledCoverageSummBottomButtonBox>


        { ((pageStatus=='active' || currentRole?.includes('Admin')) && (currentRole !== 'BuyPlan Viewer' && currentRole !== 'BuyPlan Approver' && currentRole !== 'Sector Governance' && currentRole !== 'Sector Finance' && !currentRole.includes('Owner') && selectedRow.status !== 'CLOSED' && selectedRow.status !=='SUSPENDED' && currentRole !=='GBS/Governance')) ?

        (selectedRow.atlasFxEnabled == true && nextMonthCloseBuyPlanTag==false) ?
           <Box> <Button sx={StyledCoverageSummBottomButton} style={{cursor: 'not-allowed', background: '#f2f2f2'}}>
                 Close Buy Plan</Button>
           </Box> :
      <Box sx={{mb: '2rem'}} >
        <Button sx={StyledCoverageSummBottomButton} onClick={() => {
          openConfirmBox();
        }}>Close Buy Plan</Button>
      </Box> :
      ''

          // (nextMonthCloseBuyPlanTag==false || currentRole.includes('Admin') || nextMonthBetweenDays==true) && (currentRole === 'BuyPlan Approver' || isHideCloseButton || selectedRow.status === 'CLOSED' ? '' :
          // (selectedRow.atlasFxEnabled == true) && nextMonthBetweenDays==false ? <Box sx={{mb: '2rem'}} >
          //   <Button sx={StyledCoverageSummBottomButton} style={{cursor: 'not-allowed', background: '#f2f2f2'}}
          //   >Close Buy Plan</Button>
          // </Box> : <Box sx={{mb: '2rem'}} >
          //   <Button sx={StyledCoverageSummBottomButton} onClick={() => {
          //     openConfirmBox();
          //   }}>Close Buy Plan</Button>
          // </Box>)
        }
        {currentRole !== 'BuyPlan Approver' && selectedRow.status == 'CLOSED' && accessControlReview=='Edit' && <Button sx={StyledCoverageSummBottomButton} onClick={() => {
          setshowApproveConfirmDialog(true);
        }}>Re-open Buyplan</Button>}
        <AppConfirmDialog open={showApproveConfirmDialog} handleClose={()=>{
          setshowApproveConfirmDialog(false);
        }} text={'Do you want to re-open the plan?'} secondLineText={''} setYes={approvalCall} ifYesFirst ={false}/>
        <AppConfirmDialog open={isClosePopupVisible} handleClose={() => setIsClosePopupVisible(false)}
          text={totalVariance > 0 ? 'There are variances in coverage data. Do you confirm it?' : 'Are you sure you want to close the buy plan?'}
          setYes={() => {
            setIsClosePopupVisible(false);
            closeBuyPlan(selectedRow.workFlowId);
          }} ifYesFirst={false} />
        <AppDialog open={isSuccessPopupVisible} handleClose={() => {
          setIsSuccessPopupVisible(false);
          setIsPopupVisible(false);
        }}
        text='Buy Plan Closed Successfully' />
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrmsg('');
        }} text={errmsg} />
      </>
  );
}
