export const CopyHoverStyles = {
  'padding': '5px',
  'position': 'absolute',
  'whiteSpace': 'nowrap',
  'wordWrap': 'no-wrap',
  'boxShadow': '1px 1px 20px #aaa',
  'borderRadius': '5px',
  'backgroundColor': '#fff',

  'transformStyle': 'preserve-3d',
  'zIndex': '2000',
  'fontSize': '0.7em',
  '&:after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '10px',
    height: '10px',
    transformOrigin: '50% 50%',
    transform: 'rotate(45deg) translateX(-50%)',
    backgroundColor: '#fff',
    left: '73%',
    top: '-1px',
    zIndex: '2000',
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '10px',
    height: '10px',
    transformOrigin: '50% 50%',
    transform: 'rotate(45deg) translateX(-50%) translateZ(-1px)',
    backgroundColor: '#fff',
    left: '73%',
    top: '0px',
    zIndex: '-1',
    boxShadow: '-1px -1px 20px #aaa',
  },
  '&__dang': {
    color: 'black',
    boxShadow: '1px 1px 20px rgba(black, 0.3)',
  },
};
