/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {generateMonthDropDownValues} from '../../../services/utils';
import {setGuidancePriceFilter} from '../../../redux/features/configurations';
import customealltheme from '../../../theme';

const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
};

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            'marginInlineEnd': '1rem',
            'width': '5px',
          },
          '*::-webkit-scrollbar-track': {
            'marginInlineEnd': '1rem',
            'background': '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            'background': '#a6a7a8',
            'borderRadius': '2px',
            'marginInlineEnd': '1rem',

          },
          '.MuiMenu-paper': {
            'maxHeight': '150px',
            'overflowY': 'auto',
            'width': '100px',
            'whiteSpace': 'nowrap',
            'textOverflow': 'ellipsis',
          },
        },
      },
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

let planMonths;

function GuidanceList({row, setFiltersResetClicked, setSubmitClicked, Currency, ActiveSectors, ActiveCommodities, dispatch, setSortType, playbookTypes}) {
  const selectedDropDownData = useSelector((state)=>state?.api?.selectedDropDownData);

  const [valueCategory, setValueCategory] = React.useState([]);
  const [valueCommodity, setValueCommodity] = React.useState([]);
  const [MastervalueSector, setMasterValueSector] = React.useState([]);
  const [valueSector, setValueSector] = React.useState([]);
  const [valueType, setValueType] = React.useState([]);
  const [selectedCommodities, setSelectedCommodities] = React.useState([]);
  const [selectedValueSectorList, setselectedValueSectorList] = React.useState([]);
  const [isSectorSelected, setIsSectorSelected] = React.useState();
  const [isTypeSelected, setIsTypeSelected] = React.useState();
  const [IsMasterSectorSelected, setIsMasterSectorSelected] = React.useState();
  const [isCategorySelected, setIsCategorySelected] = React.useState();
  const [isCommoditySelected, setIsCommodiitySelected] = React.useState();
  const [planMonthDropdown, setPlanMonthDropdown] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [currencyDesArray, setcurrencyDesArray] = useState([]);

  const typeDropdownValue = [playbookTypes.NON_PLAYBOOK, playbookTypes.PLAYBOOK];

  const handleChangeLocalUnit = (event) => {
    const {
      target: {value},
    } = event;

    if (value[value.length - 1] === 'currencyDes_all') {
      const str = ['currencyDes_all'];
      Currency.map((r) => (
        str.push(r?.currency)
      )),

      setcurrencyDesArray(str);
    } else if (currencyDesArray.includes('currencyDes_all') && !value.includes('currencyDes_all')) {
      setcurrencyDesArray([]);
    } else {
      if (currencyDesArray.includes('currencyDes_all')) setcurrencyDesArray([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('currencyDes_all')) tepVal.splice(tepVal.indexOf('currencyDes_all'), 1);
      setcurrencyDesArray(tepVal);
    }
  };

  const handleSubmit =()=> {
    dispatch(
        setGuidancePriceFilter({
          sector: valueSector,
          commodity: valueCommodity,
          currency: currencyDesArray,
          selectedMonth: selectedMonth,
          valueType,
        }),
    );
    setSubmitClicked((preValue) => !preValue);
  };

  const handleChangeCommodity = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'comm_all') {
      const str = ['comm_all'];
      if (!isCategorySelected) ActiveCommodities.map((ele) => ele?.commodities.map((row)=> str.push(row?.name)));
      else selectedCommodities.map((row) => str.push(row?.name));
      setValueCommodity(str);
    } else if (valueCommodity.includes('comm_all') && !value.includes('comm_all')) {
      setValueCommodity([]);
    } else {
      if (valueCommodity.includes('comm_all')) setValueCommodity([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('comm_all')) tepVal.splice(tepVal.indexOf('comm_all'), 1);
      setValueCommodity(tepVal);
    }
    setIsCommodiitySelected(true);
  };


  const handleMonthChange = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'all_month') {
      const str = ['all_month'];
      planMonthDropdown?.map((r) => (
        str.push(r?.priceMonthYear)
      )),

      setSelectedMonth(str);
    } else if (selectedMonth.includes('all_month') && !value.includes('all_month')) {
      setSelectedMonth([]);
    } else {
      if (selectedMonth.includes('all_month')) setSelectedMonth([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('all_month')) tepVal.splice(tepVal.indexOf('all_month'), 1);
      setSelectedMonth(tepVal);
    }
  };


  const handleChangeSector = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'sector_all') {
      const str = ['sector_all'];
      ActiveSectors.map((obj)=>
        obj?.sectors?.map((row) => (
          str.push(row?.name)
        )),
      ),
      setValueSector(str);
    } else if (valueSector.includes('sector_all') && !value.includes('sector_all')) {
      setValueSector([]);
    } else {
      if (valueSector.includes('sector_all')) setValueSector([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('sector_all')) tepVal.splice(tepVal.indexOf('sector_all'), 1);
      setValueSector(tepVal);
    }
    setIsSectorSelected(true);
  };

  const handleChangeType = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'type_all') {
      const str = ['type_all'];
      typeDropdownValue.map((obj) =>str.push(obj)), setValueType(str);
    } else if (valueType.includes('type_all') && !value.includes('type_all')) {
      setValueType([]);
    } else {
      if (valueType.includes('type_all')) setValueType([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('type_all')) tepVal.splice(tepVal.indexOf('type_all'), 1);
      setValueType(tepVal);
    }
    setIsTypeSelected(true);
  };

  useEffect(() => {
    setPlanMonthDropdown(_.orderBy(
        _.uniqBy(row, 'priceMonthYear') || [],
        [(item) => new Date('01 ' + item.priceMonthYear)],
        ['desc'],
    ));
  }, [row]);
  const tempCommodities = [];
  useEffect(()=>{
    if (valueCategory.length === 0) {
      setIsCategorySelected(false);
    } else {
      (ActiveCommodities.length !== 0) &&
                    ActiveCommodities?.map((category)=>
                      valueCategory.map((ele)=> {
                        if (category.name === ele) {
                          category.commodities.map((commodity)=>tempCommodities.push(commodity?.name));
                        }
                      } ),
                    );
    }
    setSelectedCommodities([...tempCommodities]);

    if (valueCommodity.length === 0) {
      setIsCommodiitySelected(false);
    }

    if (valueSector.length === 0) {
      setIsSectorSelected(false);
    }
    if (valueType.length === 0) {
      setIsTypeSelected(false);
    }


    const tempSectors = [];
    if (MastervalueSector.length === 0) {
      setIsMasterSectorSelected(false);
    } else {
      (ActiveSectors.length !== 0) &&
                    ActiveSectors?.map((row)=>

                      MastervalueSector.map((sector)=>
                        row?.sectors.map((id)=>{
                          if (row.name === sector) {
                            if (!tempSectors.includes(id)) {
                              tempSectors.push(id);
                            };
                          }
                        })),

                    );
    }
    setselectedValueSectorList([...tempSectors]);
  }, [valueCategory, valueCommodity, valueSector, isSectorSelected, isTypeSelected, isCategorySelected, isCommoditySelected]);

  if (!planMonths) {
    planMonths = generateMonthDropDownValues();
  }
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: '100%', padding: '18px'}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
          }}
          style={customealltheme.palette.filterheading}
        >
          Guidance Price
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'flex-start', paddingTop: '35px', flexWrap: 'wrap'}}>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
              Type
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={{
                    '.MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueType}
                  onChange={handleChangeType}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={(selected, displayEmpty) => (displayEmpty = valueType.length ? selected.join(', ') : 'Select All')}
                  className="newclass"
                  style={{
                    '& .MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem sx={{padding: '3px 12px'}} key="type_all" value="type_all" className="this-is-new-class">
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueType?.indexOf('type_all') > -1} />
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass" />
                  </MenuItem>
                  {typeDropdownValue.length !== 0 &&
                    typeDropdownValue
                        ?.sort((a, b) => a.localeCompare(b))
                        ?.map((row, index) => (
                          <MenuItem
                            sx={{
                              padding: '3px 12px',
                            }}
                            key={index}
                            value={row}
                            className="this-is-new-class"
                          >
                            <Checkbox
                              sx={{
                                '.MuiSvgIcon-root': {
                                  fontSize: '14px',
                                },
                              }}
                              checked={valueType?.indexOf(row) > -1}
                            />
                            <ListItemText
                              sx={{
                                '.MuiTypography-root': {
                                  fontSize: '14px',
                                },
                              }}
                              primary={row}
                              className="myClass"
                            />
                          </MenuItem>
                        ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
              Sector
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={{
                    '.MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueSector}
                  onChange={handleChangeSector}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={(selected, displayEmpty) => (displayEmpty = valueSector.length ? selected.join(', ') : 'Select All')}
                  className="newclass"
                  style={{
                    '& .MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem sx={{padding: '3px 12px'}} key="sector_all" value="sector_all" className="this-is-new-class">
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueSector?.indexOf('sector_all') > -1} />
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass" />
                  </MenuItem>
                  {IsMasterSectorSelected ?
                    selectedValueSectorList.length !== 0 &&
                      selectedValueSectorList
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((row, ind) => (
                            <MenuItem
                              sx={{
                                padding: '3px 12px',
                              }}
                              key={row?.id}
                              value={row?.name}
                              className="this-is-new-class"
                            >
                              <Checkbox
                                sx={{
                                  '.MuiSvgIcon-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                checked={valueSector?.indexOf(row?.name) > -1}
                              />
                              <ListItemText
                                sx={{
                                  '.MuiTypography-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                primary={row?.name}
                                className="myClass"
                              />
                            </MenuItem>
                          )) :
                    ActiveSectors.length !== 0 &&
                      ActiveSectors.flatMap((obj) => obj.sectors)
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((row) => (
                            <MenuItem
                              sx={{
                                padding: '3px 12px',
                              }}
                              key={row.id}
                              value={row.name}
                              className="this-is-new-class"
                            >
                              <Checkbox
                                sx={{
                                  '.MuiSvgIcon-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                checked={valueSector?.indexOf(row.name) > -1}
                              />
                              <ListItemText
                                sx={{
                                  '.MuiTypography-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                primary={row.name}
                                className="myClass"
                              />
                            </MenuItem>
                          ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
              Commodity
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueCommodity}
                  label="value"
                  onChange={handleChangeCommodity}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={(selected, displayEmpty) => (displayEmpty = valueCommodity.length ? selected.join(', ') : 'Select All')}
                >
                  <MenuItem sx={{padding: '3px 12px'}} key="comm_all" value="comm_all" className="this-is-new-class">
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueCommodity?.includes('comm_all')} />
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass" />
                  </MenuItem>
                  {!isCategorySelected ?
                    ActiveCommodities &&
                      ActiveCommodities.flatMap((ele) => ele.commodities)
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((row) => (
                            <MenuItem
                              sx={{
                                padding: '3px 12px',
                              }}
                              key={row.id}
                              value={row.name}
                            >
                              <Checkbox
                                sx={{
                                  '.MuiSvgIcon-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                checked={valueCommodity.indexOf(row.name) > -1}
                              />
                              <ListItemText
                                sx={{
                                  '.MuiTypography-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                primary={row.name}
                              />
                            </MenuItem>
                          )) :
                    selectedCommodities &&
                      selectedCommodities
                          .sort((a, b) => a.localeCompare(b))
                          .map((commodity, idx) => (
                            <MenuItem
                              sx={{
                                padding: '3px 12px',
                              }}
                              key={idx}
                              value={commodity}
                            >
                              <Checkbox
                                sx={{
                                  '.MuiSvgIcon-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                checked={valueCommodity.indexOf(commodity) > -1}
                              />
                              <ListItemText
                                sx={{
                                  '.MuiTypography-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                primary={commodity}
                              />
                            </MenuItem>
                          ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
              Currency
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={currencyDesArray}
                  label="value"
                  onChange={handleChangeLocalUnit}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={(selected, displayEmpty) => (displayEmpty = currencyDesArray.length ? selected.join(', ') : 'Select All')}
                >
                  <MenuItem sx={{padding: '3px 12px'}} key="currencyDes_all" value="currencyDes_all" className="this-is-new-class">
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={currencyDesArray?.includes('currencyDes_all')} />
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass" />
                  </MenuItem>
                  {Currency?.filter((Curr) => Curr.currency.trim() !== '')
                      .sort((a, b) => a.currency.localeCompare(b.currency))
                      .map((Curr, idx) => (
                        <MenuItem
                          sx={{
                            padding: '3px 12px',
                          }}
                          key={idx}
                          value={Curr.currency}
                        >
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={currencyDesArray.indexOf(Curr.currency) > -1}
                          />
                          <ListItemText
                            sx={{
                              '.MuiTypography-root': {
                                fontSize: '14px',
                              },
                            }}
                            primary={Curr.currency.toUpperCase()}
                          />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Plan Month
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    sx={{
                      '.MuiOutlinedInput-input': {
                        padding: '11.5px 14px',
                      },
                      '.MuiSelect-select': {
                        font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                        color: 'rgba(85, 87, 89, 1)',
                      },
                    }}
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    input={<CustomInput label="Tag" />}
                    displayEmpty
                    renderValue={(selected, displayEmpty) => (displayEmpty = selectedMonth.length ? selected.join(', ') : 'Select All')}
                    className="newclass"
                  >
                    <MenuItem sx={{padding: '3px 12px'}} key="all_month" value="all_month" className="this-is-new-class">
                      <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={selectedMonth?.indexOf('all_month') > -1} />
                      <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass" />
                    </MenuItem>
                    {planMonthDropdown?.length !== 0 &&
                      planMonthDropdown?.map((r) => (
                        <MenuItem
                          sx={{
                            padding: '3px 12px',
                          }}
                          key={r?.id}
                          value={r?.priceMonthYear}
                          className="this-is-new-class"
                        >
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={selectedMonth?.indexOf(r?.priceMonthYear) > -1}
                          />

                          <ListItemText
                            sx={{
                              '.MuiTypography-root': {
                                fontSize: '14px',
                              },
                            }}
                            primary={r?.priceMonthYear ? r.priceMonthYear.replace('-', ' ') : ''} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} component="span"></Box>
            <Box style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
              <Box sx={{minWidth: 130, marginTop: '28px'}}>
                <Button
                  onClick={handleSubmit}
                  sx={{
                    'backgroundColor': '#4297d3',
                    'color': '#ffffff',
                    'textTransform': 'none',
                    'minWidth': '0',
                    'padding': '8px 35px',
                    '&:hover': {
                      backgroundColor: '#4297d3',
                    },
                  }}
                  style={customealltheme.palette.filterSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: {
              display: 'inline-block',
              marginRight: '2px',
              font: 'normal normal 600 13px/15px \'Segoe UI\'',
              color: '#3174b6',
              cursor: 'pointer',
            },
            marginTop: '30px',
          }}
          style={customealltheme.palette.resetbutton}
        >
          <p onClick={()=>{
            const tempObj = {...selectedDropDownData};
            Object.keys(tempObj).map((el)=>tempObj[el]=[]);
            dispatch(setGuidancePriceFilter([]));
            setValueCategory([]);
            setValueCommodity([]);
            setSelectedMonth([]);
            setcurrencyDesArray([]);
            setValueSector([]);
            setValueType([]);
            setMasterValueSector([]);
            dispatch(setSortType(''));
            setFiltersResetClicked((preValue)=> !preValue);
          }}>Reset Filters</p>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default GuidanceList;
