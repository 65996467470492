import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PlaybookCompMapSingleRow from './PlaybookCompMapSingleRow';
import {Box, TablePagination} from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {useDispatch, useSelector} from 'react-redux';
import config from '../../../Utils/Config';
import customealltheme from '../../../theme';
import {setSortFilter, setSortType} from '../../../redux/features/configurations';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const PlaybookCompMapTable = ({
  data,
  setAddingTrigger,
  heads,
  sortValues,
  accessControl,
  values,
  addingTrigger,
  updateRow,
  setIsErrorPopupVisible,
  setErrMsg,
  pagination,
  setPagination,
  ActiveMasterSectors,
  ActiveSectors,
  ActiveCategories,
  ActiveCommodities,
  ActiveSubCommodities,
  ActiveCountry,
  setPage,
  page,
})=>{
  const Edit = config.AccessLevel.Edit;
  const dispatch = useDispatch();
  const [editModeDisableTrigger, setEditModeDisableTrigger]=useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setEditModeDisableTrigger((editModeDisableTriggerValue)=>!editModeDisableTriggerValue);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(+event.target.value);
    setPage(0);
  };

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  return (
    <>
      <>
        <Box sx={{width: '100%', padding: '0px 18px'}}>
          <Box sx={{marginTop: '20px'}}>
            <TableContainer component={Paper} id='playbookcompmaptable' style={{maxWidth: '100%', overflowX: 'auto'}}>
              <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead id='playbookcompmaphead'>
                  <TableRow>
                    {heads.map((head, index) => (
                      <React.Fragment key={index}>
                        {head.subcol ? (
                          <StyledTableCell align="center" colSpan={head.subcol.length} style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px', borderLeft: '1px solid #E0E0E0'}}>
                            {head.col}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align="left" style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px', borderLeft: head.col=='Last Edited By' && '1px solid #E0E0E0'}}>
                            <Box style={customealltheme.palette.TableHead}>
                              <Box>{head.col}</Box>
                              {sortValues.includes(head.col) &&
                                <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0.5'}}
                                  onClick={()=>{
                                    dispatch(setSortFilter(values[head.col]));
                                    if (sorttype === 'asc' ) {
                                      dispatch(setSortType('desc'));
                                    } if (sorttype === 'desc' || sorttype === '') {
                                      dispatch(setSortType('asc'));
                                    }
                                  }}
                                  onMouseOver={(e)=>{
                                    e.target.style.opacity = '1';
                                  }}
                                  onMouseLeave={(e)=>{
                                    e.target.style.opacity = '0.5';
                                  }}
                                > <SwapVertIcon />{sorttype}
                                </Box>}
                            </Box>

                          </StyledTableCell>
                        )}
                      </React.Fragment>
                    ))}
                    {accessControl===Edit &&<StyledTableCell align="left" style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px'}}>Action</StyledTableCell>}
                  </TableRow>
                  {heads.some((head) => head.subcol) && (
                    <TableRow >
                      {heads.map((head, index) => (
                        <React.Fragment key={index}>
                          {head.subcol ? (
                            head.subcol.map((subcol, subIndex) => (
                              <StyledTableCell align="left" key={`${index}-${subIndex}`} style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px', borderLeft: subIndex==0 && '1px solid #E0E0E0'}}>
                                <Box style={customealltheme.palette.TableHead}>
                                  <Box> {subcol}</Box>
                                </Box>
                              </StyledTableCell>
                            ))
                          ) : (
                            <StyledTableCell align="left" style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px', borderLeft: head.col=='Last Edited By' && '1px solid #E0E0E0'}}></StyledTableCell>
                          )}
                        </React.Fragment>
                      ))}
                      <StyledTableCell align="left" style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px'}}></StyledTableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {data?.slice(page * pagination, page * pagination + pagination)
                      ?.map((row, key) => (
                        <PlaybookCompMapSingleRow
                          row={row}
                          key={key}
                          setAddingTrigger={setAddingTrigger}
                          addingTrigger={addingTrigger}
                          updateRow={updateRow}
                          setErrMsg={setErrMsg}
                          accessControl={accessControl}
                          setIsErrorPopupVisible={setIsErrorPopupVisible}
                          editModeDisableTrigger={editModeDisableTrigger}
                          page = {page}
                          ActiveMasterSectors={ActiveMasterSectors}
                          ActiveSectors = {ActiveSectors}
                          ActiveCategories = {ActiveCategories}
                          ActiveCommodities = {ActiveCommodities}
                          ActiveSubCommodities ={ActiveSubCommodities}
                          ActiveCountry={ActiveCountry}
                        />
                      ))}

                </TableBody>
              </Table>
            </TableContainer>

            {data.length>0 && <TablePagination
              rowsPerPageOptions={[2, 5, 10, 15]}
              component="div"
              count={data?.length || 0}
              rowsPerPage={pagination}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
          </Box>
        </Box>
      </>

    </>
  );
};

export default PlaybookCompMapTable;
