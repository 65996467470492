import React from 'react';
import './DropdownSelect.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from 'react';

const DropdownSelect = ({selectedRow, rowData, indexOfYear, apiData, i}) => {
  const [sourceTypeselected, setSourceTypeSelected] = React.useState('');
  const [dropDownRequired, setDropDownRequired] = React.useState(false);
  const [dropDownValues, setDropDownValues] = React.useState([]);

  const handleChange = (event) => {
    setSourceTypeSelected(event.target.value);
    apiData[indexOfYear].map((element, index)=>{
      if (index==i) {
        element.sourceType = event.target.value;
        rowData.sourceType = event.target.value;
      }
    });
  };

  useEffect(()=>{
    const hedgingTypes = [];
    if ((selectedRow.coverageMode=='SUPPLIER'||selectedRow.coverageMode=='BOTH')) {
      hedgingTypes.push('SUPPLIER');
    }
    if ((selectedRow.coverageMode=='TREASURY'||selectedRow.coverageMode=='BOTH')) {
      hedgingTypes.push('TREASURY');
    }
    if (selectedRow.others== true || selectedRow.coverageMode=='NONE') {
      hedgingTypes.push('OTHER');
    }
    setDropDownRequired(hedgingTypes.length>1);
    setDropDownValues(hedgingTypes);
  }, [selectedRow]);

  useEffect(()=>{
    setSourceTypeSelected((rowData?.sourceType?.charAt(0).toUpperCase() + rowData?.sourceType?.slice(1).toUpperCase()) || '');
  }, [rowData]);

  return (
    dropDownRequired ?
      <FormControl variant='standard' sx={{width: '95px', fontSize: '12px'}}>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          defaultValue={'Select'}
          value={sourceTypeselected || rowData?.sourceType?.charAt(0).toUpperCase() + rowData?.sourceType?.slice(1).toUpperCase()}
          placeholder={'Select'}
          onChange={handleChange}
          disabled={(rowData?.disableCell)}
          sx={{width: '95px', fontSize: '12px'}}
        >
          {
            dropDownValues.map((name, id) => {
              return <MenuItem sx={{fontSize: '13px'}} key={id} value={name}>{name}</MenuItem>;
            })
          }
        </Select>
      </FormControl> :
    <div className='saveCoverageInput'>
      {dropDownValues[0]}
    </div>
  );
};

export default DropdownSelect;
