import React from 'react';
import Styles from '../../../styles/target';
import {utils, writeFileXLSX} from 'xlsx';
import {hedgingDecider} from '../../../services/utils';

const DownloadBuyplanList = ({data}) => {
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const formatStatus = (selectedValue) => {
    switch (selectedValue) {
      case 'Active':
        return 'ACTIVE';
      case 'Inactive':
        return 'INACTIVE';
      case 'Coverage Suspension':
        return 'COV_SUSPENDED';
      case 'Coverage Review Suspension':
        return 'REVIEW_SUSPENDED';
      case 'ACTIVE':
        return 'Active';
      case 'INACTIVE':
        return 'Inactive';
      case 'COV_SUSPENDED':
        return 'Coverage Suspension';
      case 'REVIEW_SUSPENDED':
        return 'Coverage Review Suspension';
      default:
        return '';
    }
  };

  function formatLastEdited(formatDate) {
    const formattedDate = `${new Date(formatDate).getDate() < 10 ? '0' +
                new Date(formatDate).getDate() :
                new Date(formatDate).getDate()}-${new Date(formatDate).getMonth() < 10 ?
            monthArr[new Date(formatDate).getMonth()] :
            monthArr[new Date(formatDate).getMonth()] }-${new Date(formatDate).getFullYear()}`;
    return formattedDate;
  }

  const onDownload = () => {
    const sheetData = {};
    const excelFormattedData = data.map((item) => {
      const excelCurrentIterationData = {};
      excelCurrentIterationData['PlayBook Name']= item?.showValue ? (item?.play_book_name || '') : '';
      excelCurrentIterationData['Buy Plan Name']= item?.showValue ? (item?.buy_plan_name || '') : '';
      excelCurrentIterationData['Master Sector']= item?.showValue ? (item?.master_sector || '') : '';
      excelCurrentIterationData['Sector']= item?.sector || '';
      excelCurrentIterationData['Country']= item?.country || '';
      excelCurrentIterationData['Category']= item?.showValue ? (item?.category || '') : '';
      excelCurrentIterationData['Commodity']= item?.showValue ? (item?.commodity || '') : '';
      excelCurrentIterationData['Sub Commodity']= item?.showValue ? (item?.sub_commodity || '') : '';
      excelCurrentIterationData['Rounding Number']= item?.showValue ? (item?.decimal_scale===0 ? 0 : (item?.decimal_scale || '') ) : '';
      excelCurrentIterationData['Buy Plan Owner']= item?.showValue ? (item?.buyplan_owner || '') : '';
      excelCurrentIterationData['Buy Plan Creator']= item?.showValue ? (item?.buyplan_creator || '') : '';
      excelCurrentIterationData['Buy Plan Approver']= item?.showValue ? (item?.buyplan_approver || '') : '';
      excelCurrentIterationData['Buy Plan Admin']= item?.showValue ? (item?.buyplan_admin || '') : '';
      excelCurrentIterationData['Forecast Creator']= item?.showValue ? (item?.forecast_creator || '') : '';
      excelCurrentIterationData['Hedging']= item?.showValue ? hedgingDecider(item?.coverageMode, item?.others) : '';
      excelCurrentIterationData['Send Forecast to Atlas Fx']=item?.showValue ? ((item?.is_atlasfx?'Y':'N') || ''): '';
      excelCurrentIterationData['Carry Volume']=item?.showValue ? ((item?.is_carry_vol_enabled?'Y':'N') || ''): '';
      excelCurrentIterationData['Current Status']= item?.showValue ? formatStatus(item['current_status']) : '';
      excelCurrentIterationData['Start Month']= item?.showValue ? (item?.active_start_date || '') : '';
      excelCurrentIterationData['End Month']= item?.showValue ? (item?.active_end_date || '') : '';
      excelCurrentIterationData['Last Edited By']= item?.showValue ? (item?.last_edited_by || '') : '';
      excelCurrentIterationData['Last Edited On']= item?.showValue ? (formatLastEdited(item?.last_edited) || '') : '';
      return excelCurrentIterationData;
    });
    sheetData['BUYPLAN_LIST'] = [...excelFormattedData];
    const wb = utils.book_new();
    const colWidths = [{wch: 150}];
    wb['!cols'] = colWidths;
    Object.keys(sheetData)?.forEach((data) => {
      const ws = utils.json_to_sheet(sheetData[data]);
      utils.book_append_sheet(wb, ws, data);
    });
    writeFileXLSX(wb, `BUYPLAN_LIST.xlsx`);
  };

  if (!(data?.length > 0)) {
    return <></>;
  }
  return (
    <Styles.StyledDownloadButton onClick={onDownload}>
        Download
    </Styles.StyledDownloadButton>
  );
};

export default DownloadBuyplanList;
