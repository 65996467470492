import * as React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import {useState} from 'react'; // useEffect
import {AppButton, AppNotSelectedButton} from '../common/AppButton/AppButton';
import Styles from '../../styles/Forecast';

export default function PlayBookUpdateReasonModal({isOpen, handleClose, handleSavePlayBookTarget}) {
  const [reason, setReason] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const reasons=useSelector((state)=>state?.commonState?.playBookReasons);
  const handleReason = (Reason) =>{
    if (Reason) {
      setReason(Reason);
    }
  };

  const handleSaveTarget = ()=>{
    let reasonLabel = null;
    reasons.forEach((thisReason) => {
      if (thisReason.update_type_id === reason) {
        reasonLabel=thisReason.update_type;
      }
    });
    if (reason && reasonLabel) {
      handleSavePlayBookTarget(reason, reasonLabel);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={isOpen}
      >
        <DialogContent>
          <DialogContentText sx={{'font-family': 'Segoe UI', 'font-size': '13px', 'color': '#3B4655', 'fontWeight': 600}}>
          Please select a reason for the target update
          </DialogContentText>

          <Box sx={{mt: 2}}>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <FormControl sx={{mt: 2, minWidth: 200, flexDirection: 'row', p: 0, flexShrink: 0, textAlign: 'center'}}>
                  <Styles.StyledVarianceSelect
                    sx={{minWidth: 200}}
                    autoFocus
                    value={reason}
                    onChange={(e) => handleReason(e.target.value)}
                    label="Select Reason"
                    inputProps={{
                      name: 'max-width',
                      id: 'max-width',
                    }}
                    displayEmpty
                    renderValue={(selected, displayEmpty) => {
                      let Return ='Select Reason';
                      for (let i=0; i<reasons.length; i++) {
                        if (reasons[i].update_type_id===selected) {
                          Return =reasons[i].update_type;
                          break;
                        }
                      }
                      return Return;
                    }}
                  >
                    {reasons.map((thisReason)=>{
                      return (
                        <Styles.StyledVarianceMenuItem key={thisReason.update_type_id} value={thisReason.update_type_id}>{thisReason.update_type}</Styles.StyledVarianceMenuItem>
                      );
                    })}
                  </Styles.StyledVarianceSelect>
                </FormControl>
              </Box>
            </Box>
          </Box>


          <DialogActions sx={{mb: 2, mt: 2, justifyContent: 'space-between'}}>
            <AppNotSelectedButton
              onClick={async () => {
                setShowLoader(false);
                handleClose();
              }}
              label="Close"
            />
            <AppButton
              disabled ={!reason}
              onClick={async () => {
                setShowLoader(true);
                handleSaveTarget();
              }}
              label="Save"
            />

          </DialogActions>
          {showLoader &&
          <Box sx={{'display': 'flex', 'justify-content': 'center', 'm': 2, 'mt': 4}}>
            <CircularProgress color="primary"/>
          </Box>
          }
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}
