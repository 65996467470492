/* eslint-disable camelcase */
import axiosInstance from '../../../apis/axiosInstance';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ErrorIcon from '@mui/icons-material/Error';
import React, {useState, useRef, useEffect} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {createTheme, styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import {Box, Button, FormControl, InputBase, MenuItem, Select, TablePagination, TextareaAutosize} from '@mui/material';
import CommonConfSingleRow from './GuidanceSingleRow';
import theme from '../../../theme';
import {useSelector} from 'react-redux';
import config from '../../../Utils/Config';
import Papa from 'papaparse';
import axios from 'axios';
import {read, utils, writeFile} from 'xlsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import customealltheme from '../../../../src/theme/index';
import {setSortFilter} from '../../../../src/redux/features/configurations';
import {ErrorMessageList, DownloadInstructions, errorInstructions, instructions, SuccessMessage} from './GuidanceBulkInstructions';


const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const Popup = ({open, onClose}) => {
  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [open, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{color: 'green', margin: 'auto'}}>Success!</DialogTitle>
      <DialogContent>
        <SuccessMessage/>
      </DialogContent>
    </Dialog>
  );
};


function CommonConfTable({idKey, submitClicked, filterResetClicked, setPage, Units, Volumes, Currency, ActiveSectorsList, page, accessControl, data, setErrMsg, sortValues, setIsErrorPopupVisible, setAddingTrigger, addingTrigger, heads, values, singleUpdate, newRowUpdate, pagination, setPagination, dispatch, setSortType, playbookTypes}) {
  const [AddNewRow, setNewRow] = useState(false);
  const [selectedSectorId, setSelectedSectorId] = useState('');
  const handleAddRow = () =>{
    clearNewRow();
    setNewRow(true);
  };

  const [commodityId, setcommodityId] = useState('');
  const [Volumeunit, setVolumeunit] = useState('');
  const [playBookType, setPlayBookType] = useState('');
  const [sectorId, setsectorId] = useState('');
  const [priceMonthYear, setPriceMonthYear] = useState('');
  const currentDate = new Date().toISOString().split('T')[0];
  const [currencyId, setCurrencyId] = useState('');
  const [guidancePrice, setGuidancePrice] = useState('');
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(+event.target.value);
    setPage(0);
  };

  const handleCommodityId = (e) => {
    setcommodityId(e.target.value);
  };

  const handlecurrencyId = (e) => {
    setCurrencyId(e.target.value);
  };

  const saveTheRow = () =>{
    let yearMonth=new Date().getFullYear()+'-'+new Date().getMonth();
    if (priceMonthYear) {
      const pMY = priceMonthYear.split('-');
      if (pMY.length>2) {
        yearMonth=pMY[0]+'-'+pMY[1];
      }
    }

    newRowUpdate({
      user_id: 0,
      id: 0,
      sectorId: sectorId,
      commodityId: commodityId,
      priceMonthYear: yearMonth,
      currencyId: currencyId,
      guidancePrice: guidancePrice,
      measurementUnitId: Volumeunit,
      isNonPlaybook: playBookType === playbookTypes.NON_PLAYBOOK ? true: playBookType === playbookTypes.PLAYBOOK ? false : null,
    })
        .then((res) => {
          if (res && res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
            setAddingTrigger(!addingTrigger);
          } else if (res) {
          // success
            setNewRow(false);
            setAddingTrigger(!addingTrigger);
            clearNewRow();
          }
        })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured');
          setAddingTrigger(!addingTrigger);
        });
  };

  const clearNewRow = () => {
    setNewRow(false);
    setVolumeunit('');
    setPlayBookType('');
    setcommodityId('');
    setsectorId('');
    setCurrencyId('');
    setPriceMonthYear('');
    setGuidancePrice('');
  };

  const isValidFloatGreaterThanZero = (value) => {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue > 0;
  };

  const enableSaveButton = sectorId!='' && commodityId!='' && currencyId!='' && Volumeunit!='' && isValidFloatGreaterThanZero(guidancePrice) && priceMonthYear!='' && playBookType != '';

  const saveButtonStyles = {
    border: '1px solid gray',
    display: 'flex',
    border: '0.5px solid #CECECE',
    borderRadius: '4px',
    width: '58px',
    alignItems: 'center',
    gap: '5px',
    padding: '2px 6px',
    background: '#4297d3',
    color: 'white',
    justifyContent: 'center',
    cursor: enableSaveButton ? 'pointer' : 'not-allowed',
    opacity: enableSaveButton ? 1:0.5,
  };

  const apiRespDropdownData = useSelector((state)=>state?.api?.apiValueDropdownData);
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const Edit = config.AccessLevel.Edit;

  useEffect(()=> {
    setNewRow(false);
  }, [sorttype]);


  //  Csv upload and download
  const [uploadMessage, setUploadMessage] = useState('');
  const [UploadStatus, setUploadStatus] = useState('');
  const [csvData, setCsvData] = useState('');
  const [errorData, setErrorData] =useState('');
  const [errorMessage, setErrorMessage] =useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [currencyMap, setCurrencyMap] = useState('');
  const [sectorCommodityMap, setSectorCommodityMap] = useState('');
  const [unitMap, setUnitMap] = useState('');
  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  const formats = ['MMM-YYYY', 'MMM-YY'];

  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(()=>{
    if (currentRole?.includes('Sector')) {
      const sectors = [];
      const commodities = [];
      apiRespDropdownData['sectorGroups']?.map((data)=>{
        data['sectors']?.map((dc)=>{
          sectors.push(dc.name);
        });
      });
      apiRespDropdownData['commodityGroups']?.map((data)=>{
        data['commodities']?.map((dc)=>{
          commodities.push(dc.name);
        });
      });
    }
  }, [currentRole]);
  const headings = ['Type', 'Sector', 'Commodity', 'Currency', 'Plan Month', 'Guidance Price', 'Volume Unit'];
  const currencyMapHeadings = ['Currency', 'Currency Description'];
  const unitMapHeadings = ['Unit', 'Unit Description'];
  const sectorCommodityMapHeadings = ['Type', 'Sector', 'Commodity'];


  useEffect(()=> {
    setNewRow(false);
  }, [page, submitClicked, filterResetClicked]);

  useEffect(() => {
    setCsvData([]);
    setCurrencyMap([]);
    setSectorCommodityMap([]);
    setUnitMap([]);
    const newData = data
        .filter((obj) => obj.is_editable === 'Y')
        .map((obj) => ({
          'Type': (obj.isNonPlaybook===true?'Non Playbook' : obj.isNonPlaybook===false ? 'Playbook': '') || '',
          'Sector': obj.sector || '',
          'Commodity': obj.commodity || '',
          'Currency': obj.currency || '',
          'Plan Month': moment(obj.priceMonthYear, 'MMM-YYYY').format('MMM-YY') || '',
          'Guidance Price': obj.guidancePrice || '',
          'Volume Unit': obj.measurementUnit || '',
        }));

    setCsvData((prevCsvData) => [...prevCsvData, ...newData]);

    const currencyMapping = Currency.filter((u) => u.is_active).map((obj) => ({
      'Currency': obj.currency || '',
      'Currency Description': obj.currency_description,
    }));

    setCurrencyMap(currencyMapping);

    const unitMapping = Units.map((obj) => ({
      'Unit': obj.unit || '',
      'Unit Description': obj.unit_description,
    }));

    setUnitMap(unitMapping);

    const typeSectorCommodity=[];
    ActiveSectorsList['Playbook']?.forEach((item) => {
      item.childModelList.forEach((child) => {
        typeSectorCommodity.push({
          Type: 'Playbook',
          Sector: item.name,
          Commodity: child.name,
        });
      });
    });
    ActiveSectorsList['Non-Playbook']?.forEach((item) => {
      item.childModelList.forEach((child) => {
        typeSectorCommodity.push({
          Type: 'Non Playbook',
          Sector: item.name,
          Commodity: child.name,
        });
      });
    });

    setSectorCommodityMap(typeSectorCommodity);
  }, [data, Currency]);

  const handleFilename = (name) => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('en-US', {month: 'long'});
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const formattedDateTime = `${name}_${day}${month}${year}_${hours}H${minutes}M${seconds}S.xlsx`;
    return formattedDateTime;
  };

  const instructionsHeadings = ['Instructions'];

  const handleExport = () => {
    const name = 'Guidance_Price';
    const filename = handleFilename(name);
    const workbook = utils.book_new();
    const dataSheet = utils.json_to_sheet(csvData, {header: headings});
    const currencySheet = utils.json_to_sheet(currencyMap, {header: currencyMapHeadings});
    const unitSheet = utils.json_to_sheet(unitMap, {header: unitMapHeadings});
    const sectorSheet = utils.json_to_sheet(sectorCommodityMap, {header: sectorCommodityMapHeadings});
    const instructionsSheet = utils.json_to_sheet(instructions, {header: instructionsHeadings});
    utils.book_append_sheet(workbook, dataSheet, 'Data');
    utils.book_append_sheet(workbook, instructionsSheet, 'Instructions');
    utils.book_append_sheet(workbook, currencySheet, 'Currency Info');
    utils.book_append_sheet(workbook, unitSheet, 'Volume Unit Info');
    utils.book_append_sheet(workbook, sectorSheet, 'Sector Commodity Info');
    writeFile(workbook, filename);
    setOpen(false);
  };

  const handleErrorExport = (data) => {
    const name = 'Guidance_Price_Error_Report';
    const filename = handleFilename(name);
    const workbook = utils.book_new();
    const dataArray = [
      ['Type', 'Sector', 'Commodity', 'Currency', 'Plan Month', 'Guidance Price', 'Volume Unit', 'Error message'],
      ...data?.map((item) =>
        [
            item?.isNonPlaybook === true ? 'Non Playbook' : item?.isNonPlaybook === false ? 'Playbook' : '',
            item?.sector, item.commodity,
            item?.currency,
            moment(item.priceMonthYear, 'YYYY-MM').format('MMM-YY'),
            item?.guidancePrice,
            item?.measurementUnit,
            item?.rejectReason? item.rejectReason.join(', \n'): '',
        ]),
    ];
    const worksheet = utils.aoa_to_sheet(dataArray);
    const instructionsSheet = utils.json_to_sheet(errorInstructions, {header: instructionsHeadings});
    const currencySheet = utils.json_to_sheet(currencyMap, {header: currencyMapHeadings});
    const unitSheet = utils.json_to_sheet(unitMap, {header: unitMapHeadings});
    const sectorSheet = utils.json_to_sheet(sectorCommodityMap, {header: sectorCommodityMapHeadings});
    utils.book_append_sheet(workbook, worksheet, 'Data');
    utils.book_append_sheet(workbook, instructionsSheet, 'Instructions');
    utils.book_append_sheet(workbook, currencySheet, 'Currency Info');
    utils.book_append_sheet(workbook, unitSheet, 'Volume Unit Info');
    utils.book_append_sheet(workbook, sectorSheet, 'Sector Commodity Info');
    writeFile(workbook, filename);
    setErrorPopup(false);
  };

  const payloadKeys =
     {
       'Type': 'isNonPlaybook',
       'Sector': 'sector',
       'Plan Month': 'priceMonthYear',
       'Commodity': 'commodity',
       'Currency': 'currency',
       'Guidance Price': 'guidancePrice',
       'Volume Unit': 'measurementUnit',
     };

  const conf = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const parseDate = (v) => {
    const d = v - 1;
    const t = Math.round((d - Math.floor(d)) * 24 * 60 * 60);
    return moment(new Date(1900, 0, d, 0, 0, t)).format('YYYY-MM');
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    const csvData = [];
    if (file.size === 0) {
      setUploadMessage('File should not be empty');
    } else if (file.size < 5767168) {
      setUploadMessage('Uploading...');
      const reader = new FileReader();
      reader.onload = (event) => {
        const extension = file.name.split('.').pop();
        let rows = [];
        if (extension === 'xlsx' || extension === 'xls') {
          const wb = read(event.target.result);
          const sheets = wb.SheetNames;
          if (sheets.length) {
            rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          }

          if (rows.length > 0) {
            rows.forEach((res) => {
              const PlanMonth = typeof(res['Plan Month']) === 'number' ? parseDate(res['Plan Month']) : moment(res['Plan Month'], formats).format('YYYY-MM');
              res['Plan Month']=PlanMonth;

              res['Type'] = res['Type'] === playbookTypes.NON_PLAYBOOK? true: res['Type'] === playbookTypes.PLAYBOOK?false:null;

              const modifiedData = {};
              Object.keys(res).forEach((key) => {
                if (key !== 'Error message') {
                  const modifiedKey = payloadKeys[key] || key;
                  let value = res[key];
                  if (key === 'Type') {
                    modifiedData[modifiedKey] = value;
                  } else {
                    value = value || '';
                    modifiedData[modifiedKey] = String(value).trim();
                  }
                }
              });
              csvData.push(modifiedData);
            });

            const jsonData = JSON.stringify(csvData);
            axiosInstance.post('/guidancePrice/bulkUpload', jsonData, conf)
                .then((response) => {
                  setErrorData(response.data);
                  setAddingTrigger(!addingTrigger);
                  const successCount = csvData.length - response.data.length;
                  setErrorMessage(`${successCount} succeeded, ${response.data.length} failed out of ${csvData.length} rows`);
                  if (response.data.length === 0) {
                    setSuccessPopup(true);
                  } else {
                    setErrorPopup(true);
                  }
                })
                .catch((error) => {
                  setIsErrorPopupVisible(true);
                  setErrMsg('Error has been occured');
                });

            setTimeout(() => {
              setUploadMessage('Uploaded');
            }, 5000);
            setTimeout(() => {
              setUploadMessage('');
            }, 8000);
            setUploadStatus('success');
          } else {
            document.getElementById('fileUpload').value = '';
            setUploadMessage('Data is not there in the file.');
            setUploadStatus('failed');
          }
        }

        if (extension==='csv') {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('fileName', file.name);
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function(result) {
              if (result.data.length > 0) {
                result.data.forEach((res) => {
                  const PlanMonth = typeof(res['Plan Month']) === 'number' ? parseDate(res['Plan Month']) : moment(res['Plan Month'], formats).format('YYYY-MM');
                  res['Plan Month']=PlanMonth;
                  res['Type'] = res['Type'] === playbookTypes.NON_PLAYBOOK? true: res['Type'] === playbookTypes.PLAYBOOK?false:null;
                  const modifiedData = {};
                  Object.keys(res).forEach((key) => {
                    if (key !== 'Error message') {
                      const modifiedKey = payloadKeys[key] || key;
                      let value = res[key];
                      if (key === 'Type') {
                        modifiedData[modifiedKey] = value;
                      } else {
                        value = value || '';
                        modifiedData[modifiedKey] = String(value).trim();
                      }
                    }
                  });
                  csvData.push(modifiedData);
                });
                const jsonData = JSON.stringify(csvData);
                axios.post('https:///guidancePrice/bulkUpload', jsonData, conf)
                    .then((response) => {
                      const successCount = csvData.length - response.data.length;
                      setErrorMessage(`${successCount} succeeded, ${response.data.length} failed out of ${csvData.length} rows`);
                      setAddingTrigger(!addingTrigger);
                      if (response.data.length === 0) {
                        setSuccessPopup(true);
                      } else {
                        setErrorPopup(true);
                      }
                    })
                    .catch((error) => {
                      setIsErrorPopupVisible(true);
                      setErrMsg('Error has been occured');
                    });
                setTimeout(() => {
                  setUploadMessage('Uploaded');
                }, 5000);
                setTimeout(() => {
                  setUploadMessage('');
                }, 8000);
                setUploadStatus('success');
                setSuccessPopup(true);
              } else {
                document.getElementById('fileUpload').value = '';
                setUploadMessage('Data is not there in CSV File.');
                setUploadStatus('failed');
              }
            },
          });
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setUploadMessage('File exceeds 5.5MB');
    }
    setTimeout(() => {
      document.getElementById('fileUpload').value = '';
    }, 2000);
  };

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrorPopupClose = () => {
    setErrorPopup(false);
  };

  const handleSuccessPopupClose =() =>{
    setSuccessPopup(false);
  };


  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const descriptionElementRefError = useRef(null);
  useEffect(() => {
    if (errorPopup) {
      const {current: descriptionElement} = descriptionElementRefError;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [errorPopup]);

  const [ActiveSectorsL, setActiveSectorsL] = useState([]);

  useEffect(()=>{
    const activeSector = [];
    ActiveSectorsList?.[playbookTypes.PLAYBOOK]?.map((r)=>{
      r.active === false && !activeSector.includes(r.name) && activeSector.push({value: r.name, key: r.id, type: playbookTypes.PLAYBOOK});
    });

    ActiveSectorsList?.['Non-Playbook']?.map((r) => {
      r.active === false && !activeSector.includes(r.name) && activeSector.push({value: r.name, key: r.id, type: playbookTypes.NON_PLAYBOOK});
    });
    setActiveSectorsL([...activeSector]);
  }, [ActiveSectorsList]);
  return (
    <>
      {/* Success Popup */}
      <div>{successPopup && <Popup open={successPopup} onClose={handleSuccessPopupClose} />}</div>
      {/* Error Popup  */}
      <Dialog
        open={errorPopup}
        onClose={handleErrorPopupClose}
        scroll={scroll}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="scroll-dialog-title-error"
        aria-describedby="scroll-dialog-description-error"
      >
        <DialogTitle id="scroll-dialog-title-error" style={{color: '#F44336'}}>
          Error: {errorMessage}
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description-error"
            ref={descriptionElementRefError}
            tabIndex={-1}
            style={{maxHeight: '200px', overflowY: 'auto'}}
          >
            {errorMessage && <ErrorMessageList/>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleErrorPopupClose}
            sx={{
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '5px 10px',
              '&:hover': {
                color: '#000',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleErrorExport(errorData)}
            sx={{
              'backgroundColor': '#4297d3',
              'color': '#ffffff',
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '5px 10px',
              '&:hover': {
                backgroundColor: '#4297d3',
              },
            }}
          >
            <CloudDownloadIcon style={{margin: '0 5px'}} /> Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Instructions popup */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Instructions</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1} style={{maxHeight: '300px', overflowY: 'auto'}}>
            <DownloadInstructions/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '5px 10px',
              '&:hover': {
                color: '#000',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleExport}
            sx={{
              'backgroundColor': '#4297d3',
              'color': '#ffffff',
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '5px 10px',
              '&:hover': {
                backgroundColor: '#4297d3',
              },
            }}
          >
            <CloudDownloadIcon style={{margin: '0 5px'}} /> Download
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{width: '100%', padding: '0px 18px'}}>
        <Box sx={{marginTop: '20px'}}>
          {accessControl === Edit && (
            <div style={{display: 'flex', justifyContent: 'flex-end', gap: '3px', alignItems: 'center'}}>
              <div style={{display: 'flex', justifyContent: 'flex-end', transform: 'translateY(-52px)'}}>
                <div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                      <Button
                        size="small"
                        sx={{
                          background: 'white',
                          color: '#00B1FF',
                          font: '10px/20px \'Segoe UI\'',
                          height: '30px',
                          minWidth: '6rem',
                          marginRight: '10px',
                          fontSize: '0.8rem',
                          border: 'none',
                          cursor: 'pointer',
                          textTransform: 'capitalize',
                        }}
                      >
                        <label style={{width: '100%'}} htmlFor="fileUpload">
                          Bulk upload
                        </label>
                        <input
                          required
                          type="file"
                          id="fileUpload"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={handleImport}
                          style={{display: 'none'}}
                        />
                        <div>
                          {UploadStatus == 'success' ? (
                            <CloudDoneIcon style={{marginTop: '5px', marginLeft: '5px'}} />
                          ) : UploadStatus == 'failed' ? (
                            <ErrorIcon style={{marginTop: '5px', color: 'red', marginLeft: '5px'}} />
                          ) : (
                            <CloudUploadIcon style={{marginTop: '5px', marginLeft: '5px'}} />
                          )}
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div style={{position: 'absolute', marginLeft: '5px', marginTop: '5px'}}>
                    <span className={UploadStatus == 'failed' ? 'text-danger' : 'text-success'}>{uploadMessage}</span>
                  </div>
                </div>
                <div>
                  <Button
                    onClick={handleClickOpen('paper')}
                    sx={{
                      'backgroundColor': '#4297d3',
                      'color': '#ffffff',
                      'textTransform': 'none',
                      'padding': '5px 10px',
                      '&:hover': {
                        backgroundColor: '#4297d3',
                      },
                    }}
                  >
                    Download Bulk Upload Template
                  </Button>
                </div>
              </div>
              <Box>
                {' '}
                <Button onClick={handleAddRow} sx={theme.palette.AddNewTableButton}>
                  <AddIcon /> Add new row
                </Button>
              </Box>
            </div>
          )}
          <TableContainer component={Paper} id="guidancepricetable">
            <Table sx={{minWidth: 700}} aria-label="customized table">
              <TableHead id="guidancepricehead">
                <TableRow>
                  {heads.map((h, index) => (
                    <StyledTableCell align="left" key={index}>
                      <Box style={customealltheme.palette.TableHead}>
                        {h == 'Guidance Price/ Open Market Price' ? (
                          <abbr title="Price per local unit of measurement" style={{textDecoration: 'none', cursor: 'pointer'}}>
                            <Box style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                              {h}
                              <Box>
                                <span className="plusIcon">i</span>
                              </Box>
                            </Box>
                          </abbr>
                        ) : (
                          <Box>{h}</Box>
                        )}

                        {sortValues[index] === true && (
                          <Box
                            style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0.5'}}
                            onClick={(e) => {
                              dispatch(setSortFilter(values[index]));
                              if (sorttype === 'asc') {
                                dispatch(setSortType('desc'));
                              }
                              if (sorttype === 'desc' || sorttype === '') {
                                dispatch(setSortType('asc'));
                              }
                            }}
                            onMouseOver={(e) => {
                              e.target.style.opacity = '1';
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.opacity = '0.5';
                            }}
                          >
                            {' '}
                            <SwapVertIcon />
                            {sorttype}
                          </Box>
                        )}
                      </Box>
                    </StyledTableCell>
                  ))}
                  {accessControl === Edit && <StyledTableCell align="left">Action</StyledTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {AddNewRow === true && (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
                      <FormControl style={{width: '100px'}}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={playBookType}
                          onChange={(e) => {
                            setPlayBookType(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {Object.values(playbookTypes).map((t) => (
                            <MenuItem key={t} value={t} sx={customTheme.selectMenuBox}>
                              <span>{t === playbookTypes.NON_PLAYBOOK ? playbookTypes.NON_PLAYBOOK : t === playbookTypes.PLAYBOOK ? playbookTypes.PLAYBOOK: ''}</span>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <FormControl style={{width: '100px'}}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={sectorId}
                          onChange={(e) => {
                            setsectorId(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {ActiveSectorsL?.length !== 0 &&
                            _.orderBy(
                                ActiveSectorsL?.filter((s) => s.type === playBookType),
                                [(sec) => sec?.value?.toLowerCase()],
                            )?.map((r) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.key}
                                value={r?.key}
                                className="this-is-new-class"
                                onClick={(e) => {
                                  setSelectedSectorId(r.key);
                                }}
                              >
                                {r?.value}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <FormControl style={{width: '100px'}}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={commodityId}
                          onChange={handleCommodityId}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {ActiveSectorsList?.[playBookType === playbookTypes.NON_PLAYBOOK ? 'Non-Playbook' : playBookType]?.length !== 0 &&
                            ActiveSectorsList?.[playBookType === playbookTypes.NON_PLAYBOOK ? 'Non-Playbook' : playBookType]?.map(
                                (obj) =>
                                  obj.id === selectedSectorId &&
                                _.orderBy(obj.childModelList, [(s) => s.name?.toLowerCase()])?.map((r, ind) => (
                                  <MenuItem sx={customTheme.selectMenuBox} key={r?.id} value={r?.id} className="this-is-new-class">
                                    {r.name}
                                  </MenuItem>
                                )),
                            )}
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormControl style={{width: '100px'}}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={currencyId}
                          onChange={handlecurrencyId}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                            <span>Select</span>
                          </MenuItem>
                          {Currency.length !== 0 &&
                            Currency?.filter((Curr) => Curr.currency.trim() !== '' && Curr.is_active)
                                .sort((a, b) => a.currency.localeCompare(b.currency))
                                .map((obj) => (
                                  <MenuItem
                                    sx={customTheme.selectMenuBox}
                                    key={obj?.currency_id}
                                    value={obj?.currency_id}
                                    className="this-is-new-class"
                                  >
                                    {obj.currency.toUpperCase()}
                                  </MenuItem>
                                ))}
                        </Select>
                      </FormControl>
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      <input
                        type="date"
                        id="datemin"
                        name="datemin"
                        min={currentDate}
                        value={priceMonthYear}
                        onChange={(e) => {
                          setPriceMonthYear(e.target.value);
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      <TextareaAutosize
                        style={theme.palette.TextArea}
                        placeholder="Type in here…"
                        minRows={1}
                        maxRows={4}
                        onChange={(e) => {
                          setGuidancePrice(e.target.value);
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      <FormControl style={{width: '100px'}}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={Volumeunit}
                          onChange={(e) => {
                            setVolumeunit(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {Units.length !== 0 &&
                            _.orderBy(Units, [(cat) => cat?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                              <MenuItem sx={customTheme.selectMenuBox} key={r?.unit_id} value={r?.unit_id} className="this-is-new-class">
                                {r.unit_description}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row"></StyledTableCell>
                    <StyledTableCell component="th" scope="row"></StyledTableCell>

                    <StyledTableCell align="left">
                      <Box sx={{display: 'flex', gap: '5px'}}>
                        <Box sx={saveButtonStyles} onClick={enableSaveButton ? saveTheRow : undefined}>
                          <Box>Save</Box>
                        </Box>
                        <Box
                          sx={{
                            border: '1px solid gray',
                            display: 'flex',
                            border: '0.5px solid #CECECE',
                            borderRadius: '4px',
                            width: '58px',
                            alignItems: 'center',
                            gap: '5px',
                            padding: '2px 6px',
                            cursor: 'pointer',
                          }}
                          onClick={clearNewRow}
                        >
                          <Box>Delete</Box>
                        </Box>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {data?.slice(page * pagination, page * pagination + pagination)?.map((row, key) => (
                  <CommonConfSingleRow
                    row={row}
                    key={key}
                    setAddingTrigger={setAddingTrigger}
                    addingTrigger={addingTrigger}
                    values={values}
                    idKey={idKey}
                    singleUpdate={singleUpdate}
                    setErrMsg={setErrMsg}
                    Units={Units}
                    Volumes={Volumes}
                    accessControl={accessControl}
                    Currency={Currency}
                    setIsErrorPopupVisible={setIsErrorPopupVisible}
                    filterResetClicked={filterResetClicked}
                    submitClicked={submitClicked}
                    page={page}
                    ActiveSectorsList={ActiveSectorsList}
                    apiRespDropdownData={apiRespDropdownData}
                    playbookTypes = {playbookTypes}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {data.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 15]}
              component="div"
              count={data?.length || 0}
              rowsPerPage={pagination}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default CommonConfTable;
