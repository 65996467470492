/* This Page is the  common table page which has features like  editing the row and used by different
admin pages.
*/

import styled from '@emotion/styled';
import {Box,
  createTheme,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextareaAutosize} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import theme from '../../../../theme';
import config from '../../../../Utils/Config';
import customealltheme from '../../../../theme';
const Edit = config.AccessLevel.Edit;

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const CommonConfSingleRow = ({row, setAddingTrigger, addingTrigger,
  values, editValues, dateValues, idKey, singleUpdate, accessControl,
  setErrMsg, setIsErrorPopupVisible, page}) => {
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [editedDetails, setEditDetails] = useState({});
  const [isActiveValue, setIsActiveValue] = React.useState('');

  const handleIsActive = (e) => {
    setIsActiveValue(e.target.value);
  };

  function toCamelCase(arr) {
    let string = arr[0];
    for (let i=1; i<arr.length; i++) {
      string = string+ arr[i].charAt(0).toUpperCase()+arr[i].
          slice(1, arr[i].length);
    }
    return string;
  }
  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(()=>{
    const keyvalues = {};
    values.map((h, index)=>{
      keyvalues[h] = row[h];
    });
    setEditDetails({...keyvalues});
  }, [row, values]);

  const editTheRow = () =>{
    setEditMode(true);
  };
  useEffect(()=>{
    setEditMode(false);
  }, [page]);
  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () =>{
    setEditMode(false);
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);
    const keyvalues = {};
    const originalEdited = {...editedDetails};
    values.map((h, index)=>{
      if (document.getElementById(h) !==null) {
        originalEdited[h] = document.getElementById(h).value;
      }

      keyvalues[toCamelCase(h.split('_'))] =
       document.getElementById(h) !==null ?
      document.getElementById(h).value : editedDetails[h];
    });
    // setEditDetails({...originalEdited});
    idKey.map((idkey)=>{
      keyvalues[`${idkey}`] = row[idkey];
      keyvalues[toCamelCase(idkey.split('_'))] = row[idkey];
      delete keyvalues[idkey];
    });


    singleUpdate({...keyvalues, active: isActiveValue !=='' ? isActiveValue: row.is_active, isActive: isActiveValue !=='' ? isActiveValue : row.is_active}).then((res)=>{
      if (res.userMessage) {
        setIsErrorPopupVisible(true);
        setErrMsg(res.userMessage);
        setAddingTrigger(!addingTrigger);
      }
    }).catch((err)=>{
      setIsErrorPopupVisible(true);
      setErrMsg('Error has been occured');
      setAddingTrigger(!addingTrigger);
    });
  };

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#eff6fc',
      color: 'rgba(59, 70, 85, 1)',
      font: 'normal normal 600 13px/19px \'Segoe UI\'',
      padding: '9px',
    },
    [`&.${tableCellClasses.body}`]: {
      font: 'normal normal normal 13px/19px \'Segoe UI\'',
      color: 'rgba(109, 120, 136, 1)',
      padding: '9px',
    },
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: ' #FFFFFF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
    <StyledTableRow>

      <StyledTableCell component="th" scope="row">
        { editMode===true?
                <TextareaAutosize id={'currency_description'} style={theme.palette.TextArea}
                  placeholder="Type in here…"
                  defaultValue={editedDetails['currency_description']}
                  minRows={1}
                  maxRows={4}
                /> :
              editedDetails['currency_description']
        }
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        { editMode===true && row.is_editable ==='Y' ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={isActiveValue || row.is_active}
            onChange={handleIsActive}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj === 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
              `${editedDetails.is_active ? 'Y' : 'N'}`
        }
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" >
        { editMode===true?
                <TextareaAutosize id={'currency'} style={theme.palette.TextArea}
                  placeholder="Type in here…"
                  defaultValue={editedDetails['currency']}
                  minRows={1}
                  maxRows={4}
                /> :
              editedDetails['currency']
        }
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row.last_updated_by?row.last_updated_by:''}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {`${new Date(row['last_updated_on']).getDate() < 10 ? '0' +
              new Date(row['last_updated_on']).getDate() :
              new Date(row['last_updated_on']).getDate()}-${new Date(row['last_updated_on']).getMonth() < 10 ?
          monthArr[new Date(row['last_updated_on']).getMonth()] :
          monthArr[new Date(row['last_updated_on']).getMonth()] }-${new Date(row['last_updated_on']).getFullYear()}`}
      </StyledTableCell>

      {accessControl === Edit && (
        <StyledTableCell align="left">
          {editMode === false ? (
      <Box
        sx={{
          border: '1px solid gray',
          display: 'flex',
          borderRadius: '4px',
          width: '58px',
          alignItems: 'center',
          gap: '5px',
          padding: '2px 6px',
          cursor: 'pointer',
        }}
        onClick={editTheRow}
        style={customealltheme.palette.TableEdit}
      >
        <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
        <Box>Edit</Box>
      </Box>
    ) : (
      <Box sx={{display: 'flex', gap: '5px'}}>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
            background: '#4297d3',
            color: 'white',
            justifyContent: 'center',
          }}
          onClick={saveTheRow}
        >
          <Box>Save</Box>
        </Box>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
          }}
          onClick={(e) => setEditMode(false)}
        >
          <Box>Cancel</Box>
        </Box>
      </Box>
    )}
        </StyledTableCell>
      )}

    </StyledTableRow>
  );
};

export default CommonConfSingleRow;
