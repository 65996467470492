/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import {createTheme, styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, FormControl, InputBase, MenuItem, Select, TablePagination, TextareaAutosize} from '@mui/material';
import CommonConfSingleRow from './PlayBookSingleRow';
import theme from '../../../theme';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {useDispatch, useSelector} from 'react-redux';
import {getDropDownData_API} from '../../../redux/features/apiCall';
import ConfigurationServices from '../../../services/ConfigurationServices';
import config from '../../../Utils/Config';
import customealltheme from '../../../theme';
import {setSortFilter, setSortType} from '../../../redux/features/configurations';
import Styles from '../../../styles/target';
import DownloadPlaybookList from './DownloadPlaybookList';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});


function CommonConfTable({idKey, row, setPage, Currency, Units, buyplanPersona, ActiveMasterSectors, ActiveSectors, page, sortFn, sortType, ActiveCommodities, accessControl, businessUnit, country, ActiveSubCommodities, data, ActiveCategories, setErrMsg, setIsErrorPopupVisible, setAddingTrigger, addingTrigger, heads, values, editValues, dateValues, sortValues, singleUpdate, newRowUpdate, pagination, setPagination, deletePlayBookRow, editPlayBookRow}) {
  const [AddNewRow, setNewRow] = useState(false);
  const handleAddRow = () =>{
    clearNewRow();
    setNewRow(true);
  };

  const currentDate = dayjs();
  const [standardCurrencyIdValue, setStandardCurrencyIdValue] = useState('');
  const [masterSectorName, setMasterSectorName] = useState('');
  const [countryIdValue, setCountryIdValue] = useState('');
  const [mappedRegionsList, setMappedRegionsList] = useState([]);
  const [standardUnitIdValue, setStandardUnitIdValue] = useState('');
  const [disableSupplierOptionality, setDisableSupplierOptionality]= useState(false);
  const [disableTreasuryOptionality, setDisableTreasuryOptionality]= useState(false);
  const [editModeDisableTrigger, setEditModeDisableTrigger]=useState(false);
  const [playbookNameValue, setPlaybookNameValue] = useState('');
  const [masterSectorValue, setMasterSectorValue] = React.useState('');
  const [sectorIdValue, setSectorIdValue] = React.useState('');
  const [categoryIdValue, setCategoryIdValue] = React.useState('');
  const [categoryName, setCategoryName] = useState('');
  const [commodityName, setCommodityName] = useState('');
  const [commodityIdValue, setCommodityIdValue] = useState('');
  const [subcommodityIdValue, setSubcommodityIdValue] = useState('');
  const [buyPlanOwnerValue, setBuyPlanOwnerValue]= useState('');
  const [ownerPersonaValue, setOwnerPersonaValue] = useState('');
  const [cascadeSelValue, setCascadeSelValue]= useState('');
  const [optionalityValue, setOptionalityValue]= useState('');
  const [supplierTargetsValue, setSupplierTargetsValue] = useState('');
  const [supplierOptionalityValue, setSupplierOptionalityValue] = useState('');
  const [treasuryTargetsValue, setTreasuryTargetsValue] = useState('');
  const [treasuryOptionalityValue, setTreasuryOptionalityValue] = useState('');
  const [currentStatusValue, setCurrentStatusValue]=useState(null);
  const [activeStartDateValue, setActiveStartDateValue]=useState(null);
  const [activeEndDateValue, setActiveEndDateValue] = useState(null);

  const [futureStatusValue, setFutureStatusValue]=useState('');
  const present = dayjs();
  let defaultFutureStartDate = present.add(1, 'month');
  if (present.date() !== defaultFutureStartDate.date()) {
    defaultFutureStartDate = defaultFutureStartDate.subtract(defaultFutureStartDate.date(), 'day');
  }
  let defaultFutureEndDate = defaultFutureStartDate.add(10, 'year');
  if (defaultFutureStartDate.date() !== defaultFutureEndDate.date()) {
    defaultFutureEndDate = defaultFutureEndDate.subtract(defaultFutureEndDate.date(), 'day');
  }
  const [futureStatusBackendValue, setFutureStatusBackendValue] =useState('');
  const [selectedFutureStartDate, setSelectedFutureStartDate] = useState('');
  const [selectedFutureEndDate, setSelectedFutureEndDate] = useState('');
  const [futureStartDateValue, setFutureStartDateValue]=useState('');
  const [futureEndDateValue, setFutureEndDateValue]=useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setEditModeDisableTrigger((editModeDisableTriggerValue)=>!editModeDisableTriggerValue);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(+event.target.value);
    setPage(0);
  };


  useEffect(()=>{
    setDisableSupplierOptionality(false);
    if (supplierTargetsValue=='N') {
      setSupplierOptionalityValue('false');
      setTimeout(()=>{
        setDisableSupplierOptionality(true);
      }, 1000);
    }
  }, [supplierTargetsValue]);

  useEffect(()=>{
    setDisableTreasuryOptionality(false);
    if (treasuryTargetsValue=='N') {
      setTreasuryOptionalityValue('false');
      setTimeout(()=>{
        setDisableTreasuryOptionality(true);
      }, 1000);
    }
  }, [treasuryTargetsValue]);


  useEffect(()=>{
    if (sectorIdValue) {
      const countriesList = [];
      ConfigurationServices.getSectorCountries(sectorIdValue).then((res)=>{
        res.forEach((element) => {
          element.is_present === 'Y'? countriesList.push(element):'';
        });
        setMappedRegionsList(countriesList);
      }).catch((e) => {
        // console.log([e]);
      })
      ;
    }
  }, [sectorIdValue]);


  const mapFutureStatusToBackendFormat = (selectedValue) => {
    switch (selectedValue) {
      case 'Active':
        return 'ACTIVE';
      case 'Inactive':
        return 'INACTIVE';
      case 'Coverage Suspension':
        return 'COV_SUSPENDED';
      case 'Coverage Review Suspension':
        return 'REVIEW_SUSPENDED';
      default:
        return '';
    }
  };

  const handleFutureStartDateChange = (date) => {
    setSelectedFutureStartDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureStartDateValue(d);
  };

  const handleFutureEndDateChange = (date) => {
    setSelectedFutureEndDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureEndDateValue(d);
  };

  const saveTheRow = () =>{
    newRowUpdate(
        {
          'active': true,
          'activeStartDate': activeStartDateValue,
          'activeEndDate': activeEndDateValue,
          'futureStartDate': futureStartDateValue!=''? futureStartDateValue:dayjs(defaultFutureStartDate).format('YYYY-MM'),
          'futureEndDate': futureEndDateValue!=''? futureEndDateValue:dayjs(defaultFutureEndDate).format('YYYY-MM'),
          'buyplanOwner': buyPlanOwnerValue,
          'buyPlanOwnerUserId': buyPlanOwnerValue,
          'buyPlanOwnerPersonaId': ownerPersonaValue,
          'cascadeTargetBP': cascadeSelValue==='true'?true:false,
          'commodityGroupId': categoryIdValue,
          'commodityId': commodityIdValue,
          'currencyId': standardCurrencyIdValue,
          'currentStatus': currentStatusValue,
          'futureStatus': futureStatusBackendValue,
          'measurementUnitId': standardUnitIdValue,
          'optionality': optionalityValue==='true'?true:false,
          'playbookName': playbookNameValue,
          'regionId': countryIdValue,
          'sectorGroupId': masterSectorValue,
          'sectorId': sectorIdValue,
          'subCommodityId': subcommodityIdValue,
          'supplierTargets': supplierTargetsValue==='Y' ? true : false,
          'supplierOptionality': supplierTargetsValue === 'N' ? false : (supplierOptionalityValue==='true'?true:false),
          'treasuryTargets': treasuryTargetsValue==='Y' ? true : false,
          'treasuryOptionality': treasuryTargetsValue === 'N' ? false : (treasuryOptionalityValue==='true'?true:false),
        },
    ).then((res)=>{
      if (res && res.userMessage) {
        setIsErrorPopupVisible(true);
        setErrMsg(res.userMessage);
        setAddingTrigger(!addingTrigger);
      } else if (res) {
        setNewRow(false);
        setAddingTrigger(!addingTrigger);
        clearNewRow();
      }
    })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('An error occurred');
          setAddingTrigger(!addingTrigger);
        });
  };

  const clearNewRow = () => {
    setActiveStartDateValue(null);
    setActiveEndDateValue(null);
    setFutureStartDateValue('');
    setFutureEndDateValue('');
    setSelectedFutureStartDate('');
    setSelectedFutureEndDate('');
    setBuyPlanOwnerValue('');
    setCascadeSelValue('');
    setCategoryIdValue('');
    setCategoryName('');
    setCommodityIdValue('');
    setCommodityName('');
    setCountryIdValue('');
    setCurrentStatusValue(null);
    setFutureStatusValue('');
    setMasterSectorName('');
    setMasterSectorValue('');
    setPlaybookNameValue('');
    setSectorIdValue('');
    setStandardCurrencyIdValue('');
    setSubcommodityIdValue('');
    setStandardUnitIdValue('');
    setSupplierTargetsValue('');
    setSupplierOptionalityValue('');
    setTreasuryTargetsValue('');
    setTreasuryOptionalityValue('');
    setOwnerPersonaValue('');
    setOptionalityValue('');
  };

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setSortType(''));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getDropDownData_API());
  }, [dispatch]);

  const Edit = config.AccessLevel.Edit;

  const enableSaveButton = playbookNameValue!=='' &&
   masterSectorValue!=='' &&
   categoryIdValue!=='' &&
   commodityIdValue!=='' &&
   buyPlanOwnerValue!=='' &&
   standardUnitIdValue!=='' &&
   standardCurrencyIdValue!=='' &&
   (cascadeSelValue==='true' || cascadeSelValue==='false') &&
   (optionalityValue==='true' || optionalityValue==='false') &&
   (treasuryOptionalityValue==='true' || treasuryOptionalityValue==='false' || (treasuryOptionalityValue === '' && treasuryTargetsValue === 'N')) &&
   (treasuryTargetsValue==='Y' || treasuryTargetsValue==='N') &&
   (supplierOptionalityValue==='true' || supplierOptionalityValue==='false' || (supplierOptionalityValue==='' && supplierTargetsValue === 'N' )) &&
   (supplierTargetsValue==='Y' || supplierTargetsValue==='N');

  const saveButtonStyles = {
    border: '1px solid gray',
    display: 'flex',
    border: '0.5px solid #CECECE',
    borderRadius: '4px',
    width: '58px',
    alignItems: 'center',
    gap: '5px',
    padding: '2px 6px',
    background: '#4297d3',
    color: 'white',
    justifyContent: 'center',
    cursor: enableSaveButton ? 'pointer' : 'not-allowed',
  };
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  return (<>
    <>
      <Box sx={{width: '100%', padding: '0px 18px'}}>
        <Box sx={{marginTop: '20px'}}>
          <Box sx={{padding: '0px 18px', display: 'flex', gap: '10px', flexDirection: 'row-reverse', marginTop: '0px'}}>
            {accessControl === Edit && (
              <Styles.StyledDownloadButton onClick={handleAddRow}>
                <AddIcon sx={{fontSize: '18px'}} /> Add new row
              </Styles.StyledDownloadButton>
            )}
            <DownloadPlaybookList data={data}/>
          </Box>
          <TableContainer component={Paper} id='playbooktable'>
            <Table sx={{minWidth: 700}} aria-label="customized table">
              <TableHead id='playbookhead'>
                <TableRow>
                  {heads.map((h, index)=>(
                    <StyledTableCell align="left" key={index} style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px'}}>
                      <Box style={customealltheme.palette.TableHead}>
                        <Box>{h}</Box>
                        {sortValues[index] === true &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0.5'}}
                          onClick={(e)=>{
                            dispatch(setSortFilter(values[index]));
                            if (sorttype === 'asc' ) {
                              dispatch(setSortType('desc'));
                            } if (sorttype === 'desc' || sorttype === '') {
                              dispatch(setSortType('asc'));
                            }
                          }}
                          onMouseOver={(e)=>{
                            e.target.style.opacity = '1';
                          }}
                          onMouseLeave={(e)=>{
                            e.target.style.opacity = '0.5';
                          }}
                        > <SwapVertIcon />{sorttype}</Box>}

                      </Box>

                    </StyledTableCell>
                  ))}
                  {accessControl===Edit && <StyledTableCell align="left">Action</StyledTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {AddNewRow === true &&
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <TextareaAutosize style={theme.palette.TextArea}
                          placeholder="Type in here…"
                          value={playbookNameValue}
                          minRows={1}
                          maxRows={4}
                          onChange={(e)=>{
                            setPlaybookNameValue(e.target.value);
                          }}
                        />
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={masterSectorValue}
                            onChange={(e)=>{
                              setMasterSectorValue(e.target.value);
                              setSectorIdValue('');
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {(ActiveMasterSectors.length !== 0) &&
                      _.orderBy(ActiveMasterSectors, [(sec) => sec?.master_sector?.toLowerCase()])?.map((obj)=>(

                        <MenuItem
                          sx={customTheme.selectMenuBox}
                          key={obj?.master_sector_id}
                          value={obj?.master_sector_id}
                          className="this-is-new-class"
                          onClick={(e)=>{
                            setMasterSectorName(obj.master_sector);
                          }}
                        >
                          {obj.master_sector}
                        </MenuItem>

                      ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>


                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={sectorIdValue}
                            onChange={(e)=>{
                              setSectorIdValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {ActiveSectors.length !== 0 &&
                            ActiveSectors?.map((obj) => ( obj.name === masterSectorName &&
                              _.orderBy(obj.sectors, [(s) => s.name?.toLowerCase()])?.map((r, ind)=>(
                                r.active==true &&
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.id}
                                  value={r?.id}
                                  className="this-is-new-class"
                                >
                                  {r.name}
                                </MenuItem>
                              ))

                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={countryIdValue}
                            onChange={(e)=>{
                              setCountryIdValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {(mappedRegionsList?.length !==0 ) &&
                              _.orderBy(mappedRegionsList, [(s) => s.region?.toLowerCase()])?.map((r, ind)=>(
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.region_id || ''}
                                  value={r?.region_id || ''}
                                  className="this-is-new-class"
                                >
                                  {r.region}
                                </MenuItem>

                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row" >
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={categoryIdValue}
                            onChange={(e)=>{
                              setCategoryIdValue(e.target.value);
                              setCommodityIdValue('');
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {ActiveCategories.length !== 0 &&
                                  _.orderBy(ActiveCategories, [(cat) => cat?.category?.toLowerCase()])?.map((obj) => (
                                    <MenuItem
                                      sx={customTheme.selectMenuBox}
                                      key={obj?.category_id}
                                      value={obj?.category_id}
                                      className="this-is-new-class"
                                      onClick={(e)=>{
                                        setCategoryName(obj.category);
                                      }}
                                    >
                                      {obj.category}
                                    </MenuItem>
                                  ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={commodityIdValue}
                            onChange={(e) => {
                              setCommodityIdValue(e.target.value);
                              setSubcommodityIdValue('');
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {ActiveCommodities.length !== 0 && categoryName!=='' &&
                                  ActiveCommodities?.map((r) => (
                                    r.name===categoryName && _.orderBy(r.commodities, [(a) => a.name?.toLowerCase()])?.map((obj) =>obj.is_active === true &&
                                    <MenuItem
                                      sx={customTheme.selectMenuBox}
                                      key={obj?.id}
                                      value={obj?.id}
                                      className="this-is-new-class"
                                      onClick={(e)=>{
                                        setCommodityName(obj.name);
                                      }}
                                    >
                                      {obj.name}
                                    </MenuItem>,
                                    )
                                  ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={subcommodityIdValue}
                            onChange={(e)=>{
                              setSubcommodityIdValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {ActiveSubCommodities.length !== 0 && commodityName!=='' &&
                                  ActiveSubCommodities?.map((r) => (
                                    r.name===commodityName &&
                                    _.orderBy(r.subCommodities, [(a) => a.name?.toLowerCase()])?.filter((sb) => sb.is_active)?.map((obj) =>
                                      <MenuItem
                                        sx={customTheme.selectMenuBox}
                                        key={obj?.id}
                                        value={obj?.id}
                                        className="this-is-new-class"
                                      >
                                        {obj.name}
                                      </MenuItem>,
                                    )
                                  ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={buyPlanOwnerValue}
                            onChange={(e)=>{
                              setBuyPlanOwnerValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty

                            className="newclass"
                          >
                            { _.orderBy(buyplanPersona['buyplan_owner'], [(c) => c.userName?.toLowerCase()])?.map((obj) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={obj.userId}
                                value={obj.userId}
                                onClick={()=>{
                                  setOwnerPersonaValue(obj.personaId);
                                }}
                                className="this-is-new-class"
                              >
                                {obj.userName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={standardUnitIdValue}
                            onChange={(e)=>{
                              setStandardUnitIdValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Units.length !== 0 &&
                              _.orderBy(Units, [(u) => u?.unit_description?.toLowerCase()?.trim()])?.map((r) => (


                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.unit_id}
                                  value={r?.unit_id}
                                  className="this-is-new-class"
                                >
                                  {r.unit_description}
                                </MenuItem>


                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={standardCurrencyIdValue}
                            onChange={(e)=>{
                              setStandardCurrencyIdValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Currency.length !== 0 &&
                              _.orderBy(Currency, [(cur) => cur?.currency_description?.toLowerCase()])?.map((r) => (


                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.currency_id}
                                  value={r?.currency_id}
                                  className="this-is-new-class"
                                >
                                  {r.currency_description}
                                </MenuItem>


                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={cascadeSelValue}
                            onChange={(e)=>{
                              setCascadeSelValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            { ['true', 'false']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj == 'true' ? 'Y' : 'N'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={optionalityValue}
                            onChange={(e)=>{
                              setOptionalityValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            { ['true', 'false']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj == 'true' ? 'Y' : 'N'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={supplierTargetsValue}
                            onChange={(e)=>{
                              setSupplierTargetsValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            { ['Y', 'N']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={supplierOptionalityValue}
                            onChange={(e)=>{
                              if (supplierTargetsValue==='Y') {
                                setSupplierOptionalityValue(e.target.value);
                              } else {
                                setSupplierOptionalityValue('false');
                              }
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            disabled={disableSupplierOptionality}
                            className="newclass"
                          >
                            { ['true', 'false']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj == 'true' ? 'Y' : 'N'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={treasuryTargetsValue}
                            onChange={(e)=>{
                              setTreasuryTargetsValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            { ['Y', 'N']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={treasuryOptionalityValue}
                            onChange={(e)=>{
                              if (treasuryTargetsValue==='Y') {
                                setTreasuryOptionalityValue(e.target.value);
                              } else {
                                setTreasuryOptionalityValue('false');
                              }
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            disabled={disableTreasuryOptionality}
                            className="newclass"
                          >
                            { ['true', 'false']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj == 'true' ? 'Y' : 'N'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                      </StyledTableCell>
                      <StyledTableCell>
                      </StyledTableCell>
                      <StyledTableCell>
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={futureStatusValue}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setFutureStatusValue(selectedValue);
                              const FutureStatusbackendValue = mapFutureStatusToBackendFormat(selectedValue);
                              setFutureStatusBackendValue(FutureStatusbackendValue);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            { ['Active', 'Inactive', 'Coverage Suspension', 'Coverage Review Suspension']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={['month', 'year']}
                            disablePast={true}
                            value={selectedFutureStartDate || defaultFutureStartDate}
                            onChange={handleFutureStartDateChange}
                            format={'MMM-YYYY'}
                            minDate={currentDate}
                            renderInput={(params) => <input {...params} />}
                          />
                        </LocalizationProvider>
                      </StyledTableCell>


                      <StyledTableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={['month', 'year']}
                            disablePast={true}
                            format={'MMM-YYYY'}
                            value={selectedFutureEndDate || defaultFutureEndDate}
                            onChange={handleFutureEndDateChange}
                            minDate={selectedFutureStartDate}
                            renderInput={(params) => <input {...params} />}
                          />
                        </LocalizationProvider>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>

                      <StyledTableCell align="left" >
                        <Box sx={{display: 'flex', gap: '5px'}}>
                          <Box
                            sx={enableSaveButton ? saveButtonStyles : {...saveButtonStyles, opacity: 0.5}}
                            onClick={enableSaveButton ? saveTheRow : undefined}
                          >
                            <Box>Save</Box>
                          </Box>
                          <Box sx={{border: '1px solid gray',
                            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
                            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
                            cursor: 'pointer'}}
                          onClick={(e)=>{
                            clearNewRow();
                            setNewRow(false);
                          }}>
                            <Box>Delete</Box>
                          </Box>
                        </Box>


                      </StyledTableCell>
                    </StyledTableRow>
                }
                {data?.slice(page * pagination, page * pagination + pagination)
                    ?.map((row, key) => (
                      <CommonConfSingleRow
                        row={row}
                        key={key}
                        setAddingTrigger={setAddingTrigger}
                        addingTrigger={addingTrigger}
                        values={values}
                        editValues={editValues}
                        dateValues={dateValues}
                        idKey={idKey}
                        singleUpdate={singleUpdate}
                        setErrMsg={setErrMsg}
                        Units={Units}
                        Currency = {Currency}
                        businessUnit={businessUnit}
                        accessControl={accessControl}
                        setIsErrorPopupVisible={setIsErrorPopupVisible}
                        buyplanPersona={buyplanPersona}
                        editModeDisableTrigger={editModeDisableTrigger}
                        page = {page}
                        deletePlayBookRow={deletePlayBookRow}
                        editPlayBookRow={editPlayBookRow}
                        ActiveSectors={ActiveSectors}
                        ActiveSubCommodities={ActiveSubCommodities}
                        country={country}

                      />
                    ))}

              </TableBody>
            </Table>
          </TableContainer>

          {data.length>0 && <TablePagination
            rowsPerPageOptions={[2, 5, 10, 15]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={pagination}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />}
        </Box>
      </Box>
    </>

  </>);
}

export default CommonConfTable;
