/* eslint-disable no-unused-vars */
/* This Page is the  Currency conversion page which has feautures like adding Currencys, editing existing Currencys and filtering the Currencys
based on the Currency.
*/

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../../services/ConfigurationServices';
import theme from '../../../../theme';
import Loading from '../../CommonConfFiles/Loading/Loading';
import ErrorMessage from '../../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../../common/App-Dialog/AppDialog';
import CommodityTable from './CommonSector/SectorTable';
import SectorDisplay from './SectorDisplay';
import config from '../../../../Utils/Config';

const Commodity = (props) => {
  const [row, setRow] = useState([]);
  const [activeMasterSector, setactiveMasterSector] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);

  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [page, setPage] = React.useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [filterResetClicked, setFiltersResetClicked] = useState(false);
  const [idKey, setIdKey] = useState([]);

  const heads = ['Master Sector', 'Sector', 'Is Active', 'Last Edited By', 'Last Edited On'];

  const values = ['master_sector_name', 'sector_name', 'sector_active', 'sector_updated_by', 'sector_updated_on',
  ];

  const dateValues = [false, false, false, false, false, false];

  const editValues = [true, false, true, false, true, false];

  const [pagination, setPagination] = useState(10);

  const [addingTrigger, setAddingTrigger] = useState(false);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedCommodityFilterValue);

  const sortValues = [true, true, false, true, true];
  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const updateSingleUnitMaster = ConfigurationServices.
      updateSectordata;
  const newRowUpdate = ConfigurationServices.postSectordata;


  useEffect(()=>{
    ConfigurationServices.getSectordata()
        .then((res)=>{
          if (res !=undefined && typeof res !== 'string') {
            const keys = [];
            res.length>0 && Object.keys(res[0]).map((obj) => {
              if (obj.split('_').includes('id')) {
                keys.push(obj);
              }
            });
            setIdKey([...keys]);
            if (res['userMessage']) {
              setRow([]);
              setErrMsg(res['userMessage']);
            } else {
              const activeSector = [];
              const inActiveSector = [];
              res.commodityGroups.forEach((masterSector) => {
                masterSector.sectors.forEach( (sector) =>{
                  (sector.active ? activeSector : inActiveSector).push({
                    'master_sector_name': masterSector.name,
                    'master_sector_id': masterSector.id,
                    'master_sector_active': masterSector.active,
                    'sector_active': sector.active,
                    'sector_id': sector.id,
                    'sector_is_editable': sector.isEditable,
                    'sector_name': sector.name,
                    'sector_regionIdList': sector.regionIdList,
                    'sectorGroupId': sector.sectorGroupId,
                    'sector_updated_on': sector.updatedOn,
                    'sector_updated_by': sector.updatedBy,
                  });
                });
              });

              activeSector.sort((a, b) => new Date(b.sector_updated_on) - new Date(a.sector_updated_on));
              inActiveSector.sort((a, b) => new Date(b.sector_updated_on) - new Date(a.sector_updated_on));

              setRow([...activeSector, ...inActiveSector]);
              setModifiedData([...activeSector, ...inActiveSector]);
            }
          } else {
            setRow([]);
            setErrMsg('Error Occured due to backend connection.');
          };
        });
  }, [addingTrigger]);

  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='category_all' ||
     filteredUnitValue[0]==='commodity_all' ||
     (filteredUnitValue?.category.length ===0 && filteredUnitValue?.commodity.length ===0 ) ) {
      setModifiedData([...row]);
    } else {
      if (filteredUnitValue['category'].length>0 &&
        filteredUnitValue['commodity'].length>0) {
        const original =[...row];
        const dummy = original.filter( (item) => filteredUnitValue['category'].includes(item.master_sector_name) && filteredUnitValue['commodity'].includes(item.sector_name));
        setModifiedData([...dummy]);
      } else
      if (filteredUnitValue['category'].length>0) {
        setModifiedData([...row.filter((item) => filteredUnitValue['category'].includes(item.master_sector_name))]);
      } else if (filteredUnitValue['commodity'].length > 0) {
        setModifiedData([...row.filter((item) => filteredUnitValue['commodity'].includes(item.sector_name))]);
      } else {
        setModifiedData([...row]);
      }
    }
    setPage(0);
  }, [filteredUnitValue, row, addingTrigger]);

  // useEffect(()=>{
  //   if (SortValue!=='') {
  //     if (sorttype === 'asc') {
  //       modifiedData?.sort((a, b) =>
  //         a[SortValue].localeCompare(b[SortValue]));
  //     } if (sorttype === 'desc') {
  //       modifiedData?.sort((a, b) =>
  //         b[SortValue].localeCompare(a[SortValue]));
  //     }
  //     setModifiedData([...modifiedData]);
  //   }
  // }, [SortValue, sorttype]);

  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='sector_updated_on') {
      if (sorttype === 'asc') {
        modifiedData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        modifiedData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setModifiedData([...modifiedData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(modifiedData, (item) => new Date(item.sector_updated_on));
        setModifiedData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(modifiedData, (item) => new Date(item.sector_updated_on));
        setModifiedData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);


  useEffect(()=>{
    ConfigurationServices.getMasterSectorData()
        .then((res)=>{
          const activeMasterSecto = [];
          res.map((r)=>{
            r.is_active === true && activeMasterSecto.push(r);
          });
          setactiveMasterSector([...activeMasterSecto]);
        });
  }, []);
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Sector_Master']) {
        setAccessControl(functionalityAccess['Configuration:Sector_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>
        <SectorDisplay row={_.orderBy(row, [(r) => r?.name?.toLowerCase()])} setSubmitClicked={setSubmitClicked} setFiltersResetClicked={setFiltersResetClicked} />
        <Box sx={{}}>
          <CommodityTable
            modifiedData={modifiedData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            sortValues={sortValues}
            activeMasterSector={activeMasterSector}
            accessControl={accessControl}
            page={page}
            setPage={setPage}
            filterResetClicked={filterResetClicked}
            submitClicked={submitClicked}
          />
          {(errMsg !=='' || modifiedData.length == 0) &&
        <ErrorMessage message='Sector' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
    </Box>
  );
};

export default Commodity;
