/* eslint-disable react/react-in-jsx-scope */
import './home.css';
import React from 'react'; // useEffect
// import {useOktaAuth} from '@okta/okta-react';

import MainComponent from '../../Components/MainComponent/MainComponent';

const Home = () => {
  // const {oktaAuth, authState} = useOktaAuth();
  // useEffect(() => {
  //   if (oktaAuth && authState) {
  //     console.log('@pep04 authState', authState);
  //   }
  // }, []);

  return (
    <>
      <MainComponent/>
    </>
  );
};

export default Home;
