/* eslint-disable camelcase */
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import config from '../../../Utils/Config';
import GuidanceList from './GuidanceDisplay';
import CommonConfTable from './GuidanceTable';
import {getDropDownData_API} from '../../../redux/features/apiCall';
import {setSortType} from '../../../redux/features/configurations';
const Guidance = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDropDownData_API());
    dispatch(setSortType(''));
  }, [dispatch]);
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [idKey, setIdKey] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [filterResetClicked, setFiltersResetClicked] = useState(false);
  const heads = [
    'Type',
    'Sector',
    'Commodity',
    'Currency',
    'Plan Month',
    'Guidance Price/ Open Market Price',
    'Volume Unit',
    'Last Edited By',
    'Last Edited On',
  ];

  const values = [
    'isNonPlaybook',
    'sector',
    'commodity',
    'currency',
    'priceMonthYear',
    'guidancePrice',
    'measurementUnit',
    'lastUpdatedBy',
    'lastUpdatedOn',
    'currencyId',
    'user_id',
    'id',
    'sectorId',
    'commodityId',
    'measurementUnitId',
  ];

  const playbookTypes = {
    NON_PLAYBOOK: 'Non Playbook',
    PLAYBOOK: 'Playbook',
  };

  const updateSingleUnitMaster = ConfigurationServices.
      updateGuidanceData;
  const newRowUpdate = ConfigurationServices.postGuidanceData;
  const [pagination, setPagination] = useState(10);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedGuidancePrice);

  const sortValues = [false, false, false, false, false, false, true, true, true];

  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const [addingTrigger, setAddingTrigger] = useState(false);


  useEffect(()=>{
    ConfigurationServices.getGuidanceData()
        .then((res)=>{
          if (res !=undefined && typeof res !== 'string') {
            const keys = [];
            res.length>0 && Object.keys(res[0])?.map((obj) => {
              if (obj.split('_').includes('id')) {
                keys.push(obj);
              }
            });

            setIdKey([...keys]);
            if (res['userMessage']) {
              setRow([]);
              setErrMsg(res['userMessage']);
            } else {
              setRow(res);
            }
          } else {
            setRow([]);
            setErrMsg('Error Occured due to backend connection.');
          };
        });
  }, [addingTrigger]);

  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='lastUpdatedOn') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.toLowerCase()?.trim()?.localeCompare(b[SortValue]?.toLowerCase()?.trim()));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.toLowerCase()?.trim()?.localeCompare(a[SortValue]?.toLowerCase()?.trim()));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.lastUpdatedOn));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.lastUpdatedOn));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);


  useEffect(() => {
    if (row.length>0) {
      if (
        filteredUnitValue === '' ||
      filteredUnitValue.length ===0 ||
      filteredUnitValue[0] === 'comm_all' ||
      filteredUnitValue[0] === 'currencyDes_all' ||
      filteredUnitValue[0] === 'all_month' ||
      filteredUnitValue[0] === 'sector_all' ||
      filteredUnitValue[0] === 'type_all'
      ) {
        setTableFilterData(row);
        return;
      }

      setTimeout(() => {
        let tempTableData = row;
        if (filteredUnitValue['selectedMonth'].length>0) {
          tempTableData = tempTableData.filter((r) =>
            filteredUnitValue['selectedMonth'].includes(r.priceMonthYear),
          );
        }

        if (filteredUnitValue['currency'].length > 0) {
          tempTableData = tempTableData.filter((r) =>
            filteredUnitValue['currency'].includes(r.currency),
          );
        }
        if (filteredUnitValue['sector'].length > 0) {
          tempTableData = tempTableData.filter((r) =>
            filteredUnitValue['sector'].includes(r.sector),
          );
        }
        if (filteredUnitValue['commodity'].length > 0) {
          tempTableData = tempTableData.filter((r) =>
            filteredUnitValue['commodity'].includes(r.commodity),
          );
        }
        if (filteredUnitValue['valueType'].length > 0) {
          tempTableData = tempTableData.filter((r) => {
            const type = r.isNonPlaybook===true ? playbookTypes.NON_PLAYBOOK : r.isNonPlaybook===false ? playbookTypes.PLAYBOOK: '';
            return filteredUnitValue['valueType'].includes(type);
          });
        }
        setTableFilterData(tempTableData);
        setPage(0);
      }, 1000);
    }
  }, [filteredUnitValue, row]);

  const [ActiveSectors, setActiveSectors] = useState([]);
  const [ActiveSectorsList, setActiveSectorsList] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getSectordata()
        .then((res)=>{
          const activeSectors = [];
          res['commodityGroups']?.map((r)=>{
            r.sectors?.map((rs)=>{
              rs.active === true && !activeSectors.includes(r) && activeSectors.push(r);
            });
          });
          setActiveSectors([...activeSectors]);
        });
  }, []);


  useEffect(()=>{
    ConfigurationServices.getGuidancePriceSectorCommodityList()
        .then((res)=>{
          setActiveSectorsList({...res});
        });
  }, []);

  const [ActiveCommodities, setActiveCommodities] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getCommodityData()
        .then((res)=>{
          const activeCommodities = [];
          res['commodityGroups']?.map((r)=>{
            r.commodities?.map((rs)=>{
              rs.is_active==true && !activeCommodities.includes(r) && activeCommodities.push(r);
            });
          });
          setActiveCommodities([...activeCommodities]);
        });
  }, []);

  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Guidance_Price']) {
        setAccessControl(functionalityAccess['Guidance_Price']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;

  const [Units, setUnits] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getUnitMasterData()
        .then((res)=>{
          const units = [];
          Array.isArray(res) && res?.map((r)=>{
            !units.includes(r) && units.push(r);
          });
          setUnits([...units].filter((unit) => unit.is_active));
        });
  }, []);


  const [Currency, setCurrency] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getCurrency()
        .then((res)=>{
          const Curren = [];
          Array.isArray(res) && res?.map((r)=>{
            !Curren.includes(r) && Curren.push(r);
          });
          setCurrency([...Curren].filter((cur) => cur.is_active));
        });
  }, []);

  const [page, setPage] = React.useState(0);
  return (
    <Box
      sx={{
        bgcolor: '#eff6fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0px',
      }}
    >
      {(accessControl === Edit || accessControl === Read) && <Box
        sx={theme.palette.whitebgbox}
      >
        <GuidanceList
          row={row}
          setPage={setPage}
          ActiveSectors={ActiveSectors}
          ActiveCommodities={ActiveCommodities}
          Currency={Currency}
          setSubmitClicked={setSubmitClicked}
          setFiltersResetClicked={setFiltersResetClicked}
          dispatch={dispatch}
          setSortType={setSortType}
          playbookTypes = {playbookTypes}
        />

        <Box sx={{}}>

          <CommonConfTable
            data={tableFilterData || []}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            accessControl={accessControl}
            values={values}
            addingTrigger={addingTrigger}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            sortValues={sortValues}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            submitClicked={submitClicked}
            filterResetClicked={filterResetClicked}
            setPage={setPage}
            page={page}
            ActiveSectorsList={ActiveSectorsList}
            Units={Units}
            Currency={Currency}
            dispatch={dispatch}
            setSortType={setSortType}
            playbookTypes = {playbookTypes}
          />


          {(errMsg !=='' || tableFilterData.length == 0) &&
        <ErrorMessage message='Guidance Price' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
    </Box>
  );
};

export default Guidance;
