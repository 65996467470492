// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveCoverageInput{
    color: #3B4655;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    font-family: 'Segoe UI';}`, "",{"version":3,"sources":["webpack://./src/Components/Coverage/SaveCoverage/SaveCoverageInput/SaveCoverageInput.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB,CAAC","sourcesContent":[".saveCoverageInput{\n    color: #3B4655;\n    font-size: 12px;\n    font-weight: 400;\n    text-align: center;\n    font-family: 'Segoe UI';}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
