import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import theme from '../../theme';
import ForecastTable from './ForecastTable';
import ForecastList from './SelectForecast';
import DashboardService from '../../services/dashboardService';
import {CircularProgress} from '@mui/material';
const Forecast = () => {
  const dropdownSpan = {
    font: 'normal normal 600 12px/15px \'Segoe UI\' ',
    color: 'rgba(59, 70, 85, 1)',
  };
  const [yearValue, setYearValue] = useState(new Date().getFullYear()+'');
  const [selctedPlanYear, setYear] = useState('');
  useEffect(()=>{
    if (selctedPlanYear!=='') {
      setYearValue(selctedPlanYear+'');
    }
  }, [selctedPlanYear]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const selectedForecastFilter = useSelector(
      (state)=>state.api.selectedForecastDropDown);

  useEffect(() => {
    if (Object.keys(selectedForecastFilter).length>0) {
      DashboardService.
          getForecastTable(selectedForecastFilter.sector, selectedForecastFilter.region
              , selectedForecastFilter.commodityGrp, selectedForecastFilter.commodity,
              selectedForecastFilter.subCommodity, selectedForecastFilter.executionMonthYear)
          .then((res)=>{
            setLoading(true);
            setData({});
            setTimeout(()=>{
              setLoading(false);
              setData(res);
              setYear(selectedForecastFilter.executionMonthYear);
            }, 1000);
          });
    } else {
      setData({});
    }
  }, [selectedForecastFilter]);

  return (
    <Box
      sx={{
        bgcolor: '#eff6fc',
        // height: '78vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0px',
      }}
    >
      <Box
        sx={theme.palette.whitebgbox}
      >
        <ForecastList forecastData={data} setYearValue={setYearValue} setYear={setYear} selctedPlanYear = {selctedPlanYear}/>
        {loading ?
        <Box sx={{display: 'flex', padding: '2px', justifyContent: 'center', alignItems: 'center', height: '20vh'}}>
          <CircularProgress sx={{color: '#0099D8'}} />
        </Box> :

        <Box sx={{}}>
          {Object.keys(data).length>0 ? <>
            <Box sx={{display: 'flex', padding: '2px', gap: '5px', border: '1px solid #DBDBDB', width: 'auto', float: 'right', margin: '2px 20px 20px  20px', borderRadius: '3px'}}>
              <Box sx={{background: yearValue == selctedPlanYear-1?'#4297d3':'', color: yearValue ==selctedPlanYear-1?'white':'', padding: '2px', cursor: 'pointer', borderRadius: '3px'}} onClick={(e)=>setYearValue(`${selctedPlanYear-1}`)}>{selctedPlanYear-1}</Box>
              <Box sx={{background: yearValue == selctedPlanYear?'#4297d3':'', color: yearValue ==selctedPlanYear?'white':'', padding: '2px', cursor: 'pointer', borderRadius: '3px'}} onClick={(e)=>setYearValue(`${selctedPlanYear}`)}>{selctedPlanYear}</Box>
              <Box sx={{background: yearValue == selctedPlanYear+1?'#4297d3':'', color: yearValue ==selctedPlanYear+1?'white':'', padding: '2px', cursor: 'pointer', borderRadius: '3px'}} onClick={(e)=>setYearValue(`${selctedPlanYear+1}`)}>{selctedPlanYear+1}</Box>
              <Box sx={{background: yearValue == selctedPlanYear+2?'#4297d3':'', color: yearValue ==selctedPlanYear+2?'white':'', padding: '2px', cursor: 'pointer', borderRadius: '3px'}} onClick={(e)=>setYearValue(`${selctedPlanYear+2}`)}>{selctedPlanYear+2}</Box>
            </Box>
            <ForecastTable selctedPlanYear = {selctedPlanYear}
              year={yearValue} data={data}/>
          </> :
          <Box sx={{display: 'flex', padding: '2px', justifyContent: 'center', alignItems: 'center', height: '20vh'}}>
            <Box sx={dropdownSpan}>Select the valid options to display the data</Box>
          </Box>
          }
        </Box>
        }
      </Box>

    </Box>
  );
};

export default Forecast;
