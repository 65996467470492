/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React from 'react';
import './DropdownSelectMonth.css';
// import {RiArrowDropDownLine} from 'react-icons/ri';
import {FormControl, MenuItem, Select} from '@mui/material';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
// import {useSelector} from 'react-redux';
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const DropdownSelect = ({supplierNames, selectedRow, rowData, indexOfYear, apiData}) => {
  const [sourceselected, setSourceNameSelected] = React.useState('');
  const [dropDownData, setDropDownData] = React.useState([]);
  const isCreator = useSelector((state) => state.roleBasedAccess);

  const handleChange = (event) => {
    setSourceNameSelected(event.target.value);
    apiData[indexOfYear].map((element, index)=>{
      if (element.id==rowData.id) {
        element.coverageYearMonth=String(event.target.value).replace(' ', '-');
        rowData.coverageYearMonth=String(event.target.value).replace(' ', '-');
        if (rowData?.prevMonth) {
          element['author'] = isCreator?.userName;
          rowData['author'] = isCreator?.userName;
          element['isChanged'] = true;
          rowData['isChanged'] = true;
        }
      }
    });
  };

  {/*
    Return will be array of strings starting from one month prior to the buyplan execution month upto three years
    EX:- ['Aug 2023', 'Sep 2023',.., 'Dec 2025']
  */}
  const nextThreeYearsFromExecutionMon = (prevMonths = false) => {
    const executinoYearMon = selectedRow.executionMonthYear;
    let startYear = executinoYearMon[0];
    let currentMonthIndex = '';
    let resultArr = null;
    if ( (executinoYearMon[1] - 1) === 0 || (executinoYearMon[1] - 1) === 1) { // if the exceution month is jan 2023 then one month prior will be Dec 2021
      startYear -= 1;
      currentMonthIndex = executinoYearMon[1] == 1 ? 10 : 11;
    } else {
      currentMonthIndex = executinoYearMon[1]-3;
    }
    if (prevMonths) {
      if ((executinoYearMon[1] - 1) === 0 || (executinoYearMon[1] - 1) === 1) {
        // eslint-disable-next-line no-unused-vars
        resultArr = months.slice(0, executinoYearMon[1] == 1 ? 10 : 11).map((mon) => (mon + ' ' + (executinoYearMon[0]-1)));
      } else {
        resultArr = months.slice(0, currentMonthIndex).map((mon) => (mon + ' ' + executinoYearMon[0]));
      }
    } else {
      const allYears = [startYear, startYear+1, startYear+2];
      const monthsFromCurrMonth = months.slice(currentMonthIndex).map((mon) => (mon + ' ' + allYears[0]));
      const nextFirstYear = months.map((mon) => mon + ' ' + allYears[1]);
      const nextSecondYear = months.map((mon) => mon + ' ' + allYears[2]);
      resultArr = [...monthsFromCurrMonth, ...nextFirstYear, ...nextSecondYear];
    }
    return resultArr;
  };


  useEffect(()=>{
    setSourceNameSelected((rowData.coverageYearMonth === undefined || rowData.coverageYearMonth === null) ? '' : String(rowData.coverageYearMonth).replace('-', ' ') || '');
    if (rowData?.prevMonth) {
      setDropDownData(nextThreeYearsFromExecutionMon(true) || []);
    } else {
      setDropDownData(nextThreeYearsFromExecutionMon() || []);
    }
  }, [rowData]);
  return (
    <div>
      <FormControl variant='standard' sx={{width: '80px', fontSize: '10px'}}>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          defaultValue={'Select'}
          value={sourceselected || ''}
          placeholder={'Select'}
          onChange={handleChange}
          label='Age'
          sx={{width: '80px', fontSize: '12px'}}
          disabled={(rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume)}
        >
          {
            dropDownData?.map((month, id) => {
              return <MenuItem sx={{width: '80px', fontSize: '12px'}} key={id} value={month}>{month}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </div>
  );
};


export default DropdownSelect;
