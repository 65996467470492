import React, {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {tableCellClasses} from '@mui/material/TableCell';
import {Box, FormControl, InputBase, MenuItem, Select, TableCell, TableRow, createTheme} from '@mui/material';
import {AppDialog} from '../../common/App-Dialog/AppDialog';
import {BiEdit} from 'react-icons/bi';
import customealltheme from '../../../theme';
import config from '../../../Utils/Config';
import ConfigurationServices from '../../../services/ConfigurationServices';
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const btnStyle={
  display: 'flex',
  border: '0.5px solid #CECECE',
  borderRadius: '4px',
  alignItems: 'center',
  gap: '5px',
  padding: '2px 6px',
  cursor: 'pointer',
};

const Edit = config.AccessLevel.Edit;


const PlaybookCompMapSingleRow = ({
  row,
  setAddingTrigger,
  addingTrigger,
  updateRow,
  setErrMsg,
  accessControl,
  setIsErrorPopupVisible,
  editModeDisableTrigger,
  page,
  ActiveMasterSectors,
  ActiveSectors,
  ActiveCategories,
  ActiveCommodities,
  ActiveSubCommodities,
  ActiveCountry}) => {
  const [editMode, setEditMode] = useState(false);
  const [masterSectorName, setMasterSectorName] = useState('');
  const [masterSectorIdValue, setMasterSectorIdValue] = useState('');
  const [sectorIdValue, setSectorIdValue] = useState('');
  const [countryIdValue, setCountryIdValue] = useState('');
  const [categoryIdValue, setCategoryIdValue] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [commodityName, setCommodityName] = useState('');
  const [commodityIdValue, setCommodityIdValue] = useState('');
  const [subcommodityIdValue, setSubcommodityIdValue] = useState('');
  const [enableSaveButton, setEnableSaveButton]=useState(false);
  const [showSuccessDialog, setshowSuccessDialog] = useState(false);
  const [mappedRegionsList, setMappedRegionsList] = useState([]);

  useEffect(()=>{
    if (sectorIdValue) {
      const countriesList = [];
      ConfigurationServices.getSectorCountries(sectorIdValue).then((res)=>{
        res.forEach((element) => {
          element.is_present === 'Y'? countriesList.push(element):'';
        });
        setMappedRegionsList(countriesList);
      }).catch((e) => {
        // console.log([e]);
      })
      ;
    }
  }, [sectorIdValue]);

  const resetDataOnCancel = ()=>{
    setEditMode(false);
    setMasterSectorIdValue(row['reporting_sector_grp_id']);
    setSectorIdValue(row['reporting_sector_id']);
    setCategoryIdValue(row['reporting_category_id']);
    setCommodityIdValue(row['reporting_commodity_id']);
    setSubcommodityIdValue(row['reporting_sub_commodity_id']);
    setCountryIdValue(row['reporting_region_id']);
    setMasterSectorName(row['reporting_sector_grp']);
    setCategoryName(row['reporting_category']);
    setCommodityName(row['reporting_commodity']);
  };

  const editTheRow = () =>{
    resetDataOnCancel();
    setEditMode(true);
  };

  useEffect(()=>{
    setEditMode(false);
  }, [page]);

  useEffect(()=>{
    if (editMode===true) {
      setEditMode(false);
    }
  }, [editModeDisableTrigger]);

  useEffect(()=>{
    const shouldEnable = masterSectorIdValue!=='' && sectorIdValue!=='' && countryIdValue!=='' && categoryIdValue!=='' && commodityIdValue!=='' && subcommodityIdValue!=='';
    setEnableSaveButton(shouldEnable);
  }, [masterSectorIdValue, sectorIdValue, countryIdValue, categoryIdValue, commodityIdValue, subcommodityIdValue]);

  const saveTheRow = () =>{
    setEditMode(false);
    setTimeout(()=>{
      setshowSuccessDialog(true);
      setAddingTrigger(!addingTrigger);
    }, 1000);

    updateRow([{
      'buyPlanReportingId': row['reporting_id'],
      'buyPlanId': row['buy_plan_id'],
      'buyPlanMappingId': row['buy_plan_mapping_id'],
      'reportingSectorGrpId': masterSectorIdValue===''?null:masterSectorIdValue,
      'reportingSectorId': sectorIdValue===''?null:sectorIdValue,
      'reportingCategoryId': categoryIdValue===''?null:categoryIdValue,
      'reportingCommodityId': commodityIdValue===''?null:commodityIdValue,
      'reportingSubCommodityId': subcommodityIdValue===''?null:subcommodityIdValue,
      'reportingRegionId': countryIdValue===''?null:countryIdValue,
    }]).then((res)=>{
      if (res.userMessage) {
        setIsErrorPopupVisible(true);
        setErrMsg(res.userMessage);
        setAddingTrigger(!addingTrigger);
      }
    }).catch((err)=>{
      setIsErrorPopupVisible(true);
      setErrMsg('Error has been occured');
      setAddingTrigger(!addingTrigger);
    });
  };

  return (
    <>
      <StyledTableRow >
        <StyledTableCell component="th" scope="row">
          {row['show_value'] && row['playbook_name']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['show_value'] && row['buy_plan_name']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" style={{borderLeft: '1px solid #E0E0E0'}}>
          {row['master_sector']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['sector']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['country']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['category']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['commodity']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['sub_commodity']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" style={{borderLeft: '1px solid #E0E0E0'}}>
          {editMode === true ?

        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={masterSectorIdValue}
            onChange={(e)=>{
              setMasterSectorIdValue(e.target.value);
              setSectorIdValue('');
              setCountryIdValue('');
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            <MenuItem value="" sx={customTheme.selectMenuBox}>
              <span>Select</span>
            </MenuItem>
            {(ActiveMasterSectors.length !== 0) &&
                      _.orderBy(ActiveMasterSectors, [(sec) => sec?.master_sector?.toLowerCase()])?.map((obj)=>(

                        <MenuItem
                          sx={customTheme.selectMenuBox}
                          key={obj?.master_sector_id}
                          value={obj?.master_sector_id}
                          className="this-is-new-class"
                          onClick={(e)=>{
                            setMasterSectorName(obj.master_sector);
                          }}
                        >
                          {obj.master_sector}
                        </MenuItem>

                      ))}
          </Select>
        </FormControl> :
        row['reporting_sector_grp']

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {editMode === true ?

        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={sectorIdValue}
            onChange={(e)=>{
              setSectorIdValue(e.target.value);
              setCountryIdValue('');
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            className="newclass"
            displayEmpty
          >
            <MenuItem value="" sx={customTheme.selectMenuBox}>
              <span>Select</span>
            </MenuItem>
            {ActiveSectors.length !== 0 &&
                            ActiveSectors?.map((obj) => ( obj.name === masterSectorName &&
                              _.orderBy(obj.sectors, [(s) => s.name?.toLowerCase()])?.map((r, ind)=>(
                                r.active==true &&
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.id}
                                  value={r?.id}
                                  className="this-is-new-class"
                                >
                                  {r.name}
                                </MenuItem>
                              ))

                            ))}
          </Select>
        </FormControl> :

         row['reporting_sector']

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {editMode === true ?

        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={countryIdValue}
            onChange={(e)=>{
              setCountryIdValue(e.target.value);
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            <MenuItem value="" sx={customTheme.selectMenuBox}>
              <span>Select</span>
            </MenuItem>
            {(mappedRegionsList?.length !==0 ) &&
            _.orderBy(mappedRegionsList, [(s) => s.region?.toLowerCase()])?.map((r, ind)=>(
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={r?.region_id || ''}
                value={r?.region_id || ''}
                className="this-is-new-class"
              >
                {r.region}
              </MenuItem>

            ))}
          </Select>
        </FormControl> :

        row['reporting_region']

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" >
          {editMode === true ?

        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={categoryIdValue}
            onChange={(e)=>{
              setCategoryIdValue(e.target.value);
              setCommodityIdValue('');
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            <MenuItem value="" sx={customTheme.selectMenuBox}>
              <span>Select</span>
            </MenuItem>
            {ActiveCategories.length !== 0 &&
                                  _.orderBy(ActiveCategories, [(cat) => cat?.category?.toLowerCase()])?.map((obj) => (
                                    <MenuItem
                                      sx={customTheme.selectMenuBox}
                                      key={obj?.category_id}
                                      value={obj?.category_id}
                                      className="this-is-new-class"
                                      onClick={(e)=>{
                                        setCategoryName(obj.category);
                                      }}
                                    >
                                      {obj.category}
                                    </MenuItem>
                                  ))}
          </Select>
        </FormControl> :

        row['reporting_category']

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {editMode === true ?

        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={commodityIdValue}
            onChange={(e) => {
              setCommodityIdValue(e.target.value);
              setSubcommodityIdValue('');
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            <MenuItem value="" sx={customTheme.selectMenuBox}>
              <span>Select</span>
            </MenuItem>
            {ActiveCommodities.length !== 0 && categoryName!=='' &&
                                  ActiveCommodities?.map((r) => (
                                    r.name===categoryName && _.orderBy(r.commodities, [(a) => a.name?.toLowerCase()])?.map((obj) =>obj.is_active === true &&
                                    <MenuItem
                                      sx={customTheme.selectMenuBox}
                                      key={obj?.id}
                                      value={obj?.id}
                                      className="this-is-new-class"
                                      onClick={(e)=>{
                                        setCommodityName(obj.name);
                                      }}
                                    >
                                      {obj.name}
                                    </MenuItem>,
                                    )
                                  ))}
          </Select>
        </FormControl> :

        row['reporting_commodity']

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {editMode === true ?

        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={subcommodityIdValue}
            onChange={(e)=>{
              setSubcommodityIdValue(e.target.value);
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            <MenuItem value="" sx={customTheme.selectMenuBox}>
              <span>Select</span>
            </MenuItem>
            {ActiveSubCommodities.length !== 0 && commodityName!=='' &&
                                  ActiveSubCommodities?.map((r) => (
                                    r.name===commodityName &&
                                    _.orderBy(r.subCommodities, [(a) => a.name?.toLowerCase()])?.filter((sb) => sb.is_active)?.map((obj) =>
                                      <MenuItem
                                        sx={customTheme.selectMenuBox}
                                        key={obj?.id}
                                        value={obj?.id}
                                        className="this-is-new-class"
                                      >
                                        {obj.name}
                                      </MenuItem>,
                                    )
                                  ))}
          </Select>
        </FormControl> :

         row['reporting_sub_commodity']

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" style={{borderLeft: '1px solid #E0E0E0'}}>
          {row['last_edited_by']}
        </StyledTableCell><StyledTableCell component="th" scope="row">
          {row['last_edited']}
        </StyledTableCell>
        {accessControl === Edit &&
            <StyledTableCell align="left">
              <Box sx={{display: 'flex', gap: '5px'}}>
                {editMode === false ?
                  <>
                    <Box
                      sx={{...btnStyle, width: '58px'}}
                      onClick={editTheRow}
                      style={customealltheme.palette.TableEdit}>
                      <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
                      <Box>Edit</Box>
                    </Box>
                  </> :
               <>
                 <Box sx={{
                   border: '1px solid gray',
                   display: 'flex', border: '0.5px solid #CECECE',
                   borderRadius: '4px',
                   width: '58px',
                   alignItems: 'center', gap: '5px',
                   padding: '2px 6px',
                   opacity: enableSaveButton?1:0.5,
                   cursor: !enableSaveButton? 'not-allowed' : 'pointer',
                   background: '#4297d3', color: 'white',
                   justifyContent: 'center',
                 }}
                 onClick={!enableSaveButton?undefined:saveTheRow}>
                   <Box>Save</Box>
                 </Box>
                 <Box
                   sx={{...btnStyle, width: '58px'}}
                   onClick={resetDataOnCancel}>
                   <Box>Cancel</Box>
                 </Box>
               </>
                }

              </Box>
            </StyledTableCell>
        }
      </StyledTableRow>
      <AppDialog open={showSuccessDialog} handleClose={()=>setshowSuccessDialog(false)} text='Compliance Mapping is Saved'/>
    </>
  );
};

export default PlaybookCompMapSingleRow;
