import React, {useEffect, useState, useRef} from 'react';
import {Button, Dialog, DialogContent} from '@mui/material';
import {DataSheetGrid, keyColumn, textColumn} from 'react-datasheet-grid';
import {FiEye} from 'react-icons/fi';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfigurationServices from '../../services/ConfigurationServices';
import {useSelector} from 'react-redux';
import {monthArr} from '../../services/utils';
import LinearProgress from '@mui/material/LinearProgress';
import {AppButton} from '../common/AppButton/AppButton';
import {AppDialog, AppErrorDialog} from '../common/App-Dialog/AppDialog';
import {ExtensionNotSupportedToView, ExtensionWithContentType, ExtensionsSupportedToUpload} from './ExtensionWithContentType';
import {BiEdit} from 'react-icons/bi';
import customealltheme from '../../theme';

const styleViewOrUploadButton = {
  'background': `white`, 'color': '#555759',
  'font': '10px/20px \'Segoe UI\'',
  'height': '20px',
  'minWidth': '3rem',
  'marginRight': '10px',
  'fontSize': '0.7rem',
  'border': 'none',
  'textTransform': 'capitalize',
  'cursor': 'pointer',
  'display': 'flex',
  'gap': '2px',
  '&:hover': {
    'background': `white`,
    'border': 'none',
    'color': `#00B1FF`,
  },
};

const deleteButtonStyles = {
  'background': `white`, 'color': '#555759',
  'font': '10px/20px \'Segoe UI\'',
  'height': '30px',
  'minWidth': '5rem',
  'marginRight': '10px',
  'fontSize': '0.7rem',
  'border': 'none',
  'textTransform': 'capitalize',
  'cursor': 'pointer',
  'display': 'flex',
  'gap': '2px',
  '&:hover': {
    'background': `white`,
    'border': 'none',
    'color': `red`,
  },
};

const LinearIndeterminate = () => {
  return (
    <Box sx={{width: '100%'}}>
      <LinearProgress color={'linearProgress'}/>
    </Box>
  );
};

const DialogConfirmToDelete = ({text, dialogOpen, handleClose, confirmDelete}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
    >
      <DialogContent style={{position: 'relative', padding: '2rem', fontWeight: 500}}>
        <div>
          <font color="#6D7888">{text}</font>
        </div>
        <center>
          <div style={{padding: '1rem'}}>
            <div style={{display: 'flex', gap: '10px', justifyContent: 'center'}}>
              <AppButton variant="outlined" label="Close" onClick={() => handleClose()} />
              <AppButton label="Delete" onClick={() => {
                confirmDelete();
                handleClose();
              }} />
            </div>
          </div>
        </center>
      </DialogContent>
    </Dialog>
  );
};

const UploadDocument = ({setSuccessDialog, setSuccessMessage, uploadedFrom, setDataLoading, setErrorDialog, setErrorMsg, rowData, setTriggerReload, selectedRow}) => {
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploadError, setUploadError] = useState(true);
  const [fileUpload, setFileUpload] = useState('Attach Document');
  const [disableUploadBtn, setDisableUploadtBtn] = useState(false);

  useEffect(()=>{
    if (rowData?.attachment_name === null) {
      setUploadError(null);
      setUploadMessage('');
      setFileUpload('Attach Document');
    }
  }, [rowData]);

  const handleDocumentUploadChange = async (event) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const fileName = file?.name || '';
    setFileUpload(fileName);
    const FileExtension = fileName?.split('.')?.slice(-1)[0]?.toLowerCase();
    const validFileExtension = !ExtensionsSupportedToUpload.includes(FileExtension);

    if (validFileExtension) {
      event.target.value = '';
      setErrorDialog(true);
      setErrorMsg(`${FileExtension} - is a restricted extension to be used. Please review users guide for approved extentions.`);
      // setUploadError(true);
      // setUploadMessage('Invalid File');
      setFileUpload('Attach Document');
      setUploadMessage('');
      return;
    }
    if (fileSize === 0) {
      event.target.value = '';
      // setUploadError(true);
      // setUploadMessage('File should not be empty');
      setFileUpload('Attach Document');
      setUploadMessage('');
      setErrorDialog(true);
      setErrorMsg('File should not be empty');
    } else if (fileSize < 5242880) {
      setUploadError(false);
      setUploadMessage('Uploading...');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Content-disposition': 'attachment; filename=\'' + file.name + '\'',
        },
      };
      const payload = {
        workflowId: selectedRow.workFlowId,
        description: rowData.attachment_description || '',
        file: file,
      };
      event.target.value = '';
      setDisableUploadtBtn(true);
      ConfigurationServices.uploadDocument(payload, config, uploadedFrom)
          .then((res) => {
            setUploadError(false);
            setUploadMessage('Uploaded');
            setSuccessDialog(true);
            setSuccessMessage(<span><b>{fileName}</b> Uploaded Successfully</span>);
            setTimeout(()=>{
              setTriggerReload((preValue) => !preValue);
            }, [500]);
          }).catch((err) => {
            // setUploadError(true);
            // setUploadMessage('Uploading failed');
            setFileUpload('Attach Document');
            setUploadMessage('');
            setErrorDialog(true);
            setErrorMsg(err?.message);
          }).finally(()=>{
            event.target.value = '';
            setDisableUploadtBtn(false);
          });
    } else {
      event.target.value = '';
      // setUploadError(true);
      // setUploadMessage('File exceeds 5MB');
      setFileUpload('Attach Document');
      setErrorDialog(true);
      setErrorMsg('File exceeds 5MB, file size should be less than 5MB');
    }
  };

  return (
    <div style={{'display': 'flex', 'gap': '10px', 'padding': '0px 5px', 'width': '100%', 'justifyContent': 'center', 'alignItems': 'center'}}>
      {
            rowData?.attachment_name === null ?
            <Button style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'space-between', 'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis'}} disableRipple disabled={disableUploadBtn} size='small' sx={styleViewOrUploadButton} >
              <label style={{'width': `${uploadMessage == '' ? 'auto' : '50%'}`, 'cursor': 'pointer', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center'}} htmlFor={`documentUpload`}>
                <AttachFileIcon style={{'fontSize': '12px', '&:hover': {'color': `#00B1FF`}}}/>
                <span style={{'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'fontSize': '11px', '&:hover': {'color': `#00B1FF`}}} title={fileUpload}>{fileUpload}</span>
              </label>
              <input disabled={disableUploadBtn} type="file" id={`documentUpload`} onChange={handleDocumentUploadChange} style={{display: 'none'}} />
              <span className={uploadError ? 'text-danger' : 'text-success'}>{uploadMessage}</span>
            </Button> :
        <span style={{'whiteSpace': 'nowrap', 'overflow': 'hidden', 'textAlign': 'start', 'textOverflow': 'ellipsis', 'fontSize': '11px', 'color': '#76808f'}} title={rowData?.attachment_name}>{rowData?.attachment_name}</span>
      }
    </div>
  );
};

const ViewDocument = ({uploadedFrom, setDataLoading, setErrorDialog, setErrorMsg, rowData}) => {
  const [disableViewBtn, setDisableViewBtn] = useState(false);
  const getContentType = ExtensionWithContentType;
  const notSupportedExt = ExtensionNotSupportedToView;
  const downloadDocument = () => {
    setDisableViewBtn(true);
    setDataLoading(true);
    ConfigurationServices.downloadDocument(rowData.id, uploadedFrom)
        .then((res) => {
          const element = document.createElement('a');
          let contentType = '';
          getContentType.forEach((item) => {
            if (Object.keys(item)[0] === rowData.attachment_name?.split('.')?.slice(-1)[0]?.toUpperCase()) {
              contentType = Object.values(item)[0];
            }
          });
          const file = new Blob([res], {type: contentType});
          element.href = URL.createObjectURL(file);
          document.body.appendChild(element);
          element.setAttribute('target', '_blank');
          element.setAttribute('rel', 'noopener noreferrer');
          if (notSupportedExt.includes(rowData.attachment_name?.split('.')?.slice(-1)[0]?.toLowerCase())) {
            element.download = `${rowData.attachment_name}`;
          }
          element.click();
          document.body.removeChild(element);
        }).catch((err) => {
          setErrorDialog(true);
          setErrorMsg(err?.message);
        }).finally(()=>{
          setTimeout(()=>{
            setDisableViewBtn(false);
          }, [500]);
          setDataLoading(false);
        });
  };
  return <div style={{'display': 'flex', 'gap': '10px', 'justifyContent': 'center', 'alignItems': 'center'}}>
    {
      rowData.attachment_name !== null ?
      <Button disableRipple disabled={disableViewBtn} sx={styleViewOrUploadButton} onClick={downloadDocument}>
        <FiEye style={{'fontSize': '12px', 'marginRight': '5px', '&:hover': {'color': `#00B1FF`}}} />View
      </Button> : ''
    }
  </div>;
};

const DeleteDocument = ({setSuccessDialog, EditDocument, setSuccessMessage, uploadedFrom, setErrorDialog, setErrorMsg, userid, setTriggerReload, rowData}) =>{
  const [deleteRowDialog, setDeleteRowDialog] = useState(false);
  const [confirmDeleteRow, setConfirmDeleteRow] = useState(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(()=>{
    if (!confirmDeleteRow) return;
    deleteTheRow();
  }, [confirmDeleteRow]);

  const deleteTheRow = () => {
    setDisableDeleteBtn(true);
    ConfigurationServices.deleteDocument(rowData.id, uploadedFrom)
        .then((res) => {
          setSuccessDialog(true);
          setSuccessMessage(<span><b>{rowData?.attachment_name}</b> Deleted Successfully</span>);
          setTimeout(()=>{
            setTriggerReload((preValue) => !preValue);
          }, [500]);
        }).catch((err) => {
          setErrorDialog(true);
          setErrorMsg(err?.message);
        }).finally(()=>{
          setTimeout(()=>{
            setDisableDeleteBtn(false);
          }, [500]);
          setConfirmDeleteRow(false);
        });
  };
  return (
    EditDocument!==false && <div style={{'display': 'flex', 'gap': '10px', 'justifyContent': 'center', 'alignItems': 'center'}}>
      {rowData.attachment_name !== null ?
      <div>
        <Button disableRipple disabled={disableDeleteBtn || (currentRole==='Central Admin' ? false : (!(rowData.authorId === userid) || rowData.disableDeleteBtn))} sx={deleteButtonStyles} onClick={()=> setDeleteRowDialog(true)}>
          <DeleteOutlineIcon style={{'fontSize': '12px', 'marginRight': '5px', '&:hover': {'color': `red`}}} /> Delete
        </Button>
        <DialogConfirmToDelete text={<span>Are you sure you want to delete <b>{`'${rowData.attachment_name}'`} </b>?</span>} dialogOpen={deleteRowDialog} handleClose={() => setDeleteRowDialog(false)} confirmDelete={() => setConfirmDeleteRow(true)}/>
      </div> : ''
      }
    </div>
  );
};

const btnStyle={
  display: 'flex',
  border: '0.5px solid #CECECE',
  borderRadius: '4px',
  alignItems: 'center',
  gap: '5px',
  padding: '2px 6px',
  cursor: 'pointer',
};

function EditDocumentDescription({handleEditDescription, rowData, EditDocument, userid}) {
  const onCancel = () => {
    handleEditDescription(rowData.id, 'cancel');
  };

  const onSave = () => {
    handleEditDescription(rowData.id, 'save');
  };

  const onEdit = () => {
    handleEditDescription(rowData.id, 'edit');
  };

  if (!rowData.attachment_name || !EditDocument || (userid != rowData.authorId)) return;

  return (
    <>
      <Box sx={{display: 'flex', gap: '5px', fontSize: '11px', color: '#76808f'}}>
        {!rowData.isEditDescription ?
        <>
          <Box sx={{...btnStyle, width: '58px'}} style={customealltheme.palette.TableEdit}>
            <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
            <Box onClick={onEdit}>Edit</Box>
          </Box>
        </> :
        <>

          <Box
            sx={{
              ...btnStyle,
              background: '#4297d3',
              color: 'white',
              width: '58px',
              justifyContent: 'center',
            }}

          >
            <Box onClick={onSave}>Save</Box>
          </Box><Box sx={{...btnStyle, width: '58px'}}>
            <Box onClick={onCancel}>Cancel</Box>
          </Box>
        </>}
      </Box>
    </>
  );
};
const DocumentUpload = ({selectedRow, text, uploadedFrom, EditDocument}) => {
  const [showDocumentTable, setShowDocumentTable] = useState(true);
  const [triggerReload, setTriggerReload] = useState(false);
  const [triggerRef, setTriggerRef] = useState(0);
  const [getDocumentsList, setGetDocumentsList] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const userid = useSelector((state) => state?.roleBasedAccess?.userId);


  const COLUMNS = [
    {
      ...keyColumn('author', textColumn),
      title: 'Author',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('date', textColumn),
      title: 'Date',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('attachment_description', textColumn),
      title: 'Documents Description',
      disabled: ({rowData}) => {
        return rowData.isEditDescription ? false : rowData?.disableTheRow;
      },
      minWidth: 400,
    },
    {
      ...keyColumn('attachment_name', textColumn),
      title: 'Upload Documents',
      disabled: ({rowData}) => {
        return rowData?.disableTheRow;
      },
      minWidth: 200,
      maxWidth: 200,
      component: ({rowData}) => <UploadDocument setSuccessDialog={setSuccessDialog} setSuccessMessage={setSuccessMessage} uploadedFrom={uploadedFrom} setDataLoading={setDataLoading} setErrorDialog={setErrorDialog} setErrorMsg={setErrorMsg} rowData={rowData} setTriggerReload={setTriggerReload} selectedRow={selectedRow}/>,
    },
    {
      ...keyColumn('viewDocument', textColumn),
      title: 'View Documents',
      disabled: ({rowData}) => {
        return rowData?.disableTheRow || (rowData.attachment_name == null);
      },
      minWidth: 100,
      maxWidth: 100,
      component: ({rowData}) => <ViewDocument uploadedFrom={uploadedFrom} setDataLoading={setDataLoading} setErrorDialog={setErrorDialog} setErrorMsg={setErrorMsg} rowData={rowData}/>,
    },
    {
      ...keyColumn('deleteDocument', textColumn),
      title: 'Action',
      disabled: ({rowData}) => {
        return rowData?.disableTheRow || (rowData.attachment_name == null);
      },
      minWidth: EditDocument ? 300 : 100,
      maxWidth: EditDocument ? 300 : 100,
      component: ({rowData}) => <><DeleteDocument EditDocument={EditDocument} setSuccessDialog={setSuccessDialog} setSuccessMessage={setSuccessMessage} uploadedFrom={uploadedFrom} setErrorDialog={setErrorDialog} setErrorMsg={setErrorMsg} userid={userid} setTriggerReload={setTriggerReload} rowData={rowData} /><EditDocumentDescription handleEditDescription={handleEditDescription} rowData={rowData} EditDocument={EditDocument} userid={userid} /></>,
    },
  ];

  function handleEditDescription(id, type) {
    const documentsDataCopy = [...documentsData.current];
    const index = documentsDataCopy.findIndex((d) => d.id === id);

    documentsDataCopy[index].isEditDescription = type === 'edit' ? true : false;

    if (type === 'cancel') {
      documentsDataCopy[index].attachment_description = documentsDataCopy[index].original_attachment_description;
    }

    if (type === 'save') {
      const description = documentsDataCopy[index].attachment_description || '';
      ConfigurationServices.updateAttachmentDescription(id, uploadedFrom, description).then(()=> {
        setTriggerReload((preValue) => !preValue);
      }).catch(()=> {
        console.log({err});
      });
    } else {
      documentsData.current = documentsDataCopy;
      setTriggerRef(parseInt(Math.random() * 100));
    }
  }


  function getDate() {
    const d = new Date();
    const dd = d.getDate();
    const mm = d.getMonth();
    const fullYear = d.getFullYear();
    return `${dd}-${monthArr[mm]}-${fullYear}`;
  };

  const [columns, setColumns] = useState(COLUMNS);
  const documentsData = useRef([]);

  useEffect(() => {
    if (isCreator?.functionalityAccess?.hasOwnProperty('Buy_Plan:Documents')) {
      setShowDocumentTable(true);
      setGetDocumentsList(true);
    } else {
      setShowDocumentTable(false);
    }
  }, [isCreator, selectedRow]);

  useEffect(() => {
    if (!getDocumentsList) return;
    setDataLoading(true);

    ConfigurationServices.getUploadedDocumentsList(selectedRow.workFlowId, uploadedFrom)
        .then((res) => {
          if (res != undefined && typeof res !== 'string') {
            const keys = [];
            res.length > 0 && Object.keys(res[0]).map((obj) => {
              if (obj.split('_').includes('id')) {
                keys.push(obj);
              }
            });
            if (res['userMessage']) {
              documentsData.current = [];
              // setErrMsg(res['userMessage']);
            } else {
              let data = [];
              const newRow = {
                author: isCreator?.userName,
                date: getDate(),
                attachment_description: null,
                attachment_name: null,
              };
              let editFlag = false;
              if (EditDocument) {
                editFlag = true;
              }

              if (res?.length) {
                data = res.map((val) => {
                  return {
                    author: val.last_updated_by || val.created_by,
                    date: val.last_updated_on || val.created_on,
                    attachment_description: val.attachment_description,
                    original_attachment_description: val.attachment_description,
                    attachment_name: val.attachment_name,
                    id: val.id,
                    authorId: val.attached_by,
                    disableTheRow: true,
                    disableDeleteBtn: !editFlag,
                  };
                });
              }
              if (editFlag) {
                data.push(newRow);
              }
              setColumns(COLUMNS);
              documentsData.current = data;
            }
          } else {
            // setErrMsg('Error Occured due to backend connection.');
          };
        }).catch((err) => {
          console.log({err});
        }).finally(()=>{
          setDataLoading(false);
        });
  }, [getDocumentsList, triggerReload]);

  return (
        (showDocumentTable) ?
            <div style={{marginTop: '18px'}}>
              {/* <div style={{margin: '10px 0px'}}></div> */}
              <div className="">{text || 'Document Upload'}</div>
              {
                dataLoading ? <LinearIndeterminate /> : <></>
              }
              {<DataSheetGrid
                value={documentsData.current}
                onChange={(rowData) => {
                  documentsData.current = rowData;
                  setTriggerRef(triggerRef + 1);
                }}
                columns={ EditDocument!==false ? columns : columns.slice(0, columns.length-1)}
                className='savedDatasheetGridCoverageTable docDatasheet'
                disableContextMenu={true}
                gutterColumn={false}
              />}
              <AppErrorDialog open={errorDialog} handleClose={() => setErrorDialog(false)} text={errorMsg}/>
              <AppDialog open={successDialog} handleClose={() => setSuccessDialog(false)} text={successMessage}/>
              {/* <AppDialog open={uploadSuccessDialog} handleClose={() => setSuccessDialog(false)} text={uploadSucMessage}/> */}
              <div style={{margin: '10px 0px'}}></div>
            </div> :
            ''
  );
};

export default DocumentUpload;
