import {defaultMonthValues, monthArr, months, roundToTwo, titleCase} from '../../services/utils';
import {defaultObj} from './consts';

export const forecastDefaultData = (selectedRow) => {
  const obj = {};
  const y = new Date().getFullYear();
  // const m = new Date().getMonth();
  const list = [y, y + 1, y + 2];
  const firstMonth = selectedRow.executionMonthYear[1]-2;
  const secondMonth = selectedRow.executionMonthYear[1]-1;
  if (!obj[list[0]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prevWorkFlowId,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workFlowId,
      planMonth: titleCase(months[secondMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    obj[list[0]] = [a, a2];
  }
  if (!obj[list[1]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prevWorkFlowId,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workFlowId,
      planMonth: titleCase(months[secondMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    obj[list[1]] = [a, a2];
  }
  if (!obj[list[2]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prevWorkFlowId,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workFlowId,
      planMonth: titleCase(months[secondMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    obj[list[2]] = [a, a2];
  }
  return obj;
};

export const fyCalculateTotal = (selectedData={}) => {
  // console.log('fyCalculateTotal selectedData', selectedData);
  return Object.values(selectedData).map((val, key) => {
    if (!val['key']) {
      val['key'] = key + 1;
    } else val['key'] = key + 1;
    const total = Object.keys(val).reduce((accumulator, currentValue) => {
      if (months.indexOf(currentValue) >= 0) {
        return accumulator + (val[currentValue] || 0);
      } else return accumulator;
    }, 0);
    // for read only
    if (val.fy===0) {
      val.fy = total;
    }
    return val;
  });
};

export const getSortedMonthlyData = (arr, selectedRow, copy) => {
  let k = '';
  let pMonth = null;
  if (Object.keys(copy).map((a) => a).length == 2) {
    delete copy[arr[0].workflow_id];
    const va = Object.values(copy).map((a) => a);
    pMonth = va[0].executionMonthYear[1] - 1;
  } else {
    if (arr[0].workflow_id == selectedRow.workFlowId) {
      pMonth = selectedRow.executionMonthYear[1] - 2;
    } else {
      pMonth = selectedRow.executionMonthYear[1] - 1;
    }
    // pMonth = selectedRow.executionMonthYear[1] - 1
  }
  if (arr[0].month > pMonth) {
    arr[0].key = 2;
    k = 1;
  } else k = 2;
  // month, year and key value setup in constant
  const defaultObj = {
    month: pMonth,
    planMonth: titleCase(months[pMonth]),
    planYear: arr[0].planYear,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    fy: 0,
    workflow_id: selectedRow.workFlowId,
    key: k,
  };
  arr = [...arr, defaultObj];
  arr.sort((a, b) => (a.month > b.month ? 0 : -1));
  return arr;
};

export const monthNYearSetter = (obj, val) => {
  const key = val.workflow_id;
  const key1 = val.forecastYearMonth[0];
  if (!obj[key1]) {
    obj[key1] = {};
    const a = {
      ...defaultObj,
      month: val.executionMonthYear[1] - 1,
      planMonth: titleCase(months[val.executionMonthYear[1] - 1]),
      planYear: val.executionMonthYear[0],
      workflow_id: val.workflow_id,
      [months[val.forecastYearMonth[1] - 1]]: val.forecastValue,
    };
    obj[key1][key] = a;
  } else {
    if (!obj[key1][key]) {
      const a = {
        ...defaultObj,
        workflow_id: val.workflow_id,
        planMonth: titleCase(months[val.executionMonthYear[1] - 1]),
        planYear: val.executionMonthYear[0],
        [months[val.forecastYearMonth[1] - 1]]: val.forecastValue,
      };
      obj[key1][key] = a;
    } else {
      obj[key1][key] = {
        ...obj[key1][key],
        workflow_id: val.workflow_id,
        planMonth: titleCase(months[val.executionMonthYear[1] - 1]),
        planYear: val.executionMonthYear[0],
        [months[val.forecastYearMonth[1] - 1]]: val.forecastValue,
      };
    }
  }
  return obj;
};

export const rowsSetter = (obj, workflowIdsObj, selectedRow) => {
  if (Object.keys(obj).length < 3) {
    const wfIDs = Object.keys(workflowIdsObj)
        .map((val) => val)
        .sort((a, b) => (a < b ? -1 : 0));
    const y = new Date().getFullYear();
    const list = [y, y + 1, y + 2];
    let firstMonth = null;
    let secondMonth = null;
    if (wfIDs.length == 1) {
      if (workflowIdsObj[wfIDs[0]].workflow_id == selectedRow.workFlowId) {
        secondMonth = workflowIdsObj[wfIDs[0]].executionMonthYear[1] - 1;
        firstMonth = secondMonth - 1;
      } else {
        firstMonth = workflowIdsObj[wfIDs[0]].executionMonthYear[1] - 1;
        secondMonth = firstMonth + 1;
      }
    } else if (wfIDs.length == 2) {
      firstMonth = workflowIdsObj[wfIDs[0]].executionMonthYear[1] - 2;
      secondMonth = workflowIdsObj[wfIDs[1]].executionMonthYear[1] - 1;
    }
    if (!obj[list[0]]) {
      const a = {
        ...defaultObj,
        workflow_id: selectedRow.prevWorkFlowId,
        planMonth: titleCase(months[firstMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      const a2 = {
        ...defaultObj,
        workflow_id: selectedRow.workFlowId,
        planMonth: titleCase(months[secondMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      obj[list[0]] = [a, a2];
    }
    if (!obj[list[1]]) {
      const a = {
        ...defaultObj,
        workflow_id: selectedRow.prevWorkFlowId,
        planMonth: titleCase(months[firstMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      const a2 = {
        ...defaultObj,
        workflow_id: selectedRow.workFlowId,
        planMonth: titleCase(months[secondMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      obj[list[1]] = [a, a2];
    }
    if (!obj[list[2]]) {
      const a = {
        ...defaultObj,
        workflow_id: selectedRow.prevWorkFlowId,
        planMonth: titleCase(months[firstMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      const a2 = {
        ...defaultObj,
        workflow_id: selectedRow.workFlowId,
        planMonth: titleCase(months[secondMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      obj[list[2]] = [a, a2];
    }
  }
  return obj;
};

const makeDefaultValuesZero = (selectedRow, newData, index, years) =>{
  newData[index] = {
    month: 3,
    planMonth: monthArr[selectedRow.executionMonthYear[1]-2 < 0 ? 12 + selectedRow.executionMonthYear[1]-2 : selectedRow.executionMonthYear[1]-2],
    source: 'Total',
    sourceAlias: 'Total',
    planYear: years[0],
    ...defaultMonthValues,
    year: years[0],
    fy: 0,
    workflow_id: selectedRow.workFlowId,
  };
};

const averagePriceTotalCalculations = (newData, start, end, dummyObject) =>{
  newData.slice(start, end).map((pT)=>{
    months.map((m)=>{
      if (pT.sourceAlias == 'Covered Price') {
        newData[newData.length-1][m] = +newData[newData.length-1][m] * +pT[m];
        dummyObject[m] += newData[newData.length-1][m];
        newData[newData.length-1][m] = 0;
      } else {
        newData[newData.length-1][m] += +pT[m];
      }
    });
  });
};

const individualCoveredPriceTotal = (newData, start, end, makingZero, dummyObject2) =>{
  let total = 0;
  let fyTotal = 0;
  newData[makingZero]['fy'] = 0;
  months.map((m)=>{
    newData.slice(start, end).map((d)=>{
      if (d.sourceAlias=='Covered Price') {
        dummyObject2[m] *= +d[m];
      } else {
        dummyObject2[m] += +d[m];
      }
    });
  });
  newData.slice(start, makingZero).map((d)=>{
    total += +d['fy'];
  });
  months.map((m)=>{
    fyTotal += +dummyObject2[m];
    dummyObject2[m] = 0;
  });

  fyTotal = roundToTwo(+fyTotal/+total);
  newData[makingZero]['fy'] = fyTotal;
  total = 0;
  fyTotal = 0;
};

export const updatingTotal1 = (selectedRow, years, sectorRegionLength, newData) =>{
  // Making last Total column 0
  makeDefaultValuesZero(selectedRow, newData, newData.length-2, years);

  // Making last Average Covered Price column 0
  makeDefaultValuesZero(selectedRow, newData, newData.length-1, years);


  // Adding Total for all month columns but not 'Covered Price'
  newData.slice(0, newData.length-2).map((pT)=>{
    if (pT.sourceAlias !== 'Covered Price') {
      months.map((m)=>{
        newData[newData.length-2][m] += +pT[m];
      });
    }
  });

  const dummyObject = {month: 3,
    planMonth: monthArr[selectedRow.executionMonthYear[1]-2 < 0 ? 12 + selectedRow.executionMonthYear[1]-2 : selectedRow.executionMonthYear[1]-2],
    source: 'Average Covered Price',
    sourceAlias: 'Average Covered Price',
    planYear: years[0],
    ...defaultMonthValues,
    year: years[0],
    fy: 0,
    workflow_id: selectedRow.workFlowId};

  // calculating Average covered price total
  averagePriceTotalCalculations(newData, 0, sectorRegionLength+1, dummyObject);
  averagePriceTotalCalculations(newData, sectorRegionLength+1, (2*sectorRegionLength)+2, dummyObject);
  averagePriceTotalCalculations(newData, (2*sectorRegionLength)+2, (3*sectorRegionLength)+3, dummyObject);

  months.map((m)=>{
    dummyObject[m] = roundToTwo(+dummyObject[m]/+newData[newData.length-2][m]);
  });

  newData[newData.length-1] = {...dummyObject};

  newData.map((p1)=>{
    p1['fy'] = 0;
    months.map((m)=>{
      p1['fy'] += +p1[m];
    });
  });


  // Individual covered price calculation

  const dummyObject2 = {
    ...defaultMonthValues,
  };

  // supplier calculation
  individualCoveredPriceTotal(newData, 0, sectorRegionLength+1, sectorRegionLength, dummyObject2);


  // Treasury calculation
  individualCoveredPriceTotal(newData, sectorRegionLength+1, (2*sectorRegionLength)+2, (2*sectorRegionLength)+1, dummyObject2);


  // Other calculation
  individualCoveredPriceTotal(newData, (2*sectorRegionLength)+2, (3*sectorRegionLength)+3, (3*sectorRegionLength)+2, dummyObject2);

  //  Total Average covered price calculation
  let averageCoveredTotal = 0;
  months.map((m)=>{
    averageCoveredTotal += (+newData[newData.length-2][m] * +newData[newData.length-1][m]);
  });

  averageCoveredTotal = roundToTwo(+averageCoveredTotal/ +newData[newData.length-2]['fy']);

  newData[newData.length-1]['fy'] = averageCoveredTotal;

  return newData;
};
