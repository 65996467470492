/* eslint-disable no-unused-vars */
/* This Page is the  unit master page which has feautures like adding units, editing existing units and filtering the units
based on the unit.
*/

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import theme from '../../theme';
// import Loading from '/CommonConfFiles/Loading/Loading';
import {AppErrorDialog} from '../common/App-Dialog/AppDialog';
import config from '../../Utils/Config';
import ErrorMessage from '../Configurations/CommonConfFiles/ErrorMessage/ErrorMessage';
import SupplierTable from './Tables/SupplierTable';
import SupplierServices from '../../services/supplierServices';

const SupplierMaster = ({selectedRow}) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);


  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);

  const heads = ['Supplier Name', 'Supplier Description',
    'Is Active', 'Add Date', 'Last Edited By', 'Last Edited On'];

  const values = ['supplier_name', 'supplier_description', 'is_active', 'created_on', 'last_updated_by', 'last_updated_on'];

  const dateValues = [false, false, false];

  const editValues = [true, true, true];

  const [pagination, setPagination] = useState(10);

  const [addingTrigger, setAddingTrigger] = useState(false);

  const sortValues = [true, false, false, false, true];

  const [sorttype, setSorttype] = useState('asc');
  const updateSingleUnitMaster = (data) => {
    return SupplierServices.updateSupplierData({...data, buyplanId: selectedRow.buyPlanId});
  };

  const newRowUpdate = (data) => {
    return SupplierServices.postSupplierData({...data, buyplanId: selectedRow.buyPlanId});
  };

  useEffect(()=>{
    SupplierServices.getSupplierData(selectedRow.buyPlanId, selectedRow.workFlowId)
        .then((res)=>{
          setTimeout(()=>{
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id') && obj.split('_').includes('supplier')) {
                  keys.push(obj);
                }
              });
              const updatedRes = [];
              res.forEach((eachObj) => updatedRes.push({...eachObj, 'supplier_saved': true}));
              setTableFilterData(updatedRes);
              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                setRow(res);
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          }, 100);
        });
  }, [addingTrigger]);

  const sortFn = (SortValue) => {
    if (SortValue!=='') {
      if (sorttype === 'asc') {
        // sorting in descending
        setSorttype('desc');
        tableFilterData.sort((a, b) =>
          b[SortValue].localeCompare(a[SortValue]));
      } else if (sorttype === 'desc') {
        // sorting in ascending
        setSorttype('asc');
        tableFilterData.sort((a, b) =>
          a[SortValue].localeCompare(b[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    }
  };

  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Supplier_Master']) {
      setAccessControl(functionalityAccess['Buy_Plan:Supplier_Master']);
    } else {
      setAccessControl(functionalityAccess['Buy_Plan:Supplier_Master']);
    }
  }, [functionalityAccess]);
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [page, setPage] = React.useState(0);

  // console.log('Caterofy', {
  //   data: {tableFilterData},
  //   accessControl: {accessControl},
  //   setAddingTrigger: {setAddingTrigger},
  //   heads: {heads},
  //   values: {values},
  //   editValues: {editValues},
  //   addingTrigger: {addingTrigger},
  //   dateValues: {dateValues},
  //   idKey: {idKey},
  //   singleUpdate: {updateSingleUnitMaster},
  //   newRowUpdate: {newRowUpdate},
  //   setIsErrorPopupVisible: {setIsErrorPopupVisible},
  //   setErrMsg: {setErrMsg},
  //   pagination: {pagination},
  //   setPagination: {setPagination},
  //   sortValues: {sortValues},
  //   setPage: {setPage},
  //   page: {page}});
  const [currentRole, setCurrentRole] = useState(null);
  const [nextMonthCloseBuyPlanTag, setNextMonthCloseBuyPlanTag] = useState(false);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);
  useEffect(()=>{
    const todayRef = new Date();
    const currentYear = todayRef.getFullYear();
    const currentMonth = (todayRef.getMonth()) + 1;
    const currentDate = getCurrentMonthWDCount();
    let flagToUpdate = false;
    const checkYearAndMon = selectedRow.executionMonthYear;
    if (currentYear >= checkYearAndMon[0]) {
      if (currentYear == checkYearAndMon[0]) {
        if (currentMonth===checkYearAndMon[1]) {
          flagToUpdate = false;
        } else if (currentMonth>checkYearAndMon[1]) {
          if (((currentMonth - checkYearAndMon[1]) === 1)) {
            if (currentDate>=6) {
              flagToUpdate = true;
            } else {
              flagToUpdate = false;
            }
          } else {
            flagToUpdate = true;
          }
        }
      } else {
        if ((currentMonth==1 && checkYearAndMon[1]==12) ) {
          if (currentDate>=6) {
            flagToUpdate = true;
          } else {
            flagToUpdate = false;
          }
        } else {
          flagToUpdate = true;
        }
      }
    } else {
      flagToUpdate = true;
    }
    setNextMonthCloseBuyPlanTag(flagToUpdate);
  }, [selectedRow, currentRole]);

  const getCurrentMonthWDCount = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    let worked = 0;
    for (let i = 1; i <= today.getDate(); i++) {
      const testDate = new Date(year, month, i);
      const day = testDate.getDay();
      if (day > 0 && day < 6) {
        ++worked;
      }
    }
    return worked;
  };
  return (
    <Box>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>
        <Box sx={{}}>
          {<SupplierTable
            data={tableFilterData}
            selectedRow={selectedRow}
            accessControl={accessControl}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            sortValues={sortValues}
            setPage={setPage}
            page={page}
            sortFn={sortFn} currentRole={currentRole}
            sorttype={sorttype}
            nextMonthCloseBuyPlanTag={nextMonthCloseBuyPlanTag}
          />
          }
          {errMsg !=='' &&
        <ErrorMessage message='Unit Master' errmsg={errMsg}/>
          }
        </Box>
        {/* } */}
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg} />
      </Box>}
    </Box>
  );
};

export default SupplierMaster;
