/* eslint-disable camelcase */
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {generateMonthDropDownValues} from '../../../services/utils';
import {getDropDownDataAll_API} from '../../../redux/features/apiCall';
import {setBuyPlanReportFilter, setSortType} from '../../../redux/features/configurations';
import customealltheme from '../../../theme';

const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
};

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            'marginInlineEnd': '1rem',
            'width': '5px',
          },
          '*::-webkit-scrollbar-track': {
            'marginInlineEnd': '1rem',
            'background': '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            'background': '#a6a7a8',
            'borderRadius': '2px',
            'marginInlineEnd': '1rem',

          },
          '.MuiMenu-paper': {
            'maxHeight': '150px',
            'overflowY': 'auto',
            'width': '100px',
            'whiteSpace': 'nowrap',
            'textOverflow': 'ellipsis',
          },
        },
      },
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

let planMonths;

function BuyPlanReportTableList({row, setPagination, setPage, businessUnit, country, ActiveSectors, ActiveCategories, ActiveCommodities, ActiveSubCommodities}) {
  const selectedDropDownData = useSelector((state)=>state?.api?.selectedDropDownData);
  const [valueSector, setValueSector] = React.useState([]);
  const [valueRegion, setValueRegion] = React.useState([]);
  const [selectedRegionIdList, setSelectedRegionIdList] = React.useState([]);
  const [valueCategory, setValueCategory] = React.useState([]);
  const [valueBuyplanName, setvalueBuyplanName] = React.useState([]);

  const [valueCommodity, setValueCommodity] = React.useState([]);
  const [isCategorySelected, setIsCategorySelected] = React.useState();
  const [isCommoditySelected, setIsCommodiitySelected] = React.useState();
  const [selectedCommodities, setSelectedCommodities] = React.useState([]);
  const [selectedSubCommodities, setSelectedSubCommodities] = React.useState([]);
  const [valueSubCommodity, setValueSubCommodity] = React.useState([]);

  const [isSectorSelected, setIsSectorSelected] = React.useState();

  const [businessUnitFilter, setbusinessUnitfilter] = React.useState([]);
  const [buyplanNames, setBuyplanNames] = React.useState([]);
  useEffect(()=>{
    if (row.length>0) {
      const buyplanNameData = [];
      const buyPlanDetailsData = [];
      row.map((r)=>{
        if (!buyplanNameData.includes(r.buy_plan_name)) {
          buyplanNameData.push(r.buy_plan_name);
          buyPlanDetailsData.push(r);
        }
      });
      setBuyplanNames([...buyPlanDetailsData]);
    }
  }, [row]);

  const handleSubmit =()=> {
    dispatch(setBuyPlanReportFilter({
      sector: valueSector,
      country: valueRegion,
      reporting_bu: businessUnitFilter,
      category: valueCategory,
      commodity: valueCommodity,
      sub_commodity: valueSubCommodity,
      buy_plan_name: valueBuyplanName,
    }));
  };

  const apiRespDropdownData = useSelector((state)=>state?.api?.apiValueDropdownDataAll);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDropDownDataAll_API());
  }, [dispatch]);


  const handleChangeRegion = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      if (!isSectorSelected) apiRespDropdownData?.regions.map((row) => str.push(row?.region));
      else selectedRegionIdList.map((regionId) => apiRespDropdownData?.regions.filter((apiReg) => regionId === apiReg?.id).map((row) => str.push(row?.region)));
      setValueRegion(str);
    } else if (valueRegion.includes('select_all') && !value.includes('select_all')) {
      setValueRegion([]);
    } else {
      if (valueRegion.includes('select_all')) setValueRegion([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueRegion(tepVal);
    }
    // handleSubmit();
  };

  const handleChangeSector = (event) => {
    setValueRegion([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      ActiveSectors.map((row) => (
        str.push(row?.name)
      )),
      setValueSector(str);
    } else if (valueSector.includes('select_all') && !value.includes('select_all')) {
      setValueSector([]);
    } else {
      if (valueSector.includes('select_all')) setValueSector([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueSector(tepVal);
    }
    setIsSectorSelected(true);
    // handleSubmit();
  };
  const tempRegionIdList = [];
  const tempCommodities = [];
  const tempSubCommodities = [];
  useEffect(()=>{
    if (valueCategory.length === 0) {
      setIsCategorySelected(false);
    } else {
      (ActiveCommodities.length !== 0) &&
                      ActiveCommodities?.map((obj)=>
                        valueCategory.map((ele)=> {
                          if (obj.categoryName === ele) {
                            tempCommodities.push(obj?.name);
                          }
                        } ),
                      );
    }
    setSelectedCommodities([...tempCommodities]);

    if (valueCommodity.length === 0) {
      setIsCommodiitySelected(false);
    } else {
      (ActiveSubCommodities.length !== 0) &&
      ActiveSubCommodities?.map((obj) =>
        valueCommodity.map((ele)=>{
          if (obj.commodityName === ele) {
            tempSubCommodities.push(obj?.name);
          }
        }),

      );
    }
    setSelectedSubCommodities([...tempSubCommodities]);


    if (valueSector.length === 0) {
      setIsSectorSelected(false);
    } else {
      (apiRespDropdownData.length !== 0) &&
                    apiRespDropdownData?.sectorGroups.map((obj)=>
                      obj?.sectors?.map((row) =>{
                        valueSector.map((sector)=>
                          row?.regionIdList.map((id)=>{
                            if (row.name === sector) {
                              if (!tempRegionIdList.includes(id)) {
                                tempRegionIdList.push(id);
                              };
                            }
                          }));
                      } ),
                    );
    }
    setSelectedRegionIdList([...tempRegionIdList]);
  }, [valueRegion, valueSector, isSectorSelected, valueCategory, valueCommodity, isCategorySelected, isCommoditySelected]);


  if (!planMonths) {
    planMonths = generateMonthDropDownValues();
  }


  const handleChangeBU = (event) => {
    // setcategory([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      businessUnit?.map((r) => (
        str.push(r?.business_unit)
      )),
      // console.log(str);
      setbusinessUnitfilter(str);
    } else if (businessUnitFilter.includes('select_all') && !value.includes('select_all')) {
      setbusinessUnitfilter([]);
    } else {
      if (businessUnitFilter.includes('select_all')) setbusinessUnitfilter([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setbusinessUnitfilter(tepVal);
    }
  };

  const handleBuyPlanNameChange = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      row?.map((row) => str.push(row?.buy_plan_name));
      setvalueBuyplanName(str);
    } else if (valueBuyplanName.includes('select_all') && !value.includes('select_all')) {
      setvalueBuyplanName([]);
    } else {
      if (valueBuyplanName.includes('select_all')) setvalueBuyplanName([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setvalueBuyplanName(tepVal);
    }
    // handleSubmit();
  };

  const handleChangeCategory = (event) => {
    setValueCommodity([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      ActiveCategories?.map((row) => str.push(row?.category));
      setValueCategory(str);
    } else if (valueCategory.includes('select_all') && !value.includes('select_all')) {
      setValueCategory([]);
    } else {
      if (valueCategory.includes('select_all')) setValueCategory([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueCategory(tepVal);
    }
    setIsCategorySelected(true);
    // handleSubmit();
  };

  const handleChangeCommodity = (event) => {
    setValueSubCommodity([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      if (!isCategorySelected) {
        ActiveCommodities.map((row) => {
          str.push(row?.name);
        });
      } else {
        selectedCommodities.map((row) => str.push(row));
      }
      setValueCommodity(str);
    } else if (valueCommodity.includes('select_all') && !value.includes('select_all')) {
      setValueCommodity([]);
    } else {
      if (valueCommodity.includes('select_all')) setValueCommodity([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueCommodity(tepVal);
    }
    setIsCommodiitySelected(true);
    // handleSubmit();
  };

  const handleChangeSubCommodity = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      if (!isCommoditySelected) ActiveSubCommodities?.map((subCommodity)=> str.push(subCommodity?.name));
      else selectedSubCommodities.map((row) => str.push(row));
      setValueSubCommodity(str);
    } else if (valueSubCommodity.includes('select_all') && !value.includes('select_all')) {
      setValueSubCommodity([]);
    } else {
      if (valueSubCommodity.includes('select_all')) setValueSubCommodity([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueSubCommodity(tepVal);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: '100%', padding: '18px'}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
          }}
          style={customealltheme.palette.filterheading}
        >
        Buy Plan Report Mapping
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'flex-start', paddingTop: '35px', flexWrap: 'wrap'}}>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Buy Plan
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      'padding': '11.5px 14px',
                      'border': 'none',
                      '.MuiOutlinedInput-notchedOutline:hover': {border: '1px solid blue'},
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  variant="standard"
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueBuyplanName}
                  label="valueCategory"
                  onChange={handleBuyPlanNameChange}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueBuyplanName.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 5px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '13px'}}} checked={valueBuyplanName?.indexOf('select_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '13px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(buyplanNames.length !== 0) &&
                    _.orderBy(buyplanNames, [(cat) => cat?.buy_plan_name?.toLowerCase()])?.map((row) => (

                      <MenuItem sx={{
                        'padding': '3px 5px',
                      }} key={row?.buy_plan_id} value={row?.buy_plan_name}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '13px',
                            },
                          }}
                          checked={valueBuyplanName.indexOf(row?.buy_plan_name) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '13px',
                              whiteSpace: 'normal',
                            },
                          }}
                          primary={row?.buy_plan_name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Sector
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueSector}
                  onChange={handleChangeSector}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueSector.length ? selected.join(', ') :'Select All'}
                  className="newclass"
                  style={{
                    '& .MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueSector?.indexOf('select_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {
                    (ActiveSectors.length !== 0) &&
                      _.orderBy(ActiveSectors, (msec) => msec?.name?.toLowerCase())?.map((row)=>
                        (
                          <MenuItem sx={{
                            'padding': '3px 12px',
                          }}
                          key={row?.id} value={row?.name} className="this-is-new-class">
                            <Checkbox
                              sx={{
                                '.MuiSvgIcon-root': {
                                  fontSize: '14px',
                                },
                              }}
                              checked={valueSector?.indexOf(row?.name) > -1}
                            />
                            <ListItemText sx={{
                              '.MuiTypography-root': {
                                fontSize: '14px',
                              },
                            }}
                            primary={row?.name}
                            className="myClass"
                            />
                          </MenuItem>))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Region/Country
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiPaperRoot-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
                      bgcolor: 'green',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueRegion}
                  label="value"
                  onChange={handleChangeRegion}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueRegion.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueRegion?.indexOf('select_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {!isSectorSelected ? ((country.length !== 0) &&
                    _.orderBy(country, [(r) => r.region?.toLowerCase()]).map((row) =>
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={row?.region_id} value={row?.region}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueRegion.indexOf(row?.region) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={row?.region} />
                      </MenuItem>,
                    )):
                  (_.orderBy(_.intersectionWith(country, selectedRegionIdList, (a, b)=> a.region_id == b), [(r) => r.region?.toLowerCase()])?.
                      map((region, idx)=> (
                        <MenuItem sx={{
                          'padding': '3px 12px',
                        }}
                        key={idx} value={region.region} className="this-is-new-class">
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={valueRegion?.indexOf(region.region) > -1}
                          />
                          <ListItemText sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={region.region}
                          className="myClass"
                          />
                        </MenuItem>),
                      ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Category
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      'padding': '11.5px 14px',
                      'border': 'none',
                      '.MuiOutlinedInput-notchedOutline:hover': {border: '1px solid blue'},
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  variant="standard"
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueCategory}
                  label="valueCategory"
                  onChange={handleChangeCategory}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueCategory.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 5px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '13px'}}} checked={valueCategory?.indexOf('select_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '13px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(ActiveCategories.length !== 0) &&
                    _.orderBy(ActiveCategories, [(cat) => cat?.category?.toLowerCase()])?.map((row) => (

                      <MenuItem sx={{
                        'padding': '3px 5px',
                      }} key={row?.category_id} value={row?.category}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '13px',
                            },
                          }}
                          checked={valueCategory.indexOf(row?.category) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '13px',
                              whiteSpace: 'normal',
                            },
                          }}
                          primary={row?.category} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Commodity
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueCommodity}
                  label="value"
                  onChange={handleChangeCommodity}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueCommodity.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 5px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '13px'}}} checked={valueCommodity?.includes('select_all')}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '13px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {!isCategorySelected? ((ActiveCommodities.length !== 0) &&
                    _.orderBy(ActiveCommodities, [(s) => s.name?.toLowerCase()])?.map((row) => (
                      <MenuItem sx={{
                        'padding': '3px 5px',
                      }} key={row?.id} value={row?.name}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '13px',
                            },
                          }}
                          checked={valueCommodity.indexOf(row?.name) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '13px',
                              whiteSpace: 'normal',
                            },
                          }}
                          primary={row?.name} />
                      </MenuItem>
                    ))): (_.orderBy(selectedCommodities, [(c) => c?.toLowerCase()])?.map((commodity, idx)=>
                      <MenuItem sx={{
                        'padding': '3px 5px',
                      }} key={idx} value={commodity}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '13px',
                            },
                          }}
                          checked={valueCommodity.indexOf(commodity) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '13px',
                              whiteSpace: 'normal',
                            },
                          }}
                          primary={commodity} />
                      </MenuItem>,
                    ))}

                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Sub Commodity
            </Box>
            <Box sx={customealltheme.palette.filterDropdown}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueSubCommodity}
                  label="value"
                  onChange={handleChangeSubCommodity}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueSubCommodity.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 5px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '13px'}}} checked={valueSubCommodity?.includes('select_all')}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '13px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {!isCommoditySelected ? ((ActiveSubCommodities?.length !== 0) &&
                  _.orderBy(ActiveSubCommodities, [(s) => s.name?.toLowerCase()])?.map((row) => (
                    <MenuItem sx={{
                      'padding': '3px 5px',
                    }} key={row?.id} value={row?.name}>
                      <Checkbox
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '13px',
                          },
                        }}
                        checked={valueSubCommodity.indexOf(row?.name) > -1}
                      />
                      <ListItemText
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '13px',
                            whiteSpace: 'normal',
                          },
                        }}
                        primary={row?.name} />
                    </MenuItem>
                  ))


                    ):
                    (_.orderBy(selectedSubCommodities, [(c) => c?.toLowerCase()])?.map((subcommodity, idx)=>
                      <MenuItem sx={{
                        'padding': '3px 5px',
                      }} key={idx} value={subcommodity}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '13px',
                            },
                          }}
                          checked={valueSubCommodity.indexOf(subcommodity) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '13px',
                              whiteSpace: 'normal',
                            },
                          }}
                          primary={subcommodity} />
                      </MenuItem>,
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Business Unit
            </Box>
            <Box
              sx={customealltheme.palette.filterDropdown}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiPaperRoot-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
                      bgcolor: 'green',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={businessUnitFilter}
                  onChange={handleChangeBU}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => {
                    let renderString='Select All';
                    if (selected && selected.length>0) {
                      renderString = businessUnit.reduce((accumulated, businessUnit)=>{
                        if (selected.includes(businessUnit.business_unit)) {
                          return accumulated ==='' ? businessUnit.business_unit : accumulated+','+businessUnit.business_unit;
                        }
                        return accumulated;
                      }, '');
                    }
                    return renderString;
                  }}
                  className="newclass"
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={businessUnitFilter?.indexOf('select_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {businessUnit.length > 0 &&
                      businessUnit
                          .sort((a, b) => a.business_unit.localeCompare(b.business_unit))
                          .map((businessUnit, index) => (
                            <MenuItem
                              sx={{
                                'padding': '3px 12px',
                              }}
                              key={index}
                              value={businessUnit?.business_unit}
                              className="this-is-new-class"
                            >
                              <Checkbox
                                sx={{
                                  '.MuiSvgIcon-root': {
                                    fontSize: '14px',
                                  },
                                }}
                                checked={businessUnitFilter?.indexOf(businessUnit?.business_unit) > -1}
                              />
                              <ListItemText sx={{
                                '.MuiTypography-root': {
                                  fontSize: '13px',
                                  whiteSpace: 'normal',
                                },
                              }}
                              primary= {businessUnit?.business_unit}
                              className="myClass"
                              />

                            </MenuItem>
                          ))}
                </Select>
              </FormControl>
            </Box>
          </Box>


          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} component="span">

            </Box>
            <Box style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
              <Box sx={{minWidth: 130, marginTop: '28px'}}>
                <Button
                  onClick={handleSubmit}
                  sx={{'backgroundColor': '#4297d3',
                    'color': '#ffffff',
                    'textTransform': 'none',
                    'minWidth': '0',
                    'padding': '8px 35px',
                    '&:hover': {
                      backgroundColor: '#4297d3',
                    }}} style={customealltheme.palette.filterSubmit}>Submit</Button>
              </Box>
              {/* <Box sx={{minWidth: 130, font: 'font: normal normal 600 12px/16px Segoe UI', marginTop: '22px', display: 'flex', gap: '2px', alignItems: 'center', cursor: 'pointer'}} onClick={handleSideBar}>
                <Box sx={{color: '#0073E4'}}><FilterListIcon style={{fontSize: '28px', fontWeight: '600'}}/></Box>
                <Box sx={{color: '#004F9A', font: 'normal normal 600 12px/16px Segoe UI', fontSize: '15px', fontWeight: '600', transform: 'translateY(-2px)'}}>More Filters</Box>
              </Box> */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: {
              display: 'inline-block',
              marginRight: '2px',
              font: 'normal normal 600 13px/15px \'Segoe UI\'',
              color: '#3174b6',
              cursor: 'pointer',
            },
            marginTop: '30px',
          }}
          style={customealltheme.palette.resetbutton}
        >
          <p onClick={()=>{
            const tempObj = {...selectedDropDownData};
            Object.keys(tempObj).map((el)=>tempObj[el]=[]);
            dispatch(setBuyPlanReportFilter([]));
            setValueRegion([]);
            setValueSector([]);
            setbusinessUnitfilter([]);
            dispatch(setSortType(''));
            setValueCategory([]);
            setvalueBuyplanName([]);
            setValueCommodity([]);
            setValueSubCommodity([]);
            setPagination(10);
            setPage(0);
          }}>Reset Filters</p>
        </Box>
      </Box>
    </ThemeProvider>

  );
}

export default BuyPlanReportTableList;
