import styled from '@emotion/styled';
import {Box, FormControl, InputBase, ListItemText, MenuItem, Select, TableCell, TableRow, createTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import config from '../../../Utils/Config';
import customealltheme from '../../../theme';
import {AppDialog} from '../../common/App-Dialog/AppDialog';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});
const Edit = config.AccessLevel.Edit;
const CommonConfSingleRow = ({row, allSectors, setAddingTrigger, addingTrigger, accessControl,
  singleUpdate, businessUnit, country,
  setErrMsg, setIsErrorPopupVisible, editModeDisableTrigger, page, allCommodities, allSubCommodities}) => {
  const [editMode, setEditMode] = useState(false);
  const [sectorIdValue, setSectorIdValue] = React.useState([]);
  const [regionIdValue, setregionIdValue] = React.useState([]);
  const [businessUnitIdValue, setBusinessUnitIdValueValue] = useState([]);
  const [showSuccessDialog, setshowSuccessDialog] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const [commodityIdValue, setCommodityIdValue] = useState([]);
  const [subCommodityIdValue, setSubCommodityIdValue] = useState([]);

  useEffect(()=>{
    if (editMode===true) {
      setEditMode(false);
    }
  }, [editModeDisableTrigger]);

  const editTheRow = () =>{
    resetDateOnCancel();
    setEditMode(true);
  };
  useEffect(()=>{
    setEditMode(false);
  }, [page]);


  const resetDateOnCancel = () => {
    setEditMode(false);
    setSectorIdValue([]);
    setregionIdValue([]);
    setCommodityIdValue([]);
    setSubCommodityIdValue([]);
    setBusinessUnitIdValueValue([]);
    settingValues();
  };

  const settingValues = () =>{
    for (let i=0; i< row.length; i++) {
      setSectorIdValue((prev)=>[...prev, row[i]['reporting_sector_id']==null? '' : row[i]['reporting_sector_id']]);
      setregionIdValue((prev)=>[...prev, row[i]['reporting_region_id']==null? '' : row[i]['reporting_region_id']]);
    }
    setCommodityIdValue([row[0]['reporting_commodity_id']===null? '' : row[0]['reporting_commodity_id']]);
    setSubCommodityIdValue([row[0]['reporting_sub_commodity_id']===null? '' : row[0]['reporting_sub_commodity_id']]);
    setBusinessUnitIdValueValue([row[0]['reporting_bu_id']==null? '' : row[0]['reporting_bu_id']]);
  };

  useEffect(()=>{
    if (row.length>0) {
      settingValues();
    }
  }, [row]);

  const handleBu = (e, i) =>{
    const businessUnits = [...businessUnitIdValue];
    businessUnits.splice(0, 1, e.target.value);
    setBusinessUnitIdValueValue(businessUnits);
  };

  const handleSector = (e, i) =>{
    const sectorsReport = [...sectorIdValue];
    sectorsReport.splice(i, 1, e.target.value);
    setSectorIdValue(sectorsReport);
  };

  const handleRegion = (e, i) =>{
    const regionReport = [...regionIdValue];
    regionReport.splice(i, 1, e.target.value);
    setregionIdValue(regionReport);
  };

  const handleReportingCommodityChange = (e, i) =>{
    const commodity = [...commodityIdValue];
    commodity.splice(0, 1, e.target.value);
    setCommodityIdValue(commodity);
  };

  const handleReportingSubCommodityChange = (e, i) =>{
    const subCommodity = [...subCommodityIdValue];
    subCommodity.splice(0, 1, e.target.value);
    setSubCommodityIdValue(subCommodity);
  };


  useEffect(()=>{
    let count = 0;
    if (businessUnitIdValue[0]==0) {
      count=count+1;
    }
    sectorIdValue.map((b)=>{
      if (b==0) {
        count=count+1;
      }
    });
    regionIdValue.map((b)=>{
      if (b==0) {
        count=count+1;
      }
    });
    if (commodityIdValue[0]==0) {
      count=count+1;
    }
    if (subCommodityIdValue[0]==0) {
      count=count+1;
    }
    if (count>0) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [businessUnitIdValue, regionIdValue, sectorIdValue, commodityIdValue, subCommodityIdValue]);
  const saveTheRow = () =>{
    setEditMode(false);

    const sendData = [];
    row.map((r, index)=>{
      const sendingObject = {
        'buyPlanReportingId': r.reporting_id,
        'buyPlanId': r.buy_plan_id,
        'buyPlanMappingId': r.buy_plan_mapping_id,
        'reportingSectorId': sectorIdValue[index]==''? null : sectorIdValue[index],
        'reportingRegionId': regionIdValue[index]==''? null : regionIdValue[index],
        'reportingCommodityId': commodityIdValue[0] === '' ? null : commodityIdValue[0],
        'reportingSubCommodityId': subCommodityIdValue[0] === '' ? null : subCommodityIdValue[0],
        'reportingBUId': businessUnitIdValue[0]==''? null : businessUnitIdValue[0],
      };
      sendData.push(sendingObject);
    });
    singleUpdate(sendData).then((res)=>{
      setshowSuccessDialog(true);
      setAddingTrigger(!addingTrigger);
    }).catch((err)=>{
      setIsErrorPopupVisible(true);
      setErrMsg(err?.userMessage || 'Error has been occured');
      setAddingTrigger(!addingTrigger);
    });
  };
  return (
    <>
      {row.length>0 && row.map((r, index)=>(
        <StyledTableRow key={index}>
          <StyledTableCell component="th" scope="row">
            {index==0 && r['buy_plan_id']}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {index==0 && r['buy_plan_name']}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {r['sector']}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {r['country']}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {index==0 && r['category']}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {index==0 && r['commodity']}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {index==0 && r['sub_commodity']}
          </StyledTableCell>

          <StyledTableCell component="th" scope="row">
            { editMode===true ?<FormControl style={{width: '100px'}}>
              <Select
                labelId="demo-multiple-checkbox-label"
                sx = {{
                  '.MuiOutlinedInput-input': {
                    padding: '11.5px 14px',
                  },
                  '.MuiSelect-select': {
                    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                    color: 'rgba(85, 87, 89, 1)',
                  },
                }}
                value={sectorIdValue[index]}
                onChange={(e)=>{
                  handleSector(e, index);
                }}
                label="Select"
                input={<CustomInput label="Tag" />}
                displayEmpty
                className="newclass"
              >
                <MenuItem value="" sx={{'padding': '1px 5px'}}>
                  <span style={{fontSize: '13px', marginLeft: '8px', color: 'rgba(59, 70, 85, 1)'}}>Select</span>
                </MenuItem>
                {allSectors.length !== 0 &&
                              _.orderBy(allSectors, [(s) => s.name?.toLowerCase()])?.map((r, ind)=>(
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.id}
                                  value={r?.id}
                                  className="this-is-new-class"
                                >
                                  <ListItemText sx={{
                                    '.MuiTypography-root': {
                                      fontSize: '13px',
                                      whiteSpace: 'normal', color: 'rgba(59, 70, 85, 1)',
                                    },
                                  }}
                                  primary={r.name}
                                  className="myClass"
                                  />
                                </MenuItem>
                              ))

                }
              </Select>
            </FormControl> : r.reporting_sector}
          </StyledTableCell>

          <StyledTableCell component="th" scope="row">
            {editMode==true? <FormControl style={{width: '100px'}}>
              <Select
                labelId="demo-multiple-checkbox-label"
                sx = {{
                  '.MuiOutlinedInput-input': {
                    padding: '11.5px 14px',
                  },
                  '.MuiSelect-select': {
                    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                    color: 'rgba(85, 87, 89, 1)',
                  },
                }}
                value={regionIdValue[index]}
                onChange={(e)=>{
                  handleRegion(e, index);
                }}
                label="Select"
                input={<CustomInput label="Tag" />}
                displayEmpty
                className="newclass"
              >
                <MenuItem value="" sx={{'padding': '1px 5px'}}>
                  <span style={{fontSize: '13px', marginLeft: '8px', color: 'rgba(59, 70, 85, 1)'}}>Select</span>
                </MenuItem>
                {(country?.length !==0 ) &&
                              _.orderBy(country, [(s) => s.region?.toLowerCase()])?.map((r, ind)=>(
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.region_id || ''}
                                  value={r?.region_id || ''}
                                  className="this-is-new-class"
                                >
                                  <ListItemText sx={{
                                    '.MuiTypography-root': {
                                      fontSize: '13px',
                                      whiteSpace: 'normal', color: 'rgba(59, 70, 85, 1)',
                                    },
                                  }}
                                  primary={r.region}
                                  className="myClass"
                                  />
                                </MenuItem>

                              ))}
              </Select>
            </FormControl>: r.reporting_region}
          </StyledTableCell>

          <StyledTableCell component="th" scope="row">
            {index === 0 && (editMode===true? <FormControl style={{width: '100px'}}>
              <Select
                labelId="demo-multiple-checkbox-label"
                sx = {{
                  '.MuiOutlinedInput-input': {
                    padding: '11.5px 14px',
                  },
                  '.MuiSelect-select': {
                    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                    color: 'rgba(85, 87, 89, 1)',
                  },
                }}
                value={commodityIdValue[index]!==0 && commodityIdValue[index]}
                onChange={(e)=>{
                  handleReportingCommodityChange(e, index);
                }}
                label="Select"
                input={<CustomInput label="Tag" />}
                displayEmpty
                className="newclass"
              >
                <MenuItem value="" sx={{'padding': '1px 5px'}}>
                  <span style={{fontSize: '13px', marginLeft: '8px', color: 'rgba(59, 70, 85, 1)'}}>Select</span>
                </MenuItem>
                {(allCommodities?.length !==0 ) &&
                              _.orderBy(allCommodities, [(s) => s.name?.toLowerCase()])?.map((r, ind)=>(
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.id || ''}
                                  value={r?.id || ''}
                                  className="this-is-new-class"
                                >
                                  <ListItemText sx={{
                                    '.MuiTypography-root': {
                                      fontSize: '13px',
                                      whiteSpace: 'normal', color: 'rgba(59, 70, 85, 1)',
                                    },
                                  }}
                                  primary={r.name}
                                  className="myClass"
                                  />
                                </MenuItem>
                              ))}
              </Select>
            </FormControl>: r.reporting_commodity)}
          </StyledTableCell>
          <StyledTableCell component='th'scope='row'>
            {index === 0 && (editMode===true? <FormControl style={{width: '100px'}}>
              <Select
                labelId="demo-multiple-checkbox-label"
                sx = {{
                  '.MuiOutlinedInput-input': {
                    padding: '11.5px 14px',
                  },
                  '.MuiSelect-select': {
                    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                    color: 'rgba(85, 87, 89, 1)',
                  },
                }}
                value={subCommodityIdValue[index]!==0 && subCommodityIdValue[index]}
                onChange={(e)=>{
                  handleReportingSubCommodityChange(e, index);
                }}
                label="Select"
                input={<CustomInput label="Tag" />}
                displayEmpty
                className="newclass"
              >
                <MenuItem value="" sx={{'padding': '1px 5px'}}>
                  <span style={{fontSize: '13px', marginLeft: '8px', color: 'rgba(59, 70, 85, 1)'}}>Select</span>
                </MenuItem>
                {(allSubCommodities?.length !==0 ) &&
                              _.orderBy(allSubCommodities, [(s) => s.name?.toLowerCase()])?.map((r, ind)=>(
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.id || ''}
                                  value={r?.id || ''}
                                  className="this-is-new-class"
                                >
                                  <ListItemText sx={{
                                    '.MuiTypography-root': {
                                      fontSize: '13px',
                                      whiteSpace: 'normal', color: 'rgba(59, 70, 85, 1)',
                                    },
                                  }}
                                  primary={r.name}
                                  className="myClass"
                                  />
                                </MenuItem>

                              ))}
              </Select>
            </FormControl>: r.reporting_subCommodity)}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {index==0 && (editMode===true ?<FormControl style={{width: '100px'}}>
              <Select
                labelId="demo-multiple-checkbox-label"
                sx = {{
                  '.MuiOutlinedInput-input': {
                    padding: '11.5px 14px',
                  },
                  '.MuiSelect-select': {
                    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                    color: 'rgba(85, 87, 89, 1)',
                  },
                }}
                value={businessUnitIdValue[index]!==0 && businessUnitIdValue[index]}
                onChange={(e)=>{
                  handleBu(e, index);
                }}
                label="Select"
                input={<CustomInput label="Tag" />}
                displayEmpty
                className="newclass"
              >
                <MenuItem value="" sx={{'padding': '1px 5px'}}>
                  <span style={{fontSize: '13px', marginLeft: '8px', color: 'rgba(59, 70, 85, 1)'}}>Select</span>
                </MenuItem>
                {businessUnit.length !== 0 &&
                              _.orderBy(businessUnit, [(bu) => bu?.business_unit?.toLowerCase()])?.map((obj) => (
                                <MenuItem
                                  sx={{
                                    'padding': '3px 12px',
                                  }}
                                  key={obj?.bu_id}
                                  value={obj?.bu_id}
                                  className="this-is-new-class"
                                >
                                  <ListItemText sx={{
                                    '.MuiTypography-root': {
                                      fontSize: '13px',
                                      whiteSpace: 'normal', color: 'rgba(59, 70, 85, 1)',
                                    },
                                  }}
                                  primary={obj.business_unit}
                                  className="myClass"
                                  />
                                </MenuItem>

                              ))}
              </Select>
            </FormControl> : r.reporting_bu)}
          </StyledTableCell>


          <StyledTableCell component="th" scope="row">
            {index==0 && r['last_edited_by']}
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {r['last_edited']}
          </StyledTableCell>

          {accessControl === Edit && <StyledTableCell align="left">
            {index==0 && (editMode === false ? <Box sx={{border: '1px solid gray',
              display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
              width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
              cursor: 'pointer'}} onClick={editTheRow} style={customealltheme.palette.TableEdit}>
              <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
              <Box>Edit</Box>
            </Box> :
        <Box sx={{display: 'flex', gap: '5px'}}>
          <Box sx={{border: '1px solid gray',
            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px', opacity: disableSave?0.5:1,
            cursor: disableSave? 'not-allowed' : 'pointer', background: '#4297d3', color: 'white',
            justifyContent: 'center'}} onClick={disableSave?undefined:saveTheRow}>
            <Box>Save</Box>
          </Box>
          <Box sx={{border: '1px solid gray',
            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
            cursor: 'pointer'}} onClick={resetDateOnCancel}>
            <Box>Cancel</Box>
          </Box>
        </Box>)
            }

          </StyledTableCell>}
        </StyledTableRow>
      ))}

      <AppDialog open={showSuccessDialog} handleClose={()=>setshowSuccessDialog(false)} text='Report Mapping is Saved'/>

    </>
  );
};

export default CommonConfSingleRow;
