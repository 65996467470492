import {createSlice} from '@reduxjs/toolkit';

export const buyPlanDataSlice = createSlice({
  name: 'buyPlanData',
  initialState: {
    value: [],
    negitiveValueReasons: [],
  },
  reducers: {
    buyPlanData: (state, action) => {
      state.value = action.payload;
    },
    setnegitiveValueReasons: (state, action) => {
      state.negitiveValueReasons = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {buyPlanData, setnegitiveValueReasons} = buyPlanDataSlice.actions;

export default buyPlanDataSlice.reducer;
