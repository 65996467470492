/* eslint-disable no-unused-vars */
/* This Page is the  Currency conversion page which has feautures like adding Currencys, editing existing Currencys and filtering the Currencys
based on the Currency.
*/

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import Loading from '../CommonConfFiles/Loading/Loading';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import CommodityTable from './CommonCommodity/CommodityTable';
import CommodityDisplay from './CommodityDisplay';
import config from '../../../Utils/Config';
import _ from 'lodash';

const Commodity = (props) => {
  const [row, setRow] = useState([]);
  const [ActiveCategories, setActiveCategories] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);


  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);

  const heads = ['Category', 'Commodity', 'Is Active', 'Last Edited By', 'Last Edited On'];

  const values = ['category', 'commodity', 'is_active', 'updated_by', 'updated_on'];

  const dateValues = [false, false, false, false, false, false];

  const editValues = [true, false, true, false, true, false];

  const [pagination, setPagination] = useState(10);

  // const [loading, setLoading] = useState(false);
  const [addingTrigger, setAddingTrigger] = useState(false);
  const [page, setPage] = React.useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [filterResetClicked, setFiltersResetClicked] = useState(false);
  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedCommodityFilterValue);

  const sortValues = [true, true, false, true, true, false];

  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const updateSingleUnitMaster = ConfigurationServices.
      updateCommodityData;
  const newRowUpdate = ConfigurationServices.postCommodityData;


  useEffect(()=>{
    ConfigurationServices.getCommodityData()
        .then((res)=>{
          if (res !=undefined && typeof res !== 'string') {
            const keys = [];
            res.length>0 && Object.keys(res[0]).map((obj) => {
              if (obj.split('_').includes('id')) {
                keys.push(obj);
              }
            });

            setIdKey([...keys]);
            if (res['userMessage']) {
              setRow([]);
              setErrMsg(res['userMessage']);
            } else {
              const activeComm = [];
              const inActiveComm = [];
              res.commodityGroups.forEach((item) => {
                item.commodities.forEach( (subItem) =>{
                  (subItem.is_active ? activeComm : inActiveComm).push({
                    'category': item.name,
                    'commodity': subItem.name,
                    'is_active': subItem.is_active,
                    'updated_on': subItem.updated_on,
                    'updated_by': subItem.updated_by,
                    'category_id': item.id,
                    'is_editable': subItem.is_editable,
                    'commodityId': subItem.id,
                  });
                });
              });

              activeComm.sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on));
              inActiveComm.sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on));

              setModifiedData([...activeComm, ...inActiveComm]);
              setRow([...activeComm, ...inActiveComm]);
            }
          } else {
            setRow([]);
            setErrMsg('Error Occured due to backend connection.');
          };
        });
  }, [addingTrigger]);

  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='category_all' ||
     filteredUnitValue[0]==='commodity_all' ||
     (filteredUnitValue?.category.length ===0 && filteredUnitValue?.commodity.length ===0 ) ) {
      // setModifiedData([...row]);
      setModifiedData([...row]);
    } else {
      if (filteredUnitValue['category'].length>0 && filteredUnitValue['commodity'].length>0 ) {
        const original =[...row];
        const dummy = original.filter( (item) => filteredUnitValue['category'].includes(item.category) && filteredUnitValue['commodity'].includes(item.commodity));
        setModifiedData([...dummy]);
      } else
      if (filteredUnitValue['category'].length>0) {
        setModifiedData(row.filter((r)=>{
          return filteredUnitValue['category'].includes(r.category);
        }));
      } else if (filteredUnitValue['commodity'].length > 0) {
        setModifiedData(row.filter((r)=>{
          return filteredUnitValue['commodity'].includes(r.commodity);
        }));
      } else {
        setModifiedData([...row]);
      }
    }
    setPage(0);
  }, [filteredUnitValue, row, addingTrigger]);

  // useEffect(()=>{
  //   if (SortValue!=='') {
  //     if (sorttype === 'asc') {
  //       modifiedData.sort((a, b) =>
  //         a[SortValue]?.localeCompare(b[SortValue]));
  //     } if (sorttype === 'desc') {
  //       modifiedData.sort((a, b) =>
  //         b[SortValue]?.localeCompare(a[SortValue]));
  //     }
  //     setModifiedData([...modifiedData]);
  //   }
  // }, [SortValue, sorttype]);

  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='updated_on') {
      if (sorttype === 'asc') {
        modifiedData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        modifiedData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setModifiedData([...modifiedData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(modifiedData, (item) => new Date(item.updated_on));
        setModifiedData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(modifiedData, (item) => new Date(item.updated_on));
        setModifiedData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  useEffect(()=>{
    ConfigurationServices.getCategoryData()
        .then((res)=>{
          const activeCategories = [];
          res.map((r)=>{
            r.is_active === true && activeCategories.push(r);
          });
          setActiveCategories([...activeCategories]);
        });
  }, []);


  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Commodity_Master']) {
        setAccessControl(functionalityAccess['Configuration:Commodity_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>
        <CommodityDisplay row={_.orderBy(row, [(r) => r?.name?.toLowerCase()])} setSubmitClicked={setSubmitClicked} setFiltersResetClicked={setFiltersResetClicked}/>
        {/* {loading ? <Loading /> : */}
        <Box sx={{}}>
          <CommodityTable
            modifiedData={modifiedData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            addingTrigger={addingTrigger}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            ActiveCategories={ActiveCategories}
            sortValues={sortValues}
            accessControl={accessControl}
            setPage={setPage}
            page={page}
            filterResetClicked={filterResetClicked}
            submitClicked={submitClicked}
          />
          {(errMsg !=='' || modifiedData.length == 0) &&
        <ErrorMessage message='Commodity' errmsg={errMsg}/>
          }
        </Box>
        {/* } */}
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
    </Box>
  );
};

export default Commodity;
