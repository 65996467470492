/* eslint-disable max-len */
import {Box, Button, Typography, Select, MenuItem, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledFilterBox = styled(Box)(({}) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

const StyledFilterPeriodButton = styled(Button)(({isselected, theme}) => ({
  'color': isselected ==='true' ? '#fff' : theme.palette.customText.dark,
  'backgroundColor': isselected ==='true' ? '#4297D3' : 'none',
  'padding': 0,
  'borderRadius': 2,
  'textTransform': 'none',
  'minWidth': '2rem',
  'width': '2rem',
  'height': '1.1rem',
  'fontSize': '12px',
  'lineHeight': '1rem',
  'alignItems': 'end',
  'margin': '2px',
  'fontFamily': 'Segoe UI',
  ':hover': {
    backgroundColor:
    isselected ==='true' ? theme.palette.customBackground.lightBlue : 'none',
    color: isselected ==='true' ? '#fff' : theme.palette.customText.dark,
  },
}));

const StyledBoxOnPeriodButtons = styled(Box)(({theme})=>({
  borderRadius: '3px',
  border: `1px solid ${theme.palette.customBorder.darkGrey}`,
}));

const StyledSubFilterContainer = styled(Box)({
  marginBottom: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '25px',
});

const StyledBoxFilter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
});

const StyledFilter = styled(Box)(({theme})=>({
  'flexWrap': 'wrap',
  'display': 'flex',
  'alignItems': 'start',
  'marginBottom': '10px',
  'flexDirection': 'column',
  'borderBottom': `1px solid ${theme.palette.customText.darkGrey}`,
}));

const StyledTargetBody = styled(Box)(({theme}) => ({
  boxShadow: `0px 3px 6px ${theme.palette.customShadowColor.grey}`,
  borderRadius: '0.825rem',
  padding: '1.5rem',
  backgroundColor: theme.palette.customBackground.white,
}));

const StyledResetFilter = styled(Typography)(({theme}) => ({
  color: theme.palette.customText.textReset,
  cursor: 'pointer',
  marginTop: '1.5rem',
  fontWeight: 600,
  fontSize: '12px',
}));

const StyledToggleFilter = styled(Typography)(({theme}) => ({
  cursor: 'pointer',
  fontWeight: 600,
  color: theme.palette.customText.dark,
}));

const StyledToogleBox = styled(Box)(({theme}) => ({
  borderBottom: `1px solid ${theme.palette.customColor.horizontalLineColor}`,
  marginBottom: '1rem',
}));

const StyledSubmitButton = styled(Button)(({theme}) => ({
  'backgroundColor': theme.palette.customBackground.activeButtonBackground,
  'color': theme.palette.customText.white,
  'textTransform': 'none',
  'minWidth': '0',
  'padding': '8px 35px',
  '&:hover': {
    backgroundColor: theme.palette.customBackground.activeButtonBackground,
  },
  'fontFamily': 'Segoe UI',
  'borderRadius': '5px',
  'fontSize': '12px',
}));

const StyledDownloadButton = styled(Button)(({theme}) => {
  return ({
    'backgroundColor': theme.palette.customBackground.activeButtonBackground,
    'color': theme.palette.customText.white,
    'textTransform': 'none',
    'minWidth': '0',
    'padding': '5px 20px',
    '&:hover': {
      backgroundColor: theme.palette.customBackground.activeButtonBackground,
    },
    'fontFamily': 'Segoe UI',
    'borderRadius': '5px',
    'fontSize': '12px',
    // 'float': 'right',
    'margin': '0px 10px 15px 10px',
  });
});

const StyledTableBox = styled(Box)(({theme}) => ({
  marginBottom: '2.5rem',
}));

const StyledEditOrSaveButton = styled(Button)(({theme}) => ({
  'backgroundColor': theme.palette.customBackground.lightBlue,
  'color': theme.palette.customText.white,
  '&:hover': {
    backgroundColor: theme.palette.customBackground.lightBlue,
  },
  'textTransform': 'none',
  'marginRight': '1rem',
  'fontSize': '13px',
  'height': '35px',
}));

const StyledSubFilterContainerLeft = styled(Box)(() => ({}));

const StyledSubFilterContainerRight = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledPlayBookButton = styled(Button)((props) => ({
  'backgroundColor': props.isplaybook ==='true' ?
  props.theme.palette.customBackground.activeButtonBackground :
  props.theme.palette.customText.white,
  'color': props.isplaybook ==='true' ?
  props.theme.palette.customText.white :
  props.theme.palette.customText.lightBlackText,
  'borderColor': props.theme.palette.customBackground.activeButtonBackground,
  'textTransform': 'none',
  'borderRadius': '4px 0 0 4px',
  'fontFamily': 'Segoe UI',
  'fontSize': '13px',
  'padding': '9px 10px',
  'height': '35px',
  'boxShadow:': 'unset',
  '&:hover': {
    backgroundColor: props.isplaybook ==='true' ?
    props.theme.palette.customBackground.activeButtonBackground :
    props.theme.palette.customText.white,
    borderColor: props.theme.palette.customBackground.activeButtonBackground,
  },
}));

const StyledBuyPlanButton = styled(Button)((props) => ({
  'backgroundColor': props.isplaybook !=='true' ?
  props.theme.palette.customBackground.activeButtonBackground :
  props.theme.palette.customText.white,
  'color': props.isplaybook !=='true' ?
  props.theme.palette.customText.white :
  props.theme.palette.customText.lightBlackText,
  'borderColor': props.theme.palette.customBackground.activeButtonBackground,
  'textTransform': 'none',
  'borderRadius': '0 4px 4px 0',
  'fontFamily': 'Segoe UI',
  'fontSize': '13px',
  'padding': '9px 10px',
  'height': '35px',
  'boxShadow:': 'unset',
  '&:hover': {
    backgroundColor: props.isplaybook !=='true' ?
    props.theme.palette.customBackground.activeButtonBackground :
    props.theme.palette.customText.white,
    borderColor: props.theme.palette.customBackground.activeButtonBackground,
  },
}));


const StyledCircularProgress = styled(Box)(() => ({
  justifyContent: 'center',
}));

const StyledBoxSelectFilter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '1.25rem',
  marginTop: '10px',
});

const StyledBoxLabel = styled(Box)(({theme})=>({
  marginBottom: '15px',
  color: theme.palette.customText.dark,
  fontWeight: 600,
  fontSize: '12px',
}));

const StyledSelect = styled(Select)(({disabled, theme})=>({
  'minWidth': '144px',
  'maxWidth': '144px',
  '& .MuiInputBase-input': {
    'padding': '7.5px 26px 7.5px 12px',
    'border': `1px solid ${theme.palette.customColor.horizontalLineColor}`,
    'borderRadius': 4,
    'color': theme.palette.customText.lightGrey,
    'fontSize': '13px',
    'fontFamily': 'Segoe UI',
    '&:focus': {
      borderRadius: 4,
    },
    ...(disabled && {
      'background': theme.palette.customBackground.disabledBackground,
      'color': theme.palette.customColor.disabledColor,
    }),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
}));

const StyledSelectBuyPlan = styled(Select)(({disabled, theme})=>({
  'minWidth': '200px',
  // 'maxWidth': '200px',
  '& .MuiInputBase-input': {
    'padding': '7.5px 26px 7.5px 12px',
    'border': `1px solid ${theme.palette.customColor.horizontalLineColor}`,
    'borderRadius': 4,
    'color': theme.palette.customText.lightGrey,
    'fontSize': '13px',
    'fontFamily': 'Segoe UI',
    '&:focus': {
      borderRadius: 4,
    },
    ...(disabled && {
      'background': theme.palette.customBackground.disabledBackground,
      'color': theme.palette.customColor.disabledColor,
    }),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
}));

const StyledMenuItem = styled(MenuItem)(({theme})=>({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '144px',
  overflow: 'hidden',
  fontSize: '11px',
  padding: '5px',
  fontFamily: 'Segoe UI',
}));

const StyledMenuItemBuyPlan = styled(MenuItem)(({theme})=>({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: '200px',
  overflow: 'hidden',
  fontSize: '11px',
  padding: '5px',
  fontFamily: 'Segoe UI',
  whiteSpace: 'break-spaces',
}));

const StyledTableField = styled(TextField)(({backgroundColor, theme})=>({
  '& div': {
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
  },
  '& input': {
    'textOverflow': 'ellipsis',
    'whiteSpace': 'nowrap',
    'fontSize': '12px',
    'padding': 0,
    'fontFamily': 'Segoe UI',
    'width': '58px',
    'height': '22px',
    'textAlign': 'center',
    'border': 'none',
    'color': theme.palette.customText.dark,
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
    'backgroundColor': backgroundColor,
  },
  '& fieldset': {
    outline: 'none',
    border: 'none',
  },

}));


const StyledErrorBox = styled(Box)(({theme})=>({
  color: theme.palette.customText.dark,
  fontSize: '13px',
  padding: '10px',
  fontFamily: 'Segoe UI',
  display: 'flex',
  justifyContent: 'center',
}));

export default {
  StyledFilterBox,
  StyledFilterPeriodButton,
  StyledBoxOnPeriodButtons,
  StyledSubFilterContainer,
  StyledBoxFilter,
  StyledFilter,
  StyledTargetBody,
  StyledResetFilter,
  StyledToggleFilter,
  StyledToogleBox,
  StyledSubmitButton,
  StyledDownloadButton,
  StyledTableBox,
  StyledEditOrSaveButton,
  StyledSubFilterContainerLeft,
  StyledSubFilterContainerRight,
  StyledPlayBookButton,
  StyledBuyPlanButton,
  StyledCircularProgress,
  StyledBoxSelectFilter,
  StyledBoxLabel,
  StyledSelect,
  StyledMenuItem,
  StyledTableField,
  StyledErrorBox,
  StyledSelectBuyPlan,
  StyledMenuItemBuyPlan,
};
