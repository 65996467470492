/* eslint-disable no-unused-vars */
/* This Page is the  unit master page which has feautures like adding units, editing existing units and filtering the units
based on the unit.
*/

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import UnitDisplay from './UnitDisplay';
import CommonConfTable from './commonTable/CommonConfTable';
import Loading from '../CommonConfFiles/Loading/Loading';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import config from '../../../Utils/Config';

const UnitMaster = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);


  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);

  const heads = ['Unit Description', 'UoM', 'Is Active', 'Last Edited By', 'Last Edited On'];

  const values = ['unit_description', 'unit', 'is_active', 'last_updated_by', 'last_updated_on',
  ];

  const dateValues = [false, false, false, false, true];

  const editValues = [true, true, false, false, false];


  const [pagination, setPagination] = useState(10);

  const [loading, setLoading] = useState(false);
  const [addingTrigger, setAddingTrigger] = useState(false);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedUniMasterFilterValue);


  const sortValues = [true, true, false, true, true];


  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);


  const updateSingleUnitMaster = ConfigurationServices.updateUnitMasterData;
  const newRowUpdate = ConfigurationServices.postUnitMasterData;


  useEffect(()=>{
    ConfigurationServices.getUnitMasterData()
        .then((res)=>{
          setLoading(true);
          setTimeout(()=>{
            setLoading(false);
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                setRow(res);
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          }, 100);
        });
  }, [addingTrigger]);

  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='unitDes_all' ||
     filteredUnitValue[0]==='unit_all' ) {
      row.sort((a, b) => {
        const boolComparison = Number(b[values[1]]) - Number(a[values[1]]);
        if (boolComparison !== 0) {
          return boolComparison;
        } else {
          return new Date(b[values[4]]) - new Date(a[values[4]]);
        }
      });

      setTableFilterData([...row]);
    } else {
      setLoading(true);

      setTimeout(()=>{
        setLoading(false);
        if (filteredUnitValue['UomArray'].length>0 &&
        filteredUnitValue['localUnitarray'].length>0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['UomArray'].includes(r.unit) &&
            filteredUnitValue['localUnitarray'].includes(r.unit_description);
          }));
        } else if (filteredUnitValue['UomArray'].length>0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['UomArray'].includes(r.unit);
          }));
        } else if (filteredUnitValue['localUnitarray'].length > 0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['localUnitarray']
                .includes(r.unit_description);
          }));
        } else if (filteredUnitValue['UomArray'].length == 0 &&
          filteredUnitValue['localUnitarray'].length == 0) {
          setTableFilterData([...row]);
        }
        setPage(0);
      }, 100);
    }
  }, [filteredUnitValue, row, addingTrigger]);


  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='last_updated_on') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.toLowerCase()?.trim()?.localeCompare(b[SortValue]?.toLowerCase()?.trim()));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.toLowerCase()?.trim()?.localeCompare(a[SortValue]?.toLowerCase()?.trim()));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);


  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Units_Master']) {
        setAccessControl(functionalityAccess['Configuration:Units_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);
  const [page, setPage] = React.useState(0);

  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>


        <UnitDisplay row={_.orderBy(row, [(r) => r?.unit_description?.toLowerCase()?.trim()]) } setPage={setPage}/>


        {/* {loading ? <Loading /> : */}


        <Box sx={{}}>
          <CommonConfTable
            data={tableFilterData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            sortValues={sortValues}
            accessControl={accessControl}
            setPage={setPage}
            page={page}
          />

          {(errMsg !=='' || tableFilterData.length == 0) &&
<ErrorMessage message='Unit Master' errmsg={errMsg}/>
          }
        </Box>
        {/* } */}
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg} />
      </Box>}
    </Box>
  );
};

export default UnitMaster;
