/* eslint-disable max-len */
import React from 'react';
import './SaveCoverageInput.css';
import {monthArr} from '../../../../services/utils';

function SaveCoverageInput({selectedRow, typeOfColumn}) {
  let setColumnType = '';
  if (typeOfColumn === 'executionMonth') {
    setColumnType =`${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`;
  } else if (typeOfColumn === 'coverageUnit') {
    setColumnType = selectedRow.measuringUnit;
  } else if (typeOfColumn === 'coveredPriceCurrency') {
    setColumnType = selectedRow.currency;
  }
  // else if (typeOfColumn === 'sourceName') {
  //   setColumnType = selectedRow.coverageMode;
  // }
  return (
    <div className='saveCoverageInput'>
      {setColumnType}
    </div>
  );
}

export default SaveCoverageInput;
