// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-table-input {
    position: relative;
    width: 58px;
    height: 22px;
    font-size: 12px;
    color: #6d7888;
    display: flex;
    align-items: left;
    font-family: 'Segoe UI';
}

.app-table-input input {
    width: 100%;
    border: 1px solid #DBDBDB;
    border-radius: 2px;
    font-size: 12px;
    color: #6d7888;
    font-family: 'Segoe UI';
}

.app-table-input input:focus {
    border: 1px solid #DBDBDB;
    outline: none;
}

.app-table-input input:disabled {
    border: none;
}

.app-table-input input:disabled .app-table-input-icon {
    display: none;
}

.app-table-input-icon {
    width: 0;
    height: 0;
    border-bottom: 4px solid #4297d3;
    border-left: 6px solid transparent;
    position: absolute;
    right: 2px;
    bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NonPlayBook/CoverageUpdate/AppTableInput/appTableInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,cAAc;IACd,aAAa;IACb,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kCAAkC;IAClC,kBAAkB;IAClB,UAAU;IACV,WAAW;AACf","sourcesContent":[".app-table-input {\n    position: relative;\n    width: 58px;\n    height: 22px;\n    font-size: 12px;\n    color: #6d7888;\n    display: flex;\n    align-items: left;\n    font-family: 'Segoe UI';\n}\n\n.app-table-input input {\n    width: 100%;\n    border: 1px solid #DBDBDB;\n    border-radius: 2px;\n    font-size: 12px;\n    color: #6d7888;\n    font-family: 'Segoe UI';\n}\n\n.app-table-input input:focus {\n    border: 1px solid #DBDBDB;\n    outline: none;\n}\n\n.app-table-input input:disabled {\n    border: none;\n}\n\n.app-table-input input:disabled .app-table-input-icon {\n    display: none;\n}\n\n.app-table-input-icon {\n    width: 0;\n    height: 0;\n    border-bottom: 4px solid #4297d3;\n    border-left: 6px solid transparent;\n    position: absolute;\n    right: 2px;\n    bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
