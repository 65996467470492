/* eslint-disable no-unused-vars */
import React from 'react';
// import AddIcon from '@mui/icons-material/Add';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import {Box, createTheme, Dialog, DialogContent, FormControl, InputBase, MenuItem, Select, TablePagination, TextareaAutosize} from '@mui/material';
import customealltheme from '../../theme';
import LogsSingleRow from './TargetLogsSingleRow';
import {useSelector} from 'react-redux';
import Styles from '../../../src/styles/target';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

function LogsTable({popup, setPopup, name, reason}) {
  const heads = reason ? ['Execution Month', 'Target Label', 'Target Month', 'Target Year', 'Column Changed', ' Previous Value', 'Current Value', 'Reason', 'Last Edited By', 'Last Edited On']:
  ['Execution Month', 'Target Label', 'Target Month', 'Target Year', 'Column Changed', ' Previous Value', 'Current Value', 'Last Edited By', 'Last Edited On'];
  const targetLogsData = useSelector((state)=>state?.targetApi?.targetLogsData);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setPopup(false);
    }
  };
  return (

    <Box sx={{width: '100%', padding: '0px 18px'}}>
      <Dialog
        open={popup}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{height: '80vh', overflowY: 'auto'}}>
          <Box sx={{width: '100%'}}>
            <Box
              mb={3}
              sx={{
                font: 'normal normal 700 15px/21px \'Segoe UI\' ',
                color: '#0F5DAA',
              }}
              style={customealltheme.palette.filterheading}
            >
              {name}
            </Box>
          </Box>
          <Box sx={{marginTop: '20px'}}>
            <TableContainer component={Paper} id='logtable' style={{maxHeight: '65vh', overflowY: 'auto'}}>
              <Table stickyHeader sx={{minWidth: 700}} aria-label="customized table">
                <TableHead id='categoryhead'>
                  <TableRow>
                    {heads.map((h, index)=>(
                      <StyledTableCell align="left" key={index}>
                        <Box style={customealltheme.palette.TableHead}>
                          <Box>{h}</Box>
                        </Box>

                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody >
                  {
                    targetLogsData?.map((row, key) => (
                      <LogsSingleRow
                        row={row} key={key} reason={reason}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {targetLogsData?.length===0 && <Styles.StyledErrorBox>No Logs Found.</Styles.StyledErrorBox>}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>

  );
}

export default LogsTable;

