/* This Page is the  common table page which has features like  editing the row and used by different
admin pages.
*/

import styled from '@emotion/styled';
import {Box, createTheme, FormControl, InputBase, MenuItem, Select, TableCell, TableRow, TextareaAutosize} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import customealltheme from '../../../theme';

const config={
  AccessLevel: {
    Edit: 'Edit',
    Read: 'Read',
  },
};

const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const Edit = config.AccessLevel.Edit;

const SupplierMasterSingleRow = ({row, nextMonthCloseBuyPlanTag, currentRole, selectedRow, setAddingTrigger, key, addingTrigger,
  values, editValues, dateValues, idKey, singleUpdate, accessControl,
  setErrMsg, setIsErrorPopupVisible}) => {
  const [editMode, setEditMode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [editedDetails, setEditDetails] = useState({});

  const [localCurrencyId, setlocalCurrencyId] = React.useState(row.is_active);
  const [desc, setDesc] = React.useState(row.supplier_description);
  const descInputRef = useRef();
  const [textAreaFocus, setTextAreaFocus] = useState(false);

  useEffect(() => {
    descInputRef?.current?.focus();
    const length = desc?.length;
    descInputRef?.current?.setSelectionRange(length, length);
  }, [desc]);

  const handleLocalDes = (e) => {
    setlocalCurrencyId(e.target.value);
  };

  function toCamelCase(arr) {
    let string = arr[0];
    for (let i=1; i<arr.length; i++) {
      string = string+ arr[i].charAt(0).toUpperCase()+arr[i].
          slice(1, arr[i].length);
    }
    return string;
  }
  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(()=>{
    const keyvalues = {};
    values.map((h, index)=>{
      keyvalues[h] = row[h];
    });
    // console.log(keyvalues);
    setEditDetails({...keyvalues});
  }, [row, values]);

  const editTheRow = () =>{
    setEditMode(true);
  };

  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () =>{
    setEditMode(false);
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);
    const keyvalues = {};
    const originalEdited = {...editedDetails};
    values.map((h, index)=>{
      if (document.getElementById(h) !==null) {
        originalEdited[h] = document.getElementById(h).value;
      }

      keyvalues[toCamelCase(h.split('_'))] =
       document.getElementById(h) !==null ?
      document.getElementById(h).value : editedDetails[h];
    });
    setEditDetails({...originalEdited});
    idKey.map((idkey)=>{
      keyvalues[`${idkey}`] = row[idkey];
      keyvalues[toCamelCase(idkey.split('_'))] = row[idkey];
      delete keyvalues[idkey];
    });
    singleUpdate({...keyvalues, supplierName: row.supplier_name, supplierDescription: desc!=='' ? desc : row.supplier_description,
      active: localCurrencyId !=='' ? localCurrencyId : row.is_active}).then((res)=>{
      if (res.userMessage) {
        setIsErrorPopupVisible(true);
        setErrMsg(res.userMessage);
        setAddingTrigger(!addingTrigger);
      }
    }).catch((err)=>{
      setIsErrorPopupVisible(true);
      setErrMsg('Error Has Occurred');
      setAddingTrigger(!addingTrigger);
    });
  };

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#eff6fc',
      color: 'rgba(59, 70, 85, 1)',
      font: 'normal normal 600 13px/19px \'Segoe UI\'',
      padding: '9px',
    },
    [`&.${tableCellClasses.body}`]: {
      font: 'normal normal normal 13px/19px \'Segoe UI\'',
      color: 'rgba(109, 120, 136, 1)',
      padding: '9px',
    },
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: ' #FFFFFF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  function toTitleCase(str) {
    if (str && typeof str == 'string') {
      return str[0]?.toUpperCase() + str?.slice(1);
    }
  }

  useEffect(() => {
    if (!editMode) {
      setTextAreaFocus(false);
      setDesc('');
    }
  }, [editMode]);

  return (
    <StyledTableRow>

      <StyledTableCell component="th" scope="row">
        { (editMode===true && editedDetails?.supplier_saved) ?
                <TextareaAutosize id="supplierName" style={{padding: '8px 6px', borderColor: '#ccc', borderRadius: '5px'}}
                  placeholder="Type in here…"
                  defaultValue={editedDetails['supplier_name']}
                  minRows={1}
                  maxRows={4}
                /> :
              `${editedDetails.supplier_name}`
        }
      </StyledTableCell>

      {
        (editMode === true) ?
        <StyledTableCell sx={{position: 'relative'}} component="th" scope="row">
          <TextareaAutosize id="supplierName" style={{padding: '12px 10px', height: '100%', width: '100%', borderColor: '#ccc', borderRadius: '5px'}}
            placeholder="Type in here…"
            defaultValue={desc || row.supplier_description}
            minRows={1}
            maxRows={4}
            maxLength={100}
            onChange={(e) => setDesc(e.target.value)}
            onFocus={(e) => setTextAreaFocus(true)}
            onBlur={(e)=>setTextAreaFocus(false)}
            ref={descInputRef}
          />
          <Box sx={{
            position: 'absolute',
            top: '3px',
            right: '25px',
            zIndex: '121231',
            background: 'white',
            color: 'rgb(69, 128, 230)',
            fontSize: '10px',
            padding: '0px 2px',
          }}>{ textAreaFocus ? `${(desc || row.supplier_description).length}/100` : ''}</Box>
        </StyledTableCell>:
        <StyledTableCell component="th" scope="row">
          { editedDetails.supplier_description}
        </StyledTableCell>
      }
      {/* <StyledTableCell component="th" scope="row">
        { editMode===true ?
                <TextareaAutosize id="supplierDescription" style={{padding: '8px 6px', borderColor: '#ccc', borderRadius: '5px'}}
                  placeholder="Type in here…"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  // defaultValue={editedDetails['supplier_description']}
                  minRows={1}
                  ref={descInputRef}
                  maxRows={4}
                /> :
              `${editedDetails.supplier_description}`
        }
      </StyledTableCell> */}
      <StyledTableCell component="th" scope="row">
        { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={localCurrencyId}
            onChange={handleLocalDes}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj == 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
              `${toTitleCase(editedDetails.is_active==true ?'Y':'N')}`
        }
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">

        {`${editedDetails.created_on}`}

      </StyledTableCell>
      <StyledTableCell component="th" scope="row">

        {`${editedDetails.last_updated_by}`}

      </StyledTableCell>
      <StyledTableCell component="th" scope="row">

        {`${editedDetails.last_updated_on}`}

      </StyledTableCell>


      {(accessControl === Edit && (nextMonthCloseBuyPlanTag==false || currentRole.includes('Admin')) && selectedRow.status!=='CLOSED' && selectedRow.status !== 'SUSPENDED') && <StyledTableCell align="left">
        {editMode === false ? <Box sx={{border: '1px solid gray',
          display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
          width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
          cursor: row.is_editable=='Y'?'pointer':'not-allowed'}} onClick={row.is_editable=='Y' ? editTheRow : console.log('')} style={customealltheme.palette.TableEdit}>
          <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
          <Box>Edit</Box>
        </Box> :
        <Box sx={{display: 'flex', gap: '5px'}}>
          <Box sx={{border: '1px solid gray',
            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
            cursor: 'pointer', background: '#4297d3', color: 'white',
            justifyContent: 'center'}} onClick={saveTheRow}>
            <Box>Save</Box>
          </Box>
          <Box sx={{border: '1px solid gray',
            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
            cursor: 'pointer'}} onClick={(e)=>{
            setEditMode(false);
            setlocalCurrencyId(row.is_active);
          }}>
            <Box>Cancel</Box>
          </Box>
        </Box>
        }

      </StyledTableCell>
      }
    </StyledTableRow>
  );
};

export default SupplierMasterSingleRow;
