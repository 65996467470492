/* eslint-disable prefer-const */

import fromExponential from 'from-exponential';

/* eslint-disable max-len */
export const titleCase = (str) => {
  let splitted = str?.toLowerCase().split(' ') || [];
  for (let i = 0; i < splitted.length; i++) {
    splitted[i] = splitted[i].charAt(0).toUpperCase() + splitted[i].slice(1);
  }
  return splitted.join(' ');
};

export const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const monthsCap = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export const leapYear = (year) => {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
};

export const generateMonthDropDownValues = () => {
  const planMonths = [];
  const offsetYear = 1;
  const currYear = new Date().getFullYear();
  const currMonth = new Date().getMonth();
  const NO_OF_YEARS = 3;
  const monthsUptoThisYear = monthArr.slice(0, currMonth+1).reverse();
  const remainingMonthsThisYear = monthArr.slice(currMonth+1).reverse();
  const prevTwoYearsWithOutMon = [Number(currYear)-1, Number(currYear)-2];
  const prevThreeYears = [];
  monthsUptoThisYear.forEach((month) => prevThreeYears.push(month +' ' + currYear));
  prevTwoYearsWithOutMon.forEach((year) => {
    const monthArrDummy = [...monthArr];
    monthArrDummy.reverse().forEach((month) => prevThreeYears.push(month +' ' + year));
  });
  remainingMonthsThisYear.forEach((month) => prevThreeYears.push(month +' ' + (currYear- NO_OF_YEARS)));
  // console.log({prevThreeYears});
  const toYear = currYear - offsetYear;
  for (let index = currYear; index >= toYear; index--) {
    for (let mindex=0; mindex < monthArr.length; mindex++) {
      const keystr = monthArr[mindex]+' '+index;
      const obj = {key: [index, mindex+1], value: keystr};
      planMonths.push(obj);
    }
  }
  const planMonthsModified = [];
  prevThreeYears.forEach( (year) => {
    const value = {'key': [Number(year.split(' ')[1]), Number(monthArr.indexOf(year.split(' ')[0])+1)], 'value': year};
    planMonthsModified.push(value);
  });
  return planMonthsModified;
};

export const dropDownValuesForNextThreeYears = () => {
  const planMonths = [];
  const offsetYear = 1;
  const currYear = new Date().getFullYear();
  const currMonth = new Date().getMonth();
  // const NO_OF_YEARS = 3;
  const monthsUptoThisYear = monthArr.slice(currMonth);
  // const remainingMonthsThisYear = monthArr.slice(0, currMonth+1);
  const prevTwoYearsWithOutMon = [Number(currYear)+1, Number(currYear)+2];
  const prevThreeYears = [];
  monthsUptoThisYear.forEach((month) => prevThreeYears.push(month +' ' + currYear));
  prevTwoYearsWithOutMon.forEach((year) => {
    const monthArrDummy = [...monthArr];
    monthArrDummy.forEach((month) => prevThreeYears.push(month +' ' + year));
  });
  // remainingMonthsThisYear.forEach((month) => prevThreeYears.push(month +' ' + (currYear + NO_OF_YEARS)));
  // console.log({prevThreeYears});
  const toYear = currYear - offsetYear;
  for (let index = currYear; index >= toYear; index--) {
    for (let mindex=0; mindex < monthArr.length; mindex++) {
      const keystr = monthArr[mindex]+' '+index;
      const obj = {key: [index, mindex+1], value: keystr};
      planMonths.push(obj);
    }
  }
  const planMonthsModified = [];
  prevThreeYears.forEach( (year) => {
    const value = {'key': [Number(year.split(' ')[1]), Number(monthArr.indexOf(year.split(' ')[0])+1)], 'value': year};
    planMonthsModified.push(value);
  });
  // console.log({planMonthsModified});
  return planMonthsModified;
};

export function roundToTwo(decider, num) {
  if (num!==undefined && num?.toString().includes('e')) {
    return +parseFloat(+fromExponential(num)).toFixed(decider==undefined?2:+decider);
  }
  if (isNaN(num) || num==null) return 0;
  // if (!Number.isInteger(num) && !(Math.floor(num) == num)) {
  if (num % 1 !== 0) {
    const roundedNum = +(Math.round(num + 'e+2') + 'e-2');
    if (roundedNum == 0) return 0;
    return +roundedNum.toFixed(decider==undefined?2:+decider);
  } else {
    return parseFloat(num);
  }
}

export const commaTextToNumber = (num) =>{
  return num?.toString().split(',').length>1 ? num?.toString().split(',').join('') : num;
};

export const formatNumber = (number, index) => {
  let numberToFormat = number;
  let strNumberToFormat = String(numberToFormat);
  if (strNumberToFormat.includes('.') && strNumberToFormat.split('.').length > 1) {
    numberToFormat = strNumberToFormat.split('.')[1]?.length < index ? numberToFormat : (strNumberToFormat.split('.')[0] + '.' + strNumberToFormat.split('.')[1].substring(0, index));
  };
  return numberToFormat;
};

export const buyplanStatusArr = [
  'APPROVED',
  'CLOSED',
  'DRAFT',
  'NOT STARTED',
  'REJECTED',
  'SUSPENDED',
  'SUBMITTED',
];

export const defaultMonthValues = {
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0,
  fy: 0,
};

export const nextThreeYearsFromExecutionMon = (selectedRow) => {
  const executinoYearMon = selectedRow.executionMonthYear;
  const startYear = executinoYearMon[1]==1?executinoYearMon[0]-1:executinoYearMon[0];
  const currentMonthIndex = '';
  let resultArr = null;
  const allYears = [startYear, startYear+1, startYear+2];
  const monthsFromCurrMonth = monthArr.slice(currentMonthIndex).map((mon) => (mon + ' ' + allYears[0]));
  const nextFirstYear = monthArr.map((mon) => mon + ' ' + allYears[1]);
  const nextSecondYear = monthArr.map((mon) => mon + ' ' + allYears[2]);
  resultArr = [...monthsFromCurrMonth, ...nextFirstYear, ...nextSecondYear];
  return resultArr;
};

export const isDivAtTop = () => {
  let pathname;
  if (location.pathname.slice(1).split('/').includes('configuration')) {
    pathname = location.pathname.slice(1).split('/')[1];
  } else {
    pathname = location.pathname.slice(1);
  }
  // console.log(pathname);
  const table = document.getElementById(`${pathname}table`);

  const divOffset = table?.offsetTop;
  const scrollPosition = window.scrollY || document.documentElement.scrollTop;
  if (scrollPosition >= divOffset) {
    document.getElementById(`${pathname}head`)?.classList.add('stickyHead');
    document.getElementById(`${pathname}head`).style.zIndex = '999';

    document.getElementById(`${pathname}head`).style.top = `${scrollPosition-divOffset-1}px`;
  } else {
    document.getElementById(`${pathname}head`)?.classList.remove('stickyHead');
  }
};

// Attach the function to the scroll event
window.onscroll = isDivAtTop;


// Desc: This function is used to determine the hedging mechanism based on the coverage,  mode and others field in the data.
export const hedgingDecider = (coverageMode, others) => {
  let hedgingMech = '';
  if (coverageMode=='SUPPLIER' && others== false) {
    hedgingMech = 'Supplier';
  } else if (coverageMode=='SUPPLIER' && others== true) {
    hedgingMech = 'Supplier/Other';
  } else if (coverageMode=='TREASURY' && others== false) {
    hedgingMech = 'Treasury';
  } else if (coverageMode=='TREASURY' && others== true) {
    hedgingMech = 'Treasury/Other';
  } else if (coverageMode=='BOTH' && others== false) {
    hedgingMech = 'Supplier/Treasury';
  } else if (coverageMode=='BOTH' && others==true) {
    hedgingMech = 'Supplier/Treasury/Other';
  } else if (coverageMode=='NONE' && others==false) {
    hedgingMech = 'None';
  } else if (others==true && coverageMode=='NONE') {
    hedgingMech = 'Other';
  }
  return hedgingMech;
};

