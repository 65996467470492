import axiosInstance from '../apis/axiosInstance';


const SupplierServices = {

  getSupplierData: (buyPlanId, wfid) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/supplier/getSuppliers?buyPlanId=${buyPlanId}&workflowId=${wfid}`)
          .then((res) => {
            resolve(res?.data);
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },
  getOtherSupplierData: (buyPlanId) => {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/supplier/notForBuyPlanId?buyPlanId=${buyPlanId}`)
          .then((res) => {
            resolve(res?.data);
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  updateSupplierData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/supplier/saveSuppliers`, data)
          .then((res) => {
            resolve(res?.data);
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  postSupplierData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/supplier/saveSuppliers`, data)
          .then((res) => {
            resolve(res?.data);
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

};


export default SupplierServices;
