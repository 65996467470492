/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import {getDropDownData_API, setClearForecastFilter, setForecastFilter,
  setSelectedDropDownData} from '../../redux/features/apiCall';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {utils, writeFileXLSX} from 'xlsx';
import {setSortType} from '../../redux/features/configurations';
import ConfigurationServices from '../../services/ConfigurationServices';

const dropdownSpan = {
  font: 'normal normal 600 12px/16px Segoe UI',
  color: 'rgba(59, 70, 85, 1)',
};

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'Segoe UI , size 12px, left alignment',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    'font': 'normal normal normal 13px/15px \'Segoe UI\' ',
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

function ForecastList({forecastData, setYearValue, setYear, selctedPlanYear}) {
  const selectedDropDownData = useSelector(
      (state) => state?.api?.selectedDropDownData,
  );

  const [valueCategory, setValueCategory] = useState('');
  const [valueCommodity, setValueCommodity] = useState('');
  const [valueSubCommodity, setValueSubCommodity] = useState('');
  const [valueSector, setValueSector] = useState('');
  const [valueRegion, setValueRegion] = useState('');
  const [valuePlanMonth, setValuePlanMonth] = useState('');
  const [valueBuyPlan, setValueBuyPlan]=useState('');
  const [sectorList, setSectorList] = useState([]);
  const [commodityList, setCommodityList] = useState([]);
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [selectedSubCommodities, setSelectedSubCommodities] = useState([]);
  const [selectedRegionIdList, setSelectedRegionIdList] = useState([]);
  const [buyPlanList, setBuyPlanList]=useState([]);
  const [filterBuyPlanList, setFilterBuyPlanList]=useState([]);


  const apiRespDropdownData = useSelector(
      (state) => state?.api?.apiValueDropdownData,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDropDownData_API());
  }, []);

  const handleChangeBuyPlan = (event) =>{
    const selectedBuyPlanId = event.target.value;
    const selectedBuyPlan = filterBuyPlanList.find((bp) => bp['buyPlanId'] === selectedBuyPlanId);
    setValueBuyPlan(selectedBuyPlanId);
    setValueSector(selectedBuyPlan['sectorId']);
    setValueRegion(selectedBuyPlan['regionId']);
    setValueCategory(selectedBuyPlan['commodityGroupId']);
    setValueCommodity(selectedBuyPlan['commodityId']);
    setValueSubCommodity(selectedBuyPlan['subCommodityId']);
    setSelectedRegionIdList([{id: selectedBuyPlan['regionId'], region: selectedBuyPlan['region']}]);
    setSelectedCommodities([{id: selectedBuyPlan['commodityId'], name: selectedBuyPlan['commodity']}]);
    setSelectedSubCommodities([{id: selectedBuyPlan['subCommodityId'], name: selectedBuyPlan['subCommodity']}]);
  };

  const handleChangePlanMonth = (event) => {
    setValuePlanMonth(event.target.value);
  };

  // row is in object form
  const getRowDataByYear = (row, year) => {
    const months = {Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0, Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0};
    Object.keys(row).forEach((r) => {
      const [mm, yy] = r.split('-');
      if (yy == year) {
        months[mm] = row[r];
      }
    });
    return months;
  };

  const getFYTotal = (row, year) => {
    let total = 0;
    Object.keys(row).forEach((r) => {
      const yy = r.split('-')[1];
      if (yy == year) {
        total = total + Number(row[r]);
      }
    });
    return total;
  };

  const onDownload = () => {
    const currentYr = Number(selctedPlanYear.toString().slice(2));
    const sheetData = {[currentYr-1]: [], [currentYr]: [], [currentYr + 1]: [], [currentYr + 2]: []};
    [currentYr-1, currentYr, currentYr + 1, currentYr + 2].forEach((yy) => {
      Object.keys(forecastData).forEach((forecastMonth) => {
        const fyKey = `FY ${yy} Total`;
        sheetData[yy].push({
          'Plan Month': forecastMonth,
          // 'Plan Year': `20${yy}`,
          ...getRowDataByYear(forecastData[forecastMonth], yy),
          [fyKey]: getFYTotal(forecastData[forecastMonth], yy),
        });
      });
    });

    const wb = utils.book_new();
    Object.keys(sheetData).forEach((k) => {
      const ws = utils.json_to_sheet(sheetData[k]);
      utils.book_append_sheet(wb, ws, `20${k}`);
    });

    writeFileXLSX(wb, `Forecast-${Object.keys(forecastData)[0]}.xlsx`);
  };

  const handleChangeSector = (event) => {
    setValueRegion('');
    setValueSector(event.target.value);

    const tempRegionIdList = [];
    apiRespDropdownData.length !== 0 &&
        apiRespDropdownData?.sectorGroups.map((obj) =>
          obj?.sectors?.map((row) => {
            row?.regionIdList.map((id) => {
              if (row.id == event.target.value) {
                if (!tempRegionIdList.includes(id)) {
                  tempRegionIdList.push(id);
                }
              }
            });
          }),
        );
    const regionList =[];
    apiRespDropdownData?.regions.map((row)=>{
      if (tempRegionIdList.includes(row.id) && !regionList.includes(row)) {
        regionList.push(row);
      }
    });
    setSelectedRegionIdList([...regionList]);
  };

  const handleChangeRegion = (event) => {
    setValueRegion(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setValueCommodity('');
    setValueSubCommodity('');
    setValueCategory(event.target.value);

    const tempCommodities = [];
    apiRespDropdownData.length !== 0 &&
        apiRespDropdownData?.commodityGroups?.map((category) => {
          if (category.id == event.target.value) {
            category.commodities.map((commodity) =>
              tempCommodities.push(commodity),
            );
          }
        });

    setSelectedCommodities([...tempCommodities]);
  };

  const handleChangeCommodity = (event) => {
    setValueSubCommodity('');
    const {
      target: {value},
    } = event;
    setValueCommodity(value);

    const tempSubCommodities = [];
    apiRespDropdownData.length !== 0 &&
    apiRespDropdownData?.commodityGroups?.map((ele) =>
      ele?.commodities?.map((commodity) => {
        if (commodity.id == value) {
          commodity.subCommodities.map((subCommodity) =>
            tempSubCommodities.push(subCommodity),
          );
        }
      }),
    );

    setSelectedSubCommodities([...tempSubCommodities]);
  };

  const handleChangeSubCommodity = (event) => {
    const {
      target: {value},
    } = event;
    setValueSubCommodity(value);
  };

  const handleSubmit = () => {
    dispatch(
        setForecastFilter({
          sector: valueSector,
          region: valueRegion,
          commodityGrp: valueCategory,
          commodity: valueCommodity,
          subCommodity: valueSubCommodity,
          executionMonthYear: valuePlanMonth,
        }),
    );
  };


  useEffect(()=>{
    const sectorlist = [];
    apiRespDropdownData['sectorGroups']?.map((obj)=>{
      obj['sectors']?.map((row)=>{
        if (!sectorlist.includes(row)) {
          sectorlist.push(row);
        }
      });
    });
    setSectorList([...sectorlist]);
    const commoditylist = [];
    apiRespDropdownData['commodityGroups']?.map((obj)=>{
      if (!commoditylist.includes(obj)) {
        commoditylist.push(obj);
      }
    });
    setCommodityList([...commoditylist]);
  }, [apiRespDropdownData]);

  useEffect(()=>{
    ConfigurationServices.getBuyPlanDataInForecast().then((res)=>{
      setBuyPlanList(res);
      setFilterBuyPlanList(res);
    }).catch((e) => {
    });
  }, []);

  useEffect(() => {
    let filteredBuyPlanList = buyPlanList;

    // Filter by sector
    if (valueSector !== '') {
      filteredBuyPlanList = filteredBuyPlanList.filter((bp) => bp['sectorId'] === valueSector);
    }

    // Filter by region
    if (valueRegion !== '') {
      filteredBuyPlanList = filteredBuyPlanList.filter((bp) => bp['regionId'] === valueRegion);
    }

    // Filter by category
    if (valueCategory !== '') {
      filteredBuyPlanList = filteredBuyPlanList.filter((bp) => bp['commodityGroupId'] === valueCategory);
    }

    // Filter by commodity
    if (valueCommodity !== '') {
      filteredBuyPlanList = filteredBuyPlanList.filter((bp) => bp['commodityId'] === valueCommodity);
    }

    // Filter by sub-commodity
    if (valueSubCommodity !== '') {
      filteredBuyPlanList = filteredBuyPlanList.filter((bp) => bp['subCommodityId'] === valueSubCommodity);
    }

    // Update the state with the filtered list
    setFilterBuyPlanList(filteredBuyPlanList);
  }, [valueSector, valueRegion, valueCategory, valueCommodity, valueSubCommodity, buyPlanList]);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: '100%', padding: '18px'}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal bold 14px/19px Segoe UI',
            color: '#0F5DAA',
          }}
        >
          Forecasts
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '35px',
            flexWrap: 'wrap',
          }}
        >

          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} style={{font: 'normal normal 600 12px/16px Segoe UI'}} component="span">
              Sector
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth >
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={theme.selectBox}
                  value={valueSector}
                  onChange={handleChangeSector}
                  label="Select"
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  className="newclass"
                  style={{width: '144px'}}
                >
                  <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}} value="" sx={theme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>
                  {sectorList.length !== 0 &&
                    sectorList.sort((a, b)=>a['name'].localeCompare(b['name'])).map((row) => (
                      <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}}
                        sx={theme.selectMenuBox}
                        key={row?.id}
                        value={row?.id}
                        className="this-is-new-class"
                      >
                        <span>{row.name}</span>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} style={{font: 'normal normal 600 12px/16px Segoe UI'}} component="span">
              Region/Country
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={theme.selectBox}
                  value={valueRegion}
                  label="value"
                  style={{width: '144px'}}
                  onChange={handleChangeRegion}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                >
                  <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}} value="" sx={theme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>
                  {
                    selectedRegionIdList.length !== 0 &&
                      selectedRegionIdList?.sort((a, b)=> a['region'].localeCompare(b['region'])).map((region, idx) => {
                        return (
                          <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}}
                            sx={theme.selectMenuBox}
                            key={idx}
                            value={region.id}
                            className="this-is-new-class"

                          >
                            <span>{region.region}</span>
                          </MenuItem>
                        );
                      },
                      )
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} style={{font: 'normal normal 600 12px/16px Segoe UI'}} component="span">
              Category
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={theme.selectBox}
                  variant="standard"
                  value={valueCategory}
                  style={{width: '144px'}}
                  label="valueCategory"
                  onChange={handleChangeCategory}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                >
                  <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}} value="" sx={theme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>
                  {commodityList.length !== 0 &&
                    commodityList?.sort((a, b)=>a['name'].localeCompare(b['name'])).map((row) => (
                      <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}}
                        sx={theme.selectMenuBox}
                        key={row?.id}
                        value={row?.id}

                      >
                        <span>{row?.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} style={{font: 'normal normal 600 12px/16px Segoe UI'}} component="span">
              Commodity
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={theme.selectBox}
                  value={valueCommodity}
                  style={{width: '144px'}}
                  label="value"
                  onChange={handleChangeCommodity}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                >
                  <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}} value="" sx={theme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>
                  {selectedCommodities?.sort((a, b)=>a['name'].localeCompare(b['name'])).map((commodity, idx) => (
                    <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}}
                      sx={theme.selectMenuBox}
                      key={idx}
                      value={commodity.id}
                    >
                      <span> {commodity.name}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>


          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Sub-Commodity
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={theme.selectBox}
                  value={valueSubCommodity}
                  style={{width: '144px'}}
                  label="value"
                  onChange={handleChangeSubCommodity}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                >
                  <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}} value="" sx={theme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>
                  {selectedSubCommodities?.sort((a, b)=>a['name'].localeCompare(b['name'])).map((subcommodity, idx) => (
                    <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}}
                      sx={theme.selectMenuBox}
                      key={idx}
                      value={subcommodity.id}
                    >
                      <span>{subcommodity.name}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Buy Plan
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={theme.selectBox}
                  value={valueBuyPlan}
                  style={{width: '144px'}}
                  label="value"
                  onChange={handleChangeBuyPlan}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                >
                  <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}} value="" sx={theme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>
                  {[...new Set(filterBuyPlanList.map((bp) => bp['buyPlanId']))]
                      .map((uniqueBuyPlanId) =>
                        filterBuyPlanList.find((bp) => bp['buyPlanId'] === uniqueBuyPlanId))
                      .sort((a, b) => a['buyPlanName'].localeCompare(b['buyPlanName']))
                      .map((bp) => (
                        <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}}
                          sx={theme.selectMenuBox}
                          key={bp['buyPlanId']}
                          value={bp['buyPlanId']}
                        >
                          <span>{bp['buyPlanName']}</span>
                        </MenuItem>
                      ))
                  }

                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} style={{font: 'normal normal 600 12px/16px Segoe UI'}} component="span">
              Plan Year
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={theme.selectBox}
                  value={valuePlanMonth}
                  label="Select All"
                  onChange={handleChangePlanMonth}
                  style={{width: '144px'}}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                >
                  <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}} value="" sx={theme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>
                  {[new Date().getFullYear(),
                    new Date().getFullYear() - 1,

                    new Date().getFullYear() - 2,

                  ].length !== 0 &&
                    [
                      new Date().getFullYear(),
                      new Date().getFullYear() - 1,

                      new Date().getFullYear() - 2,

                    ].map((row) => (
                      <MenuItem style={{font: 'normal normal normal 12px/16px Segoe UI'}}
                        sx={theme.selectMenuBox}
                        key={row}
                        value={row}
                      >
                        <span> {row}</span>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} style={{font: 'normal normal 600 12px/16px Segoe UI'}} component="span"></Box>
            <Box sx={{minWidth: 130, marginTop: '28px'}}>
              {valueSector !== '' &&
              valueRegion !== '' &&
              valueCommodity !== '' &&
              valueSubCommodity !== '' &&
              valuePlanMonth.length !== 0 ? (
                <Button
                  onClick={handleSubmit}
                  sx={{
                    'backgroundColor': '#4297d3',
                    'color': '#ffffff',
                    'textTransform': 'none',
                    'minWidth': '0',
                    'padding': '8px 35px',
                    'width': '110px',
                    'height': '40px',
                    '&:hover': {
                      backgroundColor: '#4297d3',
                    },
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  sx={{
                    'backgroundColor': '#4297d3',
                    'color': '#ffffff',
                    'textTransform': 'none',
                    'minWidth': '0',
                    'padding': '8px 35px',
                    'cursor': 'not-allowed',
                    'width': '110px',
                    'height': '40px',
                    '&:hover': {
                      backgroundColor: '#4297d3',
                    },
                  }}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>


          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} component="span"></Box>
            <Box sx={{minWidth: 130, marginTop: '28px'}}>
              {Object.keys(forecastData).length>0 && (
                <Button
                  onClick={onDownload}
                  sx={{
                    'backgroundColor': '#4297d3',
                    'color': '#ffffff',
                    'textTransform': 'none',
                    'minWidth': '0',
                    'padding': '8px 35px',
                    '&:hover': {
                      backgroundColor: '#4297d3',
                    },
                  }}
                >
                  Download
                </Button>)
              }
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: {
              display: 'inline-block',
              marginRight: '2px',
              font: 'normal normal 600 13px/15px \'Segoe UI\'',
              color: '#3174b6',
              cursor: 'pointer',
            },
            marginTop: '30px',
          }}
        >
          <p
            onClick={() => {
              const tempObj = {...selectedDropDownData};
              Object.keys(tempObj).map((el) => (tempObj[el] = []));
              dispatch(setSelectedDropDownData(tempObj));
              dispatch(setClearForecastFilter());
              setValueCategory('');
              setValueCommodity('');
              setValuePlanMonth('');
              setValueRegion('');
              setValueSector('');
              dispatch(setSortType(''));

              setValueSubCommodity('');
              setSelectedRegionIdList([]);
              setSelectedCommodities([]);
              setSelectedSubCommodities([]);
              setYearValue(new Date().getFullYear()+'');
              setFilterBuyPlanList(buyPlanList);
              setValueBuyPlan('');
            }}
          >
            Reset Filters
          </p>
        </Box>
      </Box>
      <Box
        sx={{border: '1px solid #f5f5f5', width: '97%', margin: 'auto'}}
      ></Box>
    </ThemeProvider>
  );
}

export default ForecastList;
