import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Box, Menu, MenuItem, Typography} from '@mui/material';
import {setClearForecastFilter} from '../../../redux/features/apiCall';
import './SubHeader.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useDispatch, useSelector} from 'react-redux';

import {setCurrencySelected, setselectedConfiguration, setSelectedPlayBook,
  setSelectedUnitMaster, setSelectedUnitConversion, setPlayBookFilter, setSelectedCurrencyConversion, setCategoryFilter, setCommodityFilter, setSubCommodityFilter, setMasterSectorFilter, setSectorFilter, setSortFilter, setSortType, setCountryFilter, setBuyPlanFilter, setRoleAccessFilter, setGuidancePriceFilter, setBuyPlanReportFilter, setselectedReport}
  from '../../../redux/features/configurations';
import {resetNavData} from '../../../redux/buyplanTab/NavigationDataSlice';

function SubHeader() {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(()=>{
    if ( pathname !== '/forecast') {
      dispatch(setClearForecastFilter());
    }
    if (pathname !== '/configuration') {
      dispatch(setSelectedUnitMaster(''));
    }
    if (pathname !== '/target') {
      dispatch(resetNavData());
    }
  }, [dispatch, pathname]);

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = useState(false);
  const [anchorEl3, setAnchorEl3] = useState(false);
  const [showConfigurationMenu, setShowConfigurationMenu] = useState(false);
  const [showCommodityMasterMenu, setShowCommodityMasterMenu] = useState(false);
  const [showSectorMasterMenu, setShowSectorMasterMenu] = useState(false);
  const [showUnitsMasterMenu, setShowUnitsMasterMenu] = useState(false);
  const [showUnitsConversionMasterMenu, setShowUnitsConversionMasterMenu] = useState(false);
  const [showCurrencyMasterMenu, setShowCurrencyMasterMenu] = useState(false);
  const [showPlayBookMenu, setShowPlayBookMenu] = useState(false);
  const [showBuyPlanMenu, setShowBuyPlanMenu] = useState(false);

  const [anchorElR, setAnchorElR] = useState(false);
  const openR = Boolean(anchorElR);
  const [showReportMenu, setShowReportMenu] = useState(false);

  const [showBuyPlanReport, setShowBuyPlanReport] = useState(false);
  const [showPlaybookCompMap, setShowPlaybookCompMap]=useState(false);
  const [showPowerBiReport, setShowPowerBiReport]=useState(false);

  const [showRolesAccessMenu, setShowRolesAccessMenu] = useState(false);
  const [menuPagesToShow, setMenuPagesToShow]=useState({
    Home: false,
    FX_Rates: false,
    Guidance_Price: false,
    Non_Playbook: false,
    Forecast: false,
    Target: false,
  });

  const availableFunctionality=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  const [currentRole, setCurrentRole]= useState('');

  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(()=>{
    if (availableFunctionality) {
      let showConfigMenu=false;
      if (availableFunctionality['Configuration:Commodity_Master']) {
        setShowCommodityMasterMenu(true);
        showConfigMenu=true;
      }
      if (availableFunctionality['Configuration:Sector_Master']) {
        setShowSectorMasterMenu(true);
        showConfigMenu=true;
      }
      if (availableFunctionality['Configuration:Units_Master']) {
        setShowUnitsMasterMenu(true);
        showConfigMenu=true;
      }
      if (availableFunctionality['Configuration:Unit_Conversion_Master']) {
        setShowUnitsConversionMasterMenu(true);
        showConfigMenu=true;
      }
      if (availableFunctionality['Configuration:Currency_Master']) {
        setShowCurrencyMasterMenu(true);
        showConfigMenu=true;
      }
      if (availableFunctionality['Configuration:PlayBook']) {
        setShowPlayBookMenu(true);
        showConfigMenu=true;
      }
      if (availableFunctionality['Configuration:BuyPlan']) {
        setShowBuyPlanMenu(true);
        showConfigMenu=true;
      }
      if (availableFunctionality['Configuration:Roles_&Access']) {
        setShowRolesAccessMenu(true);
        showConfigMenu=true;
      }

      setShowConfigurationMenu(showConfigMenu);

      let showReportmenu=false;

      // Change here for BU REPORT functionality
      if (availableFunctionality['Reports:BuyPlanReporting']) {
        setShowBuyPlanReport(true);
        showReportmenu=true;
      }
      if (availableFunctionality['Reports:BuyPlanReporting']) {
        setShowPlaybookCompMap(true);
        showReportmenu=true;
      }
      if (availableFunctionality['Reports:PBIReports']) {
        setShowPowerBiReport(true);
        showReportmenu=true;
      }

      setShowReportMenu(showReportmenu);

      const menuPagesToShows = {};

      if (availableFunctionality['FX_Rates']) {
        // setShowFxRatesMenu(true);
        menuPagesToShows['FX_Rates']=true;
      }
      if (availableFunctionality['Guidance_Price']) {
        // setShowGuidance(true);
        menuPagesToShows['Guidance_Price']= true;
      }

      if (availableFunctionality['Non_PlayBook:Active_List'] || availableFunctionality['Non_PlayBook:Historical_List']) {
        // setShowGuidance(true);
        menuPagesToShows['Non_Playbook']= true;
      }
      const functionalityAccessKeys = Object.keys(availableFunctionality);
      if (functionalityAccessKeys.some((functionalityName)=> ~functionalityName.indexOf('Home_Page'))) {
        menuPagesToShows['Home']= true;
      }
      if (functionalityAccessKeys.some((functionalityName)=> functionalityName==='Forecast')) {
        menuPagesToShows['Forecast']= true;
      }
      if (functionalityAccessKeys.some((functionalityName)=> ~functionalityName.indexOf('Targets'))) {
        menuPagesToShows['Target']= true;
      }

      setMenuPagesToShow(menuPagesToShows);
    }
  }, [availableFunctionality]);

  const handleClick = (event) => {
    if (event.target.getAttribute('id') !== 'submenu') {
      if (open==true) {
        setAnchorEl(null);
        setAnchorEl2(false);
        setAnchorEl3(false);
      } else {
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handleClickR = (event) => {
    if (event.target.getAttribute('id') !== 'submenu') {
      if (openR==true) {
        setAnchorElR(null);
      } else {
        setAnchorElR(event.currentTarget);
      }
    }
  };

  const handleClose = (e) => {
    if (e.target.getAttribute('id') !== 'submenu') {
      dispatch(setselectedConfiguration(e.target.getAttribute('id')));
      dispatch(setSelectedUnitMaster([]));
      dispatch(setSelectedPlayBook([]));
      dispatch(setCurrencySelected([]));
      dispatch(setSelectedUnitConversion([]));
      dispatch(setPlayBookFilter([]));
      dispatch(setSelectedCurrencyConversion([]));
      dispatch(setCategoryFilter([]));
      dispatch(setCommodityFilter([]));
      dispatch(setSubCommodityFilter([]));
      dispatch(setMasterSectorFilter([]));
      dispatch(setSectorFilter([]));
      dispatch(setSortFilter(''));
      dispatch(setSortType('asc'));

      dispatch(setGuidancePriceFilter([]));
      dispatch(setCountryFilter([]));
      dispatch(setPlayBookFilter([]));
      dispatch(setBuyPlanFilter([]));
      dispatch(setRoleAccessFilter([]));
      dispatch(setBuyPlanReportFilter([]));
      setAnchorEl(null);
      setAnchorEl2(false);
      setAnchorEl3(false);
      history.push('/configuration');
    }
  };

  const handleCloseR = (e) => {
    if (e.target.getAttribute('id') !== 'submenu') {
      dispatch(setselectedReport(e.target.getAttribute('id')));
      dispatch(setSelectedUnitMaster([]));
      dispatch(setSelectedPlayBook([]));
      dispatch(setCurrencySelected([]));
      dispatch(setSelectedUnitConversion([]));
      dispatch(setPlayBookFilter([]));
      dispatch(setSelectedCurrencyConversion([]));
      dispatch(setCategoryFilter([]));
      dispatch(setCommodityFilter([]));
      dispatch(setSubCommodityFilter([]));
      dispatch(setMasterSectorFilter([]));
      dispatch(setSectorFilter([]));
      dispatch(setSortFilter(''));
      dispatch(setSortType('asc'));

      dispatch(setGuidancePriceFilter([]));
      dispatch(setCountryFilter([]));
      dispatch(setPlayBookFilter([]));
      dispatch(setBuyPlanFilter([]));
      dispatch(setRoleAccessFilter([]));
      dispatch(setBuyPlanReportFilter([]));
      setAnchorElR(null);
      history.push('/report');
    }
  };


  const commonStyle = {
    cursor: 'pointer',
    marginLeft: '10px',
    marginRight: '15px',
    flexGrow: 1,
    flexShrink: 0,
    a: {
      color: 'white',
      textDecoration: 'none',
      font: 'normal normal 600 14px/21px \'Segoe UI\'',
      opacity: '1',
      paddingBottom: '2px',
    },
  };

  const commonMenuStyles={
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  };

  const commonSubMenuStyles={
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
    cursor: 'pointer',
  };


  return (
    <Box
      sx={{
        'bgcolor': '#215094',
        'display': 'flex',
        'padding': '12px 10px 10px 10px',
        'color': 'white',
        'height': '54px',
        'justifyContent': 'space-between',
        'marginTop': '0px',
        '.MuiBox-root': {
          marginTop: '0px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          fontSize: '14px',
          position: 'relative',
        }}
      >
        {menuPagesToShow.Home &&
          <Box sx={commonStyle}>
            <Link
              to="/home"
              style={{
                borderBottom: pathname === '/home' ? '2px solid #efbc28' : 'none',
              }}
            >
              Home
            </Link>
          </Box>
        }
        {menuPagesToShow.Forecast &&
        <Box sx={commonStyle}>
          <Link
            to="/forecast"
            style={{
              borderBottom:
                pathname === '/forecast' ? '2px solid #efbc28' : 'none',
            }}
          >
            Forecasts
          </Link>
        </Box>
        }

        {menuPagesToShow.Target &&
        <Box sx={commonStyle}>
          <Link
            to="/target"
            style={{
              borderBottom:
                pathname === '/target' ? '2px solid #efbc28' : 'none',
            }}
          >
            Target
          </Link>
        </Box>
        }
        {menuPagesToShow.FX_Rates &&
        <Box sx={commonStyle}>
          <Link
            to="/fxrates"
            style={{
              borderBottom:
                pathname === '/fxrates' ? '2px solid #efbc28' : 'none',
            }}
          >
            FX Rates
          </Link>
        </Box>
        }
        {menuPagesToShow.Guidance_Price &&
        <Box sx={commonStyle}>
          <Link
            to="/guidanceprice"
            style={{
              borderBottom:
                pathname === '/guidanceprice' ? '2px solid #efbc28' : 'none',
            }}
          >
            Guidance Price
          </Link>
        </Box>
        }
        {menuPagesToShow.Non_Playbook &&
        <Box sx={commonStyle}>
          <Link
            to="/nonPlayBook"
            style={{
              borderBottom:
                pathname === '/nonPlayBook' ? '2px solid #efbc28' : 'none',
            }}
          >
            Non Playbook
          </Link>
        </Box>
        }
        {showConfigurationMenu &&
        <Box sx={commonStyle}>
          <span
            style={{
              borderBottom:
                pathname === '/configuration' ? '2px solid #efbc28' : 'none',
              display: 'flex',
              fontWeight: '600',
            }}
            onClick={handleClick}
          >
            Configuration <ExpandMoreIcon />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              aria-labelledby="basic-demo-button"
            >

              { showCommodityMasterMenu &&
              <MenuItem
                id="submenu"
                sx={commonMenuStyles}
                onClick={()=>{
                  setAnchorEl2(!anchorEl2);
                  setAnchorEl3(false);
                }}
              >
              Commodity Master <ArrowForwardIosIcon sx={{fontSize: '12px', marginRight: '10px'}} />

              </MenuItem>
              }
              { showSectorMasterMenu &&
              <MenuItem
                id="submenu"
                sx={commonMenuStyles}
                onClick={()=>{
                  setAnchorEl3(!anchorEl3);
                  setAnchorEl2(false);
                }}
              >
              Sector Master <ArrowForwardIosIcon sx={{fontSize: '12px', marginRight: '10px'}} />

              </MenuItem>
              }
              { showUnitsMasterMenu &&
              <MenuItem onClick={handleClose} id="units"
                sx={commonMenuStyles}>
              Units Master</MenuItem>
              }
              { showUnitsConversionMasterMenu &&
              <MenuItem onClick={handleClose} id="unitsconversion"
                sx={commonMenuStyles}>
              Units Conversion Master</MenuItem>
              }
              { showCurrencyMasterMenu &&
              <MenuItem onClick={handleClose} id="currency"
                sx={commonMenuStyles}>
              Currency Master</MenuItem>
              }
              { showRolesAccessMenu &&
              <MenuItem onClick={handleClose} id="rolesaccess"
                sx={commonMenuStyles}>
              Roles & Access</MenuItem>
              }
              { showPlayBookMenu &&
              <MenuItem onClick={handleClose} id="playbook"
                sx={commonMenuStyles}>
              Playbook</MenuItem>
              }
              { showPlayBookMenu &&
              <MenuItem onClick={handleClose} id="nonplaybook"
                sx={commonMenuStyles}>
              Non Playbook</MenuItem>
              }
              { showBuyPlanMenu &&
              <MenuItem onClick={handleClose} id="buyplan"
                sx={commonMenuStyles}>
              Buy Plan</MenuItem>
              }
              {/* { showBuyPlanReport &&
              <MenuItem onClick={handleClose} id="buyplanreport"
                sx={commonMenuStyles}>
              Buy Plan Report Mapping</MenuItem>
              }
              { showPlaybookCompMap &&
              <MenuItem onClick={handleClose} id="playbookcompmap"
                sx={commonMenuStyles}>
              Playbook Compliance Mapping</MenuItem>
              } */}
              { showPlaybookCompMap &&
              <MenuItem onClick={handleClose} id="notificationSettings"
                sx={commonMenuStyles}>
              Notification Settings</MenuItem>
              }
            </Menu>
            <Box style={{display: anchorEl2 ? 'block' : 'none', background: 'white', right: '0px',
              transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)',
              borderRadius: '4px',
              position: 'absolute',
              right: '-80px',
              top: '25px',
              zIndex: '10000',
              boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              paddingTop: '8px',
              paddingBottom: '8px'}}
            sx={commonStyle}
            >

              <MenuItem onClick={handleClose} id="category"
                sx={commonSubMenuStyles}>
              Category</MenuItem>


              <MenuItem onClick={handleClose} id="commodity"
                sx={commonSubMenuStyles}>
              Commodity</MenuItem>


              <MenuItem onClick={handleClose} id="subcommodity"
                sx={commonSubMenuStyles}>
              Sub Commodity</MenuItem>


            </Box>

            <Box style={{display: anchorEl3 ? 'block' : 'none', background: 'white', right: '0px',
              transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)',
              borderRadius: '4px',
              position: 'absolute',
              right: '-70px',
              top: '56px',
              zIndex: '10000',
              boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
              paddingTop: '8px',
              paddingBottom: '8px'}}
            sx={commonStyle}
            >

              <MenuItem onClick={handleClose} id="mastersector"
                sx={commonSubMenuStyles}>
              Master Sector</MenuItem>


              <MenuItem onClick={handleClose} id="sector"
                sx={commonSubMenuStyles}>
              Sector</MenuItem>


              <MenuItem onClick={handleClose} id="country"
                sx={commonSubMenuStyles}>
              Country</MenuItem>

              <MenuItem onClick={handleClose} id="businessunit"
                sx={commonSubMenuStyles}>
              Business Unit</MenuItem>


            </Box>

          </span>


        </Box>
        }
        {showReportMenu &&
        <Box sx={commonStyle}>
          <span
            style={{
              borderBottom:
                pathname === '/report' ? '2px solid #efbc28' : 'none',
              display: 'flex',
              fontWeight: '600',
            }}
            onClick={handleClickR}
          >
            Reports <ExpandMoreIcon />
            <Menu
              id="basic-menu"
              anchorEl={anchorElR}
              open={openR}
              aria-labelledby="basic-demo-button"
            >
              { showBuyPlanReport &&
              <MenuItem onClick={handleCloseR} id="buyplanreport"
                sx={commonMenuStyles}>
                Buy Plan Report Mapping
              </MenuItem>
              }
              { showPlaybookCompMap &&
              <MenuItem onClick={handleCloseR} id="playbookcompmap"
                sx={commonMenuStyles}>
                PlayBook Compliance Mapping
              </MenuItem>
              }
              { showPowerBiReport &&
              <MenuItem onClick={handleCloseR} id="powerbireport"
                sx={commonMenuStyles}>
                Power Bi Report
              </MenuItem>
              }
            </Menu>
          </span>
        </Box>
        }

      </Box>

      { currentRole=='Central Admin' && <Typography
        sx={{
          font: 'normal normal 600 12px/21px \'Segoe UI\'',
          marginRight: '2em',
          cursor: 'pointer',
          color: 'white',
        }}
      >
        [v{process.env.REACT_APP_UI_VERSION}]
      </Typography>}

    </Box>
  );
}

export default SubHeader;
