/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import {floatColumn, keyColumn, textColumn} from 'react-datasheet-grid';
import {AppTableInput2} from '../common/AppTableInput/AppTableInput';
import {
  BackgroundColorHandler,
  BackgroundColorHandler2,
  BackgroundColorHandlerForYear,
  BoldingFyTotal,
  CoverageComponent,
  MinusComponent,
  MinusComponent2,
  ParentHeader,
  ParentHeader2,
  PlusComponent,
  PlusComponent2,
  VarianceChange,
  VarianceChange2,
} from './Components';
import {colorReturn, colorReturn2, colorReturn3} from './EditBuyPlanService';
import {months} from '../../services/utils';

/* eslint-disable max-len */
export const defaultObj = {
  market: '',
  period: '',
  planYear: null,
  volumeLE: null,
  coverage: null,
  sLastMonthCoverage: null,
  tLastMonthCoverage: null,
  coveragePercent: null,
  updatedFcst: null,
  targetPercentMin: null,
  targetPercentMax: null,
  minCoverage: null,
  maxCoverage: null,
  tminCoverage: null,
  tmaxCoverage: null,
  sminCoverage: null,
  smaxCoverage: null,
  smustCover: null,
  smayCover: null,
  swillCover: null,
  tmustCover: null,
  tmayCover: null,
  twillCover: null,
  owillCover: null,
  overAllWillCover: null,
  overAllCoverage: null,
  overAllCoveragePercent: null,
  newCoverage: null,
  newCoveragePercent: null,
  comments: null,
  targetCoveragePercent: null,
};

// const quarterMonths = ['Mar', 'Jun', 'Sep', 'Dec'];

export const varianceReasons = [
  'Forecast Change',
  'PO Reconciliation',
  'Supplier cannot supply/hedge',
  'Fixed lot sizes',
  'Lack of contract',
  'Others',
];

export const columnsUtil = (isPreviousYearData, execMonthDecider, handleGroups, handleEdit, isEdit, selectedRow, isRecall, quarterMonths, years, previousMonthWillCoverEditButton) => {
  const curPeriod = new Date();
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const isBeforeMonth = (rowData, month) => {
    const NotJanFeb = selectedRow.executionMonthYear[1]!==1 && selectedRow.executionMonthYear[1]!==2;
    if ((previousMonthWillCoverEditButton==false|| previousMonthWillCoverEditButton=='') && isPreviousYearData==true && +rowData.planYear<+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])] && NotJanFeb) {
      return true;
    }
    if (previousMonthWillCoverEditButton==true && NotJanFeb) {
      return (+rowData.planYear == +((isPreviousYearData==true?+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])]:+years[0])) && month >= (selectedRow?.executionMonthYear[1]-1 ==0?11: selectedRow?.executionMonthYear[1]-2)) || +rowData.planYear!=+(isPreviousYearData==true?+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])]:+years[0]);
    } else if (previousMonthWillCoverEditButton==true && !NotJanFeb) {
      return (+rowData.planYear == (+years[0])) && month >= (selectedRow?.executionMonthYear[1]-1 ==0?11: selectedRow?.executionMonthYear[1]==2 ? 12 : selectedRow?.executionMonthYear[1]-2) || +rowData.planYear!=+years[0];
    } else if ((previousMonthWillCoverEditButton==false|| previousMonthWillCoverEditButton=='') && NotJanFeb) {
      return (+rowData.planYear == +((isPreviousYearData==true?+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])]:+years[0]))) && month < (selectedRow?.executionMonthYear[1]-1 ==0?11: selectedRow?.executionMonthYear[1]-2);
    } else if ((previousMonthWillCoverEditButton==false|| previousMonthWillCoverEditButton=='') && !NotJanFeb) {
      return ((+rowData.planYear == +years[0]) && ((selectedRow.executionMonthYear[1] == 1 && +month<11) || (selectedRow.executionMonthYear[1] == 2 && +month<12)));
    }
  };
  if (selectedRow.optionality == false) {
    return {
      first: [
        {
          ...keyColumn('planYear', textColumn),
          component: ({rowData, rowIndex}) => (
            <BackgroundColorHandlerForYear
              rowIndex={rowIndex}
              sel='Year'
              value={rowData['planYear']}

              color={colorReturn3(rowData, rowIndex, selectedRow)}
            />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Year', color: 'first', info: ''}} />,
          disabled: true,
        },
        {
          ...keyColumn('period', textColumn),
          component: ({rowData, rowIndex}) => (
            <BackgroundColorHandlerForYear
              rowIndex={rowIndex}
              sel='Period'
              value={rowData['period']}

              color={colorReturn3(rowData, rowIndex, selectedRow)}
            />
            // <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='period' />
          ),
          title: <ParentHeader values={{parentTitle: '', lower: 'Period', color: 'first', info: ''}} />,
          disabled: true,
        },
      ],
      second2: [
        {
          ...keyColumn('coverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <CoverageComponent rowIndex={rowIndex} val={rowData} />
          ),
          width: 130,
          // title: (
          // <PlusComponent
          //   values={{
          //     parentTitle: 'Last month-Overall',
          //     lower: 'Coverage',
          //     color: 'second',
          //     click: (e) => {
          //       handleGroups('plus', 'second2');
          //     },
          //   }}
          // />
          // ),
          title: (
            <div><PlusComponent2
              values={{
                parentTitle: 'Last month-Overall',
                lower: 'Coverage',
                color: 'second', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
                click: () => handleGroups('plus', 'second2'),
              }} /></div>

          ),
          disabled: true,
        },
      ],
      second: [
        {
          ...keyColumn('volumeLE', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='volumeLE' />
          ),
          title: (
            <MinusComponent2
              values={{
                info: `Prior Month Volume Forecast`,
                parentTitle: '',
                lower: 'Volume LE',
                color: 'second',
                click: (e) => {
                  handleGroups('plus', 'second');
                },
              }}
            />
          ),
          disabled: true,
        },
        {
          ...keyColumn('coverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <CoverageComponent rowIndex={rowIndex} val={rowData} />
          ),
          title: <ParentHeader values={{parentTitle: '', lower: 'Coverage', color: 'second', info: 'Prior Month Coverage:  Coverage that was locked last month (This amount should be consistent with previous month coverage commitments)'}} />,
          disabled: true,
        },
        {
          ...keyColumn('coveragePercent', textColumn),
          component: ({rowData, rowIndex}) => (
            <BackgroundColorHandler
              rowIndex={rowIndex}

              value={rowData['coveragePercent']}
              color={colorReturn(rowData)}
            />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Coverage %', color: 'second', info: 'Coverage as of prior month end'}} />,
          disabled: true,
          width: 120,
        },
        {
          ...keyColumn('varianceReason', textColumn),
          component: ({rowData, rowIndex}) => (
            <VarianceChange2
              rowIndex={rowIndex}
              quarterMonths={quarterMonths}
              varianceReasons={varianceReasons}
              rowData={rowData}
              columnName='varianceReason'
              handleEdit={handleEdit}
              color={colorReturn(rowData)}
            />
          ),
          title: <ParentHeader values={{parentTitle: 'Last month-Overall', lower: 'Variance Reason', color: 'second', info: `If last month's coverage reported differs from what last month's buy plan commitments, an explanation must be provided.`}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall) {
              return true;
            }
            if (rowIndex !== 12 && rowIndex !== 25 && rowIndex !== 38) return (colorReturn(rowData) == 'green' ? true : false);
            if (rowIndex == 12 || rowIndex == 25 || rowIndex == 38) return true;
          },
          minWidth: 130,
        },
        {
          ...keyColumn('comments', textColumn),
          component: ({rowData, rowIndex, focus}) => (
            <BackgroundColorHandler2
              rowIndex={rowIndex}
              type="comments"
              value={rowData['comments']}
              columnName='comments'
              handleEdit={handleEdit}
              rowData={rowData}
              color={colorReturn(rowData)}
              focus={focus}
            />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Comments', color: 'second', info: `If last month's coverage reported differs from what last month's buy plan commitments, an explanation must be provided.`}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            // console.log(isRecall, isEdit);
            if (isRecall) {
              return true;
            }
            if (rowIndex !== 12 && rowIndex !== 25 && rowIndex !== 38) return (colorReturn(rowData) == 'green' ? true : false);
            if (rowIndex == 12 || rowIndex == 25 || rowIndex == 38) return true;
          },
        },
      ],
      third2: [
        {
          ...keyColumn('updatedFcst', textColumn),
          minWidth: 120,
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='updatedFcst' />
          ),
          title: (
            <div><PlusComponent2
              values={{
                parentTitle: 'Overall',
                lower: 'Updated Fcst',
                color: 'third', info: `Current forecast for current buy plan month`,
                click: () => handleGroups('plus', 'third2'),
              }} /></div>

          ),
          disabled: true,
        },
      ],
      third: [
        {
          ...keyColumn('updatedFcst', textColumn),
          minWidth: 120,
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='updatedFcst' />
          ),
          title: (

            <div><MinusComponent2
              values={{
                parentTitle: null,
                lower: 'Updated Fcst',
                color: 'third', info: `Current forecast for current buy plan month`,
                click: () => handleGroups('minus', 'third'),
              }} /></div>


          ),
          disabled: true,
        },
        {
          ...keyColumn('targetPercentMin', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='targetPercentMin' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target %', color: 'third', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('minCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='minCoverage' />
          ),
          title: <ParentHeader pixels={'-50px'} values={{parentTitle: 'Overall', lower: 'Min Coverage', color: 'third', info: `Minimum Total coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('mustCover', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='mustCover' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'third', info: `The minimum volume that must be taken in the current month. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
          disabled: true,
        },
      ],
      fourthS: [
        {
          ...keyColumn('swillCover', textColumn),
          minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='swillCover'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: (
            <div><PlusComponent2
              values={{
                parentTitle: 'Supplier',
                lower: 'Will cover',
                color: 'fourth', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
                click: () => handleGroups('plus', 'fourthS'),
              }} /></div>

          ),
          // title: (
          //   <PlusComponent
          //     values={{
          //       parentTitle: 'Supplier',
          //       lower: 'Will cover',
          //       color: 'fourth',
          //       click: () => handleGroups('plus', 'fourthS'),
          //     }}
          //   />
          // ),
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ],
      fourth: selectedRow.targetCoverageMode === 'SUPPLIER' || selectedRow.targetCoverageMode === 'BOTH'? (selectedRow.supplierOptionality ? [
        {
          ...keyColumn('sLastMonthCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sLastMonthCoverage' />
          ),
          title: (
            <MinusComponent2
              values={{
                info: `Prior Month Coverage:  Coverage that was locked last month from Supplier`,
                parentTitle: null,
                lower: 'Last month coverage',
                color: 'fourth',
                click: () => handleGroups('minus', 'fourth'),
              }}
            />
          ),
          disabled: true,
        },
        {
          ...keyColumn('stargetPercentMin', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='stargetPercentMin' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target % Min', color: 'fourth', info: `Minimum coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('stargetPercentMax', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='stargetPercentMax' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target % Max', color: 'fourth', info: `Maximum coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Min" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('sminCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sminCoverage' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Min Coverage', color: 'fourth', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with supplier (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('smaxCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smaxCoverage' />
          ),
          title: <ParentHeader values={{parentTitle: 'Supplier', lower: 'Max Coverage', color: 'fourth', info: `Maximum Total coverage volume requirement per playbook requirements for this month to be taken with supplier (Applies to programs with optionality and will be identical to "Min Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('smustCover', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smustCover' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth', info: `The minimum volume that must be taken in the current month with supplier. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('smayCover', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smayCover' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: '"May" Cover', color: 'fourth', info: `The maxiumum volume that must be taken in the current month with supplier. Maximum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Must Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('swillCover', textColumn),
          minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='swillCover'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', info: 'Coverage that will be taken in the current month with supplier. This should be the volume communicated to the supplier and be within min/max cover', color: 'fourth'}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
        selectedRow?.is_carry_vol_enabled && {
          ...keyColumn('sCarryValue', textColumn),
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}
            focus={focus}
            rowIndex={rowIndex}
            columnName='sCarryValue'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader values={{parentTitle: null, lower: 'Carry Volume', info: 'carry', color: 'fourth'}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ].filter(Boolean) : [
        {
          ...keyColumn('sLastMonthCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sLastMonthCoverage' />
          ),
          title: (
            <MinusComponent2
              values={{
                info: `Prior Month Coverage:  Coverage that was locked last month from Supplier`,
                parentTitle: null,
                lower: 'Last month coverage',
                color: 'fourth',
                click: () => handleGroups('minus', 'fourth'),
              }}
            />
          ),
          disabled: true,
        },
        {
          ...keyColumn('stargetPercentMin', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='stargetPercentMin' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target %', color: 'fourth', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('sminCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sminCoverage' />
          ),
          title: <ParentHeader values={{parentTitle: 'Supplier', lower: 'Min Coverage', color: 'fourth', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with supplier (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('smustCover', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smustCover' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth', info: `The minimum volume that must be taken in the current month with supplier. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('swillCover', textColumn),
          minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='swillCover'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', info: 'Coverage that will be taken in the current month with supplier. This should be the volume communicated to the supplier and be within min/max cover', color: 'fourth'}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        }, selectedRow?.is_carry_vol_enabled && {
          ...keyColumn('sCarryValue', textColumn),
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}
            focus={focus}
            rowIndex={rowIndex}
            columnName='sCarryValue'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader values={{parentTitle: null, lower: 'Carry Volume', info: 'carry', color: 'fourth'}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ].filter(Boolean)) : [
        {
          ...keyColumn('sLastMonthCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sLastMonthCoverage' />
          ),
          title: (
            <MinusComponent2
              values={{
                info: `Prior Month Coverage:  Coverage that was locked last month from Supplier`,
                parentTitle: null,
                lower: 'Last month coverage',
                color: 'fourth',
                click: () => handleGroups('minus', 'fourth'),
              }}
            />
          ),
          disabled: true,
        },
        {
          ...keyColumn('swillCover', textColumn),
          minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='swillCover'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader pixels={'-50px'} values={{parentTitle: 'Supplier', info: 'Coverage that will be taken in the current month with supplier. This should be the volume communicated to the supplier and be within min/max cover', lower: 'Will cover', color: 'fourth'}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        }, selectedRow?.is_carry_vol_enabled && {
          ...keyColumn('sCarryValue', textColumn),
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}
            focus={focus}
            rowIndex={rowIndex}
            columnName='sCarryValue'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader values={{parentTitle: null, lower: 'Carry Volume', info: 'carry', color: 'fourth'}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ].filter(Boolean),
      fourth2T: [
        {
          ...keyColumn('twillCover', textColumn),
          minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='twillCover'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: (
            <div><PlusComponent2
              values={{
                parentTitle: 'Treasury',
                lower: 'Will cover',
                color: 'fourth2', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
                click: () => handleGroups('plus', 'fourth2T'),
              }} /></div>

          ),
          // title: (
          //   <PlusComponent
          //     values={{
          //       parentTitle: 'Treasury',
          //       lower: 'Will cover',
          //       color: 'fourth2',
          //       click: () => handleGroups('plus', 'fourth2T'),
          //     }}
          //   />
          // ),
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ],
      fourth2: selectedRow.targetCoverageMode === 'TREASURY' || selectedRow.targetCoverageMode === 'BOTH' ? (selectedRow.treasuryOptionality ? [
        {
          ...keyColumn('tLastMonthCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tLastMonthCoverage' />
          ),
          title: (
            <MinusComponent2
              values={{
                info: `Prior Month Coverage:  Coverage that was locked last month from Treasury`,
                parentTitle: null,
                lower: 'Last month coverage',
                color: 'fourth2',
                click: () => handleGroups('minus', 'fourth2'),
              }}
            />
          ),
          disabled: true,
        },
        {
          ...keyColumn('ttargetPercentMin', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='ttargetPercentMin' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target % Min', color: 'fourth2', info: `Minimum coverage % per playbook requirements for this month with Treasury (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('ttargetPercentMax', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='ttargetPercentMax' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target % Max', color: 'fourth2', info: `Maximum coverage % per playbook requirements for this month with Treasury (Applies to programs with optionality and will be identical to "% Min" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('tminCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tminCoverage' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Min Coverage', color: 'fourth2', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with Treasury (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('tmaxCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmaxCoverage' />
          ),
          title: <ParentHeader values={{parentTitle: 'Treasury', lower: 'Max Coverage', color: 'fourth2', info: `Maximum Total coverage volume requirement per playbook requirements for this month to be taken with Treasury (Applies to programs with optionality and will be identical to "Min Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('tmustCover', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmustCover' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth2', info: `The minimum volume that must be taken in the current month with Treasury. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('tmayCover', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmayCover' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: '"May" Cover', color: 'fourth2', info: `The maxiumum volume that must be taken in the current month with Treasury. Maximum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Must Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('twillCover', textColumn),
          minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='twillCover'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', color: 'fourth2', info: `Coverage that will be taken in the current month with Treasury. This should be the volume commuincated to Treasury and be within min/max cover`}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ] : [
        {
          ...keyColumn('tLastMonthCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tLastMonthCoverage' />
          ),
          title: (
            <MinusComponent2
              values={{
                info: `Prior Month Coverage:  Coverage that was locked last month from Treasury`,
                parentTitle: null,
                lower: 'Last month coverage',
                color: 'fourth2',
                click: () => handleGroups('minus', 'fourth2'),
              }}
            />
          ),
          disabled: true,
        },
        {
          ...keyColumn('ttargetPercentMin', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='ttargetPercentMin' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target %', color: 'fourth2', info: `Coverage % per playbook requirements for this month with Treasury (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('tminCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tminCoverage' />
          ),
          title: <ParentHeader values={{parentTitle: 'Treasury', lower: 'Min Coverage', color: 'fourth2', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with Treasury (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('tmustCover', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmustCover' />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth2', info: `The minimum volume that must be taken in the current month with Treasury. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('twillCover', textColumn),
          minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='twillCover'
            handleEdit={handleEdit}
            isEdit={isEdit}
            rowData={rowData} />,
          title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', color: 'fourth2', info: `Coverage that will be taken in the current month with Treasury. This should be the volume commuincated to Treasury and be within min/max cover`}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ]) : [{
        ...keyColumn('tLastMonthCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tLastMonthCoverage' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Coverage:  Coverage that was locked last month from Treasury`,
              parentTitle: null,
              lower: 'Last month coverage',
              color: 'fourth2',
              click: () => handleGroups('minus', 'fourth2'),
            }}
          />
        ),
        disabled: true,
      },
      {
        ...keyColumn('twillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='twillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader pixels={'-38px'} values={{parentTitle: 'Treasury', lower: 'Will cover', color: 'fourth2', info: `Coverage that will be taken in the current month with Treasury. This should be the volume commuincated to Treasury and be within min/max cover`}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      }],
      fourPointFive: [{
        ...keyColumn('oLastMonthCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='oLastMonthCoverage' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Coverage:  Coverage that was locked last month from Others`,
              parentTitle: null,
              lower: 'Last month coverage',
              color: 'third',
              click: () => handleGroups('minus', 'fourPointFive'),
            }}
          />
        ),
        disabled: true,
      }, {
        ...keyColumn('owillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='owillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader
          pixels={'-50px'} values={{parentTitle: 'Others', lower: 'Other will cover', info: 'Coverage that will be taken in the current month with Others (Only applicable when there is alternatives to Supplier / Treasury Hedging). This should be within min/max cover', color: 'third'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total') {
            return true;
          }
          return false;
        },
      }],
      fourPointFive2: [{
        ...keyColumn('owillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='owillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: (
          <div><PlusComponent2
            values={{
              parentTitle: 'Others',
              lower: 'Other will cover',
              color: 'third', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
              click: () => handleGroups('plus', 'fourPointFive2'),
            }} /></div>

        ),
        // title: <ParentHeader values={{parentTitle: null, lower: 'Other will cover', color: 'third'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total') {
            return true;
          }
          return false;
        },
      }],
      fifth2: [
        {
          ...keyColumn('overAllWillCover', textColumn), minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='overAllWillCover'
            isEdit={isEdit}
            handleEdit={handleEdit}
            rowData={rowData} />,
          title: (
            <div><PlusComponent2
              values={{
                parentTitle: 'Overall-Monthly',
                lower: 'Will cover',
                color: 'fifth', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
                click: () => handleGroups('plus', 'fifth2'),
              }} /></div>

          ),
          // title: (
          //   <PlusComponent
          //     values={{
          //       parentTitle: 'Overall-Monthly',
          //       lower: 'Will cover',
          //       color: 'fifth',
          //       click: () => handleGroups('plus', 'fifth2'),
          //     }}
          //   />
          // ),
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || selectedRow.coverageMode=='SUPPLIER' || selectedRow.coverageMode=='TREASURY' || selectedRow.coverageMode=='BOTH' || selectedRow.others==true || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
      ],
      fifth: [
        {
          ...keyColumn('overAllWillCover', textColumn), minWidth: 130,
          component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

            focus={focus}
            rowIndex={rowIndex}
            columnName='overAllWillCover'
            isEdit={isEdit}
            handleEdit={handleEdit}
            rowData={rowData} />,
          title: (
            <MinusComponent2
              values={{
                info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
                parentTitle: '',
                lower: 'Will cover',
                color: 'fifth',
                click: () => handleGroups('minus', 'fifth'),
              }}
            />
          ),
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || isBeforeMonth(rowData, month) || selectedRow.coverageMode=='SUPPLIER' || selectedRow.coverageMode=='TREASURY' || selectedRow.coverageMode=='BOTH' || selectedRow.others==true || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
              return true;
            }
            return false;
          },
        },
        {
          ...keyColumn('overAllCoverage', textColumn),
          component: ({rowData, rowIndex}) => (
            <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='overAllCoverage' />
          ),
          title: <ParentHeader values={{parentTitle: 'Overall-Monthly', lower: 'New Coverage', color: 'fifth', info: `New Total Coverage inclusive of current month "Will Cover" (across Supplier & Treasury)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('overAllCoveragePercent', textColumn),
          component: ({rowData, rowIndex}) => (
            <BackgroundColorHandler
              rowIndex={rowIndex}

              value={rowData['overAllCoveragePercent']}
              color={colorReturn3(rowData, rowIndex, selectedRow)}
            />
          ),
          title: (
            <ParentHeader
              values={{
                parentTitle: null,
                lower: 'New Coverage %',
                color: 'fifth',
                info: `New Total Coverage % inclusive of current month "Will Cover" (across Supplier & Treasury)`,
                click: () => handleGroups('minus', 'sixth'),
              }}
            />
          ),
          disabled: true,
        },
      ],
      sixth: [
        {
          ...keyColumn('newCoveragePercent', textColumn),
          component: ({rowData, rowIndex}) => (
            <BackgroundColorHandler
              rowIndex={rowIndex}

              value={rowData['newCoveragePercent']}
              color={colorReturn2(rowData)}
            />
          ),
          minWidth: 120,
          title: <ParentHeader values={{parentTitle: null, lower: 'New Coverage %', color: 'sixth', info: `New Total Coverage % inclusive of current month "Will Cover" (across Supplier & Treasury)`}} />,
          disabled: true,
        },
        {
          ...keyColumn('targetCoveragePercentMin', textColumn),
          component: ({rowData, rowIndex}) => (
            <BackgroundColorHandler
              rowIndex={rowIndex}

              value={rowData['targetCoveragePercentMin']}
              color={colorReturn2(rowData)}
            />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Target Coverage %', color: 'sixth', info: `Target Coverage % for the Quarter`}} />,
          disabled: true,
          minWidth: 120,
        },
        {
          ...keyColumn('targetVariancePercent', textColumn),
          component: ({rowData, rowIndex}) => (
            <BackgroundColorHandler
              rowIndex={rowIndex}

              value={rowData['targetVariancePercent']}
              color={colorReturn2(rowData)}
            />
          ),
          title: <ParentHeader values={{parentTitle: 'Quarterly', lower: 'Target Variance %', color: 'sixth', info: `Delta between Quarterly Coverage and Quarterly Target`}} />,
          disabled: true,
          minWidth: 120,
        },
        {
          ...keyColumn('qvarianceReason', textColumn),
          component: ({rowData, rowIndex}) => {
            return <VarianceChange
              quarterMonths={quarterMonths}
              rowIndex={rowIndex}
              varianceReasons={varianceReasons}
              rowData={rowData}
              columnName={'qvarianceReason'}
              handleEdit={handleEdit}
              color={colorReturn2(rowData)}
            />;
          },
          title: <ParentHeader values={{parentTitle: null, lower: 'Variance Reason', color: 'sixth', info: `Explanation for delta between Quarterly Coverage and Quarterly Target (required if variance > 2.5%)`}} />,
          disabled: ({rowData, rowIndex}) =>{
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || !quarterMonths?.includes(rowData.period)) {
              return true;
            }
            return colorReturn2(rowData) == 'green' ? true : false;
          },
          minWidth: 130,
        },
        {
          ...keyColumn('qcomments', textColumn),
          component: ({rowData, rowIndex, focus}) => (
            <BackgroundColorHandler
              rowIndex={rowIndex}
              type="comments"
              value={rowData['qcomments']}
              rowData={rowData}
              columnName='qcomments'
              handleEdit={handleEdit}
              color={colorReturn2(rowData)}
              focus={focus}
            />
          ),
          title: <ParentHeader values={{parentTitle: null, lower: 'Comments', color: 'sixth', info: `Free Text commentary for delta between Quarterly Coverage and Quarterly Target (required if variance > 2.5%)`}} />,
          disabled: ({rowData, rowIndex}) => {
            const month = monthArr.indexOf(rowData.period)+1;
            if (isRecall || !quarterMonths?.includes(rowData.period)) {
              return true;
            }
            return (colorReturn2(rowData) == 'green') ? true : false;
          },
          minWidth: 120,
        },
      ],
    };
  }
  return {
    first: [
      {
        ...keyColumn('planYear', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandlerForYear
            rowIndex={rowIndex}
            sel='Year'
            value={rowData['planYear']}

            color={colorReturn3(rowData, rowIndex, selectedRow)} />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Year', color: 'first', info: ''}} />,
        disabled: true,
      },
      {
        ...keyColumn('period', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandlerForYear
            rowIndex={rowIndex}
            sel='Period'

            value={rowData['period']}

            color={colorReturn3(rowData, rowIndex, selectedRow)}
          />
          // <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='period' />
        ),
        title: <ParentHeader values={{parentTitle: '', lower: 'Period', color: 'first', info: ''}} />,
        disabled: true,
      },
    ],
    second2: [
      {
        ...keyColumn('coverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <CoverageComponent rowIndex={rowIndex} val={rowData} />
        ),
        width: 130,
        title: (
          <div><PlusComponent2
            values={{
              parentTitle: 'Last month-Overall',
              lower: 'Coverage',
              color: 'second', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
              click: () => handleGroups('plus', 'second2'),
            }} /></div>

        ),
        // title: (
        //   <PlusComponent
        //     values={{
        //       parentTitle: 'Last month-Overall',
        //       lower: 'Coverage',
        //       color: 'second',
        //       click: (e) => {
        //         handleGroups('plus', 'second2');
        //       },
        //     }}
        //   />
        // ),
        disabled: true,
      },
    ],
    second: [
      {
        ...keyColumn('volumeLE', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='volumeLE' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Volume Forecast`,
              parentTitle: '',
              lower: 'Volume LE',
              color: 'second',
              click: (e) => {
                handleGroups('plus', 'second');
              },
            }}
          />
        ),
        disabled: true,
      },
      {
        ...keyColumn('coverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <CoverageComponent rowIndex={rowIndex} val={rowData} />
        ),
        title: <ParentHeader values={{parentTitle: '', lower: 'Coverage', color: 'second', info: 'Prior Month Coverage:  Coverage that was locked last month (This amount should be consistent with previous month coverage commitments)'}} />,
        disabled: true,
      },
      {
        ...keyColumn('coveragePercent', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandler
            rowIndex={rowIndex}

            value={rowData['coveragePercent']}
            color={colorReturn(rowData)}
          />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Coverage %', color: 'second', info: 'Coverage as of prior month end'}} />,
        disabled: true,
        width: 120,
      },
      {
        ...keyColumn('varianceReason', textColumn),
        component: ({rowData, rowIndex}) => (
          <VarianceChange2
            quarterMonths={quarterMonths}
            rowIndex={rowIndex}
            varianceReasons={varianceReasons}
            rowData={rowData}
            columnName='varianceReason'
            handleEdit={handleEdit}
            color={colorReturn(rowData)}
          />
        ),
        title: <ParentHeader values={{parentTitle: 'Last month-Overall', lower: 'Variance Reason', color: 'second', info: `If last month's coverage reported differs from what last month's buy plan commitments, an explanation must be provided.`}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall) {
            return true;
          }
          if (rowIndex !== 12 && rowIndex !== 25 && rowIndex !== 38) return (colorReturn(rowData) == 'green' ? true : false);
          if (rowIndex == 12 || rowIndex == 25 || rowIndex == 38) return true;
        },
        minWidth: 130,
      },
      {
        ...keyColumn('comments', textColumn),
        component: ({rowData, rowIndex, focus}) => (
          <BackgroundColorHandler2
            rowIndex={rowIndex}
            type="comments"
            value={rowData['comments']}
            columnName='comments'
            handleEdit={handleEdit}
            rowData={rowData}
            color={colorReturn(rowData)}
            focus={focus}
          />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Comments', color: 'second', info: `If last month's coverage reported differs from what last month's buy plan commitments, an explanation must be provided.`}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall) {
            return true;
          }
          if (rowIndex !== 12 && rowIndex !== 25 && rowIndex !== 38) return (colorReturn(rowData) == 'green' ? true : false);
          if (rowIndex == 12 || rowIndex == 25 || rowIndex == 38) return true;
        },
      },
    ],
    third2: [
      {
        ...keyColumn('updatedFcst', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='updatedFcst' />
        ),
        minWidth: 120,
        title: (
          <div><PlusComponent2
            values={{
              parentTitle: 'Overall',
              lower: 'Updated Fcst',
              color: 'third', info: `Current forecast for current buy plan month`,
              click: () => handleGroups('plus', 'third2'),
            }} /></div>

        ),
        disabled: true,
      },
    ],
    third: [
      {
        ...keyColumn('updatedFcst', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='updatedFcst' />
        ),
        minWidth: 120,
        title: (

          <div><MinusComponent2
            values={{
              info: `Current forecast for current buy plan month`,
              parentTitle: null,
              lower: 'Updated Fcst',
              color: 'third',
              click: () => handleGroups('minus', 'third'),
            }} /></div>


        ),
        disabled: true,
      },
      {
        ...keyColumn('targetPercentMin', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='targetPercentMin' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target % Min', color: 'third', info: `Minimum coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('targetPercentMax', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='targetPercentMax' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target % Max', color: 'third', info: `Maximum coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Min" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('minCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='minCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: 'Overall', lower: 'Min Coverage', color: 'third', info: `Minimum Total coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('maxCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='maxCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Max Coverage', color: 'third', info: `Maximum Total coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Min Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('mustCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='mustCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'third', info: `The minimum volume that must be taken in the current month. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('mayCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='mayCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"May" Cover', color: 'third', info: `The maxiumum volume that must be taken in the current month. Maximum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Must Coverage" if no optionality present)`}} />,
        disabled: true,
      },
    ],
    fourthS: [
      {
        ...keyColumn('swillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='swillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: (
          <div><PlusComponent2
            values={{
              parentTitle: 'Supplier',
              lower: 'Will cover',
              color: 'fourth', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
              click: () => handleGroups('plus', 'fourthS'),
            }} /></div>

        ),
        // title: (
        //   <PlusComponent
        //     values={{
        //       parentTitle: 'Supplier',
        //       lower: 'Will cover',
        //       color: 'fourth',
        //       click: () => handleGroups('plus', 'fourthS'),
        //     }}
        //   />
        // ),
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ],
    fourth: selectedRow.targetCoverageMode === 'SUPPLIER' || selectedRow.targetCoverageMode === 'BOTH'? (selectedRow.supplierOptionality ? [
      {
        ...keyColumn('sLastMonthCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sLastMonthCoverage' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Coverage:  Coverage that was locked last month from Supplier`,
              parentTitle: null,
              lower: 'Last month coverage',
              color: 'fourth',
              click: () => handleGroups('minus', 'fourth'),
            }}
          />
        ),
        disabled: true,
      },
      {
        ...keyColumn('stargetPercentMin', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='stargetPercentMin' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target % Min', color: 'fourth', info: `Minimum coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('stargetPercentMax', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='stargetPercentMax' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target % Max', color: 'fourth', info: `Maximum coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Min" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('sminCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sminCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Min Coverage', color: 'fourth', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with supplier (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('smaxCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smaxCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: 'Supplier', lower: 'Max Coverage', color: 'fourth', info: `Maximum Total coverage volume requirement per playbook requirements for this month to be taken with supplier (Applies to programs with optionality and will be identical to "Min Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('smustCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smustCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth', info: `The minimum volume that must be taken in the current month with supplier. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('smayCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smayCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"May" Cover', color: 'fourth', info: `The maxiumum volume that must be taken in the current month with supplier. Maximum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Must Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('swillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='swillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', info: 'Coverage that will be taken in the current month with supplier. This should be the volume communicated to the supplier and be within min/max cover', color: 'fourth'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      }, selectedRow?.is_carry_vol_enabled && {
        ...keyColumn('sCarryValue', textColumn),
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}
          focus={focus}
          rowIndex={rowIndex}
          columnName='sCarryValue'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader values={{parentTitle: null, lower: 'Carry Volume', info: 'carry', color: 'fourth'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ].filter(Boolean) : [
      {
        ...keyColumn('sLastMonthCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sLastMonthCoverage' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Coverage:  Coverage that was locked last month from Supplier`,
              parentTitle: null,
              lower: 'Last month coverage',
              color: 'fourth',
              click: () => handleGroups('minus', 'fourth'),
            }}
          />
        ),
        disabled: true,
      },
      {
        ...keyColumn('stargetPercentMin', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='stargetPercentMin' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target %', color: 'fourth', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('sminCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sminCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: 'Supplier', lower: 'Min Coverage', color: 'fourth', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with supplier (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('smustCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='smustCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth', info: `The minimum volume that must be taken in the current month with supplier. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('swillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='swillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', info: 'Coverage that will be taken in the current month with supplier. This should be the volume communicated to the supplier and be within min/max cover', color: 'fourth'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      }, selectedRow?.is_carry_vol_enabled && {
        ...keyColumn('sCarryValue', textColumn),
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}
          focus={focus}
          rowIndex={rowIndex}
          columnName='sCarryValue'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader values={{parentTitle: null, lower: 'Carry Volume', info: 'carry', color: 'fourth'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ].filter(Boolean)) : [
      {
        ...keyColumn('sLastMonthCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='sLastMonthCoverage' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Coverage:  Coverage that was locked last month from Supplier`,
              parentTitle: null,
              lower: 'Last month coverage',
              color: 'fourth',
              click: () => handleGroups('minus', 'fourth'),
            }}
          />
        ),
        disabled: true,
      },
      {
        ...keyColumn('swillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='swillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader pixels={'-50px'} values={{parentTitle: 'Supplier', info: 'Coverage that will be taken in the current month with supplier. This should be the volume communicated to the supplier and be within min/max cover', lower: 'Will cover', color: 'fourth'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      }, selectedRow?.is_carry_vol_enabled && {
        ...keyColumn('sCarryValue', textColumn),
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}
          focus={focus}
          rowIndex={rowIndex}
          columnName='sCarryValue'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader values={{parentTitle: null, lower: 'Carry Volume', info: 'carry', color: 'fourth'}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ].filter(Boolean),
    fourth2T: [
      {
        ...keyColumn('twillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='twillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: (
          <div><PlusComponent2
            values={{
              parentTitle: 'Treasury',
              lower: 'Will cover',
              color: 'fourth2', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
              click: () => handleGroups('plus', 'fourth2T'),
            }} /></div>

        ),
        // title: (
        //   <PlusComponent
        //     values={{
        //       parentTitle: 'Treasury',
        //       lower: 'Will cover',
        //       color: 'fourth2',
        //       click: () => handleGroups('plus', 'fourth2T'),
        //     }}
        //   />
        // ),
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ],
    fourth2: selectedRow.targetCoverageMode === 'TREASURY' || selectedRow.targetCoverageMode === 'BOTH' ? (selectedRow.treasuryOptionality ? [
      {
        ...keyColumn('tLastMonthCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tLastMonthCoverage' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Coverage:  Coverage that was locked last month from Treasury`,
              parentTitle: null,
              lower: 'Last month coverage',
              color: 'fourth2',
              click: () => handleGroups('minus', 'fourth2'),
            }}
          />
        ),
        disabled: true,
      },
      {
        ...keyColumn('ttargetPercentMin', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='ttargetPercentMin' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target % Min', color: 'fourth2', info: `Minimum coverage % per playbook requirements for this month with Treasury (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('ttargetPercentMax', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='ttargetPercentMax' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target % Max', color: 'fourth2', info: `Maximum coverage % per playbook requirements for this month with Treasury (Applies to programs with optionality and will be identical to "% Min" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('tminCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tminCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Min Coverage', color: 'fourth2', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with Treasury (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('tmaxCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmaxCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: 'Treasury', lower: 'Max Coverage', color: 'fourth2', info: `Maximum Total coverage volume requirement per playbook requirements for this month to be taken with Treasury (Applies to programs with optionality and will be identical to "Min Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('tmustCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmustCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth2', info: `The minimum volume that must be taken in the current month with Treasury. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('tmayCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmayCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"May" Cover', color: 'fourth2', info: `The maxiumum volume that must be taken in the current month with Treasury. Maximum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "Must Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('twillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='twillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', color: 'fourth2', info: `Coverage that will be taken in the current month with Treasury. This should be the volume commuincated to Treasury and be within min/max cover`}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ] : [
      {
        ...keyColumn('tLastMonthCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tLastMonthCoverage' />
        ),
        title: (
          <MinusComponent2
            values={{
              info: `Prior Month Coverage:  Coverage that was locked last month from Treasury`,
              parentTitle: null,
              lower: 'Last month coverage',
              color: 'fourth2',
              click: () => handleGroups('minus', 'fourth2'),
            }}
          />
        ),
        disabled: true,
      },
      {
        ...keyColumn('ttargetPercentMin', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='ttargetPercentMin' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target %', color: 'fourth2', info: `Coverage % per playbook requirements for this month with Treasury (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('tminCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tminCoverage' />
        ),
        title: <ParentHeader pixels={'50px'} values={{parentTitle: 'Treasury', lower: 'Min Coverage', color: 'fourth2', info: `Minimum Total coverage volume requirement per playbook requirements for this month to be taken with Treasury (Applies to programs with optionality and will be identical to "Max Coverage" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('tmustCover', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tmustCover' />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: '"Must" Cover', color: 'fourth2', info: `The minimum volume that must be taken in the current month with Treasury. Minimum incremental coverage volume requirement per playbook requirements for this month (Applies to programs with optionality and will be identical to "May Cover" if no optionality present)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('twillCover', textColumn),
        minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='twillCover'
          handleEdit={handleEdit}
          isEdit={isEdit}
          rowData={rowData} />,
        title: <ParentHeader values={{parentTitle: null, lower: 'Will cover', color: 'fourth2', info: `Coverage that will be taken in the current month with Treasury. This should be the volume commuincated to Treasury and be within min/max cover`}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ]) : [{
      ...keyColumn('tLastMonthCoverage', textColumn),
      component: ({rowData, rowIndex}) => (
        <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='tLastMonthCoverage' />
      ),
      title: (
        <MinusComponent2
          values={{
            info: `Prior Month Coverage:  Coverage that was locked last month from Treasury`,
            parentTitle: null,
            lower: 'Last month coverage',
            color: 'fourth2',
            click: () => handleGroups('minus', 'fourth2'),
          }}
        />
      ),
      disabled: true,
    },
    {
      ...keyColumn('twillCover', textColumn),
      minWidth: 130,
      component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

        focus={focus}
        rowIndex={rowIndex}
        columnName='twillCover'
        handleEdit={handleEdit}
        isEdit={isEdit}
        rowData={rowData} />,
      title: <ParentHeader pixels={'-50px'} values={{parentTitle: 'Treasury', lower: 'Will cover', color: 'fourth2', info: `Coverage that will be taken in the current month with Treasury. This should be the volume commuincated to Treasury and be within min/max cover`}} />,
      disabled: ({rowData, rowIndex}) =>{
        const month = monthArr.indexOf(rowData.period)+1;
        if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
          return true;
        }
        return false;
      },
    }],
    fourPointFive: [{
      ...keyColumn('oLastMonthCoverage', textColumn),
      component: ({rowData, rowIndex}) => (
        <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='oLastMonthCoverage' />
      ),
      title: (
        <MinusComponent2
          values={{
            info: `Prior Month Coverage:  Coverage that was locked last month from Others`,
            parentTitle: null,
            lower: 'Last month coverage',
            color: 'third',
            click: () => handleGroups('minus', 'fourPointFive'),
          }}
        />
      ),
      disabled: true,
    }, {
      ...keyColumn('owillCover', textColumn),
      minWidth: 130,
      component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

        focus={focus}
        rowIndex={rowIndex}
        columnName='owillCover'
        handleEdit={handleEdit}
        isEdit={isEdit}
        rowData={rowData} />,
      title: <ParentHeader
        pixels={'-50px'} values={{parentTitle: 'Others', lower: 'Other will cover', info: '\nCoverage that will be taken in the current month with Others (Only applicable when there is alternatives to Supplier / Treasury Hedging). This should be within min/max cover', color: 'third'}} />,
      disabled: ({rowData, rowIndex}) =>{
        const month = monthArr.indexOf(rowData.period)+1;
        if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total') {
          return true;
        }
        return false;
      },
    }],
    fourPointFive2: [{
      ...keyColumn('owillCover', textColumn),
      minWidth: 130,
      component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

        focus={focus}
        rowIndex={rowIndex}
        columnName='owillCover'
        handleEdit={handleEdit}
        isEdit={isEdit}
        rowData={rowData} />,
      title: (
        <div><PlusComponent2
          values={{
            parentTitle: 'Others',
            lower: 'Other will cover',
            color: 'third', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
            click: () => handleGroups('plus', 'fourPointFive2'),
          }} /></div>

      ),
      // title: <ParentHeader values={{parentTitle: null, lower: 'Other will cover', color: 'third'}} />,
      disabled: ({rowData, rowIndex}) =>{
        const month = monthArr.indexOf(rowData.period)+1;
        if (isRecall || isBeforeMonth(rowData, month) || rowData.period === 'FY Total') {
          return true;
        }
        return false;
      },
    }],
    fifth2: [
      {
        ...keyColumn('overAllWillCover', textColumn), minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='overAllWillCover'
          isEdit={isEdit}
          handleEdit={handleEdit}
          rowData={rowData} />,
        title: (
          <div><PlusComponent2
            values={{
              parentTitle: 'Overall-Monthly',
              lower: 'Will cover',
              color: 'fifth', info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
              click: () => handleGroups('plus', 'fifth2'),
            }} /></div>

        ),
        // title: (
        //   <PlusComponent
        //     values={{
        //       parentTitle: 'Overall-Monthly',
        //       lower: 'Will cover',
        //       color: 'fifth',
        //       click: () => handleGroups('plus', 'fifth2'),
        //     }}
        //   />
        // ),
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || selectedRow.coverageMode=='SUPPLIER' || selectedRow.coverageMode=='TREASURY' || selectedRow.coverageMode=='BOTH' || selectedRow.others==true || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
    ],
    fifth: [
      {
        ...keyColumn('overAllWillCover', textColumn), minWidth: 130,
        component: ({rowData, rowIndex, focus}) => <AppTableInput2 decider={+selectedRow?.decimal_scale} selectedRow={selectedRow} editableInput = {isBeforeMonth}

          focus={focus}
          rowIndex={rowIndex}
          columnName='overAllWillCover'
          isEdit={isEdit}
          handleEdit={handleEdit}
          rowData={rowData} />,
        title: (
          <MinusComponent2
            values={{
              info: `Coverage % per playbook requirements for this month (Applies to programs with optionality and will be identical to "% Max" if no optionality present)`,
              parentTitle: '',
              lower: 'Will cover',
              color: 'fifth',
              click: () => handleGroups('minus', 'fifth'),
            }}
          />
        ),
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || isBeforeMonth(rowData, month) || selectedRow.coverageMode=='SUPPLIER' || selectedRow.coverageMode=='TREASURY' || selectedRow.coverageMode=='BOTH' || selectedRow.others==true || rowData.period === 'FY Total' || rowData.period==='' || !rowData.period ) {
            return true;
          }
          return false;
        },
      },
      {
        ...keyColumn('overAllCoverage', textColumn),
        component: ({rowData, rowIndex}) => (
          <BoldingFyTotal rowData={rowData} rowIndex={rowIndex} val='overAllCoverage' />
        ),
        title: <ParentHeader values={{parentTitle: 'Overall-Monthly', lower: 'New Coverage', color: 'fifth', info: `New Total Coverage inclusive of current month "Will Cover" (across Supplier & Treasury)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('overAllCoveragePercent', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandler
            rowIndex={rowIndex}
            value={rowData['overAllCoveragePercent']}
            color={colorReturn3(rowData, rowIndex, selectedRow)}
          />
        ),
        title: (
          <ParentHeader
            values={{
              parentTitle: null,
              lower: 'New Coverage %',
              color: 'fifth',
              info: `New Total Coverage % inclusive of current month "Will Cover" (across Supplier & Treasury)`,
              click: () => handleGroups('minus', 'sixth'),
            }}
          />
        ),
        disabled: true,
      },
    ],
    sixth: [
      {
        ...keyColumn('newCoveragePercent', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandler
            rowIndex={rowIndex}

            value={rowData['newCoveragePercent']}

            color={colorReturn2(rowData)}
          />
        ),
        minWidth: 120,
        title: <ParentHeader values={{parentTitle: null, lower: 'New Coverage %', color: 'sixth', info: `New Total Coverage % inclusive of current month "Will Cover" (across Supplier & Treasury)`}} />,
        disabled: true,
      },
      {
        ...keyColumn('targetCoveragePercentMin', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandler
            rowIndex={rowIndex}

            value={rowData['targetCoveragePercentMin']}

            color={colorReturn2(rowData)}
          />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target Coverage % min', color: 'sixth', info: `Target Minimum Coverage % for the Quarter`}} />,
        disabled: true,
        minWidth: 120,
      },
      {
        ...keyColumn('targetCoveragePercentMax', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandler
            rowIndex={rowIndex}

            value={rowData['targetCoveragePercentMax']}

            color={colorReturn2(rowData)}
          />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Target Coverage % max', color: 'sixth', info: `Target Maximum Coverage % for the Quarter`}} />,
        disabled: true,
        minWidth: 120,
      },
      {
        ...keyColumn('targetVariancePercent', textColumn),
        component: ({rowData, rowIndex}) => (
          <BackgroundColorHandler
            value={rowData['targetVariancePercent']}
            rowIndex={rowIndex}

            color={colorReturn2(rowData)}
          />
        ),
        title: <ParentHeader values={{parentTitle: 'Quarterly', lower: 'Target Variance %', color: 'sixth', info: `Delta between Quarterly Coverage and Quarterly Target`}} />,
        disabled: true,
        minWidth: 120,
      },
      {
        ...keyColumn('qvarianceReason', textColumn),
        component: ({rowData, rowIndex}) => {
          return <VarianceChange
            quarterMonths={quarterMonths}
            rowIndex={rowIndex}
            varianceReasons={varianceReasons}
            rowData={rowData}
            columnName={'qvarianceReason'}
            handleEdit={handleEdit}
            color={colorReturn2(rowData)}
          />;
        },
        title: <ParentHeader values={{parentTitle: null, lower: 'Variance Reason', color: 'sixth', info: `Explanation for delta between Quarterly Coverage and Quarterly Target (required if variance > 2.5%)`}} />,
        disabled: ({rowData, rowIndex}) =>{
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || !quarterMonths?.includes(rowData.period)) {
            return true;
          }
          return colorReturn2(rowData) == 'green' ? true : false;
        },
        minWidth: 130,
      },
      {
        ...keyColumn('qcomments', textColumn),
        component: ({rowData, rowIndex, focus}) => (
          <BackgroundColorHandler
            rowIndex={rowIndex}
            type="comments"
            value={rowData['qcomments']}
            rowData={rowData}
            columnName='qcomments'
            handleEdit={handleEdit}
            color={colorReturn2(rowData)}
            focus={focus}
          />
        ),
        title: <ParentHeader values={{parentTitle: null, lower: 'Comments', color: 'sixth', info: `Free Text commentary for delta between Quarterly Coverage and Quarterly Target (required if variance > 2.5%)`}} />,
        disabled: ({rowData, rowIndex}) => {
          const month = monthArr.indexOf(rowData.period)+1;
          if (isRecall || !quarterMonths?.includes(rowData.period)) {
            return true;
          }
          return (colorReturn2(rowData) == 'green') ? true : false;
        },
        minWidth: 120,
      },
    ],
  };
};

export const allColumns = [
  'first',
  'second2',
  'second',
  'third2',
  'third',
  'fourthS',
  'fourth',
  'fourth2T',
  'fourth2',
  'fourPointFive',
  'fourPointFive2',
  'fifth2',
  'fifth',
  'sixth'];
