import styled from '@emotion/styled';
import {
  Box,
  createTheme,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextareaAutosize,
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import theme from '../../../../theme';
import config from '../../../../Utils/Config';
import customealltheme from '../../../../theme';
const Edit = config.AccessLevel.Edit;

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CommoditySingleRow = ({
  r,
  ActiveCategories,
  setAddingTrigger,
  addingTrigger,
  ActiveCommodities,
  accessControl,
  singleUpdate,
  setErrMsg,
  setIsErrorPopupVisible,
  page,
  sorttype,
  submitClicked,
  filterResetClicked,
}) => {
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line no-unused-vars

  // This editeddetails used for storing the adding details

  const [categoryId, setcategoryId] = React.useState('');
  const [commodityId, setcommodityId] = React.useState('');
  const [subCommmodityName, setSubCommodityName] = React.useState('');
  const [subCommmodityChanged, setSubCommodityChanged] = React.useState(false);
  const [categoryChanged, setCategoryChanged] = React.useState(false);
  const [active, setactive] = React.useState('');

  const [cat, setCat] = useState('');


  const handleCategoryId = (e) => {
    setcategoryId(e.target.value);
    setCategoryChanged(true);
    setcommodityId('');
  };

  const handleCommodityId = (e) => {
    setcommodityId(e.target.value);
  };
  const handleactivestatus = (e) => {
    setactive(e.target.value);
  };

  const editTheRow = () => {
    setEditMode(true);
  };

  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () => {
    if ( ((subCommmodityChanged && subCommmodityName === '') || (categoryChanged && commodityId === ''))) {
      return;
    }
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);

    singleUpdate({subCommodityName: document.getElementById(`subCommodity-${r.subCommmodity_row_id}`).value,
      commodityId: commodityId!=='' ? commodityId: r.commodity_id,
      categoryId: categoryId!=='' ? categoryId : r.item_id,
      active: active!=='' ? active : r.subCommmodity_is_active,
      isActive: active!=='' ? active : r.subCommmodity_is_active,
      subCommodityId: r.subCommmodity_row_id,
    })
        .then((res) => {
          if (res?.status == '200') {
            setEditMode(false);
          } else if (res?.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res?.userMessage);
            setEditMode(true);
          }
        })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured');
          setAddingTrigger(!addingTrigger);
          setEditMode(false);
        });
  };

  useEffect(()=> {
    setEditMode(false);
  }, [page, sorttype, submitClicked, filterResetClicked]);

  useEffect(()=>{
    if (!editMode) {
      setcategoryId('');
      setcommodityId('');
      setSubCommodityName('');
      setactive('');
      setCat('');
      setSubCommodityChanged(false);
      setCategoryChanged(false);
    }
  }, [editMode]);

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {editMode === true && r.subCommmodity_is_editable === 'Y' ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={categoryId}
              onChange={handleCategoryId}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                <span>{r.category}</span>
              </MenuItem>
              {ActiveCategories.length !== 0 &&
                ActiveCategories?.map((obj) => (
                  <MenuItem
                    sx={customTheme.selectMenuBox}
                    key={obj?.category_id}
                    value={obj?.category_id}
                    className="this-is-new-class"
                    onClick={(e)=>{
                      setCat(obj.category);
                    }}
                  >
                    {obj.category}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : (
          r.category
        )}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editMode === true && r.subCommmodity_is_editable === 'Y' ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={commodityId || r.commodity_id}
              onChange={handleCommodityId}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                <span>Select</span>
              </MenuItem>
              {ActiveCommodities.length !== 0 &&
                                  ActiveCommodities?.map((item) => (
                                    item.name=== (cat || r.category) && item.commodities.map((obj)=>obj.is_active === true &&
                                    <MenuItem
                                      sx={customTheme.selectMenuBox}
                                      key={obj?.id}
                                      value={obj?.id}
                                      className="this-is-new-class"
                                    >
                                      {obj.name}
                                    </MenuItem>,
                                    )
                                  ))}
            </Select>
          </FormControl>
        ) : (
          r.commodity_name
        )}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" >
        { editMode===true ?
                <TextareaAutosize id={`subCommodity-${r.subCommmodity_row_id}`} style={theme.palette.TextArea}
                  placeholder="Type in here…"
                  defaultValue={subCommmodityName || r.subCommmodity}
                  minRows={1}
                  maxRows={4}
                  onChange={(e)=>{
                    setSubCommodityName(e.target.value);
                    setSubCommodityChanged(true);
                  }}
                /> :
              r.subCommmodity
        }
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editMode === true && r.subCommmodity_is_editable === 'Y' ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={active || r.subCommmodity_is_active}
              onChange={handleactivestatus}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >

              { ['true', 'false']?.map((obj, index) => (
                <MenuItem
                  sx={customTheme.selectMenuBox}
                  key={index}
                  value={obj}
                  className="this-is-new-class"
                >
                  {obj === 'true'? 'Y' : 'N'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          `${r.subCommmodity_is_active ? 'Y' : 'N'}`
        )}
      </StyledTableCell>
      <StyledTableCell>{r.subCommmodity_updated_by?r.subCommmodity_updated_by:''}</StyledTableCell>
      <StyledTableCell>{r.subCommmodity_updated_on?r.subCommmodity_updated_on:''}</StyledTableCell>

      {accessControl === Edit && <StyledTableCell align="left" style={{position: 'relative'}}>
        {editMode === false ? (
          r.commodity_is_editable === 'N' ? <abbr style={{textDecoration: 'none', borderBottom: 'none'}} title="The item cannot be made inactive as it is actively used in a buy plan">
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={editTheRow} style={customealltheme.palette.TableEdit}
            >
              <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
              <Box>Edit</Box>
            </Box>
          </abbr> :

            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={editTheRow} style={customealltheme.palette.TableEdit}
            >
              <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
              <Box
              >Edit</Box>
            </Box>
        ) : (
          <Box sx={{display: 'flex', gap: '5px'}}>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                background: '#4297d3',
                color: 'white',
                justifyContent: 'center',
                cursor: `${((subCommmodityChanged && subCommmodityName === '') || (categoryChanged && commodityId === '')) ? 'not-allowed' : 'pointer'}`,
              }}
              onClick={saveTheRow}
            >
              <Box>Save</Box>
            </Box>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={(e) => setEditMode(false)}
            >
              <Box>Cancel</Box>
            </Box>
          </Box>
        )}
      </StyledTableCell>
      }
    </StyledTableRow>
  );
};

export default CommoditySingleRow;
