import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import store from './redux/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material';
import theme from './theme';
import {ErrorBoundary} from 'react-error-boundary';
import FallbackErrorRender from './Components/FallBackError';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary fallbackRender={FallbackErrorRender}
      onReset={(details) => {
        window.history.pushState({}, '', '/home');
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
      }}
    >
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
