/* eslint-disable max-len */
import {Select, MenuItem, TextareaAutosize} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledVarianceSelect = styled(Select)(({theme})=>({
  'minWidth': '185px',
  'maxWidth': '185px',
  '& .MuiInputBase-input': {
    'padding': '7.5px 26px 7.5px 12px',
    'border': `1px solid ${theme.palette.customColor.horizontalLineColor}`,
    'borderRadius': 4,
    'color': theme.palette.customText.lightGrey,
    'fontSize': '13px',
    'fontFamily': 'Segoe UI',
    '&:focus': {
      borderRadius: 4,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
}));

const StyledVarianceMenuItem = styled(MenuItem)(({theme})=>({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '185px',
  overflow: 'hidden',
  fontSize: '11px',
  padding: '5px',
  fontFamily: 'Segoe UI',
}));

const StyledVarianceCommmentText = styled(TextareaAutosize)(({theme})=>({
  'padding': '7.5px 26px 7.5px 12px',
  'border': `1px solid ${theme.palette.customColor.horizontalLineColor}`,
  'borderRadius': 4,
  'color': theme.palette.customText.lightGrey,
  'fontSize': '13px',
  'fontFamily': 'Segoe UI',
  'marginLeft': '15px',
  '&:focus': {
    'borderRadius': 4,
    'border': `1px solid ${theme.palette.customColor.horizontalLineColor}`,
  },
  '&:focus-visible': {
    'borderRadius': 4,
    'border': `1px solid ${theme.palette.customColor.horizontalLineColor}`,
  },
}));


export default {
  StyledVarianceSelect,
  StyledVarianceMenuItem,
  StyledVarianceCommmentText,
};
