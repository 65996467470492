/* eslint-disable max-len */
import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from 'react-router-dom';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {LoginCallback, Security, SecureRoute} from '@okta/okta-react';

import OktaLogin from './OktaLogin';
import {ThemeProvider} from '@mui/material/styles';
import theme from './theme/index';
import Header from './Components/Header/Header';
import Home from './Pages/Home';
import Target from './Pages/Target';
import Forecast from './Pages/Forecast/Forecast';
import Configuration from './Pages/Configuration/Configuration';
import CurrencyConversion from './Components/Configurations/CurrencyConversion/CurrencyConversion';
import Guidance from './Components/Configurations/GuidancePrice/Guidance';
import Health from './Components/Health/Health';
import NonPlayBook from './Components/NonPlayBook/NonPlayBook';
import HelpContent from './Components/Header/Help/HelpContent';
import Report from './Pages/Report/Report';

const ENV = process.env;
// console.log('ISSUER ------------ ', ENV.REACT_APP_OKTAUTH_ISSUER);
const oktaAuth = new OktaAuth({
  issuer: ENV.REACT_APP_OKTAUTH_ISSUER,
  clientId: ENV.REACT_APP_OKTAUTH_CLIENTID,
  redirectUri: window.location.origin + ENV.REACT_APP_OKTAUTH_REDIRECT_URI_PATH,
  pkce: true,
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  responseType: ['code'], // ['id_token', 'token']
  devMode: false,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state={
      justLogin: false,
    };
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      this.setState({justLogin: true});
      props.history.replace(
          toRelativeUrl(originalUri || '/', window.location.origin),
      );
    };
  }

  render() {
    return (
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={this.restoreOriginalUri}
      >
        <>
          <Header history={this.props.history} justLogin={this.state.justLogin}/>
          <Switch>
            <Route path="/" exact={true} component={OktaLogin} />
            <Route path="/login" exact={true} component={OktaLogin} />
            <Route path="/login/callback" exact={true} component={LoginCallback} />
            <SecureRoute path="/home" component={Home} />
            <SecureRoute path="/health" component={Health} />
            <SecureRoute path="/target" component={Target} />
            <SecureRoute path="/forecast" component={Forecast} />
            <SecureRoute path="/configuration" component={Configuration} />
            <SecureRoute path="/configuration/:paths" component={Configuration} />
            <SecureRoute path="/report" component={Report} />
            <SecureRoute path="/report/:paths" component={Report} />
            <SecureRoute path="/guidanceprice" component={Guidance} />
            <SecureRoute path="/fxrates" component={CurrencyConversion} />
            <SecureRoute path="/nonPlayBook" component={NonPlayBook} />
            <SecureRoute path="/help" component={HelpContent} />
          </Switch>
        </>
      </Security>
    );
  }
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <AppWithRouterAccess />
        </Router>
      </ThemeProvider>
    );
  }
}

export default RouterApp;
