
/* eslint-disable new-cap */
import {monthArr, months, titleCase} from '../../../services/utils';
import {defaultObj} from './consts';

// this function used for filling default data
export const forecastDefaultData = (selectedRow, years) => {
  const obj = {};
  // const y = new Date().getFullYear();
  // using selectedRow.execution_month[0] and years[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years
  const y = selectedRow.execution_month[1]==1?selectedRow.execution_month[0]-1:selectedRow.execution_month[0];
  // const m = new Date().getMonth();
  const list = [y, y + 1, y + 2];
  const firstMonth = selectedRow.execution_month[1]==1? 11 :selectedRow.execution_month[1]-2;
  const secondMonth = selectedRow.execution_month[1]-1;
  if (!obj[list[0]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prev_wf_id,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.execution_month[1]==1?selectedRow.execution_month[0]-1:selectedRow.execution_month[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workflow_id,
      planMonth: titleCase(months[secondMonth]),
      month: +monthArr.indexOf(titleCase(months[secondMonth])),
      planYear: selectedRow.execution_month[1]==1?selectedRow.execution_month[0]-1:selectedRow.execution_month[0],
    };
    // filling the data with workflowId : data
    obj[list[0]] = {[selectedRow.prev_wf_id]: a, [selectedRow.workflow_id]: a2};
  }
  if (!obj[list[1]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prev_wf_id,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.execution_month[1]==1?selectedRow.execution_month[0]-1:selectedRow.execution_month[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workflow_id,
      planMonth: titleCase(months[secondMonth]),
      month: +monthArr.indexOf(titleCase(months[secondMonth])),

      planYear: selectedRow.execution_month[1]==1?selectedRow.execution_month[0]-1:selectedRow.execution_month[0],
    };
    obj[list[1]] = {[selectedRow.prev_wf_id]: a, [selectedRow.workflow_id]: a2};
  }
  if (!obj[list[2]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prev_wf_id,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.execution_month[1]==1?selectedRow.execution_month[0]-1:selectedRow.execution_month[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workflow_id,
      planMonth: titleCase(months[secondMonth]),
      month: +monthArr.indexOf(titleCase(months[secondMonth])),

      planYear: selectedRow.execution_month[1]==1?selectedRow.execution_month[0]-1:selectedRow.execution_month[0],
    };
    obj[list[2]] = {[selectedRow.prev_wf_id]: a, [selectedRow.workflow_id]: a2};
  }
  console.log(obj);
  return obj;
};

// it mainly add the key which is defining factor for which row should be editable and which will be upper row,
// also it calculate fytotal for read only data

const CommaRemoval = (val) => {
  // const re = new RegExp(`^[0-9]{1,2}([,.][0-9]{1,2})?$`);
  // const flag = re.test(val.toString());
  // if (flag) {
  return val?.toString().replaceAll(',', '') || '';
  // }
};

export const fyCalculateTotal = (selectedData={}) => {
  // console.log('fyCalculateTotal selectedData', selectedData);
  if (selectedData) {
    return Object.values(selectedData).map((val, key) => {
      if (!val['key']) {
        val['key'] = key + 1;
      } else val['key'] = key + 1;
      const total = Object.keys(val).reduce((accumulator, currentValue) => {
        if (months.indexOf(currentValue) >= 0) {
          return accumulator + (+CommaRemoval(val[currentValue]) || 0);
        } else return accumulator;
      }, 0);
      // for read only
      if (val.fy===0) {
        val.fy = total;
      }
      val.fy=+CommaRemoval(val.fy);
      return val;
    });
  }
};

// If only one row for particular year
export const getSortedMonthlyData = (arr, selectedRow, copy, selectedYear, years) => {
  let k = '';
  let pMonth = null;
  let eMonth = selectedRow.execution_month;
  if (!Array.isArray(selectedRow.execution_month)) eMonth = eMonth.split('-');
  if (Object.keys(copy).map((a) => a).length == 2) {
    delete copy[arr[0].workflow_id];
    const va = Object.values(copy).map((a) => a);
    pMonth = +va[0].execution_month[1] - 1;
  } else {
    if (arr[0].workflow_id == selectedRow.workflow_id) {
      pMonth = +eMonth[1] - 2;
    } else {
      pMonth = +eMonth[1] - 1;
    }
    // pMonth = selectedRow.execution_month[1] - 1
  }
  if (arr[0].month > pMonth) {
    arr[0].key = 2;
    k = 1;
  } else k = 2;
  // month, year and key value setup in constant
  // pMonth = pMonth==-1 ? 11 : pMonth;   using this because if the present month is jan then previous will become -1 then we need to make it as 11 by default
  const defaultObj = {
    month: pMonth,
    planMonth: titleCase(months[pMonth==-1?11:pMonth]),
    planYear: arr[0].planYear,
    jan: getValueOfPreviousMonthForcast(arr, 'jan', pMonth, selectedYear, selectedRow, years),
    feb: getValueOfPreviousMonthForcast(arr, 'feb', pMonth, selectedYear, selectedRow, years),
    mar: getValueOfPreviousMonthForcast(arr, 'mar', pMonth, selectedYear, selectedRow, years),
    apr: getValueOfPreviousMonthForcast(arr, 'apr', pMonth, selectedYear, selectedRow, years),
    may: getValueOfPreviousMonthForcast(arr, 'may', pMonth, selectedYear, selectedRow, years),
    jun: getValueOfPreviousMonthForcast(arr, 'jun', pMonth, selectedYear, selectedRow, years),
    jul: getValueOfPreviousMonthForcast(arr, 'jul', pMonth, selectedYear, selectedRow, years),
    aug: getValueOfPreviousMonthForcast(arr, 'aug', pMonth, selectedYear, selectedRow, years),
    sep: getValueOfPreviousMonthForcast(arr, 'sep', pMonth, selectedYear, selectedRow, years),
    oct: getValueOfPreviousMonthForcast(arr, 'oct', pMonth, selectedYear, selectedRow, years),
    nov: getValueOfPreviousMonthForcast(arr, 'nov', pMonth, selectedYear, selectedRow, years),
    dec: getValueOfPreviousMonthForcast(arr, 'dec', pMonth, selectedYear, selectedRow, years),
    fy: 0,
    workflow_id: selectedRow.workflow_id,
    key: k,
  };
  // console.log(defaultObj);
  // update the forcast value with previous month forcast and total the fy value
  // months.forEach((month)=>{
  //   const prevMonthForcastValue = getValueOfPreviousMonthForcast(arr, month, pMonth, selectedYear, selectedRow, years);
  //   // if (prevMonthForcastValue>0) {
  //   // console.log('prevMonthForcastValue', prevMonthForcastValue);
  //   defaultObj[month]=prevMonthForcastValue; // update this months value with previous forcast
  //   defaultObj['fy']=defaultObj['fy'] + prevMonthForcastValue; // also update the fy for total
  //   // }
  // });

  // now update the value of previous months

  arr = [...arr, defaultObj];
  arr.sort((a, b) => (a.month > b.month ? 0 : -1));
  return arr;
};

export const updateTheDataWithPrevMonth= (data, selectedRow)=>{
  // console.log(data);
  // this condition states that if the data[1].planMonth is Dec and data[0].planMonth is Jan then we are reversing the data[0] and data[1]
  if ((months.indexOf(data[0]['planMonth'].toLowerCase())!==11 &&months.indexOf(data[0]['planMonth'].toLowerCase())>months.indexOf(data[1]['planMonth'].toLowerCase())) || ( months.indexOf(data[1]['planMonth'].toLowerCase())==11 && months.indexOf(data[0]['planMonth'].toLowerCase()) == 0)) {
    data[1].key = 1;
    data[0].key = 2;
    data = [data[1], data[0]];
  }
  let eMonth = selectedRow.execution_month;
  if (!Array.isArray(selectedRow.execution_month)) eMonth = eMonth.split('-');
  // console.log('@02 updateTheDataWithPrevMonth eMonth', eMonth);
  months.forEach((month, index)=>{
    // console.log('month,index , data[1][month], eMonth[1]+2, index < eMonth[1]-2', month, index, data[1][month], eMonth[1]-2, index < eMonth[1]+2);
    if (data[1][month]===0 && index < eMonth[1]-2 ) { // +2 for only copy for previous non editable months only
      // data[1][month]= data[0][month];
      // console.log(data);
    }
  });
  return data;
};

const getValueOfPreviousMonthForcast = (arr, month, pMonth, selectedYear, selectedRow, years)=>{
  if (parseInt(selectedYear)!==years[0]) {
    return 0;
  }
  // const currentMonthIndex = selectedRow.execution_month[1];
  // // Added selectedRow.execution_month[1]==1 this because for jan month buyplan
  // const shouldUpdateWithPreviousMonthValue = selectedRow.execution_month[1]==1? true :arr[0].month < pMonth; // if data (arr) is for previous month then we will use in next month
  // // console.log('@prev shouldUpdateWithPreviousMonthValue,month,months.indexOf(month), arr[0].month, arr[0][month] : ', shouldUpdateWithPreviousMonthValue, month, arr[0].month, arr[0][month]);
  // return shouldUpdateWithPreviousMonthValue && (selectedRow.execution_month[1]==1? months.indexOf(month)< 11 : months.indexOf(month) < currentMonthIndex-2) ? arr[0][month] : 0;
};

export const monthNYearSetter = (obj, fsct, selectedRow) => {
  // console.log('@fsct getCall monthNYearSetter obj, fsct: ', obj, fsct);
  const workFlowId = fsct.non_pb_wf_id;
  const Year = +fsct.forecast_year_month[0];
  if (!obj[Year]) {
    obj[Year] = {};
    const a = {
      ...defaultObj,
      month: selectedRow.workflow_id!== workFlowId ? (+selectedRow.execution_month[1] - 2 < 0 ? 0 : +selectedRow.execution_month[1] - 2 ) :+selectedRow.execution_month[1] - 1,
      planMonth: titleCase(months[selectedRow.workflow_id!== workFlowId ? +selectedRow.execution_month[1] - 2 < 0 ? 0 : +selectedRow.execution_month[1] - 2 :+selectedRow.execution_month[1] - 1]),
      planYear: selectedRow.execution_month[0],
      workflow_id: fsct.non_pb_wf_id,
      [months[+fsct.forecast_year_month[1] - 1]]: fsct.forecast_value,
      // fy: fsct.forecastValue,
    };
    obj[Year][workFlowId] = a;
  } else {
    if (!obj[Year][workFlowId]) {
      const a = {
        ...defaultObj,
        month: selectedRow.workflow_id!== workFlowId ? (+selectedRow.execution_month[1] - 2 < 0 ? 0 : +selectedRow.execution_month[1] - 2 ) :+selectedRow.execution_month[1] - 1,
        workflow_id: fsct.non_pb_wf_id,
        planMonth: titleCase(months[selectedRow.workflow_id!== workFlowId ? +selectedRow.execution_month[1] - 2 < 0 ? 0 : +selectedRow.execution_month[1] - 2 :+selectedRow.execution_month[1] - 1]),
        planYear: selectedRow.execution_month[0],
        [months[+fsct.forecast_year_month[1] - 1]]: fsct.forecast_value,
        // fy: fsct.forecastValue,
      };
      obj[Year][workFlowId] = a;
    } else {
      obj[Year][workFlowId] = {
        ...obj[Year][workFlowId],
        workflow_id: fsct.non_pb_wf_id,
        planMonth: titleCase(months[selectedRow.workflow_id!== workFlowId ? +selectedRow.execution_month[1] - 2 < 0 ? 0 : +selectedRow.execution_month[1] - 2 :+selectedRow.execution_month[1] - 1]),
        planYear: selectedRow.execution_month[0],
        [months[+fsct.forecast_year_month[1] - 1]]: fsct.forecast_value,
        // fy: obj[Year][workFlowId]['fy']+fsct.forecastValue,
      };
    }
  }

  // console.log('@doc  monthNYearSetter obj ', obj);

  return obj;
};

// only for the case when we have received only half of the data like data is for 2023 and 2024 but no data for 2025
export const rowsSetter = (obj, workflowIdsObj, selectedRow) => {
  // debugger;
  // console.log('@fsct obj', obj);
  // console.log('@rowsSetter workflowIdsObj', workflowIdsObj);
  if (true || Object.keys(obj).length < 3) { // just to make sure 3 year of data is there
    const wfIDs = Object.keys(workflowIdsObj)
        .map((val) => val)
        .sort((a, b) => (a < b ? -1 : 0));

    // const y = new Date().getFullYear();
    // using selectedRow.execution_month[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years

    const y = selectedRow.execution_month[1] == 1? selectedRow.execution_month[0] -1: selectedRow.execution_month[0];
    const list = [y, y + 1, y + 2];
    let firstMonth = null;
    let secondMonth = null;
    let my = null; // month Year
    let my2 = null;
    // if (!Array.isArray(workflowIdsObj[wfIDs[0]].execution_month)) { // if execution_month is string
    //   my = workflowIdsObj[wfIDs[0]].execution_month.split('-');
    // }
    if (!Array.isArray(workflowIdsObj[wfIDs[0]].execution_month)) { // if execution_month is string
      my = workflowIdsObj[wfIDs[0]].execution_month.split('-');
    }
    if (wfIDs.length == 1) {
      if (workflowIdsObj[wfIDs[0]].workflow_id == selectedRow.workflow_id) { // means current workFlowId
        if (!my) {
          // get month from execution_month of this workflowIdsObj Also -1 because month index start from 0
          secondMonth = workflowIdsObj[wfIDs[0]].execution_month[1] - 1;
          firstMonth = secondMonth - 1; // we don't have data so previous is -1 to last/second
        } else {
          secondMonth = +my[1] - 1;
          firstMonth = secondMonth - 1;
        }
      } else { // for previous workflowId
        if (!Array.isArray(workflowIdsObj[wfIDs[1]]?.execution_month)) { // FIXME
          my2 = workflowIdsObj[wfIDs[1]]?.execution_month.split('-');
        }
        if (!my) {
          firstMonth = workflowIdsObj[wfIDs[0]].execution_month[1] - 1;
          secondMonth = firstMonth + 1;
        } else {
          firstMonth = +my[1] - 1;
          secondMonth = firstMonth + 1;
        }
      }
    } else if (wfIDs.length == 2) {
      if (!Array.isArray(workflowIdsObj[wfIDs[1]].execution_month)) {
        my2 = workflowIdsObj[wfIDs[1]].execution_month.split('-');
      }
      if (!my) {
        firstMonth = workflowIdsObj[wfIDs[0]].execution_month[1] - 2;
        secondMonth = workflowIdsObj[wfIDs[1]].execution_month[1] - 1;
      } else {
        firstMonth = my[1] - 2;
        secondMonth = my2[1] - 1;
      }
    }

    if ( (obj[list[0]]!==undefined && !Object.keys(obj[list[0]]).includes(`${selectedRow.prev_wf_id}`)) || !obj[list[0]]) {
      const a = {
        ...defaultObj,
        month: selectedRow.execution_month[1] == 1 ? 11 : selectedRow.execution_month[1] - 2,
        workflow_id: selectedRow.prev_wf_id,
        planMonth: titleCase(months[selectedRow.execution_month[1] == 1 ? 11 : selectedRow.execution_month[1] - 2]),
        planYear: selectedRow.execution_month[0],
      };
      obj[list[0]] = {...obj[list[0]], [selectedRow.prev_wf_id]: a};
    }
    if ((obj[list[1]]!==undefined && !Object.keys(obj[list[1]]).includes(`${selectedRow.prev_wf_id}`)) || !obj[list[1]]) {
      const a = {
        ...defaultObj,
        month: selectedRow.execution_month[1] == 1 ? 11 : selectedRow.execution_month[1] - 2,
        workflow_id: selectedRow.prev_wf_id,
        planMonth: titleCase(months[selectedRow.execution_month[1] == 1 ? 11 : selectedRow.execution_month[1] - 2]),
        planYear: selectedRow.execution_month[0],
      };
      obj[list[1]] = {...obj[list[1]], [selectedRow.prev_wf_id]: a};
    }
    if ((obj[list[2]]!==undefined && !Object.keys(obj[list[2]]).includes(`${selectedRow.prev_wf_id}`)) || !obj[list[2]]) {
      const a = {
        ...defaultObj,
        month: selectedRow.execution_month[1] == 1 ? 11 : selectedRow.execution_month[1] - 2,
        workflow_id: selectedRow.prev_wf_id,
        planMonth: titleCase(months[selectedRow.execution_month[1] == 1 ? 11 : selectedRow.execution_month[1] - 2]),
        planYear: selectedRow.execution_month[0],
      };
      obj[list[2]] = {...obj[list[2]], [selectedRow.prev_wf_id]: a};
    }
  }

  console.log('@rowsSetter final obj', obj);
  return obj;
};
