import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import {styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
const StyledSelect = styled(Select)({
  minWidth: '144px',
  maxWidth: '144px',
});

const CustomInput = styled(InputBase)(({disabled, theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'border': `1px solid ${theme.palette.customColor.horizontalLineColor}`,
    'padding': '7.5px 26px 7.5px 12px',
    'color': theme.palette.customText.lightGrey,
    'fontSize': '13px',
    'fontFamily': 'Segoe UI',
    '&:focus': {
      borderRadius: 4,
    },
    ...(disabled && {
      background: theme.palette.customBackground.disabledBackground,
    }),
  },
}));

const StyledListItemText= styled(ListItemText)(({theme})=>({
  '& .MuiListItemText-primary': {
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    color: theme.palette.customText.lightGrey,
  },

}));

const StyledCheckbox = styled(Checkbox)(({theme})=>({
  'padding': '3px',
  '& svg': {
    'fontFamily': 'Segoe UI',
    'fontSize': '15px',
    'fill': theme.palette.customText.lightGrey,
  },
}));

function MultiSelectBox(props) {
  return (
    <StyledSelect
      // labelId='demo-multiple-checkbox-label'
      id={props.id}
      multiple
      value={props.selectedItems}
      onChange={props?.handleChange}
      // input={<OutlinedInput label={props.label} />}
      displayEmpty
      renderValue={(selected, displayEmpty) => {
        const selectedLabels = [];
        props.items.forEach((item) => {
          if (selected.includes(item.value)) {
            selectedLabels.push(item.label);
          }
        });
        return selectedLabels.length ? selectedLabels.join(', ') : 'Select';
      }}
      disabled={props?.disabled}
      input={<CustomInput />}
      // MenuProps={MenuProps}
      sx={{}}
    >
      {props.items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <StyledCheckbox
            checked={props?.selectedItems.indexOf(item.value) > -1} />
          <StyledListItemText primary={item.label} />
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

export default MultiSelectBox;
