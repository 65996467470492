import styled from '@emotion/styled';
import {
  Box,
  createTheme,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextareaAutosize,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit, BiErrorAlt} from 'react-icons/bi';
import theme from '../../../theme';
import customealltheme from '../../../theme';
import config from '../../../Utils/Config';

import {monthArr} from '../../../services/utils';
const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CommonConfSingleRow = ({
  row, setAddingTrigger, Units, addingTrigger, accessControl, submitClicked, filterResetClicked, playbookTypes,
  Currency, values, idKey, singleUpdate, setErrMsg, setIsErrorPopupVisible, page,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedDetails, setEditDetails] = useState({});
  const [guidancePrice, setGuidancePrice] = useState('');
  const [currencyId, setCurrencyId] = React.useState('');
  const [volumeUnitId, setvolumeUnitId] = useState(row['measurementUnitId']);


  useEffect(()=>{
    setEditMode(false);
  }, [page, submitClicked, filterResetClicked]);

  function toCamelCase(arr) {
    let string = arr[0];
    for (let i = 1; i < arr.length; i++) {
      string =
        string +
        arr[i].charAt(0).toUpperCase() +
        arr[i].slice(1, arr[i].length);
    }
    return string;
  }
  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(() => {
    const keyvalues = {};
    values.map((h, index) => {
      keyvalues[h] = row[h];
    });
    setEditDetails({...keyvalues});
  }, [row, values]);

  const editTheRow = () => {
    setEditMode(true);
    setGuidancePrice(row['guidancePrice']);
  };
  useEffect(()=>{
    setEditMode(false);
  }, [page]);

  const saveTheRow = () =>{
    setEditMode(false);
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);
    const keyvalues = {};
    const originalEdited = {...editedDetails};
    values.map((h, index) => {
      if (document.getElementById(h) !== null) {
        originalEdited[h] = document.getElementById(h).value;
      }

      keyvalues[toCamelCase(h.split('_'))] =
        document.getElementById(h) !== null ?
          document.getElementById(h).value :
          editedDetails[h];
    });
    setEditDetails({...originalEdited});
    idKey.map((idkey) => {
      keyvalues[`${idkey}`] = row[idkey];
      keyvalues[toCamelCase(idkey.split('_'))] = row[idkey];
      delete keyvalues[idkey];
    });

    singleUpdate({
      id: row.id,
      // `${keyvalues.conversionMonthYear.split('-')[1]}-${String(monthArr.indexOf(keyvalues.conversionMonthYear.split('-')[0]) + 1).padStart(2, '0')}`,
      currencyId: currencyId === '' ? keyvalues.currencyId : currencyId,
      guidancePrice: guidancePrice,
      priceMonthYear: `${row.priceMonthYear.split('-')[1]}-${String(monthArr.indexOf(row.priceMonthYear.split('-')[0]) + 1).padStart(2, '0')}`,
      commodityId: row.commodityId,
      sectorId: row.sectorId,
      user_id: row.user_id,
      measurementUnitId: volumeUnitId === null || volumeUnitId === '' ? row['measurementUnitId'] : volumeUnitId,
      isNonPlaybook: row.isNonPlaybook,
    })
        .then((res) => {
          if (res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
            setAddingTrigger(!addingTrigger);
          } else if (res) {
            setEditMode(false);
            setAddingTrigger(!addingTrigger);
          }
        })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured');
          setAddingTrigger(!addingTrigger);
        });
  };


  const Edit = config.AccessLevel.Edit;

  const handlecurrencyId = (e) => {
    setCurrencyId(e.target.value);
  };

  const handleGuidancePrice = (e) => {
    setGuidancePrice(e.target.value);
  };

  const handleVolumeUnit=(e)=>{
    setvolumeUnitId(e.target.value);
  };

  const isValidFloatGreaterThanZero = (value) => {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue > 0;
  };
  const checkVolumeUnit = () =>{
    const valueCheck = volumeUnitId || row['measurementUnitId'];
    const result = (valueCheck !== null && valueCheck !== undefined && valueCheck !== '');
    return result;
  };
  const enableSaveButton = (currencyId!='' || editedDetails.currency!='') && (isValidFloatGreaterThanZero(guidancePrice)) && (checkVolumeUnit());
  const saveButtonStyles = {
    border: '1px solid gray',
    display: 'flex',
    border: '0.5px solid #CECECE',
    borderRadius: '4px',
    width: '58px',
    alignItems: 'center',
    gap: '5px',
    padding: '2px 6px',
    background: '#4297d3',
    color: 'white',
    justifyContent: 'center',
    cursor: enableSaveButton ? 'pointer' : 'not-allowed',
    opacity: enableSaveButton ? 1:0.5,
  };

  useEffect(()=>{
    if (!editMode) {
      setvolumeUnitId('');
      setCurrencyId('');
    }
  }, [editMode]);

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {editedDetails?.isNonPlaybook===true ? playbookTypes?.NON_PLAYBOOK : (editedDetails?.isNonPlaybook===false ? playbookTypes?.PLAYBOOK : '')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editedDetails?.sector}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editedDetails?.commodity}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editMode === true ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={currencyId}
              onChange={handlecurrencyId}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                <span>{editedDetails?.currency ? editedDetails?.currency.toUpperCase() : ''}</span>
              </MenuItem>
              {Currency?.length !== 0 &&
                Currency?.filter((Curr) => Curr?.currency.trim() !== '' && Curr?.is_active)
                    .sort((a, b) => a.currency.localeCompare(b.currency))
                    .map((obj) => (
                      <MenuItem sx={customTheme.selectMenuBox} key={obj?.currency_id} value={obj?.currency_id} className="this-is-new-class">
                        {obj?.currency ? obj?.currency.toUpperCase() : ''}
                      </MenuItem>
                    ))}
            </Select>
          </FormControl>
        ) : (
          `${editedDetails?.currency ? editedDetails?.currency.toUpperCase() : ''}`
        )}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editedDetails?.priceMonthYear ? editedDetails?.priceMonthYear.replace('-', ' ') : ''}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editMode === true ? (
          <TextareaAutosize
            style={theme.palette.TextArea}
            placeholder="Type in here…"
            onChange={handleGuidancePrice}
            minRows={1}
            maxRows={4}
            value={guidancePrice}
          />
        ) : (
          `${editedDetails?.guidancePrice ? editedDetails?.guidancePrice?.toLocaleString('en-US', {maximumFractionDigits: 2}) : ''}`
        )}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row">
        {editMode === true ? (
          <FormControl>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={volumeUnitId || row['measurementUnitId'] || ''}
              onChange={handleVolumeUnit}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              {Units.length !== 0 &&
                _.orderBy(Units, [(cat) => cat?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                  <MenuItem sx={customTheme.selectMenuBox} key={r?.unit_id} value={r?.unit_id} className="this-is-new-class">
                    {r?.unit_description}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        ) : editedDetails?.measurementUnit !== undefined && editedDetails?.measurementUnit != null ? (
          `${editedDetails?.measurementUnit}`
        ) : (
          ' '
        )}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row">
        {row['lastUpdatedBy']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['lastUpdatedOn'] ? row['lastUpdatedOn'] : ''}
      </StyledTableCell>

      {accessControl === Edit && row?.is_editable == 'Y' ? (
        <StyledTableCell align="left">
          {editMode === false ? (
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={editTheRow}
              style={customealltheme.palette.TableEdit}
            >
              <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
              <Box>Edit</Box>
            </Box>
          ) : (
            <Box sx={{display: 'flex', gap: '5px'}}>
              <Box>
                <Box
                  sx={enableSaveButton ? saveButtonStyles : {...saveButtonStyles, opacity: 0.5}}
                  onClick={enableSaveButton ? saveTheRow : undefined}
                >
                  Save
                </Box>
              </Box>
              <Box
                sx={{
                  border: '1px solid gray',
                  display: 'flex',
                  border: '0.5px solid #CECECE',
                  borderRadius: '4px',
                  width: '58px',
                  alignItems: 'center',
                  gap: '5px',
                  padding: '2px 6px',
                  cursor: 'pointer',
                }}
                onClick={(e) => setEditMode(false)}
              >
                <Box>Cancel</Box>
              </Box>
            </Box>
          )}
        </StyledTableCell>
      ) : accessControl !== Edit ? (
        ''
      ) : (
        <StyledTableCell>
          <Box
            sx={{
              border: '1px solid #CECECE',
              display: 'flex',
              borderRadius: '4px',
              width: '58px',
              alignItems: 'center',
              gap: '5px',
              padding: '2px 6px',
              cursor: 'not-allowed',
              color: '#CECECE',
            }}
            style={customealltheme.palette.TableEdit}
          >
            <BiErrorAlt style={{fontSize: '13px', color: '#CECECE'}} />
            <Box>Edit</Box>
          </Box>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
};

export default CommonConfSingleRow;
