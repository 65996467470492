/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const Health = () => {
  return (
    <div>Hello There! I'm Global Procurement UI Service!</div>
  );
};

export default Health;
