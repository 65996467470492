/* eslint-disable */
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {leapYear, months, monthsCap, roundToTwo} from '../../../services/utils';
import './appTableInput.css';

export const AppTableInput = ({
  focus, decider,
  historicalTrigger,
  selectedRow,
  setHistoricalTrigger,
  subMissionData = null,
  data,
  setData,
  setSubmissionData = null,
  forecastVal = [],
  columnName,
  isDisabled,
  rowData,
}) => {
  const [value, setValue] = useState('');
  const [letterFlag, setLetterFlag] = useState(false);
  useEffect(() => {
    // console.log('object');
    if(!isNaN(rowData[columnName]?.toString().split(',').length>1 ? rowData[columnName]?.toString().split(',').join('') : rowData[columnName])){
      if (rowData && rowData[columnName] || !focus) {
      // setValue(formatNumber(rowData[columnName] && rowData[columnName].toString()?.replaceAll(',',''))?.toLocaleString('en-US'))
      setValue(formatNumber(roundToTwo(decider, rowData[columnName] && rowData[columnName].toString()?.replaceAll(',','')))?.toLocaleString('en-US'))
    // } else if (rowData && rowData[columnName] == 0 || !focus) setValue(formatNumber(rowData[columnName])?.toLocaleString('en-US'));
    } else if (rowData && rowData[columnName] == 0 || !focus) setValue(formatNumber(roundToTwo(decider, rowData[columnName]))?.toLocaleString('en-US'));
    else {
      // setValue(formatNumber(rowData[columnName])?.toLocaleString('en-US'));
      setValue(formatNumber(roundToTwo(decider, rowData[columnName]))?.toLocaleString('en-US'));
    }
   } else {
    rowData[columnName]=0
    setValue(0)
   }
  }, [rowData]);
  const ref = useRef(null);
  const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
    .toString();
    let splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
      if (!(+splitArray[1] > 0)) formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  // This function will be called only when `focus` changes
  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);
  return (
    <>
      {((rowData.source == 'Supplier' || rowData.source == 'Treasury' || (rowData.source == 'Other' && selectedRow.others == true)) && selectedRow.coverageMode == 'BOTH') || (rowData.source.toUpperCase() === selectedRow.coverageMode) || (rowData.source == 'Total' && (selectedRow.coverageMode=='NONE' && selectedRow.others==false)) || (rowData.source == 'Other' && selectedRow.others == true) ? (
        <div className="app-table-input">
          <div className="app-table-input-icon"></div>
          <input
            ref={ref}
            type="text"
            onFocus={(e) => {
            const value = e.target.value.split(',').join('');
            // console.log(e.target.value.split(',').join(''))
            setValue(value)
        }}
            onChange={(e) => {
              // console.log(typeof +e.target.value)

              if(((!isNaN(e.target.value)) || (e.target.value[1]=='-') || (e.target.value[0]=='-' && !isNaN(+e.target.value.slice(1,))) ) && !e.target.value.includes('!') && !e.target.value.includes('@') && !e.target.value.includes('#') && !e.target.value.includes('$') && !e.target.value.includes('%') && !e.target.value.includes('^') && !e.target.value.includes('&') && !e.target.value.includes('*') && !e.target.value.includes('(') && !e.target.value.includes(')') && !e.target.value.includes('=') && !e.target.value.includes('+')){
                // const value = e.target && e.target.value ? parseFloat(e.target.value) : 0;
                rowData[columnName] = e.target.value;
                // console.log(e.target.value[0])
               
                // to remove the case like 010 to 10 and 024 to 24 also make sure it is not 0.12
                if (e.target.value[0] == 0 && e.target.value[1] !== '.') {
                  e.target.value = e.target.value.slice(1,);
                }

                // e.target.value = formatNumber2(e.target.value);
                let total = Object.keys(rowData).reduce((accumulator, currentValue) => {

                    if (months.indexOf(currentValue) >= 0) {

                       return accumulator + (+rowData[currentValue]?.toString()?.split(',').join('') || 0)
                    }
                    else return accumulator
                    },
                0)
                // we are not adding normal total calculation for covered price fy
                if(rowData.sourceAlias!=='Covered Price'){
                  rowData.fy = roundToTwo(decider, total)
                }
                // if(data.length){
                //     data[1] = rowData;
                //     setData([...data])
                // }
                // if(subMissionData) {
                //     const payloadData = {
                //         "workflowId":rowData.workflow_id,
                //         "forecastYearMonth":[+forecastVal[0],+forecastVal[1]],
                //         "forecastValue":+value
                //     }
                //     if(!subMissionData[columnName + forecastVal[0]]) subMissionData[columnName + forecastVal[0]] = payloadData
                //     else subMissionData[columnName + forecastVal[0]] = payloadData
                //     setSubmissionData({...subMissionData})
                // }
                let splitArray = e.target.value.split('.');

                // allow to enter till 5 decimal and format into 2 decimal
                if (splitArray.length > 1) {
                  if (splitArray[1].length > 5) {
                    splitArray[1] = splitArray[1].substring(0, splitArray[1].length - 1);
                    // const inputValue = splitArray && splitArray.join('.') ? parseFloat(splitArray.join('.')) : 0;
                    setValue(splitArray.join('.'));
                  } else {
                    let value = e.target && e.target.value ? e.target.value : 0;
                    setValue(value);
                  }
                } else {
                  const inputValue = e.target && e.target.value ? e.target.value : 0;
                  setValue(inputValue);
                }
              }
              const d = new Date();
              setHistoricalTrigger(d.getMilliseconds());
              setLetterFlag(false);
            }}
            onKeyDown={(e) => {
              if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 106 && e.keyCode <= 109) || e.keyCode == 111) {
                setLetterFlag(true);
              }
            }}
            onBlur={(e) => {
           if(e.target.value !== '-'){
            let value = e.target && e.target.value ? parseFloat(e.target.value) : 0;
            // if decimal round it 2 decimal
            if(value % 1 !==0 ) {
                value = parseFloat(value);
            }
            // rowData[columnName] = value;
            // setValue(formatNumber(value))
            if (decider === 0){
              const roundValue = roundToTwo(decider, value);
              rowData[columnName] = roundValue;
              setValue(formatNumber(roundValue))
            } else {
              rowData[columnName] = value;
              setValue(formatNumber(roundToTwo(decider, value)));
            }
            // calculate fy total
            let total = Object.keys(rowData).reduce((accumulator, currentValue) => {
                if (months.indexOf(currentValue) >= 0) return accumulator + (+rowData[currentValue]?.toString()?.split(',').join('') || 0)
                else return accumulator
                },
            0)
            // we are not adding normal total calculation for covered price fy
            if(rowData.sourceAlias!=='Covered Price'){
              rowData.fy = roundToTwo(decider, total)
            }

            if(data.length){
                data[1] = rowData;
                setData([...data])
            }
            if(subMissionData) {
                const payloadData = {
                    "workflowId":rowData.workflow_id,
                    "forecastYearMonth":[+forecastVal[0],+forecastVal[1]],
                    "forecastValue":+value
                }
                if(!subMissionData[columnName + forecastVal[0]]) subMissionData[columnName + forecastVal[0]] = payloadData
                else subMissionData[columnName + forecastVal[0]] = payloadData
                setSubmissionData({...subMissionData})
            }
           }
           else{
            rowData[columnName] = 0
            setValue(0)
           }
        
        }}
            value={value}
          />
        </div>
      ) : (
        <div style={{color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData[columnName]?.toLocaleString('en-US')}</div>
      )}
    </>
  );
};

export const AppTableInput2 = ({handleEdit, columnName, rowIndex, rowData}) => {
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value);
  };
  return (
    <div className="app-table-input">
      <div className="app-table-input-icon"></div>
      <input type="text" onChange={(e) => handleChange(e)} value={isNaN(value)?0:value} />
    </div>
  );
};
