import styled from '@emotion/styled';
import {TableCell, TableRow} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import config from '../../../Utils/Config';
import EditConfTable from './BuyPlanEditRow';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CommonConfSingleRow = ({mappedData, row, setAddingTrigger, addingTrigger, Units, Currency,
  values, idKey, singleUpdate, deleteBuyPlanRow, accessControl, buyplanPersona,
  setErrMsg, setIsErrorPopupVisible, ActiveSectors, country, Playbooks, editBuyPlanRow}) => {
  // eslint-disable-next-line no-unused-vars
  const [editedDetails, setEditDetails] = useState({});
  const Edit = config.AccessLevel.Edit;
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const formatStatus = (selectedValue) => {
    switch (selectedValue) {
      case 'Active':
        return 'ACTIVE';
      case 'Inactive':
        return 'INACTIVE';
      case 'Coverage Suspension':
        return 'COV_SUSPENDED';
      case 'Coverage Review Suspension':
        return 'REVIEW_SUSPENDED';
      case 'ACTIVE':
        return 'Active';
      case 'INACTIVE':
        return 'Inactive';
      case 'COV_SUSPENDED':
        return 'Coverage Suspension';
      case 'REVIEW_SUSPENDED':
        return 'Coverage Review Suspension';
      default:
        return '';
    }
  };

  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(()=>{
    const keyvalues = {};
    values.map((h, index)=>{
      keyvalues[h] = row[h];
    });
    setEditDetails({...keyvalues});
  }, [row, values]);

  const showValue = row.showValue;
  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {showValue && row['play_book_name']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && row['buy_plan_name']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && row['master_sector']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['sector']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['country']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && row['category']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && row['commodity']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && row['sub_commodity']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && (editedDetails.buyplan_owner !== undefined && editedDetails.buyplan_owner != null) ? editedDetails.buyplan_owner : ''}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && (editedDetails.buyplan_creator !== undefined && editedDetails.buyplan_creator != null ? editedDetails.buyplan_creator : '')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && (editedDetails.buyplan_approver !== undefined && editedDetails.buyplan_approver != null? editedDetails.buyplan_approver : '')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        { showValue && (editedDetails.buyplan_admin !== undefined && editedDetails.buyplan_admin != null ? editedDetails.buyplan_admin : '')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && ((editedDetails.forecast_creator !== undefined && editedDetails.forecast_creator != null) ? editedDetails.forecast_creator : '')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && row['hedgingMech']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="center">
        { showValue && (row['is_atlasfx'] ? 'Y' : 'N')}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        { showValue && formatStatus(row['current_status'])}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        { showValue && row['active_start_date']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {(editedDetails.active_end_date !== null && showValue) ? row['active_end_date'] : ''}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && row['last_edited_by']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {showValue && `${new Date(row['last_edited']).getDate() < 10 ? '0' +
              new Date(row['last_edited']).getDate() :
              new Date(row['last_edited']).getDate()}-${new Date(row['last_edited']).getMonth() < 10 ?
          monthArr[new Date(row['last_edited']).getMonth()] :
          monthArr[new Date(row['last_edited']).getMonth()] }-${new Date(row['last_edited']).getFullYear()}`}
      </StyledTableCell>
      {accessControl === Edit && <StyledTableCell align="left">
        {showValue && <EditConfTable
          row= {row}
          mappedData={mappedData}
          Units ={Units}
          Currency={Currency}
          buyplanPersona={buyplanPersona}
          singleUpdate={singleUpdate}
          deleteBuyPlanRow={deleteBuyPlanRow}
          setAddingTrigger={setAddingTrigger}
          values={values}
          idKey ={idKey}
          addingTrigger={addingTrigger}
          setErrMsg ={setErrMsg}
          setIsErrorPopupVisible={setIsErrorPopupVisible}
          formatStatus={formatStatus}
          ActiveSectors={ActiveSectors}
          country={country}
          Playbooks={Playbooks}
          editBuyPlanRow={editBuyPlanRow}
        />}
      </StyledTableCell>}
    </StyledTableRow>
  );
};

export default CommonConfSingleRow;
