/* eslint-disable max-len */
/* for target screen api call and managing the global store data using redux store*/
import {createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../../apis/axiosInstance';

export const TargetApiCalls = createSlice(
    {
      name: 'targetApiCall',
      initialState: {
        targetLogsData: null,
        targetPlayBookData: null,
        targetBuyPlanData: null,
        allTargetNames: null,
        error: false,
        errorMsg: null,
        isSaved: false,
        saveError: false,
        fetchCount: 1,
      },
      reducers: {
        setInitialState: (state, action)=>{
          return {
            targetPlayBookData: null,
            targetLogsData: null,
            targetBuyPlanData: null,
            allTargetNames: null,
            error: false,
            errorMsg: null,
            isSaved: false,
            saveError: false,
            fetchCount: 1,
          };
        },
        targetLogsData: (state, action) => {
          if (action.payload && action.payload.length>0) {
            return {
              ...state,
              targetLogsData: action.payload,
              error: false,
              errorMsg: null,
            };
          } else {
            state.targetLogsData=[];
            // state.error=true;
            // state.errorMsg='No Data Found';
          }
        },
        targetPlayBookData: (state, action) => {
          if (action.payload && action.payload.length>0) {
            return {
              ...state,
              targetPlayBookData: action.payload,
              fetchCount: state.fetchCount+1,
              error: false,
              errorMsg: null,
            };
          } else {
            state.targetPlayBookData=[];
            state.error=true;
            state.errorMsg='No Data Found';
          }
        },
        targetBuyPlanData: (state, action) => {
          if (action.payload && action.payload.length>0) {
            return {
              ...state,
              targetBuyPlanData: action.payload,
              fetchCount: state.fetchCount+1,
              error: false,
              errorMsg: null,
            };
          } else {
            state.targetBuyPlanData = [];
            state.error=true;
            state.errorMsg='No Data Found';
          }
        },
        allTargetNames: (state, action)=>{
          if (action.payload && action.payload.length>0) {
            state.allTargetNames = action.payload;
          }
        },
        savePlaybooks: (state, action)=>{
          if (action.payload && action.payload===true) {
            state.error=false;
            state.isSaved=true;
            state.errorMsg=null;
          } else {
            state.isSaved=false;
            state.error=true;
          }
        },
        saveBuyPlans: (state, action)=>{
          if (action.payload && action.payload===true) {
            state.error=false;
            state.isSaved=true;
            state.errorMsg=null;
          } else {
            state.isSaved=false;
            state.error=true;
          }
          state.saving=false;
        },
        startSavingTarget: (state)=>{
          state.isSaved=false;
          state.error=false;
        },
        saveTargetFailed: (state, action)=>{
          state.isSaved=false;
          state.error=true;
          state.errorMsg=action?.payload?.response?.data?.userMessage;
        },
        getTargetFailed: (state, action)=>{
          state.targetBuyPlanData = [];
          state.targetPlayBookData=[];
          state.error=true;
          state.errorMsg=action?.payload?.response?.data?.userMessage;
        },
        startGettingTarget: (state, action)=>{
          state.error=false;
          state.errorMsg=null;
        },
        startGettingLogs: (state, action)=>{
          state.error=false;
          state.errorMsg=null;
        },
        getLogsFailed: (state, action)=>{
          state.targetLogsData = [];
          state.error=true;
          state.errorMsg=action?.payload?.response?.data?.userMessage;
        },
      },
    });

export const getTargetPlayBookData = (playBookId) => async (dispatch) => {
  dispatch(startGettingTarget());
  axiosInstance.get(`/target/playbookTargets?playBookId=${playBookId}`)
      .then((resp) => {
        dispatch(targetPlayBookData(resp.data));
      })
      .catch((err) => {
        console.error('Error target api call err : ', err);
        dispatch(getTargetFailed(err));
      },
      );
};

export const getTargetLogsData = (id, pb) => async (dispatch) => {
  dispatch(startGettingLogs());
  const url = pb ? `/target/playBookTargetLogs?playbookId=${id}` : `/target/buyPlanTargetLogs?buyplanId=${id}`;
  axiosInstance.get(url)
      .then((resp) => {
        dispatch(targetLogsData(resp.data));
      })
      .catch((err) => {
        console.error('Error target api call err : ', err);
        dispatch(targetLogsData(err));
      },
      );
};

export const getTargetBuyPlanData = (buyPlanId) => async (dispatch) => {
  dispatch(startGettingTarget());
  axiosInstance.get(`/target/buyplanTargets?buyPlanId=${buyPlanId}`)
      .then((resp) => {
        dispatch(targetBuyPlanData(resp.data));
      })
      .catch((err) => {
        dispatch(getTargetFailed(err));
        console.error('Error buyplanTargets api call err : ', err);
      });
};

export const getAllTargetNames = () => async (dispatch) => {
  axiosInstance.get(`/target/getAllTargetNames`)
      .then((resp) => {
        dispatch(allTargetNames(resp.data));
      })
      .catch((err) => console.error('getAllTargetNames api err : ', err));
};

export const savePlaybooksHandler = (data) => async (dispatch) => {
  dispatch(startSavingTarget());
  axiosInstance.post(`/target/playBookTargets/save`, data)
      .then((resp) => {
        dispatch(savePlaybooks(resp.data));
      })
      .catch((err) => {
        dispatch(saveTargetFailed(err));
        console.error('savePlaybooks api err : ', err);
      });
};

export const saveBuyPlansHandler = (data) => async (dispatch) => {
  dispatch(startSavingTarget());
  axiosInstance.post(`/target/buyPlanTargets/save`, data) // /target/buyPlanTargets/save
      .then((resp) => {
        dispatch(saveBuyPlans(resp.data));
      })
      .catch((err) => {
        dispatch(saveTargetFailed(err));
        console.error('saveBuyPlansHandler api err : ', err);
      });
};


export const {
  targetLogsData, targetPlayBookData, targetBuyPlanData, allTargetNames, savePlaybooks, startSavingTarget, saveBuyPlans,
  saveTargetFailed, startGettingTarget, getTargetFailed, getLogsFailed, startGettingLogs, setInitialState,
} = TargetApiCalls.actions;

// this is for configureStore
export default TargetApiCalls.reducer;
