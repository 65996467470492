/* eslint-disable camelcase */
/* eslint-disable max-len */
import {createSlice} from '@reduxjs/toolkit';
// import axios from 'axios';
import DialogService from '../../services/dialogService';
import axiosInstance from '../../apis/axiosInstance';
import {hedgingDecider} from '../../services/utils';

export const apiCallSlice = createSlice(
    {
      name: 'apiCall',
      initialState: {
        apiValueSummaryMetricsData: [],
        apiValueDropdownData: [],
        apiiValueNonPlayBookDropdownData: [],
        apiValueDropdownDataAll: [],
        apiValueBuyPlanData: [],
        apiValueNonPlayBookData: [],
        apiValuePopupStepper: [],
        apiValueCoverageData: [],
        selectedDropDownData: {},
        selectedNonPlayBookDropDownData: {},
        selectedForecastDropDown: {},
        apiRespBuyPlanDataStatusChanged: 1,
        PlanStatusChange: '',
        commentTaggingRoles: [],
        selectedRolesTagged: [],
      },
      reducers: {

        callSummaryMetricsDataAPI: (state, action) => {
          state.apiValueSummaryMetricsData = action.payload;
        },
        callDropdownDataAPI: (state, action) => {
          state.apiValueDropdownData = action.payload;
        },
        callNonPlayBookDropdownDataAPI: (state, action) => {
          state.apiiValueNonPlayBookDropdownData = action.payload;
        },
        callDropdownDataAllAPI: (state, action) => {
          state.apiValueDropdownDataAll = action.payload;
        },
        callBuyPlanListAPI: (state, action) => {
          state.apiValueBuyPlanData = action.payload;
        },
        callNonPlayBookListAPI: (state, action) => {
          state.apiValueNonPlayBookData = action.payload;
        },
        callPopupStepperAPI: (state, action)=> {
          state.apiValuePopupStepper = action.payload;
        },
        callCoverageDataAPI: (state, action) => {
          state.apiValueCoverageData = action.payload;
        },
        setSelectedDropDownData: (state, action)=>{
          state.selectedDropDownData = action.payload;
        },
        setSelectedNonPlayBookDropDownData: (state, action)=>{
          state.selectedNonPlayBookDropDownData = action.payload;
        },
        setForecastFilter: (state, action) =>{
          state.selectedForecastDropDown = action.payload;
        },
        setClearForecastFilter: (state, action) =>{
          state.selectedForecastDropDown = {};
        },
        setPlanStatusChange: (state, action) =>{
          state.PlanStatusChange = action.payload;
        },
        setCommentTagging: (state, action) =>{
          state.commentTaggingRoles = action.payload;
        },
        setSelectedRolesTagged: (state, action) =>{
          state.selectedRolesTagged = action.payload;
        },
        updateStatusOfBuyPlanData: (state, action)=> {
          const wfid=action.payload.id;
          if (state.apiValueBuyPlanData) {
            const data=state.apiValueBuyPlanData;
            const length= data.length;
            let foundIndex=null;
            for (let i = 0; i < length; i++) {
              if (data[i].workFlowId && data[i].workFlowId === wfid) {
                // this is the buyplan to be updated
                foundIndex=i;
                // state.apiValueBuyPlanData[i].status=action.payload.status
                break; // for performance
              }
            }
            if (foundIndex) {
              const updatedObj= {...state.apiValueBuyPlanData[foundIndex], status: action.payload.status, forecastSubmitted: action.payload.args.length>0 ? action.payload.args[0]?.isForecastSubmitted : state.apiValueBuyPlanData[foundIndex].forecastSubmitted};
              const updatedData = [
                ...data.slice(0, foundIndex),
                updatedObj,
                ...data.slice(foundIndex + 1),
              ];
              // console.log('trying to update state ')
              return {
                ...state,
                apiValueBuyPlanData: updatedData,
                // apiRespBuyPlanDataStatusChanged:state.apiRespBuyPlanDataStatusChanged+1
              };
            }
          }
        },
        updateStatusOfNonPlayBookData: (state, action)=> {
          const wfid=action.payload.id;
          // console.log(action.payload);
          if (state.apiValueNonPlayBookData) {
            const data=state.apiValueNonPlayBookData;
            const length= data.length;
            let foundIndex=null;
            for (let i = 0; i < length; i++) {
              if (data[i].workflow_id && data[i].workflow_id === wfid) {
                // this is the buyplan to be updated
                foundIndex=i;
                // state.apiValueBuyPlanData[i].status=action.payload.status
                break; // for performance
              }
            }
            // console.log(foundIndex);
            if (foundIndex) {
              const updatedObj= {...state.apiValueNonPlayBookData[foundIndex], status: action.payload.status};
              const updatedData = [
                ...data.slice(0, foundIndex),
                updatedObj,
                ...data.slice(foundIndex + 1),
              ];
              // console.log('trying to update state ')
              return {
                ...state,
                apiValueNonPlayBookData: updatedData,
                // apiRespBuyPlanDataStatusChanged:state.apiRespBuyPlanDataStatusChanged+1
              };
            }
          }
        },
      },
    });

export const getSummaryMetricsData_API = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/home/getKPIData').then((resp) => resp);
    dispatch(callSummaryMetricsDataAPI(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getDropDownData_API = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/home/getDropDownData').then((resp) => resp);
    dispatch(callDropdownDataAPI(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getNonPlayBookDropDownData_API = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/nonPlaybook/dropdown').then((resp) => resp);
    dispatch(callNonPlayBookDropdownDataAPI(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const getDropDownDataAll_API = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/buyplan/getAllDropDownData').then((resp) => resp);
    dispatch(callDropdownDataAllAPI(response.data));
  } catch (err) {
    throw new Error(err);
  }
};
export const getBuyPlanData_API = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/home/getBuyPlanList').then((resp) => resp);
    let result = response.data;

    if (Array.isArray(result)) {
      result = result?.map((r) => ({...r, hedgingMech: hedgingDecider(r?.coverageMode, r?.others)}));
    }

    dispatch(callBuyPlanListAPI(result));
  } catch (err) {
    throw new Error(err);
  }
};

export const convertToCamelCase = (obj) => {
  const camelCaseObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = key;
      if (camelCaseKey=='execution_month') {
        camelCaseObj[camelCaseKey] = [+obj[key].split('-')[0], +obj[key].split('-')[1]];
      } else if (camelCaseKey == 'coverage_year_month') {
        camelCaseObj[camelCaseKey] = [+obj[key].split('-')[0], +obj[key].split('-')[1]];
      } else if (camelCaseKey == 'forecast_year_month') {
        camelCaseObj[camelCaseKey] = [+obj[key].split('-')[0], +obj[key].split('-')[1]];
      } else {
        camelCaseObj[camelCaseKey] = obj[key];
      }
    }
  }
  return camelCaseObj;
};

export const getNonPlayBookData_API = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/nonPlaybook/workflows').then((resp) => resp);
    dispatch(callNonPlayBookListAPI(response.data.map((obj)=> convertToCamelCase(obj))));
  } catch (err) {
    throw new Error(err);
  }
};


export const getCoverageData_API = (id) => async (dispatch) => {
  try {
    const response = await DialogService.getCoverageData(id).then((resp) => resp);
    dispatch(callCoverageDataAPI(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const updateStatusOfBuyPlan = (id, status, ...args) => async (dispatch) => {
  try {
    dispatch(updateStatusOfBuyPlanData({id: id, status: status, args: args}));
  } catch (err) {
    throw new Error(err);
  }
};

export const updateCommentTagging = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/users/getActiveUsers').then((resp) => resp);
    dispatch(setCommentTagging(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const {callSummaryMetricsDataAPI, setSelectedRolesTagged, setCommentTagging, updateStatusOfNonPlayBookData, callNonPlayBookDropdownDataAPI, setSelectedNonPlayBookDropDownData, callNonPlayBookListAPI, setPlanStatusChange, callDropdownDataAPI, callDropdownDataAllAPI, callBuyPlanListAPI, callPopupStepperAPI, callCoverageDataAPI, setSelectedDropDownData, setForecastFilter, setClearForecastFilter, updateStatusOfBuyPlanData} = apiCallSlice.actions;

// this is for configureStore
export default apiCallSlice.reducer;
