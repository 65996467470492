/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn} from 'react-datasheet-grid';
// DataSheetGridPropsBase

// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../services/dialogService';
import {months, titleCase} from '../../services/utils';
// import {AppDialog} from '../common/App-Dialog/AppDialog';
import {AppTableInput} from './AppTableInput/AppTableInput';
import {CopyIcon} from '../common/Icons/icons';
import './forecast.css';
import {AppButton} from '../common/AppButton/AppButton';
// import {defaultObj} from './consts';
import {forecastDefaultData, fyCalculateTotal, getSortedMonthlyData, monthNYearSetter, rowsSetter} from './utils';
// import {useSelector} from 'react-redux';

const PreviousMonthTable = ({
  subMissionData,
  setSubmissionData,
  years,
  setTabsEnableCheck,
  data,
  setData,
  setModifiedData,
  selectedYear,
  modifiedData,
  selectedRow,
}) => {
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const curMonth = new Date().getMonth() - 2;
  // eslint-disable-next-line no-unused-vars
  const [forecastData, setForecastData] = useState([]);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const [columns, setColumns] = useState([]);
  const [workflowIdsObj, setWorkflowIdsObj] = useState({});
  const [accessControl, setAccessControl]=useState('');

  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Forecast_Update']) {
      setAccessControl(functionalityAccess['Buy_Plan:Forecast_Update']);
    }
  }, [functionalityAccess]);

  const [firstLoad, setFirstLoad] = useState(false);
  const [skipper, setSkipper] = useState(false);
  const columnsSetter = () => {
    const columns = [];
    months.forEach((mon) => {
      columns.push({
        ...keyColumn(mon, textColumn),
        component: ({rowData}) => (
          <AppTableInput
            data={data}
            subMissionData={subMissionData}
            setSubmissionData={setSubmissionData}
            setData={setData}
            isDisabled={isDisabled(rowData, mon)}
            columnName={mon}
            forecastVal={[selectedYear, 1]}
            rowData={rowData}
          />
        ),
        title: titleCase(mon),
        disabled: ({rowData}) =>
        accessControl === 'Edit' ?
          // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
          buttonStatus==='edit' ?
            rowData?.key == 1 ?
              true :
              !(selectedYear > selectedRow.executionMonthYear[0]) ?
              months[curMonth] == mon ?
                true :
                false :
              false :
            true:
        true,

      });
    });
    return columns;
  };
  useEffect(() => {
    setColumns([]);
    if (selectedYear && selectedYear != 0) {
      let arr = fyCalculateTotal(modifiedData[selectedYear]);
      if (arr.length == 1) {
        const copy = JSON.parse(JSON.stringify(workflowIdsObj));
        arr = getSortedMonthlyData(arr, selectedRow, copy);
      }
      setData(arr);
      setTimeout(() => {
        const cols = columnsSetter();
        // set columns value as per condition
        if (!(buttonStatus==='edit')) {
          setColumns((pre) => [
            {
              ...keyColumn('planMonth', textColumn),
              title: 'Plan month',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            {
              ...keyColumn('planYear', textColumn),
              title: 'Plan year',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            ...cols,
            {...keyColumn('fy', textColumn), title: `FY ${selectedYear} Total`, disabled: true},
          ]);
        } else {
          setColumns([
            {
              ...keyColumn('planMonth', textColumn),
              title: 'Plan month',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            {
              ...keyColumn('planYear', textColumn),
              title: 'Plan year',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            {
              ...keyColumn('jan', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'jan')}
                  columnName="jan"
                  forecastVal={[selectedYear, 1]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Jan',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'jan' ?
              //         true :
              //         false :
              //       false :
              //     true:
              //     true,
            },
            {
              ...keyColumn('feb', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'feb')}
                  columnName="feb"
                  forecastVal={[selectedYear, 2]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Feb',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'feb' ?
              //         true :
              //         false :
              //       false :
              //     true:
              //     true,
            },
            {
              ...keyColumn('mar', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'mar')}
                  columnName="mar"
                  forecastVal={[selectedYear, 3]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Mar',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'mar' ?
              //         true :
              //         false :
              //       false :
              //     true:
              //     true,
            },
            {
              ...keyColumn('apr', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'apr')}
                  columnName="apr"
                  forecastVal={[selectedYear, 4]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Apr',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'apr' ?
              //         true :
              //         false :
              //       false :
              //     true:
              //     true,
            },
            {
              ...keyColumn('may', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'may')}
                  columnName="may"
                  forecastVal={[selectedYear, 5]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'May',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'may' ?
              //         true :
              //         false :
              //       false :
              //     true:
              // true,
            },
            {
              ...keyColumn('jun', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'jun')}
                  columnName="jun"
                  forecastVal={[selectedYear, 6]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Jun',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'jun' ?
              //         true :
              //         false :
              //       false :
              //     true:
              // true,
            },
            {
              ...keyColumn('jul', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'jul')}
                  columnName="jul"
                  forecastVal={[selectedYear, 7]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Jul',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'jul' ?
              //         true :
              //         false :
              //       false :
              //     true:
              // true,
            },
            {
              ...keyColumn('aug', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'aug')}
                  columnName="aug"
                  forecastVal={[selectedYear, 8]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Aug',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'aug' ?
              //         true :
              //         false :
              //       false :
              //     true:
              // true,
            },
            {
              ...keyColumn('sep', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'sep')}
                  columnName="sep"
                  forecastVal={[selectedYear, 9]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Sep',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'sep' ?
              //         true :
              //         false :
              //       false :
              //     true:
              //     true,
            },
            {
              ...keyColumn('oct', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'oct')}
                  columnName="oct"
                  forecastVal={[selectedYear, 10]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Oct',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'oct' ?
              //         true :
              //         false :
              //       false :
              //     true:
              // true,
            },
            {
              ...keyColumn('nov', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'nov')}
                  columnName="nov"
                  forecastVal={[selectedYear, 11]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Nov',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'nov' ?
              //         true :
              //         false :
              //       false :
              //     true:
              // true,
            },
            {
              ...keyColumn('dec', textColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'dec')}
                  columnName="dec"
                  forecastVal={[selectedYear, 12]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Dec',
              // disabled: ({rowData}) =>
              // accessControl === 'Edit' ?
              //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
              //   buttonStatus==='edit' ?
              //     rowData?.key == 1 ?
              //       true :
              //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
              //       months[curMonth] == 'dec' ?
              //         true :
              //         false :
              //       false :
              //     true:
              // true,
            },
            {...keyColumn('fy', textColumn), title: `FY ${selectedYear} Total`, disabled: true},
            {
              component: ({rowData}) =>
                rowData?.key == 1 ? (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      copyRow(rowData);
                    }}
                  >
                    <CopyIcon />
                  </span>
                ) : (
                  ''
                ),
              title: '',
            },
          ]);
        }
      }, 100);
    }
  }, [selectedYear]);

  useEffect(() => {
    setTabsEnableCheck((prev) => {
      return {...prev, buyPlan: false};
    });
    if (selectedRow.prevWorkFlowId) getCall();
  }, [selectedRow]);

  const getCall = () => {
    DialogService.getForecastData(selectedRow.workFlowId, selectedRow.prevWorkFlowId)
        .then((res) => {
          if (res.length) {
            setForecastData(res);
            let obj = {};
            res.forEach((val) => {
              if (!workflowIdsObj[val.workflow_id]) {
                workflowIdsObj[val.workflow_id] = val;
                setWorkflowIdsObj({...workflowIdsObj});
              }
              // let key = val.executionMonthYear[0] + ',' + months[val.executionMonthYear[1]-1]
              obj = {...monthNYearSetter(obj, val)};
            });
            obj = {...rowsSetter(obj, workflowIdsObj, selectedRow)};
            // console.log(obj);
            setModifiedData(obj);
          } else {
          // when there is no data for a all three years
            const obj = forecastDefaultData(selectedRow);
            // console.log(obj);
            setModifiedData(obj);
          }
        })
        .catch((err) => console.log(err));
  };

  const postCall = () => {
    const payload = Object.values(subMissionData).map((val) => val);
    console.log(payload);
    // DialogService.saveForecastData(payload).then((res) => {
    //   if (res == true) {
    //     setIsSavePopupVisible(true);
    //     setTabsEnableCheck((prev) => {
    //       return {...prev, buyPlan: true};
    //     });
    //   }
    // });
  };

  const copyRow = (row) => {
    const forecastYearMonth = data[1];
    data[1] = {...row, planMonth: forecastYearMonth.planMonth, workflow_id: forecastYearMonth.workflow_id, month: forecastYearMonth.month, key: forecastYearMonth.key};
    setData([...data]);
  };

  const isDisabled = (rowData, month) => {
    const flag = months.indexOf(month);
    const is =
    accessControl === 'Edit' ?
      // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
      buttonStatus==='edit' ?
        rowData?.key == 1 || (selectedYear == selectedRow.executionMonthYear[0] && curMonth >= flag) ?
          true :
          false :
        true :
    true;
    return is;
  };

  useEffect(() => {
    if (!skipper) pasteCall();
    setIsSavePopupVisible(false);
  }, [data]);

  const pasteCall = () => {
    let fyTotal = 0;
    if (firstLoad) {
      Object.keys(data[1]).forEach((val, i) => {
        if (months.includes(val)) {
          fyTotal += +data[1][val];
          const selectedMonth = months.indexOf(val);
          const payloadData = {
            'workflowId': data[1].workflow_id,
            'forecastYearMonth': [+selectedYear, selectedMonth+1],
            'forecastValue': data[1][val],
          };
          if (!subMissionData[val + selectedYear]) subMissionData[val + selectedYear] = payloadData;
          else subMissionData[val + selectedYear] = payloadData;
        }
      });
      setSubmissionData({...subMissionData});
      data[1] = {...data[1], fy: fyTotal};
      setData([...data]);
    }
    if (!firstLoad) {
      setFirstLoad(true);
    }
    setSkipper(true);
    setTimeout(() => {
      setSkipper(false);
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('paste', pasteCall());
    return window.removeEventListener('paste', pasteCall());
  }, []);

  useEffect(() => {
    setIsSavePopupVisible(false);
  }, [modifiedData]);

  return (
    <>
      {((columns.length && data.length) || '') && (
        <div className="forecast-table">
          {selectedYear == years[0] ? (
            <DataSheetGrid
              value={[data[0]]}
              onChange={setData}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          ) : (
            ''
          )}

          {selectedYear == years[1] ? (
            <DataSheetGrid
              value={[data[0]]}
              onChange={setData}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          ) : (
            ''
          )}

          {selectedYear == years[2] ? (
            <DataSheetGrid
              value={[data[0]]}
              onChange={setData}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          ) : (
            ''
          )}

          {/* {accessControl === 'Edit' && (selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT') ? ( */}
          {accessControl === 'Edit' && buttonStatus==='edit' ? (
            <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
              <AppButton
                onClick={() => {
                  postCall();
                }}
                label={isSavePopupVisible ? 'Saved' : 'Save'}
              />
            </Box>
          ) : (
            ''
          )}
        </div>
      )}
      {/* <AppDialog
        open={isSavePopupVisible}
        handleClose={() => setIsSavePopupVisible(false)}
        text="Forecast details saved successfully!"
        type={1}
        setNo="1"
        setYes="1"
      /> */}
    </>
  );
};

export default PreviousMonthTable;
