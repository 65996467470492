export const defaultObj = {
  month: '',
  planMonth: '',
  planYear: '',
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0,
  fy: 0,
  workflow_id: null,
};
