/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, {useState} from 'react';
import './DropdownSelect.css';
// import {RiArrowDropDownLine} from 'react-icons/ri';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';

const DropdownSelect = ({setSourceTypeChoosen, supplierNames, selectedRow, rowData, indexOfYear, apiData}) => {
  const [sourceselected, setSourceNameSelected] = React.useState('');
  const [dropDownRequired, setDropDownRequired] = React.useState(false);
  const [dropDownValues, setDropDownValues] = React.useState([]);
  const isCreator = useSelector((state) => state.roleBasedAccess);

  const handleChange = (event) => {
    setSourceNameSelected(event.target.value);
    apiData[indexOfYear].map((element, index)=>{
      if (element.id==rowData.id) {
        element.sourceType = event.target.value;
        rowData.sourceType = event.target.value;
        if (rowData?.prevMonth) {
          element['author'] = isCreator?.userName;
          rowData['author'] = isCreator?.userName;
          element['isChanged'] = true;
          rowData['isChanged'] = true;
        }
      }
    });
  };

  useEffect(()=>{
    let dropDownRequired = '';
    const hedgingTypes = [];
    if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== false) {
      hedgingTypes.push('SUPPLIER');
      dropDownRequired = false;
    } else if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== true) {
      hedgingTypes.push('SUPPLIER');
      hedgingTypes.push('OTHER');
      dropDownRequired = true;
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== false) {
      hedgingTypes.push('TREASURY');
      dropDownRequired = false;
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== true) {
      hedgingTypes.push('TREASURY');
      hedgingTypes.push('OTHER');
      dropDownRequired = true;
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others== false) {
      hedgingTypes.push('SUPPLIER');
      hedgingTypes.push('TREASURY');
      dropDownRequired = true;
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others==true) {
      hedgingTypes.push('SUPPLIER');
      hedgingTypes.push('TREASURY');
      hedgingTypes.push('OTHER');
      dropDownRequired = true;
    } else if (selectedRow.coverageMode=='NONE') {
      hedgingTypes.push('OTHER');
      dropDownRequired = false;
    }
    setDropDownRequired(dropDownRequired);
    setDropDownValues(hedgingTypes);
  }, [selectedRow]);

  useEffect(()=>{
    setSourceTypeChoosen(sourceselected || rowData.sourceType.toUpperCase() || '' );
  }, [sourceselected]);

  useEffect(()=>{
    setSourceNameSelected((rowData?.sourceType?.charAt(0).toUpperCase() + rowData?.sourceType?.slice(1).toUpperCase()) || '');
  }, [rowData]);

  return (
    dropDownRequired ?
      <FormControl variant='standard' sx={{width: '95px', fontSize: '12px'}}>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          defaultValue={'Select'}
          value={sourceselected || rowData?.sourceType?.charAt(0).toUpperCase() + rowData?.sourceType?.slice(1).toUpperCase()}
          placeholder={'Select'}
          onChange={handleChange}
          label='Age'
          disabled={(rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume)}
          sx={{width: '95px', fontSize: '12px'}}
        >
          {
            dropDownValues.map((name, id) => {
              return <MenuItem sx={{fontSize: '13px'}} key={id} value={name}>{name}</MenuItem>;
            })
          }
        </Select>
      </FormControl> :
    <div className='saveCoverageInput'>
      {dropDownValues[0]}
    </div>
  );
};


export default DropdownSelect;


