import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
const DropdownSelectSourceName = ({sourceTypeChoosen, supplierNames, rowData, indexOfYear, apiData}) => {
  const [supplierNameselected, setSupplierNameSelected] = React.useState('');
  const isCreator = useSelector((state) => state.roleBasedAccess);

  const handleChange = (event) => {
    setSupplierNameSelected(event.target.value);
    if (event.target.value === 'No Data Found') {
      setSupplierNameSelected('');
      return;
    }
    apiData[indexOfYear].map((element, index)=>{
      if (element.id==rowData.id) {
        element.sourceName = event.target.value;
        rowData.sourceName = event.target.value;
        if (rowData?.prevMonth) {
          element['author'] = isCreator?.userName;
          rowData['author'] = isCreator?.userName;
          element['isChanged'] = true;
          rowData['isChanged'] = true;
        }
      }
    });
  };
  if (rowData.sourceType?.toUpperCase() === 'TREASURY' || rowData.sourceType?.toUpperCase() === 'OTHER') {
    apiData[indexOfYear].map((element, index)=>{
      if (element.id==rowData.id) {
        element.sourceName = rowData.sourceType?.toUpperCase() === 'TREASURY' ? 'TREASURY' : 'OTHER';
        rowData.sourceName = rowData.sourceType?.toUpperCase() === 'TREASURY' ? 'TREASURY' : 'OTHER';
      }
    });
  }

  if (rowData.sourceType?.toUpperCase() === 'SUPPLIER' && (rowData.sourceName === 'TREASURY' || rowData.sourceName === 'OTHER')) {
    apiData[indexOfYear].map((element, index)=>{
      if (element.id==rowData.id) {
        element.sourceName = '';
        rowData.sourceName = '';
        setSupplierNameSelected('');
      }
    });
  }


  useEffect(()=>{
    setSupplierNameSelected(rowData.sourceName !== '' ? rowData.sourceName : '');
  }, [rowData]);

  useEffect(()=>{
    setSupplierNameSelected(rowData.sourceName || rowData.sourceId || '');
    apiData[indexOfYear].map((element, index)=>{
      if (element.id==rowData.id) {
        element.sourceName = rowData.sourceName || rowData.sourceId;
        rowData.sourceName = rowData.sourceName || rowData.sourceId;
      }
    });
  }, [apiData, indexOfYear, rowData]);
  return (
    !(rowData.sourceType.toUpperCase() === 'TREASURY' || rowData.sourceType.toUpperCase() === 'OTHER') ?
    <div>
      <FormControl variant='standard' sx={{width: '78px', fontSize: '12px'}}>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          defaultValue={'Select'}
          value={supplierNameselected || ''}
          disabled={(rowData.sourceType.toUpperCase() === 'TREASURY' || rowData.sourceType.toUpperCase() === 'OTHER' || (rowData?.isAtlasFX || rowData?.disableCell))}
          placeholder={'Select'}
          onChange={handleChange}
          label='Age'
          sx={{width: '78px', fontSize: '12px'}}
        >
          {
            (supplierNames.length > 0 ? [...supplierNames]: ['No Data Found']).map((supplier, id) => {
              return <MenuItem sx={{fontSize: '13px'}} key={id} value={supplier.supplier_id} >{supplier.supplier_name}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </div>:
    <div className='saveCoverageInput'>
      {rowData.sourceType?.toUpperCase()}
    </div>
  );
};


export default DropdownSelectSourceName;
