/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Comments, CommentsTable} from '../EditBuyPlan/Components';
import {useSelector} from 'react-redux';
import DialogService from '../../services/dialogService';
import DocumentUpload from '../DocumentUpload/DocumentUpload';
import PreviousVarianceTable from '../ForecastTab/PreviousVarianceTable';
import PastMonthForecastCommentsTable from '../common/PreviousMonthCommentsTable/PastMonthForecastCommentsTable';
import ConfigurationServices from '../../services/ConfigurationServices';
import CarryVolumeReasons from '../common/PreviousMonthCommentsTable/CarryVolumeReasons';

const Audit = ({selectedRow}) => {
  const userid = useSelector((state) => state?.roleBasedAccess?.userId);
  const [buyplancommentsData, setbuyplanCommentsData] = useState([]);
  const [PastMonthpreviousCommentsData, setPastMonthpreviousCommentsData] = useState([]);
  const [previousvarianceData, setVarianceData] = useState([]);
  const [CarryVolumeReasonsData, setCarryVolumeReasonsData] = useState([]);

  //   const [buyplanapproverCommentsData, setbuyplanApproverCommentsData] = useState([]);
  const [coveragecommentsData, setcoverageCommentsData] = useState([]);
  useEffect(() => {
    getcoverageComments();
  }, [selectedRow]);
  const getcoverageComments = async () => {
    await DialogService.getCoverageComments(selectedRow.workFlowId).then((res) => {
      // console.log(res);
      if (res.length) {
        setcoverageCommentsData(res);
      }
    });
  };

  useEffect(() => {
    DialogService.getComments(selectedRow.workFlowId).then((res) => {
      // console.log(res);
      if (res.length) {
        // const approver = res.filter((val) => val.is_approver);
        // const nonApprover = res.filter((val) => !val.is_approver);
        // setbuyplanApproverCommentsData(approver);
        setbuyplanCommentsData(res);
      }
    });
  }, []);


  useEffect(()=>{
    ConfigurationServices.getForecastVariance(selectedRow.workFlowId).then((res)=>{
      setVarianceData([...res]);
    }).catch((err)=>{
      console.log(err);
    });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getPastMonthComments(selectedRow.workFlowId, 'FORECAST').then((res)=>{
      const apidata = [...res];
      setPastMonthpreviousCommentsData([...apidata]);
    }).catch((err)=>{
      console.log(err);
    });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCarryVolumeReason(selectedRow.workFlowId).then((res)=>{
      setCarryVolumeReasonsData([...res]);
    }).catch((err)=>{
      console.log(err);
    });
  }, []);

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '20px'}}>
      <PastMonthForecastCommentsTable data={PastMonthpreviousCommentsData} header={'Historical Forecast Update Reasons'}/>
      <PreviousVarianceTable data={previousvarianceData}/>
      <CarryVolumeReasons data={CarryVolumeReasonsData} header={'Carry Volume Reasons'}/>
      <DocumentUpload text="Buy Plan Document Upload" selectedRow={selectedRow} uploadedFrom={'workflow'} EditDocument={false} />

      {/* {buyplanapproverCommentsData.length > 0 && (
        <ApproverComments
          text="Buyplan Approver Comments"
          DisableComments={true}
          userid={userid}
          approverCommentsData={buyplanapproverCommentsData}
        />
      )} */}
      {/* <div style={{margin: '10px 0px'}}></div> */}


      <CommentsTable text="Buy Plan Comments" clName={'auditbuyplanApproverComments'} DisableComments={true} userid={userid} commentsData={buyplancommentsData} />

      <div style={{margin: '10px 0px'}}></div>

      <DocumentUpload text="Coverage Document Upload" selectedRow={selectedRow} uploadedFrom={'coverage'} EditDocument={false} />

      <CommentsTable clName={'auditcoverageComments'} text="Coverage Comments" DisableComments={true} userid={userid} commentsData={coveragecommentsData} />

    </div>
  );
};

export default Audit;
