import {Tooltip} from '@mui/material';
import React from 'react';

const InfoTooltipIcon = ({hoverTitle='Info', toolTipConfig}) => {
  const configs = {placement: 'bottom', ...toolTipConfig};
  return (
    <Tooltip title={hoverTitle} arrow style={{cursor: 'pointer'}} {...configs}>
      <span className='plusIcon'>i</span>
    </Tooltip>
  );
};

export default InfoTooltipIcon;
