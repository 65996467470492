/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Button} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {DataSheetGrid, textColumn, keyColumn} from 'react-datasheet-grid';
import {AppButton} from '../../common/AppButton/AppButton';
import 'react-datasheet-grid/dist/style.css';
import './SaveCoverage.css';
import ClearIcon from '@mui/icons-material/Clear';
import {AppDialog, AppErrorDialog, BulkUploadError} from '../../common/App-Dialog/AppDialog';
import {commaTextToNumber, formatNumber, monthArr, roundToTwo} from '../../../services/utils';
import DialogService from '../../../services/dialogService';
import DropdownSelect from './DropdownSelect/DropdownSelect';
import SaveCoverageInput from './SaveCoverageInput/SaveCoverageInput';
import DropdownSelectMonth from './DropdownSelectMonth/DropdownSelectMonth';
import Styles from '../../../styles/workflowModal';
import AddIcon from '@mui/icons-material/Add';
import {useRef} from 'react';
import {useLayoutEffect} from 'react';

import {useSelector} from 'react-redux';
import DropdownSelectSector from './DropdownSelectSector/DropdownSelectSector';
import DropdownSelectCountry from './DropdownSelectCountry/DropdownSelectCountry';

const InputComponent = ({wfDetails, focus, i, updateTotalValues, selectedRow, keyToCheck, indexOfYear, apiData, rowData, typeOfCell = 'number'}) => {
  const [inputValue, setInputValue] = useState(rowData[keyToCheck] || '');
  const ref = useRef(null);
  const decider = wfDetails?.decimal_scale;

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);

  useEffect(()=>{
    let updatedValue = rowData[keyToCheck] || '';
    if (typeOfCell !=='text') {
      const roundedValue = commaTextToNumber(rowData[keyToCheck]);
      updatedValue = (rowData[keyToCheck] === undefined || rowData[keyToCheck] == null || rowData[keyToCheck] == '') ? '' : (isNaN(roundedValue) ? 0 : roundedValue);
    }
    if (typeOfCell==='text') {
      rowData[keyToCheck] = updatedValue;
    } else if (typeOfCell!='text' && updatedValue === 0) {
      rowData[keyToCheck] = 0;
    }
    // setInputValue(commaTextToNumber(updatedValue));
    if (keyToCheck === 'coverageVolume' || keyToCheck === 'coveredPrice') {
      setInputValue(roundToTwo(decider, Number(commaTextToNumber(updatedValue))));
    } else {
      setInputValue(commaTextToNumber(updatedValue));
    }
  }, [rowData]);

  const handleChangeValue = (event, keyToCheck, rowData, selectedRow, indexOfYear, apiData, decimalPoint, i) => {
    let valueToUpdate = event.target.value;
    if (typeOfCell!='text') {
      valueToUpdate = formatNumber(event.target.value, decimalPoint);
    }
    apiData[indexOfYear].map((element, index)=>{
      if (index==i) {
        element[keyToCheck] = valueToUpdate;
        rowData[keyToCheck] = valueToUpdate;
      }
    });
    if (typeOfCell!='text') {
      updateTotalValues();
    }
    setInputValue(valueToUpdate);
  };

  return (
    <>
      <input
        ref={ref}
        className={'dsg-input dsg-input-align-right'}
        value={inputValue}
        type={typeOfCell}
        onBlur={(e) => {
          let valueToUpdate = e.target.value;
          if (typeOfCell!='text' && String(valueToUpdate).includes('.')) {
            valueToUpdate = Number(valueToUpdate);
          }
          apiData[indexOfYear].map((element, index)=>{
            if (index==i) {
              element[keyToCheck] = valueToUpdate;
              // rowData[keyToCheck] = valueToUpdate; //db update
              if (keyToCheck === 'coverageVolume' || keyToCheck === 'coveredPrice') {
                rowData[keyToCheck] = decider === 0 ? roundToTwo(decider, valueToUpdate) : valueToUpdate;
              } else {
                rowData[keyToCheck] = valueToUpdate;
              }
            }
          });
          // setInputValue(valueToUpdate);  // UI update
          if (keyToCheck === 'coverageVolume' || keyToCheck === 'coveredPrice') {
            setInputValue(roundToTwo(decider, valueToUpdate));
          } else {
            setInputValue(valueToUpdate);
          }
        }}
        onChange={(e) => handleChangeValue(e, keyToCheck, rowData, selectedRow, indexOfYear, apiData, 5, i)} />
    </>
  );
};

export default function SaveCoverage({wfDetails, sectorCountryMap, prevNonSuspendedmonth, getSourceType, setCoverageData, updateRowsvalue, setChangeCoverageTable, setCoveragesSaved, apiData, selectedRow, allYears, selectedYear}) {
  const [data, setData] = useState(apiData);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [isBulkErrorPopupVisible, setIsBulkErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [coverageVolume, setCoverageVolume] = useState('');
  const [weightedPrice, setWeightedPrice] = useState('');
  const [disableSavebtn, setDisableSaveBtn] = useState(false);

  const [allBulkUploadErrors, setAllBulkUploadErrors] = useState([]);
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const userHasPrevMonthAccess = isCreator?.functionalityAccess?.hasOwnProperty('Coverage:Previous_Month');

  useEffect(() => {
    if (selectedRow.workFlowId) {
      getCall(monthArr);
    };
  }, [selectedRow]);

  function updateTotalValues() {
    let totVolume = 0;
    // let totPrice = 0;
    let weightedPrice = 0;
    const currYear = new Date().getFullYear();
    const diff = Number(selectedYear) < currYear ? 0 : Number(selectedYear) - currYear;
    if (data.length > 0) {
      data[diff]?.map((item) => {
        totVolume += Number(commaTextToNumber(item.coverageVolume)) || 0;
        // totPrice += Number(item.coveredPrice) || 0;
        weightedPrice += Number(commaTextToNumber(item.coverageVolume) * commaTextToNumber(item.coveredPrice)) || 0;
      });
    }
    setWeightedPrice((weightedPrice/totVolume).toFixed(+wfDetails?.decimal_scale) === 'NaN' ? 0 : (+weightedPrice/+totVolume).toString().split('.').length>1? roundToTwo(+wfDetails?.decimal_scale, +weightedPrice/+totVolume).toLocaleString('en-US') : (+weightedPrice/+totVolume).toLocaleString('en-US'));

    setCoverageVolume(totVolume.toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, totVolume).toLocaleString('en-US') : (totVolume===0 ? 0 : Number(totVolume)) );
  }

  useEffect(()=>{
    updateTotalValues();
  }, [data]);

  const getCall = (month) => {
    const temp = [[]];
    DialogService.getHistoricalSectorCoverageData(selectedRow.prevWorkFlowId).then((res) =>{
      res.sort((a, b) => new Date(a['coverageYearMonth']) - new Date(b['coverageYearMonth']));
      res.map(( element) => {
        element.executionMonth=`${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`;
        element.coverageUnit = selectedRow.measuringUnit;
        element.coveredPriceCurrency = selectedRow.currency;
        element['disableCell'] = userHasPrevMonthAccess && false;
        element.savedRow = true;
      });
      // need to filter all 3 years
      for (let index=0; index < allYears.length; index++) {
        temp[index]=res?.filter((entry) => entry);
      }
      if (temp[0].length === 0) {
        const addDefaultEmptyRow = {
          'id': 'f0',
          'executionMonth': `${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`,
          'coverageUnit': selectedRow.measuringUnit,
          'coveredPriceCurrency': selectedRow.currency,
          'coverageVolume': '',
          'coverageYearMonth': '',
          'coveredPrice': '',
          'sourceType': getSourceType(selectedRow),
          'workflowId': selectedRow.workflowId,
          'savedRow': false,
        };
        setData([[addDefaultEmptyRow, ...temp[0]], temp[1], temp[2]]);
      } else {
        setData(temp);
      }
    });
  };


  const columns = [
    {...keyColumn('executionMonth', textColumn), component: () => <SaveCoverageInput prevNonSuspendedmonth={prevNonSuspendedmonth} selectedRow={selectedRow} typeOfColumn={'executionMonth'} />, title: 'Plan Month', disabled: 'true'},
    {...keyColumn('coverageYearMonth', textColumn), component: ({rowData, rowIndex}) => <DropdownSelectMonth i={rowIndex} selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Coverage Month', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('sourceType', textColumn), component: ({rowData, rowIndex}) => <DropdownSelect selectedRow={selectedRow} i={rowIndex} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Source Type', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('sector_id', textColumn), title: 'Sector', component: ({rowData, rowIndex}) => <DropdownSelectSector i={rowIndex} sectorCountryMap={sectorCountryMap} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('region_id', textColumn), title: 'Country', component: ({rowData, rowIndex}) => <DropdownSelectCountry i={rowIndex} sectorCountryMap={sectorCountryMap} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('coverageVolume', textColumn), component: ({rowData, rowIndex, focus}) => <InputComponent wfDetails={wfDetails} i={rowIndex} focus={focus} keyToCheck={'coverageVolume'} updateTotalValues={updateTotalValues} selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Coverage Volume', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('coverageUnit', textColumn), component: () => <SaveCoverageInput selectedRow={selectedRow} typeOfColumn={'coverageUnit'} />, title: 'Coverage Unit', disabled: 'true'},
    {...keyColumn('coveredPrice', textColumn), component: ({rowData, rowIndex, focus}) => <InputComponent wfDetails={wfDetails} i={rowIndex} focus={focus} keyToCheck={'coveredPrice'} updateTotalValues={updateTotalValues} selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Covered Price', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('coveredPriceCurrency', textColumn), component: () => <SaveCoverageInput selectedRow={selectedRow} typeOfColumn={'coveredPriceCurrency'} />, title: 'Covered Price Currency', disabled: 'true'},
  ];

  function handleAddRow() {
    const newRowObj = {
      'executionMonth': '',
      'coverageUnit': '',
      'coveredPriceCurrency': '',
      'sourceType': getSourceType(selectedRow),
      'savedRow': false,
    };
    const updateObj = [...data[0], newRowObj];
    addNewRowToGrid(updateObj, false);
  }

  function editCoverage(value, sIndex) {
    const temp = [[]];
    temp[sIndex] = value;
    for (let index=0; index < data.length; index++) {
      if (index == sIndex) continue;
      temp[index] = data[index];
    }
    setData(temp);
  }

  function updateErrorMsg(errMsg = '') {
    setIsErrorPopupVisible(true);
    setErrMsg(errMsg);
  }
  const checkRequiredDetails = (value, checkRowInd) => {
    let flagToAddNewRow = true;
    if (value.length >= 1) {
      const lastRow = value[value.length-checkRowInd];
      if (lastRow == undefined) return flagToAddNewRow;
      if (!((lastRow.hasOwnProperty('coverageYearMonth') && lastRow.coverageYearMonth !== '' && lastRow.coverageYearMonth !== null) &&
          (lastRow.hasOwnProperty('sourceType') && lastRow.sourceType !== '' && lastRow.sourceType !== null) &&
          (lastRow.hasOwnProperty('sector_id') && lastRow.sector_id !== '' && lastRow.sector_id !== null) &&
          (lastRow.hasOwnProperty('region_id') && lastRow.region_id !== '' && lastRow.region_id !== null) &&
          (lastRow.hasOwnProperty('coveredPrice') && lastRow.coveredPrice !== '' && lastRow.coveredPrice !== null) &&
          (lastRow.hasOwnProperty('coverageVolume') && lastRow.coverageVolume !== '' && lastRow.coverageVolume !== null)
      )) {
        updateErrorMsg('Please Enter The Required Details');
        flagToAddNewRow = false;
      }
    }
    return flagToAddNewRow;
  };

  function checkAllFields(data, msg) {
    let flagReturn = true;
    data.map((eachRow) => {
      if (!((eachRow.hasOwnProperty('coverageYearMonth') && eachRow.coverageYearMonth !== '' && eachRow.coverageYearMonth != null ) &&
          (eachRow.hasOwnProperty('sourceType') && eachRow.sourceType !== '' && eachRow.sourceType !== null) &&
          (eachRow.hasOwnProperty('sector_id') && eachRow.sector_id !== '' && eachRow.sector_id !== null) &&
          (eachRow.hasOwnProperty('region_id') && eachRow.region_id !== '' && eachRow.region_id !== null) &&
          (eachRow.hasOwnProperty('coveredPrice') && eachRow.coveredPrice !== '' && eachRow.coveredPrice !== null) &&
          (eachRow.hasOwnProperty('coverageVolume') && eachRow.coverageVolume !== '' && eachRow.coverageVolume !== null)
      )) {
        updateErrorMsg(msg);
        flagReturn = false;
      }
    });
    return flagReturn;
  }

  const postCall = () => {
    const covtemp = JSON.parse(JSON.stringify([...data[0]]));
    const coverages = [];
    const flagToAddNewRow = checkAllFields(covtemp, 'Please Enter The Required Details');
    if (flagToAddNewRow) {
      covtemp.map((item, index) => {
        const covYearMonth = typeof(item.coverageYearMonth) === 'string' ? item.coverageYearMonth.split('-') : item.coverageYearMonth;
        item.coverageYearMonth = [covYearMonth[1]*1, monthArr.indexOf(covYearMonth[0])+1];
        // setCoverageData([...apiData]);
        // item.coveredPrice=commaTextToNumber(item.coveredPrice);
        item.coveredPrice = wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, commaTextToNumber(item.coveredPrice)) : commaTextToNumber(item.coveredPrice);
        // item.coverageVolume=commaTextToNumber(item.coverageVolume);
        item.coverageVolume = wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, commaTextToNumber(item.coverageVolume)) : commaTextToNumber(item.coverageVolume);
        item.workflowId = selectedRow.prevWorkFlowId;
        item.sourceType = item.sourceType.toUpperCase();
        item.sectorId = item.sector_id;
        item.regionId = item.region_id;
        item.executionMonth=`${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`;
        item.total = item.coverageVolume;
        delete item.coverageVolume;
        item.avgPrice = item.coveredPrice;
        delete item.coveredPrice;
        coverages.push(item);
      });
      const payload = {'workflowId': + selectedRow.prevWorkFlowId, 'prevWorkflowId': +0, 'totalCoverages': coverages};
      setDisableSaveBtn(true);
      DialogService.saveHistoricalSectorCoverageData(payload).then((res) => {
        if (res == true) {
          setIsSavePopupVisible(true);
          updateRowsvalue(monthArr);
        }
      }).catch((e) => {
        setIsErrorPopupVisible(true);
        if (e.errorMessage.includes('Total Coverage mismatch')) {
          setErrMsg('There\'s a mismatch between Total of the Coverage Volume on Historical Coverage tab and Total of the Coverage Volume entered on this tab. Please Check and Re-enter.');
        } else {
          setErrMsg(e.userMessage);
        }
      }).finally(() => {
        setDisableSaveBtn(false);
      });
    }
  };

  function addNewRowToGrid(value, gridChange) {
    let flagToAddNewRow = false;
    if (!gridChange) {
      flagToAddNewRow = checkRequiredDetails(value, 2);
    }
    if (value.length == 0) {
      editCoverage(value, 0);
    }
    value[value.length-1].executionMonth=`${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`;
    value[value.length-1].coverageUnit=selectedRow.measuringUnit;
    value[value.length-1].coveredPriceCurrency=selectedRow.currency;
    apiData[0]=value;
    if (gridChange) {
      editCoverage(value, 0);
    } else if (flagToAddNewRow) {
      editCoverage(value, 0);
    } else {
      editCoverage(value.slice(0, value.length-1), 0);
    }
  }

  return (
    <><div style={{
      marginRight: '-16px'}}>
      <Box sx={{
        display: 'flex',
        width: '100%',
        padding: '15px 15px 0px 5px',
        flexDirection: 'row-reverse',
        marginTop: '-15px',
        marginBottom: '10px',
        gap: '10px',
      }}>
        <Button
          className='addNewRow'
          onClick={() => handleAddRow(false, false)}
        >
          <AddIcon sx={{fontSize: '18px', color: 'white'}} />Add New Row
        </Button>
      </Box>
    </div><div>
      {allYears.indexOf(selectedYear) == 0 ?
          <div className="save-coverage-table">
            {data[0] && <DataSheetGrid
              value={data[0]}
              onChange={(value) => {
                addNewRowToGrid(value, true);
              } }
              // allowAddNew={true}
              stickyRightColumn={{
                component: ({rowData, deleteRow}) => (
                  <div className='deleteRowFromGrid' style={{color: 'red'}} onClick={() => {
                    deleteRow();
                  }}>{!(rowData?.savedRow) && (<><ClearIcon /></>)}</div>
                ),
              }}
              columns={columns}
              rowClassName={() => 'coverage'}
              disableContextMenu={true}
              gutterColumn={false}
              className='savedDatasheetGridCoverageTable' />}
          </div> : (allYears.indexOf(selectedYear) == 1 ?
            <div className="save-coverage-table">
              {data[1] && <DataSheetGrid
                value={data[1]}
                onChange={(value) => {
                  if (value.length > 1) {
                    if (typeof (value[value.length - 2].id) === 'string') {
                      value[value.length - 1].id = (value[value.length - 2].id.slice(0, 1)) + (Number(value[value.length - 2].id.slice(1)) + 1);
                    } else if ((typeof (value[value.length - 2].id) !== 'string') && (value[value.length - 1].id === undefined)) {
                      value[value.length - 1].id = 'f' + (value[value.length - 2].id + 1);
                    }
                  } else {
                    value[value.length - 1].id = 'f' + 0;
                  }
                  value[value.length - 1].executionMonth = `${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`;
                  value[value.length - 1].coverageUnit = selectedRow.measuringUnit;
                  value[value.length - 1].coveredPriceCurrency = selectedRow.currency;
                  apiData[1] = value;
                  editCoverage(value, 1);
                } }
                allowAddNew={true}
                columns={columns}
                rowClassName={() => 'coverage'}
                disableContextMenu={true}
                gutterColumn={false}
                className='savedDatasheetGridCoverageTable' />}
            </div> :
            <div className="save-coverage-table">
              {data[2] && <DataSheetGrid
                value={data[2]}
                onChange={(value) => {
                  if (value.length > 1) {
                    if (typeof (value[value.length - 2].id) === 'string') {
                      value[value.length - 1].id = (value[value.length - 2].id.slice(0, 1)) + (Number(value[value.length - 2].id.slice(1)) + 1);
                    } else if ((typeof (value[value.length - 2].id) !== 'string') && (value[value.length - 1].id === undefined)) {
                      value[value.length - 1].id = 'f' + (value[value.length - 2].id + 1);
                    }
                  } else {
                    value[value.length - 1].id = 'f' + 0;
                  }
                  value[value.length - 1].executionMonth = `${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`;
                  value[value.length - 1].coverageUnit = selectedRow.measuringUnit;
                  value[value.length - 1].coveredPriceCurrency = selectedRow.currency;
                  apiData[2] = value;
                  editCoverage(value, 2);
                } }
                allowAddNew={true}
                columns={columns}
                rowClassName={() => 'coverage'}
                disableContextMenu={true}
                gutterColumn={false}
                className='savedDatasheetGridCoverageTable' />}
            </div>)}
      <Styles.StyledCoverageSummBottomButtonBox style={{display: 'flex'}}>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>Total <b>/</b> Average</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'right'}}>{coverageVolume !== null && coverageVolume}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{weightedPrice === 'NaN' ? 0 : weightedPrice}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
      </Styles.StyledCoverageSummBottomButtonBox>
      <div style={{display: 'flex', gap: '12px', justifyContent: 'flex-end', marginBottom: '25px', alignItems: 'center', marginTop: '20px'}}>
        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: '#4297d3', font: 'normal normal 400 13px/15px \'Segoe UI\''}}>
          <AppButton disabled={disableSavebtn} onClick={() => postCall()} label="Save Coverages" />
        </Box>
      </div>

      <AppDialog open={isSavePopupVisible} handleClose={() => {
        setCoveragesSaved(true);
        setIsSavePopupVisible(false);
        setChangeCoverageTable(true);
      } } text='Coverage details saved successfully' />
      <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
        setIsErrorPopupVisible(false);
        setErrMsg('');
      } } text={errMsg} />

      <BulkUploadError open={isBulkErrorPopupVisible} handleClose={() => {
        setIsBulkErrorPopupVisible(false);
        setAllBulkUploadErrors([]);
      } } text={allBulkUploadErrors} />
    </div></>
  );
}
