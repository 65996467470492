/* eslint-disable no-unused-vars */
import {Box, Dialog, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {AppButton} from '../../common/AppButton/AppButton';
import {styled} from '@mui/material/styles';
import NotificationSettingDialogSingleRow from './NotificationSettingDialogSingleRow';
import {tableCellClasses} from '@mui/material/TableCell';
import ConfigurationServices from '../../../services/ConfigurationServices';
import customealltheme from '../../../theme';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
    whiteSpace: 'noWrap',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));


const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8f8f8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const NotificationSettingDialog = ({open, setopen, accessControl, getAllRoles, scenarioId, selectedCat, setscenarioId}) => {
  const [data, setData] = useState([]);
  useEffect(()=>{
    if (scenarioId) {
      ConfigurationServices.getRecipientList(scenarioId).then((res)=>{
        setData([...res['to']]);
      }).catch((err)=>{
        console.log(err);
      });
    }
  }, [scenarioId]);
  return (
    <Dialog fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') setopen(false);
      }}
    >
      <DialogContent style={{position: 'relative', padding: '2rem', fontWeight: 500}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
            padding: '5px 18px',
          }}
          style={customealltheme.palette.filterheading}
        >
          {selectedCat}
        </Box>
        <Box sx={{width: '100%', padding: '0px 18px'}}>
          <Box sx={{marginTop: '20px'}}>
            <TableContainer component={Paper} className='stickytable'>
              <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead className='stickytablehead'>
                  <TableRow>
                    {/* <StyledTableCell align="left">Notification Recipient No</StyledTableCell> */}
                    <StyledTableCell align="left">Persona Receiving The Notification</StyledTableCell>
                    {/* <StyledTableCell align="left">CC Persona Receiving The Notification</StyledTableCell> */}
                    <StyledTableCell align="left">Notification Text</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row, key) => (
                    <NotificationSettingDialogSingleRow scenarioId={scenarioId} row={row} key={key} getAllRoles={getAllRoles}
                      accessControl={accessControl} setData={setData} setopen={setopen}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box sx={{float: 'right'}}>
          <div style={{padding: '1rem'}}>
            <AppButton label="Close" onClick={() => {
              setscenarioId(null);
              setopen(false);
            }} />
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationSettingDialog;

