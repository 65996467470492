// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-button {
    background: #4297D3;
    color: white;
    border: none;
    border-radius: 3px;
    min-width: 110px;
    height: 30px;
    font-size: 13px;
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/common/AppButton/appButton.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,cAAc;AAClB","sourcesContent":[".app-button {\n    background: #4297D3;\n    color: white;\n    border: none;\n    border-radius: 3px;\n    min-width: 110px;\n    height: 30px;\n    font-size: 13px;\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
