/* eslint-disable no-unused-vars */
import React, {memo, useState} from 'react';
import {MinusIconComponent} from '../common/AppPlusMinusButtons/MinusButton';
import {PlusIconComponent} from '../common/AppPlusMinusButtons/PlusButton';
import {colorReturn} from './EditBuyPlanService';
import {Box, FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, tableCellClasses} from '@mui/material';
import {DataSheetGrid, keyColumn, textColumn} from 'react-datasheet-grid';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {AppButton} from '../common/AppButton/AppButton';
import DialogService from '../../services/dialogService';
import {useRef} from 'react';
import {useLayoutEffect} from 'react';
import {monthArr} from '../../services/utils';
import {AppErrorDialog} from '../common/App-Dialog/AppDialog';
import RolestaggingPopUp from '../common/CommentsTagging/RolestaggingPopUp';
import {taggingColumn, getLastCharacterXPosition} from '../common/CommentsTagging/TaggingUtils';
import styled from 'styled-components';
// import {AppTableInput2} from '../common/App-Table-Input/AppTableInput';
// import {AppDate} from '../common/AppDate/AppDate';

// const quarterMonths = ['Feb', 'May', 'Aug', 'Dec'];

export const BoldingFyTotal = ({rowData, rowIndex, val}) => {
  // console.log(rowData[val]);
  return (
    <div style={{fontWeight: (rowIndex== 12 || rowIndex== 25 || rowIndex== 38 || rowIndex==39) && 600, color: (rowIndex== 12 || rowIndex== 25 || rowIndex== 38 || rowIndex==39) && 'black', marginTop: val=='period' && '10px'}}>{rowData[val]!==null && rowData[val]?.toLocaleString('en-US')}</div>
  );
};

export const ParentHeader = ({values, pixels}) => {
  const [infoValue, setInfoValue] = useState(false);

  return (
    <div className={`parent-header-container ${values.color}`}>
      <div className={`parent  ${values.parentTitle == 'Last month-Overall' ? 'last-month-overall' : ''}`} style={pixels ? {left: pixels, position: 'relative'} : {'display': 'flex'}}>
        {values.parentTitle}
      </div>
      <div className="flex-class" style={{cursor: 'pointer'}}>{values.lower}{values.info===''?null:<span className='plusIcon' onClick={() => setInfoValue(!infoValue)}>i</span>}</div>
      {infoValue && <span>{values.info}</span>}
    </div>
  );
};

export const MinusComponent = ({values}) => {
  return (
    <div className={`parent-header-container ${values.color}`}>
      <div className="parent">{values.parentTitle}</div>
      <div className="flex-class">
        {values.lower}
        <span onClick={values.click}>
          <MinusIconComponent />
        </span>
      </div>
    </div>
  );
};

export const PlusComponent = ({values}) => {
  return (
    <div className={`parent-header-container ${values.color}`}>
      <div className="parent">{values.parentTitle}</div>
      <div className="flex-class">
        {values.lower}
        <span onClick={values.click}>
          <PlusIconComponent />
        </span>
      </div>
    </div>
  );
};


export const PlusComponent2 = ({values, pixels}) => {
  const [infoValue, setInfoValue] = useState(false);
  return (
    <div>
      <div className={`parent-header-container ${values.color}`}>
        <div className="parent">{values.parentTitle}</div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className="flex-class">
            {values.lower}
            <span onClick={values.click}>
              <PlusIconComponent />
            </span>
          </div>
          <div>
            <div style={{cursor: 'pointer'}}>{values.info===''?null:<span className='plusIcon' onClick={() => setInfoValue(!infoValue)}>i</span>}</div>
            {/* {infoValue && <div style={{position: 'absolute', left: '-90px', top: '20px', background: 'rgba(9, 0, 114, 0.31)'}}>{values.info}</div>} */}
          </div>
        </div>


      </div>
      {infoValue && <div className={`parent-header-container ${values.color}`} style={{textAlign: 'center', padding: '2px'}}>{values.info}</div>}
    </div>
  );
};


export const MinusComponent2 = ({values, pixels}) => {
  const [infoValue, setInfoValue] = useState(false);
  return (
    <div>
      <div className={`parent-header-container ${values.color}`}>
        <div className="parent">{values.parentTitle}</div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className="flex-class">
            {values.lower}
            <span onClick={values.click}>
              <MinusIconComponent />
            </span>
          </div>
          <div>
            <div style={{cursor: 'pointer'}}>{values.info===''?null:<span className='plusIcon' onClick={() => setInfoValue(!infoValue)}>i</span>}</div>
            {/* {infoValue && <div style={{position: 'absolute', left: '-90px', top: '20px', background: 'rgba(9, 0, 114, 0.31)'}}>{values.info}</div>} */}
          </div>
        </div>


      </div>
      {infoValue && <div className={`parent-header-container ${values.color}`} style={{textAlign: 'center', padding: '2px'}}>{values.info}</div>}
    </div>
  );
};

export const CoverageComponent = ({val, v, rowIndex}) => {
  return (
    <>
      <div className="buy-plan-coverage">
        <div style={{fontWeight: (rowIndex== 12 || rowIndex== 25 || rowIndex== 38 || rowIndex==39) && 600, color: (rowIndex== 12 || rowIndex== 25 || rowIndex== 38 || rowIndex==39) && 'black', marginTop: val=='period' && '10px'}}>{val['coverage']!==null && val['coverage']?.toLocaleString('en-US')}</div>
        {rowIndex !== 12 && rowIndex!== 25 && rowIndex!== 38 && rowIndex!==39 ? (
          <div
            className={`circle ${
              colorReturn(val) != 'green' ?
                colorReturn(val) != 'red' ?
                  colorReturn(val) :
                  'dark-red' :
                'dark-green'
            }`}
          ></div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export const BackgroundColorHandler = ({rowData, focus, columnName, handleEdit, color, value, type, rowIndex}) => {
  const [val, setVal] = useState('');
  const data = useSelector((state) => state.buyPlanData.value);
  const year = useSelector((state) => state.buyPlanData.year);
  useEffect(()=>{
    if (year!=='') {
      yearArrays = [year, +year+1, +year+2];
    }
  }, [year]);

  const isEdit = useSelector((state) => state.editStatus.value);
  const handleChange = (event) => {
    setVal(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value, data, val);
  };
  const ref = useRef(null);
  useEffect(() => {
    if (rowData && columnName) setVal(rowData[columnName]==null?'':rowData[columnName]);
  }, [rowData]);
  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);
  if (type == 'comments') {
    return <>{rowIndex !== 12 && !isEdit && Math.abs(+rowData.tVariance) >=2.5 ? <div className={`buy-plan-text-div ${color} center`}><textarea ref={ref} onChange={(e)=> handleChange(e)} value={val} style={{height: 'fitContent'}} className={`buy-plan-text-area ${color}`}></textarea></div> : <div className={`buy-plan-text-div ${color} center`}>{Math.abs(+rowData.tVariance) >=2.5 &&
      <Tooltip style={{background: 'white'}} arrow title={val} disableInteractive>
        <div style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100px',
        }}>{val}

        </div>
      </Tooltip>
    }</div>}</>;
  }
  return <div className={`color-provider ${color}`} style={{color: (rowIndex === 12 || rowIndex === 25 || rowIndex === 38 || rowIndex==39) ? 'black' : '#76808f', fontWeight: (rowIndex === 12 || rowIndex === 25 || rowIndex === 38 || rowIndex==39) ? '600' : 'normal', background: rowIndex==39&&'none'}}>{val || value}</div>;
};

export const removeborderForSticky = ()=>{
  document.getElementsByClassName('clickableBox')[0]?.classList.remove('clickableBox');
};

export const getPrevMonth = (current) =>{
  if (current == 'Jan') {
    return monthArr[0];
  }
  return monthArr[monthArr.indexOf(current)-1];
};

export const getPrevYearRow = (current) =>{
  if (+current.split('-')[2] == 0 || +current.split('-')[2] == 13 || +current.split('-')[2] == 25) {
    return;
  }
  return +current.split('-')[2]-1;
};

export const getNextMonth = (current) =>{
  if (current == 'Dec') {
    return monthArr[11];
  }
  return monthArr[monthArr.indexOf(current)+1];
};

export const getNextYearRow = (current) =>{
  if (+current.split('-')[2] == 11 || +current.split('-')[2] == 24 || +current.split('-')[2] == 37) {
    return;
  }
  return +current.split('-')[2]+1;
};

let yearArrays = [];

document.addEventListener('keydown', async function(event) {
  // Check which arrow key was pressed
  if (document.getElementsByClassName('clickableBox')?.length>0) {
    const selected = document.getElementsByClassName('clickableBox')[0]?.classList[document.getElementsByClassName('clickableBox')[0]?.classList?.length-2];
    console.log(selected);
    // const firstYear = 2022;
    // // const yearArrays = [firstYear, +firstYear+1, +firstYear+2];
    // if (yearArrays.length==0) {
    //   yearArrays = [firstYear, +firstYear+1, +firstYear+2];
    // }
    if (selected.split('-')[0] == 'Total') {
      if (selected.split('-').includes('period')) {
        switch (event.key) {
          case 'ArrowRight':
            removeborderForSticky();
            break;
          case 'ArrowLeft':
            removeborderForSticky();
            const changedRow = selected.split('-');
            changedRow[2] = 'year';
            document.getElementsByClassName(changedRow.join('-'))[0].classList.add('clickableBox');
            break;
          case 'ArrowUp':
            const changedRow2 = selected.split('-');
            if (changedRow2[1] == 12) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[0]}-Dec-11-period`)[0].classList.add('clickableBox');
            } else if (changedRow2[1] == 25) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[1]}-Dec-24-period`)[0].classList.add('clickableBox');
            } else if (changedRow2[1] == 38) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[2]}-Dec-37-period`)[0].classList.add('clickableBox');
            }
            break;
          case 'ArrowDown':
            const changedRow3 = selected.split('-');
            if (changedRow3[1] == 12) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[1]}-Jan-13-period`)[0].classList.add('clickableBox');
            } else if (changedRow3[1] == 25) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[2]}-Jan-26-period`)[0].classList.add('clickableBox');
            }
            break;
        }
      } else if (selected.split('-').includes('year')) {
        switch (event.key) {
          case 'ArrowLeft':
            break;
          case 'ArrowRight':
            removeborderForSticky();
            const changedRow = selected.split('-');
            changedRow[2] = 'period';
            document.getElementsByClassName(changedRow.join('-'))[0].classList.add('clickableBox');
            break;
          case 'ArrowUp':
            const changedRow2 = selected.split('-');
            if (changedRow2[1] == 12) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[0]}-Dec-11-year`)[0].classList.add('clickableBox');
            } else if (changedRow2[1] == 25) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[1]}-Dec-24-year`)[0].classList.add('clickableBox');
            } else if (changedRow2[1] == 38) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[2]}-Dec-37-year`)[0].classList.add('clickableBox');
            }
            break;
          case 'ArrowDown':
            const changedRow3 = selected.split('-');
            if (changedRow3[1] == 12) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[1]}-Jan-13-year`)[0].classList.add('clickableBox');
            } else if (changedRow3[1] == 25) {
              removeborderForSticky();
              document.getElementsByClassName(`${yearArrays[2]}-Jan-26-year`)[0].classList.add('clickableBox');
            }
            break;
        }
      }
    } else {
      if (selected.split('-').includes('period')) {
        switch (event.key) {
          case 'ArrowRight':
            removeborderForSticky();
            break;
          case 'ArrowLeft':
            removeborderForSticky();
            document.getElementsByClassName(selected)[0].previousElementSibling.classList.add('clickableBox');
            break;
          case 'ArrowDown':
            if (+selected.split('-')[2]!==37 && +selected.split('-')[2]!==11 && +selected.split('-')[2]!==24) {
              const changedRow = selected.split('-');
              changedRow[1] = getNextMonth(selected.split('-')[1]);
              changedRow[2] = getNextYearRow(selected);
              removeborderForSticky();
              document.getElementsByClassName(changedRow.join('-'))[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==11) {
              removeborderForSticky();
              document.getElementsByClassName('Total-12-period')[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==24) {
              removeborderForSticky();
              document.getElementsByClassName('Total-25-period')[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==37) {
              removeborderForSticky();
              document.getElementsByClassName('Total-38-period')[0].classList.add('clickableBox');
            }
            break;
          case 'ArrowUp':
            if (+selected.split('-')[2]!==0 && +selected.split('-')[2]!==13 && +selected.split('-')[2]!==26) {
              const changedRow = selected.split('-');
              changedRow[1] = getPrevMonth(selected.split('-')[1]);
              changedRow[2] = getPrevYearRow(selected);
              removeborderForSticky();
              document.getElementsByClassName(changedRow.join('-'))[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==13) {
              removeborderForSticky();
              document.getElementsByClassName('Total-12-period')[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==26) {
              removeborderForSticky();
              document.getElementsByClassName('Total-25-period')[0].classList.add('clickableBox');
            }
            break;
        }
      } else if (selected.split('-').includes('year')) {
        switch (event.key) {
          case 'ArrowRight':
            removeborderForSticky();
            document.getElementsByClassName(selected)[0].nextElementSibling.classList.add('clickableBox');
            break;
          case 'ArrowDown':
            if (+selected.split('-')[2]!==37 && +selected.split('-')[2]!==11 && +selected.split('-')[2]!==24) {
              const changedRow = selected.split('-');
              changedRow[1] = getNextMonth(selected.split('-')[1]);
              changedRow[2] = getNextYearRow(selected);
              removeborderForSticky();
              document.getElementsByClassName(changedRow.join('-'))[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==11) {
              removeborderForSticky();
              document.getElementsByClassName('Total-12-year')[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==24) {
              removeborderForSticky();
              document.getElementsByClassName('Total-25-year')[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==37) {
              removeborderForSticky();
              document.getElementsByClassName('Total-38-year')[0].classList.add('clickableBox');
            }
            break;
          case 'ArrowUp':
            if (+selected.split('-')[2]!==0 && +selected.split('-')[2]!==13 && +selected.split('-')[2]!==26) {
              const changedRow = selected.split('-');
              changedRow[1] = getPrevMonth(selected.split('-')[1]);
              changedRow[2] = getPrevYearRow(selected);
              removeborderForSticky();
              document.getElementsByClassName(changedRow.join('-'))[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==13) {
              removeborderForSticky();
              document.getElementsByClassName('Total-12-year')[0].classList.add('clickableBox');
            } else if (+selected.split('-')[2] ==26) {
              removeborderForSticky();
              document.getElementsByClassName('Total-25-year')[0].classList.add('clickableBox');
            }
            break;
        }
      }
    }
  } else {
    switch (event.key) {
      case 'ArrowLeft':
        removeborderForSticky();
        break;
      case 'ArrowRight':
        removeborderForSticky();
        break;
      case 'ArrowUp':
        removeborderForSticky();
        break;
      case 'ArrowDown':
        removeborderForSticky();
        break;
    }
  }
});

document.addEventListener('click', function(event) {
  if (!event.target.classList.contains('clickableBox')) {
    document.getElementsByClassName('clickableBox')[0]?.classList.remove('clickableBox');
  }
});

export const handleBorder = (e, rowIndex, classname) =>{
  const allYears = document.getElementsByClassName('stickyYearBorder');
  for (let i=0; i< allYears.length; i++) {
    allYears[i].classList.remove('clickableBox');
  }
  e.target.classList.add('clickableBox');
};

export const BackgroundStickyColumns = ({rowData, classname, focus, columnName, handleEdit, color, value, type, rowIndex}) => {
  const [val, setVal] = useState('');
  const data = useSelector((state) => state.buyPlanData.value);
  const isEdit = useSelector((state) => state.editStatus.value);
  const handleChange = (event) => {
    setVal(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value, data, val);
  };
  const ref = useRef(null);
  useEffect(() => {
    if (rowData && columnName) setVal(rowData[columnName]);
  }, [rowData]);
  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);
  return <div onClick={(e)=>handleBorder(e, rowIndex, classname)} className={`color-provider stickyYearBorder ${color} ${classname}`} style={{color: (rowIndex === 12 || rowIndex === 25 || rowIndex === 38 || rowIndex==39) ? 'black' : '#76808f', fontWeight: (rowIndex === 12 || rowIndex === 25 || rowIndex === 38 || rowIndex==39) ? '600' : 'normal'}}>{val || value}</div>;
};
export const BackgroundColorHandlerForYear = ({rowData, sel, focus, columnName, handleEdit, color, value, type, rowIndex}) => {
  const [val, setVal] = useState('');
  const data = useSelector((state) => state.buyPlanData.value);
  const isEdit = useSelector((state) => state.editStatus.value);
  const handleChange = (event) => {
    setVal(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value, data, val);
  };
  const ref = useRef(null);
  useEffect(() => {
    if (rowData && columnName) setVal(rowData[columnName]);
  }, [rowData]);
  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);
  return <div className={`color-provider ${color} ${(rowIndex === 12 || rowIndex === 25 || rowIndex === 38 || rowIndex==39 || rowIndex==39 ) && sel=='Year' && 'periodMover'}`} style={{color: (rowIndex === 12 || rowIndex === 25 || rowIndex === 38 || rowIndex==39 || rowIndex==39) ? 'black' : '#76808f', fontWeight: (rowIndex === 12 || rowIndex === 25 || rowIndex === 38 || rowIndex==39 || rowIndex==39) ? '600' : 'normal', background: rowIndex==39&&'none'}}
    onClick={()=>{
      const scrollingDiv = document.getElementsByClassName('table-container')[0]?.children[0]?.children[1];

      // console.log(scrollingDiv);
      // // Function to scroll the div to the left
      scrollingDiv.scrollLeft = 0;
    }}
  >{(rowIndex === 12 || rowIndex === 25 || rowIndex === 38 ) ? 'FY Total' :
  rowIndex==39 ? 'Grand Total' :
  (val || value)}</div>;
};

export const BackgroundColorHandler2 = ({rowData, focus, columnName, handleEdit, color, value, type, rowIndex}) => {
  const [val, setVal] = useState('');
  const data = useSelector((state) => state.buyPlanData.value);
  const isEdit = useSelector((state) => state.editStatus.value);
  const handleChange = (event) => {
    setVal(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value, data, val);
  };
  const ref = useRef(null);
  // useEffect(()=>{
  //   console.log(val);
  //   // handleEdit(columnName, rowData.planYear, rowIndex, val, data, val);
  // }, [val]);
  useEffect(() => {
    if (rowData && columnName) setVal(rowData[columnName]==null?'':rowData[columnName]);
  }, [rowData]);
  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);
  if (type == 'comments') {
    return <>{rowIndex !== 12 && !isEdit ? <div className={`buy-plan-text-div ${color} center`}><textarea ref={ref} onChange={(e)=> handleChange(e)} value={val} style={{height: 'fitContent'}} className={`buy-plan-text-area ${color}`}></textarea></div> : <div className={`buy-plan-text-div ${color} center`}>{rowData.isPrevAndActualCovSame == true && <Tooltip style={{background: 'white'}} arrow title={val} disableInteractive>
      <div style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100px',
      }}>{val}

      </div>
    </Tooltip>}</div>}</>;
  }
  return (
    <>
      <div className={`color-provider ${color}`}>{val || value}</div>
    </>
  );
};
export const VarianceChange = ({varianceReasons = [], handleEdit, columnName, color, rowIndex, rowData, quarterMonths}) => {
  const [variance, setVariance] = React.useState('');
  const data = useSelector((state) => state.buyPlanData.value);
  const isEdit = useSelector((state) => state.editStatus.value);
  const quartersData = useSelector((state) => state.commonState.quaters);
  const handleChange = (event) => {
    setVariance(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value, data, variance);
  };
  useEffect(() => {
    if (rowData && columnName) setVariance(rowData[columnName]);
  }, [rowData]);
  return (
    <>{columnName == 'qvarianceReason' ?
    <>
      {(rowIndex !== 12 && Math.abs(+rowData.tVariance) >=2.5) && (quarterMonths || quartersData)?.includes(rowData.period) ? (
            <Box sx={{minWidth: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '39px'}} className={`${color}`}>
              {!isEdit ? <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} fullWidth>
                <Select
                  id="variance"
                  value={variance}
                  onChange={handleChange}
                  className={`buy-plan-select`}
                  disabled={color=='green'}
                  sx={{
                    'boxShadow': 'none',
                    '.MuiOutlinedInput-notchedOutline': {border: 0},
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    'borderRadius': 0,
                    'width': '80%',
                    'background': 'white',
                    'fontSize': '12px',
                  }}
                >
                  {varianceReasons?.map((val, key) => (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> : <>{variance || rowData[columnName]}</>}
            </Box>
          ) : (
            <Box sx={{minWidth: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '39px'}} className={`${color}`}></Box>
          )}
    </> :
      <>
        {rowIndex !== 12 ? (
            <Box sx={{minWidth: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '39px'}} className={`${color}`}>
              {!isEdit ? <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} fullWidth>
                <Select
                  id="variance"
                  value={variance}
                  onChange={handleChange}
                  className={`buy-plan-select`}
                  disabled={color=='green'}
                  sx={{
                    'boxShadow': 'none',
                    '.MuiOutlinedInput-notchedOutline': {border: 0},
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    'borderRadius': 0,
                    'width': '80%',
                    'background': 'white',
                    'fontSize': '12px',
                  }}
                >
                  {varianceReasons?.map((val, key) => (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> : <>{variance || rowData[columnName]}</>}
            </Box>
          ) : (
            <></>
          )}
      </>
    }
    </>
  );
};

export const VarianceChange2 = ({varianceReasons = [], handleEdit, columnName, color, rowIndex, rowData, quarterMonths}) => {
  const [variance, setVariance] = React.useState('');
  const data = useSelector((state) => state.buyPlanData.value);
  const isEdit = useSelector((state) => state.editStatus.value);
  const quartersData = useSelector((state) => state.commonState.quaters);
  const handleChange = (event) => {
    setVariance(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value, data, variance);
  };
  useEffect(() => {
    if (rowData && columnName) setVariance(rowData[columnName]);
  }, [rowData]);
  return (
    <>
      {(rowData.isPrevAndActualCovSame == true || typeof rowData.isPrevAndActualCovSame=='number' ) ? <>{columnName == 'qvarianceReason' ?
    <>
      {(rowIndex !== 12 || rowIndex !== 25 || rowIndex !== 38) && (quarterMonths || quartersData)?.includes(rowData.period) ? (
            <Box sx={{minWidth: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '39px'}} className={`${color}`}>
              {!isEdit ? <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} fullWidth>
                <Select
                  id="variance"
                  value={variance}
                  onChange={handleChange}
                  className={`buy-plan-select`}
                  disabled={color=='green'}
                  sx={{
                    'boxShadow': 'none',
                    '.MuiOutlinedInput-notchedOutline': {border: 0},
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    'borderRadius': 0,
                    'width': '80%',
                    'background': 'white',
                    'fontSize': '12px',
                  }}
                >
                  {varianceReasons?.map((val, key) => (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> : <>{variance || rowData[columnName]}</>}
            </Box>
          ) : (
            <Box sx={{minWidth: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '39px'}} className={`${color}`}></Box>
          )}
    </> :
      <>
        {(rowIndex !== 12 || rowIndex !== 25 || rowIndex !== 38) ? (
            <Box sx={{minWidth: 140, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '39px'}} className={`${color}`}>
              {!isEdit ? <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} fullWidth>
                <Select
                  id="variance"
                  value={variance}
                  onChange={handleChange}
                  className={`buy-plan-select`}
                  disabled={color=='green'}
                  sx={{
                    'boxShadow': 'none',
                    '.MuiOutlinedInput-notchedOutline': {border: 0},
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                    'borderRadius': 0,
                    'width': '80%',
                    'background': 'white',
                    'fontSize': '12px',
                  }}
                >
                  {varianceReasons?.map((val, key) => (
                    <MenuItem key={key} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> : <>{variance || rowData[columnName]}</>}
            </Box>
          ) : (
            <></>
          )}
      </>
      }
      </> : ''}
    </>
  );
};

export const AppTextArea = ({handleEdit, columnName, rowIndex, rowData}) => {
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value);
  };
  return <textarea onChange={(e)=> handleChange(e)} value={value} className={`buy-plan-text-area`}></textarea>;
};

const getDate = () => {
  const d = new Date();
  const dd = d.getDate();
  const mm = d.getMonth();
  const fullYear = d.getFullYear();
  return `${dd}-${monthArr[mm]}-${fullYear}`;
};

export const ApproverComments = ({handleEdit, text, accessControlComment, userid, date, edit, approverCommentsData, authState, commentsTrigger, setCommentsTrigger}) => {
  // console.log(accessControlComment);
  // disabled: accessControlComment!==edit,
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const emptyRow = {
    author: '',
    date: '',
    comment: '',
  };
  const columns = [
    {
      ...keyColumn('author', textColumn),
      title: 'Author',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('date', textColumn),
      title: 'Date',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('comment', textColumn),
      title: 'Comment',
      disabled: true,
    },
  ];

  const [rows, setRows] = useState([]);
  const [name, setName] = useState('');
  const [changedObj, setChangedObj] = useState(null);
  useEffect(() => {
    const n = isCreator?.userName;
    setName(n);
  }, []);

  useEffect(() => {
    // console.log(approverCommentsData);
    let data = [];
    if (approverCommentsData?.length) {
      data = approverCommentsData.map((val) => {
        return {
          author: val.created_by,
          date: val.updated_on,
          comment: val.comments,
          id: val.id,
          created_by: val.created_by_id,
        };
      });
    }
    setRows([...data]);
  }, [approverCommentsData]);

  const sendData = () =>{
    if (changedObj !== null) {
      const row = rows[changedObj];
      // console.log(row);
      let payload = null;
      if (changedObj == (rows.length-1)) {
        payload = {
          'comment': row.comment,
          'workflow_id': wfId,
          'is_approver': 1,
        };
        DialogService.saveComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: (name || isCreator?.userName),
                  date: date || getDate(),
                  comment: '',
                }]);
              }
              setChangedObj(null);
            });
      } else {
        payload = {
          'comment': row.comment,
          'id': row.id,
          'is_approver': 1,
        };
        DialogService.editComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: (name || isCreator?.userName),
                  date: date,
                  comment: '',
                }]);
              }
              setChangedObj(null);
            });
      }
    }
  };

  return (
    <>{rows.length ? <div className='comments-table'>
      <div className="approver-comments-header">{text || 'Approver Comments'}</div>
      <DataSheetGrid
        value={rows}
        onChange={(rowData, rowIndex) => {
          setChangedObj(rowIndex[0].fromRowIndex);
          setRows(rowData);
        }}
        columns={columns}
        rowClassName={() => 'forecast buy-plan'}
        disableContextMenu={true}
        gutterColumn={false}
      />
    </div> : ''}</>
  );
};

export const Comments = ({userid, text, wfId, date, commentsData, commentsTrigger, setCommentsTrigger, saveComment, editComment, DisableComments}) => {
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const [flag, setFlag] = useState(true);
  const [open, setOpen] = useState(false);
  const [errMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);
  const [name, setName] = useState('');
  const [changedObj, setChangedObj] = useState(null);
  const [columns, setColumns] = useState([
    {
      ...keyColumn('author', textColumn),
      title: 'Author',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('date', textColumn),
      title: 'Date',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('comment', textColumn),
      title: 'Comment',
      disabled: ({rowData, rowIndex}) => {
        if (commentsData.length > 1 && ((rowIndex < commentsData.length-2) || (rowIndex < rows.length-2))) return true;
        else {
          if (rowData.created_by) {
            return (rowData.created_by == userid) ? false : true;
          }
          return false;
        }
      },
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        ...keyColumn('author', textColumn),
        title: 'Author',
        disabled: true,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        ...keyColumn('date', textColumn),
        title: 'Date',
        disabled: true,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        ...keyColumn('comment', textColumn),
        title: 'Comment',
        disabled: ({rowData, rowIndex}) => {
          if ((commentsData.length > 1 && ((rowIndex < commentsData.length-2) || (rowIndex < rows.length-2))) || DisableComments) return true;
          else {
            if (rowData.created_by) {
              return (rowData.created_by == userid) ? false : true;
            }
            return false;
          }
        },
      },
    ]);
    setFlag(!flag);
  }, [isCreator, rows.length, commentsData]);

  const emptyRow = {
    author: '',
    date: '',
    comment: '',
  };

  useEffect(() => {
    const n = isCreator?.userName;
    setName(n);
  }, [isCreator]);

  useEffect(() => {
    // console.log(commentsData);
    let data = [];
    const n = isCreator?.userName;
    if (commentsData?.length) {
      data = commentsData.map((val) => {
        return {
          author: val.created_by,
          date: val.updated_on,
          comment: val.comments,
          id: val.id,
          created_by: val.created_by_id,
        };
      });
    }
    if (DisableComments) {
      setRows([...data]);
    } else {
      setRows([...data, {...emptyRow, date: date, author: n}]);
    }
  }, [commentsData]);
  const sendData = async () =>{
    if (changedObj !== null) {
      const row = rows[changedObj];
      // console.log(row);
      let payload = null;
      if (changedObj == (rows.length-1)) {
        payload = {
          'comment': row.comment,
          'workflow_id': wfId,
          'is_approver': 0,
        };
        await saveComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: name,
                  date: date,
                  comment: '',
                }]);
              }
              setChangedObj(null);
            }).catch((err)=>{
              setErrorMessage(err?.userMessage);
              setOpen(true);
            });
      } else {
        payload = {
          'comment': row.comment,
          'id': row.id,
          'is_approver': 0,
          'workflow_id': wfId,
        };
        await editComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: name,
                  date: date,
                  comment: '',
                }]);
              }
              setChangedObj(null);
            }).catch((err)=>{
              setErrorMessage(err?.userMessage);
              setOpen(true);
            });
      }
    }
    setCommentsTrigger(!commentsTrigger);
  };

  return (
    <div className='comments-table'>
      <div className="approver-comments-header">{text || 'Comments'}</div>
      {flag && <DataSheetGrid
        value={rows}
        onChange={(rowData, rowIndex) => {
          setChangedObj(rowIndex[0].fromRowIndex);
          setRows(rowData);
        }}
        columns={columns}
        rowClassName={() => 'forecast buy-plan'}
        disableContextMenu={true}
        gutterColumn={false}
      />}

      {!flag && <DataSheetGrid
        value={rows}
        onChange={(rowData, rowIndex) => {
          setChangedObj(rowIndex[0].fromRowIndex);
          setRows(rowData);
        }}
        columns={columns}
        rowClassName={() => 'forecast buy-plan'}
        disableContextMenu={true}
        gutterColumn={false}
      />}
      {<Box sx={{
        width: '110px',
        height: '30px',
        borderRadius: '5px',
        background: '#4297D3',
        color: 'white',
        fontSize: '12px',
        textAlign: 'center',
        padding: '5px 5px 5px 5px',
        float: 'right',
        marginTop: '10px',
        display: DisableComments ? 'none' : 'block',
        cursor: 'pointer',
      }} onClick={sendData}>Save Comment</Box>}

      <AppErrorDialog open={open} handleClose={() => setOpen(false)} text={errMessage}/>

    </div>
  );
};


const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));


const StyledTableRow = styled(TableRow)(({theme}) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const CommentsTable = ({isApprover, clName, text, wfId, date, commentsData, commentsTrigger, setCommentsTrigger, saveComment, editComment, DisableComments}) => {
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const [flag, setFlag] = useState(true);
  const [open, setOpen] = useState(false);
  const [errMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);
  const [name, setName] = useState('');
  const [changedObj, setChangedObj] = useState(null);
  const [searchingString, setsearchingString] = useState('');
  const [addedtags, setAddedTags] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const emptyRow = {
    author: '',
    date: '',
    comment: '',
  };
  useEffect(()=>{
    if (changedObj == (rows.length-2)) {
      setNewMessage(rows[rows.length-2].comment);
    }
  }, [changedObj, rows]);

  useEffect(() => {
    const n = isCreator?.userName;
    setName(n);
  }, [isCreator]);

  useEffect(() => {
    // console.log(commentsData);
    let data = [];
    const n = isCreator?.userName;
    if (commentsData?.length) {
      data = commentsData.map((val) => {
        return {
          author: val.created_by,
          date: val.updated_on,
          comment: val.comments,
          id: val.id,
          created_by: val.created_by_id,
        };
      });
    }
    if (DisableComments) {
      setRows([...data]);
    } else {
      setRows([...data, {...emptyRow, date: date, author: n}]);
    }
  }, [commentsData]);
  const sendData = async (e) =>{
    e.target.disabled = true;
    e.target.style.cursor = 'not-allowed';

    if (changedObj !== null) {
      let row = [];
      // console.log(row);
      let payload = null;
      let payload2 = null;

      if (rows[rows.length-1].comment!=='' && changedObj == (rows.length-1)) {
        row = rows[rows.length-1];
        payload = {
          'comment': newMessage,
          'workflow_id': wfId,
          'is_approver': isApprover,
          'taggedUsers': addedtags[rows.length-1],
        };
        console.log(newMessage, row.comment);
        await saveComment(payload)
            .then((res) => {
              // console.log(res);
              // if (changedObj == (rows.length-1)) {
              //   setRows([...rows, {
              //     author: name,
              //     date: date,
              //     comment: '',
              //   }]);
              // }
              // setChangedObj(null);

              e.target.disabled = false;
              e.target.style.cursor = 'pointer';
            }).catch((err)=>{
              e.target.disabled = false;
              e.target.style.cursor = 'pointer';
              setErrorMessage(err?.userMessage);
              setOpen(true);
            });
      }
      if (rows[rows.length-2]?.comment!=='' && changedObj == (rows.length-2)) {
        row = rows[rows.length-2];
        payload2 = {
          'comment': newMessage,
          'workflow_id': wfId,
          'id': row.id,
          'is_approver': isApprover,
          'taggedUsers': addedtags[rows.length-2],
        };
        // console.log(newMessage, row.comment);
        await editComment(payload2)
            .then((res) => {
              // console.log(res);
              // if (changedObj == (rows.length-1)) {
              //   setRows([...rows, {
              //     author: name,
              //     date: date,
              //     comment: '',
              //   }]);
              // }
              // setChangedObj(null);
            }).catch((err)=>{
              setErrorMessage(err?.userMessage);
              setOpen(true);
            });
      }
    }
    if (changedObj == (rows.length-1) && rows[rows.length-1].comment!=='') {
      setRows([...rows, {
        author: name,
        date: date,
        comment: '',
      }]);
    }
    e.target.disabled = false;
    e.target.style.cursor = 'pointer';
    setNewMessage('');
    setChangedObj(null);
    setAddedTags({});
    setsearchingString('');
    setCommentsTrigger(!commentsTrigger);
  };


  return (
    <div className='comments-table' style={{marginTop: '10px'}}>
      <div className="approver-comments-header" style={{position: 'relative'}}>{text || 'Comments'}</div>
      <TableContainer component={Paper} className='commentsTagTable' style={{boxShadow: 'none'}}>
        <Table sx={{minWidth: 700}} aria-label="customized table">
          <TableHead className='stickytablehead'>
            <TableRow>
              <StyledTableCell align="left" style={{width: '250px', fontSize: '12px'}}><div style={{marginLeft: '70px'}}>Author</div></StyledTableCell>
              <StyledTableCell align="left" style={{width: '250px', fontSize: '12px'}}><div style={{marginLeft: '30px'}}>Date</div></StyledTableCell>
              <StyledTableCell align="left" style={{fontSize: '12px'}}><div style={{textAlign: 'center'}}>Comment</div></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i)=> (
              <CommentsSingleRow setNewMessage={setNewMessage} DisableComments={DisableComments} clName={clName} key={i} r={r} setChangedObj={setChangedObj} rows={rows} i={i} setRows={setRows} setAddedTags={setAddedTags} addedtags={addedtags} changedObj={changedObj} setsearchingString={setsearchingString} searchingString={searchingString}/>
            ))}

          </TableBody>
        </Table>
      </TableContainer>

      {<Box sx={{
        width: '110px',
        height: '30px',
        borderRadius: '5px',
        background: '#4297D3',
        color: 'white',
        fontSize: '12px',
        textAlign: 'center',
        padding: '5px 5px 5px 5px',
        float: 'right',
        marginTop: '10px',
        display: DisableComments ? 'none' : 'block',
        cursor: 'pointer',
      }} onClick={sendData}>Save Comment</Box>}

      <AppErrorDialog open={open} handleClose={() => setOpen(false)} text={errMessage}/>

    </div>
  );
};

export const CommentsSingleRow = ({i, setNewMessage, DisableComments, clName, rows, r, setChangedObj, setRows, setAddedTags, addedtags, changedObj, searchingString, setsearchingString}) => {
  const [content, setContent] = useState('');
  const [enablingRolePopup, setEnablingRolePopup] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const allRolesToTag = useSelector((state) => state.api.commentTaggingRoles);
  const [NotdisablingThings, setNotDisablingThings] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);

  const inputRef = useRef(null);

  const saveCursorPosition = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preSelectionRange = range.cloneRange();
      preSelectionRange.selectNodeContents(inputRef.current);
      preSelectionRange.setEnd(range.startContainer, range.startOffset);
      const startOffset = preSelectionRange.toString().length;
      return startOffset;
    }
    return null;
  };

  const restoreCursorPosition = (position) => {
    const selection = window.getSelection();
    const range = document.createRange();
    const parentNode = inputRef.current;

    if (parentNode.childNodes.length > 0) {
      let currentNode = null;
      let currentPosition = 0;

      // Traverse child nodes to find the node containing the desired position
      for (let i = 0; i < parentNode.childNodes.length; i++) {
        const childNode = parentNode.childNodes[i];

        if (childNode.nodeType === Node.TEXT_NODE) {
          const nodeLength = childNode.textContent.length;
          const endPosition = currentPosition + nodeLength;

          if (endPosition >= position) {
            currentNode = childNode;
            const offset = position - currentPosition;
            range.setStart(currentNode, offset);
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            return; // Exit the loop once the position is found and set
          }

          currentPosition = endPosition;
        } else if (childNode.nodeType === Node.ELEMENT_NODE && childNode.nodeName === 'SPAN') {
          const spanTextLength = childNode.textContent.length;
          const endPosition = currentPosition + spanTextLength;

          if (endPosition >= position) {
            const offset = position - currentPosition;
            range.setStart(childNode.firstChild || childNode, offset); // Set range within the span
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            return; // Exit the loop once the position is found and set
          }

          currentPosition = endPosition;
        }
      }

      // if (!currentNode) {
      // console.error('Invalid position:', position);
      // }
    } else {
      console.error('No text content found in inputRef.current');
    }
  };


  useEffect(()=>{
    setContent(r.comment);
  }, [r]);

  useEffect(()=>{
    setNotDisablingThings(((DisableComments==false) && (i === rows.length - 1 || (i === rows.length - 2 && rows[rows.length - 1].author === rows[rows.length - 2].author))));
    applyColorStyles();
  }, [DisableComments, i, rows]);

  // useEffect(()=>{
  //   console.log(addedtags);
  // }, [addedtags]);

  useEffect(()=>{
    if (i!==changedObj && changedObj!==null) {
      setEnablingRolePopup(false);
    }
  }, [i, changedObj]);

  const handleClosePopup = (event) => {
    if (!event.target.closest(`#rolePop${clName}${i}`)) {
      setEnablingRolePopup(false);
    }
    // console.log(event.target.classList);
    if (!event.target.classList?.contains('tagNames')) {
      for (let i=0; i<document.getElementsByClassName('taggerDetails').length; i++) {
        document.getElementsByClassName('taggerDetails')[i].style.display='none';
      }
      if (NotdisablingThings) {
        document.getElementById(`row${clName}${i}`).setAttribute('contentEditable', 'true');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClosePopup);
    return () => {
      document.removeEventListener('click', handleClosePopup);
    };
  }, []);


  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.innerText = content;
      applyColorStyles();
      // setCursorToEnd();
      addToUndoStack(content);
      if (cursorPosition !== null) {
        restoreCursorPosition(cursorPosition);
      }
    }
  }, [content]);

  const handleChange = () => {
    setChangedObj(i);
    if (inputRef.current) {
      setContent(inputRef.current.innerText);
      enablingTaggingPopup(inputRef.current.innerText);
      applyColorStyles();
      // setCursorToEnd();
    }
  };

  const setCursorToEnd = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(inputRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };


  const applyColorStyles = () => {
    if (inputRef.current) {
      const content = inputRef.current.innerText;
      const words = content.split(' ');
      inputRef.current.innerHTML = words
          .map((word, c) => {
            if (word.trim().startsWith('@')) {
              const tagMail = allRolesToTag?.filter((f)=> f.user_name.trim().toLowerCase()==(word.trim().slice(1).split('_').join(' ').trim().toLowerCase()||word.trim().slice(1)))[0]?.email;

              if (NotdisablingThings) {
                return `<span class='tagNames' style="color: #215094; font-weight: 600; font-size: 14px; cursor: pointer;" title=${tagMail}>${word}</span>`;
                // return word;
              } else {
                return `<span class='tagNames' contentEditable='false' style="color: #215094; font-weight: 600; font-size: 14px; cursor: pointer;" onclick="
                for(let i=0; i<document.getElementsByClassName('taggerDetails').length; i++){
                  document.getElementsByClassName('taggerDetails')[i].style.display='none'
                }
                document.getElementById('taggerDetails${i}${c}${clName}').style.display = 'flex';
                document.getElementById('row${clName}${i}').setAttribute('contentEditable', 'false')
                "><span style="position: relative;" class='tagNames' >${word}
                <div style="display: none" class='taggerDetails' id='taggerDetails${i}${c}${clName}'>
                <div class="tagImage">${word.slice(1).split('_').length>1?word.trim().slice(1).split('_')[0][0]+word.trim().slice(1).split('_')[1][0]:word.trim().slice(1).split('_')[0][0]}</div>
                <div>
                <div>${tagMail}</div>
                </div>
                </div>
                </span>
                </span>
                `;
              }
            }
            return `<span>${word}</span>`;
          })
          .join(' ');
    }
  };


  const changingCommentText = (name) => {
    const d = rows.map((m, index)=> index!==changedObj ? m : {...m, comment: m.comment.slice(0, cursorPosition-1) + ` @${name} ` + m.comment.slice(cursorPosition-1)});
    setCursorPosition(d[changedObj].comment.length);
    restoreCursorPosition(d[changedObj].comment.length);
    setNewMessage(d[changedObj].comment);
    setRows([...d]);
  };

  const enablingTaggingPopup= (r)=>{
    const currentPosition = saveCursorPosition();
    console.log(currentPosition);
    setCursorPosition(currentPosition);
    // debugger;
    // console.log(r, rows[i]['comment']);
    // if (r!== rows[i]['comment'] && r?.split(' ')[r?.split(' ').length-1].trim()[0]!=='@') {
    //   rows[i]['comment'] = r;
    // }
    let a = '';
    for (let i=currentPosition-1; i>=0; i--) {
      if (r[i]!=undefined && r[i]!==' ') {
        a+=r[i];
      } else {
        break;
      }
    }
    console.log(a.split('').reverse().join(''));
    // console.log(r?.split(' ')[r?.split(' ').length-1].length==r?.split(' ')[r?.split(' ').length-1].trim().length);
    // console.log(r?.split(' ')[r?.split(' ').length-1].trim()[0]=='@' && r?.split(' ')[r?.split(' ').length-1].at(-1)!==' ');
    if (a.split('').reverse().join('').startsWith('@')) {
      setEnablingRolePopup(true);
      setsearchingString(a.split('').reverse().join('').slice(1));
      setNewMessage(inputRef.current.innerText);
    } else {
      setEnablingRolePopup(false);
      setsearchingString('');
      // const d = rows.map((m, index)=> index!==changedObj ? m : {...m, comment: content});
      rows[i]['comment'] = r;
      setNewMessage(inputRef.current.innerText);
      // console.log(rows);
      // setRows([...rows]);
      // setRows([...d]);
      // console.log(rows);
    }
    // console.log(r);
    // console.log(r.split(' ').map((m)=>m.trim()));
    // let countOfAts = 0;
    // for (const i of r.split(' ').map((m)=>m.trim())) {
    //   if (!i.startsWith('@')) {
    //     countOfAts+=1;
    //   }
    // }
    // if (countOfAts==0) {
    //   rows[i]['comment'] = r;
    // }
    if (addedtags[changedObj]) {
      const removalTags = addedtags[changedObj]?.filter((m)=>r?.split(' ').map((f)=>f.slice(1).trim()).includes(m.user_name.split(' ').length>1 ? m.user_name.split(' ').join('_') : m.user_name));
      setAddedTags((prev)=>({...prev, [changedObj]: removalTags}));
    }
    const div = document.getElementById(`row${clName}${i}`);
    const lastCharXPosition = getLastCharacterXPosition(div);
    // console.log(lastCharXPosition);
    if (lastCharXPosition) {
      if (lastCharXPosition!==0 && lastCharXPosition<970) {
        document.getElementById(`rolePop${clName}${i}`).style.position = `absolute`;
        document.getElementById(`rolePop${clName}${i}`).style.marginLeft = `${lastCharXPosition-70}px`;
      } else if (lastCharXPosition!==0 && lastCharXPosition>=970) {
        document.getElementById(`rolePop${clName}${i}`).style.position = `absolute`;
        document.getElementById(`rolePop${clName}${i}`).style.marginLeft = `${lastCharXPosition-500}px`;
      } else {
        document.getElementById(`rolePop${clName}${i}`).style.position = `absolute`;
        document.getElementById(`rolePop${clName}${i}`).style.marginLeft = `400px`;
      }
    }
  };


  const addToUndoStack = (content) => {
    setUndoStack([...undoStack, content]);
  };

  const undo = () => {
    if (undoStack.length > 1) {
      const lastChange = undoStack.pop();
      setRedoStack([...redoStack, lastChange]);
      setContent(undoStack[undoStack.length - 1]);
      inputRef.current.innerText = undoStack[undoStack.length - 1];
      applyColorStyles();
      // setCursorToEnd();
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const lastChange = redoStack.pop();
      setUndoStack([...undoStack, lastChange]);
      setContent(lastChange);
      inputRef.current.innerText = lastChange;
      applyColorStyles();
      // setCursorToEnd();
    }
  };

  // Listen for Ctrl + Z to undo and Ctrl + Y to redo
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z' && i==changedObj) {
        undo();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'y' && i==changedObj) {
        redo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [undo, redo]);

  return (
    <><StyledTableRow style={{background: NotdisablingThings ? '' : '#fafafa'}}>
      <StyledTableCell component="th" scope="row" style={{background: '#fafafa', fontSize: '11px',
        fontWeight: '400',
        fontFamily: 'Myriad Pro'}}>
        <div style={{marginLeft: '70px'}}> {r.author}</div>
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" style={{background: '#fafafa', fontSize: '11px',
        fontWeight: '400',
        fontFamily: 'Myriad Pro'}}>
        <div style={{marginLeft: '10px'}}> {r.date} </div>
      </StyledTableCell>
      <StyledTableCell style={{background: NotdisablingThings? '#ffffff' :'#fafafa', fontSize: '11px',
        fontWeight: '400',
        fontFamily: 'Myriad Pro'}} onClick={(e)=>{
        setChangedObj(i);
        for (let i=0; i< document.getElementsByClassName('IndiCommentColor').length; i++) {
          document.getElementsByClassName('IndiCommentColor')[i].classList.remove('IndiCommentColor');
        }
        for (let i=0; i< document.getElementsByClassName('IndiCommentColorDisabled').length; i++) {
          document.getElementsByClassName('IndiCommentColorDisabled')[i].classList.remove('IndiCommentColorDisabled');
        }
        if (NotdisablingThings) {
          document.getElementById(`row${clName}${i}`).classList.add('IndiCommentColor');
        } else {
          document.getElementById(`row${clName}${i}`).classList.add('IndiCommentColorDisabled');
        }
        setIsClicked(true);
      }} className={`textWrap ${isClicked ? NotdisablingThings ? 'IndiCommentColor' :'IndiCommentColorDisabled' : ''}`} id={`row${clName}${i}`}
      component="th"
      scope="row"
      contentEditable={NotdisablingThings}
      onInput={handleChange}
      ref={inputRef} />
    </StyledTableRow><div>
      <div id={`rolePop${clName}${i}`}>
        {enablingRolePopup && <RolestaggingPopUp changedObj={changedObj} addedtags={addedtags} setAddedTags={setAddedTags} searchingString={searchingString} changingCommentText={changingCommentText} setEnablingRolePopup={setEnablingRolePopup} />}
      </div>
    </div></>
  );
};
