/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {MinusIconComponent} from '../common/AppPlusMinusButtons/MinusButton';
import {PlusIconComponent} from '../common/AppPlusMinusButtons/PlusButton';
import {colorReturn} from './EditBuyPlanService';
import {Box, FormControl, MenuItem, Select} from '@mui/material';
import {DataSheetGrid, keyColumn, textColumn} from 'react-datasheet-grid';
import DialogService from '../../services/dialogService';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import RolestaggingPopUp from '../common/CommentsTagging/RolestaggingPopUp';
import {taggingColumn} from '../common/CommentsTagging/TaggingUtils';
// import {useDispatch, useSelector} from 'react-redux';
// import {getFunctionalityAccess} from '../../redux/RoleBasedAccess';
// import {useEffect} from 'react';
// import {AppTableInput2} from '../common/App-Table-Input/AppTableInput';
// import {AppDate} from '../common/AppDate/AppDate';

export const ParentHeader = ({values}) => {
  return (
    <div className={`parent-header-container ${values.color}`}>
      <div className={`parent  ${values.parentTitle == 'Last month-Overall' ? 'last-month-overall' : ''}`}>
        {values.parentTitle}
      </div>
      <div className="flex-class">{values.lower}</div>
    </div>
  );
};

export const MinusComponent = ({values}) => {
  return (
    <div className={`parent-header-container ${values.color}`}>
      <div className="parent">{values.parentTitle}</div>
      <div className="flex-class">
        {values.lower}
        <span onClick={values.click}>
          <MinusIconComponent />
        </span>
      </div>
    </div>
  );
};

export const PlusComponent = ({values}) => {
  return (
    <div className={`parent-header-container ${values.color}`}>
      <div className="parent">{values.parentTitle}</div>
      <div className="flex-class">
        {values.lower}
        <span onClick={values.click}>
          <PlusIconComponent />
        </span>
      </div>
    </div>
  );
};

export const CoverageComponent = ({val, v, rowIndex}) => {
  return (
    <>
      <div className="buy-plan-coverage">
        <div>{val}</div>
        {rowIndex !== 12 ? (
          <div
            className={`circle ${
              colorReturn(v, val) != 'green' ?
                colorReturn(v, val) != 'red' ?
                  colorReturn(v, val) :
                  'dark-red' :
                'dark-green'
            }`}
          ></div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export const BackgroundColorHandler = ({rowData, columnName, handleEdit, color, value, type, rowIndex}) => {
  const [val, setVal] = useState('');
  const handleChange = (event) => {
    setVal(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value);
  };
  if (type == 'comments') {
    return <>{rowIndex !== 12 ? <textarea onChange={(e)=> handleChange(e)} value={val} className={`buy-plan-text-area ${color}`}></textarea> : ''}</>;
  }
  return <div className={`color-provider ${color}`}>{value}</div>;
};

export const VarianceChange = ({varianceReasons = [], handleEdit, columnName, color, rowIndex, rowData}) => {
  const [variance, setVariance] = React.useState('');
  const handleChange = (event) => {
    setVariance(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value);
  };
  return (
    <>
      {rowIndex !== 12 ? (
        <Box sx={{minWidth: 120, display: 'flex', justifyContent: 'center', height: '39px'}} className={`${color}`}>
          <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} fullWidth>
            <Select
              id="variance"
              value={variance}
              onChange={handleChange}
              className={`buy-plan-select`}
              disabled={color=='green'}
              sx={{
                'boxShadow': 'none',
                '.MuiOutlinedInput-notchedOutline': {border: 0},
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                'borderRadius': 0,
                'width': '80%',
                'background': 'white',
              }}
            >
              {varianceReasons?.map((val, key) => (
                <MenuItem key={key} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export const AppTextArea = ({handleEdit, columnName, rowIndex, rowData}) => {
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
    handleEdit(columnName, rowData.planYear, rowIndex, event.target.value);
  };
  return <textarea onChange={(e)=> handleChange(e)} value={value} className={`buy-plan-text-area`}></textarea>;
};


export const ApproverComments2 = ({handleEdit, accessControlComment, userid, edit, wfId, date, accessControlReview, approverCommentsData, authState, commentsTrigger, setCommentsTrigger}) => {
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const [flag, setFlag] = useState(true);
  const [rows, setRows] = useState([]);
  const [name, setName] = useState('');
  const [changedObj, setChangedObj] = useState(null);
  const [columns, setColumns] = useState([
    {
      ...keyColumn('author', textColumn),
      title: 'Author',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('date', textColumn),
      title: 'Date',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('comment', textColumn),
      title: 'Comment',
      disabled: ({rowData, rowIndex}) => {
        if (isCreator.currentRoleId == 1) return true;
        if ((rowIndex < approverCommentsData.length-2) || (rowIndex < rows.length-2)) return true;
        else {
          if (rowData.created_by) {
            return (rowData.created_by == userid) ? false : true;
          }
          return false;
        }
      },
    },
  ]);

  useEffect(() => {
    // if (!columns.length) {
    setColumns([
      {
        ...keyColumn('author', textColumn),
        title: 'Author',
        disabled: true,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        ...keyColumn('date', textColumn),
        title: 'Date',
        disabled: true,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        ...keyColumn('comment', textColumn),
        title: 'Comment',
        disabled: ({rowData, rowIndex}) => {
          if (isCreator.currentRoleId == 1) return true;
          if ((rowIndex < approverCommentsData.length-2) || (rowIndex < rows.length-2)) return true;
          else {
            if (rowData.created_by) {
              return (rowData.created_by == userid) ? false : true;
            }
            return false;
          }
        },
      },
    ]);
    // }
    setFlag(!flag);
  }, [isCreator, rows.length, approverCommentsData]);
  const emptyRow = {
    author: '',
    date: '',
    comment: '',
  };

  useEffect(() => {
    const n = isCreator?.userName;
    setName(n);
  }, []);

  useEffect(() => {
    // console.log(approverCommentsData);
    let data = [];
    const n = isCreator?.userName;
    if (approverCommentsData?.length) {
      data = approverCommentsData.map((val) => {
        return {
          author: val.created_by,
          date: val.updated_on,
          comment: val.comments,
          id: val.id,
          created_by: val.created_by_id,
        };
      });
    }
    setRows([...data, {...emptyRow, date: date, author: n}]);
  }, [approverCommentsData]);
  const sendData = () =>{
    if (changedObj !== null) {
      const row = rows[changedObj];
      // console.log(row);
      let payload = null;
      if (changedObj == (rows.length-1)) {
        payload = {
          'comment': row.comment,
          'workflow_id': wfId,
          'is_approver': 1,
        };
        DialogService.saveComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: (name || isCreator?.userName),
                  date: date,
                  comment: '',
                }]);
              }
              setChangedObj(null);
            });
      } else {
        payload = {
          'comment': row.comment,
          'id': row.id,
          'is_approver': 1,
          'workflow_id': wfId,
        };
        DialogService.editComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: (name || isCreator?.userName),
                  date: date,
                  comment: '',
                }]);
              }
              setChangedObj(null);
            });
      }
    }
    setCommentsTrigger(!commentsTrigger);
  };
  // console.log(accessControlReview);
  return (
    <div>
      <div className='comments-table'>
        <div className="approver-comments-header">Approver Comments</div>
        {flag && <DataSheetGrid
          value={rows}
          onChange={(rowData, rowIndex) => {
            setChangedObj(rowIndex[0].fromRowIndex);
            setRows(rowData);
          }}
          columns={columns}
          rowClassName={() => 'forecast buy-plan'}
          disableContextMenu={true}
          gutterColumn={false}
        />}
        {!flag && <DataSheetGrid
          value={rows}
          onChange={(rowData, rowIndex) => {
            setChangedObj(rowIndex[0].fromRowIndex);
            setRows(rowData);
          }}
          columns={columns}
          rowClassName={() => 'forecast buy-plan'}
          disableContextMenu={true}
          gutterColumn={false}
        />}
        <Box sx={{
          width: '110px',
          height: '30px',
          borderRadius: '5px',
          background: '#4297D3',
          color: 'white',
          fontSize: '12px',
          textAlign: 'center',
          padding: '5px 5px 5px 5px',
          float: 'right',
          marginTop: '10px',
          zIndex: 1,
          cursor: 'pointer',
        }} onClick={sendData}>Save Comment</Box>
      </div>

    </div>


  );
};

export const Comments = ({handleEdit, commentsTrigger, setCommentsTrigger, accessControlComment, userid, edit, wfId, date, accessControlReview, commentsData, authState}) => {
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const [flag, setFlag] = useState(true);
  const [rows, setRows] = useState([]);
  const [name, setName] = useState('');
  const [changedObj, setChangedObj] = useState(null);
  const [columns, setColumns] = useState([
    {
      ...keyColumn('author', textColumn),
      title: 'Author',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('date', textColumn),
      title: 'Date',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('comment', textColumn),
      title: 'Comment',
      disabled: ({rowData, rowIndex}) => {
        if (commentsData.length > 1 && ((rowIndex < commentsData.length-2) || (rowIndex < rows.length-2))) return true;
        else {
          if (rowData.created_by) {
            return (rowData.created_by == userid) ? false : true;
          }
          return false;
        }
      },
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        ...keyColumn('author', textColumn),
        title: 'Author',
        disabled: true,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        ...keyColumn('date', textColumn),
        title: 'Date',
        disabled: true,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        ...keyColumn('comment', textColumn),
        title: 'Comment',
        disabled: ({rowData, rowIndex}) => {
          if (commentsData.length > 1 && ((rowIndex < commentsData.length-2) || (rowIndex < rows.length-2))) return true;
          else {
            if (rowData.created_by) {
              return (rowData.created_by == userid) ? false : true;
            }
            return false;
          }
        },
      },
    ]);
    setFlag(!flag);
  }, [isCreator, rows.length, commentsData]);

  const emptyRow = {
    author: '',
    date: '',
    comment: '',
  };

  useEffect(() => {
    const n = isCreator?.userName;
    setName(n);
  }, [isCreator]);

  useEffect(() => {
    // console.log(commentsData);
    let data = [];
    const n = isCreator?.userName;
    if (commentsData?.length) {
      data = commentsData.map((val) => {
        return {
          author: val.created_by,
          date: val.updated_on,
          comment: val.comments,
          id: val.id,
          created_by: val.created_by_id,
        };
      });
    }
    setRows([...data, {...emptyRow, date: date, author: n}]);
  }, [commentsData]);
  const sendData = () =>{
    if (changedObj !== null) {
      const row = rows[changedObj];
      // console.log(row);
      let payload = null;
      if (changedObj == (rows.length-1)) {
        payload = {
          'comment': row.comment,
          'workflow_id': wfId,
          'is_approver': 0,
        };
        DialogService.saveComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: name,
                  date: date,
                  comment: '',
                }]);
              }
              setChangedObj(null);
            });
      } else {
        payload = {
          'comment': row.comment,
          'id': row.id,
          'is_approver': 0,
        };
        DialogService.editComment(payload)
            .then((res) => {
              // console.log(res);
              if (changedObj == (rows.length-1)) {
                setRows([...rows, {
                  author: name,
                  date: date,
                  comment: '',
                }]);
              }
              setChangedObj(null);
            });
      }
    }
    setCommentsTrigger(!commentsTrigger);
  };

  return (
    <div className='comments-table'>
      <div className="approver-comments-header">Comments</div>
      {flag && <DataSheetGrid
        value={rows}
        onChange={setRows}
        columns={columns}
        rowClassName={() => 'forecast buy-plan'}
        disableContextMenu={true}
        gutterColumn={false}
      />}

      {!flag && <DataSheetGrid
        value={rows}
        onChange={setRows}
        columns={columns}
        rowClassName={() => 'forecast buy-plan'}
        disableContextMenu={true}
        gutterColumn={false}
      />}
      {<Box sx={{
        width: '110px',
        height: '30px',
        borderRadius: '5px',
        background: '#4297D3',
        color: 'white',
        fontSize: '12px',
        textAlign: 'center',
        padding: '5px 5px 5px 5px',
        float: 'right',
        marginTop: '10px',
        cursor: 'pointer',
      }} onClick={sendData}>Save Comment</Box>}
    </div>
  );
};


