/* eslint-disable */
// using selectedRow.execution_month[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years

import React, {useEffect, useState} from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {DialogContent, Grid} from '@mui/material';
import {titleCase, months, monthArr} from '../../services/utils';
import CloseIcon from '@mui/icons-material/Close';
import '../AppMainDialog/index.css';
import {useDispatch, useSelector} from 'react-redux';
import {editStatus} from '../../redux/EditStatus';
import DialogService from '../../services/dialogService';
import { setlinkingLoad} from '../../redux/features/configurations';

import {changeButtonStatusValue} from '../../redux/features/buttonStatus';


import ForecastTable from './ForecastTab/Forecast';
import ConfigurationServices from '../../services/ConfigurationServices';
import CoverageUpdate from './CoverageUpdate/CoverageUpdate';
// import styled from '@emotion/styled';
// const ButtonComponent = styled('button')({
//   yearButton: {
//     background: "#ffffff",
//     color: "#6d7888",
//     fontSize: "12px",
//     border: "none",
//     outline: "0px",
//     "&:hover": {
//       background: "#4297d3",
//       color: "#ffffff",
//     },
//     "&:active": {
//       background: "#4297d3",
//       color: "#ffffff",
//     }
//   }
// })
//

const gridCSS = {
  activeButton: {
    background: '#4297d3',
    color: '#ffffff',
    fontSize: '12px',
    border: 'none',
    outline: '0px',
    cursor: 'pointer',
    borderRadius: '3px',
    padding: '2px 3px',
  },
  inActiveButton: {
    background: '#ffffff',
    color: '#6d7888',
    fontSize: '12px',
    cursor: 'pointer',
    border: 'none',
    outline: '0px',
    padding: '2px 3px',
  },
  tabContainer: {
    padding: '15px',
    height: '95vh',
  },
  grid: {
    background: '#F8F8F8',
    padding: '10px 0px',
    borderRadius: '10px',
    margin: '0 !important',
    width: '100% !important',
  },
  head: {
    color: '#0071DC',
    fontSize: '12px',
    fontWeight: 600,
    font: 'normal normal 600 12px/16px Segoe UI',
    marginBottom: '8px',
  },
  text: {
    color: '#3B3A39',
    fontSize: '12px',
    font: 'normal normal 600 12px/16px Segoe UI',
  },
};

function TabPanel(props) {
  const {className, children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={className}
    >
      {value === index && (
        <Box sx={{p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `tab-${index}`,
  };
}

const TreasurySelections = ['first', 'second', 'third', 'fourth2', 'fourPointFive', 'fifth', 'sixth'];
const SupplierSelections = ['first', 'second', 'third', 'fourth', 'fourPointFive', 'fifth', 'sixth'];
const BothSelections = ['first', 'second', 'third', 'fourth', 'fourth2', 'fourPointFive', 'fifth', 'sixth'];
const NoneSelections = ['first', 'second', 'third', 'fourPointFive', 'fifth', 'sixth'];
const today = new Date();
const TabsComponent = React.memo(({selectedRow, setIsPopupVisible, access}) => {
  const dispatch = useDispatch();
  const buttonStatus = useSelector((state) => state.btnStatus.value);

  const [currentRole, setCurrentRole] = useState(null);
  const [value, setValue] = React.useState('');
  const [yearValue, setYearValue] = React.useState(selectedRow.execution_month[0] + '');
  const [previousSelectedYearTracker, setpreviousSelectedYearTracker] = React.useState(null);
  const [selectedYear, setSelectedYear] = React.useState(null);
  const [modifiedData, setModifiedData] = React.useState({});
  const [years, setYears] = React.useState([]);
  const [data, setData] = React.useState([]);
  let [flag, setFlag] = React.useState(false);
  const [subMissionData, setSubmissionData] = React.useState({});
  const [hedgingMechanism, setHedgingMechanism] = useState('');
  const [tabsEnableCheck, setTabsEnableCheck] = React.useState({
    forecast: selectedRow.isHistoricalDataEnabled == true && selectedRow.status !== 'CLOSED' ? false : true,
    buyPlan: buttonStatus === 'view',
    coverage: buttonStatus === 'view',
    historical: false,
  });
  const [ForecastPreviousMonthAccess, setForecastPreviousMonthAccess] = useState('')
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  // const userid=useSelector((state)=>state?.roleBasedAccess?.userId);

  const [NonPlayBookCoverageData1, setNonPlayBookCoverageData1] = useState([]);
  const [NonPlayBookCoverageData2, setNonPlayBookCoverageData2] = useState([]);
  const [NonPlayBookCoverageData3, setNonPlayBookCoverageData3] = useState([]);

  useEffect(()=>{
    if(years.length>0){
      setNonPlayBookCoverageData1([
        {
          month: 3,
          type: 'Covered Price',
          planMonth: monthArr[selectedRow.execution_month[1]-1 < 0 ? 12 + selectedRow.execution_month[1]-1 : selectedRow.execution_month[1]-1],
          planYear: years[0],
          jan: 0,
          year: years[0],
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
          fy: 0,
          workflow_id: selectedRow.workflow_id,
        },
        {
          month: 3,
          type: 'Covered Volume',
          planMonth: monthArr[selectedRow.execution_month[1]-1 < 0 ? 12 + selectedRow.execution_month[1]-1 : selectedRow.execution_month[1]-1],
          planYear: years[0],
          jan: 0,
          year: years[0],
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
          fy: 0,
          workflow_id: selectedRow.workflow_id,
        },
        
      ])
      setNonPlayBookCoverageData2([{
        month: 3,
        type: 'Covered Price',
        planMonth: monthArr[selectedRow.execution_month[1]-1 < 0 ? 12 + selectedRow.execution_month[1]-1 : selectedRow.execution_month[1]-1],
        planYear: years[0]+1,
        jan: 0,
        year: years[0],
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        fy: 0,
        workflow_id: selectedRow.workflow_id,
      },
      {
        month: 3,
        type: 'Covered Volume',
        planMonth: monthArr[selectedRow.execution_month[1]-1 < 0 ? 12 + selectedRow.execution_month[1]-1 : selectedRow.execution_month[1]-1],
        planYear: years[0]+1,
        jan: 0,
        year: years[0],
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        fy: 0,
        workflow_id: selectedRow.workflow_id,
      },
      ])
      setNonPlayBookCoverageData3([  {
        month: 3,
        type: 'Covered Price',
        planMonth: monthArr[selectedRow.execution_month[1]-1 < 0 ? 12 + selectedRow.execution_month[1]-1 : selectedRow.execution_month[1]-1],
        planYear: years[0]+2,
        jan: 0,
        year: years[0],
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        fy: 0,
        workflow_id: selectedRow.workflow_id,
      },  {
        month: 3,
        type: 'Covered Volume',
        planMonth: monthArr[selectedRow.execution_month[1]-1 < 0 ? 12 + selectedRow.execution_month[1]-1 : selectedRow.execution_month[1]-1],
        planYear: years[0]+2,
        jan: 0,
        year: years[0],
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        fy: 0,
        workflow_id: selectedRow.workflow_id,
      }])
    }
    }, [years])
  useEffect(()=>{

    if (functionalityAccess && functionalityAccess['Forecast:Previous_Month']) {
      setForecastPreviousMonthAccess(functionalityAccess['Forecast:Previous_Month']);
    }
    else{
      setForecastPreviousMonthAccess('');
    }

  }, [functionalityAccess, currentRole]);
  const [columnsStatus, setCoulumnsStatus] = React.useState({
    first: true,
    second2: false,
    second: false,
    third2: false,
    third: false,
    fourthS: false,
    fourth: false,
    fourth2T: false,
    fourth2: false,
    fifth2: false,
    fourPointFive: false,
    fourPointFive2: false,
    fifth: false,
    sixth: true,
  });
  const [initialArr, setInitialArr] = React.useState([]);
  const [selectedArr, setSelectedArr] = React.useState([]);
  const [firstLoad, setFirstLoad] = React.useState(false);
  // const [isForecastUpdateTabEditable, setIsForecastUpdateTabEditable]=useState(true);
  const isForecastUpdateTabEditable = useSelector((state) => state.configurations.ForecastUpdateSaveButton);

  const currentRoleId = useSelector((state) => state?.roleBasedAccess?.currentRoleId);
  const availableRoles = useSelector((state) => state?.roleBasedAccess?.availableRoles);
  const [yearCount, setYearCount] = useState([]);
  useEffect(() => {
    if (currentRoleId) {
      availableRoles.map((role) => {
        if (currentRoleId === role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(() => {
    // if (currentRole !== null) {
    //   if (currentRole !== 'Forecast Creator') {
    //     setValue(selectedRow?.isHistoricalDataEnabled == true ? 0 : 2);
    //   } else {
    //     setValue(2);
    //     setTabsEnableCheck((prev) => ({...prev, forecast: true}));
    //   }
    // }
    setValue(0);
  }, [currentRole]);

  const buyplanStatus = useSelector((state) => state.btnStatus.status);

  const [dataTrigger, setDataTrigger] = useState(false);
  useEffect(() => {
    let hedgingMech = '';
    if (selectedRow.coverageMode == 'SUPPLIER' && selectedRow.others == false) {
      hedgingMech = 'Supplier';
    } else if (selectedRow.coverageMode == 'SUPPLIER' && selectedRow.others == true) {
      hedgingMech = 'Supplier/Other';
    } else if (selectedRow.coverageMode == 'TREASURY' && selectedRow.others == false) {
      hedgingMech = 'Treasury';
    } else if (selectedRow.coverageMode == 'TREASURY' && selectedRow.others == true) {
      hedgingMech = 'Treasury/Other';
    } else if (selectedRow.coverageMode == 'BOTH' && selectedRow.others == false) {
      hedgingMech = 'Supplier/Treasury';
    } else if (selectedRow.coverageMode == 'BOTH' && selectedRow.others == true) {
      hedgingMech = 'Supplier/Treasury/Other';
    } else if (selectedRow.coverageMode == 'NONE' && selectedRow.others == false) {
      hedgingMech = 'None';
    } else if (selectedRow.others == true && selectedRow.coverageMode == 'NONE') {
      hedgingMech = 'Other';
    }
    setHedgingMechanism(hedgingMech);
  }, [selectedRow]);
 

  useEffect(() => {
    if (selectedRow.prevworkflow_id > 0 && buyplanStatus !== 'NEW') {
      dispatch(setlinkingLoad(false));
      setTabsEnableCheck((prev) => ({...prev, forecast: true}));
      // if(value<2){

      //   setValue(2)
      // }
    }
  }, [selectedRow]);

 

  useEffect(() => {
    if (years.length) {
      setSelectedYear(years[0]);
      setpreviousSelectedYearTracker(years[0]);
      // setYearCount([years[0]])
    } else {
      let years = Object.keys(modifiedData)
        .map((val) => val)
        .sort();
      setSelectedYear(years[0]);

      setpreviousSelectedYearTracker(years[0]);
      // setYearCount([years[0]])
    }
  }, [years]);




  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFirstLoad(false);
  };
  //  checking if the current month is jan the years will be [selectedRow.execution_month[0]-1, selectedRow.execution_month[0], selectedRow.execution_month[0]+1]
  useEffect(() => {
    if (months[selectedRow.execution_month[1] - 1] == 'jan') {
      setYears([selectedRow.execution_month[0] - 1, selectedRow.execution_month[0], selectedRow.execution_month[0] + 1]);
    } else {
      setYears([selectedRow.execution_month[0], selectedRow.execution_month[0] + 1, selectedRow.execution_month[0] + 2]);
    }
  }, [selectedRow]);

  useEffect(() => {
    return () => dispatch(editStatus(true));
  }, []);

  // Is used for calculating quarters for particular month

  // const [wfDetails, setWfDetails] = useState(); useEffect(()=>{
  //   ConfigurationServices.getPlayBookWorkflowDetails(selectedRow.workflow_id).then(res=>{
  //     setWfDetails(res);
  //   })
  // },[selectedRow]);



  useEffect(() => {
    // console.log('@appmaindialog selectedRow',selectedRow)
    if (selectedRow && !firstLoad) {
      // if(selectedRow.status != 'NEW' && selectedRow.status != 'DRAFT') {
      //   setTabsEnableCheck({...tabsEnableCheck, buyPlan: true});
      // }
      if (selectedRow?.coverageMode == 'TREASURY') {
        let arr = JSON.parse(JSON.stringify(TreasurySelections));
        if (!selectedRow.others) {
          arr = arr.filter((val) => val !== 'fourPointFive');
        }
        setTimeout(() => {
          setInitialArr([...arr]);
          changeStatus([...arr]);
        });
      }
      if (selectedRow?.coverageMode == 'SUPPLIER') {
        let arr = JSON.parse(JSON.stringify(SupplierSelections));
        if (!selectedRow.others) {
          arr = arr.filter((val) => val !== 'fourPointFive');
        }
        setTimeout(() => {
          setInitialArr([...arr]);
          changeStatus([...arr]);
        });
      }
      if (selectedRow?.coverageMode == 'BOTH') {
        let arr = JSON.parse(JSON.stringify(BothSelections));
        if (!selectedRow.others) {
          arr = arr.filter((val) => val !== 'fourPointFive');
        }
        setTimeout(() => {
          setInitialArr([...arr]);
          changeStatus([...arr]);
        });
      }
      if (selectedRow?.coverageMode == 'NONE') {
        let arr = JSON.parse(JSON.stringify(NoneSelections));
        if (!selectedRow.others) {
          arr = arr.filter((val) => val !== 'fourPointFive');
        }
        setTimeout(() => {
          setInitialArr([...arr]);
          changeStatus([...arr]);
        });
      }
      setFirstLoad(true);
      // if(selectedRow.prevworkflow_id > 0){

      //   // setValue(2);
      //   setTabsEnableCheck((prev) => {
      //     return {...prev, forecast: true};
      //   });
      // }
    }
  }, [selectedRow]);

  const changeStatus = (arr) => {
    let status = columnsStatus;
    Object.keys(status).forEach((val) => {
      if (arr.indexOf(val) > -1) {
        status[val] = true;
      } else status[val] = false;
    });
    setCoulumnsStatus({...status});
  };




  const [NonPlayBookForecastUpdate1, setNonPlayBookForecastUpdate1] = useState([]);
  const [NonPlayBookForecastUpdate2, setNonPlayBookForecastUpdate2] = useState([]);
  const [NonPlayBookForecastUpdate3, setNonPlayBookForecastUpdate3] = useState([]);

  useEffect(() => {
    Object.values(modifiedData).map((mobj, index) => {
      const Ddata1 = [];
      const Ddata2 = [];
      const Ddata3 = [];
      Object.values(mobj).map((sobj) => {
        if (index == 0) {
          Ddata1.push(sobj);
        }
        if (index == 1) {
          Ddata2.push(sobj);
        }
        if (index == 2) {
          Ddata3.push(sobj);
        }
      });
      if (selectedYear == years[0] && NonPlayBookForecastUpdate1.length === 0) {
        setNonPlayBookForecastUpdate1((prev) => [...prev, ...Ddata1]);
      }
      if (selectedYear == years[1] && NonPlayBookForecastUpdate2.length === 0) {
        setNonPlayBookForecastUpdate2((prev) => [...prev, ...Ddata2]);
      }
      if (selectedYear == years[2] && NonPlayBookForecastUpdate3.length === 0) {
        setNonPlayBookForecastUpdate3((prev) => [...prev, ...Ddata3]);
      }
    });
  }, []);

  // updates the modifiedData variable when data1, data2, data3 changes
  useEffect(() => {
    const newModified = JSON.parse(JSON.stringify(modifiedData));
    if (NonPlayBookForecastUpdate1.length > 0 && !Array.isArray(newModified[years[0]])) {
      newModified[years[0]][selectedRow.workflow_id] = NonPlayBookForecastUpdate1[1];
    }
    setModifiedData({...newModified});
  }, [NonPlayBookForecastUpdate1]);

  useEffect(() => {
    const newModified = JSON.parse(JSON.stringify(modifiedData));
    if (NonPlayBookForecastUpdate2.length > 0 && !Array.isArray(newModified[years[1]])) {
      newModified[years[1]][selectedRow.workflow_id] = NonPlayBookForecastUpdate2[1];
    }
    setModifiedData(newModified);
  }, [NonPlayBookForecastUpdate2]);

  useEffect(() => {
    const newModified = JSON.parse(JSON.stringify(modifiedData));
    if (NonPlayBookForecastUpdate3.length > 0 && !Array.isArray(newModified[years[2]])) {
      newModified[years[2]][selectedRow.workflow_id] = NonPlayBookForecastUpdate3[1];
    }
    setModifiedData(newModified);
  }, [NonPlayBookForecastUpdate3]);


  useEffect(() => {
    if ((selectedRow.isHistoricalDataEnabled == true && value == 2) || (selectedRow.isHistoricalDataEnabled == false && value == 2)) {
      const Ddata1 = [];
      const Ddata2 = [];
      const Ddata3 = [];
      Object.values(modifiedData).map((mobj, index) => {
        Object.values(mobj).map((sobj) => {
          if (index == 0) {
            Ddata1.push(sobj);
          }
          if (index == 1) {
            Ddata2.push(sobj);
          }
          if (index == 2) {
            Ddata3.push(sobj);
          }
        });
      });
      
    }
    //  }
  }, [modifiedData, data, value]);



  useEffect(() => {
    if (selectedYear !== null && selectedYear !== undefined) {
      if (yearCount.length < 3) {
        setYearCount((prev) => [...prev, selectedYear]);
      } else {
        setYearCount([yearCount[1], yearCount[2], selectedYear]);
      }
    }
  }, [selectedYear]);

  return (
    <Box>
      <Grid container sx={gridCSS.grid} rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Non Playbook Name</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.non_playbook_name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Sector</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.sector}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Country</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.region}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Category</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.category}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Commodity</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.commodity}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Sub Commodity</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.sub_commodity}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Execution Month</Typography>
          <Typography sx={gridCSS.text}>
            {selectedRow?.execution_month && `${titleCase(months[selectedRow?.execution_month[1] - 1])} ${selectedRow?.execution_month[0]}`}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>BU Description</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.buDescription}</Typography>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Units</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.measurement_unit}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Currency</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.currency}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography sx={gridCSS.head}>Owner</Typography>
          <Typography sx={gridCSS.text}>{selectedRow?.created_by_name}</Typography>
        </Grid>
      </Grid>
      <Box sx={{width: '100%'}}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            height: '22px',
            marginBottom: '7.5px',
            border: 'none',
            alignItems: 'center',
          }}
        >
          <Tabs
            value={value}
            textColor="primary"
            indicatorColor="secondary"
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              sx: {
                top: 32,
              },
            }}
            sx={{
              gap: '24px',
            }}
          >
            <Tab
              label="Forecast Update"
              sx={{
                minWidth: '0px',
                textTransform: 'capitalize',
                padding: '0px',
                fontSize: '13px',
                fontWeight: 600,
                display: (selectedRow.isHistoricalDataEnabled == false || currentRole == 'Forecast Creator') && 'none',
              }}
            />{' '}
            <Tab
              label="Coverages"
              sx={{
                minWidth: '0px',
                textTransform: 'capitalize',
                padding: '0px',
                fontSize: '13px',
                fontWeight: 600,
                display: (selectedRow.isHistoricalDataEnabled == false || currentRole == 'Forecast Creator') && 'none',
              }}
            />{' '}
            
            
          </Tabs>
          <Box sx={{border: '1px solid #dbdbdb', padding: '1px', borderRadius: '3px'}}>
            {value == 0 &&
              years.length &&
              years.map((val) => (
                <button
                  onClick={() => {
                    setTimeout(() => setSelectedYear(val), 500);
                    // setSelectedYear(val)
                  }}
                  style={selectedYear == val ? gridCSS.activeButton : gridCSS.inActiveButton}
                >
                  {val}
                </button>
              ))}

           

            {value == 1 &&
              years.length &&
              years.map((val) => (
                <button
                  onClick={() => {
                    // if(selectedYear !== previousSelectedYearTracker){
                    //   // setpreviousSelectedYearTracker(selectedYear);
                    //   setYearCount(prev=>[...prev, selectedYear])

                    // }
                    setTimeout(() => setSelectedYear(val), 500);
                    // setSelectedYear(val)
                  }}
                  style={selectedYear == val ? gridCSS.activeButton : gridCSS.inActiveButton}
                >
                  {val}
                </button>
              ))}

          </Box>
        </Box>

        {/* {selectedRow.prevworkflow_id == -1 && <> */}
 
      
        <TabPanel
          style={{padding: 0, display: selectedRow.isHistoricalDataEnabled == false && 'none'}}
          className="forecast-container"
          value={value}
          index={1}
        >

          <CoverageUpdate access={access} setIsPopupVisible={setIsPopupVisible} setDataTrigger={setDataTrigger} dataTrigger={dataTrigger}  setValue={setValue} years={years} setTabsEnableCheck={setTabsEnableCheck} data={data} setData={setData} selectedRow={selectedRow} setModifiedData={setModifiedData} selectedYear={selectedYear} modifiedData={modifiedData} NonPlayBookCoverageData1={NonPlayBookCoverageData1} NonPlayBookCoverageData2={NonPlayBookCoverageData2} NonPlayBookCoverageData3={NonPlayBookCoverageData3} setNonPlayBookCoverageData1={setNonPlayBookCoverageData1} setNonPlayBookCoverageData2={setNonPlayBookCoverageData2} setNonPlayBookCoverageData3={setNonPlayBookCoverageData3}/>

        </TabPanel>

        

        <TabPanel
          style={{padding: 0, display: selectedRow.isHistoricalDataEnabled == false && 'none'}}
          className="forecast-container"
          value={value}
          index={0}
        >

          <ForecastTable access={access} ForecastPreviousMonthAccess={ForecastPreviousMonthAccess} previousSelectedYearTracker={previousSelectedYearTracker} setSelectedYear={setSelectedYear} NonPlayBookForecastUpdate1={NonPlayBookForecastUpdate1} NonPlayBookForecastUpdate2={NonPlayBookForecastUpdate2} NonPlayBookForecastUpdate3={NonPlayBookForecastUpdate3} setNonPlayBookForecastUpdate1={setNonPlayBookForecastUpdate1} setNonPlayBookForecastUpdate2={setNonPlayBookForecastUpdate2} setNonPlayBookForecastUpdate3={setNonPlayBookForecastUpdate3} subMissionData={subMissionData} setSubmissionData={setSubmissionData} years={years} setTabsEnableCheck={setTabsEnableCheck} isForecastUpdateTabEditable={isForecastUpdateTabEditable}  data={data} setData={setData} selectedRow={selectedRow} setModifiedData={setModifiedData} selectedYear={selectedYear} modifiedData={modifiedData} yearCount={yearCount} />

        </TabPanel>
      </Box>
    </Box>
  );
});

export default function EditNonPlayBook({selectedRow, open, handleClose, setIsPopupVisible, access}) {
  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={'xl'} open={open} onClose={() => {}} sx={gridCSS.tabContainer}>
        <DialogContent
          style={{
            height: '90vh',
            position: 'relative',
            paddingTop: '40px',
            paddingBottom: '20px',
          }}
        >
          <Box sx={{position: 'absolute', top: '5px', right: '10px', cursor: 'pointer'}} onClick={() => handleClose()}>
            <CloseIcon />
          </Box>
          <TabsComponent access={access} selectedRow={selectedRow} setIsPopupVisible={setIsPopupVisible} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
