import React from 'react';
import './DropdownSelectMonth.css';
import {FormControl, MenuItem, Select} from '@mui/material';
import {useEffect} from 'react';
import {nextThreeYearsFromExecutionMon} from '../../../../services/utils';

const DropdownSelect = ({selectedRow, rowData, indexOfYear, apiData, i}) => {
  const [monthSelected, setMonthSelected] = React.useState('');
  const [dropDownData, setDropDownData] = React.useState([]);

  const handleChange = (event) => {
    setMonthSelected(event.target.value);
    apiData[indexOfYear].map((element, index)=>{
      if (index==i) {
        element.coverageYearMonth=String(event.target.value).replace(' ', '-');
        rowData.coverageYearMonth=String(event.target.value).replace(' ', '-');
      }
    });
  };

  {/*
    Return will be array of strings starting from one month prior to the buyplan execution month upto three years
    EX:- ['Aug 2023', 'Sep 2023',.., 'Dec 2025']
  */}

  useEffect(()=>{
    setMonthSelected((rowData.coverageYearMonth === undefined || rowData.coverageYearMonth === null) ? '' : String(rowData.coverageYearMonth).replace('-', ' ') || '');
    if (rowData?.prevMonth) {
      setDropDownData(nextThreeYearsFromExecutionMon(selectedRow, true) || []);
    } else {
      setDropDownData(nextThreeYearsFromExecutionMon(selectedRow) || []);
    }
  }, [rowData, selectedRow]);
  return (
    <div>
      <FormControl variant='standard' sx={{width: '80px', fontSize: '10px'}}>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          defaultValue={'Select'}
          value={monthSelected || ''}
          placeholder={'Select'}
          onChange={handleChange}
          sx={{width: '80px', fontSize: '12px'}}
          disabled={(rowData?.disableCell)}
        >
          {
            dropDownData?.map((month, id) => {
              return <MenuItem sx={{width: '80px', fontSize: '12px'}} key={id} value={month}>{month}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownSelect;
