import React from 'react';
import Reports from '../../Components/Reports/Reports';

const Report = () => {
  return (
    <div>
      <Reports/>
    </div>
  );
};

export default Report;
