import {months, roundToTwo, titleCase} from '../../services/utils';
import {defaultObj} from './EditBuyPlanConsts';

// function roundToTwo(decider, num) {
//   if (isNaN(num)) return 0;
//   // if (!Number.isInteger(num) && !(Math.floor(num) == num)) {
//   if (num % 1 !== 0) {
//     const roundedNum = +(Math.round(num + 'e+2') + 'e-2');
//     if (roundedNum == 0) return 0;
//     return roundedNum;
//   } else {
//     return parseFloat(num);
//   }
// }

const targetSetter = (year, targets, obj, i, decider) => {
  const mon = months[i].toUpperCase();
  let targetArr = null;
  if (targets['BPEXCEPTION'] || targets['BPREFERENCE']) {
    if (targets['BPEXCEPTION'] && targets['BPEXCEPTION'][`${mon}-${year}`]) {
      targetArr = targets['BPEXCEPTION'][`${mon}-${year}`].split(',');
    } else if (targets['BPREFERENCE'] && targets['BPREFERENCE'][`${mon}-${year}`]) {
      targetArr = targets['BPREFERENCE'][`${mon}-${year}`].split(',');
    }
  } else if (targets['PBEXCEPTION'] || targets['PBREFERENCE']) {
    if (targets['PBEXCEPTION'] && targets['PBEXCEPTION'][`${mon}-${year}`]) {
      targetArr = targets['PBEXCEPTION'][`${mon}-${year}`].split(',');
    } else if (targets['PBREFERENCE'] && targets['PBREFERENCE'][`${mon}-${year}`]) {
      targetArr = targets['PBREFERENCE'][`${mon}-${year}`].split(',');
    }
  }
  if (!targetArr) {
    obj = {
      ...obj,
      targetPercentMin: 0,
      targetPercentMax: 0,
      stargetPercentMin: 0,
      stargetPercentMax: 0,
      ttargetPercentMin: 0,
      ttargetPercentMax: 0,
      minCoverage: 0,
      maxCoverage: 0,
      sminCoverage: 0,
      smaxCoverage: 0,
      tminCoverage: 0,
      tmaxCoverage: 0,
      mayCover: 0,
      mustCover: 0,
      smayCover: 0,
      smustCover: 0,
      tmayCover: 0,
      tmustCover: 0,
      swillCover: 0,
      twillCover: 0,
      owillCover: 0,
      overAllWillCover: (obj.swillCover || 0) + (obj.twillCover || 0) + (obj.owillCover || 0),
      overAllCoverage: +obj.coverage + ((obj.swillCover || 0) + (obj.twillCover || 0) + (obj.owillCover || 0)),
      overAllCoveragePercent:
        obj.coverage && obj.updatedFcst ?
          roundToTwo(decider, ((+obj.coverage + (+(obj.swillCover || 0) + (obj.owillCover || 0) + (obj.twillCover || 0) || 0)) / +obj.updatedFcst) * 100) :
          0,
    };
  } else {
    obj = {
      ...obj,
      targetPercentMin: +targetArr[4],
      targetPercentMax: +targetArr[5],
      stargetPercentMin: +targetArr[0],
      stargetPercentMax: +targetArr[1],
      ttargetPercentMin: +targetArr[2],
      ttargetPercentMax: +targetArr[3],
      minCoverage: roundToTwo(decider, +obj.updatedFcst * (+targetArr[4] / 100)),
      maxCoverage: roundToTwo(decider, +obj.updatedFcst * (+targetArr[5] / 100)),
      sminCoverage: roundToTwo(decider, +obj.updatedFcst * (+targetArr[0] / 100)),
      smaxCoverage: roundToTwo(decider, +obj.updatedFcst * (+targetArr[1] / 100)),
      tminCoverage: roundToTwo(decider, +obj.updatedFcst * (+targetArr[2] / 100)),
      tmaxCoverage: roundToTwo(decider, +obj.updatedFcst * (+targetArr[3] / 100)),
      mayCover: roundToTwo(decider, ((targetArr[5] / 100) * obj.updatedFcst) - obj.coverage),
      mustCover: roundToTwo(decider, ((targetArr[4] / 100) * obj.updatedFcst) - obj.coverage),
      smayCover: roundToTwo(decider, ((targetArr[1] / 100) * obj.updatedFcst) - obj.sLastMonthCoverage),
      smustCover: roundToTwo(decider, ((targetArr[0] / 100) * obj.updatedFcst) - obj.sLastMonthCoverage),
      tmayCover: roundToTwo(decider, ((targetArr[3] / 100) * obj.updatedFcst) - obj.tLastMonthCoverage),
      tmustCover: roundToTwo(decider, ((targetArr[2] / 100) * obj.updatedFcst) - obj.tLastMonthCoverage),
      swillCover: 0,
      twillCover: 0,
      owillCover: 0,
      overAllWillCover: (obj.swillCover || 0) + (obj.twillCover || 0) + (obj.owillCover || 0),
      overAllCoverage: +obj.coverage + ((obj.swillCover || 0) + (obj.twillCover || 0) + (obj.owillCover || 0)),
      overAllCoveragePercent:
        obj.coverage && obj.updatedFcst ?
          roundToTwo(decider, ((+obj.coverage + (+(obj.swillCover || 0) + (obj.twillCover || 0) + (obj.owillCover || 0))) / +obj.updatedFcst) * 100) :
          0,
    };
  }
  return obj;
};

const coveragesFormation = (coveragesData, years, plannedCoverages, decider) => {
  const obj = {
    [years[0]]: {
      treasury: {},
      supplier: {},
      other: {},
      plannedCoverages: {},
    },
    [years[1]]: {
      treasury: {},
      supplier: {},
      other: {},
      plannedCoverages: {},
    },
    [years[2]]: {
      treasury: {},
      supplier: {},
      other: {},
      plannedCoverages: {},
    },
  };
  coveragesData.forEach((val) => {
    const mon = val.coverageYearMonth[1] - 1;
    if (val.coverageYearMonth[0] == years[0]) {
      const y1 = obj[years[0]];
      if (val.sourceType == 'SUPPLIER') {
        y1.supplier[months[mon]] = val;
      } else if (val.sourceType == 'OTHER') {
        y1.other[months[mon]] = val;
      } else {
        y1.treasury[months[mon]] = val;
      }
      obj[years[0]] = {...y1};
    }
    if (years[1] == val.coverageYearMonth[0]) {
      const y2 = obj[years[1]];
      if (val.sourceType == 'SUPPLIER') {
        y2.supplier[months[mon]] = val;
      } else if (val.sourceType == 'OTHER') {
        y2.other[months[mon]] = val;
      } else {
        y2.treasury[months[mon]] = val;
      }
      obj[years[1]] = {...y2};
    }
    if (years[2] == val.coverageYearMonth[0]) {
      const y3 = obj[years[2]];
      if (val.sourceType == 'SUPPLIER') {
        y3.supplier[months[mon]] = val;
      } else if (val.sourceType == 'OTHER') {
        y3.other[months[mon]] = val;
      } else {
        y3.treasury[months[mon]] = val;
      }
      obj[years[2]] = {...y3};
    }
  });
  plannedCoverages.forEach((val) => {
    const mon = val.coverageYearMonth[1] - 1;
    if (val.coverageYearMonth[0] == years[0]) {
      const y1 = obj[years[0]];
      y1.plannedCoverages[months[mon]] = val.total;
      obj[years[0]] = {...y1};
    }
    if (years[1] == val.coverageYearMonth[0]) {
      const y2 = obj[years[1]];
      y2.plannedCoverages[months[mon]] = val.total;
      obj[years[1]] = {...y2};
    }
    if (years[2] == val.coverageYearMonth[0]) {
      const y3 = obj[years[2]];
      y3.plannedCoverages[months[mon]] = val.total;
      obj[years[2]] = {...y3};
    }
  });
  return obj;
};

const quarterMonths = ['mar', 'jun', 'sep', 'dec'];

// const calculateTargetVariance = (obj, targetCoveragePercent, newCoveragePercent, selectedRow) => {
//   if (selectedRow.optionality) {
//     if (obj.minCoverage < newCoveragePercent) {
//       return Math.abs(obj.minCoverage - newCoveragePercent);
//     }
//     if (obj.minCoverage > newCoveragePercent) {
//       return Math.abs(obj.maxCoverage - newCoveragePercent);
//     }
//   } else {
//     return Math.abs(newCoveragePercent - targetCoveragePercent);
//   }
// };
const totalEvalutator = (data1, data2, data3, years, decider) => {
  let obj1 = JSON.parse(JSON.stringify(defaultObj));
  let obj2 = JSON.parse(JSON.stringify(defaultObj));
  let obj3 = JSON.parse(JSON.stringify(defaultObj));
  data1.forEach((val, key) => {
    if (key == 11) {
      const coveragePercent1 = (val.coverage && val.volumeLE && ((val.coverage/val.volumeLE)*100))*val.coverage;
      const supplierPercentMin1 = (val.updatedFcst && val.stargetPercentMin && val.updatedFcst * val.stargetPercentMin) || 0;
      const supplierPercentMax1 = (val.updatedFcst && val.stargetPercentMax && val.updatedFcst * val.stargetPercentMax) || 0;
      const targetMin1 = (val.updatedFcst && val.targetPercentMin && val.updatedFcst * val.targetPercentMin) || 0;
      const targetMax1 = (val.updatedFcst && val.targetPercentMax && val.updatedFcst * val.targetPercentMax) || 0;
      const treasuryPercentMin1 = (val.updatedFcst && val.ttargetPercentMin && val.updatedFcst * val.ttargetPercentMin) || 0;
      const treasuryPercentMax1 = (val.updatedFcst && val.ttargetPercentMax && val.updatedFcst * val.ttargetPercentMax) || 0;
      const overAllCoveragePercentWeighted = (+val.overAllCoverage * +val.overAllCoveragePercent);
      obj1 = {
        ...obj1,
        period: 'FY Total',
        market: '',
        planYear: obj1.planYear,
        volumeLE: roundToTwo(decider, (+obj1.volumeLE || 0) + val.volumeLE),
        coverage: roundToTwo(decider, (+obj1.coverage || 0) + val.coverage),
        sLastMonthCoverage: roundToTwo(decider, (+obj1.sLastMonthCoverage || 0) + val.sLastMonthCoverage),
        tLastMonthCoverage: roundToTwo(decider, (+obj1.tLastMonthCoverage || 0) + val.tLastMonthCoverage),
        oLastMonthCoverage: roundToTwo(decider, +obj1.oLastMonthCoverage+(val.oLastMonthCoverage || 0)),
        coveragePercent: roundToTwo(decider, (obj1.coveragePercent + coveragePercent1)/((+obj1.coverage || 0) + val.coverage)),
        updatedFcst: roundToTwo(decider, (+obj1.updatedFcst || 0) + val.updatedFcst),
        targetPercentMin: roundToTwo(decider, ((+obj1.targetPercentMin || 0) + targetMin1)/((+obj1.updatedFcst || 0) + val.updatedFcst)),
        targetPercentMax: roundToTwo(decider, ((+obj1.targetPercentMax || 0) + targetMax1)/((+obj1.updatedFcst || 0) + val.updatedFcst)),
        minCoverage: roundToTwo(decider, (+obj1.minCoverage || 0) + val.minCoverage),
        maxCoverage: roundToTwo(decider, (+obj1.maxCoverage || 0) + val.maxCoverage),
        tminCoverage: roundToTwo(decider, (+obj1.tminCoverage || 0) + val.tminCoverage),
        tmaxCoverage: roundToTwo(decider, (+obj1.tmaxCoverage || 0) + val.tmaxCoverage),
        sminCoverage: roundToTwo(decider, (+obj1.sminCoverage || 0) + val.sminCoverage),
        smaxCoverage: roundToTwo(decider, (+obj1.smaxCoverage || 0) + val.smaxCoverage),
        smustCover: roundToTwo(decider, (+obj1.smustCover || 0) + val.smustCover),
        smayCover: roundToTwo(decider, (+obj1.smayCover || 0) + val.smayCover),
        swillCover: roundToTwo(decider, (+obj1.swillCover || 0) + val.swillCover),
        tmustCover: roundToTwo(decider, (+obj1.tmustCover || 0) + val.tmustCover),
        tmayCover: roundToTwo(decider, (+obj1.tmayCover || 0) + val.tmayCover),
        twillCover: roundToTwo(decider, (+obj1.twillCover || 0) + val.twillCover),
        owillCover: roundToTwo(decider, (+obj1.owillCover || 0) + val.owillCover),
        overAllWillCover: roundToTwo(decider, (+obj1.overAllWillCover || 0) + val.overAllWillCover),
        overAllCoverage: roundToTwo(decider, (+obj1.overAllCoverage || 0) + val.overAllCoverage),
        overAllCoveragePercent: roundToTwo(decider, ((+obj1.overAllCoveragePercent || 0) + overAllCoveragePercentWeighted)/((+obj1.overAllCoverage || 0) + val.overAllCoverage)),
        newCoverage: roundToTwo(decider, (+obj1.newCoverage || 0) + val.newCoverage),
        newCoveragePercent: null,
        comments: '',
        targetCoveragePercentMin: null,
        targetCoveragePercentMax: null,
        stargetPercentMin: roundToTwo(decider, ((obj1.stargetPercentMin || 0) + supplierPercentMin1)/((+obj1.updatedFcst || 0) + val.updatedFcst)),
        stargetPercentMax: roundToTwo(decider, ((obj1.stargetPercentMax || 0) + supplierPercentMax1)/((+obj1.updatedFcst || 0) + val.updatedFcst)),
        ttargetPercentMin: roundToTwo(decider, ((obj1.ttargetPercentMin || 0) + treasuryPercentMin1)/((+obj1.updatedFcst || 0) + val.updatedFcst)),
        ttargetPercentMax: roundToTwo(decider, ((obj1.ttargetPercentMax || 0) + treasuryPercentMax1)/((+obj1.updatedFcst || 0) + val.updatedFcst)),
      };
      const coveragePercent2 = (data2[key].coverage && data2[key].volumeLE && ((data2[key].coverage/data2[key].volumeLE)*100))*data2[key].coverage;
      const supplierPercentMin2 = data2[key].updatedFcst * data2[key].stargetPercentMin;
      const supplierPercentMax2 = data2[key].updatedFcst * data2[key].stargetPercentMax;
      const targetMin2 = data2[key].updatedFcst * data2[key].targetPercentMin;
      const targetMax2 = data2[key].updatedFcst * data2[key].targetPercentMax;
      const treasuryPercentMin2 = data2[key].updatedFcst * data2[key].ttargetPercentMin;
      const treasuryPercentMax2 = data2[key].updatedFcst * data2[key].ttargetPercentMax;
      const overAllCoveragePercentWeighted2 = (data2[key].overAllCoverage * data2[key].overAllCoveragePercent);
      obj2 = {
        ...obj2,
        market: '',
        period: 'FY Total',
        planYear: obj2.planYear,
        volumeLE: roundToTwo(decider, (+obj2.volumeLE || 0) + data2[key].volumeLE),
        coverage: roundToTwo(decider, (+obj2.coverage || 0) + data2[key].coverage),
        sLastMonthCoverage: roundToTwo(decider, (+obj2.sLastMonthCoverage || 0) + data2[key].sLastMonthCoverage),
        tLastMonthCoverage: roundToTwo(decider, (+obj2.tLastMonthCoverage || 0) + data2[key].tLastMonthCoverage),
        oLastMonthCoverage: roundToTwo(decider, (+obj2.oLastMonthCoverage || 0) + data2[key].oLastMonthCoverage),
        coveragePercent: roundToTwo(decider, ((+obj2.coveragePercent || 0) + coveragePercent2)/((+obj2.coverage || 0) + data2[key].coverage)),
        updatedFcst: roundToTwo(decider, (+obj2.updatedFcst || 0) + data2[key].updatedFcst),
        targetPercentMin: roundToTwo(decider, ((+obj2.targetPercentMin || 0) + targetMin2)/((+obj2.updatedFcst || 0) + data2[key].updatedFcst)),
        targetPercentMax: roundToTwo(decider, ((+obj2.targetPercentMax || 0) + targetMax2)/((+obj2.updatedFcst || 0) + data2[key].updatedFcst)),
        minCoverage: roundToTwo(decider, (+obj2.minCoverage || 0) + data2[key].minCoverage),
        maxCoverage: roundToTwo(decider, (+obj2.maxCoverage || 0) + data2[key].maxCoverage),
        tminCoverage: roundToTwo(decider, (+obj2.tminCoverage || 0) + data2[key].tminCoverage),
        tmaxCoverage: roundToTwo(decider, (+obj2.tmaxCoverage || 0) + data2[key].tmaxCoverage),
        sminCoverage: roundToTwo(decider, (+obj2.sminCoverage || 0) + data2[key].sminCoverage),
        smaxCoverage: roundToTwo(decider, (+obj2.smaxCoverage || 0) + data2[key].smaxCoverage),
        smustCover: roundToTwo(decider, (+obj2.smustCover || 0) + data2[key].smustCover),
        smayCover: roundToTwo(decider, (+obj2.smayCover || 0) + data2[key].smayCover),
        swillCover: roundToTwo(decider, (+obj2.swillCover || 0) + data2[key].swillCover),
        tmustCover: roundToTwo(decider, (+obj2.tmustCover || 0) + data2[key].tmustCover),
        tmayCover: roundToTwo(decider, (+obj2.tmayCover || 0) + data2[key].tmayCover),
        twillCover: roundToTwo(decider, (+obj2.twillCover || 0) + data2[key].twillCover),
        owillCover: roundToTwo(decider, (+obj2.owillCover || 0) + data2[key].owillCover),
        overAllWillCover: roundToTwo(decider, (+obj2.overAllWillCover || 0) + data2[key].overAllWillCover),
        overAllCoverage: roundToTwo(decider, (+obj2.overAllCoverage || 0) + data2[key].overAllCoverage),
        overAllCoveragePercent: roundToTwo(decider, ((+obj2.overAllCoveragePercent || 0) + overAllCoveragePercentWeighted2)/((+obj2.overAllCoverage || 0) + data2[key].overAllCoverage)),
        newCoverage: roundToTwo(decider, (+obj2.newCoverage || 0) + data2[key].newCoverage),
        newCoveragePercent: null,
        comments: '',
        targetCoveragePercentMin: null,
        targetCoveragePercentMax: null,
        stargetPercentMin: roundToTwo(decider, (((obj2.stargetPercentMin) || 0) + supplierPercentMin2)/((+obj2.updatedFcst || 0) + data2[key].updatedFcst)),
        stargetPercentMax: roundToTwo(decider, (((obj2.stargetPercentMax) || 0) + supplierPercentMax2)/((+obj2.updatedFcst || 0) + data2[key].updatedFcst)),
        ttargetPercentMin: roundToTwo(decider, (((obj2.ttargetPercentMin) || 0) + treasuryPercentMin2)/((+obj2.updatedFcst || 0) + data2[key].updatedFcst)),
        ttargetPercentMax: roundToTwo(decider, (((obj2.ttargetPercentMax) || 0) + treasuryPercentMax2)/((+obj2.updatedFcst || 0) + data2[key].updatedFcst)),
      };

      const coveragePercent3 = (data3[key].coverage && data3[key].volumeLE && ((data3[key].coverage/data3[key].volumeLE)*100))*data3[key].coverage;
      const supplierPercentMin3 = (data3[key].stargetPercentMin && data3[key].updatedFcst && data3[key].updatedFcst * data3[key].stargetPercentMin) || 0;
      const supplierPercentMax3 = (data3[key].updatedFcst && data3[key].stargetPercentMax && data3[key].updatedFcst * data3[key].stargetPercentMax) || 0;
      const targetMin3 = (data3[key].updatedFcst && data3[key].targetPercentMin && data3[key].updatedFcst * data3[key].targetPercentMin) || 0;
      const targetMax3 = (data3[key].updatedFcst && data3[key].targetPercentMax && data3[key].updatedFcst * data3[key].targetPercentMax) || 0;
      const treasuryPercentMin3 = (data3[key].updatedFcst && data3[key].ttargetPercentMin && data3[key].updatedFcst * data3[key].ttargetPercentMin) || 0;
      const treasuryPercentMax3 = (data3[key].updatedFcst && data3[key].ttargetPercentMax && data3[key].updatedFcst * data3[key].ttargetPercentMax) || 0;
      const overAllCoveragePercentWeighted3 = (data3[key].overAllCoverage * data3[key].overAllCoveragePercent);

      obj3 = {
        ...obj3,
        market: '',
        period: 'FY Total',
        planYear: obj3.planYear,
        volumeLE: roundToTwo(decider, (+obj3.volumeLE || 0) + data3[key].volumeLE),
        coverage: roundToTwo(decider, (+obj3.coverage || 0) + data3[key].coverage),
        sLastMonthCoverage: (+obj3.sLastMonthCoverage || 0) + data3[key].sLastMonthCoverage,
        tLastMonthCoverage: (+obj3.tLastMonthCoverage || 0) + data3[key].tLastMonthCoverage,
        oLastMonthCoverage: (+obj3.oLastMonthCoverage || 0) + data3[key].oLastMonthCoverage,
        coveragePercent: roundToTwo(decider, ((+obj3.coveragePercent || 0) + coveragePercent3)/((+obj3.coverage || 0) + data3[key].coverage)),
        updatedFcst: (+obj3.updatedFcst || 0) + data3[key].updatedFcst,
        targetPercentMin: roundToTwo(decider, ((+obj3.targetPercentMin || 0) + targetMin3)/((+obj3.updatedFcst || 0) + data3[key].updatedFcst)),
        targetPercentMax: roundToTwo(decider, ((+obj3.targetPercentMax || 0) + targetMax3)/((+obj3.updatedFcst || 0) + data3[key].updatedFcst)),
        minCoverage: roundToTwo(decider, (+obj3.minCoverage || 0) + data3[key].minCoverage),
        maxCoverage: roundToTwo(decider, (+obj3.maxCoverage || 0) + data3[key].maxCoverage),
        tminCoverage: roundToTwo(decider, (+obj3.tminCoverage || 0) + data3[key].tminCoverage),
        tmaxCoverage: roundToTwo(decider, (+obj3.tmaxCoverage || 0) + data3[key].tmaxCoverage),
        sminCoverage: roundToTwo(decider, (+obj3.sminCoverage || 0) + data3[key].sminCoverage),
        smaxCoverage: roundToTwo(decider, (+obj3.smaxCoverage || 0) + data3[key].smaxCoverage),
        smustCover: roundToTwo(decider, (+obj3.smustCover || 0) + data3[key].smustCover),
        smayCover: roundToTwo(decider, (+obj3.smayCover || 0) + data3[key].smayCover),
        swillCover: roundToTwo(decider, (+obj3.swillCover || 0) + data3[key].swillCover),
        tmustCover: roundToTwo(decider, (+obj3.tmustCover || 0) + data3[key].tmustCover),
        tmayCover: roundToTwo(decider, (+obj3.tmayCover || 0) + data3[key].tmayCover),
        twillCover: roundToTwo(decider, (+obj3.twillCover || 0) + data3[key].twillCover),
        owillCover: roundToTwo(decider, (+obj3.owillCover || 0) + data3[key].owillCover),
        overAllWillCover: roundToTwo(decider, (+obj3.overAllWillCover || 0) + data3[key].overAllWillCover),
        overAllCoverage: roundToTwo(decider, (+obj3.overAllCoverage || 0) + data3[key].overAllCoverage),
        overAllCoveragePercent: roundToTwo(decider, ((+obj3.overAllCoveragePercent || 0) + overAllCoveragePercentWeighted3)/((+obj3.overAllCoverage || 0) + data3[key].overAllCoverage)),
        newCoverage: roundToTwo(decider, (+obj3.newCoverage || 0) + data3[key].newCoverage),
        newCoveragePercent: null,
        comments: '',
        targetCoveragePercentMin: null,
        targetCoveragePercentMax: null,
        stargetPercentMin: roundToTwo(decider, (((obj3.stargetPercentMin) || 0) + supplierPercentMin3)/((+obj3.updatedFcst || 0) + data3[key].updatedFcst)),
        stargetPercentMax: roundToTwo(decider, (((obj3.stargetPercentMax) || 0) + supplierPercentMax3)/((+obj3.updatedFcst || 0) + data3[key].updatedFcst)),
        ttargetPercentMin: roundToTwo(decider, (((obj3.ttargetPercentMin) || 0) + treasuryPercentMin3)/((+obj3.updatedFcst || 0) + data3[key].updatedFcst)),
        ttargetPercentMax: roundToTwo(decider, (((obj3.ttargetPercentMax) || 0) + treasuryPercentMax3)/((+obj3.updatedFcst || 0) + data3[key].updatedFcst)),
      };
    } else {
      const coveragePercent1 = (val.coverage && val.volumeLE && ((val.coverage/val.volumeLE)*100))*val.coverage;
      const supplierPercentMin1 = (val.updatedFcst && val.stargetPercentMin && val.updatedFcst * val.stargetPercentMin) || 0;
      const supplierPercentMax1 = (val.updatedFcst && val.stargetPercentMax && val.updatedFcst * val.stargetPercentMax) || 0;
      const targetMin1 = (val.updatedFcst && val.targetPercentMin && val.updatedFcst * val.targetPercentMin) || 0;
      const targetMax1 = (val.updatedFcst && val.targetPercentMax && val.updatedFcst * val.targetPercentMax) || 0;
      const treasuryPercentMin1 = (val.updatedFcst && val.ttargetPercentMin && val.updatedFcst * val.ttargetPercentMin) || 0;
      const treasuryPercentMax1 = (val.updatedFcst && val.ttargetPercentMax && val.updatedFcst * val.ttargetPercentMax) || 0;
      const overAllCoveragePercentWeighted = (val.overAllCoverage * val.overAllCoveragePercent);
      obj1 = {
        ...obj1,
        period: 'FY Total',
        market: '',
        planYear: obj1.planYear,
        volumeLE: (+obj1.volumeLE || 0) + val.volumeLE,
        coverage: (+obj1.coverage || 0) + val.coverage,
        sLastMonthCoverage: (+obj1.sLastMonthCoverage || 0) + val.sLastMonthCoverage,
        tLastMonthCoverage: (+obj1.tLastMonthCoverage || 0) + val.tLastMonthCoverage,
        oLastMonthCoverage: (+obj1.oLastMonthCoverage || 0) + val.oLastMonthCoverage,
        coveragePercent: obj1.coveragePercent + coveragePercent1,
        updatedFcst: (+obj1.updatedFcst || 0) + val.updatedFcst,
        targetPercentMin: (+obj1.targetPercentMin || 0) + targetMin1,
        targetPercentMax: (+obj1.targetPercentMax || 0) + targetMax1,
        minCoverage: (+obj1.minCoverage || 0) + val.minCoverage,
        maxCoverage: (+obj1.maxCoverage || 0) + val.maxCoverage,
        mustCover: roundToTwo(decider, (+obj1.mustCover || 0) + val.mustCover),
        mayCover: roundToTwo(decider, (+obj1.mayCover || 0) + val.mayCover),
        tminCoverage: ((+obj1.tminCoverage || 0) + val.tminCoverage),
        tmaxCoverage: ((+obj1.tmaxCoverage || 0) + val.tmaxCoverage),
        sminCoverage: ((+obj1.sminCoverage || 0) + val.sminCoverage),
        smaxCoverage: ((+obj1.smaxCoverage || 0) + val.smaxCoverage),
        smustCover: (+obj1.smustCover || 0) + val.smustCover,
        smayCover: (+obj1.smayCover || 0) + val.smayCover,
        swillCover: (+obj1.swillCover || 0) + val.swillCover,
        tmustCover: (+obj1.tmustCover || 0) + val.tmustCover,
        tmayCover: (+obj1.tmayCover || 0) + val.tmayCover,
        twillCover: (+obj1.twillCover || 0) + val.twillCover,
        owillCover: (+obj1.owillCover || 0) + val.owillCover,
        overAllWillCover: (+obj1.overAllWillCover || 0) + val.overAllWillCover,
        overAllCoverage: (+obj1.overAllCoverage || 0) + val.overAllCoverage,
        overAllCoveragePercent: ((+obj1.overAllCoveragePercent || 0) + overAllCoveragePercentWeighted),
        newCoverage: (+obj1.newCoverage || 0) + val.newCoverage,
        newCoveragePercent: ((+obj1.coverage || 0) + val.coverage) / 4,
        comments: '',
        targetCoveragePercentMin: ((+obj1.targetCoveragePercentMin || 0) + val.targetCoveragePercentMin) / 4,
        targetCoveragePercentMax: ((+obj1.targetCoveragePercentMax || 0) + val.targetCoveragePercentMax) / 4,
        stargetPercentMin: (obj1.stargetPercentMin || 0) + supplierPercentMin1,
        stargetPercentMax: (obj1.stargetPercentMax || 0) + supplierPercentMax1,
        ttargetPercentMin: (obj1.ttargetPercentMin || 0) + treasuryPercentMin1,
        ttargetPercentMax: (obj1.ttargetPercentMax || 0) + treasuryPercentMax1,
      };
      const coveragePercent2 = (data2[key].coverage && data2[key].volumeLE && ((data2[key].coverage/data2[key].volumeLE)*100))*data2[key].coverage;
      const supplierPercentMin2 = data2[key].updatedFcst * data2[key].stargetPercentMin;
      const supplierPercentMax2 = data2[key].updatedFcst * data2[key].stargetPercentMax;
      const targetMin2 = data2[key].updatedFcst * data2[key].targetPercentMin;
      const targetMax2 = data2[key].updatedFcst * data2[key].targetPercentMax;
      const treasuryPercentMin2 = data2[key].updatedFcst * data2[key].ttargetPercentMin;
      const treasuryPercentMax2 = data2[key].updatedFcst * data2[key].ttargetPercentMax;
      const overAllCoveragePercentWeighted2 = (data2[key].overAllCoverage * data2[key].overAllCoveragePercent);

      obj2 = {
        ...obj2,
        market: '',
        period: 'FY Total',
        planYear: obj2.planYear,
        volumeLE: (+obj2.volumeLE || 0) + data2[key].volumeLE,
        coverage: (+obj2.coverage || 0) + data2[key].coverage,
        sLastMonthCoverage: (+obj2.sLastMonthCoverage || 0) + data2[key].sLastMonthCoverage,
        tLastMonthCoverage: (+obj2.tLastMonthCoverage || 0) + data2[key].tLastMonthCoverage,
        oLastMonthCoverage: (+obj2.oLastMonthCoverage || 0) + data2[key].oLastMonthCoverage,
        coveragePercent: (+obj2.coveragePercent || 0) + coveragePercent2,
        updatedFcst: (+obj2.updatedFcst || 0) + data2[key].updatedFcst,
        targetPercentMin: (+obj2.targetPercentMin || 0) + targetMin2,
        targetPercentMax: (+obj2.targetPercentMax || 0) + targetMax2,
        minCoverage: (+obj2.minCoverage || 0) + data2[key].minCoverage,
        maxCoverage: (+obj2.maxCoverage || 0) + data2[key].maxCoverage,
        mustCover: roundToTwo(decider, (+obj2.mustCover || 0) + data2[key].mustCover),
        mayCover: roundToTwo(decider, (+obj2.mayCover || 0) + data2[key].mayCover),
        tminCoverage: (+obj2.tminCoverage || 0) + data2[key].tminCoverage,
        tmaxCoverage: (+obj2.tmaxCoverage || 0) + data2[key].tmaxCoverage,
        sminCoverage: (+obj2.sminCoverage || 0) + data2[key].sminCoverage,
        smaxCoverage: (+obj2.smaxCoverage || 0) + data2[key].smaxCoverage,
        smustCover: (+obj2.smustCover || 0) + data2[key].smustCover,
        smayCover: (+obj2.smayCover || 0) + data2[key].smayCover,
        swillCover: (+obj2.swillCover || 0) + data2[key].swillCover,
        tmustCover: (+obj2.tmustCover || 0) + data2[key].tmustCover,
        tmayCover: (+obj2.tmayCover || 0) + data2[key].tmayCover,
        twillCover: (+obj2.twillCover || 0) + data2[key].twillCover,
        owillCover: (+obj2.owillCover || 0) + data2[key].owillCover,
        overAllWillCover: (+obj2.overAllWillCover || 0) + data2[key].overAllWillCover,
        overAllCoverage: (+obj2.overAllCoverage || 0) + data2[key].overAllCoverage,
        overAllCoveragePercent: (+obj2.overAllCoveragePercent || 0) + overAllCoveragePercentWeighted2,
        newCoverage: (+obj2.newCoverage || 0) + data2[key].newCoverage,
        newCoveragePercent: ((+obj2.coverage || 0) + data2[key].coverage) / 4,
        comments: '',
        targetCoveragePercentMin: roundToTwo(decider, ((+obj2.targetCoveragePercentMin || 0) + data2[key].targetCoveragePercentMin) / 4),
        targetCoveragePercentMax: roundToTwo(decider, ((+obj2.targetCoveragePercentMax || 0) + data2[key].targetCoveragePercentMax) / 4),
        stargetPercentMin: (obj2.stargetPercentMin || 0) + supplierPercentMin2,
        stargetPercentMax: (obj2.stargetPercentMax || 0) + supplierPercentMax2,
        ttargetPercentMin: (obj2.ttargetPercentMin || 0) + treasuryPercentMin2,
        ttargetPercentMax: (obj2.ttargetPercentMax || 0) + treasuryPercentMax2,
      };

      const coveragePercent3 = (data3[key].coverage && data3[key].volumeLE && ((data3[key].coverage/data3[key].volumeLE)*100))*data3[key].coverage;
      const supplierPercentMin3 = (data3[key].stargetPercentMin && data3[key].updatedFcst && data3[key].updatedFcst * data3[key].stargetPercentMin) || 0;
      const supplierPercentMax3 = (data3[key].updatedFcst && data3[key].stargetPercentMax && data3[key].updatedFcst * data3[key].stargetPercentMax) || 0;
      const targetMin3 = (data3[key].updatedFcst && data3[key].targetPercentMin && data3[key].updatedFcst * data3[key].targetPercentMin) || 0;
      const targetMax3 = (data3[key].updatedFcst && data3[key].targetPercentMax && data3[key].updatedFcst * data3[key].targetPercentMax) || 0;
      const treasuryPercentMin3 = (data3[key].updatedFcst && data3[key].ttargetPercentMin && data3[key].updatedFcst * data3[key].ttargetPercentMin) || 0;
      const treasuryPercentMax3 = (data3[key].updatedFcst && data3[key].ttargetPercentMax && data3[key].updatedFcst * data3[key].ttargetPercentMax) || 0;
      const overAllCoveragePercentWeighted3 = (data3[key].overAllCoverage * data3[key].overAllCoveragePercent);

      obj3 = {
        ...obj3,
        market: '',
        period: 'FY Total',
        planYear: obj3.planYear,
        volumeLE: (+obj3.volumeLE || 0) + data3[key].volumeLE,
        coverage: (+obj3.coverage || 0) + data3[key].coverage,
        sLastMonthCoverage: (+obj3.sLastMonthCoverage || 0) + data3[key].sLastMonthCoverage,
        tLastMonthCoverage: (+obj3.tLastMonthCoverage || 0) + data3[key].tLastMonthCoverage,
        oLastMonthCoverage: (+obj3.oLastMonthCoverage || 0) + data3[key].oLastMonthCoverage,
        coveragePercent: (+obj3.coveragePercent || 0) + coveragePercent3,
        updatedFcst: (+obj3.updatedFcst || 0) + data3[key].updatedFcst,
        targetPercentMin: (+obj3.targetPercentMin || 0) + targetMin3,
        targetPercentMax: (+obj3.targetPercentMax || 0) + targetMax3,
        minCoverage: (+obj3.minCoverage || 0) + data3[key].minCoverage,
        maxCoverage: (+obj3.maxCoverage || 0) + data3[key].maxCoverage,
        mustCover: roundToTwo(decider, (+obj3.mustCover || 0) + data3[key].mustCover),
        mayCover: roundToTwo(decider, (+obj3.mayCover || 0) + data3[key].mayCover),
        tminCoverage: (+obj3.tminCoverage || 0) + data3[key].tminCoverage,
        tmaxCoverage: (+obj3.tmaxCoverage || 0) + data3[key].tmaxCoverage,
        sminCoverage: (+obj3.sminCoverage || 0) + data3[key].sminCoverage,
        smaxCoverage: (+obj3.smaxCoverage || 0) + data3[key].smaxCoverage,
        smustCover: (+obj3.smustCover || 0) + data3[key].smustCover,
        smayCover: (+obj3.smayCover || 0) + data3[key].smayCover,
        swillCover: (+obj3.swillCover || 0) + data3[key].swillCover,
        tmustCover: (+obj3.tmustCover || 0) + data3[key].tmustCover,
        tmayCover: (+obj3.tmayCover || 0) + data3[key].tmayCover,
        twillCover: (+obj3.twillCover || 0) + data3[key].twillCover,
        owillCover: (+obj3.owillCover || 0) + data3[key].owillCover,
        overAllWillCover: (+obj3.overAllWillCover || 0) + data3[key].overAllWillCover,
        overAllCoverage: (+obj3.overAllCoverage || 0) + data3[key].overAllCoverage,
        overAllCoveragePercent: (+obj3.overAllCoveragePercent || 0) + overAllCoveragePercentWeighted3,
        newCoverage: (+obj3.newCoverage || 0) + data3[key].newCoverage,
        newCoveragePercent: roundToTwo(decider, ((+obj3.coverage || 0) + data3[key].coverage) / 4),
        comments: '',
        targetCoveragePercentMin: roundToTwo(decider, ((+obj3.targetCoveragePercentMin || 0) + val.targetCoveragePercentMin) / 4),
        targetCoveragePercentMax: roundToTwo(decider, ((+obj3.targetCoveragePercentMax || 0) + val.targetCoveragePercentMax) / 4),
        stargetPercentMin: (obj3.stargetPercentMin || 0) + supplierPercentMin3,
        stargetPercentMax: (obj3.stargetPercentMax || 0) + supplierPercentMax3,
        ttargetPercentMin: (obj3.ttargetPercentMin || 0) + treasuryPercentMin3,
        ttargetPercentMax: (obj3.ttargetPercentMax || 0) + treasuryPercentMax3,
      };
    }
    if ((key+1) % 3 == 0 && key != 0) {
      if (data1[key].targetVariancePercent >= 2.5) {
        data1[key-1].tVariance = data1[key].targetVariancePercent;
        data1[key-2].tVariance = data1[key].targetVariancePercent;
        data1[key].tVariance = data1[key].targetVariancePercent;
      }
      if (data2[key].targetVariancePercent >= 2.5) {
        data2[key-1].tVariance = data2[key].targetVariancePercent;
        data2[key-2].tVariance = data2[key].targetVariancePercent;
        data2[key].tVariance = data2[key].targetVariancePercent;
      }
      if (data3[key].targetVariancePercent >= 2.5) {
        data3[key-1].tVariance = data3[key].targetVariancePercent;
        data3[key-2].tVariance = data3[key].targetVariancePercent;
        data3[key].tVariance = data3[key].targetVariancePercent;
      }
    }
  });
  let grandTotalObj = {
    market: '',
    period: '',
    planYear: '',
    volumeLE: 0,
    coverage: 0,
    sLastMonthCoverage: 0,
    tLastMonthCoverage: 0,
    oLastMonthCoverage: 0,
    coveragePercent: 0,
    updatedFcst: 0,
    targetPercentMin: 0,
    targetPercentMax: 0,
    minCoverage: 0,
    maxCoverage: 0,
    tminCoverage: 0,
    tmaxCoverage: 0,
    sminCoverage: 0,
    smaxCoverage: 0,
    mayCover: 0,
    mustCover: 0,
    smustCover: 0,
    smayCover: 0,
    swillCover: 0,
    stargetPercentMin: 0,
    stargetPercentMax: 0,
    tmustCover: 0,
    tmayCover: 0,
    twillCover: 0,
    owillCover: 0,
    ttargetPercentMin: 0,
    ttargetPercentMax: 0,
    overAllWillCover: 0,
    overAllCoverage: 0,
    overAllCoveragePercent: 0,
    newCoveragePercent: 0,
    comments: 0,
    targetCoveragePercent: 0,
  };
  const newArr = [...data1, obj1, ...data2, obj2, ...data3, obj3];
  // Added new row
  for (let i=0; i<39; i++) {
    if (i !== 12 && i !== 25 && i !== 38) {
      if (i!==newArr.length-1) {
        grandTotalObj = {
          period: '',
          volumeLE: roundToTwo(decider, +grandTotalObj.volumeLE+(+newArr[i].volumeLE||0)),
          coverage: roundToTwo(decider, +grandTotalObj.coverage+(+newArr[i].coverage||0)),
          sLastMonthCoverage: roundToTwo(decider, +grandTotalObj.sLastMonthCoverage+(+newArr[i].sLastMonthCoverage || 0)),
          tLastMonthCoverage: roundToTwo(decider, +grandTotalObj.tLastMonthCoverage+(+newArr[i].tLastMonthCoverage || 0)),
          oLastMonthCoverage: roundToTwo(decider, +grandTotalObj.oLastMonthCoverage+(+newArr[i].oLastMonthCoverage || 0)),
          // coveragePercent: roundToTwo(decider, +grandTotalObj.coveragePercent+(+newArr[i].coveragePercent || 0)),
          updatedFcst: roundToTwo(decider, +grandTotalObj.updatedFcst+(+newArr[i].updatedFcst || 0)),
          targetPercentMin: roundToTwo(decider, +grandTotalObj.targetPercentMin+(+newArr[i].targetPercentMin*+newArr[i].updatedFcst)),
          targetPercentMax: roundToTwo(decider, +grandTotalObj.targetPercentMax+(+newArr[i].targetPercentMax*+newArr[i].updatedFcst)),
          minCoverage: roundToTwo(decider, +grandTotalObj.minCoverage+(+newArr[i].minCoverage || 0)),
          maxCoverage: roundToTwo(decider, +grandTotalObj.maxCoverage+(+newArr[i].maxCoverage || 0)),
          tminCoverage: roundToTwo(decider, +grandTotalObj.tminCoverage+(+newArr[i].tminCoverage || 0)),
          tmaxCoverage: roundToTwo(decider, +grandTotalObj.tmaxCoverage+(+newArr[i].tmaxCoverage || 0)),
          sminCoverage: roundToTwo(decider, +grandTotalObj.sminCoverage+(+newArr[i].sminCoverage || 0)),
          smaxCoverage: roundToTwo(decider, +grandTotalObj.smaxCoverage+(+newArr[i].smaxCoverage || 0)),
          mayCover: roundToTwo(decider, +grandTotalObj.mayCover+(+newArr[i].mayCover || 0)),
          mustCover: roundToTwo(decider, +grandTotalObj.mustCover+(+newArr[i].mustCover || 0)),
          smustCover: roundToTwo(decider, +grandTotalObj.smustCover+(+newArr[i].smustCover || 0)),
          smayCover: roundToTwo(decider, +grandTotalObj.smayCover+(+newArr[i].smayCover || 0)),
          swillCover: roundToTwo(decider, +grandTotalObj.swillCover+(+newArr[i].swillCover || 0)),
          stargetPercentMin: roundToTwo(decider, +grandTotalObj.stargetPercentMin+(+newArr[i].stargetPercentMin*+newArr[i].updatedFcst)),
          stargetPercentMax: roundToTwo(decider, +grandTotalObj.stargetPercentMax+(+newArr[i].stargetPercentMax*+newArr[i].updatedFcst)),
          tmustCover: roundToTwo(decider, +grandTotalObj.tmustCover+(+newArr[i].tmustCover || 0)),
          tmayCover: roundToTwo(decider, +grandTotalObj.tmayCover+(+newArr[i].tmayCover || 0)),
          twillCover: roundToTwo(decider, +grandTotalObj.twillCover+(+newArr[i].twillCover || 0)),
          owillCover: roundToTwo(decider, +grandTotalObj.owillCover+(+newArr[i].owillCover || 0)),
          ttargetPercentMin: roundToTwo(decider, +grandTotalObj.ttargetPercentMin+(+newArr[i].ttargetPercentMin*+newArr[i].updatedFcst)),
          ttargetPercentMax: roundToTwo(decider, +grandTotalObj.ttargetPercentMax+(+newArr[i].ttargetPercentMax*+newArr[i].updatedFcst)),
          overAllWillCover: roundToTwo(decider, +grandTotalObj.overAllWillCover+(+newArr[i].overAllWillCover || 0)),
          overAllCoverage: roundToTwo(decider, +grandTotalObj.overAllCoverage+(+newArr[i].overAllCoverage || 0)),
          // overAllCoveragePercent: roundToTwo(decider, +grandTotalObj.ttargetPercentMin+(+newArr[i].ttargetPercentMin*+newArr[i].updatedFcst)),
        };
      }
    }
  }
  // newArr[38]['volumeLE']!==0 ? roundToTwo(decider, (newArr[38]['coverage']/newArr[38]['volumeLE'])*100) : 0;
  newArr[39] = {...grandTotalObj,
    coveragePercent: grandTotalObj['volumeLE']!==0 ? roundToTwo(decider, (grandTotalObj['coverage']/grandTotalObj['volumeLE'])*100) : 0,
    targetPercentMin: grandTotalObj['updatedFcst']!==0 ? roundToTwo(decider, (grandTotalObj['targetPercentMin']/grandTotalObj['updatedFcst'])) : 0,
    targetPercentMax: grandTotalObj['updatedFcst']!==0 ? roundToTwo(decider, (grandTotalObj['targetPercentMax']/grandTotalObj['updatedFcst'])) : 0,
    stargetPercentMin: grandTotalObj['updatedFcst']!==0 ? roundToTwo(decider, (grandTotalObj['stargetPercentMin']/grandTotalObj['updatedFcst'])) : 0,
    stargetPercentMax: grandTotalObj['updatedFcst']!==0 ? roundToTwo(decider, (grandTotalObj['stargetPercentMax']/grandTotalObj['updatedFcst'])) : 0,
    ttargetPercentMin: grandTotalObj['updatedFcst']!==0 ? roundToTwo(decider, (grandTotalObj['ttargetPercentMin']/grandTotalObj['updatedFcst'])) : 0,
    ttargetPercentMax: grandTotalObj['updatedFcst']!==0 ? roundToTwo(decider, (grandTotalObj['ttargetPercentMax']/grandTotalObj['updatedFcst'])) : 0,
    overAllCoveragePercent: grandTotalObj['updatedFcst']!==0 ? roundToTwo(decider, (grandTotalObj['overAllCoverage']/grandTotalObj['updatedFcst'])*100) : 0,
  };
  return {[years[0]]: [...data1, obj1], [years[1]]: [...data2, obj2], [years[2]]: [...data3, obj3, newArr[39]]};
};

const dataAssigner = async (data1, data2, data3, years, wfID, prevWFID, coveragesData, prevData, targets, selectedRow, plannedCoverages, decider) => {
  let totalUpdatedForecast1 = 0;
  let totalUpdatedForecast2 = 0;
  let totalUpdatedForecast3 = 0;
  let totalOverAllCoverage1 = 0;
  let totalOverAllCoverage2 = 0;
  let totalOverAllCoverage3 = 0;
  let targetCoveragePercentMin1 = 0;
  let targetCoveragePercentMin2 = 0;
  let targetCoveragePercentMin3 = 0;
  let targetCoveragePercentMax1 = 0;
  let targetCoveragePercentMax2 = 0;
  let targetCoveragePercentMax3 = 0;
  // As we needed this for 12 months in all three years
  for (let i = 0; i < months.length; i++) {
    let obj1 = JSON.parse(JSON.stringify(defaultObj));
    let obj2 = JSON.parse(JSON.stringify(defaultObj));
    let obj3 = JSON.parse(JSON.stringify(defaultObj));
    const first = prevData[years[0]];
    const second = prevData[years[1]];
    const third = prevData[years[2]];
    const m = months[i];
    // new Promise((resolve, reject) => {
    // Sometimes due to wrong data we are getting more than 3 years it will affect the toggles in forecast update
    years.slice(0, 3).forEach((y, index) => {
      // console.log(coveragesData, y, coveragesData[y]);
      const a = 'obj';
      if (coveragesData[y].supplier[m]) {
        eval(a + (index + 1)).sLastMonthCoverage = coveragesData[y].supplier[m].total;
        eval(a + (index + 1))['workflowId'] = coveragesData[y].supplier[m]?.workflowId;
        eval(a + (index + 1))['coverageYearMonth'] = coveragesData[y].supplier[m].coverageYearMonth;
      } else {
        eval(a + (index + 1)).sLastMonthCoverage = 0;
      }
      if (coveragesData[y].treasury[m]) {
        eval(a + (index + 1)).tLastMonthCoverage = coveragesData[y].treasury[m].total;
        eval(a + (index + 1))['workflowId'] = coveragesData[y].treasury[m].workflowId;
        eval(a + (index + 1))['coverageYearMonth'] = coveragesData[y].treasury[m].coverageYearMonth;
      } else {
        eval(a + (index + 1)).tLastMonthCoverage = 0;
      }
      if (coveragesData[y].other[m]) {
        eval(a + (index + 1)).oLastMonthCoverage = coveragesData[y].other[m].total;
        eval(a + (index + 1))['workflowId'] = coveragesData[y].other[m].workflowId;
        eval(a + (index + 1))['coverageYearMonth'] = coveragesData[y].other[m].coverageYearMonth;
      } else {
        eval(a + (index + 1)).oLastMonthCoverage = 0;
      }
      if (coveragesData[y].plannedCoverages && coveragesData[y].plannedCoverages[m]) {
        eval(a + (index + 1)).plannedCoverages = coveragesData[y].plannedCoverages[m];
      } else {
        eval(a + (index + 1)).plannedCoverages = null;
      }
      // console.log(eval(a + (index + 1)).coverage-eval(a + (index + 1)).plannedCoverages);
      eval(a + (index + 1)).coverage = +eval(a + (index + 1)).tLastMonthCoverage + +eval(a + (index + 1)).sLastMonthCoverage + ((coveragesData[y] && coveragesData[y].other && coveragesData[y].other[m]?.total) || 0);
      if (plannedCoverages.length==0) {
        eval(a + (index + 1)).isPrevAndActualCovSame = false;
      } else {
        eval(a + (index + 1)).isPrevAndActualCovSame = roundToTwo(decider, eval(a + (index + 1)).coverage) - roundToTwo(decider, eval(a + (index + 1)).plannedCoverages);
      }
    });
    obj1 = {
      ...obj1,
      planYear: years[0],
      volumeLE: !Array.isArray(first) ? (first[prevWFID] ? first[prevWFID][m] : 0) : first[0][m],
      updatedFcst: !Array.isArray(first) ? (first[wfID] ? first[wfID][m] : 0) : first[1][m],
      period: titleCase(m),
      workFlowId: selectedRow.workFlowId,
    };
    obj1.coveragePercent = obj1.coverage && obj1.volumeLE && roundToTwo(decider, (obj1.coverage/obj1.volumeLE)*100) || 0;

    obj2 = {
      ...obj2,
      planYear: years[1],
      volumeLE: !Array.isArray(second) ? (second[prevWFID] ? second[prevWFID][m] : 0) : second[0][m],
      updatedFcst: !Array.isArray(second) ? (second[wfID] ? second[wfID][m] : 0) : second[1][m],
      period: titleCase(m),
      workFlowId: selectedRow.workFlowId,
    };
    obj2.coveragePercent = obj2.coverage && obj2.volumeLE && roundToTwo(decider, (obj2.coverage/obj2.volumeLE)*100);

    obj3 = {
      ...obj3,
      planYear: years[2],
      volumeLE: !Array.isArray(third) ? (third[prevWFID] ? third[prevWFID][m] : 0) : third[0][m],
      updatedFcst: !Array.isArray(third) ? (third[wfID] ? third[wfID][m] : 0) : third[1][m],
      period: titleCase(m),
      workFlowId: selectedRow.workFlowId,
    };
    obj3.coveragePercent = obj3.coverage && obj3.volumeLE && roundToTwo(decider, (obj3.coverage/obj3.volumeLE)*100);
    if (targets && Object.keys(targets).length) {
      obj1 = await targetSetter(years[0], targets, obj1, i, decider);
      obj2 = await targetSetter(years[1], targets, obj2, i, decider);
      obj3 = await targetSetter(years[2], targets, obj3, i, decider);
    }
    if (quarterMonths.indexOf(m) > -1) {
      const weightedAverageMin1 = obj1.updatedFcst * obj1.targetPercentMin;
      const weightedAverageMax1 = obj1.updatedFcst * obj1.targetPercentMax;
      const weightedAverageMin2 = obj2.updatedFcst * obj2.targetPercentMin;
      const weightedAverageMax2 = obj2.updatedFcst * obj2.targetPercentMax;
      const weightedAverageMin3 = obj3.updatedFcst * obj3.targetPercentMin;
      const weightedAverageMax3 = obj3.updatedFcst * obj3.targetPercentMax;
      totalUpdatedForecast1 = (+totalUpdatedForecast1 || 0) + (+obj1.updatedFcst || 0);
      totalUpdatedForecast2 = (+totalUpdatedForecast2 || 0) + (+obj2.updatedFcst || 0);
      totalUpdatedForecast3 = (+totalUpdatedForecast3 || 0) + (+obj3.updatedFcst || 0);
      totalOverAllCoverage1 = (+totalOverAllCoverage1 || 0) + (+obj1.overAllCoverage || 0);
      totalOverAllCoverage2 = (+totalOverAllCoverage2 || 0) + (+obj2.overAllCoverage || 0);
      totalOverAllCoverage3 = (+totalOverAllCoverage3 || 0) + (+obj3.overAllCoverage || 0);
      targetCoveragePercentMin1 = +targetCoveragePercentMin1 + weightedAverageMin1;
      targetCoveragePercentMin2 = +targetCoveragePercentMin2 + weightedAverageMin2;
      targetCoveragePercentMin3 = +targetCoveragePercentMin3 + weightedAverageMin3;
      targetCoveragePercentMax1 = +targetCoveragePercentMax1 + weightedAverageMax1;
      targetCoveragePercentMax2 = +targetCoveragePercentMax2 + weightedAverageMax2;
      targetCoveragePercentMax3 = +targetCoveragePercentMax3 + weightedAverageMax3;

      if (totalOverAllCoverage1 && totalUpdatedForecast1) {
        obj1 = {
          ...obj1,
          newCoveragePercent: roundToTwo(decider, (totalOverAllCoverage1 / (totalUpdatedForecast1)) * 100),
          targetCoveragePercentMin: roundToTwo(decider, targetCoveragePercentMin1/totalUpdatedForecast1),
          targetCoveragePercentMax: roundToTwo(decider, targetCoveragePercentMax1/totalUpdatedForecast1),
          targetVariancePercent: roundToTwo(decider, Math.abs((totalOverAllCoverage1 / ((totalUpdatedForecast1 * 100))) - roundToTwo(decider, targetCoveragePercentMin1/totalUpdatedForecast1))),
        };
      } else {
        obj1 = {
          ...obj1,
          newCoveragePercent: 0,
          targetCoveragePercentMin: roundToTwo(decider, targetCoveragePercentMin1/totalUpdatedForecast1),
          targetCoveragePercentMax: roundToTwo(decider, targetCoveragePercentMax1/totalUpdatedForecast1),
          targetVariancePercent: roundToTwo(decider, Math.abs(0 - roundToTwo(decider, targetCoveragePercentMin1/totalUpdatedForecast1))),
        };
      }
      if (totalOverAllCoverage2 && totalUpdatedForecast2) {
        obj2 = {
          ...obj2,
          newCoveragePercent: roundToTwo(decider, (totalOverAllCoverage2 / (totalUpdatedForecast2)) * 100),
          targetCoveragePercentMin: roundToTwo(decider, targetCoveragePercentMin2/totalUpdatedForecast2),
          targetCoveragePercentMax: roundToTwo(decider, targetCoveragePercentMax2/totalUpdatedForecast2),
          targetVariancePercent: roundToTwo(decider, Math.abs((totalOverAllCoverage2 / ((totalUpdatedForecast2 * 100))) - roundToTwo(decider, targetCoveragePercentMin2/totalUpdatedForecast2))),
        };
      } else {
        obj2 = {
          ...obj2,
          newCoveragePercent: 0,
          targetCoveragePercentMin: roundToTwo(decider, targetCoveragePercentMin2/totalUpdatedForecast2),
          targetCoveragePercentMax: roundToTwo(decider, targetCoveragePercentMax2/totalUpdatedForecast2),
          targetVariancePercent: Math.abs(0 - roundToTwo(decider, targetCoveragePercentMin2/totalUpdatedForecast2)),
        };
      }
      if (totalOverAllCoverage3 && totalUpdatedForecast3) {
        obj3 = {
          ...obj3,
          newCoveragePercent: roundToTwo(decider, ((totalOverAllCoverage3) / (totalUpdatedForecast3)) * 100),
          targetCoveragePercentMin: roundToTwo(decider, targetCoveragePercentMin3/totalUpdatedForecast3),
          targetCoveragePercentMax: roundToTwo(decider, targetCoveragePercentMax3/totalUpdatedForecast3),
          targetVariancePercent: roundToTwo(decider, Math.abs((totalOverAllCoverage3 / ((totalUpdatedForecast3 * 100))) - roundToTwo(decider, targetCoveragePercentMin3/totalUpdatedForecast3))),
        };
      } else {
        obj3 = {
          ...obj3,
          newCoveragePercent: 0,
          targetCoveragePercentMin: roundToTwo(decider, targetCoveragePercentMin3/totalUpdatedForecast3),
          targetCoveragePercentMax: roundToTwo(decider, targetCoveragePercentMax3/totalUpdatedForecast3),
          targetVariancePercent: Math.abs(0 - targetCoveragePercentMin3),
        };
      }
      totalUpdatedForecast1 = 0;
      totalUpdatedForecast2 = 0;
      totalUpdatedForecast3 = 0;
      totalOverAllCoverage1 = 0;
      totalOverAllCoverage2 = 0;
      totalOverAllCoverage3 = 0;
      targetCoveragePercentMin1 = 0;
      targetCoveragePercentMin2 = 0;
      targetCoveragePercentMin3 = 0;
      targetCoveragePercentMax1 = 0;
      targetCoveragePercentMax2 = 0;
      targetCoveragePercentMax3 = 0;
    } else {
      totalUpdatedForecast1 = +totalUpdatedForecast1 + +obj1.updatedFcst;
      totalUpdatedForecast2 = +totalUpdatedForecast2 + +obj2.updatedFcst;
      totalUpdatedForecast3 = +totalUpdatedForecast3 + +obj3.updatedFcst;
      totalOverAllCoverage1 = +totalOverAllCoverage1 + +obj1.overAllCoverage;
      totalOverAllCoverage2 = +totalOverAllCoverage2 + +obj2.overAllCoverage;
      totalOverAllCoverage3 = +totalOverAllCoverage3 + +obj3.overAllCoverage;
      targetCoveragePercentMin1 = +targetCoveragePercentMin1 + +obj1.targetPercentMin;
      targetCoveragePercentMin2 = +targetCoveragePercentMin2 + +obj2.targetPercentMin;
      targetCoveragePercentMin3 = +targetCoveragePercentMin3 + +obj3.targetPercentMin;
      targetCoveragePercentMax1 = +targetCoveragePercentMax1 + +obj1.targetPercentMax;
      targetCoveragePercentMax2 = +targetCoveragePercentMax2 + +obj2.targetPercentMax;
      targetCoveragePercentMax3 = +targetCoveragePercentMax3 + +obj3.targetPercentMax;
    }
    data1 = [...data1, obj1];
    data2 = [...data2, obj2];
    data3 = [...data3, obj3];
  }
  return {data1, data2, data3};
};

export const prevDataEvaluator = async (prevData = {}, selectedRow, targets, coverages = [], plannedCoverages=[], decider) => {
  let data1 = [];
  let data2 = [];
  let data3 = [];
  const years = Object.keys(prevData).map((val) => val);
  const wfID = selectedRow.workFlowId;
  const prevWFID = selectedRow.prevWorkFlowId;
  const coveragesData = await coveragesFormation(coverages, years, plannedCoverages, decider);
  const output = await dataAssigner(data1, data2, data3, years, wfID, prevWFID, coveragesData, prevData, targets, selectedRow, plannedCoverages, decider);
  // console.log('after', output);
  data1 = output.data1;
  data2 = output.data2;
  data3 = output.data3;
  const obj = totalEvalutator(data1, data2, data3, years, decider);
  return obj;
};

export const colorReturn = (rowData) => {
  // console.log(rowData);
  return rowData?.isPrevAndActualCovSame ? 'red' : 'green';
};

export const colorReturn2 = (rowData) => {
  const {tVariance} = rowData;
  if (Math.abs(tVariance) == 0) return 'green';
  if (Math.abs(tVariance) >= 2.5 && Math.abs(tVariance) < 5) return 'yellow';
  if (Math.abs(tVariance) >= 5 && Math.abs(tVariance) < 10) return 'orange';
  if (Math.abs(tVariance) >= 10) return 'red';
  return 'green';
};

export const colorReturn3 = (rowData, index, selectedRow) => {
  let colorDecider = 0;
  // console.log(rowData['coveragePercent']);
  // debugger;
  if (selectedRow.optionality == false) {
    colorDecider += (Math.abs(rowData['targetPercentMin']-rowData['overAllCoveragePercent'])/(Math.abs(rowData['targetPercentMin'])))*100;
    if (index!==12 && index !==25 && index!==38) {
      if (colorDecider == 0) return 'green';
      // if (colorDecider >= 2.5 && colorDecider < 5) return 'yellow';
      // if (colorDecider >= 5 && colorDecider < 10) return 'orange';
      // if (colorDecider >= 10) return 'red';
      if (colorDecider > 0) return 'red';
    }
  } else {
    if (rowData['overAllCoveragePercent']<rowData['targetPercentMin'] || rowData['overAllCoveragePercent'] > rowData['targetPercentMax']) {
      if (index!==12 && index !==25 && index!==38) {
        return 'red';
      }
    } else {
      if (index!==12 && index !==25 && index!==38) {
        return 'green';
      }
    }
  }

  return 'green';
};
