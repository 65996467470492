import React from 'react';
import Configurations from '../../Components/Configurations/Configurations';

const Configuration = () => {
  return (
    <div>
      <Configurations />
    </div>
  );
};

export default Configuration;
