import moment from 'moment';
import {monthArr} from '../services/utils';

export const monthsCapital = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
export const monthsCamelCase = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];


export const stringToCamelCase = (string)=>{
  const splitStr = string.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ').trimEnd();
};


export const isPreviousMonth = (previousYear, previousMonth) => {
  const adjustedMonth = ((previousMonth - 1) % 12 + 12) % 12 + 1;
  const expectedDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  const inputDate = moment().set({
    year: previousYear,
    month: adjustedMonth- 1,
    date: 1,
  }).format('YYYY-MM-DD');

  return expectedDate === inputDate;
};


export const isAfterExecutionMonthYear = (exeMonthYear, curMonth, curYear) =>{
  const exeYear = exeMonthYear[0];
  const exeMonth = exeMonthYear[1] - 1;
  const curMonthIndex = monthArr.map((month) => month.toLowerCase()).indexOf(curMonth.toLowerCase());
  if (Number(curYear) > exeYear) {
    return true;
  } else if (Number(curYear) === exeYear && Number(curMonthIndex) >= exeMonth) {
    return true;
  }
  return false;
};
