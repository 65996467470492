/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BuyPlanList from './BuyPlanList';
import ActivePlanList from './ActivePlanList';
import HistoricalPlanList from './HistoricalPlanList';
import './TableComponent.css';
import {Box, Typography} from '@mui/material';
import {getBuyPlanData_API, setSelectedDropDownData} from '../../../redux/features/apiCall';
import {isPreviousMonth} from '../../../Utils/Common';
// import {setSortFilter} from '../../../redux/features/configurations';
const sortStatusMapping = [
  {
    'sort_value': 'sector',
    'sort': false,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'region',
    'sort': false,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'commodityGrp',
    'sort': true,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'commodity',
    'sort': true,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'subCommodity',
    'sort': true,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'executionMonthYear',
    'sort': true,
    'sort_active': true,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'hedgingMech',
    'sort': true,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'varianceCount',
    'sort': true,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'status',
    'sort': true,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'action',
    'sort': false,
    'sort_active': false,
    'sort_order': 'asc',
  },
];

const NO_OF_FILTERS = 8; // No Of Filters in Home screen

export default function TableComponent() {
  const [isHistorySelected, setIsHistorySelected] = useState(false);
  const [planListData, setPlanListData] = useState([]);
  const [count, setCount] = useState({active: 0, history: 0});
  const dispatch = useDispatch();

  // const values = ['sector', 'region', 'commodityGrp', 'commodity', 'subCommodity', 'executionMonthYear', 'varianceCount', 'status', 'action'];
  const [sortMap, setSortMap] = useState(sortStatusMapping);
  const sortValues = [true, true, true, true, true, true, true, true, true, false];


  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const apiRespBuyPlanData = useSelector((state)=>state?.api?.apiValueBuyPlanData);
  const planStatusChange = useSelector((state)=>state?.api?.PlanStatusChange);
  const selectedDropDownData = useSelector((state)=>state?.api?.selectedDropDownData);
  const reloadDataAfterHistoricalCoverageSave = useSelector((state)=>state.configurations.reloaddata);

  function mapTableData(data) {
    const seggregatedData = {};
    data.forEach((buyplan) => {
      const workFlowId = buyplan.workFlowId;
      const prevData = seggregatedData[workFlowId] ? seggregatedData[workFlowId] : [];
      seggregatedData[workFlowId] = [...prevData, buyplan];
    });
    return seggregatedData;
  }

  function getBuyplanListCount(activeData, historyData) {
    const activePlansIds = [];
    const historyPlansIds = [];
    const closedHistoryPlansIds = [];
    activeData.forEach((data) => {
      if (!activePlansIds.includes(data.workFlowId)) {
        activePlansIds.push(data.workFlowId);
      }
    });
    historyData.forEach((data) => {
      if (!historyPlansIds.includes(data.workFlowId)) {
        historyPlansIds.push(data.workFlowId);
      }
    });
    historyData.forEach((data) => {
      if (!closedHistoryPlansIds.includes(data.workFlowId) && data.status!= 'CLOSED') {
        closedHistoryPlansIds.push(data.workFlowId);
      }
    });
    return {active: activePlansIds.length, history: historyPlansIds.length, notclosed: closedHistoryPlansIds.length};
  }
  function updateListData(activeData, historyData, isHistorySelected = false) {
    const historyDataSortPlan = _.sortBy(historyData, (item) => item.workFlowId);
    const activeDataSortPlan = _.sortBy(activeData, (item) => item.workFlowId);
    const data = isHistorySelected ? sortPlanMonth(historyDataSortPlan, 'asc') : sortPlanMonth(activeDataSortPlan, 'asc');
    const temp = _.cloneDeep(data);
    const buyplanListTotal = getBuyplanListCount(activeData, historyData);
    const modifiedData = [];
    const groupedIds = [];
    const unGroupedData = [];
    let prevWorkFlowId = null;
    const addDataToTable = isHistorySelected ? mapTableData(historyData) : mapTableData(activeData);
    temp.forEach((data) => {
      if (addDataToTable[data.workFlowId]?.length > 1 && !groupedIds.includes(data.workFlowId)) {
        groupedIds.push(data.workFlowId);
      }
    });
    temp.forEach((data) => {
      if (!(groupedIds.includes(data.workFlowId))) {
        unGroupedData.push(data);
      }
    });
    groupedIds.forEach((id) => {
      unGroupedData.push(...addDataToTable[id]);
    });
    unGroupedData.map((data) => {
      if (prevWorkFlowId !== data.workFlowId) {
        prevWorkFlowId = data.workFlowId;
        modifiedData.push({...data, showValue: true});
      } else {
        modifiedData.push({...data, showValue: false});
      }
    });
    const sortedPlanMonthData = sortPlanMonth([...modifiedData].sort((a, b) => parseInt(a.executionMonthYear[0]) - parseInt(b.executionMonthYear[0])), 'asc');
    setCount(buyplanListTotal);
    setPlanListData(sortedPlanMonthData);
  }

  useEffect(() => {
    setTimeout(()=>{
      dispatch(getBuyPlanData_API());
    }, 200);
  }, [dispatch, planStatusChange, reloadDataAfterHistoricalCoverageSave]);

  useEffect(() => {
    dispatch(setSelectedDropDownData(''));
  }, []);

  function seggregatedBuyplansForFilters(data, filteredWorkFlowIds) {
    const seggregatedData = [];
    data.map((buyplan) => {
      if (filteredWorkFlowIds.includes(buyplan.workFlowId)) {
        seggregatedData.push(buyplan);
      }
    });
    return seggregatedData;
  }
  const [page, setPage] = React.useState(0);
  const [dateDecider, setDateDecider] = useState(null);
  useEffect(()=>{
    const getDateOfClose = async () =>{
      if (process.env.REACT_APP_BUYPLAN_END_DAYS) {
        setDateDecider(+process.env.REACT_APP_BUYPLAN_END_DAYS);
      } else {
        setDateDecider(0);
      }
    };
    getDateOfClose();
  }, []);
  useEffect(()=>{
    if (dateDecider) {
      setTimeout(()=>{
        if (Object.values(selectedDropDownData).some((el)=>el.length > 0)) {
          const modifiedArr = [];
          const selectedWorkflowIds = [];
          apiRespBuyPlanData?.map( (buyplan) => {
            let checkedObjArr = 0;
            Object.entries(selectedDropDownData).map(([keyName, valueArr]) => {
              if (valueArr.length === 0) {
                checkedObjArr += 1;
              } else if (keyName === 'executionMonthYear') {
                if (valueArr.includes('month_all')) {
                  valueArr = valueArr.filter((item) => item !== 'month_all');
                }
                const planMonthArr = valueArr.map( (value) => JSON.stringify(value.key));
                if (planMonthArr.includes(JSON.stringify(buyplan[keyName]))) {
                  checkedObjArr += 1;
                }
              } else if (keyName === 'status') {
                const buyplanStatus = (buyplan.status == 'NEW' || buyplan.status == 'NEW_COV_PEND') ? 'NOT STARTED' : buyplan.status;
                if (valueArr.includes(buyplanStatus)) {
                  checkedObjArr += 1;
                }
              } else if (valueArr.includes(buyplan[keyName])) {
                checkedObjArr += 1;
              }
            });
            // checking all the filters selected are in each buyplan
            if (checkedObjArr === NO_OF_FILTERS) {
              modifiedArr.push(buyplan);
              if (!(selectedWorkflowIds.includes(buyplan.workFlowId))) {
                selectedWorkflowIds.push(buyplan.workFlowId);
              }
            }
          });
          const [activeData, historyData] = segregateBuyPlans(seggregatedBuyplansForFilters(apiRespBuyPlanData, selectedWorkflowIds));
          setPage(0);
          // const historyDataSortPlan = _.sortBy(historyData, (item) => item.workFlowId).sort((a, b) => parseInt(a.executionMonthYear[0]) - parseInt(b.executionMonthYear[0]));
          // const activeDataSortPlan = _.sortBy(activeData, (item) => item.workFlowId).sort((a, b) => parseInt(a.executionMonthYear[0]) - parseInt(b.executionMonthYear[0]));
          // dispatch(setSortFilter(values[5]));
          // dispatch(setSortType('asc'));
          setSortMap(sortStatusMapping);
          updateListData(activeData, historyData, isHistorySelected);
          // setCount({active: activeData.length, history: historyData.length, notclosed: historyData.filter((data) => data.status != 'CLOSED').length});

        // if (activeData.length <= 5 || historyData.length <= 5) {
        //   setPage(0);
        // }
        } else {
          const [activeData, historyData] = segregateBuyPlans(apiRespBuyPlanData);
          setPage(0);
          updateListData(activeData, historyData, isHistorySelected);

          // const historyDataSortPlan = _.sortBy(historyData, (item) => item.workFlowId).sort((a, b) => parseInt(a.executionMonthYear[0]) - parseInt(b.executionMonthYear[0]));
          // const activeDataSortPlan = _.sortBy(activeData, (item) => item.workFlowId).sort((a, b) => parseInt(a.executionMonthYear[0]) - parseInt(b.executionMonthYear[0]));
          // setCount({active: activeData.length, history: historyData.length, notclosed: historyData.filter((data) => data.status != 'CLOSED').length});

        // if (activeData.length <= 5 || historyData.length <= 5) {
        //   setPage(0);
        // }
        }
      }, 200);
    }
  }, [selectedDropDownData, apiRespBuyPlanData, isHistorySelected, dispatch, dateDecider]);

  // if executionmonth is current month or (executionmonth is last month and status is APPROVED and today is less or equal to WD5) -- then it will stay in Active
  // else it will go to History

  const segregateBuyPlans = (plans) => {
    const activeData = [];
    const historyData = [];
    (plans || []).forEach((plan) => {
      const today = new Date();
      const isExecutionCurrentMonth = plan.executionMonthYear.includes((today.getMonth()) + 1 ) && plan.executionMonthYear.includes(today.getFullYear());
      const isExecutionLastMonth = isPreviousMonth(...plan.executionMonthYear);
      const isStatusApproved = plan.status == 'APPROVED';
      if (plan.status === 'CLOSED') {
        historyData.push(plan);
      } else if (isExecutionCurrentMonth || (isExecutionLastMonth && isStatusApproved && getCurrentMonthWDCount() <= dateDecider)) {
        activeData.push(plan);
      } else {
        historyData.push(plan);
      }
    });
    return [activeData, historyData];
  };

  function sortPlanMonth(planArr, sortType) {
    const planYears = [];
    planArr.forEach( (plan) => {
      if (!(planYears.includes(String(plan.executionMonthYear[0])))) {
        planYears.push(String(plan.executionMonthYear[0]));
      }
    });
    const sortedPlanArr = [];
    planYears.forEach((planYear) => {
      const planTempArr = [];
      planArr.forEach((plan) => {
        if (String(plan.executionMonthYear[0]) === String(planYear)) {
          planTempArr.push(plan);
        }
      });
      if ( sortType === 'asc') {
        planTempArr.sort((a, b) => parseInt(a.executionMonthYear[1]) - parseInt(b.executionMonthYear[1]));
      }
      if ( sortType === 'desc') {
        planTempArr.sort((a, b) => parseInt(b.executionMonthYear[1]) - parseInt(a.executionMonthYear[1]));
      }
      sortedPlanArr.push(...planTempArr);
    });
    return sortedPlanArr;
  }
  useEffect(()=>{
    if (SortValue === 'executionMonthYear') {
      if (sorttype === 'asc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => parseInt(a.executionMonthYear[0]) - parseInt(b.executionMonthYear[0]));
        const modifiedObj = sortPlanMonth(planArr, sorttype);
        setPlanListData([...modifiedObj]);
      } if (sorttype === 'desc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => parseInt(b.executionMonthYear[0]) - parseInt(a.executionMonthYear[0]));
        const modifiedObj = sortPlanMonth(planArr, sorttype);
        setPlanListData([...modifiedObj]);
      }
    } else if (SortValue === 'varianceCount') {
      if (sorttype === 'asc') {
        planListData.sort((a, b) => parseInt(a.varianceCount) - parseInt(b.varianceCount));
      } if (sorttype === 'desc') {
        planListData.sort((a, b) => parseInt(b.varianceCount) - parseInt(a.varianceCount));
      }
      setPlanListData([...planListData]);
    } else if (SortValue!=='') {
      if (sorttype === 'asc') {
        planListData?.sort((a, b) =>
          a[SortValue].localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        planListData?.sort((a, b) =>
          b[SortValue].localeCompare(a[SortValue]));
      }
      setPlanListData([...planListData]);
    }
  }, [SortValue, sorttype]);

  const getCurrentMonthWDCount = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    let worked = 0;
    for (let i = 1; i <= today.getDate(); i++) {
      const temp = new Date(year, month, i);
      const day = temp.getDay();
      if (day > 0 && day < 6) {
        ++worked;
      }
    }
    return worked;
  };

  return (
    <Box
      sx={{
        borderRadius: '5px',
        width: '97%',
        marginTop: '20px',
        bgcolor: 'white',
      }}
    >
      <BuyPlanList />
      <Box
        sx={{border: '1px solid #f5f5f5', width: '97%', margin: 'auto'}}
      ></Box>
      <Box sx={{display: 'flex', paddingLeft: '20px'}} >
        <Typography sx={{marginRight: '20px', marginTop: '10px'}} >
          <Box component='span' sx={{
            font: 'normal normal 700 13px/19px \'Segoe UI\'',
            color: '#4a4948',
            marginTop: '10px',
            cursor: 'pointer',
          }}
          style={{
            borderBottom: isHistorySelected === false ? '2px solid #efbc28' : 'none',
          }}
          onClick={() => setIsHistorySelected(false)}>
            Active Plans
          </Box>
          &nbsp;
          <Box component='span' sx={{color: '#0F5DAA', fontWeight: '700', fontSize: '10px'}}>({count.active})</Box>
        </Typography>
        <Typography sx={{marginRight: '20px', marginTop: '10px'}}>
          <Box component='span' sx={{
            font: 'normal normal 700 13px/19px \'Segoe UI\'',
            color: '#4a4948',
            marginTop: '10px',
            cursor: 'pointer',
          }}
          style={{
            borderBottom: isHistorySelected === true ? '2px solid #efbc28' : 'none',
          }}
          onClick={() => setIsHistorySelected(true)} >
            Historical Plans
          </Box>
          &nbsp;
          <Box component='span' sx={{color: '#0F5DAA', fontWeight: '700', fontSize: '10px'}}>
            ({count.history>50?'Total 50+':'Total '+ count.history}
            {count.notclosed?<font color='#D54444'>&nbsp;&nbsp;Not Closed {count.notclosed}</font>:''})
          </Box>
        </Typography>
      </Box>
      {isHistorySelected?<HistoricalPlanList setCount={setCount} sortValues={sortValues} data={planListData} setPage={setPage} page={page} sortMap={sortMap} setSortMap={setSortMap} sortStatusMapping={sortStatusMapping} />:<ActivePlanList setCount={setCount} sortValues={sortValues} data={planListData} setPage={setPage} page={page} sortMap={sortMap} setSortMap={setSortMap} sortStatusMapping={sortStatusMapping}/>}
    </Box>
  );
}
