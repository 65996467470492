// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
    position: relative;
  }
  .dropdown > button{
    background-color: white;
    border: none;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    font-family: 'Segoe UI';
    /* color: #6d7888; */
    color: #3B4655;
    border: 1px solid #DBDBDB;
    border-radius: 2px;
    width: 78px;
    height: 22px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .menu {
    position: absolute;
    left : -2rem;
    list-style-type: none;
    margin: 5px 0;
    padding: 0;
    border: none;
    width: 150px;
    z-index: 5;
  }
  .dropdownValue{
    width: 70%;
    font-size: 12px;
    font-family: 'Segoe UI';
}
  .dropdownIcon{
    color: gray;
  }
  
  .menu > li {
    margin: 0;
  
    background-color: white;
  }
  
  .menu > li:hover {
    background-color: white;
  }
  
  .menu > li > button {
    width: 100%;
    height: 100%;  
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    font-family: 'Segoe UI';
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Coverage/SaveCoverage/DropdownSelect/DropdownSelect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;EACA;IACE,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;IACvB,oBAAoB;IACpB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,UAAU;EACZ;EACA;IACE,UAAU;IACV,eAAe;IACf,uBAAuB;AAC3B;EACE;IACE,WAAW;EACb;;EAEA;IACE,SAAS;;IAET,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;EACjB","sourcesContent":[".dropdown {\n    position: relative;\n  }\n  .dropdown > button{\n    background-color: white;\n    border: none;\n    font-size: 12px;\n    font-weight: 400;\n    text-align: center;\n    font-family: 'Segoe UI';\n    /* color: #6d7888; */\n    color: #3B4655;\n    border: 1px solid #DBDBDB;\n    border-radius: 2px;\n    width: 78px;\n    height: 22px;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n  }\n  \n  .menu {\n    position: absolute;\n    left : -2rem;\n    list-style-type: none;\n    margin: 5px 0;\n    padding: 0;\n    border: none;\n    width: 150px;\n    z-index: 5;\n  }\n  .dropdownValue{\n    width: 70%;\n    font-size: 12px;\n    font-family: 'Segoe UI';\n}\n  .dropdownIcon{\n    color: gray;\n  }\n  \n  .menu > li {\n    margin: 0;\n  \n    background-color: white;\n  }\n  \n  .menu > li:hover {\n    background-color: white;\n  }\n  \n  .menu > li > button {\n    width: 100%;\n    height: 100%;  \n    background: none;\n    color: inherit;\n    border: none;\n    padding: 5px;\n    margin: 0;\n    font-size: 12px;\n    font-weight: 400;\n    text-align: center;\n    font-family: 'Segoe UI';\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
