import * as React from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import {Box, Checkbox, FormControl, Input, InputAdornment, MenuItem} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ConfigurationServices from '../../../../services/ConfigurationServices';
const ButtonDefaultStyles = {
  'textTransform': 'capitalize',
  'backgroundColor': '#4297d3',
  'color': '#ffffff',
  'minWidth': '0',
  'padding': '8px 10px',
  'float': 'right',
  'font': 'normal normal 600 12px \'Segoe UI\'',
  '&:hover': {
    backgroundColor: '#4297d3',
  },
};

export function InputWithSearchIcon({sectorSearchWord, setSectorSearchWord}) {
  const handleInputChange =(e)=>{
    setSectorSearchWord(e.target.value);
  };

  return (
    <Box sx={{'& > :not(style)': {m: 1}, 'display': 'flex', 'width': '100%', 'justifyContent': 'center', 'padding': '0px 10px', 'position': 'sticky', 'top': '0'}}>
      <FormControl variant='standard' sx={{'width': '100%'}}>
        <Input
          placeholder='Search Region...'
          style={{
            color: '#3B4655',
            borderBottom: 'blue',
          }}
          onChange={handleInputChange}
          value={sectorSearchWord}
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}

export function ConfirmationDialogRaw(props) {
  const {onClose, allSectors, setLoading, setAllSectors, regionName, regionId, loading, open, ...other} = props;
  const radioGroupRef = React.useRef(null);
  const [sectorSearchWord, setSectorSearchWord] = React.useState('');
  const [allSectorsFromApi, setAllSectorsFromApi] = React.useState([]);
  React.useEffect(()=>{
    setAllSectorsFromApi(allSectors);
    setLoading(true);
  }, [allSectors]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const handleCancel = () => {
    onClose();
  };
  const handleOk = () => {
    const updatedActives = allSectorsFromApi.filter((org)=>{
      return org.is_present == 'Y';
    });

    const postObj = {
      'id': regionId,
      'childIdList': updatedActives.map((obj) => obj.sector_id),
    };
    ConfigurationServices.postRegionMapping(postObj).then(()=>console.log('post send'));
    onClose();
  };

  React.useEffect(()=>{
    setSectorSearchWord('');
  }, [open]);


  const handleRegionChange = (selectedItem) => {
    const updatedRegions = allSectors.map((region)=>{
      if (region.unique_id === selectedItem.unique_id ) {
        if (props.editable==='N') {
          return {...selectedItem, 'is_present': `${(selectedItem.is_present === 'Y' && !props.initialSector.includes(selectedItem.sector)) ? 'N' : 'Y'}`};
        } else {
          return {...selectedItem, 'is_present': `${selectedItem.is_present === 'Y' ? 'N' : 'Y'}`};
        }
      } else {
        return region;
      }
    });
    const sortedRegions = [];
    updatedRegions.forEach((region) => {
      if (region.is_present === 'Y') {
        sortedRegions.push(region);
      }
    });
    updatedRegions.forEach((region) => {
      if (region.is_present === 'N') {
        sortedRegions.push(region);
      }
    });
    setAllSectors(sortedRegions);
    setSectorSearchWord('');
  };

  React.useEffect(()=>{
    if (sectorSearchWord !== '') {
      const filteredData = [];
      allSectors.forEach((eachRegion) => {
        if (eachRegion.sector.toLowerCase().includes(sectorSearchWord.toLowerCase())) {
          filteredData.push(eachRegion);
        }
      });
      setAllSectorsFromApi(filteredData);
    } else {
      if (!(allSectors === null)) {
        setAllSectorsFromApi(allSectors);
      }
    }
  }, [sectorSearchWord]);


  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {width: '80%', height: 435}}}
      maxWidth='xs'
      TransitionProps={{onEntering: handleEntering}}
      open={open}
      {...other}
    >
      <DialogTitle sx={{color: '#3B4655'}}>{`Select Sectors For ${regionName}`}</DialogTitle>
      <DialogContent sx={{padding: '0px'}} dividers>
        <InputWithSearchIcon sectorSearchWord={sectorSearchWord} setSectorSearchWord={setSectorSearchWord} />
        <Box sx={{height: '270px', overflow: 'auto'}}>
          {
                !loading || allSectorsFromApi === null ?
                (
                  <MenuItem sx={{display: 'flex', justifyContent: 'center', height: '100%', padding: '2px 6px'}}>
                    <ListItemText sx={{color: '#3B4655', fontSize: '12px', textAlign: 'center'}} primaryTypographyProps={{fontSize: '18px'}} primary={'Loading...'} />
                  </MenuItem>
                ) :
                (
                  allSectorsFromApi.length > 0 ?
                (
                  allSectorsFromApi.map((region, index) => (
                    <MenuItem sx={{padding: '2px 6px'}} key={index}>
                      <Checkbox
                        style={{color: '#0071DC'}}
                        checked={region.is_present === 'Y' ? true : false}
                        onClick={() => handleRegionChange(region)}
                      />
                      <ListItemText sx={{color: '#3B4655', fontSize: '12px'}} primary={region.sector} />
                    </MenuItem>
                  ))
                ):
                (
                  <MenuItem sx={{display: 'flex', justifyContent: 'center', height: '100%', padding: '2px 6px'}}>
                    <ListItemText primaryTypographyProps={{fontSize: '18px'}} sx={{color: '#3B4655', fontSize: '18px', textAlign: 'center'}} primary={'No Regions Are Found'} />
                  </MenuItem>
                ))
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          sx={ButtonDefaultStyles}
          autoFocus onClick={handleCancel}
        >
        Cancel
        </Button>
        <Button
          disableRipple
          sx={ButtonDefaultStyles}
          autoFocus onClick={handleOk}
        >
        Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AddSectorDialogue({regionId, regionName, editable}) {
  const [open, setOpen] = React.useState(false);
  const [allSectors, setAllSectors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [initialSector, setInitialSector] = React.useState([]);
  const handleClickListItem = () => {
    setOpen(true);
    getAllSectorsRegionId(regionId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeDuplicates = (original)=>{
    let nonduplicates = [];
    const active = original.filter((org)=>{
      return org.is_present == 'Y';
    });
    nonduplicates = [...active];
    original.map((org)=>{
      let c = 0;
      nonduplicates.map((nd)=>{
        if (nd.sector_id === org.sector_id) {
          c+=1;
        }
      });
      if (c==0) {
        nonduplicates.push(org);
      }
    });
    return [...nonduplicates];
  };

  function getAllSectorsRegionId(regionId) {
    setLoading(false);
    ConfigurationServices.getRegionMapping(regionId)
        .then((res)=>{
          if (res !=undefined && typeof res !== 'string') {
            const keys = [];
            res.length>0 && Object.keys(res[0]).map((obj) => {
              if (obj.split('_').includes('id')) {
                keys.push(obj);
              }
            });
            if (res['userMessage']) {
              setAllSectors([]);
            } else {
              const addingUniqueId = [];

              const existingSector = [];

              res.forEach((item, index)=> {
                addingUniqueId.push({...item, 'unique_id': index+1});
                if ( item.is_present==='Y') {
                  existingSector.push(item.sector);
                }
              });
              setAllSectors(removeDuplicates(addingUniqueId));
              setInitialSector(existingSector);
            }
          } else {
            setAllSectors([]);
          };
        });
  }

  return (
    <Box
      sx={{
        border: '1px solid gray',
        display: 'flex',
        border: '0.5px solid #CECECE',
        borderRadius: '4px',
        // width: '88px',
        alignItems: 'center',
        gap: '5px',
        padding: '2px 6px',
        cursor: 'pointer',
        background: '#4297d3',
        color: 'white',
        justifyContent: 'center',
      }}
    >
      <AddLocationIcon sx={{fontSize: '18px'}}/>
      <List
        sx={{
          padding: '0px',
        }}
        component='div' role='group'>
        <ListItem
          sx={{
            padding: '0px',
            borderBottom: 'none',
          }}
          button
          disableRipple
          divider
          aria-haspopup='true'
          aria-controls='sectors-menu'
          aria-label='add sector'
          onClick={handleClickListItem}
        >
          <ListItemText sx={{
            padding: '0px',
            margin: '0px',
          }}
          primaryTypographyProps={{fontSize: '12px'}}
          primary='Add New Sector' />
        </ListItem>
        <ConfirmationDialogRaw
          id='sectors-menu'
          keepMounted
          open={open}
          onClose={handleClose}
          allSectors={allSectors}
          loading={loading}
          setLoading={setLoading}
          regionName={regionName}
          regionId={regionId}
          setAllSectors={setAllSectors}
          initialSector={initialSector}
          editable = {editable}
        />
      </List>
    </Box>
  );
};
