/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';

import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box} from '@mui/material';
import {monthArr, roundToTwo} from '../../services/utils';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function ForecastTable(props) {
  const [rows, setRows] = useState([]);
  const [rowValues, setRowValues] = useState([]);

  useEffect(()=>{
    setRowValues([]);
    const rowDummy = [];
    Object.entries(props.data).map((m)=>{
      rowDummy.push(m);
    });
    rowDummy.sort((a, b)=>monthArr.indexOf(a[0].split('-')[0])-monthArr.indexOf(b[0].split('-')[0]));
    setRows([...rowDummy]);
  }, [props.data, props.year]);


  useEffect(()=>{
    rows.map((r)=>{
      const dates ={};
      Object.entries(r[1]).map((rV)=>{
        // COMPARING THE YEAR AND THE DATA YEAR IS SAME OR NOT
        if (rV[0].slice(rV[0].length-2) == props.year.slice(props.year.length-2)) {
          // console.log(rV[0],rV[1]);
          dates[rV[0].slice(0, 3)]= rV[1];
        }
      });

      setRowValues((prev)=>[...prev, dates]);
    });
  }, [rows, props.year]);


  return (<>
    <>
      <Box sx={{width: '100%', padding: '0px 18px'}} style={{marginBottom: '15px'}}>
        <Box sx={{marginTop: '20px'}}>
          <TableContainer component={Paper}>
            <Table sx={{minWidth: 700}} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Plan Month</StyledTableCell>
                  {/* <StyledTableCell align="left">Plan Year</StyledTableCell> */}
                  {monthArr.map((m, key)=>(
                    <StyledTableCell align="left" key={key}>{m}</StyledTableCell>
                  ))}
                  <StyledTableCell align="left">FY {props.year.slice(props.year.length-2)} Total</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell component="th" scope="row">
                      {row[0]}
                      {/* {changeToMonth(row.executionMonthYear[1])}-{row.executionMonthYear[0]} */}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">{props.year}</StyledTableCell> */}
                    {rowValues.length>0 &&
                    <>
                      <StyledTableCell align="left">{(rowValues[key]['Jan'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Jan'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Feb'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Feb'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Mar'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Mar'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Apr'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Apr'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['May'] == undefined ? 0 : roundToTwo(2, rowValues[key]['May'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Jun'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Jun'])?.toLocaleString('en-US')) }</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Jul'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Jul'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Aug'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Aug'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Sep'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Sep'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Oct'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Oct'])?.toLocaleString('en-US')) }</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Nov'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Nov'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left">{(rowValues[key]['Dec'] == undefined ? 0 : roundToTwo(2, rowValues[key]['Dec'])?.toLocaleString('en-US'))}</StyledTableCell>
                      <StyledTableCell align="left" style={{fontWeight: '600', color: '#3B4655'}}>{
                        ((rowValues[key]['Jan'] == undefined ? 0 : + rowValues[key]['Jan']) +
                      (rowValues[key]['Feb'] == undefined ? 0 : + rowValues[key]['Feb']) +
                      (rowValues[key]['Mar'] == undefined ? 0 : + rowValues[key]['Mar']) +
                      (rowValues[key]['Apr'] == undefined ? 0 : + rowValues[key]['Apr']) +
                      (rowValues[key]['May'] == undefined ? 0 : + rowValues[key]['May']) +
                      (rowValues[key]['Jun'] == undefined ? 0 : + rowValues[key]['Jun']) +
                      (rowValues[key]['Jul'] == undefined ? 0 : + rowValues[key]['Jul']) +
                      (rowValues[key]['Aug'] == undefined ? 0 : + rowValues[key]['Aug']) +
                      (rowValues[key]['Sep'] == undefined ? 0 : + rowValues[key]['Sep']) +
                      (rowValues[key]['Oct'] == undefined ? 0 : + rowValues[key]['Oct']) +
                      (rowValues[key]['Nov'] == undefined ? 0 : + rowValues[key]['Nov']) +
                      (rowValues[key]['Dec'] == undefined ? 0 : + rowValues[key]['Dec']))?.toLocaleString('en-US')
                      }</StyledTableCell>
                    </>
                    }
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>

  </>);
}

export default ForecastTable;
