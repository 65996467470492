/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn, floatColumn, checkboxColumn} from 'react-datasheet-grid';
// DataSheetGridPropsBase

// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../../services/dialogService';
import {leapYear, monthArr, months, titleCase, monthsCap} from '../../../services/utils';
// import {AppDialog} from '../common/App-Dialog/AppDialog';
import {AppTableInput} from './AppTableInput/AppTableInput';
import './forecast.css';
import {AppButton} from '../../common/AppButton/AppButton';
// import {defaultObj} from './consts';
import {forecastDefaultData, fyCalculateTotal, getSortedMonthlyData, monthNYearSetter, rowsSetter} from './utils';
import {AppConfirmDialog, AppDialog, AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import {
  setNonP,
  setNonPlayBookCoverageButton,
  setNonPlayBookCoverageButtonlayBookCoverageButtonsetNonPlayBookCoverageButton,
} from '../../../redux/features/configurations';
import ReadOnlyColumn from '../../common/ReadOnlyColumn';
import {updateStatusOfBuyPlan, updateStatusOfNonPlayBookData} from '../../../redux/features/apiCall';
import {changeBuyplanStatus} from '../../../redux/features/buttonStatus';
// import {useSelector} from 'react-redux';

const CoverageUpdate = ({
  // subMissionData,
  // setSubmissionData,
  access,
  NonPlayBookCoverageData1,
  NonPlayBookCoverageData2,
  NonPlayBookCoverageData3,
  setNonPlayBookCoverageData1,
  setNonPlayBookCoverageData2,
  setNonPlayBookCoverageData3,
  years,
  data,
  setData,
  setDataTrigger,
  dataTrigger,
  selectedYear,
  selectedRow,
  setIsPopupVisible,
}) => {
  const [subMissionData, setSubmissionData] = React.useState({});
  const NonPlayBookCoverageButton = useSelector((state) => state.configurations.NonPlayBookCoverageButton);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const functionalityAccess = useSelector((state) => state?.roleBasedAccess?.functionalityAccess);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newWfID, setNewWfID] = useState('');
  const [confirmClosePopup, setconfirmClosePopup] = useState(false);
  const [ClosePlayBook, setClosePlayBook] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [nonPlayBookCoverage, setNonPlayBookCoverage] = useState([]);
  const [countTrigger, setCountTrigger] = useState(0);
  const handleCloseSuccess = () => {
    // postCall();
    // setTimeout(()=>{
    //   setpreviousMonthPresentMonthNotSame(false);
    // }, 300);

    setClosePlayBook(false);
    setIsPopupVisible(false);
  };
  const closeNonPlayBook = () => {
    DialogService.updateNonPlayBookStatus(selectedRow.workflow_id, 'CLOSED')
        .then((res) => {
          if (res.status == 200 || res === true) {
            dispatch(updateStatusOfNonPlayBookData({id: selectedRow.workflow_id, status: 'CLOSED'}));
            setClosePlayBook(true);
          }
        })
        .catch((err) => {
          console.log(err);
        // dispatch(updateStatusOfNonPlayBookData({id: selectedRow.workflow_id, status: 'CLOSED'}));
        // selectedRow.status = 'CLOSED';
        });
  };

  const dispatch = useDispatch();


  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId = useSelector((state) => state?.roleBasedAccess?.currentRoleId);
  const availableRoles = useSelector((state) => state?.roleBasedAccess?.availableRoles);

  useEffect(() => {
    if (currentRoleId) {
      availableRoles.map((role) => {
        if (currentRoleId === role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  const [columns, setColumns] = useState([]);
  const [columns2, setColumns2] = useState([]);

  const setColumDatas = () => {
    setTimeout(() => {
      if (selectedYear !== undefined && selectedYear !== null && selectedYear != 0) {
        setColumns([
          {
            ...keyColumn('planMonth', textColumn),
            title: 'Plan Month',
            disabled: true,
          },
          {
            ...keyColumn('year', textColumn),
            title: 'Plan Year',
            disabled: true,
          },
          {
            ...keyColumn('type', textColumn),
            title: 'Type',
            disabled: true,
            minWidth: 120,
          },
          {
            ...keyColumn('jan', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="jan"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jan',
          },
          {
            ...keyColumn('feb', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="feb"
                forecastVal={[selectedYear, 2]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Feb',
          },
          {
            ...keyColumn('mar', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="mar"
                forecastVal={[selectedYear, 3]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Mar',
          },
          {
            ...keyColumn('apr', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="apr"
                forecastVal={[selectedYear, 4]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Apr',
          },
          {
            ...keyColumn('may', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="may"
                forecastVal={[selectedYear, 5]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'May',
          },
          {
            ...keyColumn('jun', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="jun"
                forecastVal={[selectedYear, 6]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jun',
          },
          {
            ...keyColumn('jul', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="jul"
                forecastVal={[selectedYear, 7]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jul',
          },
          {
            ...keyColumn('aug', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="aug"
                forecastVal={[selectedYear, 8]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Aug',
          },
          {
            ...keyColumn('sep', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="sep"
                forecastVal={[selectedYear, 9]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Sep',
          },
          {
            ...keyColumn('oct', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="oct"
                forecastVal={[selectedYear, 10]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Oct',
          },
          {
            ...keyColumn('nov', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="nov"
                forecastVal={[selectedYear, 11]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Nov',
          },
          {
            ...keyColumn('dec', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput setCountTrigger={setCountTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="dec"
                forecastVal={[selectedYear, 12]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Dec',
          },
          {
            ...keyColumn('fy', textColumn),
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="fy" />,
            title: `FY ${selectedYear} Total`,
            disabled: true,
          },
        ]);

        setColumns2([
          {
            ...keyColumn('planMonth', textColumn),
            title: 'Plan Month',
            disabled: true,
          },
          {
            ...keyColumn('year', textColumn),
            title: 'Plan Year',
            disabled: true,
          },
          {
            ...keyColumn('type', textColumn),
            title: 'Type',
            disabled: true,
            minWidth: 120,
          },
          {
            ...keyColumn('jan', textColumn),
            disabled: true,
            title: 'Jan',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="jan" />,
          },
          {
            ...keyColumn('feb', textColumn),
            disabled: true,
            title: 'Feb',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="feb" />,
          },
          {
            ...keyColumn('mar', textColumn),
            disabled: true,
            title: 'Mar',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="mar" />,
          },
          {
            ...keyColumn('apr', textColumn),
            disabled: true,
            title: 'Apr',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="apr" />,
          },
          {
            ...keyColumn('may', textColumn),
            disabled: true,
            title: 'May',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="may" />,
          },
          {
            ...keyColumn('jun', textColumn),
            disabled: true,
            title: 'Jun',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="jun" />,
          },
          {
            ...keyColumn('jul', textColumn),
            disabled: true,
            title: 'Jul',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="jul" />,
          },
          {
            ...keyColumn('aug', textColumn),
            disabled: true,
            title: 'Aug',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="aug" />,
          },
          {
            ...keyColumn('sep', textColumn),
            disabled: true,
            title: 'Sep',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="sep" />,
          },
          {
            ...keyColumn('oct', textColumn),
            disabled: true,
            title: 'Oct',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="oct" />,
          },
          {
            ...keyColumn('nov', textColumn),
            disabled: true,
            title: 'Nov',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="nov" />,
          },
          {
            ...keyColumn('dec', textColumn),
            disabled: true,
            title: 'Dec',
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="dec" />,
          },
          {
            ...keyColumn('fy', textColumn),
            component: ({rowData, focus}) => <ReadOnlyColumn rowData={rowData} columnName="fy" />,
            title: `FY ${selectedYear} Total`,
            disabled: true,
          },
        ]);
      }
    }, 100);
  };

  useEffect(() => {
    setColumns([]);
    setColumns2([]);
    setColumDatas();
  }, [selectedYear]);

  const [getCallTrigger, setGetCallTrigger] = useState(false);

  useEffect(() => {
    if (years.length > 0) {
      getCall();
    }
  }, [selectedRow, years, getCallTrigger]);

  const yearGenerator = () => {
    const obj = {};
    years.map((y) => {
      months.map((m, i) => {
        obj[`${y}-${i + 1 < 10 ? `0${i + 1}` : i + 1}`] = 0;
      });
    });
    return obj;
  };
  const dataFilling = (year, array, coveragePrice, coverageVolume) => {
    const data = array;
    for (let index = 0; index < data.length; index++) {
      if (index == 0) {
        for (let m = 0; m < months.length; m++) {
          data[index][months[m]] = coveragePrice[`${year}-${m + 1 < 10 ? `0${m + 1}` : m + 1}`];
        }
      } else {
        for (let m = 0; m < months.length; m++) {
          data[index][months[m]] = coverageVolume[`${year}-${m + 1 < 10 ? `0${m + 1}` : m + 1}`];
        }
      }
    }
    return data;
  };
  const getCall = () => {
    DialogService.getNonPlayBookCoverageData(selectedRow.workflow_id).then((res) => {
      // res = Object.values(res.map((obj)=> convertToCamelCase(obj)));
      setNonPlayBookCoverage(res);
      const coveragePrice = yearGenerator();
      const coverageVolume = yearGenerator();
      if (res.length > 0) {
        res.map((r) => {
          coveragePrice[r.coverage_year_month] = r.coverage_price;
          coverageVolume[r.coverage_year_month] = r.coverage_volume;
        });
      }
      years.map((y, i) => {
        if (i == 0) {
          setNonPlayBookCoverageData1(dataFilling(y, NonPlayBookCoverageData1, coveragePrice, coverageVolume));
        } else if (i == 1) {
          setNonPlayBookCoverageData2(dataFilling(y, NonPlayBookCoverageData2, coveragePrice, coverageVolume));
        } else if (i == 2) {
          setNonPlayBookCoverageData3(dataFilling(y, NonPlayBookCoverageData3, coveragePrice, coverageVolume));
        }
      });

      addingFunction(NonPlayBookCoverageData1, 0);

      addingFunction(NonPlayBookCoverageData2, 1);

      addingFunction(NonPlayBookCoverageData3, 2);
    });
  };
  const updateStatus = () => {
    DialogService.updateNonPlayBookStatus(selectedRow.workflow_id, 'DRAFT')
        .then((res) => {
          if (res.status == 200 || res===true) {
            dispatch(updateStatusOfNonPlayBookData({id: selectedRow.workflow_id, status: 'DRAFT'}));
            dispatch(changeBuyplanStatus('DRAFT'));
          }
          if (res['errorMessage']!==undefined && res['errorMessage'].includes('Existing Status:DRAFT Requested Status:DRAFT')) {
            dispatch(updateStatusOfBuyPlan(selectedRow.workflow_id, 'DRAFT'));
            // setIsSavePopupVisible(true);
            dispatch(changeBuyplanStatus('DRAFT'));
          }
        }).catch((err)=>{
          console.log(err);
          setShowErrorDialog(true);
        });
  };
  const postCall = async () => {
    const coveragesPayload = [];

    const coverages = [NonPlayBookCoverageData1, NonPlayBookCoverageData2, NonPlayBookCoverageData3];

    coverages?.forEach((cov) => {
      months.forEach((m, i) => {
        const coverageYearMonth = `${cov[0].planYear}-${i + 1 < 10 ? `0${i + 1}` : i + 1}`;
        const existingCoverage = nonPlayBookCoverage?.find((c) => c.coverage_year_month === coverageYearMonth);
        coveragesPayload.push({
          id: existingCoverage?.id || 0,
          workflowId: cov[0].workflow_id,
          coverageYearMonth,
          coverageVolume: +cov[1][m],
          coveredPrice: +cov[0][m],
        });
      });
    });

    await DialogService.saveNonPlayBookCoverageData(coveragesPayload)
        .then((res) => {
          if (res == true && selectedRow.status !== 'DRAFT') {
            updateStatus();
          }
          if (res == true) {
            setIsSavePopupVisible(true);
            dispatch(setNonPlayBookCoverageButton('true'));
            setIsSavePopupVisible(true);
          }
        })
        .catch((e) => {
        // console.log('');
          setIsErrorPopupVisible(true);
          setErrMsg(e.errorMessage);
        })
        .finally(() => {
          // setDisableSaveBtn(false);
        });
    setGetCallTrigger(!getCallTrigger);
  };

  function roundToTwo(num) {
    if (isNaN(num)) return 0;
    // if (!Number.isInteger(num) && !(Math.floor(num) == num)) {
    if (num % 1 !== 0) {
      const roundedNum = +(Math.round(num + 'e+2') + 'e-2');
      if (roundedNum == 0) return 0;
      return roundedNum;
    } else {
      return parseFloat(num);
    }
  }

  const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
      if (!(+splitArray[1] > 0)) formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  const addingFunction = (data, setting) => {
    const coverageVolume = {...data[1]};
    let coverageVolumeTotal = 0;
    months.map((mn) => {
      // console.log((coverageVolume[mn] && coverageVolume[mn].toString().includes(',') ? +(coverageVolume[mn].replaceAll(',', '')) : +coverageVolume[mn] || 0));
      coverageVolumeTotal += roundToTwo(
        coverageVolume[mn] && coverageVolume[mn].toString().includes(',') ?
          +roundToTwo(coverageVolume[mn].replaceAll(',', '')) :
          +roundToTwo(coverageVolume[mn]) || 0,
      );
    });
    // console.log(coverageVolumeTotal);
    coverageVolume['fy'] = coverageVolumeTotal;


    // console.log(data);
    const coveragePrice = {...data[0]};
    let coveragePriceTotal = 0;
    months.map((mn) => {
      // console.log((coveragePrice[mn] && coveragePrice[mn].toString().includes(',') ? +(coveragePrice[mn].replaceAll(',', '')) : +coveragePrice[mn] || 0));
      coveragePriceTotal += roundToTwo(
     coveragePrice[mn] && coveragePrice[mn].toString().includes(',') ?
       +roundToTwo(+coveragePrice[mn].replaceAll(',', '') * +coverageVolume[mn].replaceAll(',', '')) :
       +roundToTwo(+coveragePrice[mn] * coverageVolume[mn]) || 0,
      );
    });
    // console.log(coveragePriceTotal);
    coveragePrice['fy'] = roundToTwo(coveragePriceTotal/coverageVolumeTotal);

    if (setting == 0) {
      setNonPlayBookCoverageData1([coveragePrice, coverageVolume]);
    }
    if (setting == 1) {
      setNonPlayBookCoverageData2([coveragePrice, coverageVolume]);
    }
    if (setting == 2) {
      setNonPlayBookCoverageData3([coveragePrice, coverageVolume]);
    }
  };
  useEffect(() => {
    addingFunction(NonPlayBookCoverageData1, 0);

    addingFunction(NonPlayBookCoverageData2, 1);

    addingFunction(NonPlayBookCoverageData3, 2);
  }, [countTrigger]);
  return (
    <>
      {((columns.length && NonPlayBookCoverageData1.length) || '') && (
        <div className="forecast-table">
          {(NonPlayBookCoverageButton == 'false' || selectedRow.status === 'NEW') && access === 'Edit' && selectedRow.status !== 'CLOSED' && selectedYear == years[0] && (
            <DataSheetGrid
              value={NonPlayBookCoverageData1}
              onChange={(newData) => {
                // console.log(newData);
                addingFunction(newData, 0);
              }}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {(NonPlayBookCoverageButton == 'false' || selectedRow.status === 'NEW') && access === 'Edit' && selectedRow.status !== 'CLOSED' && selectedYear == years[1] && (
            <DataSheetGrid
              value={NonPlayBookCoverageData2}
              onChange={(newData) => {
                addingFunction(newData, 1);
              }}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {(NonPlayBookCoverageButton == 'false' || selectedRow.status === 'NEW') && access === 'Edit' && selectedRow.status !== 'CLOSED' && selectedYear == years[2] && (
            <DataSheetGrid
              value={NonPlayBookCoverageData3}
              onChange={(newData) => {
                addingFunction(newData, 2);
              }}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {/* If button redux variable true then it shows disabled columns */}
          {(NonPlayBookCoverageButton == 'true' ||
            access !== 'Edit' ||
            selectedRow.status == 'CLOSED') && selectedRow.status !== 'NEW' &&
            selectedYear == years[0] && (
            <DataSheetGrid
              value={NonPlayBookCoverageData1}
              onChange={(newData) => {
                // console.log(newData);
                addingFunction(newData, 0);
              }}
              columns={columns2}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {(NonPlayBookCoverageButton == 'true' ||
            access !== 'Edit' ||
            selectedRow.status == 'CLOSED') && selectedRow.status !== 'NEW' &&
            selectedYear == years[1] && (
            <DataSheetGrid
              value={NonPlayBookCoverageData2}
              onChange={(newData) => {
                addingFunction(newData, 1);
              }}
              columns={columns2}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {(NonPlayBookCoverageButton == 'true' ||
            access !== 'Edit' ||
            selectedRow.status == 'CLOSED') && selectedRow.status !== 'NEW' &&
            selectedYear == years[2] && (
            <DataSheetGrid
              value={NonPlayBookCoverageData3}
              onChange={(newData) => {
                addingFunction(newData, 2);
              }}
              columns={columns2}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {/* {access === 'Edit' && (selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT') ? ( */}
          {/* {access === 'Edit' && (buttonStatus==='edit' || (currentRole === 'Central Admin' && selectedRow.status === 'DRAFT')) ? ( */}

          {access === 'Edit' &&
          (buttonStatus === 'edit' || ((selectedRow.status === 'DRAFT' || selectedRow.status == 'REJECTED'))) ? (
            (NonPlayBookCoverageButton === 'false' || selectedRow.status === 'NEW') ? (
              <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px'}}>
                <AppButton
                  onClick={() => {
                    postCall();
                  }}
                  label={'Save Coverages'}
                />
              </Box>
            ) : (
              <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px'}}>
                <AppButton
                  onClick={() => {
                    dispatch(setNonPlayBookCoverageButton('false'));
                    setDataTrigger(!dataTrigger);
                  }}
                  label={'Edit Coverages'}
                />
                {selectedRow.status=='DRAFT' && <AppButton onClick={() => setconfirmClosePopup(true)} label="Close" />
                }
              </Box>
            )
          ) : (
            ''
          )}
        </div>
      )}
      <AppErrorDialog
        open={showErrorDialog}
        handleClose={() => {
          setShowErrorDialog(false);
          setErrorMessage(null);
        }}
        text={errorMessage ? errorMessage : 'Error occured when saving'}
      />
      <AppDialog
        open={isSavePopupVisible}
        handleClose={() => {
          setIsSavePopupVisible(false);

          // setValue(1);
        }}
        text="Coverages are Saved"
      />
      <AppDialog open={ClosePlayBook} handleClose={handleCloseSuccess} text={'Non Playbook Closed Successfully'} />
      <AppErrorDialog
        open={isErrorPopupVisible}
        handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }}
        text={errMsg}
      />
      <AppConfirmDialog
        open={confirmClosePopup}
        handleClose={() => setconfirmClosePopup(false)}
        text="Are you sure you want to close this Non Playbook?"
        setYes={() => {
          setconfirmClosePopup(false);
          closeNonPlayBook();
        }}
        ifYesFirst={false}
      />
    </>
  );
};

export default CoverageUpdate;
