import {createSlice} from '@reduxjs/toolkit';

export const editStatusSlice = createSlice({
  name: 'editStatus',
  initialState: {
    value: false,
    previousMonthWillCoverEditButton: false,
  },
  reducers: {
    editStatus: (state, action) => {
      state.value = action.payload;
    },
    setPreviousMonthWillCoverEditButton: (state, action) => {
      state.previousMonthWillCoverEditButton = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {editStatus, setPreviousMonthWillCoverEditButton} = editStatusSlice.actions;

export default editStatusSlice.reducer;
