/* eslint-disable camelcase */
/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, TablePagination} from '@mui/material';
import customealltheme from '../../../theme';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {useDispatch, useSelector} from 'react-redux';
import {getDropDownData_API} from '../../../redux/features/apiCall';
import CommonConfSingleRow from './BuyPlanSingleRow';
import config from '../../../Utils/Config';
import {setSortFilter, setSortType} from '../../../redux/features/configurations';
const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function CommonConfTable({mappedData, idKey, ActiveMasterSectors, accessControl, buyplanPersona, Units, Currency, Playbooks, ActiveSectors, setPage, page, sortFn, sortType, ActiveCommodities, country, ActiveSubCommodities, data, ActiveCategories, setErrMsg, setIsErrorPopupVisible, setAddingTrigger, addingTrigger, heads, values, editValues, dateValues, sortValues, singleUpdate, deleteBuyPlanRow, pagination, setPagination, editBuyPlanRow}) {
  const Edit = config.AccessLevel.Edit;
  const [editModeDisableTrigger, setEditModeDisableTrigger]=useState(false); // to disable the edit of any row
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setEditModeDisableTrigger((editModeDisableTriggerValue)=>!editModeDisableTriggerValue);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(+event.target.value);
    setPage(0);
  };

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setSortType(''));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getDropDownData_API());
  }, [dispatch]);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  function getTotalNoOfRows(data) {
    let curCount = 0;
    const sepInd = [];
    data.forEach((eachItem, index) => {
      if (eachItem.showValue) {
        curCount += 1;
        sepInd.push(index);
      }
    });
    return {curCount, sepInd};
  }
  function getDataForTable(data, page, pagination) {
    const {sepInd} = getTotalNoOfRows(data);
    const startIndex = sepInd[page * pagination];
    const endIndex = sepInd[page * pagination + pagination];
    return data?.slice(startIndex, endIndex);
  }

  return (
    <Box sx={{width: '100%', padding: '0px 18px'}}>
      <TableContainer component={Paper} id='buyplantable'>
        <Table sx={{minWidth: 700}} aria-label="customized table">
          <TableHead id='buyplanhead'>
            <TableRow>
              {heads.map((h, index)=>(
                <StyledTableCell align="left" key={index} style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px'}}>
                  <Box style={customealltheme.palette.TableHead}>
                    <Box>{h}</Box>
                    {sortValues[index] === true &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0.5'}}
                          onClick={(e)=>{
                            dispatch(setSortFilter(values[index]));
                            if (sorttype === 'asc' ) {
                              dispatch(setSortType('desc'));
                            } if (sorttype === 'desc' || sorttype === '') {
                              dispatch(setSortType('asc'));
                            }
                          }}
                          onMouseOver={(e)=>{
                            e.target.style.opacity = '1';
                          }}
                          onMouseLeave={(e)=>{
                            e.target.style.opacity = '0.5';
                          }}
                        > <SwapVertIcon />{sorttype}</Box>}

                  </Box>

                </StyledTableCell>
              ))}
              {accessControl===Edit && <StyledTableCell align="left">Action</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (data == null || data?.length == 0) && <StyledTableRow>
                <StyledTableCell colSpan={heads.length} component="th" scope="row">
                      No records found

                </StyledTableCell>
              </StyledTableRow>
            }
            {getDataForTable(data, page, pagination)
                ?.map((row, key) => (
                  <CommonConfSingleRow
                    mappedData = {mappedData}
                    row={row}
                    key={key}
                    setAddingTrigger={setAddingTrigger}
                    addingTrigger={addingTrigger}
                    values={values}
                    editValues={editValues} dateValues={dateValues}
                    idKey={idKey}
                    singleUpdate={singleUpdate}
                    deleteBuyPlanRow={deleteBuyPlanRow}
                    setErrMsg={setErrMsg} Units={Units}
                    buyplanPersona={buyplanPersona}
                    setIsErrorPopupVisible={setIsErrorPopupVisible}
                    accessControl={accessControl}
                    editModeDisableTrigger={editModeDisableTrigger}
                    page = {page}
                    Currency={Currency}
                    ActiveSectors={ActiveSectors}
                    country={country}
                    Playbooks={Playbooks}
                    editBuyPlanRow={editBuyPlanRow}
                  />
                ))}

          </TableBody>
        </Table>
      </TableContainer>

      {data.length>0 && <TablePagination
        rowsPerPageOptions={[2, 5, 10, 15]}
        component="div"
        count={getTotalNoOfRows(data).curCount || 0}
        rowsPerPage={pagination}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />}
    </Box>
  );
}

export default CommonConfTable;
