
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ForecastDisplay from '../../Components/Forecast/ForecastDisplay';


const Forecast = (props) => {
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const [haveAccessToPage, setHaveAccessToPage]=useState(null);
  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Forecast']) {
      // setAccessControlFxRates(functionalityAccess['Forecast']);
        setHaveAccessToPage(true);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);
  return (
    <div>
      {haveAccessToPage && <ForecastDisplay />}
    </div>
  );
};

export default Forecast;
