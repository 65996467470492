// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numberCont{
    display: flex;
    gap: 1px;
    padding: 5px;
    width: 200px;
    flex-wrap: wrap;
}
.numberCont>div{
    padding: 5px 8px;
    cursor: pointer;
}

.numberCont>div:hover:not(.selectedNum){
    background: rgb(242, 234, 234);
    border-radius: 50%;
}

.selectedNum{
    background: #215094;
    color: white;

}

.roleCont{
    display: flex;
    gap: 1px;
    padding: 5px;
    width: 350px;
    flex-wrap: wrap;
}
.roleCont>div{
    padding: 5px 8px;
    cursor: pointer;
}

.roleCont>div:hover:not(.selectedNum){
    background: rgb(242, 234, 234);
}
.notiPopupInput{
    width: 100%;
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Configurations/NotificationPage/NotificationSettings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".numberCont{\n    display: flex;\n    gap: 1px;\n    padding: 5px;\n    width: 200px;\n    flex-wrap: wrap;\n}\n.numberCont>div{\n    padding: 5px 8px;\n    cursor: pointer;\n}\n\n.numberCont>div:hover:not(.selectedNum){\n    background: rgb(242, 234, 234);\n    border-radius: 50%;\n}\n\n.selectedNum{\n    background: #215094;\n    color: white;\n\n}\n\n.roleCont{\n    display: flex;\n    gap: 1px;\n    padding: 5px;\n    width: 350px;\n    flex-wrap: wrap;\n}\n.roleCont>div{\n    padding: 5px 8px;\n    cursor: pointer;\n}\n\n.roleCont>div:hover:not(.selectedNum){\n    background: rgb(242, 234, 234);\n}\n.notiPopupInput{\n    width: 100%;\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
