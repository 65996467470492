import {Box, Checkbox, useMediaQuery} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from 'react-redux';
import {setBuyPlanFilter} from '../../../redux/features/configurations';
import {useState} from 'react';
import {useEffect} from 'react';
import theme from '../../../theme';
import _ from 'lodash';
const sidebarD = {
  width: '446px',
  height: '665px',
  background: 'white',
  opacity: '1.8',
  top: '-238px',
  right: '-20px',
  position: 'absolute',
  zIndex: '10002',
  opacity: '100%',
};
const menus = {
  color: '#3B4655',
  font: 'normal normal 600 12px/16px Segoe UI',
  fontSize: '12px',
  fontWeight: ' Semibold',
  display: 'flex',
  justifyContent: 'flex-end',
  cursor: 'pointer',
  width: '100%',
  padding: '10px',
};

const checkValues={
  font: 'normal normal normal 12px/16px Segoe UI',
  fontWeight: 'Regular',
  fontWeight: '600',
};

const flexBox={display: 'flex', alignItems: 'center'};

const SidebarBuyPlan = ({handleSideBar, setPlayBookId, setBuyplanapprover, setBuyplanadmin, setForecastcreator, setBuyplancreator, setbuyOwner, setPlayBookName, setBuyPlanName, handleSubmit, setBuyPlanId, row, setValueCategory, setValueCommodity, setValueRegion, setValueSector, setValueSubCommodity, setMasterValueSector, setCurrentStatusValue, setFutureStatusValue}) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState('play_book_name');
  const [BuyPlanIds, setBuyPlanIds] = useState([]);
  const [PlayBookIds, setPlayBookIds] = useState([]);
  const [BuyPlanName, setBuyPlanNames] = useState([]);
  const [PlayBookNames, setPlayBookNames] = useState([]);
  const [Buyplanapprovers, setBuyplanapprovers] = useState([]);
  const [Buyplanadmins, setBuyplanadmins]=useState([]);
  const [Forecastcreators, setForecastcreators]=useState([]);
  const [Buyplancreators, setBuyplancreators] = useState([]);
  const [allplayIds, setAllPlayIds] = useState([]);
  const [allPlayNames, setallPlayNames] = useState([]);
  const [buyPlanCreatorList, setBuyPlanCreatorList] = useState([]);
  const [buyPlanApproverList, setBuyPlanApproverList] = useState([]);
  const [buyPlanAdminList, setBuyPlanAdminList] = useState([]);
  const [forecastCreatorList, setForecastCreatorList] = useState([]);
  const [uniqueBuyPlanNames, setUniqueBuyPlanNames] =useState([]);
  const [currentStatus, setCurrentStatus]= useState([]);
  const [futureStatus, setFutureStatus]= useState([]);
  const currentStatusList = [
    {current_status: 'ACTIVE'},
    {current_status: 'INACTIVE'},
    {current_status: 'COV_SUSPENDED'},
    {current_status: 'REVIEW_SUSPENDED'}];

  const futureStatusList = [
    {future_status: 'ACTIVE'},
    {future_status: 'INACTIVE'},
    {future_status: 'COV_SUSPENDED'},
    {future_status: 'REVIEW_SUSPENDED'}];
  console.log(row);

  const formatStatus = (selectedValue) => {
    switch (selectedValue) {
      case 'Active':
        return 'ACTIVE';
      case 'Inactive':
        return 'INACTIVE';
      case 'Coverage Suspension':
        return 'COV_SUSPENDED';
      case 'Coverage Review Suspension':
        return 'REVIEW_SUSPENDED';
      case 'ACTIVE':
        return 'Active';
      case 'INACTIVE':
        return 'Inactive';
      case 'COV_SUSPENDED':
        return 'Coverage Suspension';
      case 'REVIEW_SUSPENDED':
        return 'Coverage Review Suspension';
      default:
        return '';
    }
  };

  const selectedDropDownData = useSelector((state)=>state?.configurations?.selectedBuyPlanFilterValue);
  console.log(selectedDropDownData);

  const buyPlanIdHandle = (e) => {
    if (e.target.checked) {
      setBuyPlanIds((prev)=>[...prev, +e.target.value]);
    } else {
      setBuyPlanIds(BuyPlanIds.filter((pl)=>{
        return +pl!==+e.target.value;
      }));
    }
  };

  const playBookIdHandle =(e) =>{
    if (e.target.checked) {
      setPlayBookIds((prev)=>[...prev, +e.target.value]);
    } else {
      setPlayBookIds(PlayBookIds.filter((pl)=>{
        return +pl!==+e.target.value;
      }));
    }
  };

  const buyPlanNameHandle = (e) => {
    if (e.target.checked) {
      setBuyPlanNames((prev)=>[...prev, e.target.value]);
    } else {
      setBuyPlanNames(BuyPlanName.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  const playBookNameHandle = (e) => {
    if (e.target.checked) {
      setPlayBookNames((prev)=>[...prev, e.target.value]);
    } else {
      setPlayBookNames(PlayBookNames.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  const buyPlanCreatorHandle = (e) => {
    if (e.target.checked) {
      setBuyplancreator((prev)=>[...prev, e.target.value]);
    } else {
      setBuyplancreators(Buyplancreators?.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  const buyPlanApproverHandle = (e) => {
    if (e.target.checked) {
      setBuyplanapprovers((prev)=>[...prev, e.target.value]);
    } else {
      setBuyplanapprovers(Buyplanapprovers.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  const buyPlanAdminHandle = (e) => {
    if (e.target.checked) {
      setBuyplanadmins((prev)=>[...prev, e.target.value]);
    } else {
      setBuyplanadmins(Buyplanadmins.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  const forecastCreatorHandle = (e) => {
    if (e.target.checked) {
      setForecastcreators((prev)=>[...prev, e.target.value]);
    } else {
      setForecastcreators(Forecastcreators.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  const currentStatusHandle = (e) => {
    if (e.target.checked) {
      setCurrentStatus((prev)=>[...prev, e.target.value]);
    } else {
      setCurrentStatus(currentStatus.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  const futureStatusHandle = (e) => {
    if (e.target.checked) {
      setFutureStatus((prev)=>[...prev, e.target.value]);
    } else {
      setFutureStatus(futureStatus.filter((pl)=>{
        return pl!==e.target.value;
      }));
    }
  };

  useEffect(()=>{
    setBuyPlanName(BuyPlanName);
    setBuyPlanId(BuyPlanIds);
    setPlayBookId(PlayBookIds);
    setPlayBookName(PlayBookNames);
    setBuyplanapprover(Buyplanapprovers);
    setBuyplanadmin(Buyplanadmins);
    setCurrentStatusValue(currentStatus);
    setFutureStatusValue(futureStatus);
    setForecastcreator(Forecastcreators);
    setBuyplancreator(Buyplancreators);
  }, [BuyPlanIds, BuyPlanName, PlayBookIds, PlayBookNames, Buyplanapprovers, Buyplanadmins, Forecastcreators, Buyplancreators, currentStatus, futureStatus]);

  useEffect(()=>{
    if (Object.keys(selectedDropDownData).length>0) {
      setBuyPlanIds(selectedDropDownData['buy_plan_id']);
      setBuyPlanNames(selectedDropDownData['buy_plan_name']);
      setPlayBookIds(selectedDropDownData['play_book_id']);
      setPlayBookNames(selectedDropDownData['play_book_name']);
      setBuyplanapprovers(selectedDropDownData['buyplan_approver']);
      setBuyplanadmins(selectedDropDownData['buyplan_admin']);
      setForecastcreators(selectedDropDownData['forecast_creator']);
      setBuyplancreators(selectedDropDownData['buyplan_creator']);
      setCurrentStatus(selectedDropDownData['current_status']);
      setFutureStatus(selectedDropDownData['future_status']);
    }
  }, [selectedDropDownData]);

  const handleSidSubmit=()=>{
    handleSubmit();
    handleSideBar();
  };

  const matches = useMediaQuery('(min-height:767px)');

  const handleRest = () =>{
    dispatch(setBuyPlanFilter([]));
    setValueCategory([]);
    setValueCommodity([]);
    setValueRegion([]);
    setValueSector([]);
    setValueSubCommodity([]);
    setMasterValueSector([]);
    setbuyOwner([]);

    setBuyPlanIds([]);
    setBuyPlanNames([]);
    setPlayBookIds([]);
    setBuyplanapprovers([]);
    setBuyplanadmins([]);
    setForecastcreators([]);
    setBuyplancreators([]);
    setBuyplancreator([]);
    setPlayBookNames([]);
    setBuyPlanName([]);
    setBuyPlanId([]);
    setPlayBookId([]);
    setPlayBookName([]);
    setBuyplanapprover([]);
    setBuyplanadmin([]);
    setCurrentStatusValue([]);
    setFutureStatusValue([]);
    setForecastcreator([]);
    handleSideBar();
  };

  useEffect(()=>{
    const playIds = [];
    const playNames = [];
    row.map((r)=>{
      if (!playIds.includes(r.play_book_id)) {
        playIds.push(r.play_book_id);
        playNames.push(r.play_book_name);
      }
      setAllPlayIds([...allplayIds, ...playIds]);
      setallPlayNames([...allPlayNames, ...playNames]);
    });
    setBuyPlanCreatorList(_.uniqBy(row, 'buyplan_creator')?.filter((r) => r.buyplan_creator));
    setBuyPlanApproverList(_.uniqBy(row, 'buyplan_approver')?.filter((r) => r.buyplan_approver));
    setBuyPlanAdminList(_.uniqBy(row, 'buyplan_admin')?.filter((r) => r.buyplan_admin));
    setForecastCreatorList(_.uniqBy(row, 'forecast_creator')?.filter((r) => r.forecast_creator));

    const uR = row.reduce((acc, r) => {
      if (!acc.some((item) => item.buy_plan_name === r.buy_plan_name)) {
        acc.push(r);
      }
      return acc;
    }, []);
    setUniqueBuyPlanNames(uR);
  }, [row]);
  return (
    <Box>
      <Box style={{background: 'black', opacity: '46%', position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', zIndex: '10001'}}>
      </Box>
      <Box style={{position: 'relative'}}>
        <Box sx={sidebarD}>

          <Box sx={{padding: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #F7F7F7'}}>
            <Box style={{color: '#3B4655', font: 'normal normal 600 14px/19px Segoe UI', fontSize: '14px', fontWeight: 'Semibold'}}>Filters</Box>
            <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
              <Box style={{color: '#0073E4', cursor: 'pointer', font: 'normal normal 600 14px/19px Segoe UI', fontSize: '12px', fontWeight: 'Semibold'}} onClick={()=>{
                handleRest();
              }}>Reset Filters</Box>
              <Box><CloseIcon style={{width: '11px', height: '11px', cursor: 'pointer'}} onClick={handleSideBar}/></Box>
            </Box>
          </Box>
          <Box style={{display: 'flex', height: matches===true ? '552px': '400px'}}>
            {/* Left side names */}

            <Box style={{background: '#F7F7F7', width: '168px', height: matches===true ? '552px': '400px', padding: '20px 0px'}}>
              <Box sx={menus} style={{background: selected==='play_book_name' && 'white'}} onClick={()=>{
                setSelected('play_book_name');
              }}>
              Playbook Name
              </Box>
              <Box sx={menus} style={{background: selected==='buy_plan_name' && 'white'}} onClick={()=>{
                setSelected('buy_plan_name');
              }}>
              Buy Plan Name
              </Box>

              <Box sx={menus} style={{background: selected==='buyplan_creator' && 'white'}} onClick={()=>{
                setSelected('buyplan_creator');
              }}>
              Buy Plan Creator
              </Box>

              <Box sx={menus} style={{background: selected==='buyplan_approver' && 'white'}} onClick={()=>{
                setSelected('buyplan_approver');
              }}>
              Buy Plan Approver
              </Box>

              <Box sx={menus} style={{background: selected==='buyplan_admin' && 'white'}} onClick={()=>{
                setSelected('buyplan_admin');
              }}>
              Buy Plan Admin
              </Box>

              <Box sx={menus} style={{background: selected==='forecast_creator' && 'white'}} onClick={()=>{
                setSelected('forecast_creator');
              }}>
              Forecast Creator
              </Box>

              <Box sx={menus} style={{background: selected==='current_status' && 'white'}} onClick={()=>{
                setSelected('current_status');
                console.log(selected);
              }}>
              Current Status
              </Box>

              <Box sx={menus} style={{background: selected==='future_status' && 'white'}} onClick={()=>{
                setSelected('future_status');
              }}>
              Future Status
              </Box>


            </Box>

            {/* Right Side checkboxes */}
            <Box className='right' style={{background: 'white', width: '300px', height: matches===true ? '552px': '400px', padding: '20px 40px', overflowY: 'scroll'}}>
              {selected==='play_book_id' && (allplayIds?.length !==0) &&

              _.orderBy(allplayIds, [(p) => p?.trim().toLowerCase()]).map((r)=>(
                <Box key={r} style={flexBox}>
                  <Checkbox
                    name='checkbo'
                    value={r}
                    defaultChecked={(Object.keys(selectedDropDownData).length>0 && selectedDropDownData['play_book_id'].includes(r)) || PlayBookIds.includes(+r)}
                    size='small'
                    onClick={(e)=>{
                      playBookIdHandle(e);
                    }}/>
                  <span style={checkValues}>{r}</span></Box>
              ))

              }
              {selected==='play_book_name' && (allPlayNames?.length !==0) &&

              _.orderBy(allPlayNames, [(p) => p?.trim().toLowerCase()]).map((r)=>(
                <Box key={r} style={flexBox}>
                  <Checkbox
                    name='checkbo'
                    value={r}
                    defaultChecked={(Object.keys(selectedDropDownData).length>0 && selectedDropDownData['play_book_name'].includes(r)) || PlayBookNames.includes(r)}
                    size='small'
                    onClick={(e)=>{
                      playBookNameHandle(e);
                    }}/>
                  <span style={checkValues}>{r}</span></Box>
              ))

              }
              {selected==='buy_plan_id' && (row?.length !==0) &&

               _.orderBy(row, [(r) => r?.buy_plan_id.trim().toLowerCase()]).map((r)=>(
                 <Box key={r.buy_plan_id} style={flexBox}>
                   <Checkbox name='checkbo'
                     value={r.buy_plan_id}
                     defaultChecked={(Object.keys(selectedDropDownData).length>0 && selectedDropDownData['buy_plan_id'].includes(r.buy_plan_id)) || BuyPlanIds.includes(+r.buy_plan_id)}
                     size='small'
                     onClick={(e)=>{
                       buyPlanIdHandle(e);
                     }}/>
                   <span style={checkValues}>{r.buy_plan_id}</span></Box>
               ))

              }
              {selected==='buy_plan_name' && (uniqueBuyPlanNames?.length !==0) &&

                  _.orderBy(uniqueBuyPlanNames, [(b) => b.buy_plan_name?.trim().toLowerCase()]).map((r)=>(
                    <Box key={r.buy_plan_name}>
                      <Checkbox
                        name='checkbo'
                        value={r.buy_plan_name}
                        defaultChecked={(Object.keys(selectedDropDownData).length>0 && selectedDropDownData['buy_plan_name'].includes(r.buy_plan_name)) || BuyPlanName.includes(r.buy_plan_name)}
                        size='small'
                        onClick={(e)=>{
                          buyPlanNameHandle(e);
                        }}/>
                      <span style={checkValues}>{r.buy_plan_name}</span></Box>
                  ))

              }
              {selected === 'buyplan_creator' && (buyPlanCreatorList?.length !==0) &&

                _.orderBy(buyPlanCreatorList, [(b) => b.buyplan_creator?.trim().toLowerCase()])?.map((r) => (
                  <Box key={r.buyplan_creator} style={flexBox}>
                    <Checkbox
                      name='checkbo'
                      value={r.buyplan_creator}
                      defaultChecked={(Object.keys(selectedDropDownData).length > 0 && selectedDropDownData['buyplan_creator']?.includes(r.buyplan_creator)) || Buyplancreators?.includes(r.buyplan_creator)}
                      size='small'
                      onClick={(e) => {
                        buyPlanCreatorHandle(e);
                      }} />
                    <span style={checkValues}>{r.buyplan_creator}</span></Box>
                ))

              }
              {selected === 'buyplan_approver' && (buyPlanApproverList?.length !==0) &&

                _.orderBy(buyPlanApproverList, [(b) => b.buyplan_approver?.trim().toLowerCase()])?.map((r) => (
                  <Box key={r.buyplan_approver} style={flexBox}>
                    <Checkbox
                      name='checkbo'
                      value={r.buyplan_approver}
                      defaultChecked={(Object.keys(selectedDropDownData).length > 0 && selectedDropDownData['buyplan_approver']?.includes(r.buyplan_approver)) || Buyplanapprovers?.includes(r.buyplan_approver)}
                      size='small'
                      onClick={(e) => {
                        buyPlanApproverHandle(e);
                      }} />
                    <span style={checkValues}>{r.buyplan_approver}</span></Box>
                ))

              }
              {selected === 'buyplan_admin' && (buyPlanAdminList?.length !==0) &&

                _.orderBy(buyPlanAdminList, [(b) => b.buyplan_admin?.trim().toLowerCase()])?.map((r) => (
                  <Box key={r.buyplan_admin} style={flexBox}>
                    <Checkbox
                      name='checkbo'
                      value={r.buyplan_admin}
                      defaultChecked={(Object.keys(selectedDropDownData).length > 0 && selectedDropDownData['buyplan_admin']?.includes(r.buyplan_admin)) || Buyplanadmins?.includes(r.buyplan_admin)}
                      size='small'
                      onClick={(e) => {
                        buyPlanAdminHandle(e);
                      }} />
                    <span style={checkValues}>{r.buyplan_admin}</span></Box>
                ))

              }
              {selected === 'forecast_creator' && (forecastCreatorList?.length !==0) &&

                _.orderBy(forecastCreatorList, [(f) => f.forecast_creator?.trim().toLowerCase()])?.map((r) => (
                  <Box key={r.forecast_creator} style={flexBox}>
                    <Checkbox
                      name='checkbo'
                      value={r.forecast_creator}
                      defaultChecked={(Object.keys(selectedDropDownData).length > 0 && selectedDropDownData['forecast_creator']?.includes(r.forecast_creator)) || Forecastcreators?.includes(r.forecast_creator)}
                      size='small'
                      onClick={(e) => {
                        forecastCreatorHandle(e);
                      }}
                    />
                    <span style={checkValues}>
                      {r.forecast_creator}
                    </span>
                  </Box>
                ))

              }
              {selected === 'current_status' && (currentStatusList?.length !== 0) &&
                currentStatusList.map((c) => (
                  <Box key={c.current_status} style={flexBox}>
                    <Checkbox
                      name='checkbo'
                      value={c.current_status}
                      defaultChecked={(Object.keys(selectedDropDownData).length > 0 && selectedDropDownData['current_status']?.includes(c.current_status)) || currentStatus?.includes(c.current_status)}
                      size='small'
                      onClick={(e) => {
                        currentStatusHandle(e);
                      }}
                    />
                    <span style={checkValues}>{formatStatus(c.current_status)}</span>
                  </Box>
                ))
              }

              {selected === 'future_status' && (futureStatusList?.length !== 0) &&
                futureStatusList.map((c) => (
                  <Box key={c.future_status} style={flexBox}>
                    <Checkbox
                      name='checkbo'
                      value={c.future_status}
                      defaultChecked={(Object.keys(selectedDropDownData).length > 0 && selectedDropDownData['future_status']?.includes(c.future_status)) || futureStatus?.includes(c.future_status)}
                      size='small'
                      onClick={(e) => {
                        futureStatusHandle(e);
                      }}
                    />
                    <span style={checkValues}>{formatStatus(c.future_status)}</span>
                  </Box>
                ))
              }

            </Box>
          </Box>

          <Box sx={{padding: '15px', display: 'flex', gap: '10px', alignItems: 'center', borderTop: '1px solid #F7F7F7', justifyContent: 'flex-end'}}>
            <Box sx={{color: '#0073E4', font: 'normal normal 600 12px/16px Segoe UI', cursor: 'pointer'}} onClick={handleSideBar}>Cancel</Box>
            <Box sx={theme.palette.AddFilterTableButton} onClick={handleSidSubmit}>Submit</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarBuyPlan;
