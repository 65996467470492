/* eslint-disable */
import React from 'react';
import { startTransition, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { leapYear, monthArr, months, monthsCap, roundToTwo } from '../../../services/utils';
import './appTableInput.css';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { isAfterExecutionMonthYear } from '../../../Utils/Common';

export const AppTableInput = ({decider, setVarianceTrigger, focus, subMissionData=null, data, setData, setSubmissionData=null,forecastVal=[], columnName, isDisabled, rowData }) => {
    const [value, setValue] = useState('')
    const [letterFlag, setLetterFlag] = useState(false)

    // on row data change it format the number
    useEffect(() => {
        if(!isNaN(rowData[columnName]?.toString().split(',').length>1 ? rowData[columnName]?.toString().split(',').join('') : rowData[columnName])){
            // if(rowData && rowData[columnName] || !focus) setValue(formatNumber(rowData[columnName] && rowData[columnName].toString()?.replaceAll(',',''))?.toLocaleString('en-US'))
            if(rowData && rowData[columnName] || !focus) setValue(formatNumber(roundToTwo(decider, rowData[columnName] && rowData[columnName].toString()?.replaceAll(',','')))?.toLocaleString('en-US'));
            else if(rowData && rowData[columnName]==0 || !focus) setValue(formatNumber(roundToTwo(decider, rowData[columnName])))
        }
        else{
            setValue(0)
        }
    }, [rowData])

    const ref = useRef(null)

    const formatNumber2 = inputNumber => {
        let formetedNumber=(Number(inputNumber)).toFixed(decider);
        let splitArray=formetedNumber.split('.');
        if(splitArray.length>1){
            if(!((+splitArray[1]) > 0)) formetedNumber=splitArray[0];
        }
        return(formetedNumber);
      };

    const formatNumber = inputNumber => {
        let formetedNumber=(Number(inputNumber)).toFixed(decider).toString();
        let splitArray=formetedNumber.split('.');
        if(splitArray.length>1){
            if(!((+splitArray[1]) > 0)) formetedNumber=splitArray[0];
        }
        return(formetedNumber);
      };

      useEffect(()=>{
        
        let total = Object.keys(rowData).reduce((accumulator, currentValue) => {
                    
            if (months.indexOf(currentValue) >= 0) {
                
               return accumulator + (+rowData[currentValue] || 0)
            }
            else return accumulator
            },
        0)
        if(rowData.key!==3){
            rowData.fy = formatNumber(total)
        }
        // console.log('@fsct calculate the fy ------------------ total',total)
      }, [rowData])

  // This function will be called only when `focus` changes
    useLayoutEffect(() => {
        if (focus) {
        ref.current?.focus()
        } else {
        ref.current?.blur()
        }
    }, [focus])

    return <div className="app-table-input">
        {!isDisabled && <div className="app-table-input-icon"></div>}
        {!isDisabled? <input ref={ref} type="text" className={`${forecastVal[0]}-${columnName}`} 
       onFocus={(e) => {
            const value = e.target.value.split(',').join('');
            // console.log(e.target.value.split(',').join(''))
            setValue(value)
        }}
        onChange={(e) => {
            // console.log(typeof +e.target.value)
            // @#$%^&*(){}[]:"/,
            if(((!isNaN(e.target.value)) || (e.target.value[1]=='-') || (e.target.value[0]=='-' && !isNaN(+e.target.value.slice(1,))) ) && !e.target.value.includes('!') && !e.target.value.includes('@') && !e.target.value.includes('#') && !e.target.value.includes('$') && !e.target.value.includes('%') && !e.target.value.includes('^') && !e.target.value.includes('&') && !e.target.value.includes('*') && !e.target.value.includes('(') && !e.target.value.includes(')') && !e.target.value.includes('=') && !e.target.value.includes('+')){
                // const value = e.target && e.target.value ? parseFloat(e.target.value) : 0;
                rowData[columnName] = e.target.value;
                // console.log(e.target.value[0])
                // if(e.target.value[1]=='-' && !e.target.value[0] == 0){
                //     e.target.value = -e.target.value[0]
                // }
                
                // to remove the case like 010 to 10 and 024 to 24 also make sure it is not 0.12
                if(e.target.value[0] == 0 && e.target.value[1] !== '.') {
                    e.target.value = e.target.value.slice(1,)
                }
                
                // e.target.value = formatNumber2(e.target.value);
                let total =  Object.keys(rowData).reduce((accumulator, currentValue) => {
                    
                    if (months.indexOf(currentValue) >= 0) {
                        
                       return accumulator + (+rowData[currentValue] || 0)
                    }
                    else return accumulator
                    },
                0)
                if(rowData.key!==3){
                    rowData.fy = formatNumber(total)
                } else {
                    rowData.fy = rowData.fy
                }
                if(data.length){
                    data[1] = rowData;
                    setData([...data])
                }
                // if(Math.abs((+data[0][columnName] - +data[1][columnName])/+data[0][columnName])* 100>10){
                //     e.target.style.borderColor='red'
                // } else {
                //     e.target.style.borderColor=''
 
                // }
                if(subMissionData) {
                    const payloadData = {
                        "workflowId":rowData.workflow_id,
                        "forecastYearMonth":[+forecastVal[0],+forecastVal[1]],
                        "forecastValue":+value
                    }
                    if(!subMissionData[columnName + forecastVal[0]]) subMissionData[columnName + forecastVal[0]] = payloadData
                    else subMissionData[columnName + forecastVal[0]] = payloadData
                    setSubmissionData({...subMissionData})
                }
                let splitArray=e.target.value.split('.');

                // allow to enter till 5 decimal and format into 2 decimal
                if(splitArray.length>1){
                    if(splitArray[1].length > 5) {
                        splitArray[1] = splitArray[1].substring(0, splitArray[1].length-1);
                        // const inputValue = splitArray && splitArray.join('.') ? parseFloat(splitArray.join('.')) : 0;
                        setValue(splitArray.join('.'))
                    } else {
                        let value = e.target && e.target.value ? e.target.value : 0;
                        setValue(value)
                    }
                    
                } else {
                    const inputValue = e.target && e.target.value ? e.target.value : 0;
                    setValue(inputValue)
                }
                
            }
            setVarianceTrigger(prev=>prev+1)
            setLetterFlag(false)            
        }}
        onKeyDown={(e) => {
            if((e.keyCode >=65 && e.keyCode <= 90) || ((e.keyCode >= 106 && e.keyCode <= 109) || e.keyCode == 111)) {
                setLetterFlag(true)
            }
        }}

        onBlur={(e) => {
           if(e.target.value !== '-'){
            let value = e.target && e.target.value ? parseFloat(e.target.value) : 0;
            // if decimal round it 2 decimal
            if(value % 1 !==0 ) {
                value = parseFloat(value);
            }
            // rowData[columnName] = value;
            // setValue(formatNumber(value))
            if (decider === 0){
                const roundValue = roundToTwo(decider, value);
                rowData[columnName] = roundValue;
                setValue(formatNumber(roundValue))
            } else {
                rowData[columnName] = value;
                setValue(formatNumber(roundToTwo(decider, value)));
            }
            // calculate fy total
            let total = Object.keys(rowData).reduce((accumulator, currentValue) => {
                if (months.indexOf(currentValue) >= 0) return accumulator + (+rowData[currentValue] || 0)
                else return accumulator
                },
            0)
            if(rowData.key!==3){
                rowData.fy = formatNumber(total)
            } else {
                rowData.fy = rowData.fy
            }

            // if(data.length){
            //     data[1] = rowData;
            //     setData([...data])
            // }
            // if(Math.abs((+data[0][columnName] - +data[1][columnName])/+data[0][columnName])* 100>10){
            //     e.target.style.borderColor='red'
            // } else {
            //     e.target.style.borderColor=''

            // }
            if(subMissionData) {
                const payloadData = {
                    "workflowId":rowData.workflow_id,
                    "forecastYearMonth":[+forecastVal[0],+forecastVal[1]],
                    "forecastValue":+value
                }
                if(!subMissionData[columnName + forecastVal[0]]) subMissionData[columnName + forecastVal[0]] = payloadData
                else subMissionData[columnName + forecastVal[0]] = payloadData
                setSubmissionData({...subMissionData})
            }
           }
           else{
            rowData[columnName] = 0
            setValue(0)
           }
           setVarianceTrigger(prev=>prev+1)
        }}
         disabled={isDisabled} value={value} /> : `${formatNumber(rowData[columnName])=='-0'? 0 : formatNumber(rowData[columnName])}${rowData.key==3?'%': ''}`}
    </div>
};

export const AppTableInput2 = ({editableInput,decider, handleEdit, columnName, rowIndex, rowData, focus, selectedRow }) => {
    const [value, setValue] = useState('');
    const [letterFlag, setLetterFlag] = useState(false);
    const data = useSelector((state) => state.buyPlanData.value);
    const isEdit = useSelector((state) => state.editStatus.value);
    const previousMonthWillCoverEditButton = useSelector((state) => state.editStatus.previousMonthWillCoverEditButton);
    const ref = useRef(null)
    const month = monthArr.indexOf(rowData.period)+1;
    const negitiveValueReasons = useSelector((state) => state.buyPlanData.negitiveValueReasons);
    // useEffect(() => {
    //     // console.log(rowData[columnName]?.toString().split(',').join(''))
    //     if (rowData && !+rowData[columnName]?.toString().split(',').join('') || !focus) {
    //         if(+rowData[columnName]?.toString().split(',').join('') !== null && isNaN(+rowData[columnName]?.toString().split(',').join(''))) {
    //             setValue(+rowData[columnName]?.toString().split(',').join(''));
    //         } else {
    //             setValue(+rowData[columnName]?.toString().split(',').join(''));
    //         }
    //     } else if (rowData && +rowData[columnName]?.toString().split(',').join('') || !focus) {
    //         setValue(+rowData[columnName]?.toString().split(',').join(''));
    //     } else if (rowData && +rowData[columnName]?.toString().split(',').join('') == 0 || !focus) {
    //         setValue(+rowData[columnName]?.toString().split(',').join(''));
    //     } else {
    //         setValue(+rowData[columnName]?.toString().split(',').join(''));
    //     }
    // }, [rowData]);

    useEffect(() => {
        let newVal;
        if(selectedRow?.isLotSizeEnabled &&  columnName === 'twillCover' && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, rowData?.period, rowData?.planYear)) {
            newVal = roundToTwo(decider, +rowData[columnName]?.toString().split(',').join(''), selectedRow?.isLotSizeEnabled, selectedRow?.lotSizeToLocalUnit);
        } else {
            newVal = roundToTwo(decider, +rowData[columnName]?.toString().split(',').join(''));
        }
        if (rowData && !+rowData[columnName]?.toString().split(',').join('') || !focus) {
            if(+rowData[columnName]?.toString().split(',').join('') !== null && isNaN(newVal)) {
                setValue(+rowData[columnName]?.toString().split(',').join(''));
            } else {
                setValue(newVal);
            }
        } else if (rowData && +rowData[columnName]?.toString().split(',').join('') || !focus) {
            setValue(+rowData[columnName]?.toString().split(',').join(''));
        } else if (rowData && +rowData[columnName]?.toString().split(',').join('') == 0 || !focus) {
            setValue(newVal);
        } else {
            setValue(newVal);
        }
    }, [rowData]);

    useLayoutEffect(() => {
        if (focus) {
        ref.current?.focus()
        } else {
        ref.current?.blur()
        }
    }, [focus])

    const formatNumber = inputNumber => {
        let formetedNumber=(Number(inputNumber)).toFixed(decider).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        let splitArray=formetedNumber.split('.');
        if(splitArray.length>1){
            if(!((+splitArray[1]) > 0)) formetedNumber=splitArray[0];
        }
        return(formetedNumber);
      };
    const handleChange = (event) => {
        // console.log(event.target.value)
       if((!isNaN(event.target.value)) || event.target.value[1]=='-' || event.target.value=='-' || event.target.value[1]=='.' || event.target.value=='.' || event.target.value == 0){
        if( (event.target.value[0]==0 &&  event.target.value[1]!== '-' && typeof +event.target.value[1]== 'number' && event.target.value[1]!==undefined && event.target.value[1]!=='.')){
            event.target.value = event.target.value.slice(1,)
            handleEdit(columnName, rowData.planYear, rowIndex, +event.target.value , data, +event.target.value, false);
            setValue(event.target.value)
            setLetterFlag(false)
        }
        else if((event.target.value[0]==0 && event.target.value[1]== '-')){
            event.target.value = '-'
            setValue(event.target.value)
            setLetterFlag(false)
        }
        else if((event.target.value[0]=='-' && event.target.value[1]== 0)){
            // event.target.value = -event.target.value
            if(event.target.value.split('.').length>1){
                if(event.target.value.split('.')[1].length<=5){
                    if(event.target.value.split('.')[1]>1){
                        handleEdit(columnName, rowData.planYear, rowIndex, +event.target.value , data, +event.target.value, false);
    
                    }
                    setValue(event.target.value)
                    setLetterFlag(false)
                }
            }
            else{
                setValue(event.target.value)
            }
            
            // if(Math.abs(event.target.value)>0){
            //     handleEdit(columnName, rowData.planYear, rowIndex, event.target.value , data, event.target.value);
            
            // }
            // setLetterFlag(false)
        }
        else if(event.target.value.split('.').length>1){
            if(event.target.value.split('.')[1].length<=5){
                if(event.target.value.split('.')[1]>1){
                    handleEdit(columnName, rowData.planYear, rowIndex, +event.target.value , data, +event.target.value, false);

                }
                setValue(event.target.value)
                setLetterFlag(false)
            }
        }
        else{
             handleEdit(columnName, rowData.planYear, rowIndex, +event.target.value , data, +event.target.value, false);
             setValue(event.target.value)
             setLetterFlag(false)
        }
        
       }
       else{
        setLetterFlag(false)
       }
       
    };
    const handleBlur = (e) => {
        let newVal = e.target.value
        if(selectedRow?.isLotSizeEnabled && (columnName === 'twillCover' && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, rowData?.period, rowData?.planYear)) ){
            newVal = roundToTwo(decider, e.target.value, selectedRow?.isLotSizeEnabled, selectedRow?.lotSizeToLocalUnit); 
        }
        else if (decider === 0) {
            newVal = roundToTwo(decider, e.target.value);
        }
        if(!(e.target.value.length)) {
            setValue('')
            handleEdit(columnName, rowData.planYear, rowIndex, newVal || '', data, value, true);
        }
        if(!letterFlag && e.target.value.length){
            e.target.value = e.target.value.length? parseFloat(e.target.value): '';
            handleEdit(columnName, rowData.planYear, rowIndex, newVal, data, value, true);
            setValue(newVal)
        }
        
        
    }

    const dropdownRef = useRef(null)
    const [negitiveInfo, setNegitiveInfo] = useState(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            dropdownRef.current.classList.remove('show');
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropdownRef]);
    useEffect(()=>{
        if(rowData[`${columnName}NegitiveOption`]!==null && rowData[`${columnName}NegitiveOption`]!==undefined){
            setNegitiveInfo(negitiveValueReasons?.find(p=>p.id==rowData[`${columnName}NegitiveOption`])?.reason)
        }

    }, [rowData])
    useEffect(()=>{
        if(+value>0 && negitiveInfo!==null){
            setNegitiveInfo(null);
            handleEdit(`${columnName}NegitiveOption`, rowData.planYear, rowIndex, null , data, null);
        }
    }, [value])
    return (
      <div className="app-table-input" style={{postion: 'relative'}}>
        {(!isEdit || (previousMonthWillCoverEditButton == true && previousMonthWillCoverEditButton !== '')) && (
          <div className="app-table-input-icon"></div>
        )}
        {(!isEdit || (previousMonthWillCoverEditButton == true && previousMonthWillCoverEditButton !== '')) &&
        rowIndex !== 12 &&
        rowIndex !== 25 &&
        rowIndex !== 38 &&
        rowIndex !== 39 ? (
          <>
            <input
              style={{width: '60px'}}
              type="text"
              onWheel={(e) => e.target.blur()}
              ref={ref}
              onKeyDown={(e) => {
                if ((e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 106 && e.keyCode <= 109) || e.keyCode == 111) {
                  setLetterFlag(true);
                }
                const dropDown = document.getElementsByClassName(`negiveValueDrop`)
                for(let i=0; i< dropDown.length;i++){
                    dropDown[i].classList.remove('show')
                }
              }}
              onBlur={(e) => handleBlur(e)}
              onChange={(e) => handleChange(e)}
              value={value === '' ? '' : value}
            />
            {(+value < 0 && columnName!=='sCarryValue' && (columnName!=='overAllWillCover' || (columnName=='overAllWillCover' && selectedRow.coverageMode=='NONE' && selectedRow.others==false))) && (
              <div>
                <div style={{display: 'flex'}}>
                {!editableInput(rowData, month) &&  <div id={`negitiveOptions-selector-${rowIndex}-${columnName}`} style={{cursor: 'pointer'}} onClick={(e)=>{
                     const dropDown = document.getElementsByClassName(`negiveValueDrop`)
                     for(let i=0; i< dropDown.length;i++){
                        if(dropDown[i].getAttribute('id')!==`negitiveOptions-${rowIndex}-${columnName}`){      
                         dropDown[i].classList.remove('show')
                        }
                     }
                    document.getElementById(`negitiveOptions-${rowIndex}-${columnName}`).classList.toggle('show')
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{color: 'red'}} name={`negitiveOptions-selector-${rowIndex}-${columnName}`} width="20" height="20" viewBox="0 0 24 24">
                    <path name={`negitiveOptions-selector-${rowIndex}-${columnName}`}
                      fill="currentColor"
                      d="M11.475 14.475L7.85 10.85q-.075-.075-.112-.162T7.7 10.5q0-.2.138-.35T8.2 10h7.6q.225 0 .363.15t.137.35q0 .05-.15.35l-3.625 3.625q-.125.125-.25.175T12 14.7t-.275-.05t-.25-.175"
                    />
                  </svg>
                </div>}
                {negitiveInfo!==null &&
                <Tooltip style={{background: 'white'}} arrow title={negitiveValueReasons?.find(p=>p.id==rowData[`${columnName}NegitiveOption`])?.reason} disableInteractive>
                    <div style={{cursor: 'pointer'}}  
                // onClick={(e)=>{
                //      const dropDown = document.getElementsByClassName(`negiveValueDrop`)
                //      for(let i=0; i< dropDown.length;i++){
                //         if(dropDown[i].getAttribute('id')!==`negitiveOptions-past-${rowIndex}-${columnName}`){      
                //          dropDown[i].classList.remove('show')
                //         }
                //      }
                //     document.getElementById(`negitiveOptions-past-${rowIndex}-${columnName}`).classList.toggle('show')
                // }}
                >
                <svg style={{ transform: 'translate(-8px, 2px)'}} xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 24 24"><path fill="currentColor" d="M12 7q-.825 0-1.412-.587T10 5t.588-1.412T12 3t1.413.588T14 5t-.587 1.413T12 7m-1.5 14V9h3v12z"/></svg>
                </div>
                </Tooltip>
                 }
                
               
                </div>
                <div id={`negitiveOptions-${rowIndex}-${columnName}`} ref={dropdownRef} className={`negiveValueDrop ${columnName}`} style={{position: 'absolute', left: '-18px', zIndex: '20', width: '200px', fontSize: '14px'}}>
                    {negitiveValueReasons?.map((p)=>(
                    <div onClick={(e)=>{
                        document.getElementById(`negitiveOptions-${rowIndex}-${columnName}`).classList.remove('show')
                        handleEdit(`${columnName}NegitiveOption`, rowData.planYear, rowIndex, p.id , data, p.id);
                        setNegitiveInfo(p.reason)
                    }}>{p.reason}</div>
                    ))}
                </div>
                {/* existing selected negitive option */}
                
                {/* <div onClick={(e)=>{
                        document.getElementById(`negitiveOptions-past-${rowIndex}-${columnName}`).classList.remove('show')
                    }} id={`negitiveOptions-past-${rowIndex}-${columnName}`} className={`negiveValueDrop ${columnName}`} style={{position: 'absolute', left: '-18px', zIndex: '20'}}>
                  {rowData[`${columnName}NegitiveOption`]}
                </div> */}
              </div>
            )}
          </>
        ) : (
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><div
                        style={{
                            fontWeight: (rowIndex == 12 || rowIndex == 25 || rowIndex == 38 || rowIndex == 39) && 600,
                            color: (rowIndex == 12 || rowIndex == 25 || rowIndex == 38 || rowIndex == 39) && 'black',
                            fontSize: '11px',
                            marginTop: '3px',
                        }}
                    >
                        {(columnName === 'twillCover' && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, rowData?.period, rowData?.planYear)) ? roundToTwo(decider, value, selectedRow?.isLotSizeEnabled, selectedRow?.lotSizeToLocalUnit)?.toLocaleString('en-US') : roundToTwo(decider, value)?.toLocaleString('en-US')}
                    </div><div>
                            {((rowIndex !== 12 && rowIndex !== 25 && rowIndex !== 38 &&  rowIndex !== 39) && negitiveInfo !== null && +value<0 && columnName!=='sCarryValue'  && (columnName!=='overAllWillCover' || (columnName=='overAllWillCover' && selectedRow.coverageMode=='NONE' && selectedRow.others==false))) && 
                           <Tooltip style={{background: 'white'}} arrow title={negitiveValueReasons?.find(p=>p.id==rowData[`${columnName}NegitiveOption`])?.reason} disableInteractive>
                             <div style={{ cursor: 'pointer' }}
                            // onClick={(e) => {
                            //     const dropDown = document.getElementsByClassName(`negiveValueDrop`);
                            //     for (let i = 0; i < dropDown.length; i++) {
                            //         if (dropDown[i].getAttribute('id') !== `negitiveOptions-preEdit-past-${rowIndex}-${columnName}`) {
                            //             dropDown[i].classList.remove('show');
                            //         }
                            //     }
                            //     document.getElementById(`negitiveOptions-preEdit-past-${rowIndex}-${columnName}`).classList.toggle('show');
                            // } }
                            >
                                <svg style={{ transform: 'translate(0px, 2px)'}} xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 24 24"><path fill="currentColor" d="M12 7q-.825 0-1.412-.587T10 5t.588-1.412T12 3t1.413.588T14 5t-.587 1.413T12 7m-1.5 14V9h3v12z" /></svg>
                            </div>
                           </Tooltip>
                           }
                           {/* without tooltip */}
                            {/* <div onClick={(e)=>{
                        document.getElementById(`negitiveOptions-preEdit-past-${rowIndex}-${columnName}`).classList.remove('show')
                    }} id={`negitiveOptions-preEdit-past-${rowIndex}-${columnName}`} className={`negiveValueDrop ${columnName}`} style={{position: 'absolute', left: '-18px', zIndex: '20'}}>
                  {rowData[`${columnName}NegitiveOption`]}
                </div> */}
                        </div></div>
        )}
      </div>
    );
};
