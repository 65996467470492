/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import './BuyPlanList.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import {getDropDownData_API, setSelectedDropDownData} from '../../../../redux/features/apiCall';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {buyplanStatusArr, generateMonthDropDownValues} from '../../../../services/utils';
import config from '../../../../Utils/Config';
import _ from 'lodash';
import ConfigurationServices from '../../../../services/ConfigurationServices';

const READ = config.AccessLevel.Read;

const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
};

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            'marginInlineEnd': '1rem',
            'width': '5px',
          },
          '*::-webkit-scrollbar-track': {
            'marginInlineEnd': '1rem',
            'background': '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            'background': '#a6a7a8',
            'borderRadius': '2px',
            'marginInlineEnd': '1rem',

          },
          '.MuiMenu-paper': {
            'maxHeight': '150px',
            'overflowY': 'auto',
            'width': '100px',
            'whiteSpace': 'nowrap',
            'textOverflow': 'ellipsis',
          },
        },
      },
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

let planMonths;

function BuyPlanList() {
  const [value, setValue] = React.useState([]);
  const selectedDropDownData = useSelector((state)=>state?.api?.selectedDropDownData);

  const [valueCategory, setValueCategory] = React.useState([]);
  const [valueCommodity, setValueCommodity] = React.useState([]);
  const [valueSubCommodity, setValueSubCommodity] = React.useState([]);

  const [buyplanCre, setbuyplanCre] = React.useState([]);
  const [valueSector, setValueSector] = React.useState([]);
  const [valueRegion, setValueRegion] = React.useState([]);
  const [valuePlanMonth, setValuePlanMonth] = React.useState([]);
  const [buyplanStatusValue, setBuyplanStatusValue] = React.useState([]);
  const [selectedCommodities, setSelectedCommodities] = React.useState([]);
  const [selectedSubCommodities, setSelectedSubCommodities] = React.useState([]);
  const [selectedRegionIdList, setSelectedRegionIdList] = React.useState([]);
  const [buyerNameIdList, setBuyerNameIdList] = useState([]);
  const [isSectorSelected, setIsSectorSelected] = React.useState();
  const [isCategorySelected, setIsCategorySelected] = React.useState();
  const [isCommoditySelected, setIsCommodiitySelected] = React.useState();
  const [isOpen, setIsOpen] = React.useState(false);

  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const [sectorGroupsFromApi, setSectorGroupsFromApi] = useState([]);
  const [commodityGroupsFromApi, setcommodityGroupsFromApi] = useState([]);
  const [subCommodityGroupsFromApi, setSubCommodityGroupsFromApi] = useState([]);

  const [buyplanPersona, setBuyPlanPersona] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getBuyPlanCreatorsList()
        .then((res)=>{
          setBuyPlanPersona({'buyplan_creator': res});
        });
  }, []);
  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Home_Page:Buyer_Name_DropDown']) {
      setAccessControl(functionalityAccess['Home_Page:Buyer_Name_DropDown']);
    }
  }, [functionalityAccess]);

  const apiRespDropdownData = useSelector((state)=>state?.api?.apiValueDropdownData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDropDownData_API());
  }, []);

  const handleChange = (event) => {
    const {
      target: {value},
    } = event;
    setValue(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangePlanMonth = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'month_all') {
      const str = ['month_all'];
      planMonths.map((row) => str.push(row));
      setValuePlanMonth(str);
    } else if (valuePlanMonth.includes('month_all') && !value.includes('month_all')) {
      setValuePlanMonth([]);
    } else {
      if (valuePlanMonth.includes('month_all')) setValuePlanMonth([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('month_all')) tepVal.splice(tepVal.indexOf('month_all'), 1);
      setValuePlanMonth(tepVal);
    }
  };

  const handleChangeBuyPlanStatus = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'status_all') {
      const str = ['status_all'];
      buyplanStatusArr.map((row) => str.push(row));
      setBuyplanStatusValue(str);
    } else if (buyplanStatusValue.includes('status_all') && !value.includes('status_all')) {
      setBuyplanStatusValue([]);
    } else {
      if (buyplanStatusValue.includes('status_all')) setBuyplanStatusValue([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('status_all')) tepVal.splice(tepVal.indexOf('status_all'), 1);
      setBuyplanStatusValue(tepVal);
    }
  };

  const handleChangeRegion = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      if (!isSectorSelected) apiRespDropdownData?.regions.map((row) => str.push(row?.region));
      else selectedRegionIdList.map((regionId) => apiRespDropdownData?.regions.filter((apiReg) => regionId === apiReg?.id).map((row) => str.push(row?.region)));
      setValueRegion(str);
    } else if (valueRegion.includes('select_all') && !value.includes('select_all')) {
      setValueRegion([]);
    } else {
      if (valueRegion.includes('select_all')) setValueRegion([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueRegion(tepVal);
    }
  };

  const handleChangeCategory = (event) => {
    setValueCommodity([]);
    setValueSubCommodity([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      apiRespDropdownData?.commodityGroups?.map((row) => str.push(row?.name));
      setValueCategory(str);
    } else if (valueCategory.includes('select_all') && !value.includes('select_all')) {
      setValueCategory([]);
    } else {
      if (valueCategory.includes('select_all')) setValueCategory([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueCategory(tepVal);
    }
    setIsCategorySelected(true);
  };

  const handleChangeCommodity = (event) => {
    setValueSubCommodity([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      if (!isCategorySelected) apiRespDropdownData?.commodityGroups?.map((ele) => ele?.commodities.map((row)=> str.push(row?.name)));
      else {
        selectedCommodities.map((row) => {
          return str.push(row);
        });
      }
      setValueCommodity(str);
    } else if (valueCommodity.includes('select_all') && !value.includes('select_all')) {
      setValueCommodity([]);
    } else {
      if (valueCommodity.includes('select_all')) setValueCommodity([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueCommodity(tepVal);
    }
    setIsCommodiitySelected(true);
  };

  const handleChangeSubCommodity = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      if (!isCommoditySelected) apiRespDropdownData?.commodityGroups?.map((ele) => ele?.commodities?.map((obj) => obj?.subCommodities.map((row)=> str.push(row?.name))));
      else selectedSubCommodities.map((row) => str.push(row));
      setValueSubCommodity(str);
    } else if (valueSubCommodity.includes('select_all') && !value.includes('select_all')) {
      setValueSubCommodity([]);
    } else {
      if (valueSubCommodity.includes('select_all')) setValueSubCommodity([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      setValueSubCommodity(tepVal);
    }
  };

  const handleChangeSector = (event) => {
    setValueRegion([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'sector_all') {
      const str = ['sector_all'];
      apiRespDropdownData?.sectorGroups?.map((obj)=>
        obj?.sectors?.map((row) => (
          str.push(row?.name)
        )),
      ),
      setValueSector(str);
    } else if (valueSector.includes('sector_all') && !value.includes('sector_all')) {
      setValueSector([]);
    } else {
      if (valueSector.includes('sector_all')) setValueSector([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('sector_all')) tepVal.splice(tepVal.indexOf('sector_all'), 1);
      setValueSector(tepVal);
    }
    setIsSectorSelected(true);
  };

  const handleChangeBuyplanC = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'Buyer_all') {
      const str = ['Buyer_all'];
      buyplanPersona['buyplan_creator']?.map((row)=>
        str.push(row?.user_name),
      ),
      setbuyplanCre(str);
    } else if (buyplanCre.includes('Buyer_all') && !value.includes('Buyer_all')) {
      setbuyplanCre([]);
    } else {
      if (buyplanCre.includes('Buyer_all')) setbuyplanCre([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('Buyer_all')) tepVal.splice(tepVal.indexOf('Buyer_all'), 1);
      setbuyplanCre(tepVal);
    }
  };

  const tempCommodities = [];
  const tempSubCommodities = [];
  const tempRegionIdList = [];
  useEffect(()=>{
    if (valueCategory.length === 0) {
      setIsCategorySelected(false);
    } else {
      (apiRespDropdownData.length !== 0) &&
                    apiRespDropdownData?.commodityGroups?.map((category)=>
                      valueCategory.map((ele)=> {
                        if (category.name === ele) {
                          category.commodities.map((commodity)=>tempCommodities.push(commodity?.name));
                        }
                      } ),
                    );
    }
    setSelectedCommodities([...tempCommodities]);

    if (valueCategory.length > 0 && !(valueCommodity.length > 0)) {
      (apiRespDropdownData.length !== 0) &&
      apiRespDropdownData?.commodityGroups?.map((categories) => {
        if (valueCategory.includes(categories?.name)) {
          categories?.commodities?.map((commodity) => {
            commodity?.subCommodities?.map( (subCommodity) => tempSubCommodities.push(subCommodity.name));
          });
        }
      });
    } else if (valueCommodity.length === 0) {
      setIsCommodiitySelected(false);
    } else {
      (apiRespDropdownData.length !== 0) &&
                    apiRespDropdownData?.commodityGroups?.map((ele) =>
                      ele?.commodities?.map((commodity)=>
                        valueCommodity.map((ele)=>{
                          if (commodity.name === ele) {
                            commodity.subCommodities.map((subCommodity)=>tempSubCommodities.push(subCommodity?.name));
                          }
                        }),
                      ));
    }
    setSelectedSubCommodities([...tempSubCommodities]);

    if (valueSector.length === 0) {
      setIsSectorSelected(false);
    } else {
      (apiRespDropdownData.length !== 0) &&
                    apiRespDropdownData?.sectorGroups.map((obj)=>
                      obj?.sectors?.map((row) =>{
                        valueSector.map((sector)=>
                          row?.regionIdList.map((id)=>{
                            if (row.name === sector) {
                              if (!tempRegionIdList.includes(id)) {
                                tempRegionIdList.push(id);
                              };
                            }
                          }));
                      } ),
                    );
    }
    setSelectedRegionIdList([...tempRegionIdList]);
  }, [valueCategory, valueCommodity, valueSector, isSectorSelected, isCategorySelected, isCommoditySelected]);

  const handleSubmit =()=> {
    dispatch(setSelectedDropDownData({
      sector: valueSector,
      region: valueRegion,
      commodityGrp: valueCategory,
      commodity: valueCommodity,
      subCommodity: valueSubCommodity,
      executionMonthYear: valuePlanMonth,
      buyPlanCreatorUserId: buyerNameIdList,
      status: buyplanStatusValue,
    }));
  };

  const handleClose =()=>{
    setIsOpen(false);
  };
  const handleOpen =(dropdown)=>{
    setIsOpen(dropdown);
  };

  if (!planMonths) {
    planMonths = generateMonthDropDownValues();
  }

  useEffect(() =>{
    const sectorGroupsResponse = [];
    const commodityGroupsResponse = [];
    if (Object.entries(apiRespDropdownData).length > 0) {
      if (apiRespDropdownData.sectorGroups.length > 0) {
        apiRespDropdownData.sectorGroups.forEach((sectorGroup) => {
          sectorGroup.sectors.forEach( (sector) =>{
            sectorGroupsResponse.push({
              'sectorGroup_id': sectorGroup.id,
              'sectorGroup_name': sectorGroup.name,
              'sectorGroup_active': sectorGroup.active,
              'sector_active': sector.active,
              'sector_id': sector.id,
              'sector_is_editable': sector.isEditable,
              'sector_name': sector.name,
              'sector_regionIdList': sector.regionIdList,
              'sectorGroupId': sector.sectorGroupId,
              'sector_updated_on': sector.updated_on,
              'sector_updated_by': sector.updated_by,
            });
          });
        });
        apiRespDropdownData.commodityGroups.forEach((item) => {
          item.commodities.forEach( (commodity) =>{
            commodity.subCommodities.forEach( (subCommodity) =>{
              commodityGroupsResponse.push({
                'commodityGroup_id': item.id,
                'commodityGroup_name': item.name,
                'commodity_id': commodity.id,
                'commodity_is_active': commodity.is_active,
                'commodity_is_editable': commodity.is_editable,
                'commodity_name': commodity.name,
                'commodity_updated_on': commodity.updated_on,
                'commodity_updated_by': commodity.updated_by,
                'subCommmodity_row_id': subCommodity.id,
                'subCommmodity_id': subCommodity.commodityId,
                'subCommmodity_is_active': subCommodity.is_active,
                'subCommmodity_is_editable': subCommodity.is_editable,
                'subCommmodity_name': subCommodity.name,
                'subCommmodity_updated_on': subCommodity.updated_on,
                'subCommmodity_updated_by': subCommodity.updated_by,
              });
            });
          });
        });
      }
    }
    sectorGroupsResponse.sort((a, b) => a.sector_name.localeCompare(b.sector_name));
    commodityGroupsResponse.sort((a, b) => a.commodity_name.localeCompare(b.commodity_name));
    setSectorGroupsFromApi([...sectorGroupsResponse]);
    setcommodityGroupsFromApi(_.uniqBy([...commodityGroupsResponse], 'commodity_name'));
    setSubCommodityGroupsFromApi([...commodityGroupsResponse.sort((a, b) => a.subCommmodity_name.localeCompare(b.subCommmodity_name))]);
  }, [apiRespDropdownData]);

  function sortMenuListItems(objArr, sortObjName, compareItem) {
    const newObj = [...objArr[sortObjName]];
    newObj.sort((a, b) => a[compareItem].localeCompare(b[compareItem]));
    const returnObj = {...objArr, [sortObjName]: [...newObj]};
    return returnObj;
  }

  function sortTheFilterSelected(selectedListIds, apiRespDropdownData, mapKey) {
    const filteredList = [];
    selectedListIds.forEach((id) => {
      apiRespDropdownData[mapKey].map((item) => {
        if (item.id === id) {
          filteredList.push(item);
        }
      });
    });
    return selectedListIds.length === 0 ? apiRespDropdownData : {...apiRespDropdownData, [mapKey]: filteredList};
  }

  function capitalize(input) {
    return input.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
  }
  useEffect(()=>{
    const buyPlanNameIdList = [];
    buyplanPersona['buyplan_creator']?.forEach((buyer) => {
      if (buyplanCre.includes(buyer.user_name)) {
        buyPlanNameIdList.push(buyer.id);
      }
    });
    setBuyerNameIdList(buyPlanNameIdList);
  }, [buyplanCre]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: '100%', padding: '18px'}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
          }}
        >
        Buy Plan
        </Box>
        <Box sx={{display: 'flex', justifyContent: 'flex-start', paddingTop: '35px', flexWrap: 'wrap'}}>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Sector
            </Box>
            <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueSector}
                  open={isOpen==='Sector'}
                  // onMouseEnter={handleOpen}
                  onClose={handleClose}
                  onOpen={()=>handleOpen('Sector')}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: handleClose,
                    }}}
                  onChange={handleChangeSector}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueSector.length ? selected.join(', ') :'Select All'}
                  className="newclass"
                  style={{
                    '& .MuiMenuRoot': {
                      background: 'black',
                    },
                    '.MuiPaperRoot': {
                      'width': '1000px',
                      '& .MuiList-root': {
                        maxHeight: '200px',
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='sector_all' value='sector_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueSector?.indexOf('sector_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(apiRespDropdownData.length !== 0) &&
                      sectorGroupsFromApi?.map((row) => (
                        <MenuItem sx={{
                          'padding': '3px 12px',
                        }}
                        key={row?.sector_id} value={row?.sector_name} className="this-is-new-class">
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={valueSector?.indexOf(row?.sector_name) > -1}
                          />
                          <ListItemText sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={row?.sector_name}
                          className="myClass"
                          />
                        </MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Region/Country
            </Box>
            <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
              <FormControl fullWidth>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiPaperRoot-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
                      bgcolor: 'green',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueRegion}
                  label="value"
                  open={isOpen==='Region'}
                  // onMouseEnter={handleOpen}
                  onClose={handleClose}
                  onOpen={()=>handleOpen('Region')}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: handleClose,
                    }}}
                  onChange={handleChangeRegion}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueRegion.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueRegion?.indexOf('select_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {!isSectorSelected ? ((apiRespDropdownData.length !== 0) &&
                    sortMenuListItems(apiRespDropdownData, 'regions', 'region')?.regions.map((row) =>
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={row?.id} value={row?.region} >
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueRegion.indexOf(row?.region) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={row?.region} />
                      </MenuItem>,
                    )):
                    (
                      (apiRespDropdownData.length !== 0) &&
                    sortMenuListItems(sortTheFilterSelected(selectedRegionIdList, apiRespDropdownData, 'regions'), 'regions', 'region')?.regions.map((region, idx)=>{
                      return <MenuItem sx={{
                        'padding': '3px 12px',
                      }}
                      key={idx} value={region.region} className="this-is-new-class">
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueRegion?.indexOf(region.region) > -1}
                        />
                        <ListItemText sx={{
                          '.MuiTypography-root': {
                            fontSize: '14px',
                          },
                        }}
                        primary={region.region}
                        className="myClass"
                        />
                      </MenuItem>;
                    }))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Category
            </Box>
            <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      'padding': '11.5px 14px',
                      'border': 'none',
                      '.MuiOutlinedInput-notchedOutline:hover': {border: '1px solid blue'},
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  variant="standard"
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueCategory}
                  label="valueCategory"
                  open={isOpen==='Category'}
                  // onMouseEnter={handleOpen}
                  onClose={handleClose}
                  onOpen={()=>handleOpen('Category')}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: handleClose,
                    }}}
                  onChange={handleChangeCategory}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueCategory.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueCategory?.indexOf('select_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(apiRespDropdownData.length !== 0) &&
                    sortMenuListItems(apiRespDropdownData, 'commodityGroups', 'name')?.commodityGroups?.map((row) => (
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={row?.id} value={row?.name}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueCategory.indexOf(row?.name) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={row?.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Commodity
            </Box>
            <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueCommodity}
                  label="value"
                  open={isOpen==='Commodity'}
                  // onMouseEnter={handleOpen}
                  onClose={handleClose}
                  onOpen={()=>handleOpen('Commodity')}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: handleClose,
                    }}}
                  onChange={handleChangeCommodity}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueCommodity.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueCommodity?.includes('select_all')}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {!isCategorySelected?
                  ((apiRespDropdownData.length !== 0) &&
                    commodityGroupsFromApi.map((row)=>
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={row?.commodity_id} value={row?.commodity_name}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueCommodity.indexOf(row?.commodity_name) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={row?.commodity_name} />
                      </MenuItem>)
                  ):
                    (selectedCommodities?.sort((a, b) => a.localeCompare(b))?.map((commodity, idx)=>
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={idx} value={commodity}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueCommodity.indexOf(commodity) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={commodity} />
                      </MenuItem>,
                    ))}

                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Sub-Commodity
            </Box>
            <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valueSubCommodity}
                  label="value"
                  open={isOpen==='SubCommodity'}
                  // onMouseEnter={handleOpen}
                  onClose={handleClose}
                  onOpen={()=>handleOpen('SubCommodity')}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: handleClose,
                    }}}
                  onChange={handleChangeSubCommodity}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = valueSubCommodity.length ? selected.join(', ') :'Select All'}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='select_all' value='select_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valueSubCommodity?.includes('select_all')}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {
                    isCategorySelected && !isCommoditySelected ?
                    (selectedSubCommodities?.sort((a, b) => a.localeCompare(b))?.map((subcommodity, idx)=>
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={idx} value={subcommodity}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueSubCommodity.indexOf(subcommodity) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={subcommodity} />
                      </MenuItem>,
                    )):
                  (!isCommoditySelected ? ((apiRespDropdownData.length !== 0) &&
                      subCommodityGroupsFromApi?.map((row, id)=>
                        <MenuItem sx={{
                          'padding': '3px 12px',
                        }} key={id} value={row?.subCommmodity_name}>
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '13px',
                              },
                            }}
                            checked={valueSubCommodity.indexOf(row?.subCommmodity_name) > -1}
                          />
                          <ListItemText
                            sx={{
                              '.MuiTypography-root': {
                                fontSize: '13px',
                                whiteSpace: 'normal',
                              },
                            }}
                            primary={row?.subCommmodity_name} />
                        </MenuItem>,
                      )):
                    (selectedSubCommodities?.sort((a, b) => a.localeCompare(b))?.map((subcommodity, idx)=>
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={idx} value={subcommodity}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valueSubCommodity.indexOf(subcommodity) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={subcommodity} />
                      </MenuItem>,
                    )))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Plan Month
            </Box>
            <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={valuePlanMonth}
                  label="Select All"
                  open={isOpen==='PlanMonth'}
                  // onMouseEnter={handleOpen}
                  onClose={handleClose}
                  onOpen={()=>handleOpen('PlanMonth')}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: handleClose,
                    }}}
                  onChange={handleChangePlanMonth}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => {
                    const ret = [];
                    if (selected?.includes('month_all')) {
                      const strValuesConcat = selected.slice(1).map((obj) => obj.value);
                      strValuesConcat.unshift('select_all');
                      return strValuesConcat.join(', ');
                    } else {
                      selected.forEach((obj) => ret.push(obj.value));
                      displayEmpty = ret.length == 0 ? 'Select All': ret.join(', ');
                      return displayEmpty;
                    }
                  }}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='month_all' value='month_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={valuePlanMonth?.includes('month_all')}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(planMonths.length !== 0) &&
                    planMonths.map((row) => (
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={row.value} value={row}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={valuePlanMonth.indexOf(row) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={row.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
            <Box sx={dropdownSpan} component="span">
            Buy Plan Status
            </Box>
            <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {{
                    '.MuiOutlinedInput-input': {
                      padding: '11.5px 14px',
                    },
                    '.MuiSelect-select': {
                      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                      color: 'rgba(85, 87, 89, 1)',
                    },
                  }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={buyplanStatusValue}
                  label="Select All"
                  open={isOpen==='BuyPlanStatus'}
                  // onMouseEnter={handleOpen}
                  onClose={handleClose}
                  onOpen={()=>handleOpen('BuyPlanStatus')}
                  MenuProps={{
                    PaperProps: {
                      onMouseLeave: handleClose,
                    }}}
                  onChange={handleChangeBuyPlanStatus}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => {
                    const ret = [];
                    if (selected?.includes('status_all')) {
                      const strValuesConcat = selected.slice(1).map((obj) => obj);
                      strValuesConcat.unshift('select_all');
                      return strValuesConcat.join(', ');
                    } else {
                      selected.forEach((obj) => ret.push(obj));
                      displayEmpty = ret.length == 0 ? 'Select All': ret.join(', ');
                      return displayEmpty;
                    }
                  }}
                >
                  <MenuItem sx={{'padding': '3px 12px'}} key='status_all' value='status_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={buyplanStatusValue?.includes('status_all')}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(buyplanStatusArr.length !== 0) &&
                    buyplanStatusArr.map((status, index) => (
                      <MenuItem sx={{
                        'padding': '3px 12px',
                      }} key={index} value={status}>
                        <Checkbox
                          sx={{
                            '.MuiSvgIcon-root': {
                              fontSize: '14px',
                            },
                          }}
                          checked={buyplanStatusValue.indexOf(status) > -1}
                        />
                        <ListItemText
                          sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={capitalize(status)} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {accessControl===READ &&
            <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px', marginRight: '10px'}}>
              <Box sx={dropdownSpan} component="span">
              Buyer Name
              </Box>
              <Box sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    sx = {{
                      '.MuiMenuRoot': {
                        background: 'black',
                      },
                      '.MuiOutlinedInput-input': {
                        padding: '11.5px 14px',
                      },
                      '.MuiPaperRoot': {
                        'width': '1000px',
                        '& .MuiList-root': {
                          maxHeight: '200px',
                          overflow: 'auto',
                        },
                      },
                      '.MuiSelect-select': {
                        font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                        color: 'rgba(85, 87, 89, 1)',
                      },
                    }}
                    id="demo-multiple-checkbox"
                    multiple
                    value={buyplanCre}
                    open={isOpen==='Buyer'}
                    // onMouseEnter={handleOpen}
                    onClose={handleClose}
                    onOpen={()=>handleOpen('Buyer')}
                    MenuProps={{
                      PaperProps: {
                        onMouseLeave: handleClose,
                      }}}
                    onChange={handleChangeBuyplanC}
                    input={<CustomInput label="Tag" />}
                    displayEmpty
                    renderValue={( selected, displayEmpty) => displayEmpty = buyplanCre.length ? selected.join(', ') :'Select All'}
                    className="newclass"
                    style={{
                      '& .MuiMenuRoot': {
                        background: 'black',
                      },
                      '.MuiPaperRoot': {
                        'width': '1000px',
                        '& .MuiList-root': {
                          maxHeight: '200px',
                          overflow: 'auto',
                        },
                      },
                    }}
                  >
                    <MenuItem sx={{'padding': '3px 12px'}} key='Buyer_all' value='Buyer_all' className='this-is-new-class'>
                      <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={buyplanCre?.indexOf('Buyer_all') > -1}/>
                      <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                    </MenuItem>
                    {(buyplanPersona['buyplan_creator']?.length !== 0) &&
                      buyplanPersona['buyplan_creator']?.sort((a, b)=> a['user_name'].localeCompare(b['user_name'])).map((row) => (
                        <MenuItem sx={{
                          'padding': '3px 12px',
                        }}
                        key={row?.id} value={row?.user_name} className="this-is-new-class">
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={buyplanCre?.indexOf(row?.user_name) > -1}
                          />
                          <ListItemText sx={{
                            '.MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                          primary={row?.user_name}
                          className="myClass"
                          />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            </Box>
          }
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} component="span">

            </Box>
            <Box sx={{minWidth: 130, marginTop: '28px'}}>
              <Button
                onClick={handleSubmit}
                sx={{'backgroundColor': '#4297d3',
                  'color': '#ffffff',
                  'textTransform': 'none',
                  'minWidth': '0',
                  'padding': '8px 35px',
                  '&:hover': {
                    backgroundColor: '#4297d3',
                  }}}>Submit</Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: {
              display: 'inline-block',
              marginRight: '2px',
              font: 'normal normal 600 13px/15px \'Segoe UI\'',
              color: '#3174b6',
              cursor: 'pointer',
            },
            marginTop: '30px',
          }}
        >
          <p onClick={()=>{
            const tempObj = {...selectedDropDownData};
            Object.keys(tempObj).map((el)=>tempObj[el]=[]);
            dispatch(setSelectedDropDownData(tempObj));
            setValueCategory([]);
            setValueCommodity([]);
            setValuePlanMonth([]);
            setValueRegion([]);
            setValueSector([]);
            setbuyplanCre([]);
            setValueSubCommodity([]);
            setBuyplanStatusValue([]);
          }}>Reset Filters</p>
        </Box>
      </Box>
    </ThemeProvider>

  );
}

export default BuyPlanList;
