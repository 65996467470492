
/* eslint-disable camelcase */
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import PlayBookTable from './NonPlayBookTable';
import NonPlayBookList from './NonPlayBookDisplay';
import config from '../../../Utils/Config';
const NonPlayBook = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [idKey, setIdKey] = useState([]);
  const deleteNonPlayBookRow = ConfigurationServices.deleteNonPlayBookData;
  const heads = [
    'Non Playbook Name*',
    'Sector*',
    'Region/Country*',
    'BU Description*',
    'Category*',
    'Commodity*',
    'Sub Commodity*',
    'Local UoM*',
    'Standard UoM*',
    'Currency*',
    'Owner*',
    'Status*',
    'Last Edited By',
    'Last Edited On',
  ];

  const values = [
    'name',
    'sector',
    'country',
    'business_unit',
    'category',
    'commodity',
    'sub_commodity',
    'local_unit',
    'std_unit',
    'currency',
    'npb_creator',
    'is_active',
    'updatedBy',
    'last_updated_on',
  ];

  const sortValues = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
  ];


  const selectedDropDownData = useSelector((state)=>state?.configurations?.selectedPlayBookDropDown);
  const updateSingleUnitMaster = ConfigurationServices.
      updateNonPlayBookData;
  const newRowUpdate = ConfigurationServices.postNonPlayBookData;
  const [pagination, setPagination] = useState(10);
  const [addingTrigger, setAddingTrigger] = useState(false);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);
  useEffect(()=>{
    if (SortValue !== '' && SortValue !== 'last_updated_on') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) => a[SortValue]?.localeCompare(b[SortValue]));
      }
      if (sorttype === 'desc') {
        tableFilterData.sort((a, b) => b[SortValue]?.localeCompare(a[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedData]);
      }
      if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  useEffect(()=>{
    ConfigurationServices.getNonPlayBookData()
        .then((res)=>{
          setTimeout(()=>{
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                setRow(res);
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          }, 1000);
        });
  }, [addingTrigger]);

  useEffect(()=>{
    let temp = [...row];
    if (Object.keys(selectedDropDownData).length!==0) {
      Object.entries(selectedDropDownData).map((obj, index)=>{
        if (obj[1].length!==0) {
          temp = temp.filter((r)=>{
            return obj[1].includes(r[obj[0]]);
          });
        }
      });
      setTableFilterData([...temp]);
      setPage(0);
    } else {
      setTableFilterData([...row]);
    }
  }, [selectedDropDownData, row]);


  const [ActiveMasterSectors, setActiveMasterSectors] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getMasterSectorData()
        .then((res)=>{
          const activeMasterSectors = [];
          res.map((r)=>{
            r.is_active === true && activeMasterSectors.push(r);
          });
          setActiveMasterSectors([...activeMasterSectors]);
        });
  }, []);


  const [ActiveSectors, setActiveSectors] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getSectordata()
        .then((res)=>{
          const activeSectors = [];
          res['commodityGroups'].map((r)=>{
            r.sectors.map((rs)=>{
              rs.active === true && !activeSectors.includes(r) && activeSectors.push(r);
            });
          });
          setActiveSectors([...activeSectors]);
        });
  }, []);

  const [ActiveCategories, setActiveCategories] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getCategoryData()
        .then((res)=>{
          const activeCategories = [];
          res.map((r)=>{
            r.is_active === true && activeCategories.push(r);
          });
          setActiveCategories([...activeCategories]);
        });
  }, []);
  const [ActiveCommodities, setActiveCommodities] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getCommodityData()
        .then((res)=>{
          const activeCommodities = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rs)=>{
              rs.is_active==true && !activeCommodities.includes(r) && activeCommodities.push(r);
            });
          });
          setActiveCommodities([...activeCommodities]);
        });
  }, []);

  const [ActiveSubCommodities, setActiveSubCommodities] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getSubCommodityData()
        .then((res)=>{
          const activeSub = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rc)=>{
              rc.subCommodities.map((rs)=>{
                rs.is_active==true && !activeSub.includes(rc) && activeSub.push(rc);
              });
            });
          });
          setActiveSubCommodities([...activeSub]);
        });
  }, []);


  const [country, setcountry] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getCountrysData()
        .then((res)=>{
          const contr = [];
          res['commodityGroups'].map((rs)=>{
            rs.is_active==true && !contr.includes(rs) && contr.push(rs);
          });
          setcountry([...contr]);
        });
  }, []);

  const [businessUnit, setbusinessUnit] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getBusinessUnitdata()
        .then((res)=>{
          const bu = [];
          res['commodityGroups'].map((rs)=>{
            rs.is_active===true && bu.push(rs);
          });
          setbusinessUnit(bu);
        });
  }, []);

  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);


  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:PlayBook']) {
        setAccessControl(functionalityAccess['Configuration:PlayBook']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [buyplanowner, setbuyplanowner] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getBuyPlanownerdata()
        .then((res)=>{
          const bu = [];
          res['commodityGroups'].map((r)=>{
            r.is_active==true && !bu.includes(r) && bu.push(r);
          });
          setbuyplanowner([...bu]);
        });
  }, []);

  const [Units, setUnits] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getUnitMasterData()
        .then((res)=>{
          const units = [];
          Array.isArray(res) && res?.map((r)=>{
            !units.includes(r) && units.push(r);
          });
          setUnits([...units].filter((unit) => unit.is_active));
        });
  }, []);
  const [Currency, setCurrency] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getCurrency()
        .then((res)=>{
          const currency = [];
          res.map((r)=>{
            !currency.includes(r) && currency.push(r);
          });
          setCurrency([...currency].filter((cur) => cur.is_active));
        });
  }, []);
  const [buyplanPersona, setBuyPlanPersona] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getBuyPlanPersonasdata()
        .then((res)=>{
          setBuyPlanPersona(res);
        });
  }, []);

  const [ActiveMasterSectorsList, setActiveMasterSectorsList] = useState([]);
  const [ActiveCategoriesList, setActiveCategoriesList] = useState([]);
  const [ActiveCommoditiesList, setActiveCommoditiesList] = useState([]);
  const [ActiveSubCommoditiesList, setActiveSubCommoditiesList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [ActiveSectorsList, setActiveSectorsList] = useState([]);
  const [buyplanownerList, setBuyplanownerList] = useState([]);

  useEffect(() => {
    if (!row || row.length === 0) {
      return;
    }

    // making list of unique master sector that are present in the table
    const uniqueSectorMaster = new Set(row.filter((obj) => obj.master_sector !== null && obj.master_sector !== '').map((obj) => obj.master_sector));
    let matchingObjects = ActiveMasterSectors.filter((obj) => uniqueSectorMaster.has(obj.master_sector));
    setActiveMasterSectorsList(matchingObjects);

    // making list of unique sectors that are present in the table
    const uniqueSector = new Set(row.filter((obj) => obj.sector !== null && obj.sector !== '').map((obj) => obj.sector));
    const sectors = ActiveSectors.flatMap((activeSector) => {
      return activeSector.sectors.map((sector) => ({
        ...sector,
        masterSectorName: activeSector.name,
      }));
    });
    matchingObjects = sectors.filter((obj) => uniqueSector.has(obj.name));
    setActiveSectorsList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueRegions = new Set(row.filter((obj) => obj.country !== null && obj.country !== '').map((obj) => obj.country));
    matchingObjects = country.filter((obj) => uniqueRegions.has(obj.region));
    setCountryList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueCategories = new Set(row.filter((obj) => obj.category !== null && obj.category !== '').map((obj) => obj.category));
    matchingObjects = ActiveCategories.filter((obj) => uniqueCategories.has(obj.category));
    setActiveCategoriesList(matchingObjects);

    // making list of unique commodities that are present in the table
    const uniqueCommodity = new Set(row.filter((obj) => obj.commodity !== null && obj.commodity !== '').map((obj) => obj.commodity));
    const commodity = ActiveCommodities.flatMap((activeCommodity) => {
      return activeCommodity.commodities.map((commodity) => ({
        ...commodity,
        categoryName: activeCommodity.name,
      }));
    });
    matchingObjects = commodity.filter((obj) => uniqueCommodity.has(obj.name));
    setActiveCommoditiesList(matchingObjects);

    // making list of unique subcommodities that are present in the table
    const uniqueSubCommodity = new Set(row.filter((obj) => obj.sub_commodity !== null && obj.sub_commodity !== '').map((obj) => obj.sub_commodity));
    const subCommodity = ActiveSubCommodities.flatMap((activeSubCommodity) => {
      return activeSubCommodity.subCommodities.map((subCommodity) => ({
        ...subCommodity,
        commodityName: activeSubCommodity.name,
      }));
    });
    matchingObjects = subCommodity.filter((obj) => uniqueSubCommodity.has(obj.name));
    setActiveSubCommoditiesList(matchingObjects);

    // making list of unique buyplan owner that are present in the table
    const uniqueBuyplanOwner = new Set(row.filter((obj) => obj.buyplan_owner_user_id !== null && obj.buyplan_owner_user_id !== '').map((obj) => obj.buyplan_owner_user_id));
    matchingObjects = buyplanowner.filter((obj) => uniqueBuyplanOwner.has(obj.user_id));
    setBuyplanownerList(matchingObjects);
  }, [row]);


  const [page, setPage] = React.useState(0);
  return (
    <Box
      sx={{
        bgcolor: '#eff6fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0px',
      }}
    >
      {(accessControl === Edit || accessControl === Read) && (
        <Box sx={theme.palette.whitebgbox}>
          <NonPlayBookList
            row={row}
            setPage={setPage}
            ActiveMasterSectors={ActiveMasterSectorsList}
            buyplanowner={buyplanownerList}
            ActiveSectors={ActiveSectorsList}
            ActiveCategories={ActiveCategoriesList}
            ActiveCommodities={ActiveCommoditiesList}
            country={countryList}
            ActiveSubCommodities={ActiveSubCommoditiesList}
          />

          <Box sx={{}}>
            <PlayBookTable
              data={tableFilterData}
              setAddingTrigger={setAddingTrigger}
              heads={heads}
              sortValues={sortValues}
              accessControl={accessControl}
              values={values}
              addingTrigger={addingTrigger}
              idKey={idKey}
              singleUpdate={updateSingleUnitMaster}
              newRowUpdate={newRowUpdate}
              setIsErrorPopupVisible={setIsErrorPopupVisible}
              setErrMsg={setErrMsg}
              pagination={pagination}
              setPagination={setPagination}
              ActiveCommodities={ActiveCommodities}
              ActiveSubCommodities={ActiveSubCommodities}
              businessUnit={businessUnit}
              ActiveCategories={ActiveCategories}
              setPage={setPage}
              page={page}
              ActiveSectors={ActiveSectors}
              Units={Units}
              Currency={Currency}
              buyplanPersona={buyplanPersona}
              deleteNonPlayBookRow={deleteNonPlayBookRow}
            />

            {(errMsg !== '' || tableFilterData.length == 0) && <ErrorMessage message="Non Playbook" errmsg={errMsg} />}
          </Box>
          <AppErrorDialog
            open={isErrorPopupVisible}
            handleClose={() => {
              setIsErrorPopupVisible(false);
              setErrMsg('');
            }}
            text={errMsg}
          />
        </Box>
      )}
    </Box>
  );
};

export default NonPlayBook;
