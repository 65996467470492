/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn, floatColumn} from 'react-datasheet-grid';
// DataSheetGridPropsBase
import {Dialog, DialogContent} from '@mui/material';
// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../services/dialogService';
import {monthArr, months, roundToTwo, titleCase} from '../../services/utils';
// import {AppDialog} from '../common/App-Dialog/AppDialog';
import {AppTableInput} from '../common/AppTableInput/AppTableInput';
import {CopyIcon} from '../common/Icons/icons';
import './forecast.css';
import {AppButton} from '../common/AppButton/AppButton';
// import {defaultObj} from './consts';
import {forecastDefaultData, fyCalculateTotal, getSortedMonthlyData, monthNYearSetter, rowsSetter, updateTheDataWithPrevMonth} from './utils';
import VarianceModal from './dialog';
import {updateStatusOfBuyPlan} from '../../redux/features/apiCall';
import {AppDialog, AppDialog3, AppDialog4, AppErrorDialog} from '../common/App-Dialog/AppDialog';
import {fetchVarianceReasons} from '../../redux/Common'; // fetchQuaters
import {setForecastUpdatesaveButton} from '../../redux/features/configurations';
import ReadOnlyColumn from '../common/ReadOnlyColumn';
import {CopyHoverStyles} from './CopyHoverText';
import {changeButtonStatusValue, changeBuyplanStatus} from '../../redux/features/buttonStatus';
import PreviousVarianceTable from './PreviousVarianceTable';
import ConfigurationServices from '../../services/ConfigurationServices';
import PreviousMonthForecastPopUp from './PreviousMonthForecastPopUp';
import PastMonthForecastCommentsTable from '../common/PreviousMonthCommentsTable/PastMonthForecastCommentsTable';
import {defaultMonthValues} from '../../services/utils';
const CopyRow = ({copyRow, rowData, setShowCopyDescription})=>{
  return (
    <Box sx={{width: '90px', position: 'relative', cursor: 'pointer'}}
      // title= 'Replicate Forecast'
      onClick={(e) => {
        e.preventDefault();
        copyRow(rowData);
      }}
      onMouseEnter={()=> setShowCopyDescription(true)}
      onMouseLeave={()=> setShowCopyDescription(false)}
    >
      <CopyIcon />
    </Box>);
};

const ForecastTable = ({setYears,
  wfDetails,
  subMissionData, setExecMonthDecider, setisPreviousYearData, isPreviousYearData, execMonthDecider,
  setSubmissionData, ForecastPreviousMonthAccess,
  years,
  setTabsEnableCheck, setSelectedYear,
  data,
  setData,
  setModifiedData, quarters,
  selectedYear,
  modifiedData,
  selectedRow,
  isForecastUpdateTabEditable, previousSelectedYearTracker, yearCount,
  data1, data2, data3, setdata1, setdata2, setdata3,
}) => {
  const [showCopyDescription, setShowCopyDescription] = useState(false);
  const [copyDescription, setCopyDescription] = useState('Replicate Forecast');
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const curMonth = +selectedRow.executionMonthYear[1]-4;
  // eslint-disable-next-line no-unused-vars
  const [forecastData, setForecastData] = useState([]); // useless
  // eslint-disable-next-line no-unused-vars
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false); // useless
  const [columns, setColumns] = useState([]);
  const [workflowIdsObj, setWorkflowIdsObj] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [accessControl, setAccessControl]=useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const [showSuccessDialog2, setShowSuccessDialog2] = useState(false);
  const [showPreviousMonthSuccess, setShowPreviousMonthSuccess] = useState(false);
  const [showSuccessDialog3, setShowSuccessDialog3] = useState(false);

  const [firstLoad, setFirstLoad] = useState(false);
  const [skipper, setSkipper] = useState(null);
  const [validationArr, setValidationArr] = useState([]);
  const [isValidate, setIsValidate] = useState(null); // useless
  const [showVarianceDialog, setShowVarianceDialog]= useState(false);
  const [newArr, setNewArr] = useState([]); // useless
  const [validationCallCheck, setValidationCallCheck] = useState(false); // useless
  const [isDataFatched, setIsDataFatched]=useState(false);
  const [showErrorDialog, setShowErrorDialog]=useState(false);
  const [errorMessage, setErrorMessage]=useState(null);
  // const [quarters, setQuaters]=useState(null);
  const [dataCopyTrigger, setDataCopyTrigger] = useState(1);
  const [row, setRow] = useState({});
  const [open5, setOpen5] = useState(false);
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  const [previousvarianceData, setVarianceData] = useState([]);

  const [previousVarianceTrigger, setPreviousVarianceTrigger] = useState(false);
  const [PastMonthCommentsTrigger, setPastMonthCommentsTrigger] = useState(false);

  const [varianceTrigger, setVarianceTrigger] = useState(0);

  const [previousMonthForecastEditButton, setPreviousMonthForecastEditButton] = useState('');
  const [previousMonthDifferenceData, setPreviousMonthDifferenceData] = useState([]);
  const [ConvertedPastMonthComments, setConvertedPastMonthComments] = useState([]);

  const [PastMonthpreviousCommentsData, setPastMonthpreviousCommentsData] = useState([]);

  const [showPreviousMonthDifference, setshowPreviousMonthDifference] = useState(false);


  const [previousMonthPresentMonthNotSame, setpreviousMonthPresentMonthNotSame] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(()=>{
    ConfigurationServices.getForecastVariance(selectedRow.workFlowId).then((res)=>{
      setVarianceData([...res]);
    }).catch((err)=>{
      console.log(err);
    });
  }, [previousVarianceTrigger]);

  // getting past month comments and storing
  useEffect(()=>{
    ConfigurationServices.getPastMonthComments(selectedRow.workFlowId, 'FORECAST').then((res)=>{
      const apidata = [...res];
      const convertedData = {};
      apidata.length>0 && apidata.map((data)=>{
        convertedData[data['planYearMonth']?.split('-')[0]?.toLowerCase()] = data;
      });
      setPreviousMonthDifferenceData({...convertedData});
      setConvertedPastMonthComments({...convertedData});
      setPastMonthpreviousCommentsData([...apidata]);
    }).catch((err)=>{
      console.log(err);
    });
  }, [PastMonthCommentsTrigger]);


  const dispatch = useDispatch();
  const [oldYear, setOldYear] = useState('');
  useEffect(()=>{
    // setQuaters({'Q1': 'Jan,Feb,Mar',
    //   'Q2': 'April,May,June',
    //   'Q3': 'July,Aug,Sep',
    //   'Q4': 'Oct,Nov,Dec',
    // });
    dispatch(fetchVarianceReasons());
  }, []);

  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Forecast_Update']) {
      setAccessControl(functionalityAccess['Buy_Plan:Forecast_Update']);
    }
  }, [functionalityAccess]);
  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);


  const pageStatus = useSelector((state) => state.btnStatus.page);
  const buyplanStatus = useSelector((state) => state.btnStatus.status);
  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(()=>{
    if (currentRole!==null) {
      if (currentRole=='Forecast Creator') {
        if (((selectedRow?.isHistoricalDataEnabled==true && selectedRow?.prevWorkFlowId>0)||selectedRow?.isHistoricalDataEnabled==false) && pageStatus=='active' ) {
          if (selectedRow.status == 'NEW' || selectedRow.status=='DRAFT' || selectedRow.status=='REJECTED') {
            dispatch(changeButtonStatusValue('edit'));
          } else {
            dispatch(changeButtonStatusValue('view'));
          }
        } else {
          // removing edit button
          dispatch(setForecastUpdatesaveButton(false));
          dispatch(changeButtonStatusValue('view'));
        }
      }
    }
  }, [currentRole]);


  useEffect(() => {
    if (selectedRow?.prevWorkFlowId) getCall();
  }, [selectedRow]);

  useEffect(() => {
    // console.log(buyplanStatus);
    if (selectedRow.isHistoricalDataEnabled==false) {
      if ((buyplanStatus && (buyplanStatus != 'NEW' ) && selectedRow.prevWorkFlowId>0 && selectedRow.forecastSubmitted) || selectedRow.status== 'CLOSED' || (buttonStatus=='view' && buyplanStatus != 'NEW' && selectedRow.forecastSubmitted)) { // && selectedRow?.status != 'DRAFT'
        setTabsEnableCheck((prev) => {
          return {...prev, buyPlan: true};
        });
      } else {
        setTabsEnableCheck((prev) => {
          return {...prev, buyPlan: false};
        });
      }
    }
  }, [buyplanStatus]);

  const setVarianceRow = (arr) => {
    // console.log(arr);
    if (arr.length>=2) {
      const dummy = {};
      for (const m of Object.keys(defaultMonthValues)) {
        dummy[m] = +roundToTwo(2, +CommaRemoval(arr[0][m])!==0 ? (+CommaRemoval(arr[1][m]) - +CommaRemoval(arr[0][m]))/+CommaRemoval(arr[0][m])*100: (+CommaRemoval(arr[1][m]) - +CommaRemoval(arr[0][m])/0.1));
      }
      dummy.fy= dummy.fy;
      arr = [arr[0], arr[1], {...dummy, planMonth: 'MoM Variance', key: 3, showKey: 3}];
    } else {
      arr = [arr[0], arr[1], {...defaultMonthValues, planMonth: 'MoM Variance', key: 3, showKey: 3}];
    }
    return arr;
  };

  useEffect(()=>{
    if (varianceTrigger!==0) {
      if (selectedYear == years[0]) {
        setdata1(setVarianceRow(data1));
      }
      if (selectedYear == years[1]) {
        setdata2(setVarianceRow(data2));
      }
      if (selectedYear == years[2]) {
        setdata3(setVarianceRow(data3));
      }
    }
  }, [varianceTrigger]);

  useEffect(() => {
    // console.log('@modi selectedYear, modifiedData, accessControl, isDataFatched', selectedYear, modifiedData, accessControl, isDataFatched);
    // checking modifiedData[selectedYear] !== undefined because sometimes we getting undefined
    if (selectedYear && selectedYear != 0 && accessControl && accessControl!=='' && modifiedData[selectedYear] !== undefined && years.length>0) {
      setColumns([]);
      // console.log('@fsct05 useEffect modifiedData[selectedYear] ', modifiedData[selectedYear]);
      let arr = fyCalculateTotal(modifiedData[selectedYear]);
      // debugger;
      if (arr.length == 1) {
        const copy = JSON.parse(JSON.stringify(workflowIdsObj));

        arr = getSortedMonthlyData(arr, selectedRow, copy, selectedYear, years, isPreviousYearData);
        // using selectedRow.executionMonthYear[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years
      } else if (parseInt(selectedYear)===years[0]) {
        arr = updateTheDataWithPrevMonth(arr, selectedRow);
      }
      // sometimes keys are not correct so checking and updating on the basis of planMonth
      // ( months.indexOf(arr[1]['planMonth'].toLowerCase())==11 && months.indexOf(arr[0]['planMonth'].toLowerCase()) == 0)  if we get the arr as jan in first index and dec in second index
      // this condition states that if the data[1].planMonth is Dec and data[0].planMonth is Jan then we are reversing the data[0] and data[1]

      // if (arr.length &&(( months.indexOf(arr[0]['planMonth'].toLowerCase())!==11 &&months.indexOf(arr[0]['planMonth'].toLowerCase())>months.indexOf(arr[1]['planMonth'].toLowerCase())) || ( months.indexOf(arr[1]['planMonth'].toLowerCase())==11 && months.indexOf(arr[0]['planMonth'].toLowerCase()) == 0))) {
      //   arr[1].key = 1;
      //   arr[0].key = 2;
      //   arr = [arr[1], arr[0]];
      // }
      // if (arr.length && (months.indexOf(arr[0]['planMonth'].toLowerCase())==11 && months.indexOf(arr[1]['planMonth'].toLowerCase())==10)) {
      //   arr[1].key = 1;
      //   arr[0].key = 2;
      //   arr = [arr[1], arr[0]];
      // }
      // debugger;
      if (arr.length &&(( months.indexOf(arr[0]['planMonth'].toLowerCase())!==11 &&months.indexOf(arr[0]['planMonth'].toLowerCase())>months.indexOf(arr[1]['planMonth'].toLowerCase())) || ( months.indexOf(arr[1]['planMonth'].toLowerCase())==11 && months.indexOf(arr[0]['planMonth'].toLowerCase()) == 0))) {
        arr[1].key = 1;
        arr[0].key = 2;
        arr = [arr[1], arr[0]];
      }
      if (arr.length && (months.indexOf(arr[0]['planMonth'].toLowerCase())==11 && months.indexOf(arr[1]['planMonth'].toLowerCase())==10)) {
        arr[1].key = 1;
        arr[0].key = 2;
        arr = [arr[1], arr[0]];
      }
      if (months.indexOf(arr[0]['planMonth'].toLowerCase())==11 && months.indexOf(arr[1]['planMonth'].toLowerCase())==0) {
        arr[1].planYear = selectedRow.executionMonthYear[0];
        arr[0].planYear = selectedRow.executionMonthYear[0]-1;
      }
      if (arr.length &&(months.indexOf(arr[0]['planMonth'].toLowerCase()) == +selectedRow.executionMonthYear[1]-1) &&(+arr[0]['planYear'] == +selectedRow.executionMonthYear[0])) {
        arr = [{...arr[1], showKey: 1}, {...arr[0], showKey: 2}];
      }
      arr = [{...arr[0], showKey: 1}, {...arr[1], showKey: 2}];
      console.log(arr);
      // if (months.indexOf(arr[0]['planMonth'].toLowerCase())==11 && months.indexOf(arr[1]['planMonth'].toLowerCase())==0) {
      //   arr[1].planYear = selectedRow.executionMonthYear[0];
      //   arr[0].planYear = selectedRow.executionMonthYear[0]-1;
      // }
      // console.log('@fsct05 useEffect arr ', arr);
      setData(arr);

      if (selectedYear == years[0]) {
        setdata1(setVarianceRow(arr));
        // console.log(arr);
      }
      if (selectedYear == years[1]) {
        setdata2(setVarianceRow(arr));
      }
      if (selectedYear == years[2]) {
        setdata3(setVarianceRow(arr));
      }
      setTimeout(() => {
        const cols = columnsSetterReadOnly();// columnsSetter();
        // set columns value as per condition
        if ( (!(buttonStatus==='edit' || ((currentRole.includes('Admin')) && (selectedRow.status === 'DRAFT' || selectedRow.status === 'REJECTED' || selectedRow.status === 'NEW')))) ||( isForecastUpdateTabEditable===false && previousMonthForecastEditButton==false)) { //
          setColumns((pre) => [
            {
              ...keyColumn('planMonth', textColumn),
              title: 'Plan Month',
              disabled: true,
            },
            {
              ...keyColumn('planYear', textColumn),
              title: 'Plan Year',
              disabled: true,
            },
            ...cols,
            {...keyColumn('fy', textColumn),
              component: ({rowData, focus}) => (
                <ReadOnlyColumn decider={2} rowData={rowData} columnName='fy' extraMark={true}/>
              ), title: `FY ${selectedYear} Total`, disabled: true},
          ]);
        } else {
          if (accessControl === 'Edit') {
            setColumns([
              {
                ...keyColumn('planMonth', textColumn),
                title: 'Plan month',
                disabled: true,
              },
              {
                ...keyColumn('planYear', textColumn),
                title: 'Plan year',
                disabled: true,
              },
              {
                ...keyColumn('jan', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'jan')}
                    columnName="jan"
                    forecastVal={[selectedYear, 1]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jan',
                disabled: ({rowData}) =>
                //   accessControl === 'Edit' ?
                //   // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                //   (buttonStatus==='edit' || ((currentRole.includes('Admin')) && (selectedRow.status === 'DRAFT' || selectedRow.status=='REJECTED' || selectedRow.status=='NEW'))) ?
                //     rowData?.showKey == 1 ?
                //       true :
                //       !(selectedYear > selectedRow.executionMonthYear[0]) ?
                //       curMonth >= months.indexOf('jan') ?
                //         true :
                //         false :
                //       false :
                //     true:
                // true,

                // using this because to minimize code and writing all things in one function
                  isDataSheetCellDisabled(rowData, 'jan'),
              },
              {
                ...keyColumn('feb', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'feb')}
                    columnName="feb"
                    forecastVal={[selectedYear, 2]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Feb',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'feb'),
              },
              {
                ...keyColumn('mar', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'mar')}
                    columnName="mar"
                    forecastVal={[selectedYear, 3]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Mar',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'mar'),
              },
              {
                ...keyColumn('apr', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'apr')}
                    columnName="apr"
                    forecastVal={[selectedYear, 4]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Apr',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'apr'),
              },
              {
                ...keyColumn('may', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'may')}
                    columnName="may"
                    forecastVal={[selectedYear, 5]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'May',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'may')},
              {
                ...keyColumn('jun', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'jun')}
                    columnName="jun"
                    forecastVal={[selectedYear, 6]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jun',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'jun')},
              {
                ...keyColumn('jul', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'jul')}
                    columnName="jul"
                    forecastVal={[selectedYear, 7]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jul',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'jul')},
              {
                ...keyColumn('aug', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'aug')}
                    columnName="aug"
                    forecastVal={[selectedYear, 8]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Aug',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'aug')},
              {
                ...keyColumn('sep', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'sep')}
                    columnName="sep"
                    forecastVal={[selectedYear, 9]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Sep',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'sep'),
              },
              {
                ...keyColumn('oct', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'oct')}
                    columnName="oct"
                    forecastVal={[selectedYear, 10]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Oct',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'oct')},
              {
                ...keyColumn('nov', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'nov')}
                    columnName="nov"
                    forecastVal={[selectedYear, 11]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Nov',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'nov')},
              {
                ...keyColumn('dec', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'dec')}
                    columnName="dec"
                    forecastVal={[selectedYear, 12]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Dec',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'dec')},
              {...keyColumn('fy', textColumn),
                component: ({rowData, focus}) => (
                  <ReadOnlyColumn decider={2} rowData={rowData} columnName='fy' extraMark={true}/>
                ), title: `FY ${selectedYear} Total`, disabled: true},

            ]);
          } else {
            setColumns([
              {
                ...keyColumn('planMonth', textColumn),
                title: 'Plan month',
                disabled: true,
              },
              {
                ...keyColumn('planYear', textColumn),
                title: 'Plan year',
                disabled: true,
              },
              {
                ...keyColumn('jan', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'jan')}
                    columnName="jan"
                    forecastVal={[selectedYear, 1]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jan',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'jan'),
              },
              {
                ...keyColumn('feb', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'feb')}
                    columnName="feb"
                    forecastVal={[selectedYear, 2]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Feb',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'feb'),
              },
              {
                ...keyColumn('mar', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'mar')}
                    columnName="mar"
                    forecastVal={[selectedYear, 3]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Mar',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'mar'),
              },
              {
                ...keyColumn('apr', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'apr')}
                    columnName="apr"
                    forecastVal={[selectedYear, 4]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Apr',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'apr'),
              },
              {
                ...keyColumn('may', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'may')}
                    columnName="may"
                    forecastVal={[selectedYear, 5]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'May',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'may')},
              {
                ...keyColumn('jun', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'jun')}
                    columnName="jun"
                    forecastVal={[selectedYear, 6]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jun',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'jun')},
              {
                ...keyColumn('jul', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'jul')}
                    columnName="jul"
                    forecastVal={[selectedYear, 7]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jul',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'jul')},
              {
                ...keyColumn('aug', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'aug')}
                    columnName="aug"
                    forecastVal={[selectedYear, 8]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Aug',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'aug')},
              {
                ...keyColumn('sep', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'sep')}
                    columnName="sep"
                    forecastVal={[selectedYear, 9]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Sep',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'sep'),
              },
              {
                ...keyColumn('oct', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'oct')}
                    columnName="oct"
                    forecastVal={[selectedYear, 10]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Oct',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'oct')},
              {
                ...keyColumn('nov', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'nov')}
                    columnName="nov"
                    forecastVal={[selectedYear, 11]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Nov',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'nov')},
              {
                ...keyColumn('dec', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput decider={2} setVarianceTrigger={setVarianceTrigger}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    isDisabled={isDataSheetCellDisabled(rowData, 'dec')}
                    columnName="dec"
                    forecastVal={[selectedYear, 12]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Dec',
                disabled: ({rowData}) =>
                  isDataSheetCellDisabled(rowData, 'dec')},
              {...keyColumn('fy', textColumn),
                component: ({rowData, focus}) => (
                  <ReadOnlyColumn decider={2} rowData={rowData} columnName='fy' extraMark={true}/>
                ), title: `FY ${selectedYear} Total`, disabled: true},
            ]);
          }
        }
      }, 100);
    }
  }, [selectedYear, accessControl, isDataFatched, isForecastUpdateTabEditable, years, previousMonthForecastEditButton, isPreviousYearData]); // modifiedData

  useEffect(()=>{
    if (Object.keys(row).length > 1) {
      const forecastYearMonth = selectedYear === years[0]? data1 : (selectedYear === years[1]? data2 : data3);
      if (previousMonthForecastEditButton==true) {
        if ((isPreviousYearData==true&&selectedYear == years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])] || isPreviousYearData==false&&selectedYear == years[0])) {
          const monthsData = {};
          for (let i=0; i<(selectedRow.executionMonthYear[1]==1?10: selectedRow.executionMonthYear[1]==2 ? 11: curMonth+1); i++) {
            monthsData[`${months[i]}`] = forecastYearMonth[0][months[i]];
          }
          const lastData = {...forecastYearMonth[1], ...monthsData};
          forecastYearMonth[1] = {...lastData, planMonth: forecastYearMonth[1].planMonth, workflow_id: forecastYearMonth[1].workflow_id, month: forecastYearMonth[1].month, key: forecastYearMonth[1].key, showKey: forecastYearMonth[1].showKey};
        }
      } else {
        if ((isPreviousYearData==true&&selectedYear == years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])] || isPreviousYearData==false&&selectedYear == years[0]) && previousMonthForecastEditButton!==true) {
          // (previousMonthForecastEditButton==true? curMonth < months.indexOf('oct'):curMonth >= months.indexOf('oct'))
          // console.log(curMonth);
          const monthsData = {};
          if (selectedRow.executionMonthYear[1]==1) {
            for (let i=0; i<10; i++) {
              monthsData[`${months[i]}`] = forecastYearMonth[1][months[i]];
            }
          } else if (selectedRow.executionMonthYear[1]==2) {
            for (let i=0; i<11; i++) {
              monthsData[`${months[i]}`] = forecastYearMonth[1][months[i]];
            }
          } else {
            for (let i=0; i<curMonth+1; i++) {
              monthsData[`${months[i]}`] = forecastYearMonth[1][months[i]];
            }
          }
          const lastData = {...row, ...monthsData};
          forecastYearMonth[1] = {...lastData, planMonth: forecastYearMonth[1].planMonth, workflow_id: forecastYearMonth[1].workflow_id, month: forecastYearMonth[1].month, key: forecastYearMonth[1].key, showKey: forecastYearMonth[1].showKey};
        } else {
          forecastYearMonth[1] = {...row, planMonth: forecastYearMonth[1].planMonth, workflow_id: forecastYearMonth[1].workflow_id, month: forecastYearMonth[1].month, key: forecastYearMonth[1].key, showKey: forecastYearMonth[1].showKey};
        }
      }
      // forecastYearMonth[1] = {...row, planMonth: forecastYearMonth[1].planMonth, workflow_id: forecastYearMonth[1].workflow_id, month: forecastYearMonth[1].month, key: forecastYearMonth[1].key};
      selectedYear === years[0]?
      setdata1([...forecastYearMonth]):
      (selectedYear === years[1]? setdata2([...forecastYearMonth]) : setdata3([...forecastYearMonth]));

      if (selectedRow.status=='NEW') {
        selectedYear === years[0]?
      setData([...forecastYearMonth]):
      (selectedYear === years[1]? setData([...forecastYearMonth]) : setData([...forecastYearMonth]));
      }
      months.forEach((m, i) => {
        if (document.getElementsByClassName(`${selectedYear}-${m}`)[0]!==undefined) {
          document.getElementsByClassName(`${selectedYear}-${m}`)[0].style.borderColor='';
        }
      });

      setTimeout(()=>{
        setVarianceTrigger((prev)=>prev+1);
      }, 500);
    }
  }, [dataCopyTrigger]);

  const copyRow = (row) => {
    setRow(row);
    setDataCopyTrigger((prev)=> prev+1);
  };


  const columnsSetterReadOnly = () => {
    const columns = [];
    months.forEach((mon) => {
      columns.push({
        ...keyColumn(mon, floatColumn),
        title: titleCase(mon),
        disabled: true,
        component: ({rowData, focus}) => (
          <div className={`${selectedYear}-${mon}-data`}>
            <ReadOnlyColumn decider={2} rowData={rowData} columnName={mon} extraMark={true}/>
          </div>
        ),
      });
    });
    return columns;
  };
  // This function used to call the forecast data and set it inside the modified Data variable inside the index.js
  const getCall = () => {
    DialogService.getForecastData(selectedRow.workFlowId, selectedRow.prevWorkFlowId, selectedRow.sector)
        .then(async (res) => {
          let obj = {};
          // console.log(res);
          // debugger;
          const ExeMonths = [selectedRow.executionMonthYear[0]];
          if (res.length) {
            const NotJanFeb = selectedRow.executionMonthYear[1]!==1 && selectedRow.executionMonthYear[1]!==2;
            for (let k=0; k<res.length; k++) {
              if (+res[k]?.executionMonthYear[0]!==+selectedRow.executionMonthYear[0] && +selectedRow.executionMonthYear[0]-+res[k]?.executionMonthYear[0]==1 && !ExeMonths.includes(res[k]?.executionMonthYear[0]) && ExeMonths?.length<=3) {
                ExeMonths.push(res[k]?.executionMonthYear[0]);
              }
            }
            ExeMonths.sort((a, b)=>+a-+b);
            if (NotJanFeb) {
              if (ExeMonths.length!==3) {
                setExecMonthDecider([+ExeMonths[0], +ExeMonths[0]+1, +ExeMonths[0]+2]);
              } else {
                setExecMonthDecider(ExeMonths);
              }
            } else {
              setYears([+selectedRow.executionMonthYear[0]-1, +selectedRow.executionMonthYear[0], +selectedRow.executionMonthYear[0]+1]);
            }
            console.log(ExeMonths, years);
            setForecastData(res);
            res.forEach((val) => {
              const split = val.forecastYearMonth;
              const checkIn= (NotJanFeb?+ExeMonths[0]:+[+selectedRow.executionMonthYear[0]-1, +selectedRow.executionMonthYear[0], +selectedRow.executionMonthYear[0]+1][0]);
              if (+split[0] >= checkIn) {
                if (!workflowIdsObj[val.workflowId]) {
                  workflowIdsObj[val.workflowId] = val;
                  setWorkflowIdsObj({...workflowIdsObj});
                }
                // This function used to set the month and year to each object
                obj = {...monthNYearSetter(obj, val)};
              }
            });
            // if some data is received ans some not like 2023 year data is there but 2024 data is not
            const creatingRow = await rowsSetter(obj, workflowIdsObj, selectedRow, ExeMonths);
            obj = {...creatingRow};
          } else {
            // when there is no data for a all three years and it is first time Not started forecast
            obj = forecastDefaultData(selectedRow, years);
            // console.log(obj);
            // console.log({2023:});
          }
          console.log('@fsct getCall ModifiedData ', obj);
          setModifiedData({...obj});
          setIsDataFatched(true);
        })
        .catch((err) => console.log(err));
  };

  const validationCheck = () => {
    const validationErrorArr = [];
    // console.log('@variance modifiedData', modifiedData);
    Object.keys(modifiedData).forEach((y) => {
      let quarter1 = 0;
      let quarter2 = 0;
      let quarter = 0;


      let quaterMonthCount=0;
      let prevQuater=null;

      months.forEach((m, i) => {
        const currentQuaterMonths = quarters['Q'+(quarter+1)];

        // add the quater count to the quaterMonthCount only when quater change
        if (prevQuater!==quarter) {
          const lenghtOfCurrentQuater = currentQuaterMonths.split(',').length;
          quaterMonthCount+=lenghtOfCurrentQuater;
          prevQuater=quarter;
        }

        // do addintion for values
        quarter1 = quarter1 + (modifiedData[y][selectedRow.prevWorkFlowId] && +modifiedData[y][selectedRow.prevWorkFlowId][m] || 0);
        quarter2 = quarter2 + ((modifiedData[y][selectedRow.workFlowId] && +modifiedData[y][selectedRow.workFlowId][m]) || 0);
        // console.log(quarter1, quarter2);
        if ((i+1) % quaterMonthCount == 0) {
          const difference = Math.abs(quarter1 - quarter2);
          quarter += 1;
          if (difference != 0) {
            let variance = quarter1 == 0 ? 100 : (difference/quarter1) * 100;
            variance=Math.round(variance * 10) / 10; // show max 1 decimal
            if (Math.abs(variance) > 5) {
              const obj = {
                year: y,
                month: i,
                quarter,
                variance: Math.abs(variance),
              };

              validationErrorArr.push(obj);
            }
          }
          quarter1 = 0;
          quarter2 = 0;
        }
      });
    });
    if (validationArr.length===0 && validationErrorArr.length===0) {
      // setSelectedYear(yearCount[0]);
      let notSameCount =0;
      const Ddata1 = [];
      const Ddata2 = [];
      const Ddata3 = [];
      Object.values(modifiedData).map((mobj, index)=>{
        Object.values(mobj).map((sobj)=>{
          if (index == 0) {
            Ddata1.push(sobj);
          }
          if (index == 1) {
            Ddata2.push(sobj);
          }
          if (index == 2) {
            Ddata3.push(sobj);
          }
        });
      });
      // Sometimes the user forgot to fill up the previous month forecast at that time he wont get two arrays he will get only one array this cause issue so when the data is not there we adding the default 0 data
      if (Ddata1.length==1) {
        if (Ddata1[0]['workflow_id']!=selectedRow.workFlowId) {
          Ddata1.push({
            'month': selectedRow.executionMonthYear[1]-1,
            'planMonth': monthArr[selectedRow.executionMonthYear[1]-1],
            'planYear': years[0],
            'jan': 0,
            'feb': 0,
            'mar': 0,
            'apr': 0,
            'may': 0,
            'jun': 0,
            'jul': 0,
            'aug': 0,
            'sep': 0,
            'oct': 0,
            'nov': 0,
            'dec': 0,
            'fy': '0',
            'workflow_id': selectedRow.workFlowId,
            'key': 2,
          });
        } else {
          Ddata1.push({
            'month': selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2,
            'planMonth': monthArr[selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2],
            'planYear': years[0],
            'jan': 0,
            'feb': 0,
            'mar': 0,
            'apr': 0,
            'may': 0,
            'jun': 0,
            'jul': 0,
            'aug': 0,
            'sep': 0,
            'oct': 0,
            'nov': 0,
            'dec': 0,
            'fy': '0',
            'workflow_id': selectedRow.prevWorkFlowId,
            'key': 1,
          });
        }
      }
      if (Ddata2.length==1) {
        if (Ddata2[0]['workflow_id']!=selectedRow.workFlowId) {
          Ddata2.push({
            'month': selectedRow.executionMonthYear[1]-1,
            'planMonth': monthArr[selectedRow.executionMonthYear[1]-1],
            'planYear': years[0],
            'jan': 0,
            'feb': 0,
            'mar': 0,
            'apr': 0,
            'may': 0,
            'jun': 0,
            'jul': 0,
            'aug': 0,
            'sep': 0,
            'oct': 0,
            'nov': 0,
            'dec': 0,
            'fy': '0',
            'workflow_id': selectedRow.workFlowId,
            'key': 2,
          });
        } else {
          Ddata2.push({
            'month': selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2,
            'planMonth': monthArr[selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2],
            'planYear': years[0],
            'jan': 0,
            'feb': 0,
            'mar': 0,
            'apr': 0,
            'may': 0,
            'jun': 0,
            'jul': 0,
            'aug': 0,
            'sep': 0,
            'oct': 0,
            'nov': 0,
            'dec': 0,
            'fy': '0',
            'workflow_id': selectedRow.prevWorkFlowId,
            'key': 1,
          });
        }
      }
      if (Ddata3.length==1) {
        if (Ddata3[0]['workflow_id']!=selectedRow.workFlowId) {
          Ddata3.push({
            'month': selectedRow.executionMonthYear[1]-1,
            'planMonth': monthArr[selectedRow.executionMonthYear[1]-1],
            'planYear': years[0],
            'jan': 0,
            'feb': 0,
            'mar': 0,
            'apr': 0,
            'may': 0,
            'jun': 0,
            'jul': 0,
            'aug': 0,
            'sep': 0,
            'oct': 0,
            'nov': 0,
            'dec': 0,
            'fy': '0',
            'workflow_id': selectedRow.workFlowId,
            'key': 2,
          });
        } else {
          Ddata3.push({
            'month': selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2,
            'planMonth': monthArr[selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2],
            'planYear': years[0],
            'jan': 0,
            'feb': 0,
            'mar': 0,
            'apr': 0,
            'may': 0,
            'jun': 0,
            'jul': 0,
            'aug': 0,
            'sep': 0,
            'oct': 0,
            'nov': 0,
            'dec': 0,
            'fy': '0',
            'workflow_id': selectedRow.prevWorkFlowId,
            'key': 1,
          });
        }
      }
      Ddata1.length>0 && Ddata1!==null && months.forEach((m, i) => {
        if (Ddata1[0][m] != +Ddata1[1][m]) {
          notSameCount+=1;
        }
      });
      Ddata2.length>0 && Ddata2!==null && months.forEach((m, i) => {
        if (Ddata2[0][m] != +Ddata2[1][m]) {
          notSameCount+=1;
        }
      });
      Ddata3.length>0 && Ddata3!==null && months.forEach((m, i) => {
        if (Ddata3[0][m] != +Ddata3[1][m]) {
          notSameCount+=1;
        }
      });
      if (notSameCount>0) {
        setpreviousMonthPresentMonthNotSame(true);
        setShowSuccessDialog3(true);
      } else {
        setpreviousMonthPresentMonthNotSame(false);
        setShowSuccessDialog3(true);
      }
      // setTimeout(()=>{
      //   setShowSuccessDialog3(true);
      // }, 500);
    } else {
      // setSelectedYear(yearCount[0]);
      // setOldYear(selectedYear);
      setValidationArr(validationErrorArr);
      setShowVarianceDialog(true);
    }
  };


  const updateStatus = () => {
    DialogService.updateStatus(selectedRow.workFlowId, 'DRAFT')
        .then((res) => {
          if (res.status == 200 || res===true) {
            dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));
            setShowSuccessDialog(true);
            // setIsSavePopupVisible(true);
            if (submitted) {
              dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));

              setTabsEnableCheck((prev) => {
                return {...prev, buyPlan: true};
              });
            }

            dispatch(changeBuyplanStatus('DRAFT'));
            setValidationCallCheck(false);
            dispatch(setForecastUpdatesaveButton(false));
            setShowSuccessDialog2(true);
          }
          if (res['errorMessage']!==undefined && res['errorMessage'].includes('Existing Status:DRAFT Requested Status:DRAFT')) {
            dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));
            setShowSuccessDialog(true);
            // setIsSavePopupVisible(true);
            setTabsEnableCheck((prev) => {
              return {...prev, buyPlan: true};
            });
            dispatch(changeBuyplanStatus('DRAFT'));
            setValidationCallCheck(false);
            dispatch(setForecastUpdatesaveButton(false));
            setShowSuccessDialog2(true);
          }
        });
  };

  const isDataSheetCellDisabled = (rowData, month) => {
    const flag = months.indexOf(month);
    const NotJanFeb = selectedRow.executionMonthYear[1]!==1 && selectedRow.executionMonthYear[1]!==2;
    const is =
    accessControl === 'Edit' ?
      // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
      (buttonStatus==='edit' || ((currentRole.includes('Admin')) && (selectedRow.status === 'DRAFT' || selectedRow.status=='REJECTED' || selectedRow.status=='NEW'))) ?
        (isPreviousYearData==true && NotJanFeb && selectedYear == years[0])? true :
        (rowData?.showKey == 1 || rowData?.showKey == 3) ||
        ((isPreviousYearData==true && selectedYear !== years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])] || isPreviousYearData==false && selectedYear !== years[0]) && previousMonthForecastEditButton==true) ||
        ((isPreviousYearData==true && selectedYear == years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])] || isPreviousYearData==false&&selectedYear == years[0]) && ( previousMonthForecastEditButton==true? (selectedRow.executionMonthYear[1]==1? (flag==11 || flag==10) : selectedRow.executionMonthYear[1]==2 ? flag == 11 : curMonth < flag) : (selectedRow.executionMonthYear[1]==1? (flag!==11 && flag !== 10) : selectedRow.executionMonthYear[1]==2 ? flag !== 11 :curMonth >= flag)) ) ?
          true :
          false :
        true :
    true;
    return is;
  };

  const CommaRemoval = (val) => {
    // const re = new RegExp(`^[0-9]{1,2}([,.][0-9]{1,2})?$`);
    // const flag = re.test(val.toString());
    // if (flag) {
    return (isNaN(val?.toString().replaceAll(',', '')) ? 0 : val?.toString().replaceAll(',', '')) || '';
    // }
  };

  const pasteCall = () => {
    let fyTotal = 0;
    if (!skipper || firstLoad) {
      // for (let j = 1; j < 4; j++) {
      data[1] && Object.keys(data[1]).forEach((val, i) => {
        if (months.includes(val)) {
          fyTotal += +CommaRemoval(data[1][val]);
          const selectedMonth = months.indexOf(val);
          const payloadData = {
            'workflowId': data[1].workflow_id,
            'forecastYearMonth': [+selectedYear, selectedMonth+1],
            'forecastValue': data[1][val],
          };
          if (!subMissionData[val + selectedYear]) subMissionData[val + selectedYear] = payloadData;
          else subMissionData[val + selectedYear] = payloadData;
        }
      });
      setSubmissionData({...subMissionData});
      data[1] = {...data[1], fy: fyTotal};
      // console.log('@updateData 1');
      setData([...data]);
      // }
    }
    if (!firstLoad) {
      setFirstLoad(true);
    }
    setSkipper(true);
    setTimeout(() => {
      setSkipper(false);
    }, 1000);
  };

  // useEffect(() => {
  //   if (skipper) pasteCall();
  // }, [data]);
  const formatNumber = (inputNumber) => {
    let formetedNumber=(Number(inputNumber)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const splitArray=formetedNumber.split('.');
    if (splitArray.length>1) {
      if (!((+splitArray[1]) > 0)) formetedNumber=splitArray[0];
    }
    return (formetedNumber);
  };

  useEffect(() => {
    let total = 0;
    if (isForecastUpdateTabEditable && data1 !== null && data1!==undefined && data1.length > 0) {
      Object.keys(data1[1]).forEach((val) => {
        if (months.includes(val)) {
          total += isNaN(+roundToTwo(2, CommaRemoval(data1[1][val]))) ? 0 : +roundToTwo(2, CommaRemoval(data1[1][val]));

          data1[1][val] = isNaN(data1[1][val]?.toString().split(',').join(''))?0: +(data1[1][val]?.toString().split(',').join(''));
        }
      });
      if (data1[1]['fy'] !== total) {
        data1[1]['fy'] = formatNumber(total);
        setdata1(data1);
      }
    }
  }, [data1]);

  useEffect(() => {
    let total = 0;
    if (isForecastUpdateTabEditable && data2 !== null && data2!==undefined && data2.length > 0) {
      Object.keys(data2[1]).forEach((val) => {
        if (months.includes(val)) {
          total += isNaN(+roundToTwo(2, CommaRemoval(data2[1][val]))) ? 0 : +roundToTwo(2, CommaRemoval(data2[1][val]));
          data2[1][val] = isNaN(data2[1][val]?.toString().split(',').join(''))?0: +(data2[1][val]?.toString().split(',').join(''));
        }
      });
      if (data2[1]['fy'] !== total) {
        data2[1]['fy'] = formatNumber(total);
        setdata2(data2);
      }
      // data2[1] = fyCalculateTotal(data2[1]);
      // setdata1(data2);
    }
  }, [data2]);

  useEffect(() => {
    let total = 0;
    if (isForecastUpdateTabEditable && data3 !== null && data3!==undefined && data3.length > 0) {
      Object.keys(data3[1]).forEach((val) => {
        if (months.includes(val)) {
          total += isNaN(+roundToTwo(2, CommaRemoval(data3[1][val]))) ? 0 : +roundToTwo(2, CommaRemoval(data3[1][val]));
          data3[1][val] = isNaN(data3[1][val]?.toString().split(',').join(''))?0: +(data3[1][val]?.toString().split(',').join(''));
        }
      });
      if (data3[1]['fy'] !== total) {
        data3[1]['fy'] = formatNumber(total);
        setdata3(data3);
      }
      // data3[1] = fyCalculateTotal(data3[1]);
      // setdata1(data3);
    }
  }, [data3]);

  const handleVarianceModalSubmit = (varianceData) => {
    // setSelectedYear(yearCount[0]);
    postCall(varianceData);
    // setTimeout(()=>{
    //   setShowSuccessDialog(true);
    // }, 800);
  };

  const generateData = (year) =>{
    return {
      'month': selectedRow.executionMonthYear[1]-1,
      'planMonth': monthArr[selectedRow.executionMonthYear[1]-1],
      'planYear': year,
      'jan': 0,
      'feb': 0,
      'mar': 0,
      'apr': 0,
      'may': 0,
      'jun': 0,
      'jul': 0,
      'aug': 0,
      'sep': 0,
      'oct': 0,
      'nov': 0,
      'dec': 0,
      'fy': 0,
      'workflow_id': selectedRow.workFlowId,
    };
  };

  // const [hasZero, setHasZero] = useState(false);
  const [nonZeroDialogIsOpen, setNonZeroDialogIsOpen] = useState(false);
  const [postApiData, setPostApiData] = useState({});

  const handleCloseNonZeroDialog =() =>{
    setPostApiData({});
    setNonZeroDialogIsOpen(false);
    handleCloseVarianceDialog();
    handleCloseSuccess();
    setShowLoader(false);
  };

  const postCall = (varianceData=null) => {
    // if (!isTrue && isValidate) {
    //   return false;
    // }
    // console.log(data1, data2, data3);
    setPostApiData({});
    setErrorMessage(null);
    const postData={};
    const objectAll = [];
    let hasZero;

    // using modifiedData which have latest values
    const Ddata1 = [];
    const Ddata2 = [];
    const Ddata3 = [];
    // console.log(modifiedData);
    Object.values(modifiedData).map((mobj, index)=>{
      Object.values(mobj).map((sobj)=>{
        if (index == 0) {
          Ddata1.push(sobj);
        }
        if (index == 1) {
          Ddata2.push(sobj);
        }
        if (index == 2) {
          Ddata3.push(sobj);
        }
      });
    });
    // console.log('modifiedData variables');
    if (Ddata1.length==1) {
      Ddata1.push(generateData(years[0]));
    }
    if (Ddata2.length==1) {
      Ddata2.push(generateData(years[1]));
    }
    if (Ddata3.length==1) {
      Ddata3.push(generateData(years[2]));
    }
    // console.log(Ddata1);
    // console.log(Ddata2);
    // console.log(Ddata3);
    // console.log('normal data variables');
    // console.log(data1);
    // console.log(data2);
    // console.log(data3);
    Ddata1.map((d1, index)=>{
      if (+d1.workflow_id == +selectedRow.workFlowId) {
        Object.keys(d1).map((obj)=>{
          if (months.includes(obj)) {
            // if (+CommaRemoval(d1[obj]) === 0 && hasZero === false) {
            //   hasZero = true;
            // }
            // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [+years[0], months.indexOf(obj)+1],
              forecastValue: +CommaRemoval(d1[obj]),
              // forecastValue: wfDetails?.decimal_scale === 0 ? roundToTwo(2, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    },
    );
    Ddata2.map((d1, index)=>{
      if (+d1.workflow_id == +selectedRow.workFlowId) {
        Object.keys(d1).map((obj)=>{
          if (months.includes(obj)) {
            // if (+CommaRemoval(d1[obj]) === 0 && hasZero === false) {
            //   hasZero = true;
            // }
            // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [+years[0]+1, months.indexOf(obj)+1],
              forecastValue: +CommaRemoval(d1[obj]),
              // forecastValue: wfDetails?.decimal_scale === 0 ? roundToTwo(2, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
          // }
          }
        });
      }
    },
    );
    Ddata3.map((d1, index)=>{
      if (+d1.workflow_id == +selectedRow.workFlowId) {
        Object.keys(d1).map((obj)=>{
          if (months.includes(obj)) {
          // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [+years[0]+2, months.indexOf(obj)+1],
              forecastValue: +CommaRemoval(d1[obj]),
              // forecastValue: wfDetails?.decimal_scale === 0 ? roundToTwo(2, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
          // }
          }
        });
      }
    },
    );
    postData.forecastDetails=objectAll;
    postData.forecastvariance=[];
    if (typeof varianceData==='object' && varianceData) {
      Object.keys(varianceData).map((quarter)=>{
        postData.forecastvariance.push(varianceData[quarter]);
      });
    }

    // conditionally
    if (hasZero) {
      setPostApiData(postData);
      setNonZeroDialogIsOpen(true);
      return;
    }
    postApiCall(postData);

    // DialogService.saveForecastData({...postData, isForecastSubmit: submitted}).then((res) => {
    //   if (res == true && selectedRow.status !== 'DRAFT') {
    //     updateStatus();
    //   } else {
    //     // show error popup dialog
    //     setIsSavePopupVisible(true);
    //     if (submitted) {
    //       dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));

    //       setTabsEnableCheck((prev) => {
    //         return {...prev, buyPlan: true};
    //       });
    //     }
    //     if (submitted == true) {
    //       dispatch(setForecastUpdatesaveButton(false));
    //     }
    //     setValidationCallCheck(false);
    //     setShowSuccessDialog(true);
    //     setShowSuccessDialog2(true);
    //     // setSelectedYear(oldYear);
    //     setPreviousVarianceTrigger(!previousVarianceTrigger);
    //   }
    // }).catch((err)=>{
    //   // const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
    //   const errmsg = err && err.userMessage ? err.userMessage : err?.response?.data?.userMessage ? err?.response?.data?.userMessage :'Error occured when saving';
    //   setErrorMessage(errmsg);
    //   // setSelectedYear(oldYear);
    //   setShowErrorDialog(true);
    // });
  };

  const handleSaveData = () =>{
    setNonZeroDialogIsOpen(false);
    // setPostApiData({});
    postApiCall(postApiData);
  };

  const postApiCall =(postData)=>{
    DialogService.saveForecastData({...postData, isForecastSubmit: submitted}).then((res) => {
      if (res == true && selectedRow.status !== 'DRAFT') {
        updateStatus();
      } else {
        // show error popup dialog
        setIsSavePopupVisible(true);
        if (submitted) {
          dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));

          setTabsEnableCheck((prev) => {
            return {...prev, buyPlan: true};
          });
        }
        if (submitted == true) {
          dispatch(setForecastUpdatesaveButton(false));
        }
        setValidationCallCheck(false);
        setShowSuccessDialog(true);
        setShowSuccessDialog2(true);
        // setSelectedYear(oldYear);
        setPreviousVarianceTrigger(!previousVarianceTrigger);
      }
    }).catch((err)=>{
      // const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
      const errmsg = err && err.userMessage ? err.userMessage : err?.response?.data?.userMessage ? err?.response?.data?.userMessage :'Error occured when saving';
      setErrorMessage(errmsg);
      // setSelectedYear(oldYear);
      setShowErrorDialog(true);
    });
  };

  const handleCloseVarianceDialog = () =>{
    setShowVarianceDialog();
    setValidationArr([]);
    // setSelectedYear(oldYear);
  };

  const handleCloseSuccess = ()=>{
    // postCall();
    // setTimeout(()=>{
    //   setpreviousMonthPresentMonthNotSame(false);
    // }, 300);
    setShowSuccessDialog(false);
    setShowSuccessDialog2(false);
    setShowSuccessDialog3(false);
    setShowVarianceDialog();
    setValidationArr([]);
    setShowVarianceDialog(false);
  };

  const [nonZeroDialogIsOpen2, setNonZeroDialogIsOpen2] = useState(false);
  const [postApiData2, setPostApiData2] = useState({});
  const handleCloseNonZeroDialog2 = () =>{
    setPostApiData2({});
    setNonZeroDialogIsOpen2(false);
    handleCloseVarianceDialog();
    handleCloseSuccess();
    setShowLoader(false);
  };

  const handleCloseSuccess2 = (varianceData=null)=>{
    setPostApiData2({});
    setErrorMessage(null);
    const postData={};
    const objectAll = [];
    let hasZero;

    // using modifiedData which have latest values
    const Ddata1 = [];
    const Ddata2 = [];
    const Ddata3 = [];
    // console.log(modifiedData);
    Object.values(modifiedData).map((mobj, index)=>{
      Object.values(mobj).map((sobj)=>{
        if (index == 0) {
          Ddata1.push(sobj);
        }
        if (index == 1) {
          Ddata2.push(sobj);
        }
        if (index == 2) {
          Ddata3.push(sobj);
        }
      });
    });
    if (Ddata1.length==1) {
      Ddata1.push(generateData(years[0]));
    }
    if (Ddata2.length==1) {
      Ddata2.push(generateData(years[1]));
    }
    if (Ddata3.length==1) {
      Ddata3.push(generateData(years[2]));
    }
    // console.log('modifiedData variables');
    // console.log(Ddata1);
    // console.log(Ddata2);
    // console.log(Ddata3);
    // console.log('normal data variables');
    // console.log(data1);
    // console.log(data2);
    // console.log(data3);
    Ddata1.map((d1, index)=>{
      if (+d1.workflow_id == +selectedRow.workFlowId) {
        Object.keys(d1).map((obj)=>{
          if (months.includes(obj)) {
            // if (+CommaRemoval(d1[obj]) === 0 && hasZero === false) {
            //   hasZero = true;
            // }
            // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [+years[0], months.indexOf(obj)+1],
              forecastValue: +CommaRemoval(d1[obj]),
              // forecastValue: wfDetails?.decimal_scale === 0 ? roundToTwo(2, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    },
    );
    Ddata2.map((d1, index)=>{
      if (+d1.workflow_id == +selectedRow.workFlowId) {
        Object.keys(d1).map((obj)=>{
          if (months.includes(obj)) {
            // if (+CommaRemoval(d1[obj]) === 0 && hasZero === false) {
            //   hasZero = true;
            // }
            // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [+years[0]+1, months.indexOf(obj)+1],
              forecastValue: +CommaRemoval(d1[obj]),
              // forecastValue: wfDetails?.decimal_scale === 0 ? roundToTwo(2, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
          // }
          }
        });
      }
    },
    );
    Ddata3.map((d1, index)=>{
      if (+d1.workflow_id == +selectedRow.workFlowId) {
        Object.keys(d1).map((obj)=>{
          if (months.includes(obj)) {
          // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [+years[0]+2, months.indexOf(obj)+1],
              forecastValue: +CommaRemoval(d1[obj]),
              // forecastValue: wfDetails?.decimal_scale === 0 ? roundToTwo(2, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
          // }
          }
        });
      }
    },
    );
    postData.forecastDetails=objectAll;
    postData.forecastvariance=[];
    if (typeof varianceData==='object' && varianceData) {
      Object.keys(varianceData).map((quarter)=>{
        postData.forecastvariance.push(varianceData[quarter]);
      });
    }
    if (hasZero) {
      setPostApiData2(postData);
      setNonZeroDialogIsOpen2(true);
      return;
    }
    postApiCall2(postData);
  };

  const handleSaveData2 =()=>{
    setNonZeroDialogIsOpen2(false);
    // setPostApiData2({});
    postApiCall2(postApiData2);
  };

  const postApiCall2 = (postData) =>{
    DialogService.saveForecastData({...postData, isForecastSubmit: submitted}).then((res) => {
      if (res == true && selectedRow.status !== 'DRAFT') {
        updateStatus();
      } else {
        // show error popup dialog
        setIsSavePopupVisible(true);

        setShowSuccessDialog2(true);
        // setSelectedYear(oldYear);
        if (submitted) {
          dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));

          setTabsEnableCheck((prev) => {
            return {...prev, buyPlan: true};
          });
        }
        if (submitted == true) {
          dispatch(setForecastUpdatesaveButton(false));
        }
        setValidationCallCheck(false);
        // setShowSuccessDialog(true);
        setPreviousVarianceTrigger(!previousVarianceTrigger);
      }
    }).catch((err)=>{
      // const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
      const errmsg = err && err.userMessage ? err.userMessage : err?.response?.data?.userMessage ? err?.response?.data?.userMessage :'Error occured when saving';
      setErrorMessage(errmsg);
      setShowSuccessDialog2(false);
      // setSelectedYear(oldYear);
      setShowErrorDialog(true);
    });
    setShowSuccessDialog2(false);
    setShowVarianceDialog(false);
  };


  // These function is determine wheater previous month and present month forecast is same or not and decide to show popup
  const determiningPreviousForecast = ()=>{
    const temporarypreviousMonthDifferenceDatas = {};
    const tempdata = isPreviousYearData==false? data1 : execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==1? data2: data3;
    tempdata.length>0 && tempdata!==null && months.slice(0, selectedRow.executionMonthYear[1]==1?10:selectedRow.executionMonthYear[1]==2? 11: selectedRow.executionMonthYear[1]-3).forEach((m, i) => {
      if (+tempdata[0][m] !== +tempdata[1][m]) {
        temporarypreviousMonthDifferenceDatas[m] = {'id': (ConvertedPastMonthComments[m]==undefined || ConvertedPastMonthComments[m]['id']==undefined)?0:ConvertedPastMonthComments[m]['id'], 'comment': (ConvertedPastMonthComments[m]==undefined || ConvertedPastMonthComments[m]['comment']==undefined)?'':ConvertedPastMonthComments[m]['comment']};
      }
    });

    if (Object.keys(temporarypreviousMonthDifferenceDatas).length>0) {
      setPreviousMonthDifferenceData({...temporarypreviousMonthDifferenceDatas});
      setshowPreviousMonthDifference(true);
    } else {
      const tempdata = isPreviousYearData==false? data1 : execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==1? data2: data3;
      // if there is not difference between previous month and current months
      const sendingData = {
        'forecastDetails': [],
        'forecastvariance': [],
        'isForecastCommentReqd': true,
        'previousPlanMonthComments': [],
      };
      tempdata.length>0 && tempdata!==null && months.slice(0, selectedRow.executionMonthYear[1]==1?10: selectedRow.executionMonthYear[1]==2? 11: selectedRow.executionMonthYear[1]-3).forEach((m, i) => {
        sendingData['forecastDetails']?.push({
          'workflowId': selectedRow.workFlowId,
          'forecastYearMonth': [(isPreviousYearData==false || selectedRow.executionMonthYear[1]==1 || selectedRow.executionMonthYear[1]==2)? +years[0] : +years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])], months.indexOf(m)+1],
          'forecastValue': +tempdata[1][m],
          // 'forecastValue': wfDetails?.decimal_scale === 0 ? roundToTwo(2, +tempdata[1][m]) : +tempdata[1][m],
        });
      });
      DialogService.saveForecastData({...sendingData, isForecastSubmit: submitted}).then((res) => {
        if (res == true && selectedRow.status !== 'DRAFT') {
          updateStatus();
        } else {
          // show error popup dialog
          setIsSavePopupVisible(true);
          if (submitted) {
            dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));

            setTabsEnableCheck((prev) => {
              return {...prev, buyPlan: true};
            });
          }
          setShowPreviousMonthSuccess(true);
          setPreviousMonthForecastEditButton(false);
          setshowPreviousMonthDifference(false);
          setPastMonthCommentsTrigger(!PastMonthCommentsTrigger);
        }
      }).catch((err)=>{
        // const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
        const errmsg = err && err.userMessage ? err.userMessage : err?.response?.data?.userMessage ? err?.response?.data?.userMessage :'Error occured when saving';
        setErrorMessage(errmsg);
        setShowErrorDialog(true);
      });
      setPreviousMonthDifferenceData({});
      setPreviousMonthForecastEditButton(false);
    }

    // console.log(temporarypreviousMonthDifferenceDatas);
  };


  const handlePreviousMonthForecastDifferenceSubmit=()=>{
    // console.log(previousMonthDifferenceData);
    const tempdata = isPreviousYearData==false? data1 : execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==1? data2: data3;
    const sendingData = {
      'forecastDetails': [],
      'forecastvariance': [],
      'isForecastCommentReqd': true,
      'previousPlanMonthComments': [],
    };
    tempdata.length>0 && tempdata!==null && months.slice(0, selectedRow.executionMonthYear[1]==1?10: selectedRow.executionMonthYear[1]==2? 11: selectedRow.executionMonthYear[1]-3).forEach((m, i) => {
      sendingData['forecastDetails']?.push({
        'workflowId': selectedRow.workFlowId,
        'forecastYearMonth': [(isPreviousYearData==false || selectedRow.executionMonthYear[1]==1 || selectedRow.executionMonthYear[1]==2)? +years[0] : +years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])], months.indexOf(m)+1],
        'forecastValue': +tempdata[1][m],
        // 'forecastValue': wfDetails?.decimal_scale === 0 ? roundToTwo(2, +tempdata[1][m]) : +tempdata[1][m],
      });
    });

    Object.keys(previousMonthDifferenceData).length>0 && Object.keys(previousMonthDifferenceData).map((data, index)=>{
      sendingData['previousPlanMonthComments']?.push( {
        'id': previousMonthDifferenceData[data]['id'],
        'workflowId': selectedRow.workFlowId,
        'planYearMonth': [(isPreviousYearData==false || selectedRow.executionMonthYear[1]==1 || selectedRow.executionMonthYear[1]==2)? +years[0] : +years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])], months.indexOf(data)+1],
        'comment': previousMonthDifferenceData[data]['comment'],
      });
    });
    // console.log(sendingData);
    DialogService.saveForecastData({...sendingData, isForecastSubmit: submitted}).then((res) => {
      if (res == true && selectedRow.status !== 'DRAFT') {
        updateStatus();
      } else {
        // show error popup dialog
        setIsSavePopupVisible(true);
        if (submitted) {
          dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT', {isForecastSubmitted: submitted}));

          setTabsEnableCheck((prev) => {
            return {...prev, buyPlan: true};
          });
        }
        setShowPreviousMonthSuccess(true);
        setPreviousMonthForecastEditButton(false);
        setshowPreviousMonthDifference(false);
        setPastMonthCommentsTrigger(!PastMonthCommentsTrigger);
      }
    }).catch((err)=>{
      // const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
      const errmsg = err && err.userMessage ? err.userMessage : err?.response?.data?.userMessage ? err?.response?.data?.userMessage :'Error occured when saving';
      setErrorMessage(errmsg);
      setShowErrorDialog(true);
    });
  };

  // useEffect(()=>{
  //   if (previousMonthForecastEditButton==true) {
  //     const temporarypreviousMonthDifferenceDatas = {...previousMonthDifferenceData};
  //     data1.length>0 && data1!==null && months.slice(0, selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2).forEach((m, i) => {
  //       if (+data1[0][m] !== +data1[1][m]) {
  //         temporarypreviousMonthDifferenceDatas[m] = {'id': (previousMonthDifferenceData[m]==undefined || previousMonthDifferenceData[m]['id']==undefined)?0:previousMonthDifferenceData[m]['id'], 'comment': (previousMonthDifferenceData[m]==undefined || previousMonthDifferenceData[m]['comment']==undefined)?'':previousMonthDifferenceData[m]['comment']};
  //       }
  //     });

  //     if (Object.keys(temporarypreviousMonthDifferenceDatas).length>0) {
  //       setPreviousMonthDifferenceData({...temporarypreviousMonthDifferenceDatas});
  //     } else {
  //       setPreviousMonthDifferenceData({});
  //     }
  //   }
  // }, [data, previousMonthForecastEditButton]);

  return (
    <>
      {((columns?.length && data?.length) || '') && (
        <div className="forecast-table">
          {selectedYear == years[0] ? (
            accessControl === 'Edit' && ((buttonStatus === 'edit' || (currentRole.includes('Admin') && (selectedRow.status === 'DRAFT' || selectedRow.status === 'REJECTED' || selectedRow.status === 'NEW'))) && (isForecastUpdateTabEditable === true || previousMonthForecastEditButton === true)) ? (
              <>
                {
      showCopyDescription ? (
      <Box sx={{position: 'absolute', width: '100px', fontSize: '10px', textTransform: 'capitalize', top: '340px', zIndex: '999', right: '20px', ...CopyHoverStyles}}>
        {copyDescription}
      </Box>):''
                }
                <DataSheetGrid
                  value={data1}
                  columns={columns}
                  onChange={(newData) => {
                    if (newData.length > 2) {
                      setdata1([newData[0], newData[1]]);
                      setData([newData[0], newData[1]]);
                      setTimeout(()=>{
                        setVarianceTrigger((prev)=>prev+1);
                      }, 100);
                    } else {
                      setdata1(newData);
                      setData(newData);
                      setTimeout(()=>{
                        setVarianceTrigger((prev)=>prev+1);
                      }, 100);
                    }
                  } }
                  rowClassName={() => 'forecast'}
                  disableContextMenu={true}
                  gutterColumn={false}
                  lockRows={true}
                  stickyRightColumn={{
                    component: ({rowData}) => ((isPreviousYearData==true && execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==1 && selectedYear !== years[0]) || (isPreviousYearData==true && execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==2 && selectedYear !== years[0] && selectedYear !== years[1]) || isPreviousYearData==false) && rowData?.showKey == 1 ? <CopyRow setShowCopyDescription={setShowCopyDescription} copyRow={copyRow} rowData={rowData} /> : '',
                    title: '',
                  }} /></>
            ) : (
              <DataSheetGrid
                value={data1}
                columns={columns}
                onChange={(newData) => {
                  if (newData.length > 2) {
                    setdata1([newData[0], newData[1]]);
                    setData([newData[0], newData[1]]);
                  } else {
                    setdata1(newData);
                    setData(newData);
                  }
                }}
                rowClassName={() => 'forecast'}
                disableContextMenu={true}
                gutterColumn={false}
                lockRows={true}
              />
            )

          ) : (
            ''
          )}

          {selectedYear == years[1] ? (
             accessControl === 'Edit' && ((buttonStatus === 'edit' || (currentRole.includes('Admin') && (selectedRow.status === 'DRAFT' || selectedRow.status === 'REJECTED' || selectedRow.status === 'NEW'))) && (isForecastUpdateTabEditable === true || previousMonthForecastEditButton === true)) ? (
              <> {
                showCopyDescription ? (
                <Box sx={{position: 'absolute', width: '100px', fontSize: '10px', textTransform: 'capitalize', top: '340px', zIndex: '999', right: '20px', ...CopyHoverStyles}}>
                  {copyDescription}
                </Box>):''
              }
              <DataSheetGrid
                value={data2}
                columns={columns}
                onChange={(newData) => {
                  if (newData.length > 2) {
                    setdata2([newData[0], newData[1]]);
                    setData([newData[0], newData[1]]);
                    setTimeout(()=>{
                      setVarianceTrigger((prev)=>prev+1);
                    }, 100);
                  } else {
                    setdata2(newData);
                    setData(newData);
                  }
                }}
                rowClassName={() => 'forecast'}
                disableContextMenu={true}
                gutterColumn={false}
                lockRows={true}
                stickyRightColumn={{
                  component: ({rowData}) =>
                  ((isPreviousYearData==true && execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==1 && selectedYear !== years[0]) || (isPreviousYearData==true && execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==2 && selectedYear !== years[0] && selectedYear !== years[1]) || isPreviousYearData==false) && rowData?.showKey == 1 ? <CopyRow setShowCopyDescription={setShowCopyDescription} copyRow={copyRow} rowData={rowData} /> : '',
                  title: '',
                }}
              /></>

            ) : (
              <DataSheetGrid
                value={data2}
                columns={columns}
                onChange={(newData) => {
                  if (newData.length > 2) {
                    setdata2([newData[0], newData[1]]);
                    setData([newData[0], newData[1]]);
                  } else {
                    setdata2(newData);
                    setData(newData);
                  }
                }}
                rowClassName={() => 'forecast'}
                disableContextMenu={true}
                gutterColumn={false}
                lockRows={true}
              />
            )
          ) : (
            ''
          )}

          {selectedYear == years[2] ? (
              accessControl === 'Edit' && ((buttonStatus === 'edit' || (currentRole.includes('Admin') && (selectedRow.status === 'DRAFT' || selectedRow.status === 'REJECTED' || selectedRow.status === 'NEW'))) && (isForecastUpdateTabEditable === true || previousMonthForecastEditButton === true)) ? (
                <> {
                  showCopyDescription ? (
                  <Box sx={{position: 'absolute', width: '100px', fontSize: '10px', textTransform: 'capitalize', top: '340px', zIndex: '999', right: '20px', ...CopyHoverStyles}}>
                    {copyDescription}
                  </Box>):''
                }
                <DataSheetGrid
                  value={data3}
                  columns={columns}
                  onChange={(newData) => {
                    if (newData.length > 2) {
                      setdata3([newData[0], newData[1]]);
                      setData([newData[0], newData[1]]);
                      setTimeout(()=>{
                        setVarianceTrigger((prev)=>prev+1);
                      }, 100);
                    } else {
                      setdata3(newData);
                      setData(newData);
                    }
                  }}
                  rowClassName={() => 'forecast'}
                  disableContextMenu={true}
                  gutterColumn={false}
                  lockRows={true}
                  stickyRightColumn={{
                    component: ({rowData}) =>
                    ((isPreviousYearData==true && execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==1 && selectedYear !== years[0]) || (isPreviousYearData==true && execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==2 && selectedYear !== years[0] && selectedYear !== years[1]) || isPreviousYearData==false) && rowData?.showKey == 1 ? <CopyRow setShowCopyDescription={setShowCopyDescription} copyRow={copyRow} rowData={rowData} /> : '',
                    title: '',
                  }}
                />
                </>

              ) : (
                <DataSheetGrid
                  value={data3}
                  columns={columns}
                  onChange={(newData) => {
                    if (newData.length > 2) {
                      setdata3([newData[0], newData[1]]);
                      setData([newData[0], newData[1]]);
                    } else {
                      setdata3(newData);
                      setData(newData);
                    }
                  }}
                  rowClassName={() => 'forecast'}
                  disableContextMenu={true}
                  gutterColumn={false}
                  lockRows={true}
                />
              )
          ) : (
            ''
          )}

          <Box sx={{display: 'flex', gap: '10px', justifyContent: 'flex-end'}}>
            {ForecastPreviousMonthAccess === 'Edit' && (buttonStatus==='edit' || ((currentRole.includes('Admin')) && (selectedRow.status === 'DRAFT' || selectedRow.status=='REJECTED' || selectedRow.status=='NEW'))) &&

             <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
               <AppButton disabled={isForecastUpdateTabEditable || selectedRow.executionMonthYear[1] == 3}
                 onClick={ () => {
                   if (previousMonthForecastEditButton==true) {
                     // checking the variance of each quarters
                     determiningPreviousForecast();
                   } else {
                     // we are making setForecastUpdatesaveButton(false) so that the we can make only previous month forecast edit button enable
                     dispatch(setForecastUpdatesaveButton(false));
                     setPreviousMonthForecastEditButton(true);
                   }
                 }

                 }
                 label={previousMonthForecastEditButton==true ? 'Save Historical Forecast' : 'Edit Historical Forecast'}
               />
             </Box>}
            {accessControl === 'Edit' && (buttonStatus==='edit' || ((currentRole.includes('Admin')) && (selectedRow.status === 'DRAFT' || selectedRow.status=='REJECTED' || selectedRow.status=='NEW'))) ? (
            <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px'}}>
              <AppButton disabled={previousMonthForecastEditButton == true}
                onClick={ () => {
                  if (isForecastUpdateTabEditable) {
                    // checking the variance of each quarters
                    validationCheck();
                    setSubmitted(false);
                  } else {
                    dispatch(setForecastUpdatesaveButton(true));
                    setPreviousMonthForecastEditButton(false);
                  }
                }

                }
                label={isForecastUpdateTabEditable ? 'Save' : 'Edit Forecast'}
              />
              {isForecastUpdateTabEditable && <AppButton sx={{marginLeft: '20px'}} disabled={previousMonthForecastEditButton == true}
                onClick={ () => {
                  if (isForecastUpdateTabEditable) {
                    // checking the variance of each quarters
                    validationCheck();
                    setSubmitted(true);
                  } else {
                    dispatch(setForecastUpdatesaveButton(true));
                    setPreviousMonthForecastEditButton(false);
                  }
                }

                }
                label={'Submit'}
              />}
            </Box>
          ) : (
            ''
          )
            }
          </Box>
        </div>
      )}

      {PastMonthpreviousCommentsData.length>0 && <PastMonthForecastCommentsTable data={PastMonthpreviousCommentsData} header={'Historical Forecast Update Reasons'}/>}
      {previousvarianceData.length>0 && <PreviousVarianceTable data={previousvarianceData}/>}

      <AppErrorDialog open={showErrorDialog} handleClose={() => {
        setShowErrorDialog(false); setErrorMessage(null); setShowVarianceDialog(false); setValidationArr([]); setshowPreviousMonthDifference(false); setShowSuccessDialog3(false);
      }} text={errorMessage ? errorMessage : 'Error occured when saving'}/>
      <AppDialog open={showPreviousMonthSuccess} handleClose={()=>setShowPreviousMonthSuccess(false)} text='Forecast is Saved'/>

      <AppDialog open={showSuccessDialog2} handleClose={ handleCloseSuccess} text={`Forecast is ${submitted?'Submitted':'Saved'}`}/>
      <PreviousMonthForecastPopUp isPreviousYearData={isPreviousYearData} selectedYear={selectedRow.executionMonthYear[0]} execMonthDecider={execMonthDecider} years={years} data={previousMonthDifferenceData} setData={setPreviousMonthDifferenceData} isOpen={showPreviousMonthDifference} handleClose={()=>{
        setshowPreviousMonthDifference(false);
      }} handleSubmit={handlePreviousMonthForecastDifferenceSubmit}/>
      <AppDialog3 open={showSuccessDialog3} handleClose={handleCloseSuccess} save={handleCloseSuccess2} text={previousMonthPresentMonthNotSame==true?'Are you sure you want to save the forecast?':'Forecast is the same as last month.'} text2={previousMonthPresentMonthNotSame==true?'':'Are you sure you do not want to update it?'}/>
      <AppDialog4 open={nonZeroDialogIsOpen} handleClose={handleCloseNonZeroDialog} onProceed={handleSaveData} text='Please ensure that a two-year forecast (Current Year + Next Year) is entered. Would you like to proceed with the current forecast?'/>
      <AppDialog4 open={nonZeroDialogIsOpen2} handleClose={handleCloseNonZeroDialog2} onProceed={handleSaveData2} text='Please ensure that a two-year forecast (Current Year + Next Year) is entered. Would you like to proceed with the current forecast?'/>
      {<VarianceModal isOpen={showVarianceDialog} handleClose={handleCloseVarianceDialog} data={previousvarianceData} isForcastSaved={showSuccessDialog} nonZeroDialogIsOpen={nonZeroDialogIsOpen} nonZeroDialogIsOpen2={nonZeroDialogIsOpen2} validationArr={validationArr} workFlowId={selectedRow.workFlowId} handleSubmit={handleVarianceModalSubmit} showLoader={showLoader} setShowLoader={setShowLoader}/>}
    </>
  );
};

export default ForecastTable;
