import React from 'react';
import Styles from '../../../styles/target';
import {utils, writeFileXLSX} from 'xlsx';

const DownloadRoleAccessList = ({data}) => {
  const onDownload = () => {
    const sheetData = {};
    const excelFormattedData = data.map((item) => {
      const excelCurrentIterationData = {};
      excelCurrentIterationData['Person Name'] = item?.user_name || '';
      excelCurrentIterationData['Person Email'] = item?.email || '';
      excelCurrentIterationData['GPID'] = item?.gpid || '';
      excelCurrentIterationData['Role'] = item?.user_role || '';
      excelCurrentIterationData['Sector'] = item?.sector || '';
      excelCurrentIterationData['Category'] = item?.category || '';
      excelCurrentIterationData['Active'] = item?.is_active ? 'Y': 'N' || '';
      excelCurrentIterationData['Active Start Date'] = item?.active_start_date || '';
      excelCurrentIterationData['Active End Date'] = item?.active_end_date || '';
      excelCurrentIterationData['Last Used On'] = item?.last_access_date || '';
      return excelCurrentIterationData;
    });

    sheetData['ROLE_AND_ACCESS_LIST'] = [...excelFormattedData];
    const wb = utils.book_new();
    const colWidths = [{wch: 150}];
    wb['!cols'] = colWidths;
    Object.keys(sheetData)?.forEach((data) => {
      const ws = utils.json_to_sheet(sheetData[data]);
      utils.book_append_sheet(wb, ws, data);
    });
    writeFileXLSX(wb, `ROLE_AND_ACCESS_LIST.xlsx`);
  };

  if (!(data?.length > 0)) {
    return <></>;
  }
  return <Styles.StyledDownloadButton onClick={onDownload}>Download</Styles.StyledDownloadButton>;
};

export default DownloadRoleAccessList;
