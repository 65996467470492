import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, TablePagination} from '@mui/material';
import CommonConfSingleRow from './BuyPlanReportTableSingleRow';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {useDispatch, useSelector} from 'react-redux';
import config from '../../../Utils/Config';
import customealltheme from '../../../theme';
import {setSortFilter, setSortType} from '../../../redux/features/configurations';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));


function CommonConfTable({idKey, country, allSectors, businessUnit, ActiveSectors, setPage, page, accessControl, data, setErrMsg, setIsErrorPopupVisible, setAddingTrigger, addingTrigger, heads, values, sortValues, singleUpdate, pagination, setPagination, allCommodities, allSubCommodities}) {
  const Edit = config.AccessLevel.Edit;
  const dispatch = useDispatch();
  const [editModeDisableTrigger, setEditModeDisableTrigger]=useState(false); // to disable the edit of any row

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setEditModeDisableTrigger((editModeDisableTriggerValue)=>!editModeDisableTriggerValue);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(+event.target.value);
    setPage(0);
  };

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  return (
    <>
      <>
        <Box sx={{width: '100%', padding: '0px 18px'}}>
          <Box sx={{marginTop: '20px'}}>
            <TableContainer component={Paper} id='buyplanreporttable'>
              <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead id='buyplanreporthead'>
                  <TableRow>
                    {heads.map((h, index)=>(
                      <StyledTableCell align="left" key={index} style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px'}}>
                        <Box style={customealltheme.palette.TableHead}>
                          <Box>{h}</Box>
                          {sortValues[index] === true &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0.5'}}
                          onClick={()=>{
                            dispatch(setSortFilter(values[index]));
                            if (sorttype === 'asc' ) {
                              dispatch(setSortType('desc'));
                            } if (sorttype === 'desc' || sorttype === '') {
                              dispatch(setSortType('asc'));
                            }
                          }}
                          onMouseOver={(e)=>{
                            e.target.style.opacity = '1';
                          }}
                          onMouseLeave={(e)=>{
                            e.target.style.opacity = '0.5';
                          }}
                        > <SwapVertIcon />{sorttype}</Box>}

                        </Box>

                      </StyledTableCell>
                    ))}
                    {accessControl===Edit && <StyledTableCell align="left">Action</StyledTableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.slice(page * pagination, page * pagination + pagination)
                      ?.map((row, i) => (
                        <CommonConfSingleRow row={row} key={i} setAddingTrigger={setAddingTrigger}
                          addingTrigger={addingTrigger} values={values}
                          idKey={idKey}
                          ActiveSectors={ActiveSectors}
                          singleUpdate={singleUpdate}
                          setErrMsg={setErrMsg}
                          accessControl={accessControl}
                          setIsErrorPopupVisible={setIsErrorPopupVisible}
                          editModeDisableTrigger={editModeDisableTrigger}
                          businessUnit={businessUnit}
                          page = {page}
                          country={country}
                          allSectors={allSectors}
                          allCommodities={allCommodities}
                          allSubCommodities={allSubCommodities}
                        />
                      ))}

                </TableBody>
              </Table>
            </TableContainer>

            {data.length>0 && <TablePagination
              rowsPerPageOptions={[2, 5, 10, 15]}
              component="div"
              count={data?.length || 0}
              rowsPerPage={pagination}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
          </Box>
        </Box>
      </>

    </>);
}

export default CommonConfTable;
