import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import UnitDisplayConversion from './CurrencyConversionDisplay';
import CommonConfTable from './commonTable/CurrencyConversionTable';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import config from '../../../Utils/Config';

const CurrencyConversion = (props) => {
  const [haveAccessToFXRates, setHaveAccessToFXRates]=useState(null);
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [filterResetClicked, setFiltersResetClicked] = useState(false);

  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);

  const heads = ['Base Currency', 'Plan Month', 'Standard Currency', 'Conversion Rate', 'Last Edited By', 'Last Edited On',
  ];

  const values = ['local_currency', 'conversion_month_year',
    'standard_currency',
    'conversion_multiplier', 'last_updated_by', 'last_updated_on', 'standard_currency_description', 'standard_currency_id',
  ];

  const dateValues = [false, false, false, false, false, false];

  const editValues = [true, true, false, false, true, false];

  const [pagination, setPagination] = useState(10);

  const [addingTrigger, setAddingTrigger] = useState(false);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedCurrencyConversionFilterValue);

  const updateSingleUnitMaster = ConfigurationServices.
      updateCurrencyConversionMasterData;
  const newRowUpdate = ConfigurationServices.postCurrencyConversionMasterData;

  const sortValues = [false, false, false, false, true, true];

  const [Currency, setCurrency] = useState([]);
  const [StdCurrencyId, setStdCurrencyId] = useState(-1);

  useEffect(()=>{
    ConfigurationServices.getCurrency()
        .then((res)=>{
          const Curren = [];
          Array.isArray(res) && res?.map((r)=>{
            if (r.currency === 'USD') {
              setStdCurrencyId(r.currency_id);
            }
            !Curren.includes(r) && Curren.push(r);
          });
          setCurrency([...Curren].filter((cur) => cur.is_active));
        });
  }, []);

  useEffect(()=>{
    if (haveAccessToFXRates===true) {
      ConfigurationServices.getCurrencyConversionMasterData()
          .then((res)=>{
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                // console.log(' response    ..... ', res);
                setRow(res);
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          });
    }
  }, [addingTrigger, haveAccessToFXRates]);

  useEffect(() => {
    if (
      filteredUnitValue === '' ||
      filteredUnitValue.length ===0 ||
      filteredUnitValue[0] === 'unitDes_all' ||
      filteredUnitValue[0] === 'unit_all' ||
      filteredUnitValue[0] === 'all_month'
    ) {
      filterTableByPlanMonth(row);
      return;
    }


    setTimeout(() => {
      let tempTableData = row;

      if (filteredUnitValue['selectedMonth'].length>0) {
        tempTableData = tempTableData.filter((r) =>
          filteredUnitValue['selectedMonth'].includes(r.conversion_month_year),
        );
      }

      if (filteredUnitValue['localUnitarray'].length > 0) {
        tempTableData = tempTableData.filter((r) =>
          filteredUnitValue['localUnitarray'].includes(r.local_currency),
        );
      }

      filterTableByPlanMonth(tempTableData);
      setPage(0);
    }, 1000);
  }, [filteredUnitValue, row]);

  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['FX_Rates']) {
        setAccessControl(functionalityAccess['FX_Rates']);
        setHaveAccessToFXRates(true);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const Edit = config.AccessLevel.Edit;

  const Read = config.AccessLevel.Read;

  const filterTableByPlanMonth = (tableData) => {
    let updatedTableData = [];
    if (tableData.length) {
      const monthArr = '';
      updatedTableData = [...tableData];
      if (monthArr && monthArr.length > 0) {
        updatedTableData = tableData.filter((d) => monthArr.includes(d.conversion_month_year));
      }
    }
    setTableFilterData(updatedTableData);
  };

  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='last_updated_on') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.toLowerCase()?.trim()?.localeCompare(b[SortValue]?.toLowerCase()?.trim()));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.toLowerCase()?.trim()?.localeCompare(a[SortValue]?.toLowerCase()?.trim()));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);
  const [page, setPage] = React.useState(0);
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {haveAccessToFXRates ===true && (accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>

        <UnitDisplayConversion row={row} setSubmitClicked={setSubmitClicked} setFiltersResetClicked={setFiltersResetClicked} setPage={setPage}/>


        <Box sx={{}}>
          <>
            <CommonConfTable
              data={tableFilterData}
              setAddingTrigger={setAddingTrigger}
              heads={heads}
              values={values}
              editValues={editValues}
              addingTrigger={addingTrigger}
              dateValues={dateValues}
              idKey={idKey}
              singleUpdate={updateSingleUnitMaster}
              newRowUpdate={newRowUpdate}
              setIsErrorPopupVisible={setIsErrorPopupVisible}
              setErrMsg={setErrMsg}
              pagination={pagination}
              setPagination={setPagination}
              accessControl={accessControl}
              setPage={setPage}
              page={page}
              StdCurrencyId = {StdCurrencyId}
              sortValues={sortValues}
              filterResetClicked={filterResetClicked}
              submitClicked={submitClicked}
              Currency={Currency}
            />
          </>
          {(errMsg !=='' || tableFilterData.length == 0) &&
              <ErrorMessage message='FX Rates' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
      {haveAccessToFXRates===false &&
      <Box sx={{mt: 6, p: 1, fontSize: '2em', color: '#3B4655'}}>

        <Typography variant="h4" component="h4">
          {'Oops! You don\'t have access to this page.'}
        </Typography>

      </Box>
      }
    </Box>
  );
};

export default CurrencyConversion;
