import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
const DropdownSelectCountry = ({sectorCountryMap, rowData, indexOfYear, apiData, i}) => {
  const [countrySelected, setCountrySelected] = React.useState('');
  const countrySelectedTrigger = useSelector((state)=>state.btnStatus.countryDropdownValue);

  // const [countryDropdown, setCountryDropDown] = React.useState([]);
  const [prevSectorId, setPrevSectorId] = React.useState(null);
  let countryDropdown = [];

  function changeDataValue(value) {
    apiData[indexOfYear].map((element, index) => {
      if (index==i) {
        element.region_id = value;
        rowData.region_id = value;
      }
    });
    setCountrySelected(value);
  }
  const handleChange = (event) => {
    if (event.target.value === 'No Data Found') {
      setCountrySelected('');
      return;
    }
    changeDataValue(event.target.value);
  };

  if (rowData.sector_id) {
    countryDropdown = sectorCountryMap.filter((sector) => sector.id === rowData.sector_id)[0]?.regionIdList || [];
    if (prevSectorId === null || prevSectorId !== rowData.sector_id) {
      setPrevSectorId(rowData.sector_id);
    }
  }

  useEffect(() => {
    setCountrySelected(rowData.region_id || '');
    apiData[indexOfYear].map((element, index) => {
      if (index==i) {
        element.region_id = rowData.region_id;
      }
    });
  }, [apiData, i, indexOfYear, rowData, countrySelectedTrigger]);
  return (
    <div>
      <FormControl variant='standard' sx={{width: '78px', fontSize: '12px'}}>
        <Select
          labelId='demo-simple-select-standard-label'
          id='demo-simple-select-standard'
          value={countrySelected || ''}
          disabled={rowData?.disableCell}
          placeholder={'Select'}
          onChange={handleChange}
          sx={{width: '78px', fontSize: '12px'}}
        >
          {
            (countryDropdown.length > 0 ? [...countryDropdown] : ['No Data Found']).map((country, index) => {
              return <MenuItem sx={{fontSize: '13px'}} key={country.id} value={country.id || index} >{country.region}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownSelectCountry;
