// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rolesTaggingPopup{
    background: #fff;
    width: 450px;
    max-height: 150px;
    position: absolute;
    z-index: 2000 !important;
    overflow-y: scroll;
    box-shadow: rgba(34, 34, 48, 0.05) 0px 4px 16px, rgba(34, 34, 48, 0.05) 0px 8px 32px;
}
.rolesTaggingHeader{
  padding: 5px;
  text-align: left;
  background: #215094;
  color: white;
  position: sticky;
  top: 0;
}
.rolesTaggingPopup::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    opacity: 0; /* Make scrollbar transparent */
  }
  
  .rolesTaggingPopup::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */
    border-radius: 6px; /* Round the edges of the thumb */
  }
  
  .rolesTaggingPopup::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3); /* Color of the scrollbar thumb on hover */
  }

.rolesTaggingPopup .userDetails{
    background: #F8F8F8 !important;
    width: 100%;
    /* color: white; */
    padding: 5px;
    cursor: pointer;
    transition: all ease 0.1s;

}
.rolesTaggingPopup .userDetails:hover{
    background: #EEEEEE !important;
    border-left: 3px solid #215094;
}

.rolesTaggingPopup .userDetails .username{
   font-size: 15px;
}
.rolesTaggingPopup .userDetails .email{
    font-size: 12px;
 }
`, "",{"version":3,"sources":["webpack://./src/Components/common/CommentsTagging/RolesTagging.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,wBAAwB;IACxB,kBAAkB;IAClB,oFAAoF;AACxF;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,MAAM;AACR;AACA;IACI,UAAU,EAAE,2BAA2B;IACvC,UAAU,EAAE,+BAA+B;EAC7C;;EAEA;IACE,oCAAoC,EAAE,iCAAiC;IACvE,kBAAkB,EAAE,iCAAiC;EACvD;;EAEA;IACE,oCAAoC,EAAE,0CAA0C;EAClF;;AAEF;IACI,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,yBAAyB;;AAE7B;AACA;IACI,8BAA8B;IAC9B,8BAA8B;AAClC;;AAEA;GACG,eAAe;AAClB;AACA;IACI,eAAe;CAClB","sourcesContent":[".rolesTaggingPopup{\n    background: #fff;\n    width: 450px;\n    max-height: 150px;\n    position: absolute;\n    z-index: 2000 !important;\n    overflow-y: scroll;\n    box-shadow: rgba(34, 34, 48, 0.05) 0px 4px 16px, rgba(34, 34, 48, 0.05) 0px 8px 32px;\n}\n.rolesTaggingHeader{\n  padding: 5px;\n  text-align: left;\n  background: #215094;\n  color: white;\n  position: sticky;\n  top: 0;\n}\n.rolesTaggingPopup::-webkit-scrollbar {\n    width: 8px; /* Width of the scrollbar */\n    opacity: 0; /* Make scrollbar transparent */\n  }\n  \n  .rolesTaggingPopup::-webkit-scrollbar-thumb {\n    background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */\n    border-radius: 6px; /* Round the edges of the thumb */\n  }\n  \n  .rolesTaggingPopup::-webkit-scrollbar-thumb:hover {\n    background-color: rgba(0, 0, 0, 0.3); /* Color of the scrollbar thumb on hover */\n  }\n\n.rolesTaggingPopup .userDetails{\n    background: #F8F8F8 !important;\n    width: 100%;\n    /* color: white; */\n    padding: 5px;\n    cursor: pointer;\n    transition: all ease 0.1s;\n\n}\n.rolesTaggingPopup .userDetails:hover{\n    background: #EEEEEE !important;\n    border-left: 3px solid #215094;\n}\n\n.rolesTaggingPopup .userDetails .username{\n   font-size: 15px;\n}\n.rolesTaggingPopup .userDetails .email{\n    font-size: 12px;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
