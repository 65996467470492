/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import Styles from '../../styles/Forecast';
import {AppButton, AppNotSelectedButton} from '../common/AppButton/AppButton';
import {CircularProgress} from '@mui/material';
import {useEffect} from 'react';
import {useState} from 'react';
const CarryValuePopUp = ({isOpen, sCarryValueReason, setsCarryValueReason, handleClose, handleSubmit, errorHappen, seterrorHappen}) => {
  return (
    errorHappen? <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={isOpen}
      >
        <DialogContent>

          <Box sx={{mt: 2}}>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <Box sx={{}}>
                <FormControl sx={{mt: 2, minWidth: 160, flexDirection: 'column', display: 'flex', width: '500px', gap: '5px'}}>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                  Carry Volume grand total is not within permissible range, it should be between -1 to 1
                  </Box>

                </FormControl>
              </Box>
            </Box>
          </Box>


          <DialogActions sx={{mb: 2, mt: 2}}>
            <AppNotSelectedButton
              onClick={async () => {
                handleClose();
              }}
              label="Close"
            />

          </DialogActions>
        </DialogContent>

      </Dialog>
    </React.Fragment> :
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={isOpen}
      >
        <DialogContent>

          <Box sx={{mt: 2}}>
            <DialogContentText sx={{'font-family': 'Segoe UI', 'font-size': '13px', 'color': '#3B4655'}}>
            Please enter valid reason for Carry volume not adding up to 0
            </DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <Box sx={{}}>
                <FormControl sx={{mt: 2, minWidth: 160, flexDirection: 'column', display: 'flex', width: '500px', gap: '5px'}}>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                    <Styles.StyledVarianceCommmentText
                      style={{width: 350}}
                      minRows={3}
                      onChange={(e) => setsCarryValueReason(e.target.value)}
                      value={sCarryValueReason}
                    />
                  </Box>

                </FormControl>
              </Box>
            </Box>
          </Box>


          <DialogActions sx={{mb: 2, mt: 2}}>
            <AppNotSelectedButton
              onClick={async () => {
                handleClose();
              }}
              label="Close"
            />
            <AppButton
              disabled={sCarryValueReason==''}
              onClick={async (e) => {
                e.target.disabled=true;
                handleSubmit();
              }}
              label="Save"
            />

          </DialogActions>
        </DialogContent>

      </Dialog>
    </React.Fragment>

  );
};

export default CarryValuePopUp;
