/* eslint-disable max-len */

/* This Page is the  common table page which has features like adding and editing the rows and used by different
admin pages.
*/


import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {createTheme, styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Button, FormControl, InputBase, MenuItem, Select, TablePagination, TextareaAutosize} from '@mui/material';
import CommonConfSingleRow from './CommonConfSingleRow';
import theme from '../../../../theme';

import SwapVertIcon from '@mui/icons-material/SwapVert';
import {useDispatch, useSelector} from 'react-redux';
import {setSortFilter, setSortType} from '../../../../redux/features/configurations';
import config from '../../../../Utils/Config';
import customealltheme from '../../../../theme';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});


const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function CommonConfTable({idKey, data, setPage, page, setErrMsg, sortValues, accessControl, setIsErrorPopupVisible, setAddingTrigger, addingTrigger, heads, values, editValues, dateValues, singleUpdate, newRowUpdate, pagination, setPagination}) {
  const [AddNewRow, setNewRow] = useState(false);
  const [isActiveValue, setIsActiveValue] = React.useState('');
  const [currencyDes, setCurrencyDes]= useState('');
  const [currencyName, setCurrencyName]= useState('');
  const handleAddRow = () =>{
    setNewRow(true);
  };

  // This editeddetails used for storing the adding details

  const [editedDetails, setEditDetails] = useState({});


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(+event.target.value);
    setPage(0);
  };

  // ADDING ALL VALUES AS A KEYS AND GIVING EMPTY VALUES FOR ADDING NEW ROW
  useEffect(()=>{
    const keyvalues = {};
    values.map((h, index)=>{
      keyvalues[h] = '';
    });
    setEditDetails({...keyvalues});
  }, [values]);


  function toCamelCase(arr) {
    let string = arr[0];
    for (let i=1; i<arr.length; i++) {
      string = string+ arr[i].charAt(0).toUpperCase()+arr[i].
          slice(1, arr[i].length);
    }
    return string;
  }

  const saveTheRow = () =>{
    // Call the adding api from here
    setNewRow(false);
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);
    Object.keys(editedDetails).map((obj)=>{
      if (editedDetails[toCamelCase(obj.split('_'))] !== editedDetails[`${obj}`]) {
        editedDetails[toCamelCase(obj.split('_'))] = editedDetails[`${obj}`];
        delete editedDetails[`${obj}`];
      }
    });
    newRowUpdate({...editedDetails, active: isActiveValue !=='' ? isActiveValue: row.is_active, isActive: isActiveValue !=='' ? isActiveValue : row.is_active}).then((res)=>{
      if (res.userMessage) {
        // console.log('object1');
        setIsErrorPopupVisible(true);
        setErrMsg(res.userMessage);
        setAddingTrigger(!addingTrigger);
      }
    }).catch((err)=>{
      setIsErrorPopupVisible(true);
      setErrMsg('Error has been occured');
      setAddingTrigger(!addingTrigger);
    });
    // console.log('object3');
    setEditDetails({});
  };

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setSortType(''));
  }, [dispatch]);
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const Edit = config.AccessLevel.Edit;

  return (<>
    <>
      <Box sx={{width: '100%', padding: '0px 18px'}}>
        <Box sx={{marginTop: '20px'}}>

          {accessControl===Edit && <Box> <Button
            onClick={handleAddRow}
            sx={theme.palette.AddNewTableButton}
          >
            <AddIcon />  Add new row
          </Button></Box>}
          <TableContainer component={Paper} id='currencytable'>
            <Table sx={{minWidth: 700}} aria-label="customized table">
              <TableHead id='currencyhead'>
                <TableRow>
                  {heads.map((h, index)=>(
                    <StyledTableCell align="left" key={index}>
                      <Box style={customealltheme.palette.TableHead}>
                        <Box>{h}</Box>
                        {sortValues[index] === true &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0.5'}}
                          onClick={(e)=>{
                            dispatch(setSortFilter(values[index]));
                            if (sorttype === 'asc' ) {
                              dispatch(setSortType('desc'));
                            } if (sorttype === 'desc' || sorttype === '') {
                              dispatch(setSortType('asc'));
                            }
                          }}
                          onMouseOver={(e)=>{
                            e.target.style.opacity = '1';
                          }}
                          onMouseLeave={(e)=>{
                            e.target.style.opacity = '0.5';
                          }}
                        > <SwapVertIcon />{sorttype}</Box>}

                      </Box>

                    </StyledTableCell>
                  ))}
                  {accessControl===Edit && <StyledTableCell align="left">Action</StyledTableCell>}                </TableRow>
              </TableHead>
              <TableBody>
                {AddNewRow === true &&
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row" >
                        <TextareaAutosize style={theme.palette.TextArea}
                          placeholder="Type in here…"
                          defaultValue={editedDetails[`${'currency_description'}`]}
                          minRows={1}
                          maxRows={4}
                          onChange={(e)=>{
                            setEditDetails({...editedDetails,
                              [`${'currency_description'}`]: e.target.value});
                            setCurrencyDes(e.target.value);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" >
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={isActiveValue}
                            onChange={(e)=>{
                              setIsActiveValue(e.target.value);
                              setEditDetails({...editedDetails,
                                isActive: e.target.value});
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>

                            { ['true', 'false']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj === 'true' ? 'Y' : 'N'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <TextareaAutosize style={theme.palette.TextArea}
                          placeholder="Type in here…"
                          defaultValue={editedDetails[`${'currency'}`]}
                          minRows={1}
                          maxRows={4}
                          onChange={(e)=>{
                            setEditDetails({...editedDetails,
                              [`${'currency'}`]: e.target.value});
                            setCurrencyName(e.target.value);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>

                      <StyledTableCell align="left">

                        <Box sx={{display: 'flex', gap: '5px'}}>
                          {currencyDes !== '' && currencyName!=='' && isActiveValue!=='' ?<Box sx={{border: '1px solid gray',
                            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
                            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
                            cursor: 'pointer', background: '#4297d3', color: 'white', justifyContent: 'center'}} onClick={saveTheRow}>
                            <Box>Save</Box>
                          </Box> : <Box sx={{border: '1px solid gray',
                            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
                            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
                            cursor: 'not-allowed', background: '#4297d3', color: 'white', justifyContent: 'center'}} >
                            <Box>Save</Box>
                          </Box>}
                          <Box sx={{border: '1px solid gray',
                            display: 'flex', border: '0.5px solid #CECECE', borderRadius: '4px',
                            width: '58px', alignItems: 'center', gap: '5px', padding: '2px 6px',
                            cursor: 'pointer'}} onClick={(e)=>{
                            setNewRow(false);
                            setEditDetails({});
                          }}>
                            <Box>Delete</Box>
                          </Box>
                        </Box>


                      </StyledTableCell>
                    </StyledTableRow>
                }
                {data?.slice(page * pagination, page * pagination + pagination)
                    ?.map((row, key) => (
                      <CommonConfSingleRow row={row} key={key} setAddingTrigger={setAddingTrigger}
                        addingTrigger={addingTrigger} values={values}
                        editValues={editValues} dateValues={dateValues} idKey={idKey}
                        singleUpdate={singleUpdate}
                        setErrMsg={setErrMsg}
                        accessControl={accessControl}
                        setIsErrorPopupVisible={setIsErrorPopupVisible}
                        page = {page}
                      />
                    ))}

                {/* Add a New row ehich takes values length and display needed textareas */}
              </TableBody>
            </Table>
          </TableContainer>
          {data.length>0 &&
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 15]}
              component="div"
              count={data?.length || 0}
              rowsPerPage={pagination}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Box>
      </Box>
    </>

  </>);
}

export default CommonConfTable;
