import {configureStore} from '@reduxjs/toolkit';
import apiReducer from '../redux/features/apiCall';
import authorizationReducer from '../redux/Auth';
import commonReducer from '../redux/Common';
import targetApiReducer from './target/index';
import roleBasedAccessContolReducer from '../redux/RoleBasedAccess';
import buttonStatusSlice from '../redux/features/buttonStatus';
import configureCallSlice from './features/configurations';
import buyPlanDataSliceReducer from './BuyPlan';
import editStatusSliceReducer from './EditStatus';
import isRecallStatusSliceReducer from './IsRecallStatus';
import {navigationDataReducer} from './buyplanTab/NavigationDataSlice';


const store = configureStore({
  reducer: {
    api: apiReducer,
    targetApi: targetApiReducer,
    btnStatus: buttonStatusSlice,
    configurations: configureCallSlice,
    roleBasedAccess: roleBasedAccessContolReducer,
    authorizationState: authorizationReducer,
    commonState: commonReducer,
    buyPlanData: buyPlanDataSliceReducer,
    editStatus: editStatusSliceReducer,
    isRecallStatus: isRecallStatusSliceReducer,
    navData: navigationDataReducer,
  },
});

export default store;
