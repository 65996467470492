/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {titleCase, months} from '../../../../services/utils';
import {RxCrossCircled} from 'react-icons/rx';
import {Dialog, DialogContent, StepConnector} from '@mui/material';
import DashboardService from '../../../../services/dashboardService';

function PopupStepper({open, handleClose, wfId, selectedRow}) {
  const [popupStepperAPI_Data, setPopupStepperAPI_Data] = useState([]);

  useEffect(() => {
    if (open && wfId) {
      getStatusSteps();
    }
  }, [wfId, open]);

  const getStatusSteps = () => {
    if (wfId) {
      DashboardService.getStatusSteps(wfId).then((res) => setPopupStepperAPI_Data(res || []));
    }
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={'xl'} open={open} onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
          setPopupStepperAPI_Data([]);
        };
      }}>
        <DialogContent style={{height: 'auto', position: 'relative', paddingBottom: '2rem'}}>
          <Box style={{display: 'flex', justifyContent: 'space-between', position: 'sticky', left: '0'}}>
            <h3 style={{color: '#6D7888'}}>Track Buy Plans -{selectedRow?.commodity}-{selectedRow?.subCommodity}-{selectedRow?.sector}-{selectedRow?.region}-{selectedRow?.executionMonthYear && `${(titleCase(months[selectedRow?.executionMonthYear[1]-1]))} ${selectedRow?.executionMonthYear[0]}`}</h3>
            <RxCrossCircled onClick={()=> {
              handleClose();
              setPopupStepperAPI_Data([]);
            }} style={{fontSize: '1.5rem', color: '#6D7888', cursor: 'pointer'}}/>
          </Box>
          <Box sx={{width: '100%', paddingTop: '3rem'}}>
            <Stepper alternativeLabel connector={<StepConnector sx={{
              'left': 'calc(-52% + 1.22rem)',
              'right': 'calc(50% + 0.75rem)',
              '& .MuiStepConnector-line': {borderColor: '#0071DC', borderTopWidth: '0.2rem'},
            }}/>}>
              {popupStepperAPI_Data?.map((label, index) => (
                <Step key={index} completed={label.COMPLETED} active={1==1} sx={{
                  '& .MuiStepIcon-root': {color: '#757e8d'},
                  '& .MuiStepIcon-root.Mui-active': {color: '#757e8d'},
                  '& .MuiStepIcon-root.Mui-completed': {color: '#0071DC'},
                }}>
                  <StepLabel icon=' ' sx={{
                    '& .MuiStepLabel-label': {fontFamily: 'Segoe UI', fontSize: '0.8rem'},
                    '& .MuiStepLabel-label.Mui-completed': {color: '#707070', fontWeight: 500},
                    '& .MuiStepLabel-label.Mui-active': {color: '#707070', fontWeight: 500},
                  }}>{label.STATUS}{(label.CHANGED_ON)? ' on: '+label.CHANGED_ON:''}<br/>
                    <font size='0.4rem'>{(label.CHANGED_BY)?label.STATUS+' by: '+label.CHANGED_BY:''}</font></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default PopupStepper;
