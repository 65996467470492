// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs {
    display: flex;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: white;
    border: none;
    border-bottom: 1.5px solid #77b955 !important;
} 
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
    color: #6f7781;
    background-color: white;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Home/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,2CAA2C;IAC3C,uBAAuB;IACvB,YAAY;IACZ,6CAA6C;AACjD;AACA;IACI,cAAc;IACd,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".nav-tabs {\n    display: flex;\n}\n\n.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {\n    color: var(--bs-nav-tabs-link-active-color);\n    background-color: white;\n    border: none;\n    border-bottom: 1.5px solid #77b955 !important;\n} \n.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {\n    color: #6f7781;\n    background-color: white;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
