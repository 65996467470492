/* This Page is the  Currency conversion page which has feautures like adding Currencys, editing existing Currencys and filtering the Currencys
based on the Currency.
*/

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../../services/ConfigurationServices';
import theme from '../../../../theme';
import ErrorMessage from '../../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../../common/App-Dialog/AppDialog';
import BusinessUnitDisplay from './BusinessUnitDisplay';
import BusinessTable from './CommonBusinessUnit/BusinessTable';
import config from '../../../../Utils/Config';

const BusinessUnit = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [activeSectorsr, setactiveSectorsr] = useState([]);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const [idKey, setIdKey] = useState([]);

  const heads = ['Business Unit', 'Is Active', 'Last Edited By', 'Last Edited On'];

  const values = ['business_unit', 'is_active', 'updated_by',
    'updated_on'];
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Sector_Master']) {
        setAccessControl(functionalityAccess['Configuration:Sector_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const dateValues = [false, false, false, false, false, false];

  const editValues = [true, false, true, false, true, false];


  const [pagination, setPagination] = useState(10);
  const [addingTrigger, setAddingTrigger] = useState(false);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedCommodityFilterValue);

  const sortValues = [true, false, true, true];
  const updateSingleUnitMaster = ConfigurationServices.
      updateBusinessUnitdata;
  const newRowUpdate = ConfigurationServices.postBusinessUnitdata;


  useEffect(()=>{
    ConfigurationServices.getBusinessUnitdata()
        .then((res)=>{
          setTimeout(()=>{
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                setRow(res.commodityGroups);
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          }, 100);
        });
  }, [addingTrigger]);

  const sortFn = (SortValue) => {
    if (SortValue!=='') {
      if (sorttype === 'asc') {
        // sorting in descending
        tableFilterData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      } else if (sorttype === 'desc') {
        // sorting in ascending
        tableFilterData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    }
  };

  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  // const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='updated_on') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.updated_on));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.updated_on));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='category_all' ||
     filteredUnitValue[0]==='commodity_all' ) {
      row.sort((a, b) => {
        const boolComparison = Number(b[values[1]]) - Number(a[values[1]]);
        if (boolComparison !== 0) {
          return boolComparison;
        } else {
          return new Date(b[values[3]]) - new Date(a[values[3]]);
        }
      });
      setTableFilterData([...row]);
    } else {
      setTimeout(()=>{
        if (filteredUnitValue['category'].length>0 &&
        filteredUnitValue['commodity'].length>0) {
          const original =[...row];
          const dummy = [];
          original.map((r, i)=>{
            /* eslint-disable */
            filteredUnitValue['category'].includes(r.region) ?
            /* eslint-disable */
            r.businessUnits.map((rc, j)=>{
              filteredUnitValue['commodity'].includes(rc.businessUnit) ? dummy.push({id: r.id, region: r.region, businessUnits: [{id: rc.id, businessUnit: rc.businessUnit, active: rc.active}]}) : '';
            }) : '';
          });
          setTableFilterData([...dummy]);
        } else
        if (filteredUnitValue['category'].length>0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['category'].includes(r.region);
          }));
        } else if (filteredUnitValue['commodity'].length > 0) {
          if(false){
          const original =[...row];
          const dummy = [];
          /* eslint-disable */
          original.map((r, i)=>{
            r.businessUnits.map((rc, j)=>{
               filteredUnitValue['commodity'].includes(rc.businessUnit) ? dummy.push({id: r.id, region: r.region, businessUnits: [{id: rc.id, businessUnit: rc.businessUnit, active: rc.active}]}) : '';
            });
          });
          setTableFilterData([...dummy]);
          }
          else{
            let filteredBusinessUnitData = row.filter((businessUnit,index)=>{
              if(filteredUnitValue['commodity'].includes(businessUnit.bu_id)){
                return businessUnit;
              }
            })
            setTableFilterData(filteredBusinessUnitData);
          }
        } else if (filteredUnitValue['category'].length==0 &&
        filteredUnitValue['commodity'].length==0) {
          setTableFilterData([...row]);
        }
        setPage(0);
      }, 100);
    }
  }, [filteredUnitValue, row, addingTrigger]);


  useEffect(()=>{
    ConfigurationServices.getCountrysData()
        .then((res)=>{
          const activeSectors = [];
          res['commodityGroups'].map((r)=>{
            r.is_active === true && activeSectors.push(r);
          });
          setactiveSectorsr([...activeSectors]);
        });
  }, []);
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [page, setPage] = React.useState(0);
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>

        <BusinessUnitDisplay row={row}  activeSectorsr={activeSectorsr} setPage={setPage}/>
        <Box sx={{}}>
          <BusinessTable
            data={tableFilterData}
            accessControl={accessControl}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            activeSectorsr={activeSectorsr}
            setPage={setPage}
            sortValues={sortValues}
            sortFn={sortFn}
            sorttype={sorttype}
            page={page}
          />

          {(errMsg !=='' || tableFilterData.length == 0) &&
        <ErrorMessage message='Business Unit' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
    </Box>
  );
};

export default BusinessUnit;
