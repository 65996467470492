/* eslint-disable no-unused-vars */
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux'; //
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import './MainComponent.css';
import SummaryMetrics from './SummaryMetrics/SummaryMetrics';
import TableComponent from './TableComponent/TableComponent';

function MainComponent() {
  const [haveAccessToHomePage, setHaveAccessToHomePage]=useState(null);
  const [noFunctionalityAccess, setNoFunctionalityAccess]=useState(false);
  // const [currentRole, setCurrentRole] = useState(null);
  const history = useHistory();
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const functionalityAccessFetchDone=useSelector((state)=>state?.roleBasedAccess?.functionalityAccessFetchDone);
  const isError=useSelector((state)=>state?.roleBasedAccess?.error);
  const errorMsg=useSelector((state)=>state?.roleBasedAccess?.errorMsg);
  const noAccessRoles=useSelector((state)=>state?.roleBasedAccess?.noAccessRoles);


  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);

  useEffect(()=>{
    if (currentRoleId && availableRoles && availableRoles.length>0) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          if (role.description==='Guidance Price Owner') {
            history.push('/guidanceprice');
          } else if ( role.description==='FX Rates Owner') {
            history.push('/fxrates');
          } else if ( role.description==='Conversion Rates Owner') {
            history.push('/fxrates');
          }
          // setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId, availableRoles]);


  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      const functionalityAccessKeys = Object.keys(functionalityAccess);
      if (functionalityAccessKeys.some((functionalityName)=>~functionalityName.indexOf('Home_Page'))) {
        setHaveAccessToHomePage(true);
      } else {
        setHaveAccessToHomePage(false);
      }
    }
    if (functionalityAccessFetchDone && Object.keys(functionalityAccess).length===0) { // means functionality access is fatched but no functionality
      setNoFunctionalityAccess(true);
    }
  }, [functionalityAccess, functionalityAccessFetchDone]);


  return (
    <>


      <Box
        sx={{
          bgcolor: '#eff6fc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '0px',
        }}
      >
        {haveAccessToHomePage===true ?
        <>
          <SummaryMetrics />
          <TableComponent />
        </>:
        <Box sx={{mt: 20, p: 3, fontSize: '2em', color: '#3B4655'}}>
          {haveAccessToHomePage===false || noFunctionalityAccess===true || noAccessRoles === true || isError === true ?
            <Typography variant="h4" component="h4">
              { errorMsg && errorMsg!='' ? errorMsg : 'Oops! You don\'t have access to this page.'}
            </Typography> :
          <CircularProgress sx={{m: 4}}/>
          }
        </Box>
        }
      </Box>
    </>
  );
}

export default MainComponent;
