/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import {getNonPlayBookData_API, setSelectedNonPlayBookDropDownData} from '../../redux/features/apiCall';
import NonPlayBookFilters from './NonPlayBookFilters';
import HistoricalNonPlayBook from './HistoricalNonPlayBook';
import ActiveNonPlayBook from './ActiveNonPlayBook';
const sortStatusMapping = [
  {
    'sort_value': 'non_playbook_name',
    'sort': false,
    'sort_active': true,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'sector',
    'sort': true,
    'sort_active': true,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'region',
    'sort': true,
    'sort_active': true,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'category',
    'sort': true,
    'sort_active': true,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'commodity',
    'sort': true,
    'sort_active': true,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'sub_commodity',
    'sort': true,
    'sort_active': true,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'execution_month',
    'sort': true,
    'sort_active': true,
    'sort_order': 'asc',
  },
  // {
  //   'sort_value': 'varianceCount',
  //   'sort': true,
  //   'sort_active': false,
  //   'sort_order': 'asc',
  // },
  {
    'sort_value': 'status',
    'sort': false,
    'sort_active': false,
    'sort_order': 'asc',
  },
  {
    'sort_value': 'action',
    'sort': false,
    'sort_active': false,
    'sort_order': 'asc',
  },
];

const NO_OF_FILTERS = 8; // No Of Filters in Home screen

export default function NonPlayBook() {
  const [isHistorySelected, setIsHistorySelected] = useState(false);
  const [planListData, setPlanListData] = useState([]);
  const [count, setCount] = useState({active: 0, history: 0});
  const dispatch = useDispatch();


  // const values = ['sector', 'region', 'commodityGrp', 'commodity', 'subCommodity', 'execution_month', 'varianceCount', 'status', 'action'];
  const [sortMap, setSortMap] = useState(sortStatusMapping);
  const sortValues = [true, true, true, true, true, true, true, true, false];


  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const apiValueNonPlayBookData = useSelector((state)=>state?.api?.apiValueNonPlayBookData);
  const planStatusChange = useSelector((state)=>state?.api?.PlanStatusChange);
  const selectedDropDownData = useSelector((state)=>state?.api?.selectedNonPlayBookDropDownData);
  const reloadDataAfterHistoricalCoverageSave = useSelector((state)=>state.configurations.reloaddata);

  function mapTableData(data) {
    const seggregatedData = {};
    data.forEach((buyplan) => {
      const workflow_id = buyplan.workflow_id;
      const prevData = seggregatedData[workflow_id] ? seggregatedData[workflow_id] : [];
      seggregatedData[workflow_id] = [...prevData, buyplan];
    });
    return seggregatedData;
  }

  function getBuyplanListCount(activeData, historyData) {
    const activePlansIds = [];
    const historyPlansIds = [];
    const closedHistoryPlansIds = [];
    activeData.forEach((data) => {
      if (!activePlansIds.includes(data.workflow_id)) {
        activePlansIds.push(data.workflow_id);
      }
    });
    historyData.forEach((data) => {
      if (!historyPlansIds.includes(data.workflow_id)) {
        historyPlansIds.push(data.workflow_id);
      }
    });
    historyData.forEach((data) => {
      if (!closedHistoryPlansIds.includes(data.workflow_id) && data.status!= 'CLOSED') {
        closedHistoryPlansIds.push(data.workflow_id);
      }
    });
    return {active: activePlansIds.length, history: historyPlansIds.length, notclosed: closedHistoryPlansIds.length};
  }
  function updateListData(activeData, historyData, isHistorySelected = false) {
    const historyDataSortPlan = _.sortBy(historyData, (item) => item.workflow_id);
    const activeDataSortPlan = _.sortBy(activeData, (item) => item.workflow_id);
    const data = isHistorySelected ? sortPlanMonth(historyDataSortPlan, 'asc') : sortPlanMonth(activeDataSortPlan, 'asc');
    const temp = _.cloneDeep(data);
    const buyplanListTotal = getBuyplanListCount(activeData, historyData);
    const modifiedData = [];
    const groupedIds = [];
    const unGroupedData = [];
    let prevworkflow_id = null;
    const addDataToTable = isHistorySelected ? mapTableData(historyData) : mapTableData(activeData);
    temp.forEach((data) => {
      if (addDataToTable[data.workflow_id]?.length > 1 && !groupedIds.includes(data.workflow_id)) {
        groupedIds.push(data.workflow_id);
      }
    });
    temp.forEach((data) => {
      if (!(groupedIds.includes(data.workflow_id))) {
        unGroupedData.push(data);
      }
    });
    groupedIds.forEach((id) => {
      unGroupedData.push(...addDataToTable[id]);
    });
    unGroupedData.map((data) => {
      if (prevworkflow_id !== data.workflow_id) {
        prevworkflow_id = data.workflow_id;
        modifiedData.push({...data, showValue: true});
      } else {
        modifiedData.push({...data, showValue: false});
      }
    });
    const sortedPlanMonthData = sortPlanMonth([...modifiedData].sort((a, b) => parseInt(a.execution_month[0]) - parseInt(b.execution_month[0])), 'asc');
    setCount(buyplanListTotal);
    setPlanListData(sortedPlanMonthData);
  }

  useEffect(() => {
    setTimeout(()=>{
      dispatch(getNonPlayBookData_API());
    }, 200);
  }, [dispatch, planStatusChange, reloadDataAfterHistoricalCoverageSave]);

  useEffect(() => {
    dispatch(setSelectedNonPlayBookDropDownData(''));
  }, []);

  function seggregatedBuyplansForFilters(data, filteredworkflow_ids) {
    const seggregatedData = [];
    data.map((buyplan) => {
      if (filteredworkflow_ids.includes(buyplan.workflow_id)) {
        seggregatedData.push(buyplan);
      }
    });
    return seggregatedData;
  }
  const [page, setPage] = React.useState(0);

  useEffect(()=>{
    // console.log(apiValueNonPlayBookData);
    setTimeout(()=>{
      if (Object.values(selectedDropDownData).some((el)=>el.length > 0)) {
        const modifiedArr = [];
        const selectedworkflow_ids = [];
        apiValueNonPlayBookData?.map( (buyplan) => {
          let checkedObjArr = 0;
          Object.entries(selectedDropDownData).map(([keyName, valueArr]) => {
            if (valueArr.length === 0) {
              checkedObjArr += 1;
            } else if (keyName === 'execution_month') {
              if (valueArr.includes('month_all')) {
                valueArr = valueArr.filter((item) => item !== 'month_all');
              }
              const planMonthArr = valueArr.map( (value) => JSON.stringify(value.key));
              if (planMonthArr.includes(JSON.stringify(buyplan[keyName]))) {
                checkedObjArr += 1;
              }
            } else if (keyName === 'status') {
              const buyplanStatus = (buyplan.status == 'NEW' || buyplan.status == 'NEW_COV_PEND') ? 'NOT STARTED' : buyplan.status;
              if (valueArr.includes(buyplanStatus)) {
                checkedObjArr += 1;
              }
            } else if (valueArr.includes(buyplan[keyName])) {
              checkedObjArr += 1;
            }
          });
          // checking all the filters selected are in each buyplan
          if (checkedObjArr === NO_OF_FILTERS) {
            modifiedArr.push(buyplan);
            if (!(selectedworkflow_ids.includes(buyplan.workflow_id))) {
              selectedworkflow_ids.push(buyplan.workflow_id);
            }
          }
        });
        const [activeData, historyData] = segregateBuyPlans(seggregatedBuyplansForFilters(apiValueNonPlayBookData, selectedworkflow_ids));
        setPage(0);
        // const historyDataSortPlan = _.sortBy(historyData, (item) => item.workflow_id).sort((a, b) => parseInt(a.execution_month[0]) - parseInt(b.execution_month[0]));
        // const activeDataSortPlan = _.sortBy(activeData, (item) => item.workflow_id).sort((a, b) => parseInt(a.execution_month[0]) - parseInt(b.execution_month[0]));
        // dispatch(setSortFilter(values[5]));
        // dispatch(setSortType('asc'));
        setSortMap(sortStatusMapping);
        updateListData(activeData, historyData, isHistorySelected);
        // setCount({active: activeData.length, history: historyData.length, notclosed: historyData.filter((data) => data.status != 'CLOSED').length});

      // if (activeData.length <= 5 || historyData.length <= 5) {
      //   setPage(0);
      // }
      } else {
        const [activeData, historyData] = segregateBuyPlans(apiValueNonPlayBookData);
        setPage(0);
        updateListData(activeData, historyData, isHistorySelected);

        // const historyDataSortPlan = _.sortBy(historyData, (item) => item.workflow_id).sort((a, b) => parseInt(a.execution_month[0]) - parseInt(b.execution_month[0]));
        // const activeDataSortPlan = _.sortBy(activeData, (item) => item.workflow_id).sort((a, b) => parseInt(a.execution_month[0]) - parseInt(b.execution_month[0]));
        // setCount({active: activeData.length, history: historyData.length, notclosed: historyData.filter((data) => data.status != 'CLOSED').length});

      // if (activeData.length <= 5 || historyData.length <= 5) {
      //   setPage(0);
      // }
      }
    }, 200);
  }, [selectedDropDownData, apiValueNonPlayBookData, isHistorySelected, dispatch]);

  // if executionmonth is current month or (executionmonth is last month and status is APPROVED and today is less or equal to WD5) -- then it will stay in Active
  // else it will go to History

  const segregateBuyPlans = (plans) => {
    const activeData = [];
    const historyData = [];
    (plans || []).forEach((plan) => {
      const today = new Date();
      const isExecutionCurrentMonth = plan.execution_month.includes((today.getMonth()) + 1 ) && plan.execution_month.includes(today.getFullYear());

      if (isExecutionCurrentMonth && plan.status!=='CLOSED') {
        activeData.push(plan);
      } else {
        historyData.push(plan);
      }
    });
    return [activeData, historyData];
  };

  function sortPlanMonth(planArr, sortType) {
    const planYears = [];
    planArr.forEach( (plan) => {
      if (!(planYears.includes(String(plan.execution_month[0])))) {
        planYears.push(String(plan.execution_month[0]));
      }
    });
    const sortedPlanArr = [];
    planYears.forEach((planYear) => {
      const planTempArr = [];
      planArr.forEach((plan) => {
        if (String(plan.execution_month[0]) === String(planYear)) {
          planTempArr.push(plan);
        }
      });
      if ( sortType === 'asc') {
        planTempArr.sort((a, b) => parseInt(a.execution_month[1]) - parseInt(b.execution_month[1]));
      }
      if ( sortType === 'desc') {
        planTempArr.sort((a, b) => parseInt(b.execution_month[1]) - parseInt(a.execution_month[1]));
      }
      sortedPlanArr.push(...planTempArr);
    });
    return sortedPlanArr;
  }
  useEffect(()=>{
    if (SortValue === 'executionMonthYear' || SortValue==='execution_month') {
      if (sorttype === 'asc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => parseInt(a.execution_month[0]) - parseInt(b.execution_month[0]));
        const modifiedObj = sortPlanMonth(planArr, sorttype);
        setPlanListData([...modifiedObj]);
      } if (sorttype === 'desc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => parseInt(b.execution_month[0]) - parseInt(a.execution_month[0]));
        const modifiedObj = sortPlanMonth(planArr, sorttype);
        setPlanListData([...modifiedObj]);
      }
    } else if (['non_playbook_name', 'sector', 'category', 'commodity', 'sub_commodity', 'status'].includes(SortValue)) {
      if (sorttype === 'asc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => a[SortValue].localeCompare(b[SortValue]));
        setPlanListData([...planArr]);
      } if (sorttype === 'desc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => b[SortValue].localeCompare(a[SortValue]));
        setPlanListData([...planArr]);
      }
    } else if (SortValue === 'country') {
      if (sorttype === 'asc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => a.region.localeCompare(b.region));
        setPlanListData([...planArr]);
      } if (sorttype === 'desc') {
        const planArr = [...planListData];
        planArr.sort((a, b) => b.region.localeCompare(a.region));
        setPlanListData([...planArr]);
      }
    }
  }, [SortValue, sorttype]);

  return (
    <Box
      sx={{
        borderRadius: '5px',
        width: '97%',
        marginTop: '20px',
        bgcolor: 'white',
        marginLeft: 'auto',
        marginRight: 'auto',

      }}
    >
      <NonPlayBookFilters />
      {/* <Box sx={{padding: '0px 18px', display: 'flex', gap: '10px', flexDirection: 'row-reverse', marginTop: '0px'}}>
        <Styles.StyledDownloadButton onClick={handleAddNonPlayBookRow}>
          <AddIcon sx={{fontSize: '18px'}} /> Add Non-Playbook
        </Styles.StyledDownloadButton>
      </Box> */}
      <Box sx={{border: '1px solid #f5f5f5', width: '97%', margin: 'auto'}}></Box>
      <Box sx={{display: 'flex', paddingLeft: '20px'}}>
        <Typography sx={{marginRight: '20px', marginTop: '10px'}}>
          <Box
            component="span"
            sx={{
              font: 'normal normal 700 13px/19px \'Segoe UI\'',
              color: '#4a4948',
              marginTop: '10px',
              cursor: 'pointer',
            }}
            style={{
              borderBottom: isHistorySelected === false ? '2px solid #efbc28' : 'none',
            }}
            onClick={() => setIsHistorySelected(false)}
          >
            Active Non Playbooks
          </Box>
          &nbsp;
          <Box component="span" sx={{color: '#0F5DAA', fontWeight: '700', fontSize: '10px'}}>
            ({count.active})
          </Box>
        </Typography>
        <Typography sx={{marginRight: '20px', marginTop: '10px'}}>
          <Box
            component="span"
            sx={{
              font: 'normal normal 700 13px/19px \'Segoe UI\'',
              color: '#4a4948',
              marginTop: '10px',
              cursor: 'pointer',
            }}
            style={{
              borderBottom: isHistorySelected === true ? '2px solid #efbc28' : 'none',
            }}
            onClick={() => setIsHistorySelected(true)}
          >
            Historical Non Playbooks
          </Box>
          &nbsp;
          <Box component="span" sx={{color: '#0F5DAA', fontWeight: '700', fontSize: '10px'}}>
            ({count.history > 50 ? 'Total 50+' : 'Total ' + count.history}
            {count.notclosed ? <font color="#D54444">&nbsp;&nbsp;Not Closed {count.notclosed}</font> : ''})
          </Box>
        </Typography>
      </Box>


      {/* <NonPlayBookAddPage popup={popup} setPopup={setPopup} setNonPlayBookData={setNonPlayBookData} /> */}

      {isHistorySelected ? (
        <HistoricalNonPlayBook
          setCount={setCount}
          sortValues={sortValues}
          data={planListData}
          setPage={setPage}
          page={page}
          sortMap={sortMap}
          setSortMap={setSortMap}
          sortStatusMapping={sortStatusMapping}
        />
      ) : (
        <ActiveNonPlayBook
          setCount={setCount}
          sortValues={sortValues}
          data={planListData}
          setPage={setPage}
          page={page}
          sortMap={sortMap}
          setSortMap={setSortMap}
          sortStatusMapping={sortStatusMapping}
        />
      )}
    </Box>
  );
}
