/* eslint-disable no-unused-vars */
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../../services/ConfigurationServices';
import theme from '../../../../theme';
import Loading from '../../CommonConfFiles/Loading/Loading';
import ErrorMessage from '../../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../../common/App-Dialog/AppDialog';
import CountryTable from './CommonTable/CountryTable';
import CountryDisplay from './CountryDisplay';
import config from '../../../../Utils/Config';
import _ from 'lodash';

const Country = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);


  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);

  const heads = ['Country',
    'Is Active', 'Last Edited By', 'Last Edited On'];

  const values = ['region', 'is_active', 'updated_by', 'updated_on',
  ];

  const dateValues = [false, false];

  const editValues = [true, true];

  const [pagination, setPagination] = useState(10);

  const [loading, setLoading] = useState(false);
  const [addingTrigger, setAddingTrigger] = useState(false);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedCountryValue);

  const sortValues = [true, false, true, true];


  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const updateSingleUnitCountry = ConfigurationServices.updateCountryData;
  const newRowUpdate = ConfigurationServices.postCountryData;


  useEffect(()=>{
    ConfigurationServices.getCountryData()
        .then((res)=>{
          setLoading(true);
          setTimeout(()=>{
            setLoading(false);
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                if (res && Array.isArray(res)) {
                  setRow([...res.filter((r) => r.is_active), ...res.filter((r) => !r.is_active)]);
                } else {
                  setRow([]);
                }
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          }, 1000);
        });
  }, [addingTrigger]);

  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='countrysector_all' ) {
      setTableFilterData([...row]);
    } else {
      setLoading(true);

      setTimeout(()=>{
        setLoading(false);
        if (filteredUnitValue['countrysector'].length > 0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['countrysector']
                .includes(r.region);
          }));
        } else if (filteredUnitValue['countrysector'].length == 0) {
          setTableFilterData([...row]);
        }
        setPage(0);
      }, 1000);
    }
  }, [filteredUnitValue, row, addingTrigger]);

  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='updated_on') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.updated_on));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.updated_on));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Sector_Master']) {
        setAccessControl(functionalityAccess['Configuration:Sector_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [page, setPage] = React.useState(0);
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>

        <CountryDisplay row={_.orderBy(row, [(r) => r?.region?.toLowerCase()])}/>

        {/* {loading ? <Loading /> : */}


        <Box sx={{}}>
          <CountryTable
            data={tableFilterData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            singleUpdate={updateSingleUnitCountry}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            sortValues={sortValues}
            accessControl={accessControl}
            page={page}
            setPage={setPage}
          />

          {(errMsg !=='' || tableFilterData.length == 0)&&
        <ErrorMessage message='Country' errmsg={errMsg}/>
          }
        </Box>
        {/* } */}
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg} />
      </Box>}
    </Box>
  );
};

export default Country;
