/* eslint-disable no-unused-vars */
import React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import Styles from '../../styles/Forecast';
import {AppButton, AppNotSelectedButton} from '../common/AppButton/AppButton';
import {CircularProgress} from '@mui/material';
import {useEffect} from 'react';
import {useState} from 'react';
const PreviousMonthBuyPlanPopUp = ({isOpen, handleClose, handleSubmit, data, setData, years, isPreviousYearData, execMonthDecider, selectedYear}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(()=>{
    let emptyCommentcount = 0;
    data.length>0 && data.map((d)=>{
      if (d['comment']=='') {
        emptyCommentcount+=1;
      }
    });
    if (emptyCommentcount>0) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [data]);

  const changingDataValue = (e, index, month) =>{
    const changeddata = [...data];
    changeddata[index]['comment'] = e.target.value;
    setData([...changeddata]);
  };
  return (
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={isOpen}
      >
        <DialogContent>

          <Box sx={{mt: 2}}>
            <DialogContentText sx={{'font-family': 'Segoe UI', 'font-size': '13px', 'color': '#3B4655'}}>
               You have changed the Will Cover values for historical coverage months. Please provide the reason below to save your changes
            </DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <Box sx={{}}>
                <FormControl sx={{mt: 2, minWidth: 160, flexDirection: 'column', display: 'flex', width: '500px', gap: '5px'}}>
                  {data.length>0&&data.map((d, index)=>(
                    <Box key={index} sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                      <Box>{d['planYearMonth'].split('-')[0][0]?.toUpperCase()+d['planYearMonth'].split('-')[0]?.slice(1) +' '}{isPreviousYearData==false? +years[0] : +years[execMonthDecider?.indexOf(selectedYear)]}</Box>
                      <Styles.StyledVarianceCommmentText
                        style={{width: 350}}
                        minRows={3}
                        onChange={(e) => changingDataValue(e, index, d)}
                        value={d['comment']}
                      />
                    </Box>
                  ))}

                </FormControl>
              </Box>
            </Box>
          </Box>


          <DialogActions sx={{mb: 2, mt: 2}}>
            <AppNotSelectedButton
              onClick={async () => {
                handleClose();
              }}
              label="Close"
            />
            <AppButton
              disabled={submitDisabled}
              onClick={async (e) => {
                e.target.disabled=true;
                handleSubmit();
              }}
              label="Save"
            />

          </DialogActions>
        </DialogContent>

      </Dialog>
    </React.Fragment>

  );
};

export default PreviousMonthBuyPlanPopUp;
