/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn, floatColumn, checkboxColumn, intColumn} from 'react-datasheet-grid';


import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../services/dialogService';
import {monthArr, months, roundToTwo, titleCase} from '../../services/utils';

import {AppTableInput} from './AppTableInput/AppTableInput';
import {CopyIcon} from '../common/Icons/icons';
import './forecast.css';
import {AppButton} from '../common/AppButton/AppButton';

import {forecastDefaultData, fyCalculateTotal, getSortedMonthlyData, monthNYearSetter, rowsSetter} from './utils';
import {setHistoricalsaveButton, setReloadData, setlinkingLoad} from '../../redux/features/configurations';
import {AppDialog, AppErrorDialog} from '../common/App-Dialog/AppDialog';
import {updateStatusOfBuyPlan} from '../../redux/features/apiCall';
import {changeBuyplanStatus} from '../../redux/features/buttonStatus';


const HistoricalCoverage = ({
  wfDetails,
  subMissionData, prevNonSuspendedmonth,
  setSubmissionData,
  years, setSelectedYear, historicalTrigger, setHistoricalTrigger,
  setTabsEnableCheck, setValue, setDataTrigger, dataTrigger, newWfID,
  data,
  setData, columns, columns2,
  setModifiedData,
  selectedYear,
  modifiedData,
  selectedRow,
  PreviousHistoricalCoverageYear1, PreviousHistoricalCoverageYear2, PreviousHistoricalCoverageYear3,
  setPreviousHistoricalCoverageYear1, setPreviousHistoricalCoverageYear2, setPreviousHistoricalCoverageYear3,
}) => {
  const [accessControl, setAccessControl]=useState('');
  const linkingLoad = useSelector((state)=>state.configurations.linkingLoad);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const HistoricalsaveButton = useSelector((state)=>state.configurations.HistoricalsaveButton);
  const [showErrorDialog, setShowErrorDialog]=useState(false);
  const [errorMessage, setErrorMessage]=useState(null);
  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Forecast_Update']) {
      setAccessControl(functionalityAccess['Buy_Plan:Forecast_Update']);
    }
  }, [functionalityAccess]);

  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);

  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  const dispatch = useDispatch();

  const postCall = async () => {
    const objectAll = {
      'workflowId': newWfID,
      'prevWorkflowId': 0,
      'totalCoverages': [],
    };

    // console.log([PreviousHistoricalCoverageYear1, PreviousHistoricalCoverageYear2, PreviousHistoricalCoverageYear3]);
    if (selectedRow.coverageMode =='NONE' && selectedRow.others==false) {
      PreviousHistoricalCoverageYear1.map((d1)=>{
        Object.keys(d1).map((obj)=>{
          // if (months.includes(obj) && d1.source!=='Total' && d1.source!=='Other') {

          if (months.includes(obj) && d1.source==='Total') {
            const dummyObj = {
              workflowId: newWfID,
              coverageYearMonth: [selectedRow.executionMonthYear[1]==2 ? d1.planYear-1 : d1.planYear, months.indexOf(obj)+1],
              // total: wfDetails?.decimal_scale==0?+CommaRemoval(roundToTwo(wfDetails.decimal_scale, d1[obj])):+CommaRemoval(d1[obj]),
              total: wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails.decimal_scale, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
              sourceType: 'OTHER',
            };
            objectAll['totalCoverages'].push(dummyObj);
          }
        });
      },
      );

      PreviousHistoricalCoverageYear2.map((d1)=>{
        Object.keys(d1).map((obj)=>{
          // if (months.includes(obj) && d1.source!=='Total' && d1.source!=='Other') {

          if (months.includes(obj) && d1.source==='Total') {
            const dummyObj = {
              workflowId: newWfID,
              coverageYearMonth: [selectedRow.executionMonthYear[1]==2 ? d1.planYear-1 : d1.planYear, months.indexOf(obj)+1],
              // total: wfDetails?.decimal_scale==0?+CommaRemoval(roundToTwo(wfDetails.decimal_scale, d1[obj])):+CommaRemoval(d1[obj]),
              total: wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails.decimal_scale, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
              sourceType: 'OTHER',
            };
            objectAll['totalCoverages'].push(dummyObj);
          }
        });
      },
      );

      PreviousHistoricalCoverageYear3.map((d1)=>{
        Object.keys(d1).map((obj)=>{
          // if (months.includes(obj) && d1.source!=='Total' && d1.source!=='Other') {

          if (months.includes(obj) && d1.source==='Total') {
            const dummyObj = {
              workflowId: newWfID,
              coverageYearMonth: [selectedRow.executionMonthYear[1]==2 ? d1.planYear-1 : d1.planYear, months.indexOf(obj)+1],
              // total: wfDetails?.decimal_scale==0?+CommaRemoval(roundToTwo(wfDetails.decimal_scale, d1[obj])):+CommaRemoval(d1[obj]),
              total: wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails.decimal_scale, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
              sourceType: 'OTHER',
            };
            objectAll['totalCoverages'].push(dummyObj);
          }
        });
      },
      );
    } else {
      PreviousHistoricalCoverageYear1.map((d1)=>{
        Object.keys(d1).map((obj)=>{
        // if (months.includes(obj) && d1.source!=='Total' && d1.source!=='Other') {

          if (months.includes(obj) && d1.source!=='Total') {
            const dummyObj = {
              workflowId: newWfID,
              coverageYearMonth: [selectedRow.executionMonthYear[1]==2 ? d1.planYear-1 : d1.planYear, months.indexOf(obj)+1],
              // total: wfDetails?.decimal_scale==0?+CommaRemoval(roundToTwo(wfDetails.decimal_scale, d1[obj])):+CommaRemoval(d1[obj]),
              total: wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails.decimal_scale, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
              sourceType: d1.source.toUpperCase(),
            };
            objectAll['totalCoverages'].push(dummyObj);
          }
        });
      },
      );

      PreviousHistoricalCoverageYear2.map((d1)=>{
        Object.keys(d1).map((obj)=>{
        // if (months.includes(obj) && d1.source!=='Total' && d1.source!=='Other') {

          if (months.includes(obj) && d1.source!=='Total') {
            const dummyObj = {
              workflowId: newWfID,
              coverageYearMonth: [selectedRow.executionMonthYear[1]==2 ? d1.planYear-1 : d1.planYear, months.indexOf(obj)+1],
              // total: wfDetails?.decimal_scale==0?+CommaRemoval(roundToTwo(wfDetails.decimal_scale, d1[obj])):+CommaRemoval(d1[obj]),
              total: wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails.decimal_scale, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
              sourceType: d1.source.toUpperCase(),
            };
            objectAll['totalCoverages'].push(dummyObj);
          }
        });
      },
      );

      PreviousHistoricalCoverageYear3.map((d1)=>{
        Object.keys(d1).map((obj)=>{
        // if (months.includes(obj) && d1.source!=='Total' && d1.source!=='Other') {

          if (months.includes(obj) && d1.source!=='Total') {
            const dummyObj = {
              workflowId: newWfID,
              coverageYearMonth: [selectedRow.executionMonthYear[1]==2 ? d1.planYear-1 : d1.planYear, months.indexOf(obj)+1],
              // total: wfDetails?.decimal_scale==0?+CommaRemoval(roundToTwo(wfDetails.decimal_scale, d1[obj])):+CommaRemoval(d1[obj]),
              total: wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails.decimal_scale, +CommaRemoval(d1[obj])) : +CommaRemoval(d1[obj]),
              sourceType: d1.source.toUpperCase(),
            };
            objectAll['totalCoverages'].push(dummyObj);
          }
        });
      },
      );
    }


    // console.log(objectAll);


    DialogService.saveHistoricalCoverage(objectAll).then(async (res) => {
      if (res == true) {
        setIsSavePopupVisible(true);
        dispatch(setHistoricalsaveButton('true'));
        const d = new Date();
        setHistoricalTrigger(d.getMilliseconds());
      }
      if (selectedRow.status !=='DRAFT') {
        await DialogService.updateStatus(selectedRow.workFlowId, 'DRAFT')
            .then((res) => {
              if (res.status == 200 || res===true) {
                dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT'));
              }
            });
      }
      if (selectedRow.prevWorkFlowId < 0) {
        await DialogService.postLink(selectedRow.workFlowId, newWfID).then((res) => {
          dispatch(setlinkingLoad(true));
          const d = new Date();
          dispatch(setReloadData(d.getMilliseconds()));
          dispatch(changeBuyplanStatus('DRAFT'));
        });
      } else {
        dispatch(setlinkingLoad(false));
      }
      // setTabsEnableCheck((prev)=>({...prev, forecast: true}));
    }).catch((err)=>{
      const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
      setErrorMessage(errmsg);
      setShowErrorDialog(true);
    });
    // setTimeout(()=>{
    //   setHistoricalTrigger(!historicalTrigger);
    // }, 1000);
  };

  const CommaRemoval = (val) => {
    // const re = new RegExp(`^[0-9]{1,2}([,.][0-9]{1,2})?$`);
    // const flag = re.test(val.toString());
    // if (flag) {
    return (isNaN(val?.toString().replaceAll(',', '')) ? 0 : val?.toString().replaceAll(',', '')) || 0;
    // }
  };

  const updatingTotal1 = (newData) =>{
    const d1total = [newData[0], newData[1], newData[2]];
    // setTimeout(()=>{
    // eslint-disable-next-line new-cap
    newData[0]['fy'] = roundToTwo(wfDetails?.decimal_scale, +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['jan']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['feb']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['mar'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['apr'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['may'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['jun'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['jul'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['aug'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['sep']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['oct']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['nov']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[0]['dec'])));
    // eslint-disable-next-line new-cap
    newData[1]['fy'] = roundToTwo(wfDetails?.decimal_scale, +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['jan']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['feb']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['mar'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['apr'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['may'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['jun'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['jul'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['aug'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['sep']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['oct']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['nov']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[1]['dec'])));
    // eslint-disable-next-line new-cap
    newData[2]['fy'] = roundToTwo(wfDetails?.decimal_scale, +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['jan']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['feb']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['mar'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['apr'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['may'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['jun'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['jul'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['aug'])) + +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['sep']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['oct']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['nov']))+ +roundToTwo(wfDetails?.decimal_scale, CommaRemoval(newData[2]['dec'])));
    // }, 100);
    const totald1 = {
      month: 3,
      planMonth: monthArr[+prevNonSuspendedmonth.split('-')[1]-1],
      source: 'Total',
      // using selectedRow.executionMonthYear[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years

      planYear: selectedRow.executionMonthYear[1]==2 ? years[1] : years[0],
      jan: (+CommaRemoval(newData[0].jan) + +CommaRemoval(newData[1].jan) + +CommaRemoval(newData[2].jan))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].jan) + +CommaRemoval(newData[1].jan) + +CommaRemoval(newData[2].jan)) : (+CommaRemoval(newData[0].jan) + +CommaRemoval(newData[1].jan) + +CommaRemoval(newData[2].jan)),
      feb: (+CommaRemoval(newData[0].feb) + +CommaRemoval(newData[1].feb) + +CommaRemoval(newData[2].feb))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].feb) + +CommaRemoval(newData[1].feb) + +CommaRemoval(newData[2].feb)) : (+CommaRemoval(newData[0].feb) + +CommaRemoval(newData[1].feb) + +CommaRemoval(newData[2].feb)),
      mar: (+CommaRemoval(newData[0].mar) + +CommaRemoval(newData[1].mar) + +CommaRemoval(newData[2].mar))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].mar) + +CommaRemoval(newData[1].mar) + +CommaRemoval(newData[2].mar)) : (+CommaRemoval(newData[0].mar) + +CommaRemoval(newData[1].mar) + +CommaRemoval(newData[2].mar)),
      apr: (+CommaRemoval(newData[0].apr) + +CommaRemoval(newData[1].apr) + +CommaRemoval(newData[2].apr))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].apr) + +CommaRemoval(newData[1].apr) + +CommaRemoval(newData[2].apr)) : (+CommaRemoval(newData[0].apr) + +CommaRemoval(newData[1].apr) + +CommaRemoval(newData[2].apr)),
      may: (+CommaRemoval(newData[0].may) + +CommaRemoval(newData[1].may) + +CommaRemoval(newData[2].may))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].may) + +CommaRemoval(newData[1].may) + +CommaRemoval(newData[2].may)) : (+CommaRemoval(newData[0].may) + +CommaRemoval(newData[1].may) + +CommaRemoval(newData[2].may)),
      jun: (+CommaRemoval(newData[0].jun) + +CommaRemoval(newData[1].jun) + +CommaRemoval(newData[2].jun))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].jun) + +CommaRemoval(newData[1].jun) + +CommaRemoval(newData[2].jun)) : (+CommaRemoval(newData[0].jun) + +CommaRemoval(newData[1].jun) + +CommaRemoval(newData[2].jun)),
      jul: (+CommaRemoval(newData[0].jul) + +CommaRemoval(newData[1].jul) + +CommaRemoval(newData[2].jul))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].jul) + +CommaRemoval(newData[1].jul) + +CommaRemoval(newData[2].jul)) : (+CommaRemoval(newData[0].jul) + +CommaRemoval(newData[1].jul) + +CommaRemoval(newData[2].jul)),
      aug: (+CommaRemoval(newData[0].aug) + +CommaRemoval(newData[1].aug) + +CommaRemoval(newData[2].aug))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].aug) + +CommaRemoval(newData[1].aug) + +CommaRemoval(newData[2].aug)) : (+CommaRemoval(newData[0].aug) + +CommaRemoval(newData[1].aug) + +CommaRemoval(newData[2].aug)),
      sep: (+CommaRemoval(newData[0].sep) + +CommaRemoval(newData[1].sep) + +CommaRemoval(newData[2].sep))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].sep) + +CommaRemoval(newData[1].sep) + +CommaRemoval(newData[2].sep)) : (+CommaRemoval(newData[0].sep) + +CommaRemoval(newData[1].sep) + +CommaRemoval(newData[2].sep)),
      oct: (+CommaRemoval(newData[0].oct) + +CommaRemoval(newData[1].oct) + +CommaRemoval(newData[2].oct))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].oct) + +CommaRemoval(newData[1].oct) + +CommaRemoval(newData[2].oct)) : (+CommaRemoval(newData[0].oct) + +CommaRemoval(newData[1].oct) + +CommaRemoval(newData[2].oct)),
      nov: (+CommaRemoval(newData[0].nov) + +CommaRemoval(newData[1].nov) + +CommaRemoval(newData[2].nov))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].nov) + +CommaRemoval(newData[1].nov) + +CommaRemoval(newData[2].nov)) : (+CommaRemoval(newData[0].nov) + +CommaRemoval(newData[1].nov) + +CommaRemoval(newData[2].nov)),
      dec: (+CommaRemoval(newData[0].dec) + +CommaRemoval(newData[1].dec) + +CommaRemoval(newData[2].dec))?.toString().split('.').length>1? roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0].dec) + +CommaRemoval(newData[1].dec) + +CommaRemoval(newData[2].dec)) : (+CommaRemoval(newData[0].dec) + +CommaRemoval(newData[1].dec) + +CommaRemoval(newData[2].dec)),
      year: selectedRow.executionMonthYear[1]==2 ? years[1] : years[0],
      workflow_id: selectedRow.workFlowId,
    };
    totald1['fy']= +CommaRemoval(totald1['jan'])+ +CommaRemoval(totald1['feb'])+ +CommaRemoval(totald1['mar']) + +CommaRemoval(totald1['apr']) + +CommaRemoval(totald1['may']) + +CommaRemoval(totald1['jun']) + +CommaRemoval(totald1['jul']) + +CommaRemoval(totald1['aug']) + +CommaRemoval(totald1['sep'])+ +CommaRemoval(totald1['oct'])+ +CommaRemoval(totald1['nov'])+ +CommaRemoval(totald1['dec']);

    // console.log(totald1);
    d1total.push(totald1);
    return d1total;
  };
  const updatingTotal2 = (newData) =>{
    // console.log(newData);
    const d1total = [newData[0], newData[1], newData[2], newData[3]];
    // setTimeout(()=>{
    // eslint-disable-next-line new-cap
    newData[0]['fy'] = roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[0]['jan'])+ +CommaRemoval(newData[0]['feb'])+ +CommaRemoval(newData[0]['mar']) + +CommaRemoval(newData[0]['apr']) + +CommaRemoval(newData[0]['may']) + +CommaRemoval(newData[0]['jun']) + +CommaRemoval(newData[0]['jul']) + +CommaRemoval(newData[0]['aug']) + +CommaRemoval(newData[0]['sep'])+ +CommaRemoval(newData[0]['oct'])+ +CommaRemoval(newData[0]['nov'])+ +CommaRemoval(newData[0]['dec']));
    // eslint-disable-next-line new-cap
    newData[1]['fy'] = roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[1]['jan'])+ +CommaRemoval(newData[1]['feb'])+ +CommaRemoval(newData[1]['mar']) + +CommaRemoval(newData[1]['apr']) + +CommaRemoval(newData[1]['may']) + +CommaRemoval(newData[1]['jun']) + +CommaRemoval(newData[1]['jul']) + +CommaRemoval(newData[1]['aug']) + +CommaRemoval(newData[1]['sep'])+ +CommaRemoval(newData[1]['oct'])+ +CommaRemoval(newData[1]['nov'])+ +CommaRemoval(newData[1]['dec']));
    // eslint-disable-next-line new-cap
    newData[2]['fy'] = roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[2]['jan'])+ +CommaRemoval(newData[2]['feb'])+ +CommaRemoval(newData[2]['mar']) + +CommaRemoval(newData[2]['apr']) + +CommaRemoval(newData[2]['may']) + +CommaRemoval(newData[2]['jun']) + +CommaRemoval(newData[2]['jul']) + +CommaRemoval(newData[2]['aug']) + +CommaRemoval(newData[2]['sep'])+ +CommaRemoval(newData[2]['oct'])+ +CommaRemoval(newData[2]['nov'])+ +CommaRemoval(newData[2]['dec']));
    // eslint-disable-next-line new-cap
    newData[3]['fy'] = roundToTwo(wfDetails?.decimal_scale, +CommaRemoval(newData[3]['jan'])+ +CommaRemoval(newData[3]['feb'])+ +CommaRemoval(newData[3]['mar']) + +CommaRemoval(newData[3]['apr']) + +CommaRemoval(newData[3]['may']) + +CommaRemoval(newData[3]['jun']) + +CommaRemoval(newData[3]['jul']) + +CommaRemoval(newData[3]['aug']) + +CommaRemoval(newData[3]['sep'])+ +CommaRemoval(newData[3]['oct'])+ +CommaRemoval(newData[3]['nov'])+ +CommaRemoval(newData[3]['dec']));

    // }, 100);

    return d1total;
  };

  useEffect(()=>{
    if (selectedRow.coverageMode =='NONE' && selectedRow.others == false) {
      setPreviousHistoricalCoverageYear1(updatingTotal2(PreviousHistoricalCoverageYear1));
      setPreviousHistoricalCoverageYear2(updatingTotal2(PreviousHistoricalCoverageYear2));
      setPreviousHistoricalCoverageYear3(updatingTotal2(PreviousHistoricalCoverageYear3));
    } else {
      setPreviousHistoricalCoverageYear1(updatingTotal1(PreviousHistoricalCoverageYear1));
      setPreviousHistoricalCoverageYear2(updatingTotal1(PreviousHistoricalCoverageYear2));
      setPreviousHistoricalCoverageYear3(updatingTotal1(PreviousHistoricalCoverageYear3));
    }
  }, [historicalTrigger]);


  return (
    <>
      {/* {((columns.length && PreviousHistoricalCoverageYear1.length) || '') && ( */}
      <div className="forecast-table">
        {HistoricalsaveButton == 'false' && accessControl === 'Edit' && selectedRow.status !=='SUBMITTED' && selectedRow.status !=='APPROVED' && selectedRow.status !== 'CLOSED' && selectedYear == years[0] && (
          <DataSheetGrid
            value={PreviousHistoricalCoverageYear1}
            onChange={(newData)=>{
              if (selectedRow.coverageMode =='NONE' && selectedRow.others == false) {
                setPreviousHistoricalCoverageYear1(updatingTotal2(newData));
              } else {
                setPreviousHistoricalCoverageYear1(updatingTotal1(newData));
              }
            }}
            columns={columns}
            rowClassName={() => 'forecast'}
            disableContextMenu={true}
            gutterColumn={false}
          />
        )}

        {HistoricalsaveButton == 'false' && accessControl === 'Edit' && selectedRow.status !=='SUBMITTED' && selectedRow.status !=='APPROVED' && selectedRow.status !== 'CLOSED' &&selectedYear == years[1] && (
          <DataSheetGrid
            value={PreviousHistoricalCoverageYear2}
            onChange={(newData)=>{
              if (selectedRow.coverageMode =='NONE' && selectedRow.others == false) {
                setPreviousHistoricalCoverageYear2(updatingTotal2(newData));
              } else {
                setPreviousHistoricalCoverageYear2(updatingTotal1(newData));
              }
            }}
            columns={columns}
            rowClassName={() => 'forecast'}
            disableContextMenu={true}
            gutterColumn={false}
          />
        )}

        {HistoricalsaveButton == 'false' && accessControl === 'Edit' && selectedRow.status !=='SUBMITTED' && selectedRow.status !=='APPROVED' && selectedRow.status !== 'CLOSED' && selectedYear == years[2] && (
          <DataSheetGrid
            value={PreviousHistoricalCoverageYear3}
            onChange={(newData)=>{
              if (selectedRow.coverageMode =='NONE' && selectedRow.others == false) {
                setPreviousHistoricalCoverageYear3(updatingTotal2(newData));
              } else {
                setPreviousHistoricalCoverageYear3(updatingTotal1(newData));
              }
            }}
            columns={columns}
            rowClassName={() => 'forecast'}
            disableContextMenu={true}
            gutterColumn={false}
          />
        )}


        {(HistoricalsaveButton == 'true' || accessControl !== 'Edit' || selectedRow.status =='SUBMITTED' || selectedRow.status =='APPROVED' || selectedRow.status == 'CLOSED') && selectedYear == years[0] && (
          <DataSheetGrid
            value={PreviousHistoricalCoverageYear1}
            // onChange={(newData)=>{
            //   setPreviousHistoricalCoverageYear1(updatingTotal1(newData));
            // }}
            columns={columns2}
            rowClassName={() => 'forecast'}
            disableContextMenu={true}
            gutterColumn={false}
          />
        )}

        {(HistoricalsaveButton == 'true' || accessControl !== 'Edit' || selectedRow.status =='SUBMITTED' || selectedRow.status =='APPROVED' || selectedRow.status == 'CLOSED') &&selectedYear == years[1] && (
          <DataSheetGrid
            value={PreviousHistoricalCoverageYear2}
            // onChange={(newData)=>{
            //   setPreviousHistoricalCoverageYear2(updatingTotal1(newData));
            // }}
            columns={columns2}
            rowClassName={() => 'forecast'}
            disableContextMenu={true}
            gutterColumn={false}
          />
        )}

        {(HistoricalsaveButton == 'true' || accessControl !== 'Edit' || selectedRow.status =='SUBMITTED' || selectedRow.status =='APPROVED' || selectedRow.status == 'CLOSED') && selectedYear == years[2] && (
          <DataSheetGrid
            value={PreviousHistoricalCoverageYear3}
            // onChange={(newData)=>{
            //   setPreviousHistoricalCoverageYear3(updatingTotal1(newData));
            // }}
            columns={columns2}
            rowClassName={() => 'forecast'}
            disableContextMenu={true}
            gutterColumn={false}
          />
        )}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          {linkingLoad===true ? <p></p>: <p></p>}
          {/* {accessControl === 'Edit' && (selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT') ? ( */}
          {accessControl === 'Edit' && (buttonStatus==='edit' || (currentRole.includes('Admin') && (selectedRow.status === 'DRAFT' || selectedRow.status=='REJECTED'))) ? (
          HistoricalsaveButton === 'false' ?
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
            <AppButton
              onClick={() => {
                postCall();
              }}
              label={'Save'}
            />
          </Box> :
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
            <AppButton
              onClick={() => {
                dispatch(setHistoricalsaveButton('false'));
                setDataTrigger(!dataTrigger);
              }}
              label={'Edit Coverages'}
            />
          </Box>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* )} */}
      <AppErrorDialog open={showErrorDialog} handleClose={() => {
        setShowErrorDialog(false); setErrorMessage(null);
      }} text={errorMessage ? errorMessage : 'Error occured when saving'}/>
      <AppDialog
        open={isSavePopupVisible}
        handleClose={() => {
          setIsSavePopupVisible(false);
        }}
        text="Historical Coverage Saved"
      />
    </>
  );
};

export default HistoricalCoverage;
