import palette from './palette';

const typography = {
  h5: {
    color: palette.text.secondary,
  },
  h6: {
    color: palette.text.secondary,
  },
};

export default typography;
