/* eslint-disable camelcase */
/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import {createTheme, styled} from '@mui/material/styles';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {Box, Button, Divider, FormControl, InputAdornment, InputBase, MenuItem, Select, TextareaAutosize} from '@mui/material';
import ConfigurationServices from '../../../services/ConfigurationServices';
import _ from 'lodash';
import customealltheme from '../../../theme';
import {useDispatch} from 'react-redux';
import {setSortType} from '../../../redux/features/configurations';
import {AppButton} from '../../common/AppButton/AppButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BuyPlanTextBox from './BuyPlanTextBox';

const iconColumn= {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

};

const arrowIcon = {zIndex: 2,
  width: '1px',
  height: '1px',
};

const textbox = {
  fontSize: '12px',
  font: 'normal normal normal 13px/15px \'Segoe UI\' ',
  color: 'rgba(85, 87, 89, 1)',
  width: '150px',
  height: '40px',
  marginTop: '10px',
  overflow: 'hidden',
  paddingTop: '10px',
  paddingLeft: '10px',
};

const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
};

const dropdown ={
  'width': '150px',
  'marginTop': '10px',
  '& .css-13so1lh-MuiInputBase-root': {
    width: '150px',
  },
};

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const ToggleContainer = styled('div')(({theme}) => ({
  'width': 60,
  'height': 26,
  'position': 'relative',
  'margin': '10px 10px 0px 10px',
  '& .MuiSwitch-root': {
    width: '100%',
    height: '100%',
  },
  '& .switchLabel': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#fff',
    fontSize: '0.75rem',
    transition: theme.transitions.create(['opacity'], {
      duration: 3,
    }),
  },
  '& .labelYes': {
    left: 5,
    opacity: 1,
  },
  '& .labelNo': {
    right: 5,
    opacity: 1,
  },
  '& .MuiSwitch-switchBase.Mui-checked ~ .labelYes': {
    opacity: 0,
  },
  '& .MuiSwitch-switchBase.Mui-checked ~ .labelNo': {
    opacity: 1,
  },
}));

const ToggleSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  'width': 60,
  'height': 26,
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'margin': 2,
    'transitionDuration': '3ms',
    '&.Mui-checked': {
      'transform': 'translateX(31px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#4297D3',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#6D7888',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 3,
    }),
  },
}));

function AddConfTable({buyplanPersona, Units, Currency, Playbooks, ActiveSectors, country, ActiveSubCommodities, setErrMsg, setIsErrorPopupVisible, setAddingTrigger, addingTrigger, newRowUpdate, popup, setPopup}) {
  const numberOptions = Array.from({length: 13}, (_, index) => index - 6);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setPopup(false);
      clearNewRow();
    }
  };

  const handleToggleOptionality = () => {
    setOptionalityValue((prevValue) => !prevValue);
  };

  const handleSupplierTargetsValue= () => {
    setSupplierTargetsValue((prevValue) => !prevValue);
  };

  const handleSupplierOptionalityValue= () => {
    setSupplierOptionalityValue((prevValue) => !prevValue );
  };

  const handleTreasuryTargetsValue= () => {
    setTreasuryTargetsValue((prevValue) => !prevValue);
  };

  const handleTreasuryOptionalityValue= () => {
    setTreasuryOptionalityValue((prevValue) => !prevValue );
  };

  const handleSupplierValue= () => {
    setSupplierValue((prevValue) => !prevValue );
  };

  const handleTreasuryValue= () => {
    setTreasuryValue((prevValue) => !prevValue );
  };

  const handleOthersValue= () => {
    setOthersValue((prevValue) => !prevValue );
  };

  const handleIsAtlasfxValue= () => {
    setIsAtlasfxValue((prevValue) => !prevValue );
  };

  const handleIsCarryVolume= () => {
    setIsCarryVolume((prevValue) => !prevValue );
  };

  const handleIncrement = () => {
    setOExposureMonthDifferenceValue((prevValue) => Math.min(prevValue + 1, 6));
  };

  const handleDecrement = () => {
    setOExposureMonthDifferenceValue((prevValue) => Math.max(prevValue - 1, -6));
  };

  const currentDate = dayjs();
  const [standardUnitIdValue, setStandardUnitIdValue] = useState('');
  const [commodityId, setcommodityId] = React.useState('');
  const [categoryId, setcategoryId] = React.useState('');
  const [subcommodityId, setsubcommodityId] = React.useState('');
  const [MastervalueSector, setMasterValueSector] = React.useState('');
  const [sectorId, setsectorId] = React.useState('');
  const [contrId, setcontrId] = useState('');
  const [Localunit, setLocalunit] = useState('');
  const [LocalCurrency, setLocalCurrency] = useState('');
  const [playbookIdValue, setPlaybookIdValue] = useState('');
  const [playbookNameValue, setPlayBookNameValue] = useState('');
  const [buyApprpers, setBuyAppPers] = useState('');
  const [standardCurrencyIdValue, setStandardCurrencyIdValue] = useState('');
  const [roundingNumber, setRoundingNumber] = useState('');
  const [creatorUID, setCreatorUId] = useState('');
  const [forecastUID, setForecastUId] = useState('');
  const [viewerUId, setViewerUId] = useState('');
  const [approverUID, setApproverUID] = useState('');
  const [ownerUID, setOwnerUID] = useState('');
  const [AdminUID, setAdminUID] = useState('');
  const [AdminPers, setAdminPers] = useState('');
  const [buyplanCreator, setBuyplanCreator] = useState('');
  const [buyplanApprover, setBuyplanApprover] = useState('');
  const [buycreatPer, setbuycreatPer] = useState('');
  const [forecastPer, setforecastPer] = useState('');
  const [buyPlanOwnerPersonaId, setbuyPlanOwnerPersonaId] = useState('');
  const [disableSupplierTarget, setDisableSupplierTarget] = useState(false);
  const [disableSupplierOptionality, setDisableSupplierOptionality] = useState(false);
  const [disableTreasuryOptionality, setDisableTreasuryOptionality] = useState(false);
  const [disableTreasuryTarget, setDisableTreasuryTarget] = useState(false);
  const [mappedRegionsList, setMappedRegionsList] = useState([]);
  const [showSubCommodityDropdown, setShowSubCommodityDropdown] = useState(false);
  const [buyplanNameValue, setBuyplanNameValue]= useState('');
  const [oChathamFolderEntityValue, setOChathamFolderEntityValue] = useState(null);
  const [oForecastProgramNameValue, setOForecastProgramNameValue]=useState(null);
  const [oFullEntityNameValue, setOFullEntityNameValue]=useState(null);
  const [oPlayBookValue, setOPlayBookValue]=useState(null);
  const [oCommodityValue, setOCommodityValue]=useState(null);
  const [oReportingRegionValue, setOReportingRegionValue]= useState(null);
  const [oSectorValue, setOSectorValue] =useState(null);
  const [oLegalEntityValue, setOLegalEntityValue] =useState(null);
  const [oProgramNameValue, setOProgramNameValue]=useState(null);
  const [oCommoditySubtypeValue, setOCommoditySubtypeValue]=useState(null);
  const [oCurrencyValue, setOCurrencyValue]=useState(null);
  const [oMeasurementUnitValue, setOMeasurementUnitValue]= useState(null);
  const [oExposureMonthDifferenceValue, setOExposureMonthDifferenceValue]=useState(0);
  const [supplierValue, setSupplierValue] = useState(false);
  const [treasuryValue, setTreasuryValue] = useState(false);
  const [othersValue, setOthersValue] = useState(false);
  const [categoryValue, setCategoryValue]= useState('');
  const [commodityValue, setCommodityValue]=useState('');
  const [masterSectorValue, setMasterSectorValue] = useState('');
  const [sectorValue, setSectorValue] = useState('');
  const [countryValue, setCountryValue]= useState('');
  const [subcommodityValue, setSubcommodityValue] =useState('');
  const [buyPlanOwnerValue, setBuyPlanOwnerValue] =useState('');
  const [buyPlanCreatorValue, setBuyPlanCreatorValue] =useState('');
  const [forecastCreatorValue, setForecastCreatorValue] = useState('');
  const [buyPlanViewerValue, setBuyPlanViewerValue]=useState('');
  const [buyPlanApproverValue, setBuyPlanApproverValue] =useState('');
  const [buyPlanAdminValue, setBuyPlanAdminValue] =useState('');
  const [isAtlasfxValue, setIsAtlasfxValue]=useState(false);
  const [isCarryVolume, setIsCarryVolume] = useState(false);
  const [currentStatusValue, setCurrentStatusValue]=useState(null);
  const [cascadeSelValue, setCascadeSelValue]= useState('');
  const [optionalityValue, setOptionalityValue]= useState('');
  const [supplierTargetsValue, setSupplierTargetsValue] = useState('');
  const [supplierOptionalityValue, setSupplierOptionalityValue] = useState('');
  const [treasuryTargetsValue, setTreasuryTargetsValue] = useState('');
  const [treasuryOptionalityValue, setTreasuryOptionalityValue] = useState('');
  const [activeStartDateValue, setActiveStartDateValue]=useState(null);
  const [activeEndDateValue, setActiveEndDateValue] = useState(null);
  const [futureStatusValue, setFutureStatusValue]=useState('');
  const [sectorFlag, setSectorFlag]=useState(false);
  const [countryFlag, setCountryFlag]=useState(false);
  const present = dayjs();
  let defaultFutureStartDate = present.add(1, 'month');
  if (present.date() !== defaultFutureStartDate.date()) {
    defaultFutureStartDate = defaultFutureStartDate.subtract(defaultFutureStartDate.date(), 'day');
  }
  let defaultFutureEndDate = defaultFutureStartDate.add(10, 'year');
  if (defaultFutureStartDate.date() !== defaultFutureEndDate.date()) {
    defaultFutureEndDate = defaultFutureEndDate.subtract(defaultFutureEndDate.date(), 'day');
  }
  const [futureStatusBackendValue, setFutureStatusBackendValue] =useState('');
  const [selectedFutureStartDate, setSelectedFutureStartDate] = useState('');
  const [selectedFutureEndDate, setSelectedFutureEndDate] = useState('');
  const [futureStartDateValue, setFutureStartDateValue]=useState('');
  const [futureEndDateValue, setFutureEndDateValue]=useState('');
  const [sectorCountryMaps, setSectorCountryMaps]=useState([]);
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  useEffect(()=>{
    if (supplierValue && !treasuryValue) {
      setIsAtlasfxValue(false);
    }
  }, [supplierValue, treasuryValue]);


  const handleSectorCountryDelete = (itemToRemove) => {
    const indexToRemove = sectorCountryMaps.findIndex((item) => item === itemToRemove);
    if (indexToRemove !== -1) {
      setSectorCountryMaps((prevList) => prevList.filter((item) => item !== itemToRemove));
    }
  };

  const handleAddSectorCountry = () => {
    if (countryValue && sectorValue && sectorId && contrId) {
      const exists = sectorCountryMaps.some((pair) =>
        pair.regionId === contrId && pair.sectorId === sectorId,
      );

      if (!exists) {
        setSectorCountryMaps((prevList) => [
          {id: sectorId.toString() + sectorValue + contrId.toString() + countryValue, sectorName: sectorValue, sectorId: sectorId, regionName: countryValue, regionId: contrId},
          ...prevList,
        ]);

        if (!sectorFlag) {
          setSectorValue('');
          setsectorId(null);
        }

        if (!countryFlag) {
          setCountryValue('');
          setcontrId(null);
        }
      }
    }
  };

  useEffect(() => {
    const newSupplierValue = (!supplierTargetsValue || !cascadeSelValue) ? supplierValue : true;

    if (supplierValue !== newSupplierValue) {
      setSupplierValue(newSupplierValue);
    }
  }, [playbookIdValue, cascadeSelValue, supplierTargetsValue, supplierValue]);

  useEffect(() => {
    const newTreasuryValue = (!treasuryTargetsValue || !cascadeSelValue) ? treasuryValue : true;

    if (treasuryValue !== newTreasuryValue) {
      setTreasuryValue(newTreasuryValue);
    }
  }, [playbookIdValue, cascadeSelValue, treasuryTargetsValue, treasuryValue]);


  const handlePlaybooktemClick = (obj) => {
    obj.sub_commodity ? setShowSubCommodityDropdown(true) : setShowSubCommodityDropdown(false);
    setActiveStartDateValue(null);
    setActiveEndDateValue(null);
    setBuyPlanAdminValue(obj.buyplan_admin);
    setBuyPlanApproverValue(obj.buyplan_approver);
    setBuyPlanCreatorValue(obj.buyplan_creator);
    setForecastCreatorValue(obj.forecast_creator);
    setBuyPlanViewerValue(obj.buyplan_viewer);
    setBuyPlanOwnerValue(obj.buyplan_owner);
    setbuyPlanOwnerPersonaId(obj.buyplan_owner_persona_id);
    setCascadeSelValue(obj.is_cascade_target_bp === true ? true : false);
    setcategoryId(obj.commodity_group_id);
    setCategoryValue(obj.category);
    setcommodityId(obj.commodity_id);
    setCommodityValue(obj.commodity);
    setcontrId(obj.region_id);
    setCountryValue(obj.country);
    setCurrentStatusValue(null);
    setMasterSectorValue(obj.master_sector);
    setMasterValueSector(obj.sector_group_id);
    setOptionalityValue(obj.optionality);
    setOwnerUID(obj.buyplan_owner_user_id);
    setPlayBookNameValue(obj.playbook_name);
    setsectorId(obj.sector_id);
    setSectorValue(obj.sector);
    setSectorFlag(obj.sector!=null);
    setCountryFlag(obj.country!=null);
    setStandardUnitIdValue(obj.standard_unit_id);
    setStandardCurrencyIdValue(obj.currency_id);
    setsubcommodityId(obj.sub_commodity_id);
    setSubcommodityValue(obj.sub_commodity);
    setSupplierValue(false);
    setSupplierTargetsValue(obj.supplier_targets?true:false);
    setSupplierOptionalityValue(obj.supplier_optionality);
    setTreasuryValue(false);
    setTreasuryTargetsValue(obj.treasury_targets?true:false);
    setTreasuryOptionalityValue(obj.treasury_optionality);
    setOthersValue(obj.others);
    setSectorCountryMaps([]);
  };

  useEffect(() => {
    setDisableSupplierTarget(false);
    setDisableSupplierOptionality(false);
    setDisableTreasuryTarget(false);
    setDisableTreasuryOptionality(false);
    if (cascadeSelValue == false) {
      if (supplierValue == false ||
      (!optionalityValue && supplierValue == true &&
        treasuryValue == false && (othersValue == false || othersValue == false)) ||
      (optionalityValue && supplierValue == true &&
      treasuryValue == false && (othersValue == false || othersValue == false))) {
        setSupplierTargetsValue(false);
        setSupplierOptionalityValue(false);
        setDisableSupplierTarget(true);
        setDisableSupplierOptionality(true);
      }
    }

    if (cascadeSelValue == false) {
      if (treasuryValue == false ||
      (!optionalityValue && supplierValue == false &&
        treasuryValue == true && (othersValue == false || othersValue == false)) ||
      (optionalityValue && supplierValue == false &&
        treasuryValue == true && (othersValue == false || othersValue == false))) {
        setTreasuryTargetsValue(false);
        setTreasuryOptionalityValue(false);
        setDisableTreasuryTarget(true);
        setDisableTreasuryOptionality(true);
      }
    }
  }, [supplierValue, optionalityValue, treasuryValue, othersValue]);

  useEffect(() => {
    setDisableSupplierOptionality(false);
    if (supplierTargetsValue == false) {
      setSupplierOptionalityValue(false);
      setDisableSupplierOptionality(true);
    }
  }, [supplierTargetsValue]);

  useEffect(() => {
    setDisableTreasuryOptionality(false);
    if (treasuryTargetsValue == false) {
      setTreasuryOptionalityValue(false);
      setDisableTreasuryOptionality(true);
    }
  }, [treasuryTargetsValue]);

  useEffect(()=>{
    if (sectorId) {
      const countriesList = [];
      ConfigurationServices.getSectorCountries(sectorId).then((res)=>{
        res.forEach((element) => {
          element.is_present === 'Y'? countriesList.push(element):'';
        });
        setMappedRegionsList(countriesList);
      }).catch((e) => {
        // console.log([e]);
      })
      ;
    }
  }, [sectorId]);

  const saveTheRow = () =>{
    const buyPlanMaps =
    sectorFlag && countryFlag?
    [{
      sectorId: sectorId,
      regionId: contrId,
      commodityGroupId: categoryId,
      sectorGroupId: MastervalueSector,
      commodityId: commodityId,
      subCommodityId: subcommodityId,
    }]:
    sectorCountryMaps.map((item) => {
      return {
        sectorId: item.sectorId,
        regionId: item.regionId,
        commodityGroupId: categoryId,
        sectorGroupId: MastervalueSector,
        commodityId: commodityId,
        subCommodityId: subcommodityId,
      };
    });

    const atlasConfig = {
      buyPlanId: 0,
      atlasFxConfigId: 0,
      inLegalEntity: oLegalEntityValue,
      inProgramName: oProgramNameValue,
      inCurrencyId: oCurrencyValue,
      inUnitId: oMeasurementUnitValue,
      inCovAdjustment: oExposureMonthDifferenceValue,
      inCommSubType: oCommoditySubtypeValue,
      outSector: oSectorValue,
      outRepRegion: oReportingRegionValue,
      outPlaybook: oPlayBookValue,
      oCommodity: oCommodityValue,
      oEntity: oFullEntityNameValue,
      oChathamEntity: oChathamFolderEntityValue,
      oForecastPgm: oForecastProgramNameValue,
    };

    newRowUpdate( {
      'active': true,
      'activeStartDate': activeStartDateValue,
      'activeEndDate': activeEndDateValue,
      'atlasfx': isAtlasfxValue,
      'atlasConfig': atlasConfig,
      'buyplanId': 0,
      'buyplanName': buyplanNameValue,
      'buyPlanAdminPersonaId': AdminPers,
      'buyPlanAdminUserId': AdminUID,
      'buyPlanApproverUserId': approverUID,
      'buyPlanApproverPersonaId': buyApprpers,
      'buyPlanCreatorUserId': creatorUID,
      'buyPlanCreatorPersonaId': buycreatPer,
      'buyPlanViewerUserId': viewerUId,
      'buyPlanMaps': buyPlanMaps,
      'buyPlanOwnerUserId': ownerUID?ownerUID:null,
      'buyPlanOwnerPersonaId': buyPlanOwnerPersonaId?buyPlanOwnerPersonaId:null,
      'buyPlanTargetApplicable': true,
      'carryVolEnabled': isCarryVolume,
      'cascadeTargetBP': '',
      'commodityId': commodityId,
      'commodityGroupId': categoryId,
      'coverageMode': 'BOTH',
      'currencyId': LocalCurrency,
      'currentStatus': currentStatusValue,
      'forecastCreatorUserId': forecastUID,
      'forecastCreatorPersonaId': forecastPer,
      'futureStatus': futureStatusBackendValue,
      'futureStartDate': futureStartDateValue!=''? futureStartDateValue:dayjs(defaultFutureStartDate).format('YYYY-MM'),
      'futureEndDate': futureEndDateValue!=''? futureEndDateValue:dayjs(defaultFutureEndDate).format('YYYY-MM'),
      'measurementUnitId': Localunit,
      'optionality': optionalityValue,
      'others': othersValue,
      'playbookId': playbookIdValue,
      'playbookName': playbookNameValue,
      'regionId': contrId,
      'sectorId': sectorId,
      'sectorGroupId': MastervalueSector,
      'standardCurrencyId': standardCurrencyIdValue,
      'decimalScale': roundingNumber,
      'standardUom': standardUnitIdValue,
      'subCommodityId': subcommodityId,
      'supplier': supplierValue || ((cascadeSelValue && supplierTargetsValue )),
      'supplierOptionality': supplierOptionalityValue === '' ? false : supplierOptionalityValue,
      'supplierTargets': supplierTargetsValue,
      'targetCoverageMode': 'BOTH',
      'treasury': treasuryValue || ((cascadeSelValue && treasuryTargetsValue )),
      'treasuryOptionality': treasuryOptionalityValue === '' ? false : treasuryOptionalityValue,
      'treasuryTargets': treasuryTargetsValue,
    }).then((res)=>{
      if (res && res.userMessage) {
        setIsErrorPopupVisible(true);
        setErrMsg(res.userMessage);
        setAddingTrigger(!addingTrigger);
      } else if (res) {
        handleClose();
        setAddingTrigger(!addingTrigger);
        clearNewRow();
      }
    })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('An error occurred');
          setAddingTrigger(!addingTrigger);
        });
  };

  const clearAtlasFxData = () => {
    setOLegalEntityValue(null);
    setOProgramNameValue(null);
    setOCommoditySubtypeValue(null);
    setOCurrencyValue(null);
    setOMeasurementUnitValue(null);
    setOExposureMonthDifferenceValue(0);
    setOFullEntityNameValue(null);
    setOForecastProgramNameValue(null);
    setOChathamFolderEntityValue(null);
    setOPlayBookValue(null);
    setOCommodityValue(null);
    setOSectorValue(null);
    setOReportingRegionValue(null);
  };

  useEffect(()=>{
    !isAtlasfxValue && clearAtlasFxData();
  }, [isAtlasfxValue]);

  const clearNewRow = () => {
    setMappedRegionsList([]);
    setActiveStartDateValue(null);
    setActiveEndDateValue(null);
    setFutureStartDateValue('');
    setFutureEndDateValue('');
    setFutureStatusBackendValue('');
    setFutureStatusValue('');
    setSelectedFutureStartDate('');
    setSelectedFutureEndDate('');
    setAdminPers(null);
    setAdminUID(null);
    setApproverUID(null);
    setBuyAppPers(null);
    setbuycreatPer(null);
    setforecastPer(null);
    setBuyPlanAdminValue('');
    setBuyPlanApproverValue('');
    setBuyPlanCreatorValue('');
    setForecastCreatorValue('');
    setBuyPlanViewerValue('');
    setPlaybookIdValue('');
    setBuyplanNameValue(null);
    clearAtlasFxData();
    setStandardUnitIdValue('');
    setStandardCurrencyIdValue('');
    setRoundingNumber('');
    setBuyPlanOwnerValue('');
    setcategoryId('');
    setCategoryValue('');
    setCascadeSelValue(false);
    setCreatorUId(null);
    setForecastUId(null);
    setViewerUId(null);
    setcommodityId('');
    setCommodityValue('');
    setcontrId('');
    setCountryValue('');
    setCurrentStatusValue(null);
    setIsAtlasfxValue(false);
    setIsCarryVolume(false);
    setLocalCurrency('');
    setLocalunit('');
    setMasterSectorValue('');
    setMasterValueSector('');
    setOthersValue(false);
    setOptionalityValue('');
    setPlaybookIdValue('');
    setPlayBookNameValue('');
    setsectorId('');
    setSectorValue('');
    setsubcommodityId('');
    setSubcommodityValue('');
    setSupplierTargetsValue('');
    setSupplierValue(false);
    setSupplierOptionalityValue('');
    setTreasuryTargetsValue('');
    setTreasuryValue(false);
    setTreasuryOptionalityValue('');
    setSectorCountryMaps([]);
    setSectorFlag(false);
    setCountryFlag(false);
  };

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setSortType(''));
  }, [dispatch]);


  useEffect(()=>{
    const shouldEnable = playbookIdValue!='' &&
    buyplanNameValue!='' &&
    (sectorCountryMaps.length !== 0 || (sectorFlag && countryFlag)) &&
    subcommodityValue!='' &&
    Localunit!='' &&
    LocalCurrency!='' &&
    roundingNumber!=='' &&
    buyPlanApproverValue!='' &&
    buyPlanApproverValue!=null &&
    buyPlanCreatorValue!='' &&
    buyPlanCreatorValue!=null &&
    buyPlanAdminValue!='' &&
    buyPlanAdminValue!=null &&
    futureStatusBackendValue!='' &&
    setFutureStatusValue!='' &&
    (optionalityValue===true || optionalityValue===false) &&
    (supplierTargetsValue===true || supplierTargetsValue===false) &&
    (supplierOptionalityValue === true || supplierOptionalityValue === false) &&
    (treasuryTargetsValue=== true || treasuryTargetsValue=== false) &&
    (treasuryOptionalityValue === true || treasuryOptionalityValue === false) &&
    (supplierValue===true || supplierValue===false) &&
    (treasuryValue===true || treasuryValue===false) &&
    (othersValue===true || othersValue===false) &&
    (isCarryVolume===true || isCarryVolume===false) &&
    ((isAtlasfxValue=== true &&
      Boolean(oLegalEntityValue &&
      oProgramNameValue &&
      oCommoditySubtypeValue &&
      oCurrencyValue &&
      oMeasurementUnitValue &&
      oFullEntityNameValue &&
      oForecastProgramNameValue &&
      oChathamFolderEntityValue &&
      oPlayBookValue &&
      oCommodityValue &&
      oSectorValue &&
      oReportingRegionValue ))|| isAtlasfxValue===false);
    setEnableSaveButton(shouldEnable);
  }, [playbookIdValue,
    buyplanNameValue,
    sectorCountryMaps,
    subcommodityValue,
    roundingNumber,
    Localunit,
    LocalCurrency,
    buyPlanApproverValue,
    buyPlanCreatorValue,
    buyPlanAdminValue,
    optionalityValue,
    supplierTargetsValue,
    supplierOptionalityValue,
    treasuryTargetsValue,
    treasuryOptionalityValue,
    supplierValue,
    treasuryValue,
    othersValue,
    isAtlasfxValue,
    isCarryVolume,
    sectorFlag,
    countryFlag,
    futureStatusBackendValue,
    oLegalEntityValue,
    oProgramNameValue,
    oCommoditySubtypeValue,
    oCurrencyValue,
    oMeasurementUnitValue,
    oFullEntityNameValue,
    oForecastProgramNameValue,
    oChathamFolderEntityValue,
    oPlayBookValue,
    oCommodityValue,
    oSectorValue,
    oReportingRegionValue,
  ]);

  const mapFutureStatusToBackendFormat = (selectedValue) => {
    switch (selectedValue) {
      case 'Active':
        return 'ACTIVE';
      case 'Inactive':
        return 'INACTIVE';
      case 'Coverage Suspension':
        return 'COV_SUSPENDED';
      case 'Coverage Review Suspension':
        return 'REVIEW_SUSPENDED';
      default:
        return '';
    }
  };

  const handleFutureStartDateChange = (date) => {
    setSelectedFutureStartDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureStartDateValue(d);
  };

  const handleFutureEndDateChange = (date) => {
    setSelectedFutureEndDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureEndDateValue(d);
  };
  return (
    <Box sx={{width: '100%', padding: '0px 18px'}}>

      <Dialog
        open={popup}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{height: '80vh', overflowY: 'auto'}}>
          <Box sx={{width: '100%'}}>
            <Box
              mb={3}
              sx={{
                font: 'normal normal 700 15px/21px \'Segoe UI\' ',
                color: '#0F5DAA',
              }}
              style={customealltheme.palette.filterheading}
            >
                 Add Buy Plan
            </Box>
            <Box mb={3}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         PlayBook Name*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={playbookIdValue}
                          onChange={(e) => setPlaybookIdValue(e.target.value)}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {Playbooks.length !== 0 &&
                                _.orderBy(Playbooks, [(p) => p.playbook_name?.toLowerCase()]) ?.map((obj) => (
                                  <MenuItem
                                    sx={customTheme.selectMenuBox}
                                    key={obj?.playbook_id}
                                    value={obj?.playbook_id}
                                    className="this-is-new-class"
                                    onClick={() => handlePlaybooktemClick(obj)}
                                  >
                                    {obj.playbook_name}
                                  </MenuItem>
                                ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Buy Plan Name*
                      </Box>
                    </Grid>
                    <Grid item>
                      <TextareaAutosize
                        style={textbox}
                        placeholder="Type in here…"
                        value={buyplanNameValue || ''}
                        minRows={1}
                        maxRows={4}
                        onChange={(e) => {
                          setBuyplanNameValue(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Master Sector
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={dropdown}>{masterSectorValue}</Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Sector*
                      </Box>
                    </Grid>
                    <Grid item>
                      {!sectorFlag?
                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={sectorValue}
                          onChange={(e)=>{
                            const selectedSector = e.target.value;
                            setSectorValue(selectedSector);
                            {ActiveSectors.length !== 0 &&
                                ActiveSectors?.map((obj) =>
                                  obj.name === masterSectorValue &&
                                    obj.sectors.map((r) =>
                                        r.name === selectedSector ? setsectorId(r.id) : null,
                                    ),
                                );
                            }
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {ActiveSectors.length !== 0 &&
                            ActiveSectors?.map((obj) => (
                              obj.name === masterSectorValue &&
                             obj.sectors.map((r)=>(
                               r.active===true &&
                               <MenuItem
                                 sx={customTheme.selectMenuBox}
                                 key={r?.id}
                                 value={r?.name}
                                 className="this-is-new-class"
                               >
                                 {r.name}
                               </MenuItem>
                             ))

                            ))}
                        </Select>
                      </FormControl>:
                      <Box sx={dropdown} style={{marginBottom: '22px'}}>
                        {sectorValue}
                      </Box>}
                    </Grid>
                    <Grid item>
                      {sectorCountryMaps.map((Item) => (
                        <Grid container alignItems="center" key={Item.id}>
                          <Grid item sx={{'paddingTop': '4px', 'paddingBottom': '4px'}}>
                            {Item.sectorName}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Country*
                      </Box>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" >
                        <Grid item xs={8}>
                          {!countryFlag?
                          <FormControl sx={dropdown}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              sx={customTheme.selectBox}
                              value={countryValue}
                              onChange={(e)=>{
                                const selectedCountry = e.target.value;
                                setCountryValue(selectedCountry);
                                country.forEach((obj) => {
                                  if (obj.region === selectedCountry) {
                                    setcontrId(obj.region_id);
                                  }
                                });
                              }}
                              label="Select"
                              input={<CustomInput label="Tag" />}
                              displayEmpty
                              className="newclass"
                            >
                              <MenuItem value="" sx={customTheme.selectMenuBox}>
                                <span>Select</span>
                              </MenuItem>
                              {(mappedRegionsList?.length !==0 ) && sectorValue &&
                              _.orderBy(mappedRegionsList, [(s) => s.region?.toLowerCase()])?.map((r, ind)=>(
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.region_id || ''}
                                  value={r?.region || ''}
                                  className="this-is-new-class"
                                >
                                  {r.region}
                                </MenuItem>

                              ))}
                            </Select>
                          </FormControl>:
                          <Box sx={dropdown} style={{marginBottom: '22px'}}>
                            {countryValue}
                          </Box>}

                        </Grid>
                        <Grid item xs={4}>
                          {!(sectorFlag && countryFlag)?
                          <Button
                            sx={{'textTransform': 'none', 'backgroundColor': '#4297D3', '&:hover': {backgroundColor: '#6D7888'}, 'color': '#ffffff', 'width': '80px', 'height': '50px', 'fontSize': '10px', 'marginLeft': '10px'}}
                            onClick={handleAddSectorCountry}
                          >
                               Add Sector Country
                          </Button>:''}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {sectorCountryMaps.map((Item) => (
                        <Grid container alignItems="center" key={Item.id} >
                          <Grid item xs={6} sx={{'padding': '4px'}}>
                            {Item.regionName}
                          </Grid>
                          <Grid item >
                            <IconButton onClick={() => handleSectorCountryDelete(Item)} sx={{'padding': '0px', 'margin': '0px'}}>
                              <CloseIcon style={{fontSize: '12px'}}/>
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={2.4}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Category
                      </Box>
                    </Grid>
                    <Grid item>

                      <Box sx={dropdown}>
                        {categoryValue}
                      </Box>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2.4}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Commodity
                      </Box>
                    </Grid>
                    <Grid item>

                      <Box sx={dropdown}>
                        {commodityValue}
                      </Box>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2.4}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Sub Commodity*
                      </Box>
                    </Grid>
                    <Grid item>

                      {showSubCommodityDropdown ?
                          <Box sx={dropdown}>{subcommodityValue}</Box>:
                          <FormControl sx={dropdown}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              sx={customTheme.selectBox}
                              value={subcommodityValue}
                              onChange={(e)=>{
                                const selectedSubCommodity = e.target.value;
                                setSubcommodityValue(selectedSubCommodity);
                                {ActiveSubCommodities.length !== 0 &&
                                ActiveSubCommodities?.map((obj) =>
                                  obj.name === commodityValue &&
                                    obj.subCommodities
                                        .map((r) =>
                                        r.name === selectedSubCommodity ? setsubcommodityId(r.id) : null,
                                        ),
                                );
                                }
                              }}
                              label="Select"
                              input={<CustomInput label="Tag" />}
                              displayEmpty
                              className="newclass"
                            >
                              <MenuItem value="" sx={customTheme.selectMenuBox}>
                                <span>Select</span>
                              </MenuItem>
                              {ActiveSubCommodities.length !== 0 && commodityValue!=='' &&
                                  ActiveSubCommodities?.map((r) => (
                                    r.name===commodityValue && r.subCommodities
                                        .map((obj)=>obj.is_active === true &&
                                          <MenuItem
                                            sx={customTheme.selectMenuBox}
                                            key={obj?.id}
                                            value={obj?.name}
                                            className="this-is-new-class"
                                          >
                                            {obj.name}
                                          </MenuItem>,
                                        )
                                  ))}
                            </Select>
                          </FormControl>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider color='#4297D3'/>
            <Box mb={2} mt={2}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Local UoM*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={Localunit}
                          onChange={(e)=>{
                            setLocalunit(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          {Units.length !== 0 &&
                            _.orderBy(Units, [(p) => p?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.unit_id}
                                value={r?.unit_id}
                                className="this-is-new-class"
                              >
                                {r.unit_description}
                              </MenuItem>


                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Standard UoM
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={standardUnitIdValue}
                          onChange={(e)=>{
                            setStandardUnitIdValue(e.target.value);
                          }}
                          label= {standardUnitIdValue}
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          {Units.length !== 0 &&
                              _.orderBy(Units, [(u) => u?.unit_description?.toLowerCase()?.trim()])?.map((r) => (


                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.unit_id}
                                  value={r?.unit_id}
                                  className="this-is-new-class"
                                >
                                  {r.unit_description}
                                </MenuItem>


                              ))}
                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Local Currency*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={LocalCurrency}
                          onChange={(e)=>{
                            setLocalCurrency(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {Currency.length !== 0 &&
                            _.orderBy(Currency, [(p) => p.currency_description?.toLowerCase()])?.map((r) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.currency_id}
                                value={r?.currency_id}
                                className="this-is-new-class"
                              >
                                {r.currency_description}
                              </MenuItem>


                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Standard Currency
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={standardCurrencyIdValue}
                          onChange={(e)=>{
                            setStandardCurrencyIdValue(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          {Currency.length !== 0 &&
                              _.orderBy(Currency, [(cur) => cur?.currency_description?.toLowerCase()])?.map((r) => (


                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.currency_id}
                                  value={r?.currency_id}
                                  className="this-is-new-class"
                                >
                                  {r.currency_description}
                                </MenuItem>


                              ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Rounding Number*
                      </Box>
                    </Grid>
                    <Grid item>
                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={roundingNumber}
                          onChange={(e) => {
                            setRoundingNumber(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          {[
                            {label: 'Round to 2 decimal places', val: 2},
                            {label: 'Nearest whole number', val: 0},
                          ].map((obj, index) => (
                            <MenuItem
                              sx={customTheme.selectMenuBox}
                              key={index}
                              value={obj.val}
                              className="this-is-new-class"
                            >
                              {obj.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider color='#4297D3'/>
            <Box mb={2} mt={2}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Buy Plan Owner
                      </Box>
                    </Grid>
                    <Grid item>

                      <Box sx={dropdown}>
                        {buyPlanOwnerValue}
                      </Box>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Buy Plan Creator*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={buyPlanCreatorValue}
                          onChange={(e)=>{
                            setCreatorUId(e.target.value);
                            setBuyPlanCreatorValue(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty

                          className="newclass"
                        >
                          {_.orderBy(buyplanPersona['buyplan_creator'] ?.filter(
                              (obj) => obj.userName.trim().toLowerCase() !== buyplanApprover.trim().toLowerCase(),
                          ), [(b) => b.userName?.toLowerCase()])
                              .map((obj, index) => (
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={obj.userId}
                                  value={obj.userId}
                                  onClick={()=>{
                                    setbuycreatPer(obj.personaId);
                                    setBuyplanCreator(obj.userName);
                                  }}
                                  className="this-is-new-class"
                                >
                                  {obj.userName}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Buy Plan Approver*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={buyPlanApproverValue}
                          onChange={(e)=>{
                            setApproverUID(e.target.value);
                            setBuyPlanApproverValue(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty

                          className="newclass"
                        >
                          { _.orderBy(buyplanPersona['buyplan_approver'] ?.filter(
                              (obj) => obj.userName.trim().toLowerCase()!== buyplanCreator.trim().toLowerCase(),
                          ), [(b) => b.userName?.toLowerCase()])
                              .map((obj, index) => (
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={obj.userId}
                                  value={obj.userId}
                                  onClick={()=>{
                                    setBuyAppPers(obj.personaId);
                                    setBuyplanApprover(obj.userName);
                                  }}
                                  className="this-is-new-class"
                                >
                                  {obj.userName}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Buy Plan Admin*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={buyPlanAdminValue}
                          onChange={(e)=>{
                            setAdminUID(e.target.value);
                            setBuyPlanAdminValue(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty

                          className="newclass"
                        >
                          { _.orderBy(buyplanPersona['buyplan_admin'], [(b) => b.userName?.toLowerCase()])?.map((obj, index) => (
                            <MenuItem
                              sx={customTheme.selectMenuBox}
                              key={obj.userId}
                              value={obj.userId}
                              onClick={()=>{
                                setAdminPers(obj.personaId);
                              }}
                              className="this-is-new-class"
                            >
                              {obj.userName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Forecast Creator
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={forecastCreatorValue}
                          onChange={(e)=>{
                            setForecastUId(e.target.value);
                            setForecastCreatorValue(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty

                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {_.orderBy(buyplanPersona['forecast_creator'], [(b) => b.userName?.toLowerCase()]).map((obj, index) => (
                            <MenuItem
                              sx={customTheme.selectMenuBox}
                              key={obj.userId}
                              value={obj.userId}
                              onClick={()=>{
                                setforecastPer(obj.personaId);
                              }}
                              className="this-is-new-class"
                            >
                              {obj.userName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2} mt={2}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Buy Plan Viewer
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={buyPlanViewerValue}
                          onChange={(e)=>{
                            setViewerUId(e.target.value);
                            setBuyPlanViewerValue(e.target.value);
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty

                          className="newclass"
                        >
                          <MenuItem value="" sx={customTheme.selectMenuBox}>
                            <span>Select</span>
                          </MenuItem>
                          {_.orderBy(buyplanPersona['buyplan_viewer'], [(b) => b.userName?.toLowerCase()]).map((obj, index) => (
                            <MenuItem
                              sx={customTheme.selectMenuBox}
                              key={obj.userId}
                              value={obj.userId}
                              className="this-is-new-class"
                            >
                              {obj.userName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider color='#4297D3'/>
            <Box mb={2} mt={2}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Supplier*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={supplierValue?true:false}
                              onChange={handleSupplierValue}
                              disabled={(cascadeSelValue === true && supplierTargetsValue === true )?true:false}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Treasury*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={(treasuryValue || '')?true:false}
                              onChange={handleTreasuryValue}
                              disabled= {(cascadeSelValue === true && treasuryTargetsValue === true )?true:false}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Others*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={(othersValue || '')?true:false}
                              onChange={handleOthersValue}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Send forecast to AtlasFX*
                      </Box>
                    </Grid>
                    <Grid item >

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={(isAtlasfxValue || '')?true:false}
                              onChange={handleIsAtlasfxValue}
                              disabled={supplierValue && !treasuryValue}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Carry Volume*
                      </Box>
                    </Grid>
                    <Grid item >

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={(isCarryVolume || '')?true:false}
                              onChange={handleIsCarryVolume}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2} mt={2}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Optionality*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={optionalityValue?true:false}
                              onChange={handleToggleOptionality}
                              disabled={cascadeSelValue===true?true:false}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Supplier Targets*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={supplierTargetsValue?true:false}
                              onChange={handleSupplierTargetsValue}
                              disabled={(disableSupplierTarget || cascadeSelValue)===true?true:false }
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Supplier Optionality*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={supplierOptionalityValue?true:false}
                              onChange={handleSupplierOptionalityValue}
                              disabled={(disableSupplierOptionality || cascadeSelValue)===true?true:false}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Treasury Targets*
                      </Box>
                    </Grid>
                    <Grid item >

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={treasuryTargetsValue?true:false}
                              onChange={handleTreasuryTargetsValue}
                              disabled={(disableTreasuryTarget || cascadeSelValue)===true?true:false}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={true}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Treasury Optionality*
                      </Box>
                    </Grid>
                    <Grid item >

                      <FormControlLabel
                        control={
                          <ToggleContainer>
                            <ToggleSwitch
                              checked={treasuryOptionalityValue?true:false}
                              onChange={handleTreasuryOptionalityValue}
                              disabled={(disableTreasuryOptionality || cascadeSelValue)===true?true:false}
                            />
                            <span className="switchLabel labelYes">Yes</span>
                            <span className="switchLabel labelNo">No</span>
                          </ToggleContainer>
                        }
                      />


                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider color='#4297D3'/>
            {isAtlasfxValue &&
            <>
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  p: {
                    display: 'inline-block',
                    marginRight: '2px',
                    font: 'normal normal 600 13px/15px \'Segoe UI\'',
                    color: '#3174b6',
                    cursor: 'pointer',
                  },
                }}
              >
                <p>Outbound Details (Compass → AtlasFX)</p>

              </Box><Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <BuyPlanTextBox heading={'Sector*'} value={oSectorValue} setValue={setOSectorValue} dropdownSpan={dropdownSpan}/>
                  <BuyPlanTextBox heading={'Reporting Region*'} value={oReportingRegionValue} setValue={setOReportingRegionValue} dropdownSpan={dropdownSpan}/>
                  <BuyPlanTextBox heading={'PlayBook*'} value={oPlayBookValue} setValue={setOPlayBookValue} dropdownSpan={dropdownSpan}/>
                  <BuyPlanTextBox heading={'Commodity*'} value={oCommodityValue} setValue={setOCommodityValue} dropdownSpan={dropdownSpan}/>
                  <BuyPlanTextBox heading={'Full Entity Name*'} value={oFullEntityNameValue} setValue={setOFullEntityNameValue} dropdownSpan={dropdownSpan}/>
                </Grid>
              </Box><Box mb={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <BuyPlanTextBox heading={'Chatham Folder Entity*'} value={oChathamFolderEntityValue} setValue={setOChathamFolderEntityValue} dropdownSpan={dropdownSpan}/>
                  <BuyPlanTextBox heading={'Forecast Program Name*'} value={oForecastProgramNameValue} setValue={setOForecastProgramNameValue} dropdownSpan={dropdownSpan}/>
                </Grid>
              </Box>
              <Divider color='#4297D3' />
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  p: {
                    display: 'inline-block',
                    marginRight: '2px',
                    font: 'normal normal 600 13px/15px \'Segoe UI\'',
                    color: '#3174b6',
                    cursor: 'pointer',
                  },
                }}
              >
                <p>Inbound Details (AtlasFX → Compass )</p>

              </Box>
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <BuyPlanTextBox heading={'Legal Entity*'} value={oLegalEntityValue} setValue={setOLegalEntityValue} dropdownSpan={dropdownSpan}/>
                  <BuyPlanTextBox heading={'Program Name*'} value={oProgramNameValue} setValue={setOProgramNameValue} dropdownSpan={dropdownSpan}/>
                  <BuyPlanTextBox heading={'Commodity Subtype*'} value={oCommoditySubtypeValue} setValue={setOCommoditySubtypeValue} dropdownSpan={dropdownSpan}/>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                        Currency*
                        </Box>
                      </Grid>
                      <Grid item>

                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={oCurrencyValue}
                            onChange={(e)=>{
                              setOCurrencyValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Currency.length !== 0 &&
                            _.orderBy(Currency, [(p) => p.currency_description?.toLowerCase()])?.map((r) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.currency_id}
                                value={r?.currency_id}
                                className="this-is-new-class"
                              >
                                {r.currency_description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Measurement Unit*
                        </Box>
                      </Grid>
                      <Grid item>

                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={oMeasurementUnitValue}
                            onChange={(e)=>{
                              setOMeasurementUnitValue(e.target.value);
                            }}
                            label= {oMeasurementUnitValue}
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Units.length !== 0 &&
                              _.orderBy(Units, [(u) => u?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.unit_id}
                                  value={r?.unit_id}
                                  className="this-is-new-class"
                                >
                                  {r.unit_description}
                                </MenuItem>


                              ))}
                          </Select>
                        </FormControl>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                           Exposure Month Difference*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={oExposureMonthDifferenceValue}
                            onChange={(e)=>{
                              setOExposureMonthDifferenceValue(e.target.value);
                            }}
                            label= {oExposureMonthDifferenceValue}
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                            IconComponent={() => null}
                            endAdornment={
                              <InputAdornment position="end" sx={iconColumn}>
                                <IconButton onClick={handleIncrement} sx={arrowIcon}>
                                  <ArrowDropUpIcon />
                                </IconButton>
                                <IconButton onClick={handleDecrement} sx={arrowIcon}>
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                          >
                            {numberOptions.length !== 0 &&
                              numberOptions?.map((val) => (
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={val}
                                  value={val}
                                  className="this-is-new-class"
                                >
                                  {val}
                                </MenuItem>
                              ))}
                          </Select>

                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider color='#4297D3'/>
            </>}
            <Box mb={2} mt={2}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={2.4}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Future Status*
                      </Box>
                    </Grid>
                    <Grid item>

                      <FormControl sx={dropdown}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          sx={customTheme.selectBox}
                          value={futureStatusValue}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setFutureStatusValue(selectedValue);
                            setFutureStatusBackendValue(mapFutureStatusToBackendFormat(selectedValue));
                          }}
                          label="Select"
                          input={<CustomInput label="Tag" />}
                          displayEmpty
                          className="newclass"
                        >
                          { ['Active', 'Inactive', 'Coverage Suspension', 'Coverage Review Suspension']?.map((obj, index) => (
                            <MenuItem
                              sx={customTheme.selectMenuBox}
                              key={index}
                              value={obj}
                              className="this-is-new-class"
                            >
                              {obj}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2.4}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                         Future Status Start Month
                      </Box>
                    </Grid>
                    <Grid item>

                      <Box sx={dropdown}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={['month', 'year']}
                            disablePast={true}
                            value={selectedFutureStartDate || defaultFutureStartDate}
                            onChange={handleFutureStartDateChange}
                            format={'MMM-YYYY'}
                            minDate={currentDate}
                            renderInput={(params) => <input {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>


                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2.4}>
                  <Grid container direction="column" >
                    <Grid item>
                      <Box sx={dropdownSpan} component="span">
                          Future Status End Month
                      </Box>
                    </Grid>
                    <Grid item>

                      <Box sx={dropdown}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={['month', 'year']}
                            disablePast={true}
                            format={'MMM-YYYY'}
                            value={selectedFutureEndDate || defaultFutureEndDate}
                            onChange={handleFutureEndDateChange}
                            minDate={selectedFutureStartDate}
                            renderInput={(params) => <input {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>


                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
              <AppButton label="Save" onClick={() => saveTheRow()} disabled={!enableSaveButton===true?true:false}/>
              <AppButton label="Cancel" onClick={() => handleClose()} />
            </Box>
          </Box>


        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default AddConfTable;
