/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* used to filter the data either buy buyplan or plabook just to load the data of one buyplan at once
  so first we filter and then get the data
*/
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, FormControl} from '@mui/material';
import MultiSelectDropDown from '../../Components/common/MultiSelectDropdown';
import Styles from '../../styles/target';
import {getAllTargetNames} from '../../redux/target/index';
// eslint-disable-next-line camelcase
import {getDropDownData_API} from '../../redux/features/apiCall';
import {useQuery} from '../../hooks/useQuery';
import {useHistory, useLocation} from 'react-router-dom/cjs/react-router-dom.min';
// import {changeRoleHandler} from '../../redux/RoleBasedAccess';

const TargetFilter = ({isPlayBook, onBuyplanToggle, callTargetPlayBookData, callTargetBuyPlanData, handleClearFilter}) => {
  const [selectedSector, setSelectedSector] = useState([]);
  const [selectedPlayBook, setSelectedPlayBook] = useState('');
  const [selectedBuyPlan, setSelectedBuyPlan] = useState('');
  const [allTargetNames, setAllTargetNames] = useState([]);
  const [sectorItem, setSectorItem]=useState([]);
  const [playBookDropDownItem, setPlayBookDropDownItem]=useState([]);
  const [buyPlanDropDownItem, setBuyPlanDropDownItem]=useState([]);
  const [isAllowedToAutoLoadViaURL, setAllowedToAutoLoadViaURL]=useState(false);

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const TargetNames = useSelector((state)=>state?.targetApi?.allTargetNames);
  const enableSaveButton = (selectedPlayBook > 0 && selectedSector?.length > 0 && (isPlayBook || selectedBuyPlan > 0));

  // const navData = useSelector((state)=>state.navData);
  const query = useQuery();

  const selectedSectorGroupName = query.get('sector-group');
  const selectedPlaybookName = query.get('playbook-name');
  const selectedBuyplanName = query.get('buyplan-name');
  const selectedRoleId = query.get('role');

  const isNavigatedPage = !!(selectedSectorGroupName || selectedPlaybookName || selectedBuyplanName || selectedRoleId);

  // let selectedSectorGroupName;
  // let selectedPlaybookName;
  // const selectedRoleId = history.location.pathname.split('/')[2];
  // if (!isNaN(selectedRoleId)) {
  //   selectedSectorGroupName = history.location.pathname.split('/')[3];
  //   selectedPlaybookName = history.location.pathname.split('/')[4];
  // }
  const [flag, setFlag] = useState(0);

  useEffect(() => {
    dispatch(getDropDownData_API());
  }, []);

  const allSectors = useSelector(
      (state) => state?.api?.apiValueDropdownData,
  );

  // Select SECTOR based on the sector group name from URL
  useEffect(() => {
    if (selectedSectorGroupName && selectedPlaybookName && sectorItem.length >0 && allSectors?.sectorGroups?.length >0) {
      const selectedSectorGroup = allSectors.sectorGroups.find((sectorGroup)=> sectorGroup.sectors.some((sector)=> sector.name === selectedSectorGroupName));
      const sData = sectorItem.find((item)=> item.label.toLowerCase() === selectedSectorGroup.name.toLowerCase());
      if (sData?.value) {
        setFlag(1);
        setSelectedSector([sData.value]);
        if (selectedBuyplanName) {
          onBuyplanToggle();
        }
      };
    }
  }, [sectorItem, allSectors]);

  // Select PLAYBOOK NAME based on the sector group name from URL
  useEffect(() => {
    if (selectedSectorGroupName && selectedPlaybookName && selectedSector && playBookDropDownItem.length > 0 && selectedSector.length > 0 && allSectors?.sectorGroups?.length >0 ) {
      const sData = playBookDropDownItem.find((item)=> item.name.toLowerCase() === selectedPlaybookName.toLowerCase());
      if (sData?.id) {
        setFlag(2);
        setSelectedPlayBook(Number(sData.id));
      };
    }
  }, [playBookDropDownItem, selectedSector]);

  // Select BUYPLAN NAME based on the sector group name from URL
  // Incomplete
  useEffect(() => {
    if (selectedSectorGroupName && selectedPlaybookName&& selectedBuyplanName && selectedSector && buyPlanDropDownItem.length > 0 && selectedSector.length > 0 && allSectors?.sectorGroups?.length >0 ) {
      const sData = buyPlanDropDownItem.find((item)=> item.name.toLowerCase() === selectedBuyplanName.toLowerCase());
      if (sData?.id) {
        setSelectedBuyPlan(Number(sData.id));
        setFlag(3);
      }
    }
  }, [buyPlanDropDownItem, selectedSector]);


  useEffect(() => {
    if (selectedSectorGroupName && selectedPlaybookName && selectedSector && selectedPlayBook && allSectors?.sectorGroups?.length >0 ) {
      if (!selectedBuyplanName && flag === 2) {
        handleSubmit();
      }
      if (selectedBuyplanName && flag === 3) {
        handleSubmit();
      }
    }
  }, [selectedPlayBook, selectedBuyPlan]);

  useEffect(()=>{
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params && params.size>=2) { // atleast 2 parameters
      setAllowedToAutoLoadViaURL(true);
    }
  }, []);
  useEffect(()=>{
    if (isAllowedToAutoLoadViaURL ===true && sectorItem && sectorItem.length>0) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      if (params && params.size>=2) { // atleast 2 parameters
        const sector = parseInt(params.get('sector'));
        const playbookid = parseInt(params.get('pb'));
        const buyplanid = parseInt(params.get('bp'));
        if (sector) {
          handleChangeSector([sector]);
        }
        if (playbookid) {
          handleChangePlayBook(playbookid);
        }
        if (buyplanid) {
          handleChangeBuyPlan(buyplanid);
        }
      }
    }
  }, [sectorItem, isAllowedToAutoLoadViaURL]);

  useEffect(() => {
    if (TargetNames && TargetNames.length>0) {
      setAllTargetNames(TargetNames);
      const sectors=[];
      TargetNames.forEach((sector)=>{
        sectors.push({
          label: sector.sector_group_name,
          value: sector.sector_group_id,
        });
      });
      sectors.sort((a, b) => {
        return a.label?.localeCompare(b.label);
      });
      setSectorItem([{
        label: 'Select All',
        value: 'Select All',
      }, ...sectors]);
    }
  }, [TargetNames]);

  useEffect(()=>{
    if (isPlayBook===true) {
      setSelectedBuyPlan('');
    }
  }, [isPlayBook]);

  useEffect(() => {
    dispatch(getAllTargetNames());
  }, []);

  useEffect(()=>{
    if (isAllowedToAutoLoadViaURL===true && selectedPlayBook && playBookDropDownItem.length>0) {
      if (isPlayBook===true) {
        handleAutoLoadData();
      } else if (selectedBuyPlan && buyPlanDropDownItem.length>0) {
        handleAutoLoadData();
      }
    }
  }, [selectedPlayBook, selectedBuyPlan, playBookDropDownItem, buyPlanDropDownItem, isAllowedToAutoLoadViaURL]);

  // get data for next dropdown filter
  useEffect(()=>{
    if (selectedSector) {
      const dropdownPlayBooks=[];
      allTargetNames.map((sector) => {
        if (selectedSector.includes(sector?.sector_group_id)) {
          sector?.playbooks.map((playbook) =>{
            dropdownPlayBooks.push({id: playbook.playbookId, name: playbook.playbookName});
          });
        }
      });
      setPlayBookDropDownItem(dropdownPlayBooks);
    }
  }, [selectedSector]);

  // get the data for next dropdown
  useEffect(()=>{
    if (selectedPlayBook) {
      const dropdownBuyPlans=[];
      allTargetNames?.map((sector) => {
        if (selectedSector.includes(sector?.sector_group_id)) {
          sector?.playbooks?.map((playbook) =>{
            if (playbook.playbookId === selectedPlayBook) {
              playbook.buyplans.forEach((buyplan)=>{
                dropdownBuyPlans.push({id: buyplan.buyplanId, name: buyplan.buyplanName});
              });
            }
          });
        }
      });
      setBuyPlanDropDownItem(dropdownBuyPlans);
    }
  }, [selectedPlayBook]);

  const handleAutoLoadData = ()=>{
    setAllowedToAutoLoadViaURL(false);
    handleSubmit();
  };

  const handleChangeSector = (value) => {
    if (isNavigatedPage) resetUrl();
    const isSelectAllIncluded=value.includes('Select All');
    const prevSelectAllIncluded= selectedSector.includes('Select All');

    // select all is just selected
    if (isSelectAllIncluded && !prevSelectAllIncluded) {
      const selectSectors=['Select All'];
      allTargetNames.forEach((sector)=>{
        selectSectors.push(sector.sector_group_id);
      });
      setSelectedSector(selectSectors);
      // select all is just removed
    } else if (!isSelectAllIncluded && prevSelectAllIncluded) {
      setSelectedSector([]);
    } else if (isSelectAllIncluded && prevSelectAllIncluded && value.length<selectedSector.length) {
      // remove select all as one of the element is removed
      value.splice(0, 1);
      setSelectedSector(value);
    } else {
      setSelectedSector(value);
    }
    // empty the next filters
    setSelectedPlayBook();
    setSelectedBuyPlan('');
    setBuyPlanDropDownItem([]);
  };
  const handleChangePlayBook = (value) => {
    // const {
    //   target: {value},
    // } = event;
    setSelectedPlayBook(value);

    if (!selectedBuyplanName) setSelectedBuyPlan([]);
  };
  const handleChangeBuyPlan = (value) => {
    setSelectedBuyPlan(value);
  };

  const handleSubmit = () => {
    setFlag(0);
    // get buyplan name and playbook name
    let playbookName=null;
    let buyPlanName=null;
    playBookDropDownItem.forEach((playbook)=>{
      if (playbook?.id===selectedPlayBook) {
        playbookName=playbook?.name;
      }
    });
    buyPlanDropDownItem.forEach((buyplan)=>{
      if (buyplan?.id===selectedBuyPlan) {
        buyPlanName=buyplan?.name;
      }
    });
    if (isPlayBook && selectedPlayBook) {
      callTargetPlayBookData(selectedPlayBook, playbookName, buyPlanName);
    } else if (selectedBuyPlan) {
      callTargetBuyPlanData(selectedBuyPlan, playbookName, buyPlanName);
    }
  };

  const resetUrl = ()=>{
    const currentPath = location.pathname;
    history.replace(currentPath);
  };

  // empty all filters
  const resetFilters = () => {
    setSelectedBuyPlan('');
    setSelectedSector([]);
    setSelectedPlayBook();
    handleClearFilter();
    resetUrl();
    setFlag(0);
  };


  return (
    <><Box
      component="span"
      sx={{
        font: 'normal normal bold 14px/19px Segoe UI',
        color: '#0F5DAA',
      }}
    >
      Target
    </Box><Styles.StyledFilter>
      <Styles.StyledFilterBox>
        <Styles.StyledBoxFilter>
          <FormControl>
            <MultiSelectDropDown
              items={sectorItem}
              label={'Sector'}
              id="sector"
              handleChange={(e) => handleChangeSector(e.target.value)}
              selectedItems={selectedSector}
            />
          </FormControl>
        </Styles.StyledBoxFilter>
        <Styles.StyledBoxFilter>
          <Styles.StyledBoxSelectFilter>
            <Styles.StyledBoxLabel id="demo-simple-select-label">PlayBook Name</Styles.StyledBoxLabel>
            <FormControl>
              <Styles.StyledSelectBuyPlan
                labelId="playbookName-label"
                id="playbookName"
                value={selectedPlayBook}
                label='Select'
                onChange={(e) => handleChangePlayBook(e.target.value)}
                displayEmpty
                renderValue={(selected, displayEmpty) => {
                  let Return = 'Select';
                  if (selected) {
                    playBookDropDownItem.sort((a, b) => a['name'].localeCompare(b['name'])).forEach((playbook) => {
                      if (playbook.id === selected) {
                        Return = playbook.name;
                      }
                    });
                  }
                  return Return;
                } }
              >
                {playBookDropDownItem.sort((a, b) => a['name'].localeCompare(b['name'])).map((playbook) => {
                  return <Styles.StyledMenuItemBuyPlan value={playbook.id} key={playbook.id}>{playbook.name}</Styles.StyledMenuItemBuyPlan>;
                })}
              </Styles.StyledSelectBuyPlan>
            </FormControl>
          </Styles.StyledBoxSelectFilter>
        </Styles.StyledBoxFilter>
        <Styles.StyledBoxFilter>
          <Styles.StyledBoxSelectFilter>
            <Styles.StyledBoxLabel id="demo-simple-select-label">Buy Plan Name</Styles.StyledBoxLabel>
            <FormControl>
              <Styles.StyledSelectBuyPlan
                labelId="buyplanName-label"
                id="buyplanName"
                value={selectedBuyPlan}
                label='BuyPlan Name'
                disabled={isPlayBook}
                displayEmpty
                renderValue={(selected, displayEmpty) => {
                  let Return = 'Select';
                  if (selected) {
                    buyPlanDropDownItem.sort((a, b) => a['name'].localeCompare(b['name'])).forEach((buyplan) => {
                      if (buyplan.id === selected) {
                        Return = buyplan.name.split('_').join(' ');
                      }
                    });
                  }
                  return Return;
                } }
                onChange={(e) => handleChangeBuyPlan(e.target.value)}
              >
                {buyPlanDropDownItem.sort((a, b) => a['name'].localeCompare(b['name'])).map((buyplan) => {
                  return <Styles.StyledMenuItemBuyPlan value={buyplan.id} key={buyplan.id}>{buyplan.name.split('_').join(' ')}</Styles.StyledMenuItemBuyPlan>;
                })}
              </Styles.StyledSelectBuyPlan>
            </FormControl>
          </Styles.StyledBoxSelectFilter>
        </Styles.StyledBoxFilter>

        <Box sx={{minWidth: 150, marginTop: '3.2rem'}}>
          <Styles.StyledSubmitButton style={{pointerEvents: 'auto', color: '#fff', cursor: enableSaveButton ? 'pointer' : 'not-allowed', opacity: enableSaveButton ? 1 : 0.5}} onClick={handleSubmit}
            disabled={!enableSaveButton}>
              Submit
          </Styles.StyledSubmitButton>
        </Box>
      </Styles.StyledFilterBox>
      <Styles.StyledResetFilter
        variant="span"
        gutterBottom
        onClick={resetFilters}
      >
          Reset Filters
      </Styles.StyledResetFilter>
    </Styles.StyledFilter></>
  );
};

export default TargetFilter;
