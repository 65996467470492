import React from 'react';
import Styles from '../../../styles/target';
import {utils, writeFileXLSX} from 'xlsx';

const DownloadPlaybookList = ({data}) => {
  const getFormatedStatus = (selectedValue) => {
    switch (selectedValue) {
      case 'Active':
        return 'ACTIVE';
      case 'Inactive':
        return 'INACTIVE';
      case 'Coverage Suspension':
        return 'COV_SUSPENDED';
      case 'Coverage Review Suspension':
        return 'REVIEW_SUSPENDED';
      case 'ACTIVE':
        return 'Active';
      case 'INACTIVE':
        return 'Inactive';
      case 'COV_SUSPENDED':
        return 'Coverage Suspension';
      case 'REVIEW_SUSPENDED':
        return 'Coverage Review Suspension';
      default:
        return '';
    }
  };

  const onDownload = () => {
    const sheetData = {};
    const excelFormattedData = data.map((item) => {
      const excelCurrentIterationData = {};
      excelCurrentIterationData['PlayBook ID'] = item?.playbook_id || '';
      excelCurrentIterationData['PlayBook Name'] = item?.playbook_name || '';
      excelCurrentIterationData['Master Sector'] = item?.master_sector || '';
      excelCurrentIterationData['Sector'] = item?.sector || '';
      excelCurrentIterationData['Country'] = item?.country || '';
      excelCurrentIterationData['Category'] = item?.category || '';
      excelCurrentIterationData['Commodity'] = item?.commodity || '';
      excelCurrentIterationData['Sub Commodity'] = item?.sub_commodity || '';
      excelCurrentIterationData['Buy Plan Owner'] = item?.buyplan_owner || '';
      excelCurrentIterationData['Standard UoM'] = item?.standard_unit || '';
      excelCurrentIterationData['Standard Currency'] = item?.currency || '';
      excelCurrentIterationData['Cascade Targets to Buy Plans'] = item?.is_cascade_target_bp ? 'Y' : 'N' || '';
      excelCurrentIterationData['Optionality(Y/N)'] = item?.optionality ? 'Y' : 'N' || '';
      excelCurrentIterationData['Supplier Targets(Y/N)*'] = item?.supplier_targets ? 'Y' : 'N' || '';
      excelCurrentIterationData['Supplier Optionality(Y/N)*'] = item?.supplier_optionality ? 'Y' : 'N' || '';
      excelCurrentIterationData['Treasury Targets(Y/N)**'] = item?.treasury_targets ? 'Y' : 'N' || '';
      excelCurrentIterationData['Treasury Optionality(Y/N)**'] = item?.treasury_optionality ? 'Y' : 'N' || '';
      excelCurrentIterationData['Current Status'] = getFormatedStatus(item?.current_status);
      excelCurrentIterationData['Status Start Month'] = item?.active_start_date || '';
      excelCurrentIterationData['Status End Month'] = item?.active_end_date || '';
      excelCurrentIterationData['Future Status'] = getFormatedStatus(item?.future_status);
      excelCurrentIterationData['Future Status Start Month'] = item?.future_status_start_date || '';
      excelCurrentIterationData['Future Status End Month'] = item?.future_status_end_date || '';
      excelCurrentIterationData['Last Edited By'] = item?.last_edited_by || '';
      excelCurrentIterationData['Last Edited On'] = item?.last_edited || '';
      return excelCurrentIterationData;
    });

    sheetData['PLAYBOOK_LIST'] = [...excelFormattedData];
    const wb = utils.book_new();
    const colWidths = [{wch: 150}];
    wb['!cols'] = colWidths;
    Object.keys(sheetData)?.forEach((data) => {
      const ws = utils.json_to_sheet(sheetData[data]);
      utils.book_append_sheet(wb, ws, data);
    });
    writeFileXLSX(wb, `PLAYBOOK_LIST.xlsx`);
  };

  if (!(data?.length > 0)) {
    return <></>;
  }
  return <Styles.StyledDownloadButton onClick={onDownload}>Download</Styles.StyledDownloadButton>;
};

export default DownloadPlaybookList;
