/* eslint-disable camelcase */
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Styles from '../../../styles/target';
import AddIcon from '@mui/icons-material/Add';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import BuyPlanDisplay from './BuyPlanDisplay';
import CommonConfTable from './BuyPlanTable';
import config from '../../../Utils/Config';
import _ from 'lodash';
import AddConfTable from './BuyPlanAddRow';
import DownloadBuyplanList from './DownloadBuyplanList';

const Edit = config.AccessLevel.Edit;
const Read = config.AccessLevel.Read;

const BuyPlan = (props) => {
  const heads = [
    'PlayBook Name*',
    'Buy Plan Name*',
    'Master Sector',
    'Sector*',
    'Country*',
    'Category',
    'Commodity',
    'Sub Commodity*',
    'Buy Plan Owner',
    'Buy Plan Creator*',
    'Buy Plan Approver*',
    'Buy Plan Admin*',
    'Forecast Creator',
    'Hedging',
    'Send Forecast to Atlas Fx',
    'Current Status*',
    'Start Month*',
    'End Month',
    'Last Edited By',
    'Last Edited On',

  ];

  const values = [
    'play_book_name',
    'buy_plan_name',
    'master_sector',
    'sector',
    'country',
    'category',
    'commodity',
    'sub_commodity',
    'buyplan_owner',
    'buyplan_creator',
    'buyplan_approver',
    'buyplan_admin',
    'forecast_creator',
    'hedgingMech',
    'is_atlasfx',
    'current_status',
    'active_start_date',
    'active_end_date',
    'last_edited_by',
    'last_edited',
  ];

  const sortValues = [
    true,
    true,
    true,
    false,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    true,
    true,
    true,
    true,
    true,
  ];

  const dateValues = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
  ];

  const editValues = [
    false,
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [idKey, setIdKey] = useState([]);
  const [sortType, setSorttype] = useState({});
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const selectedDropDownData = useSelector((state)=>state?.configurations?.selectedBuyPlanFilterValue);
  const updateSingleUnitMaster = ConfigurationServices.postBuyPlanData;
  const newRowUpdate = ConfigurationServices.postBuyPlanData;
  const deleteBuyPlanRow = ConfigurationServices.deleteBuyPlanData;
  const editBuyPlanRow = ConfigurationServices.getEditBuyPlanData;
  const [pagination, setPagination] = useState(10);
  const [addingTrigger, setAddingTrigger] = useState(false);
  const [Playbooks, setPlaybooks] = useState([]);
  const SortValue = useSelector((state)=>state.configurations.selectedSortName);
  const [ActiveMasterSectors, setActiveMasterSectors] = useState([]);
  const [ActiveSectors, setActiveSectors] = useState([]);
  const [ActiveCategories, setActiveCategories] = useState([]);
  const [ActiveCommodities, setActiveCommodities] = useState([]);
  const [ActiveSubCommodities, setActiveSubCommodities] = useState([]);
  const [Units, setUnits] = useState([]);
  const [Currency, setCurrency] = useState([]);
  const [country, setcountry] = useState([]);
  const currentUserId=useSelector((state)=>state?.roleBasedAccess?.userId);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const [currentRole, setCurrentRole] = useState(null);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  const [ownerPlaybooks, setOwnerPlaybooks]=useState('');
  const [buyplanowner, setbuyplanowner] = useState([]);
  const [buyplanPersona, setBuyPlanPersona] = useState([]);
  const [ActiveMasterSectorsList, setActiveMasterSectorsList] = useState([]);
  const [ActiveCategoriesList, setActiveCategoriesList] = useState([]);
  const [ActiveCommoditiesList, setActiveCommoditiesList] = useState([]);
  const [ActiveSubCommoditiesList, setActiveSubCommoditiesList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [ActiveSectorsList, setActiveSectorsList] = useState([]);
  const [buyplanownerList, setBuyplanownerList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [mappedData, setMappedData] = useState(0);
  const [addApiCall, setAddApiCall] = useState(false);
  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='last_edited') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.last_edited));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.sortBy(tableFilterData, (item) => new Date(item.last_edited)).reverse();
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:BuyPlan']) {
        setAccessControl(functionalityAccess['Configuration:BuyPlan']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const sortFn = (sortValue) => {
    // console.log(sortValue);
    if (sortValue!=='') {
      if (sortType[sortValue] === 'asc') {
        // sorting in descending
        setSorttype({...sortType, [sortValue]: 'desc'});
        tableFilterData.sort((a, b) =>
          b[sortValue]?.localeCompare(a[sortValue]));
      } else {
        // sorting in ascending
        setSorttype({...sortType, [sortValue]: 'asc'});
        tableFilterData.sort((a, b) =>
          a[sortValue]?.localeCompare(b[sortValue]));
      }
      setTableFilterData([...tableFilterData]);
    }
  };

  useEffect(()=>{
    if (addApiCall) {
      ConfigurationServices.getPlayBookDataForBuyPlan()
          .then((res)=>{
            const uniquePlaybooks = _.uniqBy(res, 'playbook_id');
            setPlaybooks(uniquePlaybooks);
          });
    }
  }, [addApiCall]);

  useEffect(()=>{
    ConfigurationServices.getBuyPlanData()
        .then((res)=>{
          setTimeout(()=>{
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                const sortedBuyplanListById = _.sortBy(res, (item) => item.buy_plan_id);
                const sortedBuyplanListByLastDate = _.sortBy(sortedBuyplanListById, (item) => new Date(item.last_edited)).reverse();
                let prevValue = null;
                sortedBuyplanListByLastDate.forEach((data) => {
                  if (prevValue !== data.buy_plan_id) {
                    data['showValue'] = true;
                    prevValue = data.buy_plan_id;
                  } else {
                    data['showValue'] = false;
                  }
                });
                setRow(sortedBuyplanListByLastDate);
                setMappedData(mapTableData(sortedBuyplanListByLastDate));
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
            setPage(0);
          }, 1000);
        });
  }, [addingTrigger]);

  useEffect(()=>{
    let temp = _.cloneDeep(row);
    if (Object.keys(selectedDropDownData).length!==0) {
      let anyfilterSelected = false;
      Object.entries(selectedDropDownData).map((obj, index)=>{
        if (obj[1].length!==0) {
          temp = temp.filter((r)=>{
            return obj[1].includes(r[obj[0]]);
          });
          anyfilterSelected = true;
        }
      });
      if (anyfilterSelected) {
        let prevBuyPlanId = null;
        temp.forEach((data) => {
          if (!(prevBuyPlanId === data.buy_plan_id)) {
            prevBuyPlanId = data.buy_plan_id;
            data.showValue = true;
          }
        });
        const groupedIds = [];
        const unGroupedData = [];
        temp.forEach((data) => {
          if (mappedData[data.buy_plan_id].length > 1 && !groupedIds.includes(data.buy_plan_id)) {
            groupedIds.push(data.buy_plan_id);
          }
        });
        temp.forEach((data) => {
          if (!(groupedIds.includes(data.buy_plan_id))) {
            unGroupedData.push(data);
          }
        });
        groupedIds.forEach((id) => {
          unGroupedData.push(...mappedData[id]);
        });
        temp = unGroupedData;
      }
      setTableFilterData([...temp]);
      mapTableData(temp);
      setPage(0);
    } else {
      setTableFilterData([...row]);
    }
  }, [selectedDropDownData, row]);

  function mapTableData(data) {
    const seggregatedData = {};
    data.forEach((buyplan) => {
      const buyPlanId = buyplan.buy_plan_id;
      const prevData = seggregatedData[buyPlanId] ? seggregatedData[buyPlanId] : [];
      seggregatedData[buyPlanId] = [...prevData, buyplan];
    });
    return seggregatedData;
  }

  useEffect(()=>{
    ConfigurationServices.getMasterSectorData()
        .then((res)=>{
          const activeMasterSectors = [];
          res.map((r)=>{
            r.is_active === true && activeMasterSectors.push(r);
          });
          setActiveMasterSectors([...activeMasterSectors]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getSectordata()
        .then((res)=>{
          const activeSectors = [];
          res['commodityGroups'].map((r)=>{
            r.sectors.map((rs)=>{
              rs.active === true && !activeSectors.includes(r) && activeSectors.push(r);
            });
          });
          setActiveSectors([...activeSectors]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCategoryData()
        .then((res)=>{
          const activeCategories = [];
          res.map((r)=>{
            r.is_active === true && activeCategories.push(r);
          });
          setActiveCategories([...activeCategories]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCommodityData()
        .then((res)=>{
          const activeCommodities = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rs)=>{
              rs.is_active==true && !activeCommodities.includes(r) && activeCommodities.push(r);
            });
          });
          setActiveCommodities([...activeCommodities]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getSubCommodityData()
        .then((res)=>{
          const activeSub = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rc)=>{
              rc.subCommodities.map((rs)=>{
                rs.is_active==true && !activeSub.includes(rc) && activeSub.push(rc);
              });
            });
          });
          setActiveSubCommodities([...activeSub]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getUnitMasterData()
        .then((res)=>{
          const units = [];
          Array.isArray(res) && res?.map((r)=>{
            !units.includes(r) && units.push(r);
          });
          setUnits([...units].filter((unit) => unit.is_active));
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCurrency()
        .then((res)=>{
          const currency = [];
          res.map((r)=>{
            !currency.includes(r) && currency.push(r);
          });
          setCurrency([...currency].filter((cur) => cur.is_active));
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCountrysData()
        .then((res)=>{
          const contr = [];
          res['commodityGroups'].map((rs)=>{
            rs.is_active==true && !contr.includes(rs) && contr.push(rs);
          });
          setcountry([...contr]);
        });
  }, []);

  useEffect(()=>{
    const filteredPlaybooks = Playbooks?.filter((p) => p.buyplan_owner_user_id == currentUserId);
    setOwnerPlaybooks(filteredPlaybooks);
  }, [Playbooks]);

  useEffect(()=>{
    ConfigurationServices.getBuyPlanownerdata()
        .then((res)=>{
          const bu = [];
          res['commodityGroups'].map((r)=>{
            r.is_active==true && !bu.includes(r) && bu.push(r);
          });
          setbuyplanowner([...bu]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getBuyPlanPersonasdata()
        .then((res)=>{
          setBuyPlanPersona(res);
        });
  }, []);

  useEffect(() => {
    if (!row || row.length === 0) {
      return;
    }

    // making list of unique master sector that are present in the table
    const uniqueSectorMaster = new Set(row.filter((obj) => obj.master_sector !== null && obj.master_sector !== '').map((obj) => obj.master_sector));
    let matchingObjects = ActiveMasterSectors.filter((obj) => uniqueSectorMaster.has(obj.master_sector));
    setActiveMasterSectorsList(matchingObjects);

    // making list of unique sectors that are present in the table
    const uniqueSector = new Set(row.filter((obj) => obj.sector !== null && obj.sector !== '').map((obj) => obj.sector));
    const sectors = ActiveSectors.flatMap((activeSector) => {
      return activeSector.sectors.map((sector) => ({
        ...sector,
        masterSectorName: activeSector.name,
      }));
    });
    matchingObjects = sectors.filter((obj) => uniqueSector.has(obj.name));
    setActiveSectorsList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueRegions = new Set(row.filter((obj) => obj.country !== null && obj.country !== '').map((obj) => obj.country));
    matchingObjects = country.filter((obj) => uniqueRegions.has(obj.region));
    setCountryList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueCategories = new Set(row.filter((obj) => obj.category !== null && obj.category !== '').map((obj) => obj.category));
    matchingObjects = ActiveCategories.filter((obj) => uniqueCategories.has(obj.category));
    setActiveCategoriesList(matchingObjects);

    // making list of unique commodities that are present in the table
    const uniqueCommodity = new Set(row.filter((obj) => obj.commodity !== null && obj.commodity !== '').map((obj) => obj.commodity));
    const commodity = ActiveCommodities.flatMap((activeCommodity) => {
      return activeCommodity.commodities.map((commodity) => ({
        ...commodity,
        categoryName: activeCommodity.name,
      }));
    });
    matchingObjects = commodity.filter((obj) => uniqueCommodity.has(obj.name));
    setActiveCommoditiesList(matchingObjects);

    // making list of unique subcommodities that are present in the table
    const uniqueSubCommodity = new Set(row.filter((obj) => obj.sub_commodity !== null && obj.sub_commodity !== '').map((obj) => obj.sub_commodity));
    const subCommodity = ActiveSubCommodities.flatMap((activeSubCommodity) => {
      return activeSubCommodity.subCommodities.map((subCommodity) => ({
        ...subCommodity,
        commodityName: activeSubCommodity.name,
      }));
    });
    matchingObjects = subCommodity.filter((obj) => uniqueSubCommodity.has(obj.name));
    setActiveSubCommoditiesList(matchingObjects);

    // making list of unique buyplan owner that are present in the table
    const uniqueBuyplanOwner = new Set(row.filter((obj) => obj.buyplan_owner_user_id !== null && obj.buyplan_owner_user_id !== '').map((obj) => obj.buyplan_owner_user_id));
    matchingObjects = buyplanowner.filter((obj) => uniqueBuyplanOwner.has(obj.user_id));
    setBuyplanownerList(matchingObjects);
  }, [row]);

  const [popup, setPopup] = useState(false);
  const handleAddRow = () =>{
    setPopup(true);
    setAddApiCall(true);
  };

  return (
    <Box
      sx={{
        bgcolor: '#eff6fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0px',
      }}
    >
      {(accessControl === Edit || accessControl === Read) && <Box
        sx={theme.palette.whitebgbox}
      >
        <BuyPlanDisplay row={row}
          ActiveMasterSectors={ActiveMasterSectorsList}
          buyplanowner={buyplanownerList}
          ActiveSectors={ActiveSectorsList}
          ActiveCategories={ActiveCategoriesList}
          ActiveCommodities={ActiveCommoditiesList}
          country={countryList}
          ActiveSubCommodities={ActiveSubCommoditiesList}/>


        <Box sx={{padding: '0px 18px', display: 'flex', gap: '10px', flexDirection: 'row-reverse', marginTop: '0px'}}>
          {accessControl===Edit && <Styles.StyledDownloadButton onClick={handleAddRow}>
            <AddIcon sx={{fontSize: '18px'}} />  Add Buy Plan
          </Styles.StyledDownloadButton>}
          <DownloadBuyplanList data={tableFilterData} />
        </Box>

        {accessControl===Edit &&
           <AddConfTable
             setAddingTrigger={setAddingTrigger}
             addingTrigger={addingTrigger}
             newRowUpdate={newRowUpdate}
             setIsErrorPopupVisible={setIsErrorPopupVisible}
             setErrMsg={setErrMsg}
             ActiveSubCommodities={ActiveSubCommodities}
             country={country}
             ActiveSectors={ActiveSectors}
             Playbooks={currentRole=='BuyPlan Owner'?ownerPlaybooks: Playbooks}
             Units={Units}
             Currency={Currency}
             buyplanPersona={buyplanPersona}
             popup ={popup}
             setPopup={setPopup}
           />}

        <Box>

          <CommonConfTable
            mappedData ={mappedData}
            data={tableFilterData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            sortValues={sortValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            accessControl={accessControl}
            singleUpdate={updateSingleUnitMaster}
            deleteBuyPlanRow = {deleteBuyPlanRow}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            ActiveCategories={ActiveCategories}
            ActiveCommodities={ActiveCommodities}
            ActiveSubCommodities={ActiveSubCommodities}
            country={country}
            setPage={setPage}
            page={page}
            sortFn={sortFn}
            sortType={sortType}
            ActiveMasterSectors={ActiveMasterSectors}
            ActiveSectors={ActiveSectors}
            Playbooks={currentRole=='BuyPlan Owner'?ownerPlaybooks: Playbooks}
            setAddApiCall={setAddApiCall}
            Units={Units}
            Currency={Currency}
            buyplanPersona={buyplanPersona}
            editBuyPlanRow={editBuyPlanRow}
          />


          {(errMsg !=='' || tableFilterData.length == 0) &&
        <ErrorMessage message='Buy Plan' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
    </Box>
  );
};

export default BuyPlan;
