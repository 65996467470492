import {createSlice} from '@reduxjs/toolkit';

export const isRecallStatusSlice = createSlice({
  name: 'isRecallStatus',
  initialState: {
    value: false,
  },
  reducers: {
    isRecallStatus: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {isRecallStatus} = isRecallStatusSlice.actions;

export default isRecallStatusSlice.reducer;
