/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Button, TextareaAutosize} from '@mui/material';
import NotificationMainSingleRow from './NotificationMainSingleRow';
import NotificationSettingDialog from './NotificationSettingDialog';
import ConfigurationServices from '../../../services/ConfigurationServices';


const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
    whiteSpace: 'noWrap',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));


const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8f8f8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const NotificationMainTable = ({accessControl}) => {
  const [open, setopen] = useState(false);

  const [data, setData] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getNotificationSetting().then((res)=>{
      setData([...res]);
    }).catch((err)=>{
      console.log(err);
    });
  }, []);
  const [getAllRoles, setGetAllRoles] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getRolesData()
        .then((res)=>{
          let activeRoles = [];
          res.map((r)=>{
            !activeRoles.includes(r) && activeRoles.push(r);
          });
          activeRoles=activeRoles.sort();
          setGetAllRoles([...activeRoles]);
        });
  }, []);
  const [scenarioId, setscenarioId] = useState(null);
  const [selectedCat, setselectedCat] = useState(null);

  return (
    <>
      <Box sx={{width: '100%', padding: '18px 18px'}}>
        <Box sx={{}}>
          <TableContainer component={Paper} className='stickytable'>
            <Table sx={{minWidth: 700}} aria-label="customized table">
              <TableHead className='stickytablehead'>
                <TableRow>
                  {/* <StyledTableCell align="left">Notification No</StyledTableCell> */}
                  <StyledTableCell align="left">Notification Category</StyledTableCell>
                  <StyledTableCell align="left">Notification Scenarios</StyledTableCell>
                  <StyledTableCell align="left">Want To Consolidate?</StyledTableCell>
                  <StyledTableCell align="left">Consolidation Frequency</StyledTableCell>
                  <StyledTableCell align="left">Reminders Frequency</StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, key) => (
                  <NotificationMainSingleRow setselectedCat={setselectedCat} setscenarioId={setscenarioId} setData={setData} open={open} setopen={setopen} row={row} key={key} accessControl={accessControl}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <NotificationSettingDialog setscenarioId={setscenarioId} selectedCat={selectedCat} scenarioId={scenarioId} open={open} setopen={setopen} accessControl={accessControl} getAllRoles={getAllRoles}/>

      </Box>
    </>
  );
};

export default NotificationMainTable;
