// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .activePlanList{
    width: 100%;
    padding: 18px;
}
.activePlanListSpan{
    font-weight: 600;
    color: rgb(96, 96, 96);
}
.tableActivePlanListDiv{
    margin-top: 20px;
} */
/* .statusClass{
    cursor: pointer;
    color: rgb(231, 3, 3);
    background-color: rgb(251, 173, 173);
    padding: 4px;
    border: none;
    border-radius: 2px;
}
.actionClass{
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1px 4px;
    background-color: rgb(216, 216, 216);
    border: navajowhite;
    border-radius: 2px;
} */
`, "",{"version":3,"sources":["webpack://./src/Components/MainComponent/TableComponent/ActivePlanList/ActivePlanList.css"],"names":[],"mappings":"AAAA;;;;;;;;;;GAUG;AACH;;;;;;;;;;;;;;;;GAgBG","sourcesContent":["/* .activePlanList{\n    width: 100%;\n    padding: 18px;\n}\n.activePlanListSpan{\n    font-weight: 600;\n    color: rgb(96, 96, 96);\n}\n.tableActivePlanListDiv{\n    margin-top: 20px;\n} */\n/* .statusClass{\n    cursor: pointer;\n    color: rgb(231, 3, 3);\n    background-color: rgb(251, 173, 173);\n    padding: 4px;\n    border: none;\n    border-radius: 2px;\n}\n.actionClass{\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    padding: 1px 4px;\n    background-color: rgb(216, 216, 216);\n    border: navajowhite;\n    border-radius: 2px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
