/* eslint-disable */
// using selectedRow.executionMonthYear[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years

import React, {useEffect, useState} from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ForecastTable from '../ForecastTab/Forecast';
import EditBuyPlanTable from '../EditBuyPlan/EditBuyPlanTable';
// import "./tabsComponent.css";
// import DocumentsTable from '../Documents';
import {AppBar, Button, DialogContent, Grid, IconButton, Slide, Toolbar, Tooltip} from '@mui/material';
import { titleCase, months, monthArr } from '../../services/utils';
import CloseIcon from '@mui/icons-material/Close';
import CoverageMockup from '../Coverage';
import './index.css';
import CoverageSummary from '../Coverage/CoverageSummary';
import { useDispatch, useSelector } from 'react-redux';
import { RowContext } from '../MainComponent/TableComponent/ActivePlanList';
import { useTabContext } from '@mui/base';
import { useContext } from 'react';
import PreviousMonthTable from '../PreviousMonth/PreviousMonthTable';
import PreviousMonthTable2 from '../PreviousMonth/PreviousMonth2';
import HistoricalCoverage from '../HistoricalCoverage/HistoricalCoverage';
import SupplierMaster from '../SupplierMaster/SupplierMaster';
import ForecastTable2 from '../ForecastTab/Forecast2';
import { editStatus } from '../../redux/EditStatus';
import ConfigurationServices from '../../services/ConfigurationServices';
import DialogService from '../../services/dialogService';
import { forecastDefaultData, monthNYearSetter, rowsSetter } from '../ForecastTab/utils';
import { setForeCastSaveButton, setForecastUpdatesaveButton, setHistoricalsaveButton, setlinkingLoad } from '../../redux/features/configurations';
import { floatColumn, intColumn, keyColumn, textColumn } from 'react-datasheet-grid';
import { setQuatersData } from '../../redux/Common';
import { AppTableInput } from '../HistoricalCoverage/AppTableInput/AppTableInput';
import ReadOnlyColumn from '../common/ReadOnlyColumn';
import { changeButtonStatusValue, changeBuyplanStatus } from '../../redux/features/buttonStatus';
import HistoricalCoverageMokup from '../HistoricalSectorCoverages';
import { defaultMonthValues } from '../../services/utils';
import Audit from '../Audit/Audit';
import {Link as MUILink} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoTooltipIcon from '../common/InfoTooltipIcon';
// import {setNavData} from '../../redux/buyplanTab/NavigationDataSlice';
// import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
// import { useHistory } from 'react-router-dom';
// import styled from '@emotion/styled';
// const ButtonComponent = styled('button')({
//   yearButton: {
//     background: "#ffffff",
//     color: "#6d7888",
//     fontSize: "12px",
//     border: "none",
//     outline: "0px",
//     "&:hover": {
//       background: "#4297d3",
//       color: "#ffffff",
//     },
//     "&:active": {
//       background: "#4297d3",
//       color: "#ffffff",
//     }
//   }
// })
//

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const gridCSS = {
  activeButton: {
    background: '#4297d3',
    color: '#ffffff',
    fontSize: '12px',
    border: 'none',
    outline: '0px',
    cursor: 'pointer',
    borderRadius: '3px',
    padding: '2px 3px',
  },
  inActiveButton: {
    background: '#ffffff',
    color: '#6d7888',
    fontSize: '12px',
    cursor: 'pointer',
    border: 'none',
    outline: '0px',
    padding: '2px 3px',
  },
  tabContainer: {
    padding: '15px',
    height: '100vh',
  },
  grid: {
    background: '#F8F8F8',
    padding: '10px 0px',
    borderRadius: '10px',
    margin: '0 !important',
    width: '100% !important',
  },
  head: {
    color: '#0071DC',
    fontSize: '12px',
    fontWeight: 600,
    font: "normal normal 600 12px/16px Segoe UI",
    marginBottom:"8px"
  },
  text: {
    color: '#3B3A39',
    fontSize: '12px',
    font: "normal normal 600 12px/16px Segoe UI",
  },
};

function TabPanel(props) {
  const {className, children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={className}
    >
      {value === index && (
        <Box sx={{p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'className': `tab-${index}`,
  };
}

const TreasurySelections = ['first', 'second', 'third', 'fourth2', 'fourPointFive', 'fifth', 'sixth'];
const SupplierSelections = ['first', 'second', 'third', 'fourth', 'fourPointFive', 'fifth', 'sixth'];
const BothSelections = ['first', 'second', 'third', 'fourth', 'fourth2', 'fourPointFive', 'fifth', 'sixth'];
const NoneSelections = ['first', 'second', 'third', 'fourPointFive', 'fifth', 'sixth'];
const today = new Date();
const TabsComponent = React.memo(({selectedRow,setIsPopupVisible}) => {

  const dispatch = useDispatch();
  const [newWfID, setNewWfID] = useState('');
  const [prevNonSuspendedmonth, setPrevNonSuspendedMonth] = useState('')
  const buttonStatus = useSelector((state) => state.btnStatus.value);

  const [currentRole, setCurrentRole] = useState(null);
  const [value, setValue] = React.useState('');
  const [yearValue, setYearValue] = React.useState(selectedRow.executionMonthYear[0]+'');
  const [previousSelectedYearTracker, setpreviousSelectedYearTracker] = React.useState(null);
  const [selectedYear, setSelectedYear] = React.useState(null);
  const [modifiedData, setModifiedData] = React.useState({})
  const [years, setYears] = React.useState([])
  const [data, setData] = React.useState([])
  let [flag, setFlag] = React.useState(false)
  const [subMissionData, setSubmissionData] = React.useState({})
  const [hedgingMechanism, setHedgingMechanism] = useState('');
  const [tabsEnableCheck, setTabsEnableCheck] = React.useState({
    forecast: selectedRow.isHistoricalDataEnabled==true && selectedRow.status !=='CLOSED' && selectedRow.status !=='SUSPENDED' ? false : true,
    buyPlan: buttonStatus==='view',
    coverage: buttonStatus==='view',
    historical: false,
  })
  const [columnsStatus, setCoulumnsStatus] = React.useState({
    first: true,
    second2: false,
    second: false,
    third2: false,
    third: false,
    fourthS: false,
    fourth: false,
    fourth2T: false,
    fourth2: false,
    fifth2: false,
    fourPointFive: false,
    fourPointFive2: false,
    fifth: false,
    sixth: true
  })
  const [initialArr, setInitialArr] = React.useState([]);
  const [selectedArr, setSelectedArr] = React.useState([]);
  const [firstLoad, setFirstLoad] = React.useState(false);
  // const [isForecastUpdateTabEditable, setIsForecastUpdateTabEditable]=useState(true);
  const isForecastUpdateTabEditable = useSelector((state)=> state.configurations.ForecastUpdateSaveButton)
  const [yearsCallingcount, setYearsCallingCount] = useState(0)
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  const [yearCount, setYearCount] = useState([]);
  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);


  useEffect(()=>{
   if(currentRole!==null){
    if(currentRole!=='Forecast Creator'){
      setValue(selectedRow?.isHistoricalDataEnabled==true ? 0 : 2)
    }
    else{
      setValue(2)
      setTabsEnableCheck((prev)=>({...prev, forecast: true}))
    }
   }
  }, [currentRole])

  // PREVIOUS MONTH FORECAST VARIABLES
  const [PreviousYearData1, setPreviousYearData1] = useState([]);
  const [PreviousYearData2, setPreviousYearData2] = useState([]);
  const [PreviousYearData3, setPreviousYearData3] = useState([]);

  useEffect(()=>{
    if(years.length>0 && prevNonSuspendedmonth!==''){
      setPreviousYearData1([
        {
          month: 3,
          planMonth: monthArr[+prevNonSuspendedmonth.split('-')[1]-1],
          planYear: +years[0],
          jan: 0,
          year: +prevNonSuspendedmonth.split('-')[0],
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
          fy: 0,
          workflow_id: selectedRow.workFlowId,
        },
      ])
      setPreviousYearData2([{
        month: 3,
        planMonth: monthArr[+prevNonSuspendedmonth.split('-')[1]-1],
        planYear: +years[0]+1,
        jan: 0,
        year: +prevNonSuspendedmonth.split('-')[0],
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        fy: 0,
        workflow_id: selectedRow.workFlowId,
      },
      ])
      setPreviousYearData3([  {
        month: 3,
        planMonth: monthArr[+prevNonSuspendedmonth.split('-')[1]-1],
        planYear: +years[0]+2,
        jan: 0,
        year: +prevNonSuspendedmonth.split('-')[0],
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        fy: 0,
        workflow_id: selectedRow.workFlowId,
      },])
    }
    }, [years, prevNonSuspendedmonth])

  const buyplanStatus = useSelector((state) => state.btnStatus.status);

  const [dataTrigger, setDataTrigger] = useState(false);
  const [workflowIdsObj, setWorkflowIdsObj] = useState({});
  useEffect(()=>{
    let hedgingMech = '';
    if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== false) {
      hedgingMech = 'Supplier';
    } else if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== true) {
      hedgingMech = 'Supplier/Other';
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== false) {
      hedgingMech = 'Treasury';
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== true) {
      hedgingMech = 'Treasury/Other';
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others== false) {
      hedgingMech = 'Supplier/Treasury';
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others==true) {
      hedgingMech = 'Supplier/Treasury/Other';
    } else if (selectedRow.coverageMode=='NONE' && selectedRow.others==false) {
      hedgingMech = 'None';
    } else if (selectedRow.others==true && selectedRow.coverageMode=='NONE') {
      hedgingMech = 'Other';
    }
    setHedgingMechanism(hedgingMech);
  }, [selectedRow]);
  useEffect(() => {
    if(newWfID!=='' && years.length>0){
      // console.log(newWfID);
      getCall();


    }
    if(selectedRow.status==='CLOSED' || selectedRow.status==='APPROVED' || selectedRow.status==='REJECTED' || selectedRow.status==='SUBMITTED' || selectedRow.status =='SUSPENDED'){
      setTabsEnableCheck((prev) => {
        return {...prev, buyPlan: true};
      });
    }
    else if(buyplanStatus !=='NEW'){
      if(selectedRow.prevWorkFlowId>0){
        if(selectedRow.isHistoricalDataEnabled==true){
          DialogService.getForecastData(selectedRow.workFlowId, selectedRow.prevWorkFlowId, selectedRow.sector)
          .then(async (res) => {
            // console.log(res.length)
            if(res.length>36 && selectedRow.forecastSubmitted){
              setTabsEnableCheck((prev) => {
                return {...prev, buyPlan: true};
              });
            }
            else{
              setTabsEnableCheck((prev) => {
                return {...prev, buyPlan: false};
              });
            }
          })
          .catch((err) => console.log(err));
        } else {
          if (selectedRow.forecastSubmitted){
            setTabsEnableCheck((prev) => {
              return {...prev, buyPlan: true};
            });
          }
        }
      }
      else{
        setTabsEnableCheck((prev)=>({...prev, buyPlan: false}))
      }
    }
    else if(buyplanStatus =='NEW' && buttonStatus=='view' && selectedRow.isHistoricalDataEnabled==true && currentRole!=='Forecast Creator'){

      setTabsEnableCheck((prev)=>({...prev, buyPlan: false}))
      // we enabling the save button if he is a Admin and not for buyplan creator
      if(currentRole?.includes('Admin')){
        dispatch(changeButtonStatusValue('edit'))
      }
    }
  }, [selectedRow, newWfID, dataTrigger,currentRole, years]);

  const isHedgingTypesDisabled = (rowData) =>{
    return ((rowData.source == 'Supplier' || rowData.source == 'Treasury' || (rowData.source == 'Other' && selectedRow.others == true)) && selectedRow.coverageMode == 'BOTH') || (rowData.source.toUpperCase() === selectedRow.coverageMode) || (rowData.source == 'Total' && (selectedRow.coverageMode=='NONE' && selectedRow.others==false)) || (rowData.source == 'Other' && selectedRow.others == true)? false : true ;
    }

  const getCall = () => {
    DialogService.getForecastData(newWfID, 0, selectedRow.sector)
        .then((res) => {
          // console.log(res)
          let obj = {};
          if (res.length>0) {
            res.forEach((val) => {
              const split = val.forecastYearMonth;
              if (+split[0] >= +years[0]) {
                if (!workflowIdsObj[val.workflowId]) {
                  workflowIdsObj[val.workflowId] = val;
                  setWorkflowIdsObj({...workflowIdsObj});
                }
                obj = {...monthNYearSetter(obj, val)};
              }
            });

            // debugger;
            // if some data is received ans some not like 2023 year data is there but 2024 data is not
            obj = {...rowsSetter(obj, workflowIdsObj, selectedRow)};
            // console.log(obj);

            Object.keys(obj).map((ob, index)=>{

                Array.isArray(obj[years[0]]) ?
                setPreviousYearData1([{...obj[years[0]][0], planYear: years[0], year: obj[years[0]][0]['planYear'],
                fy: obj[years[0]][0]['jan']+ obj[years[0]][0]['feb']+ obj[years[0]][0]['mar'] + obj[years[0]][0]['apr'] + obj[years[0]][0]['may'] + obj[years[0]][0]['jun'] + obj[years[0]][0]['jul'] + obj[years[0]][0]['aug'] + obj[years[0]][0]['sep']+ obj[years[0]][0]['oct']+ obj[years[0]][0]['nov']+ obj[years[0]][0]['dec']

              }])
                :

                setPreviousYearData1([{...obj[years[0]][newWfID], planYear: years[0], year: obj[years[0]][newWfID]['planYear'],
                fy: obj[years[0]][newWfID]['jan']+ obj[years[0]][newWfID]['feb']+ obj[years[0]][newWfID]['mar'] + obj[years[0]][newWfID]['apr'] + obj[years[0]][newWfID]['may'] + obj[years[0]][newWfID]['jun'] + obj[years[0]][newWfID]['jul'] + obj[years[0]][newWfID]['aug'] + obj[years[0]][newWfID]['sep']+ obj[years[0]][newWfID]['oct']+ obj[years[0]][newWfID]['nov']+ obj[years[0]][newWfID]['dec']
              }]);

                Array.isArray(obj[years[0]+1]) ?
                setPreviousYearData2([{...obj[years[0]+1][0], planYear: years[0]+1, year: obj[years[0]+1][0]['planYear'],
                fy: obj[years[0]+1][0]['jan']+ obj[years[0]+1][0]['feb']+ obj[years[0]+1][0]['mar'] + obj[years[0]+1][0]['apr'] + obj[years[0]+1][0]['may'] + obj[years[0]+1][0]['jun'] + obj[years[0]+1][0]['jul'] + obj[years[0]+1][0]['aug'] + obj[years[0]+1][0]['sep']+ obj[years[0]+1][0]['oct']+ obj[years[0]+1][0]['nov']+ obj[years[0]+1][0]['dec']

              }])
                :
                setPreviousYearData2([{...obj[years[0]+1][newWfID], planYear: years[0]+1, year: obj[years[0]+1][newWfID]['planYear'],
                fy: obj[years[0]+1][newWfID]['jan']+ obj[years[0]+1][newWfID]['feb']+ obj[years[0]+1][newWfID]['mar'] + obj[years[0]+1][newWfID]['apr'] + obj[years[0]+1][newWfID]['may'] + obj[years[0]+1][newWfID]['jun'] + obj[years[0]+1][newWfID]['jul'] + obj[years[0]+1][newWfID]['aug'] + obj[years[0]+1][newWfID]['sep']+ obj[years[0]+1][newWfID]['oct']+ obj[years[0]+1][newWfID]['nov']+ obj[years[0]+1][newWfID]['dec']

              }]);

                Array.isArray(obj[years[0]+2]) ?
                setPreviousYearData3([{...obj[years[0]+2][0], planYear: years[0]+2, year: obj[years[0]+2][0]['planYear'],
                fy: obj[years[0]+2][0]['jan']+ obj[years[0]+2][0]['feb']+ obj[years[0]+2][0]['mar'] + obj[years[0]+2][0]['apr'] + obj[years[0]+2][0]['may'] + obj[years[0]+2][0]['jun'] + obj[years[0]+2][0]['jul'] + obj[years[0]+2][0]['aug'] + obj[years[0]+2][0]['sep']+ obj[years[0]+2][0]['oct']+ obj[years[0]+2][0]['nov']+ obj[years[0]+2][0]['dec']

              }])
                :
                setPreviousYearData3([{...obj[years[0]+2][newWfID], planYear: years[0]+2, year: obj[years[0]+2][newWfID]['planYear'],
                fy: obj[years[0]+2][newWfID]['jan']+ obj[years[0]+2][newWfID]['feb']+ obj[years[0]+2][newWfID]['mar'] + obj[years[0]+2][newWfID]['apr'] + obj[years[0]+2][newWfID]['may'] + obj[years[0]+2][newWfID]['jun'] + obj[years[0]+2][newWfID]['jul'] + obj[years[0]+2][newWfID]['aug'] + obj[years[0]+2][newWfID]['sep']+ obj[years[0]+2][newWfID]['oct']+ obj[years[0]+2][newWfID]['nov']+ obj[years[0]+2][newWfID]['dec']

              }]);

            })
          }

        })
        .catch((err) => console.log(err));
  };



  // Historical Coverage variables
  const [PreviousHistoricalCoverageYear1, setPreviousHistoricalCoverageYear1] = useState([]);
  const [PreviousHistoricalCoverageYear2, setPreviousHistoricalCoverageYear2] = useState([]);
  const [PreviousHistoricalCoverageYear3, setPreviousHistoricalCoverageYear3] = useState([]);


  useEffect(()=>{
if(prevNonSuspendedmonth!==''){


      getHistoricalCall();
}
  }, [prevNonSuspendedmonth])




  const getHistoricalCall = () => {
    console.log(PreviousHistoricalCoverageYear1)
    const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    DialogService.getHistoricalCoverageData(newWfID).then((res) =>{
      // console.log(res);
      if(res.length>0){
        res.map(( element) => {
          element.executionMonth=`${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`;
          element.actual_coverage = (element.SUPPLIER?element.SUPPLIER:0) + (element.TREASURY?element.TREASURY:0);
          element.variance = (element.planned_coverage?element.planned_coverage:0) - (element.actual_coverage?element.actual_coverage:0);
          // element.variance = Math.abs((element.planned_coverage?element.planned_coverage:0) - (element.actual_coverage?element.actual_coverage:0));
        });
        res.sort((a, b) => {
          const [mon1, year1] = a.coverage_year_month.split('-');
          const [mon2, year2] = b.coverage_year_month.split('-');
          return (year1 - year2) || (monthArr.indexOf(mon1) - monthArr.indexOf(mon2));
        });
        const prevHistory1 = [...PreviousHistoricalCoverageYear1]
        const prevHistory2 = [...PreviousHistoricalCoverageYear2]
        const prevHistory3 = [...PreviousHistoricalCoverageYear3]
       if(selectedRow.coverageMode=='NONE' && selectedRow.others == false){
        for(let i=0; i<res.length; i++){
          if(i>=0 && i<12){
           prevHistory1[3][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? res[i]['OTHER'] : 0
          }
          if(i>=12 && i< 24){
            prevHistory2[3][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? res[i]['OTHER'] : 0

          }
          if(i>=24 && i<36){
            prevHistory3[3][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? res[i]['OTHER'] : 0

          }
        }


       }
       else{
        for(let i=0; i<res.length; i++){
          if(i>=0 && i<12){
            prevHistory1[0][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['SUPPLIER'] !==undefined ? (res[i]['SUPPLIER'].toString().split('.').length>1 ? res[i]['SUPPLIER'] : res[i]['SUPPLIER']) : 0
            prevHistory1[1][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['TREASURY'] !==undefined ? (res[i]['TREASURY'].toString().split('.').length>1 ? res[i]['TREASURY'] : res[i]['TREASURY']) : 0
            prevHistory1[2][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? (res[i]['OTHER'].toString().split('.').length>1 ? res[i]['OTHER'] : res[i]['OTHER']) : 0
          }
          if(i>=12 && i< 24){
            prevHistory2[0][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['SUPPLIER'] !==undefined ? (res[i]['SUPPLIER'].toString().split('.').length>1 ? res[i]['SUPPLIER'] : res[i]['SUPPLIER']) : 0
            prevHistory2[1][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['TREASURY'] !==undefined ? (res[i]['TREASURY'].toString().split('.').length>1 ? res[i]['TREASURY'] : res[i]['TREASURY']) : 0
            prevHistory2[2][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? (res[i]['OTHER'].toString().split('.').length>1 ? res[i]['OTHER'] : res[i]['OTHER']) : 0

          }
          if(i>=24 && i<36){
            prevHistory3[0][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['SUPPLIER'] !==undefined ? (res[i]['SUPPLIER'].toString().split('.').length>1 ? res[i]['SUPPLIER'] : res[i]['SUPPLIER']) : 0
            prevHistory3[1][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['TREASURY'] !==undefined ? (res[i]['TREASURY'].toString().split('.').length>1 ? res[i]['TREASURY'] : res[i]['TREASURY']) : 0
            prevHistory3[2][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? (res[i]['OTHER'].toString().split('.').length>1 ? res[i]['OTHER'] : res[i]['OTHER']) : 0

          }
        }
       }
        for(let i=0;i<4;i++){
          prevHistory1[i]['year'] = selectedRow.executionMonthYear[1]==2 ? years[1] : years[0];
          prevHistory2[i]['year'] = selectedRow.executionMonthYear[1]==2 ? years[1] : years[0];
          prevHistory3[i]['year'] = selectedRow.executionMonthYear[1]==2 ? years[1] : years[0];

        }

        // for(let i=12; i<24; i++){
        //   prevHistory2[0][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['SUPPLIER'] !==undefined ? res[i]['SUPPLIER'] : 0
        //   prevHistory2[1][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['TREASURY'] !==undefined ? res[i]['TREASURY'] : 0
        //   prevHistory2[2][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? res[i]['OTHER'] : 0
        // }
        // for(let i=24; i<36; i++){
        //   prevHistory3[0][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['SUPPLIER'] !==undefined ? res[i]['SUPPLIER'] : 0
        //   prevHistory3[1][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['TREASURY'] !==undefined ? res[i]['TREASURY'] : 0
        //   prevHistory3[2][res[i]['coverage_year_month'].split("-")[0].toLowerCase()] = res[i]['OTHER'] !==undefined ? res[i]['OTHER'] : 0
        // }
        setPreviousHistoricalCoverageYear1([...prevHistory1]);
        setPreviousHistoricalCoverageYear2([...prevHistory2]);
        setPreviousHistoricalCoverageYear3([...prevHistory3]);
      }
      else{
      const prevHistory1 = [...PreviousHistoricalCoverageYear1]
      const prevHistory2 = [...PreviousHistoricalCoverageYear2]
      const prevHistory3 = [...PreviousHistoricalCoverageYear3]
      for(let i=0;i<3;i++){
        prevHistory1[i]['year'] = years[0];
        prevHistory2[i]['year'] = years[0];
        prevHistory3[i]['year'] = years[0];

      }
      }
      setTabsEnableCheck((prev)=>({...prev, historical:true}))
    });
  }
  useEffect(()=>{
    console.log(selectedRow)
   if(years.length>0 && yearsCallingcount==0){
    let monthData = selectedRow.executionMonthYear[1]-1 < 10 ? '0'+(selectedRow.executionMonthYear[1]-1) : selectedRow.executionMonthYear[1]-1;
    if(monthData=='00'){
      monthData='12'
    }
    const totalYearMonth = `${selectedRow.executionMonthYear[1]==2 ? years[1] : years[0]}-${monthData}`;
    // console.log(totalYearMonth);
    if(selectedRow.isHistoricalDataEnabled==true){
      // console.log('called Historical')
      DialogService.getHistoricalId(totalYearMonth, selectedRow.buyPlanId).then((res)=>{
        console.log(res);
        setPrevNonSuspendedMonth(res.exec_month_year)
        setPreviousHistoricalCoverageYear1([
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Supplier',
            planYear: +res.exec_month_year.split('-')[0],
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Treasury',
            planYear: +res.exec_month_year.split('-')[0],
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Other',
            planYear: +res.exec_month_year.split('-')[0],
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Total',
            planYear: +res.exec_month_year.split('-')[0],
            ...defaultMonthValues,
            year: +res.exec_month_year.split('-')[0],
            workflow_id: selectedRow.workFlowId,
          },
        ])
        setPreviousHistoricalCoverageYear2([
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Supplier',
            planYear: +res.exec_month_year.split('-')[0]+1,
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Treasury',
            planYear: +res.exec_month_year.split('-')[0]+1,
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Other',
            planYear: +res.exec_month_year.split('-')[0]+1,
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Total',
            planYear: +res.exec_month_year.split('-')[0]+1,
            ...defaultMonthValues,
            year: +res.exec_month_year.split('-')[0],

            workflow_id: selectedRow.workFlowId,
          },
        ])
        setPreviousHistoricalCoverageYear3([
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Supplier',
            planYear: +res.exec_month_year.split('-')[0]+2,
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Treasury',
            planYear: +res.exec_month_year.split('-')[0]+2,
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Other',
            planYear: +res.exec_month_year.split('-')[0]+2,
            ...defaultMonthValues,
            workflow_id: selectedRow.workFlowId,
          },
          {
            month: 3,
            planMonth: monthArr[+res.exec_month_year.split('-')[1]-1],
            source: 'Total',
            planYear: +res.exec_month_year.split('-')[0]+2,
            ...defaultMonthValues,

            year: +res.exec_month_year.split('-')[0],

            workflow_id: selectedRow.workFlowId,
          },
        ])
        setNewWfID(res.workflow_id);
        // setYears([+res.exec_month_year.split('-')[0], +res.exec_month_year.split('-')[0]+1, +res.exec_month_year.split('-')[0]+2])
      }).catch(err=>{
        const prevHistory1 = [...PreviousHistoricalCoverageYear1]
        const prevHistory2 = [...PreviousHistoricalCoverageYear2]
        const prevHistory3 = [...PreviousHistoricalCoverageYear3]
        for(let i=0;i<3;i++){
          prevHistory1[i]['year'] = years[0];
          prevHistory2[i]['year'] = years[0];
          prevHistory3[i]['year'] = years[0];

        }
        setTabsEnableCheck((prev)=>({...prev, historical:true}));
        }

        )
    }
    setYearsCallingCount(p=>p+1)
   }

  }, [selectedRow, years, yearsCallingcount]);

  useEffect(()=>{
    if(selectedRow.prevWorkFlowId>0 && buyplanStatus!=='NEW'){
      dispatch(setlinkingLoad(false));
      setTabsEnableCheck((prev)=>({...prev, forecast:true}))
      // if(value<2){

      //   setValue(2)
      // }
    }
  }, [selectedRow])

  const HistoricalsaveButton = useSelector((state)=>state.configurations.HistoricalsaveButton);
  const forecastsaveButton = useSelector((state)=> state.configurations.forecastsaveButton);

  // useEffect(()=>{
  //   // console.log(selectedRow)
  //   if(selectedRow.status==='NEW'){
  //     dispatch(setForeCastSaveButton('false'))
  //     dispatch(setHistoricalsaveButton('false'))
  //     dispatch(setForecastUpdatesaveButton(true))
  //   }
  //  else{
  //   dispatch(setForeCastSaveButton('true'))
  //     dispatch(setHistoricalsaveButton('true'))
  //     dispatch(setForecastUpdatesaveButton(false))
  //  }

  // }, [selectedRow])

  useEffect(() => {
    if(years.length)
    {setSelectedYear(years[0])
    setpreviousSelectedYearTracker(years[0]);
    // setYearCount([years[0]])
  }
    else {
      let years = Object.keys(modifiedData).map(val => val).sort()
      setSelectedYear(years[0])

    setpreviousSelectedYearTracker(years[0]);
    // setYearCount([years[0]])

    }
  }, [years])

  const [historicalColumns, setHistoricalColumns] = useState([]);
  const [historicalColumns2, setHistoricalColumns2] = useState([]);
  const [historicalTrigger, setHistoricalTrigger] = useState('');

  const setColumData = (year) => {
    setTimeout(()=>{
      if (selectedYear !== undefined && selectedYear!==null && selectedYear != 0) {
        setHistoricalColumns([
          {
            ...keyColumn('planMonth', textColumn),
            title: 'Plan month',
            disabled: true,
          },
          {
            ...keyColumn('year', textColumn),
            title: 'Plan Year',
            disabled: true,
          },
          {
            ...keyColumn('source', textColumn),
            title: 'Source Name',
            disabled: true,
          },

          {
            ...keyColumn('jan', textColumn),
              component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                historicalTrigger={historicalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="jan"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jan',
            // disabled: ({rowData}) =>
            //   (rowData.source.toUpperCase() !==selectedRow.coverageMode) && true,
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('feb', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="feb"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Feb',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('mar', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="mar"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Mar',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)
          },
          {
            ...keyColumn('apr', textColumn),

            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="apr"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),
            title: 'Apr',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('may', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="may"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'May',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('jun', textColumn),

            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="jun"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),
            title: 'Jun',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('jul', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="jul"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jul',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('aug', textColumn),

            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="aug"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),
            title: 'Aug',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('sep', textColumn),

            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="sep"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),
            title: 'Sep',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('oct', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="oct"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Oct',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('nov', textColumn),

            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="nov"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),
            title: 'Nov',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {
            ...keyColumn('dec', textColumn),

            component: ({rowData, focus}) => (
              <AppTableInput decider={+wfDetails?.decimal_scale}
              selectedRow={selectedRow}
                setHistoricalTrigger={setHistoricalTrigger}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                // isDisabled={isDisabled(rowData, 'jan')}
                columnName="dec"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),
            title: 'Dec',
            disabled: ({rowData}) =>
              isHedgingTypesDisabled(rowData)


          },
          {...keyColumn('fy', intColumn),  title: `FY ${year || years[0]} Total`, disabled: true, component: ({rowData, focus}) => (
            <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='fy' />
          ),},


        ]);

        setHistoricalColumns2([
          {
            ...keyColumn('planMonth', textColumn),
            title: 'Plan month',
            disabled: true,
          },
          {
            ...keyColumn('year', textColumn),
            title: 'Plan Year',
            disabled: true,
          },
          {
            ...keyColumn('source', textColumn),
            title: 'Source Name',
            disabled: true,
          },
          {
            ...keyColumn('jan', textColumn),


            title: 'Jan',
            // disabled: ({rowData}) =>
            //   (rowData.source.toUpperCase() !==selectedRow.coverageMode) && true,
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='jan' />
            ),

          },
          {
            ...keyColumn('feb', textColumn),


            title: 'Feb',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='feb' />
            ),
          },
          {
            ...keyColumn('mar', textColumn),


            title: 'Mar',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='mar' />
            ),
          },
          {
            ...keyColumn('apr', textColumn),


            title: 'Apr',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='apr' />
            ),
          },
          {
            ...keyColumn('may', textColumn),


            title: 'May',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='may' />
            ),
          },
          {
            ...keyColumn('jun', textColumn),


            title: 'Jun',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='jun' />
            ),
          },
          {
            ...keyColumn('jul', textColumn),


            title: 'Jul',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='jul' />
            ),
          },
          {
            ...keyColumn('aug', textColumn),


            title: 'Aug',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='aug' />
            ),
          },
          {
            ...keyColumn('sep', textColumn),


            title: 'Sep',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='sep' />
            ),
          },
          {
            ...keyColumn('oct', textColumn),


            title: 'Oct',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='oct' />
            ),
          },
          {
            ...keyColumn('nov', textColumn),


            title: 'Nov',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='nov' />
            ),
          },
          {
            ...keyColumn('dec', textColumn),


            title: 'Dec',
            disabled: true,
            component: ({rowData, focus}) => (
              <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='dec' />
            ),
          },
          {...keyColumn('fy', intColumn), title: `FY ${year || years[0]} Total`, disabled: true, component: ({rowData, focus}) => (
            <ReadOnlyColumn rowData={rowData} decider={wfDetails?.decimal_scale} columnName='fy' />
          ),},


        ]);
      }
    }, 100);
  };

  useEffect(()=>{
    setHistoricalColumns([]);
    setHistoricalColumns2([]);
    setColumData(years[0]);
  }, [selectedYear]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFirstLoad(false);
  };
  //  checking if the current month is jan the years will be [selectedRow.executionMonthYear[0]-1, selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1]
  useEffect(() => {
    if(!flag, !selectedYear && modifiedData && Object.keys(modifiedData).length){
      let years = Object.keys(modifiedData).map(val => val).sort()
      if((months[selectedRow.executionMonthYear[1]-1]=='jan' || months[selectedRow.executionMonthYear[1]-1]=='feb')){
        setYears([selectedRow.executionMonthYear[0]-1, selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1])
      }
      else{
        setYears([selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1, selectedRow.executionMonthYear[0]+2])
      }

      setFlag(true)
    }
  }, [modifiedData])

  const [execMonthDecider, setExecMonthDecider] = useState([])
  const [isPreviousYearData, setisPreviousYearData] = useState(false)


  useEffect(()=>{
    if(execMonthDecider.length>0){
      if(+execMonthDecider[0] !== +selectedRow.executionMonthYear[0]){
        setisPreviousYearData(true)
      } else {
        setisPreviousYearData(false)
      }
      setYears([...execMonthDecider])
    }
  }, [execMonthDecider])

  useEffect(() => {
    return () => dispatch(editStatus(true));
  }, [])


  // Is used for calculating quarters for particular month
  const [quarters, setQuaters]=useState({});
  const [quarterMonths, setQuarterMonths] = useState([]);
  const [result, setResult] = useState({});
  const [wfDetails, setWfDetails]=useState(); useEffect(()=>{
    ConfigurationServices.getWorkflowDetails(selectedRow.workFlowId).then(res=>{
      setWfDetails(res);
    })
  },[selectedRow]);


  useEffect(()=>{
    // DYNAMIC QUARTERS IMPLEMENTATION

    ConfigurationServices.getQuartersdata(selectedRow.sectorGrpId).then(res=>{
      // console.log(res)
      setResult(res);
    })

    // STATIC QUARTERS CALCULATIONS
    // const res= {'Q1': 'Jan,Feb,Mar',
    // 'Q2': 'April,May,Jun',
    // 'Q3': 'July,Aug,Sep',
    // 'Q4': 'Oct,Nov,Dec',
    // }
    // setResult(res);

  }, []);



  useEffect(()=>{
    setQuaters(result);

    const dynamicQuarters = [];
    Object.keys(result)?.map((quarter) => {
      const lastQuarterValue = result[quarter].split(',');
      if (!dynamicQuarters.includes(lastQuarterValue[lastQuarterValue.length-1])) {
        dynamicQuarters.push(lastQuarterValue[lastQuarterValue.length-1].charAt(0).toUpperCase()+lastQuarterValue[lastQuarterValue.length-1].slice(1).toLowerCase());
      }
    });
    dynamicQuarters.sort((a, b)=>monthArr.indexOf(a)-monthArr.indexOf(b));
    // console.log(dynamicQuarters);
    setQuarterMonths([...dynamicQuarters]);
    dispatch(setQuatersData([...dynamicQuarters]));
  }, [result])

  useEffect(() => {
    // console.log('@appmaindialog selectedRow',selectedRow)
    if (selectedRow && !firstLoad) {
      // if(selectedRow.status != 'NEW' && selectedRow.status != 'DRAFT') {
      //   setTabsEnableCheck({...tabsEnableCheck, buyPlan: true});
      // }
      if (selectedRow?.coverageMode == 'TREASURY') {
        let arr = JSON.parse(JSON.stringify(TreasurySelections))
        if(!selectedRow.others ) {
          arr = arr.filter(val => val !== 'fourPointFive')
        }
        setTimeout(() =>{setInitialArr([...arr]);
        changeStatus([...arr])})
      }
      if (selectedRow?.coverageMode == 'SUPPLIER') {
        let arr = JSON.parse(JSON.stringify(SupplierSelections))
        if(!selectedRow.others ) {
          arr = arr.filter(val => val !== 'fourPointFive')
        }
        setTimeout(() =>{setInitialArr([...arr]);
        changeStatus([...arr])})
      }
      if (selectedRow?.coverageMode == 'BOTH') {
        let arr = JSON.parse(JSON.stringify(BothSelections))
        if(!selectedRow.others ) {
          arr = arr.filter(val => val !== 'fourPointFive')
        }
        setTimeout(() =>{
          setInitialArr([...arr]);
          changeStatus([...arr])
        })
      }
      if (selectedRow?.coverageMode == 'NONE') {
        let arr = JSON.parse(JSON.stringify(NoneSelections))
        if(!selectedRow.others ) {
          arr = arr.filter(val => val !== 'fourPointFive')
        }
        setTimeout(() =>{
          setInitialArr([...arr]);
          changeStatus([...arr])
        })
      }
      setFirstLoad(true)
      // if(selectedRow.prevWorkFlowId > 0){

      //   // setValue(2);
      //   setTabsEnableCheck((prev) => {
      //     return {...prev, forecast: true};
      //   });
      // }
    }
  }, [selectedRow]);

  const changeStatus = (arr) => {
    let status = columnsStatus;
    Object.keys(status).forEach(val => {
      if(arr.indexOf(val) > -1) {
        status[val] = true;
      } else status[val] = false;
    })
    setCoulumnsStatus({...status});
  }


  const updateBuyPlanTab = ()=>{
    if(selectedRow.status!=='NEW' || selectedRow.status!=='DRAFT'){
      setTabsEnableCheck({...tabsEnableCheck,buyPlan:false});
    }
  }


  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  useEffect(()=>{
    Object.values(modifiedData).map((mobj, index)=>{
      const Ddata1 = [];
      const Ddata2 = [];
      const Ddata3 = [];
      Object.values(mobj).map((sobj)=>{
        if (index == 0) {
          Ddata1.push(sobj);
        }
        if (index == 1) {
          Ddata2.push(sobj);
        }
        if (index == 2) {
          Ddata3.push(sobj);
        }
      });
      if (selectedYear == years[0] && data1.length === 0) {
        setdata1((prev)=>[...prev, ...Ddata1]);
      }
      if (selectedYear == years[1] && data2.length === 0) {
        setdata2((prev)=>[...prev, ...Ddata2]);
      }
      if (selectedYear == years[2] && data3.length === 0) {
        setdata3((prev)=>[...prev, ...Ddata3]);
      }

    });
  }, []);

  // updates the modifiedData variable when data1, data2, data3 changes
  useEffect(()=>{
        const newModified = JSON.parse(JSON.stringify(modifiedData));
        if(data1.length>0 && !Array.isArray(newModified[years[0]])){
           newModified[years[0]][selectedRow.workFlowId] = data1[1]
        }
        setModifiedData({...newModified});
  }, [data1])


  useEffect(()=>{
    const newModified = JSON.parse(JSON.stringify(modifiedData));
    if(data2.length> 0 && !Array.isArray(newModified[years[1]])){
      newModified[years[1]][selectedRow.workFlowId] = data2[1]
    }
    setModifiedData(newModified);
  }, [data2])


  useEffect(()=>{
    const newModified = JSON.parse(JSON.stringify(modifiedData));
    if(data3.length > 0 && !Array.isArray(newModified[years[2]])){
      newModified[years[2]][selectedRow.workFlowId] = data3[1]
    }
    setModifiedData(newModified);
  }, [data3])

  // This function used to check is there any variance in previous month and present month
  const coloringColumns = (data)=>{
    // Sometimes we are getting data as single object due to user forgot to enter previous month data so we adding default previous month data here
    // Sometimes we are getting data as single object due to user forgot to enter previous month data so we adding default previous month data here
    // if(data!==null && data.length==1){
    //   data.unshift({
    //     "month": selectedRow.executionMonthYear[1]==1? 11 : selectedRow.executionMonthYear[1]-2,
    //     "planMonth": monthArr[selectedRow.executionMonthYear[1]==1? 11 : selectedRow.executionMonthYear[1]-2],
    //     "planYear": selectedRow.executionMonthYear[1]==1? selectedRow.executionMonthYear[0]-1 : selectedRow.executionMonthYear[0],
    //     "jan": 0,
    //     "feb": 0,
    //     "mar": 0,
    //     "apr": 0,
    //     "may": 0,
    //     "jun": 0,
    //     "jul": 0,
    //     "aug": 0,
    //     "sep": 0,
    //     "oct": 0,
    //     "nov": 0,
    //     "dec": 0,
    //     "fy": 0,
    //     "workflow_id": selectedRow.workFlowId
    // })
    // }
    if (data.length>1 &&(( months.indexOf(data[0]['planMonth'].toLowerCase())!==11 &&months.indexOf(data[0]['planMonth'].toLowerCase())>months.indexOf(data[1]['planMonth'].toLowerCase())) || ( months.indexOf(data[1]['planMonth'].toLowerCase())==11 && months.indexOf(data[0]['planMonth'].toLowerCase()) == 0))) {
      data[1].key = 1;
      data[0].key = 2;
      data = [data[1], data[0]];
    }
    if (data.length>1 && (months.indexOf(data[0]['planMonth'].toLowerCase())==11 && months.indexOf(data[1]['planMonth'].toLowerCase())==10)) {
      data[1].key = 1;
      data[0].key = 2;
      data = [data[1], data[0]];
    }
    setTimeout(()=>{
      data.length>1 && data!==null && months.forEach((m, i) => {
        if ( Math.abs((+data[0][m] - +data[1][m])/+data[0][m])* 100>10) {
          if(document.getElementsByClassName(`${selectedYear}-${m}`)[0]!==undefined){
            document.getElementsByClassName(`${selectedYear}-${m}`)[0].style.borderColor='red';
          }
          if(document.getElementsByClassName(`${selectedYear}-${m}-data`)[1]!==undefined){
            document.getElementsByClassName(`${selectedYear}-${m}-data`)[1].classList.add('redColorAfterSave')
          }
        }  else {
          if(document.getElementsByClassName(`${selectedYear}-${m}`)[0]!==undefined){
            document.getElementsByClassName(`${selectedYear}-${m}`)[0].style.borderColor='';
          }
        }

      });
    }, 800)
  }

  // const history = useHistory()
  
  const roleId = useSelector((state)=> state?.roleBasedAccess?.currentRoleId);

  useEffect(()=>{
    if(((selectedRow.isHistoricalDataEnabled==true && value==2) || (selectedRow.isHistoricalDataEnabled==false && value==2))){
      const Ddata1 = [];
    const Ddata2 = [];
    const Ddata3 = [];
    Object.values(modifiedData).map((mobj, index)=>{

      Object.values(mobj).map((sobj)=>{
        if (index == 0) {
          Ddata1.push(sobj);
        }
        if (index == 1) {
          Ddata2.push(sobj);
        }
        if (index == 2) {
          Ddata3.push(sobj);
        }
      });

    });
    if(selectedYear == years[0]){
      coloringColumns(selectedRow.status=='NEW'?data:Ddata1)
    }
    if(selectedYear == years[1]){
      coloringColumns(selectedRow.status=='NEW'?data:Ddata2)
    }
    if(selectedYear == years[2]){
      coloringColumns(selectedRow.status=='NEW'?data:Ddata3)
    }
    }
  //  }

  }, [modifiedData, data, value])

  // const buyplanStatus = useSelector((state) => state.btnStatus.status);
  // useEffect(() => {
  //   // console.log(buyplanStatus);
  //   if ((buyplanStatus && (buyplanStatus != 'NEW' ) && selectedRow.prevWorkFlowId>0) || selectedRow.status== 'CLOSED') { // && selectedRow?.status != 'DRAFT'
  //     setTabsEnableCheck((prev) => {
  //       return {...prev, buyPlan: true};
  //     });
  //   } else {
  //     setTabsEnableCheck((prev) => {
  //       return {...prev, buyPlan: false};
  //     });
  //   }
  // }, [buyplanStatus]);


  const [buyplanAccess, setBuyplanAccess] = useState('')
  const [AuditAccess, setAuditAccess] = useState('')

  const [SupplierAccess, setSupplierAccess] = useState('')
  const [CoverageAccess, setCoverageAccess] = useState('')
  const [CoverageSummaryAccess, setCoverageSummaryAccess] = useState('')
  const [ForecastPreviousMonthAccess, setForecastPreviousMonthAccess] = useState('')
  const [BuyplanPreviousMonthAccess, setBuyplanPreviousMonthAccess] = useState('')

  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  // const userid=useSelector((state)=>state?.roleBasedAccess?.userId);

  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Buy_Plan_Creation']) {
      setBuyplanAccess(functionalityAccess['Buy_Plan:Buy_Plan_Creation']);
    }

    if (functionalityAccess && functionalityAccess['Buy_Plan:Audit_Tab']) {
      setAuditAccess(functionalityAccess['Buy_Plan:Audit_Tab']);
    }

    else if (functionalityAccess && functionalityAccess['Buy_Plan:Buy_Plan_Comments']) {
      setBuyplanAccess(functionalityAccess['Buy_Plan:Buy_Plan_Comments']);
    }
    else if (functionalityAccess && functionalityAccess['Buy_Plan:Buy_Plan_Review']) {
      setBuyplanAccess(functionalityAccess['Buy_Plan:Buy_Plan_Review']);
    }
    else{
      setBuyplanAccess('');
    }
    if (functionalityAccess && functionalityAccess["Buy_Plan:Coverage"]) {
      setCoverageAccess(functionalityAccess["Buy_Plan:Coverage"]);
    }
    else{
      setCoverageAccess('');
    }
    if (functionalityAccess && functionalityAccess['Buy_Plan:Supplier_Master']) {
      setSupplierAccess(functionalityAccess['Buy_Plan:Supplier_Master']);
    }
    else{
      setSupplierAccess('');
    }

    if (functionalityAccess && functionalityAccess['Forecast:Previous_Month']) {
      setForecastPreviousMonthAccess(functionalityAccess['Forecast:Previous_Month']);
    }
    else{
      setForecastPreviousMonthAccess('');
    }

    if (functionalityAccess && functionalityAccess['Buy_Plan:Previous_Month']) {
      setBuyplanPreviousMonthAccess(functionalityAccess['Buy_Plan:Previous_Month']);
    }
    else{
      setBuyplanPreviousMonthAccess('');
    }
    if (currentRole=='Forecast Creator') {
      setCoverageSummaryAccess('none');
    }
  }, [functionalityAccess, currentRole]);



  useEffect(()=>{
    if(selectedYear!==null && selectedYear!==undefined){
      if(yearCount.length<3){
        setYearCount(prev=>[...prev, selectedYear])
      }
      else{
        setYearCount([yearCount[1],yearCount[2], selectedYear])
      }
    }
  }, [selectedYear])


  return (<Box>
    <Grid container sx={gridCSS.grid} rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
    <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>PlayBook Name</Typography>
        <Box sx={{display: "flex", gap: '10px', alignItems: 'center'}}>
          <MUILink href={`/target?role=${roleId}&sector-group=${selectedRow?.sector}&playbook-name=${wfDetails?.playbook_name}`} target="_blank" rel="noopener" style={{ cursor: 'pointer'}}>
            <Typography sx={gridCSS.text}>{wfDetails?.playbook_name}</Typography>
          </MUILink>
          {wfDetails?.playbook_name && <InfoTooltipIcon hoverTitle='Click on the Playbook name to view Playbook target' />}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Buy Plan Name</Typography>
        <Box sx={{display: "flex", gap: '10px', alignItems: 'center'}}>
          <MUILink href={`/target?role=${roleId}&sector-group=${selectedRow?.sector}&playbook-name=${wfDetails?.playbook_name}&buyplan-name=${wfDetails?.buyplan_name}`} target="_blank" rel="noopener" style={{ cursor: 'pointer'}}>
            <Typography sx={gridCSS.text}>{wfDetails?.buyplan_name}</Typography>
          </MUILink>
          {wfDetails?.buyplan_name && <InfoTooltipIcon hoverTitle='Click on the Buy Plan name to view Buy Plan target' />}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Buy Plan Details</Typography>
        <Typography sx={gridCSS.text}>{selectedRow?.commodity} - {selectedRow?.subCommodity} - {selectedRow?.sector} - {selectedRow?.region}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Currency</Typography>
        <Typography sx={gridCSS.text}>{selectedRow?.currency}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>UoM</Typography>
        <Typography sx={gridCSS.text}>{selectedRow?.measuringUnit}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Optionality</Typography>
        <Typography sx={gridCSS.text}>{selectedRow?.optionality? "Yes" : "No"}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Hedging Mechanism</Typography>
        <Typography sx={gridCSS.text}>{hedgingMechanism}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Execution Month</Typography>
        <Typography sx={gridCSS.text}>{selectedRow?.executionMonthYear && `${(titleCase(months[selectedRow?.executionMonthYear[1]-1]))} ${selectedRow?.executionMonthYear[0]}` }</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Buy Plan Creator</Typography>
        <Typography sx={gridCSS.text}>{wfDetails?.buyplan_creator_name}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={12/5}>
        <Typography sx={gridCSS.head}>Buy Plan Approver</Typography>
        <Typography sx={gridCSS.text}>{wfDetails?.buyplan_approver_name}</Typography>
      </Grid>
    </Grid>
    <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '8px'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider',marginTop:"20px",height:"22px",marginBottom:"7.5px",border:"none", alignItems: 'center'}}>
        <Tabs value={value}
          textColor="primary"
          indicatorColor="secondary"
          onChange={handleChange} aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              top: 32,
            }
          }}
          sx={{
            gap: '24px',
          }}>

          <Tab  label="Previous Month Forecast" sx={{minWidth: '0px', textTransform: 'capitalize',padding:"0px", fontSize: '13px', fontWeight: 600, display: (selectedRow.isHistoricalDataEnabled==false || currentRole=='Forecast Creator') && 'none'}}  />  {/* display: (selectedRow.isHistoricalDataEnabled==false || selectedRow.status ==='CLOSED') && 'none'  */}
          <Tab  disabled={!tabsEnableCheck.historical} label="Historical Coverage" sx={{minWidth: '0px', textTransform: 'capitalize',padding:"0px", fontSize: '13px', fontWeight: 600, display: (selectedRow.isHistoricalDataEnabled==false || currentRole=='Forecast Creator') && 'none'}}  />
          <Tab disabled={!tabsEnableCheck.forecast} label="Forecast Update" sx={{ minWidth: '0px', textTransform: 'capitalize', padding: "0px", fontSize: '13px', fontWeight: 600 }} {...a11yProps(0)} />{/* display: selectedRow.coverageMode ==='TREASURY' && 'none' */}



          <Tab disabled={!tabsEnableCheck.buyPlan || years.length==0} label="Buy Plan" sx={{ minWidth: '0px', display: (buyplanAccess!=='Edit' &&  buyplanAccess!=='Read') && 'none' , textTransform: 'capitalize', padding: "0px", fontSize: '12px', fontWeight: 600 }} {...a11yProps(1)} />
          <Tab label="Supplier Master" disabled={selectedRow.status !== 'APPROVED' && selectedRow.status !== 'SUSPENDED' && selectedRow.status !== 'CLOSED'} sx={{ minWidth: '0px', textTransform: 'capitalize', padding: "0px", fontSize: '13px', fontWeight: 600, display: (selectedRow.coverageMode === 'TREASURY' || selectedRow.coverageMode === 'NONE' || (SupplierAccess!=='Edit' &&  SupplierAccess!=='Read') ) && 'none' }} />
          <Tab disabled={!tabsEnableCheck.coverage || selectedRow.status==='SUBMITTED' || selectedRow.status==='DRAFT' || selectedRow.status==='REJECTED' || selectedRow.status==='NEW' || !(buttonStatus==='view' || selectedRow.status==='APPROVED' || selectedRow.status==='CLOSED')}  label="Historical Sector Coverages" sx={{minWidth: '0px', display: ((CoverageAccess!=='Edit' &&  CoverageAccess!=='Read') || (selectedRow.isHistoricalDataEnabled==false || currentRole=='Forecast Creator')) && 'none', textTransform: 'capitalize',padding:"0px", fontSize: '12px', fontWeight: 600}} {...a11yProps(3)} />

          <Tab disabled={!tabsEnableCheck.coverage || selectedRow.status==='SUBMITTED' || selectedRow.status==='DRAFT' || selectedRow.status==='REJECTED' || selectedRow.status==='NEW' || !(buttonStatus==='view' || selectedRow.status==='APPROVED' || selectedRow.status==='CLOSED')} label="Coverages" sx={{minWidth: '0px', display: (CoverageAccess!=='Edit' &&  CoverageAccess!=='Read') && 'none', textTransform: 'capitalize',padding:"0px", fontSize: '12px', fontWeight: 600}} {...a11yProps(3)} />

          <Tab label="Coverage Summary" disabled={selectedRow.status==='SUBMITTED' || selectedRow.status==='DRAFT' || selectedRow.status==='REJECTED' || selectedRow.status==='NEW' || !(buttonStatus==='view' || selectedRow.status==='APPROVED' || selectedRow.status==='CLOSED')} sx={{minWidth: '0px', display: (CoverageSummaryAccess=='none') && 'none', textTransform: 'capitalize',padding:"0px", fontSize: '12px', fontWeight: 600}} {...a11yProps(4)} />
          <Tab disabled={!tabsEnableCheck.buyPlan} label="Audit" sx={{ minWidth: '0px', display: (AuditAccess!=='Edit' &&  AuditAccess!=='Read') && 'none' , textTransform: 'capitalize', padding: "0px", fontSize: '12px', fontWeight: 600 }} {...a11yProps(5)} />

        </Tabs>

      </Box>
     {(value==0 || value==1 || value==2) &&  <Box sx={ {border: '1px solid #dbdbdb', padding: '1px', borderRadius: '3px', margin: '0px 0px', width: '100px', whiteSpace: 'nowrap', alignSelf: 'flex-end'}}>
        { value==0 && years.length && years.map((val) => <button onClick={() => {
            setTimeout(() => setSelectedYear(val), 500)
            // setSelectedYear(val)
            }} style={selectedYear == val ? gridCSS.activeButton : gridCSS.inActiveButton}>{val}</button>)}


        { value==1&& years.length && years.map((val) => <button onClick={() => {
            setColumData(val);
            setTimeout(() => setSelectedYear(val), 500)
            // setSelectedYear(val)
            }} style={selectedYear == val ? gridCSS.activeButton : gridCSS.inActiveButton}>{val}</button>)}


          { value==2 && years.length && years.map((val) => <button onClick={() => {

            setTimeout(() => setSelectedYear(val), 500)

            }} style={selectedYear == val ? gridCSS.activeButton : gridCSS.inActiveButton}>{val}</button>)}


        </Box>
}

      <TabPanel style={{padding: 0}} className="forecast-container" value={value} index={2}>
      <ForecastTable  wfDetails={wfDetails} setYears={setYears} setisPreviousYearData={setisPreviousYearData} isPreviousYearData={isPreviousYearData} setExecMonthDecider={setExecMonthDecider} execMonthDecider={execMonthDecider} ForecastPreviousMonthAccess={ForecastPreviousMonthAccess} quarters={quarters} previousSelectedYearTracker={previousSelectedYearTracker} setSelectedYear={setSelectedYear} data1={data1} data2={data2} data3={data3} setdata1={setdata1} setdata2={setdata2} setdata3={setdata3} subMissionData={subMissionData} setSubmissionData={setSubmissionData} years={years} setTabsEnableCheck={setTabsEnableCheck} isForecastUpdateTabEditable={isForecastUpdateTabEditable}  data={data} setData={setData} selectedRow={selectedRow} setModifiedData={setModifiedData} selectedYear={selectedYear} modifiedData={modifiedData} yearCount={yearCount} />
      </TabPanel>
      {/* {selectedRow.prevWorkFlowId == -1 && <> */}

        <TabPanel style={{padding: 0, display: (selectedRow.isHistoricalDataEnabled==false) && 'none'}} className="forecast-container" value={value} index={0} >
        <PreviousMonthTable2  wfDetails={wfDetails} setDataTrigger={setDataTrigger} dataTrigger={dataTrigger} newWfID={newWfID} setValue={setValue} years={years} setTabsEnableCheck={setTabsEnableCheck} data={data} setData={setData} selectedRow={selectedRow} setModifiedData={setModifiedData} selectedYear={selectedYear} modifiedData={modifiedData} PreviousYearData1={PreviousYearData1} PreviousYearData2={PreviousYearData2} PreviousYearData3={PreviousYearData3} setPreviousYearData1={setPreviousYearData1} setPreviousYearData2={setPreviousYearData2} setPreviousYearData3={setPreviousYearData3}/>
      </TabPanel>
      <TabPanel style={{padding: 0, display: (selectedRow.isHistoricalDataEnabled==false) && 'none'}} className="forecast-container" value={value} index={1}>
        <HistoricalCoverage  wfDetails={wfDetails}  setHistoricalTrigger={setHistoricalTrigger} prevNonSuspendedmonth={prevNonSuspendedmonth}
                historicalTrigger={historicalTrigger} setSelectedYear={setSelectedYear} columns={historicalColumns} columns2={historicalColumns2} setDataTrigger={setDataTrigger} dataTrigger={dataTrigger} newWfID={newWfID} setValue={setValue} subMissionData={subMissionData} setSubmissionData={setSubmissionData} years={years} setTabsEnableCheck={setTabsEnableCheck} data={data} setData={setData} selectedRow={selectedRow} setModifiedData={setModifiedData} selectedYear={selectedYear} modifiedData={modifiedData}  PreviousHistoricalCoverageYear1={PreviousHistoricalCoverageYear1} PreviousHistoricalCoverageYear2={PreviousHistoricalCoverageYear2} PreviousHistoricalCoverageYear3={PreviousHistoricalCoverageYear3} setPreviousHistoricalCoverageYear1={setPreviousHistoricalCoverageYear1} setPreviousHistoricalCoverageYear2={setPreviousHistoricalCoverageYear2} setPreviousHistoricalCoverageYear3={setPreviousHistoricalCoverageYear3}/>
      </TabPanel>
      {/* </>
      } */}
      <TabPanel style={{padding: 0}} className="buyplan-container" value={value} index={3}>
        <EditBuyPlanTable checkHedgingHasSupplier={hedgingMechanism?.toLowerCase().includes('supplier')} execMonthDecider={execMonthDecider} setExecMonthDecider={setExecMonthDecider} isPreviousYearData={isPreviousYearData}  wfDetails={wfDetails} setModifiedData={setModifiedData} BuyplanPreviousMonthAccess={BuyplanPreviousMonthAccess} currentRole={currentRole} years={years} setIsPopupVisible={setIsPopupVisible} quarterMonths={quarterMonths} columnsStatus={columnsStatus} quarters={quarters} changeStatus={changeStatus} selectedArr={selectedArr} setSelectedArr={setSelectedArr} initialArr={initialArr} setInitialArr={setInitialArr} modifiedData={modifiedData} selectedRow={selectedRow} setYears={setYears} setTabsEnableCheck={setTabsEnableCheck} setValue={setValue}/>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <SupplierMaster  wfDetails={wfDetails} selectedRow={selectedRow} ></SupplierMaster>
        {/* <CoverageSummary selectedRow={selectedRow} selectedYear={selectedYear} allYears={years}/> */}
      </TabPanel>

      <TabPanel className="coverage-container" value={value} index={5} sx={{display: (selectedRow.isHistoricalDataEnabled==false || currentRole=='Forecast Creator') && 'none'}}>
        <HistoricalCoverageMokup  wfDetails={wfDetails} prevNonSuspendedmonth={prevNonSuspendedmonth} selectedRow={selectedRow} selectedYear={selectedYear} allYears={years}/>
      </TabPanel>
      <TabPanel className="coverage-container" value={value} index={6}>
        <CoverageMockup  wfDetails={wfDetails} selectedRow={selectedRow} selectedYear={selectedYear} allYears={years}/>
      </TabPanel>
      <TabPanel className="coverage-summary-container" value={value} index={7}>
        <CoverageSummary wfDetails={wfDetails} selectedRow={selectedRow} setIsPopupVisible={setIsPopupVisible} selectedYear={selectedYear} allYears={years}/>
      </TabPanel>

      <TabPanel className="coverage-summary-container" value={value} index={8}>
        <Audit wfDetails={wfDetails} selectedRow={selectedRow}/>
      </TabPanel>
    </Box>
  </Box>
  );
})

export default function AppMainDialog({open, handleClose,setIsPopupVisible}) {
  const selectedRow = useContext(RowContext)
  return (
    <React.Fragment>
      <Dialog
        // fullWidth={true}
        // maxWidth={'xl'}
        fullScreen
        open={open}
        onClose={() => {}}
        TransitionComponent={Transition}
        // sx={gridCSS.tabContainer}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#215094', height: '50px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Toolbar>
            <Button startIcon={<ArrowBackIcon />} color='inherit' sx={{textTransform: 'none','&:hover': {backgroundColor: 'transparent'}}} onClick={handleClose}>
              <Typography>Back</Typography>
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={
          {
            height: '90vh',
            position:"relative",
            paddingTop: "40px",
            paddingBottom: "20px",
          }
        }>
          {/* <Box sx={{position:"absolute", top: "5px", right: "10px", cursor:"pointer"}} onClick={() => handleClose()}><CloseIcon /></Box> */}
          <TabsComponent selectedRow={selectedRow}  setIsPopupVisible={setIsPopupVisible} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}