// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*   
  .subHeader{
    background-color: rgb(26,74,147);
    display: flex;
    padding: 10px;
    color: white;
  }
  .firstSubHeaderDiv {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    font-size: 14px;
  }
  .firstSubHeaderDivClass{
    cursor: pointer;
  }
  .firstSubHeaderDivClass a{
    text-decoration: none;
    color: white;
  }
  .secondSubHeaderDiv{
    width: 70%;
  } */
  `, "",{"version":3,"sources":["webpack://./src/Components/Header/SubHeader/SubHeader.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;KAsBK","sourcesContent":["/*   \n  .subHeader{\n    background-color: rgb(26,74,147);\n    display: flex;\n    padding: 10px;\n    color: white;\n  }\n  .firstSubHeaderDiv {\n    width: 30%;\n    display: flex;\n    justify-content: space-evenly;\n    font-size: 14px;\n  }\n  .firstSubHeaderDivClass{\n    cursor: pointer;\n  }\n  .firstSubHeaderDivClass a{\n    text-decoration: none;\n    color: white;\n  }\n  .secondSubHeaderDiv{\n    width: 70%;\n  } */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
