/* eslint-disable camelcase */
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import BuyPlanReportTable from './BuyPlanReportTable';
import BuyPlanReportTableDisplay from './BuyPlanReportDisplay';
import config from '../../../Utils/Config';
const BuyPlanReport = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);

  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);
  const [ActiveCategories, setActiveCategories] = useState([]);
  const [ActiveCommodities, setActiveCommodities] = useState([]);
  const [ActiveSubCommodities, setActiveSubCommodities] = useState([]);
  const [ActiveCategoriesList, setActiveCategoriesList] = useState([]);
  const [ActiveCommoditiesList, setActiveCommoditiesList] = useState([]);
  const [ActiveSubCommoditiesList, setActiveSubCommoditiesList] = useState([]);
  const [allCommodities, setAllCommodities] = useState([]);
  const [allSubCommodities, setAllSubCommodities] = useState([]);

  const heads = [
    'Buy Plan ID',
    'Buy Plan Name',
    'Sector',
    'Country',
    'Category',
    'Commodity',
    'Sub Commodity',
    'Reporting Sector*',
    'Reporting Country*',
    'Reporting Commodity*',
    'Reporting Sub Commodity*',
    'Reporting Business Unit*',
    'Last Edited By',
    'Last Edited On',

  ];

  const values = [
    'buy_plan_id',
    'buy_plan_name',
    'sector',
    'country',
    'category',
    'commodity',
    'sub_commodity',
    'reporting_sector',
    'reporting_region',
    'reporting_commodity',
    'reporting_sub_commodity',
    'reporting_bu',
    'last_edited_by',
    'last_edited',
    'business_unit_id',
    'standard_unit_id',
    'currency',
    'currency_id',
  ];

  const sortValues = [
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
  ];


  const selectedDropDownData = useSelector((state)=>state?.configurations?.selectedBuyPlanReportDropDown);
  const [groupedData, setgroupedData] = useState([]);

  const updateSingleUnitMaster = ConfigurationServices.
      updateBuyplanReportData;
  const newRowUpdate = ConfigurationServices.postPlayBookData;


  const [pagination, setPagination] = useState(10);


  const [addingTrigger, setAddingTrigger] = useState(false);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);
  useEffect(()=>{
    if (SortValue!=='') {
      if (SortValue!=='last_edited') {
        if (sorttype === 'asc') {
          row.sort((a, b) =>
            (a[SortValue]==null?'':a[SortValue])?.localeCompare((b[SortValue]==null?'':b[SortValue])));
        } if (sorttype === 'desc') {
          row.sort((a, b) =>
            (b[SortValue]==null?'':b[SortValue])?.localeCompare((a[SortValue]==null?'':a[SortValue])));
        }
      } else {
        if (sorttype === 'asc') {
          row.sort((a, b) => Date.parse(a.last_edited==null? '01-Jan-1970' : a.last_edited) - Date.parse(b.last_edited==null? '01-Jan-1970' : b.last_edited));
        } if (sorttype === 'desc') {
          row.sort((a, b) => Date.parse(b.last_edited==null? '01-Jan-1970' : b.last_edited) - Date.parse(a.last_edited==null? '01-Jan-1970' : a.last_edited));
        }
      }

      const data = {};
      row.map((r, i)=>{
        if (!data[`id${r.buy_plan_id}`]) {
          data[`id${r.buy_plan_id}`] = [{...r}];
        } else {
          data[`id${r.buy_plan_id}`].push({...r});
        }
      });
      const sortedDatas = [];
      Object.keys(data).length>0 && Object.keys(data).map((d)=>{
        sortedDatas.push(data[d]);
      });
      setTableFilterData([...sortedDatas]);
    }
  }, [SortValue, sorttype]);

  useEffect(()=>{
    ConfigurationServices.getCategoryData()
        .then((res)=>{
          const activeCategories = [];
          res.map((r)=>{
            r.is_active === true && activeCategories.push(r);
          });
          setActiveCategories([...activeCategories]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCommodityData()
        .then((res)=>{
          const activeCommodities = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rs)=>{
              rs.is_active===true && !activeCommodities.includes(r) && activeCommodities.push(r);
            });
          });
          if (activeCommodities.length>0) {
            const commodityNames = [];
            const allCommodities = [];
            activeCommodities.map((obj)=>{
              obj.commodities?.map((ob)=>{
                if (!commodityNames.includes(ob.name) && ob.is_active==true) {
                  commodityNames.push(ob.name);
                  allCommodities.push(ob);
                }
              });
            });
            setAllCommodities([...allCommodities]);
          }
          setActiveCommodities([...activeCommodities]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getSubCommodityData()
        .then((res)=>{
          const activeSub = [];
          res['commodityGroups'].map((r)=>{
            r.commodities.map((rc)=>{
              rc.subCommodities.map((rs)=>{
                rs.is_active==true && !activeSub.includes(rc) && activeSub.push(rc);
              });
            });
          });


          if (activeSub.length>0) {
            const subCommodityNames = [];
            const allSubCommodities = [];
            activeSub.map((obj)=>{
              obj.subCommodities?.map((ob)=>{
                if (!subCommodityNames.includes(ob.name) && ob.is_active==true) {
                  subCommodityNames.push(ob.name);
                  allSubCommodities.push(ob);
                }
              });
            });
            setAllSubCommodities([...allSubCommodities]);
          }
          setActiveSubCommodities([...activeSub]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getBuyPlanReportData()
        .then((res)=>{
          setTimeout(()=>{
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                setRow([...res]);
                setgroupedData([]);
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
            setPage(0);
          }, 1000);
        }).catch((err)=>{
          console.log(err);
        });
  }, [addingTrigger]);


  useEffect(()=>{
    if (row.length>0) {
      const data = {};
      row.map((r, i)=>{
        if (!data[`id${r.buy_plan_id}`]) {
          data[`id${r.buy_plan_id}`] = [{...r}];
        } else {
          data[`id${r.buy_plan_id}`].push({...r});
        }
      });
      Object.keys(data).length>0 && Object.keys(data).map((d)=>{
        setgroupedData((prev)=>[...prev, data[d]]);
      });
    }
  }, [row]);


  useEffect(()=>{
    let temp = [...groupedData];
    if (Object.keys(selectedDropDownData).length!==0) {
      Object.entries(selectedDropDownData).map((obj, index)=>{
        if (obj[0] == 'sector' || obj[0] == 'country' || obj[0] == 'reporting_bu') {
          if (obj[1].length!==0) {
            const temporaryData = [];
            temp.map((r)=>{
              let count = 0;
              r.map((rf)=>{
                if (obj[1].map((ob)=> ob?.toLowerCase()).includes(rf[obj[0]]?.toLowerCase())) {
                  count+=1;
                }
              });
              if (count>0) {
                temporaryData.push(r);
              }
            });
            temp = temporaryData;
          }
        } else {
          if (obj[1].length!==0) {
            temp = temp.filter((r)=>{
              return obj[1].map((ob)=> ob?.toLowerCase()).includes(r[0][obj[0]]?.toLowerCase());
            });
          }
        }
      });
      setTableFilterData([...temp]);
      setPage(0);
    } else {
      setTableFilterData([...groupedData]);
    }
  }, [selectedDropDownData, row, groupedData]);


  const [ActiveMasterSectors, setActiveMasterSectors] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getMasterSectorData()
        .then((res)=>{
          const activeMasterSectors = [];
          res.map((r)=>{
            r.is_active === true && activeMasterSectors.push(r);
          });
          setActiveMasterSectors([...activeMasterSectors]);
        });
  }, []);


  const [ActiveSectors, setActiveSectors] = useState([]);
  const [allSectors, setAllSectors] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getSectordata()
        .then((res)=>{
          const activeSectors = [];
          res['commodityGroups'].map((r)=>{
            r.sectors.map((rs)=>{
              rs.active === true && !activeSectors.includes(r) && activeSectors.push(r);
            });
          });
          if (activeSectors.length>0) {
            const sectorNames = [];
            const allSectors = [];
            activeSectors.map((obj)=>{
              obj.sectors?.map((ob)=>{
                if (!sectorNames.includes(ob.name) && ob.active==true) {
                  sectorNames.push(ob.name);
                  allSectors.push(ob);
                }
              });
            });
            setAllSectors([...allSectors]);
          }
          setActiveSectors([...activeSectors]);
        });
  }, []);

  const [country, setcountry] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getCountrysData()
        .then((res)=>{
          const contr = [];
          res['commodityGroups'].map((rs)=>{
            rs.is_active==true && !contr.includes(rs) && contr.push(rs);
          });
          setcountry([...contr]);
        });
  }, []);

  const [businessUnit, setbusinessUnit] = useState([]);
  useEffect(()=>{
    ConfigurationServices.getBusinessUnitdata()
        .then((res)=>{
          const bu = [];
          res['commodityGroups'].map((rs)=>{
            rs.is_active===true && bu.push(rs);
          });
          setbusinessUnit(bu);
        });
  }, []);
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);


  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Reports:BuyPlanReporting']) {
        setAccessControl(functionalityAccess['Reports:BuyPlanReporting']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [countryList, setCountryList] = useState([]);
  const [ActiveSectorsList, setActiveSectorsList] = useState([]);

  useEffect(() => {
    if (!row || row.length === 0) {
      return;
    }

    // making list of unique master sector that are present in the table
    const uniqueSectorMaster = new Set(row.filter((obj) => obj.master_sector !== null && obj.master_sector !== '').map((obj) => obj.master_sector));
    let matchingObjects = ActiveMasterSectors.filter((obj) => uniqueSectorMaster.has(obj.master_sector));

    // making list of unique sectors that are present in the table
    const uniqueSector = new Set(row.filter((obj) => obj.sector !== null && obj.sector !== '').map((obj) => obj.sector));
    const sectors = ActiveSectors.flatMap((activeSector) => {
      return activeSector.sectors.map((sector) => ({
        ...sector,
        masterSectorName: activeSector.name,
      }));
    });
    matchingObjects = sectors.filter((obj) => uniqueSector.has(obj.name));
    setActiveSectorsList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueRegions = new Set(row.filter((obj) => obj.country !== null && obj.country !== '').map((obj) => obj.country));
    matchingObjects = country.filter((obj) => uniqueRegions.has(obj.region));
    setCountryList(matchingObjects);

    // making list of unique regions that are present in the table
    const uniqueCategories = new Set(row.filter((obj) => obj.category !== null && obj.category !== '').map((obj) => obj.category));
    matchingObjects = ActiveCategories.filter((obj) => uniqueCategories.has(obj.category));
    setActiveCategoriesList(matchingObjects);

    // making list of unique commodities that are present in the table
    const uniqueCommodity = new Set(row.filter((obj) => obj.commodity !== null && obj.commodity !== '').map((obj) => obj.commodity));
    const commodity = ActiveCommodities.flatMap((activeCommodity) => {
      return activeCommodity.commodities.map((commodity) => ({
        ...commodity,
        categoryName: activeCommodity.name,
      }));
    });
    matchingObjects = commodity.filter((obj) => uniqueCommodity.has(obj.name));
    setActiveCommoditiesList(matchingObjects);

    // making list of unique subcommodities that are present in the table
    const uniqueSubCommodity = new Set(row.filter((obj) => obj.sub_commodity !== null && obj.sub_commodity !== '').map((obj) => obj.sub_commodity));
    const subCommodity = ActiveSubCommodities.flatMap((activeSubCommodity) => {
      return activeSubCommodity.subCommodities.map((subCommodity) => ({
        ...subCommodity,
        commodityName: activeSubCommodity.name,
      }));
    });
    matchingObjects = subCommodity.filter((obj) => uniqueSubCommodity.has(obj.name));
    setActiveSubCommoditiesList(matchingObjects);
  }, [row]);


  const [page, setPage] = React.useState(0);
  return (
    <Box
      sx={{
        bgcolor: '#eff6fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0px',
      }}
    >
      {(accessControl === Edit || accessControl === Read) && <Box
        sx={theme.palette.whitebgbox}
      >
        <BuyPlanReportTableDisplay
          row={row}
          setPage={setPage}
          setPagination={setPagination}
          ActiveSectors={ActiveSectorsList}

          ActiveCategories={ActiveCategoriesList}
          ActiveCommodities={ActiveCommoditiesList}
          country={countryList}
          ActiveSubCommodities={ActiveSubCommoditiesList}
          businessUnit={businessUnit}

        />

        <Box sx={{}}>

          <BuyPlanReportTable
            data={tableFilterData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            sortValues={sortValues}
            accessControl={accessControl}
            values={values}
            addingTrigger={addingTrigger}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            country={country}
            businessUnit={businessUnit}
            setPage={setPage}
            page={page}
            ActiveCategories={ActiveCategories}
            ActiveCommodities={ActiveCommodities}
            ActiveSubCommodities={ActiveSubCommodities}
            groupedData={groupedData}
            ActiveSectors={ActiveSectors}
            allSectors={allSectors}
            allCommodities={allCommodities}
            allSubCommodities={allSubCommodities}
          />


          {(errMsg !=='' || tableFilterData.length == 0) &&
        <ErrorMessage message='Buy Plan Report' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
    </Box>
  );
};

export default BuyPlanReport;
