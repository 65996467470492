// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-style-class {
    width: 100%;
    height: inherit;
    border: 'none';
    margin: 0px;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Reports/PowerBi/PowerBi.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,cAAc;IACd,WAAW;EACb","sourcesContent":[".report-style-class {\n    width: 100%;\n    height: inherit;\n    border: 'none';\n    margin: 0px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
