/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Box, Checkbox, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {setSelectedCurrencyConversion, setSortType} from '../../../redux/features/configurations';
import {useDispatch} from 'react-redux';
import customealltheme from '../../../theme';
import _ from 'lodash';

const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
};

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

function CurrencyConversionDisplay({row, setFiltersResetClicked, setSubmitClicked}) {
  const [localUnitarray, setLocalUnitArray] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [planMonthDropdown, setPlanMonthDropdown] = useState([]);
  const [currencyDropdown, setCurrencyDropdown] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setPlanMonthDropdown(_.orderBy(
        _.uniqBy(row, 'conversion_month_year') || [],
        [(item) => new Date('01 ' + item.conversion_month_year)],
        ['desc'],
    ));
    setCurrencyDropdown(_.orderBy(_.uniqBy(row, 'local_currency') || [], ['local_currency'], ['asc']));
  }, [row]);

  const handleSubmit = () => {
    dispatch(setSelectedCurrencyConversion({localUnitarray: localUnitarray, selectedMonth: selectedMonth,
    }));
    setSubmitClicked((prevValue) => !prevValue);
  };

  const handleMonthChange = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'all_month') {
      const str = ['all_month'];
      planMonthDropdown?.map((r) => (
        str.push(r?.conversion_month_year)
      )),

      setSelectedMonth(str);
    } else if (selectedMonth.includes('all_month') && !value.includes('all_month')) {
      setSelectedMonth([]);
    } else {
      if (selectedMonth.includes('all_month')) setSelectedMonth([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('all_month')) tepVal.splice(tepVal.indexOf('all_month'), 1);
      setSelectedMonth(tepVal);
    }
  };

  const handleChangeLocalUnit = (event) => {
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'unitDes_all') {
      const str = ['unitDes_all'];
      currencyDropdown?.map((r) => (
        str.push(r?.local_currency)
      )),

      setLocalUnitArray(str);
    } else if (localUnitarray.includes('unitDes_all') && !value.includes('unitDes_all')) {
      setLocalUnitArray([]);
    } else {
      if (localUnitarray.includes('unitDes_all')) setLocalUnitArray([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('unitDes_all')) tepVal.splice(tepVal.indexOf('unitDes_all'), 1);
      setLocalUnitArray(tepVal);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: '100%', padding: '18px'}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
          }}
          style={customealltheme.palette.filterheading}
        >
          FX Rates
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '35px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Base Currency
            </Box>
            <Box
              sx={customealltheme.palette.filterDropdown}
            >
              <FormControl fullWidth>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    sx = {theme.selectBox}
                    id="demo-multiple-checkbox"
                    multiple
                    value={localUnitarray}
                    onChange={handleChangeLocalUnit}
                    input={<CustomInput label="Tag" />}
                    displayEmpty
                    renderValue={( selected, displayEmpty) => displayEmpty = localUnitarray.length ? selected.join(', ') :'Select All'}
                    className="newclass"
                  >
                    <MenuItem sx={theme.selectMenuBox} key='unitDes_all' value='unitDes_all' className='this-is-new-class'>
                      <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={localUnitarray?.indexOf('unitDes_all') > -1}/>
                      <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                    </MenuItem>
                    {currencyDropdown?.length !== 0 &&
                            _.orderBy(currencyDropdown, [(sec) => sec?.local_currency?.toLowerCase()])?.map((r)=>(
                              <MenuItem
                                sx={theme.selectMenuBox}
                                key={r?.currency_conversion_id}
                                value={r?.local_currency}
                                className="this-is-new-class"
                              >
                                <Checkbox
                                  sx={{
                                    '.MuiSvgIcon-root': {
                                      fontSize: '14px',
                                    },
                                  }}
                                  checked={localUnitarray?.indexOf(r?.local_currency) > -1}
                                />
                                <ListItemText
                                  sx={{
                                    '.MuiTypography-root': {
                                      fontSize: '14px',
                                    },
                                  }}
                                  primary={r?.local_currency } />

                              </MenuItem>
                            ))
                    }
                  </Select>
                </FormControl>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Plan Month
            </Box>
            <Box
              sx={customealltheme.palette.filterDropdown}
            >
              <FormControl fullWidth>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    sx = {theme.selectBox}
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    input={<CustomInput label="Tag" />}
                    displayEmpty
                    renderValue={( selected, displayEmpty) => displayEmpty = selectedMonth.length ? selected.join(', ') :'Select All'}
                    className="newclass"
                  >
                    <MenuItem sx={theme.selectMenuBox} key='all_month' value='all_month' className='this-is-new-class'>
                      <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={selectedMonth?.indexOf('all_month') > -1}/>
                      <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                    </MenuItem>
                    {(planMonthDropdown?.length !== 0) &&
                      planMonthDropdown?.map((r) => (
                        <MenuItem sx={theme.selectMenuBox}
                          key={r?.currency_conversion_id}
                          value={r?.conversion_month_year}
                          className="this-is-new-class"
                        >
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={selectedMonth?.indexOf(r?.conversion_month_year) > -1}
                          />
                          <ListItemText
                            sx={{
                              '.MuiTypography-root': {
                                fontSize: '14px',
                              },
                            }}
                            primary={r?.conversion_month_year ? r.conversion_month_year.replace('-', ' ') : ''} />


                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} component="span"></Box>
            <Box sx={{minWidth: 130, marginTop: '28px'}}>
              <Button
                onClick={handleSubmit}
                sx={{
                  'backgroundColor': '#4297d3',
                  'color': '#ffffff',
                  'textTransform': 'none',
                  'minWidth': '0',
                  'padding': '8px 35px',
                  '&:hover': {
                    backgroundColor: '#4297d3',
                  },
                }}
                style={customealltheme.palette.filterSubmit}
              >
                  Submit
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: {
              display: 'inline-block',
              marginRight: '2px',
              font: 'normal normal 600 13px/15px \'Segoe UI\'',
              color: '#3174b6',
              cursor: 'pointer',
            },
            marginTop: '30px',
          }}
          style={customealltheme.palette.resetbutton}
        >
          <p
            onClick={() => {
              dispatch(setSelectedCurrencyConversion([]));
              setLocalUnitArray([]);
              setSelectedMonth([]);
              dispatch(setSortType(''));
              setFiltersResetClicked((prevValue) => !prevValue);
            }}
          >
            Reset Filters
          </p>
        </Box>
      </Box>
      <Box
        sx={{border: '1px solid #f5f5f5', width: '97%', margin: 'auto'}}
      ></Box>
    </ThemeProvider>
  );
}

export default CurrencyConversionDisplay;
