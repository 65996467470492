/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {Box, Button} from '@mui/material';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {textColumn, keyColumn, intColumn,
  DataSheetGrid,
} from 'react-datasheet-grid';
import Styles from '../../styles/workflowModal';
import {utils, writeFileXLSX} from 'xlsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorIcon from '@mui/icons-material/Error';
// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css';
import {AppButton} from '../common/AppButton/AppButton';
import './coverage.css';
import SaveCoverage from './SaveCoverage/SaveCoverage';
import DialogService from '../../services/dialogService';
import {monthArr, roundToTwo} from '../../services/utils';
import config from '../../Utils/Config';
import SupplierServices from '../../services/supplierServices';
import DocumentUpload from '../DocumentUpload/DocumentUpload';
import ConfigurationServices from '../../services/ConfigurationServices';
import {Comments, CommentsTable} from '../EditBuyPlan/Components';
import {updateCommentTagging} from '../../redux/features/apiCall';

const gridCellStyles = {color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};

const EDIT = config.AccessLevel.Edit;

export default function CoverageMockup({wfDetails, selectedRow, selectedYear, allYears}) {
  // const buttonStatus = useSelector((state) => state.btnStatus.value);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [commentsTrigger, setCommentsTrigger] = useState(false);
  const userid=useSelector((state)=>state?.roleBasedAccess?.userId);
  const [commentsData, setCommentsData] = useState([]);
  const [date, setDate] = useState(() => {
    const d = new Date();
    const dd = d.getDate();
    const mm = d.getMonth();
    const fullYear = d.getFullYear();
    return `${dd}-${monthArr[mm]}-${fullYear}`;
  });
  useEffect(()=>{
    getcoverageComments();
  }, [selectedRow, commentsTrigger]);
  const getcoverageComments = async () => {
    await DialogService.getCoverageComments(selectedRow.workFlowId)
        .then((res) => {
          // console.log(res);
          if (res.length) {
            setCommentsData(res);
          }
        });
  };

  const [coverageData, setCoverageData] = useState([[]]);
  const [changeCoverageTable, setChangeCoverageTable] = useState(true);
  const [accessControl, setAccessControl]=useState('');
  const [editaccessControl, seteditaccessControl]=useState('');

  const [coverageVolume, setCoverageVolume] = useState('');
  const [weightedPrice, setWeightedPrice] = useState('');
  const [supplierNames, setSupplierNames] = useState([]);
  const [coveragesSaved, setCoveragesSaved] = useState(false);
  // const [addNewRow, setAddNewRow] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [nextMonthCloseBuyPlanTag, setNextMonthCloseBuyPlanTag] = useState(false);
  const [sectorCountryMap, setSectorCountryMap] = useState(null);
  const isCreator = useSelector((state) => state.roleBasedAccess);

  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const [dataLoadSuccess, setDataLoadSuccess] = useState(false);
  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);
  function updateTotalValues() {
    let totVolume = 0;
    // let totPrice = 0;
    let weightedPrice = 0;
    const currYear = new Date().getFullYear();
    const diff = Number(selectedYear) < currYear ? 0 : Number(selectedYear) - currYear;
    if (coverageData.length > 0) {
      coverageData[diff]?.map((item) => {
        totVolume += Number(item.coverageVolume) || 0;
        // totPrice += Number(item.coveredPrice) || 0;
        weightedPrice += Number(item.coverageVolume * item.coveredPrice) || 0;
      });
    }
    // const Row = {
    //   'executionMonth': null,
    //   'coverageYearMonth': 'Total',
    //   'sourceType': null,
    //   'sourceName': null,
    //   'poNumber': null,
    //   'coverageVolume': String(totVolume).includes('.') ? (totVolume===0 ? 0 : Number(totVolume).toFixed(+wfDetails?.decimal_scale)) : totVolume,
    //   'coverageUnit': null,
    //   'coveredPrice': (weightedPrice/totVolume).toFixed(+wfDetails?.decimal_scale) === 'NaN' ? '0' : (weightedPrice/totVolume).toFixed(+wfDetails?.decimal_scale),
    //   'coveredPriceCurrency': null,
    //   'comments': null,
    // };
    // setTimeout(() => {
    //   coverageData[diff].push(Row);
    //   setCoverageData(coverageData);
    // });
    setWeightedPrice((weightedPrice/totVolume).toFixed(+wfDetails?.decimal_scale) === 'NaN' ? 0 : (+weightedPrice/+totVolume).toString().split('.').length>1? roundToTwo(+wfDetails?.decimal_scale, +weightedPrice/+totVolume).toLocaleString('en-US') : (+weightedPrice/+totVolume).toLocaleString('en-US'));
    setCoverageVolume(totVolume.toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, totVolume).toLocaleString('en-US') : (totVolume===0 ? 0 : Number(totVolume)) );
  }

  useEffect(()=>{
    updateTotalValues();
  }, [coverageData]);

  useEffect(()=>{
    if (coveragesSaved) {
      updateTotalValues();
      setCoveragesSaved(false);
    }
  }, [coveragesSaved]);

  useEffect(()=>{
    const todayRef = new Date();
    const currentYear = todayRef.getFullYear();
    const currentMonth = (todayRef.getMonth()) + 1;
    const currentDate = getCurrentMonthWDCount();
    let flagToUpdate = false;
    const checkYearAndMon = selectedRow.executionMonthYear;
    if (currentYear >= checkYearAndMon[0]) {
      if (currentYear == checkYearAndMon[0]) {
        if (currentMonth===checkYearAndMon[1]) {
          flagToUpdate = false;
        } else if (currentMonth>checkYearAndMon[1]) {
          if (((currentMonth - checkYearAndMon[1]) === 1)) {
            if (currentDate>=6) {
              flagToUpdate = true;
            } else {
              flagToUpdate = false;
            }
          } else {
            flagToUpdate = true;
          }
        }
      } else {
        if ((currentMonth==1 && checkYearAndMon[1]==12) ) {
          if (currentDate>=6) {
            flagToUpdate = true;
          } else {
            flagToUpdate = false;
          }
        } else {
          flagToUpdate = true;
        }
      }
    } else {
      flagToUpdate = true;
    }
    setNextMonthCloseBuyPlanTag(flagToUpdate);
  }, [selectedRow, currentRole]);

  const getCurrentMonthWDCount = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    let worked = 0;
    for (let i = 1; i <= today.getDate(); i++) {
      const testDate = new Date(year, month, i);
      const day = testDate.getDay();
      if (day > 0 && day < 6) {
        ++worked;
      }
    }
    return worked;
  };

  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Forecast_Update']) {
      setAccessControl(functionalityAccess['Buy_Plan:Forecast_Update']);
    }
    if (functionalityAccess && functionalityAccess['Buy_Plan:Coverage']) {
      seteditaccessControl(functionalityAccess['Buy_Plan:Coverage']);
    }
  }, [functionalityAccess]);

  useEffect(()=>{
    if (selectedRow.coverageMode=='SUPPLIER' || selectedRow.coverageMode=='BOTH') {
      SupplierServices.getSupplierData(selectedRow.buyPlanId, selectedRow.workFlowId)
          .then((res)=>{
            setTimeout(()=>{
              if (res !=undefined && typeof res !== 'string') {
                const keys = [];
                res.length>0 && Object.keys(res[0]).map((obj) => {
                  if (obj.split('_').includes('id') && obj.split('_').includes('supplier')) {
                    keys.push(obj);
                  }
                });
                const supplierName = [];
                res.forEach((item) => {
                  if (item.is_active) {
                    supplierName.push({
                      'supplier_id': item.supplier_id,
                      'supplier_name': item.supplier_name,
                    });
                  }
                });
                setSupplierNames(supplierName);
              }
            }, 100);
          });
    }
  }, []);

  const columns = [
    {...keyColumn('executionMonth', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['executionMonth']!==null && (rowData['executionMonth'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['executionMonth']).toLocaleString('en-US'): rowData['executionMonth'].toLocaleString('en-US'))}</div>

      ),
      title: 'Execution Month', disabled: 'true'},
    {...keyColumn('coverageYearMonth', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coverageYearMonth']!==null && (rowData['coverageYearMonth'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coverageYearMonth']).toLocaleString('en-US'): rowData['coverageYearMonth'].toLocaleString('en-US'))}</div>

      ),
      title: 'Coverage Month', disabled: 'true'},
    {...keyColumn('sourceType', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['sourceType']!==null && (rowData['sourceType'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['sourceType']).toLocaleString('en-US'): rowData['sourceType'].toLocaleString('en-US'))}</div>

      ),
      title: 'Source Type', disabled: 'true'},
    {...keyColumn('sourceName', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['sourceName']!==null && (rowData['sourceName']?.toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['sourceName'])?.toLocaleString('en-US'): rowData['sourceName']?.toLocaleString('en-US'))}</div>
      ),
      title: 'Source Name', disabled: 'true'},
    {...keyColumn('sector_id', textColumn),
      component: ({rowData, focus}) => (
        <div style={{color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['sector_id']!==null && (rowData['sector_id'])}</div>
      ),
      title: 'Sector', disabled: 'true'},
    {...keyColumn('region_id', textColumn),
      component: ({rowData, focus}) => (
        <div style={{color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['region_id']!==null && (rowData['region_id'])}</div>
      ),
      title: 'Country', disabled: 'true'},
    {...keyColumn('poNumber', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['poNumber']!==null && (rowData['poNumber'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['poNumber']).toLocaleString('en-US'): rowData['poNumber'].toLocaleString('en-US'))}</div>

      ),
      title: 'Contract/PO Number', disabled: 'true'},
    {...keyColumn('coverageVolume', intColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coverageVolume']!==null && (rowData['coverageVolume'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coverageVolume']).toLocaleString('en-US'): rowData['coverageVolume'].toLocaleString('en-US'))}</div>

      ),
      title: 'Coverage Volume', disabled: 'true'},
    {...keyColumn('coverageUnit', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coverageUnit']!==null && (rowData['coverageUnit'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coverageUnit']).toLocaleString('en-US'): rowData['coverageUnit'].toLocaleString('en-US'))}</div>

      ),
      title: 'Coverage Unit', disabled: 'true'},
    {...keyColumn('coveredPrice', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coveredPrice']!==null && (rowData['coveredPrice'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coveredPrice']).toLocaleString('en-US'): rowData['coveredPrice'].toLocaleString('en-US'))}</div>

      ),
      title: 'Covered Price', disabled: 'true'},
    {...keyColumn('coveredPriceCurrency', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coveredPriceCurrency']!==null && (rowData['coveredPriceCurrency'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coveredPriceCurrency']).toLocaleString('en-US'): rowData['coveredPriceCurrency'].toLocaleString('en-US'))}</div>

      ),
      title: 'Covered Price Currency', disabled: 'true'},
    {...keyColumn('comments', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles} title={rowData?.comments}>{rowData?.comments}</div>

      ),
      title: 'Comments', disabled: 'true'},
  ];
  useEffect(() => {
    if (selectedRow.workFlowId && supplierNames !== null & sectorCountryMap != null) {
      getCall(monthArr, supplierNames);
    };
  }, [selectedRow, supplierNames, sectorCountryMap]);

  function getSourceType(selectedRow) {
    if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others == false) {
      return 'SUPPLIER';
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others == false) {
      return 'TREASURY';
    } else if (selectedRow.coverageMode=='NONE' ) {
      return 'OTHER';
    } else return '';
  }
  function getSourceName(selectedRow) {
    if (selectedRow.coverageMode=='TREASURY') {
      return 'TREASURY';
    } else if (selectedRow.coverageMode=='NONE') {
      return 'OTHER';
    } else return '';
  }
  function capitalizeFirstLetter(stringToModify) {
    const newString = stringToModify.charAt(0).toUpperCase() + stringToModify.slice(1).toLowerCase();
    return newString;
  }
  function getSupplierName(data, element) {
    const sourceType = capitalizeFirstLetter(element.sourceType);
    if (sourceType === 'Treasury' || sourceType === 'Other') {
      return {supplier_name: sourceType};
    } else {
      const supplierObj = data.filter((supplierName) => {
        if (supplierName.supplier_id == element.sourceId) {
          return supplierName;
        }
      });
      return supplierObj[0];
    }
  }

  function getSectorName(sectorId) {
    const sectorObj = sectorCountryMap.filter((sector) => sector.id === sectorId);
    return sectorObj[0]?.name;
  }
  function getCountryName(regionId) {
    let countryName = '';
    sectorCountryMap.forEach((sector) => {
      sector.regionIdList.forEach((region) => {
        if (region.id === regionId) {
          countryName = region.region;
        }
      });
    });
    return countryName;
  }
  const getCall = (month, supplierNames=[]) => {
    const temp = [[]];
    DialogService.getCoverageData(selectedRow.workFlowId).then((res) =>{
      res.sort((a, b) => new Date(a['coverageYearMonth']) - new Date(b['coverageYearMonth']));
      res.map(( element) => {
        element.executionMonth=`${month[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`;
        element.coverageUnit = selectedRow.measuringUnit;
        element.coveredPriceCurrency = selectedRow.currency;
        element.sourceName = getSupplierName(supplierNames, element)?.supplier_name;
        element.sourceType = capitalizeFirstLetter(element.sourceType);
        element['sector_id'] = getSectorName(element.sector_id);
        element['region_id'] = getCountryName(element.region_id);
      });
      for (let index=0; index < allYears.length; index++) {
        temp[index]=res?.filter((entry) => entry);
      }
      if (temp[0].length === 0) {
        const addDefaultEmptyRow = {
          'id': 'f0',
          'executionMonth': `${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`,
          'coverageUnit': selectedRow.measuringUnit,
          'coveredPriceCurrency': selectedRow.currency,
          'coverageVolume': '',
          'coverageYearMonth': '',
          'coveredPrice': '',
          'sourceName': getSourceName(selectedRow),
          'sourceType': capitalizeFirstLetter(getSourceType(selectedRow)),
          'sector_id': '',
          'region_id': '',
          'comments': '',
          'isAtlasFX': false,
          'poNumber': '',
          'workflowId': selectedRow.workflowId,
        };
        setCoverageData([[addDefaultEmptyRow, ...temp[0]], temp[1], temp[2]]);
      } else {
        setCoverageData(temp);
      }
      setDataLoadSuccess(true);
      // setCoverageData(temp);
    });
  };

  const [sectors, setSectors] = useState({});
  const [countries, setCountries] = useState({});
  const [apiRespDropdownData, setapiRespDropdownData] = useState({});


  useEffect(()=>{
    ConfigurationServices.getHistoricalCoverageSectorRegions(selectedRow.buyPlanId).then((res)=>{
      // console.log(res);
      setapiRespDropdownData(res);
    }).catch((err)=>{
      console.log(err);
    });
  }, [selectedRow]);

  useLayoutEffect(()=>{
    if (apiRespDropdownData && apiRespDropdownData !=undefined && typeof apiRespDropdownData !== 'string' && Object.keys(apiRespDropdownData).length>0) {
      const countryObjects = {};
      apiRespDropdownData['regions'].length>0 && apiRespDropdownData['regions'].map((r)=>{
        countryObjects[r.id] = r.region;
      });
      setCountries({...countryObjects});
    }
  }, [apiRespDropdownData]);

  useEffect(()=>{
    // console.log(apiRespDropdownData);
    if (apiRespDropdownData && apiRespDropdownData !=undefined && typeof apiRespDropdownData !== 'string' && Object.keys(countries).length>0) {
      const sectorObjects = {};
      apiRespDropdownData['sectorGroups'].length>0 && apiRespDropdownData['sectorGroups'].map((r)=>{
        r['sectors'].map((rs)=>{
          sectorObjects[rs.name?.toLowerCase()] = rs.regionIdList.map((rr)=>countries[rr].toLowerCase());
        });
      });
      setSectors({...sectorObjects});
      // console.log(sectorObjects);
    }
  }, [apiRespDropdownData, countries]);


  function getRegiondIdList(regionList, countryIds) {
    const mappedCountry = [];
    regionList.regionIdList.forEach((rr)=> {
      countryIds.forEach((country) => {
        if (country.id === rr) {
          mappedCountry.push(country);
        }
      });
    });
    return mappedCountry;
  }

  useEffect(()=>{
    if (apiRespDropdownData != null && apiRespDropdownData !=undefined && typeof apiRespDropdownData !== 'string' && Object.keys(apiRespDropdownData).length>0) {
      const countryIds = [];
      const sectorCountryIds = [];
      apiRespDropdownData['regions'].length>0 && apiRespDropdownData['regions'].map((r)=>{
        countryIds.push({id: r.id, region: r.region});
      });
      apiRespDropdownData['sectorGroups'].length>0 && apiRespDropdownData['sectorGroups'].map((r)=>{
        r['sectors'].map((rs)=>{
          sectorCountryIds.push({id: rs.id, name: rs.name, regionIdList: getRegiondIdList(rs, countryIds)});
        });
      });
      setSectorCountryMap(sectorCountryIds);
    }
  }, [apiRespDropdownData]);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(updateCommentTagging());
  }, []);
  return (
    <>

      {changeCoverageTable?
      <div>
        {allYears.indexOf(selectedYear) == 0 ?
    <div className="coverage-table">
      <DataSheetGrid value={coverageData[0]} columns={columns} className={'savedDatasheetGridCoverageTable'} disableContextMenu={true} gutterColumn={false}/>
    </div> : (allYears.indexOf(selectedYear) == 1 ?
    <div className="coverage-table">
      <DataSheetGrid value={coverageData[1]} columns={columns} disableContextMenu={true} gutterColumn={false}/>
    </div> :
    <div className="coverage-table">
      <DataSheetGrid value={coverageData[2]} columns={columns} disableContextMenu={true} gutterColumn={false}/>
    </div>)}
        <Styles.StyledCoverageSummBottomButtonBox style={{display: 'flex'}}>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>Total <b>/</b> Average</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{coverageVolume !== null && coverageVolume}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{weightedPrice === 'NaN' ? 0 : weightedPrice}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        </Styles.StyledCoverageSummBottomButtonBox>
        {editaccessControl === EDIT ?<Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', color: '#4297d3', font: 'normal normal 400 13px/15px \'Segoe UI\''}}>
          <AppButton disabled={selectedRow.status === 'CLOSED' || selectedRow.status =='SUSPENDED' || (nextMonthCloseBuyPlanTag && !currentRole.includes('Admin')) || dataLoadSuccess==false } onClick={() => setChangeCoverageTable(false)} label="Edit Coverages" />
        </Box>:''}
      </div>:<SaveCoverage wfDetails={wfDetails} sectors={sectors} countries={countries} sectorCountryMap={sectorCountryMap} setChangeCoverageTable={setChangeCoverageTable} updateRowsvalue={getCall} setCoverageData={setCoverageData} setCoveragesSaved={setCoveragesSaved} supplierNames={supplierNames} apiData={coverageData} selectedRow={selectedRow} allYears={allYears} selectedYear={selectedYear}/>}
      <DocumentUpload selectedRow={selectedRow} uploadedFrom={'coverage'} EditDocument={(isCreator?.functionalityAccess['Buy_Plan:Coverage_Documents'] == EDIT)}/>
      <CommentsTable clName={'coverageComments'} DisableComments={(isCreator?.functionalityAccess['Buy_Plan:Coverage_Comments'] !== EDIT)} saveComment={DialogService.saveCoverageComment} editComment={DialogService.editCoverageComment} setShowErrorDialog={setShowErrorDialog} commentsTrigger={commentsTrigger} setCommentsTrigger={setCommentsTrigger} userid={userid} date={date} wfId={selectedRow.workFlowId} commentsData={commentsData} page='coverage'/>
    </>);
}

