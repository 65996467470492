/* eslint-disable camelcase */
/* eslint-disable max-len */

import React, {useEffect, useState} from 'react';
import {BiEdit} from 'react-icons/bi';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import {createTheme, styled} from '@mui/material/styles';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {Box, FormControl, Button, InputBase, MenuItem, Select, Divider, InputAdornment} from '@mui/material';
import ConfigurationServices from '../../../services/ConfigurationServices';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import _ from 'lodash';
import customealltheme from '../../../theme';
import {AppButton} from '../../common/AppButton/AppButton';
import {AppConfirmDialog, AppDialog} from '../../common/App-Dialog/AppDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BuyPlanTextBox from './BuyPlanTextBox';


const iconColumn= {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

};

const arrowIcon = {zIndex: 2,
  width: '1px',
  height: '1px',
};

const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: '#0F5DAA',
};

const dropdown ={
  'width': '150px',
  'marginTop': '10px',
  '& .css-13so1lh-MuiInputBase-root': {
    width: '150px',
  },
};

const subheading = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  p: {
    display: 'inline-block',
    marginRight: '2px',
    font: 'normal normal 600 13px/15px \'Segoe UI\'',
    color: '#3174b6',
    cursor: 'pointer',
  },
};

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const ToggleContainer = styled('div')(({theme}) => ({
  'width': 60,
  'height': 26,
  'position': 'relative',
  'margin': '10px 10px 0px 10px',
  '& .MuiSwitch-root': {
    width: '100%',
    height: '100%',
  },
  '& .switchLabel': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#fff',
    fontSize: '0.75rem',
    transition: theme.transitions.create(['opacity'], {
      duration: 3,
    }),
  },
  '& .labelYes': {
    left: 5,
    opacity: 1,
  },
  '& .labelNo': {
    right: 5,
    opacity: 1,
  },
  '& .MuiSwitch-switchBase.Mui-checked ~ .labelYes': {
    opacity: 0,
  },
  '& .MuiSwitch-switchBase.Mui-checked ~ .labelNo': {
    opacity: 1,
  },
}));

const ToggleSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
  'width': 60,
  'height': 26,
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'margin': 2,
    'transitionDuration': '3ms',
    '&.Mui-checked': {
      'transform': 'translateX(31px)',
      'color': '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#4297D3',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#6D7888',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 3,
    }),
  },
}));

function EditConfTable({row, mappedData, Units, Currency, buyplanPersona, singleUpdate, deleteBuyPlanRow, setAddingTrigger, addingTrigger, values, idKey, setErrMsg, setIsErrorPopupVisible, formatStatus, ActiveSectors, country, Playbooks, editBuyPlanRow}) {
  const numberOptions = Array.from({length: 13}, (_, index) => index - 6);
  const [popup, setPopup] = useState(false);
  const [sectorRegionList, setSectorRegionList] = useState([]);
  const [sectorCountryList, setSectorCountryList] = useState([]);
  const [sectorValue, setSectorValue]= useState('');
  const [sectorId, setSectorId]=useState('');
  const [countryValue, setCountryValue]=useState('');
  const [countryId, setCountryId]=useState('');
  const [mappedRegionsList, setMappedRegionsList] = useState([]);
  const [roundingNumber, setRoundingNumber] = useState('');
  const [sectorFlag, setSectorFlag]=useState(false);
  const [countryFlag, setCountryFlag]=useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [optionalityDetails, setOptionalityDetails] = useState({});
  const [buyApprpers, setBuyAppPers] = useState('');
  const [creatorUID, setCreatorUId] = useState('');
  const [localUnit, setLocalUnit] = useState('');
  const [localCurrency, setLocalCurrency] = useState('');
  const [forecastUID, setForecastUId] = useState('');
  const [viewerUId, setViewerUId] = useState('');
  const [approverUID, setApproverUID] = useState('');
  const [buycreatPer, setbuycreatPer] = useState('');
  const [forecastPer, setforecastPer] = useState('');
  const [buyadminPer, setbuyadminPer]= useState('');
  const [adminUID, setAdminUID] = useState('');
  const [buyplanCreator, setBuyplanCreator] = useState('');
  const [buyplanApprover, setBuyplanApprover] = useState('');
  const [disableSupplierTarget, setDisableSupplierTarget] = useState(false);
  const [disableSupplierOptionality, setDisableSupplierOptionality] = useState(false);
  const [disableTreasuryOptionality, setDisableTreasuryOptionality] = useState(false);
  const [disableTreasuryTarget, setDisableTreasuryTarget] = useState(false);
  const [futureStatusValue, setFutureStatusValue]=useState('');
  const [currentStatusValue, setCurrentStatusValue]=useState('');
  const [optionalityValue, setOptionalityValue]= useState('');
  const [supplierTargetsValue, setSupplierTargetsValue] = useState('');
  const [supplierOptionalityValue, setSupplierOptionalityValue] = useState('');
  const [treasuryTargetsValue, setTreasuryTargetsValue] = useState('');
  const [treasuryOptionalityValue, setTreasuryOptionalityValue] = useState('');
  const [supplierValue, setSupplierValue] = useState('');
  const [treasuryValue, setTreasuryValue] = useState('');
  const [othersValue, setOthersValue] = useState('');
  const [isAtlasfxValue, setIsAtlasfxValue]=useState(false);
  const [isCarryVolume, setIsCarryVolume] = useState(false);
  const cDate = dayjs();
  const minActiveEndDate = dayjs(row['active_start_date']);
  const minActiveStartDate = dayjs().startOf('month');
  const [selectedActiveStartDate, setSelectedActiveStartDate] = useState('');
  const [selectedActiveEndDate, setSelectedActiveEndDate] = useState('');
  const [activeStartDateValue, setActiveStartDateValue]=useState('');
  const [activeEndDateValue, setActiveEndDateValue]=useState('');
  const [oChathamFolderEntityValue, setOChathamFolderEntityValue] = useState(null);
  const [oForecastProgramNameValue, setOForecastProgramNameValue]=useState(null);
  const [oFullEntityNameValue, setOFullEntityNameValue]=useState(null);
  const [oPlayBookValue, setOPlayBookValue]=useState(null);
  const [oCommodityValue, setOCommodityValue]=useState(null);
  const [oReportingRegionValue, setOReportingRegionValue]= useState(null);
  const [oSectorValue, setOSectorValue] =useState(null);
  const [oLegalEntityValue, setOLegalEntityValue] =useState(null);
  const [oProgramNameValue, setOProgramNameValue]=useState(null);
  const [oCommoditySubtypeValue, setOCommoditySubtypeValue]=useState(null);
  const [oCurrencyValue, setOCurrencyValue]=useState(null);
  const [oMeasurementUnitValue, setOMeasurementUnitValue]= useState(null);
  const [oExposureMonthDifferenceValue, setOExposureMonthDifferenceValue]=useState(0);
  const [atlasFXConfigId, setAtlasFXConfigId] = useState(0);
  const [atlasFXConfigData, setAtlasFXConfigData] = useState(0);
  const [editBuyPlanRowData, setEditBuyPlanRowData]=useState('');
  const [showSuccessDialog, setshowSuccessDialog] = useState(false);

  useEffect(()=>{
    if (sectorId) {
      const countriesList = [];
      ConfigurationServices.getSectorCountries(sectorId).then((res)=>{
        res.forEach((element) => {
          element.is_present === 'Y'? countriesList.push(element):'';
        });
        setMappedRegionsList(countriesList);
      }).catch((e) => {
        setIsErrorPopupVisible(true);
        setErrMsg('Error has been occured', e);
      });
    }
  }, [sectorId]);

  const mapDateToBackendFormat = (date) => {
    if (date===null || date==='') return null;
    return dayjs(date).format('YYYY-MM');
  };

  const handleToggleOptionality = () => {
    setOptionalityValue((prevValue) => !prevValue);
  };

  const handleSupplierTargetsValue= () => {
    setSupplierTargetsValue((prevValue) => !prevValue);
  };

  const handleSupplierOptionalityValue= () => {
    setSupplierOptionalityValue((prevValue) => !prevValue );
  };

  const handleTreasuryTargetsValue= () => {
    setTreasuryTargetsValue((prevValue) => !prevValue);
  };

  const handleTreasuryOptionalityValue= () => {
    setTreasuryOptionalityValue((prevValue) => !prevValue );
  };

  const handleSupplierValue= () => {
    setSupplierValue((prevValue) => !prevValue );
  };

  const handleTreasuryValue= () => {
    setTreasuryValue((prevValue) => !prevValue );
  };

  const handleOthersValue= () => {
    setOthersValue((prevValue) => !prevValue );
  };

  const handleIsAtlasfxValue= () => {
    setIsAtlasfxValue((prevValue) => !prevValue );
  };

  const handleIsCarryVolume= () => {
    setIsCarryVolume((prevValue) => !prevValue );
  };

  useEffect(()=>{
    setIsAtlasfxValue(row['is_atlasfx']);
    setIsCarryVolume(row['is_carry_vol_enabled']);
    setFutureStatusValue(formatStatus(row['future_status']));
    setBuyplanCreator(row['buyplan_creator']);
    setBuyplanApprover(row['buyplan_approver']);
    setActiveStartDateValue(row['active_start_date']);
    setActiveEndDateValue(row['active_end_date']);
    setRoundingNumber(row['decimal_scale']);
    const sRList = mappedData[row.buy_plan_id]?.map((item) => {
      return {
        Id: item.sector_id.toString()+ item.region_id.toString(),
        sectorName: item.sector,
        sectorId: item.sector_id,
        regionName: item.country,
        regionId: item.region_id,
      };
    });
    setSectorRegionList(sRList);
    setSectorCountryList(sRList);
    Playbooks.forEach((pb) => {
      if (pb.playbook_id === row.play_book_id) {
        setSectorFlag(pb.sector !== null);
        setCountryFlag(pb.country !== null);
        setSectorValue(pb.sector);
        setCountryValue(pb.country);
        setSectorId(pb.sector_id);
        setCountryId(pb.region_id);
      }
    });
  }, [row]);

  const [defaultActiveStartDate, setDefaultActiveStartDate] = useState(null);

  useEffect(() => {
    if (row['active_start_date']) {
      setDefaultActiveStartDate(dayjs(row['active_start_date']));
    } else {
      const date = dayjs();
      setDefaultActiveStartDate(date);
    }
  }, [row]);

  const [defaultActiveEndDate, setDefaultActiveEndDate] = useState(null);

  useEffect(() => {
    if (row['active_end_date']) {
      setDefaultActiveEndDate(dayjs(row['active_end_date']));
    } else {
      let date = dayjs().add(1, 'month');

      if (dayjs().date() !== date.date()) {
        date = date.subtract(date.date(), 'day');
      }

      setDefaultActiveEndDate(date);
    }
  }, [row]);

  const minFutureStartDate = cDate;
  const [selectedFutureStartDate, setSelectedFutureStartDate] = useState('');
  const [selectedFutureEndDate, setSelectedFutureEndDate] = useState('');

  const [defaultFutureStartDate, setDefaultFutureStartDate] = useState(null);
  const [defaultFutureEndDate, setDefaultFutureEndDate] = useState(null);
  const [enableSaveButton, setEnableSaveButton]=useState(false);

  useEffect(() => {
    const shouldEnable = row['play_book_id'] !== '' &&
      row['buy_plan_name'] !== '' &&
      mappedData[row.buy_plan_id] !== '' &&
      row['sub_commodity'] !== '' &&
      localUnit !== '' &&
      localCurrency !== '' &&
      buyplanApprover !== null &&
      buyplanCreator !== '' &&
      adminUID !== '' &&
      roundingNumber !== '' &&
      (optionalityValue===true || optionalityValue===false) &&
      (supplierTargetsValue===true || supplierTargetsValue===false) &&
      (supplierOptionalityValue === true || supplierOptionalityValue === false) &&
      (treasuryTargetsValue === true || treasuryTargetsValue === false) &&
      (treasuryOptionalityValue === true || treasuryOptionalityValue === false)&&
      (supplierValue === true || supplierValue === false) &&
      (treasuryValue === true || treasuryValue === false) &&
      (othersValue === true || othersValue === false) &&
      (isCarryVolume === true || isCarryVolume === false) &&
      (
        (
          ((isAtlasfxValue === true))&&

          Boolean(oLegalEntityValue &&
        oProgramNameValue &&
        oCommoditySubtypeValue &&
        oCurrencyValue &&
        oMeasurementUnitValue &&
        oFullEntityNameValue &&
        oForecastProgramNameValue &&
        oChathamFolderEntityValue &&
        oPlayBookValue &&
        oCommodityValue &&
        oSectorValue &&
        oReportingRegionValue)
        ) ||
        isAtlasfxValue === false) &&
      sectorCountryList.length!=0;
    setEnableSaveButton(shouldEnable);
  }, [sectorCountryList,
    row,
    localUnit,
    localCurrency,
    buyplanApprover,
    buyplanCreator,
    roundingNumber,
    adminUID,
    optionalityValue,
    supplierTargetsValue,
    supplierOptionalityValue,
    treasuryTargetsValue,
    treasuryOptionalityValue,
    supplierValue,
    treasuryValue,
    othersValue,
    isAtlasfxValue,
    isCarryVolume,
    mappedData,
    oLegalEntityValue,
    oProgramNameValue,
    oCommoditySubtypeValue,
    oCurrencyValue,
    oMeasurementUnitValue,
    oFullEntityNameValue,
    oForecastProgramNameValue,
    oChathamFolderEntityValue,
    oPlayBookValue,
    oCommodityValue,
    oSectorValue,
    oReportingRegionValue]);

  useEffect(() => {
    if (row['future_status_start_date']) {
      setDefaultFutureStartDate(dayjs(row['future_status_start_date']));
    } else {
      let date = dayjs(row['active_end_date']).add(1, 'month');

      if (dayjs(row['active_end_date']).date() !== date.date()) {
        date = date.subtract(date.date(), 'day');
      }

      setDefaultFutureStartDate(date);
    }
  }, [row]);

  useEffect(() => {
    if (row['future_status_end_date']) {
      setDefaultFutureEndDate(dayjs(row['future_status_end_date']));
    } else if (defaultFutureStartDate) {
      let date = defaultFutureStartDate.add(10, 'year');

      if (defaultFutureStartDate.date() !== date.date()) {
        date = date.subtract(date.date(), 'day');
      }

      setDefaultFutureEndDate(date);
    }
  }, [row, defaultFutureStartDate]);

  const [futureStartDateValue, setFutureStartDateValue]=useState(null);
  const [futureEndDateValue, setFutureEndDateValue]=useState(null);

  useEffect(()=>{
    if (supplierValue && !treasuryValue) {
      setIsAtlasfxValue(false);
    }
  }, [supplierValue, treasuryValue]);

  const restoreDateFields = () => {
    setSelectedActiveStartDate(defaultActiveStartDate);
    setSelectedActiveEndDate(defaultActiveEndDate);
    setSelectedFutureStartDate(defaultFutureStartDate);
    setSelectedFutureEndDate(defaultFutureEndDate);
    setActiveStartDateValue(row['active_start_date']);
    setActiveEndDateValue(row['active_end_date']);
    setFutureStartDateValue(null);
    setFutureEndDateValue(null);
  };

  const clearAtlasFxData = () => {
    setOLegalEntityValue(null);
    setOProgramNameValue(null);
    setOCommoditySubtypeValue(null);
    setOCurrencyValue(null);
    setOMeasurementUnitValue(null);
    setOExposureMonthDifferenceValue(0);
    setOFullEntityNameValue(null);
    setOForecastProgramNameValue(null);
    setOChathamFolderEntityValue(null);
    setOPlayBookValue(null);
    setOCommodityValue(null);
    setOSectorValue(null);
    setOReportingRegionValue(null);
  };

  const cancelEdit = () => {
    restoreDateFields();
    clearAtlasFxData();
    setIsCarryVolume(row['is_carry_vol_enabled']);
    setRoundingNumber(row['decimal_scale']);
    setCreatorUId(row['buyplan_creator_user_id']);
    setApproverUID(row['buyplan_approver_user_id']);
    setAdminUID(row['buyplan_admin_user_id']);
    setForecastUId(((row['forecast_creator_user_id'] === '' || row['forecast_creator_user_id'] === null) ? null : (buyplanPersona['forecast_creator']?.some((e)=> e.userId === row['forecast_creator_user_id'] && e.active === true)) ? row['forecast_creator_user_id'] : null));
    setBuyplanCreator(row['buyplan_creator']);
    setBuyplanApprover(row['buyplan_approver']);
    setLocalCurrency(editBuyPlanRowData?.currencyId);
    setLocalUnit(editBuyPlanRowData?.measurementUnitId);
    setFutureStatusValue(formatStatus(row['future_status']));
    setSectorCountryList(sectorRegionList);
    setSectorId('');
    setCountryId('');
    setSectorFlag(false);
    setCountryFlag(false);
    setSectorValue('');
    setCountryValue('');
    setMappedRegionsList([]);
  };

  useEffect(() => {
    setDisableSupplierTarget(false);
    setDisableSupplierOptionality(false);
    setDisableTreasuryTarget(false);
    setDisableTreasuryOptionality(false);

    if (optionalityDetails.supplier == false ||
      (!optionalityDetails.optionality && optionalityDetails.supplier == true &&
        optionalityDetails.treasury == false && (optionalityDetails.others == false || optionalityDetails.others == false)) ||
      (optionalityDetails.optionality && optionalityDetails.supplier == true &&
      optionalityDetails.treasury == false && (optionalityDetails.others == false || optionalityDetails.others == false))) {
      setOptionalityDetails((prevState) => ({...prevState, supplier_targets: false, supplier_optionality: false}));
      setDisableSupplierTarget(true);
      setDisableSupplierOptionality(true);
    }
    if (optionalityDetails.treasury == false ||
      (!optionalityDetails.optionality && optionalityDetails.supplier == false &&
        optionalityDetails.treasury == true && (optionalityDetails.others == false || optionalityDetails.others == false)) ||
      (optionalityDetails.optionality && optionalityDetails.supplier == false &&
        optionalityDetails.treasury == true && (optionalityDetails.others == false || optionalityDetails.others == false))) {
      setOptionalityDetails((prevState) => ({...prevState, treasury_targets: false, treasury_optionality: false}));

      setDisableTreasuryTarget(true);
      setDisableTreasuryOptionality(true);
    }
  }, [optionalityDetails.supplier, optionalityDetails.optionality, optionalityDetails.treasury, optionalityDetails.others]);

  useEffect(() => {
    setDisableSupplierOptionality(false);
    if (optionalityDetails.supplier_targets == false) {
      setOptionalityDetails((prevState) => ({...prevState, supplier_optionality: false}));

      setDisableSupplierOptionality(true);
    }
  }, [optionalityDetails.supplier_targets]);

  useEffect(() => {
    setDisableTreasuryOptionality(false);
    if (optionalityDetails.treasury_targets == false) {
      setOptionalityDetails((prevState) => ({...prevState, treasury_optionality: false}));

      setDisableTreasuryOptionality(true);
    }
  }, [optionalityDetails.treasury_targets]);

  const editTheRow = () =>{
    setbuycreatPer(row['buyplan_creator_persona_id']);
    setbuyadminPer(row['buyplan_admin_persona_id']);
    setBuyAppPers(row['buyplan_approver_persona_id']);
    setRoundingNumber(row['decimal_scale']);
    setCreatorUId(row['buyplan_creator_user_id']);
    setApproverUID(row['buyplan_approver_user_id']);
    setAdminUID(row['buyplan_admin_user_id']);
    setforecastPer(row['forecast_creator_persona_id']);
    setForecastUId(((row['forecast_creator_user_id'] === '' || row['forecast_creator_user_id'] === null) ? null : (buyplanPersona['forecast_creator']?.some((e)=> e.userId === row['forecast_creator_user_id'] && e.active === true)) ? row['forecast_creator_user_id'] : null));
    restoreDateFields();
    setBuyplanCreator(row['buyplan_creator']);
    setBuyplanApprover(row['buyplan_approver']);
    setCurrentStatusValue(formatStatus(row['current_status']));
    setFutureStatusValue(formatStatus(row['future_status']));
    editBuyPlanRow(row['buy_plan_id']).then(
        (res)=>{
          if (res && res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
          } else if (res) {
            setEditBuyPlanRowData(res);
            setLocalUnit(res?.measurementUnitId);
            setLocalCurrency(res?.currencyId);
            setTreasuryTargetsValue(res?.treasuryTargets);
            setSupplierTargetsValue(res?.supplierTargets);
            setSupplierValue(res?.supplier);
            setTreasuryValue(res?.treasury);
            setOthersValue(res?.others);
            setSupplierOptionalityValue(res?.supplierOptionality);
            setTreasuryOptionalityValue(res?.treasuryOptionality);
            setOptionalityValue(res?.optionality);
            setViewerUId((res?.buyPlanViewerUserId === '') ? 0 : (buyplanPersona['buyplan_viewer']?.some((e)=> e.userId === res?.buyPlanViewerUserId && e.active === true)) ? res?.buyPlanViewerUserId : 0);
            const {atlasConfig} = res;
            setAtlasFXConfigData(atlasConfig);
            setAtlasFXConfigId(atlasConfig?.atlasFxConfigId);
            setOLegalEntityValue(atlasConfig?.inLegalEntity);
            setOProgramNameValue(atlasConfig?.inProgramName);
            setOCommoditySubtypeValue(atlasConfig?.inCommSubType);
            setOCurrencyValue(atlasConfig?.inCurrencyId);
            setOMeasurementUnitValue(atlasConfig?.inUnitId);
            setOExposureMonthDifferenceValue(atlasConfig?.inCovAdjustment || 0);
            setOFullEntityNameValue(atlasConfig?.oEntity);
            setOForecastProgramNameValue(atlasConfig?.oForecastPgm);
            setOChathamFolderEntityValue(atlasConfig?.oChathamEntity);
            setOPlayBookValue(atlasConfig?.outPlaybook);
            setOCommodityValue(atlasConfig?.oCommodity);
            setOSectorValue(atlasConfig?.outSector);
            setOReportingRegionValue(atlasConfig?.outRepRegion);
          }
        }).catch(
        (err)=>{
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured', err);
        });
  };

  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () =>{
    const buyPlanMaps = (editBuyPlanRowData?.isWFAvailable == 'Y' || (sectorFlag && countryFlag))?
    sectorRegionList?.map((item) => {
      return {
        sectorId: item.sectorId,
        regionId: item.regionId,
        commodityGroupId: row['commodity_group_id'],
        sectorGroupId: row['sector_group_id'],
        commodityId: row['commodity_id'],
        subCommodityId: row['sub_commodity_id'],
      };
    }):sectorCountryList?.map((item) => {
      return {
        sectorId: item.sectorId,
        regionId: item.regionId,
        commodityGroupId: row['commodity_group_id'],
        sectorGroupId: row['sector_group_id'],
        commodityId: row['commodity_id'],
        subCommodityId: row['sub_commodity_id'],
      };
    });

    const atlasConfig = {
      buyPlanId: row['buy_plan_id'],
      atlasFxConfigId: atlasFXConfigId,
      inLegalEntity: oLegalEntityValue,
      inProgramName: oProgramNameValue,
      inCurrencyId: oCurrencyValue,
      inUnitId: oMeasurementUnitValue,
      inCovAdjustment: oExposureMonthDifferenceValue,
      inCommSubType: oCommoditySubtypeValue,
      outSector: oSectorValue,
      outRepRegion: oReportingRegionValue,
      outPlaybook: oPlayBookValue,
      oCommodity: oCommodityValue,
      oEntity: oFullEntityNameValue,
      oChathamEntity: oChathamFolderEntityValue,
      oForecastPgm: oForecastProgramNameValue,
    };

    singleUpdate({
      'atlasConfig': isAtlasfxValue ? atlasConfig : atlasFXConfigData,
      'buyplanId': row['buy_plan_id'],
      'buyplanName': row['buy_plan_name'],
      'supplierOptionality': supplierOptionalityValue,
      'treasuryOptionality': treasuryOptionalityValue,
      'optionality': optionalityValue,
      'playbookId': row['play_book_id'],
      'playbookName': row['play_book_name'],
      'buyPlanMaps': buyPlanMaps,
      'measurementUnitId': localUnit?localUnit : editBuyPlanRowData?.measurementUnitId,
      'currencyId': localCurrency?localCurrency : editBuyPlanRowData?.currencyId,
      'standardCurrencyId': editBuyPlanRowData?.standardCurrencyId,
      'supplierTargets': supplierTargetsValue,
      'treasuryTargets': treasuryTargetsValue,
      'others': othersValue,
      'targetCoverageMode': 'BOTH',
      'coverageMode': 'BOTH',
      'treasury': treasuryValue,
      'supplier': supplierValue,
      'standardUom': editBuyPlanRowData?.standardUom,
      'active': true,
      'buyPlanOwnerUserId': row['buyplan_owner_user_id'],
      'buyPlanOwnerPersonaId': row['buyplan_owner_persona_id'],
      'buyPlanCreatorUserId': creatorUID,
      'buyPlanCreatorPersonaId': buycreatPer,
      'buyPlanViewerUserId': viewerUId,
      'forecastCreatorUserId': forecastUID,
      'forecastCreatorPersonaId': forecastUID > 0 ? forecastPer:'',
      'buyPlanAdminPersonaId': buyadminPer,
      'buyPlanApproverUserId': approverUID,
      'buyPlanAdminUserId': adminUID,
      'buyPlanApproverPersonaId': buyApprpers,
      'cascadeTargetBP': row['is_cascade_target_bp'],
      'buyPlanTargetApplicable': true,
      'atlasfx': isAtlasfxValue,
      'carryVolEnabled': isCarryVolume,
      'decimalScale': roundingNumber,
      // 'currentStatus': row['current_status'],
      'currentStatus': formatStatus(currentStatusValue)==''? row['current_status']:formatStatus(currentStatusValue),
      'activeStartDate': mapDateToBackendFormat(activeStartDateValue)==''? null:mapDateToBackendFormat(activeStartDateValue),
      'activeEndDate': mapDateToBackendFormat(activeEndDateValue)==''? null:mapDateToBackendFormat(activeEndDateValue),
      'futureStatus': formatStatus(futureStatusValue)==''? null:formatStatus(futureStatusValue),
      'futureStartDate': formatStatus(futureStatusValue)==''? null:(mapDateToBackendFormat(futureStartDateValue)==null? mapDateToBackendFormat(defaultFutureStartDate):mapDateToBackendFormat(futureStartDateValue)),
      'futureEndDate': formatStatus(futureStatusValue)==''? null:(mapDateToBackendFormat(futureEndDateValue)==null? mapDateToBackendFormat(defaultFutureEndDate):mapDateToBackendFormat(futureEndDateValue)),
    }).then((res)=>{
      if (res && res.userMessage) {
        restoreDateFields();
        setIsErrorPopupVisible(true);
        setErrMsg(res.userMessage);
      } else if (res) {
        handleClose();
        setAddingTrigger(!addingTrigger);
      }
    }).catch((err)=>{
      restoreDateFields();
      setIsErrorPopupVisible(true);
      setErrMsg(err.message);
      setAddingTrigger(!addingTrigger);
    });
  };

  const handleAddRow = () =>{
    setPopup(true);
    editTheRow();
  };

  const showDelPopup =()=>{
    setShowConfirmDialog(true);
  };

  const handleDeleteRow = () =>{
    deleteBuyPlanRow(row['buy_plan_id']).then(
        (res)=>{
          setShowConfirmDialog(false);
          if (res && res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
          } else if (res) {
            setAddingTrigger(!addingTrigger);
            setshowSuccessDialog(true);
          }
        }).catch(
        (err)=>{
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured', err?.message);
          setAddingTrigger(!addingTrigger);
        });
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setPopup(false);
      cancelEdit();
    }
  };

  const handleFutureStartDateChange = (date) => {
    setSelectedFutureStartDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureStartDateValue(d);
  };

  const handleFutureEndDateChange = (date) => {
    setSelectedFutureEndDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureEndDateValue(d);
  };

  const handleActiveStartDateChange = (date) => {
    setSelectedActiveStartDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setActiveStartDateValue(d);
  };

  const handleActiveEndDateChange = (date) => {
    setSelectedActiveEndDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setActiveEndDateValue(d);
  };

  const handleSectorCountryDelete = (itemToRemove) => {
    const indexToRemove = sectorCountryList.findIndex((item) => item === itemToRemove);
    if (indexToRemove !== -1) {
      setSectorCountryList((prevList) => prevList.filter((item) => item !== itemToRemove));
    }
  };

  const handleAddSectorCountry = () => {
    if (countryValue && sectorValue && sectorId && countryId) {
      const exists = sectorCountryList.some((pair) =>
        pair.regionId === countryId && pair.sectorId === sectorId,
      );

      if (!exists) {
        setSectorCountryList((prevList) => [
          {id: sectorId.toString() + sectorValue + countryId.toString() + countryValue,
            sectorName: sectorValue,
            sectorId: sectorId,
            regionName: countryValue,
            regionId: countryId},
          ...prevList,
        ]);

        if (!sectorFlag) {
          setSectorValue('');
          setSectorId(null);
        }

        if (!countryFlag) {
          setCountryValue('');
          setCountryId(null);
        }
      }
    }
  };

  const handleIncrement = () => {
    setOExposureMonthDifferenceValue((prevValue) => Math.min(prevValue + 1, 6));
  };

  const handleDecrement = () => {
    setOExposureMonthDifferenceValue((prevValue) => Math.max(prevValue - 1, -6));
  };

  return (
    <>
      <AppConfirmDialog open={showConfirmDialog} handleClose={()=>{
        setShowConfirmDialog(false);
      }} text={''} secondLineText={'Are you sure you want to delete this Buy Plan?'} setYes={handleDeleteRow} ifYesFirst ={false}/>
      <Box sx={{display: 'flex', gap: '5px'}}>
        <Box
          onClick={row['is_editable'] === 'Y' ? handleAddRow : undefined}
          sx={{
            border: '1px solid gray',
            display: 'flex',
            border: '0.5px solid #CECECE',
            borderRadius: '4px',
            width: '88px !important',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: row['is_editable'] === 'Y' ? 'pointer' : 'not-allowed',
            opacity: row['is_editable'] === 'Y' ? 1 : 0.5,
          }}
          disabled={row['is_editable'] !== 'Y'}
        >
          <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
          <Box>View/Edit</Box>
        </Box>

        <Box
          onClick={showDelPopup}
          sx={{
            display: 'flex',
            border: '0.5px solid #CECECE',
            borderRadius: '4px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
            opacity: 1,
          }}
        >
          <Box>❌</Box>
        </Box>
      </Box>


      <Box sx={{width: '100%', padding: '0px 18px'}}>

        <Dialog
          open={popup}
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{height: '80vh', overflowY: 'auto'}}>
            <Box sx={{width: '100%'}}>
              <Box
                mb={3}
                sx={{
                  font: 'normal normal 700 15px/21px \'Segoe UI\' ',
                  color: '#0F5DAA',
                }}
                style={customealltheme.palette.filterheading}
              >
                 Edit Buy Plan
              </Box>
              <Box mb={3}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         PlayBook Name*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          {row['play_book_name']}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Buy Plan Name*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          {row['buy_plan_name']}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Master Sector
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          {row['master_sector']}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Sector*
                        </Box>
                      </Grid>
                      {(editBuyPlanRowData?.isWFAvailable == 'Y') ?
                      <Grid item>
                        {sectorRegionList?.map((Item) => (
                          <Grid container alignItems="center" key={Item.Id}>
                            <Grid item sx={{'padding': '4px'}}>
                              {Item.sectorName}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>:
                       <>
                         <Grid item>
                           {!sectorFlag ?
                           <FormControl sx={dropdown}>
                             <Select
                               labelId="demo-multiple-checkbox-label"
                               sx={customTheme.selectBox}
                               value={sectorValue}
                               onChange={(e) => {
                                 const selectedSector = e.target.value;
                                 setSectorValue(selectedSector);

                                 if (ActiveSectors.length !== 0) {
                                   const matchedSector = ActiveSectors.find((obj) => obj.name === row['master_sector']);

                                   if (matchedSector) {
                                     const matchedSubSector = matchedSector.sectors.find((r) => r.name === selectedSector);

                                     if (matchedSubSector) {
                                       setSectorId(matchedSubSector.id);
                                     }
                                   }
                                 }
                               }}

                               label="Select"
                               input={<CustomInput label="Tag" />}
                               displayEmpty
                               className="newclass"
                             >
                               <MenuItem value="" sx={customTheme.selectMenuBox}>
                                 <span>Select</span>
                               </MenuItem>
                               {ActiveSectors.length !== 0 && ActiveSectors?.map((obj) => {
                                 if (obj.name === row['master_sector']) {
                                   return obj.sectors
                                       .filter((r) => r.active === true)
                                       .map((r) => (
                                         <MenuItem
                                           sx={customTheme.selectMenuBox}
                                           key={r?.id}
                                           value={r?.name}
                                           className="this-is-new-class"
                                         >
                                           {r.name}
                                         </MenuItem>
                                       ));
                                 }
                                 return null;
                               })}

                             </Select>
                           </FormControl>:
                           <Box sx={dropdown} style={{marginBottom: '22px'}}>
                             {sectorValue}
                           </Box>}
                         </Grid>
                         <Grid item>
                           {!(sectorFlag && countryFlag) && sectorCountryList?.map((Item) => (
                             <Grid container alignItems="center" key={Item.id}>
                               <Grid item sx={{'paddingTop': '4px', 'paddingBottom': '4px'}}>
                                 {Item.sectorName}
                               </Grid>
                             </Grid>
                           ))}
                         </Grid>
                       </>
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Country*
                        </Box>
                      </Grid>
                      {(editBuyPlanRowData?.isWFAvailable == 'Y') ?
                      <Grid item>
                        {sectorCountryList?.map((Item) => (
                          <Grid container alignItems="center" key={Item.Id}>
                            <Grid item sx={{'padding': '4px'}}>
                              {Item.regionName}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>:
                     <Grid item>
                       <Grid container direction="row" >
                         <Grid item xs={8}>
                           {!countryFlag?
                           <FormControl sx={dropdown}>
                             <Select
                               labelId="demo-multiple-checkbox-label"
                               sx={customTheme.selectBox}
                               value={countryValue}
                               onChange={(e) => {
                                 const selectedCountry = e.target.value;
                                 setCountryValue(selectedCountry);

                                 const matchedCountry = country.find((obj) => obj.region === selectedCountry);

                                 if (matchedCountry) {
                                   setCountryId(matchedCountry.region_id);
                                 }
                               }}

                               label="Select"
                               input={<CustomInput label="Tag" />}
                               displayEmpty
                               className="newclass"
                             >
                               <MenuItem value="" sx={customTheme.selectMenuBox}>
                                 <span>Select</span>
                               </MenuItem>
                               {(mappedRegionsList?.length !==0 ) && sectorValue &&
                             _.orderBy(mappedRegionsList, [(s) => s.region?.toLowerCase()])?.map((r)=>(
                               <MenuItem
                                 sx={customTheme.selectMenuBox}
                                 key={r?.region_id || ''}
                                 value={r?.region || ''}
                                 className="this-is-new-class"
                               >
                                 {r.region}
                               </MenuItem>

                             ))}
                             </Select>
                           </FormControl>:
                            <Box sx={dropdown} style={{marginBottom: '22px'}}>
                              {countryValue}
                            </Box>
                           }

                         </Grid>
                         <Grid item xs={4}>
                           {!(sectorFlag && countryFlag)?
                           <Button
                             sx={{'textTransform': 'none', 'backgroundColor': '#4297D3', '&:hover': {backgroundColor: '#6D7888'}, 'color': '#ffffff', 'width': '80px', 'height': '50px', 'fontSize': '10px', 'marginLeft': '10px'}}
                             onClick={handleAddSectorCountry}
                           >
                              Add Sector Country
                           </Button>:
                          ''
                           }
                         </Grid>
                       </Grid>
                       <Grid item>
                         {!(sectorFlag && countryFlag) && sectorCountryList?.map((Item) => (
                           <Grid container alignItems="center" key={Item.id} >
                             <Grid item xs={6} sx={{'padding': '4px'}}>
                               {Item.regionName}
                             </Grid>
                             <Grid item >
                               <IconButton onClick={() => handleSectorCountryDelete(Item)} sx={{'padding': '0px', 'margin': '0px'}}>
                                 <CloseIcon style={{fontSize: '12px'}}/>
                               </IconButton>
                             </Grid>
                           </Grid>
                         ))}
                       </Grid>
                     </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Category
                        </Box>
                      </Grid>
                      <Grid item>

                        <Box sx={dropdown}>
                          {row['category']}
                        </Box>


                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Commodity
                        </Box>
                      </Grid>
                      <Grid item>

                        <Box sx={dropdown}>
                          {row['commodity']}
                        </Box>


                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Sub Commodity*
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box sx={dropdown}>
                          {row['sub_commodity']}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider color='#4297D3'/>
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Local UoM*
                        </Box>
                      </Grid>
                      <Grid item>
                        { (editBuyPlanRowData?.isWFAvailable == 'N') ?
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={localUnit || editBuyPlanRowData?.measurementUnitId || ''}
                            onChange={(e)=>{
                              setLocalUnit(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                            renderValue={(value) => {
                              const res = Units?.filter((unit) => unit.unit_id ===value)[0];
                              return res?.unit_description || '';
                            }}
                          >
                            {Units.length !== 0 &&
                            _.orderBy(Units, [(p) => p?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.unit_id}
                                value={r?.unit_id}
                                className="this-is-new-class"
                              >
                                {r.unit_description}
                              </MenuItem>


                            ))}
                          </Select>
                        </FormControl>:
                            <Box sx={dropdown} >
                              {editBuyPlanRowData?.measurementUnit}
                            </Box>

                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Standard UoM
                        </Box>
                      </Grid>
                      <Grid item>

                        <FormControl sx={dropdown}>
                          {editBuyPlanRowData?.standardUomName}
                        </FormControl>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Local Currency*
                        </Box>
                      </Grid>
                      <Grid item>
                        { (editBuyPlanRowData?.isWFAvailable === 'N') ?
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={localCurrency}
                            onChange={(e)=>{
                              setLocalCurrency(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Currency.length !== 0 &&
                            _.orderBy(Currency, [(p) => p.currency_description?.toLowerCase()])?.map((r) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.currency_id}
                                value={r?.currency_id}
                                className="this-is-new-class"
                              >
                                {r.currency_description}
                              </MenuItem>


                            ))}
                          </Select>
                        </FormControl>:
                        <FormControl sx={dropdown}>
                          {editBuyPlanRowData?.currency}
                        </FormControl>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Standard Currency
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          {editBuyPlanRowData?.standardCurrency}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Rounding Number*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={roundingNumber}
                            onChange={(e) => {
                              setRoundingNumber(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            {[
                              {label: 'Round to 2 decimal places', val: 2},
                              {label: 'Nearest whole number', val: 0},
                            ].map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj.val}
                                className="this-is-new-class"
                              >
                                {obj.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider color='#4297D3'/>
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Buy Plan Owner
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box sx={dropdown}>
                          {row['buyplan_owner']}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Buy Plan Creator*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={creatorUID}
                            onChange={(e)=>{
                              setCreatorUId(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty

                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                              <span>{row['buyplan_creator']}</span>
                            </MenuItem>
                            {_.orderBy(buyplanPersona['buyplan_creator'] ?.filter(
                                (obj) => obj.userName?.trim()?.toLowerCase() !== buyplanApprover?.trim()?.toLowerCase(),
                            ), [(b) => b.userName?.toLowerCase()])
                                .map((obj, index) => (
                                  <MenuItem
                                    sx={customTheme.selectMenuBox}
                                    key={obj.userId}
                                    value={obj.userId}
                                    onClick={()=>{
                                      setbuycreatPer(obj.personaId);
                                      setBuyplanCreator(obj.userName);
                                    }}
                                    className="this-is-new-class"
                                  >
                                    {obj.userName}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Buy Plan Approver*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={approverUID}
                            onChange={(e)=>{
                              setApproverUID(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty

                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                              <span>{row['buyplan_approver']}</span>
                            </MenuItem>
                            { _.orderBy(buyplanPersona['buyplan_approver'] ?.filter(
                                (obj) => obj.userName?.trim()?.toLowerCase()!== buyplanCreator?.trim()?.toLowerCase(),
                            ), [(b) => b.userName?.toLowerCase()])
                                .map((obj, index) => (
                                  <MenuItem
                                    sx={customTheme.selectMenuBox}
                                    key={obj.userId}
                                    value={obj.userId}
                                    onClick={()=>{
                                      setBuyAppPers(obj.personaId);
                                      setBuyplanApprover(obj.userName);
                                    }}
                                    className="this-is-new-class"
                                  >
                                    {obj.userName}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Buy Plan Admin*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={adminUID}
                            onChange={(e)=>{
                              setAdminUID(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty

                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                              <span>{row['buyplan_admin']}</span>
                            </MenuItem>
                            { _.orderBy(buyplanPersona['buyplan_admin'], [(b) => b.userName?.toLowerCase()])?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={obj.userId}
                                value={obj.userId}
                                onClick={()=>{
                                  setbuyadminPer(obj.personaId);
                                }}
                                className="this-is-new-class"
                              >
                                {obj.userName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Forecast Creator
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={forecastUID}
                            onChange={(e)=>{
                              setForecastUId(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty

                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                              <span>{row['forecast_creator']}</span>
                            </MenuItem>
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {_.orderBy(buyplanPersona['forecast_creator'], [(b) => b.userName?.toLowerCase()])?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={obj.userId}
                                value={obj.userId}
                                onClick={()=>{
                                  setforecastPer(obj.personaId);
                                }}
                                className="this-is-new-class"
                              >
                                {obj.userName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                           Buy Plan Viewer
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={viewerUId}
                            onChange={(e)=>{
                              setViewerUId(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty

                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                              {buyplanPersona['buyplan_viewer']?.map((item) =>
                              item.userId === viewerUId ? (
                                <span key={item.userId}>{item.userName}</span>
                              ) : null,
                              )}
                            </MenuItem>
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {_.orderBy(buyplanPersona['buyplan_viewer'], [(b) => b.userName?.toLowerCase()])?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={obj.userId}
                                value={obj.userId}
                                className="this-is-new-class"
                              >
                                {obj.userName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider color='#4297D3'/>
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Supplier*
                        </Box>
                      </Grid>
                      <Grid item>
                        {((row['is_cascade_target_bp'] && !editBuyPlanRowData?.supplierTargets) || (!row['is_cascade_target_bp'])) ?
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={supplierValue?true:false}
                                onChange={handleSupplierValue}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        /> :
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={(supplierValue !== undefined && supplierValue != null) ? ( editBuyPlanRowData?.supplierTargets? true: supplierValue) : false}
                                disabled
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Treasury*
                        </Box>
                      </Grid>
                      <Grid item>
                        {((row['is_cascade_target_bp'] && !editBuyPlanRowData?.treasuryTargets ) || (!row['is_cascade_target_bp'])) ?
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={treasuryValue?true:false}
                                onChange={handleTreasuryValue}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        /> :
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={(treasuryValue !== undefined && treasuryValue != null) ? ( editBuyPlanRowData?.treasuryTargets? true: treasuryValue) : false}
                                disabled
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Others*
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={othersValue?true:false}
                                onChange={handleOthersValue}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Send forecast to AtlasFX*
                        </Box>
                      </Grid>
                      <Grid item >
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={isAtlasfxValue?true:false}
                                onChange={handleIsAtlasfxValue}
                                disabled={supplierValue && !treasuryValue}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Carry Volume*
                        </Box>
                      </Grid>
                      <Grid item >

                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={(isCarryVolume || '')?true:false}
                                onChange={handleIsCarryVolume}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />


                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Optionality*
                        </Box>
                      </Grid>
                      <Grid item>
                        {row['is_cascade_target_bp'] != true ?
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={optionalityValue?true:false}
                                onChange={handleToggleOptionality}
                                disabled={row['is_cascade_target_bp']===true?true:false}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        /> :
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={optionalityValue?true:false}
                                disabled
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                        }

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Supplier Targets*
                        </Box>
                      </Grid>
                      <Grid item>
                        {row['is_cascade_target_bp'] != true ?
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={supplierTargetsValue?true:false}
                                onChange={ handleSupplierTargetsValue}
                                disabled={disableSupplierTarget===true?true:false}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        /> :
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={supplierTargetsValue?true:false}
                                disabled
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Supplier Optionality*
                        </Box>
                      </Grid>
                      <Grid item>
                        {row['is_cascade_target_bp'] != true ?
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={supplierOptionalityValue?true:false}
                                onChange={handleSupplierOptionalityValue}
                                disabled={disableSupplierOptionality===true?true:false}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        /> :
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={supplierOptionalityValue?true:false}
                                disabled
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Treasury Targets*
                        </Box>
                      </Grid>
                      <Grid item>
                        {row['is_cascade_target_bp'] != true ?
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={treasuryTargetsValue?true:false}
                                onChange={ handleTreasuryTargetsValue}
                                disabled={disableTreasuryTarget===true?true:false}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        /> :
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={treasuryTargetsValue?true:false}
                                disabled
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={true}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Treasury Optionality*
                        </Box>
                      </Grid>
                      <Grid item>
                        {row['is_cascade_target_bp'] != true ?
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={treasuryOptionalityValue?true:false}
                                onChange={handleTreasuryOptionalityValue}
                                disabled={disableTreasuryOptionality===true?true:false}
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        /> :
                        <FormControlLabel
                          control={
                            <ToggleContainer>
                              <ToggleSwitch
                                checked={treasuryOptionalityValue?true:false}
                                disabled
                              />
                              <span className="switchLabel labelYes">Yes</span>
                              <span className="switchLabel labelNo">No</span>
                            </ToggleContainer>
                          }
                        />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider color='#4297D3'/>
              {isAtlasfxValue &&
              <>
                <Box mt={2} sx={subheading} >
                  <p>Outbound Details (Compass → AtlasFX)</p>
                </Box>
                <Box mb={2} mt={2}>
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <BuyPlanTextBox heading={'Sector*'} value={oSectorValue} setValue={setOSectorValue} dropdownSpan={dropdownSpan}/>
                    <BuyPlanTextBox heading={'Reporting Region*'} value={oReportingRegionValue} setValue={setOReportingRegionValue} dropdownSpan={dropdownSpan}/>
                    <BuyPlanTextBox heading={'PlayBook*'} value={oPlayBookValue} setValue={setOPlayBookValue} dropdownSpan={dropdownSpan}/>
                    <BuyPlanTextBox heading={'Commodity*'} value={oCommodityValue} setValue={setOCommodityValue} dropdownSpan={dropdownSpan}/>
                    <BuyPlanTextBox heading={'Full Entity Name*'} value={oFullEntityNameValue} setValue={setOFullEntityNameValue} dropdownSpan={dropdownSpan}/>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <BuyPlanTextBox heading={'Chatham Folder Entity*'} value={oChathamFolderEntityValue} setValue={setOChathamFolderEntityValue} dropdownSpan={dropdownSpan}/>
                    <BuyPlanTextBox heading={'Forecast Program Name*'} value={oForecastProgramNameValue} setValue={setOForecastProgramNameValue} dropdownSpan={dropdownSpan}/>
                  </Grid>
                </Box>
                <Divider color='#4297D3' />
                <Box mt={2} sx={subheading} >
                  <p>Inbound Details (AtlasFX → Compass )</p>
                </Box>
                <Box mb={2} mt={2}>
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <BuyPlanTextBox heading={'Legal Entity*'} value={oLegalEntityValue} setValue={setOLegalEntityValue} dropdownSpan={dropdownSpan}/>
                    <BuyPlanTextBox heading={'Program Name*'} value={oProgramNameValue} setValue={setOProgramNameValue} dropdownSpan={dropdownSpan}/>
                    <BuyPlanTextBox heading={'Commodity Subtype*'} value={oCommoditySubtypeValue} setValue={setOCommoditySubtypeValue} dropdownSpan={dropdownSpan}/>
                    <Grid item xs={2.4}>
                      <Grid container direction="column" >
                        <Grid item>
                          <Box sx={dropdownSpan} component="span">
                        Currency*
                          </Box>
                        </Grid>
                        <Grid item>

                          <FormControl sx={dropdown}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              sx={customTheme.selectBox}
                              value={oCurrencyValue}
                              onChange={(e)=>{
                                setOCurrencyValue(e.target.value);
                              }}
                              label="Select"
                              input={<CustomInput label="Tag" />}
                              displayEmpty
                              className="newclass"
                            >
                              <MenuItem value="" sx={customTheme.selectMenuBox}>
                                <span>Select</span>
                              </MenuItem>
                              {Currency.length !== 0 &&
                            _.orderBy(Currency, [(p) => p.currency_description?.toLowerCase()])?.map((r) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.currency_id}
                                value={r?.currency_id}
                                className="this-is-new-class"
                              >
                                {r.currency_description}
                              </MenuItem>
                            ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid container direction="column" >
                        <Grid item>
                          <Box sx={dropdownSpan} component="span">
                         Measurement Unit*
                          </Box>
                        </Grid>
                        <Grid item>

                          <FormControl sx={dropdown}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              sx={customTheme.selectBox}
                              value={oMeasurementUnitValue}
                              onChange={(e)=>{
                                setOMeasurementUnitValue(e.target.value);
                              }}
                              label= {oMeasurementUnitValue}
                              input={<CustomInput label="Tag" />}
                              displayEmpty
                              className="newclass"
                            >
                              <MenuItem value="" sx={customTheme.selectMenuBox}>
                                <span>Select</span>
                              </MenuItem>
                              {Units.length !== 0 &&
                              _.orderBy(Units, [(u) => u?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.unit_id}
                                  value={r?.unit_id}
                                  className="this-is-new-class"
                                >
                                  {r.unit_description}
                                </MenuItem>


                              ))}
                            </Select>
                          </FormControl>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={2.4}>
                      <Grid container direction="column" >
                        <Grid item>
                          <Box sx={dropdownSpan} component="span">
                           Exposure Month Difference*
                          </Box>
                        </Grid>
                        <Grid item>
                          <FormControl sx={dropdown}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              sx={customTheme.selectBox}
                              value={oExposureMonthDifferenceValue}
                              onChange={(e)=>{
                                setOExposureMonthDifferenceValue(e.target.value);
                              }}
                              label= {oExposureMonthDifferenceValue}
                              input={<CustomInput label="Tag" />}
                              displayEmpty
                              className="newclass"
                              IconComponent={() => null}
                              endAdornment={
                                <InputAdornment position="end" sx={iconColumn}>
                                  <IconButton onClick={handleIncrement} sx={arrowIcon}>
                                    <ArrowDropUpIcon />
                                  </IconButton>
                                  <IconButton onClick={handleDecrement} sx={arrowIcon}>
                                    <ArrowDropDownIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            >
                              {numberOptions.length !== 0 &&
                              numberOptions?.map((val) => (
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={val}
                                  value={val}
                                  className="this-is-new-class"
                                >
                                  {val}
                                </MenuItem>
                              ))}
                            </Select>

                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Divider color='#4297D3'/>
              </>
              }
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Current Status*
                        </Box>
                      </Grid>
                      {/* <Grid item>

                        <Box sx={dropdown}>
                          {formatStatus(row['current_status'])}
                        </Box>


                      </Grid> */}
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={currentStatusValue}
                            onChange={(e)=>{
                              setCurrentStatusValue(e.target.value);
                              setOptionalityDetails({...optionalityDetails, current_status: e.target.value});
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            { ['Active', 'Coverage Suspension', 'Coverage Review Suspension']?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Current Status Start Month*
                        </Box>
                      </Grid>
                      <Grid item>
                        {/* <Box sx={dropdown}>
                          {row['active_start_date']}
                        </Box> */}
                        <Box sx={dropdown}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={['month', 'year']}
                              format={'MMM-YYYY'}
                              value={selectedActiveStartDate || defaultActiveStartDate}
                              onChange={handleActiveStartDateChange}
                              minDate={minActiveStartDate}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Current Status End Month
                        </Box>
                      </Grid>
                      <Grid item>

                        {/* {formatStatus(futureStatusValue)==''? */}
                        <Box sx={dropdown}>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={['month', 'year']}
                              format={'MMM-YYYY'}
                              value={selectedActiveEndDate || defaultActiveEndDate}
                              onChange={handleActiveEndDateChange}
                              minDate={minActiveEndDate}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </Box>
                        {/* :<Box sx={dropdown}>
                           {row['active_end_date']}
                         </Box>
                         } */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider color='#4297D3'/>
              <Box mb={2} mt={2}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Future Status
                        </Box>
                      </Grid>
                      <Grid item>
                        <FormControl sx={dropdown}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={futureStatusValue}
                            onChange={(e)=>{
                              setFutureStatusValue(e.target.value);
                              setOptionalityDetails({...optionalityDetails, future_status: e.target.value});
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            { [null, ...['Active', 'Inactive', 'Coverage Suspension', 'Coverage Review Suspension']]?.map((obj, index) => (
                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={index}
                                value={obj}
                                className="this-is-new-class"
                              >
                                {obj || 'Select'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>


                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                         Future Status Start Month
                        </Box>
                      </Grid>
                      <Grid item>

                        {formatStatus(futureStatusValue)==''?
                        <Box sx={dropdown}>
                          {row['future_status_start_date']}
                        </Box>:
                        <FormControl sx={dropdown}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={['month', 'year']}
                              disablePast={true}
                              value={selectedFutureStartDate || defaultFutureStartDate}
                              onChange={handleFutureStartDateChange}
                              format={'MMM-YYYY'}
                              minDate={minFutureStartDate}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControl>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid container direction="column" >
                      <Grid item>
                        <Box sx={dropdownSpan} component="span">
                          Future Status End Month
                        </Box>
                      </Grid>
                      <Grid item>


                        {formatStatus(futureStatusValue)==''?
                        <Box sx={dropdown}>
                          {row['future_status_end_date']}
                        </Box>:
                        <Box sx={dropdown}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              views={['month', 'year']}
                              disablePast={true}
                              format={'MMM-YYYY'}
                              value={selectedFutureEndDate || defaultFutureEndDate}
                              onChange={handleFutureEndDateChange}
                              minDate={selectedFutureStartDate}
                              renderInput={(params) => <input {...params} />}
                            />
                          </LocalizationProvider>
                        </Box>}


                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
                <AppButton label="Save" onClick={saveTheRow} disabled={!enableSaveButton}/>
                <AppButton label="Cancel" onClick={(e)=>{
                  setPopup(false);
                  cancelEdit();
                }} />
              </Box>
            </Box>


          </DialogContent>
        </Dialog>
      </Box>
      <AppDialog open={showSuccessDialog} handleClose={()=>setshowSuccessDialog(false)} text='Buy Plan deleted Successfully!'/>
    </>
  );
}


export default EditConfTable;
