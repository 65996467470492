/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Box, Checkbox, FormControl, InputBase, ListItemText, MenuItem, Select, TableCell, TableRow, TextArea, createTheme, tableCellClasses} from '@mui/material';
import {BiEdit} from 'react-icons/bi';
import theme from '../../../theme';
import customealltheme from '../../../theme';
import config from '../../../Utils/Config';
import './NotificationSettings.css';
import moment from 'moment';
import NotificationSettingDialog from './NotificationSettingDialog';
import ConfigurationServices from '../../../services/ConfigurationServices';
import {AppDialog, AppErrorDialog} from '../../common/App-Dialog/AppDialog';
const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8f8f8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});
const NotificationSettingDialogSingleRow = ({row, key, accessControl, getAllRoles, setData, scenarioId, setopen}) => {
  const [presentRow, setPresentRow] = useState({});
  const [editMode, seteditMode] = useState(false);
  const Edit = config.AccessLevel.Edit;
  // const [open, setopen] = useState(false);
  const [RoleDesc, setRoleDesc] = React.useState([]);
  const [CCRoleDesc, setCCRoleDesc] = React.useState([]);
  const [savingerror, setSavingError] = useState(false);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const editRow = () => {
    seteditMode(true);
  };
  const saveTheRow = async () => {
    const obj = {
      id: presentRow.id,
      scenarioId: scenarioId,
      subject: presentRow.subject,
      message: presentRow.message,
      toRecipients: presentRow.recipient_persona?.map((r)=>r.roleId),
      // ccRecipients: presentRow.cc_recipient_persona?.map((r)=>r.roleId),
    };
    await ConfigurationServices.postRecipientList(obj).then((res)=>{
      setIsSavePopupVisible(true);
      setData((prev)=>prev.map((p, i)=>i==key? presentRow: p));
    }).catch((err)=>{
      // setPresentRow({...row});

      setErrorMessage(err.userMessage);
      setSavingError(true);
    });
    // setData((prev)=>prev.map((p, i)=>i==key? presentRow: p));
    seteditMode(false);
  };
  useEffect(()=>{
    setPresentRow({...row});
    const rolesDes = row.recipient_persona?.map((r)=>r.role);
    if (rolesDes.length==getAllRoles.length) {
      setRoleDesc(['select_all', ...rolesDes]);
    } else {
      setRoleDesc([...rolesDes]);
    }

    // const ccrolesDes = row.cc_recipient_persona?.map((r)=>r.role);
    // if (ccrolesDes.length==getAllRoles.length) {
    //   setCCRoleDesc(['select_all', ...ccrolesDes]);
    // } else {
    //   setCCRoleDesc([...ccrolesDes]);
    // }
  }, [getAllRoles.length, row]);

  const handleRoleDesc = (event) => {
    setRoleDesc([]);
    // debugger;
    const {
      target: {value},
    } = event;
    // debugger;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      getAllRoles?.map((row) => str.push(row?.description));
      const convertedRoles = getAllRoles.map((role) => {
        return {
          'roleType': role.role,
          'roleId': role.role_id,
          'role': role.description,
          'subject': null,
          'message': null,
        };
      });
      setPresentRow((prev)=>({...prev, recipient_persona: [...convertedRoles]}));

      setRoleDesc(str);
    } else if (RoleDesc.includes('select_all') && !value.includes('select_all')) {
      setPresentRow((prev)=>({...prev, recipient_persona: []}));

      setRoleDesc([]);
    } else {
      if (RoleDesc.includes('select_all')) {
        setPresentRow((prev)=>({...prev, recipient_persona: [...presentRow.recipient_persona.filter((f)=>Array.from(value).includes(f.role))]}));
        setRoleDesc([]);
      };

      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      console.log(tepVal);
      setRoleDesc(tepVal);
    }
  };

  const handleCCRoleDesc = (event) => {
    setCCRoleDesc([]);
    // debugger;
    const {
      target: {value},
    } = event;
    // debugger;
    if (value[value.length - 1] === 'select_all') {
      const str = ['select_all'];
      getAllRoles?.map((row) => str.push(row?.description));
      const convertedRoles = getAllRoles.map((role) => {
        return {
          'roleType': role.role,
          'roleId': role.role_id,
          'role': role.description,
          'subject': null,
          'message': null,
        };
      });
      setPresentRow((prev)=>({...prev, cc_recipient_persona: [...convertedRoles]}));

      setCCRoleDesc(str);
    } else if (RoleDesc.includes('select_all') && !value.includes('select_all')) {
      setPresentRow((prev)=>({...prev, cc_recipient_persona: []}));

      setCCRoleDesc([]);
    } else {
      if (RoleDesc.includes('select_all')) {
        setPresentRow((prev)=>({...prev, cc_recipient_persona: [...presentRow.cc_recipient_persona.filter((f)=>Array.from(value).includes(f.role))]}));
        setCCRoleDesc([]);
      };

      const tepVal = Array.from(value);
      if (tepVal.includes('select_all')) tepVal.splice(tepVal.indexOf('select_all'), 1);
      console.log(tepVal);
      setCCRoleDesc(tepVal);
    }
  };
  return (
    <>
      <StyledTableRow>
        {/* <StyledTableCell component="th" scope="row" sx={{width: '50px'}}>
          {presentRow.recipientId}
        </StyledTableCell> */}
        <StyledTableCell component="th" scope="row" sx={{width: '50px'}}>
          {editMode==true?
        //    <div className='roleCont'>{ getAllRoles.map((r) => (
        //      <div onClick={()=>{
        //        if (presentRow.recipient_persona?.find((obj) => obj.role_id === r.role_id)) {
        //          setPresentRow((prev)=>({...prev, recipient_persona: presentRow.recipient_persona?.filter((f)=>f.role_id!=r.role_id)}));
        //        } else {
        //          setPresentRow((prev)=>({...prev, recipient_persona: [...presentRow.recipient_persona, r]}));
        //        }
        //      }} key={r.role} className={`${presentRow.recipient_persona?.find((obj) => obj.role_id === r.role_id)? 'selectedNum': 'notSelectedNum'}`}>
        //        {r.role}
        //      </div>
        //    ))}</div>
        <FormControl>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx = {{
              '.MuiOutlinedInput-input': {
                'padding': '11.5px 14px',
                'border': 'none',
                '.MuiOutlinedInput-notchedOutline:hover': {border: '1px solid blue'},
              },
              '.MuiSelect-select': {
                font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                color: 'rgba(85, 87, 89, 1)',
                maxWidth: '150px',
              },
            }}
            variant="standard"
            id="demo-multiple-checkbox"
            multiple
            value={RoleDesc}
            label="roleDesc"
            onChange={handleRoleDesc}
            input={<CustomInput label="Tag" />}
            displayEmpty
            renderValue={( selected, displayEmpty) => displayEmpty = RoleDesc.length ? selected.join(', ') :'Select All'}
          >
            <MenuItem sx={{'padding': '3px 5px'}} key='select_all' value='select_all' className='this-is-new-class'>
              <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '13px'}}} checked={RoleDesc?.indexOf('select_all') > -1 || getAllRoles.length==RoleDesc.length}/>
              <ListItemText sx={{'.MuiTypography-root': {fontSize: '13px'}}} primary="Select All" className="myClass"/>
            </MenuItem>
            {(getAllRoles.length !== 0) &&
            _.orderBy(_.uniqBy(getAllRoles, 'description'), [(pb) => pb?.description?.toLowerCase()])?.map((row) => (

              <MenuItem
                sx={{
                  'padding': '3px 5px',
                }}
                key={row?.role_id}
                value={row?.description} onClick={()=>{
                  if (presentRow.recipient_persona?.find((obj) => obj.roleId === row.role_id)) {
                    setPresentRow((prev)=>({...prev, recipient_persona: presentRow.recipient_persona?.filter((f)=>f.roleId!=row.role_id)}));
                  } else {
                    setPresentRow((prev)=>({...prev, recipient_persona: [...presentRow.recipient_persona, {'roleType': row.role, 'roleId': row.role_id, 'role': row.description, 'subject': null,
                      'message': null}]}));
                  }
                }} >
                <Checkbox
                  sx={{
                    '.MuiSvgIcon-root': {
                      fontSize: '13px',
                    },
                  }}
                  checked={RoleDesc.indexOf(row?.description) > -1}
                />
                <ListItemText
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '13px',
                      whiteSpace: 'normal',
                    },
                  }}
                  primary={row?.description} />
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
           presentRow.recipient_persona?.map((role)=> role.role).join(', ')}
        </StyledTableCell>
        {/* <StyledTableCell component="th" scope="row" sx={{width: '50px'}}>
          {editMode==true?
        <FormControl>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx = {{
              '.MuiOutlinedInput-input': {
                'padding': '11.5px 14px',
                'border': 'none',
                '.MuiOutlinedInput-notchedOutline:hover': {border: '1px solid blue'},
              },
              '.MuiSelect-select': {
                font: 'normal normal normal 13px/15px \'Segoe UI\' ',
                color: 'rgba(85, 87, 89, 1)',
                maxWidth: '150px',
              },
            }}
            variant="standard"
            id="demo-multiple-checkbox"
            multiple
            value={CCRoleDesc}
            label="CCRoleDesc"
            onChange={handleCCRoleDesc}
            input={<CustomInput label="Tag" />}
            displayEmpty
            renderValue={( selected, displayEmpty) => displayEmpty = CCRoleDesc.length ? selected.join(', ') :'Select All'}
          >
            <MenuItem sx={{'padding': '3px 5px'}} key='select_all' value='select_all' className='this-is-new-class'>
              <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '13px'}}} checked={CCRoleDesc?.indexOf('select_all') > -1 || getAllRoles.length==CCRoleDesc.length}/>
              <ListItemText sx={{'.MuiTypography-root': {fontSize: '13px'}}} primary="Select All" className="myClass"/>
            </MenuItem>
            {(getAllRoles.length !== 0) &&
            _.orderBy(_.uniqBy(getAllRoles, 'description'), [(pb) => pb?.description?.toLowerCase()])?.map((row) => (

              <MenuItem
                sx={{
                  'padding': '3px 5px',
                }}
                key={row?.role_id}
                value={row?.description} onClick={()=>{
                  if (presentRow.cc_recipient_persona?.find((obj) => obj.roleId === row.role_id)) {
                    setPresentRow((prev)=>({...prev, cc_recipient_persona: presentRow.cc_recipient_persona?.filter((f)=>f.roleId!=row.role_id)}));
                  } else {
                    setPresentRow((prev)=>({...prev, cc_recipient_persona: [...presentRow.cc_recipient_persona, {'roleType': row.role, 'roleId': row.role_id, 'role': row.description, 'subject': null,
                      'message': null}]}));
                  }
                }} >
                <Checkbox
                  sx={{
                    '.MuiSvgIcon-root': {
                      fontSize: '13px',
                    },
                  }}
                  checked={CCRoleDesc.indexOf(row?.description) > -1}
                />
                <ListItemText
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '13px',
                      whiteSpace: 'normal',
                    },
                  }}
                  primary={row?.description} />
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
           presentRow.cc_recipient_persona?.map((role)=> role.role).join(', ')}
        </StyledTableCell> */}
        <StyledTableCell component="th" scope="row" sx={{width: '100%'}}>
          {presentRow.notification_text}
        </StyledTableCell>
        <StyledTableCell align="left">
          {accessControl === Edit && (
            <StyledTableCell align="left">
              {editMode === false ? (
      <Box sx={{display: 'flex', gap: '8px', width: '200px'}}>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
          }}
          onClick={editRow}
          style={customealltheme.palette.TableEdit}
        >
          <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
          <Box>Edit</Box>
        </Box>
      </Box>
    ) : (
      <Box sx={{display: 'flex', gap: '5px'}}>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
            background: '#4297d3',
            color: 'white',
            justifyContent: 'center',
          }}
          onClick={saveTheRow}
        >
          <Box>Save</Box>
        </Box>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
          }}
          onClick={(e) =>{
            setPresentRow(row);
            setRoleDesc(row.recipient_persona?.map((r)=>r.role));
            seteditMode(false);
          }}
        >
          <Box>Cancel</Box>
        </Box>
      </Box>
    )}
            </StyledTableCell>
          )}

        </StyledTableCell>
      </StyledTableRow>
      {/* <NotificationSettingDialog open={open} setopen={setopen}/> */}
      <AppErrorDialog open={savingerror} handleClose={()=> {
        setSavingError(false);
        setopen(false);
      }} text={errorMessage}/>
      <AppDialog
        open={isSavePopupVisible}
        handleClose={() => {
          setIsSavePopupVisible(false);

          // setValue(1);
        }}
        text="Notification scenario saved successfully"
      />
    </>
  );
};

export default NotificationSettingDialogSingleRow;
