import React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MultiSelectBox from './SelectBox';

const StyledBoxFilter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '1.25rem',
  marginTop: '10px',
});

const StyledBoxLabel = styled(Box)({
  marginBottom: '15px',
  color: '#3B4655',
  fontWeight: 600,
  fontSize: '12px',
});

function MultiSelectDropDown(props) {
  return (
    <StyledBoxFilter>
      <StyledBoxLabel component="span">{props?.label}</StyledBoxLabel>
      <FormControl>
        <MultiSelectBox
          items={props?.items}
          label={props?.label}
          id={props?.id}
          handleChange={props?.handleChange}
          selectedItems={props?.selectedItems}
          disabled={props?.disabled}
        />
      </FormControl>
    </StyledBoxFilter>
  );
}

export default MultiSelectDropDown;
