import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  sector: '',
  playbookName: '',
};

const navigationDataSlice = createSlice({
  name: 'navigationData',
  initialState,
  reducers: {
    selectSector: (state, action) => {
      state.sector = action.payload;
    },
    selectPlaybook: (state, action) => {
      state.playbookName = action.payload;
    },
    setNavData: (state, action) => {
      state.sector = action.payload.sector;
      state.playbookName = action.payload.playbookName;
    },
    resetNavData: (state, action)=>{
      state.sector = '';
      state.playbookName = '';
    },
  },
});

export const navigationDataReducer = navigationDataSlice.reducer;
export const {setNavData, selectSector, selectPlaybook, resetNavData} = navigationDataSlice.actions;
