/* eslint-disable max-len */
import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import {Box, Tooltip} from '@mui/material';
import Button from '@mui/material/Button';
import EditNonPlayBook from './EditNonPlayBookPopup';
// import {BiEdit} from 'react-icons/bi';
import {FiEye} from 'react-icons/fi';
import PopupStepper from './PopupStepper';
import {useDispatch, useSelector} from 'react-redux';

import {changeButtonStatusValue, changeBuyplanStatus, changePageStatus} from '../../redux/features/buttonStatus';
import {RowContext} from './ActiveNonPlayBook';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {setForeCastSaveButton, setForecastUpdatesaveButton, setHistoricalsaveButton, setNonPlayBookCoverageButton, setSortFilter, setSortType} from '../../redux/features/configurations';
import {useEffect} from 'react';
import {buyPlanData} from '../../redux/BuyPlan';
import {setPreviousMonthWillCoverEditButton} from '../../redux/EditStatus';


const TABLE_HEADS = ['Non Playbook Name', 'Sector', 'Region/Country', 'Category', 'Commodity', 'Sub-Commodity', 'Plan Month', 'Status', 'Action'];
const values = ['reference', 'sector', 'country', 'category', 'commodity', 'sub_commodity', 'execution_month', 'status', ''];

const sty = {
  color: '',
  bgcolor: '',
};

const changeButtonColor = (value) => {
  switch (value) {
    case 'APPROVED':
      sty.color = '#399f0a';
      sty.bgcolor = '#c0ecac';
      break;
    case 'NEW':
      sty.color = '#0C80F4';
      sty.bgcolor = '#DFEDFD';
      break;
    case 'NEW_COV_PEND':
      sty.color = '#0C80F4';
      sty.bgcolor = '#DFEDFD';
      break;
    case 'SUBMITTED':
      sty.color = '#CC7A00';
      sty.bgcolor = '#FCE5CD';
      break;
    case 'DRAFT':
      sty.color = '#9042D3';
      sty.bgcolor = '#D342D321';
      break;
    case 'REJECTED':
      sty.color = '#D54E4E';
      sty.bgcolor = '#F3E1E1';
      break;
    case 'CLOSED':
      sty.color = '#0F5DAA';
      sty.bgcolor = '#9fbedd';
      break;
    case 'NOT_STARTED':
      sty.color = '#00B1FF';
      sty.bgcolor = '#0093FF21';
      break;
    default:
      sty.color = '#0B5394';
      sty.bgcolor = '#DFEDFD';
      break;
  }
};

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8f8f8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function HistoricalNonPlayBook({data, sortValues, setPage, page, sortMap, setSortMap, sortStatusMapping}) {
  const rows = data;
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedWfId, setSelectedWfId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isStatusPopupVisible, setIsStatusPopupVisible] = useState(false);
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Non_PlayBook:Historical_List']) {
      setAccessControl(functionalityAccess['Non_PlayBook:Historical_List']);
    }
  }, [functionalityAccess]);
  const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const changeToMonth = (month) => {
    return monthArray[month -1];
  };
  const dispatch = useDispatch();
  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(()=>{
    setSortMap(sortStatusMapping);
  }, []);
  // const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  // useEffect(()=>{
  //   if (functionalityAccess && functionalityAccess['Home_Page:Historical_Buy_Plans_List']) {
  //     setAccessControl(functionalityAccess['Home_Page:Historical_Buy_Plans_List']);
  //   }
  // }, [functionalityAccess]);


  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  function getTotalNoOfRows(data) {
    let curCount = 0;
    const sepInd = [];
    data.forEach((eachItem, index) => {
      if (eachItem.showValue) {
        curCount += 1;
        sepInd.push(index);
      }
    });
    return {curCount, sepInd};
  }
  function getDataForTable(data, page, rowsPerPage) {
    const {sepInd} = getTotalNoOfRows(data);
    const startIndex = sepInd[page * rowsPerPage];
    const endIndex = sepInd[page * rowsPerPage + rowsPerPage];
    return data?.slice(startIndex, endIndex);
  }

  useEffect(()=>{
    if (rows && selectedRow) {
      for (let i=0; i<rows.length; i++) {
        if (selectedRow?.workflow_id===rows[i]?.workflow_id) {
          setSelectedRow(rows[i]);
          break;
        }
      }
    }
  }, [rows]);

  return (<>
    <Box sx={{width: '100%', padding: '0px 18px'}}>
      <Box sx={{marginTop: '20px'}}>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 700}} aria-label="customized table">
            <TableHead>
              <TableRow>
                {TABLE_HEADS.map((h, index)=>(
                  <StyledTableCell align="left" key={index}>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                      <Box>{h}</Box>
                      <Box style={{cursor: 'pointer', margin: '3px 0px 0px 5px'}}>
                        {h === 'Status' && (
                          <Tooltip style={{background: 'white'}} arrow title="Click on the status to see a historical timeline of activities" disableInteractive>
                            <span className='plusIcon'>i</span>
                          </Tooltip>
                        )}
                      </Box>
                      {sortValues[index] === true && sortMap[index].sort_value !== 'status' && sortMap[index].sort_value !== 'non_playbook_name' && sortMap[index].sort_active === true &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '1', fontSize: '10px', display: 'flex'}}
                          onClick={(e)=> {
                            dispatch(setSortFilter(values[index]));
                            const newSortObj = [];
                            sortMap.forEach((sortObj, id) => {
                              if (id === index) {
                                newSortObj.push({...sortObj, 'sort_active': true});
                              } else {
                                newSortObj.push({...sortObj, 'sort_active': false});
                              }
                            });
                            setSortMap(newSortObj);
                            if (sorttype === 'asc' ) {
                              dispatch(setSortType('desc'));
                              const newSortObj = [];
                              sortMap.forEach((sortObj, id) => {
                                if (id === index) {
                                  newSortObj.push({...sortObj, 'sort_order': 'desc'});
                                } else {
                                  newSortObj.push({...sortObj});
                                }
                              });
                              setSortMap(newSortObj);
                            } if (sorttype === 'desc' || sorttype === '') {
                              dispatch(setSortType('asc'));
                              const newSortObj = [];
                              sortMap.forEach((sortObj, id) => {
                                if (id === index) {
                                  newSortObj.push({...sortObj, 'sort_order': 'asc'});
                                } else {
                                  newSortObj.push({...sortObj});
                                }
                              });
                              setSortMap(newSortObj);
                            }
                          }}
                        > <SwapVertIcon sx={{fontSize: '16px'}}/>
                          {sorttype}
                        </Box>}
                      {
                        (sortMap[index].sort_value !== 'status' && sortMap[index].sort_value !== 'non_playbook_name') && !(sortValues[index] === true && sortMap[index].sort_active === true) && index !== 8 &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0', fontSize: '10px', display: 'flex'}}
                          onMouseOver={(e)=>{
                            e.target.style.opacity = '1';
                          }}
                          onMouseLeave={(e)=>{
                            e.target.style.opacity = '0';
                          }}
                          onClick={(e)=> {
                            dispatch(setSortFilter(values[index]));
                            const newSortObj = [];
                            sortMap.forEach((sortObj, id) => {
                              if (id === index) {
                                newSortObj.push({...sortObj, 'sort_active': true});
                              } else {
                                newSortObj.push({...sortObj, 'sort_active': false});
                              }
                            });
                            setSortMap(newSortObj);
                          }}
                        >
                          <SwapVertIcon sx={{fontSize: '16px'} }/>
                          {sortMap[index].sort_order}
                        </Box>
                      }
                    </Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getDataForTable(data, page, rowsPerPage)
                  ?.map((row, id) => (
                    <StyledTableRow key={id}>
                      <StyledTableCell align="left">
                        {row.non_playbook_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.sector}</StyledTableCell>
                      <StyledTableCell align="left">{row.region}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.commodity}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.sub_commodity}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {/* {row.showValue && (changeToMonth(row.executionMonthYear[1]) (row.executionMonthYear[0]))} */}
                        {
                          (`${changeToMonth(row?.execution_month[1])}  ${row.execution_month[0]}`)
                        }
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {changeButtonColor(row.status)}
                        <Button
                          sx={{
                            'cursor': 'pointer',
                            'color': `${sty.color}`,
                            'bgcolor': `${sty.bgcolor}`,
                            'border': 'none',
                            'padding': '0px',
                            'borderRadius': '3px',
                            'width': '5.5rem',
                            'font': '12px/16px \'Segoe UI\'',
                            '&:hover': {
                              backgroundColor: `${sty.bgcolor}`,
                              border: 'none',
                            },
                            'textTransform': 'capitalize',
                          }}
                          variant="outlined"
                          onClick={()=> {
                            setSelectedWfId(row.workflow_id);
                            setSelectedRow(row);
                            setIsStatusPopupVisible(true);
                          }}
                        >
                          <b>{row.status === 'NEW' || row.status === 'NEW_COV_PEND' ?'Not Started':row.status.toLowerCase()}</b>
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.showValue && <Button
                          sx={{
                            'cursor': 'pointer',
                            'display': 'flex',
                            'alignItems': 'center',
                            'padding': '1px 4px',
                            'bgcolor': 'white',
                            'border': '0.8px solid #cec8c8',
                            'borderRadius': '3px',
                            'minWidth': '6rem',
                            'font': 'normal normal normal 10px/20px \'Segoe UI\'',
                            'color': 'black',
                            '&:hover': {
                              'border': '0.8px solid #cec8c8',
                            },
                          }}
                          variant="outlined"
                          onClick={()=> {
                            dispatch(changePageStatus('historical'));
                            dispatch(changeButtonStatusValue('view'));
                            // if ( row.status === 'NEW_COV_PEND' ) {
                            //   alert( 'Previous Month Coverage has to be closed before creating Non-Playbook for current month. Please contact administrator/support team for further details' );
                            //   return;
                            // }
                            // if (accessControl==='Edit' || accessControl==='Read') {
                            // console.log(row);
                            dispatch(changeBuyplanStatus(row.status));
                            // we enabling the edit input boxes if he is a Admin not for the buyplan creator
                            if ((row.status==='NEW' || row.status === 'NEW_COV_PEND') && currentRole?.includes('Admin')) {
                              dispatch(setForeCastSaveButton('false'));
                              dispatch(setHistoricalsaveButton('false'));
                              dispatch(setForecastUpdatesaveButton(true));
                              dispatch(setNonPlayBookCoverageButton('false'));
                            } else {
                              dispatch(setForeCastSaveButton('true'));
                              dispatch(setHistoricalsaveButton('true'));
                              dispatch(setForecastUpdatesaveButton(false));
                              dispatch(setNonPlayBookCoverageButton('true'));
                            }
                            setSelectedRow(row);
                            setIsPopupVisible(true);
                            // }

                            // }
                          }}
                        >
                          {/* <><FiEye style={{fontSize: '13px', color: '#0071DC'}} />View</> */}
                          {(() => {
                            if ( accessControl==='Edit' && row.status !=='CLOSED') {
                              return (<><FiEye style={{fontSize: '13px', color: '#3174b6', textTransform: 'capitalize'}} />Edit</>);
                            } else {
                              return (<><FiEye style={{fontSize: '13px', color: '#3174b6', textTransform: 'capitalize'}} />View</>);
                            }
                          })()}
                        </Button>}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[12, 24, 36]}
          component="div"
          count={getTotalNoOfRows(rows).curCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
    <PopupStepper open={isStatusPopupVisible} handleClose={() => {
      setIsStatusPopupVisible(false);
      setSelectedWfId(null);
      setSelectedRow(null);
    }} wfId={selectedWfId} selectedRow={selectedRow}/>
    {/* <AppMainDialog open={isPopupVisible} handleClose={() => {
      setIsPopupVisible(false);
    }} selectedRow = {selectedRow}/> */}
    <RowContext.Provider value={selectedRow}>
      <EditNonPlayBook open={isPopupVisible} access={accessControl} setIsPopupVisible={setIsPopupVisible} handleClose={() => {
        setIsPopupVisible(false);
        dispatch(setNonPlayBookCoverageButton('true'));
        dispatch(changeBuyplanStatus(''));
        dispatch(buyPlanData([]));
        dispatch(setPreviousMonthWillCoverEditButton(false));
      }} selectedRow = {selectedRow} />
    </RowContext.Provider>
  </>);
}

export default HistoricalNonPlayBook;
