import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import './RolesTagging.css';
const RolestaggingPopUp = ({searchingString, changingCommentText, setAddedTags, changedObj, addedtags, setEnablingRolePopup}) => {
  const allRolesToTag = useSelector((state) => state.api.commentTaggingRoles);
  const [filteredData, setFiltereddata] = useState([]);
  useEffect(()=>{
    const sorteddata = structuredClone(allRolesToTag)?.sort((a, b)=> a.user_name.toLowerCase().localeCompare(b.user_name.toLowerCase()));

    if (searchingString=='') {
      setFiltereddata(sorteddata);
    } else {
      setFiltereddata(sorteddata.filter((f)=>f.user_name.toLowerCase().slice(0, searchingString.length) == searchingString.toLowerCase()));
    }
  }, [allRolesToTag, searchingString]);

  const addingtaggedNames = (m) => {
    if (m.user_name.split(' ').length>1) {
      changingCommentText(m.user_name.split(' ').join('_'));
    } else {
      changingCommentText(m.user_name);
    }
    const adTags = {...addedtags};
    if (adTags[changedObj]) {
      if (!adTags[changedObj].map((f)=>f.user_id).includes(m.user_id)) {
        adTags[changedObj].push(m);
      }
    } else {
      adTags[changedObj] = [m];
    }
    // console.log(adTags);
    setAddedTags({...adTags});
    setEnablingRolePopup(false);
  };

  return (
    <div className='rolesTaggingPopup'>
      {/* <div className= 'rolesTaggingHeader'>Compass Users</div> */}
      {filteredData?.map((m, i)=>(
        <div key={i} className="userDetails" onClick={()=>addingtaggedNames(m)}>
          <div className='username'>{m.user_name}</div>
          <div className='email'>{m.email}</div>
        </div>
      ))}
    </div>
  );
};

export default RolestaggingPopUp;
