/* eslint-disable no-unused-vars */
/* This Page is the  Currency master page which has feautures like adding Currencys, editing existing Currencys and filtering the Currencys
based on the Currency.
*/

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import CurrencyDisplay from './CurrencyDisplay';
// import Loading from '../CommonConfFiles/Loading/Loading';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import CommonConfTable from './commonTable/CommonConfTable';
import config from '../../../Utils/Config';

const Currency = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);


  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);

  const heads = [
    'Currency Description', 'Is Active', 'Currency', 'Last Edited By', 'Last Edited On'];

  const values = ['currency_description', 'is_active',
    'currency', 'last_updated_by', 'last_updated_on',
  ];

  const [pagination, setPagination] = useState(10);

  const dateValues = [false, false, false, false, true];

  const editValues = [true, true, true, false, false];

  const [loading, setLoading] = useState(false);
  const [addingTrigger, setAddingTrigger] = useState(false);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedCurrencyFilterValue);


  const sortValues = [true, false, true, true, true];


  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  const updateSingleUnitMaster = ConfigurationServices.updateCurrencyData;
  const newRowUpdate = ConfigurationServices.postCurrencyData;


  useEffect(()=>{
    ConfigurationServices.getCurrency()
        .then((res)=>{
          setLoading(true);
          setTimeout(()=>{
            setLoading(false);
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                setRow(res);
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          }, 100);
        });
  }, [addingTrigger]);


  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='currencyDes_all' ||
     filteredUnitValue[0]==='currency_all' ) {
      // row.sort((a, b) =>
      //   a[values[0]].localeCompare(b[values[0]]));
      setTableFilterData([...row]);
    } else {
      setLoading(true);

      setTimeout(()=>{
        setLoading(false);
        if (filteredUnitValue['CurrencyArray'].length>0 &&
        filteredUnitValue['currencyDesArray'].length>0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['CurrencyArray'].includes(r.currency) &&
            filteredUnitValue['currencyDesArray'].includes(r.currency_description);
          }));
        } else if (filteredUnitValue['CurrencyArray'].length>0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['CurrencyArray'].includes(r.currency);
          }));
        } else if (filteredUnitValue['currencyDesArray'].length > 0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['currencyDesArray']
                .includes(r.currency_description);
          }));
        } else if (filteredUnitValue['CurrencyArray'].length == 0 &&
        filteredUnitValue['currencyDesArray'].length == 0) {
          setTableFilterData([...row]);
        }
        setPage(0);
      }, 100);
    }
  }, [filteredUnitValue, row, addingTrigger]);

  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='last_updated_on') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.last_updated_on));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Currency_Master']) {
        setAccessControl(functionalityAccess['Configuration:Currency_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [page, setPage] = React.useState(0);
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>

        <CurrencyDisplay row={row} setPage={setPage}/>

        {/* {loading ? <Loading /> : */}


        <Box sx={{}}>
          <CommonConfTable
            data={tableFilterData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            sortValues={sortValues}
            accessControl={accessControl}
            setPage={setPage}
            page={page}
          />

          {(errMsg !=='' || tableFilterData.length == 0) &&
        <ErrorMessage message='Currency Master' errmsg={errMsg}/>
          }
        </Box>
        {/* } */}
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>}
    </Box>
  );
};

export default Currency;
