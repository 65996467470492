/* eslint-disable no-unused-vars */
/* This Page is the  unit master page which has feautures like adding units, editing existing units and filtering the units
based on the unit.
*/

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../../services/ConfigurationServices';
import theme from '../../../../theme';
import Loading from '../../CommonConfFiles/Loading/Loading';
import ErrorMessage from '../../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../../common/App-Dialog/AppDialog';
import CategoryTable from './CommonTable/MasterSectorTable';
import MasterSectorDisplay from './MasterSectorDisplay';
import config from '../../../../Utils/Config';
import _ from 'lodash';

const MasterSector = (props) => {
  const [row, setRow] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);


  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [idKey, setIdKey] = useState([]);

  const heads = ['Master Sector',
    'Is Active', 'Last Edited By', 'Last Edited On'];

  const values = ['master_sector', 'is_active', 'updated_by', 'updated_on',
  ];

  const dateValues = [false, false];

  const editValues = [true, true];

  const [pagination, setPagination] = useState(10);

  const [loading, setLoading] = useState(false);
  const [addingTrigger, setAddingTrigger] = useState(false);

  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedMasterSectorValue);

  const sortValues = [true, false, true, true];


  const SortValue = useSelector((state)=>state
      .configurations.selectedSortName);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const updateSingleUnitMaster = ConfigurationServices.updateMasterSectorData;
  const newRowUpdate = ConfigurationServices.postMasterSectorData;


  useEffect(()=>{
    ConfigurationServices.getMasterSectorData()
        .then((res)=>{
          setLoading(true);
          setTimeout(()=>{
            setLoading(false);
            if (res !=undefined && typeof res !== 'string') {
              const keys = [];
              res.length>0 && Object.keys(res[0]).map((obj) => {
                if (obj.split('_').includes('id')) {
                  keys.push(obj);
                }
              });

              setIdKey([...keys]);
              if (res['userMessage']) {
                setRow([]);
                setErrMsg(res['userMessage']);
              } else {
                if (res && Array.isArray(res)) {
                  setRow([...res.filter((r) => r.is_active), ...res.filter((r) => !r.is_active)]);
                } else {
                  setRow([]);
                }
              }
            } else {
              setRow([]);
              setErrMsg('Error Occured due to backend connection.');
            };
          }, 100);
        });
  }, [addingTrigger]);

  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='mastersector_all' ) {
      // row.sort((a, b) =>
      //   a[values[0]].localeCompare(b[values[0]]));
      setTableFilterData([...row]);
    } else {
      setLoading(true);

      setTimeout(()=>{
        setLoading(false);
        if (filteredUnitValue['mastersector'].length > 0) {
          setTableFilterData(row.filter((r)=>{
            return filteredUnitValue['mastersector']
                .includes(r.master_sector);
          }));
        } else if (filteredUnitValue['mastersector'].length == 0) {
          setTableFilterData([...row]);
        }
        setPage(0);
      }, 100);
    }
  }, [filteredUnitValue, row, addingTrigger]);


  useEffect(()=>{
    if (SortValue!=='' && SortValue!=='updated_on') {
      if (sorttype === 'asc') {
        tableFilterData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        tableFilterData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setTableFilterData([...tableFilterData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(tableFilterData, (item) => new Date(item.updated_on));
        setTableFilterData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(tableFilterData, (item) => new Date(item.updated_on));
        setTableFilterData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const [page, setPage] = React.useState(0);
  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Sector_Master']) {
        setAccessControl(functionalityAccess['Configuration:Sector_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>

        <MasterSectorDisplay row={_.orderBy(row, [(r) => r?.master_sector?.toLowerCase()])}/>

        {/* {loading ? <Loading /> : */}


        <Box sx={{}}>
          <CategoryTable
            data={tableFilterData}
            accessControl={accessControl}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            editValues={editValues}
            addingTrigger={addingTrigger}
            dateValues={dateValues}
            idKey={idKey}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            sortValues={sortValues}
            page={page}
            setPage={setPage}
          />

          {(errMsg !=='' || tableFilterData.length == 0) &&
        <ErrorMessage message='Master Sector' errmsg={errMsg}/>
          }
        </Box>
        {/* } */}
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg} />
      </Box>}
    </Box>
  );
};

export default MasterSector;
