import axiosInstance from '../apis/axiosInstance';


const DashboardService = {
  getStatusSteps: (wfId) => {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/workflow/getAllStatus?workFlowId=${wfId}`)
          .then((res) => {
            if (res) {
              const statusSteps = res?.data;
              // console.log(statusSteps);
              resolve(statusSteps);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  getNonPlayBookSteps: (wfId) => {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/nonPlaybook/wf_status?workflowId=${wfId}`)
          .then((res) => {
            if (res) {
              const statusSteps = res?.data;
              // console.log(statusSteps);
              resolve(statusSteps);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  getForecastTable: (secid, regid, catGid, catId, subCId, execM) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/forecastDetails?sectorId=${secid}&regionId=${regid}&commodityGroupId=${catGid}&commodityId=${catId}&subCommodityId=${subCId}&executionYear=${execM}`)
          .then((res) => {
            if (res) {
              const ForecastData = res?.data;
              resolve(ForecastData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

};


export default DashboardService;


