import React from 'react';

const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
  marginBottom: '5px',
};

export const instructions = [
  {'Instructions': ''},
  {'Instructions': '\u25CF Click on the \u0060Data\u0060 sheet to enter/edit values'},
  {'Instructions': ''},
  {'Instructions': 'For Downloaded rows:'},
  {'Instructions': '\u25CF Base currency and Plan monthcolumn will be auto populated '},
  {'Instructions': '\u25CF Enter the Conversion rate that follows the following constraints:'},
  {'Instructions': '   a. It should be a numeric value greater than 0'},
  {'Instructions': '   b. Do not enter any character value or negative value'},
  {'Instructions': ''},
  {'Instructions': 'For Adding new rows:'},
  {'Instructions': '\u25CF Refer the \u0060Currency\u0060 column in currency info sheet in downloaded template'},
  {'Instructions': '\u25CF Enter the base currency in required format'},
  {'Instructions': '\u25CF Enter the Plan Month (future/current) in the format mmm-yyyy, e.g., JUN-2025.'},
  {'Instructions': '\u25CF Do not enter multiple rows with same Base currency and Plan month'},
  {'Instructions': '\u25CF Enter the Conversion rate that follows the following constraints:'},
  {'Instructions': '   a. It should be a numeric value greater than 0'},
  {'Instructions': '   b. Do not enter any character value or any negative value'},
  {'Instructions': ''},
  {'Instructions': '\u25CF Save the template file in your system'},
  {'Instructions': '\u25CF Click on Bulk Update button to browse your saved template file'},
  {'Instructions': '\u25CF Select the template file and upload'},
  {'Instructions': '\u25CF Wait for a pop up to confirm if all rows are successfully uploaded'},

];

export const errorInstructions = [
  {'Instructions': ''},
  {'Instructions': '\u25CF Some of the rows in your file were not uploaded successfully due to errors.'},
  {'Instructions': '\u25CF The error file identifies the rows that could not be updated/added due to errors.'},
  {'Instructions': '\u25CF There is an error message column that talks about the error in each row.'},
  {'Instructions': '\u25CF Resolve the error in each row and upload the error file by clicking on bulk update button.'},
  {'Instructions': '\u25CF Click on the \u0060Data\u0060 sheet to enter/edit values'},
  {'Instructions': ''},
  {'Instructions': 'For Downloaded rows:'},
  {'Instructions': '\u25CF Base currency and Plan month column will be auto populated '},
  {'Instructions': '\u25CF Enter the Conversion rate that follows the following constraints:'},
  {'Instructions': '   a. It should be a numeric value greater than 0'},
  {'Instructions': '   b. Do not enter any character value or negative value'},
  {'Instructions': ''},
  {'Instructions': 'For Adding new rows:'},
  {'Instructions': '\u25CF Refer the \u0060Currency\u0060 column in currency info sheet in downloaded template'},
  {'Instructions': '\u25CF Enter the base currency in required format'},
  {'Instructions': '\u25CF Enter the Plan Month (future/current) in the format mmm-yyyy, e.g., JUN-2025.'},
  {'Instructions': '\u25CF Do not enter multiple rows with same Base currency and Plan month'},
  {'Instructions': '\u25CF Enter the Conversion rate that follows the following constraints:'},
  {'Instructions': '   a. It should be a numeric value greater than 0'},
  {'Instructions': '   b. Do not enter any character value or any negative value'},
  {'Instructions': ''},
  {'Instructions': '\u25CF Save the template file in your system'},
  {'Instructions': '\u25CF Click on Bulk Update button to browse your saved template file'},
  {'Instructions': '\u25CF Select the template file and upload'},
  {'Instructions': '\u25CF Wait for a pop up to confirm if all rows are successfully uploaded'},

];

export function DownloadInstructions() {
  return (
    <>
      <ul style={{marginLeft: '2px', paddingInlineStart: '20px'}}>
        <li style={dropdownSpan} >Select the filter values for Base Currency and Plan month for which bulk upload has to be done</li>
        <li style={dropdownSpan}>&apos;Select All&apos; in the filter values if you want to bulk upload all the editable rows</li>
        <li style={dropdownSpan}>Refer the &apos;Instructions&apos; sheet which will be provided in the downloaded file</li>
        <li style={dropdownSpan}>Click on the &apos;Data&apos; sheet to enter/edit values</li>
      </ul>

      <br/><b style={{marginLeft: '20px', fontSize: '15px'}}>For Downloaded rows:</b>
      <ul style={{marginLeft: '2px', paddingInlineStart: '20px'}}>
        <li style={dropdownSpan} >Base currency, Plan month and Standard Currency column will be auto populated</li>
        <li style={dropdownSpan} >Enter the Conversion rate that follows the following constraints:</li>
        <ol type='a' style={{marginLeft: '20px', paddingInlineStart: '20px'}}>
          <li style={dropdownSpan} >It should be a numeric value greater than 0</li>
          <li style={dropdownSpan} >Do not enter any character value or negative value</li>
        </ol>
      </ul>

      <br/><b style={{marginLeft: '20px', fontSize: '15px'}}>For Adding new rows:</b>
      <ul style={{marginLeft: '2px', paddingInlineStart: '20px'}}>
        <li style={dropdownSpan} >Refer the &apos;Currency&apos; column in currency info sheet in downloaded template</li>
        <li style={dropdownSpan} >Enter the base currency in required format</li>
        <li style={dropdownSpan} >Enter the Plan Month (future/current) in the format mmm-yyyy, e.g., JUN-2025.</li>
        <li style={dropdownSpan} >Enter the Standard Currency for the respective Base currency i.e., USD</li>
        <li style={dropdownSpan} >Do not enter multiple rows with same Base currency and Plan month</li>
        <li style={dropdownSpan} >Enter the Conversion rate that follows the following constraints:</li>

        <ol type='a' style={{marginLeft: '20px', paddingInlineStart: '20px'}}>
          <li style={dropdownSpan} >It should be a numeric value greater than 0</li>
          <li style={dropdownSpan} >Do not enter any character value or any negative value</li>
        </ol>
        <li style={dropdownSpan} >Save the template file in your system</li>
        <li style={dropdownSpan} >Click on Bulk Update button to browse your saved template file</li>
        <li style={dropdownSpan} >Select the template file and upload</li>
        <li style={dropdownSpan} >Wait for a pop up to confirm if all rows are successfully uploaded</li>
        <li style={dropdownSpan} >Click the download button below to begin</li>
      </ul>
    </>
  );
};

export function ErrorMessageList() {
  return (
    <ul style={{marginLeft: '2px', paddingInlineStart: '20px'}}>
      <li style={dropdownSpan}>Some of the rows in your file were not uploaded successfully due to errors. </li>
      <li style={dropdownSpan}>The error file identifies the rows that could not be updated/added due to errors. </li>
      <li style={dropdownSpan}>There is an error message column that talks about the error in each row. </li>
      <li style={dropdownSpan}>Resolve the error in each row and upload the error file by clicking on bulk update button. </li>
      <li style={dropdownSpan}>Download the error file below by clicking on the download button</li>
    </ul>
  );
}

export function SuccessMessage() {
  return (<p style={dropdownSpan}>Your action was successful.</p>);
}
