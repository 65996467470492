/* eslint-disable no-unused-vars */

import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ConfigurationServices from '../../../services/ConfigurationServices';
import theme from '../../../theme';
import Loading from '../CommonConfFiles/Loading/Loading';
import ErrorMessage from '../CommonConfFiles/ErrorMessage/ErrorMessage';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import CommodityTable from './CommonCommodity/SubCommodityTable';
import SubCommodityDisplay from './SubCommodityDisplay';
import config from '../../../Utils/Config';
import _ from 'lodash';

const SubCommodity = (props) => {
  const [row, setRow] = useState([]);
  const [ActiveCategories, setActiveCategories] = useState([]);
  const [ActiveCommodities, setActiveCommodities] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [idKey, setIdKey] = useState([]);

  const heads = ['Category', 'Commodity', 'Sub Commodity', 'Is Active', 'Last Edited By', 'Last Edited On'];

  const values = ['category', 'commodity_name',
    'subCommmodity', 'subCommmodity_is_active',
    'subCommmodity_updated_by', 'subCommmodity_updated_on',
  ];

  const dateValues = [false, false, false, false, false, false];

  const editValues = [true, false, true, false, true, false];

  const [pagination, setPagination] = useState(10);

  const [addingTrigger, setAddingTrigger] = useState(false);
  const [page, setPage] = React.useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [filterResetClicked, setFiltersResetClicked] = useState(false);
  const filteredUnitValue = useSelector((state)=>state
      .configurations.selectedSubCommodityFilterValue);

  const sortValues = [true, true, true, false, true, true];
  const SortValue = useSelector((state)=>state.configurations.selectedSortName);
  const sorttype = useSelector((state)=>state.configurations.selectedSortType);
  const updateSingleUnitMaster = ConfigurationServices.
      updateSubCommodityData;
  const newRowUpdate = ConfigurationServices.postSubCommodityData;


  useEffect(()=>{
    ConfigurationServices.getSubCommodityData()
        .then((res)=>{
          if (res !=undefined && typeof res !== 'string') {
            const keys = [];
            res.length>0 && Object.keys(res[0]).map((obj) => {
              if (obj.split('_').includes('id')) {
                keys.push(obj);
              }
            });
            setIdKey([...keys]);
            if (res['userMessage']) {
              setRow([]);
              setErrMsg(res['userMessage']);
            } else {
              const activeSubComm = [];
              const inActiveSubComm = [];

              res?.commodityGroups?.forEach((item) => {
                item.commodities?.forEach((commodity) => {
                  commodity.subCommodities?.forEach((subCommodity) => {
                    const subCommObj = {
                      'item_id': item.id,
                      'category': item.name,
                      'commodity_id': commodity.id,
                      'commodity_is_active': commodity.is_active,
                      'commodity_is_editable': commodity.is_editable,
                      'commodity_name': commodity.name,
                      'commodity_updated_on': commodity.updated_on,
                      'commodity_updated_by': commodity.updated_by,
                      'subCommmodity_row_id': subCommodity.id,
                      'subCommmodity_id': subCommodity.commodityId,
                      'subCommmodity_is_active': subCommodity.is_active,
                      'subCommmodity_is_editable': subCommodity.is_editable,
                      'subCommmodity': subCommodity.name,
                      'subCommmodity_updated_on': subCommodity.updated_on,
                      'subCommmodity_updated_by': subCommodity.updated_by,
                    };

                    if (subCommodity.is_active) {
                      activeSubComm.push(subCommObj);
                    } else {
                      inActiveSubComm.push(subCommObj);
                    }
                  });
                });
              });

              activeSubComm.sort((a, b) => {
                if (a.commodity_updated_on === b.commodity_updated_on) {
                  return new Date(b.subCommmodity_updated_on) - new Date(a.subCommmodity_updated_on);
                }
                return new Date(b.commodity_updated_on) - new Date(a.commodity_updated_on);
              });

              inActiveSubComm.sort((a, b) => {
                if (a.commodity_updated_on === b.commodity_updated_on) {
                  return new Date(b.subCommmodity_updated_on) - new Date(a.subCommmodity_updated_on);
                }
                return new Date(b.commodity_updated_on) - new Date(a.commodity_updated_on);
              });

              const row = [...activeSubComm, ...inActiveSubComm];
              setModifiedData(row);
              setRow(row);
            }
          } else {
            setRow([]);
            setErrMsg('Error Occured due to backend connection.');
          };
        });
  }, [addingTrigger]);

  useEffect(()=>{
    if (filteredUnitValue === '' || filteredUnitValue.length ===0 ||
     filteredUnitValue[0]==='category_all' ||
     filteredUnitValue[0]==='commodity_all' ||
     filteredUnitValue[0]==='subcommodity_all' ||
     (filteredUnitValue?.category.length ===0 && filteredUnitValue?.commodity.length ===0 && filteredUnitValue?.subcommodity.length === 0) ) {
      setModifiedData([...row]);
    } else {
      if (filteredUnitValue['category'].length>0 &&
        filteredUnitValue['commodity'].length>0 &&
        filteredUnitValue['subcommodity'].length>0) {
        const original =[...row];
        const dummy = original.filter( (item) => filteredUnitValue['category'].includes(item.category) && filteredUnitValue['commodity'].includes(item.commodity_name) && filteredUnitValue['subcommodity'].includes(item.subCommmodity));
        setModifiedData([...dummy]);
      } else if (filteredUnitValue['category'].length>0 && filteredUnitValue['commodity'].length>0 ) {
        const original =[...row];
        const dummy = original.filter( (item) => filteredUnitValue['category'].includes(item.category) && filteredUnitValue['commodity'].includes(item.commodity_name));
        setModifiedData([...dummy]);
      } else
      if (filteredUnitValue['category'].length>0) {
        setModifiedData(row.filter((r)=>{
          return filteredUnitValue['category'].includes(r.category);
        }));
      } else if (filteredUnitValue['commodity'].length > 0) {
        setModifiedData(row.filter((r)=>{
          return filteredUnitValue['commodity'].includes(r.commodity_name);
        }));
      } else if (filteredUnitValue['subcommodity'].length > 0) {
        setModifiedData(row.filter((r)=>{
          return filteredUnitValue['subcommodity'].includes(r.subCommmodity);
        }));
      } else {
        setModifiedData([...row]);
      }
    }
    setPage(0);
  }, [filteredUnitValue, row, addingTrigger]);

  // useEffect(()=>{
  //   if (SortValue!=='') {
  //     if (sorttype === 'asc') {
  //       modifiedData.sort((a, b) =>
  //         a[SortValue]?.localeCompare(b[SortValue]));
  //     } if (sorttype === 'desc') {
  //       modifiedData.sort((a, b) =>
  //         b[SortValue]?.localeCompare(a[SortValue]));
  //     }
  //     setModifiedData([...modifiedData]);
  //   }
  // }, [SortValue, sorttype]);
  useEffect(()=>{
    // console.log(SortValue);
    if (SortValue!=='' && SortValue!=='subCommmodity_updated_on') {
      if (sorttype === 'asc') {
        modifiedData.sort((a, b) =>
          a[SortValue]?.localeCompare(b[SortValue]));
      } if (sorttype === 'desc') {
        modifiedData.sort((a, b) =>
          b[SortValue]?.localeCompare(a[SortValue]));
      }
      setModifiedData([...modifiedData]);
    } else {
      if (sorttype === 'asc') {
        const sortedData = _.sortBy(modifiedData, (item) => new Date(item.subCommmodity_updated_on));
        setModifiedData([...sortedData]);
      } if (sorttype === 'desc') {
        const sortedDataDescending = _.reverse(modifiedData, (item) => new Date(item.subCommmodity_updated_on));
        setModifiedData([...sortedDataDescending]);
      }
    }
  }, [SortValue, sorttype]);

  useEffect(()=>{
    ConfigurationServices.getCategoryData()
        .then((res)=>{
          const activeCategories = [];
          res.map((r)=>{
            r.is_active === true && activeCategories.push(r);
          });
          setActiveCategories([...activeCategories]);
        });
  }, []);

  useEffect(()=>{
    ConfigurationServices.getCommodityData()
        .then((res)=>{
          const activeCommodities = [];
          res['commodityGroups']?.map((r)=>{
            r.commodities?.map((rs)=>{
              rs.is_active==true && !activeCommodities.includes(r) && activeCommodities.push(r);
            });
          });
          setActiveCommodities([...activeCommodities]);
        });
  }, []);
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Commodity_Master']) {
        setAccessControl(functionalityAccess['Configuration:Commodity_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>
        <SubCommodityDisplay row={_.orderBy(row, [(r) => r?.name?.toLowerCase()])} setSubmitClicked={setSubmitClicked} setFiltersResetClicked={setFiltersResetClicked}/>
        <Box sx={{}}>
          <CommodityTable
            modifiedData={modifiedData}
            setAddingTrigger={setAddingTrigger}
            heads={heads}
            values={values}
            addingTrigger={addingTrigger}
            singleUpdate={updateSingleUnitMaster}
            newRowUpdate={newRowUpdate}
            setIsErrorPopupVisible={setIsErrorPopupVisible}
            setErrMsg={setErrMsg}
            pagination={pagination}
            setPagination={setPagination}
            sortValues={sortValues}
            ActiveCategories={ActiveCategories}
            ActiveCommodities={ActiveCommodities}
            accessControl={accessControl}
            setPage={setPage}
            page={page}
            filterResetClicked={filterResetClicked}
            submitClicked={submitClicked}
          />
          {(errMsg !=='' || modifiedData.length == 0) &&
        <ErrorMessage message='Sub Commodity' errmsg={errMsg}/>
          }
        </Box>
        <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }} text={errMsg}/>
      </Box>
      }
    </Box>
  );
};

export default SubCommodity;
