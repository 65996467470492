import React from 'react';
import Grid from '@mui/material/Grid';
import {Box, TextareaAutosize} from '@mui/material';

const textbox = {
  fontSize: '12px',
  font: 'normal normal normal 13px/15px \'Segoe UI\' ',
  color: 'rgba(85, 87, 89, 1)',
  width: '150px',
  height: '40px',
  marginTop: '10px',
  overflow: 'hidden',
  paddingTop: '10px',
  paddingLeft: '10px',
};

const BuyPlanTextBox = ({heading, value, setValue, dropdownSpan}) => {
  return (
    <Grid item xs={2.4}>
      <Grid container direction="column">
        <Grid item>
          <Box sx={dropdownSpan} component="span">
            {heading}
          </Box>
        </Grid>
        <Grid item>
          <TextareaAutosize
            style={textbox}
            placeholder="Type in here…"
            value={value || ''}
            minRows={1}
            maxRows={4}
            onChange={(e) => {
              setValue(e.target.value);
            } } />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuyPlanTextBox;
