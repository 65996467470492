// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .mainComponent{
    background-color: rgb(209, 211, 213);
    display: flex;
    flex-direction: column;
    align-items: center;
} */`, "",{"version":3,"sources":["webpack://./src/Components/MainComponent/MainComponent.css"],"names":[],"mappings":"AAAA;;;;;GAKG","sourcesContent":["/* .mainComponent{\n    background-color: rgb(209, 211, 213);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
