
/* eslint-disable camelcase */
import React, {useEffect, useState, useMemo} from 'react';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import {createTheme, styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Box, Button, FormControl, InputBase, MenuItem, Select, TablePagination, TextareaAutosize} from '@mui/material';
import CommonConfSingleRow from './NonPlayBookSingleRow';
import theme from '../../../theme';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {useDispatch, useSelector} from 'react-redux';
import {getDropDownData_API} from '../../../redux/features/apiCall';
import ConfigurationServices from '../../../services/ConfigurationServices';
import config from '../../../Utils/Config';
import customealltheme from '../../../theme';
import {setSortFilter, setSortType} from '../../../redux/features/configurations';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});


function CommonConfTable({
  idKey,
  setPage,
  Currency,
  Units,
  buyplanPersona,
  ActiveSectors,
  page,
  ActiveCommodities,
  accessControl,
  businessUnit,
  ActiveCategories,
  ActiveSubCommodities,
  data,
  setErrMsg,
  setIsErrorPopupVisible,
  setAddingTrigger,
  addingTrigger,
  heads,
  values,
  sortValues,
  singleUpdate,
  newRowUpdate,
  pagination,
  setPagination,
  deleteNonPlayBookRow,
}) {
  const [AddNewRow, setNewRow] = useState(false);
  const handleAddRow = () => {
    clearNewRow();
    setNewRow(true);
  };


  const [countryValue, setCountryValue] = useState('');
  const [buDescriptionValue, setBuDescriptionValue] = useState([]);
  const [categoryValue, setCategoryValue] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [mappedRegionsList, setMappedRegionsList] = useState([]);
  const [unitValue, setUnitValue] = useState('');
  const [standardUnitValue, setStandardUnitValue] = useState('');
  const [currancyValue, setCurrancyValue] = useState('');
  const [pipelineCratorName, setPipelineCratorName] = useState('');
  const [editModeDisableTrigger, setEditModeDisableTrigger] = useState(false);
  const [referenceValue, setReferenceValue] = useState('');
  const [sectorValue, setSectorValue] = React.useState('');
  const [commodityName, setCommodityName] = useState('');
  const [commodityValue, setCommodityValue] = useState('');
  const [subCommodityValue, setSubCommodityValue] = useState('');
  const [currentStatusValue, setCurrentStatusValue] = useState('');

  const present = dayjs();
  let defaultFutureStartDate = present.add(1, 'month');
  if (present.date() !== defaultFutureStartDate.date()) {
    defaultFutureStartDate = defaultFutureStartDate.subtract(defaultFutureStartDate.date(), 'day');
  }
  let defaultFutureEndDate = defaultFutureStartDate.add(10, 'year');
  if (defaultFutureStartDate.date() !== defaultFutureEndDate.date()) {
    defaultFutureEndDate = defaultFutureEndDate.subtract(defaultFutureEndDate.date(), 'day');
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setEditModeDisableTrigger((editModeDisableTriggerValue) => !editModeDisableTriggerValue);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
    if (sectorValue) {
      const countriesList = [];
      ConfigurationServices.getSectorCountries(sectorValue)
          .then((res) => {
            res.forEach((element) => {
            element.is_present === 'Y' ? countriesList.push(element) : '';
            });
            setMappedRegionsList(countriesList);
          })
          .catch(() => {});
    }
  }, [sectorValue]);

  const saveTheRow = () => {
    const sendingData = {
      name: referenceValue,
      commodityGroupId: categoryValue,
      sectorGroupId: null,
      commodityId: commodityValue,
      sectorId: sectorValue,
      regionId: countryValue,
      subCommodityId: subCommodityValue,
      measurementUnitId: unitValue,
      stdUnitId: standardUnitValue,
      currencyId: currancyValue,
      reportingBuId: buDescriptionValue,
      creatorId: pipelineCratorName,
      isActive: currentStatusValue,
    };
    newRowUpdate(sendingData)
        .then((res) => {
          if (res && res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
            setAddingTrigger(!addingTrigger);
          } else if (res) {
            setNewRow(false);
            setAddingTrigger(!addingTrigger);
            clearNewRow();
          }
        })
        .catch(() => {
          setIsErrorPopupVisible(true);
          setErrMsg('An error occurred');
          setAddingTrigger(!addingTrigger);
        });
    // setNewRow(false);
    // setAddingTrigger(!addingTrigger);
    // clearNewRow();
  };

  const clearNewRow = () => {
    setCommodityValue('');
    setCommodityName('');
    setCountryValue('');
    setCurrentStatusValue('');
    setReferenceValue('');
    setSectorValue('');
    setSubCommodityValue('');
    setUnitValue('');
    setStandardUnitValue('');
    setCurrancyValue('');
    setPipelineCratorName('');
    setBuDescriptionValue('');
    setCategoryValue('');
    setCategoryName('');
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSortType(''));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getDropDownData_API());
  }, [dispatch]);

  const Edit = config.AccessLevel.Edit;

  const enableSaveButton =
    referenceValue !== '' &&
    sectorValue !== '' &&
    countryValue !== '' &&
    commodityValue !== '' &&
    subCommodityValue !== '' &&
    unitValue !== '' &&
    standardUnitValue !== '' &&
    currancyValue !== '' &&
    pipelineCratorName !== '' &&
    buDescriptionValue !== '' &&
    categoryValue !== '' &&
    currentStatusValue !== '';

  const saveButtonStyles = {
    border: '1px solid gray',
    display: 'flex',
    border: '0.5px solid #CECECE',
    borderRadius: '4px',
    width: '58px',
    alignItems: 'center',
    gap: '5px',
    padding: '2px 6px',
    background: '#4297d3',
    color: 'white',
    justifyContent: 'center',
    cursor: enableSaveButton ? 'pointer' : 'not-allowed',
  };
  const sorttype = useSelector((state) => state.configurations.selectedSortType);

  const getAllSectors = useMemo(() => {
    let allSectors = [];

    ActiveSectors.forEach((obj) => {
      allSectors = [...allSectors, ...obj.sectors];
    });

    return _.orderBy(allSectors, [(s) => s.name?.toLowerCase()]);
  }, [ActiveSectors]);

  const getAllCommodities = useMemo(() => {
    let allCommodities = [];
    ActiveCommodities.length !== 0 &&
    categoryName !== '' && ActiveCommodities.forEach((obj) => {
      if (obj.name === categoryName) {
        allCommodities = [...allCommodities, ...obj.commodities];
      }
    });
    return _.orderBy(allCommodities, [(s) => s.name?.toLowerCase()].filter((sb) => sb.is_active));
  }, [ActiveCommodities, ActiveCommodities.length, categoryName]);

  return (
    <>
      <>
        <Box sx={{width: '100%', padding: '0px 18px'}}>
          <Box sx={{marginTop: '20px'}}>
            {accessControl === Edit && (
              <Box>
                {' '}
                <Button onClick={handleAddRow} sx={theme.palette.AddNewTableButton}>
                  <AddIcon /> Add new row
                </Button>
              </Box>
            )}
            <TableContainer component={Paper} id="playbooktable">
              <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead id="playbookhead">
                  <TableRow>
                    {heads.map((h, index) => (
                      <StyledTableCell align="left" key={index} style={{whiteSpace: 'noWrap'}} sx={{fontSize: '12px'}}>
                        <Box style={customealltheme.palette.TableHead}>
                          <Box>{h}</Box>
                          {sortValues[index] === true && (
                            <Box
                              style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0.5'}}
                              onClick={() => {
                                dispatch(setSortFilter(values[index]));
                                if (sorttype === 'asc') {
                                  dispatch(setSortType('desc'));
                                }
                                if (sorttype === 'desc' || sorttype === '') {
                                  dispatch(setSortType('asc'));
                                }
                              }}
                              onMouseOver={(e) => {
                                e.target.style.opacity = '1';
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.opacity = '0.5';
                              }}
                            >
                              {' '}
                              <SwapVertIcon />
                              {sorttype}
                            </Box>
                          )}
                        </Box>
                      </StyledTableCell>
                    ))}
                    {accessControl === Edit && <StyledTableCell align="left">Action</StyledTableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AddNewRow === true && (
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <TextareaAutosize
                          style={theme.palette.TextArea}
                          placeholder="Type in here…"
                          value={referenceValue}
                          minRows={1}
                          maxRows={4}
                          onChange={(e) => {
                            setReferenceValue(e.target.value);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={sectorValue}
                            onChange={(e) => {
                              setSectorValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {getAllSectors?.map(
                                (r) =>
                                  r.active == true && (
                                    <MenuItem sx={customTheme.selectMenuBox} key={r?.id} value={r?.id} className="this-is-new-class">
                                      {r.name}
                                    </MenuItem>
                                  ),
                            )}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={countryValue}
                            onChange={(e) => {
                              setCountryValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {mappedRegionsList?.length !== 0 &&
                              _.orderBy(mappedRegionsList, [(s) => s.region?.toLowerCase()])?.map((r) => (
                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.region_id || ''}
                                  value={r?.region_id || ''}
                                  className="this-is-new-class"
                                >
                                  {r.region}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={buDescriptionValue}
                            onChange={(e) => {
                              setBuDescriptionValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {businessUnit.length !== 0 &&
                              _.orderBy(businessUnit, [(s) => s.business_unit?.toLowerCase()])?.map(
                                  (r) =>
                                    r.is_active == true && (
                                      <MenuItem sx={customTheme.selectMenuBox} key={r?.bu_id} value={r?.bu_id} className="this-is-new-class">
                                        {r.business_unit}
                                      </MenuItem>
                                    ),
                              )}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={categoryValue}
                            onChange={(e) => {
                              setCategoryValue(e.target.value);
                              setCommodityValue('');
                              setSubCommodityValue('');
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {ActiveCategories.length !== 0 &&
                              _.orderBy(ActiveCategories, [(s) => s.category?.toLowerCase()])?.map(
                                  (r) =>
                                    r.is_active == true && (
                                      <MenuItem sx={customTheme.selectMenuBox} key={r?.category_id} value={r?.category_id} className="this-is-new-class"
                                        onClick={() => {
                                          setCategoryName(r.category);
                                        }}
                                      >
                                        {r.category}
                                      </MenuItem>
                                    ),
                              )}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={commodityValue}
                            onChange={(e) => {
                              setCommodityValue(e.target.value);
                              setSubCommodityValue('');
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {getAllCommodities?.map(
                                (obj) =>
                                  obj.is_active === true && (
                                    <MenuItem
                                      sx={customTheme.selectMenuBox}
                                      key={obj?.id}
                                      value={obj?.id}
                                      className="this-is-new-class"
                                      onClick={() => {
                                        setCommodityName(obj.name);
                                      }}
                                    >
                                      {obj.name}
                                    </MenuItem>
                                  ),
                            )}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={subCommodityValue}
                            onChange={(e) => {
                              setSubCommodityValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {ActiveSubCommodities.length !== 0 &&
                              commodityName !== '' &&
                              ActiveSubCommodities?.map(
                                  (r) =>
                                    r.name === commodityName &&
                                  _.orderBy(r.subCommodities, [(a) => a.name?.toLowerCase()])?.filter((sb) => sb.is_active)?.map((obj) => (
                                    <MenuItem sx={customTheme.selectMenuBox} key={obj?.id} value={obj?.id} className="this-is-new-class">
                                      {obj.name}
                                    </MenuItem>
                                  )),
                              )}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={unitValue}
                            onChange={(e) => {
                              setUnitValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Units.length !== 0 &&
                              _.orderBy(Units, [(u) => u?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                                <MenuItem sx={customTheme.selectMenuBox} key={r?.unit_id} value={r?.unit_id} className="this-is-new-class">
                                  {r.unit_description}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={standardUnitValue}
                            onChange={(e) => {
                              setStandardUnitValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Units.length !== 0 &&
                              _.orderBy(Units, [(u) => u?.unit_description?.toLowerCase()?.trim()])?.map((r) => (
                                <MenuItem sx={customTheme.selectMenuBox} key={r?.unit_id} value={r?.unit_id} className="this-is-new-class">
                                  {r.unit_description}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={currancyValue}
                            onChange={(e) => {
                              setCurrancyValue(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {Currency.length !== 0 &&
                              _.orderBy(Currency, [(curr) => curr?.currency?.toLowerCase()?.trim()])?.map((r) => (
                                <MenuItem sx={customTheme.selectMenuBox} key={r?.currency_id} value={r?.currency_id} className="this-is-new-class">
                                  {r.currency.toUpperCase()}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={pipelineCratorName}
                            onChange={(e) => {
                              setPipelineCratorName(e.target.value);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>
                            {buyplanPersona?.buyplan_creator?.length !== 0 &&
                              _.orderBy(buyplanPersona?.buyplan_creator, [(curr) => curr?.buyplan_creator?.toLowerCase()?.trim()])?.map((r) => (
                                <MenuItem sx={customTheme.selectMenuBox} key={r?.userId} value={r?.userId} className="this-is-new-class">
                                  {r.userName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControl style={{width: '100px'}}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            sx={customTheme.selectBox}
                            value={currentStatusValue}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setCurrentStatusValue(selectedValue);
                            }}
                            label="Select"
                            input={<CustomInput label="Tag" />}
                            displayEmpty
                            className="newclass"
                          >
                            <MenuItem value="" sx={customTheme.selectMenuBox}>
                              <span>Select</span>
                            </MenuItem>

                            {[
                              {label: 'Active', value: true},
                              {label: 'InActive', value: false},
                            ]?.map((obj, index) => (
                              <MenuItem sx={customTheme.selectMenuBox} key={index} value={obj.value} className="this-is-new-class">
                                {obj.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row"></StyledTableCell>
                      <StyledTableCell component="th" scope="row"></StyledTableCell>

                      <StyledTableCell align="left">
                        <Box sx={{display: 'flex', gap: '5px'}}>
                          <Box
                            sx={enableSaveButton ? saveButtonStyles : {...saveButtonStyles, opacity: 0.5}}
                            onClick={enableSaveButton ? saveTheRow : undefined}
                          >
                            <Box>Save</Box>
                          </Box>
                          <Box
                            sx={{
                              border: '1px solid gray',
                              display: 'flex',
                              border: '0.5px solid #CECECE',
                              borderRadius: '4px',
                              width: '58px',
                              alignItems: 'center',
                              gap: '5px',
                              padding: '2px 6px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              clearNewRow();
                              setNewRow(false);
                            }}
                          >
                            <Box>Delete</Box>
                          </Box>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  {data?.slice(page * pagination, page * pagination + pagination)?.map((row, key) => (
                    <CommonConfSingleRow
                      row={row}
                      ActiveCategories={ActiveCategories}
                      ActiveCommodities={ActiveCommodities}
                      ActiveSubCommodities={ActiveSubCommodities}
                      key={key}
                      setAddingTrigger={setAddingTrigger}
                      addingTrigger={addingTrigger}
                      values={values}
                      idKey={idKey}
                      singleUpdate={singleUpdate}
                      setErrMsg={setErrMsg}
                      Units={Units}
                      buyplanPersona={buyplanPersona}
                      accessControl={accessControl}
                      setIsErrorPopupVisible={setIsErrorPopupVisible}
                      editModeDisableTrigger={editModeDisableTrigger}
                      page={page}
                      deleteNonPlayBookRow={deleteNonPlayBookRow}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {data.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[2, 5, 10, 15]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={pagination}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Box>
        </Box>
      </>
    </>
  );
}

export default CommonConfTable;
