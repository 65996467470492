import React from 'react';
import {Box} from '@mui/material';
import ticketReference from '../../../assets/ServiceNowTicket.jpg';

const HelpContent = () => {
  const ENV = process.env;
  return (
    <Box
      sx={{
        bgcolor: '#ffffff',
        borderRadius: '5px',
        padding: '18px',
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>
        <p style={{color: '#000000', font: '600 18px / 26px "Segoe UI"', paddingBottom: '20px'}}>If you have any questions or need technical assistance, please create a ticket in myServices by following below instructions:</p>
        <p style={{font: 'normal normal bold 16px / 28px Segoe UI',
          color: '#0F5DAA'}}>Steps:</p>
        <div style={{color: '#3b4655', font: '600 14px / 26px "Segoe UI"'}}>
          <p> 1. Click here - <a target="_blank" href={ENV.REACT_APP_SERVICE_URL} rel="noreferrer">{ENV.REACT_APP_SERVICE_URL}</a>  </p>
          <p> 2. Click on &quot;Request Something&quot; Icon </p>
          <p> 3. Click on &quot;General Business Applications Request&quot; Icon</p>
          <p> 4. Please Provide Description </p>
          <p> 5. Service Offering - Please select &quot;Commodity Management SO&quot;</p>
          <p> 6. Assignment Group - Please select &quot;Compass Support&quot;.</p>
          <p> 7. Add Attachment (Optional) </p>
          <p> 8. Click on &quot;Order Now&quot; button (Top right)</p>
          <p> 9. Ticket will be created and assigned to Compass Support group.</p>
        </div>
        <img src={ticketReference} style={{marginTop: '20px', width: '100%'}}/>
      </div>
    </Box>
  );
};

export default HelpContent;
