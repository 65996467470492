// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .header{
    background-color: rgb(38, 90, 167);
    display: flex;
    padding: 15px;
    color: white;
}
.leftHeaderBox{
    display: flex;
    width: 50%;
    align-items: center;
}
.firstLeftSubHeader{
    margin-right: 15px;
    font-weight: 700;
}
.secondLeftSubHeader{
    margin-left: 15px;
} */
/* .rightHeaderBox{
    display: flex;
    width: 50%;
    align-items: center;
}
.firstRightSubHeader{
    width: 50%;
}
.secondRightSubHeader{
    display: flex;
    justify-content: flex-end;
    width: 50%;
    align-items: center;
}
.notificationBox{
    margin-right: 10px;
}
.profileBox{
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}
#basic-button{
    color: white;
} */`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;GAiBG;AACH;;;;;;;;;;;;;;;;;;;;;;;;;GAyBG","sourcesContent":["/* .header{\n    background-color: rgb(38, 90, 167);\n    display: flex;\n    padding: 15px;\n    color: white;\n}\n.leftHeaderBox{\n    display: flex;\n    width: 50%;\n    align-items: center;\n}\n.firstLeftSubHeader{\n    margin-right: 15px;\n    font-weight: 700;\n}\n.secondLeftSubHeader{\n    margin-left: 15px;\n} */\n/* .rightHeaderBox{\n    display: flex;\n    width: 50%;\n    align-items: center;\n}\n.firstRightSubHeader{\n    width: 50%;\n}\n.secondRightSubHeader{\n    display: flex;\n    justify-content: flex-end;\n    width: 50%;\n    align-items: center;\n}\n.notificationBox{\n    margin-right: 10px;\n}\n.profileBox{\n    display: flex;\n    align-items: center;\n    margin-right: 10px;\n    cursor: pointer;\n}\n#basic-button{\n    color: white;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
