/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// DO NOT USE THIS FILE
import React from 'react';
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn, intColumn} from 'react-datasheet-grid';
// DataSheetGridPropsBase

// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../services/dialogService';
import {months, titleCase} from '../../services/utils';
// import {AppDialog} from '../common/App-Dialog/AppDialog';
import {AppTableInput} from '../common/AppTableInput/AppTableInput';
import {CopyIcon} from '../common/Icons/icons';
import './forecast.css';
import {AppButton} from '../common/AppButton/AppButton';
// import {defaultObj} from './consts';
import {forecastDefaultData, fyCalculateTotal, getSortedMonthlyData, monthNYearSetter, rowsSetter} from './utils';
import VarianceModal from './dialog';
import {updateStatusOfBuyPlan} from '../../redux/features/apiCall';


const ForecastTable2 = ({
  subMissionData,
  setSubmissionData,
  years,
  setTabsEnableCheck,
  data,
  setData,
  setModifiedData,
  selectedYear,
  modifiedData,
  selectedRow,
  data1, data2, data3, setdata1, setdata2, setdata3,
}) => {
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const curMonth = new Date().getMonth() - 2;
  // eslint-disable-next-line no-unused-vars
  const [forecastData, setForecastData] = useState([]);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const [columns, setColumns] = useState([]);
  const [workflowIdsObj, setWorkflowIdsObj] = useState({});
  const [accessControl, setAccessControl]=useState('');
  const dispatch = useDispatch();

  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Forecast_Update']) {
      setAccessControl(functionalityAccess['Buy_Plan:Forecast_Update']);
    }
  }, [functionalityAccess]);

  const [firstLoad, setFirstLoad] = useState(false);
  const [skipper, setSkipper] = useState(null);
  const [validationArr, setValidationArr] = useState([]);
  const [isValidate, setIsValidate] = useState(null);
  const [newArr, setNewArr] = useState([]);
  const [validationCallCheck, setValidationCallCheck] = useState(false);

  const [isDataFatched, setIsDataFatched]=useState(false);


  const columnsSetter = () => {
    const columns = [];
    months.forEach((mon) => {
      columns.push({
        ...keyColumn(mon, intColumn),
        component: ({rowData}) => (
          <AppTableInput
            data={data}
            subMissionData={subMissionData}
            setSubmissionData={setSubmissionData}
            setData={setData}
            isDisabled={isDisabled(rowData, mon)}
            columnName={mon}
            forecastVal={[selectedYear, 1]}
            rowData={rowData}
          />
        ),
        title: titleCase(mon),
        disabled: ({rowData}) =>
        accessControl === 'Edit' ?
          // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
          buttonStatus==='edit' ?
            rowData?.key == 1 ?
              true :
              !(selectedYear > selectedRow.executionMonthYear[0]) ?
              months[curMonth] == mon ?
                true :
                false :
              false :
            true:
        true,

      });
    });
    return columns;
  };
  useEffect(() => {
    // console.log('@fsct selectedYear, modifiedData, accessControl, isDataFatched', selectedYear, modifiedData, accessControl, isDataFatched);

    if (selectedYear && selectedYear != 0 && accessControl && accessControl!=='' ) {
      setColumns([]);
      let arr = fyCalculateTotal(modifiedData[selectedYear]);
      if (arr.length == 1) {
        const copy = JSON.parse(JSON.stringify(workflowIdsObj));
        arr = getSortedMonthlyData(arr, selectedRow, copy);
      }

      setData(arr);
      // console.log(arr);
      if (selectedYear == years[0]) {
        setdata1(arr);
      }
      if (selectedYear == years[1]) {
        setdata2(arr);
      }
      if (selectedYear == years[2]) {
        setdata3(arr);
      }
      setTimeout(() => {
        const cols = columnsSetter();
        // set columns value as per condition
        if (!(buttonStatus==='edit')) {
          setColumns((pre) => [
            {
              ...keyColumn('planMonth', textColumn),
              title: 'Plan month',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            {
              ...keyColumn('planYear', textColumn),
              title: 'Plan year',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            ...cols,
            {...keyColumn('fy', intColumn), title: `FY ${selectedYear} Total`, disabled: true},
          ]);
        } else {
          setColumns([
            {
              ...keyColumn('planMonth', textColumn),
              title: 'Plan month',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            {
              ...keyColumn('planYear', textColumn),
              title: 'Plan year',
              disabled: ({rowData}) => (rowData?.key == 1 ? true : false),
            },
            {
              ...keyColumn('jan', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'jan')}
                  columnName="jan"
                  forecastVal={[selectedYear, 1]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Jan',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'jan' ?
                      true :
                      false :
                    false :
                  true:
                  true,
            },
            {
              ...keyColumn('feb', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'feb')}
                  columnName="feb"
                  forecastVal={[selectedYear, 2]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Feb',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'feb' ?
                      true :
                      false :
                    false :
                  true:
                  true,
            },
            {
              ...keyColumn('mar', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'mar')}
                  columnName="mar"
                  forecastVal={[selectedYear, 3]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Mar',
              disabled: ({rowData}) =>
                accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'mar' ?
                      true :
                      false :
                    false :
                  true:
                  true,
            },
            {
              ...keyColumn('apr', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'apr')}
                  columnName="apr"
                  forecastVal={[selectedYear, 4]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Apr',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'apr' ?
                      true :
                      false :
                    false :
                  true:
                  true,
            },
            {
              ...keyColumn('may', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'may')}
                  columnName="may"
                  forecastVal={[selectedYear, 5]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'May',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'may' ?
                      true :
                      false :
                    false :
                  true:
              true,
            },
            {
              ...keyColumn('jun', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'jun')}
                  columnName="jun"
                  forecastVal={[selectedYear, 6]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Jun',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'jun' ?
                      true :
                      false :
                    false :
                  true:
              true,
            },
            {
              ...keyColumn('jul', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'jul')}
                  columnName="jul"
                  forecastVal={[selectedYear, 7]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Jul',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'jul' ?
                      true :
                      false :
                    false :
                  true:
              true,
            },
            {
              ...keyColumn('aug', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'aug')}
                  columnName="aug"
                  forecastVal={[selectedYear, 8]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Aug',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'aug' ?
                      true :
                      false :
                    false :
                  true:
              true,
            },
            {
              ...keyColumn('sep', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'sep')}
                  columnName="sep"
                  forecastVal={[selectedYear, 9]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Sep',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'sep' ?
                      true :
                      false :
                    false :
                  true:
                  true,
            },
            {
              ...keyColumn('oct', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'oct')}
                  columnName="oct"
                  forecastVal={[selectedYear, 10]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Oct',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'oct' ?
                      true :
                      false :
                    false :
                  true:
              true,
            },
            {
              ...keyColumn('nov', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'nov')}
                  columnName="nov"
                  forecastVal={[selectedYear, 11]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Nov',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'nov' ?
                      true :
                      false :
                    false :
                  true:
              true,
            },
            {
              ...keyColumn('dec', intColumn),
              component: ({rowData, focus}) => (
                <AppTableInput
                  data={data}
                  subMissionData={subMissionData}
                  setSubmissionData={setSubmissionData}
                  setData={setData}
                  isDisabled={isDisabled(rowData, 'dec')}
                  columnName="dec"
                  forecastVal={[selectedYear, 12]}
                  rowData={rowData}
                  focus={focus}
                />
              ),
              title: 'Dec',
              disabled: ({rowData}) =>
              accessControl === 'Edit' ?
                // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
                buttonStatus==='edit' ?
                  rowData?.key == 1 ?
                    true :
                    !(selectedYear > selectedRow.executionMonthYear[0]) ?
                    months[curMonth] == 'dec' ?
                      true :
                      false :
                    false :
                  true:
              true,
            },
            {...keyColumn('fy', intColumn), title: `FY ${selectedYear} Total`, disabled: true},
            {
              component: ({rowData}) =>
                rowData?.key == 1 ? (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      copyRow(rowData);
                    }}
                  >
                    <CopyIcon />
                  </span>
                ) : (
                  ''
                ),
              title: '',
            },
          ]);
        }
      }, 100);
    }
  }, [selectedYear, accessControl, isDataFatched]); // modifiedData


  useEffect(() => {
    if (validationArr.length) {
      setIsValidate(true);
    } else {
      setIsValidate(false);
    }
    setTimeout(() => {
      if (validationArr.length == 0 && newArr.length && isValidate != null ) {
        postCall(true);
      }
    });
  }, [validationArr]);

  useEffect(() => {
    if (selectedRow && (selectedRow?.status != 'NEW' && selectedRow?.status != 'DRAFT')) {
      setTabsEnableCheck((prev) => {
        return {...prev, buyPlan: true};
      });
    }
    if (selectedRow?.prevWorkFlowId) getCall();
  }, [selectedRow]);


  const getCall = () => {
    DialogService.getForecastData(selectedRow.workFlowId, selectedRow.prevWorkFlowId)
        .then((res) => {
          let obj = {};
          if (res.length) {
            setForecastData(res);

            res.forEach((val) => {
              const split = val.forecastYearMonth;
              if (+split[0] >= +new Date().getFullYear()) {
                if (!workflowIdsObj[val.workflow_id]) {
                  workflowIdsObj[val.workflow_id] = val;
                  setWorkflowIdsObj({...workflowIdsObj});
                }
                // let key = val.executionMonthYear[0] + ',' + months[val.executionMonthYear[1]-1]
                obj = {...monthNYearSetter(obj, val)};
              }
            });
            obj = {...rowsSetter(obj, workflowIdsObj, selectedRow)};
            // setModifiedData(obj);
          } else {
          // when there is no data for a all three years
            obj = forecastDefaultData(selectedRow);
            // setModifiedData(obj);
          }

          setIsDataFatched(true);
          setModifiedData(obj);
        })
        .catch((err) => {
          // console.log(err);
        });
  };

  const validationCheck = () => {
    const validationArr = [];
    Object.keys(modifiedData).forEach((y) => {
      let quarter1 = 0;
      let quarter2 = 0;
      let quarter = 0;
      months.forEach((m, i) => {
        quarter1 = quarter1 + (modifiedData[y][selectedRow.prevWorkFlowId] && +modifiedData[y][selectedRow.prevWorkFlowId][m] || 0);
        quarter2 = quarter2 + ((modifiedData[y][selectedRow.workFlowId] && +modifiedData[y][selectedRow.workFlowId][m]) || 0);
        if ((i+1) % 3 == 0) {
          const difference = Math.abs(quarter1 - quarter2);
          quarter += 1;
          if (difference != 0) {
            const variance = quarter1 == 0 ? 100 : (difference/quarter1) * 100;
            if (variance > 5) {
              const obj = {
                year: y,
                month: i,
                quarter,
                variance: variance,
              };
              validationArr.push(obj);
            }
          }
          quarter1 = 0;
          quarter2 = 0;
        }
      });
    });
    setValidationCallCheck(true);
    setValidationArr(validationArr);
    postCall();
  };


  const updateStatus = () => {
    DialogService.updateStatus(selectedRow.workFlowId, 'DRAFT')
        .then((res) => {
          if (res.status == 200 || res===true) {
            dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT'));
            setIsSavePopupVisible(true);
            setTabsEnableCheck((prev) => {
              return {...prev, buyPlan: true};
            });
            setValidationCallCheck(false);
          }
        });
  };


  const isDisabled = (rowData, month) => {
    const flag = months.indexOf(month);
    const is =
    accessControl === 'Edit' ?
      // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
      buttonStatus==='edit' ?
        rowData?.key == 1 || (selectedYear == selectedRow.executionMonthYear[0] && curMonth >= flag) ?
          true :
          false :
        true :
    true;
    return is;
  };

  useEffect(() => {
    setIsSavePopupVisible(false);
    if (skipper===false) pasteCall();
  }, [data]);

  const pasteCall = () => {
    let fyTotal = 0;
    if (firstLoad) {
      Object.keys(data[1]).forEach((val, i) => {
        if (months.includes(val)) {
          fyTotal += +data[1][val];
          const selectedMonth = months.indexOf(val);
          const payloadData = {
            'workflowId': data[1].workflow_id,
            'forecastYearMonth': [+selectedYear, selectedMonth+1],
            'forecastValue': data[1][val],
          };
          if (!subMissionData[val + selectedYear]) subMissionData[val + selectedYear] = payloadData;
          else subMissionData[val + selectedYear] = payloadData;
        }
      });
      setSubmissionData({...subMissionData});
      data[1] = {...data[1], fy: fyTotal};
      // console.log('@updateData 1');
      setData([...data]);
    }
    if (!firstLoad) {
      setFirstLoad(true);
    }
    setSkipper(true);
    setTimeout(() => {
      setSkipper(false);
    }, 1000);
  };

  useEffect(() => {
    setIsSavePopupVisible(false);
  }, [modifiedData]);

  const handleModalSubmit = (val) => {
    const selectedVal = validationArr.shift();
    // selectedVal['comment'] = val;
    newArr.push(selectedVal);
    setNewArr([...newArr]);
    setValidationArr([...validationArr]);
  };


  const postCall = (isTrue= false) => {
    if (!isTrue && isValidate) {
      return false;
    }
    const objectAll = [];
    data1.map((d1, index)=>{
      Object.keys(d1).map((obj)=>{
        if (months.includes(obj)) {
          // console.log(index);
          if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [new Date().getFullYear(), months.indexOf(obj)+1],
              forecastValue: +d1[obj],
            };
            objectAll.push(dummyObj);
          }
        }
      });
    },
    );
    data2.map((d1, index)=>{
      Object.keys(d1).map((obj)=>{
        if (months.includes(obj)) {
          if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [new Date().getFullYear()+1, months.indexOf(obj)+1],
              forecastValue: +d1[obj],
            };
            objectAll.push(dummyObj);
          }
        }
      });
    },
    );
    data3.map((d1, index)=>{
      Object.keys(d1).map((obj)=>{
        if (months.includes(obj)) {
          if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              forecastYearMonth: [new Date().getFullYear()+2, months.indexOf(obj)+1],
              forecastValue: +d1[obj],
            };
            objectAll.push(dummyObj);
          }
        }
      });
    },
    );
    // console.log(objectAll);
    const payload = Object.values(subMissionData).map((val) => val);
    // console.log(payload);
    DialogService.saveForecastData(objectAll).then((res) => {
      if (res == true && selectedRow.status === 'NEW') {
        updateStatus();
      } else {
        setIsSavePopupVisible(true);
        setTabsEnableCheck((prev) => {
          return {...prev, buyPlan: true};
        });
        setValidationCallCheck(false);
      }
    });
  };

  const [dataTrigger, setDataTrigger] = useState(false);
  const [row, setRow] = useState({});
  useEffect(()=>{
    if (Object.keys(row).length > 1) {
      const forecastYearMonth = selectedYear === years[0]? data1 : (selectedYear === years[1]? data2 : data3);
      forecastYearMonth[1] = {...row, planMonth: forecastYearMonth[1].planMonth, workflow_id: forecastYearMonth[1].workflow_id, month: forecastYearMonth[1].month, key: forecastYearMonth[1].key};
      selectedYear === years[0]? setdata1([...forecastYearMonth]) : (selectedYear === years[1]? setdata2([...forecastYearMonth]) : setdata3([...forecastYearMonth]));
    }
  }, [dataTrigger]);

  const copyRow = (row) => {
    setRow(row);
    setDataTrigger(!dataTrigger);
  };

  return (
    <>
      {((columns?.length && data?.length) || '') && (
        <div className="forecast-table">
          {selectedYear == years[0] ? (
            <DataSheetGrid
              value={data1}
              columns={columns}
              onChange={(newData)=>{
                // console.log(newData);
                setdata1(newData);
              }}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
              // style={{'--dsg-selection-border-color': 'transparent'}}
            />
          ) : (
            ''
          )}

          {selectedYear == years[1] ? (
            <DataSheetGrid
              value={data2}
              columns={columns}
              onChange={(newData)=>{
                setdata2(newData);
              }}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
              // style={{'--dsg-selection-border-color': 'transparent'}}
            />
          ) : (
            ''
          )}

          {selectedYear == years[2] ? (
            <DataSheetGrid
              value={data3}
              columns={columns}
              onChange={(newData)=>{
                setdata3(newData);
              }}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
              // style={{'--dsg-selection-border-color': 'transparent'}}
            />
          ) : (
            ''
          )}

          {/* {accessControl === 'Edit' && (selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT') ? ( */}
          {accessControl === 'Edit' && buttonStatus==='edit' ? (
            <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
              <AppButton
                onClick={() => {
                  if (!validationCallCheck) {
                    validationCheck();
                  }
                  // if (!validationArr.length && validationCallCheck) postCall();
                }}
                label={isSavePopupVisible ? 'Saved' : 'Save'}
              />
            </Box>
          ) : (
            ''
          )}
        </div>
      )}
      {/* <AppDialog
        open={isSavePopupVisible}
        handleClose={() => setIsSavePopupVisible(false)}
        text="Forecast details saved successfully!"
        type={1}
        setNo="1"
        setYes="1"
      /> */}
      {/* <AppDialog
        open={isSavePopupVisible}
        handleClose={() => setIsSavePopupVisible(false)}
        text="Forecast details saved successfully!"
        type={1}
        setNo="1"
        setYes="1"
      /> */}
      {<VarianceModal isOpen={isValidate} handleClose={setIsValidate} validationArr={validationArr} handleSubmit={handleModalSubmit} />}
    </>
  );
};

export default ForecastTable2;
