/* eslint-disable */

import axiosInstance from '../apis/axiosInstance';

const DialogService = {
    getForecastData : (wfId, preWFId,sector) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/workflow/forecasts?workFlowId=${wfId}&prevWorkFlowId=${preWFId}&sector=${sector}`)
            .then((res) => {
                if(res) {
                    const forecastData = res?.data;
                    resolve(forecastData)
                }
            })
            .catch((err) => reject(err));
    
        }) 
    },
    getNonPlayBookForecastData : (wfId, preWFId,sector) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/nonPlaybook/forecasts?workflowId=${wfId}`)
            .then((res) => {
                if(res) {
                    const forecastData = res?.data;
                    resolve(forecastData)
                }
            })
            .catch((err) => reject(err));
    
        }) 
    },
    getHistoricalId : (exe, buyplanId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/workflow/historical/id?buyPlanId=${buyplanId}&exeMonthYear=${exe}`)
            .then((res) => {
                if(res) {
                    const forecastData = res?.data;
                    // console.log(forecastData);
                    resolve(forecastData)
                }
            })
            .catch((err) => reject(err));
    
        }) 
    },
    postLink : (current, prevWfid) => {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/workflow/link?currWorkFlowId=${current}&prevWorkFlowId=${prevWfid}`)
            .then((res) => {
                // console.log(res);
                if(res) {
                    resolve(res?.data);
                }
            })
            .catch((err) => reject(err));
    
        }) 
    },
    getCoverageData : (wfId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/workflow/coverages?workFlowId=${wfId}`)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => reject(err));
    
        }) 
    },

    getNonPlayBookCoverageData : (wfId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/nonPlaybook/coverages?workflowId=${wfId}`)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => reject(err));
    
        }) 
    },

    getHistoricalSectorCoverageData : (wfId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/workflow/getTotalCoveragesMapping?workFlowId=${wfId}`)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => reject(err));
    
        }) 
    },

    saveHistoricalSectorCoverageData : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/totalCoverages/save`, obj)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            });
        }) 
    },

    getHistoricalCoverageData : (wfId) => {
        return new Promise((resolve) => {
            axiosInstance.get(`/workflow/historicalTotalCoverages?workFlowId=${wfId}`)
            .then((res) => {
                if(res) {
                    const coverageSummaryData = res?.data;
                    resolve(coverageSummaryData)
                }
            })
            .catch((err) => resolve(err));
    
        }) 
    },

    saveHistoricalCoverage : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/historicalCoverages/save`, obj)
            .then((res) => {
                if(res) {
                    const forecastData = res?.data;
                    resolve(forecastData)
                }
            })
            .catch((err) => reject(err));
        }) 
    },

    getCoverageSummaryData : (wfId) => {
        return new Promise((resolve) => {
            axiosInstance.get(`/workflow/getTotalAndPlannedCoverage?workFlowId=${wfId}`)
            .then((res) => {
                if(res) {
                    const coverageSummaryData = res?.data;
                    resolve(coverageSummaryData)
                }
            })
            .catch((err) => resolve(err));
    
        }) 
    },
    getHistoricalCoverages : (wfId) => {
        return new Promise((resolve) => {
            axiosInstance.get(`/workflow/getTotalCoveragesMapping?workFlowId=${wfId}`)
            .then((res) => {
                if(res) {
                    const coverageSummaryData = res?.data;
                    resolve(coverageSummaryData)
                }
            })
            .catch((err) => resolve(err));
    
        }) 
    },
    saveForecastData : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/forecasts/save`, obj)
            .then((res) => {
                if(res) {
                    const forecastData = res?.data;
                    resolve(forecastData)
                }
                else{
                    reject()
                }
            })
            .catch((err) => reject(err));
        }) 
    },
    saveNonPlayBookForecastData : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/nonPlaybook/forecasts`, obj)
            .then((res) => {
                if(res) {
                    const forecastData = res?.data;
                    resolve(forecastData)
                }
                else{
                    reject()
                }
            })
            .catch((err) => reject(err));
        }) 
    },
    savePastMonthWillCoverData : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`workflow/buyPlans/savePreviousMonthBuyplan`, obj)
            .then((res) => {
                if(res) {
                    const forecastData = res?.data;
                    resolve(forecastData)
                }
                else{
                    reject()
                }
            })
            .catch((err) => reject(err));
        }) 
    },
    getBuyPlanDetails : (url) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/workflow/getBuyPlanDetails?${url}`)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => reject(err));
        }) 
    },
    saveCoverageData : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/coverages/save`, obj)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            });
        }) 
    },

    saveNonPlayBookCoverageData : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/nonPlaybook/coverages`, obj)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            });
        }) 
    },
    closeBuyPlan : (wfId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/workflow/updateStatus?workFlowId=${wfId}&workFlowStatus=CLOSED`)
            .then((res) => {
                if(res) {
                    const isSuccess = res?.data;
                    resolve(isSuccess)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            });
        }) 
    },
    saveBuyPlan : (obj) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/buyPlans/save`, obj)
            .then((res) => {
                if(res) {
                    const coverageData = res?.data;
                    resolve(coverageData)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            });
        }) 
    },
    gettingProgramName: (buyplanId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/atlasfx/atlasConfigDetails?buyPlanId=${buyplanId}`)
            .then((res) => {
                if(res) {
                    const fxData = res?.data;
                    resolve(fxData)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            })
        })
    },
    atlasFxCall: (wfId, prevWorkflowID) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/atlasfx/fxwrite?workFlowId=${wfId}&prevWorkFlowId=${prevWorkflowID}`)
            .then((res) => {
                if(res) {
                    const fxData = res?.data;
                    resolve(fxData)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            })
        })
    },
    submitBuyPlan: (wfId, workFlowStatus) => {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/workflow/updateStatus?workFlowId=${wfId}&workFlowStatus=${workFlowStatus}`)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    resolve(output)
                }
            })
            .catch((err) => {
                resolve(err?.response?.data);
            })
        })
    },
    updateStatus: (wfId, workFlowStatus) => {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/workflow/updateStatus?workFlowId=${wfId}&workFlowStatus=${workFlowStatus}`)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    // console.log(output);
                    resolve(output)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            })
        })
    },
    updateNonPlayBookStatus: (wfId, workFlowStatus) => {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/nonPlaybook/workflowStatus?id=${wfId}&status=${workFlowStatus}`)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    // console.log(output);
                    resolve(output)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            })
        })
    },
    getComments: (wfId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/workflow/comments?workflow_id=${wfId}`)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    // console.log(output);
                    resolve(output)
                }
            })
            .catch((err) => {
                resolve(err?.response?.data);
            })
        })
    },
    saveComment: (payload) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/addComments`, payload)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    resolve(output)
                }
            })
            .catch((err) => {
                resolve(err?.response?.data);
            })
        })
    },
    editComment: (payload) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/editComments`, payload)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    resolve(output)
                }
            })
            .catch((err) => {
                resolve(err?.response?.data);
            })
        })
    },
    getCoverageComments: (wfId) => {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`/workflow/coveragesComments?workflow_id=${wfId}`)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    // console.log(output);
                    resolve(output)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            })
        })
    },
    saveCoverageComment: (payload) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`/workflow/coveragesComments`, payload)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    resolve(output)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            })
        })
    },
    editCoverageComment: (payload) => {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`/workflow/coveragesComments`, payload)
            .then((res) => {
                if(res) {
                    const output = res?.data;
                    resolve(output)
                }
            })
            .catch((err) => {
                reject(err?.response?.data);
            })
        })
    },
}

export default DialogService;