// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .summaryMetrics {
    border-radius: 5px;
    width: 97%;
    margin-top: 20px;
    background-color: white;
    padding: 18px;
  }
  .summaryMetricsSpan {
    font-weight: 600;
    color: rgb(96, 96, 96);
  }
  .summaryFourDiv {
    display: flex;
    padding-top: 10px;
  }
  .summaryMetricsFourDiv {
    margin-right: 80px;
    display: flex;
  }
  .borderSummaryMetricsFourDiv {
    border: 2px solid rgb(35, 93, 180);
    height: 45px;
  }
  .dataSummaryMetricsFourDiv {
    margin-left: 15px;
  }
  .numberSummaryMetricsFourDiv {
    color: rgb(35, 93, 180);
    font-size: 25px;
    font-weight: 600;
  }
  .textSummaryMetricsFourDiv {
    color: rgb(96, 96, 96);
    font-size: small;
    font-weight: 600;
  } */
  `, "",{"version":3,"sources":["webpack://./src/Components/MainComponent/SummaryMetrics/SummaryMetrics.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KAmCK","sourcesContent":["/* .summaryMetrics {\n    border-radius: 5px;\n    width: 97%;\n    margin-top: 20px;\n    background-color: white;\n    padding: 18px;\n  }\n  .summaryMetricsSpan {\n    font-weight: 600;\n    color: rgb(96, 96, 96);\n  }\n  .summaryFourDiv {\n    display: flex;\n    padding-top: 10px;\n  }\n  .summaryMetricsFourDiv {\n    margin-right: 80px;\n    display: flex;\n  }\n  .borderSummaryMetricsFourDiv {\n    border: 2px solid rgb(35, 93, 180);\n    height: 45px;\n  }\n  .dataSummaryMetricsFourDiv {\n    margin-left: 15px;\n  }\n  .numberSummaryMetricsFourDiv {\n    color: rgb(35, 93, 180);\n    font-size: 25px;\n    font-weight: 600;\n  }\n  .textSummaryMetricsFourDiv {\n    color: rgb(96, 96, 96);\n    font-size: small;\n    font-weight: 600;\n  } */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
