/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn, floatColumn, checkboxColumn} from 'react-datasheet-grid';
// DataSheetGridPropsBase

// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../services/dialogService';
import {monthArr, months, roundToTwo, titleCase} from '../../services/utils';
// import {AppDialog} from '../common/App-Dialog/AppDialog';
import {AppTableInput} from './AppTableInput/AppTableInput';
import {CopyIcon} from '../common/Icons/icons';
import './forecast.css';
import {AppButton} from '../common/AppButton/AppButton';
// import {defaultObj} from './consts';
import {forecastDefaultData, fyCalculateTotal, getSortedMonthlyData, monthNYearSetter, rowsSetter} from './utils';
import {AppDialog, AppErrorDialog} from '../common/App-Dialog/AppDialog';
import {setForeCastSaveButton} from '../../redux/features/configurations';
import ReadOnlyColumn from '../common/ReadOnlyColumn';
import {updateStatusOfBuyPlan} from '../../redux/features/apiCall';
// import {useSelector} from 'react-redux';

const PreviousMonthTable2 = ({
  // subMissionData,
  // setSubmissionData,
  wfDetails,
  PreviousYearData1, PreviousYearData2, PreviousYearData3, setPreviousYearData1, setPreviousYearData2, setPreviousYearData3,
  years, setValue,
  setTabsEnableCheck,
  data,
  setData,
  setModifiedData, setDataTrigger, dataTrigger,
  selectedYear,
  modifiedData,
  selectedRow,
}) => {
  const [subMissionData, setSubmissionData] = React.useState({});
  const [accessControl, setAccessControl]=useState('');
  const forecastsaveButton = useSelector((state)=> state.configurations.forecastsaveButton);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const [showErrorDialog, setShowErrorDialog]=useState(false);
  const [errorMessage, setErrorMessage]=useState(null);
  const [workflowIdsObj, setWorkflowIdsObj] = useState({});
  const [newWfID, setNewWfID] = useState('');
  const dispatch = useDispatch();
  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Forecast_Update']) {
      setAccessControl(functionalityAccess['Buy_Plan:Forecast_Update']);
    }
  }, [functionalityAccess]);

  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);

  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(()=>{
    if (years.length>0) {
      let monthData = selectedRow.executionMonthYear[1]-1 < 10 ? '0'+(selectedRow.executionMonthYear[1]-1) : selectedRow.executionMonthYear[1]-1;
      // Added the if condtion because if present month is jan we need to make monthData to be 12 then we will get the correct newWfid
      if (monthData=='00') {
        monthData='12';
      }
      const totalYearMonth = `${selectedRow.executionMonthYear[1]==2 ? years[1] : years[0]}-${monthData}`;
      // console.log(totalYearMonth);
      if (selectedRow.isHistoricalDataEnabled==true) {
        DialogService.getHistoricalId(totalYearMonth, selectedRow.buyPlanId).then((res)=>{
          console.log(res);
          setNewWfID(res.workflow_id);
        });
      }
    }
  }, [selectedRow, years]);


  const [columns, setColumns] = useState([]);
  const [columns2, setColumns2] = useState([]);

  const setColumDatas = () => {
    setTimeout(()=>{
      if (selectedYear !== undefined && selectedYear!==null && selectedYear != 0) {
        setColumns([
          {
            ...keyColumn('planMonth', textColumn),
            title: 'Plan Month',
            disabled: true,
          },
          {
            ...keyColumn('year', textColumn),
            title: 'Plan Year',
            disabled: true,
          },
          {
            ...keyColumn('jan', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="jan"
                forecastVal={[selectedYear, 1]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jan',
          },
          {
            ...keyColumn('feb', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="feb"
                forecastVal={[selectedYear, 2]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Feb',
          },
          {
            ...keyColumn('mar', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="mar"
                forecastVal={[selectedYear, 3]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Mar',
          },
          {
            ...keyColumn('apr', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="apr"
                forecastVal={[selectedYear, 4]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Apr',
          },
          {
            ...keyColumn('may', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="may"
                forecastVal={[selectedYear, 5]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'May',
          },
          {
            ...keyColumn('jun', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="jun"
                forecastVal={[selectedYear, 6]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jun',
          },
          {
            ...keyColumn('jul', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="jul"
                forecastVal={[selectedYear, 7]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Jul',
          },
          {
            ...keyColumn('aug', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="aug"
                forecastVal={[selectedYear, 8]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Aug',
          },
          {
            ...keyColumn('sep', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="sep"
                forecastVal={[selectedYear, 9]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Sep',
          },
          {
            ...keyColumn('oct', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="oct"
                forecastVal={[selectedYear, 10]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Oct',
          },
          {
            ...keyColumn('nov', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="nov"
                forecastVal={[selectedYear, 11]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Nov',
          },
          {
            ...keyColumn('dec', textColumn),
            component: ({rowData, focus}) => (
              <AppTableInput decider={2}
                data={data}
                subMissionData={subMissionData}
                setSubmissionData={setSubmissionData}
                setData={setData}
                columnName="dec"
                forecastVal={[selectedYear, 12]}
                rowData={rowData}
                focus={focus}
              />
            ),

            title: 'Dec',
          },
          {...keyColumn('fy', textColumn),
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='fy' />
            ),
            title: `FY ${selectedYear} Total`, disabled: true},
        ]);

        setColumns2([
          {
            ...keyColumn('planMonth', textColumn),
            title: 'Plan Month',
            disabled: true,
          },
          {
            ...keyColumn('year', textColumn),
            title: 'Plan Year',
            disabled: true,
          },
          {
            ...keyColumn('jan', textColumn),
            disabled: true,
            title: 'Jan',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='jan' />
            ),
          },
          {
            ...keyColumn('feb', textColumn),
            disabled: true,
            title: 'Feb',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='feb' />
            ),
          },
          {
            ...keyColumn('mar', textColumn),
            disabled: true,
            title: 'Mar',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='mar' />
            ),
          },
          {
            ...keyColumn('apr', textColumn),
            disabled: true,
            title: 'Apr',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='apr' />
            ),
          },
          {
            ...keyColumn('may', textColumn),
            disabled: true,
            title: 'May',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='may' />
            ),
          },
          {
            ...keyColumn('jun', textColumn),
            disabled: true,
            title: 'Jun',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='jun' />
            ),
          },
          {
            ...keyColumn('jul', textColumn),
            disabled: true,
            title: 'Jul',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='jul' />
            ),
          },
          {
            ...keyColumn('aug', textColumn),
            disabled: true,
            title: 'Aug',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='aug' />
            ),
          },
          {
            ...keyColumn('sep', textColumn),
            disabled: true,
            title: 'Sep',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='sep' />
            ),
          },
          {
            ...keyColumn('oct', textColumn),
            disabled: true,
            title: 'Oct',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='oct' />
            ),
          },
          {
            ...keyColumn('nov', textColumn),
            disabled: true,
            title: 'Nov',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='nov' />
            ),
          },
          {
            ...keyColumn('dec', textColumn),
            disabled: true,
            title: 'Dec',
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='dec' />
            ),
          },
          {...keyColumn('fy', textColumn),
            component: ({rowData, focus}) => (
              <ReadOnlyColumn decider={2} rowData={rowData} columnName='fy' />
            ),
            title: `FY ${selectedYear} Total`, disabled: true},

        ]);
      }
    }, 100);
  };

  useEffect(()=>{
    setColumns([]);
    setColumns2([]);
    setColumDatas();
  }, [selectedYear]);


  useEffect(() => {
    getCall();
  }, [selectedRow]);

  const getCall = () => {
    DialogService.getForecastData(selectedRow.workFlowId, selectedRow.prevWorkFlowId, selectedRow.sector)
        .then((res) => {
          let obj = {};
          if (res.length) {
            res.forEach((val) => {
              const split = val.forecastYearMonth;
              // using selectedRow.executionMonthYear[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years
              if (+split[0] >= +years[0]) {
                if (!workflowIdsObj[val.workflow_id]) {
                  workflowIdsObj[val.workflow_id] = val;
                  setWorkflowIdsObj({...workflowIdsObj});
                }
                obj = {...monthNYearSetter(obj, val)};
              }
            });

            // debugger;
            // if some data is received ans some not like 2023 year data is there but 2024 data is not
            obj = {...rowsSetter(obj, workflowIdsObj, selectedRow)};
          } else {
          // when there is no data for a all three years
            obj = forecastDefaultData(selectedRow);
          }
          // console.log('@fsct getCall ModifiedData ', obj);
          setModifiedData(obj);
        })
        .catch((err) => console.log(err));
  };


  const postCall = async () => {
    const datas = [];
    // console.log([PreviousYearData1[0], PreviousYearData2[0], PreviousYearData3[0]]);
    Object.keys(PreviousYearData1[0]).map((obj)=>{
      if (months.includes(obj)) {
        // datas.push({workflowId: newWfID, forecastYearMonth: [PreviousYearData1[0].planYear, months.indexOf(obj)+1], forecastValue: PreviousYearData1[0][obj]!==null ? (+wfDetails?.decimal_scale === 0 ? roundToTwo(2, +(PreviousYearData1[0][obj]?.toString().split(',').join(''))) : +(PreviousYearData1[0][obj]?.toString().split(',').join(''))) : 0});
        datas.push({workflowId: newWfID, forecastYearMonth: [PreviousYearData1[0].planYear, months.indexOf(obj)+1], forecastValue: PreviousYearData1[0][obj]!==null ? +(PreviousYearData1[0][obj]?.toString().split(',').join('')) : 0});
      }
    });
    Object.keys(PreviousYearData2[0]).map((obj)=>{
      if (months.includes(obj)) {
        // datas.push({workflowId: newWfID, forecastYearMonth: [PreviousYearData2[0].planYear, months.indexOf(obj)+1], forecastValue: PreviousYearData2[0][obj]!==null ? (+wfDetails?.decimal_scale === 0 ? roundToTwo(2, +(PreviousYearData2[0][obj]?.toString().split(',').join(''))) : +(PreviousYearData2[0][obj]?.toString().split(',').join(''))) : 0});
        datas.push({workflowId: newWfID, forecastYearMonth: [PreviousYearData2[0].planYear, months.indexOf(obj)+1], forecastValue: PreviousYearData2[0][obj]!==null ? +(PreviousYearData2[0][obj]?.toString().split(',').join('')) : 0});
      }
    });
    Object.keys(PreviousYearData3[0]).map((obj)=>{
      if (months.includes(obj)) {
        // datas.push({workflowId: newWfID, forecastYearMonth: [PreviousYearData3[0].planYear, months.indexOf(obj)+1], forecastValue: PreviousYearData3[0][obj]!==null ? (+wfDetails?.decimal_scale === 0 ? roundToTwo(2, +(PreviousYearData3[0][obj]?.toString().split(',').join(''))) : +(PreviousYearData3[0][obj]?.toString().split(',').join(''))) : 0});
        datas.push({workflowId: newWfID, forecastYearMonth: [PreviousYearData3[0].planYear, months.indexOf(obj)+1], forecastValue: PreviousYearData3[0][obj]!==null ? +(PreviousYearData3[0][obj]?.toString().split(',').join('')) : 0});
      }
    });
    DialogService.saveForecastData({'forecastDetails': datas, 'forecastvariance': []}).then((res) => {
      setIsSavePopupVisible(true);
      if (res == true) {
        dispatch(setForeCastSaveButton('true'));
        setDataTrigger(!dataTrigger);
      }
      if (selectedRow.status !=='DRAFT') {
        DialogService.updateStatus(selectedRow.workFlowId, 'DRAFT')
            .then((res) => {
              if (res.status == 200 || res===true) {
                dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'DRAFT'));
              }
            });
      }
    }).catch((err)=>{
      const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
      setErrorMessage(errmsg);
      setShowErrorDialog(true);
    });
  };

  const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
      if (!(+splitArray[1] > 0)) formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  const addingFunction = (data, setting) =>{
    // console.log(data);
    const prevYear = {...data[0]};
    let total = 0;
    months.map((mn)=>{
      // console.log((prevYear[mn] && prevYear[mn].toString().includes(',') ? +(prevYear[mn].replaceAll(',', '')) : +prevYear[mn] || 0));
      total+= roundToTwo(2, prevYear[mn] && prevYear[mn].toString().includes(',') ? +roundToTwo(2, prevYear[mn].replaceAll(',', '')) : +roundToTwo(2, prevYear[mn]) || 0);
    });
    // console.log(total);
    prevYear['fy'] = total;
    if (setting ==0) {
      setPreviousYearData1([{...prevYear}]);
    }
    if (setting==1) {
      setPreviousYearData2([{...prevYear}]);
    }
    if (setting==2) {
      setPreviousYearData3([{...prevYear}]);
    }
  };
  useEffect(()=>{
    addingFunction(PreviousYearData1, 0);

    addingFunction(PreviousYearData2, 1);

    addingFunction(PreviousYearData3, 2);
  }, []);
  return (
    <>
      {((columns.length && PreviousYearData1.length) || '') && (
        <div className="forecast-table">
          {forecastsaveButton=='false' && accessControl === 'Edit' && selectedRow.status !=='SUBMITTED' && selectedRow.status !=='APPROVED' && selectedRow.status !== 'CLOSED' && selectedYear == years[0] && (
            <DataSheetGrid
              value={PreviousYearData1}
              onChange={(newData)=>{
                // console.log(newData);
                addingFunction(newData, 0);
              }}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          ) }

          {forecastsaveButton=='false' && accessControl === 'Edit' && selectedRow.status !=='SUBMITTED' && selectedRow.status !=='APPROVED' && selectedRow.status !== 'CLOSED' && selectedYear == years[1] && (
            <DataSheetGrid
              value={PreviousYearData2}
              onChange={(newData)=>{
                addingFunction(newData, 1);
              }}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          ) }

          {forecastsaveButton=='false' && accessControl === 'Edit' && selectedRow.status !=='SUBMITTED' && selectedRow.status !=='APPROVED' && selectedRow.status !== 'CLOSED' && selectedYear == years[2] && (
            <DataSheetGrid
              value={PreviousYearData3}
              onChange={(newData)=>{
                addingFunction(newData, 2);
              }}
              columns={columns}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}


          {/* If button redux variable true then it shows disabled columns */}
          {(forecastsaveButton=='true' || accessControl !== 'Edit' || selectedRow.status =='SUBMITTED' || selectedRow.status =='APPROVED' || selectedRow.status == 'CLOSED') && selectedYear == years[0] && (
            <DataSheetGrid
              value={PreviousYearData1}
              onChange={(newData)=>{
                // console.log(newData);
                addingFunction(newData, 0);
              }}
              columns={columns2}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {(forecastsaveButton=='true' || accessControl !== 'Edit' || selectedRow.status =='SUBMITTED' || selectedRow.status =='APPROVED' || selectedRow.status == 'CLOSED') && selectedYear == years[1] && (
            <DataSheetGrid
              value={PreviousYearData2}
              onChange={(newData)=>{
                addingFunction(newData, 1);
              }}
              columns={columns2}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {(forecastsaveButton=='true' || accessControl !== 'Edit' || selectedRow.status =='SUBMITTED' || selectedRow.status =='APPROVED' || selectedRow.status == 'CLOSED') && selectedYear == years[2] && (
            <DataSheetGrid
              value={PreviousYearData3}
              onChange={(newData)=>{
                addingFunction(newData, 2);
              }}
              columns={columns2}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
            />
          )}

          {/* {accessControl === 'Edit' && (selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT') ? ( */}
          {/* {accessControl === 'Edit' && (buttonStatus==='edit' || (currentRole === 'Central Admin' && selectedRow.status === 'DRAFT')) ? ( */}

          {accessControl === 'Edit' && (buttonStatus==='edit' || (currentRole.includes('Admin') && (selectedRow.status === 'DRAFT' || selectedRow.status=='REJECTED'))) ? (
            forecastsaveButton === 'false' ?
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
            <AppButton
              onClick={() => {
                postCall();
              }}
              label={'Save'}
            />
          </Box> :
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
            <AppButton
              onClick={() => {
                dispatch(setForeCastSaveButton('false'));
                setDataTrigger(!dataTrigger);
              }}
              label={'Edit Forecast'}
            />
          </Box>
          ) : (
            ''
          )}
        </div>
      )}
      <AppErrorDialog open={showErrorDialog} handleClose={() => {
        setShowErrorDialog(false); setErrorMessage(null);
      }} text={errorMessage ? errorMessage : 'Error occured when saving'}/>
      <AppDialog
        open={isSavePopupVisible}
        handleClose={() => {
          setIsSavePopupVisible(false);

          // setValue(1);
        }}
        text="Previous Month Forecast Saved"
      />
    </>
  );
};

export default PreviousMonthTable2;
