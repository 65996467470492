/* eslint-disable max-len */
import {Box, Button, Typography} from '@mui/material'; // Button, Typography, Select, MenuItem, TextField
import {styled} from '@mui/material/styles';

const StyledCircularProgress = styled(Box)(() => ({
  justifyContent: 'center',
}));

const StyledBottomSummeryBox = styled(Box)(({theme})=>({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingLeft: '20%',
  border: `1px solid ${theme.palette.customText.darkGrey}`,
  marginTop: '35.6px',
}));
const StyledBottomSummerySubBox = styled(Box)(()=>({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-around',
  width: '100%',
  height: '36px',
  alignItems: 'center',
}));

const StyledCoverageSummBottomLabel=styled(Typography)(({theme})=>({
  'fontSize': '12px',
  'fontFamily': 'Segoe UI',
  'color': theme.palette.customText.dark,
}));

const StyledCoverageSummBottomButton=styled(Button)(({theme})=>({
  'backgroundColor': theme.palette.customBackground.activeButtonBackground,
  'color': theme.palette.customText.white,
  'textTransform': 'none',
  'width': '133px',
  '&:hover': {
    backgroundColor: theme.palette.customBackground.activeButtonBackground,
  },
  'fontFamily': 'Segoe UI',
  'borderRadius': '5px',
  'fontSize': '12px',
  'height': '30px',
  'fontWeight': 500,
  'marginTop': '18px',
  'float': 'right',
}));

const StyledCoverageSummBottomButtonBox=styled(Box)(({theme})=>({
  border: `1px solid ${theme.palette.customBorder.darkGrey}`,
  width: '100%',
  padding: '5px 4px',
  display: 'flex',
  justifyContent: 'space-evenly',
}));

const StyledCoverageSummBottomButtonBoxText=styled(Box)(({theme})=>({
  font: 'normal normal 400 12px/14px "Segoe UI',
}));

export default {
  StyledCircularProgress,
  StyledBottomSummeryBox,
  StyledBottomSummerySubBox,
  StyledCoverageSummBottomLabel,
  StyledCoverageSummBottomButton,
  StyledCoverageSummBottomButtonBox,
  StyledCoverageSummBottomButtonBoxText,
};
