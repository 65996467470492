/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {memo, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import '../../lib/react-datasheet-grid-custom/dist/style.css';
import './buyPlan.css';
import {Button} from '@mui/material';
import {prevDataEvaluator} from './EditBuyPlanService';
import DialogService from '../../services/dialogService';

import {allColumns, columnsUtil} from './EditBuyPlanConsts';
import {AppButton, AppButton2, AppButton3, AppButton4} from '../common/AppButton/AppButton';
import {ApproverComments, Comments, CommentsTable} from './Components';
import axios from 'axios';
import {monthArr, months, roundToTwo, titleCase} from '../../services/utils';
import config from '../../Utils/Config';
import {useDispatch, useSelector} from 'react-redux';
import {ApproverComments2} from './Components2';
// import { titleCase } from '../../services/utils';
import {forecastDefaultData, monthNYearSetter, rowsSetter} from '../ForecastTab/utils';

import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import {setPlanStatusChange, updateCommentTagging} from '../../redux/features/apiCall';
import {updateStatusOfBuyPlan} from '../../redux/features/apiCall';
import {AppDialog, AppErrorDialog, AppConfirmDialog, BulkUploadError, MustMayWillCoverVariance} from '../common/App-Dialog/AppDialog';
import {changeButtonStatusValue} from '../../redux/features/buttonStatus';
import {buyPlanData, setnegitiveValueReasons} from '../../redux/BuyPlan';
import {editStatus, setPreviousMonthWillCoverEditButton} from '../../redux/EditStatus';
import {isRecallStatus} from '../../redux/IsRecallStatus';
import {setForeCastSendToAtlasFx} from '../../redux/features/configurations';
import {useOktaAuth} from '@okta/okta-react';
import {useRef} from 'react';
import DocumentUpload from '../DocumentUpload/DocumentUpload';
import ConfigurationServices from '../../services/ConfigurationServices';
import PreviousMonthBuyPlanPopUp from './PreviousMonthBuyPlanPopUp';
import PastMonthForecastCommentsTable from '../common/PreviousMonthCommentsTable/PastMonthForecastCommentsTable';
import CarryVolumeReasons from '../common/PreviousMonthCommentsTable/CarryVolumeReasons';

import {DataSheetGrid} from '../../lib/react-datasheet-grid-custom/dist';
import DownloadEditBuyPlanTableList from './DownloadEditBuyPlanTableList';
import CarryValuePopUp from './CarryValuePopUp';
import {isAfterExecutionMonthYear} from '../../Utils/Common';


const EDIT = config.AccessLevel.Edit;

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function BootstrapDialogTitle(props) {
  const {children, onClose, ...other} = props;

  return (
    <DialogTitle sx={{m: 0, p: 2}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const gridCSS = {
  activeButton: {
    background: '#4297d3',
    color: '#ffffff',
    fontSize: '12px',
    border: 'none',
    outline: '0px',
    cursor: 'pointer',
    borderRadius: '3px',
    padding: '2px 3px',
  },
  inActiveButton: {
    background: '#ffffff',
    color: '#6d7888',
    fontSize: '12px',
    cursor: 'pointer',
    border: 'none',
    outline: '0px',
    padding: '2px 3px',
  },
  tabContainer: {
    padding: '15px',
    height: '95vh',
  },
  grid: {
    background: '#F8F8F8',
    padding: '10px 0px',
    borderRadius: '10px',
    margin: '0 !important',
    width: '100% !important',
  },
  head: {
    color: '#0071DC',
    fontSize: '12px',
    fontWeight: 600,
    font: 'normal normal 600 12px/16px Segoe UI',
    marginBottom: '8px',
  },
  text: {
    color: '#3B3A39',
    fontSize: '12px',
    font: 'normal normal 600 12px/16px Segoe UI',
  },
};

const BuyPlanTable = ({wfDetails, checkHedgingHasSupplier, setYears, isPreviousYearData, execMonthDecider, setExecMonthDecider, years, selectedArr, setModifiedData, BuyplanPreviousMonthAccess, setIsPopupVisible, quarterMonths, setTabsEnableCheck, setValue, columnsStatus, quarters, changeStatus, setSelectedArr, initialArr, selectedRow, modifiedData}) => {
  const [columns, setColumns] = useState([]);
  const [target, setTarget] = useState(null);
  const [sCarryValueReasonPopUp, setsCarryValueReasonPopUp] = useState(false);
  const [errorHappen, seterrorHappen]=useState(false);
  const [sCarryValueReason, setsCarryValueReason] = useState('');

  useEffect(()=>{
    dispatch(updateCommentTagging());
  }, []);
  // console.log(wfDetails.atlasfx_outbound_file);
  const [coverages, setCoverages] = useState([]);
  const [pcoverages, setPlannedCoverages] = useState([]);
  const [flag, setFlag] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const [bulkErrors, setBulkErrors] = useState([]);
  const [bulkErrorsOpen, setBulkErrorsOpen] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  // const [showErrorDialog2, setShowErrorDialog2] = useState(false);
  const [errorData, setErrorData] = useState('');

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showAtlasConfirm, setshowAtlasConfirm] = useState(false);
  const [mustMayTolerance, setmustMayTolerance] = useState(false);
  const [mustMayToleranceErrors, setmustMayToleranceErrors] = useState([]);

  const [programName, setprogramName] = useState(false);

  const [workflowIdsObj, setWorkflowIdsObj] = useState({});
  const [showApproveConfirmDialog, setshowApproveConfirmDialog] = useState(false);

  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const [forecastSendFlagSucStatus, setForecastSendFlagSucStatus] = useState({status: false, errMsg: ''});
  const [forecastSendFlagFaiStatus, setForecastSendFlagFaiStatus] = useState({status: false, sucMsg: ''});
  const disableForecastBtn = useSelector((state) => state.configurations.forecastSendToAtlasFx);
  // const [file, setFile] = useState(null);
  const [isRecallApproval, setIsRecallApproval] = useState(false);
  const [accessControlBuyPlan, setAccessControlBuyPlan]=useState('');
  const [downloadOutBoundFileAccess, setdownloadOutBoundFileAccess]=useState(false);
  const [accessControlComment, setAccessControlComment]=useState('');
  const [accessControlReview, setAccessControlReview]=useState('');
  const [buyPlanDetails, setBuyPlanDetails] = useState([]);
  const [planStatus, setPlanStatus] = useState('');
  const [currentRole, setCurrentRole] = useState(null);
  const [nextMonthCloseBuyPlanTag, setNextMonthCloseBuyPlanTag] = useState(false);
  const [SendAtlasFxDisabledAfterWd2, setSendAtlasFxDisabledAfterWd2] = useState(false);

  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);

  const pageStatus = useSelector((state) => state.btnStatus.page);
  const [isPlanValidate, setIsPlanValidated] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [approverCommentsData, setApproverCommentsData] = useState([]);
  const {authState} = useOktaAuth();
  const data = useSelector((state) => state.buyPlanData.value);

  const previousMonthWillCoverEditButton = useSelector((state) => state.editStatus.previousMonthWillCoverEditButton);

  const [PastMonthpreviousvarianceData, setPastMonthpreviousvarianceData] = useState([]);
  const [CarryVolumeReasonsData, setCarryVolumeReasonsData] = useState([]);

  const [PastMonthCommentsTrigger, setPastMonthCommentsTrigger] = useState(false);
  const [CarryVolumeCommentsTrigger, setCarryVolumeCommentsTrigger] = useState(false);

  const [previousMonthDifferenceData, setPreviousMonthDifferenceData] = useState([]);
  const [showPreviousMonthDifference, setshowPreviousMonthDifference] = useState(false);
  const [OriginalPastMonthComments, setOriginalPastMonthComments] = useState([]);
  const isCreator = useSelector((state) => state.roleBasedAccess);

  const isBeforeMonth = (rowData, month) => {
    const NotJanFeb = selectedRow.executionMonthYear[1]!==1 && selectedRow.executionMonthYear[1]!==2;
    if ((previousMonthWillCoverEditButton==false|| previousMonthWillCoverEditButton=='') && isPreviousYearData==true && +rowData.planYear<+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])] && NotJanFeb) {
      return true;
    }
    if (previousMonthWillCoverEditButton==true && NotJanFeb) {
      return (+rowData.planYear == +((isPreviousYearData==true?+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])]:+years[0])) && month >= (selectedRow?.executionMonthYear[1]-1 ==0?11: selectedRow?.executionMonthYear[1]-2)) || +rowData.planYear!=+(isPreviousYearData==true?+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])]:+years[0]);
    } else if (previousMonthWillCoverEditButton==true && !NotJanFeb) {
      return (+rowData.planYear == (+years[0])) && month >= (selectedRow?.executionMonthYear[1]-1 ==0?11: selectedRow?.executionMonthYear[1]==2 ? 12 : selectedRow?.executionMonthYear[1]-2) || +rowData.planYear!=+years[0];
    } else if ((previousMonthWillCoverEditButton==false|| previousMonthWillCoverEditButton=='') && NotJanFeb) {
      return (+rowData.planYear == +((isPreviousYearData==true?+years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])]:+years[0]))) && month < (selectedRow?.executionMonthYear[1]-1 ==0?11: selectedRow?.executionMonthYear[1]-2);
    } else if ((previousMonthWillCoverEditButton==false|| previousMonthWillCoverEditButton=='') && !NotJanFeb) {
      return ((+rowData.planYear == +years[0]) && ((selectedRow.executionMonthYear[1] == 1 && +month<11) || (selectedRow.executionMonthYear[1] == 2 && +month<12)));
    }
  };


  useEffect(()=>{
    ConfigurationServices.getPastMonthComments(selectedRow.workFlowId, 'BUYPLAN').then((res)=>{
      const apidata = [...res];
      setPreviousMonthDifferenceData([...apidata]);
      setPastMonthpreviousvarianceData([...apidata]);
      setOriginalPastMonthComments([...apidata]);
    }).catch((err)=>{
      console.log(err);
    });
  }, [PastMonthCommentsTrigger]);

  useEffect(()=>{
    ConfigurationServices.getCarryVolumeReason(selectedRow.workFlowId).then((res)=>{
      setCarryVolumeReasonsData([...res]);
      setsCarryValueReason(res[0]?.comment);
    }).catch((err)=>{
      console.log(err);
    });
  }, [CarryVolumeCommentsTrigger]);

  const [submitPressed, setSubmitPressed] = useState(null);
  const determiningPreviousWillCover = (submitting) =>{
    const temporaryData = [];
    // console.log(data[39]['sCarryValue']);

    // console.log(previousMonthDifferenceData);
    // console.log(data.slice(0, selectedRow.executionMonthYear[1]==1?11:selectedRow.executionMonthYear[1]-2));
    const dataDecider = (isPreviousYearData==false|| selectedRow.executionMonthYear[1]==1 || selectedRow.executionMonthYear[1]==2) ? data.slice(0, selectedRow.executionMonthYear[1]==1?10:selectedRow.executionMonthYear[1]==2?11:selectedRow.executionMonthYear[1]-3) : (execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])==1 ? data.slice(13, 13+(+selectedRow.executionMonthYear[1]-3)) : data.slice(26, 26+(+selectedRow.executionMonthYear[1]-3)));
    dataDecider?.map((d, index)=>{
      if (d['swillCover']!==0 || d['twillCover']!==0 || d['owillCover']!==0 || d['overAllWillCover']!==0) {
        let tempCount =0;
        // checking if comments already there for entered will covers
        if (OriginalPastMonthComments.length>0) {
          OriginalPastMonthComments.map((td)=>{
            if (td['planYearMonth'].split('-')[0]?.toLowerCase() == d['period']?.toLowerCase()) {
              temporaryData.push({'id': td['id'], 'comment': td['comment'], 'planYearMonth': d.period});
              tempCount+=1;
            }
          });
        }
        if (tempCount==0) {
          temporaryData.push({'id': 0, 'comment': '', 'planYearMonth': d.period});
        }
      }
    });
    // console.log(temporaryData);
    setPreviousMonthDifferenceData([...temporaryData]);
    if (temporaryData.length>0) {
      setshowPreviousMonthDifference(true);
    } else {
      const sendingData = {
        'buyPlans': [],
        'previousPlanMonthComments': [],
      };
      const payload = [];
      data.forEach((val, i) => {
        if (monthArr.indexOf(val.period) > -1) {
          const obj = {
            'workflowId': val.workFlowId || selectedRow.workFlowId,
            'coverageYearMonth': [+val.planYear, monthArr.indexOf(val.period)+1],
            'lastMonthForecast': roundToTwo(2, val.volumeLE),
            'forecast': roundToTwo(2, val.updatedFcst),
            'lastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.coverage),
            // 'supplierCoverage': roundToTwo(+wfDetails?.decimal_scale, val.swillCover),
            'supplierCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.swillCover) : val.swillCover,
            // 'treasuryCoverage': roundToTwo(+wfDetails?.decimal_scale, val.twillCover),
            'treasuryCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover) : (wfDetails?.isLotSizeEnabled && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, val?.period, val?.planYear)) ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover, wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit) : val.twillCover,
            'coverage': roundToTwo(+wfDetails?.decimal_scale, val.overAllWillCover),
            'supMinTarget': roundToTwo(2, val.stargetPercentMin),
            'supMaxTarget': roundToTwo(2, val.stargetPercentMax),
            'treasuryMinTarget': roundToTwo(2, val.ttargetPercentMin),
            'treasuryMaxTarget': roundToTwo(2, val.ttargetPercentMax),
            'minTargetPercentage': roundToTwo(2, val.targetPercentMin),
            'maxTargetPercentage': roundToTwo(2, val.targetPercentMax),
            'lastMonthReason': val.varianceReason || '',
            'lastMonthComment': val.comments || '',
            'quarterlyReason': val.qvarianceReason || '',
            'quarterlyComment': val.qcomments || '',
            // 'otherCoverage': roundToTwo(+wfDetails?.decimal_scale, val.overAllCoverage),
            'suppLastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.sLastMonthCoverage),
            'tresLastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.tLastMonthCoverage),
            'otherLastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.oLastMonthCoverage),
            'variance': roundToTwo(2, val.targetVariancePercent),
            'newCoverage': roundToTwo(+wfDetails?.decimal_scale, val.overAllCoverage),
            // 'otherCoverage': roundToTwo(+wfDetails?.decimal_scale, val.owillCover),
            'otherCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.owillCover) : val.owillCover,
            'isPrevAndActualCovSame': val?.isPrevAndActualCovSame ? true : false,
            // 'carryVolume': val.sCarryValue,
            'carryVolume': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.sCarryValue) : val.sCarryValue,
          };
          payload.push(obj);
        }
      });
      sendingData['buyPlans'] = payload;
      // console.log(sendingData);
      DialogService.savePastMonthWillCoverData({...sendingData, 'carryVolumeComment': sCarryValueReason==''?null:sCarryValueReason}).then((res)=>{
        dispatch(setPreviousMonthWillCoverEditButton(false));
        if (submitting!==true) {
          setShowSuccessDialog(true);
          setShowMessage('Plan saved as Draft');
        }
        setGroups(columnsUtil(handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, false));
        setPastMonthCommentsTrigger(!PastMonthCommentsTrigger);
        setCarryVolumeCommentsTrigger(!CarryVolumeCommentsTrigger);
      }).catch((err)=>{
        setshowPreviousMonthDifference(false);
        setShowErrorDialog(true);
        setGroups(columnsUtil(isPreviousYearData, execMonthDecider, handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, false));
        setPastMonthCommentsTrigger(!PastMonthCommentsTrigger);
      });
      // setGroups(columnsUtil(handleGroups, handleEdit, editFlag, selectedRow, isRecall, (quarterMonths || quartersData), years, false));
      if (submitting==true) {
        DialogService.updateStatus(selectedRow.workFlowId, 'SUBMITTED')
            .then((res) => {
              setShowConfirmDialog(false);
              if (res == true) {
                dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'SUBMITTED'));
                // to show plan submitted!
                setShowSuccessDialog(true);
                setShowMessage('  Plan Submitted');

                dispatch(changeButtonStatusValue('view'));
                dispatch(isRecallStatus(true));
                setIsRecallApproval('SUBMITTED');
              } else {
                // console.log('Error : ', err);
              }
            }).then(()=>{
              dispatch(setPlanStatusChange('SUBMITTED'));
            })
            .catch((err) => {
              setShowConfirmDialog(false);
              setShowErrorDialog(true);
              // console.log('Error : ', err);
            },
            );

        setSubmitPressed(null);
      }
      dispatch(setPreviousMonthWillCoverEditButton(false));
      setGroups(columnsUtil(isPreviousYearData, execMonthDecider, handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, false));
    }
  };

  const handlePreviousMonthBuyPlanDifferenceSubmit= async ()=>{
    const sendingData = {
      'buyPlans': [],
      'previousPlanMonthComments': [],
    };
    const payload = [];
    let isValidate = false;
    let errors = [];
    data.forEach((val, i) => {
      if (val.swillCoverNegitiveOption==null && val.swillCover<0 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
        errors.push(`Click the dropdown icon next to the negative 'Supplier Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
        isValidate = true;
      }
      if (val.twillCoverNegitiveOption==null && val.twillCover<0 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
        errors.push(`Click the dropdown icon next to the negative 'Treasury Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
        isValidate = true;
      }
      if (val.owillCoverNegitiveOption==null && val.owillCover<0 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
        errors.push(`Click the dropdown icon next to the negative 'Other Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
        isValidate = true;
      }
      if (val.overAllWillCoverNegitiveOption==null && val.overAllWillCover<0 && selectedRow.coverageMode=='NONE' && selectedRow.others==false && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
        errors.push(`Click the dropdown icon next to the negative 'Overall Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
        isValidate = true;
      }
      if (monthArr.indexOf(val.period) > -1) {
        const obj = {
          'workflowId': val.workFlowId || selectedRow.workFlowId,
          'coverageYearMonth': [+val.planYear, monthArr.indexOf(val.period)+1],
          'lastMonthForecast': roundToTwo(2, val.volumeLE),
          'forecast': roundToTwo(2, val.updatedFcst),
          'lastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.coverage),
          // 'supplierCoverage': roundToTwo(+wfDetails?.decimal_scale, val.swillCover),
          'supplierCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.swillCover) : val.swillCover,
          // 'treasuryCoverage': roundToTwo(+wfDetails?.decimal_scale, val.twillCover),
          'treasuryCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover) : (wfDetails?.isLotSizeEnabled && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, val?.period, val?.planYear)) ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover, wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit) : val.twillCover,
          'coverage': roundToTwo(+wfDetails?.decimal_scale, val.overAllWillCover),
          'supMinTarget': roundToTwo(2, val.stargetPercentMin),
          'supMaxTarget': roundToTwo(2, val.stargetPercentMax),
          'treasuryMinTarget': roundToTwo(2, val.ttargetPercentMin),
          'treasuryMaxTarget': roundToTwo(2, val.ttargetPercentMax),
          'minTargetPercentage': roundToTwo(2, val.targetPercentMin),
          'maxTargetPercentage': roundToTwo(2, val.targetPercentMax),
          'lastMonthReason': val.varianceReason || '',
          'lastMonthComment': val.comments || '',
          'quarterlyReason': val.qvarianceReason || '',
          'quarterlyComment': val.qcomments || '',
          // 'otherCoverage': roundToTwo(+wfDetails?.decimal_scale, val.overAllCoverage),
          'suppLastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.sLastMonthCoverage),
          'tresLastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.tLastMonthCoverage),
          'otherLastMonthCoverage': roundToTwo(+wfDetails?.decimal_scale, val.oLastMonthCoverage),
          'variance': roundToTwo(2, val.targetVariancePercent),
          'newCoverage': roundToTwo(+wfDetails?.decimal_scale, val.overAllCoverage),
          // 'otherCoverage': roundToTwo(+wfDetails?.decimal_scale, val.owillCover),
          'otherCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.owillCover) : val.owillCover,
          'isPrevAndActualCovSame': val?.isPrevAndActualCovSame ? true : false,
          // 'carryVolume': val.sCarryValue,
          'carryVolume': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.sCarryValue) : val.sCarryValue,
        };
        payload.push(obj);
      }
    });
    sendingData['buyPlans'] = payload;
    previousMonthDifferenceData.length>0 && previousMonthDifferenceData.map((data, index)=>{
      sendingData['previousPlanMonthComments']?.push( {
        'id': data['id'],
        'workflowId': selectedRow.workFlowId,
        'planYearMonth': [(isPreviousYearData==false || selectedRow.executionMonthYear[1]==1 || selectedRow.executionMonthYear[1]==2)? +years[0] : +years[execMonthDecider?.indexOf(selectedRow.executionMonthYear[0])], months.indexOf(data['planYearMonth'].split('-')[0]?.toLowerCase())+1],
        'comment': data['comment'],
      });
    });
    if (!isValidate) {
      DialogService.savePastMonthWillCoverData({...sendingData, 'carryVolumeComment': sCarryValueReason==''?null:sCarryValueReason}).then((res)=>{
        dispatch(setPreviousMonthWillCoverEditButton(false));
        if (submitPressed!==true) {
          setShowSuccessDialog(true);
          setShowMessage('Plan saved as Draft');
        }
        setGroups(columnsUtil(handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, false));
        setPastMonthCommentsTrigger(!PastMonthCommentsTrigger);
        setCarryVolumeCommentsTrigger(!CarryVolumeCommentsTrigger);
      }).catch((err)=>{
        setshowPreviousMonthDifference(false);
        setShowErrorDialog(true);
        setGroups(columnsUtil(isPreviousYearData, execMonthDecider, handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, false));
        setPastMonthCommentsTrigger(!PastMonthCommentsTrigger);
      });
      if (submitPressed==true) {
        DialogService.updateStatus(selectedRow.workFlowId, 'SUBMITTED')
            .then((res) => {
              setShowConfirmDialog(false);
              if (res == true) {
                dispatch(updateStatusOfBuyPlan(selectedRow.workFlowId, 'SUBMITTED'));
                // to show plan submitted!
                setShowSuccessDialog(true);
                setShowMessage('  Plan Submitted');

                dispatch(changeButtonStatusValue('view'));
                dispatch(isRecallStatus(true));
                setIsRecallApproval('SUBMITTED');
              } else {
                // console.log('Error : ', err);
              }
            }).then(()=>{
              dispatch(setPlanStatusChange('SUBMITTED'));
            })
            .catch((err) => {
              setShowConfirmDialog(false);
              setShowErrorDialog(true);
              // console.log('Error : ', err);
            },
            );

        setSubmitPressed(null);
      }

      dispatch(setPreviousMonthWillCoverEditButton(false));
      setGroups(columnsUtil(isPreviousYearData, execMonthDecider, handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, false));
    } else {
      setIsPlanValidated(true);
      setBulkErrors(errors);
      setBulkErrorsOpen(true);
    }
  };

  const handleCarryValueReason = async ()=>{
    seterrorHappen(false);
    setsCarryValueReasonPopUp(false);
    if (submitPressed) {
      submitBuyPlan();
    } else {
      if (previousMonthWillCoverEditButton==true && previousMonthWillCoverEditButton!=='') {
        determiningPreviousWillCover();
      } else {
        savePlan();
      }
    }
  };


  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);
  useEffect(()=>{
    const todayRef = new Date();
    const currentYear = todayRef.getFullYear();
    const currentMonth = (todayRef.getMonth()) + 1;
    const currentDate = getCurrentMonthWDCount();
    let flagToUpdate = false;
    const checkYearAndMon = selectedRow.executionMonthYear;
    if (currentYear >= checkYearAndMon[0]) {
      if (currentYear == checkYearAndMon[0]) {
        if (currentMonth===checkYearAndMon[1]) {
          flagToUpdate = false;
        } else if (currentMonth>checkYearAndMon[1]) {
          if (((currentMonth - checkYearAndMon[1]) === 1)) {
            if (currentDate>=6) {
              flagToUpdate = true;
            } else {
              flagToUpdate = false;
            }
          } else {
            flagToUpdate = true;
          }
        }
      } else {
        if ((currentMonth==1 && checkYearAndMon[1]==12) ) {
          if (currentDate>=6) {
            flagToUpdate = true;
          } else {
            flagToUpdate = false;
          }
        } else {
          flagToUpdate = true;
        }
      }
    } else {
      flagToUpdate = true;
    }
    setNextMonthCloseBuyPlanTag(flagToUpdate);
  }, [selectedRow, currentRole]);


  //  this useEffect used to check if the working day is > 2 if it is >2 then we will disable the send atlas fx button
  useEffect(()=>{
    const todayRef = new Date();
    const currentYear = todayRef.getFullYear();
    const currentMonth = (todayRef.getMonth()) + 1;
    const currentDate = getCurrentMonthWDCount();
    let flagToUpdate = false;
    const checkYearAndMon = selectedRow.executionMonthYear;
    if (currentYear >= checkYearAndMon[0]) {
      if (currentYear == checkYearAndMon[0]) {
        if (currentMonth===checkYearAndMon[1]) {
          flagToUpdate = false;
        } else if (currentMonth>checkYearAndMon[1]) {
          if (((currentMonth - checkYearAndMon[1]) === 1)) {
            if (currentDate>=3) {
              flagToUpdate = true;
            } else {
              flagToUpdate = false;
            }
          } else {
            flagToUpdate = true;
          }
        }
      } else {
        if ((currentMonth==1 && checkYearAndMon[1]==12) ) {
          if (currentDate>=3) {
            flagToUpdate = true;
          } else {
            flagToUpdate = false;
          }
        } else {
          flagToUpdate = true;
        }
      }
    } else {
      flagToUpdate = true;
    }
    setSendAtlasFxDisabledAfterWd2(flagToUpdate);
  }, [selectedRow, currentRole]);

  const getCurrentMonthWDCount = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    let worked = 0;
    for (let i = 1; i <= today.getDate(); i++) {
      const testDate = new Date(year, month, i);
      const day = testDate.getDay();
      if (day > 0 && day < 6) {
        ++worked;
      }
    }
    return worked;
  };


  const [date, setDate] = useState(() => {
    let d = new Date();
    let dd = d.getDate();
    let mm = d.getMonth();
    let fullYear = d.getFullYear();
    return `${dd}-${monthArr[mm]}-${fullYear}`;
  });
  const [commentsTrigger, setCommentsTrigger] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [atlasOutboundStatus, setAtlasOutboundStatus] = useState('');
  const [enableRecall, setEnableRecall]=useState('');

  useEffect(()=>{
    setIsRecallApproval(selectedRow.status);
    setTimeout(()=>{
      getApproverComments();
    }, 1000);
  }, [selectedRow, commentsTrigger]);

  useEffect(()=>{
    if (wfDetails?.atlasfx_outbound_status==null) {
      setAtlasOutboundStatus(0);
    } else {
      setAtlasOutboundStatus(wfDetails?.atlasfx_outbound_status);
    }
  }, [wfDetails]);

  useEffect(() => {
    setSelectedYear(years[0]);
  }, years);

  const getApproverComments = () => {
    DialogService.getComments(selectedRow.workFlowId)
        .then((res) => {
          // console.log(res);
          if (res.length) {
            const approver = res.filter((val) => val.is_approver);
            const nonApprover = res.filter((val) => !val.is_approver);
            setApproverCommentsData(approver);
            setCommentsData(nonApprover);
          }
        });
  };

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    const {workFlowId} = selectedRow;
    DialogService.updateStatus(workFlowId, 'REJECTED')
        .then((res) => {
          if (res == true) {
            setOpen2(false);
            setPlanStatus('Rejected');
            setOpen(true);
            setIsRecallApproval('REJECTED');
            dispatch(isRecallStatus(false));
          }
        }).then(()=>{
          dispatch(setPlanStatusChange('REJECTED'));
        })
        .catch((err) => console.log(err));
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const overAllCoverageCalculation = (newArr, isBlur=true) =>{
    let total_Coverage_percentage1 = 0;
    let mustCoverageTotal1 = 0;
    let mayCoverageTotal1 = 0;
    let swillcovertotal1 = 0;
    let sCarryValueTotal1 = 0;

    let twillcovertotal1 = 0;
    let owillcovertotal1 = 0;
    let overallwillcover1 = 0;
    let lastMonthCoveragePercent1 = 0;
    // 'sCarryValue'
    for (let i=0; i<12; i++) {
      newArr[i]['overAllWillCover'] = isNaN(+newArr[i]['overAllWillCover']?.toString().split(',').join(''))?0:+newArr[i]['overAllWillCover']?.toString().split(',').join('');
      newArr[i]['swillCover'] = isNaN(+newArr[i]['swillCover']?.toString().split(',').join(''))?0:+newArr[i]['swillCover']?.toString().split(',').join('');
      newArr[i]['sCarryValue'] = isNaN(+newArr[i]['sCarryValue']?.toString().split(',').join(''))?0:+newArr[i]['sCarryValue']?.toString().split(',').join('');
      if (wfDetails?.isLotSizeEnabled && isBlur && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, newArr[i]?.period, newArr[i]?.planYear)) {
        newArr[i]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, +newArr[i]['twillCover']?.toString().split(',').join(''), wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit);
      } else {
        newArr[i]['twillCover'] = isNaN(+newArr[i]['twillCover']?.toString().split(',').join(''))?0:+newArr[i]['twillCover']?.toString().split(',').join('');
      }
      newArr[i]['owillCover'] = isNaN(+newArr[i]['owillCover']?.toString().split(',').join(''))?0:+newArr[i]['owillCover']?.toString().split(',').join('');

      newArr[i]['overAllCoveragePercent'] = isNaN((newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100) || (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst']) == 'Infinity' || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == '-Infinity'? roundToTwo(2, (newArr[i]['overAllCoverage']/0.1)) : roundToTwo(2, (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100);
      // console.log(newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'], (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100);
      // console.log(newArr[i]['overAllCoverage'], newArr[i]['overAllCoveragePercent'], +newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
      total_Coverage_percentage1 += (+newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
      mustCoverageTotal1+= (+newArr[i]['mustCover']);
      mayCoverageTotal1+= (+newArr[i]['mayCover']);
      if (selectedRow.coverageMode!=='NONE' || selectedRow.others == true) {
        newArr[i]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[i]['swillCover']?.toString().split(',').join('')) + (+newArr[i]['sCarryValue']?.toString().split(',').join('')) + (+newArr[i]['twillCover']?.toString().split(',').join('')) + (+newArr[i]['owillCover']?.toString().split(',').join('')));
      }
      // console.log(+newArr[i]['swillCover']?.toString().split(',').join(''));
      overallwillcover1+= +newArr[i]['overAllWillCover']?.toString().split(',').join('');
      swillcovertotal1+= +newArr[i]['swillCover']?.toString().split(',').join('');
      sCarryValueTotal1+= +newArr[i]['sCarryValue']?.toString().split(',').join('');

      twillcovertotal1+= +newArr[i]['twillCover']?.toString().split(',').join('');
      owillcovertotal1+= +newArr[i]['owillCover']?.toString().split(',').join('');
      lastMonthCoveragePercent1+= newArr[i]['coveragePercent']*newArr[i]['coverage'];
    }
    const wholedecimals1 = +total_Coverage_percentage1/newArr[12]['overAllCoverage'];
    newArr[12]['overAllCoveragePercent']= newArr[12]['updatedFcst']!==0 ? roundToTwo(2, (newArr[12]['overAllCoverage']/newArr[12]['updatedFcst'])*100) : 0;
    newArr[12]['mustCover']= roundToTwo(+wfDetails?.decimal_scale, mustCoverageTotal1.toFixed(+wfDetails?.decimal_scale));
    newArr[12]['mayCover']= roundToTwo(+wfDetails?.decimal_scale, mayCoverageTotal1.toFixed(+wfDetails?.decimal_scale));
    newArr[12]['maxCoverage'] = roundToTwo(+wfDetails?.decimal_scale, newArr[12]['maxCoverage']);
    newArr[12]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, overallwillcover1);
    newArr[12]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, swillcovertotal1);
    newArr[12]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, sCarryValueTotal1);
    newArr[12]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, twillcovertotal1);
    newArr[12]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, owillcovertotal1);
    newArr[12]['coveragePercent'] = newArr[12]['volumeLE']!==0 ? roundToTwo(2, (newArr[12]['coverage']/newArr[12]['volumeLE'])*100) : 0;


    let swillcovertotal2 = 0;
    let sCarryValueTotal2 = 0;
    let twillcovertotal2 = 0;
    let owillcovertotal2 = 0;
    let overallwillcover2 = 0;
    let lastMonthCoveragePercent2 = 0;
    let total_Coverage_percentage2 = 0;
    let mustCoverageTotal2 = 0;
    let mayCoverageTotal2 = 0;
    for (let i=13; i< 25; i++) {
      newArr[i]['overAllWillCover'] = isNaN(+newArr[i]['overAllWillCover']?.toString().split(',').join(''))?0:+newArr[i]['overAllWillCover']?.toString().split(',').join('');
      newArr[i]['swillCover'] = isNaN(+newArr[i]['swillCover']?.toString().split(',').join(''))?0:+newArr[i]['swillCover']?.toString().split(',').join('');
      newArr[i]['sCarryValue'] = isNaN(+newArr[i]['sCarryValue']?.toString().split(',').join(''))?0:+newArr[i]['sCarryValue']?.toString().split(',').join('');
      if (wfDetails?.isLotSizeEnabled && isBlur && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, newArr[i]?.period, newArr[i]?.planYear)) {
        newArr[i]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, +newArr[i]['twillCover']?.toString().split(',').join(''), wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit);
      } else {
        newArr[i]['twillCover'] = isNaN(+newArr[i]['twillCover']?.toString().split(',').join(''))?0:+newArr[i]['twillCover']?.toString().split(',').join('');
      }
      newArr[i]['owillCover'] = isNaN(+newArr[i]['owillCover']?.toString().split(',').join(''))?0:+newArr[i]['owillCover']?.toString().split(',').join('');

      newArr[i]['overAllCoveragePercent'] = isNaN((newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100) || (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst']) == 'Infinity' || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == '-Infinity' ? roundToTwo(2, (newArr[i]['overAllCoverage']/0.1)) : roundToTwo(2, (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100);
      total_Coverage_percentage2 += (+newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
      mustCoverageTotal2+= (+newArr[i]['mustCover']);
      mayCoverageTotal2+= (+newArr[i]['mayCover']);
      if (selectedRow.coverageMode!=='NONE' || selectedRow.others == true) {
        newArr[i]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[i]['swillCover']?.toString().split(',').join('')?.toString().split(',').join('')) + (+newArr[i]['sCarryValue']?.toString().split(',').join('')) + (+newArr[i]['twillCover']?.toString().split(',').join('')?.toString().split(',').join('')) + (+newArr[i]['owillCover']?.toString().split(',').join('')?.toString().split(',').join('')));
      }
      swillcovertotal2+= +newArr[i]['swillCover']?.toString().split(',').join('')?.toString().split(',').join('');
      sCarryValueTotal2+= +newArr[i]['sCarryValue']?.toString().split(',').join('');
      twillcovertotal2+= +newArr[i]['twillCover']?.toString().split(',').join('')?.toString().split(',').join('');
      owillcovertotal2+= +newArr[i]['owillCover']?.toString().split(',').join('')?.toString().split(',').join('');
      overallwillcover2+= +newArr[i]['overAllWillCover']?.toString().split(',').join('')?.toString().split(',').join('');
      lastMonthCoveragePercent2+= newArr[i]['coveragePercent']*newArr[i]['coverage'];
    }
    const wholedecimals2 = +total_Coverage_percentage2/newArr[25]['overAllCoverage'];
    newArr[25]['overAllCoveragePercent']= newArr[25]['updatedFcst']!==0 ? roundToTwo(2, (newArr[25]['overAllCoverage']/newArr[25]['updatedFcst'])*100) : 0;
    newArr[25]['mustCover']= roundToTwo(+wfDetails?.decimal_scale, mustCoverageTotal2.toFixed(+wfDetails?.decimal_scale));
    newArr[25]['mayCover']= roundToTwo(+wfDetails?.decimal_scale, mayCoverageTotal2.toFixed(+wfDetails?.decimal_scale));
    newArr[25]['maxCoverage'] = roundToTwo(+wfDetails?.decimal_scale, newArr[25]['maxCoverage']);
    newArr[25]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, overallwillcover2);
    newArr[25]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, swillcovertotal2);
    newArr[25]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, sCarryValueTotal2);
    newArr[25]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, twillcovertotal2);
    newArr[25]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, owillcovertotal2);
    newArr[25]['coveragePercent'] = newArr[25]['volumeLE']!==0 ? roundToTwo(2, (newArr[25]['coverage']/newArr[25]['volumeLE'])*100) : 0;

    newArr[38]['overAllCoverage'] = (+newArr[38]['overAllWillCover']) + (+newArr[38]['coverage']);

    let total_Coverage_percentage3 = 0;
    let mustCoverageTotal3 = 0;
    let mayCoverageTotal3 = 0;
    let overallwillcover3 = 0;
    let swillcovertotal3 = 0;
    let sCarryValueTotal3 = 0;

    let twillcovertotal3 = 0;
    let lastMonthCoveragePercent3 = 0;

    let owillcovertotal3 = 0;
    for (let i=26; i< 38; i++) {
      newArr[i]['overAllWillCover'] = isNaN(+newArr[i]['overAllWillCover']?.toString().split(',').join(''))?0:+newArr[i]['overAllWillCover']?.toString().split(',').join('');
      newArr[i]['swillCover'] = isNaN(+newArr[i]['swillCover']?.toString().split(',').join(''))?0:+newArr[i]['swillCover']?.toString().split(',').join('');
      newArr[i]['sCarryValue'] = isNaN(+newArr[i]['sCarryValue']?.toString().split(',').join(''))?0:+newArr[i]['sCarryValue']?.toString().split(',').join('');
      if (wfDetails?.isLotSizeEnabled && isBlur && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, newArr[i]?.period, newArr[i]?.planYear)) {
        newArr[i]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, +newArr[i]['twillCover']?.toString().split(',').join(''), wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit);
      } else {
        newArr[i]['twillCover'] = isNaN(+newArr[i]['twillCover']?.toString().split(',').join(''))?0:+newArr[i]['twillCover']?.toString().split(',').join('');
      }
      newArr[i]['owillCover'] = isNaN(+newArr[i]['owillCover']?.toString().split(',').join(''))?0:+newArr[i]['owillCover']?.toString().split(',').join('');
      newArr[i]['overAllCoveragePercent'] = isNaN((newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100) || (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst']) == 'Infinity' || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == '-Infinity' ? roundToTwo(2, (newArr[i]['overAllCoverage']/0.1)) : roundToTwo(2, (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100);
      total_Coverage_percentage3 += (+newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
      mustCoverageTotal3+= (+newArr[i]['mustCover']);
      mayCoverageTotal3+= (+newArr[i]['mayCover']);
      if (selectedRow.coverageMode!=='NONE' || selectedRow.others == true) {
        newArr[i]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[i]['swillCover']?.toString().split(',').join('')) + (+newArr[i]['twillCover']?.toString().split(',').join('')) + (+newArr[i]['sCarryValue']?.toString().split(',').join('')) + (+newArr[i]['owillCover']?.toString().split(',').join('')));
      }
      swillcovertotal3+= +newArr[i]['swillCover']?.toString().split(',').join('');
      sCarryValueTotal3+= +newArr[i]['sCarryValue']?.toString().split(',').join('');

      twillcovertotal3+= +newArr[i]['twillCover']?.toString().split(',').join('');
      owillcovertotal3+= +newArr[i]['owillCover']?.toString().split(',').join('');

      overallwillcover3+= +newArr[i]['overAllWillCover']?.toString().split(',').join('');
      lastMonthCoveragePercent3+= newArr[i]['coveragePercent']*newArr[i]['coverage'];
    }
    const wholedecimals3 = +total_Coverage_percentage3/newArr[38]['overAllCoverage'];
    // console.log(wholedecimals3.toFixed(+wfDetails?.decimal_scale));
    newArr[38]['overAllCoveragePercent']= newArr[38]['updatedFcst']!==0 ? roundToTwo(2, (newArr[38]['overAllCoverage']/newArr[38]['updatedFcst'])*100) : 0;
    newArr[38]['mustCover']= roundToTwo(+wfDetails?.decimal_scale, mustCoverageTotal3.toFixed(+wfDetails?.decimal_scale));
    newArr[38]['mayCover']= roundToTwo(+wfDetails?.decimal_scale, mayCoverageTotal3.toFixed(+wfDetails?.decimal_scale));
    newArr[38]['maxCoverage'] = roundToTwo(+wfDetails?.decimal_scale, newArr[38]['maxCoverage']);
    newArr[38]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, overallwillcover3);
    newArr[38]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, swillcovertotal3);

    newArr[38]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, sCarryValueTotal3);
    newArr[38]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, twillcovertotal3);
    newArr[38]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, owillcovertotal3);
    newArr[38]['coveragePercent'] = newArr[38]['volumeLE']!==0 ? roundToTwo(2, (newArr[38]['coverage']/newArr[38]['volumeLE'])*100) : 0;


    // Extra 0's issue
    newArr[38]['newCoveragePercent'] = '';
    newArr[38]['targetCoveragePercentMin'] = '';
    newArr[38]['targetCoveragePercentMax'] = '';
    newArr[38]['targetVariancePercent'] = '';
    // QUARTERS COVERAGE CALCULATING DYNAMICALLY
    // ['Q1', 'Q2', 'Q3'] are the 3 years representation
    // console.log(quarters);


    // USE HERE TO ROUND ANY COLUMN
    for (let i=0; i<39; i++) {
      // newArr[i]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[i]['swillCover']) + (+newArr[i]['twillCover']) + (+newArr[i]['owillCover']));
      newArr[i]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[i]['overAllWillCover']) + (+newArr[i]['coverage']));
      newArr[i]['volumeLE'] = roundToTwo(2, +newArr[i]['volumeLE']);
      newArr[i]['coverage'] = roundToTwo(+wfDetails?.decimal_scale, +newArr[i]['coverage']);
      newArr[i]['updatedFcst'] = roundToTwo(2, +newArr[i]['updatedFcst']);
      newArr[i]['sLastMonthCoverage'] = roundToTwo(+wfDetails?.decimal_scale, +newArr[i]['sLastMonthCoverage']);

      newArr[i]['tLastMonthCoverage'] = roundToTwo(+wfDetails?.decimal_scale, +newArr[i]['tLastMonthCoverage']);
      // newArr[i]['oLastMonthCoverage'] = newArr[i]['coverage']-(newArr[i]['sLastMonthCoverage']+newArr[i]['tLastMonthCoverage']);
      // if (i!==12 && i!==25 && i!==39) {
      //   newArr[i]['overAllCoveragePercent'] = isNaN((newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100) || (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst']) == 'Infinity' || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == '-Infinity' ? roundToTwo(+wfDetails?.decimal_scale, (newArr[i]['overAllCoverage']/0.1)) : roundToTwo(+wfDetails?.decimal_scale, (newArr[i]['overAllCoverage']/newArr[i]['updatedFcst'])*100);
      // }
    }
    let grandTotalObj = {
      market: '',
      period: '',
      planYear: '',
      volumeLE: 0,
      coverage: 0,
      sLastMonthCoverage: 0,
      tLastMonthCoverage: 0,
      oLastMonthCoverage: 0,
      coveragePercent: 0,
      updatedFcst: 0,
      targetPercentMin: 0,
      targetPercentMax: 0,
      minCoverage: 0,
      maxCoverage: 0,
      tminCoverage: 0,
      tmaxCoverage: 0,
      sminCoverage: 0,
      smaxCoverage: 0,
      mayCover: 0,
      mustCover: 0,
      smustCover: 0,
      smayCover: 0,
      swillCover: 0,
      sCarryValue: 0,
      stargetPercentMin: 0,
      stargetPercentMax: 0,
      tmustCover: 0,
      tmayCover: 0,
      twillCover: 0,
      owillCover: 0,
      ttargetPercentMin: 0,
      ttargetPercentMax: 0,
      overAllWillCover: 0,
      overAllCoverage: 0,
      overAllCoveragePercent: 0,
      newCoveragePercent: 0,
      comments: 0,
      targetCoveragePercent: 0,
    };
    // Added new row
    for (let i=0; i<39; i++) {
      if (i !== 12 && i !== 25 && i !== 38) {
        if (i!==newArr.length-1) {
          grandTotalObj = {
            period: '',
            volumeLE: roundToTwo(2, +grandTotalObj.volumeLE+(+newArr[i].volumeLE||0)),
            coverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.coverage+(+newArr[i].coverage||0)),
            sLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.sLastMonthCoverage+(+newArr[i].sLastMonthCoverage || 0)),
            tLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.tLastMonthCoverage+(+newArr[i].tLastMonthCoverage || 0)),
            oLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.oLastMonthCoverage+(+newArr[i].oLastMonthCoverage || 0)),
            // coveragePercent: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.coveragePercent+(+newArr[i].coveragePercent || 0)),
            updatedFcst: roundToTwo(2, +grandTotalObj.updatedFcst+(+newArr[i].updatedFcst || 0)),
            targetPercentMin: roundToTwo(2, +grandTotalObj.targetPercentMin+(+newArr[i].targetPercentMin*+newArr[i].updatedFcst)),
            targetPercentMax: roundToTwo(2, +grandTotalObj.targetPercentMax+(+newArr[i].targetPercentMax*+newArr[i].updatedFcst)),
            minCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.minCoverage+(+newArr[i].minCoverage || 0)),
            maxCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.maxCoverage+(+newArr[i].maxCoverage || 0)),
            tminCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.tminCoverage+(+newArr[i].tminCoverage || 0)),
            tmaxCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.tmaxCoverage+(+newArr[i].tmaxCoverage || 0)),
            sminCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.sminCoverage+(+newArr[i].sminCoverage || 0)),
            smaxCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.smaxCoverage+(+newArr[i].smaxCoverage || 0)),
            mayCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.mayCover+(+newArr[i].mayCover || 0)),
            mustCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.mustCover+(+newArr[i].mustCover || 0)),
            smustCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.smustCover+(+newArr[i].smustCover || 0)),
            smayCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.smayCover+(+newArr[i].smayCover || 0)),
            swillCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.swillCover+(+newArr[i].swillCover || 0)),
            sCarryValue: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.sCarryValue+(+newArr[i].sCarryValue || 0)),
            stargetPercentMin: roundToTwo(2, +grandTotalObj.stargetPercentMin+(+newArr[i].stargetPercentMin*+newArr[i].updatedFcst)),
            stargetPercentMax: roundToTwo(2, +grandTotalObj.stargetPercentMax+(+newArr[i].stargetPercentMax*+newArr[i].updatedFcst)),
            tmustCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.tmustCover+(+newArr[i].tmustCover || 0)),
            tmayCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.tmayCover+(+newArr[i].tmayCover || 0)),
            twillCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.twillCover+(+newArr[i].twillCover || 0)),
            owillCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.owillCover+(+newArr[i].owillCover || 0)),
            ttargetPercentMin: roundToTwo(2, +grandTotalObj.ttargetPercentMin+(+newArr[i].ttargetPercentMin*+newArr[i].updatedFcst)),
            ttargetPercentMax: roundToTwo(2, +grandTotalObj.ttargetPercentMax+(+newArr[i].ttargetPercentMax*+newArr[i].updatedFcst)),
            overAllWillCover: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.overAllWillCover+(+newArr[i].overAllWillCover || 0)),
            overAllCoverage: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.overAllCoverage+(+newArr[i].overAllCoverage || 0)),
            // overAllCoveragePercent: roundToTwo(+wfDetails?.decimal_scale, +grandTotalObj.ttargetPercentMin+(+newArr[i].ttargetPercentMin*+newArr[i].updatedFcst)),
          };
        }
      }
    }

    // newArr[38]['volumeLE']!==0 ? roundToTwo(+wfDetails?.decimal_scale, (newArr[38]['coverage']/newArr[38]['volumeLE'])*100) : 0;
    newArr[39] = {...grandTotalObj,
      coveragePercent: grandTotalObj['volumeLE']!==0 ? roundToTwo(2, (grandTotalObj['coverage']/grandTotalObj['volumeLE'])*100) : 0,
      targetPercentMin: grandTotalObj['updatedFcst']!==0 ? roundToTwo(2, (grandTotalObj['targetPercentMin']/grandTotalObj['updatedFcst'])) : 0,
      targetPercentMax: grandTotalObj['updatedFcst']!==0 ? roundToTwo(2, (grandTotalObj['targetPercentMax']/grandTotalObj['updatedFcst'])) : 0,
      stargetPercentMin: grandTotalObj['updatedFcst']!==0 ? roundToTwo(2, (grandTotalObj['stargetPercentMin']/grandTotalObj['updatedFcst'])) : 0,
      stargetPercentMax: grandTotalObj['updatedFcst']!==0 ? roundToTwo(2, (grandTotalObj['stargetPercentMax']/grandTotalObj['updatedFcst'])) : 0,
      ttargetPercentMin: grandTotalObj['updatedFcst']!==0 ? roundToTwo(2, (grandTotalObj['ttargetPercentMin']/grandTotalObj['updatedFcst'])) : 0,
      ttargetPercentMax: grandTotalObj['updatedFcst']!==0 ? roundToTwo(2, (grandTotalObj['ttargetPercentMax']/grandTotalObj['updatedFcst'])) : 0,
      overAllCoveragePercent: grandTotalObj['updatedFcst']!==0 ? roundToTwo(2, (grandTotalObj['overAllCoverage']/grandTotalObj['updatedFcst'])*100) : 0,
    };
  };

  const varianceSetter = (selectedQuarter, newArr, rowIndexTracker, roundeddiffernce) => {
    selectedQuarter.forEach((val, i) => {
      newArr[selectedQuarter?.length+rowIndexTracker-(i+1)]['tVariance'] = roundeddiffernce;
    });
    return newArr;
  };
  //  Quarter Calculation
  const quarterWiseCalculation = (newArr, isBlur=true) => {
    // console.log(selectedRow.optionality);
    overAllCoverageCalculation(newArr, isBlur);
    let rowIndexTracker = 0;
    ['Year1', 'Year2', 'Year3'].map((qr)=>{
      quarters!==undefined && quarters !== null && ['Q1', 'Q2', 'Q3', 'Q4'].map((quart, index) => {
        const selectedQuarter = quarters[quart]?.split(',');
        // used for adding all quaterly coverages
        let quaterlyCoverageTracker = 0;
        let quaterlyTargetMinTracker = 0;
        let quaterlyTargetMaxTracker = 0;

        // used for adding all  coverages
        let coverageTotal = 0;
        let updatedForecastTotal = 0;

        // Used to update rowIndexTracker when it reaches FYTotal rows
        if (rowIndexTracker == 12 || rowIndexTracker == 25 || rowIndexTracker == 38) {
          newArr[rowIndexTracker]['newCoveragePercent'] = '';
          newArr[rowIndexTracker]['targetCoveragePercentMin'] = '';
          newArr[rowIndexTracker]['targetCoveragePercentMax'] = '';
          newArr[rowIndexTracker]['targetVariancePercent'] = '';
          newArr[rowIndexTracker]['coveragePercent'] = isNaN(newArr[rowIndexTracker]['coveragePercent'])?0:roundToTwo(2, newArr[rowIndexTracker]['coveragePercent']);
          newArr[rowIndexTracker]['targetPercentMin'] = isNaN(newArr[rowIndexTracker]['targetPercentMin'])?0:roundToTwo(2, newArr[rowIndexTracker]['targetPercentMin']);
          newArr[rowIndexTracker]['targetPercentMax'] = isNaN(newArr[rowIndexTracker]['targetPercentMax'])?0:roundToTwo(2, newArr[rowIndexTracker]['targetPercentMax']);
          rowIndexTracker+=1;
        }

        // console.log('rowIndexTracker', rowIndexTracker, 'quaterMonts', selectedQuarter?.length);

        for (let i=rowIndexTracker; i< selectedQuarter?.length + rowIndexTracker; i++) {
          // console.log(+newArr[i]['overAllCoveragePercent'], +newArr[i]['overAllCoverage'], +newArr[i]['overAllCoveragePercent'] * +newArr[i]['overAllCoverage']);
          // console.log(+newArr[i]['targetPercentMin'], +newArr[i]['updatedFcst'], +newArr[i]['targetPercentMin'] * +newArr[i]['updatedFcst']);

          quaterlyCoverageTracker+= (+newArr[i]['overAllCoveragePercent'] * +newArr[i]['overAllCoverage']);
          quaterlyTargetMinTracker+= (+newArr[i]['targetPercentMin'] * +newArr[i]['updatedFcst']);
          quaterlyTargetMaxTracker+= (+newArr[i]['targetPercentMax'] * +newArr[i]['updatedFcst']);


          // Updating weights
          coverageTotal+= (+newArr[i]['overAllCoverage']);
          updatedForecastTotal+= (+newArr[i]['updatedFcst']);

          newArr[i]['newCoveragePercent'] = '';
          newArr[i]['targetCoveragePercentMin'] = '';
          newArr[i]['targetCoveragePercentMax'] = '';
          newArr[i]['targetVariancePercent'] = '';
        }
        // console.log('quaterlyCoverageTracker', quaterlyCoverageTracker, 'coverage', coverageTotal);
        // const wholeQuaterlyNewCoveragedecimals = +quaterlyCoverageTracker/+coverageTotal;
        const wholeQuaterlyNewCoveragedecimals = (+coverageTotal/+updatedForecastTotal)*100;
        const wholeQuaterlyTargetMinCoveragedecimals = +quaterlyTargetMinTracker/+updatedForecastTotal;
        const wholeQuaterlyTargetMaxCoveragedecimals = +quaterlyTargetMaxTracker/+updatedForecastTotal;
        // console.log(wholeQuaterlyNewCoveragedecimals);
        newArr[selectedQuarter?.length+rowIndexTracker-1]['newCoveragePercent'] = (isNaN(wholeQuaterlyNewCoveragedecimals.toFixed(2)) || wholeQuaterlyNewCoveragedecimals=='Infinity' || wholeQuaterlyNewCoveragedecimals=='-Infinity') ? roundToTwo(2, +coverageTotal/0.1) : (wholeQuaterlyNewCoveragedecimals.toFixed(2) == '0.00' ? 0 :roundToTwo(2, wholeQuaterlyNewCoveragedecimals));
        newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMin'] = isNaN(wholeQuaterlyTargetMinCoveragedecimals.toFixed(2)) ? 0 : (wholeQuaterlyTargetMinCoveragedecimals.toFixed(2) == '0.00' ? 0 :wholeQuaterlyTargetMinCoveragedecimals.toFixed(2));
        newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMax'] = isNaN(wholeQuaterlyTargetMaxCoveragedecimals.toFixed(2)) ? 0 : (wholeQuaterlyTargetMaxCoveragedecimals.toFixed(2) == '0.00' ? 0 :wholeQuaterlyTargetMaxCoveragedecimals.toFixed(2));

        // CALCULATION QUARTERLY VARIANCE
        if (selectedRow.optionality === true) {
          if (+newArr[selectedQuarter?.length+rowIndexTracker-1]['newCoveragePercent'] < +newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMin']) {
            const difference = +newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMin'] - (+ newArr[selectedQuarter?.length+rowIndexTracker-1]['newCoveragePercent']);
            const roundeddiffernce = roundToTwo(2, difference);
            // console.log(+newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMin'], (+ newArr[selectedQuarter?.length+rowIndexTracker-1]['newCoveragePercent']), roundeddiffernce);
            newArr[selectedQuarter?.length+rowIndexTracker-1]['targetVariancePercent'] = roundeddiffernce;
            newArr = varianceSetter(selectedQuarter, newArr, rowIndexTracker, roundeddiffernce);
            // newArr[selectedQuarter?.length+rowIndexTracker-3]['tVariance'] = roundeddiffernce;
            // newArr[selectedQuarter?.length+rowIndexTracker-2]['tVariance'] = roundeddiffernce;
            // newArr[selectedQuarter?.length+rowIndexTracker-1]['tVariance'] = roundeddiffernce;
          } else if (+newArr[selectedQuarter?.length+rowIndexTracker-1]['newCoveragePercent'] > +newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMax']) {
            const difference = +newArr[selectedQuarter?.length+rowIndexTracker-1]['newCoveragePercent'] - (+ newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMax']);
            const roundeddiffernce = roundToTwo(2, difference);
            newArr[selectedQuarter?.length+rowIndexTracker-1]['targetVariancePercent'] = roundeddiffernce;
            newArr = varianceSetter(selectedQuarter, newArr, rowIndexTracker, roundeddiffernce);
            // newArr[selectedQuarter?.length+rowIndexTracker-3]['tVariance'] = roundeddiffernce;
            // newArr[selectedQuarter?.length+rowIndexTracker-2]['tVariance'] = roundeddiffernce;
            // newArr[selectedQuarter?.length+rowIndexTracker-1]['tVariance'] = roundeddiffernce;
          } else {
            newArr[selectedQuarter?.length+rowIndexTracker-1]['targetVariancePercent'] = 0;
            newArr = varianceSetter(selectedQuarter, newArr, rowIndexTracker, 0);
            // newArr[selectedQuarter?.length+rowIndexTracker-3]['tVariance'] = 0;
            // newArr[selectedQuarter?.length+rowIndexTracker-2]['tVariance'] = 0;
            // newArr[selectedQuarter?.length+rowIndexTracker-1]['tVariance'] = 0;
          }
        } else {
          const difference = +newArr[selectedQuarter?.length+rowIndexTracker-1]['newCoveragePercent'] - (+ newArr[selectedQuarter?.length+rowIndexTracker-1]['targetCoveragePercentMin']);
          const roundeddiffernce = roundToTwo(2, difference);
          newArr[selectedQuarter?.length+rowIndexTracker-1]['targetVariancePercent'] = roundeddiffernce;
          newArr = varianceSetter(selectedQuarter, newArr, rowIndexTracker, roundeddiffernce);
          // newArr[selectedQuarter?.length+rowIndexTracker-3]['tVariance'] = roundeddiffernce;
          // newArr[selectedQuarter?.length+rowIndexTracker-2]['tVariance'] = roundeddiffernce;
          // newArr[selectedQuarter?.length+rowIndexTracker-1]['tVariance'] = roundeddiffernce;
        }
        // UPDATING THE ROWINDEX WHICH TELLS US TO CALCULATE THE NEXT INDEX TO NEXT QUARTER
        rowIndexTracker+=(selectedQuarter?.length);
      });
    });
  };


  // FOR TREASURY ONLY PRE-POPULATING WILL COVER BASED ON MUST COVER
  const treasuryPrepopulatingFromMustCover = (newArr) =>{
    // console.log(selectedRow);
    for (let i=0; i<39; i++) {
      newArr[i]['twillCover'] = selectedRow.treasuryOptionality===false ? +newArr[i]['mustCover'] : +newArr[i]['tmustCover'];
      newArr[i]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[i]['swillCover']) + (+newArr[i]['twillCover']) + (+newArr[i]['owillCover']));
      newArr[i]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[i]['overAllWillCover']) + (+newArr[i]['coverage']));
    }
    overAllCoverageCalculation(newArr);
    quarterWiseCalculation(newArr);
  };


  const dispatch = useDispatch();
  const editFlag = useSelector((state) => state.editStatus.value);
  const isRecall = useSelector((state) => state.isRecallStatus.value);

  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const userid=useSelector((state)=>state?.roleBasedAccess?.userId);

  useEffect(()=>{
    // console.log(selectedRow);
    if (functionalityAccess && functionalityAccess['Buy_Plan:Buy_Plan_Creation']) {
      setAccessControlBuyPlan(functionalityAccess['Buy_Plan:Buy_Plan_Creation']);
    }
    if (functionalityAccess && functionalityAccess['Buy_Plan:Atlas_Outbound_Download']) {
      setdownloadOutBoundFileAccess(true);
    }
    if (functionalityAccess && functionalityAccess['Buy_Plan:Buy_Plan_Comments']) {
      setAccessControlComment(functionalityAccess['Buy_Plan:Buy_Plan_Comments']);
    }
    if (functionalityAccess && functionalityAccess['Buy_Plan:Buy_Plan_Review']) {
      setAccessControlReview(functionalityAccess['Buy_Plan:Buy_Plan_Review']);
    }
    if (functionalityAccess && functionalityAccess['Buy_Plan:Recall_After_Atlas_Submit']) {
      setEnableRecall(true);
    } else {
      setEnableRecall(false);
    }
  }, [functionalityAccess]);

  const handleGroups = (action, key) => {
    switch (key) {
      case 'second2': {
        handlePlusMinus('second2', 'second', true);
        break;
      }
      case 'third2': {
        handlePlusMinus('third2', 'third', true);
        break;
      }
      case 'fourthS': {
        handlePlusMinus('fourthS', 'fourth', true);
        break;
      }
      case 'fourth2T': {
        handlePlusMinus('fourth2T', 'fourth2', true);
        break;
      }
      case 'fifth2': {
        handlePlusMinus('fifth2', 'fifth', true);
        break;
      }
      case 'second': {
        handlePlusMinus('second', 'second2', true);
        break;
      }
      case 'third': {
        handlePlusMinus('third', 'third2', true);
        break;
      }
      case 'fourth': {
        handlePlusMinus('fourth', 'fourthS', true);
        break;
      }
      case 'fourth2': {
        handlePlusMinus('fourth2', 'fourth2T', true);
        break;
      }
      case 'fifth': {
        handlePlusMinus('fifth', 'fifth2', true);
        break;
      }
      case 'fourPointFive': {
        handlePlusMinus('fourPointFive', 'fourPointFive2', true);
        break;
      }
      case 'fourPointFive2': {
        handlePlusMinus('fourPointFive2', 'fourPointFive', true);
        break;
      }
      default:
        console.log('');
    }
  };

  const handleEdit = (key, year, index, val, arr, old, isBlur) => {
    if (arr && arr.length) {
      const newArr = JSON.parse(JSON.stringify(arr));
      newArr[index][key] = val;
      if (key === 'swillCover' || key=='sCarryValue' || key === 'twillCover' || key === 'owillCover' || key === 'overAllWillCover') {
        const difference = +val - (old || 0);
        if (index < 12) {
          newArr[12][key] = newArr[12][key] + difference;
          newArr[12]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[12]['swillCover']);

          newArr[12]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, newArr[12]['sCarryValue']);
          let swillcovertotal1 = 0;
          let sCarryValueTotal1 = 0;

          let twillcovertotal1 = 0;
          let owillcovertotal1 = 0;
          let overallwillcover1 = 0;
          newArr[12]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[12]['twillCover']);
          newArr[12]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[12]['owillCover']);
          // overAllCoveragePercent: val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE == 0 || val.FORECAST == 0 ? '' : (((parseFloat(val.TREASURY_COVERAGE || 0) + parseFloat(val.SUPPLIER_COVERAGE || 0) + parseFloat(val.LAST_MONTH_COVERAGE || 0))/parseFloat(val.FORECAST || 0))*100).toFixed(+wfDetails?.decimal_scale) || 0,
          let total_Coverage_percentage = 0;
          for (let i=0; i< 12; i++) {
            // console.log((+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst'])*100);
            newArr[i]['overAllCoveragePercent'] = (isNaN((+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst'])*100) || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == 'Infinity' || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == '-Infinity') ? 0 : roundToTwo(2, (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst'])*100);
            swillcovertotal1+= +newArr[i]['swillCover'];
            sCarryValueTotal1+= +newArr[i]['sCarryValue'];

            twillcovertotal1+= +newArr[i]['twillCover'];
            owillcovertotal1+= +newArr[i]['owillCover'];
            overallwillcover1+= +newArr[i]['overAllWillCover'];
            // console.log(newArr[i]['overAllCoverage'], newArr[i]['overAllCoveragePercent'], +newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
            total_Coverage_percentage += (+newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
          }
          // console.log(overallwillcover1);
          newArr[12]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, swillcovertotal1);

          newArr[12]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, sCarryValueTotal1);
          newArr[12]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, twillcovertotal1);
          newArr[12]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, owillcovertotal1);
          newArr[12]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, overallwillcover1);
          // newArr[12]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[12]['swillCover']) + (+newArr[12]['twillCover']) + (+newArr[12]['owillCover']));
          newArr[12]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[12]['overAllWillCover']) + (+newArr[12]['coverage']));
          const wholedecimals = +total_Coverage_percentage/+newArr[12]['overAllCoverage'];
          // console.log(wholedecimals.toFixed(+wfDetails?.decimal_scale));
          newArr[12]['overAllCoveragePercent']= roundToTwo(2, wholedecimals.toFixed(+wfDetails?.decimal_scale));
        }
        if (index > 12 && index < 25) {
          newArr[25][key] = newArr[25][key] + difference;
          newArr[25]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[25]['swillCover']);

          newArr[25]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, newArr[25]['sCarryValue']);
          newArr[25]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[25]['twillCover']);
          newArr[25]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[25]['owillCover']);

          newArr[25]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[25]['overAllWillCover']);
          // newArr[25]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[25]['swillCover']) + (+newArr[25]['twillCover']) + (+newArr[25]['owillCover']));
          newArr[25]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[25]['overAllWillCover']) + (+newArr[25]['coverage']));
          let total_Coverage_percentage = 0;
          let swillcovertotal2 = 0;
          let sCarryValueTotal2 = 0;

          let twillcovertotal2 = 0;
          let owillcovertotal2 = 0;

          let overallwillcover2 = 0;
          for (let i=13; i< 25; i++) {
            newArr[i]['overAllCoveragePercent'] = isNaN((+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst'])*100) || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == 'Infinity' || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == '-Infinity' ? 0 : roundToTwo(2, (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst'])*100);

            swillcovertotal2+= +newArr[i]['swillCover'];
            sCarryValueTotal2+= +newArr[i]['sCarryValue'];

            twillcovertotal2+= +newArr[i]['twillCover'];
            owillcovertotal2+= +newArr[i]['owillCover'];
            overallwillcover2+= +newArr[i]['overAllWillCover'];

            // console.log(newArr[i]['overAllCoverage'], newArr[i]['overAllCoveragePercent'], +newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
            total_Coverage_percentage += (+newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
          }
          newArr[25]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, swillcovertotal2);

          newArr[25]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, sCarryValueTotal2);
          newArr[25]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, twillcovertotal2);
          newArr[25]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, owillcovertotal2);
          newArr[25]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, overallwillcover2);

          // newArr[25]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[25]['swillCover']) + (+newArr[25]['twillCover']) + (+newArr[25]['owillCover']));
          newArr[25]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[25]['overAllWillCover']) + (+newArr[25]['coverage']));

          const wholedecimals = +total_Coverage_percentage/newArr[25]['overAllCoverage'];
          // console.log(wholedecimals.toFixed(+wfDetails?.decimal_scale));
          newArr[25]['overAllCoveragePercent']= roundToTwo(2, wholedecimals.toFixed(2));
        }
        if (index > 25) {
          newArr[38][key] = newArr[38][key] + difference;
          newArr[38]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[38]['swillCover']);

          newArr[38]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, newArr[38]['sCarryValue']);
          newArr[38]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[38]['twillCover']);
          newArr[38]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[38]['owillCover']);
          newArr[25]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, newArr[25]['overAllWillCover']);

          // newArr[38]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[38]['swillCover']) + (+newArr[38]['twillCover']) + (+newArr[38]['owillCover']));
          newArr[38]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[38]['overAllWillCover']) + (+newArr[38]['coverage']));
          let total_Coverage_percentage = 0;
          let swillcovertotal3 = 0;
          let sCarryValueTotal3 = 0;

          let twillcovertotal3 = 0;
          let owillcovertotal3 = 0;

          let overallwillcover3 = 0;
          for (let i=26; i< 38; i++) {
            newArr[i]['overAllCoveragePercent'] = isNaN((+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst'])*100) || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == 'Infinity' || (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst']) == '-Infinity' ? 0 : roundToTwo(2, (+newArr[i]['overAllCoverage']/+newArr[i]['updatedFcst'])*100);
            swillcovertotal3+= +newArr[i]['swillCover'];
            sCarryValueTotal3+= +newArr[i]['sCarryValue'];

            twillcovertotal3+= +newArr[i]['twillCover'];
            owillcovertotal3+= +newArr[i]['owillCover'];
            overallwillcover3+= +newArr[i]['overAllWillCover'];

            // console.log(newArr[i]['overAllCoverage'], newArr[i]['overAllCoveragePercent'], +newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
            total_Coverage_percentage += (+newArr[i]['overAllCoverage'] * +newArr[i]['overAllCoveragePercent']);
          }
          newArr[38]['swillCover'] = roundToTwo(+wfDetails?.decimal_scale, swillcovertotal3);

          newArr[38]['sCarryValue'] = roundToTwo(+wfDetails?.decimal_scale, sCarryValueTotal3);
          newArr[38]['twillCover'] = roundToTwo(+wfDetails?.decimal_scale, twillcovertotal3);
          newArr[38]['owillCover'] = roundToTwo(+wfDetails?.decimal_scale, owillcovertotal3);
          newArr[38]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, overallwillcover3);

          // newArr[38]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[38]['swillCover']) + (+newArr[38]['twillCover']) + (+newArr[38]['owillCover']));
          newArr[38]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[38]['overAllWillCover']) + (+newArr[38]['coverage']));
          const wholedecimals = +total_Coverage_percentage/newArr[38]['overAllCoverage'];
          // console.log(wholedecimals.toFixed(+wfDetails?.decimal_scale));
          newArr[38]['overAllCoveragePercent']= roundToTwo(2, wholedecimals.toFixed(2));
        }
        newArr[index]['overAllCoveragePercent'] = roundToTwo(2, newArr[index]['twillCover'] + newArr[index]['owillCover'] + newArr[index]['swillCover'] + newArr[index]['coverage'] == 0 || newArr[index]['updatedFcst'] == 0 ? 0 : (((parseFloat(newArr[index]['twillCover'] || 0) + parseFloat(newArr[index]['swillCover'] || 0) + parseFloat(newArr[index]['owillCover'] || 0) + parseFloat(newArr[index]['coverage'] || 0))/parseFloat(newArr[index]['updatedFcst'] || 0))*100)).toFixed(2) || 0;
        if (selectedRow.coverageMode!=='NONE' || selectedRow.others == true) {
          newArr[index]['overAllWillCover'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[index]['swillCover']) + (+newArr[index]['twillCover']) + (+newArr[index]['owillCover']));
        }
        newArr[index]['overAllCoverage'] = roundToTwo(+wfDetails?.decimal_scale, (+newArr[index]['overAllWillCover']) + (+newArr[index]['coverage']));
      }

      // Quater Calculation
      if ((key === 'swillCover' || key === 'sCarryValue' || key === 'twillCover' || key === 'owillCover' || key === 'overAllWillCover')) {
        overAllCoverageCalculation(newArr, isBlur);
        quarterWiseCalculation(newArr, isBlur);
      }
      // console.log(index, key, val, newArr[index][key], newArr);
      dispatch(buyPlanData(newArr));
    }
  };

  const quartersData = useSelector((state) => state.commonState.quaters);
  const [groups, setGroups] = useState(columnsUtil(isPreviousYearData, execMonthDecider, handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, previousMonthWillCoverEditButton));
  const accumulator = (sArr) => {
    let accumulatedData = [];
    const arr = [...new Set(sArr)];
    arr.forEach((val) => {
      accumulatedData = [...accumulatedData, ...groups[val]];
    });
    setColumns(accumulatedData);
    setFlag((prev) => !prev);
  };

  useEffect(() => {
    setGroups(columnsUtil(isPreviousYearData, execMonthDecider, handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, previousMonthWillCoverEditButton));
  }, [editFlag, isRecall, previousMonthWillCoverEditButton]);

  useEffect(() => {
    // console.log('@buyplantab initialArr', initialArr);
    if (initialArr.length) {
      accumulator(initialArr);
    }
  }, [initialArr, previousMonthWillCoverEditButton]);

  useEffect(() => {
    if (selectedArr.length > 1) {
      accumulator(selectedArr);
    }
  }, [selectedArr, groups]);

  const handlePlusMinus = (removalKey, addingKey, flag = false) => {
    let genArr = [];
    genArr = allColumns.filter((val) => columnsStatus[val]);
    let newArr = genArr.filter((val) => val !== removalKey);
    const index = genArr.indexOf(flag ? removalKey : addingKey);
    newArr = [...newArr.slice(0, index), addingKey, ...newArr.slice(index)];
    changeStatus(newArr);
    setSelectedArr([...newArr]);
  };

  const [buyplanDetailsTrigger, setBuplanDetailsTrigger] = useState(false);
  useEffect(() => {
    if (selectedRow) {
      getBuyPlanDetails();
      if (selectedRow.status != 'NEW' && selectedRow.status != 'DRAFT') {
        dispatch(isRecallStatus(false));
      }
    }
  }, [selectedRow, buyplanDetailsTrigger]);

  const [submitButtonVisibilty, setSubmitButtonVisibility] = useState(false);
  const getBuyPlanDetails = () => {
    const mon = selectedRow.executionMonthYear[1];
    const exeMY = selectedRow.executionMonthYear[0] + '-' + (mon.toString().length > 1 ? mon : '0' + mon);
    const url = `workFlowId=${selectedRow.workFlowId}&prevWorkFlowId=${selectedRow.prevWorkFlowId}&wfStatus=${selectedRow.status}&buyPlanMasterId=${selectedRow.buyPlanId}&exeMonthYear=${exeMY}`;
    // const url = `workFlowId=${se}&prevWorkFlowId=1887&wfStatus=${}&buyPlanMasterId=10&exeMonthYear=2023-03`;
    DialogService.getBuyPlanDetails(url)
        .then((res) => {
          // console.log(res);
          const {targets, totalCoverages, buyPlanDetails, plannedCoverages} = res;
          setTarget(targets);
          if (buyPlanDetails?.length) {
            dispatch(editStatus(true));
            setBuyPlanDetails(buyPlanDetails);
            setSubmitButtonVisibility(true);
          } else {
            totalCoverages?.length && setCoverages(totalCoverages);
            plannedCoverages?.length && setPlannedCoverages(plannedCoverages);
            setSubmitButtonVisibility(false);
          }
        })
        .catch((err) => console.log(err));
  };

  const buyPlanEvaluator = () => {
    // const quarterMonths = ['Mar', 'Jun', 'Sep', 'Dec'];
    let obj = {
      totalUpdatedForecast: 0,
      totalOverAllCoverage: 0,
      targetCoveragePercentMin: 0,
      targetCoveragePercentMax: 0,
    };
    let objTotal = {
      market: '',
      period: '',
      planYear: '',
      volumeLE: 0,
      coverage: 0,
      sLastMonthCoverage: 0,
      tLastMonthCoverage: 0,
      oLastMonthCoverage: 0,
      coveragePercent: 0,
      updatedFcst: 0,
      targetPercentMin: 0,
      targetPercentMax: 0,
      minCoverage: 0,
      maxCoverage: 0,
      tminCoverage: 0,
      tmaxCoverage: 0,
      sminCoverage: 0,
      smaxCoverage: 0,
      mayCover: 0,
      mustCover: 0,
      smustCover: 0,
      smayCover: 0,
      swillCover: 0,
      stargetPercentMin: 0,
      stargetPercentMax: 0,
      tmustCover: 0,
      tmayCover: 0,
      twillCover: 0,
      owillCover: 0,
      ttargetPercentMin: 0,
      ttargetPercentMax: 0,
      overAllWillCover: 0,
      overAllCoverage: 0,
      overAllCoveragePercent: 0,
      newCoveragePercent: 0,
      comments: 0,
      targetCoveragePercent: 0,
      twillCoverNegitiveOption: null,
      owillCoverNegitiveOption: null,
      swillCoverNegitiveOption: null,
      overAllWillCoverNegitiveOption: null,
      sCarryValue: 0,
    };
    const bpDetails = [];
    let totalOverallCoveragePercentage = 0;
    buyPlanDetails.forEach((val, i) => {
      const my = val.COVERAGE_YEARMONTH.split('-');
      // const my = [...years];
      // For quarters //
      // let total_Coverage_percentage_year1 = 0;
      // for (let i=0; i< 12; i++) {
      //   total_Coverage_percentage_year1 += (+val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE * +newArr[i]['overAllCoveragePercent']);
      // }
      // const wholedecimals = +total_Coverage_percentage_year1/newArr[12]['overAllCoverage'];
      // console.log(wholedecimals.toFixed(+wfDetails?.decimal_scale));

      if (quarterMonths.indexOf(my[0]) > -1) {
        const weightedAverageMin = val.FORECAST * val.MIN_TARGET_PERC;
        const weightedAverageMax = val.FORECAST * val.MAX_TARGET_PERC;
        obj = {
          totalUpdatedForecast: obj.totalUpdatedForecast+val.FORECAST,
          totalOverAllCoverage: +obj.totalOverAllCoverage+(+val.TREASURY_COVERAGE + +val.SUPPLIER_COVERAGE + +val.LAST_MONTH_COVERAGE),
          targetCoveragePercentMin: obj.targetCoveragePercentMin + weightedAverageMin,
          targetCoveragePercentMax: obj.targetCoveragePercentMax + weightedAverageMax,
        };
        bpDetails.push({
          market: '',
          period: my[0],
          planYear: my[1],
          volumeLE: val.LAST_MONTH_FORECAST,
          coverage: val.LAST_MONTH_COVERAGE,
          sLastMonthCoverage: val.last_month_sup_coverage || 0,
          tLastMonthCoverage: val.last_month_tres_coverage || 0,
          oLastMonthCoverage: val.last_month_other_coverage || 0,
          coveragePercent: roundToTwo(2, val.LAST_MONTH_COVERAGE && val.LAST_MONTH_FORECAST && ((+val.LAST_MONTH_COVERAGE/+val.LAST_MONTH_FORECAST)*100)) || 0,
          updatedFcst: val.FORECAST,
          targetPercentMin: val.MIN_TARGET_PERC,
          targetPercentMax: val.MAX_TARGET_PERC,
          minCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.MIN_TARGET_PERC/100)) || 0,
          maxCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.MAX_TARGET_PERC/100)) || 0,
          tminCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.TRSRY_MIN_TARGET/100)) || 0,
          tmaxCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.TRSRY_MAX_TARGET/100)) || 0,
          sminCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.SUP_MIN_TARGET/100)) || 0,
          smaxCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.SUP_MAX_TARGET/100)) || 0,
          mayCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.MAX_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE) || 0,
          mustCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.MIN_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE) || 0,
          smustCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.SUP_MIN_TARGET/100))-val.last_month_sup_coverage)) || 0,
          smayCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.SUP_MAX_TARGET/100))-val.last_month_sup_coverage)) || 0,
          swillCover: val.SUPPLIER_COVERAGE,
          stargetPercentMin: val.SUP_MIN_TARGET,
          stargetPercentMax: val.SUP_MAX_TARGET,
          tmustCover: roundToTwo(+wfDetails?.decimal_scale, (val.FORECAST * (val.TRSRY_MIN_TARGET/100))-val.last_month_tres_coverage) || 0,
          tmayCover: roundToTwo(+wfDetails?.decimal_scale, (val.FORECAST* (val.TRSRY_MAX_TARGET/100))-val.last_month_tres_coverage ) || 0,
          twillCover: val.TREASURY_COVERAGE,
          owillCover: val.OTHER_COVERAGE,
          ttargetPercentMin: val.TRSRY_MIN_TARGET,
          ttargetPercentMax: val.TRSRY_MAX_TARGET,
          overAllWillCover: val.COVERAGE,
          overAllCoverage: val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE,
          overAllCoveragePercent: val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE == 0 || val.FORECAST == 0 ? '' : (((parseFloat(val.TREASURY_COVERAGE || 0) + parseFloat(val.SUPPLIER_COVERAGE || 0) + parseFloat(val.LAST_MONTH_COVERAGE || 0))/parseFloat(val.FORECAST || 0))*100).toFixed(2) || 0,
          newCoveragePercent: null,
          comments: val.LAST_MONTH_COMMENT,
          targetCoveragePercentMin: roundToTwo(2, (obj.targetCoveragePercentMin/3)/(obj.totalUpdatedForecast/3)) || '',
          targetCoveragePercentMax: roundToTwo(2, (obj.targetCoveragePercentMax/3)/(obj.totalUpdatedForecast/3)) || '',
          varianceReason: val.LAST_MONTH_REASON || '',
          qcomments: val.QUARTERLY_COMMENT || '',
          qvarianceReason: val.QUARTERLY_REASON || '',
          tVariance: val.variance,
          isPrevAndActualCovSame: val.isPrevAndActualCovSame,

          twillCoverNegitiveOption: val.trsry_cov_reason_id,
          owillCoverNegitiveOption: val.other_cov_reason_id,
          swillCoverNegitiveOption: val.supp_cov_reason_id,
          overAllWillCoverNegitiveOption: val.cov_reason_id,
          sCarryValue: val.carry_volume,
        });
        if (i == 11 || i == 23 || i == 35) {
          const coveragePercent = (val.LAST_MONTH_COVERAGE && val.LAST_MONTH_FORECAST && ((val.LAST_MONTH_COVERAGE/val.LAST_MONTH_FORECAST)*100))*val.LAST_MONTH_COVERAGE;
          const supplierPercentMin = val.FORECAST * val.SUP_MIN_TARGET;
          const targetMin = val.FORECAST * val.MIN_TARGET_PERC;
          const treasuryPercentMin = val.FORECAST * val.TRSRY_MIN_TARGET;
          const supplierPercentMax = val.FORECAST * val.SUP_MAX_TARGET;
          const targetMax = val.FORECAST * val.MAX_TARGET_PERC;
          const treasuryPercentMax = val.FORECAST * val.TRSRY_MAX_TARGET;
          const ocPercent = (parseFloat(objTotal.overAllCoveragePercent)+((val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) == 0) || val.FORECAST == 0 ? '' : +((parseFloat(val.TREASURY_COVERAGE) + parseFloat(val.SUPPLIER_COVERAGE) + parseFloat(val.LAST_MONTH_COVERAGE))/parseFloat(val.FORECAST))*100) || 0;
          const overallCoveragePercent = (val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) * ocPercent;
          bpDetails.push({
            ...objTotal,
            comments: '',
            period: 'FY Total',
            volumeLE: roundToTwo(2, objTotal.volumeLE+val.LAST_MONTH_FORECAST),
            coverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.coverage+val.LAST_MONTH_COVERAGE),
            sLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.sLastMonthCoverage+(val.last_month_sup_coverage || 0)),
            tLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.tLastMonthCoverage+(val.last_month_tres_coverage || 0)),
            oLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.oLastMonthCoverage+(val.last_month_other_coverage || 0)),
            coveragePercent: ((objTotal.coveragePercent || 0)+coveragePercent) && (objTotal.coverage+val.LAST_MONTH_COVERAGE) && roundToTwo(2, ((+objTotal.coveragePercent || 0)+coveragePercent)/(objTotal.coverage+val.LAST_MONTH_COVERAGE)),
            updatedFcst: roundToTwo(2, objTotal.updatedFcst+val.FORECAST),
            targetPercentMin: isNaN(roundToTwo(2, (objTotal.targetPercentMin + targetMin)/(objTotal.updatedFcst + val.FORECAST))) ? 0 : roundToTwo(2, (objTotal.targetPercentMin + targetMin)/(objTotal.updatedFcst + val.FORECAST)),
            targetPercentMax: isNaN(roundToTwo(2, (objTotal.targetPercentMax + targetMax)/(objTotal.updatedFcst + val.FORECAST)))? 0 : roundToTwo(2, (objTotal.targetPercentMax + targetMax)/(objTotal.updatedFcst + val.FORECAST)),
            minCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.minCoverage + ((val.FORECAST && val.FORECAST * (val.MIN_TARGET_PERC/100)) || 0)),
            maxCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.maxCoverage + ((val.FORECAST && val.FORECAST * (val.MAX_TARGET_PERC/100)) || 0)),
            tminCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.tminCoverage+((val.FORECAST && val.FORECAST * (val.TRSRY_MIN_TARGET/100)) || 0)),
            tmaxCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.tmaxCoverage+((val.FORECAST && val.FORECAST * (val.TRSRY_MAX_TARGET/100)) || 0)),
            sminCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.sminCoverage+((val.FORECAST && val.FORECAST * (val.SUP_MIN_TARGET/100)) || 0)),
            smaxCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.smaxCoverage+((val.FORECAST && val.FORECAST * (val.SUP_MAX_TARGET/100)) || 0)),
            mayCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.mayCover+(((val.FORECAST * (val.MAX_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)) || 0,
            mustCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.mustCover+(((val.FORECAST * (val.MIN_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)) || 0,
            smustCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.smustCover+((val.FORECAST * (val.SUP_MIN_TARGET/100))-val.last_month_sup_coverage)),
            smayCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.smayCover+((val.FORECAST * (val.SUP_MAX_TARGET/100))-val.last_month_sup_coverage)) || 0,
            swillCover: objTotal.swillCover+val.SUPPLIER_COVERAGE,
            stargetPercentMin: roundToTwo(2, (objTotal.stargetPercentMin+supplierPercentMin)/(objTotal.updatedFcst + val.FORECAST)),
            stargetPercentMax: roundToTwo(2, (objTotal.stargetPercentMax+supplierPercentMax)/(objTotal.updatedFcst + val.FORECAST)),
            tmustCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.tmustCover+((val.FORECAST * (val.TRSRY_MIN_TARGET/100))-val.last_month_tres_coverage)) || 0,
            tmayCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.tmayCover+((val.FORECAST * (val.TRSRY_MAX_TARGET/100))-val.last_month_tres_coverage)) || 0,
            twillCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.twillCover+val.TREASURY_COVERAGE),
            owillCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.owillCover+val.OTHER_COVERAGE),
            ttargetPercentMin: roundToTwo(2, (objTotal.ttargetPercentMin+treasuryPercentMin)/(objTotal.updatedFcst + val.FORECAST)),
            ttargetPercentMax: roundToTwo(2, (objTotal.ttargetPercentMax+treasuryPercentMax)/(objTotal.updatedFcst + val.FORECAST)),
            overAllWillCover: roundToTwo(+wfDetails?.decimal_scale, objTotal.overAllWillCover+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.OTHER_COVERAGE)),
            overAllCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.overAllCoverage+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.OTHER_COVERAGE + val.LAST_MONTH_COVERAGE)),
            overAllCoveragePercent: (objTotal.overAllCoveragePercent + overallCoveragePercent) && (objTotal.overAllCoverage+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.OTHER_COVERAGE + val.LAST_MONTH_COVERAGE)) && roundToTwo(2, (objTotal.overAllCoveragePercent + overallCoveragePercent)/(objTotal.overAllCoverage+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.OTHER_COVERAGE + val.LAST_MONTH_COVERAGE))),
          });
          // reset to initial //
          objTotal = {
            market: '',
            period: '',
            planYear: '',
            volumeLE: 0,
            coverage: 0,
            sLastMonthCoverage: 0,
            tLastMonthCoverage: 0,
            oLastMonthCoverage: 0,
            coveragePercent: 0,
            updatedFcst: 0,
            targetPercentMin: 0,
            targetPercentMax: 0,
            minCoverage: 0,
            maxCoverage: 0,
            tminCoverage: 0,
            tmaxCoverage: 0,
            sminCoverage: 0,
            smaxCoverage: 0,
            mayCover: 0,
            mustCover: 0,
            smustCover: 0,
            smayCover: 0,
            swillCover: 0,
            stargetPercentMin: 0,
            stargetPercentMax: 0,
            tmustCover: 0,
            tmayCover: 0,
            twillCover: 0,
            owillCover: 0,
            ttargetPercentMin: 0,
            ttargetPercentMax: 0,
            overAllWillCover: 0,
            overAllCoverage: 0,
            overAllCoveragePercent: 0,
            newCoveragePercent: 0,
            comments: '',
            targetCoveragePercent: 0,
          };
          obj = {
            totalUpdatedForecast: 0,
            totalOverAllCoverage: 0,
            targetCoveragePercentMin: 0,
            targetCoveragePercentMax: 0,
          };
        } else {
          const coveragePercent = (val.LAST_MONTH_COVERAGE && val.LAST_MONTH_FORECAST && ((val.LAST_MONTH_COVERAGE/val.LAST_MONTH_FORECAST)*100))*val.LAST_MONTH_COVERAGE;
          const supplierPercentMin = val.FORECAST * val.SUP_MIN_TARGET;
          const targetMin = val.FORECAST * val.MIN_TARGET_PERC;
          const treasuryPercentMin = val.FORECAST * val.TRSRY_MIN_TARGET;
          const supplierPercentMax = val.FORECAST * val.SUP_MAX_TARGET;
          const targetMax = val.FORECAST * val.MAX_TARGET_PERC;
          const treasuryPercentMax = val.FORECAST * val.TRSRY_MAX_TARGET;
          const ocPercent = (parseFloat(objTotal.overAllCoveragePercent)+((val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) == 0) || val.FORECAST == 0 ? '' : +((parseFloat(val.TREASURY_COVERAGE) + parseFloat(val.SUPPLIER_COVERAGE) + parseFloat(val.LAST_MONTH_COVERAGE))/parseFloat(val.FORECAST))*100) || 0;
          const overallCoveragePercent = (val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) * ocPercent;
          objTotal = {
            ...objTotal,
            comments: '',
            period: 'FY Total',
            volumeLE: objTotal.volumeLE+val.LAST_MONTH_FORECAST,
            coverage: objTotal.coverage+val.LAST_MONTH_COVERAGE,
            sLastMonthCoverage: objTotal.sLastMonthCoverage+(val.last_month_sup_coverage || 0),
            tLastMonthCoverage: objTotal.tLastMonthCoverage+(val.last_month_tres_coverage || 0),
            oLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.oLastMonthCoverage+(val.last_month_other_coverage || 0)),
            coveragePercent: roundToTwo(2, (+objTotal.coveragePercent || 0) + coveragePercent),
            updatedFcst: objTotal.updatedFcst+val.FORECAST,
            targetPercentMin: objTotal.targetPercentMin + targetMin,
            targetPercentMax: objTotal.targetPercentMax + targetMax,
            minCoverage: objTotal.minCoverage + (+(val.FORECAST && val.FORECAST * (val.MIN_TARGET_PERC/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            maxCoverage: objTotal.maxCoverage + (+(val.FORECAST && val.FORECAST * (val.MAX_TARGET_PERC/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            tminCoverage: objTotal.tminCoverage+(+(val.FORECAST && val.FORECAST * (val.TRSRY_MIN_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            tmaxCoverage: objTotal.tmaxCoverage+(+(val.FORECAST && val.FORECAST * (val.TRSRY_MAX_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            sminCoverage: objTotal.sminCoverage+(+(val.FORECAST && val.FORECAST * (val.SUP_MIN_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            smaxCoverage: objTotal.smaxCoverage+(+(val.FORECAST && val.FORECAST * (val.SUP_MAX_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            mayCover: +(objTotal.mayCover+(((val.FORECAST * (val.MAX_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)).toFixed(+wfDetails?.decimal_scale) || 0,
            mustCover: +(objTotal.mustCover+(((val.FORECAST * (val.MIN_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)).toFixed(+wfDetails?.decimal_scale) || 0,
            smustCover: +(objTotal.smustCover+((val.FORECAST * (val.SUP_MIN_TARGET/100))-val.last_month_sup_coverage)).toFixed(+wfDetails?.decimal_scale),
            smayCover: +(objTotal.smayCover+((val.FORECAST * (val.SUP_MAX_TARGET/100))-val.last_month_sup_coverage)).toFixed(+wfDetails?.decimal_scale) || 0,
            swillCover: objTotal.swillCover+val.SUPPLIER_COVERAGE,
            stargetPercentMin: objTotal.stargetPercentMin+supplierPercentMin,
            stargetPercentMax: objTotal.stargetPercentMax+supplierPercentMax,
            tmustCover: +(objTotal.tmustCover+((val.FORECAST * (val.TRSRY_MIN_TARGET/100))-val.last_month_tres_coverage)).toFixed(+wfDetails?.decimal_scale) || 0,
            tmayCover: +(objTotal.tmayCover+((val.FORECAST * (val.TRSRY_MAX_TARGET/100))-val.last_month_tres_coverage)).toFixed(+wfDetails?.decimal_scale) || 0,
            twillCover: objTotal.twillCover+val.TREASURY_COVERAGE,
            owillCover: objTotal.owillCover+val.OTHER_COVERAGE,
            ttargetPercentMin: objTotal.ttargetPercentMin+treasuryPercentMin,
            ttargetPercentMax: objTotal.ttargetPercentMax+treasuryPercentMax,
            overAllWillCover: objTotal.overAllWillCover+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE),
            overAllCoverage: objTotal.overAllCoverage+(val.TREASURY_COVERAGE + val.OTHER_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE),
            overAllCoveragePercent: objTotal.overAllCoveragePercent + overallCoveragePercent,
          };
        }
      } else {
        const weightedAverageMin = val.FORECAST * val.MIN_TARGET_PERC;
        const weightedAverageMax = val.FORECAST * val.MAX_TARGET_PERC;
        obj = {
          totalUpdatedForecast: obj.totalUpdatedForecast+val.FORECAST,
          totalOverAllCoverage: +obj.totalOverAllCoverage+(+val.TREASURY_COVERAGE + +val.SUPPLIER_COVERAGE + +val.LAST_MONTH_COVERAGE),
          targetCoveragePercentMin: obj.targetCoveragePercentMin + weightedAverageMin,
          targetCoveragePercentMax: obj.targetCoveragePercentMax + weightedAverageMax,
        };
        bpDetails.push({
          market: '',
          period: my[0],
          planYear: my[1],
          volumeLE: val.LAST_MONTH_FORECAST,
          coverage: val.LAST_MONTH_COVERAGE,
          sLastMonthCoverage: val.last_month_sup_coverage || 0,
          tLastMonthCoverage: val.last_month_tres_coverage || 0,
          oLastMonthCoverage: val.last_month_other_coverage || 0,
          coveragePercent: roundToTwo(2, val.LAST_MONTH_COVERAGE && val.LAST_MONTH_FORECAST && ((+val.LAST_MONTH_COVERAGE/+val.LAST_MONTH_FORECAST)*100)) || 0,
          updatedFcst: val.FORECAST,
          targetPercentMin: val.MIN_TARGET_PERC,
          targetPercentMax: val.MAX_TARGET_PERC,
          minCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.MIN_TARGET_PERC/100)) || 0,
          maxCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.MAX_TARGET_PERC/100)) || 0,
          tminCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.TRSRY_MIN_TARGET/100)) || 0,
          tmaxCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.TRSRY_MAX_TARGET/100)) || 0,
          sminCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.SUP_MIN_TARGET/100)) || 0,
          smaxCoverage: roundToTwo(+wfDetails?.decimal_scale, val.FORECAST && val.FORECAST * (val.SUP_MAX_TARGET/100)) || 0,
          mayCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.MAX_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE) || 0,
          mustCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.MIN_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE) || 0,
          smustCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.SUP_MIN_TARGET/100))-val.last_month_sup_coverage)) || 0,
          smayCover: roundToTwo(+wfDetails?.decimal_scale, ((val.FORECAST * (val.SUP_MAX_TARGET/100))-val.last_month_sup_coverage)) || 0,
          swillCover: val.SUPPLIER_COVERAGE,
          stargetPercentMin: val.SUP_MIN_TARGET,
          stargetPercentMax: val.SUP_MAX_TARGET,
          tmustCover: roundToTwo(+wfDetails?.decimal_scale, (val.FORECAST * (val.TRSRY_MIN_TARGET/100))-val.last_month_tres_coverage) || 0,
          tmayCover: roundToTwo(+wfDetails?.decimal_scale, (val.FORECAST* (val.TRSRY_MAX_TARGET/100))-val.last_month_tres_coverage ) || 0,
          twillCover: val.TREASURY_COVERAGE,
          owillCover: val.OTHER_COVERAGE,
          ttargetPercentMin: val.TRSRY_MIN_TARGET,
          ttargetPercentMax: val.TRSRY_MAX_TARGET,
          overAllWillCover: val.COVERAGE,
          overAllCoverage: val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE,
          overAllCoveragePercent: val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE == 0 || val.FORECAST == 0 ? '' : (((+val.TREASURY_COVERAGE + +val.SUPPLIER_COVERAGE + +val.LAST_MONTH_COVERAGE)/+val.FORECAST)*100).toFixed(2) || 0,
          newCoveragePercent: null,
          comments: val.LAST_MONTH_COMMENT,
          targetCoveragePercentMin: 0,
          targetCoveragePercentMax: 0,
          varianceReason: val.LAST_MONTH_REASON || '',
          qcomments: val.quarterlyComment,
          qvarianceReason: val.quarterlyReason,
          tVariance: val.variance,
          isPrevAndActualCovSame: val.isPrevAndActualCovSame,
          twillCoverNegitiveOption: val.trsry_cov_reason_id,
          owillCoverNegitiveOption: val.other_cov_reason_id,
          swillCoverNegitiveOption: val.supp_cov_reason_id,
          overAllWillCoverNegitiveOption: val.cov_reason_id,

          sCarryValue: val.carry_volume,
        });
        if (i == 11 || i == 23 || i == 35) {
          const coveragePercent = (val.LAST_MONTH_COVERAGE && val.LAST_MONTH_FORECAST && ((val.LAST_MONTH_COVERAGE/val.LAST_MONTH_FORECAST)*100))*val.LAST_MONTH_COVERAGE;
          const supplierPercentMin = val.FORECAST * val.SUP_MIN_TARGET;
          const targetMin = val.FORECAST * val.MIN_TARGET_PERC;
          const treasuryPercentMin = val.FORECAST * val.TRSRY_MIN_TARGET;
          const supplierPercentMax = val.FORECAST * val.SUP_MAX_TARGET;
          const targetMax = val.FORECAST * val.MAX_TARGET_PERC;
          const treasuryPercentMax = val.FORECAST * val.TRSRY_MAX_TARGET;
          const ocPercent = (parseFloat(objTotal.overAllCoveragePercent)+((val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) == 0) || val.FORECAST == 0 ? '' : +((parseFloat(val.TREASURY_COVERAGE) + parseFloat(val.SUPPLIER_COVERAGE) + parseFloat(val.LAST_MONTH_COVERAGE))/parseFloat(val.FORECAST))*100) || 0;
          const overallCoveragePercent = (val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) * ocPercent;
          bpDetails.push({
            ...objTotal,
            volumeLE: objTotal.volumeLE+val.LAST_MONTH_FORECAST,
            coverage: objTotal.coverage+val.LAST_MONTH_COVERAGE,
            sLastMonthCoverage: objTotal.sLastMonthCoverage+(val.last_month_sup_coverage || 0),
            tLastMonthCoverage: objTotal.tLastMonthCoverage+(val.last_month_tres_coverage || 0),
            oLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.oLastMonthCoverage+(val.last_month_other_coverage || 0)),
            coveragePercent: roundToTwo(2, (+objTotal.coveragePercent+coveragePercent)/(objTotal.coverage+val.LAST_MONTH_COVERAGE)),
            updatedFcst: objTotal.updatedFcst+val.FORECAST,
            targetPercentMin: roundToTwo(2, (objTotal.targetPercentMin + targetMin)/(objTotal.updatedFcst + val.FORECAST)),
            targetPercentMax: roundToTwo(2, (objTotal.targetPercentMax + targetMax)/(objTotal.updatedFcst + val.FORECAST)),
            minCoverage: objTotal.minCoverage + (+(val.FORECAST && val.FORECAST * (val.MIN_TARGET_PERC/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            maxCoverage: objTotal.maxCoverage + (+(val.FORECAST && val.FORECAST * (val.MAX_TARGET_PERC/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            tminCoverage: objTotal.tminCoverage+(+(val.FORECAST && val.FORECAST * (val.TRSRY_MIN_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            tmaxCoverage: objTotal.tmaxCoverage+(+(val.FORECAST && val.FORECAST * (val.TRSRY_MAX_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            sminCoverage: objTotal.sminCoverage+(+(val.FORECAST && val.FORECAST * (val.SUP_MIN_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            smaxCoverage: objTotal.smaxCoverage+(+(val.FORECAST && val.FORECAST * (val.SUP_MAX_TARGET/100)).toFixed(+wfDetails?.decimal_scale) || 0),
            mayCover: +(objTotal.mayCover+(((val.FORECAST * (val.MAX_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)).toFixed(+wfDetails?.decimal_scale) || 0,
            mustCover: +(objTotal.mustCover+(((val.FORECAST * (val.MIN_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)).toFixed(+wfDetails?.decimal_scale) || 0,
            smustCover: +(objTotal.smustCover+((val.FORECAST * (val.SUP_MIN_TARGET/100))-val.last_month_sup_coverage)).toFixed(+wfDetails?.decimal_scale),
            smayCover: +(objTotal.smayCover+((val.FORECAST * (val.SUP_MAX_TARGET/100))-val.last_month_sup_coverage)).toFixed(+wfDetails?.decimal_scale) || 0,
            swillCover: objTotal.swillCover+val.SUPPLIER_COVERAGE,
            stargetPercentMin: roundToTwo(2, (objTotal.stargetPercentMin+supplierPercentMin)/(objTotal.updatedFcst + val.FORECAST)),
            stargetPercentMax: roundToTwo(2, (objTotal.stargetPercentMax+supplierPercentMax)/(objTotal.updatedFcst + val.FORECAST)),
            tmustCover: objTotal.tmustCover+((val.FORECAST* (val.TRSRY_MIN_TARGET/100))-val.last_month_tres_coverage),
            tmayCover: objTotal.tmayCover+((val.FORECAST * (val.TRSRY_MAX_TARGET/100))-val.last_month_tres_coverage),
            twillCover: objTotal.twillCover+val.TREASURY_COVERAGE,
            owillCover: objTotal.owillCover+val.OTHER_COVERAGE,
            ttargetPercentMin: roundToTwo(2, (objTotal.ttargetPercentMin+treasuryPercentMin)/(objTotal.updatedFcst + val.FORECAST)),
            ttargetPercentMax: roundToTwo(2, (objTotal.ttargetPercentMax+treasuryPercentMax)/(objTotal.updatedFcst + val.FORECAST)),
            overAllWillCover: objTotal.overAllWillCover+(val.TREASURY_COVERAGE + val.OTHER_COVERAGE + val.SUPPLIER_COVERAGE),
            overAllCoverage: objTotal.overAllCoverage+(+val.TREASURY_COVERAGE + val.OTHER_COVERAGE + +val.SUPPLIER_COVERAGE + +val.LAST_MONTH_COVERAGE),
            overAllCoveragePercent: (objTotal.overAllCoveragePercent + overallCoveragePercent) && (objTotal.overAllCoverage+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.OTHER_COVERAGE + val.LAST_MONTH_COVERAGE)) && roundToTwo(2, (objTotal.overAllCoveragePercent + overallCoveragePercent)/(objTotal.overAllCoverage+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.OTHER_COVERAGE + val.LAST_MONTH_COVERAGE))) || 0,
          });
          // reset to initial //
          objTotal = {
            market: '',
            period: '',
            planYear: '',
            volumeLE: 0,
            coverage: 0,
            sLastMonthCoverage: 0,
            tLastMonthCoverage: 0,
            oLastMonthCoverage: 0,
            coveragePercent: 0,
            updatedFcst: 0,
            targetPercentMin: 0,
            targetPercentMax: 0,
            minCoverage: 0,
            maxCoverage: 0,
            tminCoverage: 0,
            tmaxCoverage: 0,
            sminCoverage: 0,
            smaxCoverage: 0,
            mayCover: 0,
            mustCover: 0,
            smustCover: 0,
            smayCover: 0,
            swillCover: 0,
            stargetPercentMin: 0,
            stargetPercentMax: 0,
            tmustCover: 0,
            tmayCover: 0,
            twillCover: 0,
            owillCover: 0,
            ttargetPercentMin: 0,
            ttargetPercentMax: 0,
            overAllWillCover: 0,
            overAllCoverage: 0,
            overAllCoveragePercent: 0,
            newCoveragePercent: 0,
            comments: 0,
            targetCoveragePercent: 0,
          };
        } else {
          const coveragePercent = (val.LAST_MONTH_COVERAGE && val.LAST_MONTH_FORECAST && ((val.LAST_MONTH_COVERAGE/val.LAST_MONTH_FORECAST)*100))*val.LAST_MONTH_COVERAGE;
          const supplierPercentMin = val.FORECAST * val.SUP_MIN_TARGET;
          const targetMin = val.FORECAST * val.MIN_TARGET_PERC;
          const treasuryPercentMin = val.FORECAST * val.TRSRY_MIN_TARGET;
          const supplierPercentMax = val.FORECAST * val.SUP_MAX_TARGET;
          const targetMax = val.FORECAST * val.MAX_TARGET_PERC;
          const treasuryPercentMax = val.FORECAST * val.TRSRY_MAX_TARGET;
          const ocPercent = (parseFloat(objTotal.overAllCoveragePercent)+((val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) == 0) || val.FORECAST == 0 ? '' : +((parseFloat(val.TREASURY_COVERAGE) + parseFloat(val.SUPPLIER_COVERAGE) + parseFloat(val.LAST_MONTH_COVERAGE))/parseFloat(val.FORECAST))*100) || 0;
          const overallCoveragePercent = (val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.LAST_MONTH_COVERAGE) * ocPercent;
          objTotal = {
            ...objTotal,
            period: 'FY Total',
            volumeLE: objTotal.volumeLE+val.LAST_MONTH_FORECAST,
            coverage: objTotal.coverage+val.LAST_MONTH_COVERAGE,
            sLastMonthCoverage: objTotal.sLastMonthCoverage+(val.last_month_sup_coverage || 0),
            tLastMonthCoverage: objTotal.tLastMonthCoverage+(val.last_month_tres_coverage || 0),
            oLastMonthCoverage: roundToTwo(+wfDetails?.decimal_scale, objTotal.oLastMonthCoverage+(val.last_month_other_coverage || 0)),
            coveragePercent: (+objTotal.coveragePercent || 0)+coveragePercent,
            updatedFcst: objTotal.updatedFcst+val.FORECAST,
            targetPercentMin: objTotal.targetPercentMin + targetMin,
            targetPercentMax: objTotal.targetPercentMax + targetMax,
            minCoverage: objTotal.minCoverage + (+(val.FORECAST && val.FORECAST * (val.MIN_TARGET_PERC/100)) || 0),
            maxCoverage: objTotal.maxCoverage + (+(val.FORECAST && val.FORECAST * (val.MAX_TARGET_PERC/100)) || 0),
            tminCoverage: objTotal.tminCoverage+(+(val.FORECAST && val.FORECAST * (val.TRSRY_MIN_TARGET/100)) || 0),
            tmaxCoverage: objTotal.tmaxCoverage+(+(val.FORECAST && val.FORECAST * (val.TRSRY_MAX_TARGET/100)) || 0),
            sminCoverage: objTotal.sminCoverage+(+(val.FORECAST && val.FORECAST * (val.SUP_MIN_TARGET/100)) || 0),
            smaxCoverage: objTotal.smaxCoverage+(+(val.FORECAST && val.FORECAST * (val.SUP_MAX_TARGET/100)) || 0),
            mayCover: +(objTotal.mayCover+(((val.FORECAST * (val.MAX_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)) || 0,
            mustCover: +(objTotal.mustCover+(((val.FORECAST * (val.MIN_TARGET_PERC/100)))-val.LAST_MONTH_COVERAGE)) || 0,
            smustCover: +(objTotal.smustCover+((val.FORECAST * (val.SUP_MIN_TARGET/100))-val.last_month_sup_coverage)),
            smayCover: +(objTotal.smayCover+((val.FORECAST * (val.SUP_MAX_TARGET/100))-val.last_month_sup_coverage)) || 0,
            swillCover: objTotal.swillCover+val.SUPPLIER_COVERAGE,
            stargetPercentMin: objTotal.stargetPercentMin+supplierPercentMin,
            stargetPercentMax: objTotal.stargetPercentMax+supplierPercentMax,
            tmustCover: +(objTotal.tmustCover+((val.FORECAST * (val.TRSRY_MIN_TARGET/100))-val.last_month_tres_coverage)) || 0,
            tmayCover: +(objTotal.tmayCover+((val.FORECAST * (val.TRSRY_MAX_TARGET/100))-val.last_month_tres_coverage)) || 0,
            twillCover: objTotal.twillCover+val.TREASURY_COVERAGE,
            owillCover: objTotal.owillCover+val.OTHER_COVERAGE,
            ttargetPercentMin: objTotal.ttargetPercentMin+treasuryPercentMin,
            ttargetPercentMax: objTotal.ttargetPercentMax+treasuryPercentMax,
            overAllWillCover: objTotal.overAllWillCover+(val.TREASURY_COVERAGE + val.OTHER_COVERAGE + val.SUPPLIER_COVERAGE),
            overAllCoverage: objTotal.overAllCoverage+(val.TREASURY_COVERAGE + val.SUPPLIER_COVERAGE + val.OTHER_COVERAGE + val.LAST_MONTH_COVERAGE),
            overAllCoveragePercent: roundToTwo(2, objTotal.overAllCoveragePercent + overallCoveragePercent),
          };
        }
      }
    });
    let newObj = {
      totalUpdatedForecast: 0,
      totalOverAllCoverage: 0,
      targetCoveragePercentMin: 0,
      targetCoveragePercentMax: 0,
      newCoverage: 0,
      overAllCoverage: 0,
    };
    bpDetails.forEach((val, i) => {
      const weightedAverageMin = val.updatedFcst * val.targetPercentMin;
      const weightedAverageMax = val.updatedFcst * val.targetPercentMax;
      newObj = {
        totalUpdatedForecast: newObj.totalUpdatedForecast+val.updatedFcst,
        totalOverAllCoverage: newObj.totalOverAllCoverage+(val.twillCover + val.owillCover + val.swillCover + val.coverage),
        targetCoveragePercentMin: newObj.targetCoveragePercentMin + weightedAverageMin,
        targetCoveragePercentMax: newObj.targetCoveragePercentMax + weightedAverageMax,
        newCoverage: newObj.newCoverage + (val.overAllCoverage*val.overAllCoveragePercent),
        overAllCoverage: newObj.overAllCoverage + +val.overAllCoverage,
      };
      if (quarterMonths.includes(val.period)) {
        const targetCoverageP = newObj.targetCoveragePercentMin && newObj.totalUpdatedForecast && roundToTwo(+wfDetails?.decimal_scale, (+newObj.targetCoveragePercentMin) / +newObj.totalUpdatedForecast) || 0;
        const newCoverageP = newObj.newCoverage && newObj.overAllCoverage && roundToTwo(+wfDetails?.decimal_scale, newObj.newCoverage/newObj.overAllCoverage);
        let finalVal = null;
        if (selectedRow.optionality) {
          if (val.minCoverage < newCoverageP) {
            finalVal = roundToTwo(+wfDetails?.decimal_scale, Math.abs(val.minCoverage - newCoverageP));
          }
          if (val.minCoverage > newCoverageP) {
            finalVal = roundToTwo(+wfDetails?.decimal_scale, Math.abs(newObj.maxCoverage - newCoverageP));
          }
        } else {
          finalVal = roundToTwo(+wfDetails?.decimal_scale, Math.abs(newCoverageP - targetCoverageP));
        }
        val.targetVariancePercent = (finalVal);
        // val['tVariance'] = finalVal;
        // bpDetails[i-1]['tVariance'] = finalVal;
        // bpDetails[i-2]['tVariance'] = finalVal;
        // val.newCoveragePercent = newCoverageP;
        newObj = {
          totalUpdatedForecast: 0,
          totalOverAllCoverage: 0,
          targetCoveragePercentMin: 0,
          targetCoveragePercentMax: 0,
          overAllCoverage: 0,
        };
      }
    });

    return bpDetails;
  };

  const buyplanStatus = useSelector((state) => state.btnStatus.status);
  useEffect(()=>{
    if (buyplanStatus=='NEW') {
      dispatch(editStatus(false));
    } else {
      dispatch(editStatus(true));
    }
  }, []);

  // negitiveValueWill cover options
  useEffect(() => {
    ConfigurationServices.getBuyplanNegitiveWillCoverValues().then((res)=>{
      dispatch(setnegitiveValueReasons(res));
    }).catch((err) => {
      console.log('Error occured');
    });
  }, []);
  useLayoutEffect(() => {
    if (selectedRow?.prevWorkFlowId) getCall();
  }, [selectedRow]);
  const getCall = () => {
    DialogService.getForecastData(selectedRow.workFlowId, selectedRow.prevWorkFlowId, selectedRow.sector)
        .then(async (res) => {
          let obj = {};
          // console.log(res);
          // debugger;
          const ExeMonths = [selectedRow.executionMonthYear[0]];
          if (res.length) {
            const NotJanFeb = selectedRow.executionMonthYear[1]!==1 && selectedRow.executionMonthYear[1]!==2;
            for (let k=0; k<res.length; k++) {
              if (+res[k]?.executionMonthYear[0]!==+selectedRow.executionMonthYear[0] && +selectedRow.executionMonthYear[0]-+res[k]?.executionMonthYear[0]==1 && !ExeMonths.includes(res[k]?.executionMonthYear[0]) && ExeMonths?.length<=3) {
                ExeMonths.push(res[k]?.executionMonthYear[0]);
              }
            }
            ExeMonths.sort((a, b)=>+a-+b);
            if (NotJanFeb) {
              if (ExeMonths.length!==3) {
                setExecMonthDecider([+ExeMonths[0], +ExeMonths[0]+1, +ExeMonths[0]+2]);
              } else {
                setExecMonthDecider(ExeMonths);
              }
            } else {
              setYears([+selectedRow.executionMonthYear[0]-1, +selectedRow.executionMonthYear[0], +selectedRow.executionMonthYear[0]+1]);
            }
            res.forEach((val) => {
              const split = val.forecastYearMonth;
              const checkIn= (NotJanFeb?+ExeMonths[0]:+[+selectedRow.executionMonthYear[0]-1, +selectedRow.executionMonthYear[0], +selectedRow.executionMonthYear[0]+1][0]);
              if (+split[0] >= checkIn) {
                if (!workflowIdsObj[val.workflowId]) {
                  workflowIdsObj[val.workflowId] = val;
                  setWorkflowIdsObj({...workflowIdsObj});
                }
                // This function used to set the month and year to each object
                obj = {...monthNYearSetter(obj, val)};
              }
            });
            // if some data is received ans some not like 2023 year data is there but 2024 data is not
            const creatingRow = await rowsSetter(obj, workflowIdsObj, selectedRow, ExeMonths);
            obj = {...creatingRow};
          } else {
            // when there is no data for a all three years and it is first time Not started forecast
            obj = forecastDefaultData(selectedRow, years);
            // console.log(obj);
            // console.log({2023:});
          }
          // console.log('@fsct getCall ModifiedData ', obj);
          setModifiedData({...obj});
          // setIsDataFatched(true);
        })
        .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (buyPlanDetails?.length) {
      const newArr = buyPlanEvaluator();
      // console.log(newArr);


      overAllCoverageCalculation(newArr);
      quarterWiseCalculation(newArr);

      dispatch(buyPlanData(newArr));


      // dispatch(buyPlanData(output));
    } else {
      let obj = [];
      // forecast Value
      if (target) {
        new Promise(async (resolve, reject) => {
          const output = await prevDataEvaluator(modifiedData, selectedRow, target, coverages, pcoverages, isPreviousYearData, +wfDetails.decimal_scale);
          resolve(output);
        }).then((res) => {
          Object.values(res).forEach((val) => {
            obj = [...obj, ...val];
          });

          console.log(res);
          if (selectedRow.coverageMode == 'TREASURY') {
            const arr = [...res[years[0]], ...res[years[1]], ...res[years[2]]];
            treasuryPrepopulatingFromMustCover(arr);
          } else {
            overAllCoverageCalculation(obj);
            quarterWiseCalculation(obj);
          }
          dispatch(buyPlanData(obj));
        });
      }
    }
  }, [target, modifiedData]);

  const [disableSubmit, setDisableSubmit] = useState(true);


  const [saveddataPayload, setSavedDataPayload] = useState([]);

  const saveDataFunction = async (payload, submitPressed)=>{
    await DialogService.saveBuyPlan({'buyPlans': payload, 'carryVolumeComment': sCarryValueReason==''?null:sCarryValueReason})
        .then((res) => {
          // console.log(res);
          if (res == true) {
            setShowSuccessDialog(true);
            setShowMessage('Plan saved as Draft');
            dispatch(editStatus(true));
            setDisableSubmit(false);
            setBuplanDetailsTrigger(!buyplanDetailsTrigger);
            setCarryVolumeCommentsTrigger(!CarryVolumeCommentsTrigger);
            setSavedDataPayload([]);
            setmustMayTolerance(false);
            setmustMayToleranceErrors([]);
          }
        })
        .catch((err)=>{
          setShowErrorDialog(true);
          setSavedDataPayload([]);
          setmustMayTolerance(false);
          setmustMayToleranceErrors([]);
        });
    setIsPlanValidated(false);
    const {workFlowId} = selectedRow;
    if (submitPressed!==true) {
      if (isRecallApproval === 'REJECTED') {
        await DialogService.updateStatus(workFlowId, 'DRAFT')
            .then((res) => {
              setShowConfirmDialog(false);
              if (res == true) {
                dispatch(updateStatusOfBuyPlan(workFlowId, 'DRAFT'));
                dispatch(changeButtonStatusValue('view'));
                // dispatch(isRecallStatus(true));
                setIsRecallApproval('DRAFT');
              } else {
                // console.log('Error : ', err);
              }
            }).then(()=>{
              dispatch(setPlanStatusChange('DRAFT'));
            })
            .catch((err) => {
              setShowConfirmDialog(false);
              setShowErrorDialog(true);
              // console.log('Error : ', err);
            },
            );
      }
    } else {
      await DialogService.updateStatus(workFlowId, 'SUBMITTED')
          .then((res) => {
            setShowConfirmDialog(false);
            if (res == true) {
              dispatch(updateStatusOfBuyPlan(workFlowId, 'SUBMITTED'));
              // to show plan submitted!
              setShowSuccessDialog(true);
              setShowMessage('  Plan Submitted');

              dispatch(changeButtonStatusValue('view'));
              dispatch(isRecallStatus(true));
              setIsRecallApproval('SUBMITTED');
              setSubmitPressed(null);
            } else {
              // console.log('Error : ', err);
            }
          }).then(()=>{
            dispatch(setPlanStatusChange('SUBMITTED'));
          })
          .catch((err) => {
            setShowConfirmDialog(false);
            setShowErrorDialog(true);
            setSubmitPressed(null);
            // console.log('Error : ', err);
          },
          );
    }
  };

  const mustMayCoverErrorDecider = (optionality, rowData) => {
    if (!optionality) {
      if ((rowData['overAllWillCover']<(rowData['mustCover']-1) || rowData['overAllWillCover'] > (rowData['mustCover']+1)) ||
      ((rowData['overAllCoveragePercent']<(rowData['targetPercentMin']-0.5) || rowData['overAllCoveragePercent'] > (rowData['targetPercentMin']+0.5)))
      ) {
        return true;
      }
      return false;
    } else {
      if ((rowData['overAllWillCover']<(rowData['mustCover']-1) || rowData['overAllWillCover'] > (rowData['mayCover']+1)) ||
    ((rowData['overAllCoveragePercent']<(rowData['targetPercentMin']-0.5) || rowData['overAllCoveragePercent'] > (rowData['targetPercentMax']+0.5)))
      ) {
        return true;
      }
      return false;
    }
  };

  const savePlan = () => {
    const payload = [];
    let isValidate = false;
    dispatch(buyPlanData(data));
    const errors = [];
    // const quarterMonths = ['Mar', 'Jun', 'Sep', 'Dec'];
    // console.log(data);
    let mustMayCoverError = [];
    if (selectedRow.compReviewSuspended == true) {
      data.forEach((val, i) => {
        if (val.swillCoverNegitiveOption==null && val.swillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Supplier Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (val.twillCoverNegitiveOption==null && val.twillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Treasury Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (val.owillCoverNegitiveOption==null && val.owillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Other Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (val.overAllWillCoverNegitiveOption==null && val.overAllWillCover<0 && selectedRow.coverageMode=='NONE' && selectedRow.others==false && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Overall Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (i!==12 && i!==25 && i!==38 && i!==39 && mustMayCoverErrorDecider(selectedRow.optionality, val) && (val.tVariance<2.5 && val.tVariance>-2.5)) {
          mustMayCoverError.push(`${val.period} ${val.planYear}`);
        }
        if (monthArr.indexOf(val.period) > -1) {
          const obj = {
            'workflowId': val.workFlowId || selectedRow.workFlowId,
            'coverageYearMonth': [+val.planYear, monthArr.indexOf(val.period)+1],
            'lastMonthForecast': val.volumeLE,
            'forecast': val.updatedFcst,
            'lastMonthCoverage': val.coverage,
            // 'supplierCoverage': val.swillCover,
            'supplierCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.swillCover) : val.swillCover,
            // 'treasuryCoverage': val.twillCover,
            'treasuryCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover) : (wfDetails?.isLotSizeEnabled && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, val?.period, val?.planYear)) ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover, wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit) : val.twillCover,
            'coverage': val.overAllWillCover,
            'supMinTarget': val.stargetPercentMin,
            'supMaxTarget': val.stargetPercentMax,
            'treasuryMinTarget': val.ttargetPercentMin,
            'treasuryMaxTarget': val.ttargetPercentMax,
            'minTargetPercentage': val.targetPercentMin,
            'maxTargetPercentage': val.targetPercentMax,
            'lastMonthReason': val.varianceReason || '',
            'lastMonthComment': val.comments || '',
            'quarterlyReason': val.qvarianceReason || '',
            'quarterlyComment': val.qcomments || '',
            // 'otherCoverage': val.overAllCoverage,
            'suppLastMonthCoverage': val.sLastMonthCoverage,
            'tresLastMonthCoverage': val.tLastMonthCoverage,
            'otherLastMonthCoverage': val.oLastMonthCoverage,
            'variance': val.targetVariancePercent,
            'newCoverage': val.overAllCoverage,
            // 'otherCoverage': val.owillCover,
            'otherCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.owillCover) : val.owillCover,
            'isPrevAndActualCovSame': val?.isPrevAndActualCovSame ? true : false,
            'suppCovReasonId': val.swillCoverNegitiveOption,
            'covReasonId': val.overAllWillCoverNegitiveOption,
            'trsryCovReasonId': val.twillCoverNegitiveOption,
            'otherCovReasonId': val.owillCoverNegitiveOption,
            // 'carryVolume': val.sCarryValue,
            'carryVolume': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.sCarryValue) : val.sCarryValue,
          };
          payload.push(obj);
          // console.log(obj);
        }
      });
    } else {
      data.forEach((val, i) => {
        if (val.swillCoverNegitiveOption==null && val.swillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Supplier Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (val.twillCoverNegitiveOption==null && val.twillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Treasury Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (val.owillCoverNegitiveOption==null && val.owillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Other Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (val.overAllWillCoverNegitiveOption==null && val.overAllWillCover<0 && selectedRow.coverageMode=='NONE' && selectedRow.others==false && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
          errors.push(`Click the dropdown icon next to the negative 'Overall Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
          isValidate = true;
        }
        if (i!==12 && i!==25 && i!==38 && i!==39 && mustMayCoverErrorDecider(selectedRow.optionality, val) && (val.tVariance<2.5 && val.tVariance>-2.5)) {
          mustMayCoverError.push(`${val.period} ${val.planYear}`);
        }
        if (((val.isPrevAndActualCovSame && (!val.comments || !val.varianceReason)) || (Math.abs(+val.tVariance) >= 2.5 && quarterMonths.includes(val.period)) && (!val.qcomments || !val.qvarianceReason))) {
          if (val.isPrevAndActualCovSame && !val.comments) {
            errors.push(`Last Month Overall-comments-in ${val.planYear}-${val.period}`);
          }
          if (val.isPrevAndActualCovSame && !val.varianceReason) {
            errors.push(`Last Month Overall-Variance Reason-in ${val.planYear}-${val.period}`);
          }
          if ((Math.abs(+val.tVariance) >= 2.5 && quarterMonths.includes(val.period)) && !val.qcomments) {
            errors.push(`Quarterly-Comments-in ${val.planYear}-${val.period}`);
          }
          if ((Math.abs(+val.tVariance) >= 2.5 && quarterMonths.includes(val.period)) && !val.qvarianceReason) {
            errors.push(`Quarterly-Variance Reason-in ${val.planYear}-${val.period}`);
          }
          isValidate = true;
        } else {
          if (monthArr.indexOf(val.period) > -1) {
            const obj = {
              'workflowId': val.workFlowId || selectedRow.workFlowId,
              'coverageYearMonth': [+val.planYear, monthArr.indexOf(val.period)+1],
              'lastMonthForecast': val.volumeLE,
              'forecast': val.updatedFcst,
              'lastMonthCoverage': val.coverage,
              // 'supplierCoverage': val.swillCover,
              'supplierCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.swillCover) : val.swillCover,
              // 'treasuryCoverage': val.twillCover,
              'treasuryCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover) : (wfDetails?.isLotSizeEnabled && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, val?.period, val?.planYear)) ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover, wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit) : val.twillCover,
              'coverage': val.overAllWillCover,
              'supMinTarget': val.stargetPercentMin,
              'supMaxTarget': val.stargetPercentMax,
              'treasuryMinTarget': val.ttargetPercentMin,
              'treasuryMaxTarget': val.ttargetPercentMax,
              'minTargetPercentage': val.targetPercentMin,
              'maxTargetPercentage': val.targetPercentMax,
              'lastMonthReason': val.varianceReason || '',
              'lastMonthComment': val.comments || '',
              'quarterlyReason': val.qvarianceReason || '',
              'quarterlyComment': val.qcomments || '',
              // 'otherCoverage': val.overAllCoverage,
              'suppLastMonthCoverage': val.sLastMonthCoverage,
              'tresLastMonthCoverage': val.tLastMonthCoverage,
              'otherLastMonthCoverage': val.oLastMonthCoverage,
              'variance': val.targetVariancePercent,
              'newCoverage': val.overAllCoverage,
              // 'otherCoverage': val.owillCover,
              'otherCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.owillCover) : val.owillCover,
              'isPrevAndActualCovSame': val?.isPrevAndActualCovSame ? true : false,
              'suppCovReasonId': val.swillCoverNegitiveOption,
              'covReasonId': val.overAllWillCoverNegitiveOption,
              'trsryCovReasonId': val.twillCoverNegitiveOption,
              'otherCovReasonId': val.owillCoverNegitiveOption,
              // 'carryVolume': val.sCarryValue,
              'carryVolume': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.sCarryValue) : val.sCarryValue,
            };
            payload.push(obj);
          }
        }
      });
    }

    // console.log(payload, 'payload');
    if (!isValidate) {
      if (mustMayCoverError.length==0) {
        saveDataFunction(payload);
      } else {
        setmustMayToleranceErrors(mustMayCoverError);
        setSavedDataPayload(payload);
        setmustMayTolerance(true);
      }
    } else {
      setIsPlanValidated(true);
      setBulkErrors(errors);
      setBulkErrorsOpen(true);
      // setShowMessage(errors.join('\n'));
      // setTimeout(() => {
      //   setShowSuccessDialog(true);
      // });
    }
  };

  const submitBuyPlan = async () => {
    const {workFlowId} = selectedRow;
    // if (isPlanValidate) {
    //   setShowMessage('the required values.');
    //   setTimeout(() => {
    //     setShowSuccessDialog(true);
    //   });
    // } else {
    // This flag is used to decide wheather save and submit function trigger are not if it false that means no validation errors  are present
    let isValidate = false;
    let mustMayCoverError = [];

    const errors = [];
    // if (!editFlag) {
    const payload = [];
    dispatch(buyPlanData(data));
    if (previousMonthWillCoverEditButton==true && previousMonthWillCoverEditButton!=='') {
      setSubmitPressed(true);
      determiningPreviousWillCover(true);
    } else {
      if (selectedRow.compReviewSuspended == true) {
        data.forEach((val, i) => {
          if (val.swillCoverNegitiveOption==null && val.swillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Supplier Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (val.twillCoverNegitiveOption==null && val.twillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Treasury Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (val.owillCoverNegitiveOption==null && val.owillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Other Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (val.overAllWillCoverNegitiveOption==null && val.overAllWillCover<0 && selectedRow.coverageMode=='NONE' && selectedRow.others==false && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Overall Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (i!==12 && i!==25 && i!==38 && i!==39 && mustMayCoverErrorDecider(selectedRow.optionality, val) && (val.tVariance<2.5 && val.tVariance>-2.5)) {
            mustMayCoverError.push(`${val.period} ${val.planYear}`);
          }
          if (monthArr.indexOf(val.period) > -1) {
            const obj = {
              'workflowId': val.workFlowId || selectedRow.workFlowId,
              'coverageYearMonth': [+val.planYear, monthArr.indexOf(val.period)+1],
              'lastMonthForecast': val.volumeLE,
              'forecast': val.updatedFcst,
              'lastMonthCoverage': val.coverage,
              // 'supplierCoverage': val.swillCover,
              'supplierCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.swillCover) : val.swillCover,
              // 'treasuryCoverage': val.twillCover,
              'treasuryCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover) : (wfDetails?.isLotSizeEnabled && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, val?.period, val?.planYear)) ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover, wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit) : val.twillCover,
              'coverage': val.overAllWillCover,
              'supMinTarget': val.stargetPercentMin,
              'supMaxTarget': val.stargetPercentMax,
              'treasuryMinTarget': val.ttargetPercentMin,
              'treasuryMaxTarget': val.ttargetPercentMax,
              'minTargetPercentage': val.targetPercentMin,
              'maxTargetPercentage': val.targetPercentMax,
              'lastMonthReason': val.varianceReason || '',
              'lastMonthComment': val.comments || '',
              'quarterlyReason': val.qvarianceReason || '',
              'quarterlyComment': val.qcomments || '',
              // 'otherCoverage': val.overAllCoverage,
              'suppLastMonthCoverage': val.sLastMonthCoverage,
              'tresLastMonthCoverage': val.tLastMonthCoverage,
              'otherLastMonthCoverage': val.oLastMonthCoverage,
              'variance': val.targetVariancePercent,
              'newCoverage': val.overAllCoverage,
              // 'otherCoverage': val.owillCover,
              'otherCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.owillCover) : val.owillCover,
              'isPrevAndActualCovSame': val?.isPrevAndActualCovSame ? true : false,
              'suppCovReasonId': val.swillCoverNegitiveOption,
              'covReasonId': val.overAllWillCoverNegitiveOption,
              'trsryCovReasonId': val.twillCoverNegitiveOption,
              'otherCovReasonId': val.owillCoverNegitiveOption,
              // 'carryVolume': val.sCarryValue,
              'carryVolume': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.sCarryValue) : val.sCarryValue,
            };
            payload.push(obj);
          // console.log(obj);
          }
        });
      } else {
        data.forEach((val, i) => {
          if (val.swillCoverNegitiveOption==null && val.swillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Supplier Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (val.twillCoverNegitiveOption==null && val.twillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Treasury Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (val.owillCoverNegitiveOption==null && val.owillCover<0 && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Other Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (val.overAllWillCoverNegitiveOption==null && val.overAllWillCover<0 && selectedRow.coverageMode=='NONE' && selectedRow.others==false && i!==12 && i!==25 && i!==38 && i!==39 && !isBeforeMonth(val, monthArr.indexOf(val.period)+1)) {
            errors.push(`Click the dropdown icon next to the negative 'Overall Will Cover' value in [${val.period} ${val.planYear}] to choose your reason for the negative value.`);
            isValidate = true;
          }
          if (i!==12 && i!==25 && i!==38 && i!==39 && mustMayCoverErrorDecider(selectedRow.optionality, val) && (val.tVariance<2.5 && val.tVariance>-2.5)) {
            mustMayCoverError.push(`${val.period} ${val.planYear}`);
          }
          if (((val.isPrevAndActualCovSame && (!val.comments || !val.varianceReason)) || (Math.abs(+val.tVariance) >= 2.5 && quarterMonths.includes(val.period)) && (!val.qcomments || !val.qvarianceReason))) {
          // console.log('Please enter a variance');
            if (!val.comments) {
              errors.push(`Last Month Overall-comments-in ${val.planYear}-${val.period}`);
            }
            if (!val.varianceReason) {
              errors.push(`Last Month Overall-Variance Reason-in ${val.planYear}-${val.period}`);
            }
            if ((Math.abs(+val.tVariance) >= 2.5 && quarterMonths.includes(val.period)) && !val.qcomments) {
              errors.push(`Quarterly-Comments-in ${val.planYear}-${val.period}`);
            }
            if ((Math.abs(+val.tVariance) >= 2.5 && quarterMonths.includes(val.period)) && !val.qvarianceReason) {
              errors.push(`Quarterly-Variance Reason-in ${val.planYear}-${val.period}`);
            }
            isValidate = true;
          } else {
            if (monthArr.indexOf(val.period) > -1) {
              const obj = {
                'workflowId': val.workFlowId || selectedRow.workFlowId,
                'coverageYearMonth': [+val.planYear, monthArr.indexOf(val.period)+1],
                'lastMonthForecast': val.volumeLE,
                'forecast': val.updatedFcst,
                'lastMonthCoverage': val.coverage,
                // 'supplierCoverage': val.swillCover,
                'supplierCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.swillCover) : val.swillCover,
                // 'treasuryCoverage': val.twillCover,
                'treasuryCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover) : (wfDetails?.isLotSizeEnabled && isAfterExecutionMonthYear(selectedRow?.executionMonthYear, val?.period, val?.planYear)) ? roundToTwo(+wfDetails?.decimal_scale, val.twillCover, wfDetails?.isLotSizeEnabled, wfDetails?.lotSizeToLocalUnit) : val.twillCover,
                'coverage': val.overAllWillCover,
                'supMinTarget': val.stargetPercentMin,
                'supMaxTarget': val.stargetPercentMax,
                'treasuryMinTarget': val.ttargetPercentMin,
                'treasuryMaxTarget': val.ttargetPercentMax,
                'minTargetPercentage': val.targetPercentMin,
                'maxTargetPercentage': val.targetPercentMax,
                'lastMonthReason': val.varianceReason || '',
                'lastMonthComment': val.comments || '',
                'quarterlyReason': val.qvarianceReason || '',
                'quarterlyComment': val.qcomments || '',
                // 'otherCoverage': val.overAllCoverage,
                'suppLastMonthCoverage': val.sLastMonthCoverage,
                'tresLastMonthCoverage': val.tLastMonthCoverage,
                'otherLastMonthCoverage': val.oLastMonthCoverage,
                'variance': val.targetVariancePercent,
                'newCoverage': val.overAllCoverage,
                // 'otherCoverage': val.owillCover,
                'otherCoverage': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.owillCover) : val.owillCover,
                'isPrevAndActualCovSame': val?.isPrevAndActualCovSame ? true : false,
                'suppCovReasonId': val.swillCoverNegitiveOption,
                'covReasonId': val.overAllWillCoverNegitiveOption,
                'trsryCovReasonId': val.twillCoverNegitiveOption,
                'otherCovReasonId': val.owillCoverNegitiveOption,
                // 'carryVolume': val.sCarryValue,
                'carryVolume': wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, val.sCarryValue) : val.sCarryValue,
              };
              payload.push(obj);
            // console.log(obj);
            }
          }
        });
      }
      if (!isValidate) {
        if (mustMayCoverError.length==0) {
          saveDataFunction(payload, true);
        } else {
          setmustMayToleranceErrors(mustMayCoverError);
          setSavedDataPayload(payload);
          setmustMayTolerance(true);
        }
      } else {
        setIsPlanValidated(true);
        setBulkErrors(errors);
        setBulkErrorsOpen(true);
      // setTimeout(() => {
      //   setShowSuccessDialog(true);
      // });
      }
      // const {workFlowId} = selectedRow;
      // if (isRecallApproval === 'REJECTED') {
      //   DialogService.updateStatus(workFlowId, 'DRAFT')
      //       .then((res) => {
      //         setShowConfirmDialog(false);
      //         if (res == true) {
      //           dispatch(updateStatusOfBuyPlan(workFlowId, 'DRAFT'));
      //           dispatch(changeButtonStatusValue('view'));
      //           // dispatch(isRecallStatus(true));
      //           setIsRecallApproval('DRAFT');
      //         } else {
      //           // console.log('Error : ', err);
      //         }
      //       }).then(()=>{
      //         dispatch(setPlanStatusChange('DRAFT'));
      //       })
      //       .catch((err) => {
      //         setShowConfirmDialog(false);
      //         setShowErrorDialog(true);
      //         // console.log('Error : ', err);
      //       },
      //       );
      // }
      // }
    // }
    }
  };
  const atlasFxCall = () => {
    setErrorData('');
    const {workFlowId, prevWorkFlowId} = selectedRow;
    DialogService.atlasFxCall(workFlowId, prevWorkFlowId)
        .then((res) => {
          setForecastSendFlagSucStatus({status: true, sucMsg: 'Forecast details have been sent to AtlasFX. The file will be available for download in a few minutes.'});
          dispatch(setForeCastSendToAtlasFx([...disableForecastBtn, workFlowId]));
          // console.log(res);
          setshowAtlasConfirm(false);
        })
        .catch((err) => {
          // setShowErrorDialog2(true);
          setErrorData(err?.userMessage || 'Error occured when sending data to atlasfx');
          setshowAtlasConfirm(false);
          // setForecastSendFlagFaiStatus({status: true, errMsg: 'Forecast details have been sent to AtlasFX. The file will be available for download in a few minutes.'});
        });
  };

  const rejectrecall = () =>{
    if (isRecallApproval === 'REJECTED' || isRecallApproval==='APPROVED' ) {
      const {workFlowId} = selectedRow;
      DialogService.updateStatus(workFlowId, 'SUBMITTED')
          .then((res) => {
            if (res == true) {
              setIsRecallApproval('SUBMITTED');
            }
          }).then(()=>{
            dispatch(setPlanStatusChange('SUBMITTED'));
            setIsRecallApproval('SUBMITTED');
          })
          .catch((err) => console.log(err));
    } else if (isRecallApproval==='CLOSED') {
      const {workFlowId} = selectedRow;
      DialogService.updateStatus(workFlowId, 'SUBMITTED')
          .then((res) => {
            if (res == true) {
              setIsRecallApproval('SUBMITTED');
            }
          }).then(()=>{
            dispatch(setPlanStatusChange('SUBMITTED'));
            setIsPopupVisible(false);
          })
          .catch((err) => console.log(err));
    } else {
      setOpen2(true);
    }
  };

  const recall = () =>{
    // if (isRecallApproval === 'REJECTED' || isRecallApproval==='APPROVED') {
    setOpen3(true);


    // };
  };

  const handleClickOpen3 = () => {
    const {workFlowId} = selectedRow;
    DialogService.updateStatus(workFlowId, 'FORECAST_SUBMITTED') .then(()=>{
      setIsRecallApproval('DRAFT');
      setOpen3(false);
      setDisableSubmit(true);
    })
        .then(()=>{
          dispatch(setPlanStatusChange('DRAFT'));

          setIsRecallApproval('DRAFT');
          dispatch(isRecallStatus(false));
        })
        .catch((err) => console.log(err));
    setSubmitPressed(null);
  };

  const downloadOutBoundFile = async () => {
    setErrorData('');
    await ConfigurationServices.downloadOutboundAtlasfxFile(selectedRow.workFlowId)
        .then((res) => {
          const element = document.createElement('a');
          let contentType = 'text/csv';
          const file = new Blob([res], {type: contentType});
          element.href = URL.createObjectURL(file);
          element.download = `${selectedRow?.commodity}-${selectedRow?.subCommodity}-${selectedRow?.sector}-${selectedRow?.region}-${(titleCase(months[selectedRow?.executionMonthYear[1]-1]))}${selectedRow?.executionMonthYear[0]}.csv`;
          document.body.appendChild(element);
          element.setAttribute('target', '_blank');
          element.setAttribute('rel', 'noopener noreferrer');
          element.click();
          document.body.removeChild(element);
        }).catch((err) => {
          // setShowErrorDialog2('Error occured while downloading atlasfx outbound file');
          setErrorData(err?.message || 'Error occured while downloading atlasfx outbound file');
        });
  };

  const approvalCall = () =>{
    const {workFlowId} = selectedRow;
    DialogService.updateStatus(workFlowId, 'APPROVED')
        .then((res) => {
          if (res == true) {
            setOpen(true);
            setPlanStatus('Approved');
            setOpen(true);
            setIsRecallApproval('APPROVED');
            setTabsEnableCheck((prev)=>({...prev, coverage: true}));
          }
        }).then(()=>{
          dispatch(setPlanStatusChange('APPROVED'));
          setshowApproveConfirmDialog(false);
        })
        .catch((err) => {
          setshowApproveConfirmDialog(false);
        });
  };

  const handleClose3 = () =>{
    setOpen3(false);
  };
  const ref = useRef(null);
  const ref2 = useRef(null);
  // console.log('@buyplantab flag, selectedRow ', flag, selectedRow);
  // console.log('@buyplantab columns ', columns);
  const programDetails = () =>{
    setErrorData('');
    DialogService.gettingProgramName(selectedRow?.buyPlanId).then((res)=>{
      setprogramName(res?.out_forcast_program);

      setshowAtlasConfirm(true);
    }).catch((err)=>{
      // setShowErrorDialog2(true);
      setErrorData(err?.userMessage || 'Error occured while getting data!');
    });
  };
  return (
    <div className="buy-plan-container">
      <div className="buyplan_years_container" style={{gap: '10px'}}>
        <DownloadEditBuyPlanTableList data={data} selectedRow={selectedRow} years={years} wfDetails={wfDetails}/>
        <div className='legends' style={{border: '1px solid #dbdbdb', padding: '1px', borderRadius: '3px'}}>
          <div className='legend'>
            <div>Match</div>
            <div className="legendValues">
              <div className='circle green'>
              </div>
              <div className='text'>0%</div></div>
          </div>
          <div className='legend'>
            <div> Event</div>
            <div className="legendValues">
              <div className='circle yellow'>
              </div>
              <div className='text'>2.5% - 5%</div>
            </div>
          </div>
          <div className='legend'>
            <div>Minor</div>
            <div className="legendValues">
              <div className='circle orange'>
              </div>
              <div className='text'>5% - 10%</div>
            </div>
          </div>
          <div className='legend'>
            <div> Critical</div>
            <div className="legendValues">
              <div className='circle red'>
              </div>
              <div className='text'>{'>=10%'}</div>
            </div>
          </div>
        </div>
        <div>
          { years.length && years.map((val, key) => <button key={key} onClick={() => {
            setTimeout(() => setSelectedYear(val), 500);
            if (val == years[0]) {
              ref.current?.setActiveCell({col: 0, row: 0});
              ref2.current?.setActiveCell({col: 0, row: 0});
            }
            if (val == years[1]) {
              ref.current?.setActiveCell({col: 0, row: 13});
              ref2.current?.setActiveCell({col: 0, row: 13});
            }
            if (val == years[2]) {
              ref.current?.setActiveCell({col: 0, row: 26});
              ref2.current?.setActiveCell({col: 0, row: 26});
            }
          }} style={selectedYear == val ? gridCSS.activeButton : gridCSS.inActiveButton}>{val}</button>)}
        </div>
      </div>
      <div className='table-container'>
        {/* <div className='first-col'>
          <ParentHeader values={{parentTitle: null, lower: 'Year', color: 'first'}} />
          <ParentHeader values={{parentTitle: '', lower: 'Period', color: 'first'}} />
        </div> */}

        {flag && (
          <>
            {selectedRow &&
            (columns.length || '') &&
                  <DataSheetGrid stickyLeft={2}
                    ref={ref}
                    value={data}
                    onChange={(rowData) => {
                      if (!editFlag || (previousMonthWillCoverEditButton==true && previousMonthWillCoverEditButton!=='')) {
                        let newArr=JSON.parse(JSON.stringify(rowData));
                        overAllCoverageCalculation(newArr);
                        // console.log(newArr);
                        quarterWiseCalculation(newArr);
                        dispatch(buyPlanData(newArr));
                      }
                    }}
                    columns={columns}
                    rowClassName={() => 'forecast buy-plan'}
                    disableContextMenu={true}
                    gutterColumn={false}
                    lockRows = {true}
                  />
            }
          </>
        )}
        {/* stickyRightColumn={
          {
            component: ({rowData}) => (
              <div className="sticky-col">
                <div>{rowData.planYear}</div>
                <div>{rowData.period}</div>
              </div>
            ),
          }
        } */}
        {!flag && (
          <>
            {selectedRow &&
            (columns.length || '') &&
                  <DataSheetGrid stickyLeft={2}
                    value={data}
                    ref={ref2}
                    onChange={(rowData) => {
                      if (!editFlag || (previousMonthWillCoverEditButton==true && previousMonthWillCoverEditButton!=='')) {
                        const newArr=JSON.parse(JSON.stringify(rowData));
                        overAllCoverageCalculation(newArr);
                        // console.log(newArr);
                        quarterWiseCalculation(newArr);


                        dispatch(buyPlanData(newArr));
                      }
                    }}
                    columns={columns}
                    rowClassName={() => 'forecast buy-plan'}
                    disableContextMenu={true}
                    gutterColumn={false}
                    lockRows = {true}
                  />
            }
          </>
        )}
      </div>
      {/* {accessControlReview!== EDIT ? */}
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        {accessControlBuyPlan === EDIT && isRecallApproval != 'CLOSED' && (pageStatus=='active' || (currentRole.includes('Admin'))) &&
      <div className="buttons-container">
        {BuyplanPreviousMonthAccess==EDIT && isRecallApproval != 'CLOSED' && <Button size='small' sx={{'background': 'white', 'color': '#6d7888', 'border': '1px solid #cecece',
          'height': '30px',
          'minWidth': '6rem',
          'fontSize': '0.8rem',
          'textTransform': 'capitalize',
          '&:hover': {
            'background': '#4297D3',
            'border': 'none',
            'color': 'white',
          },
          'marginRight': '10px',
        }} disabled={isRecall || isRecallApproval==='APPROVED' || isRecallApproval==='SUBMITTED' || isRecallApproval=='SUSPENDED' || !editFlag || selectedRow.executionMonthYear[1] == 3} onClick={() => {
          if (previousMonthWillCoverEditButton==true && previousMonthWillCoverEditButton!=='') {
            if (+data[39]['sCarryValue']!==0 && +data[39]['sCarryValue']<=1 && +data[39]['sCarryValue']>=-1 && wfDetails.is_carry_vol_enabled==true && checkHedgingHasSupplier) {
              seterrorHappen(false);
              setsCarryValueReasonPopUp(true);
            } else if ((+data[39]['sCarryValue']>1 || +data[39]['sCarryValue']<-1) && wfDetails.is_carry_vol_enabled==true && checkHedgingHasSupplier) {
              seterrorHappen(true);
              setsCarryValueReasonPopUp(true);
            } else {
              determiningPreviousWillCover();
            }
          } else {
            dispatch(editStatus(true));
            dispatch(setPreviousMonthWillCoverEditButton(true));
            setGroups(columnsUtil(isPreviousYearData, execMonthDecider, handleGroups, handleEdit, editFlag, {...selectedRow, ...wfDetails}, isRecall, (quarterMonths || quartersData), years, true));

            // setTimeout(() => {
            //   setFlag((pre) => !pre);
            // });
          };
        }
        }>
          {(previousMonthWillCoverEditButton==true && previousMonthWillCoverEditButton!=='') ? 'Save Historical Will Cover' : 'Edit Historical Will Cover'}
        </Button> }
        {accessControlBuyPlan === EDIT && isRecallApproval != 'CLOSED'&& <Button size='small' sx={{'background': 'white', 'color': '#6d7888', 'border': '1px solid #cecece',
          'height': '30px',
          'minWidth': '6rem',
          'fontSize': '0.8rem',
          'textTransform': 'capitalize',
          '&:hover': {
            'background': '#4297D3',
            'border': 'none',
            'color': 'white',
          },
        }} disabled={isRecall || isRecallApproval==='APPROVED' || isRecallApproval=='SUSPENDED' || isRecallApproval==='SUBMITTED' || (previousMonthWillCoverEditButton==true && previousMonthWillCoverEditButton!=='')} onClick={() => {
          if (editFlag) {
            dispatch(editStatus(false));
            dispatch(setPreviousMonthWillCoverEditButton(false));
            setTimeout(() => {
              setFlag((pre) => !pre);
            });
          } else {
            if (+data[39]['sCarryValue']!==0 && +data[39]['sCarryValue']<=1 && +data[39]['sCarryValue']>=-1 && wfDetails.is_carry_vol_enabled==true && checkHedgingHasSupplier ) {
              seterrorHappen(false);
              setsCarryValueReasonPopUp(true);
            } else if ((+data[39]['sCarryValue']>1 || +data[39]['sCarryValue']<-1) && wfDetails.is_carry_vol_enabled==true && checkHedgingHasSupplier) {
              seterrorHappen(true);
              setsCarryValueReasonPopUp(true);
            } else {
              savePlan();
            }
          }
        }
        }>
          {editFlag ? 'Edit Plan' : 'Save Plan'}
        </Button>}
        <AppButton customCSS={{'margin': '0 10px'}} disabled={(isRecallApproval != 'NEW' && isRecallApproval != 'DRAFT' && isRecallApproval != 'SUBMITTED') || (disableSubmit === true && isRecallApproval!=='SUBMITTED' && submitButtonVisibilty==false) } onClick={() => isRecallApproval !== 'SUBMITTED' ? setShowConfirmDialog(true) : recall()} label={isRecallApproval === 'SUBMITTED' ? 'Recall Submit' : 'Submit Plan'} />
        <AppButton disabled={(isRecallApproval != 'APPROVED' || (atlasOutboundStatus !== 0) || (wfDetails?.atlasfx_outbound_file!=null && !enableRecall) || disableForecastBtn.includes(selectedRow.workFlowId) || selectedRow.atlasFxEnabled==false || (SendAtlasFxDisabledAfterWd2 && !enableRecall))} onClick={() => programDetails()} label="Send forecast to AtlasFX " />
      </div>}
        {downloadOutBoundFileAccess && (atlasOutboundStatus == 2 || atlasOutboundStatus == 4) && <div className="buttons-container">
          <AppButton customCSS={{'margin': '0 10px', 'whiteSpace': 'noWrap'}} onClick={() => downloadOutBoundFile()} label={'Download atlasfx file'} />
        </div>
        }
        {accessControlReview===EDIT && <div className="buttons-container" style={{display: ((currentRole!=='BuyPlan Approver' ) && isRecallApproval=='CLOSED') && 'none'}}>
          <AppButton2 customCSS={{'margin': '0 10px'}} onClick={() => rejectrecall()} disabled={((isRecallApproval === 'DRAFT' || isRecallApproval=='SUSPENDED'|| isRecallApproval === 'NEW' )|| (currentRole=='BuyPlan Approver' && nextMonthCloseBuyPlanTag==true && !enableRecall))} label={(isRecallApproval !== 'SUBMITTED' ) ? 'Recall Approve' : 'Reject Plan'} />
          {isRecallApproval != 'CLOSED' && <AppButton3 disabled={isRecallApproval != 'SUBMITTED' || (currentRole=='BuyPlan Approver' && nextMonthCloseBuyPlanTag==true)} onClick={() => selectedRow.varianceCount>0 ? setshowApproveConfirmDialog(true) : approvalCall()} label="Approve Plan" />}
        </div>}
      </div>

      <DocumentUpload selectedRow={selectedRow} uploadedFrom={'workflow'} EditDocument={(isCreator?.functionalityAccess['Buy_Plan:Documents'] === EDIT)}/>
      {PastMonthpreviousvarianceData.length>0 && <PastMonthForecastCommentsTable data={PastMonthpreviousvarianceData} header={'Historical Will Cover Update Reasons'}/>}
      {CarryVolumeReasonsData.length>0 && <CarryVolumeReasons data={CarryVolumeReasonsData} header={'Carry Volume Reasons'}/>}
      {(accessControlReview!== EDIT && isRecallApproval != 'CLOSED') ? <ApproverComments userid={userid} date={date} wfId={selectedRow.workFlowId} authState={authState} edit={EDIT} handleEdit={handleEdit} approverCommentsData={approverCommentsData} accessControlComment={accessControlComment} commentsTrigger={commentsTrigger} setCommentsTrigger={setCommentsTrigger} setShowErrorDialog={setShowErrorDialog}/> :
      <CommentsTable DisableComments={false} clName={'buyplanApproverComments'} isApprover={1} saveComment={DialogService.saveComment} editComment={DialogService.editComment} text={'Approver Comments'} setShowErrorDialog={setShowErrorDialog} commentsTrigger={commentsTrigger} setCommentsTrigger={setCommentsTrigger} userid={userid} date={date} wfId={selectedRow.workFlowId} authState={authState} edit={EDIT} handleEdit={handleEdit} commentsData={approverCommentsData} accessControlComment={accessControlComment} accessControlReview={accessControlReview}/>}
      <CommentsTable DisableComments={false} clName={'buyplanComments'} isApprover={0} saveComment={DialogService.saveComment} editComment={DialogService.editComment} setShowErrorDialog={setShowErrorDialog} commentsTrigger={commentsTrigger} setCommentsTrigger={setCommentsTrigger} userid={userid} date={date} wfId={selectedRow.workFlowId} authState={authState} edit={EDIT} handleEdit={handleEdit} commentsData={commentsData} accessControlComment={accessControlComment} />
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
          </BootstrapDialogTitle> */}
          <DialogContent>
            <Typography
              sx={{padding: '10px 10px 0px 10px', font: '16px', color: '#6D7888', font: 'Segoe UI, Semibold'}}>
              <span>Plan {planStatus}</span>
            </Typography>
          </DialogContent>
          <AppButton3 onClick={() => handleClose()} label="Ok" customCSS={{transform: 'translate(24%, -20%)', marginBottom: '20px'}} />
        </BootstrapDialog>
      </div>

      <div>
        <BootstrapDialog
          onClose={handleClose2}
          aria-labelledby="customized-dialog-title"
          open={open2}
        >
          {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
          </BootstrapDialogTitle> */}
          <DialogContent>
            <Typography
              sx={{padding: '10px 10px 0px 10px', font: '16px', color: '#6D7888', font: 'Segoe UI, Semibold'}}>
              <span>Are you sure you want to reject the plan ?</span>
            </Typography>
          </DialogContent>
          <div style={{display: 'flex', gap: '15px', justifyContent: 'center'}}>
            <AppButton4 onClick={() => setOpen2(false)} label="No" customCSS={{marginBottom: '20px', background: 'white', color: '#6D7888'}} />
            <AppButton3 onClick={() => handleClickOpen2()} label="Yes" customCSS={{marginBottom: '20px'}} />
          </div>
        </BootstrapDialog>
      </div>
      <AppDialog open={showSuccessDialog} handleClose={() => {
        setShowConfirmDialog(false);
        setShowSuccessDialog(false);
        setsCarryValueReasonPopUp(false);
        setshowPreviousMonthDifference(false);
      }} text={showMessage}/>

      <div>
        <BootstrapDialog
          onClose={handleClose3}
          aria-labelledby="customized-dialog-title"
          open={open3}
        >
          {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
          </BootstrapDialogTitle> */}
          <DialogContent>
            <Typography
              sx={{padding: '10px 10px 0px 10px', font: '16px', color: '#6D7888', font: 'Segoe UI, Semibold'}}>
              <span>Are you sure you want to recall the plan ?</span>
            </Typography>
          </DialogContent>
          <div style={{display: 'flex', gap: '15px', justifyContent: 'center'}}>
            <AppButton4 onClick={() => setOpen3(false)} label="No" customCSS={{marginBottom: '20px', background: 'white', color: '#6D7888'}} />
            <AppButton3 onClick={() => handleClickOpen3()} label="Yes" customCSS={{marginBottom: '20px'}} />
          </div>
        </BootstrapDialog>
      </div>
      <AppErrorDialog open={showErrorDialog!==false} handleClose={() => {
        setShowErrorDialog(false);
        setshowPreviousMonthDifference(false);
      }} text={showErrorDialog && showErrorDialog!==true ? showErrorDialog : 'Error occured when saving'}/>
      {/* <AppErrorDialog open={showErrorDialog2!==false} handleClose={() => setShowErrorDialog2(false)} text={showErrorDialog2 && showErrorDialog2!==true ? showErrorDialog2 : 'Error occured when sending data to atlasfx'}/> */}
      <AppErrorDialog open={errorData} handleClose={() => setErrorData('')} text={errorData? errorData : 'Error occured when sending data to atlasfx'}/>

      <AppErrorDialog open={forecastSendFlagFaiStatus.status} handleClose={() => setForecastSendFlagFaiStatus({status: false, errMsg: 'Error occured while sending the Forecast to AtlasFX'})} text={'Error occured when sending the Forecast to AtlasFX'}/>
      <AppDialog open={forecastSendFlagSucStatus.status} handleClose={() => {
        setForecastSendFlagSucStatus({status: false, sucMsg: 'Forecast details have been sent to AtlasFX. The file will be available for download in a few minutes.'});
      }} text='Forecast details have been sent to AtlasFX. The file will be available for download in a few minutes.'/>
      <AppConfirmDialog open={showApproveConfirmDialog} handleClose={()=>{
        setshowApproveConfirmDialog(false);
      }} text={'The Buy Plan has compliance events.'} secondLineText={' Do you still want to approve the plan?'} setYes={approvalCall} ifYesFirst ={false}/>

      <AppConfirmDialog open={showConfirmDialog} handleClose={()=>{
        setShowConfirmDialog(false);
        setSubmitPressed(false);
      }} text={'Once Buy Plan is submitted it will not be editable.'} secondLineText={'Are you sure you want to submit the plan?'} setYes={()=>{
        setShowConfirmDialog(false);
        setSubmitPressed(true);
        if (+data[39]['sCarryValue']!==0 && +data[39]['sCarryValue']<=1 && +data[39]['sCarryValue']>=-1 && wfDetails.is_carry_vol_enabled==true && checkHedgingHasSupplier ) {
          seterrorHappen(false);
          setsCarryValueReasonPopUp(true);
        } else if ((+data[39]['sCarryValue']>1 || +data[39]['sCarryValue']<-1) && wfDetails.is_carry_vol_enabled==true && checkHedgingHasSupplier) {
          seterrorHappen(true);
          setsCarryValueReasonPopUp(true);
        } else {
          submitBuyPlan();
        }
      }} ifYesFirst ={false}/>

      <AppConfirmDialog open={showAtlasConfirm} handleClose={()=>{
        setshowAtlasConfirm(false);
      }} text={`Forecast file for ${programName} will be sent to AtlasFx.  Do you Confirm?`} secondLineText={''} setYes={atlasFxCall} ifYesFirst ={false}/>

      {/* Must cover may cover tolerance Popup */}
      <MustMayWillCoverVariance open={mustMayTolerance} handleClose={()=>{
        setmustMayTolerance(false);
        setSubmitPressed(false);
      }} text={mustMayToleranceErrors} secondLineText={''} setYes={()=>saveDataFunction(saveddataPayload, submitPressed)} ifYesFirst ={false}/>

      <CarryValuePopUp handleClose={()=>{
        seterrorHappen(false);
        setSubmitPressed(false);
        setsCarryValueReasonPopUp(false);
      }} errorHappen={errorHappen} seterrorHappen={seterrorHappen} handleSubmit={handleCarryValueReason} isOpen={sCarryValueReasonPopUp} sCarryValueReason={sCarryValueReason} setsCarryValueReason={setsCarryValueReason} />
      <PreviousMonthBuyPlanPopUp isPreviousYearData={isPreviousYearData} selectedYear={selectedRow.executionMonthYear[0]} execMonthDecider={execMonthDecider} years={years} data={previousMonthDifferenceData} setData={setPreviousMonthDifferenceData} isOpen={showPreviousMonthDifference} handleClose={()=>{
        setshowPreviousMonthDifference(false);
        setSubmitPressed(null);
      }} handleSubmit={handlePreviousMonthBuyPlanDifferenceSubmit}/>

      <BulkUploadError open={bulkErrorsOpen} handleClose={() => {
        setBulkErrorsOpen(false);
        setShowConfirmDialog(false);
        setBulkErrors([]);
        setshowPreviousMonthDifference(false);
        setSubmitPressed(null);
      } } text={bulkErrors} heading='Please fill in all the mandatory items below' />
    </div>
  );
};

export default memo(BuyPlanTable);
