import React from 'react';
import {DataSheetGrid, keyColumn, textColumn} from 'react-datasheet-grid';
import ReadOnlyColumn from '../common/ReadOnlyColumn';

const PreviousVarianceTable = ({data}) => {
  const columns = [
    {
      ...keyColumn('lastUpdatedUser', textColumn),
      title: 'Author',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('lastUpdatedOn', textColumn),
      title: 'Date',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('quarter', textColumn),
      title: 'Quarter',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('variance', textColumn),
      title: 'Variance %',
      component: ({rowData, focus}) => (
        <ReadOnlyColumn rowData={rowData} columnName='variance' />
      ),
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('varianceReason', textColumn),
      title: 'Variance Reason',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('comment', textColumn),
      title: 'Comments',
      disabled: true,
    },
  ];
  return (
    <div style={{marginTop: '10px'}}>
      <div style={{marginBottom: '10px'}} className="">Forecast Variance Reasons</div>
      <DataSheetGrid
        value={data}
        columns={columns}
        className='savedDatasheetGridCoverageTable docDatasheet'
        disableContextMenu={true}
        gutterColumn={false}
      />
    </div>
  );
};

export default PreviousVarianceTable;
