/* eslint-disable max-len */
/* for target screen api call and managing the global store data using redux store*/
import {createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../../apis/axiosInstance';

export const Common = createSlice({
  name: 'common',
  initialState: {
    varianceReasons: [],
    quaters: [],
    playBookReasons: [],
  },
  reducers: {

    setVarianceReasons: (state, action) => {
      if (action.payload) {
        state.varianceReasons=action.payload;
      }
    },
    setQuatersData: (state, action) => {
      if (action.payload) {
        state.quaters=action.payload;
      }
    },
    setTargetPlayBookReason: (state, action) => {
      if (action.payload) {
        state.playBookReasons=action.payload;
      }
    },
  },
});

export const fetchVarianceReasons = () => async (dispatch) => {
  axiosInstance.get(`/workflow/staticData?key=FORECAST_VARIANCE`)
      .then((resp) => {
        dispatch(setVarianceReasons(resp.data));
      })
      .catch((err) => {
        // dispatch(getVarianceReasonsFailed(err));
        console.error('Error fetchVarianceReasons api call err : ', err);
      });
};

export const fetchQuaters = () => async (dispatch) => {
  axiosInstance.get(`/workflow/staticData?key=FORECAST_VARIANCE`)
      .then((resp) => {
        dispatch(setQuatersData(resp.data));
      })
      .catch((err) => {
        // dispatch(getVarianceReasonsFailed(err));
        console.error('Error fetchVarianceReasons api call err : ', err);
      });
};

export const fetchTargetPlayBookReason = () => async (dispatch) => {
  axiosInstance.get(`/target/updateTypes`)
      .then((resp) => {
        dispatch(setTargetPlayBookReason(resp.data));
      })
      .catch((err) => {
        // dispatch(getVarianceReasonsFailed(err));
        console.error('Error fetchTargetReason api call err : ', err);
      });
};

export const {
  setVarianceReasons,
  setQuatersData,
  setTargetPlayBookReason,
} = Common.actions;

// this is for configureStore
export default Common.reducer;
