/* eslint-disable react-hooks/exhaustive-deps */
import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {textColumn, keyColumn, intColumn,
  DataSheetGrid,
} from 'react-datasheet-grid';
import Styles from '../../styles/workflowModal';

import 'react-datasheet-grid/dist/style.css';
import {AppButton} from '../common/AppButton/AppButton';
import './coverage.css';
import SaveCoverage from './SaveCoverage/HistoricalSaveCoverage';
import DialogService from '../../services/dialogService';
import {monthArr, roundToTwo} from '../../services/utils';
import config from '../../Utils/Config';
import ConfigurationServices from '../../services/ConfigurationServices';

const EDIT = config.AccessLevel.Edit;
const gridCellStyles = {color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};

export default function HistoricalCoverageMokup({wfDetails, selectedRow, selectedYear, allYears, prevNonSuspendedmonth}) {
  const [coverageData, setCoverageData] = useState([[]]);
  const [changeCoverageTable, setChangeCoverageTable] = useState(true);
  const [editaccessControl, seteditaccessControl]=useState('');
  const [coverageVolume, setCoverageVolume] = useState('');
  const [weightedPrice, setWeightedPrice] = useState('');
  const [coveragesSaved, setCoveragesSaved] = useState(false);

  const [currentRole, setCurrentRole] = useState(null);
  const [nextMonthCloseBuyPlanTag, setNextMonthCloseBuyPlanTag] = useState(false);
  const [sectorCountryMap, setSectorCountryMap] = useState(null);

  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  const [dataLoadSuccess, setDataLoadSuccess] = useState(false);

  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);
  function updateTotalValues() {
    let totVolume = 0;
    // let totPrice = 0;
    let weightedPrice = 0;
    const currYear = new Date().getFullYear();
    const diff = Number(selectedYear) < currYear ? 0 : Number(selectedYear) - currYear;
    if (coverageData.length > 0) {
      coverageData[diff]?.map((item) => {
        totVolume += Number(item.coverageVolume) || 0;
        // totPrice += Number(item.coveredPrice) || 0;
        weightedPrice += Number(item.coverageVolume * item.coveredPrice) || 0;
      });
    }
    setWeightedPrice((weightedPrice/totVolume).toFixed(+wfDetails?.decimal_scale) === 'NaN' ? 0 : (+weightedPrice/+totVolume).toString().split('.').length>1? roundToTwo(+wfDetails?.decimal_scale, +weightedPrice/+totVolume).toLocaleString('en-US') : (+weightedPrice/+totVolume).toLocaleString('en-US'));
    setCoverageVolume(totVolume.toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, totVolume).toLocaleString('en-US') : (totVolume===0 ? 0 : Number(totVolume)) );
  }

  useEffect(()=>{
    updateTotalValues();
  }, [coverageData]);

  useEffect(()=>{
    if (coveragesSaved) {
      updateTotalValues();
      setCoveragesSaved(false);
    }
  }, [coveragesSaved]);

  useEffect(()=>{
    const todayRef = new Date();
    const currentYear = todayRef.getFullYear();
    const currentMonth = (todayRef.getMonth()) + 1;
    const currentDate = getCurrentMonthWDCount();
    let flagToUpdate = false;
    const checkYearAndMon = selectedRow.executionMonthYear;
    if (currentYear >= checkYearAndMon[0]) {
      if (currentYear == checkYearAndMon[0]) {
        if (currentMonth===checkYearAndMon[1]) {
          flagToUpdate = false;
        } else if (currentMonth>checkYearAndMon[1]) {
          if (((currentMonth - checkYearAndMon[1]) === 1)) {
            if (currentDate>=6) {
              flagToUpdate = true;
            } else {
              flagToUpdate = false;
            }
          } else {
            flagToUpdate = true;
          }
        }
      } else {
        if ((currentMonth==1 && checkYearAndMon[1]==12) ) {
          if (currentDate>=6) {
            flagToUpdate = true;
          } else {
            flagToUpdate = false;
          }
        } else {
          flagToUpdate = true;
        }
      }
    } else {
      flagToUpdate = true;
    }
    setNextMonthCloseBuyPlanTag(flagToUpdate);
  }, [selectedRow, currentRole]);

  const getCurrentMonthWDCount = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    let worked = 0;
    for (let i = 1; i <= today.getDate(); i++) {
      const testDate = new Date(year, month, i);
      const day = testDate.getDay();
      if (day > 0 && day < 6) {
        ++worked;
      }
    }
    return worked;
  };

  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Buy_Plan:Coverage']) {
      seteditaccessControl(functionalityAccess['Buy_Plan:Coverage']);
    }
  }, [functionalityAccess]);

  const columns = [
    {...keyColumn('executionMonth', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['executionMonth']!==null && (rowData['executionMonth'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['executionMonth']).toLocaleString('en-US'): rowData['executionMonth'].toLocaleString('en-US'))}</div>

      ),
      title: 'Plan Month', disabled: 'true'},
    {...keyColumn('coverageYearMonth', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coverageYearMonth']!==null && (rowData['coverageYearMonth'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coverageYearMonth']).toLocaleString('en-US'): rowData['coverageYearMonth'].toLocaleString('en-US'))}</div>

      ),
      title: 'Coverage Month', disabled: 'true'},
    {...keyColumn('sourceType', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['sourceType']!==null && (rowData['sourceType'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['sourceType']).toLocaleString('en-US'): rowData['sourceType'].toLocaleString('en-US'))}</div>

      ),
      title: 'Source Type', disabled: 'true'},
    {...keyColumn('sname', textColumn),
      component: ({rowData, focus}) => (
        <div style={{color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['sname']!==null && (rowData['sname'])}</div>
      ),
      title: 'Sector', disabled: 'true'},
    {...keyColumn('rname', textColumn),
      component: ({rowData, focus}) => (
        <div style={{color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{rowData['rname']!==null && (rowData['rname'])}</div>
      ),
      title: 'Country', disabled: 'true'},
    {...keyColumn('coverageVolume', intColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coverageVolume']!==null && (rowData['coverageVolume'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coverageVolume']).toLocaleString('en-US'): rowData['coverageVolume'].toLocaleString('en-US'))}</div>

      ),
      title: 'Coverage Volume', disabled: 'true'},
    {...keyColumn('coverageUnit', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coverageUnit']!==null && (rowData['coverageUnit'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coverageUnit']).toLocaleString('en-US'): rowData['coverageUnit'].toLocaleString('en-US'))}</div>

      ),
      title: 'Coverage Unit', disabled: 'true'},
    {...keyColumn('coveredPrice', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coveredPrice']!==null && (rowData['coveredPrice'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coveredPrice']).toLocaleString('en-US'): rowData['coveredPrice'].toLocaleString('en-US'))}</div>

      ),
      title: 'Covered Price', disabled: 'true'},
    {...keyColumn('coveredPriceCurrency', textColumn),
      component: ({rowData, focus}) => (
        <div style={gridCellStyles}>{rowData['coveredPriceCurrency']!==null && (rowData['coveredPriceCurrency'].toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, rowData['coveredPriceCurrency']).toLocaleString('en-US'): rowData['coveredPriceCurrency'].toLocaleString('en-US'))}</div>

      ),
      title: 'Covered Price Currency', disabled: 'true'},
  ];

  useEffect(() => {
    if (selectedRow.workFlowId && sectorCountryMap != null) {
      getCall(monthArr);
    };
  }, [selectedRow, sectorCountryMap]);

  function getSourceType(selectedRow) {
    if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others == false) {
      return 'SUPPLIER';
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others == false) {
      return 'TREASURY';
    } else if (selectedRow.coverageMode=='NONE' ) {
      return 'OTHER';
    } else return '';
  }

  function capitalizeFirstLetter(stringToModify) {
    const newString = stringToModify.charAt(0).toUpperCase() + stringToModify.slice(1).toLowerCase();
    return newString;
  }

  const getCall = (month) => {
    const temp = [[]];
    DialogService.getHistoricalSectorCoverageData(selectedRow.prevWorkFlowId).then((res) =>{
      res.sort((a, b) => new Date(a['coverageYearMonth']) - new Date(b['coverageYearMonth']));
      res.map(( element) => {
        element.executionMonth=`${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`;
        element.coverageUnit = selectedRow.measuringUnit;
        element.coveredPriceCurrency = selectedRow.currency;
        element.sourceType = capitalizeFirstLetter(element.sourceType);
      });
      // here we are filtering because we need data within the years
      for (let index=0; index < allYears.length; index++) {
        temp[index]=res?.filter((entry) => entry);
      }
      if (temp[0].length === 0) {
        const addDefaultEmptyRow = {
          'id': 'f0',
          'executionMonth': `${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`,
          'coverageUnit': selectedRow.measuringUnit,
          'coveredPriceCurrency': selectedRow.currency,
          'coverageVolume': '',
          'coverageYearMonth': '',
          'coveredPrice': '',
          'sourceType': capitalizeFirstLetter(getSourceType(selectedRow)),
          'sector_id': '',
          'region_id': '',
          'workflowId': selectedRow.workflowId,
        };
        setCoverageData([[addDefaultEmptyRow, ...temp[0]], temp[1], temp[2]]);
      } else {
        setCoverageData(temp);
      }
      setDataLoadSuccess(true);
    });
  };

  const [apiRespDropdownData, setapiRespDropdownData] = useState({});

  useEffect(()=>{
    ConfigurationServices.getHistoricalCoverageSectorRegions(selectedRow.buyPlanId).then((res)=>{
      // console.log(res);
      setapiRespDropdownData(res);
    }).catch((err)=>{
      console.log(err);
    });
  }, [selectedRow]);

  function getRegiondIdList(regionList, countryIds) {
    const mappedCountry = [];
    regionList.regionIdList.forEach((rr)=> {
      countryIds.forEach((country) => {
        if (country.id === rr) {
          mappedCountry.push(country);
        }
      });
    });
    return mappedCountry;
  }

  useEffect(()=>{
    if (apiRespDropdownData != null && apiRespDropdownData !=undefined && typeof apiRespDropdownData !== 'string' && Object.keys(apiRespDropdownData).length>0) {
      const countryIds = [];
      const sectorCountryIds = [];
      apiRespDropdownData['regions'].length>0 && apiRespDropdownData['regions'].map((r)=>{
        countryIds.push({id: r.id, region: r.region});
      });
      apiRespDropdownData['sectorGroups'].length>0 && apiRespDropdownData['sectorGroups'].map((r)=>{
        r['sectors'].map((rs)=>{
          sectorCountryIds.push({id: rs.id, name: rs.name, regionIdList: getRegiondIdList(rs, countryIds)});
        });
      });
      setSectorCountryMap(sectorCountryIds);
    }
  }, [apiRespDropdownData]);
  return (
    <>
      {changeCoverageTable?
      <div>
        {allYears.indexOf(selectedYear) == 0 ?
    <div className="coverage-table">
      <DataSheetGrid value={coverageData[0]} columns={columns} className={'savedDatasheetGridCoverageTable'} disableContextMenu={true} gutterColumn={false}/>
    </div> : (allYears.indexOf(selectedYear) == 1 ?
    <div className="coverage-table">
      <DataSheetGrid value={coverageData[1]} columns={columns} disableContextMenu={true} gutterColumn={false}/>
    </div> :
    <div className="coverage-table">
      <DataSheetGrid value={coverageData[2]} columns={columns} disableContextMenu={true} gutterColumn={false}/>
    </div>)}
        <Styles.StyledCoverageSummBottomButtonBox style={{display: 'flex'}}>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>Total <b>/</b> Average</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'right', marginLeft: '-55px'}}>{coverageVolume !== null && coverageVolume}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'right', marginRight: '-55px'}}>{weightedPrice === 'NaN' ? 0 : weightedPrice}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
          <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        </Styles.StyledCoverageSummBottomButtonBox>
        {editaccessControl === EDIT ?<Box sx={{display: 'flex', gap: '5px', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', color: '#4297d3', font: 'normal normal 400 13px/15px \'Segoe UI\''}}>
          {/* <AppButton disabled={selectedRow.status === 'CLOSED' || (nextMonthCloseBuyPlanTag && !currentRole.includes('Admin')) } onClick={() => setOpen(true)} label="Add Historical Coverages" /> */}
          <AppButton disabled={selectedRow.status === 'CLOSED' || selectedRow.status =='SUSPENDED' || (nextMonthCloseBuyPlanTag && !currentRole.includes('Admin')) || dataLoadSuccess==false } onClick={() => setChangeCoverageTable(false)} label="Edit Coverages" />
        </Box>:''}
      </div>:<SaveCoverage wfDetails={wfDetails} prevNonSuspendedmonth={prevNonSuspendedmonth} getSourceType={getSourceType} sectorCountryMap={sectorCountryMap} setChangeCoverageTable={setChangeCoverageTable} updateRowsvalue={getCall} setCoverageData={setCoverageData} setCoveragesSaved={setCoveragesSaved} apiData={coverageData} selectedRow={selectedRow} allYears={allYears} selectedYear={selectedYear}/>}
    </>);
}
