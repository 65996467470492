import React from 'react';
import Styles from '../../styles/target';
import {utils, writeFileXLSX} from 'xlsx';
import moment from 'moment';


const DownloadEditBuyPlanTableList = ({data, selectedRow, years, wfDetails}) => {
  const {executionMonthYear: [year, month]} = selectedRow;
  const date = moment({year, month: month - 1});
  const formattedDate = date.format('MMM YYYY');

  const onDownload = () => {
    const excelFormattedData = data.map((item, index) => {
      const {
        first,
        second,
        third,
        fourth,
        fourth2,
        fourPointFive,
        fifth,
        sixth,
      } = getRow(item, selectedRow);


      if (index === 39) {
        first.Period = 'Grand Total';
      }

      if (selectedRow?.coverageMode == 'TREASURY') {
        return {...first, ...second, ...third, ...fourth2, ...(selectedRow.others ? fourPointFive : {}), ...fifth, ...sixth};
      }

      if (selectedRow?.coverageMode == 'SUPPLIER') {
        return {...first, ...second, ...third, ...fourth, ...(selectedRow.others ? fourPointFive : {}), ...fifth, ...sixth};
      }

      if (selectedRow?.coverageMode == 'BOTH') {
        return {...first, ...second, ...third, ...fourth, ...fourth2, ...(selectedRow.others ? fourPointFive : {}), ...fifth, ...sixth};
      }

      if (selectedRow?.coverageMode == 'NONE') {
        return {...first, ...second, ...third, ...(selectedRow.others ? fourPointFive : {}), ...fifth, ...sixth};
      }
    });
    const wb = utils.book_new();
    const colWidths = [{wch: 150}];
    wb['!cols'] = colWidths;

    const fileName = `${selectedRow.commodity}-${selectedRow.subCommodity}-${selectedRow.sector}-${selectedRow.region}-${formattedDate}`;

    const ws = utils.json_to_sheet(excelFormattedData);
    utils.book_append_sheet(wb, ws, `${years[0]} | ${years[1]} |${years[2]}`);

    writeFileXLSX(wb, `${fileName}.xlsx`);
  };

  const getRow = (item, selectedRow) => {
    const first = {
      Year: item?.planYear || '',
      Period: item?.period || '',
    };
    const second = {
      'Last month-Overall volumeLE': item?.volumeLE?.toString() || '',
      'Last month-Overall Coverage': item?.coverage?.toString() || '',
      'Last month-Overall Coverage%': item?.coveragePercent?.toString() || '',
      'Last month-Overall Variance Reason': item?.varianceReason || '',
      'Last month-Overall Comments': item?.isPrevAndActualCovSame == true ? item?.comments || '' : '',
    };


    let fourth = {};

    if (selectedRow.targetCoverageMode === 'SUPPLIER' || selectedRow.targetCoverageMode === 'BOTH') {
      if (selectedRow.supplierOptionality) {
        fourth ={'Supplier Last Month Coverage': item?.sLastMonthCoverage?.toString() || '',
          'Supplier Target % Min': item?.stargetPercentMin?.toString() || '',
          'Supplier Target % Max': item?.stargetPercentMax?.toString() || '',
          'Supplier Min Coverage': item?.sminCoverage?.toString() || '',
          'Supplier Max Coverage': item?.smaxCoverage?.toString() || '',
          'Supplier "Must" Cover': item?.smustCover?.toString() || '',
          'Supplier "May" Cover': item?.smayCover?.toString() || '',
          'Supplier "Will" cover': item?.swillCover?.toString() || '',
          ...(wfDetails.is_carry_vol_enabled === true ? {'Supplier "Carry" Volume': item?.sCarryValue?.toString() || ''} : {}),
        };
      } else {
        fourth={'Supplier Last Month Coverage': item?.sLastMonthCoverage?.toString() || '',
          'Supplier Target % Min': item?.stargetPercentMin?.toString() || '',
          'Supplier Min Coverage': item?.sminCoverage?.toString() || '',
          'Supplier "Must" Cover': item?.smustCover?.toString() || '',
          'Supplier "Will" cover': item?.swillCover?.toString() || '',
          ...(wfDetails.is_carry_vol_enabled === true ? {'Supplier "Carry" Volume': item?.sCarryValue?.toString() || ''} : {}),
        };
      }
    } else {
      fourth = {'Supplier Last Month Coverage': item?.sLastMonthCoverage?.toString() || '',
        'Supplier "Will" cover': item?.swillCover?.toString() || '',
        ...(wfDetails.is_carry_vol_enabled === true ? {'Supplier "Carry" Volume': item?.sCarryValue?.toString() || ''} : {}),
      };
    }

    let fourth2 = {};

    if (selectedRow.targetCoverageMode === 'TREASURY' || selectedRow.targetCoverageMode === 'BOTH') {
      if (selectedRow.treasuryOptionality ) {
        fourth2 = {'Treasury Last Month Coverage': item?.tLastMonthCoverage?.toString() || '',
          'Treasury Target % Min': item?.ttargetPercentMin?.toString() || '',
          'Treasury Target % Max': item?.ttargetPercentMax?.toString() || '',
          'Treasury Min Coverage': item?.tminCoverage?.toString() || '',
          'Treasury Max Coverage': item?.tmaxCoverage?.toString() || '',
          'Treasury Must Cover': item?.tmustCover?.toString() || '',
          'Treasury May Cover': item?.tmayCover?.toString() || '',
          'Treasury Will Cover': item?.twillCover?.toString() || ''};
      } else {
        fourth2 = {'Treasury Last Month Coverage': item?.tLastMonthCoverage?.toString() || '',
          'Treasury Target % Min': item?.ttargetPercentMin?.toString() || '',
          'Treasury Min Coverage': item?.tminCoverage?.toString() || '',
          'Treasury Must Cover': item?.tmustCover?.toString() || '',
          'Treasury Will Cover': item?.twillCover?.toString() || ''};
      }
    } else {
      fourth2 = {'Treasury Last Month Coverage': item?.tLastMonthCoverage?.toString() || '',
        'Treasury Will Cover': item?.twillCover?.toString() || ''};
    }


    const fourPointFive = {
      'Others Last month coverage': item?.oLastMonthCoverage?.toString() || '',
      'Others Will Cover': item?.owillCover?.toString() || '',
    };

    const fourPointFive2 = {
      'Others Will Cover': item?.owillCover?.toString() || '',
    };

    const fifth2 = {
      'Overall-Monthly Will Cover': item?.overAllWillCover?.toString() || '',
    };

    const fifth = {
      'Overall-Monthly Will Cover': item?.overAllWillCover?.toString() || '',
      'Overall-Monthly New Coverage': item?.overAllCoverage?.toString() || '',
      'Overall-Monthly New Coverage %': item?.overAllCoveragePercent?.toString() || '',
    };

    let sixth = {};
    let third = {};
    if (selectedRow.optionality) {
      sixth = {
        'Quarterly New Coverage %': item?.newCoveragePercent?.toString() || '',
        'Quarterly Target Coverage % min': item?.targetCoveragePercentMin?.toString() || '',
        'Quarterly Target Coverage % max': item?.targetCoveragePercentMax?.toString() || '',
        'Quarterly Target Variance %': item?.targetVariancePercent?.toString() || '',
        'Quarterly Variance Reason': item?.qvarianceReason || '',
        'Quarterly Comments': item?.qcomments || '',
      };

      third = {
        'Overall Updated Fcst': item?.updatedFcst?.toString() || '',
        'Overall Target %': item?.targetPercentMin?.toString() || '',
        'Overall Overall Target % Max': item?.targetPercentMax?.toString() || '',
        'Overall Min Coverage': item?.minCoverage?.toString() || '',
        'Overall Max Coverage': item?.maxCoverage?.toString() || '',
        'Overall Must Coverage': item?.mustCover?.toString() || '',
        'Overall May Coverage': item?.mayCover?.toString() || '',
      };
    } else {
      sixth = {
        'Quarterly New Coverage %': item?.newCoveragePercent?.toString() || '',
        'Quarterly Target Coverage %': item?.targetCoveragePercentMin?.toString() || '',
        'Quarterly Target Variance %': item?.targetVariancePercent?.toString() || '',
        'Quarterly Variance Reason': item?.qvarianceReason || '',
        'Quarterly Comments': item?.qcomments || '',
      };

      third = {
        'Overall Updated Fcst': item?.updatedFcst?.toString() || '',
        'Overall Target %': item?.targetPercentMin?.toString() || '',
        'Overall Min Coverage': item?.minCoverage?.toString() || '',
        'Overall Must Coverage': item?.mustCover?.toString() || '',
      };
    }

    return {
      first,
      second,
      third,
      fourth,
      fourth2,
      fourPointFive,
      fourPointFive2,
      fifth2,
      fifth,
      sixth,
    };
  };

  if (!(data?.length > 0)) {
    return <></>;
  }
  return (
    <Styles.StyledDownloadButton style={{margin: '0px'}} onClick={onDownload}>
        Export
    </Styles.StyledDownloadButton>
  );
};

export default DownloadEditBuyPlanTableList;

