/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DataSheetGrid, textColumn, keyColumn, floatColumn} from 'react-datasheet-grid';
// DataSheetGridPropsBase
// Import the style only once in your app!
import 'react-datasheet-grid/dist/style.css';
import DialogService from '../../../services/dialogService';
import {monthArr, months, roundToTwo, titleCase} from '../../../services/utils';
// import {AppDialog} from '../common/App-Dialog/AppDialog';
import {AppTableInput} from '../../common/AppTableInput/AppTableInput';
// import {CopyIcon} from '../../common/Icons/icons';
import './forecast.css';
import {AppButton} from '../../common/AppButton/AppButton';
// import {defaultObj} from './consts';
import {forecastDefaultData, fyCalculateTotal, getSortedMonthlyData, monthNYearSetter, rowsSetter} from './utils';
import {convertToCamelCase, updateStatusOfBuyPlan, updateStatusOfNonPlayBookData} from '../../../redux/features/apiCall';
import {AppDialog, AppDialog3, AppErrorDialog} from '../../common/App-Dialog/AppDialog';
import {setForecastUpdatesaveButton} from '../../../redux/features/configurations';
import ReadOnlyColumn from '../../common/ReadOnlyColumn';
// import {CopyHoverStyles} from './CopyHoverText';
import {changeButtonStatusValue, changeBuyplanStatus} from '../../../redux/features/buttonStatus';

// const CopyRow = ({copyRow, rowData}) => {
//   const [showCopyDescription, setShowCopyDescription] = useState(false);
//   // eslint-disable-next-line no-unused-vars
//   const [copyDescription, setCopyDescription] = useState('Replicate Forecast');
//   return (
//     <Button
//       sx={{width: '90px', position: 'relative'}}
//       onClick={(e) => {
//         e.preventDefault();
//         copyRow(rowData);
//       }}
//       onMouseEnter={() => setShowCopyDescription(true)}
//       onMouseLeave={() => setShowCopyDescription(false)}
//     >
//       <CopyIcon />
//       {showCopyDescription ? (
//         <Box sx={{position: 'absolute', width: '100px', fontSize: '10px', textTransform: 'capitalize', top: '20px', ...CopyHoverStyles}}>
//           {copyDescription}
//         </Box>
//       ) : (
//         ''
//       )}
//     </Button>
//   );
// };

const ForecastTable = ({
  subMissionData,
  access,
  setSubmissionData,
  years,
  setTabsEnableCheck,
  data,
  setData,
  setModifiedData,
  selectedYear,
  modifiedData,
  selectedRow,
  isForecastUpdateTabEditable,
  NonPlayBookForecastUpdate1,
  NonPlayBookForecastUpdate2,
  NonPlayBookForecastUpdate3,
  setNonPlayBookForecastUpdate1,
  setNonPlayBookForecastUpdate2,
  setNonPlayBookForecastUpdate3,
}) => {
  const buttonStatus = useSelector((state) => state.btnStatus.value);
  const curMonth = +selectedRow.execution_month[1] - 3;
  // eslint-disable-next-line no-unused-vars
  const [forecastData, setForecastData] = useState([]); // useless
  // eslint-disable-next-line no-unused-vars
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false); // useless
  const [columns, setColumns] = useState([]);
  const [workflowIdsObj, setWorkflowIdsObj] = useState({});

  const [showSuccessDialog2, setShowSuccessDialog2] = useState(false);
  const [showPreviousMonthSuccess, setShowPreviousMonthSuccess] = useState(false);
  const [showSuccessDialog3, setShowSuccessDialog3] = useState(false);

  const [isDataFatched, setIsDataFatched] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dataCopyTrigger, setDataCopyTrigger] = useState(1);
  const [row, setRow] = useState({});
  const [varianceTrigger, setVarianceTrigger] =useState(0);

  const [previousVarianceTrigger, setPreviousVarianceTrigger] = useState(false);

  const [previousMonthForecastEditButton, setPreviousMonthForecastEditButton] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(selectedRow);
  }, [selectedRow]);

  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId = useSelector((state) => state?.roleBasedAccess?.currentRoleId);
  const availableRoles = useSelector((state) => state?.roleBasedAccess?.availableRoles);

  const pageStatus = useSelector((state) => state.btnStatus.page);
  const buyplanStatus = useSelector((state) => state.btnStatus.status);
  useEffect(() => {
    if (currentRoleId) {
      availableRoles.map((role) => {
        if (currentRoleId === role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);

  useEffect(() => {
    if (currentRole !== null) {
      if (currentRole == 'Forecast Creator') {
        if (
          ((selectedRow?.isHistoricalDataEnabled == true && selectedRow?.prev_wf_id > 0) || selectedRow?.isHistoricalDataEnabled == false) &&
          pageStatus == 'active'
        ) {
          if (selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' || selectedRow.status == 'REJECTED') {
            dispatch(changeButtonStatusValue('edit'));
          } else {
            dispatch(changeButtonStatusValue('view'));
          }
        } else {
          // removing edit button
          dispatch(setForecastUpdatesaveButton(false));
          dispatch(changeButtonStatusValue('view'));
        }
      }
    }
  }, [currentRole]);

  useEffect(() => {
    getCall();
  }, [selectedRow]);

  useEffect(() => {
    // console.log(buyplanStatus);
    if (selectedRow.isHistoricalDataEnabled == false) {
      if (
        (buyplanStatus && buyplanStatus != 'NEW' && selectedRow.prev_wf_id > 0 && selectedRow.forecastSubmitted) ||
        selectedRow.status == 'CLOSED' ||
        (buttonStatus == 'view' && buyplanStatus != 'NEW' && selectedRow.forecastSubmitted)
      ) {
        // && selectedRow?.status != 'DRAFT'
        setTabsEnableCheck((prev) => {
          return {...prev, buyPlan: true};
        });
      } else {
        setTabsEnableCheck((prev) => {
          return {...prev, buyPlan: false};
        });
      }
    }
  }, [buyplanStatus]);

  useEffect(() => {
    // console.log('@modi selectedYear, modifiedData, access, isDataFatched', selectedYear, modifiedData, access, isDataFatched);
    // checking modifiedData[selectedYear] !== undefined because sometimes we getting undefined
    if (selectedYear && selectedYear != 0 && access && access !== '' && modifiedData[selectedYear] !== undefined && years.length > 0) {
      setColumns([]);
      // console.log('@fsct05 useEffect modifiedData[selectedYear] ', modifiedData[selectedYear]);
      let arr = fyCalculateTotal(modifiedData[selectedYear]);
      // debugger;
      if (arr.length == 1) {
        const copy = JSON.parse(JSON.stringify(workflowIdsObj));

        arr = getSortedMonthlyData(arr, selectedRow, copy, selectedYear, years);
        // using selectedRow.execution_month[0] because if we use new Date().getFullYear() it always takes 2023 current year it will affect for buyplans 2022, 2021, 2026 years
      }
      // sometimes keys are not correct so checking and updating on the basis of planMonth
      // ( months.indexOf(arr[1]['planMonth'].toLowerCase())==11 && months.indexOf(arr[0]['planMonth'].toLowerCase()) == 0)  if we get the arr as jan in first index and dec in second index
      // this condition states that if the data[1].planMonth is Dec and data[0].planMonth is Jan then we are reversing the data[0] and data[1]

      if (
        arr.length &&
        ((months.indexOf(arr[0]['planMonth'].toLowerCase()) !== 11 &&
          months.indexOf(arr[0]['planMonth'].toLowerCase()) > months.indexOf(arr[1]['planMonth'].toLowerCase())) ||
          (months.indexOf(arr[1]['planMonth'].toLowerCase()) == 11 && months.indexOf(arr[0]['planMonth'].toLowerCase()) == 0))
      ) {
        arr[1].key = 1;
        arr[0].key = 2;
        arr = [arr[1], arr[0]];
      }
      if (arr.length && months.indexOf(arr[0]['planMonth'].toLowerCase()) == 11 && months.indexOf(arr[1]['planMonth'].toLowerCase()) == 10) {
        arr[1].key = 1;
        arr[0].key = 2;
        arr = [arr[1], arr[0]];
      }
      if (months.indexOf(arr[0]['planMonth'].toLowerCase()) == 11 && months.indexOf(arr[1]['planMonth'].toLowerCase()) == 0) {
        arr[1].planYear = selectedRow.execution_month[0];
        arr[0].planYear = selectedRow.execution_month[0] - 1;
      }
      // console.log('@fsct05 useEffect arr ', arr);
      setData(arr);

      if (selectedYear == years[0]) {
        setNonPlayBookForecastUpdate1(arr);
      }
      if (selectedYear == years[1]) {
        setNonPlayBookForecastUpdate2(arr);
      }
      if (selectedYear == years[2]) {
        setNonPlayBookForecastUpdate3(arr);
      }
      setTimeout(() => {
        const cols = columnsSetterReadOnly(); // columnsSetter();
        // set columns value as per condition
        if (
          !(
            buttonStatus === 'edit' ||
            (currentRole.includes('Admin') && (selectedRow.status === 'DRAFT' || selectedRow.status === 'REJECTED' || selectedRow.status === 'NEW'))
          ) ||
          (isForecastUpdateTabEditable === false && previousMonthForecastEditButton == false)
        ) {
          //
          setColumns((pre) => [
            {
              ...keyColumn('planMonth', textColumn),
              title: 'Plan Month',
              disabled: true,
            },
            {
              ...keyColumn('planYear', textColumn),
              title: 'Plan Year',
              disabled: true,
            },
            ...cols,
            {
              ...keyColumn('fy', textColumn),
              component: ({rowData, focus}) => <ReadOnlyColumn decider={2} rowData={rowData} columnName="fy" />,
              title: `FY ${selectedYear} Total`,
              disabled: true,
            },
          ]);
        } else {
          if (access === 'Edit') {
            setColumns([
              {
                ...keyColumn('planMonth', textColumn),
                title: 'Plan month',
                disabled: true,
              },
              {
                ...keyColumn('planYear', textColumn),
                title: 'Plan year',
                disabled: true,
              },
              {
                ...keyColumn('jan', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'jan')}
                    columnName="jan"
                    forecastVal={[selectedYear, 1]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jan',
                // disabled: ({rowData}) =>
                // // using this because to minimize code and writing all things in one function
                //   isDataSheetCellDisabled(rowData, 'jan'),
              },
              {
                ...keyColumn('feb', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'feb')}
                    columnName="feb"
                    forecastVal={[selectedYear, 2]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Feb',
                // disabled: ({rowData}) =>
                //   isDataSheetCellDisabled(rowData, 'feb'),
              },
              {
                ...keyColumn('mar', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'mar')}
                    columnName="mar"
                    forecastVal={[selectedYear, 3]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Mar',
                // disabled: ({rowData}) =>
                //   isDataSheetCellDisabled(rowData, 'mar'),
              },
              {
                ...keyColumn('apr', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'apr')}
                    columnName="apr"
                    forecastVal={[selectedYear, 4]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Apr',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'apr'),
              },
              {
                ...keyColumn('may', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'may')}
                    columnName="may"
                    forecastVal={[selectedYear, 5]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'May',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'may'),
              },
              {
                ...keyColumn('jun', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'jun')}
                    columnName="jun"
                    forecastVal={[selectedYear, 6]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jun',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'jun'),
              },
              {
                ...keyColumn('jul', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'jul')}
                    columnName="jul"
                    forecastVal={[selectedYear, 7]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jul',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'jul'),
              },
              {
                ...keyColumn('aug', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'aug')}
                    columnName="aug"
                    forecastVal={[selectedYear, 8]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Aug',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'aug'),
              },
              {
                ...keyColumn('sep', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'sep')}
                    columnName="sep"
                    forecastVal={[selectedYear, 9]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Sep',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'sep'),
              },
              {
                ...keyColumn('oct', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'oct')}
                    columnName="oct"
                    forecastVal={[selectedYear, 10]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Oct',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'oct'),
              },
              {
                ...keyColumn('nov', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'nov')}
                    columnName="nov"
                    forecastVal={[selectedYear, 11]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Nov',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'nov'),
              },
              {
                ...keyColumn('dec', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'dec')}
                    columnName="dec"
                    forecastVal={[selectedYear, 12]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Dec',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'dec'),
              },
              {...keyColumn('fy', textColumn), title: `FY ${selectedYear} Total`, disabled: true},
              // {
              //   component: ({rowData}) =>
              //     rowData?.key == 1 &&
              //     (selectedRow.status == 'DRAFT' ||
              //       selectedRow.status === 'NEW' ||
              //       selectedRow.status === 'NEW_COV_PEND' ||
              //       selectedRow.status === 'REJECTED') ? (
              //       <CopyRow copyRow={copyRow} rowData={rowData} />
              //     ) : (
              //       ''
              //     ),
              //   title: '',
              //   // disabled: true,
              // },
            ]);
          } else {
            setColumns([
              {
                ...keyColumn('planMonth', textColumn),
                title: 'Plan month',
                disabled: true,
              },
              {
                ...keyColumn('planYear', textColumn),
                title: 'Plan year',
                disabled: true,
              },
              {
                ...keyColumn('jan', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'jan')}
                    columnName="jan"
                    forecastVal={[selectedYear, 1]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jan',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'jan'),
              },
              {
                ...keyColumn('feb', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'feb')}
                    columnName="feb"
                    forecastVal={[selectedYear, 2]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Feb',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'feb'),
              },
              {
                ...keyColumn('mar', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'mar')}
                    columnName="mar"
                    forecastVal={[selectedYear, 3]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Mar',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'mar'),
              },
              {
                ...keyColumn('apr', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'apr')}
                    columnName="apr"
                    forecastVal={[selectedYear, 4]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Apr',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'apr'),
              },
              {
                ...keyColumn('may', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'may')}
                    columnName="may"
                    forecastVal={[selectedYear, 5]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'May',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'may'),
              },
              {
                ...keyColumn('jun', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'jun')}
                    columnName="jun"
                    forecastVal={[selectedYear, 6]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jun',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'jun'),
              },
              {
                ...keyColumn('jul', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'jul')}
                    columnName="jul"
                    forecastVal={[selectedYear, 7]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Jul',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'jul'),
              },
              {
                ...keyColumn('aug', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'aug')}
                    columnName="aug"
                    forecastVal={[selectedYear, 8]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Aug',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'aug'),
              },
              {
                ...keyColumn('sep', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'sep')}
                    columnName="sep"
                    forecastVal={[selectedYear, 9]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Sep',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'sep'),
              },
              {
                ...keyColumn('oct', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'oct')}
                    columnName="oct"
                    forecastVal={[selectedYear, 10]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Oct',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'oct'),
              },
              {
                ...keyColumn('nov', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'nov')}
                    columnName="nov"
                    forecastVal={[selectedYear, 11]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Nov',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'nov'),
              },
              {
                ...keyColumn('dec', textColumn),
                component: ({rowData, focus}) => (
                  <AppTableInput
                    setVarianceTrigger={setVarianceTrigger}
                    decider={2}
                    data={arr}
                    subMissionData={subMissionData}
                    setSubmissionData={setSubmissionData}
                    setData={setData}
                    // isDisabled={isDataSheetCellDisabled(rowData, 'dec')}
                    columnName="dec"
                    forecastVal={[selectedYear, 12]}
                    rowData={rowData}
                    focus={focus}
                  />
                ),
                title: 'Dec',
                // disabled: ({rowData}) => isDataSheetCellDisabled(rowData, 'dec'),
              },
              {...keyColumn('fy', textColumn), title: `FY ${selectedYear} Total`, disabled: true},
            ]);
          }
        }
      }, 100);
    }
  }, [selectedYear, access, isDataFatched, isForecastUpdateTabEditable, years, previousMonthForecastEditButton]); // modifiedData

  useEffect(() => {
    if (Object.keys(row).length > 1) {
      // debugger;
      const forecastYearMonth =
        selectedYear === years[0] ? NonPlayBookForecastUpdate1 : selectedYear === years[1] ? NonPlayBookForecastUpdate2 : NonPlayBookForecastUpdate3;
      if (previousMonthForecastEditButton == true) {
        if (selectedYear == years[0]) {
          const monthsData = {};
          for (let i = 0; i < (selectedRow.execution_month[1] == 1 ? 11 : curMonth + 1); i++) {
            monthsData[`${months[i]}`] = forecastYearMonth[0][months[i]];
          }
          const lastData = {...forecastYearMonth[1], ...monthsData};
          forecastYearMonth[1] = {
            ...lastData,
            planMonth: forecastYearMonth[1].planMonth,
            workflow_id: forecastYearMonth[1].workflow_id,
            month: forecastYearMonth[1].month,
            key: forecastYearMonth[1].key,
          };
        }
      } else {
        if (selectedYear == years[0] && previousMonthForecastEditButton !== true) {
          // (previousMonthForecastEditButton==true? curMonth < months.indexOf('oct'):curMonth >= months.indexOf('oct'))
          // console.log(curMonth);
          const monthsData = {};
          if (selectedRow.execution_month[1] == 1) {
            for (let i = 0; i < 11; i++) {
              monthsData[`${months[i]}`] = forecastYearMonth[1][months[i]];
            }
          } else {
            for (let i = 0; i < curMonth + 1; i++) {
              monthsData[`${months[i]}`] = forecastYearMonth[1][months[i]];
            }
          }
          const lastData = {...row, ...monthsData};
          forecastYearMonth[1] = {
            ...lastData,
            planMonth: forecastYearMonth[1].planMonth,
            workflow_id: forecastYearMonth[1].workflow_id,
            month: forecastYearMonth[1].month,
            key: forecastYearMonth[1].key,
          };
        } else {
          forecastYearMonth[1] = {
            ...row,
            planMonth: forecastYearMonth[1].planMonth,
            workflow_id: forecastYearMonth[1].workflow_id,
            month: forecastYearMonth[1].month,
            key: forecastYearMonth[1].key,
          };
        }
      }
      // forecastYearMonth[1] = {...row, planMonth: forecastYearMonth[1].planMonth, workflow_id: forecastYearMonth[1].workflow_id, month: forecastYearMonth[1].month, key: forecastYearMonth[1].key};
      selectedYear === years[0] ?
        setNonPlayBookForecastUpdate1([...forecastYearMonth]) :
        selectedYear === years[1] ?
        setNonPlayBookForecastUpdate2([...forecastYearMonth]) :
        setNonPlayBookForecastUpdate3([...forecastYearMonth]);

      if (selectedRow.status == 'NEW') {
        selectedYear === years[0] ?
          setData([...forecastYearMonth]) :
          selectedYear === years[1] ?
          setData([...forecastYearMonth]) :
          setData([...forecastYearMonth]);
      }
      months.forEach((m, i) => {
        if (document.getElementsByClassName(`${selectedYear}-${m}`)[0] !== undefined) {
          document.getElementsByClassName(`${selectedYear}-${m}`)[0].style.borderColor = '';
        }
      });
    }
  }, [dataCopyTrigger]);

  // const copyRow = (row) => {
  //   setRow(row);
  //   setDataCopyTrigger((prev) => prev + 1);
  // };

  const columnsSetterReadOnly = () => {
    const columns = [];
    months.forEach((mon) => {
      columns.push({
        ...keyColumn(mon, floatColumn),
        title: titleCase(mon),
        disabled: true,
        component: ({rowData, focus}) => (
          <div className={`${selectedYear}-${mon}-data`}>
            <ReadOnlyColumn decider={2} rowData={rowData} columnName={mon} />
          </div>
        ),
      });
    });
    return columns;
  };
  // This function used to call the forecast data and set it inside the modified Data variable inside the index.js
  const getCall = () => {
    DialogService.getNonPlayBookForecastData(selectedRow.workflow_id)
        .then(async (res) => {
          let obj = {};
          res = Object.values(res.map((obj) => convertToCamelCase(obj)));
          if (res.length) {
            setForecastData(res);
            res.forEach((val) => {
              const split = val.forecast_year_month;
              if (
                +split[0] >=
              +(months[selectedRow.execution_month[1] - 1] == 'jan' ? selectedRow.execution_month[0] - 1 : selectedRow.execution_month[0])
              ) {
                if (!workflowIdsObj[val.non_pb_wf_id]) {
                  workflowIdsObj[val.non_pb_wf_id] = val;
                  setWorkflowIdsObj({...workflowIdsObj});
                }
                // This function used to set the month and year to each object
                obj = {...monthNYearSetter(obj, val, selectedRow)};
              }
            });

            // if some data is received ans some not like 2023 year data is there but 2024 data is not
            const creatingRow = await rowsSetter(obj, workflowIdsObj, selectedRow);
            obj = {...creatingRow};
          } else {
          // when there is no data for a all three years and it is first time Not started forecast
            obj = forecastDefaultData(selectedRow, years);
          // console.log(obj);
          // console.log({2023:});
          }
          // console.log('@fsct getCall ModifiedData ', obj);
          setModifiedData({...obj});
          setIsDataFatched(true);
        })
        .catch((err) => console.log(err));
  };

  const updateStatus = () => {
    DialogService.updateNonPlayBookStatus(selectedRow.workflow_id, 'DRAFT')
        .then((res) => {
          if (res.status == 200 || res === true) {
            dispatch(updateStatusOfNonPlayBookData({id: selectedRow.workflow_id, status: 'DRAFT'}));
            dispatch(setForecastUpdatesaveButton(false));
            setShowSuccessDialog2(true);
            dispatch(changeBuyplanStatus('DRAFT'));
          }
          if (res['errorMessage'] !== undefined && res['errorMessage'].includes('Existing Status:DRAFT Requested Status:DRAFT')) {
            dispatch(updateStatusOfBuyPlan(selectedRow.workflow_id, 'DRAFT'));
            // setIsSavePopupVisible(true);
            setTabsEnableCheck((prev) => {
              return {...prev, buyPlan: true};
            });
            dispatch(changeBuyplanStatus('DRAFT'));
            dispatch(setForecastUpdatesaveButton(false));
            setShowSuccessDialog2(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowErrorDialog(true);
        });
  };

  // const isDataSheetCellDisabled = (rowData, month) => {
  //   const flag = months.indexOf(month);
  //   const is =
  //     access === 'Edit' ? // selectedRow.status == 'NEW' || selectedRow.status == 'DRAFT' ?
  //         buttonStatus === 'edit' ||
  //         (currentRole.includes('Admin') && (selectedRow.status === 'DRAFT' || selectedRow.status == 'REJECTED' || selectedRow.status == 'NEW')) ? // here when the month is jan 2023 then we need to make year as selectedRow.execution_month[0]-1 because that previous month is Dec 2022
  //           //  (selectedRow.execution_month[1]==1? flag!==11 :curMonth >= flag)  we checking this because if month is jan 2023 then for year 2021 only dec will has to editable
  //           rowData?.key == 1 ||
  //           (selectedYear !== years[0] && previousMonthForecastEditButton == true) ||
  //           (selectedYear == years[0] &&
  //             (previousMonthForecastEditButton == true ?
  //               selectedRow.execution_month[1] == 1 ?
  //                 flag == 11 :
  //                 curMonth < flag :
  //               selectedRow.execution_month[1] == 1 ?
  //               flag !== 11 :
  //               curMonth >= flag)) ?
  //           true :
  //           false :
  //         true :
  //       true;
  //   return is;
  // };

  const CommaRemoval = (val) => {
    // const re = new RegExp(`^[0-9]{1,2}([,.][0-9]{1,2})?$`);
    // const flag = re.test(val.toString());
    // if (flag) {
    return (isNaN(val?.toString().replaceAll(',', '')) ? 0 : val?.toString().replaceAll(',', '')) || '';
    // }
  };

  // useEffect(() => {
  //   if (skipper) pasteCall();
  // }, [data]);
  const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const splitArray = formetedNumber.split('.');
    if (splitArray.length > 1) {
      if (!(+splitArray[1] > 0)) formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  useEffect(() => {
    let total = 0;
    if (
      isForecastUpdateTabEditable &&
      NonPlayBookForecastUpdate1 !== null &&
      NonPlayBookForecastUpdate1 !== undefined &&
      NonPlayBookForecastUpdate1.length > 0
    ) {
      Object.keys(NonPlayBookForecastUpdate1[1]).forEach((val) => {
        if (months.includes(val)) {
          total += isNaN(+roundToTwo(2, CommaRemoval(NonPlayBookForecastUpdate1[1][val]))) ?
            0 :
            +roundToTwo(2, CommaRemoval(NonPlayBookForecastUpdate1[1][val]));

          NonPlayBookForecastUpdate1[1][val] = isNaN(NonPlayBookForecastUpdate1[1][val]?.toString().split(',').join('')) ?
            0 :
            +NonPlayBookForecastUpdate1[1][val]?.toString().split(',').join('');
        }
      });
      if (NonPlayBookForecastUpdate1[1]['fy'] !== total) {
        NonPlayBookForecastUpdate1[1]['fy'] = formatNumber(total);
        setNonPlayBookForecastUpdate1(NonPlayBookForecastUpdate1);
      }
    }
  }, [NonPlayBookForecastUpdate1]);

  useEffect(() => {
    let total = 0;
    if (
      isForecastUpdateTabEditable &&
      NonPlayBookForecastUpdate2 !== null &&
      NonPlayBookForecastUpdate2 !== undefined &&
      NonPlayBookForecastUpdate2.length > 0
    ) {
      Object.keys(NonPlayBookForecastUpdate2[1]).forEach((val) => {
        if (months.includes(val)) {
          total += isNaN(+roundToTwo(2, CommaRemoval(NonPlayBookForecastUpdate2[1][val]))) ?
            0 :
            +roundToTwo(2, CommaRemoval(NonPlayBookForecastUpdate2[1][val]));
          NonPlayBookForecastUpdate2[1][val] = isNaN(NonPlayBookForecastUpdate2[1][val]?.toString().split(',').join('')) ?
            0 :
            +NonPlayBookForecastUpdate2[1][val]?.toString().split(',').join('');
        }
      });
      if (NonPlayBookForecastUpdate2[1]['fy'] !== total) {
        NonPlayBookForecastUpdate2[1]['fy'] = formatNumber(total);
        setNonPlayBookForecastUpdate2(NonPlayBookForecastUpdate2);
      }
      // NonPlayBookForecastUpdate2[1] = fyCalculateTotal(NonPlayBookForecastUpdate2[1]);
      // setNonPlayBookForecastUpdate1(NonPlayBookForecastUpdate2);
    }
  }, [NonPlayBookForecastUpdate2]);

  useEffect(() => {
    let total = 0;
    if (
      isForecastUpdateTabEditable &&
      NonPlayBookForecastUpdate3 !== null &&
      NonPlayBookForecastUpdate3 !== undefined &&
      NonPlayBookForecastUpdate3.length > 0
    ) {
      Object.keys(NonPlayBookForecastUpdate3[1]).forEach((val) => {
        if (months.includes(val)) {
          total += isNaN(+roundToTwo(2, CommaRemoval(NonPlayBookForecastUpdate3[1][val]))) ?
            0 :
            +roundToTwo(2, CommaRemoval(NonPlayBookForecastUpdate3[1][val]));
          NonPlayBookForecastUpdate3[1][val] = isNaN(NonPlayBookForecastUpdate3[1][val]?.toString().split(',').join('')) ?
            0 :
            +NonPlayBookForecastUpdate3[1][val]?.toString().split(',').join('');
        }
      });
      if (NonPlayBookForecastUpdate3[1]['fy'] !== total) {
        NonPlayBookForecastUpdate3[1]['fy'] = formatNumber(total);
        setNonPlayBookForecastUpdate3(NonPlayBookForecastUpdate3);
      }
      // NonPlayBookForecastUpdate3[1] = fyCalculateTotal(NonPlayBookForecastUpdate3[1]);
      // setNonPlayBookForecastUpdate1(NonPlayBookForecastUpdate3);
    }
  }, [NonPlayBookForecastUpdate3]);

  const generateData = (year) => {
    return {
      month: selectedRow.execution_month[1] - 1,
      planMonth: monthArr[selectedRow.execution_month[1] - 1],
      planYear: year,
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      fy: 0,
      workflow_id: selectedRow.workflow_id,
    };
  };

  const postCall = (varianceData = null) => {
    // if (!isTrue && isValidate) {
    //   return false;
    // }
    // console.log(NonPlayBookForecastUpdate1, NonPlayBookForecastUpdate2, NonPlayBookForecastUpdate3);
    setErrorMessage(null);
    const postData = [];
    const objectAll = [];

    // using modifiedData which have latest values
    const DummyNonPlayBookForecastUpdate1 = [];
    const DummyNonPlayBookForecastUpdate2 = [];
    const DummyNonPlayBookForecastUpdate3 = [];
    // console.log(modifiedData);
    Object.values(modifiedData).map((mobj, index) => {
      Object.values(mobj).map((sobj) => {
        if (index == 0) {
          DummyNonPlayBookForecastUpdate1.push(sobj);
        }
        if (index == 1) {
          DummyNonPlayBookForecastUpdate2.push(sobj);
        }
        if (index == 2) {
          DummyNonPlayBookForecastUpdate3.push(sobj);
        }
      });
    });
    // console.log('modifiedData variables');
    if (DummyNonPlayBookForecastUpdate1.length == 1) {
      DummyNonPlayBookForecastUpdate1.push(generateData(years[0]));
    }
    if (DummyNonPlayBookForecastUpdate2.length == 1) {
      DummyNonPlayBookForecastUpdate2.push(generateData(years[1]));
    }
    if (DummyNonPlayBookForecastUpdate3.length == 1) {
      DummyNonPlayBookForecastUpdate3.push(generateData(years[2]));
    }
    // console.log(DummyNonPlayBookForecastUpdate1);
    // console.log(DummyNonPlayBookForecastUpdate2);
    // console.log(DummyNonPlayBookForecastUpdate3);
    // console.log('normal data variables');
    // console.log(NonPlayBookForecastUpdate1);
    // console.log(NonPlayBookForecastUpdate2);
    // console.log(NonPlayBookForecastUpdate3);
    DummyNonPlayBookForecastUpdate1.map((d1, index) => {
      if (+d1.workflow_id == +selectedRow.workflow_id) {
        Object.keys(d1).map((obj) => {
          if (months.includes(obj)) {
            // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              executionMonthYear: [+selectedRow.execution_month[0], +selectedRow.execution_month[1]],
              forecastYearMonth: [+years[0], months.indexOf(obj) + 1],
              // forecastValue: +roundToTwo(2, CommaRemoval(d1[obj])),
              forecastValue: +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    });
    DummyNonPlayBookForecastUpdate2.map((d1, index) => {
      if (+d1.workflow_id == +selectedRow.workflow_id) {
        Object.keys(d1).map((obj) => {
          if (months.includes(obj)) {
            // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              executionMonthYear: [+selectedRow.execution_month[0], +selectedRow.execution_month[1]],
              forecastYearMonth: [+years[0] + 1, months.indexOf(obj) + 1],
              // forecastValue: +roundToTwo(2, CommaRemoval(d1[obj])),
              forecastValue: +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    });
    DummyNonPlayBookForecastUpdate3.map((d1, index) => {
      if (+d1.workflow_id == +selectedRow.workflow_id) {
        Object.keys(d1).map((obj) => {
          if (months.includes(obj)) {
            // if (index == 1) {
            const dummyObj = {
              workflowId: d1.workflow_id,
              executionMonthYear: [+selectedRow.execution_month[0], +selectedRow.execution_month[1]],
              forecastYearMonth: [+years[0] + 2, months.indexOf(obj) + 1],
              // forecastValue: +roundToTwo(2, CommaRemoval(d1[obj])),
              forecastValue: +CommaRemoval(d1[obj]),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    });
    postData.forecastDetails = objectAll;
    // postData.forecastvariance=[];
    // console.log('ObjectAl', objectAll);
    if (typeof varianceData === 'object' && varianceData) {
      Object.keys(varianceData).map((quarter) => {
        postData.forecastvariance.push(varianceData[quarter]);
      });
    }
    DialogService.saveNonPlayBookForecastData([...objectAll])
        .then((res) => {
          if (res == true && selectedRow.status !== 'DRAFT') {
            updateStatus();
          } else {
          // show error popup dialog
            setIsSavePopupVisible(true);
            dispatch(setForecastUpdatesaveButton(false));

            setShowSuccessDialog2(true);
            // setSelectedYear(oldYear);
            setPreviousVarianceTrigger(!previousVarianceTrigger);
          }
        })
        .catch((err) => {
          const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
          setErrorMessage(errmsg);
          // setSelectedYear(oldYear);
          setShowErrorDialog(true);
        });
  };

  const handleCloseSuccess = () => {
    // postCall();
    // setTimeout(()=>{
    //   setpreviousMonthPresentMonthNotSame(false);
    // }, 300);
    setShowSuccessDialog2(false);
    setShowSuccessDialog3(false);
  };

  const handleCloseSuccess2 = (varianceData = null) => {
    setErrorMessage(null);
    const postData = {};
    const objectAll = [];

    // using modifiedData which have latest values
    const DummyNonPlayBookForecastUpdate1 = [];
    const DummyNonPlayBookForecastUpdate2 = [];
    const DummyNonPlayBookForecastUpdate3 = [];
    // console.log(modifiedData);
    Object.values(modifiedData).map((mobj, index) => {
      Object.values(mobj).map((sobj) => {
        if (index == 0) {
          DummyNonPlayBookForecastUpdate1.push(sobj);
        }
        if (index == 1) {
          DummyNonPlayBookForecastUpdate2.push(sobj);
        }
        if (index == 2) {
          DummyNonPlayBookForecastUpdate3.push(sobj);
        }
      });
    });
    if (DummyNonPlayBookForecastUpdate1.length == 1) {
      DummyNonPlayBookForecastUpdate1.push(generateData(years[0]));
    }
    if (DummyNonPlayBookForecastUpdate2.length == 1) {
      DummyNonPlayBookForecastUpdate2.push(generateData(years[1]));
    }
    if (DummyNonPlayBookForecastUpdate3.length == 1) {
      DummyNonPlayBookForecastUpdate3.push(generateData(years[2]));
    }
    // console.log('modifiedData variables');
    // console.log(DummyNonPlayBookForecastUpdate1);
    // console.log(DummyNonPlayBookForecastUpdate2);
    // console.log(DummyNonPlayBookForecastUpdate3);
    // console.log('normal data variables');
    // console.log(NonPlayBookForecastUpdate1);
    // console.log(NonPlayBookForecastUpdate2);
    // console.log(NonPlayBookForecastUpdate3);
    DummyNonPlayBookForecastUpdate1.map((d1, index) => {
      if (+d1.workflow_id == +selectedRow.workflow_id) {
        Object.keys(d1).map((obj) => {
          if (months.includes(obj)) {
            // if (index == 1) {
            const dummyObj = {
              workflow_id: d1.workflow_id,
              forecastYearMonth: [+years[0], months.indexOf(obj) + 1],
              forecastValue: +roundToTwo(2, CommaRemoval(d1[obj])),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    });
    DummyNonPlayBookForecastUpdate2.map((d1, index) => {
      if (+d1.workflow_id == +selectedRow.workflow_id) {
        Object.keys(d1).map((obj) => {
          if (months.includes(obj)) {
            // if (index == 1) {
            const dummyObj = {
              workflow_id: d1.workflow_id,
              forecastYearMonth: [+years[0] + 1, months.indexOf(obj) + 1],
              forecastValue: +roundToTwo(2, CommaRemoval(d1[obj])),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    });
    DummyNonPlayBookForecastUpdate3.map((d1, index) => {
      if (+d1.workflow_id == +selectedRow.workflow_id) {
        Object.keys(d1).map((obj) => {
          if (months.includes(obj)) {
            // if (index == 1) {
            const dummyObj = {
              workflow_id: d1.workflow_id,
              forecastYearMonth: [+years[0] + 2, months.indexOf(obj) + 1],
              forecastValue: +roundToTwo(2, CommaRemoval(d1[obj])),
            };
            objectAll.push(dummyObj);
            // }
          }
        });
      }
    });
    postData.forecastDetails = objectAll;
    postData.forecastvariance = [];
    if (typeof varianceData === 'object' && varianceData) {
      Object.keys(varianceData).map((quarter) => {
        postData.forecastvariance.push(varianceData[quarter]);
      });
    }
    DialogService.saveForecastData({...postData})
        .then((res) => {
          if (res == true && selectedRow.status !== 'DRAFT') {
            updateStatus();
          } else {
          // show error popup dialog
            setIsSavePopupVisible(true);

            setShowSuccessDialog2(true);
          // setSelectedYear(oldYear);
          }
        })
        .catch((err) => {
          const errmsg = err && err.userMessage ? err.userMessage : 'Error occured when saving';
          setErrorMessage(errmsg);
          setShowSuccessDialog2(false);
          // setSelectedYear(oldYear);
          setShowErrorDialog(true);
        });
    setShowSuccessDialog2(false);
  };

  return (
    <>
      {((columns?.length && data?.length) || '') && (
        <div className="forecast-table">
          {(selectedYear == years[0] && NonPlayBookForecastUpdate1.length>1) ? (
            <DataSheetGrid
              value={[NonPlayBookForecastUpdate1[1]]}
              columns={columns}
              onChange={(newData) => {
                if (newData.length > 2) {
                  setNonPlayBookForecastUpdate1([newData[0], newData[1]]);
                  setData([newData[0], newData[1]]);
                } else {
                  setNonPlayBookForecastUpdate1([NonPlayBookForecastUpdate1[0], newData[0]]);
                  setData(newData);
                }
              }}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
              lockRows={true}
              // style={{'--dsg-selection-border-color': 'transparent'}}
            />
          ) : (
            ''
          )}

          {(selectedYear == years[1] && NonPlayBookForecastUpdate2.length>1) ? (
            <DataSheetGrid
              value={[NonPlayBookForecastUpdate2[1]]}
              columns={columns}
              onChange={(newData) => {
                // console.log('@dataupdate  ..... ', [NonPlayBookForecastUpdate2[0], newData]);
                if (newData.length > 2) {
                  setNonPlayBookForecastUpdate2([newData[0], newData[1]]);
                  setData([newData[0], newData[1]]);
                } else {
                  setNonPlayBookForecastUpdate2([NonPlayBookForecastUpdate2[0], newData[0]]);
                  setData(newData);
                }
              }}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
              lockRows={true}
              // style={{'--dsg-selection-border-color': 'transparent'}}
            />
          ) : (
            ''
          )}

          {(selectedYear == years[2] && NonPlayBookForecastUpdate3.length>1) ? (
            <DataSheetGrid
              value={[NonPlayBookForecastUpdate3[1]]}
              columns={columns}
              onChange={(newData) => {
                // console.log('@dataupdate  ..... ', newData);
                if (newData.length > 2) {
                  setNonPlayBookForecastUpdate3([newData[0], newData[1]]);
                  setData([newData[0], newData[1]]);
                } else {
                  setNonPlayBookForecastUpdate3([NonPlayBookForecastUpdate3[0], newData[0]]);
                  setData(newData);
                }
              }}
              rowClassName={() => 'forecast'}
              disableContextMenu={true}
              gutterColumn={false}
              lockRows={true}
              // style={{'--dsg-selection-border-color': 'transparent'}}
            />
          ) : (
            ''
          )}

          <Box sx={{display: 'flex', gap: '10px', justifyContent: 'flex-end'}}>
            {access === 'Edit' &&
            (buttonStatus === 'edit' ||
              (currentRole.includes('Admin') &&
                (selectedRow.status === 'DRAFT' || selectedRow.status == 'REJECTED' || selectedRow.status == 'NEW'))) ? (
              <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px'}}>
                <AppButton
                  disabled={previousMonthForecastEditButton == true}
                  onClick={() => {
                    if (isForecastUpdateTabEditable) {
                      // checking the variance of each quarters
                      // validationCheck();
                      // setSubmitted(false);
                      postCall();
                    } else {
                      dispatch(setForecastUpdatesaveButton(true));
                      setPreviousMonthForecastEditButton(false);
                    }
                  }}
                  label={isForecastUpdateTabEditable ? 'Save' : 'Edit Forecast'}
                />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </div>
      )}

      <AppErrorDialog
        open={showErrorDialog}
        handleClose={() => {
          setShowErrorDialog(false);
          setErrorMessage(null);
          setShowSuccessDialog3(false);
        }}
        text={errorMessage ? errorMessage : 'Error occured when saving'}
      />
      <AppDialog open={showPreviousMonthSuccess} handleClose={() => setShowPreviousMonthSuccess(false)} text="Forecast is Saved" />

      <AppDialog open={showSuccessDialog2} handleClose={handleCloseSuccess} text={`Forecast is ${'Saved'}`} />
      {/* <PreviousMonthForecastPopUp years={years} data={previousMonthDifferenceData} setData={setPreviousMonthDifferenceData} isOpen={showPreviousMonthDifference} handleClose={()=>{
        setshowPreviousMonthDifference(false);
      }} handleSubmit={handlePreviousMonthForecastDifferenceSubmit}/> */}
      <AppDialog3
        open={showSuccessDialog3}
        handleClose={handleCloseSuccess}
        save={handleCloseSuccess2}
        text={'Forecast is the same as last month.'}
        text2={'Are you sure you do not want to update it?'}
      />
    </>
  );
};

export default ForecastTable;
