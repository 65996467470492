
/* eslint-disable no-unused-vars */
import React from 'react';
import {DataSheetGrid, keyColumn, textColumn} from 'react-datasheet-grid';
import ReadOnlyColumn from '../ReadOnlyColumn';

const CarryVolumeReasons = ({data, header}) => {
  const columns = [
    {
      ...keyColumn('last_updated_by', textColumn),
      title: 'Author',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('last_updated_on', textColumn),
      title: 'Date',
      disabled: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      ...keyColumn('comment', textColumn),
      title: 'Comments',
      component: ({rowData, focus}) => (
        <input disabled value={rowData['comment']} style={{width: '100%', border: 'none', outline: '0', padding: '5px', background: 'rgb(250, 250, 250)', color: 'rgb(109, 120, 136)', fontSize: '12px', minHeight: '40px'}} />
      ),
      disabled: true,
    },
  ];
  return (
    <div style={{marginTop: '10px'}}>
      <div style={{marginBottom: '10px'}} className="">{header}</div>
      <DataSheetGrid
        value={data}
        columns={columns}
        className='savedDatasheetGridCoverageTable docDatasheet'
        disableContextMenu={true}
        gutterColumn={false}
      />
    </div>
  );
};

export default CarryVolumeReasons;
