import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import {useDispatch} from 'react-redux'; // useSelector

import {changeRoleHandler} from '../../redux/RoleBasedAccess';
import Styles from '../../styles/RoleSelectionModal';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {CircularProgress, Dialog} from '@mui/material';
import {useQuery} from '../../hooks/useQuery';

const RoleSelectionModalPopup = ({currentRoleId, availableRoles, handleCloseModal}) => {
  // const [currentRole, setCurrentRole]= useState(null);
  const [selectedRole, setSelectedRole]= useState('');
  // const [isDialogOpen, setDialogOpen]= useState(true);
  const dispatch= useDispatch();

  useEffect(()=>{
    if (currentRoleId) {
      setSelectedRole(currentRoleId);
    }
  }, [currentRoleId]);


  const handleClose =()=>{
    // setDialogOpen(false);
    handleCloseModal();
  };
  const handleChange = (e)=>{
    const newRole= e.target.value;
    if (newRole) {
      setSelectedRole(newRole);
    }
  };

  const query = useQuery();
  const selectedSectorGroup = query.get('sector-group');
  const selectedPlaybookName = query.get('playbook-name');
  const selectedBuyplanName = query.get('buyplan-name');
  const selectedRoleId = Number(query.get('role'));

  const history = useHistory();
  // let selectedSectorGroup;
  // let selectedPlaybookName;
  // const selectedRoleId = history.location.pathname.split('/')[2];
  // if (!isNaN(selectedRoleId)) {
  //   selectedSectorGroup = history.location.pathname.split('/')[3];
  //   selectedPlaybookName = history.location.pathname.split('/')[4];
  // }


  // console.log('@pep isDialogOpen', isDialogOpen);
  const handleChangeRoleSubmit = ()=>{
    // update the current role to redux store
    // close the popup modal

    if (selectedRoleId && selectedSectorGroup && selectedPlaybookName) {
      dispatch(changeRoleHandler(selectedRoleId));
      handleClose();
      if (!selectedSectorGroup && !selectedPlaybookName) {
        history.push('/home');
      }
    }

    if (selectedRole) {
      dispatch(changeRoleHandler(selectedRole));
      handleClose();
      if (!selectedSectorGroup && !selectedPlaybookName) {
        history.push('/home');
      }
    }
  };

  useEffect(()=>{
    if (selectedRoleId || selectedSectorGroup || selectedPlaybookName || selectedBuyplanName) {
      if (selectedRoleId && selectedSectorGroup && selectedPlaybookName) {
        handleChangeRoleSubmit();
      } else {
        history.push('/home');
      }
    }
  }, [selectedRoleId, selectedSectorGroup, selectedPlaybookName]);

  // If anything on the url is changed, redirect to home
  // useEffect(()=>{
  // if (!isNaN(selectedRoleId)) { // If the selectedRoleId is a number, then we can asume that it's coming from 'View Play Target' icon navigation
  //   if (!selectedRoleId || !selectedSectorGroup || !selectedPlaybookName) {
  //     history.push('/home');
  //   }
  // }
  // }, [selectedRoleId, selectedSectorGroup, selectedPlaybookName]);

  if (selectedRoleId || selectedSectorGroup || selectedPlaybookName || selectedBuyplanName) {
    return (
      <Dialog onClose={() => {}} open={true} fullScreen>
        <Box sx={{backgroundColor: '#24589E', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </Box>
      </Dialog>
    );
  }


  return (
    <Styles.StyledDialog onClose={() => {}} open={true}>
      <Styles.StyledDialogTitle>Select The Role</Styles.StyledDialogTitle>
      <Box>
        <Styles.StyledFormControl fullWidth>
          <Styles.StyledSelect
            labelId="role-select-label"
            id="role-select"
            value={selectedRole}
            onChange={handleChange}
            displayEmpty
            renderValue={(selected, displayEmpty) => {
              let label = 'Select';
              if (selected) {
                availableRoles.forEach((role) => {
                  if (selected === role.role_id) {
                    label = role.description;
                  }
                });
              }
              return label;
            }}
          >
            {_.orderBy(availableRoles, [(role) => role.description], ['asc'])?.map((role) => {
              return (
                <Styles.StyledMenuItem key={role.role_id} value={role.role_id}>
                  {role.description}
                </Styles.StyledMenuItem>
              );
            })}
          </Styles.StyledSelect>
          <Styles.StyledButton onClick={handleChangeRoleSubmit}>Submit</Styles.StyledButton>
        </Styles.StyledFormControl>
      </Box>
    </Styles.StyledDialog>
  );
};

export default RoleSelectionModalPopup;
