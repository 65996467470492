import {months, titleCase} from '../../services/utils';
import {defaultObj} from './consts';

export const forecastDefaultData = (selectedRow) => {
  const obj = {};
  const y = new Date().getFullYear();
  // const m = new Date().getMonth();
  const list = [y, y + 1, y + 2];
  const firstMonth = selectedRow.executionMonthYear[1]-2;
  const secondMonth = selectedRow.executionMonthYear[1]-1;
  if (!obj[list[0]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prevWorkFlowId,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workFlowId,
      planMonth: titleCase(months[secondMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    obj[list[0]] = [a, a2];
  }
  if (!obj[list[1]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prevWorkFlowId,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workFlowId,
      planMonth: titleCase(months[secondMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    obj[list[1]] = [a, a2];
  }
  if (!obj[list[2]]) {
    const a = {
      ...defaultObj,
      workflow_id: selectedRow.prevWorkFlowId,
      planMonth: titleCase(months[firstMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    const a2 = {
      ...defaultObj,
      workflow_id: selectedRow.workFlowId,
      planMonth: titleCase(months[secondMonth]),
      planYear: selectedRow.executionMonthYear[0],
    };
    obj[list[2]] = [a, a2];
  }
  return obj;
};

export const fyCalculateTotal = (selectedData) => {
  return Object.values(selectedData).map((val, key) => {
    if (!val['key']) {
      val['key'] = key + 1;
    } else val['key'] = key + 1;
    const total = Object.keys(val).reduce((accumulator, currentValue) => {
      if (months.indexOf(currentValue) > 0) {
        return accumulator + (val[currentValue] || 0);
      } else return accumulator;
    }, 0);
    val.fy = total;
    return val;
  });
};

export const getSortedMonthlyData = (arr, selectedRow, copy) => {
  let k = '';
  let pMonth = null;
  if (Object.keys(copy).map((a) => a).length == 2) {
    delete copy[arr[0].workflow_id];
    const va = Object.values(copy).map((a) => a);
    pMonth = va[0].executionMonthYear[1] - 1;
  } else {
    if (arr[0].workflow_id == selectedRow.workFlowId) {
      pMonth = selectedRow.executionMonthYear[1] - 2;
    } else {
      pMonth = selectedRow.executionMonthYear[1] - 1;
    }
    // pMonth = selectedRow.executionMonthYear[1] - 1
  }
  if (arr[0].month > pMonth) {
    arr[0].key = 2;
    k = 1;
  } else k = 2;
  // month, year and key value setup in constant
  const defaultObj = {
    month: pMonth,
    planMonth: titleCase(months[pMonth]),
    planYear: arr[0].planYear,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    fy: 0,
    workflow_id: selectedRow.workFlowId,
    key: k,
  };
  arr = [...arr, defaultObj];
  arr.sort((a, b) => (a.month > b.month ? 0 : -1));
  return arr;
};

export const monthNYearSetter = (obj, val) => {
  const key = val.workflow_id;
  const key1 = val.forecastYearMonth[0];
  if (!obj[key1]) {
    obj[key1] = {};
    const a = {
      ...defaultObj,
      month: val.executionMonthYear[1] - 1,
      planMonth: titleCase(months[val.executionMonthYear[1] - 1]),
      planYear: val.executionMonthYear[0],
      workflow_id: val.workflow_id,
      [months[val.forecastYearMonth[1] - 1]]: val.forecastValue,
    };
    obj[key1][key] = a;
  } else {
    if (!obj[key1][key]) {
      const a = {
        ...defaultObj,
        workflow_id: val.workflow_id,
        planMonth: titleCase(months[val.executionMonthYear[1] - 1]),
        planYear: val.executionMonthYear[0],
        [months[val.forecastYearMonth[1] - 1]]: val.forecastValue,
      };
      obj[key1][key] = a;
    } else {
      obj[key1][key] = {
        ...obj[key1][key],
        workflow_id: val.workflow_id,
        planMonth: titleCase(months[val.executionMonthYear[1] - 1]),
        planYear: val.executionMonthYear[0],
        [months[val.forecastYearMonth[1] - 1]]: val.forecastValue,
      };
    }
  }
  return obj;
};

export const rowsSetter = (obj, workflowIdsObj, selectedRow) => {
  if (Object.keys(obj).length < 3) {
    const wfIDs = Object.keys(workflowIdsObj)
        .map((val) => val)
        .sort((a, b) => (a < b ? -1 : 0));
    const y = new Date().getFullYear();
    const list = [y, y + 1, y + 2];
    let firstMonth = null;
    let secondMonth = null;
    if (wfIDs.length == 1) {
      if (workflowIdsObj[wfIDs[0]].workflow_id == selectedRow.workFlowId) {
        secondMonth = workflowIdsObj[wfIDs[0]].executionMonthYear[1] - 1;
        firstMonth = secondMonth - 1;
      } else {
        firstMonth = workflowIdsObj[wfIDs[0]].executionMonthYear[1] - 1;
        secondMonth = firstMonth + 1;
      }
    } else if (wfIDs.length == 2) {
      firstMonth = workflowIdsObj[wfIDs[0]].executionMonthYear[1] - 2;
      secondMonth = workflowIdsObj[wfIDs[1]].executionMonthYear[1] - 1;
    }
    if (!obj[list[0]]) {
      const a = {
        ...defaultObj,
        workflow_id: selectedRow.prevWorkFlowId,
        planMonth: titleCase(months[firstMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      const a2 = {
        ...defaultObj,
        workflow_id: selectedRow.workFlowId,
        planMonth: titleCase(months[secondMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      obj[list[0]] = [a, a2];
    }
    if (!obj[list[1]]) {
      const a = {
        ...defaultObj,
        workflow_id: selectedRow.prevWorkFlowId,
        planMonth: titleCase(months[firstMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      const a2 = {
        ...defaultObj,
        workflow_id: selectedRow.workFlowId,
        planMonth: titleCase(months[secondMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      obj[list[1]] = [a, a2];
    }
    if (!obj[list[2]]) {
      const a = {
        ...defaultObj,
        workflow_id: selectedRow.prevWorkFlowId,
        planMonth: titleCase(months[firstMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      const a2 = {
        ...defaultObj,
        workflow_id: selectedRow.workFlowId,
        planMonth: titleCase(months[secondMonth]),
        planYear: selectedRow.executionMonthYear[0],
      };
      obj[list[2]] = [a, a2];
    }
  }
  return obj;
};
