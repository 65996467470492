import {createSlice} from '@reduxjs/toolkit';

export const buttonStatusSlice = createSlice({
  name: 'btnStatus',
  initialState: {
    value: '',
    status: '',
    page: '',
    countryDropdownValue: '',
  },
  reducers: {
    changeButtonStatusValue: (state, action) => {
      state.value = action.payload;
    },
    changeCountryDropdownValue: (state, action) => {
      state.countryDropdownValue = action.payload;
    },
    changeBuyplanStatus: (state, action) => {
      state.status = action.payload;
    },
    changePageStatus: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const {changeButtonStatusValue, changeBuyplanStatus, changePageStatus, changeCountryDropdownValue} = buttonStatusSlice.actions;

export default buttonStatusSlice.reducer;
