/* This Page is the admin configuration page where the admin can add units, currency, roles, playbook,
e.t.c. Here the page gets the value from the reducer which decide which page should be visible on the screen.
*/

import React from 'react';
import {useSelector} from 'react-redux';
import BuyPlan from './buyplan/BuyPlan';
import Category from './Category/Category';
import Commodity from './commodity/Commodity';
import Currency from './currency/Currency';
import CurrencyConversion from './CurrencyConversion/CurrencyConversion';
import PlayBook from './playbook/PlayBook';
import RolesAccess from './rolesaccess/RolesAccess';
import SubCommodity from './SubCommodity/SubCommodity';
import UnitConversion from './unitconversion/UnitConversion';
import UnitMaster from './Unitmaster/UnitMaster';
import MasterSector from './SectorMaster/MasterSector/MasterSector';
import Sector from './SectorMaster/Sector/Sector';
import BusinessUnit from './SectorMaster/BusinessUnit/BusinessUnit';
import Country from './SectorMaster/Country/Country';
import {useEffect} from 'react';
import {useHistory} from 'react-router';
import NonPlayBook from './NonPlaybook/NonPlayBook';
import NotificationMain from './NotificationPage/NotificationMain';
const Configurations = () => {
  const selectedConf = useSelector((state)=>state.configurations.
      selectedConfiguration);

  const navigate = useHistory();
  useEffect(()=>{
    navigate.push(`/configuration/${selectedConf}`);
  }, [selectedConf]);

  if (selectedConf==='units') {
    return (
      <UnitMaster />
    );
  } else if (selectedConf==='commodity') {
    return (

      <Commodity />

    );
  } else if (selectedConf==='subcommodity') {
    return (

      <SubCommodity />

    );
  } else if (selectedConf==='unitsconversion') {
    return (

      <UnitConversion />

    );
  } else if (selectedConf==='currencyconversion') {
    return (

      <CurrencyConversion />

    );
  } else if (selectedConf==='currency') {
    return (

      <Currency />

    );
  } else if (selectedConf==='rolesaccess') {
    return (

      <RolesAccess />

    );
  } else if (selectedConf==='playbook') {
    return (

      <PlayBook />

    );
  } else if (selectedConf==='buyplan') {
    return (

      <BuyPlan />

    );
  } else if (selectedConf==='category') {
    return (

      <Category />

    );
  } else if (selectedConf==='mastersector') {
    return (

      <MasterSector />

    );
  } else if (selectedConf==='sector') {
    return (

      <Sector />

    );
  } else if (selectedConf==='nonplaybook') {
    return (

      <NonPlayBook />

    );
  } else if (selectedConf==='businessunit') {
    return (

      <BusinessUnit />

    );
  } else if (selectedConf==='country') {
    return (

      <Country />

    );
  } else if (selectedConf==='notificationSettings') {
    return (

      <NotificationMain />

    );
  }
};

export default Configurations;
