import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppButton, AppNotSelectedButton} from '../common/AppButton/AppButton';
import Styles from '../../styles/Forecast';

export default function VarianceModal({isOpen, showLoader, setShowLoader, data, nonZeroDialogIsOpen, nonZeroDialogIsOpen2, handleClose, validationArr, handleSubmit, isForcastSaved, workFlowId}) {
  const [variance, setVariance] = useState({});
  // const [showLoader, setShowLoader] = useState(false);
  const [isValidated, setIsValidated] = useState(false);

  const varianceReasons=useSelector((state)=>state?.commonState?.varianceReasons);

  useEffect(()=>{
    if (isForcastSaved===true) {
      setShowLoader(false);
    }
  }, [isForcastSaved]);

  useEffect(()=>{
    const variance = {};
    const oldReasons = [];
    validationArr.length>0 && validationArr.map((vA)=>{
      oldReasons.push(`${vA['quarter']}-${vA['year']}`);
    });
    console.log(oldReasons);
    data.length>0 && varianceReasons.length>0 && data.map((d)=>{
      if (oldReasons.includes(d.quarter.slice(1))) {
        variance[`${d.quarter.slice(1)}`] = {...d, 'reason': d['varianceReason'], 'varianceReasonID': varianceReasons.filter((vr)=> vr.value==d['varianceReason'])[0]['id']};
      }
    });
    validationArr.length>0 && validationArr.map((v)=>{
      if (variance[`${v.quarter}-${v.year}`]) {
        variance[`${v.quarter}-${v.year}`].variance = v.variance;
      }
    });

    setVariance({...variance});
  }, [data, varianceReasons, validationArr]);

  useEffect(()=>{
    // validate
    let isValid=true;
    validationArr.map((thisValidationArr)=>{
      const quarter=thisValidationArr?.quarter;
      const year=thisValidationArr?.year;
      const quarterAndYear=quarter+'-'+year;
      const isReasonSelectedForThisQuater = variance[quarterAndYear] && variance[quarterAndYear].reason && variance[quarterAndYear].reason!=='' ? true : false;
      const isCommentTextAddedForThisQuater = variance[quarterAndYear] && variance[quarterAndYear].comment && variance[quarterAndYear].comment!=='' ? true : false;
      // console.log('isReasonSelectedForThisQuater, isCommentTextAddedForThisQuater', isReasonSelectedForThisQuater, isCommentTextAddedForThisQuater);
      if (isReasonSelectedForThisQuater!==true || isCommentTextAddedForThisQuater!==true) {
        isValid=false;
      }
    });
    setIsValidated(isValid);
  }, [variance, validationArr]);

  const handleVariance = (value, quarter, year, actualVariance, isCommentText)=>{
    const quarterAndYear=quarter+'-'+year;
    const varianceData={...variance};
    if (!varianceData[quarterAndYear]) {
      varianceData[quarterAndYear]={};
    }

    varianceData[quarterAndYear]['wf_id']= workFlowId;
    varianceData[quarterAndYear]['quarter']= 'Q' + quarterAndYear;
    varianceData[quarterAndYear]['variance']= actualVariance; // variance value
    if (isCommentText) {
      varianceData[quarterAndYear]['comment']=value;
    } else {
      // varianceData[quarterAndYear]['reason']=value; // selected reason for variance
      varianceData[quarterAndYear]['varianceReasonID']=value; // selected reason for variance
      for (let index = 0; index < varianceReasons.length; index++) {
        if (value===varianceReasons[index]['id']) {
          varianceData[quarterAndYear]['reason']=varianceReasons[index]['value'];
          break;
        }
      }
    }
    // console.log('set Variance Data quarterAndYear ', quarterAndYear, varianceData[quarterAndYear]);
    setVariance(varianceData);
  };
  return (
    <React.Fragment>
      <Dialog
        maxWidth={'sm'}
        open={isOpen}
      >
        <DialogContent>
          <DialogContentText sx={{'font-family': 'Segoe UI', 'font-size': '13px', 'color': '#3B4655', 'fontWeight': 600}}>
         The forecast you have entered has significant variance from previous forecast, do you confirm the data to be accurate. If no, please click on close button to update the forecast
          </DialogContentText>
          {validationArr.map((validationArray, index) => {
            const quarter=validationArray?.quarter;
            const year=validationArray?.year;
            const quarterAndYear=quarter+'-'+year;
            const selectedValue= variance[quarterAndYear] && variance[quarterAndYear].reason ? variance[quarterAndYear].reason : 'Select Variance Reason';
            const commentText= variance[quarterAndYear] && variance[quarterAndYear].comment ? variance[quarterAndYear].comment : null;
            return (
              <Box key={index} sx={{mt: 2}}>
                <DialogContentText sx={{'font-family': 'Segoe UI', 'font-size': '13px', 'color': '#3B4655'}}>
                  Please select a reason from dropdown for the variance ({validationArray?.variance}%) happening at Q{`${quarter}-${year}` } level.
                </DialogContentText>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                  }}
                >
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{'marginRight': '15px', 'width': '100px', 'color': '#3B4655', 'fontWeight': 600}}>{`Q${quarter}-${year}`}</Box>
                    <FormControl sx={{mt: 2, minWidth: 160, flexDirection: 'row'}}>
                      {/* <InputLabel htmlFor="max-width">Variance Reason</InputLabel> */}
                      <Styles.StyledVarianceSelect
                        autoFocus
                        value={selectedValue}
                        onChange={(e) => handleVariance(e.target.value, quarter, year, validationArray?.variance, false)}
                        label="Select Variance Reason"
                        inputProps={{
                          name: 'max-width',
                          id: 'max-width',
                        }}
                        displayEmpty
                        renderValue={(selected, displayEmpty) => {
                          let Return ='Select Variance Reason';
                          // console.log('selected   ', selected);
                          if (selected) {
                            // for (let index = 0; index < varianceReasons.length; index++) {
                            //   if (selected===varianceReasons[index]['id']) {
                            //     Return=varianceReasons[index]['value'];
                            //     break;
                            //   }
                            // }
                            Return =selected;
                          }
                          return Return;
                        }}
                      >

                        {/* <Styles.StyledVarianceMenuItem value="Forecast Change">Forecast Change</Styles.StyledVarianceMenuItem>
                        <Styles.StyledVarianceMenuItem value="PO Reconciliation">PO Reconciliation</Styles.StyledVarianceMenuItem>
                        <Styles.StyledVarianceMenuItem value="Supplier cannot supply">Supplier cannot supply</Styles.StyledVarianceMenuItem>
                        <Styles.StyledVarianceMenuItem value="Fixed lot sizes">Fixed lot sizes</Styles.StyledVarianceMenuItem>
                        <Styles.StyledVarianceMenuItem value="Others">Others</Styles.StyledVarianceMenuItem> */}
                        {varianceReasons.map((varianceReason)=>{
                          return (
                            <Styles.StyledVarianceMenuItem key={varianceReason.id} value={varianceReason.id}>{varianceReason.value}</Styles.StyledVarianceMenuItem>
                          );
                        })}
                      </Styles.StyledVarianceSelect>
                      <Styles.StyledVarianceCommmentText
                        aria-label="Variance Reason"
                        minRows={3}
                        placeholder="Variance Reason"
                        style={{width: 240}}
                        onChange={(e) => handleVariance(e.target.value, quarter, year, validationArray?.variance, true)}
                        value={commentText}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            );
          })
          }
          {(showLoader && !(nonZeroDialogIsOpen || nonZeroDialogIsOpen2)) &&
          <Box sx={{'display': 'flex', 'justify-content': 'center', 'm': 2, 'mt': 4}}>
            <CircularProgress color="primary"/>
          </Box>
          }

          <DialogActions sx={{mb: 2, mt: 2}}>
            <AppNotSelectedButton
              onClick={async () => {
                setShowLoader(false);
                handleClose();
              }}
              label="Close"
            />
            <AppButton
              disabled ={!isValidated}
              onClick={async () => {
                setShowLoader(!(nonZeroDialogIsOpen || nonZeroDialogIsOpen2) ? true : false);
                handleSubmit(variance);
              }}
              label="Save"
            />

          </DialogActions>
        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}
