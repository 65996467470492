/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {changeCountryDropdownValue} from '../../../../redux/features/buttonStatus';
const DropdownSelectSector = ({sectorCountryMap, rowData, indexOfYear, apiData, i}) => {
  const [sectorSelected, setSectorSelected] = React.useState('');
  const noSectorFound = 'No Sector Found';
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSectorSelected(event.target.value);
    if (event.target.value === noSectorFound) {
      setSectorSelected('');
      return;
    }
    if (apiData) {
      apiData[indexOfYear].map((element, index) => {
        if (index==i) {
          element.sector_id = event.target.value;
          rowData.sector_id = event.target.value;
          rowData.region_id = '';
          const d = new Date();
          dispatch(changeCountryDropdownValue(d.getMilliseconds()));
        }
      });
    }
  };

  function getSectorName(sectorId) {
    const sectorObj = sectorCountryMap.filter((sector) => sector.id === sectorId);
    return sectorObj[0]?.name;
  }

  useEffect(() => {
    setSectorSelected(rowData.sector_id !== '' ? getSectorName(rowData.sector_id) : '');
  }, [rowData]);

  useEffect(() => {
    setSectorSelected(rowData.sector_id|| '');
    apiData[indexOfYear].map((element, index) => {
      if (index==i) {
        element.sector_id = rowData.sector_id;
      }
    });
  }, [apiData, i, indexOfYear, rowData]);
  return (
    <div>
      <FormControl variant='standard' sx={{width: '78px', fontSize: '12px'}}>
        <Select
          id='sector-dropdown'
          value={sectorSelected || ''}
          disabled={rowData?.disableCell}
          placeholder={'Select'}
          onChange={handleChange}
          sx={{width: '78px', fontSize: '12px'}}
        >
          {
            (sectorCountryMap.length > 0 ? [...sectorCountryMap] : [noSectorFound]).map((sector) => {
              return <MenuItem sx={{fontSize: '13px'}} key={sector.id} value={sector.id} >{sector.name}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownSelectSector;
