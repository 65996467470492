// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .buyPlanList{
    width: 100%;
    padding: 18px;
}
.buyPlanListSpan{
    font-weight: 600;
    color: rgb(96, 96, 96);
}
.selectOptionBuyPlanListDiv{
    display: flex;
    padding-top: 35px;
}
.selectFiveOptionBuyPlanListDiv{
    margin-right: 30px;
    color: rgb(96, 96, 96);
}
.MuiBox-root{
    margin-top: 20px;
} */`, "",{"version":3,"sources":["webpack://./src/Components/MainComponent/TableComponent/BuyPlanList/BuyPlanList.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;GAkBG","sourcesContent":["/* .buyPlanList{\n    width: 100%;\n    padding: 18px;\n}\n.buyPlanListSpan{\n    font-weight: 600;\n    color: rgb(96, 96, 96);\n}\n.selectOptionBuyPlanListDiv{\n    display: flex;\n    padding-top: 35px;\n}\n.selectFiveOptionBuyPlanListDiv{\n    margin-right: 30px;\n    color: rgb(96, 96, 96);\n}\n.MuiBox-root{\n    margin-top: 20px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
