import styled from '@emotion/styled';
import {Box, FormControl, InputBase, MenuItem, Select, TableCell, TableRow, createTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import config from '../../../Utils/Config';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {AppConfirmDialog, AppDialog} from '../../common/App-Dialog/AppDialog';

import customealltheme from '../../../theme';
import ConfigurationServices from '../../../services/ConfigurationServices';
const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const btnStyle={
  display: 'flex',
  border: '0.5px solid #CECECE',
  borderRadius: '4px',
  alignItems: 'center',
  gap: '5px',
  padding: '2px 6px',
  cursor: 'pointer',
};
const Edit = config.AccessLevel.Edit;
const CommonConfSingleRow = ({row, setAddingTrigger, Currency, Units, addingTrigger, accessControl,
  values, editValues, dateValues, idKey, singleUpdate, buyplanPersona, businessUnit,
  setErrMsg, setIsErrorPopupVisible, editModeDisableTrigger, page, deletePlayBookRow, editPlayBookRow, ActiveSectors, ActiveSubCommodities, country}) => {
  const mapDateToBackendFormat = (date) => {
    if (date===null || date==='') return null;
    return dayjs(date).format('YYYY-MM');
  };

  const formatStatus = (selectedValue) => {
    switch (selectedValue) {
      case 'Active':
        return 'ACTIVE';
      case 'Inactive':
        return 'INACTIVE';
      case 'Coverage Suspension':
        return 'COV_SUSPENDED';
      case 'Coverage Review Suspension':
        return 'REVIEW_SUSPENDED';
      case 'ACTIVE':
        return 'Active';
      case 'INACTIVE':
        return 'Inactive';
      case 'COV_SUSPENDED':
        return 'Coverage Suspension';
      case 'REVIEW_SUSPENDED':
        return 'Coverage Review Suspension';
      default:
        return '';
    }
  };

  const [editMode, setEditMode] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [standardUnitId, setstandardUnitId] = useState('');
  const [standardCurrencyId, setStandardCurrencyId] = useState('');
  const [editedDetails, setEditDetails] = useState({});
  const [buyplanUID, setplanUId] = useState('');
  const [buyplanPer, setbuyplanPer] = useState('');
  const [disableSupplierOptionality, setDisableSupplierOptionality]= useState(false);
  const [disableTreasuryOptionality, setDisableTreasuryOptionality]= useState(false);
  const [futureStatusValue, setFutureStatusValue]=useState('');
  const [sectorIdValue, setSectorIdValue]=useState(row['sector_id']);
  const [subcommodityIdValue, setSubcommodityIdValue] = useState(row['sub_commodity_id']);
  const [countryIdValue, setCountryIdValue] = useState(row['region_id']);
  const [mappedRegionsList, setMappedRegionsList] = useState([]);

  useEffect(()=>{
    if (sectorIdValue) {
      const countriesList = [];
      ConfigurationServices.getSectorCountries(sectorIdValue).then((res)=>{
        res.forEach((element) => {
          element.is_present === 'Y'? countriesList.push(element):'';
        });
        setMappedRegionsList(countriesList);
      }).catch((e) => {
        // console.log([e]);
      })
      ;
    }
  }, [sectorIdValue]);

  useEffect(()=>{
    setFutureStatusValue(formatStatus(row['future_status']));
  }, [row]);

  const cDate = dayjs();
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const minActiveEndDate = dayjs(row['active_start_date']);
  const [selectedActiveEndDate, setSelectedActiveEndDate] = useState('');
  const [activeEndDateValue, setActiveEndDateValue]=useState(row['active_end_date']);

  const [defaultActiveEndDate, setDefaultActiveEndDate] = useState(null);
  const [showSuccessDialog, setshowSuccessDialog] = useState(false);

  useEffect(() => {
    if (row['active_end_date']) {
      setDefaultActiveEndDate(dayjs(row['active_end_date']));
    } else {
      let date = dayjs().add(1, 'month');

      if (dayjs().date() !== date.date()) {
        date = date.subtract(date.date(), 'day');
      }

      setDefaultActiveEndDate(date);
    }
  }, [row]);


  const minFutureStartDate = cDate;
  const [selectedFutureStartDate, setSelectedFutureStartDate] = useState('');
  const [selectedFutureEndDate, setSelectedFutureEndDate] = useState('');
  const [defaultFutureStartDate, setDefaultFutureStartDate] = useState(null);
  const [defaultFutureEndDate, setDefaultFutureEndDate] = useState(null);
  const [hasBuyplan, setHasBuyPlan] = useState(false);

  useEffect(() => {
    if (row['future_status_start_date']) {
      setDefaultFutureStartDate(dayjs(row['future_status_start_date']));
    } else {
      let date = dayjs(row['active_end_date']).add(1, 'month');

      if (dayjs(row['active_end_date']).date() !== date.date()) {
        date = date.subtract(date.date(), 'day');
      }

      setDefaultFutureStartDate(date);
    }
  }, [row]);

  useEffect(() => {
    if (row['future_status_end_date']) {
      setDefaultFutureEndDate(dayjs(row['future_status_end_date']));
    } else if (defaultFutureStartDate) {
      let date = defaultFutureStartDate.add(10, 'year');

      if (defaultFutureStartDate.date() !== date.date()) {
        date = date.subtract(date.date(), 'day');
      }

      setDefaultFutureEndDate(date);
    }
  }, [row, defaultFutureStartDate]);

  const [futureStartDateValue, setFutureStartDateValue]=useState(null);
  const [futureEndDateValue, setFutureEndDateValue]=useState(null);

  function toCamelCase(arr) {
    let string = arr[0];
    for (let i=1; i<arr.length; i++) {
      string = string+ arr[i].charAt(0).toUpperCase()+arr[i].
          slice(1, arr[i].length);
    }
    return string;
  }
  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(()=>{
    const keyvalues = {};
    values.map((h, _index)=>{
      keyvalues[h] = row[h];
    });
    setEditDetails({...keyvalues});
  }, [row, values]);


  useEffect(()=>{
    if (editMode == true) {
      setStandardCurrencyId(editedDetails.currency_id);
    }
  }, [editMode]);


  useEffect(()=>{
    if (editMode===true) {
      setEditMode(false);
    }
  }, [editModeDisableTrigger]);

  const editTheRow = () =>{
    resetDateOnCancel();
    setEditMode(true);
    editPlayBookRow(row['playbook_id'])
        .then(
            (res)=>{
              if (res && (res?.userMessage || res?.error)) {
                setIsErrorPopupVisible(true);
                setErrMsg(res?.userMessage || `${res?.status} ${res?.error}`);
                setEditMode(false);
              } else if (res) {
                setHasBuyPlan(res?.is_bp_available);
              }
            },
        )
        .catch(
            (err)=>{
              setIsErrorPopupVisible(true);
              setErrMsg('Error has been occured', err);
              setEditMode(false);
            });
  };

  useEffect(()=>{
    setEditMode(false);
  }, [page]);

  useEffect(()=>{
    setDisableSupplierOptionality(false);
    if (!editedDetails.supplier_targets) {
      setEditDetails((prevState)=>({...prevState, supplier_optionality: false}));
      setTimeout(()=>{
        setDisableSupplierOptionality(true);
      }, 1000);
    }
  }, [editedDetails.supplier_targets]);

  useEffect(()=>{
    setDisableTreasuryOptionality(false);
    if (!editedDetails.treasury_targets) {
      setEditDetails((prevState)=>({...prevState, treasury_optionality: false}));
      setTimeout(()=>{
        setDisableTreasuryOptionality(true);
      }, 1000);
    }
  }, [editedDetails.treasury_targets]);

  useEffect(()=>{
    if (!editMode) {
      setstandardUnitId('');
    }
  }, [editMode]);
  const resetDateOnCancel = () => {
    setActiveEndDateValue(row['active_end_date']);
    setSelectedActiveEndDate(defaultActiveEndDate);
    setSelectedFutureStartDate(defaultFutureStartDate);
    setSelectedFutureEndDate(defaultFutureEndDate);
    setFutureStartDateValue(null);
    setFutureEndDateValue(null);
    setEditMode(false);
    setFutureStatusValue(formatStatus(row['future_status']));
    setSectorIdValue(row['sector_id']);
    setSubcommodityIdValue(row['sub_commodity_id']);
    setCountryIdValue(row['region_id']);
  };

  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () =>{
    setEditMode(false);
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);
    const keyvalues = {};
    const originalEdited = {...editedDetails};
    values.map((h, _index)=>{
      if (document.getElementById(h) !==null) {
        originalEdited[h] = document.getElementById(h).value;
      }

      keyvalues[toCamelCase(h.split('_'))] =
       document.getElementById(h) !==null ?
      document.getElementById(h).value : editedDetails[h];
    });
    setEditDetails({...originalEdited});
    idKey.map((idkey)=>{
      keyvalues[`${idkey}`] = row[idkey];
      keyvalues[toCamelCase(idkey.split('_'))] = row[idkey];
      delete keyvalues[idkey];
    });
    singleUpdate({...keyvalues,
      'active': true,
      'cascadeTargetBP': keyvalues.isCascadeTargetBp,
      'measurementUnitId': standardUnitId!=='' ? standardUnitId : keyvalues.standardUnitId,
      'treasuryTargets': keyvalues.treasuryTargets===true || keyvalues.treasuryTargets==='true' ? true : false,
      'supplierTargets': keyvalues.supplierTargets===true || keyvalues.supplierTargets==='true' ? true : false,
      'supplierOptionality': keyvalues.supplierTargets === 'N' ? false : keyvalues.supplierOptionality,
      'treasuryOptionality': keyvalues.treasuryTargets === 'N' ? false : keyvalues.treasuryOptionality,
      'others': keyvalues.others,
      'currencyId': standardCurrencyId!==''? standardCurrencyId: keyvalues.standardCurrencyId,
      'sectorId': sectorIdValue,
      'subCommodityId': subcommodityIdValue,
      'regionId': countryIdValue,
      'buyPlanOwnerUserId': buyplanUID,
      'buyPlanOwnerPersonaId': buyplanPer,
      'activeStartDate': mapDateToBackendFormat(keyvalues.activeStartDate),
      'activeEndDate': formatStatus(futureStatusValue)==''? (mapDateToBackendFormat(activeEndDateValue)==''? null:mapDateToBackendFormat(activeEndDateValue)):mapDateToBackendFormat(keyvalues.activeEndDate),
      'futureStatus': formatStatus(futureStatusValue)==''? null:formatStatus(futureStatusValue),
      'futureStartDate': formatStatus(futureStatusValue)==''? null:(mapDateToBackendFormat(futureStartDateValue)==null? mapDateToBackendFormat(defaultFutureStartDate):mapDateToBackendFormat(futureStartDateValue)),
      'futureEndDate': formatStatus(futureStatusValue)==''? null:(mapDateToBackendFormat(futureEndDateValue)==null? mapDateToBackendFormat(defaultFutureEndDate):mapDateToBackendFormat(futureEndDateValue)),
    }).then((res)=>{
      if (res?.errorMessage || res?.userMessage) {
        setIsErrorPopupVisible(true);
        setErrMsg(res?.errorMessage || res?.userMessage);
        setAddingTrigger(!addingTrigger);
      }
    }).catch((err)=>{
      setIsErrorPopupVisible(true);
      setErrMsg('Error has been occured');
      setAddingTrigger(!addingTrigger);
    });
  };

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#eff6fc',
      color: 'rgba(59, 70, 85, 1)',
      font: 'normal normal 600 13px/19px \'Segoe UI\'',
      padding: '9px',
    },
    [`&.${tableCellClasses.body}`]: {
      font: 'normal normal normal 13px/19px \'Segoe UI\'',
      color: 'rgba(109, 120, 136, 1)',
      padding: '9px',
    },
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: ' #FFFFFF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleFutureStartDateChange = (date) => {
    setSelectedFutureStartDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureStartDateValue(d);
  };

  const handleFutureEndDateChange = (date) => {
    setSelectedFutureEndDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setFutureEndDateValue(d);
  };

  const handleActiveEndDateChange = (date) => {
    setSelectedActiveEndDate(date);
    let d = new Date(date.toISOString());
    d = dayjs(d).format('YYYY-MM');
    setActiveEndDateValue(d);
  };

  const showDelPopup =()=>{
    setShowConfirmDialog(true);
  };

  const handleDeleteRow = () =>{
    deletePlayBookRow(row['playbook_id']).then(
        (res)=>{
          setShowConfirmDialog(false);
          if (res && res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
          } else if (res) {
            setAddingTrigger(!addingTrigger);
            setshowSuccessDialog(true);
          }
        }).catch(
        (err)=>{
          setShowConfirmDialog(false);
          setIsErrorPopupVisible(true);
          setErrMsg(err?.message);
          setAddingTrigger(!addingTrigger);
        });
  };

  return (
    <>
      {showConfirmDialog && <AppConfirmDialog open={showConfirmDialog} handleClose={()=>{
        setShowConfirmDialog(false);
      }} text={''} secondLineText={'Are you sure you want to delete this Playbook?'} setYes={handleDeleteRow} ifYesFirst ={false}/>}
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {row['playbook_id']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['playbook_name']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['master_sector']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
             (
              <FormControl style={{width: '100px'}}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx={customTheme.selectBox}
                  value={sectorIdValue}
                  onChange={(e)=>{
                    setSectorIdValue(e.target.value);
                  }}
                  label="Select"
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  className="newclass"
                >


                  <MenuItem value={null} sx={customTheme.selectMenuBox}>
                    <span>Select</span>
                  </MenuItem>

                  { hasBuyplan && row['sector_id'] && (
                    <MenuItem value={row['sector_id']} sx={customTheme.selectMenuBox}>
                      <span>{row['sector']}</span>
                    </MenuItem>

                  )}

                  { !hasBuyplan && ActiveSectors.length !== 0 &&
                      ActiveSectors?.map((obj) => ( obj.name === row['master_sector'] &&
                        _.orderBy(obj.sectors, [(s) => s.name?.toLowerCase()])?.map((r, ind)=>(
                          r.active==true &&
                          <MenuItem
                            sx={customTheme.selectMenuBox}
                            key={r?.id || ''}
                            value={r?.id || ''}
                            className="this-is-new-class"
                          >
                            {r.name}
                          </MenuItem>
                        ))
                      ))}
                </Select>
              </FormControl>) :
              row['sector']
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {editMode===true ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={countryIdValue}
              onChange={(e)=>{
                setCountryIdValue(e.target.value);
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >


              <MenuItem value={null} sx={customTheme.selectMenuBox}>
                <span>Select</span>
              </MenuItem>


              { hasBuyplan && row['region_id'] && (
                <MenuItem value={row['region_id']} sx={customTheme.selectMenuBox}>
                  <span>{row['country']}</span>
                </MenuItem>

              )}
              {!hasBuyplan && (mappedRegionsList?.length !==0 ) &&
                  _.orderBy(mappedRegionsList, [(s) => s.region?.toLowerCase()])?.map((r, ind)=>(
                    <MenuItem
                      sx={customTheme.selectMenuBox}
                      key={r?.region_id || ''}
                      value={r?.region_id || ''}
                      className="this-is-new-class"
                    >
                      {r.region}
                    </MenuItem>

                  ))}
            </Select>
          </FormControl>
              ) :
            row['country']
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['category']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['commodity']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {editMode===true ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={subcommodityIdValue}
              onChange={(e)=>{
                setSubcommodityIdValue(e.target.value);
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value={null} sx={customTheme.selectMenuBox}>
                <span>Select</span>
              </MenuItem>
              { hasBuyplan && row['sub_commodity_id'] && (
                <MenuItem value={row['sub_commodity_id']} sx={customTheme.selectMenuBox}>
                  <span>{row['sub_commodity']}</span>
                </MenuItem>

              )}
              { !hasBuyplan && ActiveSubCommodities?.length !== 0 && row['commodity']!=='' &&
                  ActiveSubCommodities?.map((r) => (
                    r.name===row['commodity'] &&
                    _.orderBy(r.subCommodities, [(a) => a.name?.toLowerCase()])?.filter((sb) => sb.is_active)?.map((obj) =>
                      <MenuItem
                        sx={customTheme.selectMenuBox}
                        key={obj?.id || ''}
                        value={obj?.id || ''}
                        className="this-is-new-class"
                      >
                        {obj.name}
                      </MenuItem>,
                    )
                  ))}
            </Select>
          </FormControl>) :
                        row['sub_commodity']
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={buyplanUID}
              onChange={(e)=>{
                setEditDetails({...editedDetails, buyplan_owner: e.target.value});
                setplanUId(e.target.value);
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty

              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                <span>{row['buyplan_owner']}</span>
              </MenuItem>
              { _.orderBy(buyplanPersona['buyplan_owner'], [(c) => c.userName?.toLowerCase()])?.map((obj) => (
                <MenuItem
                  sx={customTheme.selectMenuBox}
                  key={obj.userId}
                  value={obj.userId}
                  onClick={()=>{
                    setbuyplanPer(obj.personaId);
                  }}
                  className="this-is-new-class"
                >
                  {obj.userName}
                </MenuItem>
              ))}
            </Select>
          </FormControl> :
              (editedDetails.buyplan_owner !== undefined || editedDetails.buyplan_owner != null) ? editedDetails.buyplan_owner : 'N/A'
          }
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          { editMode===true ?
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={standardUnitId || editedDetails?.standard_unit_id || ''}
              onChange={(e)=>{
                setstandardUnitId(e.target.value);
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
              renderValue={(value) => {
                const res = Units?.filter((unit) => unit.unit_id ===value)[0];
                return res?.unit_description || '';
              }}
            >
              {Units.length !== 0 &&
                            _.orderBy(Units, [(cat) => cat?.unit_description?.toLowerCase()?.trim()])?.map((r) => (


                              <MenuItem
                                sx={customTheme.selectMenuBox}
                                key={r?.unit_id}
                                value={r?.unit_id}
                                className="this-is-new-class"
                              >
                                {r.unit_description}
                              </MenuItem>


                            ))}
            </Select>
          </FormControl> :
          (editedDetails.standard_unit !== undefined && editedDetails.standard_unit != null) ? `${editedDetails.standard_unit}` : 'N/A'

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={standardCurrencyId}
              onChange={(e)=>{
                setStandardCurrencyId(e.target.value);
                Currency.map((obj)=>{
                  obj.currency_id === e.target.value? setEditDetails({...editedDetails, currency: obj.currency_description}): '';
                });
              }}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox}>
                <span>{row['currency']}</span>
              </MenuItem>
              {Currency.length !== 0 &&
                              _.orderBy(Currency, [(cur) => cur?.currency_description?.toLowerCase()])?.map((r) => (


                                <MenuItem
                                  sx={customTheme.selectMenuBox}
                                  key={r?.currency_id}
                                  value={r?.currency_id}
                                  className="this-is-new-class"
                                >
                                  {r.currency_description}
                                </MenuItem>


                              ))}
            </Select>
          </FormControl> :
          (editedDetails.currency !== undefined && editedDetails.currency != null) ? `${editedDetails.currency}` : ''

          }
        </StyledTableCell>

        <StyledTableCell component="th" scope="row">
          { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={editedDetails.is_cascade_target_bp}
            onChange={(e)=>{
              setEditDetails({...editedDetails, is_cascade_target_bp: e.target.value});
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj == 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
              `${editedDetails.is_cascade_target_bp ? 'Y': 'N'}`
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={editedDetails.optionality}
            onChange={(e)=>{
              setEditDetails({...editedDetails, optionality: e.target.value});
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj == 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
        (editedDetails.optionality !== undefined && editedDetails.optionality != null) ? `${editedDetails.optionality === true ? 'Y' : 'N'}` : 'N/A'

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={editedDetails.supplier_targets}
            onChange={(e)=>{
              setEditDetails({...editedDetails, supplier_targets: e.target.value});
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj == 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
               (editedDetails.supplier_targets !== undefined && editedDetails.supplier_targets != null) ? `${editedDetails.supplier_targets === true ? 'Y' : 'N'}` : 'N/A'
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={editedDetails.supplier_optionality}
            onChange={(e)=>{
              setEditDetails({...editedDetails, supplier_optionality: e.target.value});
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            disabled={disableSupplierOptionality}
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj == 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
        (editedDetails.supplier_optionality !== undefined && editedDetails.supplier_optionality != null) ? `${editedDetails.supplier_optionality === true ? 'Y' : 'N'}` : 'N/A'

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={editedDetails.treasury_targets}
            onChange={(e)=>{
              setEditDetails({...editedDetails, treasury_targets: e.target.value});
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj == 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
        (editedDetails.treasury_targets !== undefined && editedDetails.treasury_targets != null) ? `${editedDetails.treasury_targets=== true ? 'Y' : 'N'}` : 'N/A'

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={editedDetails.treasury_optionality}
            onChange={(e)=>{
              setEditDetails({...editedDetails, treasury_optionality: e.target.value});
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            disabled={disableTreasuryOptionality}
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj == 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
        (editedDetails.treasury_optionality !== undefined && editedDetails.treasury_optionality != null) ? `${editedDetails.treasury_optionality === true ? 'Y' : 'N'}` : 'N/A'

          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { formatStatus(row['current_status'])}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { row['active_start_date']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
          (formatStatus(futureStatusValue)==''?
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['month', 'year']}
              format={'MMM-YYYY'}
              value={selectedActiveEndDate || defaultActiveEndDate}
              onChange={handleActiveEndDateChange}
              minDate={minActiveEndDate}
              renderInput={(params) => <input {...params} />}
            />
          </LocalizationProvider>: row['active_end_date']):
        editedDetails.active_end_date !== null ? row['active_end_date'] : ''
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={futureStatusValue}
            onChange={(e)=>{
              setFutureStatusValue(e.target.value);
              setEditDetails({...editedDetails, future_status: e.target.value});
            }}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { [null, ...['Active', 'Inactive', 'Coverage Suspension', 'Coverage Review Suspension']]?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj || 'Select'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
         formatStatus(row['future_status'])
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
          (formatStatus(futureStatusValue)==''? '':
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['month', 'year']}
              disablePast={true}
              value={selectedFutureStartDate || defaultFutureStartDate}
              onChange={handleFutureStartDateChange}
              format={'MMM-YYYY'}
              minDate={minFutureStartDate}
              renderInput={(params) => <input {...params} />}
            />
          </LocalizationProvider>):
          row['future_status_start_date']
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          { editMode===true ?
          (formatStatus(futureStatusValue)==''? '':
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['month', 'year']}
              disablePast={true}
              format={'MMM-YYYY'}
              value={selectedFutureEndDate || defaultFutureEndDate}
              onChange={handleFutureEndDateChange}
              minDate={selectedFutureStartDate}
              renderInput={(params) => <input {...params} />}
            />
          </LocalizationProvider>):
          row['future_status_end_date']
          }
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row['last_edited_by']}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {`${new Date(row['last_edited']).getDate() < 10 ? '0' +
              new Date(row['last_edited']).getDate() :
              new Date(row['last_edited']).getDate()}-${new Date(row['last_edited']).getMonth() < 10 ?
          monthArr[new Date(row['last_edited']).getMonth()] :
          monthArr[new Date(row['last_edited']).getMonth()] }-${new Date(row['last_edited']).getFullYear()}`}
        </StyledTableCell>

        {accessControl === Edit &&
         <StyledTableCell align="left">
           <Box sx={{display: 'flex', gap: '5px'}}>
             {editMode === false ?
              <>
                <Box
                  sx={{...btnStyle, width: '58px'}}
                  onClick={editTheRow}
                  style={customealltheme.palette.TableEdit}>
                  <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
                  <Box>Edit</Box>
                </Box>
                <Box
                  onClick={showDelPopup}
                  sx={{...btnStyle, opacity: 1}}>
                  <Box>❌</Box>
                </Box>
              </> :
               <>
                 <Box
                   sx={{
                     ...btnStyle,
                     background: '#4297d3',
                     color: 'white',
                     width: '58px',
                     justifyContent: 'center'}}
                   onClick={saveTheRow}
                 >
                   <Box>Save</Box>
                 </Box>
                 <Box
                   sx={{...btnStyle, width: '58px'}}
                   onClick={resetDateOnCancel}>
                   <Box>Cancel</Box>
                 </Box>
               </>
             }

           </Box>
         </StyledTableCell>
        }
      </StyledTableRow>
      <AppDialog open={showSuccessDialog} handleClose={()=>setshowSuccessDialog(false)} text='Playbook deleted Successfully!'/>
    </>
  );
};

export default CommonConfSingleRow;
