import React from 'react';
import {roundToTwo} from '../../services/utils';

const ReadOnlyColumn = ({decider, rowData, columnName, extraMark}) => {
  // adding + infront of rowdata is to convert to numeric
  return (
    <div style={{color: '#6D7888', fontSize: '11px', textAlign: 'center', marginLeft: '-12px'}}>{(+(rowData[columnName]))!==null && ((+(rowData[columnName])).toString().split('.').length>1 ? roundToTwo(decider, (+(rowData[columnName])))?.toLocaleString('en-US'): (isNaN((+(rowData[columnName]?.toString().split(',').join('')))) ? 0 : (+(rowData[columnName]?.toString().split(',').join('')))?.toLocaleString('en-US')))}{`${extraMark?rowData.key==3?'%':'':''}`}</div>

  );
};

export default ReadOnlyColumn;
