// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .tableComponent{
    border-radius: 5px;
    width: 97%;
    margin-top: 20px;
    background-color: white;
}
.resetDiv{
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    align-items: center;
}
.resetDiv p{
    margin-right: 2px;
}

.resetIcon{
    color: red;
}
.greyLine{
    border: 1px solid grey;
} */`, "",{"version":3,"sources":["webpack://./src/Components/MainComponent/TableComponent/TableComponent.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;GAqBG","sourcesContent":["/* .tableComponent{\n    border-radius: 5px;\n    width: 97%;\n    margin-top: 20px;\n    background-color: white;\n}\n.resetDiv{\n    display: flex;\n    justify-content: flex-end;\n    padding-right: 10px;\n    align-items: center;\n}\n.resetDiv p{\n    margin-right: 2px;\n}\n\n.resetIcon{\n    color: red;\n}\n.greyLine{\n    border: 1px solid grey;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
