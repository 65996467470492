import React, {useRef, useEffect, useState} from 'react';

const TaggingTextComponent = ({rowData, setRowData}) => {
  const inputRef = useRef(null);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.innerText = rowData;
      applyColorStyles();
      setCursorToEnd();
      addToUndoStack(rowData);
    }
  }, [rowData]);

  const handleChange = () => {
    if (inputRef.current) {
      setRowData(inputRef.current.innerText);
      applyColorStyles();
      setCursorToEnd();
      addToUndoStack(inputRef.current.innerText);
      setRedoStack([]); // Clear redo stack whenever there's a new change
    }
  };

  const setCursorToEnd = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(inputRef.current);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const applyColorStyles = () => {
    if (inputRef.current) {
      const content = inputRef.current.innerText;
      const words = content.split(' ');
      inputRef.current.innerHTML = words
          .map((word) => {
            if (word.startsWith('@')) {
              return `<span style="color: blue; font-weight: 600; font-size: 14px; cursor: pointer;">${word}</span>`;
            }
            return word;
          })
          .join(' ');
    }
  };

  const addToUndoStack = (content) => {
    setUndoStack([...undoStack, content]);
  };

  const undo = () => {
    if (undoStack.length > 1) {
      const lastChange = undoStack.pop();
      setRedoStack([...redoStack, lastChange]);
      setRowData(undoStack[undoStack.length - 1]);
      inputRef.current.innerText = undoStack[undoStack.length - 1];
      applyColorStyles();
      setCursorToEnd();
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const lastChange = redoStack.pop();
      setUndoStack([...undoStack, lastChange]);
      setRowData(lastChange);
      inputRef.current.innerText = lastChange;
      applyColorStyles();
      setCursorToEnd();
    }
  };

  // Listen for Ctrl + Z to undo and Ctrl + Y to redo
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        undo();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
        redo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [undo, redo]);

  return (
    <div
      className='TextCommentScroll'
      style={{
        width: '100%',
        maxHeight: '40px',
        overflowY: 'scroll',
      }}
    >
      <div
        contentEditable
        ref={inputRef}
        style={{
          width: '100%',
          padding: '0px 8px',
          textAlign: 'left',
          outline: '0',
          color: 'rgb(109, 120, 136)',
          fontSize: '12px',
          border: 'none',
        }}
        onInput={handleChange}
      />
    </div>
  );
};

export default TaggingTextComponent;


export const taggingColumn = {
  component: TaggingTextComponent,
  deleteValue: () => '',
  copyValue: ({rowData}) => rowData,
  pasteValue: ({value}) => value,
};

export const getLastCharacterXPosition = (div) => {
  const range = document.createRange();
  const selection = window.getSelection();

  if (selection.rangeCount > 0) {
    const lastTextNode = getLastTextNode(div);
    if (lastTextNode) {
      range.setStart(lastTextNode, lastTextNode.textContent.length);
      range.collapse(true);
      const rect = range.getBoundingClientRect();
      return rect.right;
    }
  }

  return 0;
};

const getLastTextNode = (div) => {
  let lastTextNode = null;
  const nodes = div.childNodes;

  for (let i = nodes.length - 1; i >= 0; i--) {
    const node = nodes[i];
    if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
      lastTextNode = node;
      break;
    }
  }

  return lastTextNode;
};
