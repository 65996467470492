import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {changeCountryDropdownValue} from '../../../../redux/features/buttonStatus';
const DropdownSelectSector = ({sectorCountryMap, rowData, indexOfYear, apiData}) => {
  const [sectorSelected, setSectorSelected] = React.useState('');
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const noSectorFound = 'No Sector Found';
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSectorSelected(event.target.value);
    if (event.target.value === noSectorFound) {
      setSectorSelected('');
      return;
    }
    if (apiData) {
      apiData[indexOfYear].map((element, index) => {
        if (element.id == rowData.id) {
          element.sector_id = event.target.value;
          rowData.sector_id = event.target.value;
          rowData.region_id = '';
          if (rowData?.prevMonth) {
            element['author'] = isCreator?.userName;
            rowData['author'] = isCreator?.userName;
            element['isChanged'] = true;
            rowData['isChanged'] = true;
          }
          const d = new Date();
          dispatch(changeCountryDropdownValue(d.getMilliseconds()));
        }
      });
    }
  };

  function getSectorName(sectorId) {
    const sectorObj = sectorCountryMap.filter((sector) => sector.id === sectorId);
    return sectorObj[0]?.name;
  }

  useEffect(() => {
    setSectorSelected(rowData.sector_id !== '' ? getSectorName(rowData.sector_id) : '');
  }, [rowData]);

  useEffect(() => {
    setSectorSelected(rowData.sector_id|| '');
    apiData[indexOfYear].map((element, index) => {
      if (element.id == rowData.id) {
        element.sector_id = rowData.sector_id;
      }
    });
  }, [apiData, indexOfYear, rowData]);
  return (
    <div>
      <FormControl variant='standard' sx={{width: '78px', fontSize: '12px'}}>
        <Select
          id='sector-dropdown'
          value={sectorSelected || ''}
          disabled={rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume}
          placeholder={'Select'}
          onChange={handleChange}
          label='Age'
          sx={{width: '78px', fontSize: '12px'}}
        >
          {
            (sectorCountryMap.length > 0 ? [...sectorCountryMap] : [noSectorFound]).map((sector) => {
              return <MenuItem sx={{fontSize: '13px'}} key={sector.id} value={sector.id} >{sector.name}</MenuItem>;
            })
          }
        </Select>
      </FormControl>
    </div>
  );
};


export default DropdownSelectSector;
