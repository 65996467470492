/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {useState} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Box, Checkbox, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {setSelectedUnitConversion, setSortFilter, setSortType} from '../../../redux/features/configurations';
import {useDispatch} from 'react-redux';
import customealltheme from '../../../theme';


const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
};

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

function UnitConversionDisplay({row}) {
//   const [localUnit, setLocalUnit] = React.useState('');
//   const [Uom, setUom] = React.useState('');

  // eslint-disable-next-line no-unused-vars
  const [localUnitarray, setLocalUnitArray] = useState([]);
  const [UomArray, setUomArray] = useState([]);


  const dispatch = useDispatch();


  const handleSubmit = () => {
    dispatch(setSelectedUnitConversion({localUnitarray: localUnitarray,
      UomArray: UomArray,
    }));
  };


  const handleChangeLocalUnit = (event) => {
    // setUomArray([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'unitDes_all') {
      const str = ['unitDes_all'];
      row.map((r) => (
        str.push(r?.local_unit_description)
      )),

      setLocalUnitArray(str);
    } else if (localUnitarray.includes('unitDes_all') && !value.includes('unitDes_all')) {
      setLocalUnitArray([]);
    } else {
      if (localUnitarray.includes('unitDes_all')) setLocalUnitArray([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('unitDes_all')) tepVal.splice(tepVal.indexOf('unitDes_all'), 1);
      setLocalUnitArray(tepVal);
    }
  };
  const newValue=row.map((ab)=>ab.local_unit_description);
  const updatedValue=[...new Set(newValue)];

  const handleChangeUOM = (event) => {
    // setLocalUnitArray([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'unit_all') {
      const str = ['unit_all'];
      row.map((r) => (
        str.push(r?.local_uom)
      )),

      setUomArray(str);
    } else if (UomArray.includes('unit_all') && !value.includes('unit_all')) {
      setUomArray([]);
    } else {
      if (UomArray.includes('unit_all')) setUomArray([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('unit_all')) tepVal.splice(tepVal.indexOf('unit_all'), 1);
      setUomArray(tepVal);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: '100%', padding: '18px'}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
          }}
          style={customealltheme.palette.filterheading}
        >
          Units Conversion Master
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '35px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
               Local Unit Description
            </Box>
            <Box
              sx={customealltheme.palette.filterDropdown}
            >
              <FormControl fullWidth>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    sx = {theme.selectBox}
                    id="demo-multiple-checkbox"
                    multiple
                    value={localUnitarray}
                    onChange={handleChangeLocalUnit}
                    input={<CustomInput label="Tag" />}
                    displayEmpty
                    renderValue={( selected, displayEmpty) => displayEmpty = localUnitarray.length ? selected.join(', ') :'Select All'}
                    className="newclass"
                  >
                    <MenuItem sx={theme.selectMenuBox} key='unitDes_all' value='unitDes_all' className='this-is-new-class'>
                      <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={localUnitarray?.indexOf('unitDes_all') > -1}/>
                      <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                    </MenuItem>
                    {updatedValue.length !== 0 &&
                    // row
                    //     .slice()
                    //     .sort((a, b) => a.local_unit_description.localeCompare(b.local_unit_description))
                    //     .map((r) => (
                        _.orderBy(updatedValue, [(sec) => sec?.toLowerCase().trim()])?.map((r)=>(
                          <MenuItem
                            sx={theme.selectMenuBox}
                            key={r}
                            value={r}
                            className="this-is-new-class"
                          >
                            <Checkbox
                              sx={{
                                '.MuiSvgIcon-root': {
                                  fontSize: '14px',
                                },
                              }}
                              checked={localUnitarray?.indexOf(r) > -1}
                            />
                            {r}
                          </MenuItem>
                        ))}

                  </Select>
                </FormControl>
              </FormControl>
            </Box>
          </Box>
          {/* <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Local UoM
            </Box>
            <Box
              sx={{minWidth: '150px', maxWidth: '150px', marginTop: '13px'}}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {theme.selectBox}
                  id="demo-multiple-checkbox"
                  multiple
                  value={UomArray}
                  onChange={handleChangeUOM}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = UomArray.length ? selected.join(', ') :'Select All'}
                  className="newclass"
                >
                  <MenuItem sx={theme.selectMenuBox} key='unit_all' value='unit_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={UomArray?.indexOf('unit_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(row.length !== 0) &&

                      row?.map((r) => (
                        <MenuItem sx={theme.selectMenuBox}
                          key={r?.unit_conversion_id} value={r?.local_uom} className="this-is-new-class">
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={UomArray?.indexOf(r?.local_uom) > -1}
                          />
                          {r?.local_uom}
                        </MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box> */}

          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} component="span"></Box>
            <Box sx={{minWidth: 130, marginTop: '28px'}}>
              <Button
                onClick={handleSubmit}
                sx={{
                  'backgroundColor': '#4297d3',
                  'color': '#ffffff',
                  'textTransform': 'none',
                  'minWidth': '0',
                  'padding': '8px 35px',
                  '&:hover': {
                    backgroundColor: '#4297d3',
                  },
                }}
                style={customealltheme.palette.filterSubmit}
              >
                  Submit
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: {
              display: 'inline-block',
              marginRight: '2px',
              font: 'normal normal 600 13px/15px \'Segoe UI\'',
              color: '#3174b6',
              cursor: 'pointer',
            },
            marginTop: '30px',
          }}
          style={customealltheme.palette.resetbutton}
        >
          <p
            onClick={() => {
              dispatch(setSelectedUnitConversion([]));
              dispatch(setSortFilter(''));
              dispatch(setSortType(''));
              setLocalUnitArray([]);
              setUomArray([]);
            }}
          >
            Reset Filters
          </p>
        </Box>
      </Box>
      <Box
        sx={{border: '1px solid #f5f5f5', width: '97%', margin: 'auto'}}
      ></Box>
    </ThemeProvider>
  );
}

export default UnitConversionDisplay;
