/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Box, FormControl, InputBase, MenuItem, Select, TableCell, TableRow, TextareaAutosize, createTheme, tableCellClasses} from '@mui/material';
import {BiEdit} from 'react-icons/bi';
import theme from '../../../theme';
import customealltheme from '../../../theme';
import config from '../../../Utils/Config';
import './NotificationSettings.css';
import moment from 'moment';
import NotificationSettingDialog from './NotificationSettingDialog';
import ConfigurationServices from '../../../services/ConfigurationServices';
import {AppDialog, AppErrorDialog} from '../../common/App-Dialog/AppDialog';
const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8f8f8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});
const NotificationMainSingleRow = ({row, accessControl, open, setopen, setData, setscenarioId, setselectedCat}) => {
  const [presentRow, setPresentRow] = useState({});
  const [editMode, seteditMode] = useState(false);
  const Edit = config.AccessLevel.Edit;
  const [savingerror, setSavingError] = useState(false);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const editRow = () => {
    seteditMode(true);
  };
  const saveTheRow = async () => {
    await ConfigurationServices.postNotificationSetting(presentRow.scenario_id, presentRow.consolidation_level==''?null: presentRow.consolidation_level, presentRow.is_consolidate, presentRow?.reminder_frequency==null? null :presentRow.reminder_frequency.join(',')).then((res)=>{
      setIsSavePopupVisible(true);
      setData((prev)=>prev.map((p)=>p.scenario_id==row.scenario_id? {...presentRow, reminder_frequency: presentRow?.reminder_frequency==null? null :presentRow?.reminder_frequency?.join(',')}: p));
    }).catch((err)=>{
      setPresentRow({...row, scenario_type: row.scenario_type.split('_').join(' '), reminder_frequency: row.reminder_frequency?.split(',')?.filter((f)=>f!=='')});
      setSavingError(true);
    });
    // setData((prev)=>prev.map((p)=>p.scenario_id==row.scenario_id? {...presentRow, reminder_frequency: presentRow.reminder_frequency.join(',')}: p));
    seteditMode(false);
  };
  useEffect(()=>{
    setPresentRow({...row, scenario_type: row.scenario_type.split('_').join(' '), reminder_frequency: row.reminder_frequency==null?null:row.reminder_frequency?.split(',')?.filter((f)=>f!=='')});
    // console.log(row);
  }, [row]);
  return (
    <>
      <StyledTableRow>
        {/* <StyledTableCell component="th" scope="row">
          {presentRow.scenario_id}
        </StyledTableCell> */}
        <StyledTableCell component="th" scope="row">
          {presentRow.scenario_type}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {presentRow.scenario_description}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {editMode==true?
     <FormControl style={{width: '100px'}}>
       <Select
         labelId="demo-multiple-checkbox-label"
         sx={customTheme.selectBox}
         value={presentRow.is_consolidate}
         onChange={(e)=>{
           setPresentRow((prev)=>({...prev, is_consolidate: e.target.value}));
           if (e.target.value==false) {
             setPresentRow((prev)=>({...prev, consolidation_level: ''}));
           }
           if (presentRow.is_reminder=='Y' && e.target.value==true) {
             setPresentRow((prev)=>({...prev, consolidation_level: 'DAILY'}));
           }
         }}
         label="Select"
         input={<CustomInput label="Tag" />}
         displayEmpty
         className="newclass"
       >
         <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
           <span>Select</span>
         </MenuItem>
         { ['Yes', 'No'] ?.map((obj, index) => (
           <MenuItem
             sx={customTheme.selectMenuBox}
             key={obj}
             value={obj=='Yes'? true : false}
             className="this-is-new-class"
             selected={(presentRow.is_consolidate && obj === 'Yes') || (!presentRow.is_consolidate && obj === 'No')}
           >
             {obj}
           </MenuItem>
         ))}
       </Select>
     </FormControl> :
    presentRow.is_consolidate ?'Yes': 'No'}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {(editMode==true && presentRow.is_reminder!=='Y')?
     <FormControl style={{width: '100px'}}>
       <Select
         labelId="demo-multiple-checkbox-label"
         sx={customTheme.selectBox}
         value={presentRow.consolidation_level}
         onChange={(e)=>{
           setPresentRow((prev)=>({...prev, consolidation_level: e.target.value}));
         }}
         label="Select"
         input={<CustomInput label="Tag" />}
         displayEmpty
         disabled = {!presentRow.is_consolidate}
         className="newclass"
       >
         <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
           <span>Select</span>
         </MenuItem>
         { ['DAILY', 'WEEKLY', 'MONTHLY']?.map((obj, index) => (
           <MenuItem
             sx={customTheme.selectMenuBox}
             key={obj}
             value={obj}
             className="this-is-new-class"
             selected={obj==presentRow.consolidation_level}
           >
             {obj}
           </MenuItem>
         ))}
       </Select>
     </FormControl> :
    presentRow.consolidation_level}
        </StyledTableCell>
        <StyledTableCell>
          {presentRow.is_reminder=='Y' ? (editMode==true?
        <div className='numberCont'>{ Array.from({length: 31}, (_, index) => index + 1).map((number) => (
          <div onClick={()=>{
            if (presentRow.reminder_frequency?.includes(number.toString())) {
              setPresentRow((prev)=>({...prev, reminder_frequency: presentRow.reminder_frequency?.filter((f)=>f!=number)}));
            } else {
              setPresentRow((prev)=>({...prev, reminder_frequency: [...presentRow.reminder_frequency, number.toString()]}));
            }
          }} key={number} className={`${presentRow.reminder_frequency?.includes( number.toString())? 'selectedNum': 'notSelectedNum'}`}>
            {number}
          </div>
        ))}</div> :
        presentRow.reminder_frequency?.sort((a, b)=>+a-+b).map((number) => {
          if (number <= 30) {
            if (moment(number, 'D').format('Do')=='Invalid date') {
              return '';
            }

            return moment(number, 'D').format('Do');
          } else {
            return '31st';
          }
        }).join(', ')) :
       'NA'}
        </StyledTableCell>
        <StyledTableCell align="left">
          {accessControl === Edit && (
            <StyledTableCell align="left">
              {editMode === false ? (
      <Box sx={{display: 'flex', gap: '8px', width: '200px'}}>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
          }}
          onClick={editRow}
          style={customealltheme.palette.TableEdit}
        >
          <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
          <Box>Edit</Box>
        </Box>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '100px !important',
            whiteSpace: 'noWrap',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
          }}
          onClick={()=>{
            setopen(true);
            setscenarioId(presentRow.scenario_id);
            setselectedCat(presentRow.scenario_type);
          }}
          style={customealltheme.palette.TableEdit}
        >
          <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
          <Box>Recipients</Box>
        </Box>
      </Box>
    ) : (
      <Box sx={{display: 'flex', gap: '5px'}}>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
            background: '#4297d3',
            color: 'white',
            justifyContent: 'center',
          }}
          onClick={saveTheRow}
        >
          <Box>Save</Box>
        </Box>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
          }}
          onClick={(e) =>{
            setPresentRow({...row, scenario_type: row.scenario_type.split('_').join(' '), reminder_frequency: row.reminder_frequency?.split(',')});
            seteditMode(false);
          }}
        >
          <Box>Cancel</Box>
        </Box>
      </Box>
    )}
            </StyledTableCell>
          )}

        </StyledTableCell>
      </StyledTableRow>
      <AppErrorDialog open={savingerror} handleClose={()=> {
        setSavingError(false);
        setopen(false);
      }} text={'Error occured while saving data'}/>
      <AppDialog
        open={isSavePopupVisible}
        handleClose={() => {
          setIsSavePopupVisible(false);

          // setValue(1);
        }}
        text="Notification setting saved successfully"
      />
    </>
  );
};

export default NotificationMainSingleRow;
