import styled from '@emotion/styled';
import {
  Box,
  TableCell,
  TableRow,
  TextareaAutosize,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit, BiErrorAlt} from 'react-icons/bi';
import theme from '../../../../theme';
import customealltheme from '../../../../theme';
import config from '../../../../Utils/Config';
import {monthArr, roundToTwo} from '../../../../services/utils';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CommonConfSingleRow = ({
  row,
  setAddingTrigger,
  addingTrigger,
  values,
  accessControl,
  idKey,
  singleUpdate,
  setErrMsg,
  setIsErrorPopupVisible,
  page,
  submitClicked,
  filterResetClicked,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editedDetails, setEditDetails] = useState({});

  useEffect(()=>{
    setEditMode(false);
  }, [page]);

  function toCamelCase(arr) {
    let string = arr[0];
    for (let i = 1; i < arr.length; i++) {
      string =
        string +
        arr[i].charAt(0).toUpperCase() +
        arr[i].slice(1, arr[i].length);
    }
    return string;
  }
  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(() => {
    const keyvalues = {};
    values.map((h, index) => {
      keyvalues[h] = row[h];
    });
    setEditDetails({...keyvalues});
  }, [row, values]);

  const editTheRow = () => {
    setEditMode(true);
  };

  const updateConversionMultiplier = () => {
    setEditDetails({
      ...editedDetails,
      conversionMultiplier: 1,
    });
  };

  useEffect(() => {
    if (editedDetails.local_currency === editedDetails.standard_currency && editMode) {
      updateConversionMultiplier();
    }
  }, [editMode]);
  const enableSaveButton = editedDetails.conversionMultiplier!='' && editedDetails.conversionMultiplier > 0;
  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () => {
    const keyvalues = {};
    const originalEdited = {...editedDetails};
    values.map((h, index) => {
      if (document.getElementById(h) !== null) {
        originalEdited[h] = document.getElementById(h).value;
      }

      keyvalues[toCamelCase(h.split('_'))] =
        document.getElementById(h) !== null ?
          document.getElementById(h).value :
          editedDetails[h];
    });
    setEditDetails({...originalEdited});
    idKey.map((idkey) => {
      keyvalues[`${idkey}`] = row[idkey];
      keyvalues[toCamelCase(idkey.split('_'))] = row[idkey];
      delete keyvalues[idkey];
    });

    singleUpdate(
        {...keyvalues,
          conversionMonthYear: `${keyvalues.conversionMonthYear.split('-')[1]}-${String(monthArr.indexOf(keyvalues.conversionMonthYear.split('-')[0]) + 1).padStart(2, '0')}`,
          localCurrencyId: row['local_currency_id'],
          standardCurrencyId: row['standard_currency_id'],
          active: true,
          conversionMultiplier: editedDetails.conversionMultiplier?editedDetails.conversionMultiplier:0},

    )
        .then((res) => {
          if (res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
            setAddingTrigger(!addingTrigger);
          } else if (res) {
            setEditMode(false);
            setAddingTrigger(!addingTrigger);
          }
        })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured');
          setAddingTrigger(!addingTrigger);
        });
  };

  const saveButtonStyles = {
    border: '1px solid gray',
    display: 'flex',
    border: '0.5px solid #CECECE',
    borderRadius: '4px',
    width: '58px',
    alignItems: 'center',
    gap: '5px',
    padding: '2px 6px',
    background: '#4297d3',
    color: 'white',
    justifyContent: 'center',
    cursor: enableSaveButton ? 'pointer' : 'not-allowed',
  };
  const Edit = config.AccessLevel.Edit;
  useEffect(()=> {
    setEditMode(false);
  }, [page, submitClicked, filterResetClicked]);
  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">

        { editedDetails.local_currency}

      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row?.conversion_month_year? row.conversion_month_year.replace('-', ' ') : ''}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row.standard_currency}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" >
        {editMode === true ? (
            editedDetails.local_currency === editedDetails.standard_currency ? (
                1
            ) : (
              <TextareaAutosize
                id="convmult"
                style={theme.palette.TextArea}
                placeholder="Type in here…"
                defaultValue={editedDetails.conversion_multiplier}
                minRows={1}
                maxRows={4}
                onChange={(e) => {
                  setEditDetails({
                    ...editedDetails,
                    conversionMultiplier: roundToTwo(2, parseFloat(e.target.value)),
                  });
                }}
              />
            )
          ) : (
            <Box
              onMouseOver={(e) => {
                e.target.title = editedDetails['conversion_multiplier'] >= 1 ? 'Multiplier' : 'Divider';
              }}
            >
              {`${editedDetails['conversion_multiplier'] ? editedDetails['conversion_multiplier']?.toLocaleString('en-US', {maximumFractionDigits: 2}) : ''}`}
            </Box>
          )}

      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row?.last_updated_by?row?.last_updated_by:''}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row?.last_updated_on?row?.last_updated_on:''}
      </StyledTableCell>


      {accessControl === Edit && row.is_editable == 'Y' ? <StyledTableCell align="left">
        {editMode === false ? (
          <Box
            sx={{
              border: '1px solid gray',
              display: 'flex',
              border: '0.5px solid #CECECE',
              borderRadius: '4px',
              width: '58px',
              alignItems: 'center',
              gap: '5px',
              padding: '2px 6px',
              cursor: 'pointer',
            }}
            onClick={editTheRow} style={customealltheme.palette.TableEdit}
          >
            <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
            <Box>Edit</Box>
          </Box>
        ) : (
          <Box sx={{display: 'flex', gap: '5px'}}>
            <Box >
              <Box sx={enableSaveButton ? saveButtonStyles : {...saveButtonStyles, opacity: 0.5}}
                onClick={enableSaveButton? saveTheRow:undefined}>Save</Box>
            </Box>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={(e) => setEditMode(false)}
            >
              <Box>Cancel</Box>
            </Box>
          </Box>
        )}
      </StyledTableCell> : accessControl !== Edit ? '' : <StyledTableCell>
        <Box
          sx={{
            border: '1px solid #CECECE',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'not-allowed',
            color: '#CECECE',
          }}
          style={customealltheme.palette.TableEdit}
        >
          <BiErrorAlt style={{fontSize: '13px', color: '#CECECE'}} />
          <Box>Edit</Box>
        </Box>
      </StyledTableCell>}
    </StyledTableRow>
  );
};

export default CommonConfSingleRow;
