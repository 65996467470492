
import React from 'react';
import Box from '@mui/material/Box';
// import {Link} from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import {Button} from '@mui/material';
export default function FallbackError({resetErrorBoundary}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  const handleRedirect = ()=>{
    resetErrorBoundary();
  };

  return (
    <div>
      <div>

        <Box style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '80vh',
          padding: '4rem',
          textAlign: 'center',
          lineHeight: '1.5',
          flexDirection: 'column',
        }}>
          <ErrorIcon sx={{fontSize: '2.5rem'}}/>
          <p style={{fontSize: '2.5rem', color: 'rgba(59, 70, 85, 1)'}}>Something went wrong.</p>
          <p style={{fontSize: '1.2rem', color: '#76808F', marginBottom: '1rem'}}>Please click to return to the homepage.</p>

          <Button sx={{
            'textDecoration': 'none',
            'font': 'normal normal 600 14px/21px "Segoe UI"',
            'backgroundColor': '#4297d3',
            'color': '#ffffff',
            'minWidth': '0%',
            'padding': '8px 35px',
            'borderRadius': '4px',
            '&:hover': {
              'backgroundColor': '#4297d3',
              'color': '#ffffff',
            },

          }} onClick={handleRedirect}>Go Home</Button>
        </Box>
      </div>
    </div>
  );
}
