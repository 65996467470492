/* This Page is the reports page where the admin can see the reports
e.t.c. Here the page gets the value from the reducer which decide which page should be visible on the screen.
*/

import React from 'react';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {useHistory} from 'react-router';
import BuyPlanReport from './buReport/BuyPlanReport';
import PlaybookCompMap from './playbookCompMap/PlaybookCompMap';
import PowerBi from './PowerBi/PowerBi';

const Reports = () => {
  const selectedReport = useSelector((state)=>state.configurations.selectedReport);

  const navigate = useHistory();
  useEffect(()=>{
    navigate.push(`/report/${selectedReport}`);
  }, [selectedReport]);

  if (selectedReport==='buyplanreport') {
    return (

      <BuyPlanReport />

    );
  } else if (selectedReport==='playbookcompmap') {
    return (

      <PlaybookCompMap />

    );
  } else if (selectedReport==='powerbireport') {
    return (

      <PowerBi />

    );
  }
};

export default Reports;
