/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/*
functionality : view/filter & Edit/Save buyplan target and playbook target with respect to Exception and Reference target
It renders tables conditional basis (like which talbe should be rendered or which not for example supplier min, overall min etc) which we receive in apis
We can filter tables data according to year wise , Reference & Exception
Excution Month is plan Month
*/
import React, {useEffect, useState, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Box, Button, CircularProgress} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {
  DynamicDataSheetGrid,
  textColumn,
  keyColumn,
  intColumn,
  createTextColumn,
} from 'react-datasheet-grid';
import MainTheme from '../../theme';
import {CopyIcon} from '../../Components/common/Icons/icons';
import Styles from '../../styles/target';
import TargetFilter from '../../Components/target/TargetFilter';
import {getTargetPlayBookData, getTargetBuyPlanData, savePlaybooksHandler, saveBuyPlansHandler, setInitialState, getTargetLogsData} from '../../redux/target/index';

import {AppDialog, AppErrorDialog} from '../../Components/common/App-Dialog/AppDialog';
import {fetchTargetPlayBookReason} from '../../redux/Common';
import {monthsCapital, monthsCamelCase} from '../../Utils/Common';
import config from '../../Utils/Config';
import PlayBookUpdateReasonModal from '../../Components/target/Dialog';
import {utils, writeFileXLSX} from 'xlsx';
import {roundToTwo} from '../../services/utils';
import LogsTable from './TargetLogsTable';
const EDIT = config.AccessLevel.Edit;
const currYear = new Date().getFullYear();

const Target = (props) => {
  const [filterPeriod, setfilterPeriod] = useState('Y0');
  const [toggle, setToggle] = useState(true);
  const [filterPeriodYear, setfilterPeriodYear] = useState(currYear);
  const [data, setData] = useState({});
  const [isReference, setIsReference] = useState(true);
  const [editDisable, setEditDisable] = useState(false);
  const [isPlayBook, setIsPlayBook] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFilters, setSelectedFilter] = useState({
    playbookName: '', buyPlanName: '', BuyPlanId: null, PlayBookId: null,
  });
  // const [tableOptions, setTableOptions]=useState({});
  const [tablesToShow, settablesToShow]=useState([]);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(null);
  const [errorMessage, setErrorMessage]=useState(null);
  const [haveAccessToPage, setHaveAccessToPage]=useState(null);
  // const [isFirstTime, setIsFirstTime]=useState(false);
  const [isPlayBookUpdateReasonmodalVisible, setIsPlayBookUpdateReasonmodalVisible]=useState(false);
  const TargetNames = useSelector((state)=>state?.targetApi?.allTargetNames);
  const [popup, setPopup] = useState(false);

  useEffect(()=>{
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params && params.size>2) { // atleast 2 parameters
      const buyplanid = parseInt(params.get('bp'));
      if (buyplanid) {
        setIsPlayBook(false);
      }
    }
  }, []);
  useEffect(()=>{
    const resultArr = TargetNames;
    resultArr?.forEach((mainObj)=>{
      mainObj.playbooks.forEach((playBook)=>{
        if (playBook.playbookName == selectedFilters.playbookName) {
          playBook.buyplans.forEach((value)=>{
            setEditDisable(value.cascadeTargetBP ? true : false);
          });
        }
      });
    });
  }, [selectedFilters]);

  const onLogClick = () => {
    setPopup(true);
    const id = isPlayBook ?selectedFilters.PlayBookId : selectedFilters.BuyPlanId;
    dispatch(getTargetLogsData(id, isPlayBook));
  };

  const onDownload = () => {
    const sheetData = {};
    const exceptionOrReference = isReference ? 'REFERENCE' : 'EXCEPTION';
    const allTableData = data[exceptionOrReference];
    Object.keys(allTableData)?.forEach((tableName) => {
      const tableData = allTableData[tableName];
      Object.keys(tableData)?.forEach((year) => {
        const excelFormattedData = tableData[year].map((data) => {
          const excelCurrentIterationData={
            'PlayBookName': data['PlayBookName'],
          };
          if (!isPlayBook) {
            excelCurrentIterationData['BuyPlan Name']= data['BuyPlanName'];
          }
          excelCurrentIterationData['Plan Month']= data['ExecutionMonth'];
          excelCurrentIterationData['Target Label']= data['TargetLabel'];
          excelCurrentIterationData['Target Type']= data['TargetType'];
          excelCurrentIterationData['Jan']= (data['JAN'] || 0) + '%';
          excelCurrentIterationData['Feb']= (data['FEB'] || 0) + '%';
          excelCurrentIterationData['Mar']= (data['MAR'] || 0) + '%';
          excelCurrentIterationData['Apr']= (data['APR'] || 0) + '%';
          excelCurrentIterationData['May']= (data['MAY'] || 0) + '%';
          excelCurrentIterationData['Jun']= (data['JUN'] || 0) + '%';
          excelCurrentIterationData['Jul']= (data['JUL'] || 0) + '%';
          excelCurrentIterationData['Aug']= (data['AUG'] || 0) + '%';
          excelCurrentIterationData['Sep']= (data['SEP'] || 0) + '%';
          excelCurrentIterationData['Oct']= (data['OCT'] || 0) + '%';
          excelCurrentIterationData['Nov']= (data['NOV'] || 0) + '%';
          excelCurrentIterationData['Dec']= (data['DEC'] || 0) + '%';
          return excelCurrentIterationData;
        });
        sheetData[year] = [...(sheetData[year] || []), ...excelFormattedData];
      });
    });
    const wb = utils.book_new();
    Object.keys(sheetData)?.forEach((year) => {
      const ws = utils.json_to_sheet(sheetData[year]);
      utils.book_append_sheet(wb, ws, year);
    });
    writeFileXLSX(wb, isPlayBook ? `PLAYBOOK ${exceptionOrReference}.xlsx` : `BUYPLAN ${exceptionOrReference}.xlsx`);
  };

  /*
    Creating custom percentage value vecause orignal percentage column take percent by deviding 100,
    which is not good fit for copy from excel and paste to excel

  */

  const customPercentColumn = createTextColumn({
    alignRight: true,
    formatBlurredInput: (value) =>{
      let enteredValue = '';
      if (!isNaN(value) && value>=0 && value!=null &&value.toString().split('.').length>1) {
        enteredValue = roundToTwo(2, value.toString().split('.')[0]+'.'+value.toString().split('.')[1].slice(0, 5)) +'%';
      } else {
        enteredValue = typeof value === 'number' && value>=0 && value!=null ?
        (value) +'%' :
        '';
      }

      return enteredValue;
    },

    formatInputOnFocus: (value) =>{
      let enteredValue = '';
      if (!isNaN(value) && value>=0 && value!=null &&value.toString().split('.').length>1) {
        enteredValue = roundToTwo(2, value.toString().split('.')[0]+'.'+value.toString().split('.')[1].slice(0, 5));
      } else {
        enteredValue = typeof value === 'number' && value>=0 && value!=null ?
        (value) :
        '';
      }

      return enteredValue;
    },
    parseUserInput: (value) => {
      const number = parseFloat(value);
      return (!isNaN(number) && number >= 0) ? roundToTwo(2, number) : null;
    },
    parsePastedValue: (value) => {
      const number = parseFloat(value);
      return (!isNaN(number) && number >= 0) ? roundToTwo(2, number) : null;
    },
  });

  const cellClassName = (rowData, isFisrtYear, targetMonth)=>{
    if (rowData.monthsOfUnEqualMinMaxValue && rowData.monthsOfUnEqualMinMaxValue.includes(targetMonth)) {
      return 'minMaxDiff';
    } else if (rowData.monthsOfExceptionValue && rowData.monthsOfExceptionValue.includes(targetMonth)) {
      return 'exceptionCell';
    } else if (isFisrtYear && monthsCapital.indexOf(rowData.ExecutionMonth) >= monthsCapital.indexOf(targetMonth)) {
      return 'historyCell';
    }
    return 'upcommingCell';
  };

  const getColumns=()=>{
    const isFisrtYear = (isReference && filterPeriod === 'Y0') || (!isReference && filterPeriodYear===currYear);
    const columns = [
      {
        ...keyColumn('PlayBookName', textColumn),
        title: 'PlayBook Name',
        disabled: true,
        headerClassName: 'header-playbook-name',
      },
      {
        ...keyColumn('ExecutionMonthToShow', textColumn),
        title: 'Plan Month',
        disabled: true,
        headerClassName: 'header-playbook-name',

      },
      {
        ...keyColumn('TargetLabel', textColumn),
        title: 'Target Label',
        disabled: true,
        headerClassName: 'header-playbook-name',

      },
      {
        ...keyColumn('TargetType', textColumn),
        title: 'Target Type',
        disabled: true,
        headerClassName: 'header-playbook-name',

      },
    ];

    if (!isPlayBook) {
      const buyplanColumn = {
        ...keyColumn('BuyPlanName', textColumn),
        title: 'Buy Plan Name',
        disabled: true,
        headerClassName: 'header-playbook-name',
      };
      columns.splice(1, 0, buyplanColumn);
    }

    monthsCapital.forEach((capitalMonth)=>{
      const currentMonthColumn={
        ...keyColumn(capitalMonth, customPercentColumn),
        headerClassName: 'header-playbook-name',
        cellClassName: (({rowData})=>cellClassName(rowData, isFisrtYear, capitalMonth)),
        title: capitalMonth[0] + capitalMonth.slice(1).toLowerCase(), // 'Jan','Feb'.....
        minWidth: 70,
        disabled: ()=>(!isEditMode),
      };
      columns.push(currentMonthColumn);
    });

    if (isEditMode===true) {
      columns.push(
          {
            ...keyColumn('', intColumn),
            component: ({rowData}) => (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  copyRow(rowData);
                }}
              >
                <CopyIcon />
              </Button>
            ),
            title: '',
          },
      );
    }
    return columns;
  };

  const columns = useMemo(()=>{
    return getColumns();
  }, [isEditMode, isPlayBook, isReference, filterPeriod, filterPeriodYear]);


  const dispatch = useDispatch();
  const functionalityAccess= useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  const targetApiData = useSelector((state)=>state?.targetApi?.targetPlayBookData);
  const error = useSelector((state)=>state?.targetApi?.error);
  const errorMsg = useSelector((state)=>state?.targetApi?.errorMsg);
  const isSaved = useSelector((state)=>state?.targetApi?.isSaved);
  const targetBuyPlanApiData = useSelector((state)=>state?.targetApi?.targetBuyPlanData);
  // to run the useEffect even if object looks like same as object comparision doesn't detect differerces
  const fetchCount = useSelector((state)=>state?.targetApi?.fetchCount);

  // to fetch the playbook edit reason
  useEffect(()=>{
    if (haveAccessToPage) {
      dispatch(fetchTargetPlayBookReason());
    }
  }, [haveAccessToPage]);

  // check for access type functionalites for this perticular user to check edit or read access
  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      const functionalityAccessKeys = Object.keys(functionalityAccess);
      if (functionalityAccessKeys.some((functionalityName)=>~functionalityName.indexOf('Targets'))) {
        setHaveAccessToPage(true);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  // check for change in playbook/buyplan, ref/excep and change functionality
  useEffect(()=>{
    if (functionalityAccess) {
      if (isPlayBook===true && isReference ===true && functionalityAccess['Targets:PlayBook_Reference'] && functionalityAccess['Targets:PlayBook_Reference']===EDIT) {
        setIsEditAllowed(true);
      } else if (isPlayBook===true && isReference ===false && functionalityAccess['Targets:PlayBook_Exception'] && functionalityAccess['Targets:PlayBook_Exception']===EDIT) {
        setIsEditAllowed(true);
      } else if (isPlayBook===false && isReference ===false && functionalityAccess['Targets:BuyPlan_Exception'] && functionalityAccess['Targets:BuyPlan_Exception']===EDIT) {
        setIsEditAllowed(true);
      } else if (isPlayBook===false && isReference ===true && functionalityAccess['Targets:BuyPlan_Reference'] && functionalityAccess['Targets:BuyPlan_Reference']===EDIT) {
        setIsEditAllowed(true);
      } else {
        setIsEditMode(false);
        setIsEditAllowed(false);
      }
    }
  }, [isPlayBook, isReference, functionalityAccess]);

  useEffect(()=>{
    if (isPlayBook && targetApiData && targetApiData.length>0) { // && isLoading===true
      setIsLoading(false);
      processTargetData(targetApiData);
    }
  }, [targetApiData, fetchCount]);

  useEffect(()=>{
    if (!isPlayBook && targetBuyPlanApiData &&
      targetBuyPlanApiData.length>0) { // && isLoading===true
      setIsLoading(false);
      processTargetData(targetBuyPlanApiData);
    }
  }, [targetBuyPlanApiData, fetchCount]);

  useEffect(()=>{
    if (isSaved===true) {
      setIsSuccessPopupVisible(true);
      setIsPlayBookUpdateReasonmodalVisible(false);
      const {PlayBookId, BuyPlanId}=selectedFilters;
      if (isPlayBook && PlayBookId) {
        dispatch(getTargetPlayBookData(PlayBookId));
      } else if (BuyPlanId) {
        dispatch(getTargetBuyPlanData(BuyPlanId));
      }
      setIsEditMode(false);
    }
  }, [isSaved]);

  useEffect(()=>{
    if (error===true && isLoading===true) {
      setIsLoading(false);
    }
  }, [error, isLoading]);


  useEffect(()=>{
    if (error ===true && errorMsg && errorMsg!=='') {
      setErrorMessage(errorMsg);
      setIsErrorPopupVisible(true);
    } else {
      setErrorMessage();
      setIsErrorPopupVisible(false);
    }
  }, [error, errorMsg]);

  useEffect(()=>{
    // clear; the data on unmount
    return ()=>{
      dispatch(setInitialState());
    };
  }, []);

  // get list of tables to show
  function getTableToShown(tableOpt) {
    const tablesToShows=[];
    if (tableOpt && tableOpt.target_coverage_mode) { //
      // check which tables need to show for overall

      if (tableOpt.optionality===true) {
        tablesToShows.push('overallMin');
        tablesToShows.push('overallMax');
      } else {
        tablesToShows.push('overallMin');
      }

      // which tables for supplier
      if (tableOpt.target_coverage_mode==='SUPPLIER' || tableOpt.target_coverage_mode==='BOTH') {
        if (tableOpt.supplier_optionality===true) {
          tablesToShows.push('supMin');
          tablesToShows.push('supMax');
        } else {
          tablesToShows.push('supMin');
        }
      }
      if (tableOpt.target_coverage_mode==='TREASURY' || tableOpt.target_coverage_mode==='BOTH') {
        // which tables for treasury
        if (tableOpt.treasury_optionality===true) {
          tablesToShows.push('trsryMin');
          tablesToShows.push('trsryMax');
        } else {
          tablesToShows.push('trsryMin');
        }
      }
      settablesToShow(tablesToShows);
    }
    return tablesToShows;
  }

  // create empty tables and later we will fill the data into it
  function createEmptyTables(tabledata, tableName, targetType) {
    const subFilters=['EXCEPTION', 'REFERENCE'];

    subFilters.map((subFilter)=>{
      const crntTableData={};
      if (subFilter==='EXCEPTION') {
        crntTableData[currYear]=[];
        crntTableData[currYear+1]=[];
        crntTableData[currYear+2]=[];
      } else {
        crntTableData['Y0']=[];
        crntTableData['Y1']=[];
        crntTableData['Y2']=[];
      }
      // enter data for every month later will update if data exist
      monthsCapital.forEach((mnth, mindex)=>{
        const planMonthData={
          ExecutionMonth: mnth,
          ExecutionMonthToShow: monthsCamelCase[monthsCapital.indexOf(mnth)],
          TargetType: targetType,
          TargetLabel: subFilter==='EXCEPTION' ? 'Exception':'Reference',
          PlayBookName: selectedFilters.playbookName,
          PlayBookId: selectedFilters.PlayBookId,
        };

        if (!isPlayBook) {
          planMonthData['BuyPlanName']=selectedFilters.buyPlanName;
          planMonthData['BuyPlanId']=selectedFilters.BuyPlanId;
        }

        if (subFilter==='EXCEPTION') {
          crntTableData[currYear][mindex]=planMonthData;
          crntTableData[currYear+1][mindex]=planMonthData;
          crntTableData[currYear+2][mindex]=planMonthData;
        } else {
          crntTableData['Y0'][mindex]=planMonthData;
          crntTableData['Y1'][mindex]=planMonthData;
          crntTableData['Y2'][mindex]=planMonthData;
        }

        tabledata[subFilter][tableName]=crntTableData;
      });
    });
  }

  function getTargetType(tableName) {
    // get targetType value according to table name
    let targetType='';
    switch (tableName) {
      case 'supMin':
        targetType= 'Supplier Min';
        break;
      case 'supMax':
        targetType= 'Supplier Max';
        break;
      case 'trsryMin':
        targetType= 'Treasury Min';
        break;
      case 'trsryMax':
        targetType= 'Treasury Max';
        break;
      case 'overallMin':
        targetType= 'Overall Min';
        break;
      case 'overallMax':
        targetType= 'Overall Max';
        break;
      default:
        targetType='';
        break;
    }
    return targetType;
  }

  function getExceptionTargetYear(targetYear) {
    let exceptionTargetYear=currYear;
    switch (targetYear) {
      case 'Y1':
        exceptionTargetYear=currYear+1;
        break;
      case 'Y2':
        exceptionTargetYear=currYear+2;
        break;
      default:
        break;
    }
    return exceptionTargetYear;
  }

  function addTargetMonthForExceptionColorCode(executionMonthData, targetMonth) {
    // to show different color track all the target months for each execution month
    let monthsOfExceptionValue=[];
    if (executionMonthData['monthsOfExceptionValue']) {
      monthsOfExceptionValue=executionMonthData['monthsOfExceptionValue'];
    }
    monthsOfExceptionValue.push(targetMonth);
    // debugger;
    return monthsOfExceptionValue;
  }

  /*
  process data in below formate to show the data in tables and show empty table even if no data available
  Excution Month is plan Month
  target lable is target_type
  data={
    EXCEPTION: {
      overallMax:{
        2023:[
          {
            APR:47
            ExecutionMonth:"FEB"
            FEB:45
            JAN:120
            MAR:46
            PlayBookId:25
            PlayBookName: "Egypt Sugar (Cover Plan)"
            TargetLabel:"Exception"
            TargetType: "Overall Min"
            monthsOfExceptionValue:['JAN', 'FEB', 'MAR', 'APR']
          }
          .
          .
          .
          .
          / same for all the array of refrerence as well
        ],
        2024:[],
        2025:[],
      }
      .
      .
    },
    REFERENCE: {
      overallMax:{
        Y0:[],
        Y1:[],
        Y2:[],
      }
      .
      .
    },
  }
  */
  function processTargetData(targetData) {
    const tableData = {
      EXCEPTION: {},
      REFERENCE: {},
    };

    const tabledata=tableData;
    let tablesToShown=[];
    //
    // if (targetData.length===1) {
    //   setIsFirstTime(false);
    // } else {
    //   setIsFirstTime(false);
    // }

    targetData.forEach((target, targetDataIndex) => {
      if (targetDataIndex ===0 && target) {
        tablesToShown = getTableToShown(target);
      }
      tablesToShown.map((tableName, index) => {
        // like SupMinTarget or SupMaxTarget
        const targetValue = target[tableName + 'Target'];
        const executionMonthIndex = monthsCapital.indexOf(target.execution_month);

        const targetType=getTargetType(tableName);


        // create empty tables to show empty even if no data exist
        // for current tablename like for yearwise and exception & Reference
        if (targetDataIndex ===0) {
          createEmptyTables(tabledata, tableName, targetType);
        } else {
          const currentTableData = {...tabledata[target.target_type][tableName]};
          if (currentTableData[target.target_year]!==undefined) {
            let executionMonthData = currentTableData[target.target_year][executionMonthIndex];
            if (!isPlayBook) {
              executionMonthData={...executionMonthData,
                'BuyPlanId': target.buy_plan_id,
              };
            }


            // set value to target month
            if (targetValue!==null) {
              executionMonthData={...executionMonthData,
                [target.target_month]: targetValue,
                'PlayBookId': target.playbook_id,
              };
            } else {
              executionMonthData={...executionMonthData,
                'PlayBookId': target.playbook_id,
              };
            }


            if (target.target_type==='EXCEPTION' && targetValue!==null) {
              const monthsOfExceptionValue= addTargetMonthForExceptionColorCode(executionMonthData, target.target_month); //  ['JAN', 'FEB']
              executionMonthData={...executionMonthData,
                monthsOfExceptionValue: monthsOfExceptionValue,
              };
            }

            currentTableData[target.target_year][executionMonthIndex] = executionMonthData;
            tabledata[target.target_type][tableName] = {...currentTableData};


            // if target_type === REFERENCE then copy that data to exception as well, just make sure there is no data
            // as we are showing data of reference and exception in exception tab
            if (target.target_type==='REFERENCE') {
              const exceptionTableData = {...tabledata['EXCEPTION'][tableName]};
              const exceptionTargetYear=getExceptionTargetYear(target.target_year); // 2023 or 2024 ....

              let exceptionExecutionMonthData = exceptionTableData[exceptionTargetYear][executionMonthIndex];

              // check the data doesn't exist for this in exception
              if (exceptionExecutionMonthData && targetValue!==null &&
              !exceptionExecutionMonthData[target.target_month] ) {
                exceptionExecutionMonthData={...exceptionExecutionMonthData,
                  [target.target_month]: targetValue,
                  'PlayBookId': target.playbook_id,
                };
                exceptionTableData[exceptionTargetYear][executionMonthIndex]= exceptionExecutionMonthData;
                tabledata['EXCEPTION'][tableName] = {...exceptionTableData};
              }
            }
          }
        }
      });
    });

    tabledata['REFERENCE'] = minMaxComparisonToShow(tabledata['REFERENCE'], tablesToShown);
    tabledata['EXCEPTION'] = minMaxComparisonToShow(tabledata['EXCEPTION'], tablesToShown);
    setData(tabledata);
  }


  const callTargetPlayBookData = (selectedPlayBook, playbookName, buyPlanName, setFilter=true)=>{
    dispatch(getTargetPlayBookData(selectedPlayBook));
    setSelectedFilter({
      playbookName: playbookName,
      buyPlanName: buyPlanName,
      PlayBookId: selectedPlayBook,
    });
    setData({});
    setIsLoading(true);
  };

  const callTargetBuyPlanData = (selectedBuyPlan, playbookName, buyPlanName, setFilter=true)=>{
    dispatch(getTargetBuyPlanData(selectedBuyPlan));
    setSelectedFilter({
      playbookName: playbookName,
      buyPlanName: buyPlanName,
      BuyPlanId: selectedBuyPlan,
    });
    setData({});
    setIsLoading(true);
  };

  const copyRow = (row) => {
    let textToBeCopied='';
    monthsCapital.forEach((month, index)=>{
      const ctab = '\t';
      const prefix=index===0 ? '':ctab;
      textToBeCopied+= prefix + row[month];
    });
    if (window.navigator && window.navigator.clipboard) {
      window.navigator.clipboard.writeText(textToBeCopied);
    }
  };

  const togglePlayBook = (toggle) =>{
    setData({});
    setIsPlayBook(toggle);
  };

  // update the table data on some actions like copy, change etc ...
  const updateTableData = (exceptionOrReference, tableName, mainFilterPeriod, newData)=>{
    setData((prevData)=>({
      ...prevData,
      [exceptionOrReference]: {
        ...prevData[exceptionOrReference],
        [tableName]: {
          ...prevData[exceptionOrReference][tableName],
          [mainFilterPeriod]: newData,
        },
      },
    }));
  };

  const removeUnwantedKeysBeforeSave = (postingData, type)=>{
    const yearsArray=[];
    if (type==='EXCEPTION') {
      yearsArray.push(currYear);
      yearsArray.push(currYear+1);
      yearsArray.push(currYear+2);
    } else {
      yearsArray.push('Y0');
      yearsArray.push('Y1');
      yearsArray.push('Y2');
    }
    Object.keys(postingData).forEach((tableName)=>{
      Object.keys(postingData[tableName]).forEach((currentYear)=>{
        for (let month = 0; month < 12; month++) {
          delete postingData[tableName][currentYear][month]['ExecutionMonthToShow'];
          if (postingData[tableName][currentYear][month]['monthsOfExceptionValue']) {
            delete postingData[tableName][currentYear][month]['monthsOfExceptionValue'];
          }
          if (postingData[tableName][currentYear][month]['monthsOfUnEqualMinMaxValue']) {
            delete postingData[tableName][currentYear][month]['monthsOfUnEqualMinMaxValue'];
          }
        }
      });
    });
    return postingData;
  };

  const minMaxComparisonToShow = (dataToProccess, tablesToShown)=>{
    Object.keys(dataToProccess).forEach((tableName)=>{
      let minTableName=null;
      if (tableName==='overallMax' && tablesToShown.includes('overallMin')) {
        minTableName='overallMin';
      }
      if (tableName==='supMax' && tablesToShown.includes('supMin')) {
        minTableName='supMin';
      }
      if (tableName==='trsryMax' && tablesToShown.includes('trsryMin')) {
        minTableName='trsryMin';
      }
      if (minTableName) {
        Object.keys(dataToProccess[tableName]).forEach((currentYear)=>{
          for (let month = 0; month < 12; month++) {
            const monthsOfUnEqualMinMaxValue =[];
            const monthsOfExceptionValue = dataToProccess[tableName][currentYear][month]['monthsOfExceptionValue'] || [];
            monthsCapital.forEach((monthName)=>{
              const currentMonthMaxTableData= dataToProccess[tableName][currentYear][month];
              const currentMonthMinTableData= dataToProccess[minTableName][currentYear][month];

              if (currentMonthMaxTableData &&
                (currentMonthMaxTableData[monthName] || currentMonthMaxTableData[monthName]===0) ) {
                if ((currentMonthMinTableData &&
                    (currentMonthMinTableData[monthName] || currentMonthMinTableData[monthName] ===0) &&
                    currentMonthMinTableData[monthName] === currentMonthMaxTableData[monthName]
                )) {
                  // means both values are equal so do nothing
                } else {
                  // to make sure this month is not available in exception month value,
                  // as exception will get more priority, so we will avoide to add it and override the exception
                  if (!monthsOfExceptionValue.includes(monthName)) {
                    monthsOfUnEqualMinMaxValue.push(monthName);
                  }
                }
              }
            });
            dataToProccess[tableName][currentYear][month]['monthsOfUnEqualMinMaxValue']=monthsOfUnEqualMinMaxValue;
          }
        });
      }
    });
    return dataToProccess;
  };

  const handleSavePlayBookTargetAfterReasonModal = (reason, reasonLabel)=>{
    if (reason) {
      const dataToSave=getDataToSave();
      dataToSave['playbook_id']=selectedFilters.PlayBookId;
      dataToSave['playbook_name']=selectedFilters.playbookName;
      dataToSave['target_update_type']={
        'TargetLabel': isReference ? 'Reference' : 'Exception',
        'update_type': reasonLabel,
        'update_type_id': reason,
      };
      dispatch(savePlaybooksHandler(dataToSave));
    }
  };

  const saveOrShowModalForPlabookEditReason = ()=>{
    // for playbook we need to add reason

    // if (isFirstTime) {
    //   const dataToSave=getDataToSave();
    //   dataToSave['playbook_id']=selectedFilters.PlayBookId;
    //   dispatch(savePlaybooksHandler(dataToSave));
    // } else {
    setIsPlayBookUpdateReasonmodalVisible(true);
    // }
  };

  const getDataToSave =()=>{
    const dataToSave = {};
    if (isReference) {
      let referenceData= JSON.parse(JSON.stringify(data['REFERENCE']));
      // now remove the unwanted data from this object
      referenceData= removeUnwantedKeysBeforeSave(referenceData, 'REFERENCE');
      dataToSave['reference']=referenceData;
      dataToSave['exception']={};
    } else {
      let exceptionData= JSON.parse(JSON.stringify(data['EXCEPTION']));
      // now remove the unwanted data from this object
      exceptionData= removeUnwantedKeysBeforeSave(exceptionData, 'EXCEPTION');
      dataToSave['exception']=exceptionData;
      dataToSave['reference']={};
    }
    return dataToSave;
  };

  // click event for either save the data or just change the mode to edit mode
  const editOrSave=(reason=null)=>{
    if (isEditMode) {
      // handle to save the data of all 6 tables with respect to all 3 years

      setErrorMessage(null);
      if (isPlayBook) {
        saveOrShowModalForPlabookEditReason();
      } else {
        const dataToSave=getDataToSave();
        dataToSave['buyplan_id']=selectedFilters.BuyPlanId;
        dispatch(saveBuyPlansHandler(dataToSave));
      }
    } else {
      // enable edit
      setIsEditMode(true);
    }
  };

  const handleClearFilter = ()=>{
    setData({});
    dispatch(setInitialState());
  };

  const closeSuccessModal = ()=>{
    setIsSuccessPopupVisible(false);
    setIsPlayBookUpdateReasonmodalVisible(false);
  };
  const cancelEdit = () => {
    setIsEditMode(false);
    isPlayBook ? processTargetData(targetApiData) : processTargetData(targetBuyPlanApiData);
  };

  const exceptionOrReference = isReference ? 'REFERENCE' : 'EXCEPTION';
  const mainFilterPeriod =isReference ? filterPeriod : filterPeriodYear;
  // const isFisrtYear = (isReference && filterPeriod === 'Y0') || (!isReference && filterPeriodYear===currYear);

  const handlePlaybookTargetToggle = ()=>{
    setToggle(true);
    togglePlayBook(true);
  };

  const handleBuyplanTargetToggle = ()=>{
    setToggle(false);
    togglePlayBook(false);
  };

  return (
    <Box>
      <LogsTable
        setPopup={setPopup}
        popup={popup}
        name={isPlayBook? `${selectedFilters.playbookName} Playbook` : `${selectedFilters.buyPlanName} Buy Plan`}
        reason = {isPlayBook? true: false}
      />
      <Box sx={{m: 2.5}}>
        <ThemeProvider theme={MainTheme}>
          {haveAccessToPage &&
          <Styles.StyledTargetBody>
            <TargetFilter
              isPlayBook={isPlayBook}
              onBuyplanToggle={handleBuyplanTargetToggle}
              setIsLoading={()=>setIsLoading}
              handleClearFilter={()=>handleClearFilter()}
              // selectedPlayBook={selectedPlayBook}
              // setSelectedPlayBook={setSelectedPlayBook}
              callTargetPlayBookData={(id, playbookName, buyPlanName)=>callTargetPlayBookData(id, playbookName, buyPlanName)}
              callTargetBuyPlanData={(id, playbookName, buyPlanName)=>callTargetBuyPlanData(id, playbookName, buyPlanName)}
              // fetchTargetTrigger={fetchTargetTrigger}
            />
            <Styles.StyledSubFilterContainer>
              <Styles.StyledSubFilterContainerLeft>
                <Styles.StyledPlayBookButton
                  isplaybook={isPlayBook.toString()}
                  variant={isPlayBook ? 'contained' : 'outlined'}
                  onClick={handlePlaybookTargetToggle}
                >
                  PlayBook Target
                </Styles.StyledPlayBookButton>
                <Styles.StyledBuyPlanButton
                  isplaybook={isPlayBook.toString()}
                  variant={isPlayBook ? 'outlined' : 'contained'}
                  onClick={handleBuyplanTargetToggle}
                >
                  Buy Plan Target
                </Styles.StyledBuyPlanButton>
              </Styles.StyledSubFilterContainerLeft>
              <Styles.StyledSubFilterContainerRight>
                {isEditMode===true ?
                <Styles.StyledEditOrSaveButton onClick={cancelEdit}>
                  Cancel
                </Styles.StyledEditOrSaveButton> : ''
                }
                {isEditAllowed && data[exceptionOrReference] && (
                  <Styles.StyledEditOrSaveButton style={{pointerEvents: 'auto', cursor: editDisable && !toggle ? 'not-allowed' : 'pointer', opacity: editDisable && !toggle ? 0.5:1}} onClick={()=>editDisable && !toggle?'':editOrSave()}>
                    {isEditMode ? 'Save ' : 'Edit '} Target
                  </Styles.StyledEditOrSaveButton>
                )}
                <Styles.StyledBoxOnPeriodButtons>
                  <Styles.StyledFilterPeriodButton
                    isselected={
                      isReference ?
                      (filterPeriod === 'Y0')?.toString() : (filterPeriodYear===currYear)?.toString()
                    }
                    onClick={() => {
                      isReference ?
                      setfilterPeriod('Y0') : setfilterPeriodYear(currYear);
                    }}
                  >
                    {isReference ? 'Y0' : currYear}
                  </Styles.StyledFilterPeriodButton>
                  <Styles.StyledFilterPeriodButton
                    isselected={
                      isReference ?
                      (filterPeriod === 'Y1')?.toString() : (filterPeriodYear===currYear + 1)?.toString()
                    }
                    onClick={() => {
                      isReference ?
                      setfilterPeriod('Y1')?.toString() :setfilterPeriodYear(currYear + 1)?.toString();
                    }}
                  >
                    {isReference ? 'Y1' : currYear + 1}
                  </Styles.StyledFilterPeriodButton>
                  <Styles.StyledFilterPeriodButton
                    isselected={isReference ?
                      (filterPeriod === 'Y2')?.toString() : (filterPeriodYear===currYear + 2)?.toString()
                    }
                    onClick={() => {
                      isReference ?
                      setfilterPeriod('Y2') : setfilterPeriodYear(currYear + 2);
                    }}
                  >
                    {isReference ? 'Y2' : currYear + 2}
                  </Styles.StyledFilterPeriodButton>
                </Styles.StyledBoxOnPeriodButtons>
              </Styles.StyledSubFilterContainerRight>
            </Styles.StyledSubFilterContainer>
            <Styles.StyledToogleBox>
              <Styles.StyledToggleFilter
                variant="span"
                gutterBottom
                onClick={() => setIsReference(true)}
                // isreference={isReference.toString()}
                sx={{
                  ...(isReference && {borderBottom: '2px solid #E8963D'}),
                }}
              >
                {isPlayBook ? 'PlayBook' : 'Buy Plan'} Reference Target
              </Styles.StyledToggleFilter>
              <Styles.StyledToggleFilter
                variant="span"
                gutterBottom
                onClick={() => setIsReference(false)}
                // isreference={isReference.toString()}
                sx={{
                  marginLeft: '1rem',
                  ...(!isReference && {borderBottom: '2px solid #E8963D'}),
                }}
              >
                {isPlayBook ? 'PlayBook' : 'Buy Plan'} Exception Target
              </Styles.StyledToggleFilter>
            </Styles.StyledToogleBox>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              {
                data[exceptionOrReference] && <Styles.StyledDownloadButton onClick={onLogClick}>
                Logs
                </Styles.StyledDownloadButton>
              }
              {
                data[exceptionOrReference] && <Styles.StyledDownloadButton onClick={onDownload}>
                Download
                </Styles.StyledDownloadButton>
              }
            </Box>

            {
              data[exceptionOrReference] ?

              tablesToShow.map((tableName) => {
                if (data[exceptionOrReference][tableName]) {
                  return (
                    <Styles.StyledTableBox key={tableName+isEditMode.toString()}>
                      <DynamicDataSheetGrid
                        key={tableName}
                        className="targetTable"
                        value={
                          data[exceptionOrReference][tableName][mainFilterPeriod]
                        }
                        onChange={(newData, operations)=>updateTableData(exceptionOrReference, tableName, mainFilterPeriod, newData, operations)}
                        columns={columns}
                        rowClassName={() => 'target'}
                        disableContextMenu={true}
                        gutterColumn={false}
                        lockRows = {true}
                      />
                    </Styles.StyledTableBox>
                  );
                }
              }) : <Styles.StyledErrorBox>No Target Available. Please Select The Appropriate Options</Styles.StyledErrorBox>


            }
            {isLoading ?
            <Styles.StyledCircularProgress sx={{display: 'flex'}}>
              <CircularProgress sx={{color: '#0099D8'}} />
            </Styles.StyledCircularProgress> :
            null}

            <AppDialog open={isSuccessPopupVisible} handleClose={()=>closeSuccessModal()} text='Saved Successfully!'/>
            <AppErrorDialog open={isErrorPopupVisible} handleClose={() => setIsErrorPopupVisible(false)} text={errorMessage ? errorMessage : 'Error occured when saving'}/>
            {isPlayBookUpdateReasonmodalVisible &&
              <PlayBookUpdateReasonModal
                isOpen={isPlayBookUpdateReasonmodalVisible}
                handleClose={()=>{
                  setIsPlayBookUpdateReasonmodalVisible(false);
                }}
                handleSavePlayBookTarget={(reason, reasonLabel)=>handleSavePlayBookTargetAfterReasonModal(reason, reasonLabel)}
              />
            }
          </Styles.StyledTargetBody>
          }
        </ThemeProvider>
      </Box>
    </Box>
  );
};
export default Target;
