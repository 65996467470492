/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import theme from '../../../theme';
import config from '../../../Utils/Config';
import {useSelector} from 'react-redux';
import NotificationMainTable from './NotificationMainTable';
import {Box} from '@mui/material';
import customealltheme from '../../../theme';

const NotificationMain = () => {
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const [accessControl, setAccessControl]=useState('');
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);
  useEffect(()=>{
    if (functionalityAccess && Object.keys(functionalityAccess).length>0) {
      if (functionalityAccess['Configuration:Units_Master']) {
        setAccessControl(functionalityAccess['Configuration:Units_Master']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);
  return (
    <Box sx={theme.palette.commonWhiteBg}>
      {(accessControl === Edit || accessControl === Read) && <Box sx={theme.palette.whitebgbox}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
            padding: '5px 18px',
          }}
          style={customealltheme.palette.filterheading}
        >
        Notification Settings
        </Box>
        <Box sx={{}}>
          <NotificationMainTable accessControl={accessControl}/>
        </Box>
        {/* } */}

      </Box>}
    </Box>
  );
};

export default NotificationMain;
