import styled from '@emotion/styled';
import {
  TableCell,
  TableRow,
} from '@mui/material';
import React from 'react';
import {tableCellClasses} from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const LogsSingleRow = ({row, reason}) => {
  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {row['execution_month'][0]?.toUpperCase()+row['execution_month']?.slice(1).toLowerCase()}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['target_type'][0]?.toUpperCase()+row['target_type']?.slice(1).toLowerCase()}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['target_month'][0]?.toUpperCase()+row['target_month']?.slice(1).toLowerCase()}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['target_year']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['column_changed']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['old_value']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['new_value']}
      </StyledTableCell>
      {reason && <StyledTableCell component="th" scope="row">
        {row['update_type']}
      </StyledTableCell>}
      <StyledTableCell component="th" scope="row">
        {row['changed_by']}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {row['changed_on']}
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default LogsSingleRow;

