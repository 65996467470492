/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, {useState} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Box, Checkbox, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {setCommodityFilter, setSortType} from '../../../../redux/features/configurations';
import {useDispatch} from 'react-redux';
import customealltheme from '../../../../theme';


const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
};

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

function SectorDisplay({row, setFiltersResetClicked, setSubmitClicked}) {
  // eslint-disable-next-line no-unused-vars
  const [category, setcategory] = useState([]);
  const [commodity, setcommodity] = useState([]);

  const dispatch = useDispatch();


  const handleSubmit = () => {
    dispatch(setCommodityFilter({category: category,
      commodity: commodity,
    }));
    setSubmitClicked((prevValue) => !prevValue);
  };


  const handleChangeCategories = (event) => {
    setcommodity([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'category_all') {
      const str = ['category_all'];
      row.map((r) => (
        str.push(r?.master_sector_name)
      )),

      setcategory(str);
    } else if (category.includes('category_all') && !value.includes('category_all')) {
      setcategory([]);
    } else {
      if (category.includes('category_all')) setcategory([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('category_all')) tepVal.splice(tepVal.indexOf('category_all'), 1);
      setcategory(tepVal);
    }
  };


  const handleChangeUOM = (event) => {
    // setcategory([]);
    const {
      target: {value},
    } = event;
    if (value[value.length - 1] === 'commodity_all') {
      const str = ['commodity_all'];
      row.map((r) => (
        str.push(r?.sector_name)
      )),
      setcommodity(str);
    } else if (commodity.includes('commodity_all') && !value.includes('commodity_all')) {
      setcommodity([]);
    } else {
      if (commodity.includes('commodity_all')) setcommodity([]);
      const tepVal = Array.from(value);
      if (tepVal.includes('commodity_all')) tepVal.splice(tepVal.indexOf('commodity_all'), 1);
      setcommodity(tepVal);
    }
  };

  const getSectorList = (modifiedList) => {
    const sortedModifiedList = row.sort((a, b) => a.sector_name.localeCompare(b.sector_name));
    if (category.length > 0) {
      const sectorList = [];
      sortedModifiedList.forEach( (listItem) => {
        if (category.includes(listItem.master_sector_name)) {
          sectorList.push(listItem);
        }
      });
      return sectorList;
    } else {
      return sortedModifiedList;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{width: '100%', padding: '18px'}}>
        <Box
          component="span"
          sx={{
            font: 'normal normal 700 15px/21px \'Segoe UI\' ',
            color: '#0F5DAA',
          }}
          style={customealltheme.palette.filterheading}
        >
          Sector
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: '35px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
             Master Sector
            </Box>
            <Box
              sx={customealltheme.palette.filterDropdown}
            >
              <FormControl fullWidth>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    sx = {theme.selectBox}
                    id="demo-multiple-checkbox"
                    multiple
                    value={category}
                    onChange={handleChangeCategories}
                    input={<CustomInput label="Tag" />}
                    displayEmpty
                    renderValue={( selected, displayEmpty) => displayEmpty = category.length ? selected.join(', ') :'Select All'}
                    className="newclass"
                  >
                    <MenuItem sx={theme.selectMenuBox} key='category_all' value='category_all' className='this-is-new-class'>
                      <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={category?.indexOf('category_all') > -1}/>
                      <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                    </MenuItem>
                    {(row.length !== 0) &&

                      _.uniqBy(row, 'master_sector_name').sort((a, b) => a.master_sector_name.localeCompare(b.master_sector_name))?.map((r) => (
                        <MenuItem sx={theme.selectMenuBox}
                          key={r?.id} value={r?.master_sector_name} className="this-is-new-class">
                          <Checkbox
                            sx={{
                              '.MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                            }}
                            checked={category?.indexOf(r?.master_sector_name) > -1}
                          />
                          {r?.master_sector_name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              color: 'rgb(96, 96, 96)',
              marginBottom: '5px',
              marginRight: '10px',
            }}
          >
            <Box sx={dropdownSpan} component="span">
              Sector
            </Box>
            <Box
              sx={customealltheme.palette.filterDropdown}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  sx = {theme.selectBox}
                  id="demo-multiple-checkbox"
                  multiple
                  value={commodity}
                  onChange={handleChangeUOM}
                  input={<CustomInput label="Tag" />}
                  displayEmpty
                  renderValue={( selected, displayEmpty) => displayEmpty = commodity.length ? selected.join(', ') :'Select All'}
                  className="newclass"
                >
                  <MenuItem sx={theme.selectMenuBox} key='commodity_all' value='commodity_all' className='this-is-new-class'>
                    <Checkbox sx={{'.MuiSvgIcon-root': {fontSize: '14px'}}} checked={commodity?.indexOf('commodity_all') > -1}/>
                    <ListItemText sx={{'.MuiTypography-root': {fontSize: '14px'}}} primary="Select All" className="myClass"/>
                  </MenuItem>
                  {(row.length !== 0) && getSectorList(row)?.map((rc, index) => (
                    <MenuItem sx={theme.selectMenuBox}
                      key={index} value={rc?.sector_name} className="this-is-new-class">
                      <Checkbox
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '14px',
                          },
                        }}
                        checked={commodity?.indexOf(rc?.sector_name) > -1}
                      />
                      {rc?.sector_name}
                    </MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>


          <Box sx={{color: 'rgb(96, 96, 96)', marginBottom: '5px'}}>
            <Box sx={dropdownSpan} component="span"></Box>
            <Box sx={{minWidth: 130, marginTop: '28px'}}>
              <Button
                onClick={handleSubmit}
                sx={{
                  'backgroundColor': '#4297d3',
                  'color': '#ffffff',
                  'textTransform': 'none',
                  'minWidth': '0',
                  'padding': '8px 35px',
                  '&:hover': {
                    backgroundColor: '#4297d3',
                  },
                }}
                style={customealltheme.palette.filterSubmit}
              >
                  Submit
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: {
              display: 'inline-block',
              marginRight: '2px',
              font: 'normal normal 600 13px/15px \'Segoe UI\'',
              color: '#3174b6',
              cursor: 'pointer',
            },
            marginTop: '30px',
          }}
          style={customealltheme.palette.resetbutton}
        >
          <p
            onClick={() => {
              dispatch(setCommodityFilter([]));
              setcategory([]);
              setcommodity([]);
              dispatch(setSortType(''));

              setFiltersResetClicked((prevValue) => !prevValue);
            }}
          >
            Reset Filters
          </p>
        </Box>
      </Box>
      <Box
        sx={{border: '1px solid #f5f5f5', width: '97%', margin: 'auto'}}
      ></Box>
    </ThemeProvider>
  );
}

export default SectorDisplay;
