/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import {Box, Tooltip} from '@mui/material';
import Button from '@mui/material/Button';
import {BiEdit} from 'react-icons/bi';
import {FiEye} from 'react-icons/fi';
import {changeButtonStatusValue, changeBuyplanStatus, changePageStatus} from '../../redux/features/buttonStatus';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {setForeCastSaveButton, setForecastUpdatesaveButton, setHistoricalsaveButton, setNonPlayBookCoverageButton, setSortFilter, setSortType} from '../../redux/features/configurations';
import {buyPlanData} from '../../redux/BuyPlan';
import {setPreviousMonthWillCoverEditButton} from '../../redux/EditStatus';
import EditNonPlayBook from './EditNonPlayBookPopup';
import PopupStepper from './PopupStepper';
// import {NonPlayBookMailDialog} from './NonPlayBookMailDialog';

const TABLE_HEADS = ['Non Playbook Name', 'Sector', 'Region/Country', 'Category', 'Commodity', 'Sub-Commodity', 'Plan Month', 'Status', 'Action'];
const values = ['non_playbook_name', 'sector', 'country', 'category', 'commodity', 'sub_commodity', 'executionMonthYear', 'status', ''];

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const sty = {
  color: '',
  bgcolor: '',
};

const changeButtonColor = (value) => {
  switch (value) {
    case 'APPROVED':
      sty.color = '#399f0a';
      sty.bgcolor = '#c0ecac';
      break;
    case 'NEW':
      sty.color = '#00B1FF';
      sty.bgcolor = '#0093FF21';
      break;
    case 'NEW_COV_PEND':
      sty.color = '#00B1FF';
      sty.bgcolor = '#0093FF21';
      break;
    case 'SUBMITTED':
      sty.color = '#E8963D';
      sty.bgcolor = '#E8963D4E';
      break;
    case 'DRAFT':
      sty.color = '#9042D3';
      sty.bgcolor = '#D342D321';
      break;
    case 'REJECTED':
      sty.color = '#D54E4E';
      sty.bgcolor = '#F3E1E1';
      break;
    case 'CLOSED':
      sty.color = '#0F5DAA';
      sty.bgcolor = '#9fbedd';
      break;
    case 'NOT_STARTED':
      sty.color = '#00B1FF';
      sty.bgcolor = '#0093FF21';
      break;
    default:
      sty.color = '#0B5394';
      sty.bgcolor = '#DFEDFD';
      break;
  }
};

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8f8f8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const actionButtonStyle = {
  'cursor': 'pointer',
  'display': 'flex',
  'alignItems': 'center',
  'padding': '3px 10px',
  'bgcolor': 'white',
  'border': '0.5px solid #cecece',
  'borderRadius': '4px',
  'minWidth': '4rem',
  'textTransform': 'capitalize',
  'font': 'normal normal normal 12px/20px \'Segoe UI\'',
  'color': '#6D7888',
  '&:hover': {
    'border': '0.5px solid #cec8c8',
  },
};

const actionButtonIconStyle = {
  fontSize: '13px',
  color: '#0071DC',
  marginRight: '5px',
};

export const RowContext = React.createContext();

function ActiveNonPlayBook({data, sortValues, setPage, page, sortMap, setSortMap, sortStatusMapping}) {
  const dispatch = useDispatch();
  const rows = data;
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedWfId, setSelectedWfId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isPopupCovPendError, setIsPopupCovPendError] = useState(false);
  const [covPendErrRow, setCovPenErrRow] = useState({});
  const [isStatusPopupVisible, setIsStatusPopupVisible] = useState(false);
  const [accessControl, setAccessControl]=useState('');
  const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const changeToMonth = (month) => {
    return monthArray[month -1];
  };
  const functionalityAccess=useSelector((state)=>state?.roleBasedAccess?.functionalityAccess);

  const [currentRole, setCurrentRole] = useState(null);
  const currentRoleId=useSelector((state)=>state?.roleBasedAccess?.currentRoleId);
  const availableRoles=useSelector((state)=>state?.roleBasedAccess?.availableRoles);

  useEffect(()=>{
    if (currentRoleId) {
      availableRoles.map((role)=>{
        if (currentRoleId===role.role_id) {
          setCurrentRole(role.description);
        }
      });
    }
  }, [currentRoleId]);
  useEffect(()=>{
    if (functionalityAccess && functionalityAccess['Non_PlayBook:Active_List']) {
      setAccessControl(functionalityAccess['Non_PlayBook:Active_List']);
    }
  }, [functionalityAccess]);

  const sorttype = useSelector((state)=>state.configurations.selectedSortType);

  useEffect(()=>{
    if (rows && selectedRow) {
      for (let i=0; i<rows.length; i++) {
        if (selectedRow?.workflow_id===rows[i]?.workflow_id) {
          setSelectedRow(rows[i]);
          break;
        }
      }
    }
  }, [rows]);

  useEffect(()=>{
    setSortMap(sortStatusMapping);
  }, []);

  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const changeStatusOnButtonClick = (statusValue) => {
    if (statusValue === true) {
      dispatch(changeButtonStatusValue('edit'));
    } else {
      dispatch(changeButtonStatusValue('view'));
    }
  };

  const getCurrentMonthWDCount = () => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    let worked = 0;
    for (let i = 1; i <= today.getDate(); i++) {
      const temp = new Date(year, month, i);
      const day = temp.getDay();
      if (day > 0 && day < 6) {
        ++worked;
      }
    }
    return worked;
  };

  function getTotalNoOfRows(data) {
    let curCount = 0;
    const sepInd = [];
    data.forEach((eachItem, index) => {
      if (eachItem.showValue) {
        curCount += 1;
        sepInd.push(index);
      }
    });
    return {curCount, sepInd};
  }
  function getDataForTable(data, page, rowsPerPage) {
    const {sepInd} = getTotalNoOfRows(data);
    const startIndex = sepInd[page * rowsPerPage];
    const endIndex = sepInd[page * rowsPerPage + rowsPerPage];
    return data?.slice(startIndex, endIndex);
    return data;
  }

  const notStartedText = () =>{
    let popText = '';
    // eslint-disable-next-line no-unused-vars
    const currentWorkingDay = getCurrentMonthWDCount();
    popText = 'Please close last month’s Non Playbook to edit current month Non Playbook. If you are unable to close last month’s Non Playbook, click on Send Email button';

    // if (currentWorkingDay <= 5) {
    //   if (accessControl=='Edit') {
    //     popText = 'Please close last month’s Non-Playbook to edit current month Non-Playbook. If you are unable to close last month’s Non-Playbook, click on Send Email button';
    //   } else {
    //     popText = 'Non-Playbook Creator is yet to close last month Non-Playbook. Last month Non-Playbook should be closed to view current month Non-Playbook';
    //   }
    // } else {
    //   if (accessControl=='Edit') {
    //     popText = 'The last month’s Non-Playbook is frozen, please share the Coverage information and raise a request to obtain approvals.';
    //   } else {
    //     popText = 'The last month’s Non-Playbook is frozen, please ask the Non-Playbook Creator to share the Coverage information and raise a request to obtain approvals.';
    //   }
    // }
    return popText;
  };
  return (<>
    {/* <NonPlayBookMailDialog selectedRow={selectedRow} accessControl={accessControl} open={isPopupCovPendError} covPendErrRow={covPendErrRow} handleClose={() => setIsPopupCovPendError(false)}
      text= {notStartedText()}
    /> */}
    <Box sx={{width: '100%', padding: '0px 18px'}}>
      <Box sx={{marginTop: '20px'}}>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 700}} aria-label="customized table">
            <TableHead>
              <TableRow>
                {TABLE_HEADS.map((h, index)=>(
                  <StyledTableCell align="left" key={index}>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                      <Box>{h}</Box>
                      <Box style={{cursor: 'pointer', margin: '3px 0px 0px 5px'}}>
                        {h === 'Status' && (
                          <Tooltip style={{background: 'white'}} arrow title="Click on the status to see a historical timeline of activities" disableInteractive>
                            <span className='plusIcon'>i</span>
                          </Tooltip>
                        )}
                      </Box>
                      {(sortMap[index].sort_value !== 'status' && sortMap[index].sort_value !== 'non_playbook_name') && sortValues[index] === true && sortMap[index].sort_active === true &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '1', fontSize: '10px', display: 'flex'}}
                          onClick={(e)=> {
                            dispatch(setSortFilter(values[index]));
                            const newSortObj = [];
                            sortMap.forEach((sortObj, id) => {
                              if (id === index) {
                                newSortObj.push({...sortObj, 'sort_active': true});
                              } else {
                                newSortObj.push({...sortObj, 'sort_active': false});
                              }
                            });
                            setSortMap(newSortObj);
                            if (sorttype === 'asc' ) {
                              dispatch(setSortType('desc'));
                              const newSortObj = [];
                              sortMap.forEach((sortObj, id) => {
                                if (id === index) {
                                  newSortObj.push({...sortObj, 'sort_order': 'desc'});
                                } else {
                                  newSortObj.push({...sortObj});
                                }
                              });
                              setSortMap(newSortObj);
                            } if (sorttype === 'desc' || sorttype === '') {
                              dispatch(setSortType('asc'));
                              const newSortObj = [];
                              sortMap.forEach((sortObj, id) => {
                                if (id === index) {
                                  newSortObj.push({...sortObj, 'sort_order': 'asc'});
                                } else {
                                  newSortObj.push({...sortObj});
                                }
                              });
                              setSortMap(newSortObj);
                            }
                          }}
                        > <SwapVertIcon sx={{fontSize: '16px'}}/>
                          {sorttype}
                        </Box>}
                      {
                        (sortMap[index].sort_value !== 'status' && sortMap[index].sort_value !== 'non_playbook_name') && !(sortValues[index] === true && sortMap[index].sort_active === true) && index !== 8 &&
                        <Box style={{transform: 'translateY(5px)', cursor: 'pointer', opacity: '0', fontSize: '10px', display: 'flex'}}
                          onMouseOver={(e)=>{
                            e.target.style.opacity = '1';
                          }}
                          onMouseLeave={(e)=>{
                            e.target.style.opacity = '0';
                          }}
                          onClick={(e)=> {
                            dispatch(setSortFilter(values[index]));
                            const newSortObj = [];
                            sortMap.forEach((sortObj, id) => {
                              if (id === index) {
                                newSortObj.push({...sortObj, 'sort_active': true});
                              } else {
                                newSortObj.push({...sortObj, 'sort_active': false});
                              }
                            });
                            setSortMap(newSortObj);
                          }}
                        >
                          <SwapVertIcon sx={{fontSize: '16px'} }/>
                          {sortMap[index].sort_order}
                        </Box>
                      }
                    </Box>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getDataForTable(data, page, rowsPerPage)
                  ?.map((row, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell align="left">
                        {row.non_playbook_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.sector}</StyledTableCell>
                      <StyledTableCell align="left">{row.region}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.commodity}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.sub_commodity}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {/* {row.showValue && (changeToMonth(row.executionMonthYear[1]) (row.executionMonthYear[0]))} */}
                        {
                          (`${changeToMonth(row?.execution_month[1])}  ${row.execution_month[0]}`)
                        }
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {changeButtonColor(row.status)}
                        <Button
                          sx={{
                            'cursor': 'pointer',
                            'color': `${sty.color}`,
                            'bgcolor': `${sty.bgcolor}`,
                            'border': 'none',
                            'padding': '0px',
                            'borderRadius': '3px',
                            'width': '5.5rem',
                            'font': '12px/16px \'Segoe UI\'',
                            '&:hover': {
                              backgroundColor: `${sty.bgcolor}`,
                              border: 'none',
                            },
                            'textTransform': 'capitalize',
                          }}
                          variant="outlined"
                          onClick={()=> {
                            setSelectedWfId(row.workflow_id);
                            setSelectedRow(row);
                            setIsStatusPopupVisible(true);
                          }}
                        >
                          <b>{(row.status === 'NEW' || row.status === 'NEW_COV_PEND' ?'Not Started':row.status.toLowerCase())}</b>
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* <Link to="/forecast" style={{textDecoration: 'none'}}> */}
                        {<Button
                          sx={actionButtonStyle}
                          variant="outlined"
                          onClick={()=> {
                            dispatch(changePageStatus('active'));
                            if (row.status=== 'NEW' || row.status === 'NEW_COV_PEND' || row.status === 'DRAFT' || row.status === 'REJECTED') {
                              changeStatusOnButtonClick(true);
                            } else {
                              changeStatusOnButtonClick(false);
                            }
                            // if ( row.status === 'NEW_COV_PEND' ) {
                            //   setCovPenErrRow(row);
                            //   setIsPopupCovPendError(true);
                            //   return;
                            // }
                            // if (accessControl==='Edit' || accessControl==='Read') {
                            // console.log(row);
                            dispatch(changeBuyplanStatus(row.status));
                            if (row.status==='NEW' || row.status === 'NEW_COV_PEND' ) {
                              dispatch(setForeCastSaveButton('false'));
                              dispatch(setHistoricalsaveButton('false'));
                              dispatch(setForecastUpdatesaveButton(true));
                              dispatch(setNonPlayBookCoverageButton('false'));
                            } else {
                              dispatch(setForeCastSaveButton('true'));
                              dispatch(setHistoricalsaveButton('true'));
                              dispatch(setForecastUpdatesaveButton(false));
                              dispatch(setNonPlayBookCoverageButton('true'));
                            }
                            setSelectedRow(row);
                            setIsPopupVisible(true);
                            if (currentRole=== 'Forecast Creator') {
                              if (row.status === 'APPROVED' || row.status === 'SUBMITTED' || row.status=== 'NEW' || row.status === 'NEW_COV_PEND') {
                                changeStatusOnButtonClick(false);
                              }
                            }
                            // }
                          }}
                        >
                          {(() => {
                            if (currentRole=== 'Sector Finance') {
                              return row.status === 'APPROVED' ? (<><BiEdit style={actionButtonIconStyle} />Edit</>) : (<><FiEye style={actionButtonIconStyle} />View</>);
                            }
                            if (currentRole=== 'Forecast Creator') {
                              return row.status === 'APPROVED' || row.status === 'SUBMITTED' ? (<><FiEye style={actionButtonIconStyle} />View</>) : (<><BiEdit style={actionButtonIconStyle} />Edit</>);
                            }
                            if ( (row.status === 'NEW' || row.status === 'NEW_COV_PEND' || row.status === 'DRAFT') && accessControl==='Edit') {
                              return (<><BiEdit style={actionButtonIconStyle} />Edit</>);
                            } else {
                              return (<><FiEye style={actionButtonIconStyle} />View</>);
                            }
                          })()}
                        </Button>}
                        {/* </Link> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={getTotalNoOfRows(rows).curCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
    <PopupStepper open={isStatusPopupVisible} handleClose={() => {
      setIsStatusPopupVisible(false);
      setSelectedWfId(null);
      setSelectedRow(null);
    }} wfId={selectedWfId} selectedRow = {selectedRow}/>
    <RowContext.Provider value={selectedRow}>
      <EditNonPlayBook open={isPopupVisible} access={accessControl} setIsPopupVisible={setIsPopupVisible} handleClose={() => {
        setIsPopupVisible(false);
        dispatch(setNonPlayBookCoverageButton('true'));
        // dispatch(setForeCastSaveButton('false'));
        // dispatch(setHistoricalsaveButton('false'));
        dispatch(changeBuyplanStatus(''));
        dispatch(buyPlanData([]));
        dispatch(setPreviousMonthWillCoverEditButton(false));
      }} selectedRow = {selectedRow} />
    </RowContext.Provider>
  </>);
}

export default ActiveNonPlayBook;
