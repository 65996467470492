/* eslint-disable camelcase */
import {Box} from '@mui/material';
import {styled} from '@mui/system';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import config from '../../../Utils/Config';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import ConfigurationServices from '../../../services/ConfigurationServices';
import './PowerBi.css';
import {AppErrorDialog} from '../../common/App-Dialog/AppDialog';

const PowerBi = (props) => {
  const getEmbedToken = ConfigurationServices.getPowerBiEmbedToken;
  const reportId = process.env.REACT_APP_POWERBI_REPORT_ID.split(',');
  const [accessControl, setAccessControl] = useState('');
  const [embedToken, setEmbedToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const Edit = config.AccessLevel.Edit;
  const Read = config.AccessLevel.Read;
  const functionalityAccess = useSelector((state) => state?.roleBasedAccess?.functionalityAccess);

  const outerBoxStyle = {
    bgcolor: '#eff6fc',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '0px',
    paddingBottom: '20px',
  };

  const Container = styled(Box)(({theme}) => ({
    border: 'none',
    backgroundColor: '#f9f9f9',
    borderRadius: '5px',
    width: '97%',
    marginTop: '20px',
    bgcolor: 'white',
    height: '115vh',
    padding: '0px',
  }));

  useEffect(() => {
    let timeoutId;
    const fetchEmbedToken = async () => {
      try {
        const requestParams ={
          'datasets': [
            {
              'id': process.env.REACT_APP_POWERBI_DATASET_ID,
              'xmlaPermissions': 'ReadOnly',
            },
          ],
          'reports': [
            {
              'id': reportId[0],
            },
            {
              'id': reportId[1],
            },
            {
              'id': reportId[2],
            },
            {
              'id': reportId[3],
            },
            {
              'id': reportId[4],
            },
          ],
          ...(accessToken!=null && {'access_token': accessToken}),
        };

        const res = await getEmbedToken(requestParams);

        if (res?.data) {
          setEmbedToken(res?.data?.embed_token);
          setAccessToken(res?.data?.access_token);

          const expiresIn = res?.data?.expires_in || 3599;
          const bufferTime = 300;
          timeoutId = setTimeout(fetchEmbedToken, (expiresIn - bufferTime) * 1000);
        }
      } catch (err) {
        console.log('Error in generating embed token', err);
      }
    };

    fetchEmbedToken();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (functionalityAccess && Object.keys(functionalityAccess).length > 0) {
      if (functionalityAccess['Reports:PBIReports']) {
        setAccessControl(functionalityAccess['Reports:PBIReports']);
      } else {
        props.history.push('/home');
      }
    }
  }, [functionalityAccess]);

  return (
    <Box sx={outerBoxStyle}>
      {(accessControl === Edit || accessControl === Read) && (
        <Container>
          <PowerBIEmbed
            cssClassName={'report-style-class'}
            embedConfig={{
              type: 'report',
              id: reportId[0],
              pageName: 'GPA_PBI_Embed',
              embedUrl: process.env.REACT_APP_POWERBI_EMBED_URL,
              accessToken: embedToken,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    visible: false,
                  },
                  pageNavigation: {
                    visible: false,
                  },
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: true,
                background: 0,
              },
            }}
            eventHandlers={
              new Map([
                ['loaded', async function() {
                  console.log('coming to Load');
                }],
                ['rendered', async function() {
                  console.log('Loading...');
                }],
                ['error', function(event) {
                  console.log(event);
                  setIsErrorPopupVisible(true);
                  setErrMsg(event?.detail?.message);
                }],
              ])
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.Report = embeddedReport;
            }}
          />
        </Container>
      )}
      <AppErrorDialog
        open={isErrorPopupVisible}
        text={errMsg}
        handleClose={() => {
          setIsErrorPopupVisible(false);
          setErrMsg('');
        }}
      />
    </Box>
  );
};

export default PowerBi;


