import axiosInstance from '../apis/axiosInstance';
import {hedgingDecider} from './utils';

const ConfigurationServices = {
  getUnitMasterData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/units/getUnits`)
          .then((res) => {
            if (res) {
              const ForecastData = res?.data;
              resolve(ForecastData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  getBuyplanNegitiveWillCoverValues: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/buyplan/reasons`)
          .then((res) => {
            if (res) {
              const reasons = res?.data;
              resolve(reasons);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },
  postUnitMasterData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/units/saveUnits`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateUnitMasterData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/units/saveUnits`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getPowerBiEmbedToken: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/powerbi/embedToken`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getWorkflowDetails: (workflowId) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/details?workFlowId=${workflowId}`)
          .then((res) => {
            if (res) {
              // const CategoryData = res?.data;
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getCurrency: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/currency/getCurrency`)
          .then((res) => {
            if (res) {
              const CurrencyData = res?.data;
              resolve(CurrencyData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  postCurrencyData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/currency/saveCurrency`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateCurrencyData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/currency/saveCurrency`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getUnitConversionMasterData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/unitsconversion/getUnitsConversion`)
          .then((res) => {
            if (res) {
              const ForecastData = res?.data;
              resolve(ForecastData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  postUnitConversionMasterData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/unitsconversion/saveUnitsConversion`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateUnitConversionMasterData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/unitsconversion/saveUnitsConversion`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  //  Currency Conversion Apis
  getCurrencyConversionMasterData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/currencyconversion/getCurrencyConversion`)
          .then((res) => {
            if (res) {
              const ForecastData = res?.data;
              resolve(ForecastData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  postCurrencyConversionMasterData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/currencyconversion/saveCurrencyConversion`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateCurrencyConversionMasterData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/currencyconversion/saveCurrencyConversion`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getEditPlayBookData: (id) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/playbook/${id}`)
          .then((res) => {
            if (res) {
              const PlaybookData = res?.data;
              resolve(PlaybookData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getPlayBookData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/playbook/getPlaybooks`)
          .then((res) => {
            if (res) {
              const PlaybookData = res?.data;
              resolve(PlaybookData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getPlayBookDataForBuyPlan: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplan/getPlaybooksForBuyplan`)
          .then((res) => {
            if (res) {
              const PlaybookData = res?.data;
              resolve(PlaybookData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postPlayBookData: (data) => {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/playbook/savePlaybook`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          })
          .catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  updatePlayBookData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/playbook/savePlaybook`, data)
          .then((res) => {
            if (res) {
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getPlayBookCompMapData: () => {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/buyplanreport/complianceReportMapping`)
          .then((res) => {
            if (res) {
              const PlaybookData = res?.data;
              resolve(PlaybookData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  postPlayBookCompMapData: (data) => {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/buyplanreport/complianceReportMapping`, data)
          .then((res) => {
            if (res) {
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  deletePlayBookData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.delete(`/playbook/deletePlaybook?playbookId=${data}`)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getEditBuyPlanData: (id) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplan/${id}`)
          .then((res) => {
            if (res) {
              const BuyplanData = res?.data;
              resolve(BuyplanData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getBuyPlanData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplan/getBuyPlans`)
          .then((res) => {
            let result = res.data;

            if (Array.isArray(result)) {
              result = result?.map((r) => ({...r, hedgingMech: hedgingDecider(r?.coverageMode, r?.others)}));
            }
            if (result) {
              resolve(result);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getBuyPlanDataInForecast: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplansWithWorkflows`)
          .then((res) => {
            if (res) {
              const BuyplanData = res?.data;
              resolve(BuyplanData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  deleteBuyPlanData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.delete(`/buyplan/deleteBuyPlan?buyPlanId=${data}`)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
            resolve(err?.response?.data);
          });
    });
  },

  getBuyPlanReportData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplanreport/getBuyplanReportMapping`)
          .then((res) => {
            if (res) {
              const BuyplanData = res?.data;
              resolve(BuyplanData);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  updateBuyplanReportData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/buyplanreport/saveBuyplanReportMapping`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  postBuyPlanData: (data) => {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/buyplan/saveBuyPlan`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          })
          .catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getCategoryData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/category/getCategory`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve(CategoryData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postCategoryData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/category/saveCategory`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateCategoryData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/category/saveCategory`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getCommodityData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/commodity/getCommodity`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve({
                'commodityGroups': CategoryData,
              });
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postCommodityData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/commodity/saveCommodity`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateCommodityData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/commodity/saveCommodity`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getSubCommodityData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/subcommodity/getSubCommodity`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve({
                'commodityGroups': CategoryData,
              });
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postSubCommodityData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/subcommodity/saveSubCommodity`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateSubCommodityData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/subcommodity/saveSubCommodity`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getMasterSectorData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/mastersector/getMasterSector`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve(CategoryData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  getGuidancePriceSectorCommodityList: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/guidancePrice/allSectorCommodityList`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve(CategoryData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  postMasterSectorData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/mastersector/saveMasterSector`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateMasterSectorData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/mastersector/saveMasterSector`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getSectordata: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/sector/getSector`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve({
                'commodityGroups': CategoryData,
              });
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postSectordata: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/sector/saveSector`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateSectordata: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/sector/saveSector`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getCountrysData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/region/getRegion`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve({
                'commodityGroups': CategoryData,
              });
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getBusinessUnitdata: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/businessunit/getBusinessUnits`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve({
                'commodityGroups': CategoryData,
              });
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getQuartersdata: (id) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/quarters?sectorGroupId=${id}`)
          .then((res) => {
            if (res) {
              const quartersData = res?.data;
              resolve(
                  quartersData,
              );
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getSectorCountries: (id) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/sector/getRegionMappings/${id}`)
          .then((res) => {
            if (res) {
              const quartersData = res?.data;
              resolve(
                  quartersData,
              );
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  postBusinessUnitdata: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/businessunit/saveBusinessUnit`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateBusinessUnitdata: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/businessunit/saveBusinessUnit`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getCountryData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/region/getRegion`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postCountryData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/region/saveRegion`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  updateCountryData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/region/saveRegion`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getBuyPlanownerdata: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/playbook/getBuyPlanOwners`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve({
                'commodityGroups': CategoryData,
              });
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getBuyPlanCreatorsList: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/home/buyers`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve(CategoryData || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getBuyPlanPersonasdata: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplan/getBuyPlanPersonas`)
          .then((res) => {
            if (res) {
              const CategoryData = res?.data;
              resolve(CategoryData);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getRolesData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/users/getUserRoles`)
          .then((res) => {
            if (res) {
              const RoleAccess = res?.data;
              resolve(RoleAccess);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getUserAccessData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/users/getUserAccess`)
          .then((res) => {
            if (res) {
              const UserAccess = res?.data;
              resolve(UserAccess);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getGuidanceData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/guidancePrice/details`)
          .then((res) => {
            if (res) {
              const UserAccess = res?.data;
              resolve(UserAccess);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  updateGuidanceData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/guidancePrice/update`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postGuidanceData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/guidancePrice/create`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postRolesandAccessData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/users/saveUserAccess`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getRegionMapping: (sectorId) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/region/getSectorMappings/${sectorId}`)
          .then((res) => {
            if (res) {
              // const CategoryData = res?.data;
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  postRegionMapping: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/region/saveSectorRegionMapping`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },
  getUserEmailList: (workflowId) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplan/getAllUsersForBuyPlan?buyPlanId=${workflowId}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getNotificationSetting: (workflowId) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/notification/scenarios`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  postNotificationSetting: (scenarioId, consolidationLevel, isConsolidated, remainderFrequency) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.put(`/notification/scenario`, {consolidationLevel, consolid: isConsolidated, reminderFrequency: remainderFrequency, id: scenarioId})
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },


  getRecipientList: (scenarioId) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/notification/scenarioRecipients?scenarioId=${scenarioId}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  postRecipientList: (obj) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/notification/scenarioRecipients`, obj)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  getNonPlayBookUserEmailList: (workflowId) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/buyplan/getAllUsersForBuyPlan?buyPlanId=${workflowId}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  getUploadedDocumentsList: (workflowId, attachmentType) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/attachments?workflowId=${workflowId}&attachmentType=${attachmentType}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  uploadDocument: (payload, header, attachmentType) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/workflow/document/upload?attachmentType=${attachmentType}`, payload, header)
          .then((res) => {
            if (res) {
              const returnData = {
                status: '200',
                message: 'Document Uploaded successfully!',
              };
              resolve(returnData);
            }
          }).catch((err) => {
            const returnData = {
              status: err?.response?.status,
              message: err?.response?.data?.userMessage,
            };
            reject(returnData);
          });
    });
  },

  downloadDocument: (attachmentId, attachmentType) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/document/download?attachmentId=${attachmentId}&attachmentType=${attachmentType}`, {responseType: 'blob'})
          .then((res) => {
            if (res) {
              resolve(res.data);
            }
          }).catch((err) => {
            const returnData = {
              status: err?.response?.status,
              message: err?.response?.data?.userMessage,
            };
            reject(returnData);
          });
    });
  },

  downloadOutboundAtlasfxFile: (wfID, attachmentType) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/atlasfx/outboundFile?workflowId=${wfID}`)
          .then((res) => {
            if (res) {
              resolve(res.data);
            }
          }).catch((err) => {
            const returnData = {
              status: err?.response?.status,
              message: err?.response?.data?.userMessage,
            };
            reject(returnData);
          });
    });
  },

  addingCarryVolumeReasons: (wfID, obj) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/atlasfx/outboundFile?workflowId=${wfID}`, obj)
          .then((res) => {
            if (res) {
              resolve(res.data);
            }
          }).catch((err) => {
            const returnData = {
              status: err?.response?.status,
              message: err?.response?.data?.userMessage,
            };
            reject(returnData);
          });
    });
  },

  deleteDocument: (attachmentId, attachmentType) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.post(`/workflow/document/delete?attachmentId=${attachmentId}&attachmentType=${attachmentType}`)
          .then((res) => {
            if (res) {
              const returnData = {
                status: '200',
                message: 'Document Deleted successfully!',
              };
              resolve(returnData);
            }
          }).catch((err) => {
            const returnData = {
              status: err?.response?.status,
              message: err?.response?.data?.userMessage,
            };
            reject(returnData);
          });
    });
  },
  updateAttachmentDescription: (attachmentId, attachmentType, description) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.put(`/workflow/document/edit?attachmentId=${attachmentId}&attachmentType=${attachmentType}&description=${description}`)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          }).catch((err) => {
            const returnData = {
              status: err?.response?.status,
              message: err?.response?.data?.userMessage,
            };
            reject(returnData);
          });
    });
  },
  getForecastVariance: (workflowId) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/forecastVariance?workFlowId=${workflowId}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getPastMonthComments: (workflowId, category) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/getPreviousMonthComments?workFlowId=${workflowId}&category=${category}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getCarryVolumeReason: (workflowId, category) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/workflow/carryVolumeComments?workFlowId=${workflowId}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },


  getHistoricalCoverageSectorRegions: (buyplanID) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/home/getDropDownForBuyPlan?buyPlanId=${buyplanID}`)
          .then((res) => {
            if (res) {
              resolve(res.data || []);
            }
          }).catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  getNonPlayBookData: () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.get(`/nonPlaybook`)
          .then((res) => {
            if (res) {
              const PlaybookData = res?.data;
              resolve(PlaybookData);
            }
          })
          .catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  postNonPlayBookData: (data) => {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/nonPlaybook`, data)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          })
          .catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

  updateNonPlayBookData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.put(`/nonPlaybook`, data)
          .then((res) => {
            if (res) {
            }
          })
          .catch((err) => {
            reject(err?.response?.data);
          });
    });
  },

  deleteNonPlayBookData: (data) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      axiosInstance.delete(`nonPlaybook?id=${data}`)
          .then((res) => {
            if (res) {
              resolve(res);
            }
          })
          .catch((err) => {
            resolve(err?.response?.data);
          });
    });
  },

};

export default ConfigurationServices;
