import React from 'react';
import './SaveCoverageInput.css';
import {monthArr} from '../../../../services/utils';

function SaveCoverageInput({selectedRow, typeOfColumn, prevNonSuspendedmonth}) {
  let setColumnType = '';
  if (typeOfColumn === 'executionMonth') {
    setColumnType =`${monthArr[+prevNonSuspendedmonth.split('-')[1]-1]}-${+prevNonSuspendedmonth.split('-')[0]}`;
  } else if (typeOfColumn === 'coverageUnit') {
    setColumnType = selectedRow.measuringUnit;
  } else if (typeOfColumn === 'coveredPriceCurrency') {
    setColumnType = selectedRow.currency;
  }
  return (
    <div className='saveCoverageInput'>
      {setColumnType}
    </div>
  );
}

export default SaveCoverageInput;
