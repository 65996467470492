import styled from '@emotion/styled';
import {
  Box,
  createTheme,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextareaAutosize,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import theme from '../../../../theme';
import config from '../../../../Utils/Config';
import customealltheme from '../../../../theme';


const Edit = config.AccessLevel.Edit;
const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CommonConfSingleRow = ({
  row,
  setAddingTrigger,
  accessControl,
  addingTrigger,
  values,
  editValues,
  dateValues,
  idKey,
  singleUpdate,
  setErrMsg,
  setIsErrorPopupVisible,
  unitData,
  page,
}) => {
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line no-unused-vars

  // This editeddetails used for storing the adding details

  const [localUnitId, setlocalUnitId] = React.useState('');
  const [standardUnitId, setstandardUnitId] = React.useState('');
  const [localUnitDescription, setLocalUnitDescription] = React.useState(row['local_unit_description']);
  const [standardUnitDescription, setStandardUnitDescription] = React.useState(row['standard_unit_description']);
  const [editedDetails, setEditDetails] = useState({});
  const [isActiveValue, setIsActiveValue] = React.useState('');

  const handleIsActive = (e) => {
    setIsActiveValue(e.target.value);
  };

  const handleLocalDes = (e) => {
    setlocalUnitId(e.target.value);
    unitData.map((u) => {
      if (u.unit_id == e.target.value) {
        setEditDetails({...editedDetails, local_uom: u.unit});
      }
    });
    if (localUnitDescription === standardUnitDescription) {
      setEditDetails({...editedDetails, conversion_multiplier: 1});
    }
  };

  const handlestandardUnitId = (e) => {
    setstandardUnitId(e.target.value);
    unitData.map((u) => {
      if (u.unit_id == e.target.value) {
        setEditDetails({...editedDetails, standard_uom: u.unit});
      }
    });
    if (localUnitDescription === standardUnitDescription) {
      setEditDetails({...editedDetails, conversion_multiplier: 1});
    }
  };

  function toCamelCase(arr) {
    let string = arr[0];
    for (let i = 1; i < arr.length; i++) {
      string =
        string +
        arr[i].charAt(0).toUpperCase() +
        arr[i].slice(1, arr[i].length);
    }
    return string;
  }
  // ADDING ALL VALUES AS A KEYS AND GIVING  VALUES FOR  SINGLE ROW
  useEffect(() => {
    const keyvalues = {};
    values.map((h, index) => {
      keyvalues[h] = row[h];
    });
    setEditDetails({...keyvalues});
  }, [row, values]);

  const editTheRow = () => {
    setEditMode(true);
  };
  useEffect(()=>{
    setEditMode(false);
  }, [page]);
  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () => {
    setEditMode(false);
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);
    const keyvalues = {};
    const originalEdited = {...editedDetails};
    values.map((h, index) => {
      if (document.getElementById(h) !== null) {
        originalEdited[h] = document.getElementById(h).value;
      }

      keyvalues[toCamelCase(h.split('_'))] =
        document.getElementById(h) !== null ?
          document.getElementById(h).value :
          editedDetails[h];
    });
    setEditDetails({...originalEdited});
    idKey.map((idkey) => {
      keyvalues[`${idkey}`] = row[idkey];
      keyvalues[toCamelCase(idkey.split('_'))] = row[idkey];
      delete keyvalues[idkey];
    });


    singleUpdate({...keyvalues,
      localUnitId: localUnitId !==''? localUnitId : row['local_unit_id'],
      standardUnitId: standardUnitId !== '' ? standardUnitId : row['standard_unit_id'],
      active: isActiveValue !=='' ? isActiveValue: row.is_active,
    })
        .then((res) => {
          if (res.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res.userMessage);
            setAddingTrigger(!addingTrigger);
          }
        })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured');
          setAddingTrigger(!addingTrigger);
        });
  };

  const cancelEdit = () => {
    setEditMode(false);
    setLocalUnitDescription(row['local_unit_description']);
    setStandardUnitDescription(row['standard_unit_description']);
    setIsActiveValue('');
    setlocalUnitId('');
    setstandardUnitId('');
    setEditDetails({...row});
  };


  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {editMode === true ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={localUnitId}
              onChange={handleLocalDes}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                <span>{row['local_unit_description']}</span>
              </MenuItem>
              {unitData.length !== 0 &&
                unitData
                    // .filter((obj) => obj.unit_description !== standardUnitDescription)
                    .sort((a, b) => a.unit_description.localeCompare(b.unit_description))
                    .map((obj) => (
                      obj.is_active && <MenuItem
                        sx={customTheme.selectMenuBox}
                        key={obj?.unit_id}
                        value={obj?.unit_id}
                        onClick={()=>{
                          setLocalUnitDescription(obj.unit_description);
                        }}
                        className="this-is-new-class"
                      >
                        {obj.unit_description}
                      </MenuItem>
                    ))}
            </Select>
          </FormControl>
        ) : (
          editedDetails['local_unit_description']
        )}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        { editMode===true && row.is_editable ==='Y' ?
        <FormControl style={{width: '100px'}}>
          <Select
            labelId="demo-multiple-checkbox-label"
            sx={customTheme.selectBox}
            value={isActiveValue || row.is_active}
            onChange={handleIsActive}
            label="Select"
            input={<CustomInput label="Tag" />}
            displayEmpty
            className="newclass"
          >
            { ['true', 'false']?.map((obj, index) => (
              <MenuItem
                sx={customTheme.selectMenuBox}
                key={index}
                value={obj}
                className="this-is-new-class"
              >
                {obj === 'true' ? 'Y' : 'N'}
              </MenuItem>
            ))}
          </Select>
        </FormControl> :
              `${editedDetails.is_active ? 'Y' : 'N'}`
        }
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editedDetails.local_uom}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editMode === true ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={standardUnitId}
              onChange={handlestandardUnitId}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                <span>{row['standard_unit_description']}</span>
              </MenuItem>
              {unitData.length !== 0 &&
                  unitData
                      .sort((a, b) => a.unit_description.localeCompare(b.unit_description))
                      .map((obj) => (
                        obj.is_active && <MenuItem
                          sx={customTheme.selectMenuBox}
                          key={obj?.unit_id}
                          value={obj?.unit_id}
                          onClick={()=>{
                            setStandardUnitDescription(obj.unit_description);
                          }}
                          className="this-is-new-class"
                        >
                          {obj.unit_description}
                        </MenuItem>
                      ))}

            </Select>
          </FormControl>
        ) : (
          editedDetails['standard_unit_description']
        )}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editedDetails.standard_uom}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editMode === true ? (
          localUnitDescription === standardUnitDescription ? (
            <>{editedDetails['conversion_multiplier'] = 1}</>
          ) : (
            <TextareaAutosize
              id='conversion_multiplier'
              style={theme.palette.TextArea}
              placeholder="Type in here…"
              defaultValue={editedDetails.conversion_multiplier}
              minRows={1}
              maxRows={4}
            />
          )
        ) : (
          editedDetails['conversion_multiplier']?.toLocaleString('en-US', {maximumFractionDigits: 2})
        )}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row">
        {editedDetails.last_updated_by?editedDetails.last_updated_by:''}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row">
        {editedDetails.last_updated_on?editedDetails.last_updated_on:''}
      </StyledTableCell>


      {accessControl === Edit && (
        <StyledTableCell align="left">
          {editMode === false ? (
      <Box
        sx={{
          border: '1px solid gray',
          display: 'flex',
          borderRadius: '4px',
          width: '58px',
          alignItems: 'center',
          gap: '5px',
          padding: '2px 6px',
          cursor: 'pointer',
        }}
        onClick={editTheRow}
        style={customealltheme.palette.TableEdit}
      >
        <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
        <Box>Edit</Box>
      </Box>
    ) : (
      <Box sx={{display: 'flex', gap: '5px'}}>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
            background: '#4297d3',
            color: 'white',
            justifyContent: 'center',
          }}
          onClick={saveTheRow}
        >
          <Box>Save</Box>
        </Box>
        <Box
          sx={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: '4px',
            width: '58px',
            alignItems: 'center',
            gap: '5px',
            padding: '2px 6px',
            cursor: 'pointer',
          }}
          onClick={cancelEdit}
        >
          <Box>Cancel</Box>
        </Box>
      </Box>
    )}
        </StyledTableCell>
      )}


    </StyledTableRow>
  );
};

export default CommonConfSingleRow;
