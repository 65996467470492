import styled from '@emotion/styled';
import {
  Box,
  createTheme,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextareaAutosize,
} from '@mui/material';
import React, {useState} from 'react';
import {tableCellClasses} from '@mui/material/TableCell';
import {BiEdit} from 'react-icons/bi';
import theme from '../../../../theme';
import config from '../../../../Utils/Config';
import customealltheme from '../../../../theme';
import {useEffect} from 'react';
const Edit = config.AccessLevel.Edit;

const CustomInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    'borderRadius': 4,
    'position': 'relative',
    'backgroundColor': theme.palette.background.paper,
    'border': '1px solid #ced4da',
    'fontSize': 16,
    'padding': '10px 26px 10px 12px',
    'transition': theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
    },
  },
}));
const customTheme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            marginInlineEnd: '1rem',
            width: '5px',
          },
          '*::-webkit-scrollbar-track': {
            marginInlineEnd: '1rem',
            background: '#inherit',
          },
          '*::-webkit-scrollbar-thumb': {
            background: '#a6a7a8',
            borderRadius: '2px',
            marginInlineEnd: '1rem',
          },
          '.MuiMenu-paper': {
            maxHeight: '150px',
            overflowY: 'auto',
            width: '100px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
  selectMenuBox: {
    padding: '3px 12px',
    fontSize: '12px',
    font: 'normal normal normal 13px/15px \'Segoe UI\' ',
    color: 'rgba(85, 87, 89, 1)',
  },
  selectBox: {
    '.MuiOutlinedInput-input': {
      'padding': '11.5px 14px',
      'border': 'none',
      '.MuiOutlinedInput-notchedOutline:hover': {
        border: '1px solid blue',
      },
    },
    '.MuiSelect-select': {
      font: 'normal normal normal 13px/15px \'Segoe UI\' ',
      color: 'rgba(85, 87, 89, 1)',
    },
  },
});

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#eff6fc',
    color: 'rgba(59, 70, 85, 1)',
    font: 'normal normal 600 13px/19px \'Segoe UI\'',
    padding: '9px',
  },
  [`&.${tableCellClasses.body}`]: {
    font: 'normal normal normal 13px/19px \'Segoe UI\'',
    color: 'rgba(109, 120, 136, 1)',
    padding: '9px',
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: ' #FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CommoditySingleRow = ({
  rowId,
  r,
  ActiveCategories,
  setAddingTrigger,
  addingTrigger,
  accessControl,
  singleUpdate,
  setErrMsg,
  setIsErrorPopupVisible,
  page,
  sorttype,
  submitClicked,
  filterResetClicked,
}) => {
  const [editMode, setEditMode] = useState(false);

  // eslint-disable-next-line no-unused-vars

  // This editeddetails used for storing the adding details
  const [commmodityChanged, setCommodityChanged] = React.useState(false);
  const [commodityName, setCommodityName] = React.useState('');
  const [localUnitId, setlocalUnitId] = React.useState('');
  const [active, setactive] = React.useState('');


  const handleLocalDes = (e) => {
    setlocalUnitId(e.target.value);
  };

  const handleactivestatus = (e) => {
    setactive(e.target.value);
  };

  const editTheRow = () => {
    setEditMode(true);
  };

  // when indiviual edited and clicked this will send a update request
  const saveTheRow = () => {
    if ( commmodityChanged && commodityName === '') {
      return;
    }
    setTimeout(()=>{
      setAddingTrigger(!addingTrigger);
    }, 1000);

    singleUpdate({commodityName: document.getElementById(`subCommodity-${r.commodityId}`).value,
      categoryId: localUnitId!=='' ? localUnitId : rowId,
      active: active!=='' ? active : r.is_active,
      isActive: active!=='' ? active : r.is_active,
      commodityId: r.commodityId,
    })
        .then((res) => {
          if (res?.status == '200') {
            setEditMode(false);
          } else if (res?.userMessage) {
            setIsErrorPopupVisible(true);
            setErrMsg(res?.userMessage);
            setEditMode(true);
          }
        })
        .catch((err) => {
          setIsErrorPopupVisible(true);
          setErrMsg('Error has been occured');
          setAddingTrigger(!addingTrigger);
          setEditMode(false);
        });
  };

  useEffect(()=> {
    setEditMode(false);
  }, [page, sorttype, submitClicked, filterResetClicked]);

  useEffect(()=>{
    if (!editMode) {
      setCommodityName('');
      setactive('');
      setCommodityChanged(false);
    }
  }, [editMode]);
  return (
    <StyledTableRow>

      <StyledTableCell component="th" scope="row">
        {editMode === true && r.is_editable === 'Y' ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={localUnitId}
              onChange={handleLocalDes}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >
              <MenuItem value="" sx={customTheme.selectMenuBox} style={{display: 'none'}}>
                <span>{r.category?r.category:''}</span>
              </MenuItem>
              {ActiveCategories.length !== 0 &&
                ActiveCategories?.map((obj) => (
                  <MenuItem
                    sx={customTheme.selectMenuBox}
                    key={obj?.category_id}
                    value={obj?.category_id}
                    className="this-is-new-class"
                  >
                    {obj.category?obj.category:''}
                  </MenuItem>
                ))}

            </Select>
          </FormControl>
        ) : (
          r.category
        )}
      </StyledTableCell>

      <StyledTableCell component="th" scope="row" >
        { editMode===true ?
                <TextareaAutosize id={`subCommodity-${r.commodityId}`} style={theme.palette.TextArea}
                  placeholder="Type in here…"
                  defaultValue={commodityName || r.commodity}
                  minRows={1}
                  maxRows={4}
                  onChange={(e)=>{
                    setCommodityName(e.target.value);
                    setCommodityChanged(true);
                  }}
                /> :
              r.commodity
        }
      </StyledTableCell>


      <StyledTableCell component="th" scope="row">
        {editMode === true && r.is_editable === 'Y' ? (
          <FormControl style={{width: '100px'}}>
            <Select
              labelId="demo-multiple-checkbox-label"
              sx={customTheme.selectBox}
              value={active || r.is_active}
              onChange={handleactivestatus}
              label="Select"
              input={<CustomInput label="Tag" />}
              displayEmpty
              className="newclass"
            >

              { ['true', 'false']?.map((obj, index) => (
                <MenuItem
                  sx={customTheme.selectMenuBox}
                  key={index}
                  value={obj}
                  className="this-is-new-class"
                >
                  {obj === 'true'? 'Y' : 'N'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          `${r.is_active ? 'Y' : 'N'}`
        )}
      </StyledTableCell>
      <StyledTableCell>{r.updated_by?r.updated_by:''}</StyledTableCell>
      <StyledTableCell>{r.updated_on?r.updated_on:''}</StyledTableCell>


      {accessControl === Edit &&
      <StyledTableCell align="left" style={{position: 'relative'}}>
        {editMode === false ? (
          r.is_editable === 'N' ? <abbr style={{textDecoration: 'none', borderBottom: 'none'}} title="The item cannot be made inactive as it is actively used in a buy plan">
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={editTheRow} style={customealltheme.palette.TableEdit}
            >
              <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
              <Box>Edit</Box>
            </Box>
          </abbr> :

            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={editTheRow} style={customealltheme.palette.TableEdit}
            >
              <BiEdit style={{fontSize: '13px', color: '#3174b6'}} />
              <Box
              >Edit</Box>
            </Box>
        ) : (
          <Box sx={{display: 'flex', gap: '5px'}}>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: `${ (commmodityChanged && commodityName === '') ? 'not-allowed' : 'pointer'}`,
                background: '#4297d3',
                color: 'white',
                justifyContent: 'center',
              }}
              onClick={saveTheRow}
            >
              <Box>Save</Box>
            </Box>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                border: '0.5px solid #CECECE',
                borderRadius: '4px',
                width: '58px',
                alignItems: 'center',
                gap: '5px',
                padding: '2px 6px',
                cursor: 'pointer',
              }}
              onClick={(e) => setEditMode(false)}
            >
              <Box>Cancel</Box>
            </Box>
          </Box>
        )}
      </StyledTableCell>
      }
    </StyledTableRow>
  );
};

export default CommoditySingleRow;
