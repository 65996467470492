/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {DataSheetGrid, textColumn, keyColumn} from 'react-datasheet-grid';
import {AppButton} from '../../common/AppButton/AppButton';
import 'react-datasheet-grid/dist/style.css';
import './SaveCoverage.css';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {AppDialog, AppErrorDialog, BulkUploadError} from '../../common/App-Dialog/AppDialog';
import {commaTextToNumber, monthArr, roundToTwo} from '../../../services/utils';
import DialogService from '../../../services/dialogService';
import DropdownSelect from './DropdownSelect/DropdownSelect';
import SaveCoverageInput from './SaveCoverageInput/SaveCoverageInput';
import DropdownSelectMonth from './DropdownSelectMonth/DropdownSelectMonth';
import Styles from '../../../styles/workflowModal';
import DropdownSelectSourceName from './DropdownSelectSourceName/DropdownSelectSourceName';
import AddIcon from '@mui/icons-material/Add';
import {useRef} from 'react';
import {useLayoutEffect} from 'react';
import {CSVLink} from 'react-csv';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

import Papa from 'papaparse';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ErrorIcon from '@mui/icons-material/Error';
import {read, utils, writeFile} from 'xlsx';
import {useSelector} from 'react-redux';
import DropdownSelectSector from './DropdownSelectSector/DropdownSelectSector';
import DropdownSelectCountry from './DropdownSelectCountry/DropdownSelectCountry';


const dropdownSpan = {
  font: 'normal normal 600 12px/15px \'Segoe UI\' ',
  color: 'rgba(59, 70, 85, 1)',
  marginBottom: '5px',
};
const InputComponent = ({wfDetails, focus, updateTotalValues, selectedRow, keyToCheck, indexOfYear, apiData, rowData, typeOfCell = 'number'}) => {
  // check cond for keyToCheck for coverageVolume and coveredPrice
  const [inputValue, setInputValue] = useState(rowData[keyToCheck] || '');
  const ref = useRef(null);
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const decider = wfDetails?.decimal_scale;

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus();
    } else {
      ref.current?.blur();
    }
  }, [focus]);
  const formatNumber = (number, index) => {
    let numberToFormat = number;
    if (String(numberToFormat).split('.').length > 1) {
      numberToFormat = String(numberToFormat).split('.')[1]?.length < index ? numberToFormat : (String(numberToFormat).split('.')[0] + '.' + String(numberToFormat).split('.')[1].substring(0, index));
    };
    return numberToFormat;
  };
  useEffect(()=>{
    let updatedValue = rowData[keyToCheck] || '';
    if (typeOfCell !=='text') {
      const roundedValue = commaTextToNumber(rowData[keyToCheck]);
      updatedValue = (rowData[keyToCheck] === undefined || rowData[keyToCheck] == null || rowData[keyToCheck] == '') ? '' : (isNaN(roundedValue) ? 0 : roundedValue);
    }
    if (typeOfCell==='text') {
      rowData[keyToCheck] = updatedValue;
    } else if (typeOfCell!='text' && updatedValue === 0) {
      rowData[keyToCheck] = 0;
    }
    // setInputValue(commaTextToNumber(updatedValue));
    if (keyToCheck === 'coverageVolume' || keyToCheck === 'coveredPrice') {
      setInputValue(roundToTwo(decider, Number(commaTextToNumber(updatedValue))));
    } else {
      setInputValue(commaTextToNumber(updatedValue));
    }
  }, [rowData]);
  const handleChangeValue = (event, keyToCheck, rowData, selectedRow, indexOfYear, apiData, decimalPoint) => {
    let valueToUpdate = event.target.value;
    if (typeOfCell!='text' && String(event.target.value).includes('.')) {
      valueToUpdate = formatNumber(event.target.value, decimalPoint);
    }
    apiData[indexOfYear].map((element, index)=>{
      if (element.id==rowData.id) {
        element[keyToCheck] = valueToUpdate;
        rowData[keyToCheck] = valueToUpdate;
        if (rowData?.prevMonth) {
          element['author'] = isCreator?.userName;
          rowData['author'] = isCreator?.userName;
          element['isChanged'] = true;
          rowData['isChanged'] = true;
        }
      }
    });
    if (typeOfCell!='text') {
      updateTotalValues();
    }
    setInputValue(valueToUpdate);
  };

  return (
    <>
      <input
        ref={ref}
        className={'dsg-input dsg-input-align-right'}
        value={inputValue}
        type={typeOfCell}
        disabled={rowData?.is_carry_volume && keyToCheck!=='coveredPrice' && keyToCheck!=='poNumber'}
        onBlur={(e) => {
          let valueToUpdate = e.target.value;
          if (typeOfCell!='text' && String(valueToUpdate).includes('.')) {
            valueToUpdate = Number(valueToUpdate);
          }
          apiData[indexOfYear].map((element, index)=>{
            if (element.id==rowData.id) {
              element[keyToCheck] = valueToUpdate;
              // rowData[keyToCheck] = valueToUpdate; // db update
              if (keyToCheck === 'coverageVolume' || keyToCheck === 'coveredPrice') {
                rowData[keyToCheck] = decider === 0 ? roundToTwo(decider, valueToUpdate) : valueToUpdate;
              } else {
                rowData[keyToCheck] = valueToUpdate;
              }
            }
          });
          // setInputValue(valueToUpdate); // UI update
          if (keyToCheck === 'coverageVolume' || keyToCheck === 'coveredPrice') {
            setInputValue(roundToTwo(decider, valueToUpdate));
          } else {
            setInputValue(valueToUpdate);
          }
        }}
        onChange={(e) => handleChangeValue(e, keyToCheck, rowData, selectedRow, indexOfYear, apiData, 5)} />
    </>
  );
};

export default function SaveCoverage({wfDetails, sectorCountryMap, setCoverageData, updateRowsvalue, setChangeCoverageTable, setCoveragesSaved, supplierNames, apiData, selectedRow, allYears, selectedYear, sectors}) {
  const defaultErrorMessage = 'Error occured when processing the request';
  const [data, setData] = useState(apiData);
  const [isSavePopupVisible, setIsSavePopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  const [isBulkErrorPopupVisible, setIsBulkErrorPopupVisible] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [coverageVolume, setCoverageVolume] = useState('');
  const [weightedPrice, setWeightedPrice] = useState('');
  const [sourceTypeChoosen, setSourceTypeChoosen] = useState('');
  const [disableSavebtn, setDisableSaveBtn] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorMessage, setErrorMessage] =useState('');
  //  Bulk  upload and download
  const [uploadError, setUploadError] = useState(true);
  const [uploadMessage, setUploadMessage] = useState('');
  const [UploadStatus, setUploadStatus] = useState('');

  const [allBulkUploadErrors, setAllBulkUploadErrors] = useState([]);
  const isCreator = useSelector((state) => state.roleBasedAccess);
  const userHasPrevMonthAccess = isCreator?.functionalityAccess?.hasOwnProperty('Coverage:Previous_Month');
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const febMonthIndex = 3;
  const disablePrevMonthForFeb = selectedRow?.executionMonthYear[1] === febMonthIndex;
  // ${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}
  const csvData = [
    {'Execution Month': ``,
      'Coverage Month': '',
      'Source Type': '',
      'Source Name': '',
      'Coverage Volume': '',
      'Contract/PO Number': '',
      'Coverage Unit': '',
      'Covered Price': '',
      'Covered Price Currency': '',
      'Comments': '',
    },
  ];

  const payloadKeys =
    {'Execution Month': 'executionMonth',
      'Coverage Month': 'coverageYearMonth',
      'Source Type': 'sourceType',
      'Source Name': 'sourceName',
      'Sectors': 'sectors',
      'Countries': 'countries',
      'Contract/PO Number': 'poNumber',
      'Coverage Unit': 'coverageUnit',
      'Coverage Volume': 'coverageVolume',
      'Covered Price': 'coveredPrice',
      'Covered Price Currency': 'coveredPriceCurrency',
      'Comments': 'comments'};


  const excelNumbertoTextFormat = (num) => {
    const excelDateNumber = num; // Assuming 45078 is the Excel date number

    // Convert Excel date number to JavaScript date object
    const date = new Date(Date.UTC(1899, 11, 31) + (excelDateNumber - 1) * 24 * 60 * 60 * 1000);

    // Adjust for the leap year 1900 issue in Excel
    if (excelDateNumber >= 60) {
      date.setDate(date.getDate() + 1);
    }

    // Format the date as desired
    // const formattedDate = date.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});

    // console.log(formattedDate);


    const month = date.toLocaleString('default', {month: 'short'});
    const year = date.getFullYear();
    const final = `${month=='Sept'? 'Sep':month}-${year}`;
    return final;
  };

  const coveragePricedVolume = [];
  const handleFileUploadChange = (event) => {
    const file = event.target.files[0];
    const csvData = [];
    if (file.size === 0) {
      setUploadError(true);
      setUploadMessage('File should not be empty');
    } else if (file.size < 5767168) {
      setUploadError(false);
      setUploadMessage('Uploading...');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      // Xlsx and csv upload
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          setUploadError(false);
          setUploadMessage('Uploading...');
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          if (rows.length>0) {
            // console.log(rows);
            rows?.map((res, index)=>{
              if (typeof res['Execution Month'] == 'number') {
                res['Execution Month'] = excelNumbertoTextFormat(res['Execution Month']);
              } else {
                res['Execution Month'] = res['Execution Month']?.split('-')[1]?.length==2? res['Execution Month']?.split('-')[0]+'-'+'20'+res['Execution Month']?.split('-')[1].slice(0):res['Execution Month'];
              }
              if (typeof res['Coverage Month'] == 'number') {
                res['Coverage Month'] = excelNumbertoTextFormat(res['Coverage Month']);
              } else {
                res['Coverage Month'] = res['Coverage Month']?.split('-')[1]?.length==2? res['Coverage Month']?.split('-')[0]+'-'+'20'+res['Coverage Month']?.split('-')[1].slice(0):res['Coverage Month'];
              }
              // console.log(res);
              // if source Type user entered empty
              // debugger;
              if (res['Source Type']!=undefined) {
                if (res['Source Type'].toLowerCase() == selectedRow.coverageMode.toLowerCase() || ((res['Source Type'].toLowerCase() == selectedRow.coverageMode.toLowerCase() || res['Source Type'].toLowerCase()=='other') && selectedRow.others==true) ||( res['Source Type'].toLowerCase()=='other' && selectedRow.coverageMode == 'NONE' && (selectedRow.others==true || selectedRow.others==false))|| ((res['Source Type'].toLowerCase()=='treasury' || res['Source Type'].toLowerCase() == 'supplier') && selectedRow.coverageMode == 'BOTH' && selectedRow.others==false) || ((res['Source Type'].toLowerCase()=='treasury' || res['Source Type'].toLowerCase() == 'supplier' || res['Source Type'].toLowerCase() == 'other') && selectedRow.coverageMode == 'BOTH' && selectedRow.others==true)) {
                  res['Source Type'].toUpperCase();
                } else {
                  res['Source Type'] = '';
                }
              } else {
                res['Source Type'] = '';
              }
              if (isNaN(res['Coverage Volume']) || isNaN(res['Covered Price'])) {
                coveragePricedVolume.push(index+2);
              }
              // console.log(res);
              Object.keys(res)?.map((r)=>{
                res[payloadKeys[r]] = res[r];
                delete res[r];
              });
              csvData.push({...res, workflowId: selectedRow.workFlowId, isAtlasFX: false, is_carry_volume: false, id: `f${index}`});
            });
            if (coveragePricedVolume.length==0) {
              setUploadMessage('Uploaded');
              setUploadStatus('success');
              // console.log([[...data[0], ...csvData], data[1], data[2]]);


              addExceldata([...csvData], true);
              // setSuccessPopup(true);
              // setErrorPopup(true);
              // setErrorMessage('Hloo');
            } else {
              addExceldata([...csvData], true);
              // updateErrorMsg(`Covered price and Coverage volume should be numeric.
              // Check the Excel Rows ${coveragePricedVolume.join(',')}`);
              // // setTimeout(()=>{
              // document.getElementById('fileUpload').value='';
              setUploadMessage('Upload Failed');
              setUploadStatus('failed');
              // }, 8000);
            }


            setTimeout(()=>{
              document.getElementById('fileUpload').value='';
              setUploadMessage('');
              setUploadStatus('');
            }, 5000);
          } else {
            // setTimeout(()=>{
            document.getElementById('fileUpload').value='';
            setUploadMessage('Data is not there in the file.');
            setUploadStatus('failed');
            // }, 10000);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      setUploadError(true);
      setUploadMessage('File exceeds 5.5MB');
    }
    setTimeout(()=>{
      document.getElementById('fileUpload').value='';
      setUploadMessage('');
      setUploadStatus('');
    }, 5000);
  };
  const Popup = ({open, onClose}) => {
    useEffect(() => {
      if (open) {
        const timeout = setTimeout(() => {
          onClose();
        }, 3000);

        return () => clearTimeout(timeout);
      }
    }, [open, onClose]);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle style={{color: 'green', margin: 'auto'}}>Success!</DialogTitle>
        <DialogContent>
          <p style={dropdownSpan}>Your action was successful.</p>
        </DialogContent>
      </Dialog>
    );
  };

  const handleExport = () => {
    const headings = [[
      'Execution Month',
      'Coverage Month',
      'Source Type',
      'Source Name',
      'Sectors',
      'Countries',
      'Contract/PO Number',
      'Coverage Volume',
      'Coverage Unit',
      'Covered Price',
      'Covered Price Currency',
      'Comments',
    ]];

    const firstRowData = [
      {'Execution Month': ``,
        'Coverage Month': ``,
        'Source Type': '',
        'Source Name': '',
        'Contract/PO Number': '',
        'Coverage Volume': '',
        'Coverage Unit': `${selectedRow.measuringUnit}`,
        'Covered Price': 'USD',
        'Covered Price Currency': '',
        'Comments': '',
      },
    ];

    const instructions = [
      {'Instructions': ''},
      {'Instructions': '\u25CF Click on the Download Starter file button below'},
      // {'Instructions': ''},
      {'Instructions': '\u25CF Enter the Execution Month in the format mmm-yy  / mmm-yyyy (Ex- Apr-23 / Apr-2023). Execution month information is present in buy plan details section of the coverage tab. Execution month is same for all the rows.'},
      {'Instructions': '\u25CF Execution year month should match with the Buy plan execution month. '},

      {'Instructions': '\u25CF Enter the Coverage Month values in the format mmm-yy  / mmm-yyyy (Ex- Apr-23 / Apr-2023) '},
      {'Instructions': '\u25CF Enter the Coverage Month values from previous month to future months. Previous months are not allowed.'},

      {'Instructions': '\u25CF Enter the Source Type as Supplier, Treasury or Others '},

      {'Instructions': '\u25CF Enter the Source name as below:'},
      {'Instructions': '   a. Source Name as one of the Supplier Name from Supplier Master page, if the Source Type is Supplier'},
      {'Instructions': '   b. Source Name as Treasury, if the Source Type is Treasury'},
      // {'Instructions': ''},
      {'Instructions': '\u25CF Enter the Sector and Country for which the coverage is being taken. Please refer the Sector Country Mapping Info of the template file'},

      {'Instructions': '\u25CF Enter the Coverage Volume in numeric format. Upto 5 decimal values allowed'},
      {'Instructions': '\u25CF Enter the Coverage Unit which is used for the buy plan. Coverage unit is present in the Buy plan details section on coverage tab'},
      {'Instructions': '\u25CF Enter the Covered Price with per unit Covered Price values. Upto 5 decimal places allowed'},
      {'Instructions': '\u25CF Enter the Covered Price Currency which is used for the buy plan. Currency information is present in the Buy plan details section on the coverage tab'},
      {'Instructions': '\u25CF Enter Comments if required (Optional)'},
      {'Instructions': '\u25CF Click on Bulk upload button, browse and select the saved file.'},
      {'Instructions': '\u25CF Click on Bulk Update button to browse your saved template file'},
      {'Instructions': '\u25CF Select the template file and upload'},
      {'Instructions': '\u25CF Wait for a pop up to confirm if all rows are successfully uploaded'},

    ];

    const SuppliersNames = [];
    supplierNames.length>0 && supplierNames.map((s, index)=>{
      SuppliersNames.push({
        'Suppliers': s.supplier_name,
      });
    });

    const countrySectorList = [];
    if (Object.keys(sectors).length>0) {
      Object.keys(sectors).map((sc)=>{
        sectors[sc]?.map((scs)=>{
          countrySectorList.push({'sector': sc.toUpperCase(), 'country': scs});
        });
      });
    }

    const sourceTypes = [];
    if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== false) {
      sourceTypes.push({'Source Type': 'SUPPLIER'});
    } else if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== true) {
      sourceTypes.push({'Source Type': 'SUPPLIER'});
      sourceTypes.push({'Source Type': 'OTHER'});
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== false) {
      sourceTypes.push({'Source Type': 'TREASURY'});
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== true) {
      sourceTypes.push({'Source Type': 'TREASURY'});
      sourceTypes.push({'Source Type': 'OTHER'});
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others== false) {
      sourceTypes.push({'Source Type': 'SUPPLIER'});
      sourceTypes.push({'Source Type': 'TREASURY'});
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others==true) {
      sourceTypes.push({'Source Type': 'SUPPLIER'});
      sourceTypes.push({'Source Type': 'TREASURY'});
      sourceTypes.push({'Source Type': 'OTHER'});
    } else if (selectedRow.coverageMode=='NONE') {
      sourceTypes.push({'Source Type': 'OTHER'});
    }

    if (selectedRow.coverageMode=='SUPPLIER' || selectedRow.coverageMode=='BOTH') {
      if (SuppliersNames.length>0) {
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        // utils.sheet_add_json(ws, movies, {origin: 'A2', skipHeader: true});
        const SuppliersNamesSheet = utils.json_to_sheet(SuppliersNames, {header: ['Suppliers']});
        const SuppliersTypeSheet = utils.json_to_sheet(sourceTypes, {header: ['Source Type']});
        const instructionsSheet = utils.json_to_sheet(instructions, {header: ['Instructions']});
        const sectorCountry = utils.json_to_sheet(countrySectorList);


        utils.book_append_sheet(wb, ws, 'Data');
        utils.book_append_sheet(wb, instructionsSheet, 'Instructions');
        utils.book_append_sheet(wb, SuppliersTypeSheet, 'Source Type Info');
        utils.book_append_sheet(wb, SuppliersNamesSheet, 'Source Name Info');
        utils.book_append_sheet(wb, sectorCountry, 'Sector Country Mapping Info');


        writeFile(wb, 'coverage_bulk_upload_template.xlsx');
      } else {
        updateErrorMsg('Please add atleast one active Supplier in Supplier Master to enter coverages.');
        setOpen(false);
      }
    } else {
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      // utils.sheet_add_json(ws, movies, {origin: 'A2', skipHeader: true});
      const SuppliersNamesSheet = utils.json_to_sheet(SuppliersNames, {header: ['Suppliers']});
      const SuppliersTypeSheet = utils.json_to_sheet(sourceTypes, {header: ['Source Type']});
      const instructionsSheet = utils.json_to_sheet(instructions, {header: ['Instructions']});
      const sectorCountry = utils.json_to_sheet(countrySectorList);


      utils.book_append_sheet(wb, ws, 'Data');
      utils.book_append_sheet(wb, instructionsSheet, 'Instructions');
      utils.book_append_sheet(wb, SuppliersTypeSheet, 'Source Type Info');
      utils.book_append_sheet(wb, SuppliersNamesSheet, 'Source Name Info');
      utils.book_append_sheet(wb, sectorCountry, 'Sector Country Mapping Info');


      writeFile(wb, 'coverage_bulk_upload_template.xlsx');
    }
  };


  useEffect(() => {
    if (selectedRow.workFlowId && supplierNames !== null) {
      getCall(monthArr, supplierNames);
    };
  }, [selectedRow, supplierNames]);

  function updateTotalValues() {
    let totVolume = 0;
    // let totPrice = 0;
    let weightedPrice = 0;
    const currYear = new Date().getFullYear();
    const diff = Number(selectedYear) < currYear ? 0 : Number(selectedYear) - currYear;
    if (data.length > 0) {
      data[diff]?.map((item) => {
        totVolume += Number(commaTextToNumber(item.coverageVolume)) || 0;
        // totPrice += Number(item.coveredPrice) || 0;
        weightedPrice += Number(commaTextToNumber(item.coverageVolume) * commaTextToNumber(item.coveredPrice)) || 0;
      });
    }
    setWeightedPrice((weightedPrice/totVolume).toFixed(+wfDetails?.decimal_scale) === 'NaN' ? 0 : (+weightedPrice/+totVolume).toString().split('.').length>1? roundToTwo(+wfDetails?.decimal_scale, +weightedPrice/+totVolume).toLocaleString('en-US') : (+weightedPrice/+totVolume).toLocaleString('en-US'));

    setCoverageVolume(totVolume.toString().split('.').length>1 ? roundToTwo(+wfDetails?.decimal_scale, totVolume).toLocaleString('en-US') : (totVolume===0 ? 0 : Number(totVolume)) );
  }

  useEffect(()=>{
    updateTotalValues();
  }, [data]);

  function getSourceType(selectedRow) {
    if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others == false) {
      return 'SUPPLIER';
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others == false) {
      return 'TREASURY';
    } else if (selectedRow.coverageMode=='NONE') {
      return 'OTHER';
    } else return '';
  }
  function getSourceName(selectedRow) {
    if (selectedRow.coverageMode=='TREASURY') {
      return 'TREASURY';
    } else if (selectedRow.coverageMode=='NONE') {
      return 'OTHER';
    } else return '';
  }
  function checkDisableForRow(userHasAccess, row) {
    if (userHasAccess) {
      return false;
    }
    const coverageYearMonth = [row.coverageYearMonth.split('-')[1], months.indexOf(row.coverageYearMonth.split('-')[0])];
    const executinoYearMon = [selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[1]-1];
    if (((executinoYearMon[0]-1) == coverageYearMonth[0]) ) {
      if ((coverageYearMonth[1]==11 && executinoYearMon[1]==0) || (coverageYearMonth[1]==10 && executinoYearMon[1]==0) ) {
        return false;
      } else if ((coverageYearMonth[1]==11 && executinoYearMon[1]==1) ) {
        return false;
      } else {
        return true;
      }
    } else if ((executinoYearMon[0] == coverageYearMonth[0])) {
      return !((coverageYearMonth[1]+2) >= executinoYearMon[1]);
    }
  }
  const getCall = (month, supplierNames=[]) => {
    const temp = [[]];
    DialogService.getCoverageData(selectedRow.workFlowId).then((res) =>{
      res.sort((a, b) => new Date(a['coverageYearMonth']) - new Date(b['coverageYearMonth']));
      res.map(( element) => {
        element.executionMonth=`${month[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`;
        element.coverageUnit = selectedRow.measuringUnit;
        element.coveredPriceCurrency = selectedRow.currency;
        element['disableCell'] = checkDisableForRow(userHasPrevMonthAccess, element);
        element['prevMonth'] = checkDisableForRow(false, element);
        element['savedRow'] = true;
        element['isChanged'] = false;
      });
      // temp[index]=res?.filter((entry) => entry.coverageYearMonth.endsWith(''+allYears[index]));
      for (let index=0; index < allYears.length; index++) {
        temp[index]=res?.filter((entry) => entry);
      }
      if (temp[0].length === 0) {
        const addDefaultEmptyRow = {
          'id': 'f0',
          'executionMonth': `${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`,
          'coverageUnit': selectedRow.measuringUnit,
          'coveredPriceCurrency': selectedRow.currency,
          'coverageVolume': '',
          'coverageYearMonth': '',
          'coveredPrice': '',
          'sourceName': getSourceName(selectedRow),
          'sourceType': getSourceType(selectedRow),
          'sector_id': '',
          'region_id': '',
          'comments': '',
          'isAtlasFX': false,
          'is_carry_volume': false,
          'poNumber': '',
          'workflowId': selectedRow.workflowId,
          'savedRow': false,
        };
        setData([[addDefaultEmptyRow, ...temp[0]], temp[1], temp[2]]);
      } else {
        setData(temp);
      }
      // setData(temp);
    });
  };
  const handleErrorPopupClose = () => {
    setErrorPopup(false);
  };

  const handleSuccessPopupClose =() =>{
    setSuccessPopup(false);
  };

  const descriptionElementRefError = useRef(null);
  useEffect(() => {
    if (errorPopup) {
      const {current: descriptionElement} = descriptionElementRefError;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [errorPopup]);
  const columns = [
    {...keyColumn('executionMonth', textColumn), component: () => <SaveCoverageInput selectedRow={selectedRow} typeOfColumn={'executionMonth'} />, title: 'Execution Month', disabled: 'true'},
    {...keyColumn('coverageYearMonth', textColumn), component: ({rowData}) => <DropdownSelectMonth selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Coverage Month', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume )},
    {...keyColumn('sourceType', textColumn), component: ({rowData}) => <DropdownSelect selectedRow={selectedRow} setSourceTypeChoosen={setSourceTypeChoosen} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Source Type', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume)},
    {...keyColumn('sourceName', textColumn), component: ({rowData}) => <DropdownSelectSourceName sourceTypeChoosen={sourceTypeChoosen} supplierNames={supplierNames} typeOfColumn={'sourceName'} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Source Name', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('sector_id', textColumn), title: 'Sector', component: ({rowData}) => <DropdownSelectSector sectorCountryMap={sectorCountryMap} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume)},
    {...keyColumn('region_id', textColumn), title: 'Country', component: ({rowData}) => <DropdownSelectCountry sectorCountryMap={sectorCountryMap} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume)},
    {...keyColumn('poNumber', textColumn), title: 'Contract/PO Number', component: ({rowData, focus}) => <InputComponent wfDetails={wfDetails} focus={focus} keyToCheck={'poNumber'} updateTotalValues={updateTotalValues} selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} typeOfCell='text' />, disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('coverageVolume', textColumn), component: ({rowData, focus}) => <InputComponent wfDetails={wfDetails} focus={focus} keyToCheck={'coverageVolume'} updateTotalValues={updateTotalValues} selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Coverage Volume', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume)},
    {...keyColumn('coverageUnit', textColumn), component: () => <SaveCoverageInput selectedRow={selectedRow} typeOfColumn={'coverageUnit'} />, title: 'Coverage Unit', disabled: 'true'},
    {...keyColumn('coveredPrice', textColumn), component: ({rowData, focus}) => <InputComponent wfDetails={wfDetails} focus={focus} keyToCheck={'coveredPrice'} updateTotalValues={updateTotalValues} selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} />, title: 'Covered Price', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell)},
    {...keyColumn('coveredPriceCurrency', textColumn), component: () => <SaveCoverageInput selectedRow={selectedRow} typeOfColumn={'coveredPriceCurrency'} />, title: 'Covered Price Currency', disabled: 'true'},
    {...keyColumn('comments', textColumn), component: ({rowData, focus}) => <InputComponent wfDetails={wfDetails} focus={focus} keyToCheck={'comments'} updateTotalValues={updateTotalValues} selectedRow={selectedRow} rowData={rowData} indexOfYear={allYears.indexOf(selectedYear)} apiData={data} typeOfCell='text' />, title: 'Comments', disabled: ({rowData}) => (rowData?.isAtlasFX || rowData?.disableCell || rowData?.is_carry_volume)},
  ];
  function handleAddRow(prevMonth=false, isChanged=false) {
    const newRowId = data.length>0 ? data[0][data[0].length-1]?.id : 'f0';
    const newRowObj = {
      'id': `f${Number(String(newRowId).slice(1)) + 1}`,
      'executionMonth': '',
      'coverageUnit': '',
      'coveredPriceCurrency': '',
      'sourceName': getSourceName(selectedRow),
      'sourceType': getSourceType(selectedRow),
      'prevMonth': prevMonth,
      'savedRow': false,
      'isChanged': isChanged,
    };
    const updateObj = [...data[0], newRowObj];
    addNewRowToGrid(updateObj, false);
  }

  function editCoverage(value, sIndex) {
    const temp = [[]];
    temp[sIndex] = value;
    for (let index=0; index < data.length; index++) {
      if (index == sIndex) continue;
      temp[index] = data[index];
    }
    setData(temp);
  }

  function editCoverage2(value, sIndex) {
    const temp = [[]];
    temp[sIndex] = value;
    for (let index=0; index < data.length; index++) {
      if (index == sIndex) continue;
      temp[index] = data[index];
    }
    // setData(temp);
    const covtemp = JSON.parse(JSON.stringify([...temp[0]]));
    const coverages = [];
    const sourceIds={};
    supplierNames.length>0 && supplierNames.map((s)=>{
      sourceIds[s['supplier_name']?.toLowerCase()] = s['supplier_id'];
    });

    const countrySectorErrors = [];
    covtemp.length>0 && covtemp.map((c, index)=>{
      const selectedSectors = [];

      if (c.countries==undefined || c.sectors==undefined ) {
        countrySectorErrors.push(index+2);
      } else {
        // console.log(sectors);
        // console.log(Object.keys(sectors)?.map((s)=>s.toLowerCase()));
        const mainSectors = Object.keys(sectors);
        let errors = false;
        c.sectors.split(',').map((sec)=>{
          if (mainSectors.indexOf(sec.trim().toLowerCase())==-1) {
            countrySectorErrors.push(index+2);
            errors=true;
          }
        });
        if (errors==false) {
          c.sectors.split(',').map((sec)=>{
            sectors[sec.trim().toLowerCase()].map((sc)=>{
              selectedSectors.push(sc);
            });
          });
          c.countries.split(',')?.map((cos)=>{
            if (selectedSectors.indexOf(cos.trim().toLowerCase())==-1) {
              countrySectorErrors.push(index+2);
            }
          });
        }
      }
      // console.log(selectedSectors);
    });
    // console.log(countrySectorErrors);
    const coverageMonthErrors = [];
    const executionMonthErrors = [];

    // checking execution month years
    covtemp.length>0 && covtemp.map((c, index)=>{
      if (c.executionMonth != `${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`) {
        executionMonthErrors.push(index+2);
      }
    });

    // Checking coverage Month years
    covtemp.length>0 && covtemp.map((c, index)=>{
      // console.log(![selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1, selectedRow.executionMonthYear[0]+2].includes(+c.coverageYearMonth?.split('-')[1]) || (monthArr.indexOf(c.coverageYearMonth?.split('-')[0])<monthArr.indexOf(monthArr[selectedRow.executionMonthYear[1]-1]) && +c.coverageYearMonth?.split('-')[1]==[selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1, selectedRow.executionMonthYear[0]+2][0]));
      // selectedRow.executionMonthYear[1]-2!==-1 used to check it is Dec month or not if it is then we will get -1
      if (selectedRow.executionMonthYear[1]-2==-1) {
        if (![selectedRow.executionMonthYear[0]-1, selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1].includes(+c.coverageYearMonth?.split('-')[1]) || (monthArr.indexOf(c.coverageYearMonth?.split('-')[0])<monthArr.indexOf(monthArr[10]) && +c.coverageYearMonth?.split('-')[1]==[selectedRow.executionMonthYear[0]-1, selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1][0])) {
          coverageMonthErrors.push(index+2);
        }
      } else if (selectedRow.executionMonthYear[1]-2==0) {
        if (![selectedRow.executionMonthYear[0]-1, selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1].includes(+c.coverageYearMonth?.split('-')[1]) || (monthArr.indexOf(c.coverageYearMonth?.split('-')[0])<monthArr.indexOf(monthArr[11]) && +c.coverageYearMonth?.split('-')[1]==[selectedRow.executionMonthYear[0]-1, selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1][0])) {
          coverageMonthErrors.push(index+2);
        }
      } else {
        if (![selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1, selectedRow.executionMonthYear[0]+2].includes(+c.coverageYearMonth?.split('-')[1]) || (monthArr.indexOf(c.coverageYearMonth?.split('-')[0])<monthArr.indexOf(monthArr[selectedRow.executionMonthYear[1]-3]) && +c.coverageYearMonth?.split('-')[1]==[selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1, selectedRow.executionMonthYear[0]+2][0])) {
          coverageMonthErrors.push(index+2);
        }
      }
    });

    const sourceTypes = [];
    if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== false) {
      sourceTypes.push('SUPPLIER');
    } else if (selectedRow.coverageMode=='SUPPLIER' && selectedRow.others== true) {
      sourceTypes.push('SUPPLIER');
      sourceTypes.push('OTHER');
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== false) {
      sourceTypes.push('TREASURY');
    } else if (selectedRow.coverageMode=='TREASURY' && selectedRow.others== true) {
      sourceTypes.push('TREASURY');
      sourceTypes.push('OTHER');
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others== false) {
      sourceTypes.push('SUPPLIER');
      sourceTypes.push('TREASURY');
    } else if (selectedRow.coverageMode=='BOTH' && selectedRow.others==true) {
      sourceTypes.push('SUPPLIER');
      sourceTypes.push('TREASURY');
      sourceTypes.push('OTHER');
    } else if (selectedRow.coverageMode=='NONE') {
      sourceTypes.push('OTHER');
    }

    const sourceTypeErrors = [];
    covtemp.length>0 && covtemp.map((c, index)=>{
      if (!sourceTypes.includes(c.sourceType.toUpperCase())) {
        sourceTypeErrors.push(index+2);
      }
    });


    const coverageUnitError = [];
    covtemp.length>0 && covtemp.map((c, index)=>{
      if (c.coverageUnit =='' || (c.coverageUnit?.trim().toUpperCase() !== selectedRow.measuringUnit?.trim().toUpperCase())) {
        coverageUnitError.push(index+2);
      }
    });


    const coveredPriceCurrencyError = [];
    covtemp.length>0 && covtemp.map((c, index)=>{
      if (c.coveredPriceCurrency =='' || (c.coveredPriceCurrency?.trim().toUpperCase() !== selectedRow.currency?.trim().toUpperCase())) {
        coveredPriceCurrencyError.push(index+2);
      }
    });
    let labelsMissing = false;
    covtemp.map((item, index) => {
      const covYearMonth = typeof(item.coverageYearMonth) === 'string' ? item.coverageYearMonth.split('-') : item.coverageYearMonth;
      if (covYearMonth==undefined) {
        labelsMissing = true;
        return;
      }
      item.coverageYearMonth = [covYearMonth[1]*1, monthArr.indexOf(covYearMonth[0]?.trim())+1];
      if (typeof (item.id) === 'string' && (item.id)[0] === 'f' ) {
        delete item.id;
      }
      if (item.sourceType === 'SUPPLIER' && (item.sourceName === 'TREASURY' || item.sourceName === 'OTHER')) {
        item.sourceName = '';
      }
      apiData[0].map((apiItem) => {
        if (apiItem.id === item.id) {
          apiItem = {...item};
        }
      });
      setCoverageData([...apiData]);
      item.workflowId = selectedRow.workFlowId;
      item.sourceType = item.sourceType.toUpperCase();
      item.workflowId = selectedRow.workFlowId;
      if (item.sourceType === 'TREASURY' || item.sourceType === 'OTHER') {
        item.sourceId = 0;
      } else {
        item.sourceId = sourceIds[`${item?.sourceName?.toLowerCase()}`];
      }
      item.sourceName = getSupplierName2(supplierNames, item, index)?.supplier_name;
      if (item.countries!==undefined) {
        // item.countries = item.countries.split(',').map((it)=>it.trim());
        item.region = item.countries;
        delete item.countries;
      }
      if (item.sectors!==undefined) {
        // item.sectors = item.sectors.split(',').map((it)=>it.trim());
        item.sector = item.sectors;
        delete item.sectors;
      }
      item.coverageVolume = +wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, item.coverageVolume) : item.coverageVolume;
      item.coveredPrice = +wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, item.coveredPrice) : item.coveredPrice;
      coverages.push(item);
    });
    // console.log(coverages);
    // });
    const sourceIdNullErrors = [];
    coverages?.map((c, index)=>{
      if (c.sourceId == undefined) {
        sourceIdNullErrors.push(index+2);
      }
    });


    // Writing popups according to missing fields
    if (labelsMissing) {
      setAllBulkUploadErrors(['Please upload the valid template']);
      setIsBulkErrorPopupVisible(true);
      return;
    }
    if (executionMonthErrors.length==0 && coverageMonthErrors.length==0 && sourceTypeErrors.length==0 && sourceIdNullErrors.length==0 && coverageUnitError.length === 0 && coveredPriceCurrencyError.length == 0 && coveragePricedVolume.length==0 && TreasuryOtherSupplierNameErrors.length==0 && countrySectorErrors.length==0) {
      // checking execution month year is with condition
      // if (coverageMonthErrors.length==0) {
      // source Type wrong insertion
      // if (sourceTypeErrors.length==0) {
      // covtemp.forEach((entry) => {
      // if (sourceIdNullErrors.length>0) {
      //   updateErrorMsg(`You may have entered wrong supplier names. Check the Source Name in rows ${sourceIdNullErrors.join(',')}. Please check the Excel and refer Source Name sheet.`);
      //   setUploadStatus('failed');
      //   setUploadMessage('Upload Failed');
      // } else {
      // checking coverage Unit errors
      // if (coverageUnitError.length === 0) {
      // checking covered price currency is USD or not
      // if (coveredPriceCurrencyError.length == 0) {
      const payload = {'workflowId': + selectedRow.workFlowId, 'prevWorkflowId': + selectedRow.prevWorkFlowId, 'coverages': coverages};
      setDisableSaveBtn(true);
      DialogService.saveCoverageData(payload).then((res) => {
        if (res == true) {
          setIsSavePopupVisible(true);
          updateRowsvalue(monthArr, supplierNames);
          // setSuccessPopup(true);
        } else {
          setIsErrorPopupVisible(true);
          setErrMsg(defaultErrorMessage);
        }
      }).catch((e) => {
        // // console.log('');
        // setUploadStatus('failed');
        // setUploadMessage('Upload Failed');
        const ErrorMessageArrays = [];
        if (e.errorList!==undefined) {
          e.errorList?.map((err)=>{
            ErrorMessageArrays.push(err);
          });
        } else {
          ErrorMessageArrays.push(e.userMessage);
        }
        setAllBulkUploadErrors([...ErrorMessageArrays]);
        setIsBulkErrorPopupVisible(true);
      }).finally(() => {
        setDisableSaveBtn(false);
      });
      //   } else {
      // setUploadStatus('failed');
      // setUploadMessage('Upload Failed');
      // updateErrorMsg(`Please Enter the valid Covered price currency it should be ${selectedRow.currency}.
      // Check the Excel Rows ${coveredPriceCurrencyError.join(',')}`);
      //   }
      // } else {
      //   setUploadStatus('failed');
      //   setUploadMessage('Upload Failed');
      // updateErrorMsg(`Please Enter the valid Coverage Unit it should be ${selectedRow.measuringUnit}.
      // Check the Excel Rows ${coverageUnitError.join(',')}`);
      // }
    // }
    // } else {
    //   setUploadStatus('failed');
    //   setUploadMessage('Upload Failed');
    //   updateErrorMsg(`Please Enter the valid Source Type it should be among ${sourceTypes.join(',')}.
    //  Check the Excel Rows ${sourceTypeErrors.join(',')}`);
    // }
    // } else {
    //   setUploadStatus('failed');
    //   setUploadMessage('Upload Failed');
    //   updateErrorMsg(`Please Enter the Coverage month dates it should be among ${allYears.join(',')} and also dates should be from Present Dates (${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}) to Future month (Dec-${selectedRow.executionMonthYear[0]+2}). Past months and Past years are not allowed.
    //   Check the Excel Rows ${coverageMonthErrors.join(',')}`);
    // }
    } else {
      const ErrorMessageArrays = [];
      setUploadStatus('failed');
      setUploadMessage('Upload Failed');
      if (executionMonthErrors.length>0) {
        ErrorMessageArrays.push(`Please Enter the valid execution month it should be matches with Buyplan execution month ${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]} /  ${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0].toString().slice(2)}.
        Check the Excel Rows ${executionMonthErrors.join(',')}`);
      }
      if (coverageMonthErrors.length>0) {
        ErrorMessageArrays.push(`Check the Excel Row 2. Please Enter the Coverage month within the permissible range from ${monthArr[selectedRow.executionMonthYear[1]-2==-1?10:selectedRow.executionMonthYear[1]-2==0?11:selectedRow.executionMonthYear[1]-3]}-${(selectedRow.executionMonthYear[1]-2==-1 || selectedRow.executionMonthYear[1]-2==0)?selectedRow.executionMonthYear[0]-1:selectedRow.executionMonthYear[0]} to Dec-${selectedRow.executionMonthYear[1]-2==-1?selectedRow.executionMonthYear[0]+1:selectedRow.executionMonthYear[0]+2}`);
        // ErrorMessageArrays.push(`Please Enter the Coverage month dates it should be among ${selectedRow.executionMonthYear[1]-2==-1?[selectedRow.executionMonthYear[0]-1, selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1].join(','):[selectedRow.executionMonthYear[0], selectedRow.executionMonthYear[0]+1, selectedRow.executionMonthYear[0]+2].join(',')} and also dates should be from Previous Month-Present year (${monthArr[selectedRow.executionMonthYear[1]-2==-1?11:selectedRow.executionMonthYear[1]-2]}-${selectedRow.executionMonthYear[1]-2==-1?selectedRow.executionMonthYear[0]-1:selectedRow.executionMonthYear[0]}) to Future month-Future year (Dec-${selectedRow.executionMonthYear[1]-2==-1?selectedRow.executionMonthYear[0]+1:selectedRow.executionMonthYear[0]+2}). Past Dates are not allowed.
        // Check the Excel Rows ${coverageMonthErrors.join(',')}`);
      }
      if (sourceTypeErrors.length>0) {
        ErrorMessageArrays.push(`Please Enter the valid Source Type it should be among ${sourceTypes.join(',')}.
        Check the Excel Rows ${sourceTypeErrors.join(',')}`);
      }
      if (sourceIdNullErrors.length>0) {
        ErrorMessageArrays.push(`You may have entered wrong source names. Check the Source Name in rows ${sourceIdNullErrors.join(',')}. Please check the Excel and refer Source Name sheet.`);
      }
      if (countrySectorErrors.length>0) {
        ErrorMessageArrays.push(`Please enter Valid Sector/Country in rows ${countrySectorErrors.join(',')}. Refer the Sector Country Mapping Info of the template file.`);
      }
      if (TreasuryOtherSupplierNameErrors.length>0) {
        ErrorMessageArrays.push(`Please enter valid source names for the rows ${TreasuryOtherSupplierNameErrors.join(',')} where for Source type Treasury the source name should be Treasury and for source type Other the source name should be Other.`);
      }
      if (coverageUnitError.length>0) {
        ErrorMessageArrays.push(`Please Enter the valid Coverage Unit it should be ${selectedRow.measuringUnit}.
        Check the Excel Rows ${coverageUnitError.join(',')}`);
      }
      if (coveredPriceCurrencyError.length>0) {
        ErrorMessageArrays.push(`Please Enter the valid Covered price currency it should be ${selectedRow.currency}.
        Check the Excel Rows ${coveredPriceCurrencyError.join(',')}`);
      }
      if (coveragePricedVolume.length>0) {
        ErrorMessageArrays.push(`Coverege volume and Covered Price should be in numeric format.
        Check the Excel Rows ${coveragePricedVolume.join(',')}`);
      }
      setAllBulkUploadErrors((prev)=>[...prev, ...ErrorMessageArrays]);
      setIsBulkErrorPopupVisible(true);
    }
  }


  function updateErrorMsg(errMsg = '') {
    setIsErrorPopupVisible(true);
    setErrMsg(errMsg);
  }
  const checkRequiredDetails = (value, checkRowInd) => {
    let flagToAddNewRow = true;
    // let errDisplayMsg = '';
    if (value.length >= 1) {
      const lastRow = value[value.length-checkRowInd];
      if (lastRow == undefined) return flagToAddNewRow;
      if (!((lastRow.hasOwnProperty('coverageYearMonth') && lastRow.coverageYearMonth !== '' && lastRow.coverageYearMonth !== null) &&
          ((lastRow.hasOwnProperty('sourceName') && lastRow.sourceName !== '' && lastRow.sourceName !== null) ||
          (lastRow.hasOwnProperty('sourceId') && lastRow.sourceId !== '' && lastRow.sourceId !== null)) &&
          (lastRow.hasOwnProperty('sourceType') && lastRow.sourceType !== '' && lastRow.sourceType !== null) &&
          (lastRow.hasOwnProperty('sector_id') && lastRow.sector_id !== '' && lastRow.sector_id !== null) &&
          (lastRow.hasOwnProperty('region_id') && lastRow.region_id !== '' && lastRow.region_id !== null) &&
          (lastRow.hasOwnProperty('coveredPrice') && lastRow.coveredPrice !== '' && lastRow.coveredPrice !== null) &&
          (lastRow.hasOwnProperty('coverageVolume') && lastRow.coverageVolume !== '' && lastRow.coverageVolume !== null)
      )|| ((lastRow.hasOwnProperty('prevMonth') && lastRow.prevMonth) ?
        (lastRow.hasOwnProperty('comments') ? (lastRow.comments === '' || lastRow.comments === null) : true) : false )) {
        updateErrorMsg('Please Enter The Required Details');
        flagToAddNewRow = false;
      }
      // if (!flagToAddNewRow) {
      //   updateErrorMsg(errDisplayMsg);
      // }
    }
    return flagToAddNewRow;
  };

  function checkAllFields(data, msg) {
    let flagReturn = true;
    data.map((eachRow) => {
      if (!((eachRow.hasOwnProperty('coverageYearMonth') && eachRow.coverageYearMonth !== '' && eachRow.coverageYearMonth != null ) &&
          ((eachRow.hasOwnProperty('sourceName') && eachRow.sourceName !== '' && eachRow.sourceName !== null) ||
          (eachRow.hasOwnProperty('sourceId') && eachRow.sourceId !== '' && eachRow.sourceId !== null)) &&
          (eachRow.hasOwnProperty('sourceType') && eachRow.sourceType !== '' && eachRow.sourceType !== null) &&
          (eachRow.hasOwnProperty('sector_id') && eachRow.sector_id !== '' && eachRow.sector_id !== null) &&
          (eachRow.hasOwnProperty('region_id') && eachRow.region_id !== '' && eachRow.region_id !== null) &&
          (eachRow.hasOwnProperty('coveredPrice') && eachRow.coveredPrice !== '' && eachRow.coveredPrice !== null) &&
          (eachRow.hasOwnProperty('coverageVolume') && eachRow.coverageVolume !== '' && eachRow.coverageVolume !== null)
      )|| ((eachRow.hasOwnProperty('prevMonth') && eachRow.prevMonth) ?
      (eachRow.hasOwnProperty('comments') ? (eachRow.comments === '' || eachRow.comments === null) : true) : false )) {
        updateErrorMsg(msg);
        flagReturn = false;
      }
    });
    return flagReturn;
  }
  function capitalizeFirstLetter(stringToModify) {
    const newString = stringToModify.charAt(0).toUpperCase() + stringToModify.slice(1).toLowerCase();
    return newString;
  }
  function getSupplierName(data, element) {
    const sourceType = capitalizeFirstLetter(element.sourceType);
    if (sourceType === 'Treasury' || sourceType === 'Other') {
      return {supplier_name: sourceType};
    } else {
      const supplierObj = data.filter((supplierName) => {
        if (supplierName.supplier_id == element.sourceName) {
          return supplierName;
        }
      });
      return supplierObj[0];
    }
  }


  const TreasuryOtherSupplierNameErrors = [];
  function getSupplierName2(data, element, index) {
    const sourceType = capitalizeFirstLetter(element.sourceType);
    if (sourceType === 'Treasury' || sourceType === 'Other') {
      if (element.sourceName?.toString().toLowerCase() == sourceType.toLowerCase()) {
        return {supplier_name: sourceType};
      } else {
        TreasuryOtherSupplierNameErrors.push(index+2);
      }
    } else {
      const supplierObj = data.filter((supplierName) => {
        if (supplierName.supplier_id == element.sourceName) {
          return supplierName;
        }
      });
      return supplierObj[0];
    }
  }
  const postCall = () => {
    const covtemp = JSON.parse(JSON.stringify([...data[0]]));
    const coverages = [];
    const flagToAddNewRow = checkAllFields(covtemp, 'Please Enter The Required Details');
    if (flagToAddNewRow) {
    // covtemp.forEach((entry) => {
      covtemp.map((item, index) => {
        const covYearMonth = typeof(item.coverageYearMonth) === 'string' ? item.coverageYearMonth.split('-') : item.coverageYearMonth;
        item.coverageYearMonth = [covYearMonth[1]*1, monthArr.indexOf(covYearMonth[0])+1];
        if (typeof (item.id) === 'string' && (item.id)[0] === 'f' ) {
          delete item.id;
        }
        if (item.sourceType === 'SUPPLIER' && (item.sourceName === 'TREASURY' || item.sourceName === 'OTHER')) {
          item.sourceName = '';
        }
        apiData[0].map((apiItem) => {
          if (apiItem.id === item.id) {
            apiItem = {...item};
          }
        });
        // setCoverageData([...apiData]);
        // item.coveredPrice=commaTextToNumber(item.coveredPrice);
        item.coveredPrice = wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, commaTextToNumber(item.coveredPrice)) : commaTextToNumber(item.coveredPrice);
        // item.coverageVolume=commaTextToNumber(item.coverageVolume);
        item.coverageVolume = wfDetails?.decimal_scale === 0 ? roundToTwo(+wfDetails?.decimal_scale, commaTextToNumber(item.coverageVolume)) : commaTextToNumber(item.coverageVolume);
        item.workflowId = selectedRow.workFlowId;
        item.sourceType = item.sourceType.toUpperCase();
        item.workflowId = selectedRow.workFlowId;
        item.sectorId = item.sector_id;
        item.regionId = item.region_id;
        if (item.sourceType === 'TREASURY' || item.sourceType === 'OTHER') {
          item.sourceId = 0;
        } else {
          item.sourceId = item?.sourceName;
        }
        if (item?.prevMonth && item?.isChanged) {
          item.comments = (item.author ? (item.author+ '-') : '' ) + item.comments;
        }
        item.sourceName = getSupplierName(supplierNames, item)?.supplier_name;
        coverages.push(item);
      });
      // });
      const payload = {'workflowId': + selectedRow.workFlowId, 'prevWorkflowId': + selectedRow.prevWorkFlowId, 'coverages': coverages};
      setDisableSaveBtn(true);
      DialogService.saveCoverageData(payload).then((res) => {
        if (res == true) {
          setIsSavePopupVisible(true);
          // getCall(monthArr);
          updateRowsvalue(monthArr, supplierNames);
        }
      }).catch((e) => {
        // console.log('');
        setIsErrorPopupVisible(true);
        setErrMsg(e.errorMessage);
      }).finally(() => {
        setDisableSaveBtn(false);
      });
    }
  };

  function addNewRowToGrid(value, gridChange) {
    let flagToAddNewRow = false;
    if (!gridChange) {
      flagToAddNewRow = checkRequiredDetails(value, 2);
    }
    if (value.length == 0) {
      editCoverage(value, 0);
    }
    // const flagToAddNewRow = checkRequiredDetails(value, 2);
    if (value.length>1) {
      if (typeof (value[value.length-2].id) === 'string') {
        value[value.length-1].id = (value[value.length-2].id.slice(0, 1)) + (Number(value[value.length-2].id.slice(1)) + 1);
      } else if ((typeof (value[value.length-2].id) !== 'string') && (value[value.length-1].id === undefined)) {
        value[value.length-1].id='f'+(value[value.length-2].id+1);
      }
    } else {
      value[value.length-1].id = 'f' + 0;
    }
    value[value.length-1].executionMonth=`${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`;
    value[value.length-1].coverageUnit=selectedRow.measuringUnit;
    value[value.length-1].coveredPriceCurrency=selectedRow.currency;
    apiData[0]=value;
    if (gridChange) {
      editCoverage(value, 0);
    } else if (flagToAddNewRow) {
      editCoverage(value, 0);
    } else {
      editCoverage(value.slice(0, value.length-1), 0);
    }
  }

  function addExceldata(value, gridChange) {
    let flagToAddNewRow = false;
    if (!gridChange) {
      flagToAddNewRow = checkRequiredDetails(value, 2);
    }
    // const flagToAddNewRow = checkRequiredDetails(value, 2);
    if (value.length>1) {
      if (typeof (value[value.length-2].id) === 'string') {
        value[value.length-1].id = (value[value.length-2].id.slice(0, 1)) + (Number(value[value.length-2].id.slice(1)) + 1);
      } else if ((typeof (value[value.length-2].id) !== 'string') && (value[value.length-1].id === undefined)) {
        value[value.length-1].id='f'+(value[value.length-2].id+1);
      }
    } else {
      value[value.length-1].id = 'f' + 0;
    }
    // value[value.length-1].executionMonth=`${monthArr[selectedRow.executionMonthYear[1]-1]}-${selectedRow.executionMonthYear[0]}`;
    // value[value.length-1].coverageUnit=selectedRow.measuringUnit;
    // value[value.length-1].coveredPriceCurrency=selectedRow.currency;
    apiData[0]=value;
    if (gridChange) {
      editCoverage2(value, 0);
    } else if (flagToAddNewRow) {
      editCoverage2(value, 0);
    } else {
      editCoverage2(value.slice(0, value.length-1), 0);
    }
  }


  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (


    <><div style={{
      marginRight: '-16px'}}>
      <Box sx={{
        display: 'flex',
        width: '100%',
        padding: '15px 15px 0px 5px',
        flexDirection: 'row-reverse',
        marginTop: '-15px',
        marginBottom: '10px',
        gap: '10px',
      }}>
        <Button
          className='addNewRow'
          onClick={() => handleAddRow(false, false)}
        >
          <AddIcon sx={{fontSize: '18px', color: 'white'}} />Add New Row
        </Button>
        {
          userHasPrevMonthAccess && <Button sx={{opacity: `${disablePrevMonthForFeb ? 0.5 : 1 }`}} disabled={disablePrevMonthForFeb} onClick={() => handleAddRow(true, true)} className='addNewRow'>
            <AddIcon sx={{fontSize: '18px', color: 'white'}} />
          Add Previous Month Row
          </Button>
        }
      </Box>
    </div><div>
      {allYears.indexOf(selectedYear) == 0 ?
          <div className="save-coverage-table">
            {data[0] && <DataSheetGrid
              value={data[0]}
              onChange={(value) => {
                addNewRowToGrid(value, true);
              } }
              // allowAddNew={true}
              stickyRightColumn={{
                component: ({rowData, deleteRow}) => (
                  <div className='deleteRowFromGrid' onClick={() => {
                    deleteRow();
                  }}>{!(rowData?.savedRow) && (<>❌</>)}</div>
                ),
              }}
              columns={columns}
              rowClassName={() => 'coverage'}
              disableContextMenu={true}
              gutterColumn={false}
              className='savedDatasheetGridCoverageTable' />}
          </div> : (allYears.indexOf(selectedYear) == 1 ?
            <div className="save-coverage-table">
              {data[1] && <DataSheetGrid
                value={data[1]}
                onChange={(value) => {
                  if (value.length > 1) {
                    if (typeof (value[value.length - 2].id) === 'string') {
                      value[value.length - 1].id = (value[value.length - 2].id.slice(0, 1)) + (Number(value[value.length - 2].id.slice(1)) + 1);
                    } else if ((typeof (value[value.length - 2].id) !== 'string') && (value[value.length - 1].id === undefined)) {
                      value[value.length - 1].id = 'f' + (value[value.length - 2].id + 1);
                    }
                  } else {
                    value[value.length - 1].id = 'f' + 0;
                  }
                  value[value.length - 1].executionMonth = `${monthArr[selectedRow.executionMonthYear[1] - 1]}-${selectedRow.executionMonthYear[0]}`;
                  value[value.length - 1].coverageUnit = selectedRow.measuringUnit;
                  value[value.length - 1].coveredPriceCurrency = selectedRow.currency;
                  apiData[1] = value;
                  editCoverage(value, 1);
                } }
                allowAddNew={true}
                columns={columns}
                rowClassName={() => 'coverage'}
                disableContextMenu={true}
                gutterColumn={false}
                className='savedDatasheetGridCoverageTable' />}
            </div> :
            <div className="save-coverage-table">
              {data[2] && <DataSheetGrid
                value={data[2]}
                onChange={(value) => {
                  if (value.length > 1) {
                    if (typeof (value[value.length - 2].id) === 'string') {
                      value[value.length - 1].id = (value[value.length - 2].id.slice(0, 1)) + (Number(value[value.length - 2].id.slice(1)) + 1);
                    } else if ((typeof (value[value.length - 2].id) !== 'string') && (value[value.length - 1].id === undefined)) {
                      value[value.length - 1].id = 'f' + (value[value.length - 2].id + 1);
                    }
                  } else {
                    value[value.length - 1].id = 'f' + 0;
                  }
                  value[value.length - 1].executionMonth = `${monthArr[selectedRow.executionMonthYear[1] - 1]}-${selectedRow.executionMonthYear[0]}`;
                  value[value.length - 1].coverageUnit = selectedRow.measuringUnit;
                  value[value.length - 1].coveredPriceCurrency = selectedRow.currency;
                  apiData[2] = value;
                  editCoverage(value, 2);
                } }
                allowAddNew={true}
                columns={columns}
                rowClassName={() => 'coverage'}
                disableContextMenu={true}
                gutterColumn={false}
                className='savedDatasheetGridCoverageTable' />}
            </div>)}
      <Styles.StyledCoverageSummBottomButtonBox style={{display: 'flex'}}>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>Total <b>/</b> Average</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{coverageVolume !== null && coverageVolume}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{weightedPrice === 'NaN' ? 0 : weightedPrice}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
        <Styles.StyledCoverageSummBottomButtonBoxText style={{flex: 1, textAlign: 'center'}}>{''}</Styles.StyledCoverageSummBottomButtonBoxText>
      </Styles.StyledCoverageSummBottomButtonBox>
      <div style={{display: 'flex', gap: '12px', justifyContent: 'flex-end', marginBottom: '25px', alignItems: 'center', marginTop: '20px'}}>
        <div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div><Button size='small' sx={{
              'background': 'white', 'color': '#00B1FF',
              'font': '10px/20px \'Segoe UI\'',
              'height': '30px',
              'minWidth': '6rem',
              'marginRight': '10px',
              'fontSize': '0.8rem',
              'border': 'none',
              'cursor': 'pointer',
              'textTransform': 'capitalize',
            // '&:hover': {
            //   'background': '#4297D3',
            //   'border': 'none',
            //   'color': 'white',
            // },
            }}>
              <label style={{width: '100%'}} htmlFor="fileUpload">Bulk upload</label>
              <input type="file" id="fileUpload" onChange={handleFileUploadChange} style={{display: 'none'}} />
              <div>{UploadStatus == 'success' ? <CloudDoneIcon style={{marginTop: '5px', marginLeft: '5px'}} /> : UploadStatus == 'failed' ? <ErrorIcon style={{marginTop: '5px', color: 'red', marginLeft: '5px'}} /> : <CloudUploadIcon style={{marginTop: '5px', marginLeft: '5px'}} />}</div>

            </Button>
            </div>
          </div>
          <div style={{position: 'absolute', marginLeft: '5px', marginTop: '-5px'}}><span className={UploadStatus == 'failed' ? 'text-danger' : 'text-success'}>{uploadMessage}</span></div>
        </div>
        <div>
          <Button
          // onClick={onDownload}
            sx={{
              'backgroundColor': '#4297d3',
              'color': '#ffffff',
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '2px 10px',
              '&:hover': {
                backgroundColor: '#4297d3',
              },
            }}
          >
            <div onClick={handleClickOpen('paper')} style={{color: '#ffffff', textDecoration: 'none'}} >
            Download Bulk Upload Template
            </div>
          </Button>
        </div>
        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: '#4297d3', font: 'normal normal 400 13px/15px \'Segoe UI\''}}>
          <AppButton disabled={disableSavebtn} onClick={() => postCall()} label="Save Coverages" />
        </Box>
      </div>


      <AppDialog open={isSavePopupVisible} handleClose={() => {
        setCoveragesSaved(true);
        setIsSavePopupVisible(false);
        setChangeCoverageTable(true);
      } } text='Coverage details saved successfully' />
      <AppErrorDialog open={isErrorPopupVisible} handleClose={() => {
        setIsErrorPopupVisible(false);
        setErrMsg('');
      } } text={errMsg} />

      <BulkUploadError open={isBulkErrorPopupVisible} handleClose={() => {
        setIsBulkErrorPopupVisible(false);
        setAllBulkUploadErrors([]);
      } } text={allBulkUploadErrors} />


      {/* Instructions popup */}
      {successPopup && <Popup open={successPopup} onClose={handleSuccessPopupClose} />}

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Instructions</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <ol>
              <li style={dropdownSpan}>Click on the Download Starter file button below</li>
              <li style={dropdownSpan}>Enter the Execution Month in the format mmm-yy  / mmm-yyyy (Ex- Apr-23 / Apr-2023). Execution month information is present in buy plan details section of the coverage tab. Execution month is same for all the rows</li>
              <li style={dropdownSpan}>Execution year month should match with the Buy plan execution month.</li>
              <li style={dropdownSpan}>Enter the Coverage Month values in the format mmm-yy  / mmm-yyyy (Ex- Apr-23 / Apr-2023)</li>
              <li style={dropdownSpan}> Enter the Coverage Month values from previous month to future months. Previous months are not allowed.</li>

              <li style={dropdownSpan}>Enter the Source Type as Supplier, Treasury or Others</li>
              <li style={dropdownSpan}>Enter the Source name as below:
                <ol type='a' style={{marginLeft: '20px'}}>
                  <li style={{...dropdownSpan, marginTop: '5px'}}>Source Name as one of the Supplier Name from Supplier Master page, if the Source Type is Supplier</li>
                  <li style={dropdownSpan}>Source Name as Treasury, if the Source Type is Treasury</li>
                  <li style={dropdownSpan}>Source Name as Others, if the Source Type is Others</li>
                </ol>

              </li>
              <li style={dropdownSpan}>Enter the Sector and Country for which the coverage is being taken. Please refer the Sector Country Mapping Info of the template file</li>

              <li style={dropdownSpan}>Enter the Coverage Volume in numeric format. Upto 5 decimal values allowed</li>
              <li style={dropdownSpan}>Enter the Coverage Unit which is used for the buy plan. Coverage unit is present in the Buy plan details section on coverage tab</li>
              <li style={dropdownSpan}>Enter the Covered Price with per unit Covered Price values. Upto 5 decimal places allowed</li>
              <li style={dropdownSpan}>Enter the Covered Price Currency which is used for the buy plan. Currency information is present in the Buy plan details section on the coverage tab</li>
              <li style={dropdownSpan}>Enter Comments if required (Optional)</li>
              <li style={dropdownSpan}>Save the file in your system</li>
              <li style={dropdownSpan}>Click on Bulk upload button, browse and select the saved file.</li>
              <li style={dropdownSpan}>Click on Open to complete the bulk upload</li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}
            sx={{
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '5px 10px',
              '&:hover': {
                color: '#000',
              },
            }}
          >Cancel
          </Button>
          <Button
            onClick={() => handleExport()}
            sx={{
              'backgroundColor': '#4297d3',
              'color': '#ffffff',
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '5px 10px',
              '&:hover': {
                backgroundColor: '#4297d3',
              },
            }}>
            <CloudDownloadIcon style={{margin: '0 5px'}} /> Download
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={errorPopup}
        onClose={handleErrorPopupClose}
        scroll={scroll}
        fullWidth={true}
        maxWidth= "sm"
        aria-labelledby="scroll-dialog-title-error"
        aria-describedby="scroll-dialog-description-error"
      >
        <DialogTitle id="scroll-dialog-title-error" style={{color: '#F44336'}}>{errorMessage}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description-error"
            ref={descriptionElementRefError}
            tabIndex={-1}
            style={{maxHeight: '300px', overflowY: 'auto'}}

          >
            <>
              {errorMessage &&

              <ul style={{marginLeft: '2px', paddingInlineStart: '20px'}}>
                <li style={dropdownSpan}>Some of the rows in your file were not uploaded successfully due to errors. </li>
                <li style={dropdownSpan}>The error file identifies the rows that could not be updated/added due to errors. </li>
                <li style={dropdownSpan}>There is an error message column that talks about the error in each row. </li>
                <li style={dropdownSpan}>Resolve the error in each row and upload the error file by clicking on bulk update button. </li>
                <li style={dropdownSpan}>Download the error file below by clicking on the download button</li>
              </ul>
              }
            </>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorPopupClose}
            sx={{
              'textTransform': 'none',
              'minWidth': '0',
              'padding': '5px 10px',
              '&:hover': {
                color: '#000',
              },
            }}
          >Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </div></>
  );
}
